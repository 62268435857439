import React, { useState, Fragment } from "react";
import "./index.css";
import { Tabs, Row, Col } from "antd";
import { connect } from "react-redux";

import Artsetrecord from "../Artsetrecord";
import ArtSetData from "../Artsetdata";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { setCostumeLeftActiveTab } from "store/action";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";

const { TabPane } = Tabs;

const ArtSetMain = (props) => {
  const { setCostumeLeftActiveTab, costumeLeftActiveTab, activePermisision } =
    props;
  const [ActiveTab, setActiveTab] = useState(costumeLeftActiveTab);

  const handleChange = (e) => {
    setCostumeLeftActiveTab(e);
  };

  return (
    <Fragment>
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Art/Set </TitleText>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row style={{ padding: 10 }}>
            <Col>
              <Tabs
                defaultActiveKey={ActiveTab}
                onChange={handleChange}
                destroyInactiveTabPane
              >
                <TabPane tab="Location-Wise" key="1">
                  <Artsetrecord {...props} />
                </TabPane>
                <TabPane tab="Shooting Day-Wise" key="2">
                  <ArtSetData fromLeft {...props} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { costumeLeftActiveTab },
  user: { menus },
}) => {
  return {
    costumeLeftActiveTab,
    activePermisision: getSPermissionFromMenus(PermissionPage.ART_SET, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtSetMain);
