import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class VfxService {
  vfxService(type, sceneId, data, equipmentName, sceneEquipmentId) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )} `,
      },
    };
    switch (type) {
      case Services.VfxVariables.GET_VFX_TAB_LIST:
        return axios.get(`${getScriptAPIURL()}/scene/${sceneId}/vfx`, config);

      case Services.VfxVariables.GET_VFX_LIST:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/vfx/scenes`,
          config
        );

      case Services.VfxVariables.GET_REQUIREMENT_LIST_DD:
        return axios.get(
          `${getScriptAPIURL()}/vfx/requirements?sceneId=${sceneId}`,
          config
        );

      case Services.VfxVariables.GET_PAYMENT_LIST_DD:
        return axios.get(`${getScriptAPIURL()}/vfx/requirements`, config);

      case Services.VfxVariables.SAVE_VFX:
        return axios.post(
          `${getScriptAPIURL()}/scene/${sceneId}/vfx`,
          data,
          config
        );

      case Services.VfxVariables.UPDATE_VFX:
        return axios.put(`${getScriptAPIURL()}/vfx/${sceneId}`, data, config);

      case Services.VfxVariables.UPDATE_VFX_DESCRIPTION:
        return axios.patch(
          `${getScriptAPIURL()}/scene/${sceneId}/vfx/description?vfxDescription=${data}`,
          data,
          config
        );

      case Services.VfxVariables.GET_VFX_BY_ID:
        return axios.get(`${getScriptAPIURL()}/vfx/${sceneId}`, config);

      case Services.VfxVariables.DELETE_VFX:
        return axios.delete(`${getScriptAPIURL()}/vfx/${sceneId}`, config);

      default:
        break;
    }
  }
}
