import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Row,
  Popconfirm,
  Tooltip,
  Icon,
  Table,
  Input,
  Form,
  Select,
} from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import RecordNotFound from "components/shared/RecordNotFound";
import LegalService from "services/LegalService";
import { useHistory } from "react-router-dom";
import {
  PriceFormat,
  MaskPaymentType,
  DownloadFormURL,
  checkFor,
} from "utilities/ValidationHelper";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
} from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import LegalPayeeService from "services/LegalPayee";
import { OnboardServices } from "services";
import GlobalService from "services/GlobalService";

import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";

const TechnicianTable = (props) => {
  const { Option } = Select;
  const { getFieldDecorator, validateFields } = props.form;
  // State hook
  const userState = useSelector((state) => state?.user);
  // history hook
  const history = new useHistory();
  // States
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [phoneCodeTypeData, setPhoneCodeTypeData] = useState([]);
  const [actorSalutationTypeData, setActorSalutationTypeData] = useState([]);
  //class Initialization
  const globalService = new GlobalService();

  // const values
  const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  const activePermisision = getSPermissionFromMenus(
    "Char-Actor",
    userState?.menus || []
  );
  const techniciansTableColumn = [
    {
      title: "#",
      align: "center",
      key: "key",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: () => {
        return props.location.state.technicianType;
      },
      dataIndex: "partyName",
      key: "partyName",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Form.Item style={{ margin: 0 }}>
          {getFieldDecorator(`partyName_${record.id}`, {
            initialValue: text,
            rules: [
              {
                required: true,
                message: "Enter Name!",
              },
            ],
          })(
            <Input
              addonBefore={salutationDropDown(record)}
              type="text"
              onBlur={(e) => {
                const newValue = e.target.value;
                if (newValue.trim() !== "") {
                  onBlurChange(newValue, record, "partyName");
                }
              }}
            />
          )}
        </Form.Item>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
      inputType: "text",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Input
          addonBefore={phoneCodeDropDown(text, record)}
          type="text"
          pattern="[0-9]*"
          placeholder="Phone Number"
          maxLength={10}
          minLength={10}
          defaultValue={text}
          onBlur={(e) => onClickChange(e.target.value, record, "phoneNumber")}
        />
      ),
    },
    {
      title: "Bank Account Number",
      dataIndex: "bankAccountNumber",
      key: "bankAccountNumber",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Input
          type="text"
          defaultValue={text}
          onBlur={(e) =>
            onClickChange(e.target.value, record, "bankAccountNumber")
          }
        />
      ),
    },
    {
      title: "IFSC Code",
      dataIndex: "ifscCode",
      key: "ifscCode",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Form.Item style={{ margin: 0 }}>
          {getFieldDecorator(`ifscCode_${record.id}`, {
            initialValue: text,
            rules: [
              {
                pattern: "[A-Za-z]{4}[0-9]{7}",
                message: "Invalid IFSC Code",
              },
            ],
          })(
            <Input
              type="text"
              onBlur={(e) =>
                onClickIFSCChange(e.target.value, record, "ifscCode")
              }
            />
          )}
        </Form.Item>
      ),
    },
    {
      title: "PAN",
      dataIndex: "panNumber",
      key: "panNumber",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Form.Item style={{ margin: 0 }}>
          {getFieldDecorator(`panNumber_${record.id}`, {
            initialValue: text,
            rules: [
              {
                pattern: new RegExp("([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"),
                message: "Please Enter Correct Pan Format Ex: JSDFG1234H ",
              },
            ],
          })(
            <Input
              type="text"
              onBlur={(e) => onClickChange(e.target.value, record, "panNumber")}
            />
          )}
        </Form.Item>
      ),
    },
    {
      title: "GST",
      dataIndex: "gstNumber",
      key: "gstNumber",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Form.Item style={{ margin: 0 }}>
          {getFieldDecorator(`gstNumber_${record.id}`, {
            initialValue: text,
            rules: [
              {
                pattern: new RegExp(
                  "[0-3]{1}[0-9]{1}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-2]{1}[Zz]{1}[0-9A-Za-z]{1}"
                ),
                message: "Please Enter Correct Gst Format Ex:22AAAAA0000A1Z5",
              },
            ],
          })(
            <Input
              type="text"
              onBlur={(e) => onClickChange(e.target.value, record, "gstNumber")}
            />
          )}
        </Form.Item>
      ),
    },
    {
      title: "UPI ID",
      dataIndex: "upiId",
      key: "upiId",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Input
          type="text"
          defaultValue={text}
          onBlur={(e) => onClickChange(e.target.value, record, "upiId")}
        />
      ),
    },
    {
      title: "GPAY Number",
      dataIndex: "gpayNumber",
      key: "gpayNumber",
      align: "center",
      editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      render: (text, record) => (
        <Input
          type="text"
          defaultValue={text}
          onBlur={(e) => onClickChange(e.target.value, record, "gpayNumber")}
        />
      ),
    },
  ];
  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    techniciansTableColumn.push({
      title: "Action",
      width: "5%",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            <Popconfirm
              title="Sure to Delete?"
              onConfirm={() => handleDelete(record.key, record.id)}
            >
              <Tooltip
                title={GlobalConstants.DEL}
                placement={GlobalConstants.RIGHT}
              >
                <Button
                  style={{
                    height: "18px",
                    width: "18px",
                    fontSize: "14px",
                    marginTop: 0,
                  }}
                  icon="delete"
                  type="link"
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    });
  }

  // UseEffect to get Technician Table Data from api
  useEffect(() => {
    getTechnicianTableData();
    getCountries();
    getActorSalutationTypeData();
  }, []);

  // functions
  const getCountries = () => {
    const onBoardService = new OnboardServices();
    onBoardService
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        //setCountryTypeData(res.data.countries);
        setPhoneCodeTypeData(res.data.countries);
      });
  };
  const getActorSalutationTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SALUTATION_TYPE
      )
      .then((res) => {
        setActorSalutationTypeData(res?.data || []);
      });
  };
  const phoneCodeDropDown = (phoneNumber, record) => {
    const recordIndex = tableData.findIndex(
      (x) => x.phoneNumber === phoneNumber
    );
    let defaultPhoneCode = null;
    if (recordIndex > -1) {
      const phCode = tableData[recordIndex].phCountryCode;
      if (phCode) {
        const countryPhoneDetail = phoneCodeTypeData.find(
          (x) => x.code === phCode
        );
        if (countryPhoneDetail) {
          defaultPhoneCode = countryPhoneDetail.phoneCode; // +376
        }
      }
    }
    return (
      <Select
        defaultValue={defaultPhoneCode}
        onChange={(e) => onClickChange(e, record, "phCountryCode")}
      >
        {phoneCodeTypeData.map((a) => {
          return <Option value={a?.code}>{a?.phoneCode}</Option>;
        })}
      </Select>
    );
  };

  const salutationDropDown = (record) => {
    let salutationlabel = null;
    const salutationType = actorSalutationTypeData.find(
      (x) => x.value === record.salutation
    );
    if (salutationType) {
      salutationlabel = salutationType.label;
    }
    return (
      <Select
        defaultValue={salutationlabel}
        onChange={(e) => onClickChange(e, record, "salutation")}
      >
        {actorSalutationTypeData.map((a) => {
          return <Option value={a?.value}>{a?.label}</Option>;
        })}
      </Select>
    );
  };

  const getTechnicianTableData = () => {
    setLoading(true);
    const payload = {
      legalAgreementType: "TECHNICIAN",
      technicianId: props.location.state.technicianId,
      size: 0,
    };
    LegalService.getLegalAggreement(payload)
      .then((res) => {
        setLoading(false);
        setTableData(res?.data?.content || []);
        setCount(res?.data?.content?.length || 0);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  const handleAdd = () => {
    setCount(count + 1);
    const newData = {
      key: count,
      salutation: null,
      partyName: null,
      phoneNumber: null,
      phoneCode: null,
      bankAccountNumber: null,
      bankName: null,
      branchName: null,
      ifscCode: null,
      panNumber: null,
      gpayNumber: null,
      gstNumber: null,
      upiId: null,
      technicianId: null,
    };
    setTableData([...tableData, newData]);
  };
  const handleDelete = (key, id) => {
    setTableData(tableData.filter((item) => item.key !== key));
    setCount(tableData.length);
    if (id) {
      LegalPayeeService.service(
        Services.LegalPayeeVariables.DELETE_LEGAL_PAYEE,
        null,
        id
      )
        .then((res) => {
          showNotifications(
            Notifications.SUCCESS,
            "Successfully",
            " Deleted !!"
          );
          getTechnicianTableData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );
        });
    }
  };
  const handleUpdate = (record) => {
    LegalPayeeService.service(
      Services.LegalPayeeVariables.UPDATE_LEGAL_PAYEE,
      record,
      record.id
    )
      .then((res) => {
        // this.setState({
        //   isDisableInput: false,
        // });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        //getTechnicianTableData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };
  const handleSubmit = (record) => {
    LegalPayeeService.service(
      Services.LegalPayeeVariables.CREATE_LEGAL_PAYEE,
      record
    )
      .then((res) => {
        // this.setState({
        //  isDisableInput: false,
        // });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Technician Created Successfully!!"
        );
        // getTechnicianTableData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };
  const onBlurChange = (value, record, type) => {
    if (!record.salutation) {
      record.salutation = "MR";
    }
    if (!record.projectId) {
      record.projectId = projectId;
    }
    // if (!record.phCountryCode) {
    //   record.phCountryCode = "+91";
    // }
    if (!record.technicianId) {
      record.technicianId = props.location.state.technicianId;
    }
    if (value !== "NaN" && value) {
      record[type] = value;
      handleSave(record);
    }
  };
  const onClickChange = (value, record, type) => {
    if (value !== "NaN" && value) {
      record[type] = value;
    }
  };
  const onClickIFSCChange = (value, record, type) => {
    if (value !== "NaN" && value) {
      record[type] = value;
      fetch(`https://ifsc.razorpay.com/${value}`)
        .then((res) => res.json())
        .then((json) => {
          record["branchName"] = json?.BRANCH;
          record["bankName"] = json?.BANK;
        });
    }
  };
  const handleSave = (row) => {
    //const newData = [...this.state.dataSource];
    const index = tableData.findIndex((item) => row.key === item.key);
    const item = tableData[index];
    tableData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTableData(tableData);
    if (!row.id) {
      handleSubmit(row);
    }
    if (row.id) {
      handleUpdate(row);
    }
  };
  return (
    <div>
      <Row span={24}>
        <Title hasBack history={history}>
          <TitleText>{props.location.state.technicianType}</TitleText>
        </Title>
      </Row>
      <Row style={{ padding: 10 }}>
        <Col xl={24} style={{ paddingBottom: 10 }}>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
            <Button
              onClick={handleAdd}
              type="primary"
              style={{ float: "right" }}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          )}
        </Col>
        <Col xl={24}>
          <Table
            bordered
            dataSource={tableData}
            columns={techniciansTableColumn}
            loading={loading}
            pagination={false}
            locale={{
              emptyText: !loading && <RecordNotFound />,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const TechnicianTableForm = Form.create()(TechnicianTable);

export default TechnicianTableForm;
