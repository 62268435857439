import React, { Component } from "react";
import { Row, Col, Collapse } from "antd";

import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import CardContainer from "components/shared/CardContainer";
import ArtSetServices from "services/ArtSetServices";
import { Services } from "constants/AppConstants";
import { Value, Label } from "components/shared/Label";
import ThemeColors from "constants/ThemeConstants/variables";
import ArtSetOverviewTable from "./ArtSetOverviewTable/ArtSetOverviewTable";

const { Panel } = Collapse;

export default class ArtSetOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sceneLocationId: props.location.state.sceneLocationId,
      scenes: props.location.state.scenes,
      overViewData: null,
      openPanel: "0",
    };
    this._artSetService = new ArtSetServices();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { sceneLocationId } = this.state;
    this._artSetService
      .service(
        Services.ArtSetVariables.GET_ART_SET_OVERVIEW_DETAILS,
        sceneLocationId
      )
      .then((res) => {
        this.setState({
          overViewData: res.data,
        });
      })
      .catch((err) => {});
  };

  handleAccordionChange = (openPanel) => {
    this.setState({
      openPanel,
    });
  };

  renderScenes = () => {
    const { scenes, openPanel } = this.state;
    return (
      scenes &&
      scenes.map((scene, i) => (
        <Row
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          type="flex"
          justify="center"
        >
          <Col md={{ span: 22 }}>
            <Collapse activeKey={scenes.map((scene, i) => i.toString())}>
              <Panel
                header={`Scene Number: ${scene.sceneNumber}`}
                key={i}
                className="accordion_style"
              >
                <ArtSetOverviewTable
                  {...this.props}
                  artSetLeft
                  artSetLeftSceneId={scene.sceneId}
                  serviceType={Services.ArtSetVariables.GET_ART_SET_LIST}
                />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ))
    );
  };
  render() {
    const { overViewData } = this.state;
    const isOverViewData = overViewData && true;
    return (
      <Row>
        <Col xs={24} style={{ paddingBottom: 10 }}>
          <Title hasBack {...this.props}>
            <TitleText>Art/Set Overview</TitleText>
          </Title>
          <Row type="flex" justify="center" style={{ padding: 10 }}>
            <Col xs={22}>
              <Row>
                <Col xs={12}>
                  <Label>Scene Location</Label>
                  <Value
                    style={{ paddingLeft: 5, color: ThemeColors.primaryColor }}
                  >
                    {isOverViewData && overViewData.sceneLocation}
                  </Value>
                </Col>
                <Col
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Label>Actual Location</Label>
                  <Value
                    style={{ paddingLeft: 5, color: ThemeColors.primaryColor }}
                  >
                    {isOverViewData && overViewData.actualLocation}
                  </Value>
                </Col>
              </Row>
            </Col>
          </Row>
          {this.renderScenes()}
        </Col>
      </Row>
    );
  }
}
