import React, { Component } from "react";
import { Table, Input, Form, Select, Modal } from "antd";
import UploadImage from "components/shared/UploadImage/UploadImage";
import {
  AttachmentType,
  GlobalConstants,
  LocalStorage,
  Lotties,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { ScriptService } from "services";
import { Services, Notifications } from "constants/AppConstants";
import ArtSetTabService from "services/SceneOverviewService/ArtSetTabServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { Link } from "react-router-dom";
import ImageViewer from "components/shared/ImageViewer";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty, isEqual } from "lodash";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, removeColumns } from "utilities/ValidationHelper";
import LottieComponent from "components/shared/LottieComponent";
import { Value } from "components/shared/Label";
import RecordNotFound from "components/shared/RecordNotFound";
import PaymentTypeDD from "components/ArtSetTab/ArtSetTable/PaymentTypeDD";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";

const { Option } = Select;
const { confirm } = Modal;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              type="number"
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              style={{ width: "100%" }}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              style={{ width: "100%" }}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class ArtSetOverviewTable extends Component {
  getArtSetType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_DETAIL_TYPE
      )
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
    this._artSetTabService = new ArtSetTabService();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      artSetDDList: [],
      count: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      dropDown: null,
      sceneId: null,
      isLoading: true,
      isButtonLoading: false,
      sceneId: null,
      paymentTypeData: [],
      pageType: null,
      serviceType: null,
      dataSource: [],
      shootDate: null,
      artSetLeft: props.artSetLeft,
      artSetLeftSceneId: props.artSetLeftSceneId,
      serviceType: props.serviceType,
      artSetKeyword: null,
      // tableCount: 0
    };
  }
  recordData = (record) => {
    if (this.state.fileList) {
      this.handleSave(record, this.state.fileList, "image");
    }
  };

  deleteImage = (id) => {
    let deleteIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let finalData = imageFiles.splice(deleteIndex, 1);
    this.setState({
      imageFiles,
    });
  };

  splitBasedOnFileTypes = (files) => {
    let tempImageFiles = [];
    files.forEach((file) => {
      if (file.images) {
        tempImageFiles.push(file?.images);
      }
    });
    let imageFiles =
      tempImageFiles &&
      tempImageFiles.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      imageFiles,
    });
  };

  handleImage = (url, data) => {
    if (data?.images?.length > 0) {
      data.images = [
        ...data.images,
        { attachmentId: url, fileType: GlobalConstants.IMAGE },
      ];
    } else {
      data.images = [{ attachmentId: url, fileType: GlobalConstants.IMAGE }];
    }
    this.handleSave(data, this.state.fileList, "image", this.state.fileList);
  };

  componentDidMount() {
    const { artSetLeft, artSetLeftSceneId, serviceType } = this.state;
    if (
      this.props.pageType === "Page" &&
      this.props.serviceType &&
      this.props.shootDate
    ) {
      this.setState(
        {
          pageType: this.props.pageType,
          serviceType: this.props.serviceType,
          shootDate: this.props.shootDate,
        },
        () => this.getArtSetData()
      );
    }
    if (artSetLeft) {
      this.setState(
        {
          sceneId: artSetLeftSceneId,
          serviceType,
        },
        () => this.getArtSetData()
      );
    }
    this.getArtSetDD();
    this.getArtSetType();
    this.getPaymentTypeData();
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  getPaymentTypeData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_PAYMENT
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data,
        });
      });
  }

  getArtSetData = () => {
    const { artSetLeft } = this.state;
    if (this.state.serviceType) {
      this.setState({
        isLoading: true,
      });
      this.setState({ count: 0 });
      this._artSetTabService
        .service(
          this.state.serviceType,
          null,
          null,
          this.state.sceneId || this.state.shootDate
        )
        .then((res) => {
          let artSetData = [];
          res.data.artSets &&
            res.data.artSets.map((data, i) => {
              artSetData.push({ ...data, key: this.state.count });
              this.setState({
                count: this.state.count + 1,
              });
            });
          this.setState(
            {
              dataSource: artSetData,
              isLoading: false,
            },
            () => {
              this.splitBasedOnFileTypes(this.state.dataSource);
              !artSetLeft && this.checkForEmpty(this.state.dataSource);
            }
          );
        });
    }
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        this.checkForEmpty(this.state.dataSource);
      }
    );
    this._artSetTabService
      .service(Services.ArtSetVariables.DELETE_ART_SET_LIST, id)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Successfully Deleted!!"
        );
        // this.setState({ count: 0 });
        // this.getArtSetData();
        // this.getArtSetData()
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message
        );
      });
  };

  handleSubmit = (record) => {
    this.setState({ isButtonLoading: true });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
    let data = this.state.dataSource[indexOfTheCurrentRow];
    record.type = record.type;
    this._artSetTabService
      .service(Services.ArtSetVariables.ADD_ART_SET, record)
      .then((res) => {
        this.setState({ isButtonLoading: false });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully!!"
        );
        // this.setState({ count: 0 });
        this.getArtSetData();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          showNotifications(
            Notifications.ERROR,
            "Access Denied",
            "Please contact your Project Admin"
          );
          this.props.getPermissions();
        } else {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          );
        }
      });
  };

  handleUpdate = (record) => {
    this.setState({ isButtonLoading: true });
    let type = record.type;
    // type = type.replace(/ /g, "_");
    // let enumType = type.toUpperCase();
    let data = {
      description: record.description,
      images: record.images,
      quantity: record.quantity,
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
      type: type,
      artSetDetailId: record.artSetDetailId,
      costType: record.costType,
      costEstimation: record.costEstimation,
      sceneId: record.sceneId,
    };
    // if (type !== "SPECIAL_PROPERTY") {
    //   delete data.costType;
    // }
    if (record.artSetDetailId) {
      this._artSetTabService
        .service(
          Services.ArtSetVariables.UPDATE_ART_SET_LIST,
          data,
          record.artSetDetailId
        )
        .then((res) => {
          this.setState({ isButtonLoading: false });
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
          if (this.state.pageType === "Page") {
            this.getArtSetData();
          }
          this.getArtSetData();
          // this.setState({ count: 0 });
          // this.getArtSetData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          );
        });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      type: null,
      quantity: "",
      images: [],
      costEstimation: "",
      costType: null,
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type) {
    this.handleSave(record, value, type);
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const that = this;
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      let modifyItem = newData[index];
      switch (type) {
        case "type":
          modifyItem.type = value;
          modifyItem.costTypeValue = null;
          modifyItem.costType = null;
          break;
        case "costType":
          modifyItem.costTypeValue = value;
          modifyItem.costType = value;
          break;
        case "description":
          modifyItem.description = value;
          break;
        case "image":
          modifyItem.images = value;
          break;
        default:
          break;
      }
    }
    if (!row.artSetDetailId) {
      if (
        row.description &&
        row.type &&
        row.quantity &&
        checkFor(this.props.activePermisision, GlobalConstants.FINANCE)
          ? row.costEstimation && row.costType
          : true
      ) {
        let data = row;
        confirm({
          title:
            "Do you want to apply this Art/Set details to all scenes which are shoot at the same location?",
          content: `Click "OK" to apply`,
          onOk() {
            return new Promise((resolve, reject) => {
              data = { ...data, isSetAllScenes: true };
              that.handleSubmit(data);
              resolve();
            });
          },
          onCancel() {
            data = { ...data, isSetAllScenes: false };
            that.handleSubmit(data);
          },
        });
      }
    }
    if (row.artSetDetailId) {
      if (
        row.description &&
        row.type &&
        row.quantity &&
        checkFor(this.props.activePermisision, GlobalConstants.FINANCE)
          ? row.costEstimation && row.costType
          : true
      ) {
        if (!isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  getArtSetDD = (value) => {
    const { artSetLeftSceneId } = this.state;
    this._artSetTabService
      .service(
        Services.ArtSetVariables.GET_ART_SET_DROPDOWN,
        null,
        null,
        artSetLeftSceneId
      )
      .then((res) => {
        this.setState({
          artSetDDList: res.data.artSets,
        });
      })
      .catch(() => {
        this.setState({});
      });
  };

  handleArtSet = (name, record) => {
    this._artSetTabService
      .service(Services.ArtSetVariables.GET_ART_SET_BY_NAME, name)
      .then((res) => {
        let data = {
          costEstimation: res.data.amount,
          costType: res.data.costType,
          type: res.data.type,
          description: name,
          sceneId: this.state.sceneId,
        };
        if (record.artSetDetailId) {
          data = {
            ...data,
            artSetDetailId: record.artSetDetailId,
            quantity: 1,
          };
          this.handleUpdate(data);
        } else {
          this.handleSubmit(data);
        }
      })
      .catch((err) => {
        this.setState({});
      });
  };

  handleArtSetValue = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          artSetKeyword: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  setArtSet = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = this.state.artSetKeyword;
      if (newData) {
        this.setState({ eventType });
        record.description = newData;
        this.onChange(newData, record, "description");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  render() {
    const { dataSource, isLoading, artSetLeft } = this.state;
    const { currentPermission, activePermissions } = this.props;

    let columnsData = null;

    if (this.state.pageType !== "Page") {
      columnsData = [
        {
          title: "#",
          align: "center",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Description",
          dataIndex: "description",
          // editable: true,
          width: "20%",
          className: "overflowInput",
          render: (text, record) => (
            // this.state.isLoading && (
            <Select
              showSearch
              onSearch={(val) => this.handleArtSetValue(val, record)}
              onFocus={() =>
                this.setState({
                  juniorCharcKeyword: null,
                  eventType: "isNotBlur",
                })
              }
              onBlur={(value) => this.setArtSet(value, record, "blur")}
              style={{ width: "100%", minWidth: "98%", maxWidth: "200px" }}
              placeholder="Select type"
              // disabled={!this.props.EDIT && record.characterId}
              // onChange={value =>
              //   this.onChange(value, record, "characterName")
              // }
              onChange={(value) => this.handleArtSet(value, record)}
              value={text ? text : ""}
              // defaultValue={text ? text : ""}
              className={`${!text ? "custom-empty-active" : ""}`}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.artSetDDList &&
                this.state.artSetDDList.map((option, i) => (
                  <Option value={option.label}>{option.label}</Option>
                ))}
            </Select>
          ),
          // )
        },
        {
          title: "Type",
          dataIndex: "type",
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <Select
                style={{ width: "100%" }}
                placeholder="Select type"
                onChange={(value) => this.onChange(value, record, "type")}
                defaultValue={text ? text : ""}
              >
                {this.state.dropDown &&
                  this.state.dropDown.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ) : null,
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          editable: true,
          width: "8%",
          className: "cash",
        },
        {
          title: "Cost Estimation (₹)",
          dataIndex: "costEstimation",
          editable: true,
          width: "15%",
          className: "cash",
        },
        {
          title: "Payment Type",
          dataIndex: "costTypeValue",
          render: (text, record) => (
            <PaymentTypeDD
              type={record.type}
              text={text}
              onChange={this.onChange}
              record={record}
            />
          ),
        },
        {
          title: "Image Reference",
          dataIndex: "images",
          className: "upload-table",
          render: (text, record) => {
            return (
              <React.Fragment>
                {record?.images?.length > 0 ? (
                  <ImageViewer
                    imageCount={1}
                    ImagesData={record?.images}
                    type="table"
                    canDelete
                    refreshData={this.getArtSetData}
                    url={Services.ArtSetVariables.DELETE_TABLE_IMAGE}
                  />
                ) : null}
                <div className="small-upload-image">
                  {!this.state.isButtonLoading ? (
                    <UploadImage
                      keys={record}
                      type={AttachmentType.artSetImage}
                      handleImage={this.handleImage}
                    />
                  ) : null}
                </div>
              </React.Fragment>
            );
          },
        },
      ];
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    if (!checkFor(this.props.activePermisision, GlobalConstants.FINANCE)) {
      columnsData = removeColumns(columnsData, [
        "Cost Estimation (₹)",
        "Payment Type",
      ]);
    }

    const columns = columnsData.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex !== "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <div>
        {!isEmpty(dataSource) && artSetLeft ? (
          <Table
            className="art-set"
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={dataSource}
            columns={columns}
            // pagination={{ pageSize: 1000 }}
            pagination={false}
            locale={{
              emptyText: !isLoading && <RecordNotFound />,
            }}
            loading={isLoading}
          />
        ) : (
          <RecordNotFound />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.ART_SET, menus),
  };
};

export default connect(mapStateToProps, null)(ArtSetOverviewTable);
