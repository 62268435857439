import axios from "axios";
import { LocalStorage } from "constants/AppConstants";
import { getAttachementAPIURL } from "utilities/APIHelper";

let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

const projectImageUpload = (domain = "", data = {}, requestParams = {}) => {
  return axios.post(`${getAttachementAPIURL()}/attachment/${domain}`, data, {
    params: requestParams,
  });
};

const UploadService = {
  projectImageUpload,
};

export default UploadService;
