import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Modal,
  Tooltip,
  Icon,
  DatePicker,
} from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { GlobalConstants, LocalStorage } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { Services, Notifications } from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import { connect } from "react-redux";
import * as _ from "lodash";
import { getSPermissionFromMenus } from "store/stateHelper";
import BudjetService from "services/BudjetService";
import AccountingService from "services/AccountingService";
import Uploaddocumentfile from "components/uploaddocumentfile";
import RecordNotFound from "components/shared/RecordNotFound";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getPermissions } from "store/thunk-actions";
import {
  checkFor,
  amountOnly,
  StartCase,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import FileCard from "components/shared/FileCardComponent";
import EquipmentDocumentUpload from "components/shared/EquipmentDocumentUpload";
import DeleteComponent from "components/shared/DeleteComponent";
import AccPaymentTypeDD from "../AccPaymentTypeDD";
import AccPaymentModeDD from "../AccPaymentModeDD";
import moment from "moment";
import SharedDocumentUpload from "components/shared/SharedDocumentUpload";
import Accounting from "services/AccountingServices";
import { handleError } from "utilities/ErrorHandler";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const { confirm } = Modal;

const dateFormat = GlobalConstants.DD_MM_YYYY;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^(0|[0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              type="number"
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class AccTravellist extends Component {
  getArtSetType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_DETAIL_TYPE
      )
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  handleSeperateImage = (record) => {
    let temp = [];
    record.documents &&
      record.documents.map((record) => {
        temp.push({
          fileId: record.fileId,
          documentId: record.documentId,
          fileType: GlobalConstants.DOCUMENT,
        });
      });
    return temp;
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();

    this._accountingService = new AccountingService();
    this.account = new Accounting();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      isLoading: false,
      count: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      planId: props.match.params.id,
      expenseTypeData: [],
      paymentTypeData: [],
      paymentModeData: [],
      receiverNameList: [],
      receiverName: null,
      productionAccountId: props.match.params.id,
    };
  }

  splitBasedOnFileTypes = (files) => {
    let tempImageFiles = [];
    let files1 = files.filter((file) => {
      if (file.images !== undefined) {
        tempImageFiles.push(...file.images);
      }
    });
    let imageFiles =
      tempImageFiles &&
      tempImageFiles.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      imageFiles,
    });
  };

  componentDidMount() {
    if (this.state.productionAccountId) {
      this.fetchData();
      this.fetchExpenseType();
      this.fetchPaymentMode();
      this.fetchPaymentType();
      this.getPayee();
    }
  }

  getPayee = () => {
    this.account
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN)
      .then((res) => {
        this.setState({
          receiverNameList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  fetchExpenseType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EXPENSE_TYPE
      )
      .then((res) => {
        this.setState({
          expenseTypeData: res.data,
        });
      });
  };

  fetchPaymentType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data || [],
        });
      });
  };

  fetchPaymentMode = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        this.setState({
          paymentModeData: res.data || [],
        });
      });
  };

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  fetchData = () => {
    const { productionAccountId } = this.state;
    let dataSource = [];
    this.setState({
      isLoading: true,
    });
    this.account
      .services(
        Services.AccountingVariables.GET_PRE_PRODUCTION_MORE,
        AccountingVariables.TRAVEL_AND_LODGEING,
        productionAccountId
      )
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: this.state.count,
              payee: data.receiverName,
              paymentType: ReplaceWithSpace(data.paymentType),
              paymentMode: ReplaceWithSpace(data.paymentMode),
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      payeeId: null,
      receiverName: null,
      amountPaid: null,
      paymentMode: null,
      referenceNumber: null,
      documents: [],
      comments: null,
      paymentDate: null,
      count: 0,
      description: null,
      payee: null,
      itemQuantity: null,
      expenseType: null,
    };
    this.setState(
      {
        dataSource: [...dataSource, newData],
      },
      () => {
        console.log(this.state.dataSource);
      }
    );
  };

  onChange = (value, record, type) => {
    record[type] = value;
    this.handleSave(record);
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.productionPaymentId) {
      if (
        row.description &&
        row.paymentDate &&
        row.paymentMode &&
        row.paymentType &&
        (row.receiverName || row.payeeId) &&
        row.amountPaid &&
        // row.referenceNumber &&
        row.itemQuantity &&
        row.expenseType
      ) {
        this.handleUpdate(row);
      }
      // let equal = checkEqual(row, this.state.activeRecord);
      // if (!checkEqual(row, this.state.activeRecord)) {

      // }
    } else {
      if (
        row.description &&
        row.paymentDate &&
        row.paymentMode &&
        row.paymentType &&
        (row.receiverName || row.payeeId) &&
        row.amountPaid &&
        // row.referenceNumber &&
        row.itemQuantity &&
        row.expenseType
      ) {
        this.handleSubmit(row);
        // else {]
        //   showNotifications(
        //     Notifications.ERROR,
        //     "Failed",
        //     "Crew count must be greater than 0"
        //   );
        // }
      }
    }
    this.setState({ dataSource: newData });
  };

  handleSubmit = (record) => {
    const { productionAccountId } = this.state;
    const {
      amountPaid,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode,
      paymentType,
      referenceNumber,
      receiverName,
      expenseType,
      itemQuantity,
    } = record;
    const data = {
      amountPaid,
      category: AccountingVariables.TRAVEL_AND_LODGEING,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode: paymentMode,
      paymentType: paymentType,
      productionAccountId,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      receiverName,
      referenceNumber,
      expenseType,
      itemQuantity,
    };
    this.account
      .services(Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (record) => {
    const { productionAccountId } = this.state;
    const {
      amountPaid,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode,
      paymentType,
      referenceNumber,
      receiverName,
      productionPaymentId,
      expenseType,
      itemQuantity,
    } = record;
    const data = {
      amountPaid,
      category: AccountingVariables.TRAVEL_AND_LODGEING,
      comments,
      description,
      payeeId,
      paymentDate: moment(
        paymentDate,
        GlobalConstants.DD_MM_YYYY,
        true
      ).isValid()
        ? paymentDate
        : moment(paymentDate).utc().format(GlobalConstants.DD_MM_YYYY),
      paymentMode: paymentMode.toUpperCase(),
      paymentType: paymentType.toUpperCase(),
      productionAccountId,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      receiverName,
      referenceNumber,
      expenseType,
      itemQuantity,
    };
    this.account
      .services(
        Services.AccountingVariables.UPDATE_PRE_PRODUCTION_MORE,
        data,
        productionPaymentId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  handleReceiverName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          receiverName: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handlePayee = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    record["receiverName"] = null;
    this.setState({
      receiverName: null,
    });
    this.handleSave(record);
  };

  handleReceiver = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.receiverName);
      if (newData) {
        this.setState({ eventType });
        record.receiverName = newData;
        record.payeeId = null;
        record.payee = newData;
        this.onChange(newData, record, "payee");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  disabledDate = (current, toDate) => {
    const { shootDate } = this.state;
    // Can not select days before today and today
    if (current) {
      if (toDate) {
        return current < moment(toDate, dateFormat).add(1, "days");
      }
      return current < moment().startOf("day");
    }
  };

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      receiverNameList,
      productionAccountId,
    } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const { activePermisision } = this.props;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const column = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },

      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "name",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            autoFocus={!record.productionPaymentId}
            onBlur={(e) => this.onChange(e.target.value, record, "description")}
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "Expense Type",
        dataIndex: "expenseType",
        align: "center",
        width: "8%",
        render: (text, record) => (
          <Select
            className={`${!text ? "err" : ""} border-none`}
            style={{ width: "100%" }}
            placeholder="Select type"
            onChange={(value) => this.onChange(value, record, "expenseType")}
            defaultValue={text ? text : ""}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
            }
          >
            {this.state.expenseTypeData &&
              this.state.expenseTypeData.map((option, i) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "itemQuantity",
        key: "itemQuantity",
        align: "right",
        width: "8%",
        className: "cash",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            type="number"
            defaultValue={text}
            onBlur={(e) =>
              this.onChange(e.target.value, record, "itemQuantity")
            }
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "RECEIVER NAME",
        dataIndex: "payee",
        key: "payee",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            showSearch
            className={`${!text ? "err" : ""}`}
            value={record.payee}
            onChange={(e) => this.handlePayee(e, record, "payeeId")}
            onBlur={(e) => this.handleReceiver(e, record, "blur")}
            onSearch={(val) => this.handleReceiverName(val, record)}
          >
            {receiverNameList &&
              receiverNameList.map((list) => {
                return <option value={list.value}>{list.label}</option>;
              })}
          </Select>
        ),
      },
      {
        title: "AMOUNT PAID",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: "8%",
        className: "cash",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            type="number"
            defaultValue={text}
            onBlur={(e) => this.onChange(amountOnly(e), record, "amountPaid")}
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        key: "paymentType",
        width: "7%",
        render: (text, record) => (
          <AccPaymentTypeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentType"
          />
        ),
      },
      {
        title: "Payment Mode",
        dataIndex: "paymentMode",
        key: "paymentMode",
        width: "8%",
        render: (text, record) => (
          <AccPaymentModeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentMode"
          />
        ),
      },
      // {
      //   title: "Reference no",
      //   dataIndex: "referenceNumber",
      //   key: "referenceNumber",
      //   width: "8%",
      //   render: (text, record) => (
      //     <Input
      //       className={`${!text ? "err" : ""}`}
      //       defaultValue={text}
      //       onBlur={e =>
      //         this.onChange(e.target.value, record, "referenceNumber")
      //       }
      //       style={{ width: "100%" }}
      //     />
      //   )
      // },
      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: "10%",
        render: (text, record) => (
          <DatePicker
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "paymentDate")
            }
            className={`${!text ? "err" : ""}`}
            format={dateFormat}
            disabledDate={(current) => this.disabledDate(current)}
          />
        ),
      },
      {
        title: "Documents",
        dataIndex: "documents",
        key: "documents",
        width: "6%",
        render: (text, record) =>
          record.productionPaymentId && (
            <SharedDocumentUpload
              type={GlobalConstants.PRE_PRODUCTION_DOCUMENT}
              // key={index}
              refreshData={this.fetchData}
              data={{
                ...record,
                productionAccountId,
                paymentDate: moment(record.paymentDate)
                  .utc()
                  .format(GlobalConstants.DD_MM_YYYY),
              }}
            />
          ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        width: "10%",
        render: (text, record) => (
          <TextArea
            defaultValue={text}
            placeholder="enter comments"
            autoSize
            onBlur={(e) => this.onChange(e.target.value, record, "comments")}
          />
        ),
      },
      {
        title: "",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "3%",
        render: (text, record) => (
          <>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
              <DeleteComponent
                record={record}
                onClick={(record) => this.handleDelete(record)}
              />
            )}
          </>
        ),
      },
    ];

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex !== "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xl={24}>
          <Title hasBack {...this.props}>
            <TitleText>Accounting - Project Plan Details </TitleText>
          </Title>
          <br />
        </Col>
        <Col xs={24} style={{ padding: 10 }}>
          <Col xs={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24} className="accProductionScroll">
            <Table
              className="art-set"
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
              loading={isLoading}
            />
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({
  scene,
  activeItems: { activeMenuPermissions },
  user: { menus },
}) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccTravellist);
