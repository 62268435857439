import React, { Component, Fragment } from "react";
import { Row, Col, Button } from "antd";
import DualListBox from "react-dual-listbox";
import { isEqual } from "lodash";

import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { Value } from "components/shared/Label";
import ReportService from "services/ReportService";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import Loader from "components/shared/Loader";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";

export default class ReportMore extends Component {
  constructor(props) {
    super(props);
    this.report = new ReportService();
    this.state = {
      saving: false,
      reportId: this.props.match.params.id,
      valuesFetched: false,
      ...this.getInitialState(),
    };
  }

  componentDidMount() {
    const { reportId } = this.state;
    Promise.all([
      this.getAllReportFields(),
      this.getSelectedReportFields(reportId),
    ])
      .then(([res1, res2]) => {
        this.setState({
          valuesFetched: true,
          options: res1.data || [],
          selected: res2.data.reportFieldIds.map((field) => field.toString()),
          initialSelected: res2.data.reportFieldIds.map((field) =>
            field.toString()
          ),
        });
      })
      .catch(([err]) => {
        this.setState({
          valuesFetched: true,
        });
      });
  }

  getSelectedReportFields = (reportId) => {
    return this.report.reportService(
      Services.ReportVariables.GET_REPORT_FIELDS_BY_ID,
      reportId
    );
  };

  getAllReportFields = () => {
    return this.report.reportService(
      Services.ReportVariables.GET_ALL_REPORT_FIELDS
    );
  };

  getInitialState = () => {
    return {
      selected: [],
      initialSelected: [],
      options: [],
    };
  };

  onChange = (selected) => {
    this.setState({ selected });
  };

  handleSave = () => {
    const { selected, reportId } = this.state;
    let fields = selected.map((field) => {
      return {
        reportFieldId: field,
      };
    });
    this.setState({
      saving: true,
      valuesFetched: false,
    });
    this.report
      .reportService(
        Services.ReportVariables.SAVE_REPORT_FIELDS,
        { fields },
        reportId
      )
      .then((res) => {
        this.setState({
          saving: false,
          valuesFetched: true,
          initialSelected: selected,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          saving: false,
          valuesFetched: true,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        this.handleReset();
      });
  };

  handleReset = () => {
    this.setState({
      selected: this.state.initialSelected,
    });
  };

  render() {
    const { selected, saving, initialSelected, valuesFetched, options } =
      this.state;
    const isSame = isEqual(selected, initialSelected);
    const isEmpty = selected.length === 0;
    return (
      <Fragment>
        {!valuesFetched && <Loader />}
        <Row style={{ height: "100%" }}>
          <Col>
            <Title {...this.props} hasBack>
              <TitleText>Report Detail</TitleText>
            </Title>
          </Col>
          <Row type="flex" justify="end" style={{ height: "100%" }}>
            <Col xl={24}>
              <Row type="flex" align="middle" justify="center">
                <Col
                  xl={20}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: 10,
                  }}
                >
                  <Value>Available Columns</Value>
                  <Value style={{ paddingRight: 54 }}>Selected Columns</Value>
                </Col>
                <Col xl={20} style={{ paddingTop: 10 }} className="listBox">
                  <DualListBox
                    // canFilter
                    options={options}
                    selected={selected}
                    onChange={this.onChange}
                    preserveSelectOrder
                    showOrderButtons
                    // showHeaderLabels
                    disabled={!valuesFetched}
                  />
                </Col>
                <Col
                  xl={20}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: 54,
                  }}
                >
                  <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    loading={saving}
                    onClick={this.handleSave}
                    icon="save"
                    disabled={isEmpty || isSame}
                  >
                    Save Report
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginTop: 10, marginLeft: 5 }}
                    onClick={this.handleReset}
                    icon="reload"
                    disabled={isSame}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Fragment>
    );
  }
}
