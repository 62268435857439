import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button, Icon, Input, Tag, Switch } from "antd";
import { Link } from "react-router-dom";

import AccountingServices from "services/AccountingService";
import TooltipIcon from "components/shared/TooltipIcon";
import { MenusRoute, GlobalConstants, Services } from "constants/AppConstants";
import {
  StartCase,
  checkFor,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const { Search } = Input;

const AccLocationActorTable = (props) => {
  const account = new AccountingServices();
  const [DataSource, setDataSource] = useState([]);
  const [AdvanceDataSource, setAdvanceDataSource] = useState([]);
  const [SearchKey, setSearchKey] = useState(null);
  const [switchActive, setSwitchActive] = useState(false);
  let filteredDataSource = [];
  useEffect(() => {
    getLocationData();
    getAdvancePaymentData();
  }, []);

  const handleSearch = (key) => {
    setSearchKey(key);
  };

  const { activePermisision } = props;

  filteredDataSource = SearchKey
    ? switchActive
      ? AdvanceDataSource.filter((data) =>
          data.description.toLowerCase().includes(SearchKey)
        )
      : DataSource.filter((data) =>
          data.description.toLowerCase().includes(SearchKey)
        )
    : switchActive
    ? AdvanceDataSource
    : DataSource;

  const columns = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shoot date",
      dataIndex: "shootDate",
      key: "shootDate",
      width: "18%",
      render: (text) => <Tag className="statusTag">{text}</Tag>,
    },
    {
      title: "Location name",
      dataIndex: "locationName",
      key: "name",
      width: "25%",
      render: (text) => (
        <Highlighter
          highlightClassName="highLightClass"
          searchWords={[SearchKey]}
          autoEscape={true}
          textToHighlight={text}
        />
      ),
    },
    {
      title: "Agreed amount",
      dataIndex: "agreedAmount",
      key: "agreedAmount",
      width: "10%",
      align: "right",
      render: (text, record) => (
        <span>
          {record.shootAmount} {ReplaceWithSpace(record.shootPaymentType)}
        </span>
      ),
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      align: "right",
      width: "10%",
    },
    {
      title: "Pending amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
      align: "right",
      width: "10%",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      align: "right",
      width: "10%",
    },
  ];

  const columns1 = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Scence Location",
      dataIndex: "sceneLocations",
      key: "sceneLocations",
      width: "18%",
      render: (text) => (
        <div className="commaContainer" style={{ width: 200 }}>
          {text &&
            text.map((a) => {
              return <span>{a}</span>;
            })}
        </div>
      ),
    },
    {
      title: "Actual Location",
      dataIndex: "actualLocation",
      key: "actualLocation",
      width: "18%",
    },
    {
      title: "Agreed amount",
      dataIndex: "agreedAmount",
      key: "agreedAmount",
      width: "18%",
      align: "right",
    },
    {
      title: "Advance Amount",
      dataIndex: "advancePayment",
      key: "advancePayment",
      align: "right",
      width: "18%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns1.push({
      title: "Action",
      // key: "key",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            props.history.push({
              pathname: `${MenusRoute.ACCOUNTING}/production/location/advance`,
              state: {
                category: GlobalConstants.PRODUCTION_CAPS,
                title: record.actualLocation,
                shootDate: record.shootDate,
                details: {
                  productionCategoryDepartment: GlobalConstants.LOCATION_CAPS,
                  description: record.actualLocation,
                  categoryDepartment: GlobalConstants.LOCATION_CAPS,
                  category: GlobalConstants.PRODUCTION_CAPS,
                  productionAccountId: record.productionAccountId || "",
                  department: GlobalConstants.LOCATION_CAPS,
                  isAdvance: switchActive,
                },
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns.push({
      title: "Action",
      // key: "key",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            props.history.push({
              pathname: `${MenusRoute.ACCOUNTING}/production/location`,
              state: {
                category: GlobalConstants.PRODUCTION_CAPS,
                title: record.locationName,
                shootDate: record.shootDate,
                details: {
                  productionCategoryDepartment: GlobalConstants.LOCATION_CAPS,
                  description: record.locationName,
                  categoryDepartment: GlobalConstants.LOCATION_CAPS,
                  category: GlobalConstants.PRODUCTION_CAPS,
                  productionAccountId: record.productionAccountId || "",
                  department: GlobalConstants.LOCATION_CAPS,
                  isAdvance: switchActive,
                },
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  const getLocationData = () => {
    account
      .accountingService(Services.AccountingVariables.GET_PRODUCTION_DATA, {
        productionDepartment: GlobalConstants.LOCATION_CAPS,
      })
      .then((res) => {
        setDataSource(res.data.content);
      });
  };
  const getAdvancePaymentData = () => {
    account
      .accountingService(
        Services.AccountingVariables.GET_PRODUCTION_DATA_ADVANCE_PAYMENT,
        {
          location: GlobalConstants.LOCATION_CAPS,
          isAdvance: switchActive,
        }
      )
      .then((res) => {
        setAdvanceDataSource(res.data.content);
      });
  };

  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Row style={{ padding: 10 }}>
      <Col>
        <Row>
          <Col xl={8} style={{ marginBottom: 5 }}>
            {/* {productionDepartment === "PRODUCTION" && ( */}
            <Search
              placeholder="search description here..."
              onChange={(e) => handleSearch(e.target.value)}
              enterButton
              allowClear
            />
            {/* )} */}
          </Col>
          <Col xl={8} style={{ textAlign: "center" }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <span>
                {" "}
                Shoot date{" "}
                <Switch
                  onChange={(record) => {
                    console.log(record);
                    setSwitchActive(record);
                  }}
                />{" "}
                Advance Payment{" "}
              </span>
            )}
          </Col>

          <Col xl={8} style={{ marginBottom: 5 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                type="primary"
                style={{ marginBottom: 16, float: "right" }}
              >
                <Link to={`${MenusRoute.ACCOUNTING}/addlocation`}>
                  <Icon type="plus" style={{ fontSize: "15px" }} /> Location
                </Link>
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col>
        {switchActive ? (
          <Table
            dataSource={filteredDataSource}
            columns={columns1}
            bordered
            // scroll={{ y: "50vh" }}
            pagination={false}
          />
        ) : (
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            bordered
            // scroll={{ y: "50vh" }}
            pagination={false}
          />
        )}
      </Col>
    </Row>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

export default connect(mapStateToProps, null)(AccLocationActorTable);
