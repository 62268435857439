import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import CreateLocationModal from "components/Location/AddLocationModal";
import SkeletonLoader from "components/shared/SkeletonLoader";
import NoSceneFound from "components/NoSceneFound";
import {
  Services,
  LocalStorage,
  GlobalConstants,
} from "constants/AppConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import GoogleLocation from "components/shared/GoogleMap";
import LocationCardComponent from "components/LocationCardComponent";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import { getPermissions } from "store/thunk-actions";
import ActorSkeletonCard from "components/Actor/ActorSkeletonCard";
import { Link } from "react-router-dom";

class LocationTab extends Component {
  state = {
    cardList: [],
    isActorsFetched: false,
    locationModel: false,
    mapModel: false,
    latitude: null,
    longitude: null,
    sceneLocationId: null,
  };

  constructor(props) {
    super(props);
    this._locationService = new LocationTabService();
  }

  handleLatLongValue = (latitude, longitude) => {
    this.setState({
      latitude,
      longitude,
    });
  };

  handleModel = (name, value, type) => {
    if (type !== "entire") {
      if (name === "map") {
        this.setState({
          mapModel: value,
          locationModel: !value,
        });
      } else {
        this.setState({
          mapModel: !value,
          locationModel: value,
        });
      }
    } else {
      this.setState({
        mapModel: false,
        locationModel: false,
      });
    }
  };

  componentDidMount() {
    localStorage.removeItem(GlobalConstants.REDIRECT_TO);
    this.setState(
      {
        sceneLocationId: localStorage.getItem(LocalStorage.LOCATION_ID),
      },
      () => {
        this.fetchLocation();
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneLocationId !== this.state.sceneLocationId) {
      this.setState(
        {
          sceneLocationId: nextProps.activeSceneLocationId,
        },
        () => {
          this.fetchLocation();
        }
      );
    }
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  fetchLocation = () => {
    this.setState({
      isActorsFetched: false,
      cardList: [],
    });
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION,
        this.state.sceneLocationId
      )
      .then((res) => {
        this.setState({
          cardList: res.data.locations || [],
          isActorsFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  refreshLocation = () => {
    this.fetchLocation();
  };

  renderLocation = () => {
    let locationList = [];
    if (this.state.cardList) {
      this.state.cardList.map((location, i) => {
        locationList.push(
          <LocationCardComponent
            key={i}
            {...this.props}
            actualLocationName={location.actualLocationName}
            locationId={location.locationId}
            locationAuditionId={location.locationAuditionId}
            status={location.state}
            imageId={location?.locationImages[0]?.attachment?.url}
            refreshLocation={this.fetchLocation}
            sceneLocationName={localStorage.getItem(
              LocalStorage.SCENE_LOCATION_NAME
            )}
            ImageIdList={location.locationImages}
          />
        );
      });
      return locationList;
    }
    return (
      <NoSceneFound
        type="Location"
        refreshProjectList={this.refreshProjectList}
      />
    );
  };

  renderSkeletonCards = () => {
    let template = [];
    for (let i = 1; i <= 4; i++) {
      template.push(<ActorSkeletonCard />);
    }
    return template;
  };

  render() {
    const { latitude, longitude } = this.state;
    const { activePermisision, activeSceneLocationId } = this.props;
    return (
      <Row className="mb-25">
        <Col
          xl={{ offset: 1, span: 21 }}
          lg={{ offset: 1, span: 21 }}
          md={{ offset: 1, span: 21 }}
          style={{ border: "1px solid #ccc" }}
        >
          <h3
            className=""
            style={{
              backgroundColor: "#001529",
              color: "#fff",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {localStorage.getItem(LocalStorage.SCENE_LOCATION_NAME)}
          </h3>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) ? (
            <Link
              to={`/project/add-location/` + this.props.activeSceneLocationId}
            >
              <Button
                type="primary"
                // onClick={() => {
                //   this.setState({ locationModel: true });
                // }}
                icon="plus"
                style={{ marginLeft: "10px" }}
              >
                Add Location
              </Button>
            </Link>
          ) : null}

          {this.state.locationModel && (
            <CreateLocationModal
              {...this.props}
              locationModel={this.state.locationModel}
              handleModel={this.handleModel}
              latitude={latitude}
              longitude={longitude}
              refreshLocation={this.fetchLocation}
              closeModal={this.closeModal}
              sceneLocationId={activeSceneLocationId}
              // locationId={activeSceneLocationId}
              type="Add"
            />
          )}

          {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
            <Row className="pb-10">
              {this.state.isActorsFetched
                ? this.renderLocation()
                : this.renderSkeletonCards()}
            </Row>
          ) : (
            <ContactAdminErrorTemplate />
          )}
          {/* <GoogleLocation
            geoLocation={this.geoLocation}
            handleModel={this.handleModel}
            mapModel={this.state.mapModel}
            getLatLongValues={this.handleLatLongValue}
            latitude={latitude}
            longitude={longitude}
          /> */}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneLocationId: scene.activeSceneLocationId,
    activePermisision: getSPermissionFromMenus("Location", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationTab);
