import React, { Component } from "react";
import { Modal, Button } from "antd";
import "./pdf.css";
import SchedularService from "services/SchedularServices";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import { showActionMessage, showNotifications } from "../NotificationComponent";
import Axios from "axios";
import { handleError } from "utilities/ErrorHandler";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import Loader from "../Loader";

class PDFComponent extends Component {
  constructor(props) {
    super(props);
    this._schedulerService = new SchedularService();
    this.state = {
      numPages: null,
      pageNumber: 1,
      height: null,
      isPDFfetching: false,
      dataLoaded: true,
    };
  }

  sendMail = () => {
    this.setState({
      isMailLoading: true,
    });
    let data = {
      projectId: this.props.projectId,
      date: this.props.scheduleDate,
    };
    this._schedulerService
      .services(Services.SchedularVariables.SEND_MAIL, null, null, data)
      .then((res) => {
        this.setState({
          isMailLoading: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          `Mail sent ${Notifications.SUCCESSFULLY}`
        );
      })
      .catch((err) => {
        this.setState({
          isMailLoading: false,
        });
      });
  };

  showModal = () => {
    const { customURL } = this.props;
    if (customURL) {
      this.setState({
        isPDFfetching: true,
      });
      Axios.get(customURL)
        .then((res) => {
          if (res.status !== 204) {
            this.setState({
              visible: true,
            });
          } else {
            showNotifications(Notifications.ERROR, "Failed", "No report found");
          }
          this.setState({
            isPDFfetching: false,
          });
        })
        .catch((err) => {
          this.setState({
            isPDFfetching: false,
          });
          if (err.response.data.status === 403) {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              GlobalConstants.ACCESS_DENIED_ERROR
            );
            this.props.getPermissions();
          } else {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          }
        });
    } else {
      this.setState({
        visible: true,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { isPDFfetching, isMailLoading, dataLoaded } = this.state;
    const {
      projectId,
      scheduleDate,
      icon,
      text,
      disabled,
      hideMail,
      customURL,
    } = this.props;
    const scheduleDateInMon = scheduleDate;
    const url = customURL
      ? customURL
      : `${getScriptAPIURL()}/scenes/scheduler/report/download?contentDisposition=INLINE&date=${scheduleDateInMon}&projectId=${projectId}`;
    return (
      <div className="pdf-container">
        <Button
          loading={isPDFfetching}
          type="primary"
          icon={icon ? icon : ""}
          onClick={this.showModal}
          disabled={disabled}
        >
          {text ? text : "View PDF"}
        </Button>
        <Modal
          width="100%"
          className="pdf-modal no-x"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          destroyOnClose
        >
          <div className="pdf-style">
            {!dataLoaded && <Loader />}
            {!hideMail && (
              <div className="email-button-area">
                <div className="mail-button-center">
                  <Button
                    loading={isMailLoading}
                    onClick={() => this.sendMail()}
                    type="primary"
                    icon="mail"
                  >
                    Send Mail
                  </Button>
                </div>
              </div>
            )}
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: 50,
              }}
            >
              <Button
                onClick={this.handleCancel}
                type="primary"
                icon="close"
                style={{ borderRadius: 0 }}
              ></Button>
            </div>
            <object className="pdf" data={url} type="application/pdf">
              <iframe
                title="view PDF"
                src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
              ></iframe>
            </object>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(null, mapDispatchToProps)(PDFComponent);
