import React, { Component } from "react";
import {
  Modal,
  Col,
  Row,
  Button,
  Input,
  Select,
  InputNumber,
  Form,
} from "antd";

import SongsStuntsServices from "services/SongsStuntsServices";
import {
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";

const { Option } = Select;

class PaymentTableModal extends Component {
  constructor(props) {
    super(props);
    this._songsServices = new SongsStuntsServices();
  }

  state = this._emptyState();

  _emptyState() {
    return {
      amount: null,
      paymentDate: null,
      remarks: null,
      rowid: null,
      sequenceType: "Day",
      sceneTypeDetailId: null,
      type: GlobalConstants.ADD,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    console.log("nextProps.recordData", nextProps.recordData);
    if (nextProps.recordData) {
      if (nextProps.type !== state.type)
        if (nextProps.recordData !== null) {
          return {
            type: nextProps.type,
            amount: nextProps.recordData.amount,
            paymentDate: nextProps.recordData.paymentDate,
            remarks: nextProps.recordData.remarks,
            rowid: nextProps.recordData.id,
            sequenceType: nextProps.recordData.sequenceType,
            sceneTypeDetailId: nextProps.recordData.sceneTypeDetailId,
            paymentId: nextProps.recordData.paymentId,
          };
        } else {
          return this._emptyState();
        }
    } else return null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          type,
          amount,
          paymentDate,
          remarks,
          paymentId,
          sequenceType,
          isButtonLoading,
        } = this.state;
        const adddata = {
          amount,
          paymentDate,
          remarks,
        };
        const data = {
          amount,
          paymentDate,
          remarks,
          paymentId,
          //   sequenceType
        };
        // this.setState({
        //   isButtonLoading: true
        // });

        switch (type) {
          case GlobalConstants.ADD:
            this.props.closeModal();
            this.props.handlepostdata(adddata);
            // this._songsServices
            //   .service(
            //     Services.SongVariables.ADD_SONGS_STUNTS,
            //     Services.SongVariables.SONGS,
            //     null,
            //     data
            //   )
            //   .then(res => {
            //     this.setState(
            //       {
            //         isButtonLoading: false,
            //         visible: false
            //       },
            //       () => {
            //         this._emptyState();
            //         this.props.refreshData();
            //         this.props.closeModal();
            //       }
            //     );
            //     console.log(res);
            //     showNotifications(
            //       Notifications.SUCCESS,
            //       "Success",
            //       "Songs Created Successfully!!"
            //     );
            //   })
            //   .catch(err => {
            //     this.setState({
            //       isButtonLoading: false
            //     });
            //     console.log(err);
            //     showNotifications(
            //       Notifications.ERROR,
            //       "Failed",
            //       "Something went wrong!!"
            //     );
            //   });
            break;
          case GlobalConstants.UPDATE:
            // let data = Object.assign(data, {
            //   sceneTypeDetailId: this.state.sceneTypeDetailId
            // });
            // let tempData = {
            //   ...data,
            //   sceneTypeDetailId: this.state.sceneTypeDetailId
            // };
            // console.log("data...temp", tempData);
            this.props.handlemodal(data);
            this.props.closeModal();

            //     this._songsServices
            //       .service(
            //         Services.SongVariables.UPDATE_SONGS_STUNTS,
            //         Services.SongVariables.SONGS,
            //         this.state.sceneTypeDetailId,
            //         data
            //       )
            //       .then(res => {
            //         this.setState(
            //           {
            //             isButtonLoading: false,
            //             visible: false
            //           },
            //           () => {
            //             this._emptyState();
            //             this.props.refreshData();
            //             this.props.closeModal();
            //           }
            //         );
            //         console.log(res);
            //         showNotifications(
            //           Notifications.SUCCESS,
            //           "Success",
            //           "Songs Updated Successfully!!"
            //         );
            //       })
            //       .catch(err => {
            //         showNotifications(
            //           Notifications.ERROR,
            //           "Failed",
            //           "Something went wrong!!"
            //         );

            //         this.setState({
            //           isButtonLoading: false
            //         });
            //         console.log(err);
            //       });
            break;
          default:
            break;
        }
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    console.log("this.state...", this.state);
    // const { type } = this.props;
    const {
      amount,
      paymentDate,
      remarks,
      sequenceType,
      type,
      isButtonLoading,
    } = this.state;
    const buttonText = type === GlobalConstants.ADD ? "Add" : "Update";
    return (
      <div>
        <h3 className="font-600 mb-20 primary-font-color text-center">
          Payment Terms
        </h3>
        <Form>
          <Row gutter={16}>
            {/* <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">S No</label>
              <Input />
            </Col> */}
            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Amount</label>
              <Form.Item>
                {getFieldDecorator("amount", {
                  initialValue: amount,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Amount!",
                    },
                    // {
                    //   pattern: new RegExp("^[A-Za-z]*$"),
                    //   message: "Enter Alphabets onlys"
                    // }
                  ],
                })(
                  <InputNumber
                    onChange={(amount) => this.setState({ amount })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Payment Date</label>
              <Form.Item>
                {getFieldDecorator("paymentdate", {
                  initialValue: paymentDate,
                  rules: [
                    {
                      required: true,
                      message: "Please enter PaymentDate!",
                    },
                  ],
                })(
                  <Input
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      this.setState({ paymentDate: e.target.value })
                    }
                  />
                )}
              </Form.Item>
            </Col>

            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Remarks</label>
              <Form.Item>
                {getFieldDecorator("remarks", {
                  initialValue: remarks,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Remarks!",
                    },
                  ],
                })(
                  <Input
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => this.setState({ remarks: e.target.value })}
                  />
                )}
              </Form.Item>
            </Col>
            {/* <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Day/Sequence</label>
              <Form.Item>
                {getFieldDecorator("daysequence", {
                  initialValue: sequenceType,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Day/Sequence!"
                    }
                  ]
                })(
                  <Select
                    defaultValue={sequenceType && sequenceType}
                    placeholder="Select Day/Sequence"
                    style={{ width: 200 }}
                    onChange={sequenceType =>
                      this.setState({
                        sequenceType: sequenceType
                      })
                    }
                  >
                    <Option value="Day">Day</Option>
                    <Option value="Sequence">Sequence</Option>
                  </Select>
                )}
              </Form.Item>
            </Col> */}
          </Row>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            // loading={isButtonLoading}
          >
            {buttonText}
          </Button>
        </Form>
      </div>
    );
  }
}
const paymenttablemodal = Form.create()(PaymentTableModal);
export default paymenttablemodal;
