import React, { Component, Fragment } from "react";
import { Table, Tag, Row, Col, Divider, Icon } from "antd";
import { Link } from "react-router-dom";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import BudjetService from "services/BudjetService";
import {
  Services,
  GlobalConstants,
  LocalStorage,
  Screens,
} from "constants/AppConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import {
  checkFor,
  Capitalize,
  PriceFormat,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { Value } from "components/shared/Label";
import TooltipComponent from "components/shared/TooltipComponent";
import moment from "moment";

class Production extends Component {
  constructor(props) {
    super(props);

    this._budgetService = new BudjetService();

    this.state = {
      dataSource: [],
      contractDataSource: [],
      isLoading: false,
      pageType: props.pageType,
      totalBudgetEstimationCost: 0,
    };
  }

  componentDidMount() {
    this.getProductionData();
  }

  getProductionData = () => {
    const { pageType } = this.state;
    const type =
      pageType === GlobalConstants.BY_CALL_SHEET
        ? Services.BudjetVariables.GET_PRODUCTION_DATA
        : null;
    if (type) {
      this._budgetService
        .getTransport({ isBudget: true })
        .then((res) => {
          this.setState(
            {
              dataSource: res.data.productionShoots,
              isLoading: true,
            },
            () => this.totalEstimationCost()
          );
        })
        .catch((err) =>
          this.setState({
            isLoading: true,
          })
        );
    } else {
      this._budgetService
        .budjetService(Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE)
        .then((res) => {
          this.setState({
            contractDataSource: res.data.productionPurchases,
            isLoading: true,
          });
        })
        .catch((err) =>
          this.setState({
            isLoading: true,
          })
        );
    }
  };

  totalEstimationCost = () => {
    let budgetEstCost = 0;
    let { dataSource } = this.state;
    dataSource &&
      dataSource.map((data) => {
        budgetEstCost = budgetEstCost + parseFloat(data.budgetEstimation);
      });
    this.setState({
      totalBudgetEstimationCost: budgetEstCost,
    });
  };

  render() {
    const {
      dataSource,
      isLoading,
      pageType,
      contractDataSource,
      totalBudgetEstimationCost,
    } = this.state;
    const { activePermisision, getPermissions } = this.props;

    const data = [
      {
        budgetEstimation: "20000.00",
        description: "demos",
        paymentType: "Purchase",
        department: "Art Set",
        departmentEnum: "ART_SET",
        id: 470,
        amountPaid: "678.00",
        productionCategoryDepartment: "ART_SET",
        category: "PRODUCTION_PURCHASE",
      },
      {
        budgetEstimation: "1000.00",
        description: "demos",
        paymentType: "Purchase",
        department: "Costume",
        departmentEnum: "COSTUME",
        id: 470,
        amountPaid: "678.00",
        productionCategoryDepartment: "ART_SET",
        category: "PRODUCTION_PURCHASE",
      },
      {
        budgetEstimation: "4000.00",
        description: "demos",
        paymentType: "Purchase",
        department: "Makeup",
        departmentEnum: "MAKEUP",
        id: 470,
        amountPaid: "678.00",
        productionCategoryDepartment: "ART_SET",
        category: "PRODUCTION_PURCHASE",
      },
      {
        budgetEstimation: "4000.00",
        description: "demos",
        paymentType: "Purchase",
        department: "Location",
        departmentEnum: "LOCATION",
        id: 470,
        amountPaid: "678.00",
        productionCategoryDepartment: "ART_SET",
        category: "PRODUCTION_PURCHASE",
      },
    ];

    const contractColumn = [
      {
        title: "#",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      // {
      //   title: "Description",
      //   dataIndex: "description",
      //   // align: "center",
      //   width: "70%",
      //   render: text => (
      //     <div style={{ textAlign: "left" }}>{Capitalize(text)}</div>
      //   )
      // },
      // {
      //   title: "Details",
      //   dataIndex: "description",
      //   // align: "center",
      //   render: text => (
      //     <div style={{ textAlign: "left" }}>{Capitalize(text)}</div>
      //   )
      // },
      {
        // title: "Department",
        title: "Type",
        dataIndex: "productionCategoryDepartment",
        align: "center",
        width: "30%",
        render: (text, record) => (
          <Tag className={`${record.productionCategoryDepartment} dateTag`}>
            {ReplaceWithSpace(text)}
          </Tag>
        ),
      },
      // {
      //   title: "Paid Amount",
      //   dataIndex: "budgetEstimation",
      //   align: "right",
      //   width: "30%"
      //   // render: (text, record) => (
      //   //   <Tag className={`${record.departmentEnum} dateTag`}>{text}</Tag>
      //   // )
      // },
      {
        title: "Cost Estimation (₹)",
        dataIndex: "budgetEstimation",
        align: "right",
        width: "30%",
        render: (text, record) => (
          <div style={{ textAlign: "right" }}>{PriceFormat(text)}</div>
        ),
      },
      {
        title: "Action",
        dataIndex: "budgetEstimation",
        align: "center",
        width: "5%",
        render: (text, record) => (
          <Link to={`/project/assets/${record.productionCategoryDepartment}`}>
            <Icon type="eye" />
          </Link>
        ),
        // render: text => (
        //   <div style={{ textAlign: "right" }}>{PriceFormat(text)}</div>
        // )
      },

      // {
      //   title: "Payment Type",
      //   dataIndex: "paymentType",
      //   align: "center"
      // }
    ];
    const callSheetColumns = [
      {
        title: "#",
        dataIndex: "name",
        key: "name",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Shooting Date",
        dataIndex: "shootDate",
        align: "center",
        width: "10%",
        render: (text) => <Tag className="statusTag">{text}</Tag>,
      },
      {
        title: "Scene Location",
        dataIndex: "sceneLocations",
        width: "25%",
        render: (text, record) => (
          <div className="commaContainer">
            {text &&
              text.map((a) => {
                return <span>{a.locationName}</span>;
              })}
          </div>
        ),
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocations",
        width: "25%",
        render: (text, record) => (
          <div className="commaContainer">
            {text &&
              text.map((a) => {
                return <span>{Capitalize(a.locationName)}</span>;
              })}
          </div>
        ),
      },
      {
        title: "Scene No",
        dataIndex: "scenes",
        width: "15%",
        render: (text, record) => (
          <div style={{ width: 150 }}>
            {text &&
              text.map((scene) => {
                return (
                  <span>
                    <Tag
                      className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                      style={{
                        color: "white",
                        backgroundColor: "#ccc",
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        this.props.history.push("/project/screen-overview");
                        localStorage.setItem(
                          GlobalConstants.REDIRECT_TO,
                          Screens.GENERAL
                        );
                        localStorage.setItem(
                          LocalStorage.ACTIVE_SCENE_ID,
                          scene.sceneId
                        );
                        // this.props.setActiveTab("1");
                      }}
                    >
                      <Link style={{ color: "white" }}>
                        <TooltipComponent
                          sceneNumber={scene.sceneNumber}
                          text={scene.description}
                          placement="right"
                        />
                      </Link>
                    </Tag>
                  </span>
                );
              })}
          </div>
        ),
      },
      {
        title: "Budget Estimation (₹)",
        dataIndex: "budgetEstimation",
        key: "address",
        width: "15%",
        align: "right",
        render: (text) => (
          <div style={{ textAlign: "right" }}>{PriceFormat(text)}</div>
        ),
      },
      // {
      //   title: "Status",
      //   dataIndex: "budgetState",
      //   key: "address",
      //   align: "center",
      //   render: (value, record) => (
      //     <Tag className={`statusTag ${record.budgetStateEnum}`}>
      //       {record.budgetState}
      //     </Tag>
      //   )
      // },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      callSheetColumns.push({
        title: "Action",
        key: "action",
        align: "center",
        render: (text, record) => (
          <>
            <Link
              to={`/project/update-production/${moment(
                record?.shootDate,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")}/view`}
            >
              <Icon type="eye" />
            </Link>
            <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
            <Link
              to={`/project/update-production/${moment(
                record?.shootDate,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")}/edit`}
            >
              <Icon type="edit" />
            </Link>
          </>
        ),
      });
    }

    const columns =
      pageType === GlobalConstants.BY_PURCHASE
        ? contractColumn
        : callSheetColumns;

    return (
      <Fragment>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row style={{ padding: 10 }} type="flex" justify="center">
            <Col xl={24}>
              {pageType !== GlobalConstants.BY_PURCHASE && isLoading && (
                <div style={{ paddingBottom: 5 }}>
                  <span style={{ fontSize: 16, fontWeight: "500" }}>
                    Total Budget Estimation Cost: ₹{" "}
                  </span>
                  <Value style={{ fontSize: 16, fontWeight: "bold" }}>
                    {PriceFormat(totalBudgetEstimationCost.toFixed(2))}
                  </Value>
                </div>
              )}
              <Table
                bordered
                loading={!isLoading}
                dataSource={
                  pageType === GlobalConstants.BY_PURCHASE
                    ? contractDataSource
                    : dataSource
                }
                columns={columns}
                // pagination={{ pageSize: 1000 }}
                // scroll={{ y: "50vh" }}
                pagination={false}
                locale={{
                  emptyText: isLoading && <RecordNotFound />,
                }}
              />
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Production);
