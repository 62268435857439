import React, { Component } from "react";
import { Row, Col, Form, Input, Select, Button, Radio } from "antd";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
import Uploaddocumentfile from "components/uploaddocumentfile";
import ImageViewer from "components/shared/ImageViewer";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
} from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import GlobalService from "services/GlobalService";
import { OnboardServices } from "services";
import ActorsServices from "services/ActorsServices";
import FileCard from "components/shared/FileCardComponent";
import {
  amountOnly,
  checkFor,
  numberOnly,
  handleGoogleResponse,
} from "utilities/ValidationHelper";
import PaymentTermsTable from "components/PaymentTermsTable";
import StaffDetailsTable from "components/StaffDetailsTable";
import GeoCode from "components/shared/GeoCode";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import ActorAvailabilityTable from "components/ActorAvailabilityTable";
import ActorConfirmModel from "components/ActorConfirmModel";
import { Prompt } from "react-router-dom";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { GeoServices } from "components/shared/GeoCode/geoCall";

import "./profile.css";
import { handleResponse } from "utilities/HandleResponseHelper";
import { handleError } from "utilities/ErrorHandler";
import Loader from "components/shared/Loader";

const Option = { Select };

class Actorprofile extends Component {
  constructor(props) {
    super(props);
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
    this.scriptservice = new ScriptService();
    this._globalService = new GlobalService();
    this.onboardservices = new OnboardServices();
    this._actorService = new ActorsServices();
    this.state = {
      isdirty: false,
      ddd: "",
      currentStatus: null,
      actorId: null,
      projectimageid: null,
      formvalues: null,
      paymentData: [],
      staffData: [],
      fileuploadid: null,
      isButtonDisabled: true,
      actorName: null,
      characterName: null,
      characterType: null,
      rumeneration: null,
      pan: null,
      gst: null,
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      vanityVan: null,
      pickup: true,
      conveyance: null,
      status: null,
      isLoading: false,
      gettingCharacterName: null,
      gettingCharacterType: null,
      gettingVanityVan: null,
      gettingStatus: null,
      gettingcountry: null,
      fileData: null,
      rowKey: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      paymentCount: 0,
      staffCount: 0,
      actorAvailabilityCount: 0,
      isStaffRequired: false,
      actorPaymentTypeData: null,
      actorPaymentType: null,
      isPaymentTableLoading: false,
      actorAvailability: [],
      confirmedActorname: null,
      isModalVisible: false,
      phoneCodeList: null,
      phoneCode: "+91",
      isActorDetailFetched: false,
    };
  }

  handleGeoChange = (e) => {
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          const [city, state, gettingcountry] = handleGoogleResponse(res);
          this.setState(
            {
              city,
              state,
              gettingcountry,
              zipCode: e,
            },
            () => console.log(this.state.city)
          );
        })
        .catch((err) => {
          this.setState({
            city: null,
            state: null,
            gettingcountry: null,
            zipCode: e,
          });
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
        });
    }
  };

  componentDidMount() {
    this.getCountries();
    this.getActorDetails();
    this.getActorPaymentTypeData();
    this.getCharacterType();
    this.getCountries();
    this.getStatus();
    this.getVanityVan();
    if (this.props.match.params.id) {
      this.setState({
        actorId: this.props.match.params.id,
      });
    }
    this.setState({
      gettingStatus: "TO_BE_CONFIRMED",
    });
    let characterType = this.props.match.params.characterType;
  }

  getActorDetails = () => {
    let characterType = this.props.characterType;

    let urlId = this.props.match.params.id;
    let actorId = urlId;
    // if(this.props){
    if (
      !this.props.match.params.id ||
      this.props.match.params.id == "undefined"
    ) {
      return;
    }
    // }

    this.setState({
      isActorDetailFetched: false,
    });

    this._actorService
      .actorsServices(Services.ActorVariables.GET_ACTOR_ID, actorId)
      .then((res) => {
        try {
          this.setState(
            {
              fileData:
                res.data &&
                res.data.actorDocument &&
                res.data.actorDocument[0].fileData,
              actorName: res.data.actorName,
              gettingCharacterName: this.props.match.params.characterName,
              gettingCharacterType: this.props.match.params.characterType,
              rumeneration: res.data.projectActor.rumuneration,
              // actorPaymentType: res.data.actorPaymentType,
              gst: res.data.projectActor.gst,
              // paymentData: res.data.actorPayment,
              // staffData: res.data.actorStaff,
              pan: res.data.pan,
              isPaymentTableLoading: false,
              addressLine: res.data.address && res.data.address.addressLine,
              city: res.data.address && res.data.address.city,
              state: res.data.address && res.data.address.state,
              zipCode: res.data.address && res.data.address.zipCode,
              gettingcountry: res.data.address && res.data.address.country,
              gettingVanityVan:
                res.data.projectActor && res.data.projectActor.vanityVan,
              pickup:
                res.data.projectActor && res.data.projectActor.isPickUP
                  ? true
                  : false,
              conveyance:
                res.data.projectActor && res.data.projectActor.conveyance,
              gettingStatus:
                res.data.projectActor && res.data.projectActor.state,
              currentStatus:
                res.data.projectActor && res.data.projectActor.state,
              fileList: res.data && res.data.actorDocument,
              isStaffRequired:
                res.data && res.data.isStaffRequired ? true : false,
              actorPaymentType: res.data.actorPaymentType,
              phoneCode: res.data.phoneCode,
              phoneNumber: res.data.phoneNumber,
              email: res.data.email,
              isActorDetailFetched: true,
            },
            () => this.splitBasedOnFileTypes(this.state.fileList)
          );
        } catch (err) {
          console.log(err);
          this.setState({
            isActorDetailFetched: true,
          });
        }
        this.refs.image.scrollIntoView({ block: "end", behavior: "smooth" });
        let paymentData = [];
        res.data.actorPayment.map((datum, i) => {
          paymentData.push({ ...datum, key: i });
          this.setState({
            paymentData,
            count: this.state.paymentCount + 1,
          });
        });
        let actorAvailability = [];
        res.data.actorAvailability.map((datum, i) => {
          actorAvailability.push({ ...datum, key: i });
          this.setState({
            actorAvailability,
            count: this.state.actorAvailabilityCount + 1,
          });
        });

        let staffData = [];
        res.data.actorStaff.map((datum, i) => {
          staffData.push({ ...datum, key: i });
          this.setState({
            staffData,
            count: this.state.staffCount + 1,
          });
        });
      })
      .catch((err) => {
        this.props.getPermissions();
      });
  };

  getActorPaymentTypeData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ACTOR_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          actorPaymentTypeData: res.data,
        });
      });
  };

  getVanityVan = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "VANITY_VAN")
      .then((res) => {
        this.setState({
          vanityVan: res.data,
        });
      });
  };

  getStatus = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({
          status: res.data,
        });
      });
  };

  getCharacterType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "CHARACTER_TYPE")
      .then((res) => {
        this.setState({
          characterType: res.data,
        });
      });
  };

  getCountries = () => {
    let country = [];
    this.onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          country: res.data.countries,
          phoneCodeList: res.data.countries,
        });
      });
  };

  getDetails = (city, state, gettingcountry, zipCode) => {
    this.setState({
      city,
      isdirty: true,
    });
    this.setState({
      state,
      isdirty: true,
    });
    this.setState({
      gettingcountry,
      isdirty: true,
    });
    this.setState({
      zipCode,
      isdirty: true,
    });
  };

  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
    });
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState(
        {
          fileList: temp,
        },
        () => {
          if (this.props.match.params.id != undefined) {
            this.handleImageUpdate();
          }
        }
      );
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  handleDocument = (id) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { fileData: id, fileType: GlobalConstants.DOCUMENT },
      ];
      this.setState(
        {
          fileList: temp,
        },
        () => {
          if (this.props.match.params.id != undefined) {
            this.handleDocumentUpdate();
          }
        }
      );
    } else {
      let temp = [{ fileData: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      docFiles,
      imageFiles,
    });
  };

  // handlepaymentpost = (postdata) => {
  // let datainstate = this.state.paymentData
  // datainstate.push({...postdata, key: this.state.rowKey})
  // // let length = datainstate.length
  // // let arraylength = length + 1
  // //  let addobject = Object.assign(datainstate[arraylength],datainpost)

  //   this.setState({
  //     paymentData:datainstate,
  //     rowKey: this.state.rowKey + 1
  //   })
  // // console.log("addobject",addobject)

  // //   console.log("postdata",postdata)
  // //   // this.setState({
  // //   //   paymentData:addobject
  // //   // })
  // //   console.log(this.state.paymentData)
  // }

  handlepayment = (data) => {
    this.setState({
      paymentData: data,
    });
  };

  handlestaff = (details) => {
    this.setState({
      staffData: details,
    });
  };

  handleAvailability = (details) => {
    this.setState({
      actorAvailability: details,
    });
  };

  deleteImage = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteImageIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;

    imageFiles.splice(deleteImageIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  deleteDoc = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteDocIndex = this.state.docFiles.findIndex(
      (a) => a.documentId === id
    );

    let docFiles = this.state.docFiles;
    let fileList = this.state.fileList;
    docFiles.splice(deleteDocIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      docFiles,
      fileList,
    });
  };
  handleImageUpdate() {
    const data = {
      actorId: this.props.match.params.id,
      actorName: this.state.actorName,
      // actorPayment: this.state.paymentData,
      // actorStaff: this.state.staffData,
      address: {
        addressLine: this.state.addressLine,
        city: this.state.city,
        country: this.state.gettingcountry,
        state: this.state.state,
        zipCode: this.state.zipCode,
      },
      characterName: this.props.match.params.characterName,
      characterType: this.props.match.params.characterType,
      actorPaymentType: this.state.actorPaymentType,
      isStaffRequired: this.state.isStaffRequired,
      actorDocument: this.state.fileList,
      pan: this.state.pan,
      email: this.state.email,
      phoneCode: this.state.phoneCode,
      phoneNumber: this.state.phoneNumber,
      projectActor: {
        conveyance: this.state.conveyance,
        gst: this.state.gst,
        isPickUP: this.state.pickup,
        projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        rumuneration: this.state.rumeneration,
        state: this.state.gettingStatus,
        vanityVan: this.state.gettingVanityVan,
      },
    };

    const notConfirmedData = {
      email: this.state.email,
      phoneCode: this.state.phoneCode,
      phoneNumber: this.state.phoneNumber,
      actorId: this.props.match.params.id,
      actorDocument: this.state.fileList,
      actorName: this.state.actorName,
      characterName: this.props.match.params.characterName,
      characterType: this.props.match.params.characterType,
      projectActor: {
        projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        state: this.state.gettingStatus,
      },
    };

    if (
      this.state.gettingStatus === GlobalConstants.CONFIRMED ||
      this.state.gettingStatus === GlobalConstants.NEGOTIATION
    ) {
      this.scriptservice
        .scriptService(Services.AddActor.ADD_ACTOR, data)
        .then((res) => {
          console.log(res);
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
          this.setState({ isLoading: false });
          this.getActorDetails();
        })
        .catch((err) => {
          this.setState({ isLoading: true });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong!!"
          );
        });
    } else {
      this.scriptservice
        .scriptService(Services.AddActor.ADD_ACTOR, notConfirmedData)
        .then((res) => {
          console.log(res);
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
          this.getActorDetails();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong!!"
          );
        });
    }
  }
  handleDocumentUpdate() {
    const data = {
      actorId: this.props.match.params.id,
      actorName: this.state.actorName,
      // actorPayment: this.state.paymentData,
      // actorStaff: this.state.staffData,
      address: {
        addressLine: this.state.addressLine,
        city: this.state.city,
        country: this.state.gettingcountry,
        state: this.state.state,
        zipCode: this.state.zipCode,
      },
      characterName: this.props.match.params.characterName,
      characterType: this.props.match.params.characterType,
      actorPaymentType: this.state.actorPaymentType,
      isStaffRequired: this.state.isStaffRequired,
      actorDocument: this.state.fileList,
      pan: this.state.pan,
      projectActor: {
        conveyance: this.state.conveyance,
        gst: this.state.gst,
        isPickUP: this.state.pickup,
        projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        rumuneration: this.state.rumeneration,
        state: this.state.gettingStatus,
        vanityVan: this.state.gettingVanityVan,
      },
      email: this.state.email,
      phoneCode: this.state.phoneCode,
      phoneNumber: this.state.phoneNumber,
    };

    const notConfirmedData = {
      email: this.state.email,
      phoneCode: this.state.phoneCode,
      phoneNumber: this.state.phoneNumber,
      actorId: this.props.match.params.id,
      actorDocument: this.state.fileList,
      actorName: this.state.actorName,
      characterName: this.props.match.params.characterName,
      characterType: this.props.match.params.characterType,
      projectActor: {
        projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        state: this.state.gettingStatus,
      },
    };

    if (
      this.state.gettingStatus === GlobalConstants.CONFIRMED ||
      this.state.gettingStatus === GlobalConstants.NEGOTIATION
    ) {
      this.scriptservice
        .scriptService(
          Services.AddActor.ADD_ACTOR,
          data,
          console.log("hai iam there", data)
        )
        .then((res) => {
          console.log(res);
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Actor Document Updated!!"
          );
          this.setState({ isLoading: false });
          // this.props.history.goBack();
          this.getActorDetails();
        })
        .catch((err) => {
          this.setState({ isLoading: true });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong!!"
          );
        });
    } else {
      this.scriptservice
        .scriptService(
          Services.AddActor.ADD_ACTOR,
          notConfirmedData,
          console.log("hai iam there", notConfirmedData)
        )
        .then((res) => {
          console.log(res);
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Actor Document Created!!"
          );
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong!!"
          );
        });
    }
  }

  handleSubmit = (e) => {
    this.setState({
      isdirty: false,
    });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formvalues: values,
          isButtonDisabled: false,
        });
        // let type = values.actorPaymentType;
        // type = type.replace(/ /g, "_");
        // let enumType = type.toUpperCase();
        const data = {
          actorDocument: this.state.fileList,
          actorName: values.actorname,
          actorPayment: this.state.paymentData,
          actorStaff: this.state.staffData,
          actorAvailability: this.state.actorAvailability,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: values.zipCode,
          },
          actorPaymentType: this.state.actorPaymentType,
          characterName: values.character,
          characterType: values.charactertype,
          isStaffRequired: this.state.isStaffRequired,
          pan: values.pan,
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: values.gst,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        const notConfirmedData = {
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          actorDocument: this.state.fileList,
          actorName: values.actorname,
          actorPayment: null,
          actorStaff: null,
          characterName: values.character,
          characterType: values.charactertype,
          isStaffRequired: null,
          actorPaymentType: null,
          pan: values.pan,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: null,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: null,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        if (
          this.state.gettingStatus === GlobalConstants.CONFIRMED ||
          this.state.gettingStatus === GlobalConstants.NEGOTIATION
        ) {
          this.scriptservice
            .scriptService(Services.AddActor.ADD_ACTOR, data)
            .then((res) => {
              console.log(res);
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "Actor Successfully Created!!"
              );
              this.props.history.goBack();
            })
            .catch((err) => {
              if (
                err.response.data &&
                err.response.data.message === GlobalConstants.ACCESS_DENIED
              ) {
                this.props.getPermissions();
                this.props.history.goBack();
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  GlobalConstants.ACCESS_DENIED_ERROR
                );
              } else {
                this.setState({ isLoading: false });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  handleError(
                    err.response &&
                      err.response.data &&
                      err.response.data.message
                  )
                );
              }
            });
        } else {
          this.scriptservice
            .scriptService(
              Services.AddActor.ADD_ACTOR,
              notConfirmedData,
              console.log("hai iam there", notConfirmedData)
            )
            .then((res) => {
              console.log(res);
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "Actor Successfully Created!!"
              );
              this.props.history.goBack();
            })
            .catch((err) => {
              if (err.response.data.message === GlobalConstants.ACCESS_DENIED) {
                this.props.getPermissions();
                this.props.history.goBack();
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  GlobalConstants.ACCESS_DENIED_ERROR
                );
              } else {
                this.setState({ isLoading: false });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something Went Wrong!!"
                );
              }
            });
        }
      }
    });
  };
  handleUpdate = (e) => {
    this.setState({
      isdirty: false,
    });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          actorId: this.props.match.params.id,
          actorName: values.actorname,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: values.zipCode,
          },
          characterName: values.character,
          characterType: values.charactertype,
          actorPaymentType: this.state.actorPaymentType,
          isStaffRequired: this.state.isStaffRequired,
          actorDocument: this.state.fileList,
          pan: values.pan,
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: values.gst,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        const notConfirmedData = {
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          actorId: this.props.match.params.id,
          actorDocument: this.state.fileList,
          actorName: values.actorname,
          characterName: values.character,
          characterType: values.charactertype,
          projectActor: {
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            state: values.status,
          },
        };

        if (
          this.state.gettingStatus === GlobalConstants.CONFIRMED ||
          this.state.gettingStatus === GlobalConstants.NEGOTIATION
        ) {
          this.scriptservice
            .scriptService(
              Services.AddActor.ADD_ACTOR,
              data,
              console.log("hai iam there", data)
            )
            .then((res) => {
              // showNotifications(
              //   Notifications.SUCCESS,
              //   "Success",
              //   "Actor Successfully Updated!!"
              // );
              showActionMessage(
                GlobalConstants.SUCCESS,
                GlobalConstants.CHANGES_HAVE_BEEN_SAVED
              );
              this.setState({ isLoading: false });
              this.props.history.goBack();
            })
            .catch((err) => {
              if (err.response.data.message === GlobalConstants.ACCESS_DENIED) {
                this.props.getPermissions();
                this.props.history.goBack();
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  handleError(
                    err.response &&
                      err.response.data &&
                      err.response.data.message
                  )
                );
              } else {
                this.setState({ isLoading: true });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  handleError(
                    err.response &&
                      err.response.data &&
                      err.response.data.message
                  )
                );
              }
            });
        } else {
          this.scriptservice
            .scriptService(
              Services.AddActor.ADD_ACTOR,
              notConfirmedData,
              console.log("hai iam there", notConfirmedData)
            )
            .then((res) => {
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "Actor Successfully Created!!"
              );
              this.props.history.goBack();
            })
            .catch((err) => {
              if (err.response.data.message === GlobalConstants.ACCESS_DENIED) {
                this.props.getPermissions();
                this.props.history.goBack();
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  GlobalConstants.ACCESS_DENIED_ERROR
                );
              } else {
                this.setState({ isLoading: false });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something Went Wrong!!"
                );
              }
            });
        }
      }
    });
  };

  staffTable() {
    if (this.state.isStaffRequired === true) {
      // this.getActorDetails();
    }
  }

  setActorPayment = (value) => {
    this.setState({
      actorPaymentType: value,
      isPaymentTableLoading: true,
      isdirty: true,
    });
  };

  changeStaffDetails = (value) => {
    this.setState({
      isStaffRequired: value.target.value,
      isdirty: true,
    });
    if (value.target.value === true) {
      // this.getActorDetails();
    }
  };

  // onBlur
  dd1 = (value) => {
    console.log("val;;ue", value);
    console.log("ddd", this.state.ddd);
    console.log("ddd", this.state);
    let ddd = this.state.ddd;
    let a = this.state.status || [];
    let b = a.map((a) => a.label);
    let dx = true;
    new Promise((resolve) => {
      b.forEach((x) => {
        if (x.trim() != ddd.trim()) {
          dx = false;
        } else {
          dx = true;
        }
      });

      resolve();
    })
      .then(() => {
        if (!dx) {
          a.push({ label: ddd, value: ddd });
        }
      })
      .then(() => {
        this.setState({
          status: a,
        });
      })
      .then(() => {
        this.setState({
          gettingStatus: ddd,
        });
      });
  };

  // onType
  ddd = (value) => {
    console.clear();
    console.log("val;;ue", value);
    if (value && value.trim().length > 0) {
      console.log("val;;ue", value);

      this.setState({
        ddd: value,
      });
    }
  };

  handleStatus = (value) => {
    if (value !== GlobalConstants.CONFIRMED) {
      this.setState({
        gettingStatus: value,
        // isdirty: true
      });
    }

    let characterId = this.props.match.params.characterId;
    if (value === GlobalConstants.CONFIRMED) {
      this._actorService
        .actorsServices(
          Services.ActorVariables.GET_CONFIRMED_ACTOR,
          null,
          null,
          null,
          null,
          null,
          characterId
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              confirmedActorname:
                res.data.actors && res.data.actors[0].actorName,
              isModalVisible: true,
            });
          }
          if (res.status === 204) {
            this.setState({
              gettingStatus: value,
            });
          }
        });
    }
  };
  submitData = () => {
    this.setState({
      gettingStatus: GlobalConstants.CONFIRMED,
      isModalVisible: false,
    });
  };
  closeModal = () => {
    this.setState({
      // gettingStatus: GlobalConstants.TO_BE_CONFIRMED,
      isModalVisible: false,
    });
    this.props.history.goBack();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const {
      isButtonDisabled,
      actorName,
      characterName,
      characterType,
      city,
      state,
      status,
      rumeneration,
      pan,
      gst,
      addressLine,
      zipCode,
      country,
      conveyance,
      pickup,
      vanityVan,
      paymentData,
      staffData,
      gettingVanityVan,
      gettingStatus,
      gettingcountry,
      isStaffRequired,
      actorAvailability,
      isModalVisible,
      fileList,
    } = this.state;
    const { activePermisision } = this.props;

    const { imageFiles } = this.state;
    const maxImageReached = imageFiles && imageFiles.length >= 4;

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <div style={{ height: "100%" }}>
        <Prompt
          when={this.state.isdirty == true}
          message={(location) =>
            " Are you sure you want to leave the page without submitting"
          }
        />
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Actor Profile </TitleText>
          </Title>
          <br />
        </Row>

        <Row align="middle" justify="center" type="flex">
          <Col
            xl={{ span: 22, offset: 0 }}
            lg={{ span: 22, offset: 0 }}
            md={{ span: 21, offset: 0 }}
          >
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      md={{ span: 11 }}
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>*Actor Name</label>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("actorname", {
                          initialValue: actorName,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Actorname!",
                            },
                          ],
                        })(
                          <Input
                            type="text"
                            placeholder="Actor Name"
                            onChange={(value) =>
                              this.setState({ actorName: value }, () => {
                                // this.setState({
                                //   isdirty: true
                                // });
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      md={{ span: 11 }}
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label ref="image">Character Name</label>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("character", {
                          initialValue: this.props.match.params.characterName,
                          rules: [
                            {
                              required: false,
                              message: "Please Enter Character Name!",
                            },
                          ],
                        })(
                          <Input
                            style={{ fontWeight: "bold" }}
                            disabled
                            type="text"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 10 }}>
                  <Row
                  // style={{ position: "absolute", right: "0%", zIndex: 1 }}
                  >
                    {this.state.imageFiles && (
                      <ImageViewer
                        imageCount={2}
                        type="table"
                        ImagesData={this.state.imageFiles}
                        canDelete={true}
                        refreshData={() => this.getActorDetails()}
                        url={Services.AddActor.DELETE_ACTOR_DOCUMENT}
                      />
                    )}
                    {/* {!maxImageReached && (
                      <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator("projectimageid", {
                          initialValue: this.state.projectimageid,
                          rules: [
                            {
                              required: false,
                              message: "Please Upload Photos!"
                            }
                          ]
                        })(
                          <MultipleSelectImage
                            handleImageIDS={this.handleImageIDS}
                          />
                        )}
                      </Form.Item>
                    )} */}
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      md={{ span: 11 }}
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>Phone Number</label>
                    </Col>

                    <Col xl={{ span: 16 }} lg={{ span: 13 }} md={{ span: 15 }}>
                      <Form.Item>
                        {getFieldDecorator("phoneNumber", {
                          initialValue: this.state.phoneNumber,
                          rules: [
                            {
                              required: false,
                              message: "Please Enter Character Type!",
                            },
                            this.state.phoneCode === "+91" && {
                              pattern: ".{10,}",
                              message: "Enter 10 Numbers Only!",
                            },
                          ],
                        })(
                          <Input
                            addonBefore={
                              <Form.Item
                                className="payment-type"
                                style={{
                                  margin: "0px",
                                  height: "30px",
                                  top: "-4px",
                                }}
                              >
                                {getFieldDecorator("phoneCode", {
                                  initialValue:
                                    this.state.phoneCode || undefined,
                                })(
                                  <Select
                                    showSearch
                                    defaultValue={this.state.phoneCode}
                                    style={{ width: "80px" }}
                                    optionFilterProp="children"
                                    onChange={(value) =>
                                      this.setState({ phoneCode: value })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toString()
                                        .toLowerCase()
                                        .includes(
                                          input.toString().toLowerCase()
                                        )
                                    }
                                  >
                                    {this.state.phoneCodeList &&
                                      (this.state.phoneCodeList || []).map(
                                        (option, i) => (
                                          <Option value={option.phoneCode}>
                                            {option.phoneCode}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                )}
                              </Form.Item>
                            }
                            style={{ fontWeight: "bold" }}
                            placeholder="Phone Number"
                            onChange={(e) =>
                              this.setState({ phoneNumber: numberOnly(e) })
                            }
                            maxLength={
                              this.state.phoneCode === "+91" ? 10 : null
                            }
                            minLength={
                              this.state.phoneCode === "+91" ? 10 : null
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      md={{ span: 11 }}
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>Email</label>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <div id="email-div">
                        <Form.Item>
                          {getFieldDecorator("email", {
                            initialValue: this.state.email,
                            rules: [
                              {
                                required: false,
                                message: "Please Enter status!",
                              },
                            ],
                          })(
                            <Input
                              style={{ fontWeight: "bold" }}
                              type="email"
                              placeholder="Enter your email "
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      md={{ span: 11 }}
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>Character Type</label>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("charactertype", {
                          initialValue: this.props.match.params.characterType,
                          rules: [
                            {
                              required: false,
                              message: "Please Enter Character Type!",
                            },
                          ],
                        })(
                          <Select
                            disabled
                            style={{
                              width: "100%",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {characterType &&
                              characterType.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col
                      xl={{ span: 8 }}
                      lg={{ span: 10 }}
                      md={{ span: 11 }}
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>*Status</label>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("status", {
                          initialValue:
                            (gettingStatus && gettingStatus) ||
                            GlobalConstants.TO_BE_CONFIRMED,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter status!",
                            },
                          ],
                        })(
                          <Select
                            defaultValue={GlobalConstants.TO_BE_CONFIRMED}
                            placeholder="Status"
                            style={{ width: "100%" }}
                            // onChange={value =>
                            //   this.setState({ gettingStatus: value })
                            // }

                            // showSearch
                            // onSearch={this.ddd}
                            // onBlur={this.dd1}
                            onChange={this.handleStatus}
                          >
                            {status &&
                              status.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <React.Fragment>
                {this.state.gettingStatus === GlobalConstants.CONFIRMED ||
                this.state.gettingStatus === GlobalConstants.NEGOTIATION ? (
                  <React.Fragment>
                    <Row>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        {console.log(checkFor(activePermisision, "Finance"))}
                        {checkFor(activePermisision, "Finance") ? (
                          <Row gutter={16}>
                            <Col
                              xl={{ span: 8 }}
                              lg={{ span: 10 }}
                              md={{ span: 11 }}
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>*Remuneration</label>
                            </Col>

                            <Col
                              xl={{ span: 12 }}
                              lg={{ span: 13 }}
                              md={{ span: 12 }}
                            >
                              <Form.Item>
                                {getFieldDecorator("rumeneration", {
                                  initialValue: rumeneration,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please Enter Rumeneration!",
                                    },
                                  ],
                                })(
                                  <Input
                                    prefix="₹"
                                    placeholder="Rumeneration"
                                    onChange={(value) => {
                                      this.setState(
                                        {
                                          rumeneration: amountOnly(value),
                                        },
                                        () => {
                                          this.setState({
                                            isdirty: true,
                                          });
                                        }
                                      );
                                    }}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                      {checkFor(activePermisision, "Finance") ? (
                        <Col
                          xl={{ span: 8 }}
                          lg={{ span: 12 }}
                          md={{ span: 14 }}
                        >
                          <Row gutter={16}>
                            <Col
                              xl={{ span: 8 }}
                              lg={{ span: 10 }}
                              md={{ span: 11 }}
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>*Payment Type</label>
                            </Col>
                            <Col
                              xl={{ span: 12 }}
                              lg={{ span: 13 }}
                              md={{ span: 12 }}
                            >
                              <Form.Item>
                                {getFieldDecorator("actorPaymentType", {
                                  initialValue:
                                    this.state.actorPaymentType || undefined,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please Enter Rumeneration!",
                                    },
                                  ],
                                })(
                                  <Select
                                    placeholder="Payment Type"
                                    style={{ width: "100%" }}
                                    onChange={this.setActorPayment}
                                  >
                                    {this.state.actorPaymentTypeData &&
                                      this.state.actorPaymentTypeData.map(
                                        (option, i) => (
                                          <Option value={option.value}>
                                            {option.label}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>

                    {this.state.actorPaymentType === "CONTRACT" ||
                    this.state.actorPaymentType === "Contract" ? (
                      checkFor(activePermisision, "Finance") ? (
                        <Row style={{ paddingTop: 25 }}>
                          <Col
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                          >
                            <Row gutter={16}>
                              <Col
                                xl={{ span: 3 }}
                                lg={{ span: 5 }}
                                md={{ span: 6 }}
                              >
                                <div
                                  style={{
                                    fontWeight: "600",
                                    paddingTop: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  <label>Payment Terms</label>
                                </div>
                              </Col>
                              <Col
                                xl={{ span: 21 }}
                                lg={{ span: 19 }}
                                md={{ span: 18 }}
                              >
                                <Form.Item>
                                  {getFieldDecorator("paymentdata", {
                                    initialValue: paymentData,
                                    rules: [
                                      {
                                        required: false,
                                        message: "Please Enter Payment Terms",
                                      },
                                    ],
                                  })(
                                    <PaymentTermsTable
                                      {...this.props}
                                      getFromChild={this.handlepayment}
                                      actorId={this.state.actorId}
                                      rumeneration={this.state.rumeneration}
                                    />
                                  )}
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : null
                    ) : null}

                    <Row>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        {checkFor(activePermisision, "Personal") ? (
                          <Row gutter={16}>
                            <Col
                              xl={{ span: 8 }}
                              lg={{ span: 10 }}
                              md={{ span: 11 }}
                            >
                              <div
                                style={{
                                  fontWeight: "600",
                                  paddingTop: "8px",
                                  textAlign: "right",
                                }}
                              >
                                <label>PAN </label>
                              </div>
                            </Col>
                            <Col
                              xl={{ span: 12 }}
                              lg={{ span: 13 }}
                              md={{ span: 12 }}
                            >
                              <Form.Item>
                                {getFieldDecorator("pan", {
                                  rules: [
                                    {
                                      required: false,
                                      message: "Please Enter Pan !",
                                    },
                                    {
                                      pattern: new RegExp(
                                        "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                                      ),
                                      message:
                                        "Please Enter Correct Pan Format Ex: JSDFG1234H ",
                                    },
                                  ],
                                  initialValue: pan,
                                })(
                                  <Input
                                    style={{
                                      textTransform: "uppercase",
                                    }}
                                    type="text"
                                    placeholder="Pan No"
                                    maxLength={10}
                                    onChange={(value) =>
                                      this.setState({
                                        pan: value,
                                        isdirty: true,
                                      })
                                    }
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        {checkFor(activePermisision, "Personal") ? (
                          <Row gutter={16}>
                            <Col
                              xl={{ span: 8 }}
                              lg={{ span: 10 }}
                              md={{ span: 11 }}
                            >
                              <div
                                style={{
                                  fontWeight: "600",
                                  paddingTop: "8px",
                                  textAlign: "right",
                                }}
                              >
                                <label>GST </label>
                              </div>
                            </Col>
                            <Col
                              xl={{ span: 12 }}
                              lg={{ span: 13 }}
                              md={{ span: 12 }}
                            >
                              <Form.Item>
                                {getFieldDecorator("gst", {
                                  initialValue: gst,
                                  rules: [
                                    {
                                      required: false,
                                      message: "Please Enter Gst !",
                                    },
                                    {
                                      pattern: new RegExp(
                                        "[0-3]{1}[0-9]{1}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-2]{1}[Zz]{1}[0-9A-Za-z]{1}"
                                      ),
                                      message:
                                        "Please Enter Correct Gst Format Ex:22AAAAA0000A1Z5",
                                    },
                                  ],
                                })(
                                  <Input
                                    style={{
                                      textTransform: "uppercase",
                                    }}
                                    type="text"
                                    maxLength={15}
                                    onChange={(value) => {
                                      this.setState({
                                        gst: value,
                                        isdirty: true,
                                      });
                                    }}
                                    placeholder="Gst No"
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>*Zip code</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("zipCode", {
                                initialValue: zipCode,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Zipcode!",
                                  },
                                  {
                                    pattern: new RegExp("^[1-9][0-9]{5}$"),
                                    message: "Enter Six Digits Only",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="Zipcode"
                                  maxLength="6"
                                  onChange={(e) =>
                                    this.handleGeoChange(numberOnly(e))
                                  }
                                />
                                // <GeoCode
                                //   defaultValue={this.state.zipCode}
                                //   getDetails={this.getDetails}
                                // />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>Country</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("country", {
                                initialValue: gettingcountry,
                                rules: [
                                  {
                                    required: false,
                                    message: "Please Enter country!",
                                  },
                                ],
                              })(
                                <Select
                                  style={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                  showSearch
                                  placeholder="Country"
                                  className="w-100"
                                  onChange={(value) =>
                                    this.setState({ gettingcountry: value })
                                  }
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  value={gettingcountry}
                                >
                                  {this.state.country &&
                                    (this.state.country || []).map(
                                      (option, i) => (
                                        <Option value={option.name}>
                                          {option.name}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                                fontWeight: "bold",
                              }}
                            >
                              <label>State</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("state", {
                                initialValue: state,
                                // rules: [
                                //   {
                                //     required: false,
                                //     message: "Please Enter State!"
                                //   },
                                //   {
                                //     message: "Enter Alphabets onlys"
                                //   }
                                // ]
                              })(
                                <Input
                                  type="text"
                                  placeholder="State"
                                  onChange={(value) =>
                                    this.setState({ state: value })
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                fontWeight: "bold",
                              }}
                            >
                              <label>City</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("city", {
                                initialValue: city,
                                // rules: [
                                //   {
                                //     required: false,
                                //     message: "Please Enter City!"
                                //   }
                                // ]
                              })(
                                <Input
                                  type="text"
                                  placeholder="City"
                                  onChange={(value) =>
                                    this.setState({ city: value })
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 14 }}>
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>*Address</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("Address", {
                                initialValue: addressLine,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Address!",
                                  },
                                ],
                              })(
                                <Input
                                  type="text"
                                  placeholder="Address"
                                  onChange={(value) =>
                                    this.setState({
                                      addressLine: value,
                                      isdirty: true,
                                    })
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={{ span: 8 }} lg={{ span: 13 }} md={{ span: 12 }}>
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>*Staff</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("isStaffRequired", {
                                initialValue: isStaffRequired,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Select Staff Details!",
                                  },
                                ],
                              })(
                                <Radio.Group
                                  name="radiogroup"
                                  onChange={this.changeStaffDetails}
                                >
                                  <Radio value={true}>Yes</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {this.state.isStaffRequired ? (
                      <Row>
                        <Col
                          xl={{ span: 24 }}
                          lg={{ span: 24 }}
                          md={{ span: 24 }}
                        >
                          <Row gutter={16}>
                            <Col
                              xl={{ span: 3 }}
                              lg={{ span: 5 }}
                              md={{ span: 6 }}
                            >
                              <div
                                style={{
                                  fontWeight: "600",
                                  paddingTop: "8px",
                                  paddingBottom: 20,
                                  textAlign: "right",
                                }}
                              >
                                <label>Staff Details</label>
                              </div>
                            </Col>
                            <Col
                              xl={{ span: 20 }}
                              lg={{ span: 24 }}
                              md={{ span: 24 }}
                            >
                              <Form.Item>
                                {getFieldDecorator("staffdata", {
                                  initialValue: staffData,
                                  rules: [
                                    {
                                      required: false,
                                      message: "Please Enter Staff Details!",
                                    },
                                  ],
                                })(
                                  <StaffDetailsTable
                                    {...this.props}
                                    getFromChild={this.handlestaff}
                                    actorId={this.state.actorId}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>

              <Row
                gutter={12}
                type="flex"
                justify="end"
                style={{ marginTop: "20px" }}
              >
                <Col>
                  <Button onClick={() => this.props.history.goBack()}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  {this.props.match.params.id != undefined ? (
                    <Button
                      type="primary"
                      disabled={
                        !checkFor(
                          activePermisision,
                          GlobalConstants.PERMISSION_EDIT
                        )
                      }
                      onClick={this.handleUpdate}
                      // loading={isLoading}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled={
                        !checkFor(
                          activePermisision,
                          GlobalConstants.PERMISSION_ADD
                        )
                      }
                      onClick={this.handleSubmit}
                      // loading={isLoading}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {!this.state.isActorDetailFetched && <Loader size={12} />}
        <ActorConfirmModel
          isModalVisible={isModalVisible}
          closeModal={this.closeModal}
          submitData={this.submitData}
          confirmedActorname={this.state.confirmedActorname}
          characterName={this.props.match.params.characterName}
        />
      </div>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

const ActorProfileVendor = Form.create()(Actorprofile);
export default connect(mapStateToProps, mapDispatchToProps)(ActorProfileVendor);
