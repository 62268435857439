import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Table, Button, Row, Col, Icon, Input, Select, DatePicker } from "antd";
import moment from "moment";

import { amountOnly } from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  MenusRoute,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { Link } from "react-router-dom";
import DeleteComponent from "components/shared/DeleteComponent";
import EquipmentDocumentUpload from "components/shared/EquipmentDocumentUpload";

const { MonthPicker, RangePicker } = DatePicker;
const { TextArea } = Input;

const dateFormat = "YYYY/MM/DD";
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

class AccUpdateProduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.handleAdd();
  }

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      quantity: "",
      amount: "",
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, projectProductionCategoryId }) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "name",
        align: "center",
        width: "10%",
        render: () => <Input autoFocus style={{ width: "100%" }} />,
      },
      {
        title: "Department",
        dataIndex: "description",
        key: "name",
        align: "center",
        width: "10%",
        render: () => <Input style={{ width: "100%" }} />,
      },
      {
        title: "RECEIVER NAME",
        dataIndex: "receiverName",
        key: "name",
        align: "center",
        width: "8%",
        render: () => <Select style={{ width: "100%" }} showSearch />,
      },
      {
        title: "AMOUNT PAID",
        dataIndex: "amount",
        key: "address",
        align: "center",
        width: "10%",
        render: () => (
          <Input
            type="number"
            onChange={(e) => amountOnly(e)}
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "Payment Type",
        dataIndex: "amount",
        key: "address",
        align: "center",
        width: "8%",
        render: () => <Select style={{ width: "100%" }} />,
      },
      {
        title: "Payment Mode",
        dataIndex: "amount",
        key: "address",
        align: "center",
        width: "8%",
        render: () => <Select style={{ width: "100%" }} />,
      },
      {
        title: "Payment duration",
        dataIndex: "amount",
        key: "address",
        align: "center",
        width: "8%",
        render: () => <Select style={{ width: "100%" }} />,
      },
      {
        title: "Reference no",
        dataIndex: "description",
        key: "name",
        align: "center",
        width: "8%",
        render: () => <Input style={{ width: "100%" }} />,
      },
      {
        title: "Documents",
        dataIndex: "amount",
        key: "address",
        align: "center",
        width: "6%",
        render: (text, record) => (
          <EquipmentDocumentUpload
            // key={index}
            // refreshData={this.fetchData}
            data={record}
          />
        ),
      },
      {
        title: "Comments",
        dataIndex: "quantity",
        key: "age",
        align: "center",
        width: "12%",
        render: () => <TextArea placeholder="enter comments" autoSize />,
      },
      {
        title: "Payment date",
        dataIndex: "quantity",
        key: "age",
        align: "center",
        width: "18%",
        render: () => (
          <DatePicker
            defaultValue={moment("01/01/2015", dateFormatList[0])}
            format={dateFormatList}
          />
        ),
      },
      {
        title: "",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "5%",
        render: (text, record) => (
          <>
            <DeleteComponent
              record={record}
              onClick={(record) => this.handleDelete(record)}
            />
            {/* <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            /> */}
            <Link to={`${MenusRoute.ACCOUNTING}/receiverpayment`}>
              <Icon type="user" />
            </Link>
          </>
        ),
      },
    ];
    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col xl={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24} className="accProductionScroll">
            <Table
              style={{ width: 1800 }}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccUpdateProduction);
