import React, { Component, Fragment } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Table,
  Button,
  Icon,
  Modal,
} from "antd";
import InvoiceUpload from "components/shared/InvoiceUpload";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { ReplaceWithSpace } from "utilities/ValidationHelper";
import TextArea from "antd/lib/input/TextArea";
import TooltipIcon from "components/shared/TooltipIcon";
import InvoiceForm from "components/shared/InvoiceForm";
import AddPaymentInvoiceForm from "./AddPaymentInvoiceForm";
import Accounting from "services/AccountingServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const dateFormat = GlobalConstants.DD_MM_YYYY;

class AddPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Category: null,
      ...this.getInitialState(props),
    };
    this.global = new GlobalService();
    this.account = new Accounting();
  }

  getInitialState = ({ category }) => {
    return {
      CategoryTypeList: [],
      SubCategoryList: [],
      PaymentFormValues: {
        amountPaid: 0,
        invoiceNumber: null,
        category,
        categoryDepartment: "PRODUCTION",
        comments: null,
        description: null,
        documents: [],
        expenseType: null,
        invoiceId: null,
        isAmountSplit: false,
        itemQuantity: 0,
        payeeId: null,
        payeeName: null,
        paymentDate: null,
        paymentId: null,
        paymentMode: null,
        paymentType: null,
        productionAccount: null,
        productionAccountId: null,
        productionDepartment: "PRODUCTION",
        referenceNumber: null,
        shootDate: null,
        isAdvance: false,
      },
    };
  };

  componentDidMount() {
    const { category } = this.props;
    Promise.all([
      this.categoryTypeList(),
      this.subCategoryList(category),
      this.getPaymentModeData(),
      this.getPayee(category),
      this.getExpenseType(),
    ]).then(([res1, res2, res3, res4, res5]) => {
      this.setState({
        SubCategoryList: res2.data,
        paymentModeList: res3.data,
        payeeList: res4.data,
        expenseTypeData: res5.data,
      });
    });
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  getExpenseType = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.EXPENSE_TYPE
    );
  };

  getPaymentModeData = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PAYMENT_MODE
    );
  };

  getPayee = (category) => {
    return this.account.services(
      Services.AccountingVariables.GET_PAYEE_DROPDOWN,
      category
    );
  };

  subCategoryList = (category) => {
    return this.account.services(
      Services.AccountingVariables.GET_SUB_CATEGORY,
      category
    );
  };

  categoryTypeList = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PRODUCTION_CATEGORY
    );
  };

  onChangeDD = (value, name) => {
    this.setState({
      PaymentFormValues: {
        ...this.state.PaymentFormValues,
        [name]: value,
      },
    });
  };

  handleInvoice = () => {
    this.setState({
      isInvoiceVisible: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isInvoiceVisible: false,
      activeInvoice: null,
    });
  };

  handleDocument = (files = []) => {
    const {
      PaymentFormValues: { documents },
    } = this.state;
    const lists = [...documents];

    files.map((file) => {
      const { fileData, fileType, fileId } = file;
      lists.push({
        fileId,
        fileType,
      });
    });
    this.setState(
      {
        PaymentFormValues: {
          ...this.state.PaymentFormValues,
          documents: lists,
        },
      },
      () => this.handleSavePayment()
    );
  };

  handleSavePayment = () => {
    this.account
      .services(
        Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE,
        this.state.PaymentFormValues
      )
      .then((res) => {
        console.log(res);
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.props.history.goBack();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.upload.handleSave();
      }
    });
  };

  handleInvoiceNumber = (invoiceNumber, invoiceId) => {
    this.setState({
      PaymentFormValues: {
        ...this.state.PaymentFormValues,
        invoiceNumber,
        invoiceId,
      },
    });
  };

  handleInputChange = (value, type) => {
    this.setState(
      {
        PaymentFormValues: {
          ...this.state.PaymentFormValues,
          [type]: value,
        },
      },
      () => this.state.PaymentFormValues.payeeName
    );
  };

  handleVendorDescription = (id, lists) => {
    let obj = Object.assign({}, ...lists.filter((list) => list.value === id));
    this.setState({
      PaymentFormValues: {
        ...this.state.PaymentFormValues,
        description: obj.label,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
    };
    const tableCol = {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 19 },
      },
    };
    const customCol = {
      labelCol: {
        sm: { span: 3 },
        xl: { span: 3 },
      },
      wrapperCol: {
        sm: { span: 21 },
        xl: { span: 21 },
      },
    };
    const {
      SubCategoryList,
      invoiceName,
      isInvoiceVisible,
      subCategory,
      paymentModeList,
      payeeList,
      expenseTypeData,
      PaymentFormValues: {
        description,
        invoiceNumber,
        productionAccountId,
        payeeName,
        payeeId,
      },
    } = this.state;

    const { category } = this.props;

    return (
      <Fragment>
        <Row style={{ padding: 10 }}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col xl={8}>
                <Form.Item label="Vendor" hasFeedback>
                  {getFieldDecorator("subCategory", {
                    // initialValue: invoiceNumber,
                    rules: [
                      {
                        required: true,
                        message: "Vendor Required!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      className="upperCase"
                      // showSearch
                      allowClear
                      onChange={(productionAccountId) =>
                        this.setState(
                          {
                            PaymentFormValues: {
                              ...this.state.PaymentFormValues,
                              productionAccountId,
                            },
                          },
                          () =>
                            this.handleVendorDescription(
                              productionAccountId,
                              SubCategoryList
                            )
                        )
                      }
                      value={productionAccountId}
                    >
                      {SubCategoryList &&
                        SubCategoryList.map((list, i) => {
                          return (
                            <Option key={i} value={list.value}>
                              {list.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {/* <Col xl={8}>
                <Form.Item label="Expense Type" hasFeedback>
                  {getFieldDecorator("expenseType", {
                    // initialValue: invoiceNumber,
                    rules: [
                      {
                        required: true,
                        message: "Payment Mode Required!"
                      }
                    ]
                  })(
                    <Select
                      style={{ width: "100%" }}
                      className="upperCase"
                      showSearch
                      onChange={expenseType =>
                        this.setState({
                          PaymentFormValues: {
                            ...this.state.PaymentFormValues,
                            expenseType
                          }
                        })
                      }
                      //   onBlur={this.handleInvoiceBlur}
                      //   onSearch={this.handleInvoiceNumber}
                    >
                      {expenseTypeData &&
                        expenseTypeData.map((list, i) => {
                          return (
                            <Option key={i} value={list.value}>
                              {list.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Description" hasFeedback>
                  {getFieldDecorator("description", {
                    rules: [
                      {
                        required: true,
                        message: "Description Required!"
                      }
                    ]
                  })(
                    <Input
                      onChange={e =>
                        this.handleInputChange(e.target.value, "description")
                      }
                    />
                  )}
                </Form.Item>
              </Col> */}

              <Col xl={8}>
                <Form.Item label="Payment date" hasFeedback>
                  {getFieldDecorator("paymentDate", {
                    rules: [
                      {
                        required: true,
                        message: "Payment date Required!",
                      },
                    ],
                  })(
                    <DatePicker
                      // onChange={paymentDateChange}
                      onChange={(value, PaymentDate) =>
                        this.handleInputChange(PaymentDate, "paymentDate")
                      }
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Payee" hasFeedback>
                  {getFieldDecorator("payeeName", {
                    rules: [
                      {
                        required: true,
                        message: "Payee Required!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      className="caps"
                      showSearch
                      onChange={(payeeId) =>
                        this.setState({
                          PaymentFormValues: {
                            ...this.state.PaymentFormValues,
                            payeeId,
                            payeeName: null,
                          },
                        })
                      }
                      onBlur={() =>
                        !payeeId &&
                        this.props.form.setFieldsValue({
                          payeeName,
                        })
                      }
                      onSearch={(e) => {
                        if (e) {
                          this.setState(
                            {
                              PaymentFormValues: {
                                ...this.state.PaymentFormValues,
                                payeeId: null,
                                payeeName: e,
                              },
                            },
                            () => this.handleInputChange(e, "payeeName")
                          );
                        }
                      }}
                    >
                      {payeeList &&
                        payeeList.map((list, i) => {
                          return (
                            <Option key={i} value={list.value}>
                              {list.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Amount Paid" hasFeedback>
                  {getFieldDecorator("amountPaid", {
                    // initialValue: invoiceNumber,
                    rules: [
                      {
                        required: true,
                        message: "Amount Paid Required!",
                      },
                    ],
                  })(
                    <Input
                      type="number"
                      onChange={(e) =>
                        this.handleInputChange(e.target.value, "amountPaid")
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Payment mode" hasFeedback>
                  {getFieldDecorator("paymentMode", {
                    // initialValue: invoiceNumber,
                    rules: [
                      {
                        required: true,
                        message: "Payment Mode Required!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      className="upperCase"
                      showSearch
                      onChange={(paymentMode) =>
                        this.setState({
                          PaymentFormValues: {
                            ...this.state.PaymentFormValues,
                            paymentMode,
                          },
                        })
                      }
                      //   onBlur={this.handleInvoiceBlur}
                      //   onSearch={this.handleInvoiceNumber}
                    >
                      {paymentModeList &&
                        paymentModeList.map((list, i) => {
                          return (
                            <Option key={i} value={list.value}>
                              {list.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Reference number" hasFeedback>
                  {getFieldDecorator("referenceNumber", {
                    rules: [
                      {
                        required: false,
                        message: "Reference number Required!",
                      },
                    ],
                  })(
                    <Input
                      onChange={(e) =>
                        this.handleInputChange(
                          e.target.value,
                          "referenceNumber"
                        )
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              {/* <Col xl={12}>
                <Row> */}
              <Col xl={8}></Col>
              {description && (
                <Col xl={8}>
                  <Form.Item label="Invoice" hasFeedback>
                    {getFieldDecorator("invoiceId", {
                      rules: [
                        {
                          required: false,
                          message: "Referance number Required!",
                        },
                      ],
                    })(
                      invoiceNumber ? (
                        <>
                          <a>{invoiceNumber}</a>
                          <TooltipIcon
                            icon="delete"
                            titleText={GlobalConstants.REMOVE_PAYEE}
                            onClick={() =>
                              this.setState({
                                PaymentFormValues: {
                                  ...this.state.PaymentFormValues,
                                  invoiceNumber: null,
                                  invoiceId: null,
                                },
                              })
                            }
                          ></TooltipIcon>
                        </>
                      ) : (
                        <Button
                          onClick={() => this.handleInvoice()}
                          style={{ width: "100%" }}
                          type="primary"
                        >
                          <Icon type="plus" style={{ fontSize: "11px" }} />{" "}
                          Invoice
                        </Button>
                      )
                    )}
                  </Form.Item>
                </Col>
              )}
              <Col xl={8}></Col>
              <Col xl={8}>
                <Form.Item label="Comments" hasFeedback>
                  {getFieldDecorator("comments", {
                    rules: [
                      {
                        required: false,
                        message: "Referance number Required!",
                      },
                    ],
                  })(
                    <TextArea
                      // rows={4}
                      onChange={(e) =>
                        this.handleInputChange(e.target.value, "comments")
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={24}>
                <Form.Item {...customCol} label="Documents">
                  {getFieldDecorator("document", {
                    rules: [
                      {
                        required: false,
                        message: "Invoice Date Required!",
                      },
                    ],
                  })(
                    <InvoiceUpload
                      onRef={(ref) => (this.upload = ref)}
                      handleDocument={this.handleDocument}
                      handleCancel={this.handleCancel}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={24}>
                <Col style={{ float: "right", paddingRight: 50 }}>
                  <Button
                    type="ghost"
                    style={{ marginRight: 10 }}
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" onClick={this.handleSubmit}>
                    Submit
                  </Button>
                </Col>
              </Col>
              {/* </Row>
              </Col> */}
            </Row>
          </Form>
        </Row>
        <Modal
          destroyOnClose
          className="createModal no-x"
          key={1}
          title="Create Invoice"
          visible={isInvoiceVisible}
          width={"95%"}
          // style={{ maxHeight: "40px" }}
          footer={[
            <Button key="back" onClick={() => this.handleCloseModal()}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.child.handleSubmit()}
            >
              Submit
            </Button>,
          ]}
        >
          <AddPaymentInvoiceForm
            category={category}
            // activePayee={activePayee}
            // activePayment={activePayment}
            description={description}
            details={{
              productionCategoryDepartment: null,
            }}
            onRef={(ref) => (this.child = ref)}
            handleInvoiceNumber={this.handleInvoiceNumber}
            handleInvoice={this.handleInvoice}
            handleCloseModal={this.handleCloseModal}
          />
        </Modal>
      </Fragment>
    );
  }
}

const AddPaymentVendor = Form.create({ name: "register" })(AddPaymentForm);

export default AddPaymentVendor;
