import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  Icon,
} from "antd";
import * as _ from "lodash";

import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { ScriptService } from "services";
import { showNotifications } from "components/shared/NotificationComponent";
import ActorsServices from "services/ActorsServices";

import "./StaffDetailsTable.css";
import MaskedInput from "react-maskedinput";
import { checkFor } from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: title ? title + "is required." : "",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
              onFocus={this.setActiveRecord}
              className="empty-active"
              width={200}
              // placeholder={`enter ${title}`}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: title ? title + "is required." : "",
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            onFocus={this.setActiveRecord}
            className="empty-active"
            // placeholder={`enter ${title}`}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class StaffDetailsTable extends Component {
  getPaymentType = () => {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "STAFF_PAYMENT")
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  getPanType = () => {
    this.scriptservice
      .scriptService(Services.SceneOVerviewVariable.PAN_DROP_DOWN)
      .then((res) => {
        this.setState({
          panDropDown: res.data,
        });
      });
  };

  getDesignation = () => {
    this.scriptservice
      .scriptService(Services.SceneOVerviewVariable.DESIGNATION_DROP_DOWN)
      .then((res) => {
        this.setState({
          designationDropDown: res.data,
        });
      });
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
    this._actorsService = new ActorsServices();
    this.onChange = this.onChange.bind(this);
    // this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      dropDown: null,
      actorId: null,
      activeRecord: null,
      panDropDown: null,
      designationDropDown: null,
      // designationTypeValue: null,
      designationShow: null,
      showPANGST: this.props?.showPANGST || true,
    };
  }

  componentDidMount() {
    this.getPaymentType();
    this.getPanType();
    this.getDesignation();
    if (this.props.actorId) {
      this.setState(
        {
          actorId: this.props.actorId,
        },
        () => this.getActorDetails()
      );
    }
    this.checkForEmpty(this.state.dataSource);
  }
  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  designationBlurChanges(value, record) {
    let designation = this.state.designationTypeValue;
    let a = this.state.designationDropDown || [];
    let b = a.map((a) => a.name);
    let dx = true;
    if (designation != null) {
      new Promise((resolve) => {
        b.forEach((x) => {
          if (x && x.toString().trim() != designation.toString().trim()) {
            dx = false;
          } else {
            dx = true;
          }
        });

        resolve();
      })
        .then(() => {
          if (!dx) {
            a.push({ name: designation, value: designation });
          }
        })
        .then(() => {
          this.setState({
            designationDropDown: a,
          });
        })
        .then(() => {
          this.designationChange(designation, record);
          record.designationName = a;
        });
    }
  }

  getActorDetails = () => {
    this._actorsService
      .actorsServices(Services.ActorVariables.GET_ACTOR_ID, this.state.actorId)
      .then((res) => {
        let dataSource = [];
        res.data.actorStaff.map((datum, i) => {
          dataSource.push({ ...datum, key: this.state.count });
          this.setState({
            dataSource,
            totalRecords: dataSource,
            count: this.state.count + 1,
          });
        });
      });
  };

  handleSubmit = (record) => {
    let staffId = null;
    let type = record.paymentType;
    type = type && type.replace(/ /g, "_");
    let enumType = type && type.toUpperCase();
    let actorId = this.state.actorId;
    let paymentId = null;
    if (isNaN(record.designationName)) {
      let data = {
        actorId: this.state.actorId,
        designationName: record.designationName,
        payment: record.payment,
        paymentType: enumType,
        staffName: record.staffName,
        paymentProofId: record.paymentProofId,
        paymentProofNumber: record.paymentProofNumber,
      };
      if (actorId) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.POST_STAFF_DETAILS,
            actorId,
            null,
            data,
            staffId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Saved Successfully!!"
            );
            this.getActorDetails();
          })
          .catch((err) => {
            this.props.getPermissions && this.props.getPermissions();
            if (this.props.actorId) {
              this.setState(
                {
                  actorId: this.props.actorId,
                },
                () => this.getActorDetails()
              );
            }
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    } else {
      let data = {
        actorId: this.state.actorId,
        designationId: record.designationName,
        payment: record.payment,
        paymentType: enumType,
        staffName: record.staffName,
        paymentProofId: record.paymentProofId,
        paymentProofNumber: record.paymentProofNumber,
      };
      if (actorId) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.POST_STAFF_DETAILS,
            actorId,
            null,
            data,
            staffId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Saved Successfully!!"
            );
            this.getActorDetails();
          })
          .catch((err) => {
            this.props.getPermissions && this.props.getPermissions();
            this.state.dataSource && this.state.dataSource.pop();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    }
  };

  handleUpdate = (record) => {
    let actorId = this.state.actorId;
    let staffId = record.staffId;
    let type = record.paymentType;
    type = type.replace(/ /g, "_");
    let enumType = type.toUpperCase();
    if (isNaN(record.designationName)) {
      let data = {
        actorId: this.state.actorId,
        designationName: record.designationName,
        payment: record.payment,
        paymentType: enumType,
        staffName: record.staffName,
        paymentProofId: record.paymentProofId,
        paymentProofNumber: record.paymentProofNumber,
      };
      if (actorId) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.PUT_STAFF_DETAILS,
            actorId,
            null,
            data,
            staffId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Updated Successfully!!"
            );
            this.getActorDetails();
          })
          .catch((err) => {
            this.props.getPermissions && this.props.getPermissions();
            if (this.props.actorId) {
              this.setState(
                {
                  actorId: this.props.actorId,
                },
                () => this.getActorDetails()
              );
            }
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    } else {
      let data = {
        actorId: this.state.actorId,
        designationId: record.designationName,
        payment: record.payment,
        paymentType: enumType,
        staffName: record.staffName,
        paymentProofId: record.paymentProofId,
        paymentProofNumber: record.paymentProofNumber,
      };
      if (actorId) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.PUT_STAFF_DETAILS,
            actorId,
            null,
            data,
            staffId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Saved Successfully!!"
            );
            this.getActorDetails();
          })
          .catch((err) => {
            if (this.props.actorId) {
              this.setState(
                {
                  actorId: this.props.actorId,
                },
                () => this.getActorDetails()
              );
            }
            this.props.getPermissions && this.props.getPermissions();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    }
  };

  handleDelete = (key, staffId, record) => {
    const dataSource = [...this.state.dataSource];
    if (staffId) {
      this.scriptservice
        .scriptService(Services.AddActor.DEL_ACTOR, staffId)
        .then((res) => {
          this.setState(
            {
              dataSource: dataSource.filter((item) => item.key !== key),
            },
            () => {
              this.props.getFromChild(this.state.dataSource);
              this.checkForEmpty(this.state.dataSource);
            }
          );
          showNotifications(
            Notifications.SUCCESS,
            "Deleted",
            "Deleted Successfully!!"
          );
        })
        .catch((err) => {
          this.props.getPermissions && this.props.getPermissions();
          if (this.props.actorId) {
            this.setState(
              {
                actorId: this.props.actorId,
              },
              () => this.getActorDetails()
            );
          }
        });
    } else {
      let updatedDataSource = this.state.dataSource.filter(
        (data) => data.key !== record.key
      );
      if (updatedDataSource.length === 0) {
        updatedDataSource.push({
          key: 0,
          staffName: "",
          designationName: "",
          paymentType: null,
          paymentProofId: null,
          paymentProofNumber: null,
        });
      }
      this.setState({ dataSource: updatedDataSource });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      staffName: "",
      designationName: "",
      paymentType: null,
      paymentProofId: null,
      paymentProofNumber: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record) {
    this.handleSave(record, value, "paymentType");
  }
  panChange(value, record) {
    if (value) {
      record.paymentProofNumber = null;
    }
    this.handleSave(record, value, "gst");
  }

  designationChange(value, record) {
    this.handleSave(record, value, "designation");
  }
  inputChange(value, record) {
    this.handleSave(record, value, "category");
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      if (type === "paymentType") {
        let modifyItem = newData[index];
        modifyItem.paymentType = value;
      } else if (type === "gst") {
        let modifyItem = newData[index];
        modifyItem.paymentProofId = value;
      } else if (type === "designation") {
        let modifyItem = newData[index];
        modifyItem.designationName = value;
        modifyItem.designationLabel =
          this.state.designationDropDown.filter(
            (data) => data?.value === value
          )[0]?.label || null;
      } else if (type === "category") {
        let modifyItem = newData[index];
        modifyItem.paymentProofNumber = value;
      }
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      this.props.getFromChild(this.state.dataSource);
    });
    if (!row.staffId) {
      const Personal =
        checkFor(this.props.activePermisision, "Personal") &&
        row.paymentProofId;
      const Finance =
        checkFor(this.props.activePermisision, "Finance") &&
        row.payment &&
        row.paymentType;
      if (row.paymentProofId === 13) {
        if (row.staffName && row.designationName) {
          if (
            checkFor(this.props.activePermisision, "Personal") &&
            checkFor(this.props.activePermisision, "Finance")
          ) {
            if (Personal && Finance) {
              this.handleSubmit(row);
            }
          } else {
            if (Finance || Personal) {
              this.handleSubmit(row);
            }
          }
        } else {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "All Fields Are Mandatory"
          );
        }
      } else {
        const Personal =
          checkFor(this.props.activePermisision, "Personal") &&
          row.paymentProofId &&
          row.paymentProofNumber;
        const Finance =
          checkFor(this.props.activePermisision, "Finance") &&
          row.payment &&
          row.paymentType;
        if (row.staffName && row.designationName) {
          if (
            checkFor(this.props.activePermisision, "Personal") &&
            checkFor(this.props.activePermisision, "Finance")
          ) {
            if (Personal && Finance) {
              this.handleSubmit(row);
            }
          } else {
            if (Finance || Personal) {
              this.handleSubmit(row);
            }
          }
        } else {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "All Fields Are Mandatory"
          );
        }
      }
    }
    if (row.staffId) {
      if (row.paymentProofId === 13) {
        if (
          row.staffName &&
          row.designationName &&
          row.payment &&
          row.paymentType &&
          row.paymentProofId
        ) {
          if (!_.isEqual(row, this.state.activeRecord)) {
            this.handleUpdate(row);
          }
        }
      } else {
        if (
          row.staffName &&
          row.designationName &&
          row.payment &&
          row.paymentType &&
          row.paymentProofNumber &&
          row.paymentProofId
        ) {
          this.handleUpdate(row);
        }
      }
    }
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  render() {
    const { dataSource, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    let columns = [
      {
        title: "Name",
        dataIndex: "staffName",
        width: "15%",
        editable: true,
        align: "center",
        render: (text, record) => {
          return {
            props: {
              className: `manage-width ${!text ? "empty-active" : ""}`,
            },
            children: text,
          };
        },
      },
      {
        title: "Designation",
        dataIndex: "designationName",
        align: "center",
        width: "15%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select
              showSearch
              disabled={
                !checkFor(
                  this.props.activePermisision,
                  GlobalConstants.PERMISSION_EDIT
                ) && record.staffId
              }
              // onSearch={this.designationTypeValue}
              onBlur={(value) => this.designationBlurChanges(value, record)}
              style={{ width: 150 }}
              className={`${!text ? "custom-empty-active" : ""}`}
              placeholder="Select type"
              onChange={(value) => this.designationChange(value, record)}
              value={record.designationName}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.designationDropDown &&
                this.state.designationDropDown.map((option, i) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          ) : null,
      },
    ];

    if (checkFor(this.props.activePermisision, "Finance")) {
      columns.push(
        {
          title: "Rate",
          dataIndex: "payment",
          width: "10%",
          editable: true,
          align: "center",
          render: (text, record) => {
            return {
              props: {
                className: `manage-width ${!text ? "empty-active" : ""}`,
              },
              children: text,
            };
          },
        },
        {
          title: "Type",
          dataIndex: "paymentType",
          width: "12%",
          align: "center",
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <Select
                showSearch
                style={{ width: 100 }}
                className={`${!text ? "custom-empty-active" : ""}`}
                placeholder="Select type"
                onChange={(value) => this.onChange(value, record)}
                defaultValue={text ? text : ""}
                disabled={
                  !checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && record.staffId
                }
              >
                {this.state.dropDown &&
                  this.state.dropDown.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ) : null,
        }
      );
    }

    if (this.showPANGST) {
      if (checkFor(this.props.activePermisision, "Personal")) {
        columns.push({
          title: "Pan / Gst",
          dataIndex: "paymentProofId",
          align: "center",
          width: "12%",
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <Select
                showSearch
                disabled={
                  !checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && record.staffId
                }
                style={{ width: 100 }}
                className={`${!text ? "custom-empty-active" : ""}`}
                placeholder="Select type"
                onChange={(value) => this.panChange(value, record)}
                defaultValue={text ? text : ""}
              >
                {this.state.panDropDown &&
                  this.state.panDropDown.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ) : null,
        });
      }
    }

    if (this.showPANGST) {
      columns.push({
        title: "Pan / Gst No",
        dataIndex: "paymentProofNumber",
        width: "30%",
        align: "center",
        render: (text, record, index) => {
          if (record.paymentProofId === "11") {
            return (
              <MaskedInput
                className="border"
                style={{ width: "100%" }}
                disabled={
                  !checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && record.staffId
                }
                mask="AAAAA1111A"
                defaultValue={text}
                onBlur={(e) => this.inputChange(e.target.value, record)}
                value={record.paymentProofNumber}
              />
            );
          }
          if (record.paymentProofId === "12") {
            return (
              <MaskedInput
                className="border"
                style={{ width: "100%" }}
                disabled={
                  !checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && record.staffId
                }
                mask="11AAAAA1111A1A*"
                defaultValue={text}
                onBlur={(e) => this.inputChange(e.target.value, record)}
                value={record.paymentProofNumber}
              />
            );
          }
          if (record.paymentProofId === 13) {
            return <Input defaultValue="" disabled />;
          }
        },
      });
    }

    if (checkFor(this.props.activePermisision, "Delete")) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        width: "13%",
        className: "operation",
        align: "center",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Row type="flex" justify="center" align="middle">
              <Col xl={11}>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() =>
                    this.handleDelete(record.key, record.staffId, record)
                  }
                >
                  <Tooltip
                    title={GlobalConstants.DEL}
                    placement={GlobalConstants.RIGHT}
                  >
                    {" "}
                    <Button icon="delete" type="link" />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          ) : null,
      });
    }

    const columnsData = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record, i) => {
          const cellStatus = record.key === i && record.staffId;
          return {
            record,
            inputType: col.dataIndex === "payment" ? "number" : "text",
            editable:
              col.editable !==
              checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )
                ? record.staffId
                  ? false
                  : true
                : true,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            setActiveRecord: this.setActiveRecord,
          };
        },
      };
    });

    return (
      <Row type="flex" justify="center" align="middle">
        <Col xl={24}>
          <Table
            components={components}
            rowClassName={() => "editable-row "}
            className="editing-table"
            bordered
            dataSource={dataSource}
            columns={columnsData}
            // pagination={{ pageSize: 1000 }}
            pagination={false}
            locale={{
              emptyText: <RecordNotFound />,
            }}
          />
        </Col>
        {checkFor(this.props.activePermisision, "Add") ? (
          <Col xl={24} style={{ textAlign: "end" }}>
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ marginTop: 16 }}
              disabled={hasNewRow}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          </Col>
        ) : null}
      </Row>
    );
  }
}

export default StaffDetailsTable;
