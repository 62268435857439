import React, { Component, Fragment } from "react";
import { Table, Button, Row, Col, Icon, Input, Select, DatePicker } from "antd";
import DeleteComponent from "components/shared/DeleteComponent";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";

import ActorCrewDD from "./ActorCrewDD";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  checkFor,
  StartCase,
  numberOnly,
  amountOnly,
} from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import TooltipIcon from "components/shared/TooltipIcon";
import {
  MenusRoute,
  Services,
  Notifications,
  LocalStorage,
} from "constants/AppConstants";
import TransportService from "services/TransportService";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import InvoiceService from "services/InvoiceService";
import GlobalService from "services/GlobalService";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import Loader from "components/shared/Loader";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const { Option, OptGroup } = Select;

class TransportMore extends Component {
  constructor(props) {
    super(props);

    this._transportService = new TransportService();
    this._invoicesService = new InvoiceService();
    this._globalService = new GlobalService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      shootDate: props.match.params.shootDate,
      actorCrewList: [],
      dropLocationList: [],
      driverList: [],
      isLoading: true,
      loadingDriverDetails: false,
    };
  }

  componentDidMount() {
    // this.fetchTransportByshootDate();
    this.fetchData();
    this.fetchPassanger();
    this.fetchDriverList();
    this.fetchDropLocationList();
  }

  fetchDropLocationList = () => {
    this._transportService
      .transportService(
        Services.TransportVariables.GET_VEHICLE_DROP_LOCATION_LIST,
        this.state.shootDate
      )
      .then((res) => {
        this.setState({
          dropLocationList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchData = () => {
    const { shootDate } = this.state;
    this.setState({
      isLoading: true,
    });

    this._transportService
      .transportService(
        Services.TransportVariables.GET_SCHEDULE_VEHICLE,
        shootDate
      )
      .then((res) => {
        this.setState({ dataSource: [], totalRecords: [], count: 0 });
        res?.data?.vehicles?.forEach((data, index) => {
          data.key = index + 1;
          data.dummyName = data?.driverName;
          this.setState({ count: this.count + 1 });
        });
        this.setState({
          dataSource: res?.data?.vehicles || [],
          totalRecords: res?.data?.vehicles || [],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChange = (value, record, type) => {
    if (value && type !== "driverNumber") {
      record[type] = value;
      if (type === "passengerType") {
        record["passengerIdList"] = [];
        record["passengers"] = [];
      }
      this.handleSave(record, type);
    } else {
      if (value) {
        record.driverNumber = value;
        this.handleSave(record, type);
      } else {
        return;
      }
    }
  };

  fetchPassanger() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "PASSENGER_TYPE")
      .then((res) => {
        this.setState({
          gstData: res.data || [],
        });
      });
  }

  fetchDriverList() {
    this._transportService
      .transportService(Services.TransportVariables.GET_DRIVER_LIST)
      .then((res) => {
        this.setState({
          driverList: res.data || [],
        });
      });
  }

  fetchTransportByshootDate() {
    this._transportService
      .transportService(
        Services.TransportVariables.GET_TRANSPORT_MAIN_BY_SHOOT_DATE,
        { shootDate: this.props.match.params.shootDate }
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionShoots || [],
        });
      });
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.scheduleVehicleId) {
      if (
        row.vehicleName &&
        row.passengerType &&
        // row.passengers &&
        row.passengers?.length !== 0 &&
        row.dropLocationId &&
        (row.driverName || row.driverId) &&
        row.driverNumber &&
        row.driverNumber?.length === 10
      ) {
        // console.log("row", row)
        this.handleUpdate(row);
        // console.log(row);
      }
    } else {
      if (
        row.vehicleName &&
        row.passengerType &&
        row.passengers?.length !== 0 &&
        row.dropLocationId &&
        (row.driverName || row.driverId) &&
        row.driverNumber &&
        row.driverNumber?.toString()?.length === 10
      ) {
        this.handleSubmit(row);
      }
    }
    this.setState({ dataSource: newData });
  };

  handleSubmit = (row) => {
    const { shootDate } = this.state;
    const {
      passengerType,
      passengers,
      vehicleName,
      dropLocationId,
      driverName,
      driverId,
      driverNumber,
    } = row;
    const data = {
      vehicleName,
      passengerType,
      shootDate,
      driverName,
      driverId,
      driverNumber,
      dropLocation: {
        dropLocationId,
        passengers,
      },
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };
    this._transportService
      .transportService(Services.TransportVariables.SAVE_SCHEDULE_VEHICLE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (row) => {
    const { shootDate } = this.state;
    const {
      passengerType,
      passengers,
      vehicleName,
      dropLocationId,
      scheduleVehicleId,
      vehicleDropLocationId,
      driverName,
      driverId,
      driverNumber,
    } = row;
    const data = {
      vehicleName,
      shootDate,
      passengerType,
      dropLocation: {
        dropLocationId,
        vehicleDropLocationId,
        passengers,
      },
      driverName,
      driverId,
      driverNumber,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };
    this._transportService
      .transportService(
        Services.TransportVariables.UPDATE_SCHEDULE_VEHICLE,
        data,
        scheduleVehicleId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      vehicleName: null,
      passengerType: null,
      passengers: null,
      dropLocationId: null,
      driverId: null,
      driverName: null,
      driverNumber: null,
      dummyName: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, scheduleVehicleId }) => {
    if (scheduleVehicleId) {
      this._transportService
        .transportService(
          Services.TransportVariables.DELETE_SCHEDULE_VEHICLE,
          scheduleVehicleId,
          null,
          false
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  getActorCrewListData(passengerType) {
    this._transportService
      .transportService(Services.TransportVariables.GET_PASSANGER_DD, {
        shootDate: this.props.match.params.shootDate,
        passengerType: passengerType,
      })
      .then((res) => {
        this.setState({
          actorCrewList: Object.values(res.data) || [],
        });
      })
      .catch((err) => {
        this.setState({
          actorCrewList: [],
        });
      });
  }

  handleDriverBlur = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.driverName);
      if (newData) {
        this.setState({ eventType });
        record.dummyName = newData;
        record.driverId = null;
        record.driverName = newData;
        this.onChange(newData, record, "driverName");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  handleDriverName = (value, record, type) => {
    if (value && value.trim()?.length > 0) {
      this.setState(
        {
          driverName: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handleDriverChange = (value, record) => {
    const { driverList } = this.state;
    this.setState({
      loadingDriverDetails: true,
    });
    let driver = Object.assign(
      {},
      ...driverList.filter((driver) => driver.value === value)
    );
    record.dummyName = driver.label;
    record.driverName = null;
    record.driverId = value;
    this._transportService
      .transportService(Services.TransportVariables.GET_DRIVER_DETAIL, value)
      .then((res) => {
        if (res.data.driverNumber) {
          record.driverNumber = parseInt(res.data.driverNumber);
        }
        this.setState(
          {
            loadingDriverDetails: false,
          },
          () => this.handleSave(record)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const groupName = ["Main", "Supporting", "Junior", "Animal"];

    const {
      dataSource,
      isLoading,
      totalRecords,
      driverList,
      loadingDriverDetails,
    } = this.state;
    const { activePermisision } = this.props;
    const hasNewRow = dataSource?.length === totalRecords?.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Car",
        dataIndex: "vehicleName",
        key: "vehicleName",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""} border-none`}
            autoFocus={!record.scheduleVehicleId}
            defaultValue={text}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleVehicleId
            }
            onBlur={(e) => this.onChange(e.target.value, record, "vehicleName")}
          />
        ),
      },
      {
        title: "Driver name",
        dataIndex: "dummyName",
        key: "dummyName",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            showSearch
            className={`${!text ? "err" : ""}`}
            value={record.dummyName}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleVehicleId
            }
            onChange={(e) => this.handleDriverChange(e, record, "driverId")}
            onBlur={(e) => this.handleDriverBlur(e, record, "blur")}
            onSearch={(val) => this.handleDriverName(val, record, "dummyName")}
          >
            {driverList &&
              driverList.map((list) => {
                return <Option value={list.value}>{list.label}</Option>;
              })}
          </Select>
        ),
      },
      {
        title: "ph.Number",
        dataIndex: "driverNumber",
        key: "driverNumber",
        align: "left",
        width: "10%",
        render: (text, record) =>
          !loadingDriverDetails && (
            <Input
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.scheduleVehicleId
              }
              value={record.driverNumber}
              className={`${
                !text || text?.length !== 10 ? "err" : ""
              } border-none`}
              maxLength={10}
              defaultValue={text}
              style={{ width: "100%" }}
              onBlur={(e) =>
                this.onChange(e.target.value, record, "driverNumber")
              }
              onChange={(e) =>
                this.onChange(numberOnly(e), record, "driverNumber")
              }
            />
          ),
      },
      {
        title: "Type",
        dataIndex: "passengerType",
        key: "passengerType",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            className={`${!text ? "err" : ""} border-none`}
            placeholder="Select type"
            defaultValue={text ? text : ""}
            onChange={(value) => {
              this.onChange(value, record, "passengerType");
            }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleVehicleId
            }
          >
            {this.state.gstData &&
              this.state.gstData.map((option, i) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Drop location",
        dataIndex: "dropLocationId",
        key: "dropLocationId",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            className={`${!text ? "err" : ""} border-none caps`}
            placeholder="Select type"
            defaultValue={
              record.dropLocationName ? record.dropLocationName : ""
            }
            onChange={(value) => {
              this.onChange(value, record, "dropLocationId");
            }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleVehicleId
            }
          >
            {this.state.dropLocationList &&
              this.state.dropLocationList.map((option, i) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Actor/Crew/Equipment",
        dataIndex: "passengers",
        key: "passengers",
        align: "left",
        width: "20%",
        render: (text, record) => (
          <ActorCrewDD
            className={`${!text || text?.length === 0 ? "err" : ""}`}
            text={record.passengerIdList || []}
            record={record}
            type={record.passengerType}
            onChange={this.onChange}
            shootDate={this.props.match.params.shootDate}
            fieldName="passengers"
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleVehicleId
            }
          />
        ),
      },
    ];

    if (
      checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
      checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)
    ) {
      columns.push({
        title: "Action",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleVehicleId && (
                <TooltipIcon
                  icon={"more"}
                  onClick={() =>
                    this.props.history.push(
                      `${MenusRoute.TRANSPORT}/more/${record.scheduleVehicleId}`,
                      {
                        details: {
                          dropLocationName: record.dropLocationName,
                          vehicleName: record.vehicleName,
                        },
                      }
                    )
                  }
                ></TooltipIcon>
              )}
            {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
              <DeleteComponent
                record={record}
                onClick={(record) => this.handleDelete(record)}
              />
            )}
          </>
        ),
      });
    }

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        {loadingDriverDetails && <Loader />}
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>
              Shoot date - {this.props.match.params.shootDate}{" "}
            </TitleText>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                disabled={hasNewRow}
                type="primary"
                className="flrt"
                onClick={this.handleAdd}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Transport
              </Button>
            )}
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ padding: 10 }}
        >
          {/* <Col xl={18} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col> */}
          <Col xl={22}>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              loading={isLoading}
              pagination={false}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.TRANSPORT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransportMore);
