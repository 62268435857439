import React, { Component } from "react";
import {
  Modal,
  Col,
  Row,
  Button,
  Input,
  Select,
  InputNumber,
  Form,
} from "antd";

import SongsStuntsServices from "services/SongsStuntsServices";
import {
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import GlobalService from "services/GlobalService";
import { numberOnly } from "utilities/ValidationHelper";

const { Option } = Select;

class Stuntmodel extends Component {
  constructor(props) {
    super(props);
    this._songsServices = new SongsStuntsServices();
    this._globalService = new GlobalService();
  }

  state = this._emptyState();

  _emptyState() {
    return {
      description: null,
      memberCount: null,
      price: null,
      sequenceType: "Day",
      sceneTypeDetailId: null,
      type: GlobalConstants.ADD,
      visible: false,
      sequenceTypeData: [],
    };
  }

  componentDidMount() {
    this.getSequenceType();
  }

  getSequenceType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SEQUENCE_TYPE
      )
      .then((res) => {
        console.log(res.data);
        this.setState({ sequenceTypeData: res.data });
      });
  };

  static getDerivedStateFromProps(nextProps, state) {
    console.log("nextProps.recordData", nextProps.recordData);
    if (nextProps.recordData) {
      if (nextProps.type !== state.type)
        if (nextProps.recordData !== null) {
          return {
            type: nextProps.type,
            description: nextProps.recordData.description,
            memberCount: nextProps.recordData.memberCount,
            price: nextProps.recordData.price,
            sequenceType: nextProps.recordData.sequenceType,
            sceneTypeDetailId: nextProps.recordData.sceneTypeDetailId,
          };
        } else {
          return this._emptyState();
        }
    } else return null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          type,
          description,
          memberCount,
          price,
          sequenceType,
          isButtonLoading,
        } = this.state;
        const data = {
          description,
          memberCount,
          price,
          sequenceType,
        };
        this.setState({
          isButtonLoading: true,
        });
        console.log("song post data..", data);

        switch (type) {
          case GlobalConstants.ADD:
            this._songsServices
              .service(
                Services.SongVariables.ADD_SONGS_STUNTS,
                Services.SongVariables.STUNT,
                null,
                data
              )
              .then((res) => {
                this.setState(
                  {
                    isButtonLoading: false,
                    visible: false,
                  },
                  () => {
                    this._emptyState();
                    this.props.refreshData();
                    this.props.closeModal();
                  }
                );
                console.log(res);
                showNotifications(
                  Notifications.SUCCESS,
                  "Success",
                  "Stunts Created Successfully!!"
                );
              })
              .catch((err) => {
                this.setState({
                  isButtonLoading: false,
                });
                console.log(err);
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something went wrong!!"
                );
              });
            break;
          case GlobalConstants.UPDATE:
            // let data = Object.assign(data, {
            //   sceneTypeDetailId: this.state.sceneTypeDetailId
            // });
            let tempData = {
              ...data,
              sceneTypeDetailId: this.state.sceneTypeDetailId,
            };

            this._songsServices
              .service(
                Services.SongVariables.UPDATE_SONGS_STUNTS,
                Services.SongVariables.STUNT,
                this.state.sceneTypeDetailId,
                data
              )
              .then((res) => {
                this.setState(
                  {
                    isButtonLoading: false,
                    visible: false,
                    description: null,
                    memberCount: null,
                    price: null,
                    sequenceType: "Day",
                    sceneTypeDetailId: null,
                    type: GlobalConstants.ADD,
                  },
                  () => {
                    this.props.refreshData();
                    this.props.closeModal();
                  }
                );
                console.log(res);
                showNotifications(
                  Notifications.SUCCESS,
                  "Success",
                  "Stunts Updated Successfully!!"
                );
              })
              .catch((err) => {
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something went wrong!!"
                );

                this.setState({
                  isButtonLoading: false,
                });
                console.log(err);
              });
            break;
          default:
            break;
        }
      }
    });
  };

  render() {
    const { name } = this.props;
    const { getFieldDecorator } = this.props.form;
    const {
      description,
      memberCount,
      price,
      sequenceType,
      type,
      isButtonLoading,
      sequenceTypeData,
    } = this.state;
    console.log("this.state...", this.state);
    const buttonText = type === GlobalConstants.ADD ? "Add" : "Update";
    return (
      <div>
        <h3 className="font-600 mb-20 primary-font-color text-center">
          Stunts
        </h3>
        <Form>
          <Row gutter={16}>
            {/* <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">S No</label>
              <Input />
            </Col> */}
            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Stunt Type</label>
              <Form.Item>
                {getFieldDecorator("songtype", {
                  initialValue: description,
                  rules: [
                    {
                      required: true,
                      message: "Please enter SongType!",
                    },
                    // {
                    //   pattern: new RegExp("^[A-Za-z]*$"),
                    //   message: "Enter Alphabets onlys"
                    // }
                  ],
                })(
                  <Input
                    onChange={(description) =>
                      this.setState({ description: description.target.value })
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Number of People</label>
              <Form.Item>
                {getFieldDecorator("numberofpeople", {
                  initialValue: memberCount,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Number Of People!",
                    },
                  ],
                })(
                  <Input
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) =>
                      this.setState({ memberCount: numberOnly(value) })
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Day/Sequence</label>
              <Form.Item>
                {getFieldDecorator("daysequence", {
                  initialValue: sequenceType,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Day/Sequence!",
                    },
                  ],
                })(
                  <Select
                    defaultValue={sequenceType && sequenceType}
                    placeholder="Select Day/Sequence"
                    style={{ width: "100%" }}
                    onChange={(sequenceType) =>
                      this.setState({
                        sequenceType: sequenceType,
                      })
                    }
                  >
                    {sequenceTypeData &&
                      sequenceTypeData.map((sequence, i) => (
                        <Option value={sequence.label}>{sequence.label}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
              <label className="font-600">Rate</label>
              <Form.Item>
                {getFieldDecorator("rate", {
                  initialValue: price,
                  rules: [
                    {
                      required: true,
                      message: "Please enter Rate!",
                    },
                    // {
                    //   pattern: new RegExp("^[A-Za-z]*$"),
                    //   message: "Enter Alphabets onlys"
                    // }
                  ],
                })(
                  <Input
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) =>
                      this.setState({ price: numberOnly(value) })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 22 }}>
              <Row type="flex" justify="end">
                <Button
                  type="primary"
                  onClick={this.handleSubmit}
                  loading={isButtonLoading}
                >
                  {buttonText}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const StuntsModal = Form.create()(Stuntmodel);
export default StuntsModal;
