import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  Icon,
  Modal,
} from "antd";
import UploadImage from "components/shared/UploadImage/UploadImage";
import { GlobalConstants, LocalStorage } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  AttachmentType,
} from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import ImageViewer from "components/shared/ImageViewer";
import { connect } from "react-redux";
import * as _ from "lodash";

import "./CostumeTable.css";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, removeColumns } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import PaymentTypeDropdown from "components/PaymentTypeDropdown";
import { handleError } from "utilities/ErrorHandler";

const { Option, OptGroup } = Select;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class CostumeTable extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._costumeTabService = new CostumeTabService();
    this.onChange = this.onChange.bind(this);
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      isModalVisible: false,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      dropDown: null,
      sceneId: null,
      isLoading: true,
      isButtonLoading: false,
      sceneId: null,
      statusTypeData: [],
      characterNameList: [],
      paymentTypedData: [],
      activeSceneId: null,
      designationTypeValue: null,
      activeRecord: null,
    };
  }
  componentDidMount() {
    this.getCharacterListData();
    this.getStatusData();
    this.getCostumeData();
    this.getPaymentData();
    // this.checkForEmpty(this.state.dataSource);
  }
  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.sceneId) {
      this.setState(
        {
          sceneId: nextProps.activeSceneId,
        },
        () => this.getCostumeData()
      );
    }
  }

  handleFocus = (record) => {
    this.setState({ designationTypeValue: null, eventType: "isNotBlur" });
  };

  designationTypeValue(value, record) {
    if (value.length) {
      delete record.characterName;
      delete record.characterId;
    }
    if (value && value.trim().length > 0) {
      this.setState({
        designationTypeValue: value,
      });
    }
    this.setState({
      isOnChanged: false,
    });
  }

  designationBlurChanges(value, record, eventType) {
    if (this.state.isOnChanged != true) {
      let designation = this.state.designationTypeValue;
      if (designation) {
        this.setState({ eventType });
        record.characterName = designation;
        this.onChange(designation, record, "characterName");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  }

  toggleModal = (activeRecord, activeValue) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      activeRecord,
      activeValue,
    });
  };

  getCharacterListData() {
    this._costumeTabService
      .costumeTabService(Services.SceneOVerviewVariable.GET_CHARC_NAME_LIST)
      .then((res) => {
        this.setState({ characterNameList: Object.values(res.data) });
      });
  }

  getStatusData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({
          statusTypeData: res.data,
        });
      });
  }

  getPaymentData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.COSTUME_MAKEUP_PAYMENT
      )
      .then((res) => {
        this.setState({
          paymentTypedData: res.data,
        });
      });
  }

  getCostumeData = () => {
    this.setState({ count: 0, isLoading: true, dataSource: [] });
    this._costumeTabService
      .costumeTabService(
        Services.SceneOVerviewVariable.GET_COSTUMES,
        null,
        null,
        this.state.sceneId
      )
      .then((res) => {
        let costumeData = [];
        res.data.costumes &&
          res.data.costumes.map((data, i) => {
            costumeData.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState(
          {
            dataSource: costumeData,
            totalRecords: costumeData,
            isLoading: false,
          },
          () => {
            this.splitBasedOnFileTypes(this.state.dataSource);
            // this.checkForEmpty(this.state.dataSource);
          }
        );
      });
  };

  recordData = (record) => {
    if (this.state.fileList) {
      this.handleSave(record, this.state.fileList, "image");
    }
  };

  handleSeperateImage = (record) => {
    let temp = [];
    record.costumeMakeupDocument &&
      record.costumeMakeupDocument.map((record) => {
        temp.push({
          attachmentId: record.attachment?.id,
          documentId: record.documentId,
        });
      });
    return temp;
  };

  deleteImage = (id) => {
    let deleteIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let finalData = imageFiles.splice(deleteIndex, 1);
    this.setState({
      imageFiles,
    });
  };

  splitBasedOnFileTypes = (files) => {
    let tempImageFiles = [];
    let files1 = files.filter((file) => {
      if (file.images !== undefined) {
        tempImageFiles.push(...file.images);
      }
    });
    let imageFiles =
      tempImageFiles &&
      tempImageFiles.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      imageFiles,
    });
  };

  handleImage = (url, data) => {
    const { fileList } = this.state;
    if (fileList) {
      let temp = [
        ...fileList,
        { attachmentId: url, fileType: GlobalConstants.IMAGE },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ attachmentId: url, fileType: GlobalConstants.IMAGE }];
      this.setState(
        {
          fileList: temp,
        },
        () => {
          this.handleSave(data, this.state.fileList, "image");
        }
      );
    }
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    if (id) {
      this._costumeTabService
        .costumeTabService(
          Services.SceneOVerviewVariable.DELETE_COSTUME_TAB_TABLE,
          id
        )
        .then((res) => {
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Costume Deleted!!"
          );
          this.props.getNotificationCount(0);
          this.setState(
            {
              dataSource: dataSource.filter((item) => item.key !== key),
            },
            () => {
              // this.checkForEmpty(this.state.dataSource);
            }
          );
          this.getCostumeData();
        })
        .catch((err) => {
          this.props.getPermissions();
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      this.setState(
        { dataSource: dataSource.filter((item) => item.key !== key) },
        () => {
          //  this.checkForEmpty(this.state.dataSource);
        }
      );
    }
  };

  handleSubmit = (record) => {
    if (record.costumeMakeupDocument) {
      let recordData = {
        description: record.description,
        documents: record.costumeMakeupDocument,
        generalInfo: record.generalInfo,
        sceneCharacterId: record.sceneCharacterId,
        sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        paymentType: record.paymentType,
      };
      if (record.specialCost) {
        recordData.cost = record.specialCost || null;
      }
      this.setState({ isButtonLoading: true });
      const lengthOfTheDataSource = this.state.dataSource.length - 1;
      const newData = [...this.state.dataSource];
      const indexOfTheCurrentRow = newData.findIndex(
        (data) => record.key === data.key
      );
      let data = this.state.dataSource[indexOfTheCurrentRow];
      this._costumeTabService
        .costumeTabService(
          Services.SceneOVerviewVariable.SAVE_COSTUME_TAB_TABLE,
          recordData
        )
        .then((res) => {
          this.setState({
            isButtonLoading: false,
            eventType: null,
            designationTypeValue: null,
          });
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Costume created!!"
          );
          this.props.getNotificationCount();
          this.getCostumeData();
        })
        .catch((err) => {
          this.props.getPermissions();
          this.setState({
            isButtonLoading: false,
            eventType: null,
            designationTypeValue: null,
          });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      let recordData = {
        description: record.description,
        generalInfo: record.generalInfo,
        sceneCharacterId: record.sceneCharacterId,
        sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        paymentType: record.paymentType,
      };
      if (record.specialCost) {
        recordData.cost = record.specialCost || null;
      }
      this.setState({ isButtonLoading: true });
      const lengthOfTheDataSource = this.state.dataSource.length - 1;
      const newData = [...this.state.dataSource];
      const indexOfTheCurrentRow = newData.findIndex(
        (data) => record.key === data.key
      );
      let data = this.state.dataSource[indexOfTheCurrentRow];
      this._costumeTabService
        .costumeTabService(
          Services.SceneOVerviewVariable.SAVE_COSTUME_TAB_TABLE,
          recordData
        )
        .then((res) => {
          this.setState({
            isButtonLoading: false,
            eventType: null,
            designationTypeValue: null,
          });
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Costume created!!"
          );
          this.props.getNotificationCount();
          this.getCostumeData();
        })
        .catch((err) => {
          this.props.getPermissions();
          this.setState({
            isButtonLoading: false,
            eventType: null,
            designationTypeValue: null,
          });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    }
  };

  handleUpdate = (record) => {
    if (record.costumeMakeupDocument) {
      let recordData = {
        cost: record.specialCost,
        description: record.description,
        documents: record.costumeMakeupDocument,
        generalInfo: record.generalInfo,
        sceneCharacterId: record.sceneCharacterId,
        sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        paymentType: record.paymentType,
      };
      record.cost = record.specialCost;
      record.sceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
      if (record.costumeMakeupId) {
        this._costumeTabService
          .costumeTabService(
            Services.SceneOVerviewVariable.UPDATE_COSTUME_LIST,
            recordData,
            record.costumeMakeupId
          )
          .then((res) => {
            this.setState({
              isButtonLoading: false,
              eventType: null,
              designationTypeValue: null,
            });
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
            this.props.getNotificationCount();
            this.getCostumeData();
          })
          .catch((err) => {
            this.props.getPermissions();
            this.setState({
              isButtonLoading: false,
              eventType: null,
              designationTypeValue: null,
            });
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    } else {
      let recordData = {
        cost: record.specialCost,
        description: record.description,
        generalInfo: record.generalInfo,
        sceneCharacterId: record.sceneCharacterId,
        sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        paymentType: record.paymentType,
      };
      record.cost = record.specialCost;
      record.sceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
      if (record.costumeMakeupId) {
        this._costumeTabService
          .costumeTabService(
            Services.SceneOVerviewVariable.UPDATE_COSTUME_LIST,
            recordData,
            record.costumeMakeupId
          )
          .then((res) => {
            this.setState({
              isButtonLoading: false,
              eventType: null,
              designationTypeValue: null,
            });
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
            this.props.getNotificationCount();
            this.getCostumeData();
          })
          .catch((err) => {
            this.props.getPermissions();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      characterName: "",
      description: "",
      costumeMakeupDocument: "",
      actor: "",
      specialCost: "",
      state: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type, eventType) {
    this.handleSave(record, value, type);
    this.setState({
      isOnChanged: true,
    });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value || value === 0) {
      let modifyItem = newData[index];
      switch (type) {
        case "characterName":
          if (typeof value === "number") {
            modifyItem.sceneCharacterId = value;
            modifyItem.characterName = value;
          } else if (typeof value !== "number") {
            modifyItem.generalInfo = value;
            modifyItem.sceneCharacterId = "";
            modifyItem.characterName = value;
          }
          break;
        case "paymentType":
          modifyItem.paymentType = value;
          modifyItem.paymentType = value;
          break;
        case "image":
          if (modifyItem.costumeMakeupDocument) {
            modifyItem.costumeMakeupDocument = [
              ...modifyItem.costumeMakeupDocument,
              ...value,
            ];
          } else {
            modifyItem.costumeMakeupDocument = value;
          }
          this.setState({ fileList: null });
          break;
        default:
          break;
      }
    }
    if (!row.costumeMakeupId) {
      if (
        !checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        ) &&
        (row.sceneCharacterId ||
          row.sceneCharacterId === 0 ||
          row.generalInfo) &&
        row.description
      ) {
        this.handleSubmit(row);
      } else if (
        (row.sceneCharacterId ||
          row.sceneCharacterId === 0 ||
          row.generalInfo) &&
        row.description &&
        row.specialCost &&
        row.paymentType
      ) {
        row.cost = row.specialCost;
        this.handleSubmit(row);
      }
    }
    if (row.costumeMakeupId) {
      // if (row.specialCost && row.state) {
      if (!_.isEqual(row, this.state.activeRecord)) {
        row.paymentType = row.paymentType;
        if (type === "paymentType") row.paymentType = value;
        this.handleUpdate(row);
      }
      // }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  componentDidUpdate(nextProps) {
    if (nextProps.activeSceneId !== this.state.sceneId) {
      this.setState(
        {
          sceneId: nextProps.activeSceneId,
        },
        () => this.getCharacterListData()
      );
    }
  }

  render() {
    const {
      isModalVisible,
      activeRecord,
      activeValue,
      isLoading,
      dataSource,
      totalRecords,
      characterNameList,
    } = this.state;
    const { activePermisision } = this.props;
    const groupName = ["Main", "Supporting", "Junior", "Animal", "Others"];
    // characterNameList.splice(4, 1);
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    let columns = [
      {
        title: "#",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Character",
        dataIndex: "characterName",
        width: "15%",
        render: (text, record) => (
          <Select
            // showSearch
            // disabled={
            //   (!checkFor(
            //     this.props.activePermisision,
            //     GlobalConstants.PERMISSION_EDIT
            //   ) &&
            //     record.costumeMakeupId) ||
            //   record.costumeMakeupId
            // }
            onFocus={() => this.handleFocus(record)}
            onSearch={(val) => this.designationTypeValue(val, record)}
            onBlur={(value) =>
              this.designationBlurChanges(value, record, "blur")
            }
            onChange={(value) => {
              this.onChange(value, record, "characterName");
            }}
            defaultValue={text ? text : record.sceneCharacterId}
            value={text ? text : record.sceneCharacterId}
            style={{ width: "100%" }}
            className={`${!text ? "custom-empty-active" : ""}`}
            placeholder="Select type"
          >
            {characterNameList &&
              characterNameList.map((characterGroup, i) => (
                <OptGroup label={groupName[i]}>
                  {characterGroup &&
                    characterGroup.map((character, i) => (
                      <Option value={parseInt(character.value)}>
                        {character.label}
                      </Option>
                    ))}
                  {/* <Option value={"character.value"}>{"character.label"}</Option> */}
                </OptGroup>
              ))}
            {/* {this.state.characterNameList &&
              this.state.characterNameList.map((option, i) => (
                <Option value={option.characterId}>
                  {option.characterName}
                </Option>
              ))} */}
          </Select>
        ),
        // render: (text, record) => {
        //   return this.state.eventType === "blur" ? (
        //     <Select
        //       showSearch
        //       disabled={!checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_EDIT) && record.costumeMakeupId}
        //       onSearch={this.designationTypeValue}
        //       onBlur={value => this.designationBlurChanges(value, record, "blur")}
        //       value={record.characterName}
        //       style={{ width: "100%" }}
        //       className="border-none"
        //       placeholder="Select type"
        //       onChange={value => this.onChange(value, record, "characterName")}
        //       defaultValue={text ? text : record.characterName}
        //     >
        //       {this.state.characterNameList &&
        //         this.state.characterNameList.map((option, i) => (
        //           <Option value={option.characterId}>
        //             {option.characterName}
        //           </Option>
        //         ))}
        //     </Select>
        //   ) : (
        //       <Select
        //         showSearch
        //         onSearch={this.designationTypeValue}
        //         onBlur={value => this.designationBlurChanges(value, record, "blur")}
        //         disabled={!checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_EDIT) && record.costumeMakeupId}
        //         style={{ width: "100%" }}
        //         className="border-none"
        //         placeholder="Select type"
        //         onChange={value => this.onChange(value, record, "characterName")}
        //         defaultValue={text ? text : record.characterName}
        //       >
        //         {this.state.characterNameList &&
        //           this.state.characterNameList.map((option, i) => (
        //             <Option value={option.characterId}>
        //               {option.characterName}
        //             </Option>
        //           ))}
        //       </Select>
        //     )
        // }
      },
      {
        title: "Actor",
        dataIndex: "actor",
        width: "10%",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "20%",
        className: "caps",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""}`,
            },
            children: text,
          };
        },
      },

      {
        title: "Payment Type",
        width: "10%",
        dataIndex: "paymentType",
        render: (text, record) => {
          return (
            !isLoading && (
              <Select
                disabled={
                  !checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && record.costumeMakeupId
                }
                style={{ width: "100%" }}
                className={`${!text ? "custom-empty-active" : ""}`}
                placeholder="Select type"
                onChange={(value) =>
                  this.onChange(value, record, "paymentType")
                }
                defaultValue={text ? text : ""}
              >
                {this.state.paymentTypedData &&
                  this.state.paymentTypedData.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            )
          );
        },
      },

      // {
      //   title: "Status",
      //   dataIndex: "state",
      //   width: "20%",
      //   render: (text, record) => (
      //     <Select
      //       disabled={
      //         !checkFor(
      //           this.props.activePermisision,
      //           GlobalConstants.PERMISSION_EDIT
      //         ) && record.costumeMakeupId
      //       }
      //       style={{ width: "100%" }}
      //       className="border-none"
      //       placeholder="Select type"
      //       onChange={value => this.onChange(value, record, "status")}
      //       defaultValue={text ? text : ""}
      //     >
      //       {this.state.statusTypeData &&
      //         this.state.statusTypeData.map((option, i) => (
      //           <Option value={option.value}>{option.label}</Option>
      //         ))}
      //     </Select>
      //   )
      // },
      {
        title: "Image",
        dataIndex: "costumeMakeupDocument",
        className: "upload-table  take-td-full",
        style: { padding: "26px 16px" },
        width: "15%",
        padding: "26px 16px",
        render: (text, record) => {
          return (
            <React.Fragment>
              {record?.costumeMakeupDocument?.length > 0 ? (
                <ImageViewer
                  imageCount={1}
                  ImagesData={record?.costumeMakeupDocument}
                  type="table"
                  refreshData={this.getCostumeData}
                  url={Services.CostumesMakeupVariables.COSTUMES_DELETE_DOC}
                  canDelete
                />
              ) : null}
              <div className="small-upload-image">
                {!this.state.isButtonLoading ? (
                  <UploadImage
                    keys={record}
                    type={AttachmentType?.costumePicture}
                    handleImage={this.handleImage}
                  />
                ) : null}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: "Continuity",
        width: "10%",
        align: "center",
        render: (text, record) => {
          return (
            !record.costumeMakeupId && (
              <Tooltip
                title={GlobalConstants.IMPORT_FROM_PREVIOUS_SCENES}
                placement={GlobalConstants.RIGHT}
              >
                <Button
                  type="primary"
                  onClick={() => this.toggleModal(record)}
                  disabled={!record.sceneCharacterId}
                >
                  <Icon type="download" style={{ fontSize: "20px" }} />
                </Button>
              </Tooltip>
            )
          );
        },
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "5%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Row>
                <Col xl={11}>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() =>
                      this.handleDelete(record.key, record.costumeMakeupId)
                    }
                  >
                    <Tooltip
                      title={GlobalConstants.DEL}
                      placement={GlobalConstants.RIGHT}
                    >
                      <Button icon="delete" type="link" />
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </React.Fragment>
          ) : null,
      });
    }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      columns.splice(4, 0, {
        title: "Cost (₹)",
        dataIndex: "specialCost",
        width: "10%",
        editable: true,
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""} cash`,
            },
            children: text,
          };
        },
      });
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    if (!checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      columns = removeColumns(columns, ["Payment type"]);
    }

    const columnsData = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "specialCost" ? "number" : "text",
          editable:
            col.editable !==
            checkFor(
              this.props.activePermisision,
              GlobalConstants.PERMISSION_EDIT
            )
              ? record.costumeMakeupId
                ? false
                : true
              : true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          paddingTop: 0,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) ? (
          <Col xs={24}>
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 5, float: "right" }}
              disabled={hasNewRow}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} />
              Entry
            </Button>
          </Col>
        ) : null}
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
                <Table
                  className="art-set"
                  components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columnsData}
                  pagination={false}
                  loading={isLoading}
                />
              ) : (
                <ContactAdminErrorTemplate />
              )}
            </Col>
          </Row>
        </Col>
        <Modal
          centered
          visible={isModalVisible}
          onCancel={() => {
            this.toggleModal();
            // this.getCostumeData();
          }}
          footer={null}
          destroyOnClose
          title="Scene Continuity"
        >
          <PaymentTypeDropdown
            activeRecord={activeRecord}
            activeValue={activeValue}
            toggleModal={this.toggleModal}
            refreshData={this.getCostumeData}
            dropdownType={GlobalConstants.COSTUME}
          />
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Costume", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostumeTable);
