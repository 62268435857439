import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Icon,
  Select,
  Button,
  Checkbox,
  Tag,
} from "antd";
import FileCard from "components/shared/FileCardComponent";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Screens,
  AttachmentType,
} from "constants/AppConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import GlobalService from "services/GlobalService";
import GeoCode from "components/shared/GeoCode";
import { OnboardServices } from "services";
import ImageViewer from "components/shared/ImageViewer";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import { setActiveTab } from "store/action";
import { connect } from "react-redux";
import LocationConfirmModel from "components/LocationConfirmModel";
import { numberOnly, amountOnly, checkFor } from "utilities/ValidationHelper";
import { GeoServices } from "components/shared/GeoCode/geoCall";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import { Link, Prompt } from "react-router-dom";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import LocationAvailabilityTable from "components/Location/LocationAvailabilityTable";
import Loader from "components/shared/Loader";
import TooltipComponent from "components/shared/TooltipComponent";
import { handleError } from "utilities/ErrorHandler";
import MultipleUpload from "components/MultipleUpload/MultipleUpload";
import Map from "components/Map/Map";
import locationIcon from "../../assets/icons/location.png";

const { TextArea } = Input;
class AddLocationPage extends Component {
  state = {
    visible: false,
    sceneLocation: null,
    actualLocation: null,
    locationType: null,
    contactPerson: null,
    contactNumber: null,
    shootAmount: null,
    shootAmountType: null,
    workAmount: null,
    workAmountType: null,
    addressLine: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
    status: null,
    imageId: null,
    isLoading: false,
    locationId: null,
    locationListDatas: [],
    statusListDatas: [],
    countryListDatas: [],
    paymentListDatasShoot: [],
    paymentListDatasSetWork: [],
    governmentApprovalStatusDatas: [],
    fileList: null,
    docFiles: null,
    imageFiles: null,
    sceneLocationName: null,
    isModalVisible: false,
    confirmedLocationName: null,
    sceneLocationId: null,
    phoneCodeList: null,
    phoneCode: "+91",
    isdirty: false,
    isLocationDetailFetched: false,
    showMap: false,
    lat: null,
    lng: null,
    governmentApprovalVisible: false,
    existingLocationDatas: [],
    actualLocationId: null,
  };

  constructor(props) {
    super(props);
    this.getLocation();
    this._globalService = new GlobalService();
    this._onboardservices = new OnboardServices();
    this._locationService = new LocationTabService();
    this._geoService = new GeoServices();
  }

  getLocation() {
    window.navigator.geolocation.getCurrentPosition(
      (e) => {
        if (!this.state.lat) {
          this.setState({
            lat: e?.coords?.latitude,
            lng: e?.coords?.longitude,
          });
        }
      },
      (err) => {
        showNotifications(
          Notifications.ERROR,
          "Permission denied",
          err?.message
        );
      }
    );
  }

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      docFiles,
      imageFiles,
    });
  };

  componentWillUnmount() {
    localStorage.setItem(GlobalConstants.REDIRECT_TO, Screens.LOCATION);
    this.props.setActiveTab("1");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === "Update") {
      if (nextProps.locationId) {
        if (nextProps.locationId !== this.state.locationId) {
          this.setState(
            {
              locationId: nextProps.locationId,
              actualLocation: nextProps.actualLocationName,
            },
            () => {
              this.getLocationDetails();
            }
          );
        }
      }
    }
  }

  componentDidMount() {
    this.getLocationData();
    this.getPaymentData();
    this.getGovtApprovalStatusData();
    this.getStatusData();
    this.getCountries();
    this.getPhoneCode();
    this.setState(
      {
        locationId: this.props.match.params.locationId,
        sceneLocationName: this.props.match.params.sceneLocationName,
        sceneLocationId: this.props.match.params.sceneLocationId,
      },
      () => {
        if (this.state.locationId) {
          this.getLocationDetails();
        }
      }
    );
  }

  getLocationDetails = () => {
    this.setState({
      isLocationDetailFetched: false,
    });
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_BY_ID,
        this.state.locationId,
        this.state.sceneLocationId ||
          localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID)
      )
      .then((res) => {
        this.setState(
          {
            addressLine: res.data.address && res.data.address.addressLine,
            city: res.data.address && res.data.address.city,
            country: res.data.address && res.data.address.country,
            state: res.data.address && res.data.address.state,
            zipCode: res.data.address && res.data.address.zipCode,
            phoneCode: res.data.phoneCode,
            contactNumber: res.data.contactNumber,
            contactPerson: res.data.contactPerson,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            lat: res.data.latitude,
            lng: res.data.longitude,
            locationType: res.data.locationType,
            shootAmount: res.data.shootAmount,
            governmentApprovalStatus: res.data.governmentApprovalStatusEnum,
            isNeedGovernmentApproval: res.data.isNeedGovernmentApproval,
            shootAmountType: res.data.shootPaymentType,
            status: res.data.state,
            workAmount: res.data.workAmount,
            workAmountType: res.data.workPaymentType,
            actualLocation: this.props.match.params.actualLocationName,
            fileList: res.data.locationImage,
            pan: res.data.pan,
            gst: res.data.gst,
            comment: res.data.comment,
            cautionDeposit: res.data.cautionDeposit,
            isLocationDetailFetched: true,
            mappedScenes: res.data.mappedScenes,
          },
          () => {
            this.splitBasedOnFileTypes(this.state.fileList);
          }
        );
        this.refs.image.scrollIntoView({ block: "end", behavior: "smooth" });
      });
  };

  deleteImage = (id) => {
    let fileDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );
    let imageDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;
    imageFiles.splice(imageDeleteIndex, 1);
    fileList.splice(fileDeleteIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    // this.props.handleModel("location", false, "entire");
  };

  handleCancel = (e) => {
    this.props.form.resetFields();
    // this.props.handleModel("location", false, "entire");
    this.setState({
      sceneLocation: null,
      actualLocation: null,
      locationType: null,
      contactPerson: null,
      contactNumber: null,
      shootAmount: null,
      shootAmountType: null,
      workAmount: null,
      workAmountType: null,
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      status: null,
      imageId: null,
      isLoading: false,
      locationId: null,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      status: null,
    });
  };

  disableUpdate = (value) => {
    this.setState({
      isLoading: value,
    });
  };

  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      // files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
      files.push(id);
    });

    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  getDetails = (city, state, country, zipCode) => {
    this.setState({
      city,
      state,
      country,
    });
    this.setState({
      zipCode,
      isdirty: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      addressLine,
      city,
      state,
      country,
      zipCode,
      contactNumber,
      contactPerson,
      imageId,
      locationType,
      sceneLocation,
      actualLocation,
      shootAmount,
      shootAmountType,
      workAmount,
      workAmountType,
      phoneCode,
      status,
      isNeedGovernmentApproval,
      governmentApprovalStatus,
      locationId,
      pan,
      gst,
      comment,
      locationAvailabilities,
      cautionDeposit,
      actualLocationId,
    } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        if (
          this.state.status === GlobalConstants.CONFIRMED ||
          this.state.status === GlobalConstants.NEGOTIATION
        ) {
          const data = {
            locationId: this.props.location.pathname.split("/")[3] || null,
            address: {
              addressLine: addressLine,
              city: city,
              country: country,
              state: state,
              zipCode: zipCode,
            },
            contactNumber: contactNumber,
            contactPerson: contactPerson,
            phoneCode: phoneCode,
            latitude: this.props.latitude,
            locationImage: this.state.fileList,
            locationName: actualLocation,
            locationType: locationType,
            longitude: this.props.longitude,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            shootAmount: shootAmount,
            // shootPaymentType: "PER_DAY",
            shootPaymentType: shootAmountType,
            state: this.state.status,
            workAmount: workAmount,
            // workPay mentType: "PER_DAY"
            workPaymentType: workAmountType,
            isNeedGovernmentApproval,
            governmentApprovalStatus,
            // actualLocationId: locationId,
            actualLocationId: this.state.actualLocationId || null,
            pan,
            gst,
            comment,
            locationAvailabilities,
            cautionDeposit,
          };
          if (
            !checkFor(
              this.props.activePermisision,
              GlobalConstants.PERMISSION_PERSONAL
            )
          ) {
            delete data.address;
          }
          this._locationService
            .locationTabService(
              Services.SceneOVerviewVariable.ADD_LOCATION,
              data
            )
            .then((res) => {
              this.setState({ isLoading: false, locationId: null });
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "New Location Created !!"
              );
              this.props.getNotificationCount();
              this.props.history.goBack();
              localStorage.setItem(
                GlobalConstants.REDIRECT_TO,
                Screens.LOCATION
              );
              this.props.setActiveTab("1");
            })
            .catch((err) => {
              this.props.getPermissions();
              console.log(err);
              this.setState({ isLoading: false });
              this.props.form.resetFields();
              // this.handleCancel();
              showNotifications(
                Notifications.ERROR,
                "Failed",
                err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
                handleError(
                  err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
                )
              );
            });
        } else {
          const data = {
            // locationId:
            //   this.state.sceneLocationId ||
            //   localStorage.getItem(LocalStorage.LOCATION_ID),
            locationId: this.props.location.pathname.split("/")[3] || null,
            contactNumber: contactNumber,
            contactPerson: contactPerson,
            phoneCode: phoneCode,
            locationImage: this.state.fileList,
            locationName: actualLocation,
            locationType: locationType,
            state: this.state.status,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            address: {
              addressLine: addressLine,
              city: city,
              country: country,
              state: state,
              zipCode: zipCode,
            },
            isNeedGovernmentApproval,
            governmentApprovalStatus,
            pan,
            gst,
            comment,
            cautionDeposit,
            actualLocationId: this.state.actualLocationId || null,
          };
          if (
            !checkFor(
              this.props.activePermisision,
              GlobalConstants.PERMISSION_PERSONAL
            )
          ) {
            delete data.address;
          }
          this._locationService
            .locationTabService(
              Services.SceneOVerviewVariable.ADD_LOCATION,
              data
            )
            .then((res) => {
              this.setState({ isLoading: false, locationId: null });
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "New Location Created !!"
              );
              this.props.getNotificationCount();
              this.props.history.goBack();
              localStorage.setItem(
                GlobalConstants.REDIRECT_TO,
                Screens.LOCATION
              );
              this.props.setActiveTab("1");
            })
            .catch((err) => {
              console.log(err);
              this.props.getPermissions();
              this.setState({ isLoading: false });
              this.props.form.resetFields();
              // this.handleCancel();
              showNotifications(
                Notifications.ERROR,
                "Failed",
                err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
                handleError(
                  err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
                )
              );
            });
        }
      }
    });
  };

  getLocationData = () => {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "LOCATION_TYPE")
      .then((res) => {
        this.setState({ locationListDatas: res.data });
      });
  };

  getPaymentData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "LOCATION_PAYMENT_TYPE",
        true
      )
      .then((res) => {
        this.setState({ paymentListDatasShoot: res.data });
      });
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "LOCATION_PAYMENT_TYPE"
      )
      .then((res) => {
        this.setState({ paymentListDatasSetWork: res.data });
      });
  };

  getGovtApprovalStatusData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "GOVERNMENT_APPROVAL"
      )
      .then((res) => {
        this.setState({ governmentApprovalStatusDatas: res.data });
      });
  };

  getStatusData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({ statusListDatas: res.data });
      });
  };

  getCountries = () => {
    this._onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          countryListDatas: res.data.countries,
        });
      });
  };

  getPhoneCode = () => {
    this._globalService
      .globalService(Services.GlobalVariables.PHONE_CODE)
      .then((res) => {
        this.setState({
          phoneCodeList: res.data.countries,
        });
      });
  };

  handleStatus = (value) => {
    if (value !== GlobalConstants.CONFIRMED) {
      this.setState({ status: value });
    }

    // let locationId = this.state.sceneLocationId || localStorage.LOCATION_ID;
    let locationId = this.props.location.pathname.split("/")[3];
    if (value === GlobalConstants.CONFIRMED) {
      this._locationService
        .locationTabService(
          Services.SceneOVerviewVariable.GET_CONFIRMED_LOCATION,
          null,
          locationId
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              confirmedLocationName:
                res.data.locations && res.data.locations[0].actualLocationName,
              isModalVisible: true,
              isdirty: true,
            });
          }
          if (res.status === 204) {
            this.setState({
              status: value,
            });
          }
        });
      this.setState({ governmentApprovalVisible: true });
    } else {
      this.setState({ governmentApprovalVisible: false });
    }
  };
  submitData = () => {
    this.setState({
      status: GlobalConstants.CONFIRMED,
      isModalVisible: false,
    });
  };
  closeModal = () => {
    this.setState({
      // gettingStatus: GlobalConstants.TO_BE_CONFIRMED,
      isModalVisible: false,
    });
    this.props.history.goBack();
  };

  handleAvailability = (details) => {
    this.setState({
      locationAvailabilities: details,
    });
  };
  getExistingLocation = (e) => {
    if (e === "") return null;
    this.setState({
      actualLocation: e,
    });

    let sceneLocId = this.props.location.pathname.split("/")[3];
    this._locationService
      .locationTabService(
        Services.OnboardVariables.GET_EXISTING_LOCATION,
        e,
        sceneLocId
      )
      .then((res) => {
        this.setState({ existingLocationDatas: res.data });
      })
      .catch((err) => {});
  };

  getExistingLocationDetails = (id, autoCompleteLocation) => {
    this.setState({ governmentApprovalVisible: false });
    this.setState({
      actualLocation: autoCompleteLocation,
      isdirty: false,
    });
    this.setState({ actualLocationId: id });
    this.setState({ existingLocationDatas: null });
    this._locationService
      .locationTabService(
        Services.OnboardVariables.GET_EXISTING_LOCATION_DETAILS,
        id
      )
      .then((res) => {
        if (res.data.state === "CONFIRMED") {
          // this.setState({ governmentApprovalVisible: false });
        }
        // this.setState({ governmentApprovalVisible: false });
        this.setState(
          {
            addressLine: res.data.address && res.data.address.addressLine,
            city: res.data.address && res.data.address.city,
            country: res.data.address && res.data.address.country,
            state: res.data.address && res.data.address.state,
            zipCode: res.data.address && res.data.address.zipCode,
            phoneCode: res.data.phoneCode,
            contactNumber: res.data.contactNumber,
            contactPerson: res.data.contactPerson,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            lat: res.data.latitude,
            lng: res.data.longitude,
            locationType: res.data.locationType,
            shootAmount: res.data.shootAmount,
            // governmentApprovalStatus: res.data.governmentApprovalStatusEnum,
            // isNeedGovernmentApproval: res.data.isNeedGovernmentApproval,
            isNeedGovernmentApproval: false,
            shootAmountType: res.data.shootPaymentType,
            // status: res.data.state,
            workAmount: res.data.workAmount,
            workAmountType: res.data.workPaymentType,
            // actualLocation: this.props.match.params.actualLocationName,
            fileList: res.data.locationImage,
            pan: res.data.pan,
            gst: res.data.gst,
            comment: res.data.comment,
            cautionDeposit: res.data.cautionDeposit,
            isLocationDetailFetched: true,
            mappedScenes: res.data.mappedScenes,
          },
          () => {
            this.splitBasedOnFileTypes(this.state.fileList);
          }
        );
      })
      .catch((err) => {});
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { latitude, longitude, activePermisision } = this.props;
    const {
      fileList,
      docFiles,
      imageFiles,
      locationId,
      isLocationDetailFetched,
    } = this.state;
    const { Option } = Select;

    const maxImageReached = imageFiles && imageFiles.length >= 4;
    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <div style={{ padding: 20 }}>
        <Prompt
          when={this.state.isdirty == true}
          message={(location) =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <Row
          type="flex"
          align="middle"
          justify={"space-between"}
          style={{
            backgroundColor: "#001529",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          className="box-shadow"
        >
          <Col xl={12}>
            <Button
              type="primary"
              style={{
                backgroundColor: "transparent",
                borderWidth: 0,
                fontSize: 20,
                paddingLeft: 0,
              }}
              onClick={() => {
                this.props.history.goBack();
                localStorage.setItem(
                  GlobalConstants.REDIRECT_TO,
                  Screens.LOCATION
                );
                this.props.setActiveTab("1");
              }}
            >
              <Icon type="left" />
            </Button>
            <TitleText>
              {this.state.sceneLocationName ||
                localStorage.getItem(LocalStorage.SCENE_LOCATION_NAME)}
            </TitleText>
          </Col>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="primary"
                loading={this.state.isLoading}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Col>
          )}
        </Row>
        <Row gutter={16} style={{ marginBottom: 10, marginTop: 20 }}>
          <Col span={8}>
            <label className="text-right  m-0 ">*Actual Location Name :</label>
            <Form.Item className="m-0">
              {getFieldDecorator("actuallocation", {
                initialValue: this.state.actualLocation,
                rules: [
                  {
                    required: true,
                    message: "Enter your Actual Location!",
                  },
                ],
              })(
                // <Input
                //   placeholder="Actual Location"
                //   // onChange={(e) =>
                //   //   this.setState({
                //   //     actualLocation: e.target.value,
                //   //     isdirty: true,
                //   //   })
                //   // }
                //   onChange={this.getExistingLocation}
                // />

                <>
                  {/* <Input
                    type="select"
                    onChange={this.getExistingLocation}
                    value={this.state.actualLocation || null}
                  />
                  <span className="locationList">
                    {this.state.existingLocationDatas &&
                      this.state.existingLocationDatas.map((locData, index) => (
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          className="locationSelect"
                          key={index}
                          value={locData.value}
                          onClick={(e) =>
                            this.getExistingLocationDetails(
                              locData.value,
                              locData.label
                            )
                          }
                        >
                          {locData.label}{" "}
                        </li>
                      ))}
                  </span> */}

                  <Select
                    showSearch
                    placeholder="Country"
                    className="w-100"
                    onSearch={(value) => this.getExistingLocation(value)}
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={(e) => {
                      if (
                        this.state.existingLocationDatas?.includes({
                          label: e,
                        }) === false
                      ) {
                        let oldData = this.state?.existingLocationDatas;
                        if (typeof oldData === "string") {
                          oldData = [{ label: e, value: e }];
                        } else {
                          oldData.push({ label: e, value: e });
                        }
                        this.setState({
                          existingLocationDatas: oldData,
                          actualLocation: e,
                        });
                      }
                    }}
                    value={this.state.actualLocation || null}
                    filterOption={(input, option) =>
                      option.props.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {this.state?.existingLocationDatas &&
                      (this.state?.existingLocationDatas || []).map(
                        (option, i) => (
                          <Option
                            value={option.value}
                            key={i}
                            onClick={(e) =>
                              this.getExistingLocationDetails(
                                option.value,
                                option.label
                              )
                            }
                          >
                            {option.label}
                          </Option>
                        )
                      )}
                  </Select>
                </>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="text-right m-0">Contact Person * </label>
            <Form.Item className="m-0">
              {getFieldDecorator("contactperson", {
                initialValue: this.state.contactPerson,
              })(
                <Input
                  placeholder="Contact Person Name"
                  onChange={(e) =>
                    this.setState({
                      contactPerson: e.target.value,
                      isdirty: true,
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="text-right  m-0">Phone Number * </label>
            <Form.Item className="m-0">
              {getFieldDecorator("contactnumber", {
                initialValue: this.state.contactNumber,
                rules: [
                  {
                    required: true,
                    message: "Enter your contact number!",
                  },
                  this.state.phoneCode === "+91" && {
                    pattern: ".{10,}",
                    message: "Enter 10 Numbers Only!",
                  },
                ],
              })(
                <Input
                  placeholder="Contact Number"
                  style={{ width: "100%" }}
                  maxLength={this.state.phoneCode === "+91" ? 10 : null}
                  onChange={(value) => {
                    this.setState({
                      contactNumber: numberOnly(value),
                      isdirty: true,
                    });
                  }}
                  addonBefore={
                    <Form.Item
                      className="payment-type"
                      style={{
                        margin: "0px",
                        height: "30px",
                        top: "-4px",
                      }}
                    >
                      {getFieldDecorator("phoneCode", {
                        initialValue: this.state.phoneCode || undefined,
                      })(
                        <Select
                          showSearch
                          defaultValue={this.state.phoneCode}
                          placeholder="Country Code"
                          style={{ width: "100px" }}
                          optionFilterProp="children"
                          onChange={(value) => {
                            this.setState({
                              phoneCode: value,
                              isdirty: true,
                            });
                          }}
                          filterOption={(input, option) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .includes(input.toString().toLowerCase())
                          }
                        >
                          {this.state.phoneCodeList &&
                            (this.state.phoneCodeList || []).map(
                              (option, i) => (
                                <Option value={option.phoneCode}>
                                  {option.phoneCode}
                                </Option>
                              )
                            )}
                        </Select>
                      )}
                    </Form.Item>
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <Col span={8}>
            <label className="text-right  m-0">*Status :</label>
            <Form.Item className="m-0">
              {getFieldDecorator("locationstatus", {
                initialValue:
                  this.state.status || GlobalConstants.TO_BE_CONFIRMED,
              })(
                <Select
                  placeholder="Status"
                  className="w-100"
                  onChange={this.handleStatus}
                >
                  {this.state.statusListDatas &&
                    (this.state.statusListDatas || []).map((option, i) => (
                      <Option value={option.value}>{option.label}</Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="">Location Type </label>
            <Form.Item className="m-0">
              {getFieldDecorator("locationtype", {
                initialValue: this.state.locationType || undefined,
                rules: [
                  {
                    required: false,
                    message: "Enter your Actual Location!",
                  },
                ],
              })(
                <Select
                  onChange={(value) =>
                    this.setState({ locationType: value, isdirty: true })
                  }
                  placeholder="Select Location Type"
                  className="w-100"
                >
                  {this.state.locationListDatas &&
                    (this.state.locationListDatas || []).map((option, i) => (
                      <Option value={option.value}>{option.label}</Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="text-right  m-0">ZipCode * </label>
            <Form.Item className="m-0">
              {getFieldDecorator("zipcode", {
                initialValue: this.state.zipCode,
              })(
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    placeholder="enter you pincode"
                    maxLength={6}
                    value={this.state.zipCode || null}
                    onChange={(e) => {
                      this.setState({
                        zipCode: e.target.value.substring(0, 5),
                        isdirty: true,
                      });
                    }}
                  />
                  <img
                    src={locationIcon}
                    style={{
                      margin: "0px 10px",
                      cursor: "pointer",
                      height: 30,
                      width: 30,
                    }}
                    onClick={() =>
                      this.setState({
                        showMap: true,
                      })
                    }
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <Col span={8}>
            <label className="text-right  m-0">City * </label>
            <Form.Item className="m-0">
              {getFieldDecorator("city", {
                initialValue: this.state.city,
              })(
                <Input
                  placeholder="City"
                  onChange={(e) =>
                    this.setState({
                      city: e.target.value,
                      isdirty: true,
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="text-right  m-0">*State :</label>
            <Form.Item className="m-0">
              {getFieldDecorator("state", {
                initialValue: this.state.state,
              })(
                <Input
                  placeholder="State"
                  onChange={(e) =>
                    this.setState({
                      state: e.target.value,
                      isdirty: true,
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <label className="text-right  m-0">Country * </label>
            <Form.Item className="m-0">
              {getFieldDecorator("country", {
                initialValue: this.state.country || undefined,
              })(
                <Select
                  style={{ fontWeight: "bold", color: "black" }}
                  showSearch
                  placeholder="Country"
                  className="w-100"
                  onChange={(value) =>
                    this.setState({ country: value, isdirty: true })
                  }
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                  value={this.state.country || null}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.countryListDatas &&
                    (this.state.countryListDatas || []).map((option, i) => (
                      <Option value={option.name}>{option.name}</Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <Col span={24}>
            <label className="text-right  m-0">*Address :</label>
            <Form.Item className="m-0">
              {getFieldDecorator("address", {
                initialValue: this.state.addressLine,
              })(
                <Input
                  placeholder="Address"
                  onChange={(e) =>
                    this.setState({
                      addressLine: e.target.value,
                      isdirty: true,
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {this.state.status === GlobalConstants.NEGOTIATION ||
        // this.governmentApprovalVisible ? (
        this.state.governmentApprovalVisible ? (
          <>
            {checkFor(
              activePermisision,
              GlobalConstants.PERMISSION_FINANCE
            ) && (
              <Row gutter={16} style={{ marginBottom: 10 }}>
                <Col span={8}>
                  <label className="text-right  m-0">
                    Rent/Hire (Shoot Day) :
                  </label>
                  <Form.Item className="m-0">
                    {getFieldDecorator("shootday", {
                      initialValue: this.state.shootAmount,
                      rules: [
                        {
                          required:
                            this.state.status === GlobalConstants.CONFIRMED
                              ? true
                              : false,
                          message: "This field is required!",
                        },
                      ],
                    })(
                      <Input
                        prefix=" ₹ "
                        placeholder="Enter Amount"
                        onChange={(e) =>
                          this.setState({
                            shootAmount: amountOnly(e),
                            isdirty: true,
                          })
                        }
                        addonAfter={
                          <Form.Item
                            className="payment-type"
                            style={{
                              margin: "0px",
                              height: "30px",
                              top: "-4px",
                            }}
                          >
                            {getFieldDecorator("shootAmountType", {
                              initialValue:
                                this.state.shootAmountType || undefined,
                            })(
                              <Select
                                placeholder="Payment Type"
                                style={{ width: "125px" }}
                                onChange={(value) =>
                                  this.setState({
                                    shootAmountType: value,
                                    isdirty: true,
                                  })
                                }
                              >
                                {this.state.paymentListDatasShoot &&
                                  (this.state.paymentListDatasShoot || []).map(
                                    (option, i) => (
                                      <Option value={option.value}>
                                        {option.label}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            )}
                          </Form.Item>
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <label className="text-right  m-0">
                    Rent/Hire (Set Work Day) :
                  </label>
                  <Form.Item className="m-0">
                    {getFieldDecorator("setWorkDay", {
                      initialValue: this.state.workAmount,
                      rules: [
                        {
                          required:
                            this.state.status === GlobalConstants.CONFIRMED
                              ? true
                              : false,
                          message: "This field is required!",
                        },
                      ],
                    })(
                      <Input
                        prefix=" ₹ "
                        placeholder="Enter Amount"
                        onChange={(e) =>
                          this.setState({
                            workAmount: amountOnly(e),
                            isdirty: true,
                          })
                        }
                        addonAfter={
                          <Form.Item
                            className="payment-type"
                            style={{
                              margin: "0px",
                              height: "30px",
                              top: "-4px",
                            }}
                          >
                            {getFieldDecorator("workAmountType", {
                              initialValue:
                                this.state.workAmountType || undefined,
                            })(
                              <Select
                                placeholder="Payment Type"
                                onChange={(value) =>
                                  this.setState({
                                    workAmountType: value,
                                    isdirty: true,
                                  })
                                }
                                style={{ width: "125px" }}
                              >
                                {this.state.paymentListDatasSetWork &&
                                  (
                                    this.state.paymentListDatasSetWork || []
                                  ).map((option, i) => (
                                    <Option value={option.value}>
                                      {option.label}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <label className="text-right  m-0">Caution deposit :</label>
                  <Form.Item className="m-0">
                    {getFieldDecorator("cautionDeposit", {
                      initialValue: this.state.cautionDeposit,
                      rules: [{ required: false, message: "" }],
                    })(
                      <Input
                        // placeholder="enter comment for the location"
                        // autoSize={{ minRows: 6, maxRows: 5 }}
                        onChange={(e) =>
                          this.setState({ cautionDeposit: numberOnly(e) })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        ) : null}
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <Col span={16}>
            <label className="">Notes </label>
            <Form.Item className="payment-type">
              {getFieldDecorator("comment", {
                initialValue: this.state.comment || undefined,
              })(
                <TextArea
                  placeholder="enter comment for the location"
                  rows={4}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
              )}
            </Form.Item>
          </Col>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE) &&
            this.state.governmentApprovalVisible && (
              <Col span={8}>
                <Col span={12}>
                  <label className="text-right  m-0">
                    Government Approval:
                  </label>
                  <Form.Item className="m-0" style={{ marginRight: 10 }}>
                    {getFieldDecorator("isNeedGovernmentApproval", {
                      initialValue: this.state.isNeedGovernmentApproval,
                    })(
                      <Checkbox
                        checked={this.state.isNeedGovernmentApproval}
                        onChange={(value) => {
                          this.setState({
                            isNeedGovernmentApproval:
                              value.target.checked || false,
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                {this.state.isNeedGovernmentApproval && (
                  <Col span={12}>
                    <label className="text-right  m-0">
                      Government Approval Status:
                    </label>
                    <Form.Item
                      className="payment-type"
                      style={{
                        margin: "0px",
                        height: "30px",
                        top: "-4px",
                      }}
                    >
                      {getFieldDecorator("governmentApprovalStatus", {
                        initialValue:
                          this.state.governmentApprovalStatus || undefined,
                        rules: [
                          {
                            required: this.state.isNeedGovernmentApproval
                              ? true
                              : false,
                            message: "Goverment Approval status is required!",
                          },
                        ],
                      })(
                        <Select
                          placeholder="Government approval status"
                          onChange={(value) =>
                            this.setState({
                              governmentApprovalStatus: value,
                              isdirty: true,
                            })
                          }
                          style={{ width: "232px" }}
                        >
                          {this.state.governmentApprovalStatusDatas &&
                            (
                              this.state.governmentApprovalStatusDatas || []
                            ).map((option, i) => (
                              <Option value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Col>
            )}
        </Row>
        {this.state.governmentApprovalVisible && (
          <Row gutter={16} style={{ marginBottom: 10 }}>
            <Col span={16}>
              <label className="">Location Availability </label>
              <Form.Item className="payment-type">
                <LocationAvailabilityTable
                  getFromChild={this.handleAvailability}
                  locationId={this.props.match.params.locationId}
                  sceneLocationId={this.props.match.params.sceneLocationId}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col style={{ display: "flex", gap: 10 }}>
            <Button
              onClick={() => {
                this.props.history.goBack();
                localStorage.setItem(
                  GlobalConstants.REDIRECT_TO,
                  Screens.LOCATION
                );
                this.props.setActiveTab("1");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={this.state.isLoading}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Row>
          <label className="font-600">Photos</label>
          {/* <UploadImage
                handleImage={this.handleImage}
                text="Upload Location Image"
              /> */}
          <Form.Item>
            {getFieldDecorator("projectimageid", {
              initialValue: this.state.projectimageid,
            })(
              <React.Fragment>
                <div className="fileCardContainer">
                  {imageFiles &&
                    imageFiles.map((file, i) => (
                      <FileCard
                        file={file}
                        deleteImage={this.deleteImage}
                        pageType={
                          Services.LocationVariables.LOCATION_DELETE_DOC
                        }
                      />
                    ))}
                </div>
                {!this.state.isLoading && (
                  // <UploadImage handleImage={this.handleImage} />
                  // <MultipleSelectImage
                  //   maxLength={2}
                  //   handleImageIDS={this.handleImageIDS}
                  // />

                  <MultipleUpload
                    multiple={true}
                    existingData={this.state.fileList || []}
                    type={AttachmentType?.locationPicture}
                    uploadedAttachement={(e) => this.handleImageIDS(e)}
                    refreshData={() => this.getLocationDetails()}
                  />
                )}
              </React.Fragment>
            )}
          </Form.Item>
        </Row>
        <LocationConfirmModel
          isModalVisible={this.state.isModalVisible}
          closeModal={this.closeModal}
          submitData={this.submitData}
          confirmedLocationName={this.state.confirmedLocationName}
          // characterName={this.props.match.params.characterName}
        />
        {this.state.showMap && (
          <Map
            lat={this.state.lat}
            lng={this.state.lng}
            onChange={(e) => {
              console.log(e);
              this.setState({
                zipCode: e?.postalCode,
                country: e?.country,
                state: e?.state,
                city: e.city,
                addressLine: e?.address,
                showMap: false,
                longitude: e?.longitude,
                latitude: e?.latitude,
                lng: e?.longitude,
                lat: e?.latitude,
              });
            }}
            onClose={() => {
              this.setState({
                showMap: false,
              });
            }}
          />
        )}
      </div>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const AddLocation = Form.create()(AddLocationPage);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Location", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
