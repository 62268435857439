import axios from "axios";
import { LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";


const getTechnicians = (data = {}) => {
  const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  return axios.get(
    `${getScriptAPIURL()}/project/${projectId}/technician`,
    {
      params: data,
    }
  );
};

const TechnicianService = { getTechnicians };

export default TechnicianService;
