import { isEmpty } from "lodash";

const Error_Messages = [
  {
    message: "INVALID_PAID_AMOUNT",
    response: "Paid Amount exceeds an Invoice Amount",
  },
  {
    message: "amount.exceed",
    response: "Please Check The Amount",
  },
  {
    message: "ALREADY_MAPPED",
    response: "This description has already given",
  },
  {
    message: "vendor.name.already.found",
    response: "Vendor Name Already Exist",
  },
  {
    message: "phone.number.already.exist",
    response: "Phone Number Already Exist",
  },
  {
    message: "phone.number.should.be.10.digits",
    response: "Phone Number Should be 10 digits",
  },
  {
    message: "email.already.exist",
    response: "Email Already Exist",
  },
  {
    message: "CHARACTER_ALREADY_EXISTS",
    response: "Character Already Exist",
  },
  {
    message: "INVALID_VENDOR_TYPE",
    response: "Select Vendor For Choosed Equipment Type",
  },
  {
    message: "work.date.exceed.compare.to.shoot.date",
    response: "Please check Work date with Shoot date",
  },
  {
    message: "SCHEDULE_DATE_SHOULD_NOT_BE_CHANGED_AFTER_SHOOT_DATE",
    response: "Schedule date shouldn't be changed after Shoot date",
  },
  {
    message: "equipment.already.mapped",
    response: "Equipment already mapped",
  },
  {
    message: "character.already.found",
    response: "Character Name already found",
  },
  {
    message: "SCENE_STILL_NOT_COMPLETED",
    response: "Scene still not completed",
  },
  {
    message: "phone.number.already.exist",
    response: "Phone number already exist",
  },
  {
    message: "ATLEAST_ONE_SCENE_SHOULD_BE_PRESENT",
    response: "Atleast one scene should be present",
  },
  {
    message: "GST_ALREADY_EXIST",
    response: "Gst Already exist",
  },
  {
    message: "PAN_ALREADY_EXIST",
    response: "Pan Already exist",
  },
  {
    message: "quantity.should.not.be.null",
    response: "Quantity is Mandatory",
  },
  {
    message: "access.denied",
    response: "Access Denied",
  },
  {
    message: "REPORT_ALREADY_EXIST",
    response: "Report name must not be same",
  },
  {
    message: "PAYEE_CANT_RECEIVE_MORE_THAN_AMOUNT_LIMIT",
    response: "Payee cant receive more that amount limit",
  },
  {
    message: "INVALID_SHOOT_DATE",
    response: "Please check the shootdate",
  },
  {
    message: "PAID_AMOUNT_SHOULD_NOT_EXCEED_ABOVE_RUMUNERATION",
    response: "Paid amount should not exceed renumeration",
  },
  {
    message: "TDS_CANT_SET_WITHOUT_GST",
    response: "TDS cant set without GST",
  },
  {
    message: "SCENE_ALREADY_SCHEDULED_WITH_DATE",
    response: "Scene already scheduled with date",
  },
  {
    message: "schedule.shoot.date.not.found",
    response: "Schedule shoot date not found.",
  },
  {
    message: "ACCESS_DENIED",
    response: "Access denied contact your Project Admin!!",
  },
  {
    message: "CATEGORY_ALREADY_EXIST",
    response: "Category already exist.",
  },
  {
    message: "DATA_NOT_FOUND",
    response: "Data not found.",
  },
  {
    message: "art.set.already.found",
    response: "Artset already found!",
  },
  {
    message: "INVALID_PAYEE",
    response: "Please check Payee",
  },
  {
    message: "location.name.type.images.are.mandatory",
    response: "Location Name, Location Type and Images are mandatory",
  },
  {
    message: "AMOUNT_EXCEED",
    response: "Amount Exceeded",
  },
];

export const handleError = (message) => {
  if (message) {
    const filteredError = Error_Messages.filter(
      (error) =>
        error.message.toLowerCase() === message?.message?.trim().toLowerCase()
    );
    const errorObj = Object.assign({}, ...filteredError);
    return !isEmpty(filteredError) ? errorObj.response : "Something went wrong";
  } else {
    return "Something went wrong";
  }
};
