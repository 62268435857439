import React, { Component, Fragment } from "react";
import { Button, Modal } from "antd";
import Uploaddocumentfile from "components/uploaddocumentfile";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
} from "constants/AppConstants";
import UploadDocumentComponent from "components/shared/UploadDocumentAndDisplay/UploadDocumentComponent";
import { showActionMessage, showNotifications } from "./NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import EquipmentProjectService from "services/EquipmentProjectService";
import DisplayDocumentComponent from "./UploadDocumentAndDisplay/DisplayDocumentComponent";
import DocumentDelete from "services/DocumentDelete";
import Accounting from "services/AccountingServices";
import moment from "moment";

class SharedDocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      data: props.data,
      fileList: props.data.documents || [],
      productionId: props.data.productionPaymentId,
      category: props.category,
      needDataBack: props.needDataBack || false,
    };
    this._equipmentProjectService = new EquipmentProjectService();
    this.account = new Accounting();
    this._docDelete = new DocumentDelete();
  }

  fetchData = () => {
    const { productionId } = this.state;
    this.account
      .services(
        Services.AccountingVariables.GET_PRODUCTION_DATE_MORE_BY_ID,
        productionId
      )
      .then((res) => {
        this.setState({
          fileList: res.data.documents,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showModal = () => {
    this.fetchData();
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleDelete = (id) => {
    const { productionId } = this.state;
    this._docDelete
      .deleteService(
        Services.AccountingVariables.DELETE_PRE_POST_PRODUCTION_IMAGE_OLD,
        productionId,
        null,
        id
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  handleDocument = (files) => {
    const { data } = this.state;
    let newData = [];

    files.forEach((file) => {
      const { fileType, attachmentId } = file;
      newData.push({
        attachmentId,
        fileType,
      });
    });
    data.documents = newData;
    if (this.state.needDataBack) {
      this.setState({
        isModalVisible: false,
      });
      return this.props.callback(data);
    }
    this.handleUpdate(data);
  };

  handleUpdate = (record) => {
    const { category } = this.state;
    record.projectId = parseInt(
      localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    );
    record = {
      ...record,
      category,
      paymentDate: moment(
        record.paymentDate,
        GlobalConstants.DD_MM_YYYY,
        true
      ).isValid()
        ? record.paymentDate
        : moment(record.paymentDate, "DD-MM-YYYY")
            .utc()
            .format(GlobalConstants.DD_MM_YYYY),
      paymentMode: record?.paymentMode?.toUpperCase() || null,
      paymentType: record?.paymentType?.toUpperCase() || null,
      paymentId: record.productionPaymentId,
    };
    record.isAdvance = false;
    if (window.location.href.includes("advance")) {
      record.isAdvance = true;
      delete record.shootDate;
    }
    if (record?.shootDate === "Invalid date") record.shootDate = null;
    this.account
      .services(Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE, record)
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.DOCUMENT_UPLOADED_SUCCESSFULLY
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  render() {
    const { isModalVisible, fileList } = this.state;
    const { data, refreshData, iconButton, buttonType, disabled } = this.props;
    return (
      <Fragment>
        <Button
          className="ant-btn ant-btn-link ant-btn-icon-only"
          type={buttonType || "ghost"}
          onClick={this.showModal}
          icon={`${iconButton ? "book" : ""}`}
          disabled={disabled}
        >
          {!iconButton ? "Documents" : ""}
        </Button>
        <Modal
          title="Documents"
          visible={isModalVisible}
          onOk={this.handleOk}
          // onCancel={this.handleCancel}
          className="createModal no-x"
          footer={null}
          destroyOnClose
          width={1000}
        >
          <DisplayDocumentComponent
            handleDelete={this.handleDelete}
            files={fileList}
          />
          <UploadDocumentComponent
            handleDocument={this.handleDocument}
            handleCancel={this.handleCancel}
          />
        </Modal>
      </Fragment>
    );
  }
}
export default SharedDocumentUpload;
