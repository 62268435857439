import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Radio,
  Table,
  Select,
  Form,
  Icon,
} from "antd";
import TooltipIcon from "components/shared/TooltipIcon";
import { Link } from "react-router-dom";
import MakeupTabService from "services/SceneOverviewService/MakeupTabService";
import VfxService from "services/VfxService";
import GlobalService from "services/GlobalService";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import {
  checkFor,
  amountOnly,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { connect } from "react-redux";
import {
  GlobalConstants,
  Services,
  LocalStorage,
  Notifications,
} from "constants/AppConstants";
import DeleteComponent from "components/shared/DeleteComponent";
import RecordNotFound from "components/shared/RecordNotFound";

import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import VFXDocumentUpload from "./VFXDocumentUpload";

const { TextArea } = Input;
const plainOptions = ["2D", "3D"];

const { Option } = Select;

class VFXTab extends Component {
  constructor(props) {
    super(props);
    this._makeupService = new MakeupTabService();
    this._vfxService = new VfxService();
    this._globalService = new GlobalService();

    this.state = {
      vfxDescription: "",
      dataSource: [],
      totalRecords: [],
      count: 0,
      isLoading: true,
      requirementList: [],
      paymentTypeList: [],
      activeSceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
      activeDescription: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handleDescription = (description) => {
    const { activeDescription, activeSceneId } = this.state;
    if (activeDescription !== description) {
      this._vfxService
        .vfxService(
          Services.VfxVariables.UPDATE_VFX_DESCRIPTION,
          activeSceneId,
          description
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    }
  };

  fetchData = () => {
    const { activeSceneId } = this.state;
    let dataSource = [];
    this._vfxService
      .vfxService(Services.VfxVariables.GET_VFX_TAB_LIST, activeSceneId)
      .then((res) => {
        res.data.vfxDetails &&
          res.data.vfxDetails.map((data, i) => {
            dataSource.push({
              ...data,
              key: i,
              vfxRequirementString: data.vfxRequirement,
            });
          });
        this.setState(
          {
            dataSource,
            totalRecords: dataSource,
            isLoading: false,
            vfxDescription: res.data.vfxDescription,
            count: res.data.vfxDetails?.length,
          }
          // () => this.state.dataSource.length === 0 && this.handleAdd()
        );
      });

    this._vfxService
      .vfxService(
        Services.VfxVariables.GET_REQUIREMENT_LIST_DD,
        this.props.activeSceneId
      )
      .then((res) => {
        this.setState({
          requirementList: res.data || [],
        });
      });

    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "VFX_PAYMENT_TYPE"
      )
      .then((res) => {
        this.setState({
          paymentTypeList: res.data || [],
        });
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  handleUpdate = (row) => {
    const { vfxDetailId } = row;
    const data = { ...row };
    delete data.key;
    delete data.vfxRequirementString;
    this._vfxService
      .vfxService(Services.VfxVariables.UPDATE_VFX, vfxDetailId, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSubmit = (row) => {
    const { activeSceneId } = this.state;
    const data = { ...row };
    delete data.key;
    delete data.vfxRequirementString;
    this._vfxService
      .vfxService(Services.VfxVariables.SAVE_VFX, activeSceneId, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    if (row.vfxDetailId) {
      if (
        row.description &&
        // row.estimationAmount &&
        // row.paymentType &&
        row.quantity &&
        (row.vfxRequirement || row.vfxRequirementId)
      ) {
        this.handleUpdate(row);
      }
    } else {
      if (
        row.description &&
        // row.estimationAmount &&
        // row.paymentType &&
        row.quantity &&
        (row.vfxRequirement || row.vfxRequirementId)
      ) {
        this.handleSubmit(row);
      }
    }
    this.setState({ dataSource: newData });
  };

  onChange(value, record, type) {
    record[type] = value;
    if (type === "vfxRequirementId") {
      record.vfxRequirementString = value;
      record.vfxRequirement = null;
    }
    this.handleSave(record, value, type);
  }

  handleRequirement = (requirementValue, record) => {
    if (requirementValue && requirementValue.trim().length > 0) {
      this.setState({
        requirementValue,
      });
      record.vfxRequirementId = null;
    }
    this.setState({
      isOnChanged: false,
    });
  };

  setRequirement = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = ReplaceWithSpace(this.state.requirementValue);
      if (newData) {
        this.setState({ eventType });
        if (!record.vfxRequirementId) {
          record.vfxRequirementString = newData;
          record.vfxRequirement = newData;
        }
        this.onChange(newData, record, "vfxRequirement");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      description: null,
      // estimationAmount: null,
      // paymentType: null,
      quantity: null,
      vfxRequirement: null,
      vfxRequirementId: null,
      key: count,
      sceneNumber: null,
      vfxRequirementString: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, vfxDetailId }) => {
    if (vfxDetailId) {
      this._vfxService
        .vfxService(Services.VfxVariables.DELETE_VFX, vfxDetailId)
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  render() {
    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        key: "key",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "15%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            disabled={
              !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
            }
            onBlur={(e) => this.onChange(e.target.value, record, "description")}
            // onFocus={() => this.setActiveRecord(record)}
            autoFocus={!record.vfxRequirementId}
            style={{ width: "100%" }}
          />
        ),
      },
      // {
      //   title: "Cost",
      //   dataIndex: "estimationAmount",
      //   key: "estimationAmount",
      //   align: "right",
      //   width: "10%",
      //   className: "cash",
      //   render: (text, record) => (
      //     <Input
      //       type="number"
      //       className={`${!text ? "err" : ""}`}
      //       defaultValue={text}
      //       style={{ width: "100%" }}
      //       onBlur={e =>
      //         this.onChange(e.target.value, record, "estimationAmount")
      //       }
      //     />
      //   )
      // },
      // {
      //   title: "payment Type",
      //   dataIndex: "paymentType",
      //   key: "paymentType",
      //   align: "center",
      //   width: "10%",
      //   render: (text, record) => (
      //     <Select
      //       showSearch
      //       style={{ width: "100%" }}
      //       className={`${!text ? "err" : ""}`}
      //       placeholder="Select type"
      //       onChange={value => this.onChange(value, record, "paymentType")}
      //       defaultValue={text ? text : ""}
      //       // disabled={
      //       //   !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
      //       // }
      //     >
      //       {this.state.paymentTypeList &&
      //         this.state.paymentTypeList.map((option, i) => (
      //           <Option value={option.value}>{option.label}</Option>
      //         ))}
      //     </Select>
      //   )
      // },
      {
        title: "Requirement",
        dataIndex: "vfxRequirementString",
        key: "vfxRequirementString",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <Select
            showSearch
            style={{ width: "100%" }}
            className={`${!text ? "err" : ""}`}
            disabled={
              !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
            }
            placeholder="Select type"
            onSearch={(val) => this.handleRequirement(val, record)}
            onBlur={(value) => this.setRequirement(value, record, "blur")}
            onChange={(value) =>
              this.onChange(value, record, "vfxRequirementId")
            }
            value={text ? text : ""}
            // disabled={
            //   !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
            // }
          >
            {this.state.requirementList &&
              this.state.requirementList.map((option, i) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        align: "right",
        width: "5%",
        className: "cash",
        render: (text, record) => (
          <Input
            type="number"
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            // onFocus={() => this.setActiveRecord(record)}
            onBlur={(e) => this.onChange(e.target.value, record, "quantity")}
            style={{ width: "100%" }}
            disabled={
              !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
            }
          />
        ),
      },
      {
        title: "Documents",
        dataIndex: "vfxDocuments",
        key: "vfxDocuments",
        align: "center",
        width: "10%",
        render: (text, record) =>
          record.vfxDetailId && (
            <VFXDocumentUpload
              // key={index}
              disabled={
                !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
              }
              data={record}
            />
          ),
      },
      {
        title: "action",
        dataIndex: "action",
        align: "center",
        width: "5%",
        render: (text, record) => {
          return (
            checkFor(currentPermission, GlobalConstants.PERMISSION_DELETE) && (
              <DeleteComponent
                record={record}
                onClick={(record) => this.handleDelete(record)}
              />
            )
          );
        },
      },
    ];

    const { currentPermission } = this.props;
    const { vfxDescription, dataSource, isLoading, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    return (
      <>
        {checkFor(currentPermission, GlobalConstants.PERMISSION_VIEW) ? (
          <Row type="flex" justify="center" align="middle">
            <Col xl={22}>
              <Col xs={24}>
                <Form>
                  <Row type="flex" justify="center">
                    <Col xs={24}>
                      <Row style={{ paddingTop: 20 }}>
                        <Col
                          // lg={{ span: 11 }}
                          // md={{ span: 24 }}
                          // sm={{ span: 24 }}
                          xl={{ span: 24 }}
                        >
                          <Row className="mb-25 veritical-center">
                            <Col
                            // lg={{ span: 12 }}
                            // md={{ span: 12 }}
                            // xs={{ span: 12 }}
                            >
                              <h3 className="text-right font-600 m-0">
                                Vfx Description &nbsp;:
                              </h3>
                            </Col>

                            <Col xl={{ span: 20 }} style={{ paddingLeft: 10 }}>
                              <Form.Item className="m-0">
                                {!isLoading && (
                                  <TextArea
                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                    className="disabled"
                                    onFocus={(e) =>
                                      this.setState({
                                        activeDescription: e.target.value,
                                      })
                                    }
                                    defaultValue={vfxDescription}
                                    onBlur={(e) =>
                                      this.handleDescription(e.target.value)
                                    }
                                    disabled={
                                      !checkFor(
                                        currentPermission,
                                        GlobalConstants.PERMISSION_EDIT
                                      )
                                    }
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xl={24} style={{ paddingBottom: 10 }}>
                {checkFor(
                  currentPermission,
                  GlobalConstants.PERMISSION_ADD
                ) && (
                  <Button
                    onClick={this.handleAdd}
                    type="primary"
                    style={{ marginBottom: 5, float: "right" }}
                    disabled={hasNewRow}
                  >
                    <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                  </Button>
                )}
              </Col>
              <Col xs={24}>
                <Row type="flex" justify="center" align="middle">
                  <Col xl={24}>
                    <Table
                      bordered
                      rowClassName={
                        !checkFor(
                          currentPermission,
                          GlobalConstants.PERMISSION_EDIT
                        )
                          ? "cursor-notallowed"
                          : ""
                      }
                      columns={columns}
                      dataSource={dataSource}
                      pagination={false}
                      loading={isLoading}
                      locale={{
                        emptyText: !isLoading && <RecordNotFound />,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    currentPermission: getSPermissionFromMenus(PermissionPage.VFX, menus),
  };
};

export default connect(mapStateToProps)(VFXTab);
