import React, { Component, Fragment } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
  AutoComplete,
  Icon,
} from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import EquipmentServices from "services/EquipmentServices";
import EquipmentProjectService from "services/EquipmentProjectService";
import VendorListDD from "components/shared/VendorListDD";
import { handleError } from "utilities/ErrorHandler";
import AddButton from "components/shared/AddButton";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { connect } from "react-redux";
import { checkFor, Capitalize } from "utilities/ValidationHelper";
import EquipmentDocumentUpload from "components/shared/EquipmentDocumentUpload";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.dataIndex === "quantity") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    } else {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    }
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class StandardEquipment extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._equipmentService = new EquipmentServices();
    this._equipmentProjectService = new EquipmentProjectService();
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      count: 0,
      page: null,
      actorId: null,
      activeSceneId: null,
      equipmentDropDown: [],
      equipment: null,
      designationTypeValue: null,
      isDisableInput: false,
      totalRecords: [],
      equipmentTypeDropDown: [],
      // tableCount: 0
    };
  }

  componentDidMount() {
    this.getEquipmentDropDown();
    this.getEquipmentTypeDropDown();
    this.fetchData();
    this.getPaymentTypeData();
    // this.checkForEmpty(this.state.dataSource);
  }

  getEquipmentDropDown = () => {
    this._equipmentService
      .service(
        Services.EquipmentVariables.EQUIPMENT_DROPDOWN_LEFT,
        null,
        null,
        this.state.designationTypeValue
      )
      .then((res) => {
        this.setState({ equipmentDropDown: res.data.equipments });
      });
  };

  getEquipmentTypeDropDown = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EQUIPMENT_RESOURCE_TYPE
      )
      .then((res) => {
        this.setState({ equipmentTypeDropDown: res.data });
      });
  };

  getPaymentTypeData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data,
        });
      });
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  designationTypeValue(value) {
    if (value && value.trim().length > 0) {
      this.setState({
        designationTypeValue: value,
      });
    }
    this.getEquipmentDropDown();
  }

  designationBlurChanges(value, record, type) {
    this.getEquipmentDropDown();
    let designation = this.state.designationTypeValue;
    let a = this.state.equipmentDropDown || [];
    let b = a.map((a) => a.name);
    let dx = true;
    if (designation != null) {
      new Promise((resolve) => {
        b.forEach((x) => {
          if (x && x.toString().trim() != designation.toString().trim()) {
            dx = false;
          } else {
            dx = true;
          }
        });

        resolve();
      })
        .then(() => {
          if (!dx) {
            a.push({ label: designation, value: designation });
          }
        })
        .then(() => {
          this.setState({
            equipmentDropDown: a,
          });
        })
        .then(() => {
          if (!this.state.isDisableInput) {
            this.onChange(designation, record, type);
            record.equipment = a;
          }
          // this.setState({
          //   designationShow:designation
          // });
        });
    }
  }

  fetchData = () => {
    this.setState({
      count: 0,
      isLoading: true,
    });
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.GET_STANDARD_EQUIPMENT
      )
      .then((res) => {
        let dataSource = [];
        res.data.equipments &&
          res.data.equipments.map((datum, i) => {
            dataSource.push({ ...datum, key: i });
          });
        this.setState({
          dataSource,
          totalRecords: res.data.equipments ? res.data.equipments : [],
          isLoading: false,
          count: res?.data?.equipments?.length,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleSubmit = (record) => {
    record.equipmentType = "STANDARD";
    record.equipmentResourceType = record.equipmentResourceType;
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.POST_STANDARD_EQUIPMENT,
        record
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipments Created Successfully!!"
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.props.getPermissions();
      });
  };

  handleUpdate = (record) => {
    // const data = {
    //   equipment: record.equipment,
    //   paymentType: record.paymentType,
    //   modal: record.modal,
    //   price: record.price,
    //   equipmentType: "STANDARD",
    //   projectId: parseInt(localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID))
    // };
    record.equipmentType = "STANDARD";
    record.equipmentResourceType = record.equipmentResourceType;
    record.projectId = parseInt(
      localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    );
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.PUT_STANDARD_EQUIPMENT,
        record,
        record.scheduleEquipmentId
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.props.getPermissions();
      });
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        // this.checkForEmpty(this.state.dataSource);
      }
    );
    if (id) {
      this._equipmentProjectService
        .equipmentProjectService(
          Services.EquipmentVariables.DELETE_STANDARD_EQUIPMENT,
          null,
          id
        )
        .then((res) => {
          showNotifications(
            Notifications.SUCCESS,
            "Successfully",
            " Deleted !!"
          );
          this.fetchData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );

          this.props.getPermissions();
        });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      equipment: null,
      equipmentResourceType: null,
      equipmentResourceType: null,
      // price: null,
      // paymentType: null,
      model: null,
      quantity: null,
      vendorId: null,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
      // count:count + 1
    });
  };

  onChange(value, record, type, update) {
    if (value) {
      this.setState({ isDisableInput: true });
    }

    this.handleSave(record, value, type, update);

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type, update = true) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    let modifyItem = newData[index];
    if (value) {
      switch (type) {
        case "equipment":
          modifyItem.equipment = value;
          break;
        case "vendorId":
          modifyItem.vendorId = value;
          break;
        case "model":
          modifyItem.model = value;
          break;
        case "equipmentResourceType":
          modifyItem.equipmentResourceType = value;
          break;
        default:
          break;
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });

    if (!row.scheduleEquipmentId) {
      if (row.equipment && row.equipmentResourceType && update) {
        this.handleSubmit(row);
      }
    }
    if (row.scheduleEquipmentId) {
      if (row.equipment && row.equipmentResourceType && update) {
        this.handleUpdate(row);
      }
    }
  };

  render() {
    const { dataSource, totalRecords, isLoading } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const { activePermisision } = this.props;
    const column = [
      {
        title: "#",
        dataIndex: "sno",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Equipment Type",
        dataIndex: "equipmentResourceType",
        // align: "center",
        width: "25%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select
              showSearch
              onSearch={this.designationTypeValue}
              // onBlur={(value) =>
              //   this.designationBlurChanges(
              //     value,
              //     record,
              //     "equipmentResourceType"
              //   )
              // }
              value={record.equipmentResourceType}
              style={{ width: "100%" }}
              placeholder="Select Equipment"
              // disabled={record.scheduleEquipmentId}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.scheduleEquipmentId
              }
              defaultValue={text ? text : ""}
              onChange={(value) =>
                this.onChange(value, record, "equipmentResourceType")
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              className="caps"
            >
              {this.state.equipmentTypeDropDown &&
                this.state.equipmentTypeDropDown.map((option, i) => (
                  <Option value={option.value}>
                    {Capitalize(option.label)}
                  </Option>
                ))}
            </Select>
          ) : null,
      },
      {
        title: "Equipment",
        dataIndex: "equipment",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        width: "25%",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "15%",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        align: "center",
        className: "cash",
      },
      {
        title: "Vendors",
        dataIndex: "vendorId",
        width: "15%",
        align: "center",
        render: (text, record) => (
          <VendorListDD
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.scheduleEquipmentId
            }
            text={text}
            record={record}
            onChange={this.onChange}
          />
        ),
      },
      {
        title: "Documents",
        width: "10%",
        render: (text, record, index) =>
          record.scheduleEquipmentId && (
            <EquipmentDocumentUpload
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.scheduleEquipmentId
              }
              key={index}
              refreshData={this.fetchData}
              data={record}
            />
          ),
      },
    ];
    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      column.push({
        title: "",
        dataIndex: "operation",
        width: "8%",
        align: "center",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.scheduleEquipmentId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  {" "}
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null,
      });
    }
    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "model" ? "text" : "number",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <Fragment>
        <Row type="flex" align="middle" justify="center">
          <Col xl={24}>
            <Col xl={24} style={{ textAlign: "end" }}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
                <AddButton
                  text="Entry"
                  onClick={this.handleAdd}
                  type="primary"
                  style={{ marginBottom: 10 }}
                  disabled={hasNewRow}
                />
              )}
            </Col>
            <Col xs={24}>
              <Table
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                loading={isLoading}
                // scroll={{ y: "50vh" }}
                pagination={false}
              />
            </Col>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.EQUIPMENT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardEquipment);
