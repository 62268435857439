import React, { Component } from "react";
import { Form, Select, Button, Row, Col, Input, Icon, Tooltip } from "antd";
import { isEmpty, debounce } from "lodash";
import "antd/dist/antd.css";

import "./Filterform.scss";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import {
  Services,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import Axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SceneNoSelectedKeys: [],
      LocationSelectedKeys: [],
      episodeDropdown: [],
      episodeLoading: false,
      EnvironmentType: "",
      EnvironmentTime: "",
      Description: "",
      Episode: "",
      resetForms: false,
      isSearchActive: false,
      LocationDataSource: props.LocationDataSource,
      isWebSeries: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this._locationService = new LocationTabService();
    this.handleDesc = debounce(this.handleDesc, 300);
  }

  componentDidMount() {
    const projectData = JSON.parse(localStorage.getItem("projectData"));
    projectData?.projectTypeId === 5
      ? this.setState({ isWebSeries: true }, () => this.getEpisodeDropdown())
      : this.setState({ isWebSeries: false });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      LocationDataSource: nextProps.LocationDataSource,
    });
  }
  getLocation = () => {
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_LIST_BY_PROJECT
      )
      .then((res) => {
        this.setState({
          LocationDataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleReset = () => {
    this.props.handleClear();
    let p = new Promise((resolve) => {
      this.setState({
        resetForms: true,
      });
      resolve();
    });

    p.then(() => {
      this.setState({
        resetForms: false,
        isSearchActive: false,
        SceneNoSelectedKeys: [],
        LocationSelectedKeys: [],
        EnvironmentType: "",
        EnvironmentTime: "",
        Description: "",
        Episode: "",
      });
    });
  };

  getEpisodeDropdown = () => {
    this.setState({ episodeLoading: true });
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    Axios.get(
      `${getScriptAPIURL()}/project/${projectId}/episode?size=10&page=0&sort=episode.%20episodeNumber%2Casc`,
      config
    ).then((res) => {
      this.setState({
        episodeDropdown: res?.data?.content || [],
        episodeLoading: false,
      });
    });
  };

  checkHasValues = (
    SceneNoSelectedKeys,
    LocationSelectedKeys,
    EnvironmentTime,
    EnvironmentType,
    Description
  ) =>
    !isEmpty(SceneNoSelectedKeys) ||
    !isEmpty(LocationSelectedKeys) ||
    !isEmpty(EnvironmentTime) ||
    !isEmpty(EnvironmentType) ||
    Description;

  handleSubmit() {
    let SceneNoSelectedKeys = this.state.SceneNoSelectedKeys;
    let LocationSelectedKeys = this.state.LocationSelectedKeys;
    let EnvironmentTime = this.state.EnvironmentTime;
    let EnvironmentType = this.state.EnvironmentType;
    let Description = this.state.Description;
    let Episode = this.state?.Episode;
    this.props.handleSubmit(
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      Episode
    );
  }

  handleLocation = (SelectedKeys) => {
    const { LocationDataSource } = this.state;

    const LocationSelectedKeys = [];
    SelectedKeys.forEach((keyLocation) => {
      LocationDataSource.filter((location) => {
        if (location.label === keyLocation) {
          LocationSelectedKeys.push(location.value);
        }
      });
    });

    this.setState(
      {
        LocationSelectedKeys,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );
  };

  handleScenes = (SceneNoSelectedKeys) =>
    this.setState(
      {
        SceneNoSelectedKeys,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );

  handleEnv = (EnvironmentType) =>
    this.setState(
      {
        EnvironmentType,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );

  handleDesc = (Description) => {
    this.setState(
      {
        Description,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );
  };

  handleEpisode = (Episode) => {
    this.setState(
      {
        Episode,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );
  };

  handleEnvTime = (EnvironmentTime) =>
    this.setState(
      {
        EnvironmentTime,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );

  render() {
    const { form } = this.props;
    const { resetForms } = this.state;
    const { Option } = Select;
    return (
      <Form className="search-form">
        {!resetForms && (
          <>
            <Row gutter={8} style={{ margin: 0 }}>
              <Col span={8}>
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  placeholder="Scene no"
                  style={{ width: "100%" }}
                  // onBlur={this.handleSubmit}
                  onChange={this.handleScenes}
                >
                  {this.props.sceneNumberDataSource &&
                    this.props.sceneNumberDataSource.map((option) => (
                      <Option value={option}>{option}</Option>
                    ))}
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={5}
                  placeholder="Location"
                  // onBlur={this.handleSubmit}
                  onChange={this.handleLocation}
                >
                  {this.state.LocationDataSource &&
                    this.state.LocationDataSource.map((option) => (
                      <Option value={option.label}>{option.label}</Option>
                    ))}
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={2}
                  name="EnvironmentType"
                  placeholder="INT/EXT"
                  allowClear
                  // onBlur={this.handleSubmit}
                  onChange={this.handleEnv}
                >
                  {this.props.environment &&
                    this.props.environment.map((a, i) => (
                      <Option value={a.value}>{a.label}</Option>
                    ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={8} style={{ margin: 0, marginTop: 10 }}>
              <Col span={8}>
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={2}
                  name="EnvironmentType"
                  // name="EnvironmentTime"
                  placeholder="DAY/NIGHT"
                  allowClear
                  // onBlur={this.handleSubmit}
                  onChange={this.handleEnvTime}
                >
                  {this.props.time &&
                    this.props.time.map((a, i) => (
                      <Option value={a.value}>{a.value}</Option>
                    ))}
                </Select>
              </Col>
              <Col span={8}>
                <Input
                  name="Description"
                  allowClear
                  placeholder="Description"
                  onChange={(e) => this.handleDesc(e.target.value)}
                />
              </Col>
              {this.state?.isWebSeries && (
                <Col span={6}>
                  <Select
                    loading={this.state.episodeLoading}
                    placeholder="Episode"
                    allowClear
                    onChange={this.handleEpisode}
                  >
                    {this.state.episodeDropdown.length &&
                      this.state.episodeDropdown?.map((e) => (
                        <Option value={e?.id}>{e?.episodeNumber}</Option>
                      ))}
                  </Select>
                </Col>
              )}
              <Col span={2}>
                <Tooltip
                  title={GlobalConstants.RESET}
                  placement={GlobalConstants.TOP}
                >
                  <Button
                    onClick={this.handleReset}
                    style={{ position: "relative", width: "100%" }}
                    type="primary"
                  >
                    <Icon type="reload" style={{ fontSize: "15px" }} />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }
}
export default Form.create()(FilterForm);
