import React, { Component } from "react";
import "antd/dist/antd.css";
import { Upload, Button, Icon, message } from "antd";
import GlobalService from "services/GlobalService";
import { AttachmentType, ImageDomain, Services } from "constants/AppConstants";
import UploadService from "services/uploadService";

class Uploaddocumentfile extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
  }

  state = {
    fileList: null,
    pdfUpload: false,
  };

  handleFile = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    var imageFile = new FormData();
    imageFile.append("file", info.file);

    UploadService.projectImageUpload(ImageDomain?.aceScript, imageFile, {
      attachmentType: this.props.type || AttachmentType?.projectPicture,
      isBucketUrlRequired: true,
    }).then((res) => {
      this.setState({
        imageUrl: null,
      });
      this.props.handleDocument(res?.data?.id, this.props.keys);
    });
  };

  beforeUpload = (file) => {
    let isJPG =
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png";
    if (isJPG) {
      this.setState({
        pdfUpload: true,
      });
    }
    if (!isJPG) {
      message.error("You can only upload PDF , DOC and DOCX file !");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Document must smaller than 5MB!");
    }
    return !isJPG || !isLt2M;
  };

  handleChange = (info) => {
    if (info.fileList.length !== 0) {
      let fileList = [...info.fileList];
      if (!this.state.fileList && fileList) {
        this.setState({
          fileList,
        });
        this.handleFile(info);
      }
    }
  };

  render() {
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      // multiple: true
    };
    return (
      <Upload
        accept={this.props.type !== "contract" ? ".doc,.docx,.pdf" : ""}
        className="customUpload"
        beforeUpload={this.beforeUpload}
        onChange={
          !this.state.pdfUpload === true
            ? (info) => this.handleChange(info)
            : null
        }
      >
        <Button>
          <Icon type="upload" />
          {!this.props.iconOnly && this.props.type === "contract"
            ? "Upload Attachment"
            : "Upload Documents"}
        </Button>
      </Upload>
    );
  }
}

export default Uploaddocumentfile;
