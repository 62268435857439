import { GlobalConstants, LocalStorage, Screens } from "constants/AppConstants";
import React, { Component } from "react";
import { connect } from "react-redux";

import Slider from "react-slick";
import { setActiveTab, setActiveSceneId } from "store/action";
import TooltipComponent from "../TooltipComponent";

class PaginationComponent extends Component {
  state = {
    activeItem: 5,
    prevIndex: null,
    total: 60,
    scenes: null,
    activeSceneId: null,
  };

  componentWillReceiveProps(nextProps) {
    // this.goto();
    if (nextProps.allScenes) {
      if (this.state.activeSceneId === null) {
        this.setState(
          {
            scenes: nextProps.allScenes,
            activeSceneId: nextProps.activeSceneId,
          },
          () => this.goto()
        );
      } else {
        this.setState({
          scenes: nextProps.allScenes,
        });
      }
    }
  }

  goto = () => {
    if (this.state.scenes) {
      let findIndex = this.state.scenes.findIndex(
        (scene) =>
          scene.sceneId.toString() === this.props.activeSceneId.toString()
      );
      this.slider.slickGoTo(findIndex);
    }
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     scenes: nextProps.allScenes
  //   };
  // }

  toolTip = (sceneNumber, sceneDescription) => {
    return (
      <TooltipComponent
        sceneNumber={sceneNumber}
        text={sceneDescription}
        placement="top"
      />
    );
  };

  renderItems = () => {
    const { activeItem, scenes } = this.state;
    const { activeSceneId } = this.props;
    scenes && scenes.sort((a, b) => a.sceneOrder - b.sceneOrder);

    let template = [];
    scenes &&
      scenes.map((scene, i) => {
        return template.push(
          <div className="sample paginationComponent" key={i}>
            <div
              style={{ fontSize: 12 }}
              className={`textArea ${
                scene.sceneId.toString() === activeSceneId.toString()
                  ? "active-new"
                  : ""
              }`}
              onClick={() => this.props.setActiveSceneId(scene.sceneId)}
            >
              {this.toolTip(scene.sceneNumber, scene.sceneDescription)}
            </div>
          </div>
        );
      });
    return template;
  };
  render() {
    const { scenes } = this.state;
    const settings = {
      dots: false,
      // infinite: true,
      speed: 500,
      slidesToShow: scenes && (scenes.length > 15 ? 15 : scenes.length),
      slidesToScroll: 5,
      afterChange: this.afterChange,
      beforeChange: this.beforeChange,
      swipeToSlide: true,
      // focusOnSelect: true,
    };
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          {this.renderItems()}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = ({ scene }) => {
  return {
    allScenes: scene.scenes,
    activeSceneId: scene.activeSceneId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setActiveSceneId: (activeTabId) => dispatch(setActiveSceneId(activeTabId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginationComponent);
