import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Modal,
  Tooltip,
  Icon,
} from "antd";
import FileCard from "components/shared/FileCardComponent";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { AttachmentType, GlobalConstants } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { Services, Notifications } from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { connect } from "react-redux";
import * as _ from "lodash";
import { getSPermissionFromMenus } from "store/stateHelper";
import BudjetService from "services/BudjetService";
import Uploaddocumentfile from "components/uploaddocumentfile";
import RecordNotFound from "components/shared/RecordNotFound";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getPermissions } from "store/thunk-actions";
import { checkFor } from "utilities/ValidationHelper";

const { Option } = Select;
const { confirm } = Modal;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^(0|[0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              type="number"
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class Travellist extends Component {
  getArtSetType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_DETAIL_TYPE
      )
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  handleSeperateImage = (record) => {
    let temp = [];
    record.documents &&
      record.documents.map((record) => {
        temp.push({
          fileId: record.fileId,
          documentId: record.documentId,
          fileType: GlobalConstants.DOCUMENT,
        });
      });
    return temp;
  };

  constructor(props) {
    super(props);
    this._budgetService = new BudjetService();
    this._globalService = new GlobalService();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      planId: props.match.params.id,
      expenseTypeData: [],
    };
  }

  splitBasedOnFileTypes = (files) => {
    let tempImageFiles = [];
    let files1 = files.filter((file) => {
      if (file.images !== undefined) {
        tempImageFiles.push(...file.images);
      }
    });
    let imageFiles =
      tempImageFiles &&
      tempImageFiles.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      imageFiles,
    });
  };

  componentDidMount() {
    if (this.state.planId) {
      this.fetchData(this.state.planId);
      this.fetchExpenseType();
    }
  }

  fetchExpenseType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EXPENSE_TYPE
      )
      .then((res) => {
        this.setState({
          expenseTypeData: res.data,
        });
      });
  };

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  fetchData = (id) => {
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_PROJECT_PLAN_BY_CATEGORY,
        "",
        id
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.projectPlanningCategories || [],
          totalRecords: res.data.projectPlanningCategories || [],
          isLoading: true,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: true,
        });
      });
  };

  handleDocument = (id, data) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { attachmentId: id, fileType: GlobalConstants.DOCUMENT },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ attachmentId: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState(
        {
          fileList: temp,
        },
        () => this.handleSave(data, this.state.fileList, "documents")
      );
    }
  };

  deleteDoc = (id) => {
    this.fetchData(this.state.planId);
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteDocIndex = this.state.docFiles.findIndex(
      (a) => a.documentId === id
    );

    let docFiles = this.state.docFiles;
    let fileList = this.state.fileList;
    docFiles.splice(deleteDocIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      docFiles,
      fileList,
    });
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];

    this._budgetService
      .budjetService(
        Services.BudjetVariables.DELETE_PROJECT_PLAN_BY_CATEGORY,
        "",
        id
      )
      .then((res) => {
        this.fetchData(this.state.planId);
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
      })
      .catch((err) => {
        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].message,
        );
      });
  };

  handleSubmit = (record) => {
    this.setState({
      isLoading: true,
    });
    const data = {
      description: record.description,
      documents: record.documents,
      expenseType: record.expenseType,
      quantity: record.quantity,
      rate: record.rate,
      projectPlanningId: this.state.planId,
    };
    this._budgetService
      .budjetService(
        Services.BudjetVariables.CREATE_PROJECT_PLAN_BY_CATEGORY,
        data,
        this.state.planId
      )
      .then((res) => {
        this.fetchData(this.state.planId);
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "New Project plan detail created"
        );
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].message,
        );
      });
  };

  handleUpdate = (record) => {
    this.setState({
      isLoading: true,
    });
    let documents = [];
    if (record.documents) {
      record.documents.map((list) => {
        if (!list.documentId) {
          return documents.push(list);
        }
      });
    }
    const data = {
      description: record.description,
      documents: record.documents,
      expenseType: record.expenseType.toUpperCase(),
      quantity: record.quantity,
      rate: record.rate,
      projectPlanningId: this.state.planId,
    };
    this._budgetService
      .budjetService(
        Services.BudjetVariables.UPDATE_PROJECT_PLAN_BY_CATEGORY,
        data,
        record.projectPlanningCategoryId
      )
      .then((res) => {
        this.fetchData(this.state.planId);
        this.setState({
          isLoading: false,
          fileuploadid: null,
          fileList: null,
        });
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Success",
        //   "Updated Successfully"
        // );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
      })
      .catch((err) => {
        this.props.getPermissions();
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].message,
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      expenseType: null,
      quantity: "",
      rate: "",
      total: "",
      documents: [],
    };
    this.setState(
      {
        dataSource: [...dataSource, newData],
      },
      () => {
        console.log(this.state.dataSource);
      }
    );
  };

  onChange(value, record, type) {
    this.handleSave(record, value, type);
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const that = this;
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    let modifyItem = newData[index];
    if (value) {
      switch (type) {
        case "expenseType":
          modifyItem.expenseType = value;
          break;
        case "documents":
          // if (row.documents) {
          //   row.documents = [...row.documents, ...value];
          // } else {
          modifyItem.documents = value;
          // }
          break;
        default:
          break;
      }
    }

    if (!row.projectPlanningCategoryId) {
      if (row.description && row.quantity && row.rate) {
        this.handleSubmit(row);
      }
    } else if (row.projectPlanningCategoryId) {
      if (modifyItem.documents) {
        row.documents = modifyItem.documents;
        this.handleUpdate(row);
      } else {
        this.handleUpdate(row);
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const { activePermisision } = this.props;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const column = [
      {
        title: "Description",
        dataIndex: "description",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        width: "20%",
        className: "caps",
        align: "center",
      },
      {
        title: "Expense Type",
        dataIndex: "expenseType",
        align: "center",
        width: "20%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            className="border-none"
            placeholder="Select type"
            onChange={(value) => this.onChange(value, record, "expenseType")}
            defaultValue={text ? text : ""}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
            }
          >
            {this.state.expenseTypeData &&
              this.state.expenseTypeData.map((option, i) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        width: "10%",
        align: "center",
        className: "cash",
      },
      {
        title: "Rate Per Unit",
        dataIndex: "rate",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        width: "12%",
        align: "center",
        className: "cash",
      },
      {
        title: "Total",
        dataIndex: "total",
        // editable: true,
        width: "10%",
        align: "center",
        className: "cash",
        render: (text) => text && <span className="flrt">{text}</span>,
      },
      {
        title: "Documents",
        dataIndex: "documents",
        align: "center",
        width: "23%",
        render: (text, record) => {
          return (
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <React.Fragment>
                <div className="fileCardContainer">
                  {record?.documents?.map((file, i) => (
                    <FileCard
                      isImageOnly
                      file={file}
                      deleteDoc={this.deleteDoc}
                      projectPlanningCategoryId={
                        record.projectPlanningCategoryId
                      }
                      pageType={
                        Services.BudjetVariables.DELETE_TRAVEL_LODEGING_DOCUMENT
                      }
                    />
                  ))}
                </div>
                {this.state.isLoading && (
                  <Uploaddocumentfile
                    keys={record}
                    iconOnly
                    type={AttachmentType?.projectPlanningDocument}
                    handleDocument={this.handleDocument}
                  />
                )}
              </React.Fragment>
            )
          );
        },
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      column.push({
        title: "Action",
        width: "30%",
        align: "center",
        render: (text, record) => (
          <React.Fragment>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() =>
                this.handleDelete(record.key, record.projectPlanningCategoryId)
              }
            >
              <Tooltip
                title={GlobalConstants.DEL}
                placement={GlobalConstants.RIGHT}
              >
                <Button icon="delete" type="link" />
              </Tooltip>
            </Popconfirm>
          </React.Fragment>
        ),
      });
    }

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex !== "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xl={24}>
          <Title hasBack {...this.props}>
            <TitleText>Project Plan Details</TitleText>
          </Title>
          <br />
        </Col>
        <Col
          xs={24}
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Row>
            <Col xs={24}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
                <Button
                  onClick={this.handleAdd}
                  type="primary"
                  style={{ marginBottom: 16, float: "right" }}
                  disabled={hasNewRow}
                >
                  <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                </Button>
              )}
            </Col>
            <Col xs={24}>
              <Table
                className="art-set"
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{
                  emptyText: isLoading && <RecordNotFound />,
                }}
                loading={!isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({
  scene,
  activeItems: { activeMenuPermissions },
  user: { menus },
}) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus(PermissionPage.ART_SET, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Travellist);
