import React, { Component } from "react";

import { Icons, Services, LocalStorage, Screens } from "constants/AppConstants";
import IconComponent from "components/shared/IconComponent";
import NotificationServices from "services/NotificationServices";
import {
  NotificationTitleConstants,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { StartCase } from "utilities/ValidationHelper";
import { connect } from "react-redux";
import { setActiveTab, setActiveSceneId } from "store/action";
import ThemeColors from "constants/ThemeConstants/variables";
import Loader from "components/shared/Loader";

const data = [
  {
    title: "Map Character with Scenes",
    count: 10,
    image: Icons.SCRIPTS_BREAKDOWN,
  },
  {
    title: "Confirm the Actual Location",
    count: 15,
    image: Icons.LOCATION,
  },
  {
    title: "Map Equipments with Vendor",
    count: 3,
    image: Icons.EQUIPMENTS,
  },
  {
    title: "Actor Availability Warnings",
    count: 20,
    image: Icons.ACTORS,
  },
  {
    title: "Unused Equipments",
    count: 12,
    image: Icons.EQUIPMENTS,
  },
];

class NotificationContentComponent extends Component {
  constructor(props) {
    super(props);

    this.notificationServices = new NotificationServices();
    this.state = {
      Notifications: null,
      isLoading: true,
    };
  }

  fetchNotifications = () => {
    this.notificationServices
      .service(Services.NotificationVariables.GET_NOTIFICATIONS)
      .then((res) => {
        console.clear();
        console.log(res.data.notifications);
        this.setState({
          Notifications: res.data.notifications,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          Notifications: null,
        });
      });
  };

  componentDidMount() {
    this.fetchNotifications();
  }

  checkForRedirect = (redirectTo) => {
    switch (redirectTo) {
      case Screens.SONGS:
        this.props.setActiveTab("8");
        break;
      case Screens.STUNTS:
        this.props.setActiveTab("9");
        break;
      case Screens.COSTUMES:
        this.props.setActiveTab("5");
        break;
      case Screens.MAKEUP:
        this.props.setActiveTab("6");
        break;
      case Screens.ACTOR:
        this.props.setActiveTab("2");
        break;
      case Screens.LOCATION:
        this.props.setActiveTab("3");
        break;
      case Screens.ART_SET:
        this.props.setActiveTab("4");
        break;
      case Screens.EQUIPMENTS:
        this.props.setActiveTab("11");
        break;
      case Screens.VFX:
        this.props.setActiveTab("7");
        break;
      default:
        break;
    }
  };

  handleClick = (value, type, name, charType, activeTab) => {
    this.checkForRedirect(activeTab);
    const { history, setActiveTab, toggleNotificationBar, setActiveSceneId } =
      this.props;
    toggleNotificationBar();
    switch (type) {
      case GlobalConstants.SCENE_WITH_NO_CHAR_MAP: {
        history.push("/project/screen-overview");
        localStorage.setItem(GlobalConstants.REDIRECT_TO, activeTab);
        localStorage.setItem(LocalStorage.ACTIVE_SCENE_ID, value);
        setActiveSceneId(value);
        this.checkForRedirect(activeTab);
        break;
      }
      case GlobalConstants.LOCATION_WITHOUT_ACTUAL: {
        history.push(
          `/project/location-audition/${value.sceneLocationName}/${value.sceneLocationId}`
        );
        break;
      }
      case GlobalConstants.ACTOR_AVAILABILITY: {
        history.push(
          `/project/actorprofile/${value.actorId}/${value.actorName}/${value.characterType}/${value.characterId}`
        );
        break;
      }
      case GlobalConstants.LOCATION_AVAILABILITY: {
        history.push(
          `/project/update-location/${value.locationId}/${value.locationName}/${value.sceneLocationName}/${value.sceneLocationId}`
        );
        break;
      }
      case GlobalConstants.CHARACTERS_WITHOUT_CONFIRM: {
        history.push(
          `/project/audition/${value.characterType}/${value.characterId}/${value.characterName}`
        );
        break;
      }
      case GlobalConstants.EQUIPMENTS_WITHOUT_MAP: {
        history.push("/project/equipments");
        localStorage.setItem(GlobalConstants.FROM_DASHBOARD, true);
        break;
      }
    }
  };

  renderNames = (data, isActor = false) => {
    let type = isActor
      ? GlobalConstants.ACTOR_AVAILABILITY
      : GlobalConstants.LOCATION_AVAILABILITY;
    let template = [];
    data &&
      data.map((datum, i) =>
        template.push(
          <span key={i} onClick={() => this.handleClick(datum, type)}>
            {StartCase(datum.locationName) || StartCase(datum.actorName)}
          </span>
        )
      );

    return <p className="notificationCommaContainer">{template}</p>;
  };

  renderText = (type, data) => {
    let singular, text;
    switch (type) {
      case NotificationTitleConstants.MAP_CHARACTER:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.ACTOR
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Character.
          </>
        );
      case NotificationTitleConstants.ARTSET_ALERTS:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.ART_SET
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Art Set.
          </>
        );
      case NotificationTitleConstants.COSTUME_ALERTS:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.COSTUMES
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Costume.
          </>
        );
      case NotificationTitleConstants.MAKEUP_ALERTS:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.MAKEUP
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Makeup.
          </>
        );
      case NotificationTitleConstants.EQUIPMENT_ALERTS:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.EQUIPMENTS
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Equipment.
          </>
        );
      case NotificationTitleConstants.SONG_ALERTS:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.SONGS
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Song.
          </>
        );
      case NotificationTitleConstants.STUNT_ALERTS:
        return (
          <>
            Scene{" "}
            <a
              onClick={() =>
                this.handleClick(
                  data.sceneId,
                  GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
                  null,
                  null,
                  Screens.STUNTS
                )
              }
            >
              <strong>{data.sceneNumber}</strong>
            </a>{" "}
            has no Stunt.
          </>
        );
      case NotificationTitleConstants.CONFIRM_ACTOR:
        return (
          <>
            <a
              onClick={() =>
                this.handleClick(
                  data,
                  GlobalConstants.CHARACTERS_WITHOUT_CONFIRM,
                  null,
                  null,
                  Screens.STUNTS
                )
              }
            >
              <strong>{StartCase(data.characterName)}</strong>(
              {data.characterType})
            </a>{" "}
            is not confirmed.
          </>
        );
      case NotificationTitleConstants.CONFIRM_ACTUAL_LOCATION:
        return (
          <>
            <a
              onClick={() =>
                this.handleClick(
                  data,
                  GlobalConstants.LOCATION_WITHOUT_ACTUAL,
                  null,
                  null,
                  Screens.STUNTS
                )
              }
            >
              <strong>{data.sceneLocationName}</strong>
            </a>{" "}
            is not confirmed.
          </>
        );
      case NotificationTitleConstants.LOCATION_AVAILABILITY:
        singular = data.locations.length === 1;
        text = this.renderNames(data.locations);
        return (
          <>
            Given location{singular ? "" : "s"} {text} {singular ? "is" : "are"}{" "}
            not available on <strong>{data.shootDate}</strong>.
          </>
        );
      case NotificationTitleConstants.ACTOR_AVAILABILITY:
        singular = data.actors && data.actors.length === 1;
        text = this.renderNames(data.actors, true);
        return (
          <>
            Selected actor{singular ? "" : "s"} {text} {singular ? "is" : "are"}{" "}
            not available on <strong>{data.shootDate}</strong>.
          </>
        );

      default:
        return "error";
    }
  };

  renderList = (title, notification) => {
    let template = [];

    notification &&
      notification.map((notification, i) =>
        template.push(
          <li className="list" key={i}>
            {/* <IconComponent name={datum.image} /> */}
            <span className="content">
              {/* Scene <strong>1</strong> has no character */}
              {this.renderText(title, notification)}
            </span>
            {/* <div className="notificationDot" /> */}
          </li>
        )
      );
    return <ul className="childListStyle">{template}</ul>;
  };
  render() {
    const { Notifications, isLoading } = this.state;
    return !isLoading ? (
      <ul className="listStyle">
        {Notifications &&
          Notifications.map((notification, i) => (
            <li className="list" key={i}>
              <span className="title">
                {notification.title}
                <span className="count">({notification.count})</span>
              </span>
              {this.renderList(
                notification.title,
                notification.scenesAlert ||
                  notification.locationAlerts ||
                  notification.notConfirmedLocations ||
                  notification.actorAvailabilityWarings ||
                  notification.notConfirmedActors
              )}
            </li>
          ))}
      </ul>
    ) : (
      <Loader size={12} />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    setActiveSceneId: (sceneId) => dispatch(setActiveSceneId(sceneId)),
  };
};

export default connect(null, mapDispatchToProps)(NotificationContentComponent);
