import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class CrewService {
  crewService(type, data, id, paymentId) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.CrewVariables.GET_PROJECT_DATA:
        return axios.get(`${getScriptAPIURL()}/project/${projectId}`, config);
      case Services.CrewVariables.CREATE_CREW_MEMBER:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/members`,
          data,
          config
        );
      case Services.CrewVariables.GET_CREW_MEMBER:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/users/${id}`,
          config
        );
      case Services.CrewVariables.UPDATE_CREW_MEMBER:
        return axios.put(
          `${getScriptAPIURL()}/projects/${projectId}/users/${id}`,
          data,
          config
        );
      case Services.CrewVariables.UPDATE_CREW_PAYMENT:
        return axios.put(
          `${getScriptAPIURL()}/project/members/${id}/payments/${paymentId}`,
          data,
          config
        );
      case Services.CrewVariables.CREATE_CREW_PAYMENT:
        return axios.post(
          `${getScriptAPIURL()}/project/members/${id}/payments`,
          data,
          config
        );
      case Services.CrewVariables.DELETE_CREW_PAYMENT:
        return axios.delete(
          `${getScriptAPIURL()}/project/member/payments/${id}`,
          config
        );
      case Services.CrewVariables.DELETE_CREW_MEMBER:
        return axios.delete(
          `${getScriptAPIURL()}/projects/${projectId}/users/${id}`,
          config
        );
      default:
        break;
    }
  }
}
