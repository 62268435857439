import React, { Fragment } from "react";
import TextComponent from "components/shared/TextComponent";
import CreateProject from "components/CreateProject";
import { GlobalConstants } from "constants/AppConstants";
import images from "../../assets/images/logo.png";

const NoProjectFound = (props) => {
  const roleName = props?.currentUser?.roleName || null;
  const refreshProjectList = () => {
    props.refreshProjectList();
  };
  return (
    <div style={{ textAlign: "center" }} className="noProjectArea">
      <div>
        <TextComponent style={{ color: "red" }} customize>
          <img
            src={images}
            style={{
              width: "10%",
              height: "10%",
              objectFit: "contain",
              marginBottom: 12,
            }}
            alt="logo"
          />
          <h1 className="noProject">No project in your account</h1>
          {GlobalConstants.CREATE_PROJECT.includes(roleName) && (
            <Fragment>
              <p>Create a new project</p>
              <CreateProject refreshProjectList={refreshProjectList} />
            </Fragment>
          )}
        </TextComponent>
      </div>
    </div>
  );
};

export default NoProjectFound;
