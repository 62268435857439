import React, { Component } from "react";
import { Layout, Button, Row, Col, Icon, Popover, Badge } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Images } from "assets/images";
import { removeUser, toggleNotificationBar } from "store/action";
import { Services, LocalStorage } from "constants/AppConstants";
import Storage from "utilities/LocalStorageHelper";
import { OnboardServices } from "services";
import "./header.css";
import { getNotificationCount } from "store/thunk-actions";
const { Header } = Layout;
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this._userService = new OnboardServices();
    this._storage = new Storage();
    this.state = {
      visible: false,
      isButtonLoading: false,
    };
  }
  handleLogout = () => {
    this.setState({
      isButtonLoading: true,
    });
    this._userService
      .onboardService(
        Services.OnboardVariables.LOGOUT,
        null,
        this.props.accessToken
      )
      .then((res) => {
        this._storage.remove();
        this.props.removeUser();
        this.props.history.push("/login");
        this.setState({ visible: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { notificationCount } = this.props;
    const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const text = (
      <span style={{ fontWeight: "bold" }}>
        Welcome!{" "}
        {this.props.currentUser && this.props.currentUser.name.firstName}
      </span>
    );
    const content = (
      <div>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button customButton"
          onClick={this.handleLogout}
        >
          Sign out
        </Button>
      </div>
    );
    const { toggleNotificationBar } = this.props;
    return (
      // <div>
      //   <Header
      //     style={{
      //       background: "#fff",
      //       padding: 0,
      //       overflow: "hidden",
      //       display: "flex"
      //     }}
      //   >
      //     <div
      //       className="logo"
      //       style={{
      //         width: "80px",
      //         height: "100px",
      //         backgroundColor: "#fb7791"
      //       }}
      //     >
      //       <img
      //         src={Images.logo}
      //         style={{ width: "50px", height: "60px", objectFit: "contain" }}
      //         alt="logo image"
      //       />
      //     </div>
      //     {this.props.currentUser && (
      //       <Button
      //         type="primary"
      //         htmlType="submit"
      //         className="login-form-button customButton"
      //         onClick={() => {
      //           localStorage.removeItem("user");
      //           this.props.removeUser();
      //           this.props.history.push("/login");
      //         }}
      //       >
      //         Sign out
      //       </Button>
      //     )}
      //   </Header>
      // </div>
      <div
        style={{
          backgroundColor: "#393A3D",
          height: "55px",
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}
      >
        <Row
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Col
            xl={{ span: 5 }}
            xs={{ span: 19 }}
            lg={{ span: 22 }}
            md={{ span: 21 }}
          >
            <Row type="flex" align="middle">
              <Col xs={24}>
                <Row type="flex" align="middle">
                  <Col
                    xs={24}
                    style={{
                      color: "#fff",
                      display: "flex",
                      flexDirection: "row",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to="/projects"
                      onClick={() => {
                        localStorage.removeItem(LocalStorage.ACTIVE_PROJECT_ID);
                        localStorage.removeItem(LocalStorage.PAM);
                      }}
                    >
                      <div
                        style={{
                          color: "#fff",
                          fontSize: "23px",
                          fontWeight: "600",
                          paddingLeft: "16px",
                        }}
                      >
                        ACE
                      </div>
                    </Link>
                    {projectId && (
                      <Link to={`/project/overview/${projectId}`}>
                        <div
                          className=""
                          style={{ paddingLeft: 10, color: "#fff" }}
                        >
                          {this.props.activeProjectTitle}
                        </div>
                      </Link>
                    )}
                  </Col>
                  <Col xs={12}></Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {this.props.currentUser && (
            <Col
              xl={{ offset: 13, span: 6 }}
              xs={{ span: 6 }}
              lg={{ span: 3 }}
              md={{ span: 4 }}
              style={{ height: "100%", paddingRight: 20 }}
            >
              <Row
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                {projectId && (
                  <Col
                    span={5}
                    style={{
                      color: "#fff",
                      display: "flex",
                      justifyContent: "space-around",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      className="links "
                      style={{ color: "white" }}
                      to="/projects"
                      onClick={() => {
                        localStorage.removeItem(LocalStorage.ACTIVE_PROJECT_ID);
                        localStorage.removeItem(LocalStorage.PAM);
                      }}
                    >
                      <div className="myProjects">My Projects</div>
                    </Link>
                  </Col>
                )}
                {/* <Col
                  xl={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Icon
                    className="links"
                    type="setting"
                    style={{ fontSize: "20px" }}
                  />
                </Col> */}
                {projectId && (
                  <Col
                    xl={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {/* <Icon
                    className="links"
                    type="user"
                    style={{ fontSize: "20px" }}
                  /> */}
                    <a style={{ color: "white" }}>
                      <Badge
                        onClick={() => toggleNotificationBar()}
                        count={notificationCount}
                        overflowCount={99}
                      >
                        <Icon
                          type="notification"
                          style={{ fontSize: "20px" }}
                        />
                      </Badge>
                    </a>
                  </Col>
                )}
                <Col
                  xl={8}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Popover
                    placement="bottomLeft"
                    title={text}
                    content={content}
                    trigger="click"
                    visible={this.state.visible}
                    onVisibleChange={() =>
                      this.setState({ visible: !this.state.visible })
                    }
                    className="links"
                  >
                    <Icon type="user" style={{ fontSize: "20px" }} />
                    <span style={{ paddingLeft: 6 }}>
                      {this.props.currentUser &&
                        this.props.currentUser.name.firstName}
                    </span>
                  </Popover>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ user, activeItems }) => {
  return {
    currentUser: user.currentUser,
    accessToken: user.accessToken,
    notificationCount: user.notificationCount,
    activeProjectTitle: activeItems.activeProjectTitle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeUser: () => dispatch(removeUser()),
    toggleNotificationBar: () => dispatch(toggleNotificationBar()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
