import React from "react";
import LottieComponent from "../LottieComponent";
import { Lotties } from "constants/AppConstants";

export default function ContactAdminErrorTemplate() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="accessDenied"
    >
      <LottieComponent type={Lotties.ACCESS_DENIED} width={250} />
      <span className="accessDeniedText">Access Denied</span>
      <p>You dont have permission to view this link</p>
      <span className="linear-wipe">Please contact your Project Admin</span>
    </div>
  );
}
