import React, { Component, Fragment } from "react";
import {
  Modal,
  Button,
  Icon,
  Table,
  Switch,
  Input,
  Row,
  Col,
  Upload,
  InputNumber,
  Select,
  Popconfirm,
  Divider,
  Form,
  Tooltip,
} from "antd";
import { connect } from "react-redux";

import SongsModel from "components/songsmodel";
import SongsStuntsServices from "services/SongsStuntsServices";
import {
  Services,
  GlobalConstants,
  Notifications,
  Screens,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import Uploaddocumentfile from "components/uploaddocumentfile";
import FileCard from "components/shared/FileCardComponent";
import { setActiveTab } from "store/action";
import { numberOnly, amountOnly } from "utilities/ValidationHelper";
import SongsTable from "components/SongsTable";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";

class Songtab extends Component {
  constructor(props) {
    super(props);
    this._songsServices = new SongsStuntsServices();
    this._globalService = new GlobalService();
    this.state = {
      SongsData: [],
      isDataFetched: false,
      visible: false,
      recordData: null,
      materialHire: null,
      materialPurchase: null,
      propertyHire: null,
      propertyPurchase: null,
      timeRequiredToShoot: null,
      state: null,
      statusData: [],
      fileId: null,
      fileList: null,
      activeSceneId: null,
      timeRequiredType: [],
      timeType: null,
    };
  }

  componentDidMount() {
    this.getTimeType();
    this.fetchData();
    this.fetchBudgetData();
    this.getStatus();
    let redirectTo = localStorage.getItem(
      GlobalConstants.REDIRECT_TO,
      Screens.SONGS
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchData();
          this.fetchBudgetData();
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.setActiveTab("1");
    localStorage.removeItem(GlobalConstants.REDIRECT_TO);
  }

  getStatus = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        Services.GlobalVariables.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState(
          {
            statusData: res.data,
          },
          () => console.log("status...", this.state.statusData)
        );
      });
  };

  fetchBudgetData = () => {
    // this._songsServices
    //   .service(
    //     Services.SongVariables.GET_SONGS_STUNTS_BUDGET,
    //     Services.SongVariables.SONGS
    //   )
    //   .then((res) => {
    //     this.setState(
    //       {
    //         materialHire: res.data.materialHire,
    //         materialPurchase: res.data.materialPurchase,
    //         propertyHire: res.data.propertyHire,
    //         propertyPurchase: res.data.propertyPurchase,
    //         state: res.data.state,
    //         timeRequiredToShoot: res.data.timeRequiredToShoot,
    //         sceneBudgetId: res.data.sceneBudgetId,
    //         fileList: res.data.documents,
    //         timeType: res.data.timeType,
    //       },
    //       () => console.log("song budget fetch data", res.data)
    //     );
    //   })
    //   .catch((err) => {
    //     console.log("song budget fetch data", err);
    //   });
  };

  fetchData = () => {
    this._songsServices
      .service(
        Services.SongVariables.GET_SONGS_STUNT_LIST,
        Services.SongVariables.SONGS
      )
      .then((res) => {
        console.log("song fetch data..", res);
        this.setState({
          isDataFetched: true,
          SongsData: res.data.sceneDetails,
        });
      })
      .catch((err) => {
        console.log("song fetch data", err);
      });
  };

  handleDelete = (id) => {
    this._songsServices
      .service(
        Services.SongVariables.DELETE_SONGS_STUNTS,
        Services.SongVariables.SONGS,
        id
      )
      .then((res) => {
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteDoc = (id) => {
    console.log(this.state.docFiles);
    console.log("image files id", id);
    let deleteIndex = this.state.docFiles.findIndex((a) => a.documentId === id);

    let docFiles = this.state.docFiles;
    let finalData = docFiles.splice(deleteIndex, 1);
    this.setState({
      docFiles,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  getTimeType() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "MAKE_UP_TIME")
      .then((res) => {
        this.setState({ timeRequiredType: res.data });
      });
  }
  handleSubmit = (state) => {
    // e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          materialHire,
          materialPurchase,
          propertyHire,
          propertyPurchase,
          timeRequiredToShoot,
          state,
          sceneBudgetId,
          fileId,
          fileList,
          timeType,
        } = this.state;
        let type = this.state.state;
        type = type.replace(/ /g, "_");
        let enumType = type.toUpperCase();
        const data = {
          // materialHire,
          // materialPurchase,
          // propertyHire,
          // propertyPurchase,
          // timeRequiredToShoot: timeRequiredToShoot.toString(),
          state: enumType,
          sceneBudgetId,
          // timeType,
          // documents: fileId
          //   ? [
          //       {
          //         fileId,
          //         fileType: "DOCUMENT"
          //       }
          //     ]
          //   : []
        };
        if (!sceneBudgetId) {
          this.setState({
            isButtonLoading: true,
          });
          this._songsServices
            .service(
              Services.SongVariables.ADD_SONGS_STUNTS_BUDGET,
              Services.SongVariables.SONGS,
              null,
              data
            )
            .then((res) => {
              this.setState(
                {
                  isButtonLoading: false,
                },
                () => this.fetchBudgetData()
              );
              // showNotifications(
              //   Notifications.SUCCESS,
              //   Notifications.SUCCESS_CAP,
              //   `Song budget ${Notifications.ADDED_SUCCESSFULLY}`
              // );
              showActionMessage(
                GlobalConstants.SUCCESS,
                GlobalConstants.CHANGES_HAVE_BEEN_SAVED
              );
            })
            .catch((err) => {
              this.setState(
                {
                  isButtonLoading: false,
                },
                () => this.fetchBudgetData()
              );

              showNotifications(
                Notifications.ERROR,
                Notifications.FAILED,
                `${Notifications.SOMETHING_WENT_WRONG}`
              );
            });
        } else {
          // this.setState({
          //   isButtonLoading: true,
          // });
          // this._songsServices
          //   .service(
          //     Services.SongVariables.UPDATE_SONGS_STUNTS_BUDGET,
          //     Services.SongVariables.SONGS,
          //     sceneBudgetId,
          //     data
          //   )
          //   .then((res) => {
          //     this.setState(
          //       {
          //         isButtonLoading: false,
          //         visible: false,
          //         recordData: null,
          //         materialHire: null,
          //         materialPurchase: null,
          //         propertyHire: null,
          //         propertyPurchase: null,
          //         timeRequiredToShoot: null,
          //         state: null,
          //         statusData: [],
          //         fileId: null,
          //         fileList: null,
          //         timeType: null,
          //       },
          //       () => {
          //         this.fetchBudgetData();
          //         this.getStatus();
          //       }
          //     );
          //     // showNotifications(
          //     //   Notifications.SUCCESS,
          //     //   Notifications.SUCCESS_CAP,
          //     //   `Song budget ${Notifications.UPDATED_SUCCESSFULLY}`
          //     // );
          //     showActionMessage(
          //       GlobalConstants.SUCCESS,
          //       GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          //     );
          //   })
            // .catch((err) => {
            //   this.setState(
            //     {
            //       isButtonLoading: false,
            //     },
            //     () => this.fetchBudgetData()
            //   );
            //   showNotifications(
            //     Notifications.ERROR,
            //     Notifications.FAILED,
            //     `${Notifications.SOMETHING_WENT_WRONG}`
            //   );
            // });
        }
      }
    });
  };

  handleDocument = (id) => {
    this.setState(
      {
        fileId: id,
      },
      () => console.log("fileupload id...", this.state.fileId)
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      // multiple: true
    };
    const { Option } = Select;
    const {
      SongsData,
      materialHire,
      materialPurchase,
      propertyHire,
      propertyPurchase,
      timeRequiredToShoot,
      state,
      isDataFetched,
      recordData,
      type,
      statusData,
      sceneBudgetId,
      isButtonLoading,
      fileList,
      timeType,
    } = this.state;
    const buttonText = sceneBudgetId ? "Update" : "Save";
    const columns = [
      {
        title: "#.",
        dataIndex: "sceneTypeDetailId",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.sceneTypeDetailId < b.sceneTypeDetailId,
        align: "center",
      },

      {
        title: "Description",
        dataIndex: "description",
        align: "center",
      },
      {
        title: "Nos",
        dataIndex: "memberCount",
        align: "center",
      },
      {
        title: "Day/Sequence",
        dataIndex: "sequenceType",
        align: "center",
      },

      {
        title: "Rate",
        dataIndex: "price",
        align: "center",
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (text, record) => (
          <div>
            <Tooltip
              title={GlobalConstants.EDT}
              placement={GlobalConstants.RIGHT}
            >
              {" "}
              <Button
                icon="edit"
                type="link"
                onClick={() =>
                  this.setState({
                    visible: true,
                    type: GlobalConstants.UPDATE,
                    recordData: record,
                  })
                }
              />
            </Tooltip>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this Song?"
              onConfirm={() => this.handleDelete(record.sceneTypeDetailId)}
              // onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
              // loading={this.state.isLoading}
            >
              <Tooltip
                title={GlobalConstants.DEL}
                placement={GlobalConstants.RIGHT}
              >
                {" "}
                <Button icon="delete" type="link" />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ];

    function onChange(value) {
      console.log("changed", value);
    }

    return (
      <Fragment>
        <SongsTable activeSceneId={this.state.activeSceneId} />
        <Row>
          {/* <Modal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            key={2}
            width={["630px"]}
            style={{ maxHeight: "40px" }}
            footer={null}
            destroyOnClose
          >
            <SongsModel
              closeModal={this.handleCancel}
              recordData={recordData}
              type={type}
              refreshData={this.fetchData}
            />
          </Modal> */}
          <Col
            xl={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            {/* <Row type="flex" justify="center">
              <Col
                xl={{ span: 15 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Table
                  columns={columns}
                  dataSource={SongsData}
                  pagination={false}
                  loading={!isDataFetched}
                />
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <Button
                  onClick={() =>
                    this.setState({
                      visible: true,
                      type: GlobalConstants.ADD,
                      recordData: null
                    })
                  }
                  type="primary"
                >
                  Add Entry
                </Button>
              </Col>
            </Row> */}

            <Form>
              {/* <Row>
                <Col
                  style={{ marginTop: "20px" }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 8, offset: 4 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">*Material Purchase </label>
                    </Col>

                    <Col
                      xl={{ span: 11 }}
                      lg={{ span: 11 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Form.Item>
                        {getFieldDecorator("materialPurchase", {
                          initialValue: materialPurchase,
                          rules: [
                            {
                              required: true,
                              message: "Enter material purchase!"
                            }
                          ]
                        })(
                          <Input
                            prefix="₹"
                            // value={materialPurchase}
                            min={0}
                            style={{ width: "70%" }}
                            // formatter={value =>
                            //   `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            // }
                            // parser={value => value.replace(/\₹\s?|(,*)/g, "")}
                            onChange={value =>
                              this.setState({
                                materialPurchase: amountOnly(value)
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 7 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">*Material Hire </label>
                    </Col>

                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Form.Item>
                        {getFieldDecorator("materialHire", {
                          initialValue: materialHire,
                          rules: [
                            {
                              required: true,
                              message: "Enter material hire!"
                            }
                          ]
                        })(
                          <Input
                            prefix="₹"
                            // value={materialHire}
                            min={0}
                            style={{ width: "70%" }}
                            // formatter={value =>
                            //   `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            // }
                            // parser={value => value.replace(/\₹\s?|(,*)/g, "")}
                            onChange={value =>
                              this.setState({ materialHire: amountOnly(value) })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  style={{ marginTop: "20px" }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 8, offset: 4 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">*Property Purchase </label>
                    </Col>

                    <Col
                      xl={{ span: 11 }}
                      lg={{ span: 11 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Form.Item>
                        {getFieldDecorator("propertyPurchase", {
                          initialValue: propertyPurchase,
                          rules: [
                            {
                              required: true,
                              message: "Enter property purchase!"
                            }
                          ]
                        })(
                          <Input
                            prefix="₹"
                            // value={propertyPurchase}
                            min={0}
                            style={{ width: "70%" }}
                            // formatter={value =>
                            //   `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            // }
                            // parser={value => value.replace(/\₹\s?|(,*)/g, "")}
                            onChange={value =>
                              this.setState({
                                propertyPurchase: amountOnly(value)
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 7 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">*Property Hire </label>
                    </Col>

                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Form.Item>
                        {getFieldDecorator("propertyHire", {
                          initialValue: propertyHire,
                          rules: [
                            {
                              required: true,
                              message: "Enter property hire!"
                            }
                          ]
                        })(
                          <Input
                            prefix="₹"
                            // value={propertyHire}
                            min={0}
                            style={{ width: "70%" }}
                            // formatter={value =>
                            //   `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            // }
                            // parser={value => value.replace(/\₹\s?|(,*)/g, "")}
                            onChange={value =>
                              this.setState({ propertyHire: amountOnly(value) })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  style={{ marginTop: "20px" }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 8, offset: 4 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">
                        *Time Required To Shoot
                      </label>
                    </Col>

                    <Col
                      xl={{ span: 11 }}
                      lg={{ span: 11 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Form.Item>
                        {getFieldDecorator("timeRequiredToShoot", {
                          initialValue: timeRequiredToShoot,
                          rules: [
                            {
                              required: true,
                              message: "Enter time required to shoot!"
                            }
                            // {
                            //   pattern: new RegExp("^[0-9]*$"),
                            //   message: "Enter Numbers Only!"
                            // }
                          ]
                        })(
                          <Input
                            style={{ width: "70%" }}
                            placeholder={"pick your time"}
                            onChange={value =>
                              this.setState({
                                timeRequiredToShoot: numberOnly(value)
                              })
                            }
                            addonAfter={
                              <Form.Item
                                className="payment-type"
                                style={{
                                  margin: "0px",
                                  height: "30px",
                                  top: "-4px"
                                }}
                              >
                                {getFieldDecorator("timeType", {
                                  initialValue: this.state.timeType || undefined
                                })(
                                  <Select
                                    placeholder="Time Type"
                                    style={{ width: "95px" }}
                                    onChange={value =>
                                      this.setState({ timeType: value })
                                    }
                                  >
                                    {this.state.timeRequiredType &&
                                      this.state.timeRequiredType.map(
                                        (option, i) => (
                                          <Option value={option.value}>
                                            {option.label}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                )}
                              </Form.Item>
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 7 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">Upload Documents </label>
                    </Col>

                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <div className="fileCardContainer">
                        {fileList &&
                          fileList.map((file, i) => (
                            <FileCard
                              file={file}
                              deleteDoc={this.fetchBudgetData}
                              pageType={Services.SongVariables.SONG_DELETE_DOC}
                              screenType={Services.SongVariables.SONGS}
                            />
                          ))}
                      </div>
                      {!isButtonLoading && (
                        <Uploaddocumentfile
                          handleDocument={this.handleDocument}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row> */}

              {/* <Row>
                <Col
                  style={{ marginTop: "20px" }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row gutter={12}>
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 8, offset: 4 }}
                      lg={{ span: 12 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600">*Status </label>
                    </Col>

                    <Col
                      xl={{ span: 11 }}
                      lg={{ span: 11 }}
                      md={{ span: 10 }}
                      xs={{ span: 22, offset: 1 }}
                    >
                      <Form.Item>
                        {getFieldDecorator("state", {
                          initialValue: state,
                          rules: [
                            {
                              required: true,
                              message: "Enter status!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Status"
                            style={{ width: "70%" }}
                            onChange={(state) =>
                              this.setState({ state }, () =>
                                this.handleSubmit(this.state.state)
                              )
                            }
                            value={state}
                          >
                            {statusData &&
                              statusData.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row> */}

              {/* <Row
                gutter={1}
                type="flex"
                justify="end"
                style={{ marginTop: "20px" }}
              >
                <Col
                  xl={{ span: 3 }}
                  lg={{ span: 4 }}
                  md={{ span: 6 }}
                  xs={{ span: 12 }}
                  style={{ textAlign: "end" }}
                >
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={isButtonLoading}
                    disabled={
                      !materialHire ||
                      !materialPurchase ||
                      !propertyHire ||
                      !propertyPurchase ||
                      !timeRequiredToShoot ||
                      !state ||
                      !timeType
                    }
                  >
                    {buttonText}
                  </Button>
                </Col>
              </Row> */}
            </Form>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Song", menus),
  };
};

const SongTab = Form.create()(Songtab);
// export default SongTab;

export default connect(mapStateToProps, mapDispatchToProps)(SongTab);
