import React, { useState, useEffect } from "react";
import { GlobalConstants, Menus, LocalStorage } from "constants/AppConstants";
import GeneralCostumeMakeupTable from "components/GeneralCostumeMakeupTable";
import { Row, Col } from "antd";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { connect } from "react-redux";

const GeneralCostumes = ({ activePermisision }) => {
  const [activePAM, setactivePAM] = useState(null);
  useEffect(() => {
    setactivePAM(localStorage.getItem(LocalStorage.PAM));
    return () => {};
  }, []);
  return (
    <Row type="flex" justify="center">
      <Col xl={24}>
        <GeneralCostumeMakeupTable
          activePermisision={activePermisision}
          pageType={Menus.COSTUMES}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.COSTUME, menus),
  };
};

export default connect(mapStateToProps, null)(GeneralCostumes);
