import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class TransportService {
  transportService(type, data, id, isPassenger = false) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    let projectCategoryDocumentId = {
      projectCategoryDocumentId: null,
    };

    switch (type) {
      case Services.TransportVariables.GET_TRANSPORT_MAIN_BY_SHOOT_DATE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/schedule/vehicle?shootDate=${
            data.shootDate
          }${data.vehicleName ? "&vehicleName=" + data.vehicleName : ""}`,
          config
        );
      case Services.TransportVariables.GET_PASSANGER_DD:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/passengers?shootDate=${
            data.shootDate
          }${data.vehicleName ? "&vehicleName=" + data.vehicleName : ""}${
            data.passengerType ? "&passengerType=ACTOR" : ""
          }`,
          // `${getScriptAPIURL()}/project/${projectId}/passengers?shootDate=${data.shootDate}${data.vehicleName ? '&vehicleName=' + data.vehicleName : ''}${data.passengerType  ? '&passengerType=' + data.passengerType  : ''}`,
          config
        );
      case Services.TransportVariables.GET_VEHICLE_LIST:
        return axios.get(
          `${getScriptAPIURL()}/schedule/vehicle/${
            data.scheduleVehicleId
          }/passengers`,
          config
        );
      case Services.TransportVariables.GET_DRIVER_LIST:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/driver/details`,
          config
        );
      case Services.TransportVariables.GET_DRIVER_DETAIL:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/driver/${data}`,
          config
        );
      case Services.TransportVariables.GET_VEHICLE_PASSENGER:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/passengers?passengerType=${
            data.type
          }&shootDate=${data.shootDate}`,
          config
        );
      case Services.TransportVariables.GET_VEHICLE_DROP_LOCATION_LIST:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/drop/locations?shootDate=${data}`,
          config
        );
      case Services.TransportVariables.GET_SCHEDULE_VEHICLE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/schedule/vehicle?shootDate=${data}`,
          config
        );
      case Services.TransportVariables.GET_VEHICLE_PASSENGER_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/schedule/vehicle/${data}/passengers`,
          config
        );
      case Services.TransportVariables.SAVE_SCHEDULE_VEHICLE:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/schedule/vehicle`,
          data,
          config
        );
      case Services.TransportVariables.UPDATE_SCHEDULE_VEHICLE:
        return axios.put(
          `${getScriptAPIURL()}/schedule/vehicle/${id}`,
          data,
          config
        );
      case Services.TransportVariables.UPDATE_PICKUP:
        return axios.put(
          `${getScriptAPIURL()}/vehiclepassenger/${id}/pickupaddress?pickupAddress=${data}`,
          data,
          config
        );
      case Services.TransportVariables.DELETE_SCHEDULE_VEHICLE:
        return axios.delete(
          `${getScriptAPIURL()}/schedule/vehicle/${data}?isPassenger=${isPassenger}`,
          config
        );
      default:
        break;
    }
  }
}
