import React, { Fragment, Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  BackTop,
  Tag,
  Icon,
} from "antd";
import { isEmpty } from "lodash";
import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
  LocalStorage,
} from "constants/AppConstants";
import BudjetService from "services/BudjetService";

import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";

import "./UpdateProduction.css";
import * as _ from "lodash";
import {
  GlobalVariables,
  BudjetVariables,
} from "constants/AppConstants/ServicesConstants";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import RecordNotFound from "components/shared/RecordNotFound";
import ThemeColors from "constants/ThemeConstants/variables";
import { numberOnly, checkFor } from "utilities/ValidationHelper";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { handleError } from "utilities/ErrorHandler";
import moment from "moment";
import { findEnumValue } from "utilities/UserHelper";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title, name } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                // pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              type="number"
              name={name}
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              name={name}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            name={name}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values }, null, e.currentTarget.name);
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class UpdateProduction extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._budjetService = new BudjetService();

    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      shootDate: null,
      budgetStatusData: [],
      isArtSet: props.isArtSet,
      budgetState: "IN_PROGRESS",
      initialLoad: true,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.getTypeDatas();
    this.getBudgetState();
    if (this.props.match.params.shootDate) {
      this.setState(
        {
          shootDate: this.props.match.params.shootDate,
        },
        () => this.getBudjetEstimationData()
      );
    }
  }

  getBudgetState() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.BUDGET_STATE
      )
      .then((res) => {
        this.setState({
          budgetStatusData: res.data,
        });
      });
  }

  getBudjetEstimationData = () => {
    const { isArtSet, initialLoad } = this.state;
    const { page } = this.props;
    this.setState({ count: 0, isLoading: false, dataSource: [] });
    initialLoad &&
      showActionMessage(GlobalConstants.SHOW, "Loading Details...");
    this._budjetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_BUDJET_DATA,
        this.state.shootDate,
        null,
        page,
        null,
        this.props.isViewOnly
      )
      .then((res) => {
        showActionMessage(GlobalConstants.HIDE);
        let preProductionData = [];
        res.data.postProductionPayments &&
          res.data.postProductionPayments.map((data, i) => {
            preProductionData.push({ ...data, key: i });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource: preProductionData,
          totalRecords: preProductionData,
          isLoading: true,
          callSheet: res.data.callSheet,
          sceneLocations: res.data.sceneLocations,
          actualLocations: res.data.actualLocations,
          budgetState: res.data.budgetStateEnum || GlobalConstants.IN_PROGRESS,
          initialLoad: false,
        });
      })
      .catch((err) => {
        showActionMessage(GlobalConstants.HIDE);
        this.setState({
          isLoading: true,
        });
      });
  };

  getTypeDatas = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          typeDatas: res.data,
        });
      })
      .catch((err) => {});
  };

  handleDelete = (key, record) => {
    const { refreshChartData } = this.props;
    if (record.id) {
      this._budjetService
        .budjetService(
          Services.BudjetVariables.DEL_PRODUCTION_BUDJET_DATA,
          this.props.match.params.shootDate,
          record.id,
          BudjetVariables.PRODUCTION,
          record.productionCategoryDepartment
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.getBudjetEstimationData();
          refreshChartData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );
          this.props.getPermissions();
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      category: "",
      paymentType: "",
      crewCount: "0",
      budget: "",
      productionCategoryDepartment: "PRODUCTION",
      productionCategoryDepartmentValue: "Production",
      //   projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    };

    this.setState(
      {
        dataSource: [...dataSource, newData],
      }
      // () => this.refs.end.scrollIntoView({ block: "end", behavior: "smooth" })
    );
  };

  onChange(value, record, type) {
    if (type === "unitRate") {
      record.budget = Number(value) * Number(record.crewCount);
    }
    record[type] = value;
    this.handleSave(record, value, type);

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleCrewCount = (value, record, type) => {
    const { crewCount, category } = record;
    // this.onchange(value, record, type);
    const that = this;
    if (crewCount && category) {
      this._budjetService
        .budjetService(Services.BudjetVariables.GET_PREVIOUS_PAYMENT, {
          count: crewCount,
          description: category,
        })
        .then((res) => {
          if (res.status !== 204) {
            record.paymentType = res.data.paymentType;
            record.unitRate = res.data.amount;
            this.onChange(value, record, type);
          } else {
            this.onChange(value, record, type);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  inputChange(value, record) {
    this.handleSave(record, value, "category");

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  checkForCrewCount = (row) => {
    if (row.paymentType === "PURCHASE") {
      return true;
    } else {
      return parseInt(row.crewCount);
    }
  };

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      if (type === "paymentType") {
        let modifyItem = newData[index];
        modifyItem.paymentType = value;
      } else if (type === "category") {
        let modifyItem = newData[index];
        modifyItem.category = value;
      }
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      console.log("dataSource save", this.state.dataSource);
    });
    if (!row.id) {
      if (row.productionCategoryDepartment !== GlobalConstants.VENDOR) {
        if (
          row.category &&
          this.checkForCrewCount(row) &&
          row.unitRate &&
          row.paymentType
        ) {
          this.handleSubmit(row);
        } else {
          if (
            parseInt(row.crewCount) < 1 &&
            row.category &&
            row.unitRate &&
            row.paymentType
          ) {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Crew count must be greater than 0"
            );
          }
        }
      } else {
        if (
          row.category &&
          this.checkForCrewCount(row) &&
          row.unitRate &&
          row.paymentType
        ) {
          this.handleVendorSubmit(row);
        } else {
          if (
            parseInt(row.crewCount) < 1 &&
            row.category &&
            row.unitRate &&
            row.paymentType
          ) {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Crew count must be greater than 0"
            );
          }
        }
      }
    }
    if (row.id) {
      if (
        row.category &&
        this.checkForCrewCount(row) &&
        row.unitRate &&
        row.paymentType
      ) {
        console.clear();
        console.log(row, this.state.activeRecord);
        console.log(_.isEqual(row, this.state.activeRecord));
        if (!_.isEqual(row, this.state.activeRecord)) {
          if (this.checkForCrewCount(row) || parseInt(row.crewCount) > 0) {
            this.handleUpdate(row);
          } else {
            if (parseInt(row.crewCount) < 1) {
              showNotifications(
                Notifications.ERROR,
                "Failed",
                "Crew count must be greater than 0"
              );
            }
          }
        }
      } else {
        if (parseInt(row.crewCount) < 1) {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Crew count must be greater than 0"
          );
        }
      }
    }
  };
  setActiveRecord = (record) => {
    let temp = JSON.parse(JSON.stringify(record));
    if (temp.crewCount) {
      temp.crewCount = temp.crewCount.toString();
    }
    this.setState({
      activeRecord: temp,
    });
  };

  handleSubmit = (record) => {
    // const data = {
    //   budget: record.budget,
    //   category: record.category,
    //   crewCount: record.crewCount,
    //   paymentType: record.paymentType,
    //   shootDate: this.props.match.params.shootDate
    // };
    const { refreshChartData } = this.props;
    const data = {
      category: record.category,
      crewCount: record.crewCount,
      budget: record.unitRate,
      paymentType: record.paymentType,
      budgetDepartment: BudjetVariables.PRODUCTION,
      shootDate: this.props.match.params.shootDate,
      projectId: parseInt(localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)),
    };

    this._budjetService
      .budjetService(Services.BudjetVariables.POST_UPDATE_PRODUCTION, data)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully"
        );
        this.getBudjetEstimationData();
        refreshChartData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.props.getPermissions();
      });
  };
  handleVendorSubmit = (record) => {
    // const data = {
    //   budget: record.budget,
    //   category: record.category,
    //   crewCount: record.crewCount,
    //   paymentType: record.paymentType,
    //   shootDate: this.props.match.params.shootDate
    // };
    const { refreshChartData } = this.props;

    const data = {
      amount: record.unitRate,
      paymentType: record.paymentType,
      projectId: parseInt(localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)),
      quantity: record.crewCount,
      shootDate: this.props.match.params.shootDate,
      vendorId: record.categoryId,
    };
    // const data = {
    //   category: record.category,
    //   crewCount: record.crewCount,
    //   budget: record.unitRate,
    //   paymentType: record.paymentType,
    //   budgetDepartment: BudjetVariables.PRODUCTION,
    //   shootDate: this.props.match.params.shootDate,
    //   projectId: parseInt(localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID))
    // };

    this._budjetService
      .budjetService(
        Services.BudjetVariables.POST_UPDATE_PRODUCTION_VENDOR,
        data
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Vendor created Successfully"
        );
        this.getBudjetEstimationData();
        refreshChartData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.props.getPermissions();
      });
  };

  handleUpdate = (record) => {
    const { refreshChartData } = this.props;
    const data = {
      categoryId: record.categoryId,
      category: record.category,
      crewCount: record.crewCount,
      budget: record.unitRate,
      paymentType: record.paymentType,
      budgetDepartment: BudjetVariables.PRODUCTION,
      productionCategory: record.productionCategoryDepartment,
      shootDate: this.props.match.params.shootDate,
      projectId: parseInt(localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)),
    };

    this._budjetService
      .budjetService(
        Services.BudjetVariables.UPDATE_PRODUCTION_BUDJET_DATA,
        data,
        record.id
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.setState({
          activeRecord: null,
        });
        this.getBudjetEstimationData();
        refreshChartData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.props.getPermissions();
      });
  };

  handleStatusChange = (value) => {
    this.setState({
      isStatusLoading: true,
    });
    let data = {
      state: value,
      shootDate: this.state.shootDate,
      budgetDepartment: BudjetVariables.PRODUCTION,
    };
    this._budjetService
      .budjetService(Services.BudjetVariables.UPDATE_BUDGET_STATE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getBudjetEstimationData();
        this.setState({
          isStatusLoading: false,
        });
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message
        );
        this.setState({
          isStatusLoading: false,
        });
      });
  };

  isDisabledField = (value) => {
    const Fields = [
      GlobalConstants.PRODUCTION_CAPS,
      GlobalConstants.JUNIOR_ARTIST_CAPS,
      GlobalConstants.ANIMAL_CAPS,
      GlobalConstants.VENDOR,
      GlobalConstants.VFX,
    ];

    return Fields.includes(value);
  };

  render() {
    const {
      dataSource,
      totalRecords,
      isLoading,
      actualLocations,
      sceneLocations,
      callSheet,
      budgetStatusData,
      budgetState,
    } = this.state;

    const { activePermisision, getPermissions, isViewOnly } = this.props;

    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const column = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "category",
        width: "20%",
        className: "category-width",
        // editable: true,
        align: "left",
        render: (text, record, index) => (
          <Input
            style={{ width: "100%" }}
            autoFocus={!record.id}
            defaultValue={text}
            disabled={
              record.isEnable === false ||
              this.state.budgetState === "LOCK" ||
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
              isViewOnly
            }
            onFocus={() => this.setActiveRecord(record)}
            // onChange={e => this.inputChange(e.target.value, record)}
            onBlur={(e) => this.inputChange(e.target.value, record)}
            onPressEnter={(e) => this.inputChange(e.target.value, record)}
          />
        ),
      },
      {
        title: "Department",
        dataIndex: "productionCategoryDepartment",
        width: "15%",
        align: "center",
        render: (text, record) => (
          <Tag className={`${record.productionCategoryDepartment} dateTag`}>
            {record.productionCategoryDepartment === GlobalConstants.PRODUCTION
              ? "Daily Expense"
              : findEnumValue(text)}
          </Tag>
        ),
      },
      {
        title: "Count",
        dataIndex: "crewCount",
        width: "15%",
        align: "right",
        // className: "cash",
        // editable: true,
        render: (text, record) => (
          <Input
            defaultValue={text}
            style={{ width: "100%", textAlign: "right" }}
            disabled={
              this.state.budgetState === "LOCK" ||
              !this.isDisabledField(record.productionCategoryDepartment) ||
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
              isViewOnly ||
              record.productionCategoryDepartment === "CATERING"
            }
            // className="cash"
            type="number"
            // onChange={e => this.onChange(e.target.value, record, "crewCount")}
            onPressEnter={(e) =>
              this.onChange(e.target.value, record, "crewCount")
            }
            onFocus={() => this.setActiveRecord(record)}
            onBlur={(e) => {
              this.onChange(e.target.value, record, "crewCount");
              this.handleCrewCount(e.target.value, record, "crewCount");
            }}
          />
        ),
      },
      //   {
      //     title: "Rate",
      //     dataIndex: "price",
      //     width: "15%",
      //     editable: true,
      //     align: "center"
      //   },
      //   {
      //     title: "Quantity",
      //     dataIndex: "quantity",
      //     width: "15%",
      //     editable: true,
      //     align: "center"
      //   },
      {
        title: "Unit Rate  (₹)",
        dataIndex: "unitRate",
        width: "15%",
        // editable:
        //   checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //   !isViewOnly,
        // align: "right",
        // render: (text, record) => {
        //   return {
        //     props: {
        //       className: `${!text ? "empty-active" : ""} cash`
        //     },
        //     children: text
        //   };
        // }
        render: (text, record) => (
          <Input
            defaultValue={text}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
              isViewOnly
            }
            onFocus={(e) => e.currentTarget.select()}
            // className="cash"
            style={{ textAlign: "right", width: "100%" }}
            type="number"
            // onChange={e => this.onChange(e.target.value, record, "crewCount")}
            onPressEnter={(e) =>
              this.onChange(e.target.value, record, "unitRate")
            }
            onBlur={(e) => this.onChange(e.target.value, record, "unitRate")}
          />
        ),
      },
      {
        title: "Cost  (₹)",
        dataIndex: "budget",
        width: "15%",
        align: "right",
        render: (text) => <span className="flrt">{text}</span>,
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        align: "left",
        width: 130,
        render: (text, record) =>
          this.isDisabledField(record.productionCategoryDepartment) ? (
            <Select
              showSearch
              style={{ width: 109 }}
              className={`${!text ? "custom-empty-active" : ""}`}
              placeholder="Select type"
              disabled={
                this.state.budgetState === "LOCK" ||
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
                isViewOnly
              }
              onChange={(value) => this.onChange(value, record, "paymentType")}
              defaultValue={text ? text : ""}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.typeDatas &&
                this.state.typeDatas.map((data) => {
                  return <Option value={data.value}>{data.label}</Option>;
                })}
            </Select>
          ) : (
            text
          ),
      },
    ];

    if (
      checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) &&
      !isViewOnly
    ) {
      column.push({
        title: "",
        // dataIndex: "operation",
        // width: "0%",
        render: (text, record) =>
          record.isEnable !== false && (
            <Row type="flex" justify="center">
              <React.Fragment>
                {/* {record.isEnable && ( */}
                <Popconfirm
                  // disabled={record.isEnable === false}
                  title="Sure to delete?"
                  onConfirm={() => this.handleDelete(record.key, record)}
                >
                  <Tooltip
                    title={GlobalConstants.DEL}
                    placement={GlobalConstants.RIGHT}
                  >
                    <Button
                      icon="delete"
                      type="link"
                      // disabled={record.isEnable === false}
                    />
                  </Tooltip>
                </Popconfirm>
                {/* )} */}
              </React.Fragment>
            </Row>
          ),
      });
    }

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "category" ? "text" : "number",
          editable: this.state.budgetState !== "LOCK" ? col.editable : "",
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
          name: col.dataIndex,
        }),
      };
    });

    return (
      <Row>
        <Col xs={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={24}>
              <Row type="flex" justify="middle" align="middle">
                {/* <Col xl={24}>
                  <h3
                    className="primary-font-color"
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      textAlign: "center"
                    }}
                  >
                    Update Production
                  </h3>
                </Col> */}
              </Row>
              <Row>
                <Col xl={24}>
                  <Row className="flex-row">
                    <Col xl={12}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Date:
                      </span>
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                          paddingLeft: 10,
                        }}
                      >
                        {this.props.match.params.shootDate}
                      </span>
                    </Col>
                    <Col xl={12}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Call Sheet:
                      </span>
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                          paddingLeft: 10,
                        }}
                      >
                        {callSheet}
                      </span>
                    </Col>

                    {/* <Col xl={8} style={{ paddingBottom: 10 }}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block"
                        }}
                      >
                        State:
                      </span>
                      {budgetState !== "LOCK" ? (
                        budgetState && (
                          <Select
                            style={{ marginLeft: 10, width: 200 }}
                            loading={this.state.isStatusLoading}
                            value={budgetState}
                            onChange={value => this.handleStatusChange(value)}
                            disabled={isEmpty(dataSource)}
                          >
                            {budgetStatusData &&
                              budgetStatusData.map(list => (
                                <option value={list.value}>{list.label}</option>
                              ))}
                          </Select>
                        )
                      ) : (
                        <span style={{ marginLeft: 10 }}>{budgetState}</span>
                      )}
                    </Col> */}
                  </Row>
                  <Row className="flex-row" style={{ padding: "10px 0" }}>
                    <Col xl={12}>
                      <span
                        // // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Scene Locations:
                      </span>
                      {sceneLocations &&
                        sceneLocations.map((sceneLocation, i) => (
                          <Tag
                            style={{
                              marginLeft: 0,
                              borderRadius: 12,
                              marginBottom: 3,
                            }}
                            color={ThemeColors.primaryColor}
                          >
                            {sceneLocation.locationName}
                          </Tag>
                        ))}
                    </Col>
                    <Col xl={12} style={{ paddingBottom: 10 }}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Actual Locations:
                      </span>
                      {actualLocations &&
                        actualLocations.map((actualLocation, i) => (
                          <Tag
                            style={{ marginLeft: 10, borderRadius: 12 }}
                            color={"#ee556a"}
                          >
                            {actualLocation.locationName}
                          </Tag>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) &&
              !isViewOnly && (
                <Col xl={24}>
                  <Button
                    onClick={this.handleAdd}
                    type="primary"
                    style={{ float: "right", marginBottom: 10 }}
                    disabled={hasNewRow}
                  >
                    <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                  </Button>
                </Col>
              )}
            <Col xl={24}>
              <Table
                // scroll={{ y: "50vh" }}
                pagination={false}
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                // pagination={{ pageSize: 1000 }}
                loading={!isLoading}
                locale={{
                  emptyText: isLoading && <RecordNotFound />,
                }}
              />
              <div ref="end"></div>
            </Col>
          </Row>
          <BackTop visibilityHeight="50" />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.CREW, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProduction);
