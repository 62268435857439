import { combineReducers } from "redux";
import {
  SET_FONTSIZE,
  SET_USER,
  REMOVE_USER,
  SET_ACCESS_TOKEN,
  SET_ACTIVE_MENU,
  SET_ACTIVE_PROJECT,
  SET_MENUS,
  SET_ACTIVE_TAB,
  SET_SCENE_NUMBERS,
  SEt_ACTIVE_SCENE_ID,
  SET_ACTIVE_SCENE_LOCATION_ID,
  SET_ACTIVE_MENU_PERMISSIONS,
  SET_ACTIVE_COMPONENT_CAST,
  SET_COSTUME_LEFT_ACTIVE_TAB,
  SET_MAKEUP_LEFT_ACTIVE_TAB,
  OPEN_NOTIFICATION_BAR,
  SET_NOTIFICATION_COUNT,
} from "store/action/actionTypes";
import { FontStyles } from "constants/AppConstants";

const fontInitialState = {
  fontSize: FontStyles.SMALL,
};

const userInitialState = {
  currentUser: null,
  accessToken: null,
  notificationCount: 0,
  menus: [],
};

const activeInitialState = {
  activeMenu: null,
  activeProjectIDs: null,
  activeProjectTitle: null,
  activeTabId: "1",
  activeMenuPermissions: null,
  listTypeCast: "grid",
  costumeLeftActiveTab: "1",
  makeupLeftActiveTab: "1",
  isNotificationBarVisible: false,
};

const sceneInitialState = {
  scenes: null,
  activeSceneId: null,
};

const fontReducer = (state = fontInitialState, action) => {
  switch (action.type) {
    case SET_FONTSIZE:
      return {
        ...state,
        fontSize: action.payload.size,
      };

    default:
      return state;
  }
};

const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload.user,
      };

    case REMOVE_USER:
      return {
        ...state,
        currentUser: null,
        accessToken: null,
      };
    case SET_MENUS:
      return {
        ...state,
        menus: action.payload.menus,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload.count,
      };
    default:
      return state;
  }
};

const setActiveReducer = (state = activeInitialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload.menu,
      };
    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProjectIDs: action.payload.projectIds,
        activeProjectTitle: action.payload.projectTitle,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTabId: action.payload.activeTabId,
      };
    case SET_ACTIVE_MENU_PERMISSIONS:
      return {
        ...state,
        activeMenuPermissions: action.payload.activeMenuPermissions,
      };
    case SET_ACTIVE_COMPONENT_CAST:
      return {
        ...state,
        listTypeCast: action.payload.listTypeCast,
      };
    case SET_COSTUME_LEFT_ACTIVE_TAB:
      return {
        ...state,
        costumeLeftActiveTab: action.key,
      };
    case SET_MAKEUP_LEFT_ACTIVE_TAB:
      return {
        ...state,
        makeupLeftActiveTab: action.key,
      };
    case OPEN_NOTIFICATION_BAR:
      return {
        ...state,
        isNotificationBarVisible: !state.isNotificationBarVisible,
      };

    default:
      return state;
  }
};

export const sceneReducer = (state = sceneInitialState, action) => {
  switch (action.type) {
    case SET_SCENE_NUMBERS:
      return {
        ...state,
        scenes: action.payload.sceneNumbers,
      };
    case SEt_ACTIVE_SCENE_ID:
      return {
        ...state,
        activeSceneId: action.payload.activeSceneId,
      };
    case SET_ACTIVE_SCENE_LOCATION_ID:
      return {
        ...state,
        activeSceneLocationId: action.payload.activeSceneLocationId,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  fontStyle: fontReducer,
  activeItems: setActiveReducer,
  scene: sceneReducer,
});

export default rootReducer;
