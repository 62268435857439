import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Button, Row, Col, Select, Tag } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import CostumesMakeupService from "services/CostumesMakeupServices";
import {
  Services,
  LocalStorage,
  GlobalConstants,
  Screens,
} from "constants/AppConstants";
import { Link } from "react-router-dom";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, Capitalize } from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import TooltipIcon from "components/shared/TooltipIcon";
import TooltipComponent from "components/shared/TooltipComponent";

class Costumes extends Component {
  constructor(props) {
    super(props);
    this._costumeService = new CostumesMakeupService();
  }

  state = {
    visible: false,
    dataSource: [],
    dataFetched: false,
    type: null,
    isModalVisible: false,
    budgetId: null,
    recordData: null,
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  componentDidMount() {
    this.getCostumesList();
  }

  getCostumesList = () => {
    const { viewBy } = this.props;
    this._costumeService
      .service(
        Services.CostumesMakeupVariables.GET_COSTUMES_LIST,
        null,
        null,
        viewBy
      )
      .then((res) => {
        this.setState({ dataSource: res.data.costumes, dataFetched: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { activePermisision, viewBy } = this.props;
    let columns = [
      {
        title: "#",
        dataIndex: "sNo",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Character",
        dataIndex: "characterName",
        width: "30%",
        // align: "center",
        render: (text, record) => (
          <span>{Capitalize(record.characterName || record.generalInfo)}</span>
        ),
      },
      {
        title: "Actor",
        dataIndex: "actor",
        width: "30%",
        // align: "center",
        render: (text) => text && Capitalize(text),
      },
      {
        title: "Scene Number",
        dataIndex: "scenes",
        width: "15%",
        // align: "center",
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.COSTUMES
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                      // this.props.setActiveTab("1");
                    }}
                  >
                    {/* <Link style={{ color: "white" }}>{scene.sceneNumber}</Link> */}
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },
    ];

    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_EDIT)
    ) {
      columns.push({
        title: "Action",
        dataIndex: "action",
        align: "center",
        render: (text, record) =>
          record.characterId !== 0 && (
            <div
              onClick={() =>
                this.props.history.push(
                  `/project/charactercostumedetails/${viewBy}/${record.characterId}/${record.characterName}`
                )
              }
            >
              <Link>
                <TooltipIcon icon="edit" />
              </Link>
            </div>
          ),
      });
    }

    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_FINANCE)
    ) {
      columns.splice(4, 0, {
        title: "Total Cost (₹)",
        dataIndex: "specialCost",
        align: "right",
        width: "15%",
        className: "cash",
      });
    }

    const { dataFetched } = this.state;

    return (
      <Row>
        {/* <Col xs={24}>
          <Title>
            <TitleText>Costume</TitleText>
          </Title>
        </Col> */}
        <Col xs={24}>
          <br></br>
          <Row type="flex" justify="center">
            <Col xl={24}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
                <Table
                  columns={columns}
                  dataSource={this.state.dataSource}
                  loading={!dataFetched}
                  // scroll={{ y: "50vh" }}
                  pagination={false}
                  locale={{
                    emptyText: dataFetched && <RecordNotFound />,
                  }}
                  bordered
                />
              ) : (
                <ContactAdminErrorTemplate />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Costume", menus),
  };
};

export default connect(mapStateToProps)(Costumes);
