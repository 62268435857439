import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getUserAPIURL, getScriptAPIURL } from "utilities/APIHelper";

export default class LocationServices {
  service(type, data, id, fromDate, toDate, availabilityId) {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    let activeProject = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    switch (type) {
      case Services.LocationVariables.GET_LOCATION_LIST:
        return axios.get(
          `${getScriptAPIURL()}/projects/${activeProject}/locations`,
          config
        );
      case Services.LocationVariables.GET_LOCATION_BY_SHOOT_DATE:
        return axios.get(
          `${getScriptAPIURL()}/project/${activeProject}/locations/production?shootDate=${data}`,
          config
        );
      case Services.LocationVariables.GET_LOCATION_LIST_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/project/${activeProject}/scene/locations`,
          config
        );
      case Services.LocationVariables.LOCATION_DELETE_DOC:
        return axios.delete(`${getScriptAPIURL()}/locations/documents/${data}`);
      case Services.LocationVariables.DELETE_LOCATION:
        return axios.delete(
          `${getScriptAPIURL()}/projects/${activeProject}/scene/locations/${data}`,
          config
        );
      case Services.LocationVariables.UPDATE_LOCATION_NAME:
        return axios.patch(
          `${getScriptAPIURL()}/scenelocation/${id}`,
          data,
          config
        );
      case Services.LocationVariables.PUT_LOCATION_AVAILABILITY_DETAILS:
        return axios.put(
          `${getScriptAPIURL()}/locations/${id}/availability/${availabilityId}`,
          data,
          config
        );
      case Services.LocationVariables.CHECK_SHOOT_DATE:
        return axios.get(
          `${getScriptAPIURL()}/project/${activeProject}/availability/shootdates?locationId=${id}&fromDate=${fromDate}${
            fromDate ? `&toDate=${toDate}` : ""
          }`,
          config
        );
      case Services.LocationVariables.DELETE_LOCATION_AVAILABILITY:
        return axios.delete(
          `${getScriptAPIURL()}/locations/availability/${id}`,
          config
        );
      case Services.LocationVariables.POST_LOCATION_AVAILABILITY:
        return axios.post(
          `${getScriptAPIURL()}/locations/availability`,
          data,
          config
        );
      default:
        break;
    }
  }
}
