import React, { useState } from "react";
import "./ActorLegal.scss";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  Col,
  Form,
  Select,
  Input,
  Row,
  Switch,
  Button,
  Table,
  Icon,
} from "antd";
import {
  GlobalConstants,
  LocalStorage,
  Notifications,
  Services,
} from "constants/AppConstants";
import { useEffect } from "react";
import GlobalService from "services/GlobalService";
import MultipleUpload from "components/MultipleUpload/MultipleUpload";
import { checkFor } from "utilities/ValidationHelper";
import { useSelector } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { OnboardServices } from "services";
import ActorsServices from "services/ActorsServices";
import { showNotifications } from "components/shared/NotificationComponent";
import Loader from "components/shared/Loader";
import DeleteComponent from "components/shared/DeleteComponent";
import RecordNotFound from "components/shared/RecordNotFound";
import { generateRandomName } from "utilities/UserHelper";
import { useHistory } from "react-router-dom";

const ActorLegal = ({ form, match }) => {
  const isActorProfile = match?.params?.legalAgreementType?.toLowerCase() === 'actor' || false;
  const isVendorProfile = match?.params?.legalAgreementType?.toLowerCase() === 'vendor' || false;
  const isLocationProfile = match?.params?.legalAgreementType?.toLowerCase() === 'location' || false;
  const isCrewProfile = match?.params?.legalAgreementType?.toLowerCase() === 'crew' || false;
  const characterName = match?.params?.characterName || null;
  const name = match?.params?.name || null;
  const id = match?.params?.id || null;
  let legalAgreementId = match?.params?.legalAgreementId || null;
  const rumeneration = match?.params?.rumeneration || null;
  const paymentType = match?.params?.paymentType || null;
  const userState = useSelector((state) => state?.user);
  const activePermisision = getSPermissionFromMenus(
    "Char-Actor",
    userState?.menus || []
  );
  const { getFieldDecorator } = form;
  const { Option } = Select;
  const { TextArea } = Input;
  const globalService = new GlobalService();
  const actorService = new ActorsServices();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [countryTypeData, setCountryTypeData] = useState([]);
  const [phoneCodeTypeData, setPhoneCodeTypeData] = useState([]);
  const [actorSalutationTypeData, setActorSalutationTypeData] = useState([]);
  const [actorAgreementTypeData, setActorAgreementTypeData] = useState([]);
  const [GSTTypeData, setGSTTypeData] = useState([]);
  const [actorPaymentTypeData, setActorPaymentTypeData] = useState([]);
  const [TDSTypeData, setTDSTypeData] = useState([]);
  const [designationTypeData, setDesignationTypeData] = useState([]);
  const [staffPaymentTypeData, setStaffPaymentTypeData] = useState([]);
  const [formData, setFormData] = useState({
    isStaffRequired: null,
    ifscCode: null,
    bankName: null,
    bankAccountNumber: null,
    branchName: null,
    attachments: [],
    salutation: "MR",
    partyName: null,
    phCountryCode: "+91",
    phoneNumber: null,
    email: null,
    cautionDeposit: null,
    shootDay: null,
    workDay: null,
    role: null,
    authorizedSignatory:null,
    gender: "MALE",
    fatherName: null,
    agreementType: null,
    rumeneration: rumeneration,
    paymentType: paymentType,
    address: {
      addressLine: null,
      city: null,
      zipCode: null,
      state: null,
      country: null,
    },
    tax: null,
    tds: null,
    upiId: null,
    gpayNumber: null,
    notes: null,
    panNumber: null,
    panCopyId: null,
    panImage: null,
    panFileType: null,
    gstCopyId: null,
    gstImage: null,
    gstFileType: null,
    gstNumber: null,
    aadharNumber: null,
    aadharCopyId: null,
    aadharFileType: null,
    aadharImage: null,
    legalAgreementDocments: [],
    projectActorId: id,
    vendorType: null,
  });
  const [staffDetails, setStaffDetails] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);

  if (legalAgreementId === "null") {
    legalAgreementId = null;
  }

  useEffect(() => {
    if (id) {
      getActorPaymentTypeData();
      getActorSalutationTypeData();
      getActorAgreementTypeData();
      getGSTTypeData();
      getTDSData();
      getCountries();
      getDesignationTypeData();
      getStaffPaymentTypeData();
      if (legalAgreementId) {
        getLegalDocumentData();
      }
    }
  }, []);

  const getCountries = () => {
    const onBoardService = new OnboardServices();
    onBoardService
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        setCountryTypeData(res.data.countries);
        setPhoneCodeTypeData(res.data.countries);
      });
  };

  const getLegalDocumentData = () => {
    setLoading(true);
    return actorService
      .actorsServices(
        Services.ActorVariables.LEGAL_AGREEMENT_GET,
        legalAgreementId,
        null,
        null
      )
      .then((res) => {
        const data = res?.data;
        setLoading(false);
        setFormData({
          ...formData,
          partyName: data?.partyName,
          remuneration: data?.remuneration,
          paymentType: data?.paymentType,
          characterType: data?.characterType,
          phoneCode: data?.phoneCode,
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          gender: data?.gender,
          address: data?.address,
          fatherName: data?.fatherName,
          agreementType: data?.agreementType,
          bankName: data?.bankName,
          bankAccountNumber: data?.bankAccountNumber,
          ifscCode: data?.ifscCode,
          upiId: data?.upiId,
          branchName: data?.branchName,
          gpayNumber: data?.gpayNumber,
          notes: data?.notes,
          tax: data?.tax,
          tds: data?.tds,
          panNumber: data?.pan,
          panCopyId: data?.panImage?.attachmentId,
          panImage: data?.panImage || null,
          gstImage: data?.gstImage || null,
          gstCopyId: data?.gstImage?.attachmentId,
          aadharImage: data?.aadharImage || null,
          gstNumber: data?.gst,
          aadharNumber: data?.aadhar,
          aadharCopyId: data?.aadharImage?.attachmentId,
          isStaffRequired: data?.isStaffRequired,
          legalAgreementDocments: data?.legalAgreementDocments || [],
          projectActorId: id,
          shootDay: data?.shootDay,
          workDay: data?.workDay,
          cautionDeposit: data?.cautionDeposit,
          role : data?.role,
          authorizedSignatory: data?.authorizedSignatory,
          vendorType: data?.vendorType,
        });
        if (data?.actorStaff) {
          const newStaffDetails = data?.actorStaff?.map((a) => {
            a.id = generateRandomName();
            return a;
          });
          setStaffDetails(newStaffDetails || []);
        } else {
          setStaffDetails([]);
        }
        if (data?.legalAgreementPayments) {
          const newPaymentTerms = data?.legalAgreementPayments?.map((a) => {
            a.localId = generateRandomName();
            return a;
          });
          setPaymentTerms(newPaymentTerms || []);
        } else {
          setPaymentTerms([]);
        }
      })
      .catch(() => {
        // history.goBack()
      });
  };

  const getGSTTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.GST_TAX
      )
      .then((res) => {
        setGSTTypeData(res?.data || []);
      });
  };

  const getActorAgreementTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.AGREEMENT_TYPE
      )
      .then((res) => {
        setActorAgreementTypeData(res?.data || []);
      });
  };

  const getActorSalutationTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SALUTATION_TYPE
      )
      .then((res) => {
        setActorSalutationTypeData(res?.data || []);
      });
  };

  const getActorPaymentTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ACTOR_PAYMENT_TYPE
      )
      .then((res) => {
        setActorPaymentTypeData(res?.data || []);
      });
  };

  const getTDSData = () => {
    globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "TDS_TAX")
      .then((res) => {
        setTDSTypeData(res.data || []);
      });
  };

  const getDesignationTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA_RATE,
        "DESIGNATION"
      )
      .then((res) => {
        setDesignationTypeData(res.data || []);
      });
  };

  const getStaffPaymentTypeData = () => {
    globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA_RATE,
        "STAFF_PAYMENT"
      )
      .then((res) => {
        setStaffPaymentTypeData(res.data || []);
      });
  };

  const getBranchName = () => {
    fetch(`https://ifsc.razorpay.com/${formData?.ifscCode}`)
      .then((res) => res.json())
      .then((json) => {
        setFormData({ ...formData, branchName: json?.BRANCH });
      });
  };

  const deleteAttachement = (data) => {
    setLoading(true);
    return actorService
      .actorsServices(
        Services.ActorVariables.LEGAL_AGREEMENT_DOCUMENT_DELETE,
        data?.entityId,
        null,
        null
      )
      .then((res) => {
        setLoading(false);
        getLegalDocumentData();
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Legal Agreement Attachement Deleted!"
        );
      })
      .catch(() => {
        setLoading(false);
        getLegalDocumentData();
        showNotifications(
          Notifications.ERROR,
          "Error",
          "Legal Agreement Attachement Not Deleted!"
        );
      });
  };

  const saveAttachement = (data) => {
    setLoading(true);
    const payload = {
      legalAgreementDocments: data,
    };
    return actorService
      .actorsServices(
        Services.ActorVariables.LEGAL_AGREEMENT_DOCUMENT_SAVE,
        legalAgreementId,
        null,
        payload
      )
      .then(() => {
        getLegalDocumentData();
        setLoading(false);
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Legal Agreement Attachement Saved!"
        );
      })
      .catch(() => {
        getLegalDocumentData();
        setLoading(false);
        showNotifications(
          Notifications.ERROR,
          "Error",
          "Legal Agreement Attachement Not Saved!"
        );
      });
  };

  const phoneCodeDropDown = (
    <Select
      defaultValue={"INR"}
      value={formData?.phCountryCode}
      onChange={(e) =>
        setFormData({
          ...formData,
          phCountryCode: e,
        })
      }
    >
      {phoneCodeTypeData.map((a) => {
        return <Option value={a?.code}>{a?.phoneCode}</Option>;
      })}
    </Select>
  );

  const salutationDropDown = (
    <Select
      defaultValue={"MR"}
      value={formData?.salutation}
      onChange={(e) =>
        setFormData({
          ...formData,
          salutation: e,
        })
      }
    >
      {actorSalutationTypeData.map((a) => {
        return <Option value={a?.value}>{a?.label}</Option>;
      })}
    </Select>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (err === null) {
        const payload = {
          gender: formData?.gender,
          fatherName: formData?.fatherName,
          agreementType: formData?.agreementType,
          partyName: formData?.partyName,
          salutation: formData?.salutation,
          phoneNumber: formData?.phoneNumber,
          phCountryCode: formData?.phCountryCode,
          email: formData?.email,
          projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
          ...(isLocationProfile && {locationAuditionId: id}),
          ...(isVendorProfile && {projectVendorId: id}),
          ...(isActorProfile && {projectActorId: id}),
          tax: formData?.tax,
          tds: formData?.tds,
          address: {
            addressLine: formData?.address?.addressLine,
            city: formData?.address?.city,
            zipCode: formData?.address?.zipCode,
            state: formData?.address?.state,
            country: formData?.address?.country,
          },
          bankAccountNumber: formData?.bankAccountNumber,
          bankName: formData?.bankName,
          ifscCode: formData?.ifscCode,
          branchName: formData?.branchName,
          upiId: formData?.upiId,
          gpayNumber: formData?.gpayNumber,
          notes: formData?.notes,
          isStaffRequired: formData?.isStaffRequired,
          legalAgreementDocments: formData?.legalAgreementDocments || [],
          panNumber: formData?.panNumber,
          panCopyId: formData?.panCopyId,
          gstNumber: formData?.gstNumber,
          gstCopyId: formData?.gstCopyId,
          aadharNumber: formData?.aadharNumber,
          aadharCopyId: formData?.aadharCopyId,
          actorStaffs: staffDetails,
          legalAgreementPayments: paymentTerms,
          panFileType: formData?.panFileType,
          gstFileType: formData?.gstFileType,
          aadharFileType: formData?.aadharFileType,
          shootDay: formData?.shootDay,
          workDay: formData?.workDay,
          cautionDeposit: formData?.cautionDeposit,
          role: formData?.role,
          authorizedSignatory: formData?.authorizedSignatory,
          vendorType: formData?.vendorType,
        };
        if (legalAgreementId) {
          updateData(payload);
        } else {
          saveData(payload);
        }
      }
    });
  };

  const updateData = (payload) => {
    setLoading(true);
    actorService
      .actorsServices(
        Services.ActorVariables.LEGAL_AGREEMENT_UPDATE,
        legalAgreementId,
        null,
        payload
      )
      .then(() => {
        setLoading(false);
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Legal Agreement Updated!"
        );
        getLegalDocumentData();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const saveData = (payload) => {
    setLoading(true);
    actorService
      .actorsServices(
        Services.ActorVariables.LEGAL_AGREEMENT_SAVE,
        null,
        null,
        payload
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Legal Agreement Created!"
        );
        setLoading(false);
        history.goBack();
      })
      .catch(() => {
        setLoading(true);
      });
  };

  const addPaymentTerms = () => {
    const payload = {
      localId: generateRandomName(),
      legalAgreementId: null,
      percent: null,
      description: null,
      installmentIndex: paymentTerms.length + 1,
    };
    setPaymentTerms([...paymentTerms, payload]);
  };

  const addStaffDetails = () => {
    const payload = {
      id: generateRandomName(),
      staffId: null,
      designationId: null,
      designationName: null,
      payment: null,
      paymentType: null,
      legalAgreementId: null,
    };
    setStaffDetails([...staffDetails, payload]);
  };

  const changePaymentDetails = (record, type, data) => {
    const newPaymentTerms = paymentTerms.map((a) => {
      if (a?.localId === record.localId) {
        a[type] = data;
      }
      return a;
    });
    setPaymentTerms(newPaymentTerms);
  };

  const changeStaffDetails = (record, type, data) => {
    const newStaffDetails = staffDetails.map((a) => {
      if (a?.id === record.id) {
        a[type] = data;
      }
      return a;
    });
    setStaffDetails(newStaffDetails);
  };

  const deleteStaffDetails = (id) => {
    setLoading(true);
    const detail = staffDetails.filter((a) => a.id === id)[0];
    if (detail?.staffId) {
      actorService
        .actorsServices(
          Services.ActorVariables.DELETE_ACTOR_ID,
          detail?.staffId,
          null,
          null
        )
        .then(() => {
          setLoading(false);
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Legal Staff Deleted!"
          );
          getLegalDocumentData();
        })
        .catch(() => {
          setLoading(false);
          showNotifications(
            Notifications.ERROR,
            "Error",
            "Legal Staff Not Deleted!"
          );
        });
    } else {
      const newStaffDetails = staffDetails.filter((a) => a.id !== id);
      setStaffDetails([]);
      setLoading(true);
      setTimeout(() => {
        setStaffDetails(newStaffDetails);
        setLoading(false);
      }, 500);
    }
  };

  const deletePaymentTerms = (id) => {
    const data = paymentTerms.filter((a) => a.localId === id)[0];
    if (data?.id) {
      actorService
        .actorsServices(
          Services.ActorVariables.DELETE_ACTOR_PAYMENT_TERMS_ID,
          data?.id,
          null,
          null
        )
        .then(() => {
          setLoading(false);
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Legal Payment Terms Deleted!"
          );
          getLegalDocumentData();
        })
        .catch(() => {
          setLoading(false);
          showNotifications(
            Notifications.ERROR,
            "Error",
            "Legal Payment Terms Not Deleted!"
          );
        });
    } else {
      const data = paymentTerms.filter((a) => a.localId !== id);
      setLoading(true);
      setPaymentTerms([]);
      setTimeout(() => {
        setPaymentTerms(data);
        setLoading(false);
      }, 100);
    }
  };

  const staffColumns = [
    {
      title: "#",
      align: "center",
      key: "key",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      align: "left",
      render: (text, record) => (
        <Select
          showSearch
          placeholder="Select Designation"
          onChange={(value) =>
            changeStaffDetails(record, "designationId", value)
          }
          value={record.designationId}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {designationTypeData?.map((option) => {
            return <Option value={option.value}>{option.label}</Option>;
          })}
        </Select>
      ),
    },
    {
      title: "Rate",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text, record) => (
        <Input
          type="number"
          defaultValue={text}
          onChange={(e) =>
            changeStaffDetails(record, "payment", e.target.value)
          }
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      align: "left",
      render: (text, record) => (
        <Select
          showSearch
          placeholder="Select Payment Type"
          onChange={(value) => changeStaffDetails(record, "paymentType", value)}
          value={record.paymentType}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {staffPaymentTypeData?.map((option) => {
            return <Option value={option.value}>{option.label}</Option>;
          })}
        </Select>
      ),
    },
  ];

  if (
    checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
    checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)
  ) {
    staffColumns.push({
      title: "ACTION",
      align: "center",
      render: (text, record) => (
        <>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
            <DeleteComponent
              record={record}
              onClick={(record) => deleteStaffDetails(record?.id)}
            />
          )}
        </>
      ),
    });
  }

  const paymentTermsColumns = [
    {
      title: "Installment",
      align: "center",
      key: "key",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      align: "left",
      width: "10%",
      render: (text, record) => (
        <>
          <Input
            type="number"
            defaultValue={text}
            min={0}
            onChange={(e) =>
              changePaymentDetails(record, "percentage", e.target.value)
            }
            style={{ width: "100%" }}
          />
          {text === "" && (
            <label style={{ color: "red" }} className="label">
              This field is required
            </label>
          )}
          {text > 100 && (
            <label style={{ color: "red" }} className="label">
              Should not be greater than 100
            </label>
          )}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      render: (text, record) => (
        <Input
          defaultValue={text}
          onChange={(e) =>
            changePaymentDetails(record, "description", e.target.value)
          }
          style={{ width: "100%" }}
        />
      ),
    },
  ];

  if (
    checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
    checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)
  ) {
    paymentTermsColumns.push({
      title: "ACTION",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
            <DeleteComponent
              record={record}
              onClick={(record) => deletePaymentTerms(record?.localId)}
            />
          )}
        </>
      ),
    });
  }

  const checkTotalPaymentPercentage = () => {
    if (
      paymentTerms.reduce(
        (acc, item) => Number(acc || 0) + Number(item.percentage || 0),
        0
      ) <= 100
    ) {
      return false;
    }
    return true;
  };

  const checkPaymentTermsValidation = () => {
    const emptyCount = paymentTerms.filter((item) => item.percentage === "");
    if (emptyCount.length !== 0) {
      return true;
    }
    if (checkTotalPaymentPercentage()) {
      return true;
    }
    return false;
  };

  return (
    <div className="actor-legal-wrapper">
      <Title hasBack history={history}>
        <TitleText>
          {isActorProfile && `Actor Legal - ${characterName} - ${name}`}
          {isLocationProfile && `Location Legal - ${name} - ${name}`}
          {isVendorProfile && `Vendor Legal info - ${name}`}
          {isCrewProfile && `Crew Legal info - ${name}`}
        </TitleText>
      </Title>
      {loading ? (
        <Loader />
      ) : (
        <div className="content">
          <Form
            layout="inline"
            onSubmit={handleSubmit}
            form={form}
            scrollToFirstError={true}
          >
            <Row>
              { isActorProfile &&
              <Col span={8} className="form-input">
                <label className="label">*Offical Name</label>
                <Form.Item>
                  {getFieldDecorator("partyName", {
                    initialValue: formData?.partyName,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      addonBefore={salutationDropDown}
                      placeholder="Offical Name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          partyName: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>}
              {isLocationProfile &&
                <Col span={8} className="form-input">
                <label className="label">*Location POC Name</label>
                <Form.Item>
                  {getFieldDecorator("partyName", {
                    initialValue: formData?.partyName,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      addonBefore={salutationDropDown}
                      placeholder="Location POC Name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          partyName: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              }
              { isVendorProfile &&
                <Col span={8} className="form-input">
                <label className="label">*Company Name</label>
                <Form.Item>
                  {getFieldDecorator("partyName", {
                    initialValue: formData?.partyName,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      placeholder="Company Name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          partyName: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              }
              { isCrewProfile &&
                <Col span={8} className="form-input">
                <label className="label">*Name</label>
                <Form.Item>
                  {getFieldDecorator("partyName", {
                    initialValue: formData?.partyName,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      placeholder="Company Name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          partyName: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              }
              {isCrewProfile && <Col span={8} className="form-input">
                <label className="label">*Role</label>
                <Form.Item>
                  {getFieldDecorator("role", {
                    initialValue: formData?.partyName,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      placeholder="Role"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          role: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>}
              <Col span={8} className="form-input">
                <label className="label">*Phone Number</label>
                <Form.Item>
                  {getFieldDecorator("phoneNumber", {
                    initialValue: formData?.phoneNumber,
                    rules: [
                      { required: true, message: "This field is required!" },
                      { pattern: ".{10,}", message: "Enter 10 Numbers Only!" },
                    ],
                  })(
                    <Input
                      addonBefore={phoneCodeDropDown}
                      type="text"
                      pattern="[0-9]*"
                      placeholder="Phone Number"
                      maxLength={10}
                      minLength={10}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">*Email</label>
                <Form.Item>
                  {getFieldDecorator("email", {
                    initialValue: formData?.email,
                    rules: [
                      { required: true, message: "This field is required!" },
                      {
                        pattern:
                          "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
                        message: "Invalid Email!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Email"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {isLocationProfile  && <Row>
            <Col span={8} className="form-input">
                <label className="label">Caution Deposit</label>
                <Form.Item>
                  {getFieldDecorator("cautionDeposit", {
                    initialValue: formData?.cautionDeposit,
                  })(
                    <Input
                      placeholder="Caution Deposit"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          cautionDeposit: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">*Rent/Hire (Shoot Day)</label>
                <Form.Item>
                  {getFieldDecorator("shootDay", {
                    initialValue: formData?.shootDay,
                    rules: [
                      { required: true, message: "This field is required!" }
                    ],
                  })(
                    <Input
                      placeholder="Rent/Hire Shoot Day"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          shootDay: e?.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">Rent/Hire (Set Work Day)</label>
                <Form.Item>
                  {getFieldDecorator("workDay", {
                    initialValue: formData?.workDay                   
                  })(
                    <Input
                      placeholder="Rent/Hire Set Work Day"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          workDay: e?.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>}
            {isCrewProfile && <Row>
            <Col span={8} className="form-input">
                <label className="label">*Authorized Singnatory</label>
                <Form.Item>
                  {getFieldDecorator("authorizedSignatory", {
                    initialValue: formData?.authorizedSignatory,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      addonBefore={salutationDropDown}
                      placeholder="Authorized Signatory"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          authorizedSignatory: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>}
            <Row>
              {
                isActorProfile &&
                <Col span={8} className="form-input">
                  <label className="label">Gender</label>
                  <Form.Item>
                    {getFieldDecorator("gender", {
                      initialValue: formData?.gender || "",
                    })(
                      <Select
                        placeholder="Gender"
                        style={{ width: "100%" }}
                        value={formData?.gender}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            gender: e,
                          })
                        }
                      >
                        <Option value={"MALE"}>Male</Option>
                        <Option value={"FEMALE"}>Female</Option>
                        <Option value={"OTHERS"}>Others</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              }{
               isActorProfile && 
              <Col span={8} className="form-input">
                <label className="label">Father's Name</label>
                <Form.Item>
                  {getFieldDecorator("fatherName", {
                    initialValue: formData?.fatherName,
                  })(
                    <Input
                      placeholder="Father's Name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fatherName: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              }
              {isActorProfile &&
              <Col span={8} className="form-input">
                <label className="label">*Agreement Type</label>
                <Form.Item>
                  {getFieldDecorator("agreementType", {
                    initialValue: formData?.agreementType,
                    rules: [
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Agreement Type"
                      style={{ width: "100%" }}
                      value={formData?.agreementType}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          agreementType: e,
                        })
                      }
                    >
                      {actorAgreementTypeData?.map((a) => {
                        return <Option value={a?.value}>{a?.label}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>}
            </Row>
            <Row>
              {isVendorProfile && <Col span={8} className="form-input">
                <label className="label">*Vendor Type</label>
                <Form.Item>
                  {getFieldDecorator("vendorType", {
                    initialValue: formData?.vendorType || "",
                    rules: [
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Vendor Type"
                      style={{ width: "100%" }}
                      disabled={true}
                    >
                      {actorPaymentTypeData.map((option, i) => (
                        <Option key={i} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>}
              { (isActorProfile  || isVendorProfile || isCrewProfile) &&
              <Col span={8} className="form-input">
                <label className="label"> *{isCrewProfile ? 'Salary' : 'Remuneration'}</label>
                <Form.Item>
                  {getFieldDecorator("remuneration", {
                    initialValue: formData?.rumeneration || "",
                    rules: [
                      { required: true, message: "This field is required!" },
                      { pattern: "^[0-9]+$", message: "Enter Numbers Only!" },
                    ],
                  })(
                    <Input
                      placeholder="Remuneration"
                      maxLength={100}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
              }
              <Col span={8} className="form-input">
                <label className="label">*Payment Type</label>
                <Form.Item>
                  {getFieldDecorator("paymentType", {
                    initialValue: formData?.paymentType || "",
                    rules: [
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Payment Type"
                      style={{ width: "100%" }}
                      disabled={true}
                    >
                      {actorPaymentTypeData.map((option, i) => (
                        <Option key={i} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">*Zipcode</label>
                <Form.Item>
                  {getFieldDecorator("zipCode", {
                    initialValue: formData?.address?.zipCode,
                    rules: [
                      { required: true, message: "This field is required!" },
                      { pattern: "^[0-9]+$", message: "Enter Numbers Only." },
                      { len: 6, message: " Invalid Zipcode!" },
                    ],
                  })(
                    <Input
                      placeholder="ZipCode"
                      maxLength={100}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          address: {
                            ...formData?.address,
                            zipCode: e.target.value,
                          },
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="form-input">
                <label className="label">*Country</label>
                <Form.Item>
                  {getFieldDecorator("country", {
                    initialValue: formData?.address?.country,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Select
                      showSearch={true}
                      value={formData?.address?.country}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          address: {
                            ...formData?.address,
                            country: e,
                          },
                        });
                      }}
                    >
                      {countryTypeData.map((a) => {
                        return <Option value={a?.code}>{a?.name}</Option>;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">*State</label>
                <Form.Item>
                  {getFieldDecorator("state", {
                    initialValue: formData?.address?.state,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      placeholder="State"
                      maxLength={100}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          address: {
                            ...formData?.address,
                            state: e?.target?.value,
                          },
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">*City</label>
                <Form.Item>
                  {getFieldDecorator("city", {
                    initialValue: formData?.address?.city,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <Input
                      placeholder="City"
                      maxLength={100}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          address: {
                            ...formData?.address,
                            city: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} className="form-input">
                <label className="label">*Address</label>
                <Form.Item>
                  {getFieldDecorator("addressLine", {
                    initialValue: formData?.address?.addressLine,
                    rules: [
                      { required: true, message: "This field is required!" },
                    ],
                  })(
                    <TextArea
                      placeholder="Address"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          address: {
                            ...formData?.address,
                            addressLine: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              {isActorProfile &&
              <Col span={8} className="form-input">
                <label className="label">*Staff</label>
                <Form.Item>
                  {getFieldDecorator("staff", {
                    initialValue: formData?.isStaffRequired,
                  })(
                    <Switch
                      checked={formData?.isStaffRequired}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          isStaffRequired: !formData?.isStaffRequired,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>}
            </Row>
            <Row gutter={16}>
              {formData?.isStaffRequired && isActorProfile &&(
                <Col span={12}>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <label className="label">Staff Details</label>
                      <Button onClick={() => addStaffDetails()} type="primary">
                        <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                      </Button>
                    </Col>
                    <Col className="form-input">
                      <Table
                        dataSource={staffDetails}
                        columns={staffColumns}
                        pagination={false}
                        locale={{
                          emptyText: <RecordNotFound showImage={false} />,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {paymentType === "CONTRACT" && (isActorProfile || isCrewProfile || isVendorProfile) && (
                <Col span={12}>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <label className="label">Payment Terms</label>
                      <Button onClick={() => addPaymentTerms()} type="primary">
                        <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                      </Button>
                    </Col>
                    <Col className="form-input">
                      <Table
                        dataSource={paymentTerms}
                        columns={paymentTermsColumns}
                        pagination={false}
                        locale={{
                          emptyText: <RecordNotFound showImage={false} />,
                        }}
                      />
                      {checkTotalPaymentPercentage() && (
                        <label className="label" style={{ color: "red" }}>
                          Payment Terms should to greater than 100 percentage
                        </label>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={5} className="form-input">
                <label className="label">PAN</label>
                <Form.Item>
                  {getFieldDecorator("panNumber", {
                    initialValue: formData?.panNumber,
                    rules: [
                      {
                        pattern: new RegExp(
                          "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                        ),
                        message:
                          "Please Enter Correct Pan Format Ex: JSDFG1234H ",
                      },
                    ],
                  })(
                    <Input
                      placeholder="PAN"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          panNumber: e?.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <MultipleUpload
                  showSmall={true}
                  existingData={formData?.panImage ? [formData?.panImage] : []}
                  multiple={false}
                  accept="image/*, .pdf, .doc"
                  morePreview={false}
                  onDelete={() => {
                    setFormData({
                      ...formData,
                      panCopyId: null,
                      panImage: null,
                    });
                  }}
                  uploadedAttachement={(e) => {
                    setFormData({
                      ...formData,
                      panImage: e,
                      panCopyId: e?.attachmentId,
                      panFileType: e?.fileType,
                    });
                  }}
                />
              </Col>
              <Col span={5} className="form-input">
                <label className="label">GST</label>
                <Form.Item>
                  {getFieldDecorator("gstNumber", {
                    initialValue: formData?.gstNumber,
                    rules: [
                      {
                        pattern: new RegExp(
                          "[0-3]{1}[0-9]{1}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-2]{1}[Zz]{1}[0-9A-Za-z]{1}"
                        ),
                        message:
                          "Please Enter Correct Gst Format Ex:22AAAAA0000A1Z5",
                      },
                    ],
                  })(
                    <Input
                      placeholder="GST"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          gstNumber: e?.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <MultipleUpload
                  showSmall={true}
                  existingData={formData?.gstImage ? [formData?.gstImage] : []}
                  multiple={false}
                  accept="image/*, .pdf, .doc"
                  morePreview={false}
                  onDelete={() => {
                    setFormData({
                      ...formData,
                      gstCopyId: null,
                      gstImage: null,
                    });
                  }}
                  uploadedAttachement={(e) => {
                    setFormData({
                      ...formData,
                      gstImage: e,
                      gstCopyId: e?.attachmentId,
                      gstFileType: e?.fileType,
                    });
                  }}
                />
              </Col>
              <Col span={5} className="form-input">
                <label className="label">Aadhaar</label>
                <Form.Item>
                  {getFieldDecorator("aadharNumber", {
                    initialValue: formData?.aadharNumber,
                  })(
                    <Input
                      placeholder="Aadhaar"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          aadharNumber: e?.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <MultipleUpload
                  showSmall={true}
                  existingData={
                    formData?.aadharImage ? [formData?.aadharImage] : []
                  }
                  multiple={false}
                  accept="image/*, .pdf, .doc"
                  morePreview={false}
                  onDelete={() => {
                    setFormData({
                      ...formData,
                      aadharCopyId: null,
                      aadharFileType: null,
                    });
                  }}
                  uploadedAttachement={(e) => {
                    setFormData({
                      ...formData,
                      aadharImage: e,
                      aadharCopyId: e?.attachmentId,
                      aadharFileType: e?.fileType,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8} className="form-input">
                <label className="label">Tax %</label>
                <Form.Item>
                  {getFieldDecorator("tax", {
                    initialValue: formData?.tax,
                  })(
                    <Select
                      placeholder="Tax %"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          tax: e,
                        })
                      }
                    >
                      {GSTTypeData.map((option, i) => (
                        <Option key={i} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">TDS %</label>
                <Form.Item>
                  {getFieldDecorator("tds", { initialValue: formData?.tds })(
                    <Select
                      placeholder="TDS %"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          tds: e,
                        })
                      }
                    >
                      {TDSTypeData.map((option, i) => (
                        <Option key={i} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">Number of Shoot days</label>
                <Form.Item>
                  <Input
                    type="number"
                    placeholder="Number of Shoot days"
                    // onChange={(e) =>
                    //   setFormData({
                    //     ...formData,
                    //     tds: e,
                    //   })
                    // }
                  />
                  {/* <Form.Item>
                  {getFieldDecorator("tds", { initialValue: formData?.tds })(
                   
                  )}
                </Form.Item> */}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="form-input">
                <label className="label">Bank Name</label>
                <Form.Item>
                  {getFieldDecorator("bankName", {
                    initialValue: formData?.bankName,
                  })(
                    <Input
                      placeholder="Bank Name"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bankName: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">Bank Account Number</label>
                <Form.Item>
                  {getFieldDecorator("bankAccountNumber", {
                    initialValue: formData.bankAccountNumber,
                    rules: [
                      {
                        pattern: "[0-9]{9,18}",
                        message: "Invalid Bank Account Number",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Bank Account Number"
                      maxLength={16}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bankAccountNumber: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">IFSC Code</label>
                <Form.Item>
                  {getFieldDecorator("ifscCode", {
                    initialValue: formData?.ifscCode,
                    rules: [
                      {
                        pattern: "[A-Za-z]{4}[0-9]{7}",
                        message: "Invalid IFSC Code",
                      },
                    ],
                  })(
                    <Input
                      value={formData?.ifscCode}
                      placeholder="IFSC Code"
                      maxLength={11}
                      onBlur={() => getBranchName()}
                      onChange={(e) =>
                        setFormData({ ...formData, ifscCode: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} className="form-input">
                <label className="label">Branch Name</label>
                <Form.Item>
                  {getFieldDecorator("branchName", {
                    initialValue: formData?.branchName,
                  })(
                    <Input
                      placeholder="Branch Name"
                      onChange={(e) =>
                        setFormData({ ...formData, branchName: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">UPI ID</label>
                <Form.Item>
                  {getFieldDecorator("upiId", {
                    initialValue: formData?.upiId,
                  })(
                    <Input
                      placeholder="UPI ID"
                      maxLength={40}
                      onChange={(e) =>
                        setFormData({ ...formData, upiId: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className="form-input">
                <label className="label">GPay Number</label>
                <Form.Item>
                  {getFieldDecorator("gPayNumber", {
                    initialValue: formData.gpayNumber,
                    rules: [
                      { pattern: ".{10,}", message: "Enter 10 Numbers Only!" },
                    ],
                  })(
                    <Input
                      type="text"
                      pattern="[0-9]*"
                      placeholder="GPay Number"
                      maxLength={10}
                      minLength={10}
                      onChange={(e) =>
                        setFormData({ ...formData, gpayNumber: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={16} className="form-input">
                <label className="label">Notes</label>
                <Form.Item>
                  {getFieldDecorator("notes", {
                    initialValue: formData.notes,
                  })(
                    <TextArea
                      placeholder="Notes"
                      maxLength={4000}
                      onChange={(e) =>
                        setFormData({ ...formData, notes: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {isActorProfile && isCrewProfile && isVendorProfile &&
              <Col span={16} className="form-input">
                <label className="label">Legal Attachments</label>
                <MultipleUpload
                  accept="image/*, .pdf, .doc, .txt"
                  multiple={true}
                  existingData={formData?.legalAgreementDocments || []}
                  sendNewDataOnly={true}
                  uploadedAttachement={(e) => {
                    setFormData({
                      ...formData,
                      legalAgreementDocments: e,
                    });
                    if (legalAgreementId) {
                      saveAttachement(e);
                    }
                  }}
                  morePreview={false}
                  onDelete={(e) => {
                    if (legalAgreementId) {
                      deleteAttachement(e);
                    }
                  }}
                />
              </Col>}
            </Row>
            <Row
              gutter={20}
              type="flex"
              justify="end"
              style={{ marginTop: "20px" }}
            >
              <Col>
                <Button
                  onClick={() => {
                    history?.goBack();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                {legalAgreementId ? (
                  <Button
                    type="primary"
                    disabled={
                      !checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_EDIT
                      ) || checkPaymentTermsValidation()
                    }
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    disabled={
                      !checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_ADD
                      ) || checkPaymentTermsValidation()
                    }
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    {legalAgreementId ? "Update" : "Save"}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

const ActorLegalForm = Form.create({ name: "horizontal_login" })(ActorLegal);

export default ActorLegalForm;
