import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Divider,
  Tooltip,
  Modal,
  Icon,
} from "antd";
import moment from "moment";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { numberOnly } from "utilities/ValidationHelper";
import ActorsServices from "services/ActorsServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
// import "../ActorAvailabilityTable";
import LocationScheduleTable from "./LocationScheduleTable";
import RecordNotFound from "components/shared/RecordNotFound";
import LocationServices from "services/LocationServices";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { getNotificationCount } from "store/thunk-actions";
import { connect } from "react-redux";

const dateFormat = "YYYY-MM-DD";

// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                message: "Enter numbers onlys",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class LocationAvailabilityTable extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.scriptservice = new ScriptService();
    this._locationTabService = new LocationTabService();
    this._locationService = new LocationServices();
    this.columns = [
      {
        title: "From Date",
        dataIndex: "fromDate",
        align: "center",
        width: "30%",
        render: (text, record) => (
          <DatePicker
            style={{ width: 140 }}
            className={`${!text ? "custom-empty-active" : ""}`}
            defaultValue={text ? moment(text, "DD-MM-YYYY") : ""}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "dateChange")
            }
            format={dateFormat}
            disabledDate={(current) => this.disabledDate(current)}
          />
        ),
      },
      {
        title: "To Date",
        dataIndex: "toDate",
        // editable: true,
        align: "center",
        width: "30%",
        render: (text, record) => (
          <DatePicker
            style={{ width: 140 }}
            className={`${!text ? "custom-empty-active" : ""}`}
            defaultValue={text ? moment(text, "DD-MM-YYYY") : ""}
            onChange={(value, dateString) =>
              this.onChange(value, dateString, record, "onChange")
            }
            format={dateFormat}
            disabledDate={(current) =>
              this.disabledDate(current, record.fromDate)
            }
          />
        ),
      },
      {
        title: "Action",
        dataIndex: "operation",
        // align: "center",
        width: "8%",
        className: "operation",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Row type="flex" justify="center" align="middle">
              {/* <Col xl={11}>
                {record.actorAvailabilityId ? (
                  // <Link onClick={() => this.handleUpdate(record)}>Update / </Link>
                  <Tooltip
                    title={GlobalConstants.EDT}
                    placement={GlobalConstants.RIGHT}
                  >
                    <Button
                      icon="edit"
                      type="link"
                      onClick={() => this.handleUpdate(record)}
                    />
                  </Tooltip>
                ) : (
                    // <Link onClick={() => this.handleSubmit(record)}>Save / </Link>
                    <div>
                      {this.state.actorId ? (
                        <Tooltip
                          title={GlobalConstants.SAV}
                          placement={GlobalConstants.RIGHT}
                        >
                          {" "}
                          <Button
                            icon="save"
                            type="link"
                            onClick={() => this.handleSubmit(record)}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  )}
              </Col>
              <Col xl={2}>
                {this.state.actorId ? <Divider type="vertical" /> : null}
              </Col> */}
              <Col xl={11}>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() =>
                    this.handleDelete(record.key, record.locationAvailabilityId)
                  }
                >
                  <Tooltip
                    title={GlobalConstants.DEL}
                    placement={GlobalConstants.RIGHT}
                  >
                    <Button icon="delete" type="link" />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          ) : null,
      },
    ];

    this.state = {
      dataSource: [],
      count: 0,
      page: null,
      actorId: null,
      theCount: 0,
      // paymentCount: 0
    };
  }

  componentDidMount() {
    if (this.props.locationId) {
      this.setState(
        {
          actorId: this.props.locationId,
        },
        () => this.getActorDetails()
      );
    }
    this.checkForEmpty(this.state.dataSource);
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  disabledDate = (current, toDate) => {
    // Can not select days before today and today
    if (toDate) {
      return (
        current &&
        current <
          moment(moment(toDate, dateFormat).format("YYYY-MM-DD")).startOf("day")
      );
    }
    return current && current < moment().startOf("day");
  };

  getActorDetails = () => {
    this._locationTabService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_BY_ID,
        this.state.actorId,
        this.props.sceneLocationId
      )
      .then((res) => {
        let dataSource = [];
        res.data.locationAvailabilities &&
          res.data.locationAvailabilities.map((datum, i) => {
            dataSource.push({ ...datum, key: this.state.count });
            this.setState({
              dataSource,
              count: this.state.count + 1,
            });
          });
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  handleSubmit = (record) => {
    let actorId = this.state.actorId;
    let locationAvailabilityId = record.actorAvailabilityId;
    let paymentId = null;
    let staffId = null;
    let data = {
      locationAvailabilityId: record.locationAvailabilityId,
      locationId: actorId,
      fromDate: record.fromDate,
      toDate: record.toDate,
    };
    if (actorId) {
      this._locationService
        .service(
          Services.LocationVariables.CHECK_SHOOT_DATE,
          null,
          actorId,
          record.fromDate,
          record.toDate
        )
        .then((res) => {
          if (actorId) {
            this._locationService
              .service(
                Services.LocationVariables.POST_LOCATION_AVAILABILITY,
                data
              )
              .then((res) => {
                showActionMessage(
                  GlobalConstants.SUCCESS,
                  GlobalConstants.CHANGES_HAVE_BEEN_SAVED
                );
                this.getActorDetails();
                this.props.getNotificationCount();
              })
              .catch((err) => {
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something Went Wrong"
                );
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isModalVisible: true,
            errorDataSource: err.response.data && err.response.data.errors,
          });
        });
    }
  };

  handleUpdate = (record) => {
    let actorId = this.state.actorId;
    let locationAvailabilityId = record.locationAvailabilityId;
    let paymentId = null;
    let staffId = null;
    let data = {
      locationAvailabilityId: record.locationAvailabilityId,
      actorId: actorId,
      fromDate: record.fromDate,
      toDate: record.toDate,
    };
    if (record.fromDate <= record.toDate) {
      if (actorId) {
        this._locationService
          .service(
            Services.LocationVariables.CHECK_SHOOT_DATE,
            null,
            actorId,
            record.fromDate,
            record.toDate
          )
          .then((res) => {
            this._locationService
              .service(
                Services.LocationVariables.PUT_LOCATION_AVAILABILITY_DETAILS,
                data,
                actorId,
                null,
                null,
                locationAvailabilityId
              )
              .then((res) => {
                showActionMessage(
                  GlobalConstants.SUCCESS,
                  GlobalConstants.CHANGES_HAVE_BEEN_SAVED
                );
                this.props.getNotificationCount();
              })
              .catch((err) => {
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something Went Wrong"
                );
              });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isModalVisible: true,
              errorDataSource: err.response.data.errors,
            });
          });
      }
    } else {
      showNotifications(
        Notifications.ERROR,
        "Note",
        "To Date must Be Greater Than From Date Or They Must Equal!!"
      );
    }
  };

  handleDelete = (key, locationAvailabilityId) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        this.props.getFromChild(this.state.dataSource);
        this.checkForEmpty(this.state.dataSource);
      }
    );
    this._locationService
      .service(
        Services.LocationVariables.DELETE_LOCATION_AVAILABILITY,
        null,
        locationAvailabilityId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.props.getNotificationCount();
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      fromDate: "",
      toDate: "",
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, dateString, record, onChange) {
    this.handleSave(record, dateString, onChange);
  }

  dateChange(value, dateString, record, dateChange) {
    this.handleSave(record, dateString, dateChange);
  }

  handleSave = (row, value = null, type, date) => {
    if (!row.locationAvailabilityId) {
      if (type === "onChange") {
        row.toDate = value;
      } else if (type === "dateChange") {
        row.fromDate = value;
      }
      if (row.fromDate && row.toDate) {
        if (
          moment(row.fromDate, dateFormat).format("YYYY-MM-DD") <=
          moment(row.toDate, dateFormat).format("YYYY-MM-DD")
        ) {
          this.handleSubmit(row);
          this.props.getFromChild(this.state.dataSource);
        } else {
          showNotifications(
            Notifications.ERROR,
            `"TO DATE" Is Greater Than "FROM DATE"`,
            " "
          );
        }
      }
      // else {
      //   showNotifications(
      //     Notifications.ERROR,
      //     "Failed",
      //     "All Fields Are Mandatory"
      //   );
      // }
    } else if (row.locationAvailabilityId) {
      if (type === "onChange") {
        row.toDate = value;
      } else if (type === "dateChange") {
        row.fromDate = value;
      }
      if (row.fromDate && row.toDate) {
        if (
          moment(row.fromDate, dateFormat).format("YYYY-MM-DD") <=
          moment(row.toDate, dateFormat).format("YYYY-MM-DD")
        ) {
          this.handleUpdate(row);
          this.props.getFromChild(this.state.dataSource);
        } else {
          showNotifications(
            Notifications.ERROR,
            `"TO DATE" Is Greater Than "FROM DATE"`,
            " "
          );
        }
      }
      // else {
      //   showNotifications(
      //     Notifications.ERROR,
      //     "Failed",
      //     "All Fields Are Mandatory"
      //   );
      // }
    }
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (type === "dateChange") {
      if (value) {
        let modifyItem = newData[index];
        modifyItem.fromDate = value;
      }
    }
    if (type === "onChange") {
      if (value) {
        let modifyDate = newData[index];
        modifyDate.toDate = value;
      }
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    newData.map((dateData, i) => {
      if (row.fromDate && value.toDate) {
        if (row.fromDate <= value.toDate) {
          this.setState({ dataSource: newData }, () => {
            // this.handleSubmit(row);
            this.props.getFromChild(this.state.dataSource);
          });
        }
      }
    });
  };

  handleCancel = (e) => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "amount" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Row type="flex" justify="center" align="middle">
          <Col xl={24}>
            <Table
              components={components}
              className="editing-table box-shadow"
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              // pagination={{ pageSize: 1000 }}
              pagination={false}
              locale={{
                emptyText: <RecordNotFound />,
              }}
            />
            <Col xl={24} style={{ textAlign: "end" }}>
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16 }}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            </Col>
          </Col>
        </Row>

        <Modal
          title="Shoot Schedule"
          className="createModal"
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="500px"
          footer={
            <Row>
              <Button type="primary" onClick={this.handleCancel}>
                Ok
              </Button>
            </Row>
          }
        >
          <LocationScheduleTable dataSource={this.state.errorDataSource} />
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(null, mapDispatchToProps)(LocationAvailabilityTable);
