import React, { Component, Fragment } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Table } from "antd";
import InvoiceUpload from "components/shared/InvoiceUpload";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { Services, GlobalConstants } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { ReplaceWithSpace } from "utilities/ValidationHelper";

const { Option } = Select;

const dateFormat = GlobalConstants.DD_MM_YYYY;

class AddPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Category: null,
      ...this.getInitialState(),
    };
    this.global = new GlobalService();
  }

  getInitialState = () => {
    return {
      PaymentFormValues: {
        shootDate: null,
        paymentDate: null,
        expenseType: null,
        CategoryTypeList: [],
        SubCategoryList: [],
        isAdvance: false,
      },
    };
  };

  componentDidMount() {
    Promise.all([this.categoryTypeList(), this.subCategoryList()]).then(
      ([res1]) => {
        this.setState({
          CategoryTypeList: res1.data,
        });
      }
    );
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  subCategoryList = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PRODUCTION_CATEGORY
    );
  };

  categoryTypeList = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PRODUCTION_CATEGORY
    );
  };

  onChangeDD = (value, name) => {
    this.setState({
      PaymentFormValues: {
        ...this.state.PaymentFormValues,
        [name]: value,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
    };
    const tableCol = {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 19 },
      },
    };
    const customCol = {
      labelCol: {
        sm: { span: 2 },
      },
      wrapperCol: {
        sm: { span: 22 },
      },
    };
    const { Category, CategoryTypeList } = this.state;

    const IsProductionPayment = Category === GlobalConstants.PRODUCTION_CAPS;
    return (
      <Fragment>
        <Row style={{ padding: 10 }}>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col xl={8}>
                <Form.Item label="Department" hasFeedback>
                  {getFieldDecorator("Department", {
                    // initialValue: invoiceNumber,
                    rules: [
                      {
                        required: true,
                        message: "Invoice Number Required!",
                      },
                    ],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      className="upperCase"
                      showSearch
                      autoFocus
                      //   onChange={this.handleInvoiceChange}
                      //   onBlur={this.handleInvoiceBlur}
                      //   onSearch={this.handleInvoiceNumber}
                    >
                      {CategoryTypeList &&
                        CategoryTypeList.map((list, i) => {
                          return (
                            <Option key={i} value={list.value}>
                              {list.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col xl={8}>
                <Form.Item label="Shoot date" hasFeedback>
                  {getFieldDecorator("ShootDate", {
                    rules: [
                      {
                        required: true,
                        message: "Invoice Number Required!",
                      },
                    ],
                  })(
                    <DatePicker
                      // onChange={(value, ShootDate) => setShootDate(ShootDate)}
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Payment date" hasFeedback>
                  {getFieldDecorator("PaymentDate", {
                    rules: [
                      {
                        required: true,
                        message: "Payment date Required!",
                      },
                    ],
                  })(
                    <DatePicker
                      // onChange={paymentDateChange}
                      // onChange={(value, PaymentDate) =>
                      //   setPaymentDate(PaymentDate)
                      // }
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={8}>
                <Form.Item label="Invoice Number" hasFeedback>
                  {getFieldDecorator("invoiceNumber", {
                    // initialValue: invoiceNumber,
                    rules: [
                      {
                        required: true,
                        message: "Invoice Number Required!",
                      },
                    ],
                  })(
                    // <Select
                    //   style={{ width: "100%" }}
                    //   className="upperCase"
                    //   showSearch
                    //   autoFocus
                    //   //   onChange={this.handleInvoiceChange}
                    //   //   onBlur={this.handleInvoiceBlur}
                    //   //   onSearch={this.handleInvoiceNumber}
                    // >
                    //   {CategoryTypeList &&
                    //     CategoryTypeList.map((list, i) => {
                    //       return (
                    //         <Option key={i} value={list.value}>
                    //           {list.label}
                    //         </Option>
                    //       );
                    //     })}
                    // </Select>
                    <span>Invoice</span>
                  )}
                </Form.Item>
              </Col>

              <Col xl={24}>
                <Form.Item {...customCol} label="Files">
                  {getFieldDecorator("document", {
                    rules: [
                      {
                        required: false,
                        message: "Invoice Date Required!",
                      },
                    ],
                  })(
                    <>
                      <InvoiceUpload
                        onRef={(ref) => (this.upload = ref)}
                        handleDocument={this.handleDocument}
                        handleCancel={this.handleCancel}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Fragment>
    );
  }
}

const AddPaymentPurchase = Form.create({ name: "register" })(AddPaymentForm);

export default AddPaymentPurchase;
