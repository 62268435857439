import React, { Component, Fragment, useState } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Card,
  Layout,
} from "antd";
import { connect } from "react-redux";
import axios from "axios";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Storage from "utilities/LocalStorageHelper";
import TextComponent from "components/shared/TextComponent";
import { OnboardServices } from "services";
import {
  Services,
  Notifications,
  LocalStorage,
  Screens,
} from "constants/AppConstants";
import { setUser, setAccessToken, setMenus } from "store/action";
import "./Login.css";
import "./Login.scss";
import { getResponseCode } from "utilities/HandleResponseHelper";
import { showNotifications } from "components/shared/NotificationComponent";
import { getUser, getUserMenus } from "utilities/UserHelper";
// import ParticleComponent from "components/shared/ParticlesComponent";
import ThemeColors from "constants/ThemeConstants/variables";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this._userService = new OnboardServices();
    this._storage = new Storage();
  }

  componentDidMount() {
    if (this.props.user) {
      this.props.history.push("/projects");
    }
  }

  state = {
    formLayout: "horizontal",
    isLoading: false,
    isForgotPasswordEnabled: false,
    userName: null,
    password: null,
    showPassword: false,
  };

  handleSubmit = (e) => {
    const { userName, password, isForgotPasswordEnabled } = this.state;

    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      let formusername = values.username;
      let formpassword = btoa(values.password);

      if (!err) {
        this.setState({
          formValue: values,
          userName: formusername,
          password: formpassword,
        });

        this.setState({
          isLoading: true,
        });
        if (!isForgotPasswordEnabled) {
          const data = {
            userName,
            password: btoa(password),
          };
          this._userService
            .onboardService(Services.OnboardVariables.LOGIN, data)
            .then((res) => {
              res.data.role = res.data.roleName;
              let response = getResponseCode(res.status, Screens.LOGIN);
              if (response[0].message === Services.OnboardVariables.SUCCESS) {
                this.setState({ isLoading: false }, () => {
                  this._storage.store(
                    LocalStorage.USER,
                    JSON.stringify(res.data)
                  );
                  this.props.setUser(res.data);
                  // this.props.history.push("/welcome");
                  getUserMenus().then((res) => {
                    this.props.setMenus(res.data.menus);
                  });
                  this.props.history.push("/projects");
                  showNotifications(
                    Notifications.SUCCESS,
                    "Welcome to ACE",
                    "Get your work simplified!!"
                  );
                });
              } else {
                showNotifications(
                  Notifications.ERROR,
                  "No User Found",
                  "Please check the credential!!"
                );
                this.setState({ isLoading: false });
              }
            })
            .catch((err) => {
              console.log(
                "err...",
                parseInt(err.toString().match(/-?\d+\.?\d*/))
              );
              // getResponseCode(res.status, Screens.LOGIN)
              this.setState({
                isLoading: false,
              });
              console.log(
                "error code...",
                parseInt(err.toString().match(/-?\d+\.?\d*/))
              );
              if (parseInt(err.toString().match(/-?\d+\.?\d*/)) === 401) {
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "User name password mismatch!!"
                );
              } else {
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  "Something went wrong!!"
                );
              }
              this.setState({ isLoading: false });
            });
        } else {
          const data = {
            email: userName,
          };
          this._userService
            .onboardService(Services.OnboardVariables.FORGOT_PASSWORD, data)
            .then((res) => {
              this.setState({ isLoading: false }, () => {
                showNotifications(
                  Notifications.SUCCESS,
                  "Email Sent!",
                  "Check Your Inbox!!"
                );
              });
            })
            .catch((err) => {
              console.log("err...", err);
              this.setState({
                isLoading: false,
              });
            });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout, isLoading, isForgotPasswordEnabled } = this.state;
    const formItemLayout =
      formLayout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 24 },
          }
        : null;
    const buttonItemLayout =
      formLayout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : null;

    return (
      <Layout
        style={{
          minHeight: "100vh",
          backgroundColor: ThemeColors.primaryColor,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="imageContainer" style={{ height: "100vh" }}>
          <div className="text">
            <h1>Welcome Back!</h1>
            <p>It's great to see you again</p>
            <p style={{ fontSize: "large" }}>Get your work simplified!</p>
          </div>
          <img
            src="/great.png"
            alt="Movie Man"
            style={{ height: "70%", width: "75%" }}
            className="movieMan"
          />
        </div>
        <div className="loginContainerWrapper">
          <div className="loginParticles">{/* <ParticleComponent /> */}</div>
          <TextComponent
            style={{
              position: "absolute",
              top: "15%",
              fontSize: "25px",
              fontWeight: "bold",
              backgroundColor: "white",
              borderRadius: "4px",
              height: "60px",
              width: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "1",
            }}
          >
            Welcome To &nbsp;
            <TextComponent style={{ color: "#f44e6f" }}>ACE</TextComponent>
          </TextComponent>
          <div className="loginContainer">
            <TextComponent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              {isForgotPasswordEnabled ? "FORGOT PASSWORD" : "LOGIN"}
            </TextComponent>

            <Form
              wrapperCol={4}
              //onSubmit={this.handleSubmit}
              className="login-form"
            >
              {!isForgotPasswordEnabled ? (
                <Fragment>
                  <Form.Item>
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="user"
                            style={{
                              color: "rgba(0,0,0,.25)",
                            }}
                          />
                        }
                        autoFocus
                        placeholder="Username"
                        onChange={(e) =>
                          this.setState({ userName: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        suffix={
                          <button
                            className="showPassword"
                            onClick={() => {
                              this.setState({
                                showPassword: !this.state.showPassword,
                              });
                            }}
                          >
                            <img
                              src={
                                this.state.showPassword
                                  ? "hide.png"
                                  : "/view.png"
                              }
                              style={{ height: "15px", width: "15px" }}
                            />
                          </button>
                        }
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Password"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Fragment>
              ) : (
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      autoFocus
                      placeholder="Username"
                      onChange={(e) =>
                        this.setState({ userName: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.handleSubmit}
                  className="login-form-button customButton"
                  loading={isLoading}
                >
                  {isForgotPasswordEnabled ? "Send Email" : "Log in"}
                </Button>
              </Form.Item>
              {/* {!isLoading && ( */}
              <Form.Item>
                <a
                  className={`login-form-forgot ${isLoading ? "noEvent" : ""}`}
                  style={{
                    color: "white",
                  }}
                  onClick={() =>
                    this.setState({
                      isForgotPasswordEnabled:
                        !this.state.isForgotPasswordEnabled,
                    })
                  }
                >
                  {isForgotPasswordEnabled
                    ? "Back to Login"
                    : "Forgot password?"}
                </a>
              </Form.Item>
              {/* )} */}
            </Form>
            <div className="line">
              <hr
                style={{
                  position: "absolute",
                  top: "5px",
                  width: "100%",
                  height: "1px",
                }}
              />
              <p
                style={{
                  position: "absolute",
                  top: "4px",
                  backgroundColor: "#ee556a",
                  color: "white",
                }}
              >
                New to ACE?
              </p>
            </div>
            <button
              className="registerButton"
              onClick={() => this.props.history.push("/sign-up")}
            >
              Register
            </button>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    setAccessToken: (accessToken) => dispatch(setAccessToken(accessToken)),
    setMenus: (menus) => dispatch(setMenus(menus)),
  };
};
const Login = Form.create()(LoginForm);
export default connect(mapStateToProps, mapDispatchToProps)(Login);
