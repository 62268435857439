import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button, Icon, Input, Tag } from "antd";

import TooltipIcon from "components/shared/TooltipIcon";
import { MenusRoute, GlobalConstants, Services } from "constants/AppConstants";
import { Link } from "react-router-dom";
import Accounting from "services/AccountingServices";
import RecordNotFound from "components/shared/RecordNotFound";
import moment from "moment";
import AccProductionAddEntry from "./AccProductionAddEntry";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { connect } from "react-redux";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";

const { Search } = Input;

const AccProductionCostumeMakeupTable = (props) => {
  const [DataSource, setDataSource] = useState([]);
  const [SearchKey, setSearchKey] = useState(null);
  const [IsLoading, setIsLoading] = useState(true);
  let filteredDataSource = [];
  const Account = new Accounting();
  const { activePermisision } = props;
  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (key) => {
    setSearchKey(key);
  };

  filteredDataSource = SearchKey
    ? DataSource.filter((data) =>
        data.description.toLowerCase().includes(SearchKey)
      )
    : DataSource;

  const fetchData = () => {
    Account.services(
      Services.AccountingVariables.GET_ART_SET_WITH_SHOOT_DATES_NEW,
      {
        department: GlobalConstants.COSTUME_AND_MAKEUP_CAPS,
        isAdvance: false,
      }
    )
      .then((res) => {
        setDataSource(res.data.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const columns = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shoot date",
      dataIndex: "shootDate",
      key: "shootDate",
      width: "20%",
      render: (text) => <Tag className="statusTag">{text}</Tag>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      align: "right",
      width: "20%",
    },

    {
      title: "Pending amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
      align: "right",
      width: "20%",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      align: "right",
      width: "20%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns.push({
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            props.history.push({
              pathname: `${MenusRoute.ACCOUNTING}/production/costumemakeup/${record.shootDate}`,
              state: {
                title: `Costume & Makeup ${record.shootDate}`,
                details: {
                  productionCategoryDepartment: GlobalConstants.PRODUCTION_CAPS,
                  department: GlobalConstants.COSTUME_AND_MAKEUP_CAPS,
                  category: GlobalConstants.PRODUCTION_CAPS,
                  categoryDepartment: GlobalConstants.COSTUME_AND_MAKEUP_CAPS,
                },
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Row style={{ padding: 10 }}>
      <Col>
        <Table
          dataSource={DataSource}
          columns={columns}
          bordered
          // scroll={{ y: "50vh" }}
          pagination={false}
          loading={IsLoading}
          locale={{
            emptyText: !IsLoading && <RecordNotFound />,
          }}
        />
      </Col>
    </Row>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

export default connect(mapStateToProps, null)(AccProductionCostumeMakeupTable);
