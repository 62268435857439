import React, { Component, Fragment } from "react";
import { Button, Modal } from "antd";
import Uploaddocumentfile from "components/uploaddocumentfile";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
} from "constants/AppConstants";
import UploadDocumentComponent from "components/shared/UploadDocumentAndDisplay/UploadDocumentComponent";
import { handleError } from "utilities/ErrorHandler";
import EquipmentProjectService from "services/EquipmentProjectService";
import DisplayDocumentComponent from "components/shared/UploadDocumentAndDisplay/DisplayDocumentComponent";
import DocumentDelete from "services/DocumentDelete";
import Accounting from "services/AccountingServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import InvoiceService from "services/InvoiceService";

class InvoiceDocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { isModalVisible, fileList } = this.state;
    const { data, refreshData, disabled } = this.props;
    return (
      <Fragment>
        <Button
          style={{ width: "100%" }}
          type="ghost"
          disabled={disabled}
          onClick={this.showModal}
        >
          Documents
        </Button>
        {isModalVisible && (
          <ChildComponent
            {...this.props}
            handleDelete={this.handleDelete}
            files={fileList}
            handleDocument={this.handleDocument}
            handleCancel={this.handleCancel}
          />
        )}
      </Fragment>
    );
  }
}

class ChildComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      data: props.data,
      fileList: [],
      productionId: props.data.productionAccountId,
      invoiceId: props.data.invoiceId,
      dataSource: null,
    };

    this._invoicesService = new InvoiceService();
    this._docDelete = new DocumentDelete();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.GET_INVOICE_BY_ID,
        this.state.invoiceId
      )
      .then((res) => {
        this.setState({
          dataSource: res.data,
          isLoading: false,
          fileList: res.data.invoiceDocuments || [],
        });
      });
  };

  handleDelete = (id) => {
    this._docDelete
      .deleteService(Services.InvoiceVariables.DELETE_INVOICE_DOCUMENT, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
        );
      });
  };

  handleDocument = (files) => {
    const { data, fileList } = this.state;
    const lists = [...fileList];

    files.forEach((file) => {
      lists.push({
        attachmentId: file?.attachmentId,
        fileType: file?.fileType,
      });
    });

    data.invoiceDocuments = lists;
    this.handleUpdate(data);
  };

  handleUpdate = (record) => {
    const data = {
      ...record,
      descriptionData: `${record.productionCategoryDepartment}#${record.description}`,
      invoiceDate: record.shootDate ? record.shootDate : record.invoiceDate,
    };
    delete data.key;
    delete data.description;
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.UPDATE_INVOICE_BY_SHOOT_DATE,
        data,
        data.invoiceId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  render() {
    const { handleCancel, canDelete, canUpload } = this.props;
    const { fileList } = this.state;
    return (
      <Modal
        title="Documents"
        visible
        onOk={this.handleOk}
        className="createModal no-x"
        footer={null}
        destroyOnClose
        width={1000}
      >
        <DisplayDocumentComponent
          handleDelete={this.handleDelete}
          files={fileList}
          canDelete={canDelete}
          // {...this.props}
        />
        <UploadDocumentComponent
          handleDocument={this.handleDocument}
          handleCancel={handleCancel}
          canUpload={canUpload}
        />
      </Modal>
    );
  }
}

InvoiceDocumentUpload.defaultProps = {
  canDelete: true,
  canUpload: true,
};

export default InvoiceDocumentUpload;
