import React, { Component, Fragment } from "react";
import { Row, Col, Button, Icon } from "antd";
import { Images } from "assets/images";
import { Link } from "react-router-dom";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

import "./accounting.scss";
import { Services, MenusRoute } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { checkFor, PriceFormat } from "utilities/ValidationHelper";
import SkeletonComponent from "components/shared/SkeletonComponent";
import AccountingService from "services/AccountingService";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

class AccountingMain extends Component {
  constructor(props) {
    super(props);
    this._accountingService = new AccountingService();
    this.state = {
      postProductionBudget: null,
      preProductionBudget: null,
      productionBudget: null,
      totalBudget: null,
      totalBudgetRoundOff: null,
      totalBudgetRoundOffInWords: null,
      travelAndLodgeingBudget: null,
      vendorBudget: null,
      dataFetched: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this._accountingService
      .accountingService(Services.AccountingVariables.GET_ACCOUNTING_ESTIMATION)
      .then((res) => {
        console.clear();
        console.log(JSON.stringify(res, null, 2));
        this.setState({
          postProductionBudget: res.data.postProductionBudget,
          preProductionBudget: res.data.preProductionBudget,
          productionBudget: res.data.productionBudget,
          totalBudget: res.data.totalBudget,
          totalBudgetRoundOff: res.data.totalBudgetRoundOff,
          travelAndLodgeingBudget: res.data.travelAndLodgeingBudget,
          totalBudgetRoundOffInWords: res.data.totalBudgetRoundOffInWords,
          vendorBudget: res.data.vendorBudget,
          castTechniciansBudget: res.data.castTechniciansBudget,
          dataFetched: true,
        });
      })
      .catch((err) => {});
  };

  renderCards = (cards) => {
    const { activePermisision } = this.props;
    return cards.map((card, i) => (
      <Col
        xl={{ span: 8 }}
        lg={{ span: 8 }}
        style={{ padding: "10px" }}
        className="card-hover"
      >
        {!checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) &&
          !checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE) && (
            <div className="disabledCard"></div>
          )}
        <Link
          to={card.url}
          className="btn btn-img"
          style={{ margin: 5 }}
          // onClick={() => this.props.history.push(card.url)}
        >
          <div className="details_container">
            <div className="title_amount">
              <span className="title">{card.key}</span>
              <span className="amount">
                &#8377; {PriceFormat(card.value || "0.00")}
              </span>
            </div>
          </div>
          <div className="image_container">
            <img
              className="image"
              src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
            />
          </div>
        </Link>
      </Col>
    ));
  };

  renderSkeletonCards = () => {
    let template = [];
    for (let i = 1; i <= 6; i++) {
      template.push(
        <Col xl={8} className="skeletonCard">
          <Col xl={22} className="card">
            <Col xl={22}>
              <Col xl={16}>
                <SkeletonComponent width={100} />
                <SkeletonComponent width={150} />
              </Col>
              <Col xl={8} className="flexCenter">
                <SkeletonComponent circle={true} width={40} height={40} />
              </Col>
            </Col>
          </Col>
        </Col>
      );
    }
    return template;
  };

  render() {
    const {
      postProductionBudget,
      preProductionBudget,
      vendorBudget,
      totalBudget,
      productionBudget,
      totalBudgetRoundOff,
      travelAndLodgeingBudget,
      castTechniciansBudget,
      totalBudgetRoundOffInWords,
      dataFetched,
    } = this.state;

    const { activePermisision, getPermissions } = this.props;

    const cards = [
      {
        key: "Pre-Production",
        value: preProductionBudget && preProductionBudget,
        url: `${MenusRoute.ACCOUNTING}/pre-production`,
      },
      {
        key: "Production",
        value: productionBudget && productionBudget,
        url: `${MenusRoute.ACCOUNTING}/production`,
      },
      {
        key: "Post-Production",
        value: postProductionBudget && postProductionBudget,
        url: `${MenusRoute.ACCOUNTING}/post-production`,
      },
      {
        key: "Travel & Lodging",
        value: travelAndLodgeingBudget && travelAndLodgeingBudget,
        url: `${MenusRoute.ACCOUNTING}/travel-lodgeing`,
      },
      {
        key: "Cast & Technicians",
        value: castTechniciansBudget && castTechniciansBudget,
        url: `${MenusRoute.ACCOUNTING}/casttechnicians`,
      },
      {
        key: "Vendor",
        value: vendorBudget && vendorBudget,
        url: `${MenusRoute.ACCOUNTING}/vendor`,
      },
    ];

    return (
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Accounting </TitleText>
            <Button type="primary" style={{ float: "right" }}>
              <Link to={`${MenusRoute.ACCOUNTING}/addpayment`}>
                <Icon type="plus" style={{ fontSize: "15px" }} /> Payment
              </Link>
            </Button>
          </Title>
          <br />
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Fragment>
            <div style={{ textAlign: "center" }}>
              <p>
                <span style={{ fontWeight: 600, fontSize: 20 }}>
                  ACCOUNTING ( &#8377; {PriceFormat(totalBudget)} )
                </span>
                <div style={{ fontSize: 18, fontWeight: "bold" }}>
                  {`${
                    totalBudgetRoundOffInWords
                      ? `Rupees ${totalBudgetRoundOffInWords} Only`
                      : ""
                  }`}
                  {!dataFetched && <SkeletonComponent width={400} />}
                </div>
              </p>
            </div>
            <Row type="flex" justify="center">
              <Col xl={18}>
                <Row>
                  {dataFetched
                    ? this.renderCards(cards)
                    : this.renderSkeletonCards()}
                </Row>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountingMain);
