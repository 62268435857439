import axios from "axios";
import { LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";


const getLegalAggreement = (data = {}) => {
  const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  return axios.get(
    `${getScriptAPIURL()}/project/${projectId}/legal-agreement`,
    {
      params: data,
    }
  );
};

const LegalService = { getLegalAggreement };

export default LegalService;
