import React, { Component } from "react";
import {
  Table,
  DatePicker,
  Row,
  Col,
  Icon,
  Button,
  Select,
  Popconfirm,
  Tooltip,
} from "antd";
import moment from "moment";
import ArtSetServices from "services/ArtSetServices";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import {
  checkArray,
  checkDateGreaterThan,
  StartCase,
} from "utilities/ValidationHelper";
import LocationServices from "services/LocationServices";
import { BudjetVariables } from "constants/AppConstants/ServicesConstants";
import BudjetService from "services/BudjetService";

const dateFormat = "DD-MM-YYYY";

const { Option } = Select;

export default class ArtSetStartEndDataTable extends Component {
  constructor(props) {
    super(props);
    this._locationService = new LocationServices();
    this._budjetService = new BudjetService();
    this.api = new ArtSetServices();
    this.state = {
      shootDate: props.shootDate,
      artSets: [],
      totalRecords: [],
      isLoading: true,
      count: 0,
    };
  }

  componentDidMount() {
    this.getDates();
    this.getSceneLocationList(this.state.shootDate);
  }

  getSceneLocationList = (shootDate) => {
    this._locationService
      .service(Services.LocationVariables.GET_LOCATION_BY_SHOOT_DATE, shootDate)
      .then((res) => {
        this.setState({
          sceneLocationList: checkArray(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDates = () => {
    this.setState({
      isLoading: true,
    });
    const { shootDate } = this.state;
    this.api
      .service(Services.ArtSetVariables.GET_ART_SET_DATES, shootDate)
      .then((res) => {
        let artSets = [];
        res.data.artSets &&
          res.data.artSets.map((data, i) => {
            artSets.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          artSets,
          isLoading: false,
          totalRecords: artSets,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAdd = () => {
    const { count, artSets } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      sceneLocationId: null,
      setWorkEndDate: null,
      crewCount: "0",
      setWorkStartDate: null,
      locationName: null,
    };
    this.setState({
      artSets: [...artSets, newData],
    });
  };

  updateDates = (row) => {
    const { shootDate, artSets } = this.state;
    const { refreshData } = this.props;
    const { sceneLocationId, setWorkStartDate, setWorkEndDate } = row;
    const data = {
      artSets,
      shootDate,
    };
    this.api
      .service(Services.ArtSetVariables.UPDATE_ART_SET_DATES, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        refreshData();
        this.getDates();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  disabledDate = (current, toDate) => {
    // Can not select days before today and today
    if (current) {
      if (toDate) {
        return (
          current < moment(toDate, dateFormat).add(1, "days")
          // &&
          // current >
          // moment(this.convertFormat(shootDate), dateFormat).add(-1, "days")
        );
      }
      return current < moment().startOf("day");
    }
  };

  convertFormat = (date) => moment(date, dateFormat).format("DD-MM-YYYY");

  handleDropdown = (value, record, type) => {
    const { sceneLocationList } = this.state;
    let sceneLocation = null;
    let id = null;
    record.sceneLocation = value;
    sceneLocation = sceneLocationList.filter(
      (sceneLocation) => sceneLocation.label === value && sceneLocation.value
    );
    sceneLocation = Object.assign({}, ...sceneLocation);

    id = (sceneLocation && sceneLocation.value) || value;
    record.sceneLocationId = id;
    const newData = [...this.state.artSets];
    const index = newData.findIndex((item) => record.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...record,
    });
    this.setState({ artSets: newData }, () => {
      this.dateChange(id, null, record, type);
    });
  };

  dateChange(value, dateString, row, type) {
    const { artSets, shootDate } = this.state;
    row.setWorkStartDate = this.convertFormat(row.setWorkStartDate);
    row.setWorkEndDate = this.convertFormat(row.setWorkEndDate);
    if (type !== "sceneLocationId") {
      row[type] = this.convertFormat(value);
    }

    switch (type) {
      case "setWorkStartDate":
        if (row.setWorkStartDate !== "Invalid date") {
          artSets.forEach((data) => {
            if (data.setWorkEndDate === "Invalid date") {
              data.setWorkEndDate = null;
            }
            return {
              ...data,
              [type]: value,
            };
          });
          this.setState(
            {
              artSets,
            },
            () => this.updateDates(row)
          );
        } else {
          artSets.forEach((data) => {
            return {
              ...data,
              [type]: value,
            };
          });
          this.setState({
            artSets,
          });
        }
        break;
      case "setWorkEndDate":
        if (row.setWorkEndDate !== "Invalid date" && row.setWorkEndDate) {
          // if (checkDateGreaterThan(row.setWorkEndDate, shootDate)) {
          artSets.forEach((data) => {
            return {
              ...data,
              [type]: value,
            };
          });
          this.setState(
            {
              artSets,
            },
            () => this.updateDates(row)
          );
          // }
          // else {
          //   showNotifications(
          //     Notifications.ERROR,
          //     `Work end Date shouldn't be greater than Shoot date`,
          //     " "
          //   );
          // }
        }
        break;
    }
  }

  handleDelete = (key, record) => {
    const { refreshData } = this.props;
    if (record.artSetDateId) {
      this._budjetService
        .budjetService(
          Services.BudjetVariables.DEL_ART_SET_SHOOT_DATE_DATA,
          record.artSetDateId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.getDates();
          refreshData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );
        });
    } else {
      const artSets = [...this.state.artSets];
      this.setState({
        artSets: artSets.filter((item) => item.key !== key),
      });
    }
  };

  render() {
    const { artSets, isLoading, totalRecords, sceneLocationList } = this.state;
    const hasNewRow = artSets.length === totalRecords.length + 1;
    const columns = [
      {
        title: "Scene location Name",
        dataIndex: "sceneLocationName",
        key: "sceneLocationName",
        align: "center",
        render: (text, record) => {
          if (record.locationId) {
            return text && text;
          }
          return (
            <Select
              showSearch
              style={{ width: 150 }}
              placeholder="Select location"
              className={`${!text ? "err" : ""}`}
              defaultValue={text}
              onChange={(value) =>
                this.handleDropdown(value, record, "sceneLocationId")
              }
            >
              {sceneLocationList &&
                sceneLocationList.map((data) => {
                  return <Option value={data.label}>{data.label}</Option>;
                })}
            </Select>
          );
        },
      },
      {
        title: "Location Name",
        dataIndex: "locationName",
        key: "locationName",
        align: "center",
        render: (text) => text && StartCase(text),
      },
      {
        title: "Work Start Date",
        dataIndex: "setWorkStartDate",
        key: "setWorkStartDate",
        align: "center",
        render: (text, record) => (
          <DatePicker
            style={{ width: 140 }}
            defaultValue={text ? moment(text, dateFormat) : ""}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "setWorkStartDate")
            }
            className={`${!text ? "err" : ""}`}
            format={dateFormat}
            // disabledDate={current => this.disabledDate(current)}
            disabled={!record.sceneLocationId}
          />
        ),
      },
      {
        title: "Work End Date",
        dataIndex: "setWorkEndDate",
        key: "setWorkEndDate",
        align: "center",
        render: (text, record) => (
          <DatePicker
            style={{ width: 140 }}
            defaultValue={text ? moment(text, dateFormat) : ""}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "setWorkEndDate")
            }
            disabled={
              record.setWorkStartDate === "Invalid date" ||
              !record.sceneLocationId
            }
            format={dateFormat}
            disabledDate={(current) =>
              this.disabledDate(current, record.setWorkStartDate)
            }
          />
        ),
      },
      {
        title: "Operation",
        dataIndex: "operation",
        width: "8%",
        render: (text, record) => (
          <Row type="flex" justify="center">
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record.key, record)}
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button
                    icon="delete"
                    type="link"
                    disabled={record.isEnable === false}
                  />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          </Row>
        ),
      },
    ];
    return (
      <Row>
        <Col xl={24}>
          <Button
            onClick={this.handleAdd}
            type="primary"
            style={{ float: "right", marginBottom: 10 }}
            disabled={hasNewRow}
          >
            <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
          </Button>
        </Col>
        <Col xl={24}>
          <Table
            dataSource={artSets}
            columns={columns}
            pagination={false}
            loading={isLoading}
            bordered
          />
        </Col>
      </Row>
    );
  }
}
