import React from "react";
import { Bar } from "react-chartjs-2";

const ProductionBarChart = ({ height = 100, data }) => {
  return (
    <Bar
      data={data}
      height={height}
      options={{
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.4,
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: true,
                // callback: function(value, index, values) {
                //   if(parseInt(value) >= 1000){
                //     return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //   } else {
                //     return '$' + value;
                //   }
                // },
                callback: function (value, index, array) {
                  return value < 1000000
                    ? value / 1000 + "K"
                    : value / 1000000 + "M";
                },
                beginAtZero: true,
                // min: 0,
                // max: 100000,
                // stepSize: 1000
              },
              gridLines: {
                // display: false,
                color: "#f5f5f5",
              },
            },
          ],
        },
      }}
    />
  );
};

export default ProductionBarChart;
