import React from "react";

const CustomButton = ({ children, history, color, handleClick }) => {
  return (
    <div
      className="custom-button-style"
      style={{
        "--color": color,
      }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default CustomButton;
