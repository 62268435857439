import { LocalStorage, Services } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import axios from "axios";

export default class DocumentDelete {
  deleteService(type, id, screen, data) {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    let projectCategoryDocumentId = {
      projectCategoryDocumentId: id,
    };

    switch (type) {
      case Services.EquipmentVariables.DELETE_DOCUMENT:
        return axios.delete(
          `${getScriptAPIURL()}/standard/equipments/document/${id}`,
          config
        );
      case Services.CostumesMakeupVariables.COSTUMES_DELETE_DOC:
        return axios.delete(
          `${getScriptAPIURL()}/costumes/documents/${id}`,
          config
        );
      case Services.CostumesMakeupVariables.MAKEUP_DELETE_DOC:
        return axios.delete(
          `${getScriptAPIURL()}/makeup/documents/${id}`,
          config
        );
      case Services.LocationVariables.LOCATION_DELETE_DOC:
        return axios.delete(
          `${getScriptAPIURL()}/locations/documents/${id}`,
          config
        );
      case Services.SongVariables.SONG_DELETE_DOC:
        return axios.delete(
          `${getScriptAPIURL()}/${screen}/scenes/image/${id}`,
          config
        );
      case Services.AddActor.DELETE_ACTOR_DOCUMENT:
        return axios.delete(
          `${getScriptAPIURL()}/actors/documents/${id}`,
          config
        );
      case Services.ArtSetVariables.DELETE_DOCUMENT:
        return axios.delete(
          `${getScriptAPIURL()}/artset/budget/documents/${id}`,
          config
        );
      case Services.ArtSetVariables.DELETE_TABLE_IMAGE:
        return axios.delete(
          `${getScriptAPIURL()}/artsets/detail/documents/${id}`,
          config
        );
      case Services.ArtSetVariables.DELETE_ART_SET_UPDATE:
        return axios.delete(
          `${getScriptAPIURL()}/artset/budget/documents/${id}`,
          config
        );
      case Services.CrewVariables.DELETE_CREW_DOCUMENT:
        return axios.delete(
          `${getScriptAPIURL()}/project/member/documents/${id}`,
          config
        );
      case Services.BudjetVariables.DELETE_TRAVEL_LODEGING_DOCUMENT:
        return axios.patch(
          `${getScriptAPIURL()}/project/plans/categories/${data}`,
          projectCategoryDocumentId,
          config
        );
      case Services.AccountingVariables.DELETE_PRE_POST_PRODUCTION_IMAGE:
        return axios.delete(
          `${getScriptAPIURL()}/account/payments/${id}?entityId=${data}`,
          config
        );
      case Services.AccountingVariables.DELETE_PRE_POST_PRODUCTION_IMAGE_OLD:
        return axios.delete(
          `${getScriptAPIURL()}/production-payment/${id}?documentIds=${data}`,
          config
        );
      case Services.InvoiceVariables.DELETE_INVOICE_DOCUMENT:
        return axios.delete(
          `${getScriptAPIURL()}/project/invoices/${id}?isDocumentDelete=true`,
          config
        );
      case Services.VfxVariables.DELETE_VFX_DOCUMENT:
        return axios.delete(
          `${getScriptAPIURL()}/vfx/${id}?isDocumentDelete=true`,
          config
        );
      case Services.default:
        break;
    }
  }
}
