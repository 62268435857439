import React, { Component } from "react";
import "antd/dist/antd.css";
import { Select, Input, Row, Col, Form, Checkbox, Button } from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  LocalStorage,
  Notifications,
} from "constants/AppConstants";
import { ScriptService } from "services";
import { SceneOVerviewVariable } from "constants/AppConstants/ServicesConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { showNotifications } from "components/shared/NotificationComponent";
import { StartCase } from "utilities/ValidationHelper";
const { Option } = Select;

class CreateScene extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._scriptService = new ScriptService();
    this._locationService = new LocationTabService();
    this.state = {
      environmentTypeList: [],
      environmentTimeList: [],
      sceneTypeList: [],
      plainOptions: [],
      sceneNumberList: [],
      locationNameList: [],
      sceneLocationName: "",
    };
  }

  componentDidMount() {
    this.getEnvironmentType();
    this.getEnvironmentTime();
    this.getSceneType();
    this.getNextSceneNumber();
    this.getLocationName();
  }

  getEnvironmentType() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ENVIRONMENT
      )
      .then((res) => {
        this.setState({
          environmentTypeList: res.data,
        });
      });
  }

  getEnvironmentTime() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.TIME
      )
      .then((res) => {
        this.setState({
          environmentTimeList: res.data,
        });
      });
  }

  getSceneType() {
    let datas = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SCENE
      )
      .then((res) => {
        res.data &&
          res.data.map((list) => {
            datas.push({ label: list.label, value: list.value });
          });
        this.setState({
          plainOptions: datas,
        });
      });
  }

  getNextSceneNumber() {
    this._scriptService
      .scriptService(SceneOVerviewVariable.GET_NEXT_SCENE_NUMBERS_DROPDOWN)
      .then((res) => {
        this.setState({ sceneNumberList: res.data.sceneNumbers });
      });
  }

  getLocationName() {
    this._locationService
      .locationTabService(Services.SceneOVerviewVariable.SCENE_LOCATION)
      .then((res) => {
        this.setState({
          locationNameList: Array.isArray(res.data) ? res.data : [],
        });
      });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSearch = (value) => {
    this.props.form.setFieldsValue({
      sceneLocationName: value,
    });
    this.setState({ sceneLocationName: value });
  };

  handleLoctaionBlur = (e) => {
    if (e) {
      const { locationNameList } = this.state;
      let total = locationNameList;
      let check = false;
      total.filter((list) => {
        return (check = list.label.toLowerCase() !== e.toLowerCase());
      });
      // if (check) {
      this.props.form.setFieldsValue({
        sceneLocationName: StartCase(e),
      });
      this.setState({
        locationNameList: [...total, Object.assign({}, { label: e })],
        sceneLocationName: StartCase(e),
      });
      // }
    }
  };

  handleSceneLocation = (value) => {
    this.setState({
      sceneLocationName: value,
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true,
        });
        const {
          sceneNumber,
          description,
          sceneLocationName,
          environmentType,
          timeType,
          sceneTypes,
        } = this.state;

        let data = {
          description: StartCase(description),
          environmentType: environmentType,
          isCreate: true,
          nextSceneNumber: sceneNumber,
          projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
          sceneLocation: sceneLocationName,
          sceneTypes: sceneTypes,
          timeType: timeType,
        };
        console.log(data);
        this._scriptService
          .scriptService(SceneOVerviewVariable.CREATE_INSERT_SCENE, "", data)
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              `Scene Created Successfully!`
            );
            this.setState({
              isLoading: false,
            });
            this.props.refreshData && this.props.refreshData();
            this.props.closeModal && this.props.closeModal();
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    });
  };

  render() {
    const {
      environmentTypeList,
      environmentTimeList,
      plainOptions,
      sceneNumberList,
      locationNameList,
      isLoading,
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form>
          <Row>
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            ></Col>
          </Row>
          <Row className="mb-25 veritical-center">
            <Col lg={{ span: 9 }} md={{ span: 10 }} xs={{ span: 23 }}>
              <h3 className="text-right font-600 m-0">*Scene Number :</h3>
            </Col>
            <Col
              xs={{ span: 23 }}
              lg={{ span: 14 }}
              md={{ span: 10 }}
              className="pl-15"
            >
              <Form.Item className="m-0">
                {getFieldDecorator("sceneNumber", {
                  rules: [
                    {
                      required: true,
                      message: "select scene number!",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder="Scene Number"
                    className="w-100"
                    name="sceneNumber"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => this.setState({ sceneNumber: value })}
                  >
                    {sceneNumberList &&
                      sceneNumberList.map((list) => {
                        return <option value={list.value}>{list.label}</option>;
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col lg={{ span: 9 }} md={{ span: 10 }} xs={{ span: 23 }}>
                  <h3 className="text-right font-600 m-0">
                    *Scene Description :
                  </h3>
                </Col>
                <Col
                  xs={{ span: 23 }}
                  lg={{ span: 14 }}
                  md={{ span: 10 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("description", {
                      rules: [
                        {
                          required: true,
                          message: "enter your description!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Description"
                        name="description"
                        onChange={(e) => this.handleChange(e)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col lg={{ span: 9 }} md={{ span: 10 }} xs={{ span: 23 }}>
                  <h3 className="text-right font-600 m-0">*Scene Location :</h3>
                </Col>
                <Col
                  xs={{ span: 23 }}
                  lg={{ span: 14 }}
                  md={{ span: 10 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("sceneLocationName", {
                      initialValue: this.state.sceneLocationName,
                      rules: [
                        {
                          required: true,
                          message: "select scene location name!",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        placeholder="Select Location"
                        value={this.state.sceneLocationName}
                        onSearch={(value) => this.handleSearch(value)}
                        onChange={(value) => this.handleSceneLocation(value)}
                        onBlur={(e) => this.handleLoctaionBlur(e)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {locationNameList &&
                          locationNameList.map((list) => {
                            return (
                              <option value={list.label}>{list.label}</option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row className="mb-25 veritical-center">
              <Col lg={{ span: 9 }} md={{ span: 10 }} xs={{ span: 23 }}>
                <h3 className="text-right font-600 m-0">*Environment :</h3>
              </Col>
              <Col
                xs={{ span: 23 }}
                md={{ span: 10 }}
                lg={{ span: 14 }}
                className="pl-15"
              >
                <Row gutter={0}>
                  <Col span={24}>
                    <Form.Item className="m-0">
                      {getFieldDecorator("environmentType", {
                        rules: [
                          {
                            required: true,
                            message: "select environment type!",
                          },
                        ],
                      })(
                        <Select
                          placeholder="INT/EXT"
                          className="w-100"
                          name="environmentType"
                          onChange={(e) =>
                            this.setState({ environmentType: e })
                          }
                        >
                          {environmentTypeList &&
                            environmentTypeList.map((list) => (
                              <option value={list.value}>{list.label}</option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row className="mb-25 veritical-center">
              <Col lg={{ span: 9 }} md={{ span: 10 }} xs={{ span: 23 }}>
                <h3 className="text-right font-600 m-0">*Time :</h3>
              </Col>
              <Col
                xs={{ span: 23 }}
                md={{ span: 10 }}
                lg={{ span: 14 }}
                className="pl-15"
              >
                <Row gutter={0}>
                  <Col span={24}>
                    <Form.Item className="m-0">
                      {getFieldDecorator("timeType", {
                        rules: [
                          {
                            required: true,
                            message: "select time type!",
                          },
                        ],
                      })(
                        <Select
                          placeholder="DAY/NIGHT"
                          className="w-100"
                          name="timeType"
                          onChange={(e) => this.setState({ timeType: e })}
                        >
                          {environmentTimeList &&
                            environmentTimeList.map((list) => (
                              <option value={list.value}>{list.label}</option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
          <Row className="mb-25 veritical-center">
            <Col lg={{ span: 9 }} md={{ span: 10 }} xs={{ span: 23 }}>
              <h3 className="text-right font-600 m-0">Scene Type &nbsp; :</h3>
            </Col>
            <Col md={{ span: 10 }} xs={{ span: 24 }} lg={{ span: 24 }}>
              <div className="text-left ml-15 pt-3">
                <Form.Item className="m-0">
                  <Checkbox.Group
                    style={{ textTransform: "capitalize" }}
                    options={plainOptions}
                    name="sceneTypes"
                    onChange={(e) => this.setState({ sceneTypes: e })}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={24}>
          <Row type="flex" justify="end">
            <Button
              type="default"
              style={{ marginRight: 10 }}
              onClick={() => this.props.closeModal && this.props.closeModal()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Row>
        </Col>
      </div>
    );
  }
}
export default Form.create()(CreateScene);
