import { getUserMenus, getCount } from "utilities/UserHelper";
import { setMenus, setNotificationCount } from "store/action";
import { LocalStorage } from "constants/AppConstants";

export const getPermissions = () => {
  return (dispatch) => {
    getUserMenus().then((res) => {
      dispatch(setMenus(res.data.menus));
    });
  };
};

export const getNotificationCount = () => {
  let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  return (dispatch) => {
    getCount(projectId)
      .then((res) => {
        dispatch(setNotificationCount(res.data.totalCount || 0));
      })
      .catch((err) => dispatch(setNotificationCount()));
  };
};
