import { LocalStorage, Services } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class MakeupTabService {
  makeupTabService(type, data, budjetId, sceneId = null, id) {
    let finalSceneId = "";
    if (sceneId) {
      finalSceneId = sceneId;
    } else {
      finalSceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    }
    // let sceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);

    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.SceneOVerviewVariable.GET_MAKEUP:
        return axios.get(
          `${getScriptAPIURL()}/scenes/${finalSceneId}/makeup`,
          config
        );
      case Services.SceneOVerviewVariable.GET_MAKEUP_BY_ID:
        return axios.get(`${getScriptAPIURL()}/makeup/budget/${data}`, config);
      case Services.SceneOVerviewVariable.UPDATE_MAKEUP:
        return axios.put(
          `${getScriptAPIURL()}/makeup/budget/${budjetId}`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.CREATE_MAKEUP_BUDJET:
        return axios.post(`${getScriptAPIURL()}/makeup/budget`, config);
      case Services.SceneOVerviewVariable.GET_MAKEUP_DATA:
        return axios.get(`${getScriptAPIURL()}/characters/${id}/makeup`, config);
      case Services.SceneOVerviewVariable.GETTING_MAKEUP:
        return axios.get(
          `${getScriptAPIURL()}/scenes/${finalSceneId}/makeup`,
          config
        );
      case Services.SceneOVerviewVariable.POSTING_MAKEUP:
        return axios.post(
          `${getScriptAPIURL()}/scene/${finalSceneId}/makeup`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.UPDATING_MAKEUP:
        return axios.put(
          `${getScriptAPIURL()}/makeup/${budjetId}`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.DELETE_MAKEUP_TAB_TABLE:
        return axios.delete(`${getScriptAPIURL()}/costumes/${data}`, config);
      default:
        break;
    }
  }
}
