import React, { Component } from "react";

class HeroAudition extends Component {
  componentDidMount() {
    console.log("componentDidMount HeroAudition");
  }
  render() {
    return <div>Hero Audition</div>;
  }
}

export default HeroAudition;
