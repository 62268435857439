import React, { Component, Fragment } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
  Icon,
} from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import { ScriptService } from "services";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import ActorsServices from "services/ActorsServices";
import { connect } from "react-redux";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import SongsStuntsServices from "services/SongsStuntsServices";
import * as _ from "lodash";
import RecordNotFound from "components/shared/RecordNotFound";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import { checkFor, removeColumns } from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class SongsTable extends Component {
  getSequenceType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SEQUENCE_TYPE
      )
      .then((res) => {
        this.setState({ sequenceTypeData: res.data });
      });
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
    // this._actorsService = new ActorsServices();
    this._actorService = new ActorTabService();
    this._songsServices = new SongsStuntsServices();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      actorId: null,
      activeSceneId: null,
      sequenceTypeData: [],
      descriptionTypes: [],
      // tableCount: 0
    };
  }

  componentDidMount() {
    this.fetchData();
    this.getSequenceType();
    this.getDDList();
    this.fetchDescriptionType();
  }

  fetchDescriptionType() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.DESCRIPTION_TYPE
      )
      .then((res) => {
        this.setState({ descriptionTypes: res.data });
      });
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  getDDList = () => {
    this._songsServices
      .service(
        Services.SongVariables.GET_SONGS_STUNT_DD_LIST,
        Services.SongVariables.SONGS,
        null,
        localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID)
      )
      .then((res) => {
        this.setState({
          songDDList: res.data.sceneTypes,
        });
      })
      .catch((err) => {
        console.log("song fetch data", err);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchData();
          this.getDDList();
        }
      );
    }
  }

  fetchData = () => {
    this.setState({
      count: 0,
      dataSource: [],
    });
    this._songsServices
      .service(
        Services.SongVariables.GET_SONGS_STUNT_LIST,
        Services.SongVariables.SONGS
      )
      .then((res) => {
        let dataSource = [];
        res.data.sceneDetails &&
          res.data.sceneDetails.map((datum, i) => {
            dataSource.push({ ...datum, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState(
          {
            dataSource,
            totalRecords: dataSource,
            // isActorsFetched: true,
          },
          () => {
            // this.checkForEmpty(this.state.dataSource);
          }
        );
      })
      .catch((err) => {
        console.log("song fetch data", err);
      });
  };

  handleSubmit = (record) => {
    const data = {
      description: record.description,
      memberCount: parseInt(record?.memberCount) || 0,
      price: record.price,
      sequenceType: record.sequenceType,
      descriptionType: record.descriptionType,
    };

    this._songsServices
      .service(
        Services.SongVariables.ADD_SONGS_STUNTS,
        Services.SongVariables.SONGS,
        null,
        data
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Songs Created Successfully!!"
        );
        this.fetchData();
        this.getDDList();
        this.props.getNotificationCount(0);
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (record) => {
    const data = {
      description: record.description,
      memberCount: record.memberCount,
      price: record.price,
      sequenceType: record.sequenceType,
      descriptionType: record.descriptionType,
    };
    this._songsServices
      .service(
        Services.SongVariables.UPDATE_SONGS_STUNTS,
        Services.SongVariables.SONGS,
        record.sceneTypeDetailId,
        data
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getDDList();
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        // this.checkForEmpty(this.state.dataSource);
      }
    );
    if (id) {
      this._songsServices
        .service(
          Services.SongVariables.DELETE_SONGS_STUNTS,
          Services.SongVariables.SONGS,
          id
        )
        .then((res) => {
          // showNotifications(Notifications.SUCCESS, "Successfully", " Deleted !!");
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.getDDList();
          this.fetchData();
          this.props.getNotificationCount();
        });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      memberCount: "",
      price: "",
      sequenceType: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      // count:count + 1
    });
  };

  checkForSave(updatedRow) {
    let { description, memberCount, price, sequenceType, descriptionType } =
      updatedRow;
    if (
      description &&
      memberCount &&
      price &&
      sequenceType &&
      descriptionType
    ) {
      this.handleSubmit(updatedRow);
    }
  }

  onChange(value, record, type) {
    let updatedData = [...this.state.dataSource];
    let updatedRow = updatedData.find((data) => data.key === record.key);
    updatedRow[type] = value;
    this.setState({ dataSource: updatedData });
    if (record.sceneTypeDetailId) {
      this.handleUpdate(updatedRow);
    } else {
      this.checkForSave(updatedRow);
    }
    return;
    // record[type] = value;
    // this.handleSave(record, value);

    // const lengthOfTheDataSource = this.state.dataSource.length - 1;
    // const newData = [...this.state.dataSource];
    // const indexOfTheCurrentRow = newData.findIndex(
    //   (data) => record.key === data.key
    // );
  }

  prePopulateDataSave(record) {
    if (record.sceneTypeDetailId) {
      this.handleUpdate(record);
    } else {
      this.checkForSave(record);
    }
  }

  handleSave = (row, value = null) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      let modifyItem = newData[index];
      // modifyItem.sequenceType = value;
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      // this.props.getFromChild(this.state.dataSource);
    });
    if (!row.sceneTypeDetailId) {
      if (
        !checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        ) &&
        row.description &&
        row.memberCount
      ) {
        this.handleSubmit(row);
      } else if (
        row.description &&
        row.memberCount &&
        row.sequenceType &&
        row.price
      ) {
        this.handleSubmit(row);
      }
      //   else {
      //     showNotifications(
      //       Notifications.ERROR,
      //       "Failed",
      //       "All Fields Are Mandatory"
      //     );
      //   }
    }
    if (row.sceneTypeDetailId) {
      if (
        !checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        ) &&
        row.description &&
        row.memberCount
      ) {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      } else if (
        row.description &&
        row.memberCount &&
        row.sequenceType &&
        row.price
      ) {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      }
    }
  };

  setSong = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = this.state.songKeyword;
      if (newData) {
        this.setState({ eventType });
        record.description = newData;
        this.onChange(newData, record, "description");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  handleSong = (name, record) => {
    let updatedData = [...this.state.dataSource];
    let updatedRow = updatedData.find((data) => data.key === record.key);
    updatedRow.description = name;
    this.setState({ dataSource: updatedData });
    if (record.sceneTypeDetailId) {
      this.handleUpdate(updatedRow);
    } else {
      this.checkForSave(updatedRow);
    }
    return;
    this._songsServices
      .service(
        Services.SongVariables.GET_SONGS_STUNT_DETAIL,
        Services.SongVariables.SONGS,
        localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        name
      )
      .then((res) => {
        let data = {
          description: res.data.description,
          memberCount: res.data.memberCount,
          price: res.data.price,
          sequenceType: res.data.sequenceType || "Day",
          descriptionType: res.data.descriptionType || "",
        };

        if (record.sceneTypeDetailId) {
          data = {
            ...data,
            sceneTypeDetailId: record.sceneTypeDetailId,
          };
          this.handleUpdate(data);
        } else {
          this.handleSubmit(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSongValue = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          songKeyword: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };
  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  handleDescriptionTypeChange(value, record) {
    let updatedData = [...this.state.dataSource];
    let updatedRow = updatedData.find((data) => data.key === record.key);
    updatedRow.descriptionType = value;
    this.setState({ dataSource: updatedData });
    // let payload = {...record};
    // payload.descriptionType = value;
    if (record.sceneTypeDetailId) {
      this.handleUpdate(updatedRow);
    } else {
      this.checkForSave(updatedRow);
    }
  }

  handleSave() {}

  render() {
    const { dataSource, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    let columns = [
      {
        title: "#.",
        dataIndex: "sceneTypeDetailId",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.sceneTypeDetailId < b.sceneTypeDetailId,
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "15%",
        // editable: true,
        align: "left",
        className: "overflowInput",
        render: (text, record) => (
          <Select
            showSearch
            onSearch={(val) => this.handleSongValue(val, record)}
            disabled={
              !checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )
            }
            onFocus={() =>
              this.setState({
                juniorCharcKeyword: null,
                eventType: "isNotBlur",
              })
            }
            onBlur={(value) => {
              this.prePopulate(value, record);
              this.setSong(value, record, "blur");
            }}
            style={{ width: "100%", minWidth: "98%", maxWidth: "200px" }}
            placeholder="Select type"
            // disabled={!this.props.EDIT && record.characterId}
            // onChange={value =>
            //   this.onChange(value, record, "characterName")
            // }
            onChange={(value) => this.handleSong(value, record)}
            value={text ? text : ""}
            // defaultValue={text ? text : ""}
            className={`${!text ? "custom-empty-active" : ""} caps`}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.state.songDDList &&
              this.state.songDDList.map((option, i) => (
                <Option value={option.label}>{option.label}</Option>
              ))}
          </Select>
        ),
        // return {
        //   props: {
        //     className: `${!text ? "empty-active" : ""}`
        //   },
        //   children: text
        // };
      },
      {
        title: "Description Type",
        dataIndex: "descriptionType",
        width: "15%",
        // editable: true,
        align: "left",
        className: "overflowInput",
        render: (text, record) => (
          <Select
            showSearch
            // onSearch={(val) => this.handleSongValue(val, record)}
            disabled={
              !checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )
            }
            onFocus={() =>
              this.setState({
                juniorCharcKeyword: null,
                eventType: "isNotBlur",
              })
            }
            // onBlur={(value) => this.setSong(value, record, "blur")}
            style={{ width: "100%", minWidth: "98%", maxWidth: "200px" }}
            placeholder="Select type"
            // disabled={!this.props.EDIT && record.characterId}
            // onChange={value =>
            //   this.onChange(value, record, "characterName")
            // }
            onChange={(value) =>
              this.handleDescriptionTypeChange(value, record)
            }
            value={text ? text : ""}
            // defaultValue={text ? text : ""}
            className={`${!text ? "custom-empty-active" : ""} caps`}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.state.descriptionTypes &&
              this.state.descriptionTypes.map((option, i) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        ),
        // return {
        //   props: {
        //     className: `${!text ? "empty-active" : ""}`
        //   },
        //   children: text
        // };
      },
      {
        title: "Nos",
        dataIndex: "memberCount",
        width: "10%",
        editable: checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_EDIT
        ),
        align: "right",
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""} cash`,
            },
            children: text,
          };
        },
      },
      {
        title: "Day/Sequence",
        dataIndex: "sequenceType",
        width: "15%",
        // editable: true,
        align: "left",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select
              style={{ width: 140 }}
              disabled={
                !checkFor(
                  this.props.activePermisision,
                  GlobalConstants.PERMISSION_EDIT
                )
              }
              onChange={(value) => this.onChange(value, record, "sequenceType")}
              value={text}
              className={`${!text ? "custom-empty-active" : ""}`}
            >
              {this.state.sequenceTypeData &&
                this.state.sequenceTypeData.map((sequence, i) => (
                  <Option value={sequence.value}>{sequence.label}</Option>
                ))}
            </Select>
          ) : null,
      },
      {
        title: "Rate (₹)",
        dataIndex: "price",
        align: "right",
        editable: checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_EDIT
        ),
        width: "15%",
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""} cash`,
            },
            children: text,
          };
        },
      },
    ];
    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_DELETE)
    ) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "10%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.sceneTypeDetailId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  {" "}
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null,
      });
    }
    if (
      !checkFor(
        this.props.activePermisision,
        GlobalConstants.PERMISSION_FINANCE
      )
    ) {
      columns = removeColumns(columns, ["Rate (₹)", "Amount", "Day/Sequence"]);
    }
    const columnsName = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex !== "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Fragment>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {checkFor(
            this.props.activePermisision,
            GlobalConstants.PERMISSION_ADD
          ) ? (
            <Col xs={24}>
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginBottom: 5, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} />
                Entry
              </Button>
            </Col>
          ) : (
            ""
          )}
          <Col xl={24}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnsName}
              pagination={{ pageSize: 1000 }}
              locale={{
                emptyText: <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }

  prePopulate(value, records) {
    const allDatas = this.state.dataSource;
    const filterData = allDatas.filter((a) => a.description === value);
    if (filterData?.length) {
      const data = filterData[0];
      const newData = {
        description: data?.description,
        memberCount: data?.memberCount,
        sequenceType: data?.sequenceType,
        price: data?.price,
        descriptionType: data?.descriptionType,
        documents: data?.documents,
        sceneTypeDetailId: records?.sceneTypeDetailId || null,
      };
      this.prePopulateDataSave(newData);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Song", menus),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SongsTable);
