import React, { Component } from "react";
import { Select } from "antd";
import { isEmpty } from "lodash";

import EquipmentProjectService from "services/EquipmentProjectService";
import { Services } from "constants/AppConstants";

const { Option } = Select;

export default class VendorListDD extends Component {
  constructor(props) {
    super(props);

    this._equipmentProjectService = new EquipmentProjectService();
    this.state = {
      vendorListDropdown: [],
      equipmentResourceType: props.record.equipmentResourceType,
    };
    this.fetchVendors = this.fetchVendors.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps;
    const { equipmentResourceType } = this.state;
    if (equipmentResourceType !== record.equipmentResourceType) {
      this.setState(
        {
          equipmentResourceType: record.equipmentResourceType,
        },
        this.fetchVendors
      );
    }
  }

  componentDidMount() {
    this.fetchVendors();
  }

  fetchVendors() {
    const { equipmentResourceType } = this.state;
    if (equipmentResourceType) {
      this._equipmentProjectService
        .equipmentProjectService(
          Services.EquipmentVariables.GET_VENDOR_LIST,
          equipmentResourceType
        )
        .then((res) => {
          this.setState({
            vendorListDropdown: res.data.vendors,
          });
        })
        .catch((err) => {});
    }
  }

  render() {
    const { onChange, text, record, disabled } = this.props;
    const { equipmentResourceType } = record;
    const { vendorListDropdown } = this.state;
    return (
      <Select
        style={{ width: "100%" }}
        className="border-none"
        placeholder="Select type"
        // disabled={record.scheduleEquipmentId}
        onChange={(value) => onChange(value, record, "vendorId")}
        defaultValue={record.vendorName ? record.vendorName : ""}
        disabled={disabled}
      >
        {vendorListDropdown &&
          vendorListDropdown.map((option, i) => (
            <Option value={option.value}>{option.label}</Option>
          ))}
      </Select>
    );
  }
}
