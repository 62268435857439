import React, { Component, Fragment } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
  BackTop,
  Icon,
} from "antd";
import GlobalService from "services/GlobalService";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

import {
  Services,
  GlobalConstants,
  LocalStorage,
  Notifications,
} from "constants/AppConstants";
import BudjetService from "services/BudjetService";

import "./PreProduction.css";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import * as _ from "lodash";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { checkFor } from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class ProProduction extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._budjetService = new BudjetService();

    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getTypeDatas();
    this.getPreProductionData();
  }

  getPreProductionData = () => {
    this.setState({
      isLoading: true,
    });
    this.setState({ count: 0, dataSource: [], totalRecords: [] });
    this._budjetService
      .budjetService(Services.BudjetVariables.GET_PRE_PRODUCTION_DATA)
      .then((res) => {
        res.data.preProductionPayments?.map((data, index) => {
          data.key = index + 1;
        });
        this.setState({
          count: res.data.preProductionPayments?.length + 1,
          dataSource: res?.data?.preProductionPayments,
          totalRecords: res?.data?.preProductionPayments,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getTypeDatas = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRE_PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          typeDatas: res.data,
        });
      })
      .catch((err) => {});
  };
  handleDelete = (key, projectProductionCategoryId) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      }
      // () => this.props.getFromChild(this.state.dataSource)
    );
    this.setState({
      isLoading: true,
    });
    this._budjetService
      .budjetService(
        Services.BudjetVariables.DEL_PRE_PRODUCTION,
        null,
        projectProductionCategoryId
      )
      .then((res) => {
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Deleted",
        //   "Deleted Successfully!!"
        // );
        this.setState({
          isLoading: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.getPreProductionData();
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      category: "",
      paymentType: "",
      price: "",
      quantity: "",
      total: "",
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record) {
    this.handleSave(record, value, "paymentType");

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }
  inputChange(value, record, type) {
    this.handleSave(record, value, type);

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    // if (value) {
    if (type === "paymentType") {
      let modifyItem = newData[index];
      modifyItem.paymentType = value;
      if (value === "PROJECT") {
        modifyItem.quantity = 0;
      }
    } else if (type === "category") {
      let modifyItem = newData[index];
      modifyItem.category = value;
    } else if (type === "quantity") {
      let modifyItem = newData[index];
      modifyItem.quantity = value;
    } else if (type === "price") {
      let modifyItem = newData[index];
      modifyItem.price = value;
    }
    // }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      console.log("dataSource save", this.state.dataSource);
    });

    // console.log(JSON.parse(JSON.stringify(row)));
    // console.log(JSON.parse(JSON.stringify(this.state.activeRecord)));
    // console.log(_.isEqual(row, this.state.activeRecord));
    if (!row.projectProductionCategoryId) {
      if (row.category && row.paymentType && row.price && row.quantity) {
        return this.handleSubmit(row);
      }
      // else {
      //   showNotifications(
      //     Notifications.ERROR,
      //     "Failed",
      //     "All Fields Are Mandatory"
      //   );
      // }
    }
    let isTrue = false;
    if (row.paymentType) {
      if (row.paymentType === "PROJECT") {
        isTrue = true;
      } else {
        isTrue = row.quantity;
      }
    }
    if (row.projectProductionCategoryId) {
      if (row.category && row.paymentType && row.price && isTrue) {
        // if (!_.isEqual(row, this.state.activeRecord)) {
        this.handleUpdate(row);
        // }
      }
    } else {
      if (row.category && row.paymentType && row.price && isTrue) {
        this.handleSubmit(row);
      }
    }
  };
  setActiveRecord = (record) => {
    this.setState(
      {
        activeRecord: JSON.parse(JSON.stringify(record)),
      },
      () => console.log("activeRecord", this.state.activeRecord)
    );
  };
  handleSubmit = (record) => {
    this.setState({
      isLoading: true,
    });
    const data = {
      category: record.category,
      paymentType: record.paymentType,
      price: record.price,
      quantity: record.quantity,
    };

    this._budjetService
      .budjetService(Services.BudjetVariables.SAVE_PRE_PRODUCTION_DATA, data)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully"
        );
        this.getPreProductionData();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.props.getPermissions();
      });
  };

  handleUpdate = (record) => {
    const data = {
      category: record.category,
      paymentType: record.paymentType,
      price: record.price,
      quantity: record.quantity,
      categoryId: record.categoryId,
    };
    this.setState({
      isLoading: true,
    });

    this._budjetService
      .budjetService(
        Services.BudjetVariables.UPDATE_PRE_PRODUCTION_DATA,
        data,
        record.projectProductionCategoryId
      )
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Success",
        //   "Updated Successfully"
        // );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getPreProductionData();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        this.props.getPermissions();
      });
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const { activePermisision, getPermissions } = this.props;

    const column = [
      {
        title: "#",
        align: "center",
        width: "6%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "category",
        className: "budjet-text-ellipsis caps",
        width: "30%",
        align: "left",
        render: (text, record, index) => (
          <Input
            autoFocus={!text}
            className={`${!text ? "custom-empty-active" : ""}`}
            defaultValue={text}
            disabled={record.isEnable === false}
            onBlur={(e) => this.inputChange(e.target.value, record, "category")}
          />
        ),
      },
      // {
      //   title: "Type",
      //   dataIndex: "paymentType",
      //   align: "center",
      //   width: "24%",
      //   render: (text, record) => (
      //     <Select
      //       showSearch
      //       style={{ width: 140 }}
      //       placeholder="Select type"
      //       //   optionFilterProp="children"
      //       onChange={value => this.onChange(value, record)}
      //       defaultValue={text ? text : ""}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       disabled={
      //         !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
      //       }
      //     >
      //       {this.state.typeDatas &&
      //         this.state.typeDatas.map(data => {
      //           return <Option value={data.value}>{data.label}</Option>;
      //         })}
      //     </Select>
      //   )
      // },
      // {
      //   title: "Rate (₹)",
      //   dataIndex: "price",
      //   width: "15%",
      //   editable: true,
      //   align: "center"
      // },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "15%",
        // editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        align: "center",
        className: "cash",
        render: (text, record) =>
          record.paymentType !== "PROJECT" && (
            <Input
              type="number"
              defaultValue={text}
              className={`${!text ? "custom-empty-active" : ""}`}
              onFocus={() => this.setActiveRecord(record)}
              onBlur={(e) =>
                this.inputChange(e.target.value, record, "quantity")
              }
            />
          ),
      },
      // {
      //   title: "Total",
      //   dataIndex: "totalPrice",
      //   width: "15%",
      //   // editable: true,
      //   align: "center"
      // }
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      column.splice(2, 0, {
        title: "Type",
        dataIndex: "paymentType",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select type"
            //   optionFilterProp="children"
            className={`${!text ? "custom-empty-active" : ""}`}
            onChange={(value) => this.onChange(value, record)}
            defaultValue={text ? text : ""}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
            }
          >
            {this.state.typeDatas &&
              this.state.typeDatas.map((data) => {
                return <Option value={data.value}>{data.label}</Option>;
              })}
          </Select>
        ),
      });
      column.splice(3, 0, {
        title: "Rate (₹)",
        dataIndex: "price",
        width: "15%",
        // editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        align: "center",
        className: "cash",
        render: (text, record) => (
          <Input
            type="number"
            defaultValue={text}
            className={`${!text ? "custom-empty-active" : ""}`}
            onFocus={(e, record) => this.setActiveRecord(record || "")}
            onBlur={(e) => this.inputChange(e.target.value, record, "price")}
          />
        ),
      });
      column.splice(5, 0, {
        title: "Total",
        dataIndex: "totalPrice",
        width: "15%",
        // editable: true,
        align: "center",
        className: "cash",
      });
    }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      column.push({
        title: "Action",
        dataIndex: "operation",
        width: "8%",
        render: (text, record) => (
          <Row type="flex" justify="center">
            <React.Fragment>
              <Popconfirm
                disabled={record.isEnable === false}
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(
                    record.key,
                    record.projectProductionCategoryId
                  )
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button
                    icon="delete"
                    type="link"
                    disabled={record.isEnable === false}
                  />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          </Row>
        ),
      });
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "category" ? "text" : "number",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Pre-Production </TitleText>
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Col xl={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
              loading={isLoading}
              className="production-table"
            />
          </Col>
        </Row>
        <BackTop visibilityHeight="50" />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProProduction);
