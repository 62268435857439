import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  Icon,
  Popconfirm,
  Tooltip,
} from "antd";

import { connect } from "react-redux";
import * as _ from "lodash";

import { GlobalConstants, LocalStorage, Menus } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { Services, Notifications } from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, removeColumns, StartCase } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { handleError } from "utilities/ErrorHandler";
import { Value, Label } from "components/shared/Label";
import RecordNotFound from "../RecordNotFound";

const { TextArea } = Input;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title, name } = this.props;
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <TextArea
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              name={name}
              // style={{ width: 100 }}
              rows={4}
              className="costume_makeup_table_description"
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              name={name}
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave(record, values[e.currentTarget.name], e.currentTarget.name);
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class CostumeMakeupDescriptionTable extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._costumeTabService = new CostumeTabService();
    this.onChange = this.onChange.bind(this);
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);

    this.state = {
      sceneCostumeMakeupId: props.sceneCostumeMakeupId,
      dataSource: [],
      totalRecords: [],
      count: 0,
      isModalVisible: false,
      fileList: null,
      docFiles: null,
      dropDown: null,
      sceneId: null,
      isLoading: true,
      isButtonLoading: false,
      sceneId: null,
      statusTypeData: [],
      activeSceneId: null,
      designationTypeValue: null,
      activeRecord: null,
      pageType: props.pageType,
    };
  }

  componentDidMount() {
    this.getCostumeData();
    // this.checkForEmpty(this.state.dataSource);
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  handleFocus = (record) => {
    this.setState({ designationTypeValue: null, eventType: "isNotBlur" });
  };

  designationTypeValue(value, record) {
    if (value.length) {
      delete record.characterId;
    }
    if (value && value.trim().length > 0) {
      this.setState({
        designationTypeValue: value,
      });
    }
    this.setState({
      isOnChanged: false,
    });
  }

  designationBlurChanges(value, record, eventType) {
    if (this.state.isOnChanged != true) {
      let designation = this.state.designationTypeValue;
      if (designation) {
        this.setState({ eventType });
        this.onChange(designation, record, "characterName");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  }

  toggleModal = (activeRecord, activeValue) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      activeRecord,
      activeValue,
    });
  };

  getCostumeData = () => {
    const { sceneCostumeMakeupId, pageType } = this.state;
    const type =
      pageType === Menus.MAKEUP
        ? Services.SceneOVerviewVariable.GET_GENERAL_MAKEUP_DETAILS_BY_PROJECT
        : Services.SceneOVerviewVariable.GET_GENERAL_COSTUME_DETAILS_BY_PROJECT;
    this.setState({ count: 0, isLoading: true });
    this._costumeTabService
      .costumeTabService(type, null, sceneCostumeMakeupId)
      .then((res) => {
        let costumeData = [];
        let data =
          res.data.generalCostumeDetails || res.data.generalMakeupDetails;
        data &&
          data.map((data, i) => {
            costumeData.push({ ...data, key: data?.costumeMakeUpDetailId });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState(
          {
            dataSource: costumeData,
            totalRecords: costumeData,
            isLoading: false,
          }
          // () => {
          //   this.checkForEmpty(this.state.dataSource);
          // }
        );
      });
  };

  recordData = (record) => {
    if (this.state.fileList) {
      this.handleSave(record, this.state.fileList, "image");
    }
  };

  handleDelete = (key, id) => {
    const { pageType } = this.state;
    const dataSource = [...this.state.dataSource];
    const type =
      pageType === Menus.MAKEUP
        ? Services.SceneOVerviewVariable.DELETE_GENERAL_MAKEUP_DETAILS
        : Services.SceneOVerviewVariable.DELETE_GENERAL_COSTUME_DETAILS;
    if (id) {
      this._costumeTabService
        .costumeTabService(type, null, id)
        .then((res) => {
          // showNotifications(
          //   Notifications.SUCCESS,
          //   "Success",
          //   "Successfully Deleted!!"
          // );
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );

          this.getCostumeData();
        })
        .catch((err) => {
          this.props.getPermissions();
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong"
          );
        });
    } else {
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  handleSubmit = (record) => {
    const { sceneCostumeMakeupId, pageType } = this.state;
    const type =
      pageType === Menus.MAKEUP
        ? Services.SceneOVerviewVariable.SAVE_GENERAL_MAKEUP_DETAILS
        : Services.SceneOVerviewVariable.SAVE_GENERAL_COSTUME_DETAILS;
    let recordData = {
      sceneCostumeMakeupId: sceneCostumeMakeupId,
      description: record.description,
      quantity: record.quantity,
      rate: record.rate,
    };
    this.setState({ isButtonLoading: true });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
    let data = this.state.dataSource[indexOfTheCurrentRow];
    this._costumeTabService
      .costumeTabService(type, recordData, sceneCostumeMakeupId)
      .then((res) => {
        this.setState({
          isButtonLoading: false,
          eventType: null,
          designationTypeValue: null,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully!!"
        );
        this.getCostumeData();
      })
      .catch((err) => {
        this.props.getPermissions();
        this.setState({
          isButtonLoading: false,
          eventType: null,
          designationTypeValue: null,
        });

        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(err?.response?.data?.errors[0] || "Somthing Went Wrong")
        );
      });
  };

  handleUpdate = (record) => {
    const { sceneCostumeMakeupId, pageType } = this.state;
    const type =
      pageType === Menus.MAKEUP
        ? Services.SceneOVerviewVariable.UPDATE_GENERAL_MAKEUP_DETAILS
        : Services.SceneOVerviewVariable.UPDATE_GENERAL_COSTUME_DETAILS;
    let recordData = {
      sceneCostumeMakeupId: sceneCostumeMakeupId,
      description: record.description,
      quantity: record.quantity,
      rate: record.rate,
      costumeMakeupDetailId: record.costumeMakeUpDetailId,
    };
    record.cost = record.specialCost;
    this._costumeTabService
      .costumeTabService(type, recordData, sceneCostumeMakeupId)
      .then((res) => {
        this.setState({
          isButtonLoading: false,
          eventType: null,
          designationTypeValue: null,
        });
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Success",
        //   "Updated Successfully!!"
        // );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getCostumeData();
      })
      .catch((err) => {
        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(err?.response?.data?.errors[0] || "Somthing Went Wrong")
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      quantity: null,
      rate: "",
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type, eventType) {
    this.handleSave(record, value, type);
    this.setState({
      isOnChanged: true,
    });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    let modifyItem = null;
    if (value) {
      modifyItem = newData[index];
      switch (type) {
        case "description":
          modifyItem.description = value;
          break;
        case "quantity":
          modifyItem.quantity = value;
          break;
        case "rate":
          modifyItem.rate = value;
          break;
        default:
          break;
      }
    }
    if (!modifyItem.costumeMakeUpDetailId) {
      if (
        modifyItem.description &&
        modifyItem.quantity &&
        (checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        )
          ? modifyItem.rate
          : true)
      ) {
        this.handleSubmit(modifyItem);
      }
    }
    if (modifyItem.costumeMakeUpDetailId) {
      if (!_.isEqual(modifyItem, this.state.activeRecord)) {
        this.handleUpdate(modifyItem);
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  render() {
    const { isLoading, dataSource, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const { activePermisision, characterName, totalCost, pageType } =
      this.props;
    let columns = [
      {
        title: "#",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "10%",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        className: "costume_makeup_table_description caps",
        align: "center",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "10%",
        align: "center",
        className: "cash",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      },
      {
        title: "Rate",
        dataIndex: "rate",
        width: "10%",
        align: "center",
        className: "cash",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "10%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.costumeMakeUpDetailId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null,
      });
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    if (!checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      columns = removeColumns(columns, ["Rate"]);
    }

    const columnsData = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "description" ? "text" : "number",
          name: col.dataIndex,
          editable:
            col.editable !==
            checkFor(
              this.props.activePermisision,
              GlobalConstants.PERMISSION_EDIT
            )
              ? record.costumeMakeupId
                ? false
                : true
              : true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });

    const label = pageType === Menus.MAKEUP ? "Makeup" : "Costume";

    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Col xs={12}>
                <Label>Character Name</Label>
                <Value>{StartCase(characterName)}</Value>
              </Col>
              <Col xs={12}>
                <Label>Total {label} Cost</Label> <Value>{totalCost}</Value>
              </Col>
            </Col>
            <Col xs={24}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
                <Button
                  onClick={this.handleAdd}
                  type="primary"
                  style={{ marginBottom: 5, float: "right" }}
                  disabled={hasNewRow}
                >
                  <Icon type="plus" style={{ fontSize: "15px" }} />
                  Entry
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
            <Table
              // className="costume_makeup_table"
              components={components}
              // rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnsData}
              pagination={false}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
              loading={isLoading}
            />
          ) : (
            <ContactAdminErrorTemplate />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    // activePermisision: getSPermissionFromMenus("Costume", menus)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPermissions: () => dispatch(getPermissions())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostumeMakeupDescriptionTable);
