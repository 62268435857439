import React, { Component } from "react";
import { GlobalConstants } from "constants/AppConstants";
import { Button, Tooltip } from "antd";

const Icontext = {
  edit: GlobalConstants.EDT,
  delete: GlobalConstants.DEL,
  copy: GlobalConstants.CPY,
  save: GlobalConstants.SAV,
  car: GlobalConstants.TRANSPORT,
  warning: GlobalConstants.TRANSPORT,
  more: GlobalConstants.MORE,
  download: GlobalConstants.DOWNLOAD,
  profile: GlobalConstants.ATTENDANCE,
};
const TooltipIcon = ({ onClick, icon, style, titleText = null, placement }) => {
  return (
    <Tooltip
      title={titleText || Icontext[icon]}
      placement={placement ? placement : GlobalConstants.RIGHT}
    >
      <Button icon={icon} type="link" onClick={onClick} style={style} />
    </Tooltip>
  );
};

export default TooltipIcon;
