import React, { Component, Fragment } from "react";
import { Row, Col, Button, Tabs } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

// import "./accounting.scss";
import { Services, MenusRoute } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { checkFor, PriceFormat } from "utilities/ValidationHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import InvoiceService from "services/InvoiceService";
import RecordNotFound from "components/shared/RecordNotFound";
import InvoiceTable from "./InvoiceTable";
import { setCostumeLeftActiveTab } from "store/action";
import InvoiceMoreTable from "./InvoiceMoreTable";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
const { TabPane } = Tabs;

const Categories = [
  GlobalConstants.PRE_PRODUCTION,
  GlobalConstants.PRODUCTION_CAPS,
  GlobalConstants.POST_PRODUCTION,
  GlobalConstants.TRAVEL_AND_LODGEING,
  GlobalConstants.CAST_TECHNICIANS,
  GlobalConstants.VENDOR,
];

class InvoicesMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: props.costumeLeftActiveTab,
    };
  }

  renderTabs = () => {
    let template = [];
    [
      GlobalConstants.Pre_Production,
      GlobalConstants.Production,
      GlobalConstants.Post_Production,
      GlobalConstants.Travel_Lodgeing,
      GlobalConstants.Cast_Technicians,
      GlobalConstants.Vendor,
    ].map((value, i) => {
      if (value === GlobalConstants.Production)
        template.push(
          <TabPane tab={value} key={i + 1}>
            <InvoiceTable category={Categories[i]} {...this.props} />
          </TabPane>
        );
      else {
        template.push(
          <TabPane tab={value} key={i + 1}>
            <InvoiceMoreTable category={Categories[i]} {...this.props} />
          </TabPane>
        );
      }
    });
    return template;
  };

  render() {
    const { activeKey } = this.state;
    const { setCostumeLeftActiveTab, activePermisision } = this.props;
    return (
      <Fragment>
        <Row type="flex" justify="center">
          <Col xl={24}>
            <Title>
              <TitleText>Invoices </TitleText>
            </Title>
          </Col>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
            <Col xl={24} style={{ padding: 10 }}>
              <Tabs
                defaultActiveKey={activeKey}
                onChange={(activeKey) => setCostumeLeftActiveTab(activeKey)}
                animated
                destroyInactiveTabPane
              >
                {this.renderTabs()}
              </Tabs>
            </Col>
          ) : (
            <ContactAdminErrorTemplate />
          )}
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  user: { menus },
  activeItems: { costumeLeftActiveTab },
}) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.INVOICE, menus),
    costumeLeftActiveTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesMain);
