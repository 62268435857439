import React, { Component } from "react";
import { Row, Col, Icon, Popconfirm, Modal, Tooltip } from "antd";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";

import locationImg from "assets/images/location.png";
import userImg from "assets/images/user.png";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { showNotifications } from "components/shared/NotificationComponent";
import CreateLocationModal from "components/Location/AddLocationModal";
import GoogleLocation from "components/shared/GoogleMap";

import "./locations.css";
import { checkFor, Capitalize } from "utilities/ValidationHelper";
import { connect } from "react-redux";
import { getNotificationCount } from "store/thunk-actions";
import { handleError } from "utilities/ErrorHandler";

class LocationCardComponent extends Component {
  state = {
    name: null,
    status: null,
    imageId: null,
    cardList: [],
    isLoading: false,
    locationModel: false,
    mapModel: false,
    latitude: null,
    longitude: null,
    visible: false,
    imageList: [],
  };

  constructor(props) {
    super(props);
    this._locationService = new LocationTabService();
  }

  showModal = () => {
    if (this.props.ImageIdList) {
      this.setState({
        visible: true,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleLatLongValue = (latitude, longitude) => {
    this.setState({
      latitude,
      longitude,
    });
  };

  handleModel = (name, value, type) => {
    if (type !== "entire") {
      if (name === "map") {
        this.setState({
          mapModel: value,
          locationModel: !value,
        });
      } else {
        this.setState({
          mapModel: !value,
          locationModel: value,
        });
      }
    } else {
      this.setState({
        mapModel: false,
        locationModel: false,
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  confirm = (e) => {
    this.setState({ isLoading: true });
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.DELETE_LOCATION,
        this.props.locationAuditionId
      )
      .then((res) => {
        this.props.refreshLocation();
        this.props.getNotificationCount();
        this.setState({ isLoading: false });
        showNotifications(
          Notifications.SUCCESS,
          "Successfully",
          "Location Removed!!"
        );
      })
      .catch((err) => {
        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  refreshLocation = () => {
    this.props.refreshLocation();
  };

  componentDidMount() {
    this.setState({
      imageList: this.props.ImageIdList,
    });
  }

  getImages = (imageList) => {
    let images = [];

    imageList &&
      imageList.forEach((list) => {
        images.push({
          original: list?.attachment?.url,
          thumbnail: list?.attachment?.url,
        });
      });

    return images;
  };

  selectImage = (e) => {
    console.log(e);
  };

  render() {
    const {
      actualLocationName,
      locationId,
      status,
      imageId,
      activePermisision,
    } = this.props;
    const { latitude, longitude, imageList } = this.state;
    const images = this.getImages(imageList);

    return (
      <div>
        <Col
          xl={{ span: 6 }}
          lg={{ span: 10 }}
          md={{ span: 16 }}
          xs={{ span: 24 }}
          style={{ padding: "10px" }}
        >
          <div className={status !== "Confirmed" ? "rejected " : ""}>
            <div className="card">
              {status === "Confirmed" && (
                <div>
                  <div className="confrimedCard">
                    <Icon type="check-circle" />
                  </div>
                </div>
              )}
              {checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_DELETE
              ) ? (
                <div className="deleteCard pr-15">
                  <Popconfirm
                    title="Are you sure delete this Loaction?"
                    onConfirm={this.confirm}
                    okText="Yes"
                    cancelText="No"
                    loading={this.state.isLoading}
                  >
                    <a href="#">
                      <Tooltip
                        title={GlobalConstants.DEL}
                        placement={GlobalConstants.RIGHT}
                      > 
                        <Icon type="delete" style={{ fontSize: "13px" }} />
                      </Tooltip>
                    </a>
                  </Popconfirm>
                </div>
              ) : null}
              <Row>
                {/* <Col span={16} className="vertical-center pl-15"> */}
                <Col span={20} className="cardTitle">
                  {checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) ? (
                    <Link
                      className="font-600 text-ellipsis text-wrap"
                      to={`/project/update-location/${locationId}/${actualLocationName}/${this.props.sceneLocationName.replace(
                        /\//g,
                        "_"
                      )}/${
                        this.props.sceneLocationId ||
                        localStorage.getItem(LocalStorage.LOCATION_ID)
                      }`}
                    >
                      <h3>
                        <a className="font-600 text-ellipsis text-wrap">
                          {Capitalize(actualLocationName)}
                        </a>
                      </h3>
                    </Link>
                  ) : (
                    <Link className="font-600 text-ellipsis text-wrap">
                      <h3>
                        <a className="actloc font-600 text-ellipsis text-wrap">
                          {Capitalize(actualLocationName)}
                        </a>
                      </h3>
                    </Link>
                  )}
                  <p className="locationStatus">{status}</p>
                </Col>
                <Col span={10} className="locationIcon">
                  <img
                    style={{ cursor: "pointer",float:"right"}}
                    onClick={this.showModal}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = userImg;
                    }}
                    src={imageId || locationImg}
                    alt="Location"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Modal
          footer=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <ImageGallery items={images} onClick={this.selectImage} />
        </Modal>

        <CreateLocationModal
          locationModel={this.state.locationModel}
          handleModel={this.handleModel}
          latitude={latitude}
          longitude={longitude}
          refreshLocation={this.refreshLocation}
          closeModal={this.closeModal}
          locationId={this.props.locationId}
          actualLocationName={this.props.actualLocationName}
          sceneLocationId={this.props.sceneLocationId}
          type="Update"
        />

        <GoogleLocation
          geoLocation={this.geoLocation}
          handleModel={this.handleModel}
          mapModel={this.state.mapModel}
          getLatLongValues={this.handleLatLongValue}
          latitude={latitude}
          longitude={longitude}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(null, mapDispatchToProps)(LocationCardComponent);
