import React, { Fragment } from "react";
import { Row, Button, Col } from "antd";
import { Icon } from "antd";

const Title = ({
  children,
  hasBack,
  history,
  handleChange = () => {},
  hasButton,
}) => {
  return (
    <Row
      type="flex"
      align="middle"
      justify={"space-between"}
      style={{
        backgroundColor: "#001529",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
      }}
      className="box-shadow"
    >
      {hasBack ? (
        <Col xl={24}>
          <Button
            type="primary"
            style={{
              backgroundColor: "transparent",
              borderWidth: 0,
              fontSize: 20,
              paddingLeft: 0,
            }}
            onClick={() => {
              history.goBack();
              handleChange && handleChange();
            }}
          >
            <Icon type="left" />
            {/* Go back */}
          </Button>
          {children}
        </Col>
      ) : (
        children
      )}
    </Row>
  );
};

export default Title;
