import React, { Component } from "react";
import { connect } from "react-redux";
import { Upload, Icon, message } from "antd";
import PropTypes from "prop-types";
import GlobalService from "services/GlobalService";
import { AttachmentType, ImageDomain } from "constants/AppConstants";
import ResizeFile from "utilities/ImageCompression";
import UploadService from "services/uploadService";
import { urltoFile } from "utilities/UserHelper";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJPG =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJPG) {
    message.error("You can only upload JPG, PNG and JPEG file !");
  }
  // return isJPG && isLt2M;
  return isJPG;
}

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
  }

  state = {
    loading: false,
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          loading: false,
        });
      });
      if (
        info.file.originFileObj != null &&
        info.file.originFileObj != undefined
      ) {
        ResizeFile(info.file.originFileObj).then((base64) => {
          urltoFile(base64).then((file) => {
            let formData = new FormData();
            formData.append("file", file);

            UploadService.projectImageUpload(ImageDomain?.aceScript, formData, {
              attachmentType: this.props.type || AttachmentType?.projectPicture,
              isBucketUrlRequired: true,
            }).then((res) => {
              this.setState({
                imageUrl: null,
              });
              this.props.handleImage(res?.data?.id, this.props.keys);
              if (this.props.handleResponse) {
                this.props?.handleResponse(res?.data, this.props.keys);
              }
            });
          });
        });
      }
    }
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const { imageUrl } = this.state;
    const { text } = this.props;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">{text}</div>
      </div>
    );

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={this.dummyRequest} // created for image upload issue since the mocky.io is expired
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{ height: "105px", width: "105px" }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

UploadImage.propTypes = {
  text: PropTypes.string.isRequired,
};

UploadImage.defaultProps = {
  text: "Upload Image",
};

const mapStateToProps = ({ user }) => {
  return {
    currentUser: user.currentUser,
    accessToken: user.accessToken,
  };
};

export default connect(mapStateToProps)(UploadImage);
