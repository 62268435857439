import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getUserAPIURL, getScriptAPIURL } from "utilities/APIHelper";

export default class LegalPayeeService {
 static service(type, data, id) {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.LegalPayeeVariables.DELETE_LEGAL_PAYEE:
        return axios.delete(
          `${getScriptAPIURL()}/legal-payee/${id}`,
          config
        );
      case Services.LegalPayeeVariables.UPDATE_LEGAL_PAYEE:
        return axios.put(
          `${getScriptAPIURL()}/legal-payee/${id}`,
          data,
          config
        );
      case Services.LegalPayeeVariables.CREATE_LEGAL_PAYEE:
        return axios.post(
          `${getScriptAPIURL()}/legal-payee`,
          data,
          config
        );
      default:
        break;
    }
  }
}
