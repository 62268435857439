import React, { useEffect, useState } from "react";
import "./Map.scss";
import { Icon } from "antd";

const Map = ({
  onChange = () => {},
  lat = 11.002944403622696,
  lng = 76.96619560880073,
  zoom = 9,
  onClose = () => {},
}) => {
  const [showControls, setShowControls] = useState(false);
  var locationData = {
    lat: lat || 11.002944403622696,
    lng: lng || 76.96619560880073,
  };

  function initMap() {
    let map = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: lat || 11.002944403622696,
        lng: lng || 76.96619560880073,
      },
      zoom: zoom,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit.station",
          stylers: [{ visibility: "off" }],
        },
      ],
      fullscreenControl: false,
      disableDoubleClickZoom: false,
      streetViewControl: false,
      mapTypeControl: false,
      keyboardShortcuts: false,
    });

    let marker = new window.google.maps.Marker({
      position: {
        lat: lat || 11.002944403622696,
        lng: lng || 76.96619560880073,
      },
      map,
    });

    map.addListener("click", (e) => {
      let data = e.latLng.toJSON();
      locationData = data;
      marker.setPosition(data);
    });

    // Done Button
    const doneBtn = document.getElementById("location-done-btn");
    map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(doneBtn);

    doneBtn.addEventListener("click", (e) => {
      geocode({ location: locationData });
    });

    // Input
    const input = document.getElementById("pac-input");
    const searchBox = new window.google.maps.places.SearchBox(input);

    map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(input);
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For eac  h place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        locationData = place.geometry.location.toJSON();

        // Create a marker for each place.
        marker.setPosition(place.geometry.location);
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }

  function geocode(request) {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(request).then((result) => {
      const { results } = result;
      const addressComponents = results[0]?.address_components;
      formatAddress(
        addressComponents,
        results[0].formatted_address,
        request?.location
      );
    });
  }

  const formatAddress = (allAddress = [], fullAddress, location) => {
    let result = {
      postalCode: null,
      country: null,
      state: null,
      city: null,
      address: null,
      latitude: location?.lat || null,
      longitude: location?.lng || null,
    };
    allAddress?.forEach((address) => {
      if (address?.types?.includes("postal_code")) {
        result.postalCode = address?.long_name;
      }
      if (address?.types?.includes("country")) {
        result.country = address?.long_name;
      }
      if (address?.types?.includes("administrative_area_level_1")) {
        result.state = address?.long_name;
      }
      if (address?.types?.includes("administrative_area_level_3")) {
        result.city = address?.long_name;
      }
      result.address = fullAddress;
    });
    onChange(result);
  };

  useEffect(() => {
    setTimeout(() => {
      initMap();
    }, 2000);
    setTimeout(() => {
      setShowControls(true);
    }, 1500);
  }, []);

  return (
    <div className="map-wrapper">
      <div className="header">
        <span id="title">Choose Location</span>
        <Icon
          type="close-circle"
          style={{ fontSize: 18, color: "white", cursor: "pointer" }}
          onClick={() => onClose()}
        />
      </div>
      {showControls && (
        <>
          <input
            id="pac-input"
            className="controls"
            type="text"
            placeholder="Search Box"
          />
          <button type="primary" id="location-done-btn">
            Done
          </button>
        </>
      )}
      <div className="map-main-div" id="map">
        {!showControls && (
          <div id="loading">
            <span>Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
