import React, { Component, Fragment } from "react";
import { Button, Input, Select, Row, Col, Form, Tag } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import "antd/dist/antd.css";
import PDFComponent from "components/shared/PDFViewer";
import SchedularService from "services/SchedularServices";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { showActionMessage } from "components/shared/NotificationComponent";

import "./SchedulerUpdate.css";
import { Capitalize, checkFor } from "utilities/ValidationHelper";
import LocationAccordion from "./LocationAccordion";
import SchedularActorList from "./SchedularActorList";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";

class SchedularUpdate extends Component {
  constructor(props) {
    super(props);
    this._schedulerService = new SchedularService();
    this._globalService = new GlobalService();
    this.state = {
      scheduleDate: this.props.match.params.date,
      shootEndDate: null,
      projectId: this.props.match.params.projectId,
      locationDetails: [],
      sceneDetails: [],
      callSheet: null,
      callSheetOptions: [],
      statusOption: [],
      breakOptions: [],
      breakValue: null,
      status: null,
      callTime: null,
      actorList: [],
      supportingActors: [],
      extras: [],
      artsets: [],
      songs: [],
      stunts: [],
      costumes: [],
      makeup: [],
      equipments: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
    this.getCallSheet();
    this.getBreak();
    this.getStatus();
  }

  getStatus = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        Services.GlobalVariables.SCHEDULE_STATE
      )
      .then((res) => {
        this.setState({
          statusOption: res.data,
        });
      });
  };

  getCallSheet = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        Services.GlobalVariables.CALL_SHEET
      )
      .then((res) => {
        this.setState({
          callSheetOptions: res.data,
        });
      });
  };

  getBreak = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        Services.GlobalVariables.BREAK
      )
      .then((res) => {
        this.setState({
          breakOptions: res.data,
        });
      });
  };

  fetchDetails = () => {
    const { projectId, scheduleDate } = this.state;
    this._schedulerService
      .services(
        Services.SchedularVariables.GET_SCHEDULER_MORE_SCREEN_DETAILS,
        projectId,
        null,
        scheduleDate
      )
      .then((res) => {
        this.setState({
          locationDetails: res.data.locations,
          sceneDetails: res.data.scenes,
          callSheet: res.data.callSheet,
          callSheetValue: res.data.callSheetEnum,
          callTime: res.data.callTime,
          breakValue: res.data.schedularBreakEnum,
          status: res.data.stateEnum,
          actorList: res.data.actors,
          extras: res.data.extras,
          stunts: res.data.stunts,
          songs: res.data.songs,
          artsets: res.data.artsets,
          supportingActors: res.data.supportingActors,
          shootEndDate: res.data.shootEndDate,
          costumes: res.data.costumes,
          makeups: res.data.makeups,
          equipments: res.data.equipments,
        });
      });
  };

  handleUpdate = () => {
    this.setState({
      isUpdating: true,
    });
    const {
      callTime,
      scheduleDate,
      breakValue,
      callSheetValue,
      status,
      projectId,
    } = this.state;
    let data = {
      callTime,
      shootDate: scheduleDate,
      scheduleBreak: breakValue,
      state: status,
      callSheet: callSheetValue,
      projectId,
    };
    this._schedulerService
      .services(
        Services.SchedularVariables.UPDATE_CALL_TIME_BREAK,
        null,
        null,
        data
      )
      .then((res) => {
        this.fetchDetails();
        showActionMessage(
          GlobalConstants.SUCCESS,
          `Your Changes ${Notifications.UPDATED_SUCCESSFULLY}`
        );
        this.setState({
          isUpdating: false,
        });
      })
      .catch((err) => {
        this.setState({
          isUpdating: false,
        });
      });
  };

  renderLocations = () => {
    const { locationDetails } = this.state;
    return (
      <LocationAccordion
        scheduleDate={this.state.scheduleDate}
        location={locationDetails}
      />
    );
  };

  render() {
    const { Option } = Select;

    const {
      scheduleDate,
      callSheet,
      breakOptions,
      breakValue,
      callTime,
      isUpdating,
      actorList,
      artsets,
      stunts,
      songs,
      projectId,
      costumes,
      equipments,
      makeup,
    } = this.state;
    const { activePermisision } = this.props;

    const hasValues = callTime && breakValue;
    return (
      <Fragment>
        <Row span={24}>
          <Title hasBack hasButton {...this.props}>
            <TitleText>SCHEDULER</TitleText>
            <Row type="flex" className="flrt">
              <PDFComponent projectId={projectId} scheduleDate={scheduleDate} />
            </Row>
          </Title>
        </Row>
        <Row align="middle" justify="center" type="flex">
          <Col xl={24} style={{ paddingTop: 20 }}>
            <Form>
              <Row>
                <Col xl={24}>
                  <Row style={{ paddingBottom: 15 }}>
                    <Col xl={8}>
                      <Row type="flex" align="middle">
                        <Col xl={12}>
                          <label className="text-right floatRight font-600 m-0">
                            Shoot Date : &nbsp; &nbsp;
                          </label>
                        </Col>
                        <Col xl={12}>
                          {/* <Form.Item className="m-0" width="200px"> */}
                          <div style={{ fontWeight: "bold" }}>
                            {scheduleDate}
                          </div>
                          {/* </Form.Item> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={8}>
                      <Row type="flex" align="middle">
                        <Col xl={8}>
                          <label className="text-right floatRight font-600 m-0">
                            Weather Forecast : &nbsp;
                          </label>
                        </Col>
                        <Col xl={7}>
                          <div style={{ fontWeight: "bold" }}> {"Rain"}</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={8}>
                      <Row type="flex" align="middle">
                        <Col xl={12}>
                          <label className="text-right floatRight font-600 m-0">
                            Call Sheet : &nbsp; &nbsp;
                          </label>
                        </Col>

                        <Form.Item className="m-0">
                          {callSheet ? (
                            <div style={{ fontWeight: "bold" }}>
                              {callSheet}
                            </div>
                          ) : (
                            <div>
                              <Select
                                // defaultValue={text}
                                style={{ width: 150 }}
                                onChange={(callSheetValue) => {
                                  this.setState({ callSheetValue });
                                }}
                                placeholder="Select Callsheet"
                              >
                                {this.state.callSheetOptions &&
                                  this.state.callSheetOptions.map((option) => (
                                    <Option value={option.value}>
                                      {option.label}
                                    </Option>
                                  ))}
                              </Select>
                            </div>
                          )}
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: 15 }}>
                    <Col xl={8}>
                      <Row type="flex" align="middle">
                        <Col xl={12}>
                          <label className="text-right floatRight font-600 m-0">
                            Call Time : &nbsp; &nbsp;
                          </label>
                        </Col>
                        <Col xl={12}>
                          <Form.Item className="m-0">
                            <Input
                              style={{ width: 150 }}
                              value={callTime}
                              placeholder="enter call time"
                              disabled={
                                !checkFor(
                                  activePermisision,
                                  GlobalConstants.PERMISSION_EDIT
                                )
                              }
                              onChange={(e) =>
                                this.setState({ callTime: e.target.value })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col xl={8}>
                      <Row type="flex" align="middle">
                        <Col xl={8}>
                          <label className="text-right floatRight font-600 m-0">
                            Break : &nbsp;
                          </label>
                        </Col>
                        <Form.Item className="m-0">
                          <Select
                            value={breakValue}
                            style={{ width: 150 }}
                            onChange={(breakValue) => {
                              this.setState({ breakValue });
                            }}
                            placeholder="Select Break"
                            disabled={
                              !checkFor(
                                activePermisision,
                                GlobalConstants.PERMISSION_EDIT
                              )
                            }
                          >
                            {breakOptions &&
                              breakOptions.map((option) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Row>
                    </Col>

                    <Col xl={8}>
                      <Col xl={{ offset: 12, span: 12 }}>
                        <Button
                          style={{}}
                          type="primary"
                          onClick={this.handleUpdate}
                          disabled={
                            !hasValues ||
                            !checkFor(
                              activePermisision,
                              GlobalConstants.PERMISSION_EDIT
                            )
                          }
                          loading={isUpdating}
                        >
                          Update
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Col>
                <Col xl={23} style={{ marginBottom: 15, marginLeft: 20 }}>
                  {this.renderLocations()}
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xl={22}>
            {actorList?.length !== 0 && (
              <Row>
                <SchedularActorList dataSource={actorList} />
              </Row>
            )}
          </Col>
          {/*  */}
          <Col xs={22} style={{ marginBottom: 15 }}>
            <Row>
              {artsets?.length !== 0 && (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600 m-0">Art/Set </label>
                    </Col>
                  </Row>
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                    >
                      <Form.Item className="m-0 schedulerTagContainer">
                        {artsets &&
                          artsets.map((extra) => (
                            <Tag className="schedulerTag">
                              {Capitalize(extra.description)} ({extra.quantity})
                            </Tag>
                          ))}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {songs?.length !== 0 && (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600 m-0">Song </label>
                    </Col>
                  </Row>
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                    >
                      <Form.Item className="m-0 schedulerTagContainer">
                        {songs &&
                          songs.map((extra) => (
                            <Tag className="schedulerTag">
                              {Capitalize(extra.description)} (
                              {extra.memberCount})
                            </Tag>
                          ))}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {stunts?.length !== 0 && (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600 m-0">Stunt </label>
                    </Col>
                  </Row>
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                    >
                      <Form.Item className="m-0 schedulerTagContainer">
                        {stunts &&
                          stunts.map((extra) => (
                            <Tag className="schedulerTag">
                              {Capitalize(extra.description)} (
                              {extra.memberCount})
                            </Tag>
                          ))}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
          <Col xl={22} style={{ marginBottom: 15 }}>
            <Row className="veritical-center">
              {equipments?.length !== 0 && (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600 m-0">Equipments </label>
                    </Col>
                  </Row>
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                    >
                      <Form.Item className="m-0 schedulerTagContainer">
                        {equipments &&
                          equipments.map((extra) => (
                            <Tag className="schedulerTag">
                              {Capitalize(extra.equipmentName)} (
                              {extra.quantity})
                            </Tag>
                          ))}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {costumes?.length !== 0 && (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600 m-0">Costumes </label>
                    </Col>
                  </Row>
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                    >
                      <Form.Item className="m-0 schedulerTagContainer">
                        {costumes &&
                          costumes.map((extra) => (
                            <Tag className="schedulerTag">
                              {Capitalize(extra.name)}
                            </Tag>
                          ))}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {makeup?.length !== 0 && (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                  style={{ marginTop: "20px" }}
                >
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="font-600 m-0">Makeup </label>
                    </Col>
                  </Row>
                  <Row className="veritical-center">
                    <Col
                      xl={{ span: 24 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                    >
                      <Form.Item className="m-0 schedulerTagContainer">
                        {makeup &&
                          makeup.map((extra) => (
                            <Tag className="schedulerTag">
                              {Capitalize(extra.costumeName)}
                            </Tag>
                          ))}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.SCHEDULER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedularUpdate);
