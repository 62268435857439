import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class EquipmentServices {
  service(type, sceneId, data, equipmentName, sceneEquipmentId) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )} `,
      },
    };
    switch (type) {
      case Services.EquipmentVariables.GET_EQUIPMENT_TAB:
        return axios.get(
          `${getScriptAPIURL()}/scene/${sceneId}/equipments`,
          config
        );
      case Services.EquipmentVariables.GET_EQUIPMENT_PREVIOUS_DATA:
        return axios.get(
          `${getScriptAPIURL()}/scene/${sceneId}/equipments/${data}`,
          config
        );

      case Services.EquipmentVariables.POST_EQUIPMENT_TAB:
        return axios.post(
          `${getScriptAPIURL()}/scenes/equipments`,
          data,
          config
        );
      case Services.EquipmentVariables.EQUIPMENT_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/equipments?name=${equipmentName}&sceneId=${sceneId}`,
          config
        );
      case Services.EquipmentVariables.EQUIPMENT_DROPDOWN_LEFT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/equipments?name=${equipmentName}`,
          config
        );
      case Services.EquipmentVariables.PUT_EQUIPMENT_TAB:
        return axios.put(
          `${getScriptAPIURL()}/scenes/${sceneId}/equipments/${sceneEquipmentId}`,
          data,
          config
        );
      case Services.EquipmentVariables.DEL_EQUIPMENT_TAB:
        return axios.delete(
          `${getScriptAPIURL()}/scenes/equipments/${sceneEquipmentId}`,
          config
        );
      default:
        break;
    }
  }
}
