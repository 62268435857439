import React, { Component } from "react";
import { Modal, Icon, Col, Row, Popconfirm, Button, Checkbox } from "antd";
import ImageGallery from "react-image-gallery";

import "./image.css";
import { getScriptAPIURL } from "utilities/APIHelper";
import DocumentDelete from "services/DocumentDelete";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { showNotifications, showActionMessage } from "../NotificationComponent";

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this._imageDelete = new DocumentDelete();
    this.myRef = React.createRef();
    this.state = {
      ImagesData: [],
      visible: false,
      selectedImages: [],
      imageCount: 0,
      tableImages: [],
      singleImageVisible: false,
      singleImageHide: false,
      singleImageId: null,
      isMultiSelectEnabled: false,
      selectedImagesForDelete: [],
      isDeleting: false,
      imageViewerImages: [],
      viewingImageIndex: 0,
    };
  }

  componentDidMount() {
    this.setState({ tableImages: this.props.ImagesData });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ tableImages: nextProps.ImagesData });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    // this.props.refreshData();
  };

  checkTheImageIsSelected = (imageId) => {
    return this.state.selectedImagesForDelete.includes(imageId);
  };

  renderImages() {
    let imageTemplate = [];
    const { ImagesData, imageCount } = this.props;
    if (ImagesData) {
      let totalImages = ImagesData.length;

      for (let i = 0; i < totalImages; i++) {
        if (i < imageCount) {
          imageTemplate.push(
            <div className={`image `}>
              <img src={ImagesData[i]?.attachment?.url} alt="" />
              <div
                className="overlay"
                onClick={() => {
                  // this.setState({
                  //   // visible: true,
                  //   selectedImages: [ImagesData[i].locationImageId]
                  // })
                  this.showSingleImageModal(
                    this.props.ImagesData[0]?.attachment?.url
                  );
                }}
              >
                <img
                  alt="eye"
                  className="eyeIcon"
                  src="https://img.icons8.com/color/48/000000/visible.png"
                />
              </div>
            </div>
          );
        } else {
          imageTemplate.push(
            <div
              className="overlayContent"
              onClick={() =>
                this.showSingleImageModal(
                  this.props.ImagesData[0]?.attachment?.url
                )
              }
            >
              <div className="content">
                <span className="text">+{totalImages - imageCount} Images</span>
                <span className="seeMore">see all</span>
              </div>
            </div>
          );
          break;
        }
      }

      return imageTemplate;
    }
  }

  moveImageToFirst = (imageList, id) => {
    let imageToPushFirst = imageList.filter(
      (image) => image?.attachmentId === id
    );
    let filteredImages = imageList.filter(
      (image) => image?.attachmentId !== id
    );
    filteredImages.unshift(...imageToPushFirst);

    return filteredImages;
  };

  populateImageData = (imageList, id) => {
    let images = [];

    imageList = this.moveImageToFirst(imageList, id);

    imageList &&
      imageList.map((list) => {
        images.push({
          original: list?.attachment?.url,
          thumbnail: list?.attachment?.url,
        });
      });

    this.setState({
      imageViewerImages: images,
    });
  };

  getImages = (imageList) => {
    let images = [];

    imageList &&
      imageList.map((list) => {
        images.push({
          original: list?.attachment?.url,
          thumbnail: list?.attachment?.url,
        });
      });

    return images;
  };

  confirmDelete = (id, i) => {
    const { refreshData, url } = this.props;

    console.log("refreshData", this.props);

    let temp = this.state.tableImages;
    temp.splice(i, 1);
    this.setState({ tableImages: temp });
    this._imageDelete
      .deleteService(url, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        refreshData();
      })
      .catch((err) => {
        // showNotifications(
        //   Notifications.ERROR,
        //   "Failed",
        //   "Something Went Wrong"
        // );
      });
  };

  showSingleImageModal = (id) => {
    this.setState(
      {
        singleImageVisible: true,
        visible: false,
        singleImageId: id,
      },
      () => this.populateImageData(this.props.ImagesData, id)
    );
  };

  hideSingleImageModal = () => {
    this.setState({
      singleImageVisible: false,
      // visible: true,
      // singleImageId: null
    });
  };

  addToSelectedImagesForDelete = (imageId) => {
    let temp = [];
    let selectedImagesForDelete = this.state.selectedImagesForDelete;
    selectedImagesForDelete.push(imageId);
    this.setState({
      selectedImagesForDelete,
    });
  };

  removeFromSelectedImages = (imageId) => {
    let selectedImagesForDelete = this.state.selectedImagesForDelete.filter(
      (data) => data !== imageId
    );
    this.setState({
      selectedImagesForDelete,
    });
  };

  deleteMultipleImages = () => {
    const { refreshData, url } = this.props;
    let totalImages = this.state.selectedImagesForDelete.length;
    let totalDeleted = 0;
    // console.log(
    //   "this.state.selectedImagesForDelete",
    //   this.state.selectedImagesForDelete
    // );
    const { selectedImagesForDelete } = this.state;
    this.setState({
      isDeleting: true,
    });
    selectedImagesForDelete.map((id) => {
      this._imageDelete
        .deleteService(url, id)
        .then((res) => {
          totalDeleted = totalDeleted + 1;
          if (totalImages === totalDeleted) {
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.SUCCESSFULLY_REMOVED
            );
            this.setState(
              {
                isMultiSelectEnabled: false,
                selectedImagesForDelete: [],
                isDeleting: false,
              },
              () => refreshData && refreshData()
            );
          }
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong"
          );
        });
    });
  };

  removeAllSelectedImages = () => {
    this.setState({ selectedImagesForDelete: [] });
  };

  scrollToMyRef = (i) => {
    this.myRef.current.scrollTo(i * 115, 0);
    // console.log(this.myRef.current.offsetTop)
  };

  render() {
    const {
      selectedImages,
      selectedImagesForDelete,
      isMultiSelectEnabled,
      isDeleting,
      imageViewerImages,
    } = this.state;
    const totalSelectedImages = selectedImagesForDelete.length;
    const { image_container_width, canDelete } = this.props;
    const images = this.getImages(this.props.ImagesData);

    const deleteText =
      totalSelectedImages === 1
        ? `image?`
        : `all ${totalSelectedImages} images?`;
    const deleteButtonText = isMultiSelectEnabled
      ? "Cancel the delete operation"
      : "Click to delete multiple images";

    const image_container_width_custom = {
      "--image_container_width": image_container_width,
    };
    return (
      <div className="wrapper">
        <div
          className="imageContainer"
          style={{ ...image_container_width_custom, flexDirection: "row" }}
        >
          {this.renderImages()}
        </div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={
            <Button type="primary" onClick={this.handleCancel}>
              Cancel
            </Button>
          }
          className="customModal"
          width="98%"
          destroyOnClose
        >
          {/* <ImageGallery items={images} /> */}
          <div className="image-area">
            <div className="header-area">
              <div>
                {canDelete && (
                  <Button
                    type="primary"
                    onClick={() =>
                      isMultiSelectEnabled
                        ? this.setState({ isMultiSelectEnabled: false }, () =>
                            this.removeAllSelectedImages()
                          )
                        : this.setState({ isMultiSelectEnabled: true })
                    }
                  >
                    {deleteButtonText}
                  </Button>
                )}

                {totalSelectedImages > 0 && (
                  <React.Fragment>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.removeAllSelectedImages();
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      Deselect All
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.deleteMultipleImages();
                      }}
                      style={{ marginLeft: 10 }}
                      className="delete-button"
                      loading={isDeleting}
                    >
                      {`Delete ${deleteText}`}
                    </Button>
                  </React.Fragment>
                )}
              </div>
              {isMultiSelectEnabled && (
                <div className="info-text">
                  Click the images to select.{" "}
                  {totalSelectedImages > 0 &&
                    `You have Selected ${totalSelectedImages} ${
                      totalSelectedImages === 1 ? "image" : "images"
                    }`}
                </div>
              )}
            </div>

            {this.props.type !== "table" ? (
              <ImageGallery items={images} />
            ) : (
              <div className="image-container">
                {this.state.tableImages &&
                  this.state.tableImages.map((image, i) => (
                    <div className="seperate-image-container">
                      {this.checkTheImageIsSelected(
                        image?.documentId ||
                          image?.documentId ||
                          image?.locationImageId
                      ) && (
                        <div
                          className="overlay-image"
                          onClick={() =>
                            this.removeFromSelectedImages(
                              image?.documentId ||
                                image?.documentId ||
                                image?.locationImageId
                            )
                          }
                        />
                      )}
                      <div className={`image`}>
                        {canDelete && !isMultiSelectEnabled && (
                          <div className="delete-icon">
                            <Popconfirm
                              placement="topRight"
                              title="Are you sure to delete"
                              onConfirm={() => {
                                this.confirmDelete(
                                  image?.documentId || image?.locationImageId,
                                  i
                                );
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button type="primary" icon="delete" />
                            </Popconfirm>
                            {/* <Icon type="delete" /> */}
                          </div>
                        )}
                        {isMultiSelectEnabled && (
                          <div className="check-box">
                            <Checkbox
                              checked={this.checkTheImageIsSelected(
                                image?.documentId ||
                                  image?.documentId ||
                                  image?.locationImageId
                              )}
                            />
                          </div>
                        )}
                        <div
                          onClick={() => {
                            !isMultiSelectEnabled
                              ? this.showSingleImageModal(image?.documentId)
                              : this.addToSelectedImagesForDelete(
                                  image?.documentId || image?.locationImageId
                                );
                          }}
                        >
                          <img
                            className="multiImages"
                            src={`${getScriptAPIURL()}/file/download/${
                              image?.fileId || image?.fileData
                            }`}
                          />
                        </div>
                        {/* <div className="overlay-single-image">
                      <img
                        className="eyeIcon"
                        src="https://img.icons8.com/color/48/000000/visible.png"
                      />
                    </div> */}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </Modal>

        <Modal
          visible={this.state.singleImageVisible}
          onOk={this.handleOk}
          onCancel={this.hideSingleImageModal}
          width="800px"
          footer={
            <Button type="primary" onClick={this.hideSingleImageModal}>
              Cancel
            </Button>
          }
        >
          {/* <img
            style={{ width: "100%" }}
            src={`${getScriptAPIURL()}/file/download/${this.state.singleImageId}`}
          /> */}

          <ImageGallery
            items={imageViewerImages}
            showThumbnails={false}
            onSlide={(i) => {
              this.setState({ viewingImageIndex: i });
              this.scrollToMyRef(i);
            }}
          />

          {/* new */}
          <div
            className="image-container"
            id="imageContainerThumbail"
            ref={this.myRef}
          >
            {this.state.tableImages &&
              this.state.tableImages.map((image, i) => (
                <div className="seperate-image-container">
                  {this.checkTheImageIsSelected(
                    image?.documentId ||
                      image?.documentId ||
                      image?.locationImageId
                  ) && (
                    <div
                      className="overlay-image"
                      onClick={() =>
                        this.removeFromSelectedImages(
                          image?.documentId ||
                            image?.documentId ||
                            image?.locationImageId
                        )
                      }
                    />
                  )}
                  <div className={`image`}>
                    {canDelete && !isMultiSelectEnabled && (
                      <div className="delete-icon">
                        <Popconfirm
                          placement="topRight"
                          title="Are you sure to delete"
                          onConfirm={() => {
                            this.confirmDelete(image?.entityId);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" icon="delete" />
                        </Popconfirm>
                      </div>
                    )}
                    {isMultiSelectEnabled && (
                      <div className="check-box">
                        <Checkbox
                          checked={this.checkTheImageIsSelected(
                            image?.documentId ||
                              image?.documentId ||
                              image?.locationImageId
                          )}
                        />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        this.setState({
                          viewingImageIndex: i,
                        });
                        this.scrollToMyRef(i);
                        !isMultiSelectEnabled
                          ? this.showSingleImageModal(image?.attachmentId)
                          : this.addToSelectedImagesForDelete(
                              image?.attachmentId || image?.locationImageId
                            );
                      }}
                    >
                      {this.state.viewingImageIndex === i ? (
                        <img
                          alt="thumbnail"
                          className="selectedImage"
                          src={image?.attachment?.url}
                        />
                      ) : (
                        <img
                          alt="thumbnail"
                          className="multiImages"
                          src={image?.attachment?.url}
                        />
                      )}
                    </div>
                    {/* <div className="overlay-single-image">
                      <img
                        className="eyeIcon"
                        src="https://img.icons8.com/color/48/000000/visible.png"
                      />
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ImageViewer;
ImageViewer.defaultProps = {
  canDelete: false,
};
