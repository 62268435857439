import React, { useState } from "react";
import { Tabs, Col, Row } from "antd";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import { LocalStorage } from "constants/AppConstants";
import { useHistory } from "react-router-dom";
import Crew from "./LegalTabTables/Crew";
import Vendor from "./LegalTabTables/Vendor";
import Location from "./LegalTabTables/Location";
import Actor from "./LegalTabTables/Actor";
import Technicians from "./LegalTabTables/Technicians";
import ThirdPartyPayee from "./LegalTabTables/3rdPartyPayee";

const { TabPane } = Tabs;
const Legal = () => {
  const history = new useHistory();
  const [ActiveTab, setActiveTab] = useState(
    localStorage.getItem(LocalStorage.TABINDEX) || "1"
  );
  const handleChange = (key) => {
    if (key === "5" || key === "6") {
      localStorage.setItem(LocalStorage.TABINDEX, key);
    } else {
      localStorage.removeItem(LocalStorage.TABINDEX);
    }
    setActiveTab(key);
  };
  return (
    <div>
      <Row span={24}>
        <Title
          hasBack
          handleChange={() => {
            history.goBack();
          }}
        >
          <TitleText>Legal Agreements</TitleText>
        </Title>
      </Row>
      <Row style={{ padding: 10 }}>
        <Col>
          <Tabs
            defaultActiveKey={ActiveTab}
            onChange={handleChange}
            destroyInactiveTabPane
          >
            <TabPane tab="Actor" key="1">
              <Actor />
            </TabPane>
            <TabPane tab="Location" key="2">
              <Location />
            </TabPane>
            <TabPane tab="Crew" key="3">
              <Crew />
            </TabPane>
            <TabPane tab="Vendor" key="4">
              <Vendor />
            </TabPane>
            <TabPane tab="Technicians" key="5">
              <Technicians />
            </TabPane>
            <TabPane tab="3rd Party Payee" key="6">
              <ThirdPartyPayee />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Legal;
