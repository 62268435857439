import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { findDOMNode } from "react-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { Layout, Row } from "antd";

import { MainRoutes } from "config/routes";
import LoaderComponent from "components/shared/LoaderComponent";
import {
  setUser,
  setAccessToken,
  setMenus,
  setActiveProject,
} from "store/action";
import HeaderComponent from "components/shared/HeaderComponent";
import Storage from "utilities/LocalStorageHelper";
import "./App.css";
import "./styles.scss";
import { LocalStorage } from "constants/AppConstants";
import { getUser, getUserMenus } from "utilities/UserHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import GotoTop from "components/shared/GotoTop";
import axios from "axios";
// import {  } from "store/thunk-actions";

const { Content, Footer, Sider } = Layout;

let USER_INACTIVE_TIMEOUT = 3000;
let TIMER = "";
let loader = "";
let user = null;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this._storage = new Storage();

    /** Listening for the user interaction with application */
    window.addEventListener("mousemove", () => this.resetTimer());
    window.addEventListener("mousedown", () => this.resetTimer());
    window.addEventListener("keypress", () => this.resetTimer());
    window.addEventListener("DOMMouseScroll", () => this.resetTimer());
    window.addEventListener("mousewheel", () => this.resetTimer());
    window.addEventListener("touchmove", () => this.resetTimer());
    window.addEventListener("MSPointerMove", () => this.resetTimer());

    this.startTimer();

    window.onpopstate = () => {
      console.log("prev Page...", this.props.history.location.pathname);
      if (this.props.history.location.pathname === "/projects") {
        localStorage.removeItem(LocalStorage.ACTIVE_PROJECT_ID);
      }
    };
    axios.defaults.withCredentials = true;
  }

  resize = () => {
    this.setState({ isMobileView: window.innerWidth <= 760 ? true : false });
  };

  startTimer = () => {
    // wait 2 seconds before calling goInactive
    TIMER = setTimeout(this.goInactive, USER_INACTIVE_TIMEOUT);
  };

  resetTimer = () => {
    clearTimeout(TIMER);
    this.startTimer();
  };

  goInactive = () => {
    console.log("user inactive");
  };

  goActive = () => {
    this.startTimer();
  };

  /**code for hiding the loader component with animation */
  // loader = findDOMNode(this.refs.loader);
  // const isLoading = await this.hideLoader();
  // this.setState({
  //   isLoading
  // });
  // this.props.history.push("/login");

  doSomethingBeforeUnload = () => {
    console.log("before browser closes do this...");
  };

  /**Listens the closing event of the browser tab */
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return (ev.returnValue = "Are you sure you want to close?");
    });
  };

  componentDidMount() {
    this.checkIfUserLoggedIn();
    // this.setupBeforeUnloadListener();
    this.checkForProjects();
  }

  checkForProjects = () => {
    let projectTitle = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_NAME);
    this.props.setActiveProject(null, projectTitle);
  };

  checkIfUserLoggedIn = async () => {
    loader = findDOMNode(this.refs.loader);
    const isLoading = await this.hideLoader();

    if (isLoading) {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 500);
    }
    let token = localStorage.getItem(LocalStorage.USER);
    if (token) {
      getUser()
        .then((res) => {
          this._storage.store(LocalStorage.USER, JSON.stringify(res.data));
          this.props.setAccessToken(
            JSON.parse(this._storage.load(LocalStorage.ACCESS_TOKEN))
          );
          this.props.setUser(res.data);
          getUserMenus().then((res) => {
            this.props.setMenus(res.data.menus);
          });
          let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
          if (projectId) {
            this.props.getNotificationCount();
          }
        })
        .catch((err) => {
          this._storage.remove();
          this.props.history.push("/login");
        });
    }
    this.setState({
      visible: false,
    });
  };

  hideLoader = () => {
    return new Promise((resolve) => {
      $(loader).fadeOut(600);
      resolve(true);
    });
  };

  render() {
    const { isLoading } = this.state;
    const { user } = this.props;

    if (isLoading) {
      return (
        <LoaderComponent
          ref="loader"
          text="Preparing for you.."
          textColor="#f44e6f"
        />
      );
    }

    return (
      <Row style={{ height: "100%" }}>
        {user && <HeaderComponent {...this.props} />}
        <Layout style={{ height: "100%" }} className="mainHeight">
          <MainRoutes />
        </Layout>
        {user && (
          <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
            ACE Production ©2020 Created by Round's Edge Technologies
          </Footer>
        )}
        <GotoTop />
      </Row>
    );
  }
}

const RouteWithAuth = withRouter(App);

const mapStateToProps = ({ user }) => {
  return {
    user: user.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    setAccessToken: (accessToken) => dispatch(setAccessToken(accessToken)),
    setMenus: (menus) => dispatch(setMenus(menus)),
    setActiveProject: (projectId, projectTitle) =>
      dispatch(setActiveProject(projectId, projectTitle)),
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RouteWithAuth);
