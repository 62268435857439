import axios from "axios";
import {
  Services,
  LocalStorage,
  GlobalConstants,
} from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class DashboardServices {
  service(type, data) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )} `,
      },
    };

    switch (type) {
      case Services.DashboardVariables.GET_SCENE_LIST_WITH_NO_CHAR_MAP:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scene`,
          config
        );
      case Services.DashboardVariables.GET_DETAILED_VIEW_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/payments?category=PRODUCTION&productionDepartment=${
            data.dept || ""
          }${data.startDate ? "&startDate=" + data.startDate : ""}${
            data.endDate ? "&endDate=" + data.endDate : ""
          }`,
          config
        );
      case Services.DashboardVariables.GET_DETAILED_VIEW_DATA_CATEGORY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/payments?category=PRODUCTION_PURCHASE${
            data.startDate ? "&startDate=" + data.startDate : ""
          }${data.endDate ? "&endDate=" + data.endDate : ""}`,
          config
        );
      case Services.DashboardVariables.GET_DETAILED_CHART_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/chart${
            data.startDate ? "?startDate=" + data.startDate : ""
          }${data.endDate ? "&endDate=" + data.endDate : ""}`,
          config
        );

      case Services.DashboardVariables.GET_LOCATIONS_WITHOUT_ACTUAL_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/sceneLocations`,
          config
        );

      case GlobalConstants.CHARACTERS_WITHOUT_CONFIRM:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scenecharacters?types=SUPPORTING,MAIN`,
          config
        );

      case GlobalConstants.EQUIPMENTS_WITHOUT_MAP:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/vendor/equipments`,
          config
        );

      case GlobalConstants.GET_DASH_CHART:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/chart?isCategory=${
            data.isCategory
          }&type=${data.type}&isTilldate=${data.isTilldate}`,
          config
        );

      case GlobalConstants.ACTOR_AVAILABILITY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/actors/availability`,
          config
        );
      case GlobalConstants.LOCATION_AVAILABILITY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/locations/availability`,
          config
        );

      case GlobalConstants.UNUSED_EQUIPMENTS:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/equipment`,
          config
        );
      case GlobalConstants.ALERT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scenes/alert?type=${data}`,
          config
        );
      case GlobalConstants.SCHEDULER_ALERT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scheduler/alert`,
          config
        );

      default:
        break;
    }
  }
}
