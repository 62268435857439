import { ENV_CONSTANTS } from "constants/AppConstants";

export const getUserAPIURL = () => {
  return ENV_CONSTANTS.USER_API_ENDPOINT;
};

export const getScriptAPIURL = () => {
  return ENV_CONSTANTS.SCRIPT_API_ENDPOINT;
};

export const getAttachementAPIURL = () => {
  return ENV_CONSTANTS.ATTACHEMENT_API_ENDPOINT;
};

export const getScriptTemplateAPIURL = () => {
  return ENV_CONSTANTS.SCRIPT_TEMPLATE_API_ENDPOINT;
};
