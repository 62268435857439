import React, { Component } from "react";
import {
  Modal,
  Col,
  Row,
  Button,
  Icon,
  Upload,
  Input,
  InputNumber,
  Select,
  Form,
} from "antd";
import { showNotifications } from "components/shared/NotificationComponent";
import Uploaddocumentfile from "components/uploaddocumentfile";
import UploadImage from "components/shared/UploadImage/UploadImage";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import MakeupTabService from "services/SceneOverviewService/MakeupTabService";
import FileCard from "components/shared/FileCardComponent";
import { numberOnly, amountOnly } from "utilities/ValidationHelper";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import ImageViewer from "components/shared/ImageViewer";

const Option = { Select };

class UpdateMakeupModal extends Component {
  constructor(props) {
    super(props);
    this.scriptservice = new ScriptService();
    this._globalService = new GlobalService();
    this._makeupService = new MakeupTabService();
  }

  componentDidMount() {
    this.getStatus();
    this.getMakeupTimeData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.budgetId) {
      if (nextProps.budgetId !== this.state.budgetId) {
        this.setState(
          {
            visible: nextProps.isModalVisible,
            character:
              nextProps.recordData && nextProps.recordData.characterName,
            actorName:
              (nextProps.recordData && nextProps.recordData.actor) ||
              this.props.actor,
            budgetId: nextProps.budgetId,
            sceneNo: nextProps.recordData && nextProps.recordData.sceneNumber,
            characterId:
              (nextProps.recordData && nextProps.recordData.characterId) ||
              this.props.characterId,
            sceneId: nextProps.recordData && nextProps.recordData.sceneId,
          },
          () => {
            this.getData(nextProps.budgetId);
          }
        );
      }
    } else {
      this.setState({
        // fileuploadid: null,
        // projectimageid: null,
        // formvalues: null,
        // makeupTime: null,
        // makeupTimeType: null,
        // isLoading: false,
        // materialPurchase: null,
        // materialHire: null,
        // labourExpense: null,
        // status: null,
        visible: nextProps.isModalVisible,
        character: nextProps.recordData && nextProps.recordData.characterName,
        actorName: nextProps.recordData && nextProps.recordData.actor,
        // budgetId: null,
        sceneNo: nextProps.recordData && nextProps.recordData.sceneNumber,
        characterId: nextProps.recordData && nextProps.recordData.characterId,
        sceneId: nextProps.recordData && nextProps.recordData.sceneId,
        // fileList: null,
        // docFiles: null,
        // imageFiles: null
      });
    }
  }

  getData = (budgetId) => {
    this._makeupService
      .makeupTabService(
        Services.SceneOVerviewVariable.GET_MAKEUP_BY_ID,
        budgetId
      )
      .then((res) => {
        let sample = JSON.stringify(res.data);
        this.setState(
          {
            labourExpense: res.data.labourExpense,
            materialHire: res.data.materialHier,
            materialPurchase: res.data.materialPurchase,
            status: res.data.stateEnum,
            makeupTime: res.data.makeupTime,
            makeupTimeType: res.data.makeupTimeType,
            fileList: res.data.documents,
          },
          () => this.splitBasedOnFileTypes(this.state.fileList)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteImage = (id) => {
    let deleteIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let finalData = imageFiles.splice(deleteIndex, 1);
    this.setState({
      imageFiles,
    });
  };

  deleteDoc = (id) => {
    let deleteIndex = this.state.docFiles.findIndex((a) => a.documentId === id);

    let docFiles = this.state.docFiles;
    let finalData = docFiles.splice(deleteIndex, 1);
    this.setState({
      docFiles,
    });
  };

  state = {
    fileuploadid: null,
    projectimageid: null,
    formvalues: null,
    character: null,
    makeupTime: null,
    makeupTimeType: null,
    isLoading: false,
    sceneNo: null,
    actorName: null,
    materialPurchase: null,
    materialHire: null,
    labourExpense: null,
    status: null,
    characterId: null,
    sceneId: null,
    statusData: [],
    budgetId: null,
    makeuptimeData: [],
    fileList: null,
    docFiles: null,
    imageFiles: null,
  };

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      docFiles,
      imageFiles,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  // handleImage = url => {
  //   console.log("handle image url", url);

  //   const { fileList } = this.state;

  //   this.setState({
  //     projectimageid: url
  //   });

  //   if (fileList) {
  //     let temp = [
  //       ...fileList,
  //       { fileId: url, fileType: GlobalConstants.IMAGE }
  //     ];
  //     this.setState({
  //       fileList: temp
  //     });
  //   } else {
  //     let temp = [{ fileId: url, fileType: GlobalConstants.IMAGE }];
  //     this.setState(
  //       {
  //         fileList: temp
  //       },
  //       () => {
  //         console.log(this.state.fileList);
  //       }
  //     );
  //   }
  // };
  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ fileId: id, fileType: GlobalConstants.IMAGE });
    });
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  handleDocument = (id) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { fileId: id, fileType: GlobalConstants.DOCUMENT },
      ];

      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ fileId: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    // this.setState({
    //   visible: false
    // });
    this.props.closeModal();
    this.setState({
      fileuploadid: null,
      projectimageid: null,
      formvalues: null,
      character: null,
      makeupTime: null,
      makeupTimeType: null,
      isLoading: false,
      sceneNo: null,
      actorName: null,
      materialPurchase: null,
      materialHire: null,
      labourExpense: null,
      status: null,
      characterId: null,
      sceneId: null,
      budgetId: null,
      fileList: null,
      docFiles: null,
      imageFiles: null,
    });
  };

  handleChange = (value) => {
    this.setState({
      character: value,
    });
  };

  getStatus = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({ statusData: res.data });
      });
  };

  getMakeupTimeData() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "MAKE_UP_TIME")
      .then((res) => {
        this.setState({ makeuptimeData: res.data });
      });
  }

  handleUpdate = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formvalues: values,
        });

        const data = {
          documents: this.state.fileList,
          labourExpense: this.state.labourExpense,
          materialHier: values.materialhire,
          makeupTime: this.state.makeupTime,
          makeupTimeType: this.state.makeuptimetype,
          materialPurchase: this.state.materialPurchase,
          sceneCharacterId: this.state.characterId,
          sceneId:
            this.state.sceneId ||
            localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
          state: values.status,
        };

        this.setState({ isLoading: true });
        this._makeupService
          .makeupTabService(
            Services.SceneOVerviewVariable.UPDATE_MAKEUP,
            data,
            this.state.budgetId
          )
          .then((response) => {
            this.setState({ isLoading: false }, () => {
              this.handleCancel();
              this.props.refreshData();
            });
            this.setState({
              budgetId: null,
            });
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Special Costume Updated Successfully!!"
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({ isLoading: false }, () => this.handleCancel());
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something went wrong!!"
            );
          });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formvalues: values,
        });

        const data = {
          documents: this.state.fileList,
          labourExpense: this.state.labourExpense,
          makeupTime: this.state.makeupTime,
          makeupTimeType: this.state.makeuptimetype,
          materialHier: values.materialhire,
          materialPurchase: this.state.materialPurchase,
          sceneCharacterId: this.state.characterId,
          sceneId:
            this.state.sceneId ||
            localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
          state: values.status,
        };
        this.setState({ isLoading: true });
        this.scriptservice
          .scriptService(
            Services.SceneOVerviewVariable.UPDATE_MAKEUP,
            data,
            console.log("hai iam there", data)
          )
          .then((response) => {
            this.setState({ isLoading: false }, () => this.handleCancel());
            this.props.refreshData();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Special Makeup Created Successfully!!"
            );
          })
          .catch((err) => {
            this.setState({ isLoading: false }, () => this.handleCancel());
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something went wrong!!"
            );
          });
      }
    });
  };

  render() {
    const { Option } = Select;
    const { name, closeModal, isModalVisible } = this.props;
    const { visible, budgetId, fileList, docFiles, imageFiles } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Button type="link" onClick={this.showModal}>
          {name}
        </Button>
        <Modal
          destroyOnClose
          onCancel={this.handleCancel}
          visible={isModalVisible}
          key={2}
          width="630px"
          footer={
            <Row
              gutter={12}
              type="flex"
              justify="end"
              style={{ marginTop: "20px" }}
            >
              <Col>
                <Button onClick={this.props.closeModal}>Cancel</Button>
              </Col>
              <Col>
                {budgetId ? (
                  <Button
                    type="primary"
                    loading={this.state.isLoading}
                    onClick={this.handleUpdate}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={this.state.isLoading}
                    onClick={this.handleSubmit}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          }
        >
          <h3
            className="font-600 primary-font-color text-center"
            style={{ fontSize: "20px", margin: 0 }}
          >
            {this.state.character}
          </h3>
          <h3 className="font-600 mb-20 text-center primary-font-color">
            Special Makeup
          </h3>

          <Form onSubmit={budgetId ? this.handleUpdate : this.handleSubmit}>
            <Row gutter={16}>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Scene No</label>
                <Form.Item>
                  {getFieldDecorator("sceneno", {
                    initialValue: this.state.sceneNo,
                    rules: [
                      {
                        required: true,
                        message: "Please enter Sceneno!",
                      },
                    ],
                  })(<Input type="text" disabled placeholder="SceneNo" />)}
                </Form.Item>
              </Col>
              {/* <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">Character</label>
                <Form.Item>
                  {getFieldDecorator("character", {
                    initialValue: this.state.character,
                    rules: [
                      {
                        required: true,
                        message: "Please select character!"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Character"
                      style={{ width: "260px" }}
                      onChange={this.handleChange}
                    >
                      <Option value="naman">Naman</Option>
                      <Option value="ajay">Ajay</Option>
                      <Option value="ramu">Ramu</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col> */}
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Actor</label>
                <Form.Item>
                  {getFieldDecorator("actor", {
                    initialValue: this.state.actorName,
                    rules: [
                      {
                        required: true,
                        message: "Please enter actor!",
                      },
                    ],
                  })(<Input type="text" disabled placeholder="Actor" />)}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Material Purchased</label>
                <Form.Item>
                  {getFieldDecorator("materialpurchased", {
                    initialValue: this.state.materialPurchase || undefined,
                    rules: [
                      {
                        required: true,
                        message: "Please enter materialpurchased!",
                      },
                    ],
                  })(
                    <Input
                      prefix="₹"
                      placeholder="Material Purchased"
                      onChange={(value) => {
                        this.setState(
                          {
                            materialPurchase: amountOnly(value),
                          },
                          () => {
                            console.log(
                              "value purchase",
                              this.state.materialPurchase
                            );
                          }
                        );
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Material Hire</label>
                <Form.Item>
                  {getFieldDecorator("materialhire", {
                    initialValue: this.state.materialHire,
                    rules: [
                      {
                        required: true,
                        message: "Please enter materialhire!",
                      },
                    ],
                  })(
                    <Input
                      prefix="₹"
                      placeholder="Material Hire"
                      onChange={(value) => {
                        this.setState({
                          materialHire: amountOnly(value),
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Labour Expense</label>
                <Form.Item>
                  {getFieldDecorator("labourexpense", {
                    initialValue: this.state.labourExpense,
                    rules: [
                      {
                        required: true,
                        message: "Please enter LabourExpense!",
                      },
                    ],
                  })(
                    <Input
                      prefix="₹"
                      placeholder="LabourExpense"
                      onChange={(value) => {
                        this.setState({
                          labourExpense: amountOnly(value),
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Makeup Time</label>
                <Form.Item>
                  {getFieldDecorator("makeuptime", {
                    initialValue: this.state.makeupTime,
                    rules: [
                      {
                        required: true,
                        message: "Enter MakeupTime!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Enter Makeup Time"
                      onChange={(value) => {
                        this.setState({
                          makeupTime: numberOnly(value),
                        });
                      }}
                      addonAfter={
                        <Form.Item
                          style={{ margin: "0px", height: "30px", top: "-4px" }}
                        >
                          {getFieldDecorator("makeuptimetype", {
                            initialValue:
                              this.state.makeupTimeType || undefined,
                            rules: [
                              {
                                required: true,
                                message: "Please select status!",
                              },
                            ],
                          })(
                            <Select
                              placeholder="MakeupTime"
                              style={{ width: "105px" }}
                              onChange={(value) =>
                                this.setState({ makeuptimetype: value })
                              }
                            >
                              {this.state.makeuptimeData &&
                                (this.state.makeuptimeData || []).map(
                                  (option, i) => (
                                    <Option value={option.value}>
                                      {option.label}
                                    </Option>
                                  )
                                )}
                            </Select>
                          )}
                        </Form.Item>
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">*Status</label>
                <Form.Item>
                  {getFieldDecorator("status", {
                    initialValue: this.state.status || undefined,
                    rules: [
                      {
                        required: true,
                        message: "Please select status!",
                      },
                    ],
                  })(
                    <Select placeholder="Status" style={{ width: "100%" }}>
                      {this.state.statusData &&
                        (this.state.statusData || []).map((option, i) => (
                          <Option value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">Documents</label>
                <Form.Item>
                  {getFieldDecorator("fileuploadid", {
                    initialValue: this.state.fileuploadid,
                  })(
                    <React.Fragment>
                      <div className="fileCardContainer">
                        {docFiles &&
                          docFiles.map((file, i) => (
                            <FileCard
                              file={file}
                              deleteDoc={this.deleteDoc}
                              pageType={
                                Services.CostumesMakeupVariables
                                  .MAKEUP_DELETE_DOC
                              }
                            />
                          ))}
                      </div>
                      <Uploaddocumentfile
                        handleDocument={this.handleDocument}
                      />
                    </React.Fragment>
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                <label className="font-600">Photos</label>
                <Form.Item>
                  {getFieldDecorator("projectimageid", {
                    initialValue: this.state.projectimageid,
                  })(
                    <React.Fragment>
                      <div className="fileCardContainer">
                        {imageFiles &&
                          imageFiles.map((file, i) => (
                            <FileCard
                              file={file}
                              deleteImage={this.deleteImage}
                              pageType={
                                Services.CostumesMakeupVariables
                                  .MAKEUP_DELETE_DOC
                              }
                            />
                          ))}
                      </div>
                      <MultipleSelectImage
                        handleImageIDS={this.handleImageIDS}
                      />
                      {/* <UploadImage handleImage={this.handleImage} /> */}
                    </React.Fragment>
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 11 }} style={{ marginBottom: "20px" }}>
                {this.state.imageFiles && (
                  <ImageViewer
                    imageCount={2}
                    ImagesData={this.state.imageFiles}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

// UpdateMakeupModal.propTypes = {
//   name: PropTypes.string.isRequired
// };
const updatemakeupmodal = Form.create()(UpdateMakeupModal);

export default updatemakeupmodal;
