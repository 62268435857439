import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Tag } from "antd";
import { Link } from "react-router-dom";

import BudjetService from "services/BudjetService";
import AccountingService from "services/AccountingService";
import { Services, GlobalConstants, MenusRoute } from "constants/AppConstants";
import {
  StartCase,
  checkFor,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import TooltipIcon from "components/shared/TooltipIcon";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const AccCastTechnicianTable = ({ type, activePermisision }) => {
  const castColumnsDayWise = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Character name",
      dataIndex: "characterName",
      align: "left",
      key: "characterName",
      render: (text) => StartCase(text),
    },
    {
      title: "Character Type",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => (
        <Tag className={`${text} dateTag`}>{ReplaceWithSpace(text)}</Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    castColumnsDayWise.push({
      title: "Action",
      align: "center",
      render: (text, record) => (
        <Link
          to={`${
            record.actorId
              ? `${MenusRoute.ACCOUNTING}/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
              : record.crewId
              ? `/project/updateCrewprofile/${record.crewId}`
              : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
          }`}
        >
          {(record.actorId || record.crewId) &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <Button type="link">More</Button>
            )}
          {!record.actorId &&
            !record.crewId &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="default">+ Actor</Button>
            )}
        </Link>
      ),
    });
  }
  const castColumnsContract = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Character name",
      dataIndex: "characterName",
      align: "left",
      key: "characterName",
      render: (text) => StartCase(text),
    },
    {
      title: "Character Type",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => (
        <Tag className={`${text} dateTag`}>{ReplaceWithSpace(text)}</Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Agreed Amount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    castColumnsContract.push({
      title: "Action",
      align: "center",
      render: (text, record) => (
        <Link
          to={{
            pathname: `${MenusRoute.ACCOUNTING}/pre-production/${record.productionAccountId}/edit`,
            state: {
              category: GlobalConstants.CAST_TECHNICIANS,
              title: `Cast & Technicians - ${StartCase(record.name)}`,
              hidePaymentType: true,
            },
          }}
        >
          <TooltipIcon icon="edit" />
        </Link>
      ),
    });
  }
  const technicianContract = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Role",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => (
        <Tag className={`${text} dateTag`}>{ReplaceWithSpace(text)}</Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
  ];
  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    technicianContract.push({
      align: "center",
      title: "Action",
      render: (text, record) => (
        <Link
          to={{
            pathname: `${MenusRoute.ACCOUNTING}/pre-production/${record.productionAccountId}/edit`,
            state: {
              category: GlobalConstants.CAST_TECHNICIANS,
              title: StartCase(record.description),
              hidePaymentType: true,
            },
          }}
        >
          <TooltipIcon icon="edit" />
        </Link>
      ),
    });
  }

  const technicianDayWise = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Role",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => <Tag className={`${text} dateTag`}>{text}</Tag>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    technicianDayWise.push({
      title: "Action",
      align: "center",
      render: (text, record) => (
        <Link
          to={`${
            record.actorId
              ? `/project/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
              : record.crewId
              ? `/project/updateCrewprofile/${record.crewId}`
              : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
          }`}
        >
          {(record.actorId || record.crewId) &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <Button type="link">More</Button>
            )}
          {!record.actorId &&
            !record.crewId &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="default">+ Actor</Button>
            )}
        </Link>
      ),
    });
  }

  const budgetService = new BudjetService();
  const accountService = new AccountingService();
  const [DataSource, setDataSource] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getColumn = (type) => {
    switch (type) {
      case Services.AccountingVariables.CAST_CONTRACT:
        return castColumnsContract;
      case Services.AccountingVariables.CAST_PER_DAY:
        return castColumnsDayWise;
      case Services.AccountingVariables.TECHNICIAN_CONTRACT:
        return technicianContract;
      case Services.AccountingVariables.TECHNICIAN_PER_DAY:
        return technicianDayWise;
      default:
        break;
    }
  };
  const column = getColumn(type);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    accountService
      .accountingService(Services?.AccountingVariables?.CAST_TECNICIANS, type)
      .then((res) => {
        setDataSource(res.data.content || []);
        setLoading(false);
      });
  };
  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Row type="flex" justify="center">
      <Col xl={22}>
        <Table
          bordered
          loading={Loading}
          dataSource={DataSource}
          columns={column}
          locale={{
            emptyText: !Loading && (
              <RecordNotFound text="No Cast & Technicians found" />
            ),
          }}
        />
      </Col>
    </Row>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

// export default AccCastTechnicianTable;

const mapStateToProps = ({
  scene,
  activeItems: { activeMenuPermissions },
  user: { menus },
}) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccCastTechnicianTable);
