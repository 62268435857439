import React, { useEffect, useState, Fragment } from "react";
import { Table, Row, Col, Button, Icon, Input, Tag } from "antd";

import TooltipIcon from "components/shared/TooltipIcon";
import { MenusRoute, GlobalConstants, Services } from "constants/AppConstants";
import { Link } from "react-router-dom";
import Accounting from "services/AccountingServices";
import RecordNotFound from "components/shared/RecordNotFound";
import moment from "moment";
import AccProductionAddEntry from "./AccProductionAddEntry";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { connect } from "react-redux";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

const { Search } = Input;

const AccProductionShootWise = (props) => {
  const [DataSource, setDataSource] = useState([]);
  const [SearchKey, setSearchKey] = useState(null);
  const [IsLoading, setIsLoading] = useState(true);
  let filteredDataSource = [];
  const Account = new Accounting();
  const { activePermisision } = props;
  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (key) => {
    setSearchKey(key);
  };

  filteredDataSource = SearchKey
    ? DataSource.filter((data) =>
        data.description.toLowerCase().includes(SearchKey)
      )
    : DataSource;

  const fetchData = () => {
    Account.services(
      Services.AccountingVariables.GET_ART_SET_WITH_SHOOT_DATES,
      GlobalConstants.ART_SET_CAPS
    )
      .then((res) => {
        setDataSource(res.data.productions);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shoot date",
      dataIndex: "shootDate",
      key: "shootDate",
      width: "20%",
      render: (text) => <Tag className="statusTag">{text}</Tag>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      align: "right",
      width: "35%",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      align: "right",
      width: "35%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns.push({
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            props.history.push({
              pathname: `${MenusRoute.ACCOUNTING}/production/transport/${record?.shootDate}`,
              state: {
                title: `Transport ${record?.shootDate}`,
                details: {
                  productionCategoryDepartment: GlobalConstants.PRODUCTION_CAPS,
                  department: GlobalConstants.PRODUCTION_CAPS,
                  category: GlobalConstants.PRODUCTION_CAPS,
                  categoryDepartment: record.categoryDepartment,
                },
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  return (
    <Fragment>
      <Row>
        <Col xl={24}>
          <Title hasBack {...props}>
            <TitleText>Accounting - Production - Transport</TitleText>
          </Title>
        </Col>
        <Col xl={24} style={{ padding: 10 }}>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
            <Row>
              <Col>
                <Table
                  dataSource={DataSource}
                  columns={columns}
                  bordered
                  scroll={{ y: "60vh" }}
                  pagination={false}
                  loading={IsLoading}
                  locale={{
                    emptyText: !IsLoading && <RecordNotFound />,
                  }}
                />
              </Col>
            </Row>
          ) : (
            <ContactAdminErrorTemplate />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

export default connect(mapStateToProps, null)(AccProductionShootWise);
