import React, { Fragment, useEffect, useState } from "react";

import PDFComponent from "components/shared/PDFViewer";
import { LocalStorage, Services } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import { Row, Col, Select, Form } from "antd";
import GlobalService from "services/GlobalService";

const { Option, OptGroup } = Select;

const DataIncompletionReportForm = (props) => {
  const api = new GlobalService();
  let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  const [WarningList, setWarningList] = useState([]);
  const [WarningType, setWarningType] = useState(null);
  const [AllLoaded, setAllLoaded] = useState(false);

  const handleWarningChange = (WarningType) => {
    setWarningType(WarningType);
  };

  useEffect(() => {
    getWarnings();
  }, []);

  const getWarnings = () => {
    api
      .globalService(Services.GlobalVariables.GET_WARNING_DATA)
      .then((res) => {
        console.log(res);
        setWarningList(res.data.characters);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const url = `${getScriptAPIURL()}/project/dataincompleted/reports?contentDisposition=INLINE&projectId=${projectId}${
    WarningType ? `&warningType=${WarningType}` : ""
  }`;

  const { activePermisision } = props;

  const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: {
      sm: { span: 10 },
    },
    wrapperCol: {
      sm: { span: 12 },
    },
  };

  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Fragment>
      <Row gutter={16}>
        <Col xl={10}>
          <Form {...formItemLayout}>
            <Form.Item label="Warning" hasFeedback>
              {getFieldDecorator("warningType", {
                initialValue: WarningType,
              })(
                <Select
                  style={{ width: 200 }}
                  className="upperCase"
                  showSearch
                  allowClear
                  onChange={handleWarningChange}
                >
                  {WarningList &&
                    WarningList.map((list, i) => {
                      return (
                        <Option key={i} value={list.value}>
                          {list.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Col>

        <PDFComponent
          customURL={url}
          icon="download"
          text="Report"
          projectId={projectId}
          hideMail
        />
      </Row>
    </Fragment>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

const DataIncompletionReport = Form.create({ name: "register" })(
  DataIncompletionReportForm
);
const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.REPORT, menus),
  };
};

export default connect(mapStateToProps, null)(DataIncompletionReport);
