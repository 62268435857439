import { Services, LocalStorage } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class NotificationServices {
  service(type) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    // let projectId = 3;
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
        // "Content-Type": "multipart/form-data"
      },
    };
    switch (type) {
      case Services.NotificationVariables.GET_NOTIFICATIONS:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/notification`,
          config
        );
    }
  }
}
