import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class ReportService {
  reportService(type, data, id) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    switch (type) {
      case Services.ReportVariables.GET_REPORTS:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/reports`,
          config
        );
      case Services.ReportVariables.SAVE_REPORT:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/reports`,
          data,
          config
        );
      case Services.ReportVariables.UPDATE_REPORT:
        return axios.put(
          `${getScriptAPIURL()}/projects/reports/${data.reportId}`,
          data,
          config
        );
      case Services.ReportVariables.DELETE_REPORT:
        return axios.delete(
          `${getScriptAPIURL()}/projects/reports/${data}`,
          config
        );
      case Services.ReportVariables.GET_REPORT_FIELDS_BY_ID:
        return axios.get(`${getScriptAPIURL()}/report/${data}/fields`, config);
      case Services.ReportVariables.GET_ALL_REPORT_FIELDS:
        return axios.get(`${getScriptAPIURL()}/report/fields`, config);
      case Services.ReportVariables.SAVE_REPORT_FIELDS:
        return axios.post(
          `${getScriptAPIURL()}/report/${id}/fields`,
          data,
          config
        );
      default:
        break;
    }
  }
}
