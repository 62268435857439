import React, { Component } from "react";
import { Row, Input, Col, Form } from "antd";
import ArtSetTabService from "services/SceneOverviewService/ArtSetTabServices";
import { Services, GlobalConstants } from "constants/AppConstants";
import GeneralTabService from "services/SceneOverviewService/GeneralTabService";
import ArtSetTable from "./ArtSetTable";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Loader from "components/shared/Loader";

class ArtSetTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      artSets: [],
      isDataFetched: false,
      type: null,
      screenLocation: null,
      description: null,
      environmentTime: null,
      environmentType: null,
      sceneType: null,
      dataSource: [],
      count: 0,
      sceneId: null,
      countnum: 3,
      actualLocationName: null,
      isLoading: false,
    };

    this._generalTabService = new GeneralTabService();
    this._artSetTabService = new ArtSetTabService();
  }

  componentDidMount() {
    this.getSceneDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.sceneId) {
      this.setState(
        {
          sceneId: nextProps.activeSceneId,
        },
        () => this.getSceneDetails()
      );
    }
  }

  getSceneDetails = () => {
    this.setState({
      isLoading: true,
    });
    this._generalTabService
      .generalTabService(
        Services.SceneOVerviewVariable.GET_SCENE,
        null,
        this.state.sceneId
      )
      .then((res) => {
        this.setState({
          screenLocation: res.data.location,
          actualLocationName: res.data.actualLocationName,
          description: res.data.description,
          environmentType: res.data.environmentType,
          environmentTime: res.data.timeType,
          sceneType: res.data.locationTypeValue,
          sceneId: res.data.sceneId,
        });
        this.setState({
          isLoading: false,
        });
      });
  };

  getTableData = () => {
    this._artSetTabService
      .service(Services.ArtSetVariables.GET_TABLE_DATA)
      .then((res) => {
        this.setState({
          dataSource: res.data.artSets,
        });
      });
  };

  handleSubmit = () => {
    let dataSource = [];
    dataSource = this.state.dataSource.filter(
      (data) =>
        data.staffName !== "" &&
        data.designation !== "" &&
        data.payment !== "" &&
        data.paymentType !== ""
    );
    this.setState({
      dataSource,
    });
  };

  getFromChild = (dataSource) => {
    this.setState({
      dataSource,
    });
  };

  postDatasource = (dataSource) => {
    let tableData = dataSource;

    if (tableData.length >= 0) {
      tableData.forEach(function (el) {
        let obj = Object.assign({}, el);
        obj.sceneId = this.state.sceneId;
        return obj;
      });
    }
  };

  render() {
    const { TextArea } = Input;
    const { description, actualLocationName, sceneType } = this.state;
    const { currentPermission } = this.props;
    return (
      <Row>
        <Col xs={24}>
          {checkFor(currentPermission, GlobalConstants.PERMISSION_VIEW) ? (
            <Form>
              {/* <Row type="flex" justify="center">
                <Col xs={18}>
                  <Row style={{ paddingTop: 20 }}>
                    <Col xs={{ span: 12 }}>
                      <Row className="mb-25 veritical-center">
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 10 }}
                          xs={{ span: 23, offset: 1 }}
                        >
                          <h3 className="text-right font-600 m-0">
                            Actual Location &nbsp;:
                          </h3>
                        </Col>
                        <Col
                          xs={{ span: 23 }}
                          lg={{ span: 14 }}
                          md={{ span: 10 }}
                          className="pl-15"
                        >
                          <Form.Item className="m-0">
                            <Input
                              className="disabled"
                              value={actualLocationName}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 24 }}>
                      <Row className="mb-25 veritical-center">
                        <Col
                          lg={{ span: 3 }}
                          md={{ span: 10 }}
                          xs={{ span: 23, offset: 1 }}
                        >
                          <h3 className="text-right font-600 m-0">
                            Description &nbsp; :
                          </h3>
                        </Col>
                        <Col
                          lg={{ span: 20 }}
                          md={{ span: 10 }}
                          xs={{ span: 23 }}
                          className="pl-15"
                        >
                          <Form.Item className="m-0">
                            <TextArea
                              rows={2}
                              style={{ width: "97.5%", display: "flex" }}
                              value={description}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 12 }}>
                      <Row className="mb-25 veritical-center">
                        <Col
                          lg={{ span: 7 }}
                          md={{ span: 10 }}
                          xs={{ span: 23, offset: 1 }}
                        >
                          <h3 className="text-right font-600 m-0">
                            Location Type &nbsp;:
                          </h3>
                        </Col>
                        <Col
                          xs={{ span: 23 }}
                          lg={{ span: 14 }}
                          md={{ span: 10 }}
                          className="pl-15"
                        >
                          <Form.Item className="m-0">
                            <Input
                              className="disabled"
                              value={sceneType}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row> */}

              <ArtSetTable
                {...this.props}
                pageType="Tab"
                serviceType={Services.ArtSetVariables.GET_ART_SET_LIST}
                count={this.state.count}
                getFromChild={this.getFromChild}
                sceneId={this.state.sceneId}
              />
            </Form>
          ) : (
            <ContactAdminErrorTemplate />
          )}
        </Col>
        {this.state.isLoading && <Loader />}
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    currentPermission: getSPermissionFromMenus("Art/Set", menus),
  };
};

export default connect(mapStateToProps)(ArtSetTab);
