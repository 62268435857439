import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class ActorsServices {
  actorsServices(
    type,
    actorId,
    paymentId,
    data = null,
    staffId,
    availabilityId,
    characterId
  ) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    let downloadConfig = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
      responseType: "blob",
    };

    switch (type) {
      case Services.ActorVariables.GET_ACTORS_LIST:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/casts?types=MAIN,SUPPORTING`,
          config
        );
      case Services.ActorVariables.GET_ACTORS_MAIN:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/casts?types=MAIN`,
          config
        );
      case Services.ActorVariables.GET_ACTORS_SUPPORTING:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/casts?types=SUPPORTING`,
          config
        );
      case Services.ActorVariables.GET_ACTORS_ANIMAL:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/characters?types=ANIMAL`,
          config
        );
      case Services.ActorVariables.GET_ACTORS_JUNIOR:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/characters?types=JUNIOR`,
          config
        );
      case Services.ActorVariables.GET_ACTOR_ID:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/actors/${actorId}`,
          config
        );
      case Services.ActorVariables.POST_PAYMENT_DETAILS:
        return axios.post(`${getScriptAPIURL()}/actors/payments`, data, config);
      case Services.ActorVariables.LEGAL_AGREEMENT_SAVE:
        return axios.post(`${getScriptAPIURL()}/legal-agreement`, data, config);
      case Services.ActorVariables.LEGAL_AGREEMENT_GET:
        return axios.get(
          `${getScriptAPIURL()}/legal-agreement/${actorId}`,
          data,
          config
        );
      case Services.ActorVariables.LEGAL_AGREEMENT_DOCUMENT_SAVE:
        return axios.post(
          `${getScriptAPIURL()}/legal-agreement/${actorId}/document`,
          data,
          config
        );
      case Services.ActorVariables.LEGAL_AGREEMENT_DOCUMENT_DELETE:
        return axios.delete(
          `${getScriptAPIURL()}/legal-agreement-document/${actorId}`,
          data,
          config
        );
      case Services.ActorVariables.DELETE_ACTOR_ID:
        return axios.delete(
          `${getScriptAPIURL()}/actors-staff/${actorId}`,
          data,
          config
        );
      case Services.ActorVariables.DELETE_ACTOR_PAYMENT_TERMS_ID:
        return axios.delete(
          `${getScriptAPIURL()}/legal-agreement-payment/${actorId}`,
          data,
          config
        );
      case Services.ActorVariables.LEGAL_AGREEMENT_UPDATE:
        return axios.put(
          `${getScriptAPIURL()}/legal-agreement/${actorId}`,
          data,
          config
        );
      case Services.ActorVariables.PUT_PAYMENT_DETAILS:
        return axios.put(
          `${getScriptAPIURL()}/actors/${actorId}/payments/${paymentId}`,
          data,
          config
        );
      case Services.ActorVariables.POST_STAFF_DETAILS:
        return axios.post(`${getScriptAPIURL()}/actors/staffs`, data, config);
      case Services.ActorVariables.PUT_STAFF_DETAILS:
        return axios.put(
          `${getScriptAPIURL()}/actors/${actorId}/staffs/${staffId}`,
          data,
          config
        );
      case Services.ActorVariables.POST_ACTORAVAILABILITY_DETAILS:
        return axios.post(
          `${getScriptAPIURL()}/actors/availability`,
          data,
          config
        );
      case Services.ActorVariables.PUT_ACTORAVAILABILITY_DETAILS:
        return axios.put(
          `${getScriptAPIURL()}/actors/${actorId}/availability/${availabilityId}`,
          data,
          config
        );
      case Services.ActorVariables.GET_CONFIRMED_ACTOR:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/characters/${characterId}?status=CONFIRMED`,
          config
        );
      case Services.ActorVariables.GET_ANIMAL_DETAILS:
        return axios.get(
          `${getScriptAPIURL()}/scene/${actorId}/animals?type=ANIMAL`,
          config
        );
      case Services.ActorVariables.DEL_CHARACTER:
        return axios.delete(
          `${getScriptAPIURL()}/project/${projectId}/scenes/characters/${characterId}`,
          config
        );
      case Services.ActorVariables.UPDATE_ANIMAL_STATUS:
        return axios.patch(
          `${getScriptAPIURL()}/projects/${projectId}/animals/${actorId}?status=${data}`,
          (data = {}),
          config
        );
      case Services.ActorVariables.UPDATE_ACTOR_NAME:
        return axios.patch(
          `${getScriptAPIURL()}/project/${projectId}/characters/${actorId}`,
          data,
          config
        );
      case Services.ActorVariables.CHECK_SHOOT_DATE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/availability/shootdates?actorId=${data}&fromDate=${actorId}${
            paymentId ? `&toDate=${paymentId}` : ""
          }`,
          config
        );
      case Services.ActorVariables.GET_ACTOR_OPTIONS:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/actor-names`,
          config
        );
      case Services.ActorVariables.GET_CHARACTER_OPTIONS:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/casts?types=MAIN&types=SUPPORTING`,
          config
        );
      case Services.ActorVariables.GET_DOCUMENTS_BY_ACTOR_ID:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/actors/${actorId}/documents`,
          downloadConfig
        );
        case Services.ActorVariables.GET_EXISTING_ACTORS:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/actor-names?name=${data}&sceneCharacterId=${characterId}`,
          config
        );
        case Services.ActorVariables.GET_ACTOR_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/actors/${actorId}`,
          config
        );
      default:
        break;
    }
  }
}
