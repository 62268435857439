import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { GlobalConstants } from "constants/AppConstants";
import { Services, Notifications } from "constants/AppConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { showNotifications } from "components/shared/NotificationComponent";
import "antd/dist/antd.css";

import {
  Table,
  Divider,
  Button,
  Row,
  Col,
  Icon,
  Popconfirm,
  Tooltip,
  Tag,
} from "antd";
import CrewService from "services/CrewServices";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { checkFor } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { handleError } from "utilities/ErrorHandler";

class Crew extends Component {
  constructor(props) {
    super(props);
    this._crewService = new CrewService();
    this.state = {
      isLoading: false,
      data: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this._crewService
      .crewService(Services.CrewVariables.GET_PROJECT_DATA)
      .then((res) => {
        this.setState({
          data: res.data.projectMembers,
          isLoading: true,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: true,
        });
      });
  };

  handleDelete = (id) => {
    this._crewService
      .crewService(Services.CrewVariables.DELETE_CREW_MEMBER, "", id)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Crew Member Removed Successfully"
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  state = {
    data: [],
    name: null,
    role: null,
    phone: null,
    renumeration: null,
  };
  render() {
    const { isLoading } = this.state;
    const { activePermisision, getPermissions } = this.props;
    const columns = [
      {
        title: "#",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Name",
        dataIndex: "name.firstName",
        // align: "left",
        className: "caps",
        width: "20%",
      },
      {
        title: "Phone",
        dataIndex: "phoneNumber",
        // align: "left",
        // render: text => (
        //   <div
        //     style={{
        //       flexDirection: "row",
        //       display: "flex",
        //       justifyContent: "center"
        //     }}
        //   >
        //     <Icon
        //       type="phone"
        //       rotate={90}
        //       style={{ color: "#79c447", marginRight: 10, fontSize: 20 }}
        //     />
        //     <div>{text}</div>
        //   </div>
        // )
      },
      {
        title: "Role",
        dataIndex: "roleName",
        // align: "center",
        render: (text) => <Tag className={`statusTag ${text}`}>{text}</Tag>,
      },
      {
        title: "Renumeration",
        dataIndex: "rumenuration",
        align: "right",
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <span>
            <React.Fragment>
              <Row>
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_EDIT
                ) && (
                  <Button type="link">
                    <Link to={`/project/updateCrewprofile/${record.userId}`}>
                      <Icon type="edit"></Icon>
                    </Link>
                  </Button>
                )}
                <Divider type="vertical" />

                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_DELETE
                ) && (
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => this.handleDelete(record.userId)}
                  >
                    <Tooltip
                      title={GlobalConstants.DEL}
                      placement={GlobalConstants.RIGHT}
                    >
                      <Button icon="delete" type="link" />
                    </Tooltip>
                  </Popconfirm>
                )}
              </Row>
            </React.Fragment>
          </span>
        ),
      },
    ];
    return (
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Crew </TitleText>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row type="flex" justify="center" style={{ padding: 10 }}>
            <Col xl={24}>
              <Col xs={24}>
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_ADD
                ) && (
                  <Button
                    type="primary"
                    style={{ marginBottom: 5, float: "right" }}
                  >
                    <Link to="/project/crewprofile">
                      <Icon type="plus" style={{ fontSize: "15px" }} /> Crew
                    </Link>
                  </Button>
                )}
              </Col>
              <Col xs={24}>
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_VIEW
                ) ? (
                  <Table
                    loading={!isLoading}
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={this.state.data > 10}
                    locale={{
                      emptyText: isLoading && <RecordNotFound />,
                    }}
                    bordered
                  />
                ) : (
                  <ContactAdminErrorTemplate />
                )}
              </Col>
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.CREW, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Crew);
