import { LocalStorage } from "constants/AppConstants";
import Axios from "axios";
import { getScriptAPIURL, getUserAPIURL } from "./APIHelper";
const JSONFile = require("../utilities/ENUM.json");

export const getUser = () => {
  return Axios.get(`${getUserAPIURL()}/user`);
};

export const getUserMenus = () => {
  return Axios.get(`${getUserAPIURL()}/user/permissions`);
};

export const getCount = (projectId) => {
  return Axios.get(
    `${getScriptAPIURL()}/project/${projectId}/notification/count`
  );
};

export const getProjectId = () =>
  localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

export const generateRandomName = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters?.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const urltoFile = (url) => {
  let name = generateRandomName() + ".jpg";
  return fetch(url)
    .then((res) => {
      return res.arrayBuffer();
    })
    .then((buf) => {
      return new File([buf], name, { type: "image/jpeg" });
    });
};

export const getFileExtension = (filename) => {
  const extension = filename.split(".").pop();
  return extension;
};

export const findEnumValue = (keyToFind) => {
  let jsonData = JSONFile;
  try {
    if (jsonData.hasOwnProperty(keyToFind)) {
      return jsonData[keyToFind];
    } else {
      return keyToFind;
    }
  } catch (error) {
    console.error(`Error reading JSON file: ${error.message}`);
    return keyToFind;
  }
};
