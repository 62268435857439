import React, { Component } from "react";
import { Row, Col, Form, Input, Select, Button, Radio } from "antd";
import "./profile.css";
import Uploaddocumentfile from "components/uploaddocumentfile";
import Paymentterms from "components/paymentterms";
import UploadImage from "components/shared/UploadImage/UploadImage";
import { Images } from "assets/images";
import Dynamictable from "components/dynamictable";
import { ScriptService } from "services";
import { Services, Notifications, LocalStorage } from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import GlobalService from "services/GlobalService";
import { OnboardServices } from "services";
import ActorsServices from "services/ActorsServices";
import { Link } from "react-router-dom";
import { getScriptAPIURL } from "utilities/APIHelper";
import { brotliDecompress } from "zlib";
import Paymenttermspopup from "components/paymenttermspopup";
import { object, number } from "prop-types";
import { numberOnly } from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";

const Option = { Select };

class AddActor extends Component {
  constructor(props) {
    super(props);
    this.scriptservice = new ScriptService();
    this._globalService = new GlobalService();
    this.onboardservices = new OnboardServices();
    this._actorService = new ActorsServices();
    // this.state = {
    //   paymentData: [
    //     {
    //       amount: 50,
    //       paymentDate: "20-10-2019",
    //       paymentId: 1,
    //       remarks: "fgfjgh"
    //     },
    //     {
    //       amount: 50000,
    //       paymentDate: "20-10-2019",
    //       paymentId: 2,
    //       remarks: "fgfjgh"
    //     }
    //   ]
    // };
  }

  componentDidMount() {
    this.getActorDetails();
    console.log("paramsname", this.props.match.params.characterName);
    console.log("paramsType", this.props.match.params.characterType);
    this.getCharacterType();
    this.getCountries();
    this.getStatus();
    this.getVanityVan();
  }

  getActorDetails = () => {
    let urlId = this.props.match.params.id;
    let actorId = urlId;
    // if(this.props){
    if (
      !this.props.match.params.id ||
      this.props.match.params.id == "undefined"
    ) {
      console.log(this.props.match.params.id !== undefined);

      return;
    }
    // }

    this._actorService
      .actorsServices(Services.ActorVariables.GET_ACTOR_ID, actorId)
      .then(
        (res) => {
          console.log("res.data.actorPayment", res.data.actorPayment);
          console.log("actor response", res);
          if (res.data.actorPayment && res.data.actorPayment.length > 0) {
            res.data.actorPayment.forEach((element, pos) => {
              element.key = pos;
            });
          }
          if (res.data.actorStaff && res.data.actorStaff.length > 0) {
            res.data.actorStaff.forEach((element, pos) => {
              element.key = pos;
            });
          }
          // this.refreshDataPayment(res);
          // this.refreshDataStaff(res)
          try {
            this.setState({
              fileData: res.data.actorDocument[1].fileData,
              actorName: res.data.actorName,
              gettingCharacterName: this.props.match.params.characterName,
              gettingCharacterType: this.props.match.params.characterType,
              rumeneration: res.data.projectActor.rumuneration,
              gst: res.data.projectActor.gst,
              paymentData: res.data.actorPayment,
              staffData: res.data.actorStaff,
              pan: res.data.pan,
              addressLine: res.data.address && res.data.address.addressLine,
              city: res.data.address && res.data.address.city,
              state: res.data.address && res.data.address.state,
              zipCode: res.data.address && res.data.address.zipCode,
              gettingcountry: res.data.address && res.data.address.country,
              gettingVanityVan:
                res.data.projectActor && res.data.projectActor.vanityVan,
              pickup: res.data.projectActor && res.data.projectActor.isPickUP,
              conveyance:
                res.data.projectActor && res.data.projectActor.conveyance,
              gettingStatus:
                res.data.projectActor && res.data.projectActor.state,
            });
          } catch (err) {
            console.log(err);
          }
        },
        () => {
          console.log("this.state", this.state);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  state = {
    actorId: null,
    projectimageid: null,
    formvalues: null,
    paymentData: null,
    staffData: null,
    fileuploadid: null,
    isButtonDisabled: true,
    actorName: null,
    characterName: null,
    characterType: null,
    rumeneration: null,
    pan: null,
    gst: null,
    addressLine: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
    vanityVan: null,
    pickup: null,
    conveyance: null,
    status: null,
    isLoading: false,
    gettingCharacterName: null,
    gettingCharacterType: null,
    gettingVanityVan: null,
    gettingStatus: null,
    gettingcountry: null,
    fileData: null,
    rowKey: 0,
  };

  getVanityVan = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "VANITY_VAN")
      .then((res) => {
        this.setState(
          {
            vanityVan: res.data,
          },
          () => console.log("vanity...", this.state.vanityVan)
        );
      });
  };

  getStatus = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState(
          {
            status: res.data,
          },
          () => console.log("status...", this.state.status)
        );
      });
  };

  getCharacterType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "CHARACTER_TYPE")
      .then((res) => {
        this.setState(
          {
            characterType: res.data,
          },
          () => console.log("CharacterType...", this.state.characterType)
        );
      });
  };

  getCountries = () => {
    let country = [];
    this.onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState(
          {
            country: res.data.countries,
          },
          () => console.log("Country...", this.state.country)
        );
      });
  };

  handleImage = (url) => {
    console.log("handle image url", url);

    this.setState({
      projectimageid: url,
    });
  };

  handleDocument = (id) => {
    console.log("fileuploadid", id);

    this.setState({
      fileuploadid: id,
    });
  };

  handlepayment = (data) => {
    console.log("handlepayment...", data);

    this.setState(
      {
        paymentData: data,
      },
      console.log("paymentData", this.state.paymentData)
    );
  };

  handlestaff = (details) => {
    this.setState({
      staffData: details,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formvalues: values,
          isButtonDisabled: false,
        });

        const data = {
          actorDocument: [
            {
              fileData: this.state.fileuploadid,
              fileType: "DOCUMENT",
            },
            {
              fileData: this.state.projectimageid,
              fileType: "IMAGE",
            },
          ],

          actorName: values.actorname,
          actorPayment: this.state.paymentData,
          actorStaff: this.state.staffData,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: this.state.zipCode,
          },
          characterName: values.character,
          characterType: values.charactertype,
          pan: values.pan,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: values.gst,
            isPickUP: values.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        this.scriptservice
          .scriptService(
            Services.AddActor.ADD_ACTOR,
            data,
            console.log("hai iam there", data)
          )
          .then((res) => {
            console.log(res);
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Actor Successfully Created!!"
            );
            this.props.history.goBack();
          })
          .catch((err) => {
            console.log(err);
            this.setState({ isLoading: false });
            // this.props.form.resetFields();
            // this.handleCancel();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
              handleError(
                err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
              )
            );
          });
      }
    });
  };
  handleUpdate = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formvalues: values,
          isButtonDisabled: false,
        });

        // let paymentamount = [];
        // let paymentdate = [];
        // let paymentremark = [];

        // let paymentterms = this.state.paymentData;
        // paymentterms.map((data, i) => {
        //   paymentamount.push(data.amount);
        //   paymentdate.push(data.paymentdate);
        //   paymentremark.push(data.remark);
        // });

        // console.log("paymentamount...", paymentamount);
        // console.log("paymentdate...", paymentdate);
        // console.log("paymentremark...", paymentremark);
        // console.log("paymentdata",this.state.paymentData)

        // let staffname = [];
        // let staffdesignation = [];
        // let staffpayment = [];
        // let stafftype = [];

        // let staffdetails = this.state.staffData;
        // staffdetails &&  staffdetails.map((details, i) => {
        //   staffname.push(details.name);
        //   staffdesignation.push(details.designation);
        //   staffpayment.push(details.payment);
        //   stafftype.push(details.type);
        // });
        // console.log("staffdetails...", staffdetails);
        // console.log("staffname...", staffname);
        // console.log("staffdesignation...", staffdesignation);
        // console.log("staffpayment...", staffpayment);
        // console.log("stafftype...", stafftype);
        // console.log("totalformvalue",this.state.formvalues)

        // const data = {
        //   actorDocument: [
        //     {
        //       documentId:0 ,
        //       fileData: this.state.projectimageid,
        //       fileType: "IMAGE"
        //     },
        //     {
        //       documentId: 0,
        //       fileData: this.state.fileuploadid,
        //       fileType: "DOCUMENT"
        //     }
        //   ],
        //   actorId: 0,
        //   actorName: values.actorname,
        //   actorPayment: {
        //     amount: paymentamount,
        //     paymentDate: paymentdate,
        //     remarks: paymentremark
        //   },
        //   actorStaff: {
        //     designation: staffdesignation,
        //     isSalaryNeed: true,
        //     payment: staffpayment,
        //     paymentType: stafftype,
        //     staffName: staffname
        //   },
        //   address: {
        //     addressLine: values.Address,
        //     addressLine2: null,
        //     addressTypeId: 0,
        //     city: values.city,
        //     country: values.country,
        //     id: 0,
        //     landMark: null,
        //     state: values.state,
        //     zipCode: values.zipcode
        //   },
        //   characterName: values.character,
        //   characterType: values.charactertype,
        //   pan: values.pan,
        //   projectActor: {
        //     conveyance: values.conveyance,
        //     gst: values.gst,
        //     isPickUP: values.pickup,
        //     projectId: 0,
        //     rumuneration: values.rumeneration,
        //     state: values.status,
        //     vanityVan: values.vanityvan
        //   }
        // };

        const data = {
          actorDocument: [
            {
              fileData: this.state.fileuploadid,
              fileType: "DOCUMENT",
            },
            {
              fileData: this.state.projectimageid,
              fileType: "IMAGE",
            },
          ],
          actorId: this.props.match.params.id,
          actorName: values.actorname,
          actorPayment: this.state.paymentData,
          actorStaff: this.state.staffData,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: this.state.zipCode,
          },
          characterName: values.character,
          characterType: values.charactertype,
          pan: values.pan,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: values.gst,
            isPickUP: values.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        this.scriptservice
          .scriptService(
            Services.AddActor.ADD_ACTOR,
            data,
            console.log("hai iam there", data)
          )
          .then((res) => {
            console.log(res);
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Actor Successfully Updated!!"
            );
            this.setState({ isLoading: false });
            this.props.history.goBack();
          })
          .catch((err) => {
            console.log(err);

            this.setState({ isLoading: true });
            // this.props.form.resetFields();
            // this.handleCancel();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const {
      isButtonDisabled,
      actorName,
      characterName,
      characterType,
      city,
      state,
      status,
      rumeneration,
      pan,
      gst,
      addressLine,
      zipCode,
      country,
      conveyance,
      pickup,
      vanityVan,
      paymentData,
      staffData,
      isLoading,
      gettingCharacterName,
      gettingCharacterType,
      gettingVanityVan,
      gettingStatus,
      gettingcountry,
      projectImageId,
      fileData,
    } = this.state;

    return (
      <div>
        <Row>
          <Col
            xl={{ span: 18, offset: 3 }}
            lg={{ span: 18, offset: 3 }}
            md={{ span: 21, offset: 3 }}
          >
            <Row>
              <Col
                xl={{ span: 10, offset: 10 }}
                lg={{ span: 10, offset: 10 }}
                md={{ span: 10, offset: 10 }}
              >
                <div
                  style={{
                    color: "#2da01c",
                    marginBottom: "10px",
                    fontSize: "20px",
                  }}
                >
                  Actor Profile
                </div>
              </Col>
            </Row>

            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Actor Name</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("actorname", {
                          initialValue: actorName,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Actorname!",
                            },
                            {
                              pattern: new RegExp("^[A-Za-z]*$"),
                              message: "Enter Alphabets onlys",
                            },
                          ],
                        })(<Input type="text" placeholder="Actor Name" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>*Character Name</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("character", {
                          initialValue: this.props.match.params.characterName,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Character Name!",
                            },
                            {
                              pattern: new RegExp("^[A-Za-z]*$"),
                              message: "Enter Alphabets onlys",
                            },
                          ],
                        })(
                          <Input
                            style={{ fontWeight: "bold" }}
                            disabled
                            type="text"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 10 }}>
                  <Row style={{ position: "absolute", right: "50%" }}>
                    <Form.Item>
                      {getFieldDecorator("projectimage", {
                        // initialValue: projectImageId
                      })(
                        <img
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = `${Images.user}`;
                          }}
                          src={`${getScriptAPIURL()}/file/download/${fileData}`}
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "100%",
                          }}
                        />
                      )}
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Character Type</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("charactertype", {
                          initialValue: this.props.match.params.characterType,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Character Type!",
                            },
                          ],
                        })(
                          <Select
                            disabled
                            style={{ width: "100%", fontWeight: "bold" }}
                          >
                            {characterType &&
                              characterType.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 14 }}
                  style={{ marginBottom: "30px" }}
                >
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>*Status</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("status", {
                          initialValue: gettingStatus || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter status!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Status"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.setState({ gettingStatus: value })
                            }
                          >
                            {status &&
                              status.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <React.Fragment>
                {this.state.gettingStatus === "CONFIRMED" ||
                this.state.gettingStatus === "NEGOTIATION" ? (
                  <div>
                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Remuneration</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("rumeneration", {
                                initialValue: rumeneration,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Rumeneration!",
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="Rumenration"
                                  onChange={(value) => {
                                    this.setState({
                                      rumeneration: numberOnly(value),
                                    });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 4 }}
                            lg={{ span: 5 }}
                            md={{ span: 6 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>PaymentTerms</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 20 }}
                            lg={{ span: 19 }}
                            md={{ span: 18 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("paymentdata", {
                                initialValue: paymentData,
                                rules: [
                                  {
                                    required: false,
                                    message: "Please Enter Payment Terms",
                                  },
                                ],
                              })(
                                <Paymentterms
                                  paymentData={this.state.paymentData}
                                  handlepayment={this.handlepayment}
                                  refreshData={this.refreshDataPayment}
                                  actorId={this.state.actorId}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Pan No</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {console.log("pan initial value :" + pan)}{" "}
                              {getFieldDecorator("pan", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Pan No!",
                                  },
                                  {
                                    pattern: new RegExp(
                                      "/[A-Z]{5}[0-9]{4}[A-Z]{1}/"
                                    ),
                                    message: "please Enter correct pan format",
                                  },
                                ],
                              })(<Input type="text" placeholder="Pan No" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Gst No</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("gst", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Gst No!",
                                  },
                                ],
                              })(<Input type="text" placeholder="Gst No" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>Address</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("Address", {
                                initialValue: addressLine,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Address!",
                                  },
                                ],
                              })(<Input type="text" placeholder="Address" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>City</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("city", {
                                initialValue: city,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter City!",
                                  },
                                ],
                              })(<Input type="text" placeholder="City" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>State</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("state", {
                                initialValue: state,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter State!",
                                  },
                                ],
                              })(<Input type="text" placeholder="State" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Zip code</label>
                            </div>
                          </Col>
                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("zipcode", {
                                initialValue: zipCode,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Zipcode!",
                                  },
                                ],
                              })(
                                <Input
                                  maxLength="6"
                                  placeholder="Zipcode"
                                  onChange={(value) => {
                                    this.setState({
                                      zipCode: numberOnly(value),
                                    });
                                  }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Country</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("country", {
                                initialValue: gettingcountry,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter country!",
                                  },
                                ],
                              })(
                                <Select
                                  showSearch
                                  placeholder="Country"
                                  className="w-100"
                                  //  onChange={value => this.setState({ country: value })}
                                  optionFilterProp="children"
                                  //  onFocus={this.onFocus}
                                  //  onBlur={this.onBlur}
                                  //  onSearch={this.onSearch}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.country &&
                                    (this.state.country || []).map(
                                      (option, i) => (
                                        <Option value={option.name}>
                                          {option.name}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 24 }}
                        lg={{ span: 24 }}
                        md={{ span: 24 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 4 }}
                            lg={{ span: 5 }}
                            md={{ span: 6 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Staff Details</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 20 }}
                            lg={{ span: 19 }}
                            md={{ span: 18 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("staffdata", {
                                initialValue: staffData,
                                rules: [
                                  {
                                    required: false,
                                    message: "Please Enter Staff Details!",
                                  },
                                ],
                              })(
                                <Dynamictable
                                  staffData={this.state.staffData}
                                  handlestaff={this.handlestaff}
                                  refreshData={this.refreshDataStaff}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Vanity Van</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("vanityvan", {
                                initialValue: gettingVanityVan,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Enter Vanity Van!",
                                  },
                                ],
                              })(
                                <Select
                                  placeholder="Vanity Van"
                                  style={{ width: "100%" }}
                                >
                                  {vanityVan &&
                                    vanityVan.map((option, i) => (
                                      <Option value={option.value}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                                marginBottom: "30px",
                              }}
                            >
                              <label>Pick Up</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("pickup", {
                                initialValue: pickup,
                                rules: [
                                  {
                                    required: true,
                                    message: "Please Select Pickup!",
                                  },
                                ],
                              })(
                                <Radio.Group
                                  name="radiogroup"
                                  onChange={(e) =>
                                    this.setState({ pickup: e.target.value })
                                  }
                                >
                                  <Radio value={true}>Yes</Radio>
                                  <Radio value={false}>No</Radio>
                                </Radio.Group>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      {this.state.pickup ? null : (
                        <Col
                          xl={{ span: 12 }}
                          lg={{ span: 12 }}
                          md={{ span: 14 }}
                        >
                          <Row gutter={16}>
                            <Col
                              xl={{ span: 8 }}
                              lg={{ span: 10 }}
                              md={{ span: 11 }}
                            >
                              <div
                                style={{
                                  fontWeight: "600",
                                  paddingTop: "8px",
                                  textAlign: "right",
                                  marginBottom: "30px",
                                }}
                              >
                                <label>Conveyance</label>
                              </div>
                            </Col>
                            <Col
                              xl={{ span: 12 }}
                              lg={{ span: 13 }}
                              md={{ span: 12 }}
                            >
                              <Form.Item>
                                {getFieldDecorator("conveyance", {
                                  initialValue: conveyance,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please Enter Conveyance!",
                                    },
                                  ],
                                })(
                                  <Input
                                    placeholder="Conveyance"
                                    onChange={(value) => {
                                      this.setState({
                                        conveyance: numberOnly(value),
                                      });
                                    }}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 8 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Contract</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 12 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("fileuploadid", {
                                initialValue: this.state.fileuploadid,
                                rules: [
                                  {
                                    required: false,
                                    message: "Please Upload Documents!",
                                  },
                                ],
                              })(
                                <Uploaddocumentfile
                                  handleDocument={this.handleDocument}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xl={{ span: 24 }}
                        lg={{ span: 12 }}
                        md={{ span: 14 }}
                      >
                        <Row gutter={16}>
                          <Col
                            xl={{ span: 4 }}
                            lg={{ span: 10 }}
                            md={{ span: 11 }}
                          >
                            <div
                              style={{
                                fontWeight: "600",
                                paddingTop: "8px",
                                textAlign: "right",
                              }}
                            >
                              <label>Photos</label>
                            </div>
                          </Col>

                          <Col
                            xl={{ span: 18 }}
                            lg={{ span: 13 }}
                            md={{ span: 12 }}
                          >
                            <Form.Item>
                              {getFieldDecorator("projectimageid", {
                                initialValue: this.state.projectimageid,
                                rules: [
                                  {
                                    required: false,
                                    message: "Please Upload Photos!",
                                  },
                                ],
                              })(
                                <UploadImage handleImage={this.handleImage} />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </React.Fragment>

              <Row
                gutter={12}
                type="flex"
                justify="end"
                style={{ marginTop: "20px" }}
              >
                <Col>
                  <Button onClick={() => this.props.history.goBack()}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={isLoading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const CreateActor = Form.create()(AddActor);
export default CreateActor;
