import React, { Component, Fragment } from "react";
import "antd/dist/antd.css";
import { Upload, Icon, Modal, message } from "antd";
import GlobalService from "services/GlobalService";
import { AttachmentType, ImageDomain } from "constants/AppConstants";
import UploadService from "services/uploadService";
import { urltoFile } from "utilities/UserHelper";
import ResizeFile from "utilities/ImageCompression";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class MultipleSelectImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      totalFileList: 0,
      uploadedFiles: 0,
      finalList: [],
      ImageIDS: [],
      pdfUpload: false,
    };
    this._globalService = new GlobalService();
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  getTheFileFromFileList = (totalFiles) => {
    this.state.fileList.map((file, i) => {
      let imageFile = new FormData();
      imageFile.append("file", file.originFileObj);
      this.getIdForImage(imageFile, totalFiles);
    });
  };

  uploadImages = (images) => {
    images.forEach((info) => {
      ResizeFile(info.originFileObj).then((base64) => {
        urltoFile(base64).then((file) => {
          let formData = new FormData();
          formData.append("file", file);

          UploadService.projectImageUpload(ImageDomain?.aceScript, formData, {
            attachmentType: this.props.type || AttachmentType?.projectPicture,
            isBucketUrlRequired: true,
          }).then((res) => {
            this.setState(
              {
                ImageIDS: [...this.state.ImageIDS, res?.data?.id],
              },
              () => {
                if (this.state.ImageIDS.length === images?.length) {
                  this.props.handleImageIDS(this.state?.ImageIDS);
                }
              }
            );
          });
        });
      });
    });
  };

  handleChange = (info) => {
    this.setState({
      totalFileList: info.fileList.length,
    });
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      if (this.state.totalFileList === this.state.uploadedFiles + 1) {
        this.setState(
          {
            fileList: info.fileList,
            totalFileList: 0,
          },
          () => this.uploadImages(info.fileList)
        );
      }
      this.setState({
        uploadedFiles: this.state.uploadedFiles + 1,
      });
    }
  };
  beforeUpload = (file) => {
    let isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (isJPG) {
      this.setState({
        pdfUpload: true,
      });
    }
    if (!isJPG) {
      message.error("You can only upload jpeg and png type image files !");
    }
    // const isLt2M = file.size / 1024 / 1024 < 5;
    // if (!isLt2M) {
    //   message.error("Document must smaller than 5MB!");
    // }
    return !isJPG;
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { maxLength, multiple = true } = this.props;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Fragment>
        <Upload
          customRequest={this.dummyRequest} // created for image upload issue since the mocky.io is expired
          listType="picture-card"
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          multiple={multiple}
        >
          {fileList.length >= maxLength ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Fragment>
    );
  }
}

export default MultipleSelectImage;
