import React, { Component } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import { Capitalize, checkFor } from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { connect } from "react-redux";

class CastTechnicianscard extends Component {
  state = {
    cardList: [],
    isActorsFetched: false,
    characterName: [],
    modalVisible: false,
    modalClose: false,
    activeSceneId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { dataSource: nextProps.dataSource };
  }

  render() {
    const { dataSource } = this.state;
    const { activePermisision } = this.props;
    const hasRows = isEmpty(dataSource);
    return (
      <Row className="mb-25">
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          style={{ border: "1px solid #ccc" }}
        >
          <h3
            className=""
            style={{
              backgroundColor: "#001529",
              color: "#fff",
              padding: "10px",
              textAlign: "center",
            }}
          >
            CAST & TECHNICIANS
          </h3>
          {hasRows ? (
            <RecordNotFound text="No Cast & Technicians found" />
          ) : (
            <Row>
              <Col xl={24}>
                {dataSource.map((list) => (
                  <Col
                    xl={{ span: 6 }}
                    lg={{ span: 10 }}
                    md={{ span: 16 }}
                    xs={{ span: 24 }}
                    style={{ padding: "10px" }}
                  >
                    <Row className="card">
                      <Col span={24} className="vertical-center">
                        <h3>
                          <a className="font-600 text-ellipsis">
                            <Link
                              className={`${
                                checkFor(
                                  activePermisision,
                                  GlobalConstants.PERMISSION_EDIT
                                )
                                  ? ""
                                  : "disabled-link"
                              }`}
                              to={`${
                                list.actorId
                                  ? `/project/actorprofile/${list.actorId}/${list.characterName}/${list.roleName}/${list.characterId}`
                                  : list.crewId
                                  ? `/project/updateCrewprofile/${list.crewId}`
                                  : `/project/audition/${list.roleName}/${list.characterId}/${list.characterName}`
                              }`}
                            >
                              <h4 className="primary-font-color">
                                {Capitalize(list.name) ||
                                  Capitalize(list.characterName)}
                              </h4>
                            </Link>
                          </a>
                        </h3>
                        <p>
                          <Link style={{ cursor: "default" }}>
                            {list.roleName}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CastTechnicianscard);
