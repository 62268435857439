import React, { Component, Fragment } from "react";
import { Table, Button, Row, Col, Tag } from "antd";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  checkFor,
  Capitalize,
  checkDateGreaterThan,
} from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import TooltipIcon from "components/shared/TooltipIcon";
import {
  MenusRoute,
  Services,
  LocalStorage,
  Screens,
} from "constants/AppConstants";

import BudgetService from "services/BudjetService";
import TransportService from "services/TransportService";
import { Link } from "react-router-dom";
import ThemeColors from "constants/ThemeConstants/variables";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import moment from "moment";
import { setActiveTab } from "store/action";
import TooltipComponent from "components/shared/TooltipComponent";

class TransportMain extends Component {
  constructor(props) {
    super(props);

    this._budgetService = new BudgetService();
    this._transportService = new TransportService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchTransportData();
  }

  fetchTransportData() {
    this._budgetService.getTransport({ isBudget: false }).then((res) => {
      this.setState({
        dataSource: res.data.productionShoots || [],
        isLoading: false,
      });
    });
  }

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const { activePermisision } = this.props;

    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Shoot date",
        dataIndex: "shootDate",
        key: "shootDate",
        // align: "center",
        width: "10%",
        render: (text) => (
          <Tag
            style={{ marginLeft: 0, borderRadius: 12, marginBottom: 3 }}
            color={ThemeColors.primaryColor}
          >
            {text}
          </Tag>
        ),
      },
      {
        title: "Scene No",
        dataIndex: "scenes",
        render: (text, record) => (
          <>
            {text &&
              text.map((scene) => {
                console.log("scene", scene);
                return (
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      backgroundColor: "#ccc",
                      marginBottom: 2,
                    }}
                  >
                    <Link
                      style={{
                        color: "black",
                      }}
                      to="/project/screen-overview"
                      onClick={() => {
                        localStorage.setItem(
                          GlobalConstants.REDIRECT_TO,
                          Screens.STUNTS
                        );
                        localStorage.setItem(
                          LocalStorage.ACTIVE_SCENE_ID,
                          scene.sceneId
                        );
                        this.props.setActiveTab("1");
                      }}
                    >
                      {/* {scene.sceneNumber} */}
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                );
              })}
          </>
        ),
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocations",
        render: (text, record) => (
          <span className="commaContainer">
            {text &&
              text.map((a) => {
                return <span>{Capitalize(a.locationName)}</span>;
              })}
          </span>
        ),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "Action",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <>
            {record.actualLocations &&
              record.actualLocations.length !== 0 &&
              !checkDateGreaterThan(
                record.shootDate,
                moment().format(GlobalConstants.DD_MM_YYYY)
              ) && (
                <TooltipIcon
                  icon={"more"}
                  onClick={() =>
                    this.props.history.push(
                      `${MenusRoute.TRANSPORT}/${moment(
                        record.shootDate,
                        "DD-MM-YYYY"
                      ).format("YYYY-MM-DD")}`
                    )
                  }
                ></TooltipIcon>
              )}
          </>
        ),
      });
    }
    return (
      <Fragment>
        <Row span={24}>
          <Title {...this.props}>
            <TitleText>Transporting</TitleText>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ padding: 10 }}
          >
            <Col xl={24}>
              <Table
                loading={isLoading}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{
                  emptyText: !isLoading && <RecordNotFound />,
                }}
              />
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.TRANSPORT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransportMain);
