import React, { Fragment, Component } from "react";
import "antd/dist/antd.css";
import { Upload, Button, Icon, message, Row, Col } from "antd";

import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
  ImageDomain,
  AttachmentType,
} from "constants/AppConstants";
import { handleError } from "utilities/ErrorHandler";
import { showNotifications } from "./NotificationComponent";
import UploadService from "services/uploadService";

class InvoiceUpload extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
  }

  componentDidMount() {
    this.props.onRef(this); // reference to access the functions of this component from parent's
  }

  state = {
    fileList: [],
    DocumentsIds: [],
    pdfUpload: false,
    isUploading: false,
    showUpload: true,
    uploadedFiles: 0,
  };

  beforeUpload = (file) => {
    let isJPG =
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    if (isJPG) {
      this.setState({
        pdfUpload: true,
      });
    }
    if (!isJPG) {
      message.error("You can only upload PDF , DOC and DOCX file !");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Document must smaller than 5MB!");
    }
    return !isJPG || !isLt2M;
  };

  handleSave = () => {
    this.setState({
      isUploading: true,
    });
    const { fileList } = this.state;

    if (fileList.length !== 0) {
      fileList.map((file, i) => {
        const type = this.getFileType(file);
        let imageFile = new FormData();
        imageFile.append("file", file);
        this.getIdForImage(imageFile, type, fileList.length);
      });
    } else {
      this.props.handleDocument();
    }
  };

  getFileType = (file) =>
    file.type.split("/")[0] === "image"
      ? GlobalConstants.IMAGE
      : GlobalConstants.DOCUMENT;

  getIdForImage = (imageFile, type, totalFiles) => {
    const { handleDocument } = this.props;
    UploadService.projectImageUpload(ImageDomain?.aceScript, imageFile, {
      attachmentType: this.props.type || AttachmentType?.projectPicture,
      isBucketUrlRequired: true,
    })
      .then((res) => {
        let DocumentsIds = [...this.state.DocumentsIds];
        DocumentsIds.push({
          attachmentId: res?.data?.id,
          fileType: type,
        });
        this.setState(
          {
            DocumentsIds,
            uploadedFiles: this.state.uploadedFiles + 1,
          },
          () => {
            if (this.state.DocumentsIds.length === totalFiles) {
              this.setState(
                {
                  fileList: [],
                  uploadedFiles: 0,
                  isUploading: false,
                  showUpload: false,
                },
                () => handleDocument(DocumentsIds)
              );
              setTimeout(() => {
                this.setState({
                  isUploading: false,
                  showUpload: true,
                  DocumentsIds: [],
                });
              }, 1);
            }
          }
        );
      })
      .catch((err) => {
        this.setState({
          isUploading: false,
          showUpload: false,
        });
        setTimeout(() => {
          this.setState({
            isUploading: false,
            showUpload: true,
          });
        }, 1);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        // this.props.getPermissions();
      });
  };

  handleRemove = (fileId) => {
    const { fileList } = this.state;
    this.setState({ fileList: fileList.filter((item) => item.uid !== fileId) });
  };

  handleRemoveAll = () => {
    this.setState({
      fileList: [],
    });
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const { fileList, isUploading, uploadedFiles, showUpload } = this.state;

    // const images = fileList.map(file => file.type.split("/")[0] === "image" && file);
    const images = fileList.filter(
      (file) => file.type.split("/")[0] === "image" && file
    );
    const docs = fileList.filter(
      (file) => file.type.split("/")[0] === "application" && file
    );
    const text = isUploading
      ? `Uploading...(${uploadedFiles}/${fileList.length})`
      : "Save";

    // console.log(
    //   "fileList",
    //   fileList,
    //   fileList.map(file => file.type),
    //   images,
    //   docs
    // );

    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      listType: "picture",
      className: "upload-list-inline",
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
    };
    return (
      <Row>
        <Col>
          {showUpload && (
            <Upload {...props}>
              <Button type="primary" icon="upload">
                Files
              </Button>
            </Upload>
          )}
        </Col>
      </Row>
    );
  }
}

export default InvoiceUpload;
