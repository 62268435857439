import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {Button, Col, Icon, Table } from "antd";
import RecordNotFound from "components/shared/RecordNotFound";
import LegalService from "services/LegalService";
import { PriceFormat, MaskPaymentType, DownloadFormURL, checkFor} from "utilities/ValidationHelper";
import { getScriptAPIURL } from "utilities/APIHelper";
import { GlobalConstants, LocalStorage } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
const Location = () => {
    // State hook
    const userState = useSelector((state) => state?.user);
    // States
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    // const values
    const activePermisision = getSPermissionFromMenus(
        "Char-Actor",
        userState?.menus || []
      );
    const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const locationColumn = [
        {
            title: "#",
            align: "center",
            key: "key",
            width: "10%",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Location Name",
            dataIndex: "locationName",
            key: "locationName",
            render: (text, record) => (
                <Link
                    to={{
                        pathname: `/project/actorprofile/legal/${record?.locationAuditionId || null
                            }/${record?.id || null}/${record?.remuneration || null}/${record?.paymentType
                            }/${record?.characterType}/${record?.locationName}/location`,
                    }}
                >
                    <span>{text}</span>
                </Link>
            ),
        },
        {
            title: "Contact Person",
            dataIndex: "contactPerson",
            key: "contactPerson",
        },
        {
            title: "Salary",
            dataIndex: "remuneration",
            key: "remuneration",
            align: "right",
            render: (text, record) => PriceFormat(text),
        },
        {
            title: "Payment Type",
            dataIndex: "paymentType",
            key: "paymentType",
            align: "center",
            render: (text, record) => MaskPaymentType(text),
        },
        {
            title: "Agreement",
            dataIndex: "description",
            key: "description",
            align: "center",
            render: (text, record) => (
                <Button
                    onClick={() =>
                        DownloadFormURL(
                            `${getScriptAPIURL()}/project/${projectId}/agreement-agreement/${record?.id
                            }/export?contentDisposition=INLINE`
                        )
                    }
                    type="link"
                >
                    <Icon type="download" style={{ fontSize: "15px" }} /> Download
                </Button>
            ),
        },
    ];

    // UseEffect to get Location Data from api
    useEffect(() => {
        getLocationData();
    },[])

    // functions
    const getLocationData = () => {
        setLoading(true);
        const payload = {
            legalAgreementType: "LOCATION",
            projectId,
            size: 0,
          };
    LegalService.getLegalAggreement(payload)
    .then((res) => {
        setLoading(false);
        setTableData(res?.data?.content || []);
    })
    .catch((err) => {
        setLoading(true);
    })

    }
    return (
        <div>
            <Col xl={24} style={{ paddingBottom: 10 }}>
                {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
                    <Button
                    onClick={() => {}}
                    type="primary"
                    style={{ float: "right" }}
                    >
                    <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                    </Button>
                )}
            </Col>
            <Col xl={24}>
                <Table
                    bordered
                    dataSource={tableData}
                    columns={locationColumn}
                    loading={loading}
                    pagination={false}
                    locale={{
                        emptyText: !loading && <RecordNotFound />,
                    }}
                />
            </Col>
        </div>
    )
}

export default Location;