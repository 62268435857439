import React, { Component } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Table,
  notification,
} from "antd";

import {
  LocalStorage,
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import InvoiceService from "services/InvoiceService";
import { showActionMessage, showNotifications } from "./NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import AccountingService from "services/AccountingServices";
import { StartCase } from "utilities/ValidationHelper";
import moment from "moment";
import DisplayDocumentComponent from "./UploadDocumentAndDisplay/DisplayDocumentComponent";
import InvoiceUpload from "./InvoiceUpload";
import InvoiceDisplay from "./InvoiceDisplay";
import DocumentDelete from "services/DocumentDelete";

import "./invoice.scss";
import Loader from "./Loader";

const { Option } = Select;

class PayeeFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(props),
      isDataFetched: true,
    };

    this._invoicesService = new InvoiceService();
    this._globalService = new GlobalService();
    this._accountingService = new AccountingService();
    this._docDelete = new DocumentDelete();
  }

  getInitialState = ({
    category,
    description,
    activePayment,
    activePayee,
    details: { productionCategoryDepartment, categoryDepartment },
  }) => {
    return {
      descriptionData: description,
      gstRate: null,
      invoiceAmount: null,
      invoiceDate: null,
      phoneNumber: null,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      activePayee,
      record: activePayment,
      shootDate: activePayment.shootDate,
      productionCategoryDepartment,
      tdsTax: null,
      tdsData: [],
      gstData: [],
      invoices: [],
      invoiceDocuments: [],
      productionAccountId: activePayment.productionAccountId,
      category,
      productionDepartment: categoryDepartment,
    };
  };

  generateTableData = ({ descriptionData, gstRate, tdsTax }) => {
    this.setState({});
  };

  componentDidMount() {
    const { activePayee } = this.state;
    this.props.onRef(this); // reference to access the functions of this component from parent's
    this.fetchGst();
    this.fetchTds();
    this.fetchPayeeDD();
    if (activePayee) {
      this.setState(
        {
          isDataFetched: false,
        },
        () => this.handlePayeeChange(activePayee)
      );
    }
  }

  fetchPayeeDD = () => {
    const { descriptionData, category, productionDepartment } = this.state;
    const data = {
      description: descriptionData ? descriptionData : "",
      category,
      productionDepartment,
    };

    this.setState({
      payeeDDLoading: true,
    });
    if (data.productionDepartment === undefined) {
      delete data.productionDepartment;
    }
    if (data.productionDepartment != undefined) {
      this._accountingService
        .services(Services.AccountingVariables.GET_PAYEE_DD, data)
        .then((res) => {
          this.setState({
            invoices: res.data,
            payeeDDLoading: false,
          });
        })
        .catch((err) =>
          this.setState({
            isLoading: true,
            payeeDDLoading: false,
          })
        );
    } else {
      this._accountingService
        .services(Services.AccountingVariables.GET_PAYEE_DD_WITHOUT_DEPT, data)
        .then((res) => {
          this.setState({
            invoices: res.data,
            payeeDDLoading: false,
          });
        })
        .catch((err) =>
          this.setState({
            isLoading: true,
            payeeDDLoading: false,
          })
        );
    }
  };

  fetchTds() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "TDS_TAX")
      .then((res) => {
        this.setState({
          tdsData: res.data || [],
        });
      });
  }

  fetchGst() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "GST_TAX")
      .then((res) => {
        this.setState({
          gstData: res.data || [],
        });
      });
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleSave = () => {
    const {
      payeeName,
      phoneNumber,
      pan,
      gst,
      category,
      productionDepartment,
      descriptionData,
      record,
      activePayee,
    } = this.state;
    const { handlePayeeName, handleClosePayeeModal } = this.props;

    let data = {
      category,
      description: descriptionData,
      gst,
      pan,
      payeeName,
      phoneNumber,
      productionDepartment,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };

    // let tempDescription = category === GlobalConstants.PRODUCTION_CAPS ?
    if (activePayee) {
      data = {
        ...data,
        payeeId: activePayee,
      };
    }
    let url = activePayee
      ? Services.AccountingVariables.UPDATE_PAYEE
      : Services.AccountingVariables.SAVE_PAYEE;
    let successText = activePayee
      ? GlobalConstants.UPDATED_SUCCESSFULLY
      : GlobalConstants.CREATED_SUCCESSFULLY;
    this._accountingService
      .services(url, data, activePayee)
      .then((res) => {
        showActionMessage(GlobalConstants.SUCCESS, `Payee ${successText}`);
        handlePayeeName(res.data.payeeName, res.data.payeeId, record);
        handleClosePayeeModal();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleSave();
      }
    });
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  dateChange = (value, dateString, record, type) => {
    this.setState({
      invoiceDate: dateString,
    });
  };

  handlephoneNumber = (phoneNumber) => {
    this.setState(
      {
        phoneNumber,
      },
      () =>
        this.props.form.setFieldsValue({
          phoneNumber: phoneNumber,
        })
    );
  };

  handlePayeeChange = (id) => {
    this.setState({
      isDataFetched: false,
      activePayee: id,
    });
    this._accountingService
      .services(Services.AccountingVariables.GET_CURRENT_PAYEE, id)
      .then((res) => {
        const {
          // descriptionData,
          payeeId,
          payeeName,
          pan,
          gst,
          phoneNumber,
        } = res.data;
        this.setState(
          {
            // descriptionData,
            payeeName,
            phoneNumber,
            gst,
            pan,
            isDataFetched: true,
          },
          () =>
            this.props.form.setFieldsValue({
              phoneNumber,
              // descriptionData,
              payeeName,
              gst,
              pan,
            })
        );
      });
  };

  checkInPreviousCategory = (phoneNumber) => {
    this.setState({
      isDataFetched: false,
    });
    this._accountingService
      .services(Services.AccountingVariables.GET_PREVIOUS_PAYEE, phoneNumber)
      .then((res) => {
        if (res.status !== 204) {
          const {
            // descriptionData,
            payeeId,
            payeeName,
            pan,
            gst,
            phoneNumber,
            category,
          } = res.data;
          this.setState(
            {
              // descriptionData,
              payeeName,
              phoneNumber,
              gst,
              pan,
              activePayee: payeeId,
              isDataFetched: true,
              category,
              // productionDepartment: category
            },
            () =>
              this.props.form.setFieldsValue({
                phoneNumber,
                // descriptionData,
                payeeName,
                gst,
                pan,
              })
          );
        } else if (res.status === 204) {
          let check = false;
          let oldData = this.state.invoices || [];
          oldData.filter((list) => {
            return (check =
              list.label.toLowerCase() !== phoneNumber.toLowerCase());
          });
          this.setState(
            {
              invoices: [...oldData, { label: phoneNumber }],
            },
            () => {
              this.props.form.setFieldsValue({
                phoneNumber: phoneNumber,
              });
              this.setState({ phoneNumber: phoneNumber, isDataFetched: true });
            }
          );
        } else {
          this.setState({
            isDataFetched: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isDataFetched: true });
      });
  };

  handleInvoiceBlur = (phoneNumber) => {
    const reg = new RegExp("^[0-9]+$");
    if (reg.test(phoneNumber.toLowerCase())) {
      this.props.form.setFieldsValue({
        phoneNumber: phoneNumber,
      });
      this.setState(
        {
          phoneNumber,
        },
        () => {
          if (this.state.phoneNumber && this.state.phoneNumber.length === 10) {
            this.checkInPreviousCategory(this.state.phoneNumber);
          } else {
            notification.error({
              message: "Phone Number",
              description: "Mobile Number should be 10 numeric characters",
            });
          }
        }
      );
    } else {
      notification.error({
        message: "Phone Number",
        description: "Mobile Number should be numeric",
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { phoneNumber, invoices, isDataFetched, payeeDDLoading } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
    };
    const tableCol = {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 19 },
      },
    };
    const customCol = {
      labelCol: {
        sm: { span: 2 },
      },
      wrapperCol: {
        sm: { span: 22 },
      },
    };

    return (
      <>
        {!isDataFetched && <Loader />}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col xl={24}>
              <Form.Item label="Phone Number." hasFeedback>
                {getFieldDecorator("phoneNumber", {
                  initialValue: phoneNumber,
                  rules: [
                    {
                      required: true,
                      message: "Invoice Number Required!",
                    },
                  ],
                })(
                  <Select
                    loading={payeeDDLoading}
                    style={{ width: "100%" }}
                    className="upperCase"
                    showSearch
                    // autoFocus
                    onChange={this.handlePayeeChange}
                    onBlur={this.handleInvoiceBlur}
                    onSearch={this.handlephoneNumber}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {invoices &&
                      invoices.map((list, i) => {
                        return (
                          <Option key={i} value={list.value}>
                            {list.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xl={24}>
              <Form.Item label="Payee Name" hasFeedback>
                {getFieldDecorator("payeeName", {
                  rules: [
                    {
                      required: true,
                      message: "Please Enter Payee Name !",
                    },
                  ],
                })(
                  <Input
                    style={{
                      textTransform: "uppercase",
                    }}
                    type="text"
                    placeholder="Payee name"
                    // maxLength={10}
                    onChange={(e) =>
                      this.setState({
                        payeeName: e.target.value,
                      })
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={24}>
              <Form.Item label="PAN" hasFeedback>
                {getFieldDecorator("pan", {
                  rules: [
                    {
                      required: false,
                      message: "Please Enter Pan !",
                    },
                    {
                      pattern: new RegExp(
                        "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                      ),
                      message:
                        "Please Enter Correct PAN Format Ex: JSDFG1234H ",
                    },
                  ],
                })(
                  <Input
                    style={{
                      textTransform: "uppercase",
                    }}
                    type="text"
                    placeholder="Pan No"
                    maxLength={10}
                    onChange={(e) =>
                      this.setState({
                        pan: e.target.value,
                      })
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={24}>
              <Form.Item label="GST" hasFeedback>
                {getFieldDecorator("gst", {
                  rules: [
                    {
                      required: false,
                      message: "Please Enter gst !",
                    },
                    {
                      pattern: new RegExp(
                        "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                      ),
                      message:
                        "Please Enter Correct GST Format Ex: 22AAAAA0000A1Z5 ",
                    },
                  ],
                })(
                  <Input
                    style={{
                      textTransform: "uppercase",
                    }}
                    type="text"
                    placeholder="GST No"
                    maxLength={15}
                    onChange={(e) =>
                      this.setState({
                        gst: e.target.value,
                      })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const PayeeForm = Form.create({ name: "register" })(PayeeFormEdit);

export default PayeeForm;
