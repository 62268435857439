import React, { Component } from "react";

class HeroineAudition extends Component {
  componentDidMount() {
    console.log("componentDidMount HeroineAudition");
  }
  render() {
    return <div>Heroine Audition</div>;
  }
}

export default HeroineAudition;
