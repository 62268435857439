import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuditionCardComponent from "components/AuditionCardComponent";
import { Row, Col, Button } from "antd";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import { Services, GlobalConstants, Screens } from "constants/AppConstants";
import NoSceneFound from "components/NoSceneFound";
import SkeletonLoader from "components/shared/SkeletonLoader";
import AddActorModal from "components/AddActorModal/AddActorModal";
import { setActiveTab } from "store/action";
import { connect } from "react-redux";
import { Capitalize, checkFor } from "utilities/ValidationHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

class AuditionComponent extends Component {
  state = {
    cardList: [],
    isActorsFetched: false,
    isModalVisible: false,
    characterId: null,
  };

  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
  }

  componentDidMount() {
    this.fetchActor();
    this.setState({
      characterId: this.props.match.params.id,
    });
  }

  componentWillUnmount() {
    localStorage.setItem(GlobalConstants.REDIRECT_TO, Screens.ACTOR);
    this.props.setActiveTab("1");
  }

  fetchActor = () => {
    this.setState({ isActorsFetched: false });
    this._actorService
      .actorTabService(
        Services.SceneOVerviewVariable.GET_ACTORS_BY_ID,
        this.props.match.params.id
      )
      .then((res) => {
        this.setState({
          cardList: res.data.actors,
          isActorsFetched: true,
        });
      });
  };

  deleteActors = (res) => {
    this.fetchActor();
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  renderActor = () => {
    let actorList = [];
    if (this.state.cardList) {
      this.state.cardList.forEach((actor) => {
        const singleImage =
          (actor &&
            actor.actorImages &&
            actor.actorImages[0] &&
            actor.actorImages[0].attachment &&
            actor.actorImages[0].attachment?.url) ||
          null;
        actorList.push(
          <AuditionCardComponent
            {...this.props}
            type={this.props.match.params.type}
            actorName={actor.actorName}
            status={actor.state.replace(/_/g, " ")}
            actorId={actor.actorId}
            characterName={this.props.match.params.characterName}
            deleteActors={this.deleteActors}
            ImageIdList={actor.actorImages}
            Image={singleImage}
            characterId={this.state.characterId}
            characterAuditionId={actor.characterAuditionId}
          />
        );
      });
      return actorList;
    }
    return (
      <NoSceneFound type="Actor" refreshProjectList={this.refreshProjectList} />
    );
  };

  render() {
    const { activePermisision } = this.props;
    return (
      <Row>
        <Row>
          <Title
            hasBack
            {...this.props}
            handleChange={() => {
              localStorage.setItem(GlobalConstants.REDIRECT_TO, Screens.ACTOR);
              this.props.setActiveTab("1");
            }}
          >
            <TitleText>Actor Audition </TitleText>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row className="mt-25">
            <Col
              xl={{ offset: 1, span: 21 }}
              lg={{ offset: 1, span: 21 }}
              md={{ offset: 1, span: 21 }}
              style={{ border: "1px solid #ccc" }}
            >
              <h3
                className="font-600 text-left"
                style={{
                  backgroundColor: "#001529",
                  color: "#fff",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {Capitalize(this.props.match.params.characterName)}
              </h3>
              {checkFor(activePermisision, "Add") ? (
                <Link
                  to={`/project/addactor/${this.props.match.params.characterName}/${this.props.match.params.type}/${this.state.characterId}`}
                >
                  <Button
                    type="primary"
                    icon="plus"
                    style={{ marginLeft: "10px" }}
                  >
                    Add Actor
                  </Button>
                </Link>
              ) : null}

              <Row className="pb-10">
                {this.state.isActorsFetched ? (
                  this.renderActor()
                ) : (
                  <SkeletonLoader />
                )}
              </Row>
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
        <AddActorModal
          isModalVisible={this.state.isModalVisible}
          closeModal={this.closeModal}
        />
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditionComponent);
