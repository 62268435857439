import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Select,
  DatePicker,
  Modal,
} from "antd";
import moment from "moment";

import { amountOnly, StartCase } from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  MenusRoute,
  Services,
  Notifications,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { Link } from "react-router-dom";
import DeleteComponent from "components/shared/DeleteComponent";
import AccPaymentTypeDD from "../AccPaymentTypeDD";
import AccPaymentModeDD from "../AccPaymentModeDD";
import SharedDocumentUpload from "components/shared/SharedDocumentUpload";
import Accounting from "services/AccountingServices";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import InvoiceForm from "components/shared/InvoiceForm";
import TooltipIcon from "components/shared/TooltipIcon";
import GlobalService from "services/GlobalService";
import Axios from "axios";
import VoucherDownloadButton from "components/shared/VoucherDownloadButton";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Loader from "components/shared/Loader";
import PayeeForm from "components/shared/PayeeForm";

const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

const dateFormat = "DD-MM-YYYY";

class AccProductionPurchasePayment extends Component {
  constructor(props) {
    super(props);
    this.account = new Accounting();
    this.api = new GlobalService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
      receiverNameList: [],
      expenseTypeData: [],
      receiverName: null,
      productionAccountId: props.match.params.id || null,
      isAdvance: props.location.state.isAdvance || false,
      hidePaymentType: props.location.state.hidePaymentType || false,
      isInvoiceVisible: false,
      category: props.location.state.category,
      fromTravel: props.location.state.fromTravel || false,
      details: props.location.state.details || false,
      title: props.location.state.title || false,
      activePayment: null,
      paymentTypeList: null,
      paymentModeList: null,
    };
  }

  componentDidMount() {
    const { details, category } = this.state;
    if (details) {
      this.fetchProductionData();
    } else {
      this.fetchData();
    }
    this.getPayee();
    this.getPaymentTypeData();
    this.getPaymentModeData();
    this.fetchExpenseType();
  }

  fetchProductionData = () => {
    const {
      details: {
        productionCategoryDepartment,
        description,
        categoryDepartment,
        department,
      },
      category,
      isAdvance,
    } = this.state;
    const data = {
      productionCategoryDepartment,
      description: encodeURIComponent(description) || "",
      category,
      department:
        productionCategoryDepartment === GlobalConstants.COSTUME ||
        productionCategoryDepartment === GlobalConstants.MAKEUP
          ? GlobalConstants.COSTUME_AND_MAKEUP_CAPS
          : productionCategoryDepartment,
      isAdvance,
    };
    let dataSource = [];
    this.account
      .services(Services.AccountingVariables.GET_PRODUCTION_MORE, data)
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: i,
              payee: data.receiverName || null,
              paymentDate: data.paymentDate,
              paymentType:
                (data.paymentType && data.paymentType.toLowerCase()) || null,
              paymentMode:
                (data.paymentMode && data.paymentMode.toLowerCase()) || null,
              shootDate: data.shootDate,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
          count: res.data?.payments?.length,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  fetchData = () => {
    const { productionAccountId, category } = this.state;
    let dataSource = [];
    this.account
      .services(
        Services.AccountingVariables.GET_PRE_PRODUCTION_MORE,
        category,
        productionAccountId
      )
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: this.state.count,
              payee: data.receiverName || null,
              paymentDate: moment(
                data.paymentDate,
                GlobalConstants.DD_MM_YYYY,
                true
              ).isValid()
                ? data.paymentDate
                : moment(data.paymentDate)
                    // .utc()
                    .format(GlobalConstants.DD_MM_YYYY),
              shootDate: moment(
                data.shootDate,
                GlobalConstants.DD_MM_YYYY,
                true
              ).isValid()
                ? data.shootDate
                : moment(data.shootDate)
                    // .utc()
                    .format(GlobalConstants.DD_MM_YYYY),
              paymentType:
                (data.paymentType && data.paymentType.toLowerCase()) || null,
              paymentMode:
                (data.paymentMode && data.paymentMode.toLowerCase()) || null,
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  fetchExpenseType = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EXPENSE_TYPE
      )
      .then((res) => {
        this.setState({
          expenseTypeData: res.data,
        });
      });
  };

  getPaymentTypeData = () => {
    const { details } = this.state;
    let type = details
      ? GlobalConstants.PRODUCTION_PAYMENT_TYPE
      : GlobalConstants.PRE_PRODUCTION_PAYMENT_TYPE;
    this.api
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, type)
      .then((res) => {
        this.setState({
          paymentTypeList: res.data,
        });
      });
  };

  getPaymentModeData = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        this.setState({
          paymentModeList: res.data,
        });
      });
  };

  getPayee = () => {
    const { category } = this.state;
    this.account
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN, category)
      .then((res) => {
        this.setState({
          receiverNameList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  handleAdd = () => {
    const { count, dataSource, details } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      payeeId: null,
      receiverName: null,
      amountPaid: null,
      paymentMode: null,
      referenceNumber: null,
      documents: [],
      comments: null,
      paymentDate: null,
      count: 0,
      description: details ? details.description : null,
      payee: null,
      isAmountSplit: false,
      expenseType: null,
      // itemQuantity: null,
      shootDate: null,
    };

    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, productionPaymentId }) => {
    const { details } = this.state;
    if (productionPaymentId) {
      this.account
        .services(
          Services.AccountingVariables.DELETE_PRE_PRODUCTION_MORE,
          AccountingVariables.PRE_PRODUCTION,
          productionPaymentId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          if (details) {
            this.fetchProductionData();
          } else {
            this.fetchData();
          }
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  inputChange(value, record, type) {
    this.onChange(value, record, type);
  }

  onChange = (value, record, type) => {
    if (value !== "NaN" && value) {
      record[type] = value;
      record.count = parseInt(record.count);
      this.handleSave(record);
    }
    if (value === "" && type === "billAmount") {
      record[type] = null;
      this.handleSave(record);
    }
  };

  handleInvoiceNumber = (invoiceNumber, invoiceId, record) => {
    record["invoiceNumber"] = invoiceNumber;
    record["invoiceId"] = invoiceId;
    this.handleSave(record);
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  showWarning = (row) => {
    const that = this;
    confirm({
      title: "Payment more than 10,000 will be Splited to Multiple Payments",
      content: "This Action can't be revoke",
      okText: "Ok",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          that.handleSubmit({ ...row, isAmountSplit: true });
          resolve();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        that.handleSubmit({ ...row, isAmountSplit: false });
      },
    });
  };

  handleSubmit = (record) => {
    this.setState({
      isLoading: true,
    });
    const {
      productionAccountId,
      category,
      isAdvance,
      details: { productionCategoryDepartment, categoryDepartment },
    } = this.state;
    const {
      amountPaid,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode,
      paymentType,
      referenceNumber,
      receiverName,
      invoiceId,
      productionPaymentId,
      isAmountSplit,
      expenseType,
      // itemQuantity,
      shootDate,
      billAmount,
    } = record;
    const data = {
      amountPaid,
      category,
      comments,
      description,
      payeeId,
      paymentDate: moment(
        paymentDate,
        GlobalConstants.DD_MM_YYYY,
        true
      ).isValid()
        ? paymentDate
        : moment(paymentDate)
            // .utc()
            .format(GlobalConstants.DD_MM_YYYY),
      invoiceId,
      paymentMode: paymentMode.toUpperCase(),
      paymentType: paymentType && paymentType.toUpperCase(),
      productionAccountId,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      payeeName: receiverName,
      referenceNumber,
      paymentId: productionPaymentId,
      isAmountSplit,
      expenseType,
      // itemQuantity,
      shootDate,
      productionDepartment:
        productionCategoryDepartment === GlobalConstants.COSTUME ||
        productionCategoryDepartment === GlobalConstants.MAKEUP
          ? GlobalConstants.COSTUME_AND_MAKEUP_CAPS
          : productionCategoryDepartment,
      categoryDepartment: productionCategoryDepartment,
      billAmount,
      isAdvance,
    };
    this.account
      .services(Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE, data)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        // showActionMessage(
        //   GlobalConstants.SUCCESS,
        //   GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        // );
        if (this.state.details) {
          this.fetchProductionData();
        } else {
          this.fetchData();
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        // if (categoryDepartment) {
        //   this.fetchProductionData();
        // } else {
        //   this.fetchData();
        // }
      });
  };

  checkForTravelData = (row) => {
    const { fromTravel } = this.state;
    if (fromTravel) {
      return row.expenseType;
      //  && row.itemQuantity;
    } else {
      return true;
    }
  };

  checkForProductionData = (row) => {
    const { details } = this.state;
    if (details) {
      return row.shootDate;
    } else {
      return true;
    }
  };

  checkForReference = (row) => {
    if (row.paymentMode.toUpperCase() === "CASH") {
      return true;
    } else {
      return row.referenceNumber;
    }
  };

  checkForPaymentType = (row) => {
    const { hidePaymentType } = this.state;
    if (hidePaymentType) {
      return true;
    } else {
      return row.paymentType;
    }
  };

  handleSave = (row) => {
    const { details } = this.state;
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // if (row.productionPaymentId) {
    if (
      (row.description || details.description) &&
      row.paymentDate &&
      row.paymentMode &&
      this.checkForPaymentType(row) &&
      (row.receiverName || row.payeeId) &&
      row.amountPaid &&
      (row.invoiceId || row.billAmount) &&
      this.checkForReference(row) &&
      this.checkForTravelData(row)
      // &&
      // this.checkForProductionData(row)
    ) {
      if (
        parseFloat(row.amountPaid).toFixed(2) > 10000.0 &&
        row.paymentMode.toUpperCase() === "CASH"
      ) {
        this.showWarning(row);
      } else {
        this.handleSubmit(row);
      }
    }
    this.setState({ dataSource: newData });
  };

  handleReceiverName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          receiverName: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handlePayee = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    record["receiverName"] = null;
    this.setState({
      receiverName: null,
    });
    this.handleSave(record);
  };

  handleReceiver = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.receiverName);
      if (newData) {
        this.setState({ eventType });
        record.receiverName = newData;
        record.payeeId = null;
        record.payee = newData;
        this.onChange(newData, record, "payee");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  disabledDate = (current, toDate) => {
    // Can not select days before today and today
    if (current) {
      return current > moment();
    }
  };

  handleInvoice = (activePayment) => {
    this.setState({
      activePayment,
      isInvoiceVisible: true,
      activeInvoice: activePayment.invoiceId,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isInvoiceVisible: false,
      activeInvoice: null,
    });
  };

  removeInvoice = (record) => {
    record["invoiceNumber"] = null;
    record["invoiceId"] = null;
    this.handleSave(record);
  };

  downloadVoucher = (vocherNumber) => {
    this.api
      .globalService(Services.GlobalVariables.DOWNLOAD_VOUCHER, {
        // category: GlobalConstants.PRODUCTION_CAPS,
        vocherNumber,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${vocherNumber}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  handlePayeeName = (payee, payeeId, record) => {
    record["payeeName"] = payee;
    record["payee"] = payee;
    record["payeeId"] = payeeId;
    this.handleSave(record);
  };

  handlePayeeModal = (activePayment) => {
    this.setState({
      activePayment,
      isPayeeVisible: true,
      activePayee: activePayment.payeeId,
    });
  };

  handleClosePayeeModal = () => {
    this.setState({
      isPayeeVisible: false,
      activePayee: null,
    });
  };

  removePayee = (record) => {
    record["payee"] = null;
    record["payeeId"] = null;
    record["payeeName"] = null;
    record["receiverName"] = null;
    this.handleSave(record);
  };

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      receiverNameList,
      productionAccountId,
      isInvoiceVisible,
      activePayment,
      activeInvoice,
      category,
      paymentTypeList,
      paymentModeList,
      fromTravel,
      title,
      details,
      hidePaymentType,
      activePayee,
      isPayeeVisible,
    } = this.state;

    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    let columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },

      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: "6%",
        render: (text, record) => (
          <DatePicker
            autoFocus={!record.productionPaymentId && !details}
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "paymentDate")
            }
            className={`${!text ? "err" : ""}`}
            format={dateFormat}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
            disabledDate={(current) => this.disabledDate(current)}
          />
        ),
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "name",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(e.target.value, record, "description")
            }
            onFocus={(e) => {
              this.setState({
                activeValue: e.target.value,
              });
            }}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Invoice",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        width: "5%",
        align: "center",
        render: (text, record) =>
          record.description &&
          !record.billAmount && (
            <>
              {text ? (
                checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
                  <>
                    <a onClick={() => this.handleInvoice(record)}>{text}</a>
                    <TooltipIcon
                      icon="delete"
                      titleText={GlobalConstants.REMOVE_INVOICE}
                      onClick={() => this.removeInvoice(record)}
                    ></TooltipIcon>
                  </>
                ) : (
                  <a>{text}</a>
                )
              ) : (
                <Button
                  onClick={() => this.handleInvoice(record)}
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={
                    !checkFor(
                      activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    ) && record.productionPaymentId
                  }
                >
                  <Icon type="plus" style={{ fontSize: "11px" }} /> Invoice
                </Button>
              )}
            </>
          ),
      },
      {
        title: "Bill Amount",
        dataIndex: "billAmount",
        key: "billAmount",
        width: "8%",
        align: "right",
        render: (text, record) =>
          !record.invoiceNumber && (
            <Input
              type="number"
              className={`${!text ? "err" : ""} upperCase cash`}
              defaultValue={text}
              onBlur={(e) =>
                this.state.activeValue !== e.target.value &&
                this.onChange(e.target.value, record, "billAmount")
              }
              onFocus={(e) => {
                e.currentTarget.select();
                this.setState({ activeValue: e.target.value });
              }}
              style={{ width: "100%" }}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
            />
          ),
      },
      {
        title: "Payee",
        dataIndex: "payee",
        key: "payee",
        width: "5%",
        // render: (text, record) => (
        //   <Select
        //     style={{ width: "100%" }}
        //     showSearch
        //     className={`${!text ? "err" : ""}`}
        //     value={record.payee}
        //     onChange={e => this.handlePayee(e, record, "payeeId")}
        //     onBlur={e => this.handleReceiver(e, record, "blur")}
        //     onSearch={val => this.handleReceiverName(val, record)}
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.productionPaymentId
        //     }
        //   >
        //     {receiverNameList &&
        //       receiverNameList.map(list => {
        //         return <Option value={list.value}>{list.label}</Option>;
        //       })}
        //   </Select>
        // )
        render: (text, record) =>
          record.description &&
          (text ? (
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
              <>
                <a onClick={() => this.handlePayeeModal(record)}>
                  {StartCase(record.payeeName || text)}
                </a>
                <TooltipIcon
                  icon="delete"
                  titleText={GlobalConstants.REMOVE_PAYEE}
                  onClick={() => this.removePayee(record)}
                ></TooltipIcon>
              </>
            ) : (
              <a>{StartCase(record.payeeName || text)}</a>
            )
          ) : (
            <Button
              onClick={() => this.handlePayeeModal(record)}
              style={{ width: "100%" }}
              type="primary"
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
            >
              <Icon type="plus" style={{ fontSize: "11px" }} /> Payee
            </Button>
          )),
      },
      {
        title: "AMOUNT PAID",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: "6%",
        className: "cash",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            type="number"
            defaultValue={text}
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(amountOnly(e), record, "amountPaid")
            }
            style={{ width: "100%" }}
            onFocus={(e) => {
              e.currentTarget.select();
              this.setState({
                activeValue: e.target.value,
              });
            }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        key: "paymentType",
        width: "5%",
        render: (text, record) => (
          <AccPaymentTypeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentType"
            dataSource={paymentTypeList}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Payment Mode",
        dataIndex: "paymentMode",
        key: "paymentMode",
        width: "6%",
        render: (text, record) => (
          <AccPaymentModeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentMode"
            dataSource={paymentModeList}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Reference no",
        dataIndex: "referenceNumber",
        key: "referenceNumber",
        width: "6%",
        render: (text, record) => (
          <Input
            className={`${
              !text &&
              record.paymentMode &&
              record.paymentMode.toUpperCase() !== "CASH"
                ? "err"
                : ""
            } upperCase`}
            defaultValue={text}
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(e.target.value, record, "referenceNumber")
            }
            onFocus={(e) => {
              this.setState({
                activeValue: e.target.value,
              });
            }}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Voucher no",
        dataIndex: "vocherNumber",
        key: "vocherNumber",
        width: "5%",
        render: (text, record) =>
          text && (
            <VoucherDownloadButton
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
              voucherNumber={text}
            />
          ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        width: "10%",
        render: (text, record) => (
          <TextArea
            defaultValue={text}
            placeholder="enter comments"
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(e.target.value, record, "comments")
            }
            onFocus={(e) => {
              this.setState({
                activeValue: e.target.value,
              });
            }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Documents",
        dataIndex: "documents",
        key: "documents",
        width: "3%",
        render: (text, record) =>
          record.productionPaymentId && (
            <SharedDocumentUpload
              category={category}
              iconButton
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
              type={GlobalConstants.PRE_PRODUCTION_DOCUMENT}
              buttonType="primary"
              // key={index}
              refreshData={this.fetchData}
              data={{
                ...record,
                productionPaymentId: record.productionPaymentId,
                paymentDate: moment(record.paymentDate)
                  // .utc()
                  .format(GlobalConstants.DD_MM_YYYY),
              }}
            />
          ),
      },
    ];

    if (hidePaymentType) {
      columns = columns.filter((column) => column.title !== "Payment Type");
    }

    if (details) {
      columns.splice(1, 0, {
        title: "Shoot date",
        dataIndex: "shootDate",
        key: "shootDate",
        width: "6%",
        render: (text, record) => (
          <DatePicker
            autoFocus={!record.productionPaymentId}
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "shootDate")
            }
            // className={`${!text ? "err" : ""}`}
            format={dateFormat}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
            autoFocus={!record.productionPaymentId}
            // disabledDate={current => this.disabledDate(current)}
          />
        ),
      });
      // columns.splice(3, 1);
    }

    if (fromTravel) {
      columns.splice(
        2,
        0,
        ...[
          {
            title: "Expense Type",
            dataIndex: "expenseType",
            align: "center",
            width: "5%",
            render: (text, record) => (
              <Select
                className={`${!text ? "err" : ""} border-none`}
                style={{ width: "100%" }}
                placeholder="Select type"
                onChange={(value) =>
                  this.onChange(value, record, "expenseType")
                }
                defaultValue={text ? text : ""}
                disabled={
                  !checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && record.productionPaymentId
                }
              >
                {this.state.expenseTypeData &&
                  this.state.expenseTypeData.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ),
          },
          // {
          //   title: "Quantity",
          //   dataIndex: "itemQuantity",
          //   key: "itemQuantity",
          //   align: "right",
          //   width: "4%",
          //   className: "cash",
          //   render: (text, record) => (
          //     <Input
          //       className={`${!text ? "err" : ""}`}
          //       type="number"
          //       defaultValue={text}
          //       onBlur={e =>
          //         this.onChange(e.target.value, record, "itemQuantity")
          //       }
          //       style={{ width: "100%" }}
          //     />
          //   )
          // }
        ]
      );
    }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "3%",
        render: (text, record) => (
          <>
            <DeleteComponent
              record={record}
              onClick={(record) => this.handleDelete(record)}
            />
          </>
        ),
      });
    }
    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        {isLoading && <Loader />}
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>
              Accounting - {title ? title : "Production - Purchase Wise"}{" "}
            </TitleText>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                className="flrt"
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ padding: 10 }}
        >
          <Col xl={24} className="accProductionScroll">
            <Table
              bordered
              dataSource={dataSource}
              style={{ width: 1600 }}
              columns={columns}
              pagination={dataSource.length > 10 ? { pageSize: 1000 } : false}
              // pagination={{ pageSize: 1000 }}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
              loading={isLoading}
            />
          </Col>
          {activePayment && (
            <Modal
              destroyOnClose
              className="createModal no-x"
              key={1}
              title="Create Invoice"
              visible={isInvoiceVisible}
              width={"95%"}
              // style={{ maxHeight: "40px" }}
              footer={[
                <Button key="back" onClick={() => this.handleCloseModal()}>
                  Close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => this.child.handleSubmit()}
                >
                  Submit
                </Button>,
              ]}
            >
              <InvoiceForm
                category={category}
                activeInvoice={activeInvoice}
                activePayment={activePayment}
                description={activePayment.description}
                details={details}
                onRef={(ref) => (this.child = ref)}
                handleInvoiceNumber={this.handleInvoiceNumber}
                handleCloseModal={this.handleCloseModal}
              />
            </Modal>
          )}
          {activePayment && (
            <Modal
              destroyOnClose
              className="createModal no-x"
              key={1}
              title="Create Payee"
              visible={isPayeeVisible}
              // width={"95%"}
              // style={{ maxHeight: "40px" }}
              footer={[
                <Button key="back" onClick={() => this.handleClosePayeeModal()}>
                  Close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => this.child.handleSubmit()}
                >
                  Submit
                </Button>,
              ]}
            >
              <PayeeForm
                category={category}
                activePayee={activePayee}
                activePayment={activePayment}
                description={activePayment.description}
                details={details}
                onRef={(ref) => (this.child = ref)}
                handlePayeeName={this.handlePayeeName}
                handleClosePayeeModal={this.handleClosePayeeModal}
              />
            </Modal>
          )}
        </Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccProductionPurchasePayment);
