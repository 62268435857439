import React, { Fragment, useState } from "react";
import { Row, Col, Icon, Popconfirm, Tooltip, Modal, Button } from "antd";
import { GlobalConstants } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import { Capitalize } from "utilities/ValidationHelper";
import TooltipComponent from "./TooltipComponent";

const InvoiceDisplay = ({ files, handleDelete }) => {
  const [ModalVisible, setModalVisible] = useState(false);
  const [ActiveId, setActiveId] = useState(null);
  // let images = [];
  // let docs = [];
  const images =
    files &&
    files.filter(
      (file) => file.fileType.split("/")[0] === GlobalConstants.IMAGE && file
    );
  const docs =
    files &&
    files.filter(
      (file) => file.fileType.split("/")[0] === GlobalConstants.DOCUMENT && file
    );
  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleSelect = (id) => {
    setActiveId(id);
    setModalVisible(true);
  };

  const renderImage = (images) => {
    return images.map((file) => (
      <Col xl={4} className="imageStyleContainer">
        <img
          style={{ height: 150 }}
          className="imageStyle"
          src={`${getScriptAPIURL()}/file/download/${
            file.fileData || file.fileId
          }`}
        />
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(file.documentId)}
        >
          <Tooltip
            title={GlobalConstants.DEL}
            placement={GlobalConstants.RIGHT}
          >
            <a className="deleteIcon">
              <Icon type="close" />
            </a>
          </Tooltip>
        </Popconfirm>
      </Col>
    ));
  };
  const renderDocuments = (docs) => {
    return docs.map((file, i) => (
      <Col
        key={i}
        xl={4}
        className="documentStyleContainer"
        // onClick={() => {
        //   // alert(`${getScriptAPIURL()}/file/download/${file.fileId || file.fileData}`);
        //   handleSelect(file.fileId || file.fileData);
        // }}
      >
        <Col>
          <Col xl={24} className="overlayComponent">
            <Icon
              type="eye"
              style={{ color: "white" }}
              onClick={() => {
                // alert(`${getScriptAPIURL()}/file/download/${file.fileId || file.fileData}`);
                handleSelect(file.fileId || file.fileData);
              }}
            />
            <a
              href={`${getScriptAPIURL()}/file/download/${
                file.fileId || file.fileData
              }`}
            >
              <Icon
                type="download"
                style={{ color: "white", marginLeft: 10 }}
              />
            </a>
          </Col>
          <Col className="documentStyle">
            <div className="desc">
              <TooltipComponent
                text={Capitalize(file.fileName)}
              ></TooltipComponent>
            </div>
            <img
              className="docStyle"
              src={`https://img.icons8.com/cute-clipart/64/000000/document.png`}
            />
            <Popconfirm
              title="Sure to delete?"
              onConfirm={(e) => {
                e.stopPropagation();
                handleDelete(file?.entityId);
              }}
            >
              <Tooltip
                title={GlobalConstants.DEL}
                placement={GlobalConstants.RIGHT}
              >
                <a onClick={(e) => e.stopPropagation()} className="deleteIcon">
                  <Icon type="close" />
                </a>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Col>
      </Col>
    ));
  };

  return (
    <Row className="invoice">
      {images && images.length !== 0 && (
        <Fragment>
          <Col xl={24}>
            <h2>Images</h2>
          </Col>
          <Col xl={24}>{renderImage(images)}</Col>
        </Fragment>
      )}
      {docs && docs.length !== 0 && (
        <Fragment>
          <Col xl={24} style={{ marginTop: images.length === 0 ? 0 : 10 }}>
            <h2>Documents</h2>
          </Col>
          <Col xl={24}>{renderDocuments(docs)}</Col>
        </Fragment>
      )}
      <Modal
        width="100%"
        // title="Basic Modal"
        visible={ModalVisible}
        // onOk={this.handleOk}
        onCancel={handleCancel}
        footer={null}
        className="pdf-modal"
        destroyOnClose
      >
        <div className="pdf-style">
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <Button onClick={handleCancel} type="primary" icon="close"></Button>
          </div>
          <iframe
            className="pdf"
            src={`https://docs.google.com/viewer?url=${getScriptAPIURL()}/file/download/${ActiveId}&embedded=true`}
          ></iframe>
        </div>
      </Modal>
    </Row>
  );
};

export default InvoiceDisplay;
