import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { PriceFormat } from "utilities/ValidationHelper";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

// const data = [
//   {
//     id: "Art Set",
//     label: "Art Set",
//     value: 100000,
//     color: "orange"
//   },
//   {
//     id: "Equipment",
//     label: "Equipment",
//     value: 10000,
//     color: "indianred"
//   },
//   {
//     id: "Costumes",
//     label: "Costumes",
//     value: 18900,
//     color: "tomato"
//   }
//   //   {
//   //     id: "sass",
//   //     label: "sass",
//   //     value: 100,
//   //     // color: "hsl(111, 70%, 50%)"
//   //   },
//   //   {
//   //     id: "php",
//   //     label: "php",
//   //     value: 1000,
//   //     // color: "hsl(122, 70%, 50%)"
//   //   }
// ];

const PieChart = ({
  /* see data tab */
  data,
}) => {
  const colors = [
    "orange",
    "indianred",
    "tomato",
    "hsl(111, 70%, 50%)",
    "hsl(12, 70%, 50%)",
    "hsl(52, 70%, 50%)",
  ];
  data.map((r) => (r.id = r.label));
  data.map((r, i) => (r.color = colors[i]));
  console.log("data pieChartData", JSON.stringify(data));
  const getColor = (bar) => {
    // console.log(data.filter(datum => bar.id === datum.id)[0].color);
    return data.filter((datum) => bar.id === datum.id)[0].color;
  };
  return (
    <ResponsivePie
      // tooltipFormat={value =>
      //   Number(value).toLocaleString("en-US", { minimumFractionDigits: 2 })
      // }
      sortByValue
      tooltipFormat={(value) => PriceFormat(value, true)}
      onClick={(data, event) => console.log(data, event)}
      data={data}
      margin={{ top: 40, right: 100, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      // cornerRadius={3}
      // colors={getColor}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "Art Set",
          },
          id: "dots",
        },
        {
          match: {
            id: "Equipment",
          },
          id: "dots",
        },
        {
          match: {
            id: "Costumes",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "rust",
          },
          id: "dots",
        },
        {
          match: {
            id: "make",
          },
          id: "dots",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "dots",
        },
      ]}
      // legends={[
      //   {
      //     anchor: "right",
      //     direction: "column",
      //     translateY: 56,
      //     itemWidth: 100,
      //     itemHeight: 18,
      //     itemTextColor: "#999",
      //     symbolSize: 18,
      //     symbolShape: "circle",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000"
      //         }
      //       }
      //     ]
      //   }
      // ]}
    />
  );
};
export default PieChart;
