import React, { Component } from "react";
import {
  InfoWindow,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withStateHandlers } from "recompose";
import { Button, Modal, Icon } from "antd";

const Map = compose(
  withStateHandlers(
    () => ({
      isMarkerShown: false,
      markerPosition: null,
    }),
    {
      onMapClick:
        ({ isMarkerShown }) =>
        (e) => ({
          markerPosition: e.latLng,
          isMarkerShown: true,
        }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={18}
    defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    onClick={(e) => {
      props.onMapClick(e);
      props.getLatLongValues(e.ra.x, e.ra.y);
    }}
  >
    {props.isMarkerShown && <Marker position={props.markerPosition} />}
  </GoogleMap>
));

class GoogleLocation extends Component {
  // componentDidMount() {
  //   console.log("status...", this.props.google);
  // }

  state = { visible: false };

  componentDidMount() {
    this.getMyLocation();
  }

  getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.setState(
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            () =>
              this.props.getLatLongValues(
                position.coords.latitude,
                position.coords.longitude
              )
          );
        },
        (error) => {
          this.setState(
            {
              latitude: "err-latitude",
              longitude: "err-longitude",
            },
            () =>
              this.props.getLatLongValues(
                "Please allow location",
                "to continue"
              )
          );
        }
      );
      console.log("location fetched...", this.state.latitude);
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.props.handleModel("map", false);
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.props.handleModel("map", false);
  };

  render() {
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap
        defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
        defaultZoom={13}
      />
    ));

    return (
      <div>
        {/* <Button onClick={this.showModal}>
          <Icon type="environment" />
        </Button> */}
        <Modal
          visible={this.props.mapModel}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="location-modal"
        >
          <div style={{ height: "100%" }}>
            <Map
              // Vishnu->FCMTEST-> map javascript api -> serverrkey
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMyjESK_OQMhwd9vBqEwWh_tHgvqSea9I"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              {...this.props}
              latitude={this.state.latitude}
              longitude={this.state.longitude}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default GoogleLocation;
