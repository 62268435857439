import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class GlobalService {
  globalService(type, data, isType = false) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

    let voucherConfig = {
      responseType: "blob",
    };
    switch (type) {
      case Services.GlobalVariables.GET_IMAGE_ID:
        return axios.post(`${getScriptAPIURL()}/file/upload`, data);
      case Services.GlobalVariables.GET_IMAGE_FROM_ID:
        return axios.get(`${getScriptAPIURL()}/file/download/${data}`);
      case Services.GlobalVariables.GET_GOVT_APPROVAL_DATA:
        return axios.get(`${getScriptAPIURL()}/file/download/${data}`);
      case Services.GlobalVariables.GET_MASTER_DATA:
        return axios.get(
          `${getScriptAPIURL()}/master-data?isType=${isType}&type=${data}`
        );
      case Services.GlobalVariables.GET_WARNING_DATA:
        return axios.get(`${getScriptAPIURL()}/project/warnings`);
      case Services.GlobalVariables.GET_MASTER_DATA_RATE:
        return axios.get(`${getScriptAPIURL()}/master-data?type=${data}`);
      case Services.GlobalVariables.GET_IMAGE_IDS:
        return axios.post(`${getScriptAPIURL()}/files/upload`, data);

      case Services.GlobalVariables.GET_SCENE_NUMBERS:
        return axios.get(`${getScriptAPIURL()}/project/${data}/scenes/detail`);
      case Services.GlobalVariables.GET_SCENE_LOCATION:
        return axios.get(`${getScriptAPIURL()}/project/${data}/scenes/detail`);
      case Services.GlobalVariables.GET_PROJECT_PERCENTAGE:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/completion`
        );
      case Services.GlobalVariables.DOWNLOAD_VOUCHER:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/voucher?contentDisposition=FORM_DATA&vocherNumber=${
            data.vocherNumber
          }`,
          voucherConfig
        );
      case Services.GlobalVariables.DOWNLOAD_SCHEDULER:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scheduler/report?contentDisposition=FORM_DATA`,
          voucherConfig
        );
      case Services.GlobalVariables.PHONE_CODE:
        return axios.get(`${getScriptAPIURL()}/countries`);
      case Services.GlobalVariables.DOWNLOAD_REPORT:
        return axios.get(`${getScriptAPIURL()}/report?id=${data}`);
      default:
        break;
    }
  }
}
