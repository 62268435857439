import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL, getUserAPIURL } from "utilities/APIHelper";

export default class SongsStuntsServices {
  service(type, screen, detailsId = null, data) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )} `,
      },
    };

    switch (type) {
      case Services.SongVariables.GET_SONGS_STUNT_LIST_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/${screen}/scenes`,
          config
        );
      case Services.SongVariables.GET_SONGS_STUNT_DD_LIST:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/songstunt/${screen}?sceneId=${data}`,
          config
        );
      case Services.SongVariables.GET_SONGS_STUNT_DETAIL:
        return axios.get(
          `${getScriptAPIURL()}/scene/${detailsId}/songstunt/${screen}?name=${data}`,
          config
        );
      case Services.SongVariables.GET_SONGS_STUNT_LIST:
        return axios.get(
          `${getScriptAPIURL()}/${screen}/scenes/${localStorage.getItem(
            LocalStorage.ACTIVE_SCENE_ID
          )}/details`,
          config
        );
      case Services.SongVariables.ADD_SONGS_STUNTS:
        return axios.post(
          `${getScriptAPIURL()}/${screen}/scenes/${localStorage.getItem(
            LocalStorage.ACTIVE_SCENE_ID
          )}/details`,
          data,
          config
        );
      case Services.SongVariables.UPDATE_SONGS_STUNTS:
        return axios.put(
          `${getScriptAPIURL()}/${screen}/scenes/details/${detailsId}`,
          data,
          config
        );
      case Services.SongVariables.DELETE_SONGS_STUNTS:
        return axios.delete(
          `${getScriptAPIURL()}/${screen}/scenes/details/${detailsId}`,
          config
        );
      case Services.SongVariables.GET_SONGS_STUNTS_BUDGET:
        return axios.get(
          `${getScriptAPIURL()}/${screen}/scenes/${localStorage.getItem(
            LocalStorage.ACTIVE_SCENE_ID
          )}/budget`,
          config
        );
      case Services.SongVariables.ADD_SONGS_STUNTS_BUDGET:
        return axios.post(
          `${getScriptAPIURL()}/${screen}/scenes/${localStorage.getItem(
            LocalStorage.ACTIVE_SCENE_ID
          )}/budget`,
          data,
          config
        );
      case Services.SongVariables.UPDATE_SONGS_STUNTS_BUDGET:
        return axios.put(
          `${getScriptAPIURL()}/${screen}/scenes/budget/${detailsId}`,
          data,
          config
        );

      default:
        break;
    }
  }
}
