import React, { Component, Fragment } from "react";
import { Row, Col, Form, Input, Select, Button } from "antd";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Lotties,
} from "constants/AppConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  numberOnly,
  handleGoogleResponse,
  checkFor,
} from "utilities/ValidationHelper";
import PaymentTermsTable from "components/PaymentTermsTable";
import Uploaddocumentfile from "components/uploaddocumentfile";
import GlobalService from "services/GlobalService";
import { OnboardServices } from "services";
import FileCard from "components/shared/FileCardComponent";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import RoleServices from "services/RoleServices";
import { GeoServices } from "components/shared/GeoCode/geoCall";
import debounce from "lodash/debounce";
import BudjetService from "services/BudjetService";
import LottieComponent from "components/shared/LottieComponent";
import { handleError } from "utilities/ErrorHandler";
import TextArea from "antd/lib/input/TextArea";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import Loader from "components/shared/Loader";

class VendorUpdate extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._onboardservices = new OnboardServices();
    this._budjetService = new BudjetService();
    this._roleService = new RoleServices();
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
    this.state = {
      data: [],
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      paymentTypeList: null,
      country: null,
      name: [],
      pocName: null,
      phoneNumber: null,
      email: null,
      rumeneration: null,
      address: [],
      // gst: null,
      pan: null,
      phoneCode: "+91",
      roleId: null,
      phoneCodeList: null,
      vendorId: this.props.match.params.vendorId,
      roleDataList: [],
      vendorTypeList: [],
      vendorType: null,
      comment: null,
      isDetailsFetched: false,
      hasData: false,
    };
  }

  componentDidMount() {
    this.getPaymentData();
    this.getCountries();
    this.getVendorDetails();
    this.getVendorType();
    this.handlepayment();
  }

  getVendorDetails() {
    const { vendorId } = this.state;
    if (vendorId) {
      this.setState({
        isDetailsFetched: false,
        hasData: false,
      });
      // showActionMessage(GlobalConstants.SHOW, "Loading Vendor Details");
      this._budjetService
        .budjetService(
          Services.BudjetVariables.GET_VENDOR_BY_ID,
          "",
          this.state.vendorId
        )
        .then((res) => {
          this.setState(
            {
              projectMemberId: res.data.projectMemberId,
              vendorName: res.data.name,
              pocName: res.data.pocName,
              email: res.data.email,
              phoneCode: res.data.phoneCode,
              phoneNumber: res.data.phoneNumber,
              rumeneration: res.data.rumeneration,
              pan: res.data.pan,
              // gst: res.data.gst,
              paymentType: res.data.paymentType,
              addressLine: res.data.address && res.data.address.addressLine,
              city: res.data.address && res.data.address.city,
              state: res.data.address && res.data.address.state,
              country: res.data.address && res.data.address.country,
              zipCode: res.data.address && res.data.address.zipCode,
              fileList: res.data.projectMemberDocument,
              vendorType: res.data.vendorType,
              comment: res.data.comment,
              isDetailsFetched: true,
              hasData: true,
            },
            () => {
              this.splitBasedOnFileTypes(this.state.fileList);
              // showActionMessage(GlobalConstants.HIDE);
            }
          );
        });
    }
  }

  getPaymentData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({ paymentTypeList: res.data });
      });
  };

  getVendorType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.VENDOR_TYPE
      )
      .then((res) => {
        this.setState({ vendorTypeList: res.data });
      });
  };

  handlepayment = (data) => {
    console.log("handlepayment...", data);

    this.setState({
      paymentData: data,
    });
  };

  handleCancel = (e) => {
    this.props.closeModal();
  };

  handleSubmit = (e) => {
    const {
      vendorName,
      pocName,
      // gst,
      addressLine,
      city,
      country,
      state,
      zipCode,
      paymentType,
      rumeneration,
      paymentData,
      phoneCode,
      phoneNumber,
      vendorType,
      vendorId,
      email,
      comment,
    } = this.state;
    const data = {
      name: vendorName,
      pocName,
      // pan,
      // gst,
      email,
      address: {
        addressLine,
        city,
        country,
        state,
        zipCode,
      },
      paymentType,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      rumeneration,
      projectVendorPayment: paymentData,
      phoneCode,
      phoneNumber,
      vendorType,
      vendorId,
      comment,
    };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true,
        });
        this._budjetService
          .budjetService(
            Services.BudjetVariables.CREATE_VENDOR_BY_PROJECT,
            data
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "New Crew Memeber Added Successfully"
            );
            this.setState({
              isLoading: false,
            });
            this.props.history.goBack();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
              handleError(
                // err.response && err.response.data && err.response.data.message
                err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
              )
            );
            this.props.getPermissions();
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  };

  handleUpdate = (e) => {
    const {
      vendorName,
      pocName,
      phoneNumber,
      phoneCode,
      email,
      // pan,
      // gst,
      addressLine,
      city,
      country,
      state,
      zipCode,
      paymentType,
      rumeneration,
      vendorId,
      vendorType,
      comment,
    } = this.state;
    let data = {
      name: vendorName,
      pocName,
      phoneNumber,
      phoneCode,
      email,
      // pan,
      // gst,
      address: {
        addressLine: addressLine,
        city: city,
        country: country,
        state: state,
        zipCode: zipCode,
      },
      paymentType,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      rumeneration,
      vendorId,
      vendorType,
      comment,
    };

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true,
        });
        this._budjetService
          .budjetService(
            Services.BudjetVariables.CREATE_VENDOR_PAYMENT,
            data,
            this.state.vendorId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Vendor Memeber Updated Successfully"
            );
            this.setState({
              isLoading: false,
            });
            this.props.history.goBack();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
              handleError(
                // err.response && err.response.data && err.response.data.message
                err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
              )
            );

            this.props.getPermissions();
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  };

  getCountries = () => {
    this._onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          countryListDatas: res.data.countries,
          phoneCodeList: res.data.countries,
        });
      });
  };

  getDetails = (city, state, country, zipCode) => {
    this.setState({
      city,
      state,
      country,
      zipCode,
    });
  };

  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
    });
    console.log(files);
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  handleDocument = (id) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { fileData: id, fileType: GlobalConstants.DOCUMENT },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ fileData: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };

  handleGeoChange = (e) => {
    console.log("results....", e);
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          console.log("results....", res.data.results[0].address_components);
          const [city, state, country] = handleGoogleResponse(res);
          this.setState({
            city,
            state,
            country,
            zipCode: e,
          });
        })
        .catch((err) => {
          this.setState({
            city: null,
            state: null,
            country: null,
            zipCode: e,
          });
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
          // this.props.getDetails(null, null, null, e);
        });
    }
  };

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState(
      {
        docFiles,
        imageFiles,
      },
      () => console.log("files....", this.state)
    );
  };

  deleteImage = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteImageIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;

    imageFiles.splice(deleteImageIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  deleteDoc = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteDocIndex = this.state.docFiles.findIndex(
      (a) => a.documentId === id
    );

    let docFiles = this.state.docFiles;
    let fileList = this.state.fileList;
    docFiles.splice(deleteDocIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      docFiles,
      fileList,
    });
  };

  getLabel = () => {
    const { vendorType } = this.state;
    let labelText = "*Rumeneration";
    if (vendorType) {
      switch (vendorType) {
        case GlobalConstants.CATERING:
          labelText = "*Per Plate for Catering";
          break;
        default:
          labelText = "*Rumeneration";
          break;
      }
    }
    return labelText;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const {
      vendorName,
      pocName,
      email,
      phoneCode,
      phoneNumber,
      // pan,
      gst,
      zipCode,
      country,
      state,
      city,
      addressLine,
      isLoading,
      rumeneration,
      paymentData,
      paymentType,
      projectMemberId,
      vendorType,
      isDetailsFetched,
      hasData,
      vendorId,
      comment,
    } = this.state;
    const renumerationLabel = this.getLabel();

    const { activePermisision } = this.props;

    const personal = checkFor(
      activePermisision,
      GlobalConstants.PERMISSION_PERSONAL
    );
    const finance = checkFor(
      activePermisision,
      GlobalConstants.PERMISSION_PERSONAL
    );
    const vendorData = {
      name: vendorName,
      pocName,
      // pan,
      gst,
      email,
      address: {
        addressLine,
        city,
        country,
        state,
        zipCode,
      },
      paymentType,
      projectVendorPayment: paymentData,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      rumeneration,
      phoneCode,
      phoneNumber,
      vendorType,
      vendorId,
      comment,
    };

    return (
      <Row type="flex" justify="center">
        {!hasData && vendorId && <Loader />}
        <Col xs={24}>
          <Title hasBack {...this.props}>
            <TitleText>Vendor Profile </TitleText>
          </Title>
        </Col>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          style={{ paddingTop: 20 }}
        >
          <Form onSubmit={this.handleSubmit}>
            {/* <LottieComponent type={Lotties.VENDOR_PERSON} /> */}
            <Row>
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>*Name</label>
                    </div>
                  </Col>

                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("vendorName", {
                        initialValue: vendorName,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter name!",
                          },
                        ],
                      })(
                        <Input
                          type="text"
                          placeholder="Name"
                          onChange={(e) =>
                            this.setState({ vendorName: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {personal && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>*Phone: </label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("phone", {
                          initialValue: phoneNumber,
                          rules: [
                            {
                              required: true,
                              message: "Please input your phone number!",
                            },
                            this.state.phoneCode === "+91" && {
                              pattern: ".{10,}",
                              message: "Enter 10 Numbers Only!",
                            },
                          ],
                        })(
                          <Input
                            addonBefore={
                              <Form.Item
                                className="payment-type"
                                style={{
                                  margin: "0px",
                                  height: "30px",
                                  top: "-4px",
                                }}
                              >
                                {getFieldDecorator("phoneCode", {
                                  initialValue: phoneCode || undefined,
                                })(
                                  <Select
                                    showSearch
                                    defaultValue={this.state.phoneCode}
                                    style={{ width: "80px" }}
                                    optionFilterProp="children"
                                    onChange={(value) =>
                                      this.setState({ phoneCode: value })
                                    }
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toString()
                                        .toLowerCase()
                                        .includes(
                                          input.toString().toLowerCase()
                                        )
                                    }
                                  >
                                    {this.state.phoneCodeList &&
                                      (this.state.phoneCodeList || []).map(
                                        (option, i) => (
                                          <Option value={option.phoneCode}>
                                            {option.phoneCode}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                                )}
                              </Form.Item>
                            }
                            style={{ fontWeight: "bold" }}
                            placeholder=" Phone number"
                            onChange={(e) =>
                              this.setState({ phoneNumber: numberOnly(e) })
                            }
                            maxLength={
                              this.state.phoneCode === "+91" ? 10 : null
                            }
                            minLength={
                              this.state.phoneCode === "+91" ? 10 : null
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {personal && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>*Email</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <div id="email-div">
                        <Form.Item>
                          {getFieldDecorator("email", {
                            initialValue: email || undefined,
                            rules: [
                              {
                                required: true,
                                message: "Please enter your email!",
                              },
                            ],
                          })(
                            <Input
                              style={{ fontWeight: "bold" }}
                              type="email"
                              placeholder="Enter your email "
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
              {/* {personal && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label> PAN </label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("pan", {
                          initialValue: pan,
                          rules: [
                            {
                              // required: true,
                              message: "Please Enter Pan !",
                            },
                            {
                              pattern: new RegExp(
                                "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                              ),
                              message:
                                "Please Enter Correct Pan Format Ex: JSDFG1234H ",
                            },
                          ],
                        })(
                          <Input
                            style={{
                              textTransform: "uppercase",
                            }}
                            type="text"
                            placeholder="Pan No"
                            maxLength={10}
                            onChange={(e) =>
                              this.setState({
                                pan: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )} */}
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>Poc Name</label>
                    </div>
                  </Col>

                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("pocName", {
                        initialValue: pocName,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter POC Name!",
                          },
                        ],
                      })(
                        <Input
                          type="text"
                          placeholder="pocName"
                          onChange={(e) =>
                            this.setState({ pocName: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* {personal && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>GST </label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("gst", {
                          initialValue: gst,
                          rules: [
                            {
                              // required: true,
                              message: "Please Enter Gst !",
                            },
                            {
                              pattern: new RegExp(
                                "[0-3]{1}[0-9]{1}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-2]{1}[Zz]{1}[0-9A-Za-z]{1}"
                              ),
                              message:
                                "Please Enter Correct Gst Format Ex:22AAAAA0000A1Z5",
                            },
                          ],
                        })(
                          <Input
                            style={{
                              textTransform: "uppercase",
                            }}
                            type="text"
                            maxLength={15}
                            // onChange={(value) => {
                            //   this.setState({
                            //     gst: value,
                            //   });
                            // }} 
                            placeholder="Gst No"
                            onChange={(e) =>
                              this.setState({
                                gst: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )} */}
              {/* </Row>
            }
            <Row>
               */}
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                        marginBottom: "30px",
                      }}
                    >
                      <label>*Zip Code</label>
                    </div>
                  </Col>
                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("zipCode", {
                        initialValue: zipCode,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter Zipcode!",
                          },
                          {
                            pattern: new RegExp("^[1-9][0-9]{5}$"),
                            message: "Enter Six Digits Onlys",
                          },
                        ],
                      })(
                        <Input
                          placeholder="enter you pincode"
                          maxLength={6}
                          onChange={(e) => this.handleGeoChange(numberOnly(e))}
                        />
                        // <GeoCode
                        //   defaultValue={this.state.zipCode}
                        //   getDetails={this.getDetails}
                        // />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                        marginBottom: "30px",
                      }}
                    >
                      <label>Country</label>
                    </div>
                  </Col>
                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("country", {
                        initialValue: country || undefined,
                        // rules: [
                        //   {
                        //     required: false,
                        //     message: "Please Enter country!"
                        //   }
                        // ]
                      })(
                        <Select
                          style={{ fontWeight: "bold", color: "black" }}
                          showSearch
                          placeholder="Country"
                          className="w-100"
                          onChange={(value) =>
                            this.setState({ country: value })
                          }
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.countryListDatas &&
                            (this.state.countryListDatas || []).map(
                              (option, i) => (
                                <Option value={option.name}>
                                  {option.name}
                                </Option>
                              )
                            )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                        marginBottom: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      <label>State</label>
                    </div>
                  </Col>
                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("state", {
                        initialValue: state,
                        // rules: [
                        //   {
                        //     required: false,
                        //     message: "Please Enter State!"
                        //   }
                        // ]
                      })(
                        <Input
                          type="text"
                          placeholder="State"
                          onChange={(e) =>
                            this.setState({ state: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      <label>City</label>
                    </div>
                  </Col>
                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("city", {
                        initialValue: city,
                        // rules: [
                        //   {
                        //     required: false,
                        //     message: "Please Enter City!"
                        //   }
                        // ]
                      })(
                        <Input
                          type="text"
                          placeholder="City"
                          onChange={(e) =>
                            this.setState({ city: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>*Address</label>
                    </div>
                  </Col>

                  <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                    <Form.Item>
                      {getFieldDecorator("Address", {
                        initialValue: addressLine,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter Address!",
                          },
                        ],
                      })(
                        <Input
                          type="text"
                          placeholder="Address"
                          onChange={(e) =>
                            this.setState({ addressLine: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {finance && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>*Vendor Type</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("vendorType", {
                          initialValue: vendorType,
                          rules: [
                            {
                              required: true,
                              message: "Please Select Vendor type!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Vendor Type"
                            style={{ width: "100%" }}
                            name="vendorType"
                            onChange={(value) =>
                              this.setState({ vendorType: value })
                            }
                          >
                            {this.state.vendorTypeList &&
                              this.state.vendorTypeList.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {finance && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>{renumerationLabel}</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("salary", {
                          initialValue: rumeneration || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter your Salary!",
                            },
                          ],
                        })(
                          <Input
                            style={{ fontWeight: "bold" }}
                            type="text"
                            placeholder="Rumeneration"
                            onChange={(e) =>
                              this.setState({ rumeneration: numberOnly(e) })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {finance && (
                <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>*Payment Type </label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("paymentType", {
                          initialValue: paymentType,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Payment type!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Payment Type"
                            style={{ width: "100%" }}
                            name="paymentType"
                            onChange={(value) =>
                              this.setState({ paymentType: value })
                            }
                          >
                            {this.state.paymentTypeList &&
                              this.state.paymentTypeList.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {this.state.paymentType === "CONTRACT" ||
            this.state.paymentType === "Contract" ? (
              <Row>
                <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 6 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Payment Terms</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 20 }} lg={{ span: 19 }} md={{ span: 18 }}>
                      <Form.Item>
                        <PaymentTermsTable
                          {...this.props}
                          getFromChild={this.handlepayment}
                          actorId={projectMemberId}
                          rumeneration={this.state.rumeneration}
                          vendorId={this.state.vendorId}
                          isFromVendor={true}
                          vendorData={vendorData}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}

            {/* start */}
            <Row>
              <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                <Row gutter={16}>
                  <Col xl={{ span: 4 }} lg={{ span: 4 }} md={{ span: 4 }}>
                    <div
                      style={{
                        fontWeight: "600",
                        paddingTop: "8px",
                        textAlign: "right",
                      }}
                    >
                      <label>Comments </label>
                    </div>
                  </Col>

                  <Col xl={{ span: 18 }} lg={{ span: 18 }} md={{ span: 18 }}>
                    <Form.Item className="payment-type">
                      {getFieldDecorator("comment", {
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: `GovernmentApproval Status is required.`
                        //   }
                        // ],
                        initialValue: this.state.comment || undefined,
                      })(
                        <TextArea
                          name="comment"
                          placeholder="enter comments"
                          // autoSize={{ minRows: 6, maxRows: 5 }}
                          rows={4}
                          onChange={(e) =>
                            this.setState({ comment: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>

          <Row type="flex" justify="end" style={{ marginTop: "20px" }}>
            <Col
              xl={{ span: 12, offset: 12 }}
              lg={{ span: 12 }}
              md={{ span: 14 }}
            >
              <Col offset={13}>
                <Button
                  onClick={() => this.props.history.goBack()}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
                {this.state.vendorId ? (
                  <Button
                    type="primary"
                    onClick={this.handleUpdate}
                    loading={isLoading}
                    disabled={
                      !checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_EDIT
                      )
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={this.handleSubmit}
                    disabled={
                      !checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_ADD
                      )
                    }
                  >
                    Submit
                  </Button>
                )}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const crewprofile = Form.create()(VendorUpdate);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(crewprofile);
