import { LocalStorage, Services } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class LocationTabService {
  locationTabService(type, data, sceneLocationId) {
    let sceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.SceneOVerviewVariable.GET_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/scene/locations/${data}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_LOCATION_LIST_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scene/locations`,
          config
        );
      case Services.SceneOVerviewVariable.GET_ACTUAL_LOCATION_LIST_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/actual-location-names`,
          config
        );
      case Services.SceneOVerviewVariable.GET_ACTUAL_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scenelocation/${sceneLocationId}`,
          config
        );
      case Services.SceneOVerviewVariable.ADD_LOCATION:
        return axios.post(`${getScriptAPIURL()}/locations`, data, config);
      case Services.SceneOVerviewVariable.UPDATE_LOCATION:
        return axios.post(`${getScriptAPIURL()}/locations`, data, config);
      case Services.SceneOVerviewVariable.DELETE_LOCATION:
        return axios.delete(`${getScriptAPIURL()}/locations/${data}`, config);
      case Services.SceneOVerviewVariable.GET_LOCATION_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/scenelocation/${sceneLocationId}/locations/${data}`,
          config
        );
      case Services.SceneOVerviewVariable.SCENE_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scene/locations`,
          config
        );
      case Services.SceneOVerviewVariable.GET_CONFIRMED_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/scene/locations/${sceneLocationId}?state=CONFIRMED`,
          config
        );
      case Services.SceneOVerviewVariable.GET_EXISTING_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/actual-location-names?name=${data}&sceneLocationId=${sceneLocationId}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_EXISTING_LOCATION_DETAILS:
        return axios.get(`${getScriptAPIURL()}/location/${data}`, config);
      default:
        break;
    }
  }
}
