import React, { Component, Fragment } from "react";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { Form, Row, Col, Select, Checkbox, Collapse } from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import EquipmentServices from "services/EquipmentServices";
import EquipmentProjectService from "services/EquipmentProjectService";
import moment from "moment";
import { EquipmentVariables } from "constants/AppConstants/ServicesConstants";
import { Label, Value } from "components/shared/Label";
import ThemeColors from "constants/ThemeConstants/variables";
import EquipmentTable from "./EquipmentTable";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;
const { Panel } = Collapse;

class EditSpecialEquipmentNew extends Component {
  getEquipmentDropDown = () => {
    const { designationTypeValue } = this.state;
    if (designationTypeValue) {
      this._equipmentService
        .service(
          Services.EquipmentVariables.EQUIPMENT_DROPDOWN,
          null,
          null,
          designationTypeValue
        )
        .then((res) => {
          this.setState({ equipmentDropDown: res.data.equipments });
        });
    }
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._equipmentService = new EquipmentServices();
    this._equipmentProjectService = new EquipmentProjectService();
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);
    this.onChange = this.onChange.bind(this);
    this.columns = [
      {
        title: "#",
        dataIndex: "sno",
        width: "8%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Equipment",
        dataIndex: "equipment",
        align: "center",
        width: "25%",
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        width: "15%",
        align: "center",
        // editable: true
        render: (text, record) => {
          return (
            <Select
              showSearch
              style={{ width: "100%" }}
              className="border-none"
              placeholder="Select type"
              onChange={(value) => this.onChange(value, record, "paymentType")}
              value={text ? text : ""}
              disabled={!record.equipment}
            >
              {this.state.paymentTypeData &&
                this.state.paymentTypeData.map((option, i) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          );
        },
      },
      {
        title: "Rate",
        dataIndex: "price",
        width: "15%",
        editable: true,
        align: "center",
      },
      {
        title: "Is Required",
        dataIndex: "isRequired",
        width: "15%",
        align: "center",
        render: (text, record) => (
          <Checkbox
            onClick={(e) =>
              this.handleRequired(e, record.sceneScheduleEquipmentId)
            }
            checked={text}
            disabled={!record.equipment}
          ></Checkbox>
        ),
      },
    ];

    this.state = {
      dataSource: [],
      count: 0,
      page: null,
      equipmentDropDown: [],
      equipment: null,
      designationTypeValue: null,
      isDisableInput: false,
      equipmentDetails: null,
      shootDate: props.match.params.shootDate,
      equipments: [],
      openPanel: "00",
    };
  }

  componentDidMount() {
    this.getEquipmentDropDown();
    this.getPaymentTypeData();
    this.getStatusData();
    this.checkForEmpty(this.state.dataSource);
    this.fetchData(this.state.shootDate);
  }

  handleRequired = (e, value) => {
    this._equipmentProjectService
      .equipmentProjectService(
        EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT_REQUIRED,
        e.target.checked,
        value
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully!!"
        );
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  getPaymentTypeData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data,
        });
      });
  }

  getStatusData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({
          statusData: res.data,
        });
      });
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();
  designationTypeValue(value) {
    if (value && value.trim().length > 0) {
      this.setState({
        designationTypeValue: value,
      });
    }
    this.getEquipmentDropDown();
  }

  designationBlurChanges(value, record, type) {
    this.getEquipmentDropDown();
    let designation = this.state.designationTypeValue;
    let a = this.state.equipmentDropDown || [];
    let b = a.map((a) => a.name);
    let dx = true;
    if (designation != null) {
      new Promise((resolve) => {
        b.forEach((x) => {
          if (x && x.toString().trim() != designation.toString().trim()) {
            dx = false;
          } else {
            dx = true;
          }
        });

        resolve();
      })
        .then(() => {
          if (!dx) {
            a.push({ label: designation, value: designation });
          }
        })
        .then(() => {
          this.setState({
            equipmentDropDown: a,
          });
        })
        .then(() => {
          if (!this.state.isDisableInput) {
            this.onChange(designation, record, type);
            record.equipment = a;
          }
        });
    }
  }

  fetchData = (date) => {
    this.setState({
      count: 0,
    });
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.GET_SPECIAL_EQUIPMENT_BY_DATE,
        date
      )
      .then((res) => {
        this.setState({
          equipments: res.data.equipments,
        });
      })
      .catch((err) => {
        console.log("special equipment fetch data", err);
      });
  };

  handleUpdate = (record) => {
    const data = {
      paymentType: record.paymentType,
      price: record.price,
    };

    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT,
        data,
        record.sceneScheduleEquipmentId
      )
      .then(() => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully!!"
        );
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleStatus = (value) => {
    const data = {
      shootDate:
        this.state.equipmentDetails &&
        moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY"),
      state: value,
    };
    this._equipmentProjectService
      .equipmentProjectService(
        EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT_STATE,
        data
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully!!"
        );
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        this.checkForEmpty(this.state.dataSource);
      }
    );
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.DELETE_STANDARD_EQUIPMENT,
        null,
        id
      )
      .then((res) => {
        showNotifications(Notifications.SUCCESS, "Successfully", " Deleted !!");
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      equipment: "",
      price: "",
      paymentType: "",
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type) {
    if (value) {
      this.setState({ isDisableInput: true });
    }

    this.handleSave(record, value, type);
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    switch (type) {
      case "equipment":
        if (value) {
          let modifyItem = newData[index];
          modifyItem.equipment = value;
        }
        break;
      case "paymentType":
        if (value) {
          let modifyItem = newData[index];
          modifyItem.paymentType = value;
        }
        break;
      default:
        break;
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
    if (row.sceneScheduleEquipmentId) {
      this.handleUpdate(row);
    }
  };

  renderScenes = (scenes, parentI) => {
    const { openPanel } = this.state;
    return (
      scenes &&
      scenes.map((scene, i) => (
        <Row className="mt-10" type="flex" justify="center">
          <Col md={{ span: 22 }}>
            <Collapse
              accordion
              activeKey={[openPanel]}
              onChange={this.handleAccordionChange}
            >
              <Panel
                header={`Scene Number: ${scene.sceneNumber}`}
                className="accordion_style"
                key={parentI.toString() + i.toString()}
              >
                <EquipmentTable fromLeft sceneId={scene.sceneId} />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ))
    );
  };

  handleAccordionChange = (openPanel) => {
    this.setState({
      openPanel,
    });
  };

  render() {
    const { equipments } = this.state;
    return (
      <Row>
        <Col xs={24} style={{ paddingBottom: 25 }}>
          <Title hasBack {...this.props}>
            <TitleText>Special Equipment </TitleText>
          </Title>
          {equipments.map((scene, i) => (
            <Fragment>
              <Row type="flex" justify="center" className="mt-10">
                <Col xs={22}>
                  <Row>
                    <Col xs={12}>
                      <Label>Scene Location</Label>
                      <Value
                        style={{
                          paddingLeft: 5,
                          color: ThemeColors.primaryColor,
                        }}
                      >
                        {scene.sceneLocation}
                      </Value>
                    </Col>
                    <Col
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Label>Actual Location</Label>
                      <Value
                        style={{
                          paddingLeft: 5,
                          color: ThemeColors.primaryColor,
                        }}
                      >
                        {scene.actualLocation}
                      </Value>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.renderScenes(scene.scenes, i)}
            </Fragment>
          ))}
        </Col>
      </Row>
    );
  }
}

export default Form.create()(EditSpecialEquipmentNew);
