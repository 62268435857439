import React, { Fragment } from "react";
import { Col, Tag, Icon, Button, Modal } from "antd";
import { connect } from "react-redux";

import CustomButton from "../CutomButton";
import {
  GlobalConstants,
  Screens,
  LocalStorage,
  Notifications,
} from "constants/AppConstants";
import { setActiveTab } from "store/action";
import EquipmentProjectService from "services/EquipmentProjectService";
import { EquipmentVariables } from "constants/AppConstants/ServicesConstants";
import { showActionMessage, showNotifications } from "../NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import { StartCase } from "utilities/ValidationHelper";
import { Link } from "react-router-dom";
import TooltipComponent from "../TooltipComponent";

const { confirm } = Modal;

const DashboardStatisticsTemplate = ({
  title,
  data,
  history,
  type,
  setActiveTab,
  refreshData,
  activeTab,
}) => {
  const equipmentApi = new EquipmentProjectService();

  const handleClick = (value, type, name, charType) => {
    switch (type) {
      case GlobalConstants.SCENE_WITH_NO_CHAR_MAP: {
        if (activeTab) {
          history.push("/project/screen-overview");
          localStorage.setItem(GlobalConstants.REDIRECT_TO, activeTab);
          localStorage.setItem(LocalStorage.ACTIVE_SCENE_ID, value);
          setActiveTab("1");
        } else {
          history.push("/project/schedular");
        }
        break;
      }
      case GlobalConstants.LOCATION_WITHOUT_ACTUAL: {
        history.push(`/project/location-audition/${name}/${value}`);
        break;
      }
      case GlobalConstants.CHARACTERS_WITHOUT_CONFIRM: {
        history.push(`/project/audition/${charType}/${value}/${name}`);
        break;
      }
      case GlobalConstants.EQUIPMENTS_WITHOUT_MAP: {
        history.push("/project/equipments");
        localStorage.setItem(GlobalConstants.FROM_DASHBOARD, true);
        break;
      }
    }
  };
  const renderHeader = (value1, value2, type, value3) => {
    switch (type) {
      case GlobalConstants.LOCATION_WITHOUT_ACTUAL:
        return (
          <thead>
            <tr>
              {/* <th style={{ width: "%" }}>#</th> */}
              <th className="font-weight-bold" style={{ width: "80%" }}>
                Location Name
              </th>
              <th className="font-weight-bold" style={{ width: "20%" }}>
                Action
              </th>
            </tr>
          </thead>
        );
      case GlobalConstants.ACTOR_AVAILABILITY:
        return (
          <thead>
            <tr>
              {/* <th style={{ width: "4%" }}>#</th> */}
              <th className="font-weight-bold" style={{ width: "40%" }}>
                {value1}
              </th>
              <th className="font-weight-bold" style={{ width: "28%" }}>
                {value2}
              </th>
              <th className="font-weight-bold" style={{ width: "28%" }}>
                {value3}
              </th>
            </tr>
          </thead>
        );
      case GlobalConstants.LOCATION_AVAILABILITY:
        return (
          <thead>
            <tr>
              {/* <th style={{ width: "4%" }}>#</th> */}
              <th className="font-weight-bold" style={{ width: "35%" }}>
                {value1}
              </th>
              <th className="font-weight-bold" style={{ width: "30%" }}>
                {value2}
              </th>
              <th className="font-weight-bold" style={{ width: "35%" }}>
                {value3}
              </th>
              {/* <th style={{ width: "10%" }}></th> */}
            </tr>
          </thead>
        );
      case GlobalConstants.UNUSED_EQUIPMENTS:
        return (
          <thead>
            <tr>
              {/* <th style={{ width: "%" }}>#</th> */}
              <th className="font-weight-bold" style={{ width: "80%" }}>
                Equipment Name
              </th>
              <th className="font-weight-bold" style={{ width: "20%" }}>
                Action
              </th>
            </tr>
          </thead>
        );
      default:
        return (
          <thead>
            <tr>
              {/* <th style={{ width: "4%" }}>#</th> */}
              <th className="font-weight-bold" style={{ width: "42%" }}>
                {value1}
              </th>
              {/* <th style={{ width: "56%" }}>{value2}</th> */}
              <th className="font-weight-bold" style={{ width: "50%" }}>
                {value2}
              </th>
              <th className="font-weight-bold" style={{ width: "20%" }}>
                {value3}
              </th>
            </tr>
          </thead>
        );
    }
  };

  const renderActors = (actors) =>
    actors &&
    actors.map((actor, i) => (
      <Tag
        onClick={() =>
          history.push(
            `/project/actorprofile/${actor.actorId}/${actor.actorName}/${actor.characterType}/${actor.characterId}`
          )
        }
        color="tomato"
        className="custom-tag"
        style={{ marginBottom: 3 }}
      >
        {actor.actorName}
      </Tag>
    ));
  const renderLocations = (locations) =>
    locations &&
    locations.map((location, i) => (
      <Tag
        onClick={() =>
          history.push(
            `/project/update-location/${location.locationId}/${location.locationName}/${location.sceneLocationName}/${location.sceneLocationId}`
          )
        }
        color="tomato"
        className="custom-tag"
        style={{ marginBottom: 3 }}
      >
        {location.locationName}
      </Tag>
    ));
  const renderSceneLocations = (locations) =>
    locations &&
    locations.map((location, i) => (
      <Tag color="green" style={{ marginBottom: 3, fontSize: "0.6rem" }}>
        {location.sceneLocationName}
      </Tag>
    ));

  const renderScenes = (scenes) =>
    scenes &&
    scenes.map((scene, i) => (
      <Tag color="green" style={{ marginBottom: 3 }}>
        {scene.sceneNumber}
      </Tag>
    ));

  const showDeleteConfirmationModal = (id) => {
    confirm({
      title: "Are you sure delete this Equipment?",
      content: "This Action can't be revoke",
      okText: "Ok",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteEquipment(id);
          resolve();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const deleteEquipment = (id) => {
    equipmentApi
      .equipmentProjectService(EquipmentVariables.DELETE_EQUIPMENT, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        refreshData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  const renderBody = (value1, value2, id, timeType, type, sceneDescription) => {
    let buttonColor = "#ee556a";
    // switch (value1) {
    //   case "sceneNumber": {
    //     buttonColor = "#fd27eb";
    //     break;
    //   }
    //   case "sceneLocationName": {
    //     buttonColor = "#22b9ff";
    //     break;
    //   }
    //   case "characterName": {
    //     buttonColor = "#1dc9b7";
    //     break;
    //   }
    //   case "equipmentName": {
    //     buttonColor = "#ffb822";
    //     break;
    //   }
    // }
    switch (type) {
      case GlobalConstants.LOCATION_WITHOUT_ACTUAL:
        return (
          <tbody>
            {data &&
              data.map((data, i) => (
                <tr className="list">
                  {/* <td></td> */}
                  <td>{data.sceneLocationName}</td>
                  <td>
                    <CustomButton
                      handleClick={() =>
                        handleClick(data[id], type, data.sceneLocationName)
                      }
                      color={buttonColor}
                      history={history}
                    >
                      Go
                    </CustomButton>
                  </td>
                </tr>
              ))}
          </tbody>
        );
      case GlobalConstants.ACTOR_AVAILABILITY:
        return (
          <tbody>
            {data &&
              data.map((data, i) => (
                <tr className="list">
                  {/* <td></td> */}
                  <td>{data.shootDate}</td>
                  <td>{renderActors(data.actors)}</td>
                  <td>{renderScenes(data.scenes)}</td>
                  <td>
                    {/* <CustomButton
                      handleClick={() =>
                        handleClick(data[id], type, data.sceneLocationName)
                      }
                      color={buttonColor}
                      history={history}
                    >
                      Go
                    </CustomButton> */}
                  </td>
                </tr>
              ))}
          </tbody>
        );
      case GlobalConstants.LOCATION_AVAILABILITY:
        return (
          <tbody>
            {data &&
              data.map((data, i) => (
                <tr className="list">
                  {/* <td></td> */}
                  <td>{data.shootDate}</td>
                  <td>{renderLocations(data.locations)}</td>
                  <td>{renderSceneLocations(data.locations)}</td>
                  <td>
                    {/* <CustomButton
                      handleClick={() =>
                        handleClick(data[id], type, data.sceneLocationName)
                      }
                      color={buttonColor}
                      history={history}
                    >
                      Go
                    </CustomButton> */}
                  </td>
                </tr>
              ))}
          </tbody>
        );
      case GlobalConstants.SCENE_WITH_NO_CHAR_MAP:
        return (
          <tbody>
            {data &&
              data.map((data, i) => (
                <tr className="list">
                  {/* <td></td> */}
                  <td>
                    <Tag
                      className={`${data[value2]}${data[timeType]} sceneno-tag`}
                      style={{
                        backgroundColor: "#ccc",
                        boxShadow: "none",
                      }}
                    >
                      {/* <a
                        style={{
                          color: "white",
                        }}
                      >
                        {data[value1]}
                      </a> */}
                      <Link
                        style={{
                          color: "white",
                        }}
                      >
                        <TooltipComponent
                          sceneNumber={data[value1]}
                          text={data[sceneDescription]}
                          placement="left"
                        />
                      </Link>
                    </Tag>
                  </td>
                  <td>
                    {StartCase(data[timeType])}/{StartCase(data[value2])}
                  </td>
                  <td style={{ paddingRight: 5 }}>
                    <CustomButton
                      handleClick={() =>
                        handleClick(data[id], type, data[value1], data[value2])
                      }
                      history={history}
                      color={buttonColor}
                    >
                      Go
                    </CustomButton>
                  </td>
                </tr>
              ))}
          </tbody>
        );

      case GlobalConstants.UNUSED_EQUIPMENTS:
        return (
          <tbody>
            {data &&
              data.map((data, i) => (
                <tr className="list">
                  <td>{StartCase(data[value1])}</td>
                  <td>
                    {/* <CustomButton
                      handleClick={() =>
                        handleClick(data[id], type, data[value1], data[value2])
                      }
                      history={history}
                      color={buttonColor}
                    >
                      Go
                    </CustomButton> */}
                    <Button
                      onClick={() => showDeleteConfirmationModal(data[value2])}
                      type="primary"
                    >
                      <Icon type="delete" />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        );
      default:
        return (
          <tbody>
            {data &&
              data.map((data, i) => (
                <tr className="list">
                  {/* <td></td> */}
                  <td>{StartCase(data[value1])}</td>
                  <td>{StartCase(data[value2])}</td>
                  <td style={{ paddingRight: 5 }}>
                    <CustomButton
                      handleClick={() =>
                        handleClick(data[id], type, data[value1], data[value2])
                      }
                      history={history}
                      color={buttonColor}
                    >
                      Go
                    </CustomButton>
                  </td>
                </tr>
              ))}
          </tbody>
        );
    }
  };

  const renderLists = (type) => {
    switch (type) {
      case GlobalConstants.SCENE_WITH_NO_CHAR_MAP:
        return (
          <Fragment>
            {renderHeader(
              "Scene Number",
              "Time / Environment",
              false,
              "Action"
            )}
            {renderBody(
              "sceneNumber",
              "environmentType",
              "sceneId",
              "timeType",
              GlobalConstants.SCENE_WITH_NO_CHAR_MAP,
              "description"
            )}
          </Fragment>
        );
      case GlobalConstants.LOCATION_WITHOUT_ACTUAL:
        return (
          <Fragment>
            {renderHeader(
              "Location Name",
              null,
              GlobalConstants.LOCATION_WITHOUT_ACTUAL
            )}
            {renderBody(
              "sceneLocationName",
              null,
              "sceneLocationId",
              true,
              GlobalConstants.LOCATION_WITHOUT_ACTUAL
            )}
          </Fragment>
        );
      case GlobalConstants.CHARACTERS_WITHOUT_CONFIRM:
        return (
          <Fragment>
            {renderHeader("Character Name", "Character Type", false, "Action")}
            {renderBody(
              "characterName",
              "characterType",
              "characterId",
              null,
              GlobalConstants.CHARACTERS_WITHOUT_CONFIRM
            )}
          </Fragment>
        );
      case GlobalConstants.EQUIPMENTS_WITHOUT_MAP:
        return (
          <Fragment>
            {renderHeader("Equipment Name", "Equipment Type", false, "Action")}
            {renderBody(
              "equipmentName",
              "equipmentResourcetype",
              "equipmentId",
              null,
              GlobalConstants.EQUIPMENTS_WITHOUT_MAP
            )}
          </Fragment>
        );
      case GlobalConstants.ACTOR_AVAILABILITY:
        return (
          <Fragment>
            {renderHeader(
              "Shooting Data",
              "Actors",
              GlobalConstants.ACTOR_AVAILABILITY,
              "Scenes"
            )}
            {renderBody(
              "equipmentName",
              "equipmentResourcetype",
              "equipmentId",
              null,
              GlobalConstants.ACTOR_AVAILABILITY
            )}
          </Fragment>
        );
      case GlobalConstants.LOCATION_AVAILABILITY:
        return (
          <Fragment>
            {renderHeader(
              "Shooting Data",
              "Location",
              GlobalConstants.LOCATION_AVAILABILITY,
              "Scenes"
            )}
            {renderBody(
              "equipmentName",
              "equipmentResourcetype",
              "equipmentId",
              null,
              GlobalConstants.LOCATION_AVAILABILITY
            )}
          </Fragment>
        );
      case GlobalConstants.UNUSED_EQUIPMENTS:
        return (
          <Fragment>
            {renderHeader(null, null, GlobalConstants.UNUSED_EQUIPMENTS)}
            {renderBody(
              "equipmentName",
              "projectEquipmentId",
              null,
              null,
              GlobalConstants.UNUSED_EQUIPMENTS
            )}
          </Fragment>
        );
    }
  };

  return (
    <Col xs={8} style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}>
      <div className="dashboard-statistics-container">
        <div className="dashboard-statistics-container-header">
          <div className="dashboard-statistics-head">
            <span className="dashboard-statistics-head-title">{title}</span>
          </div>
        </div>
        <div className="dashboard-statistics-container-body">
          <div className="body-container map-char-with-scene">
            <table style={{ width: "100%" }} className="list-container">
              {renderLists(type)}
            </table>
          </div>
        </div>
      </div>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(null, mapDispatchToProps)(DashboardStatisticsTemplate);
