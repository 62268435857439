import React, { Component, Fragment } from "react";
import { Table, Button, Row, Col, Icon, Input, Select, DatePicker } from "antd";
import DeleteComponent from "components/shared/DeleteComponent";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";

import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  checkFor,
  StartCase,
  numberOnly,
  amountOnly,
} from "utilities/ValidationHelper";
import { getPermissions } from "store/thunk-actions";
import TransportService from "services/TransportService";
import InvoiceService from "services/InvoiceService";
import GlobalService from "services/GlobalService";
import Loader from "components/shared/Loader";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import AttendanceTable from "./AttendanceTable";

const { Option, OptGroup } = Select;

class AttendanceMain extends Component {
  constructor(props) {
    super(props);
    this._transportService = new TransportService();
    this._invoicesService = new InvoiceService();
    this._globalService = new GlobalService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      shootDate: props.match.params.shootDate,
      locationName: props.location.state.locationName,
      actorCrewList: [],
      dropLocationList: [],
      driverList: [],
      isLoading: true,
      loadingDriverDetails: false,
    };
  }

  renderList = () =>
    ["Crew List", "Actor List", "Junior List"].map((ele, i) => (
      <Col xl={8} className="attendanceCardContainer">
        <Col className="attendanceCard">
          <AttendanceTable
            page={ele}
            shootDate={this.props.match.params.shootDate}
          />
        </Col>
      </Col>
    ));

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      driverList,
      loadingDriverDetails,
    } = this.state;
    const { activePermisision } = this.props;

    const { locationName } = this.state;

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        {loadingDriverDetails && <Loader />}
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>
              Attendance - Shoot date - {this.props.match.params.shootDate} -{" "}
              {locationName}
            </TitleText>
          </Title>
        </Row>
        <Row style={{ padding: 10 }}>{this.renderList()}</Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.TRANSPORT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceMain);
