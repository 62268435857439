import React, { Component, Fragment } from "react";
import {
  Table,
  Tag,
  Button,
  Row,
  Col,
  Divider,
  Modal,
  Icon,
  Input,
  Select,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  Services,
  GlobalConstants,
  LocalStorage,
  Screens,
  MenusRoute,
  Notifications,
} from "constants/AppConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import {
  checkFor,
  Capitalize,
  PriceFormat,
  StartCase,
} from "utilities/ValidationHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import AccountingService from "services/AccountingServices";
import GlobalService from "services/GlobalService";
import AccPaymentTypeDD from "../AccPaymentTypeDD";
import AccPaymentModeDD from "../AccPaymentModeDD";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import moment from "moment";
import DeleteComponent from "components/shared/DeleteComponent";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const { confirm } = Modal;
const { TextArea } = Input;

const dateFormat = GlobalConstants.DD_MM_YYYY;
class AccProductionDataMore extends Component {
  constructor(props) {
    super(props);

    this._globalService = new GlobalService();
    this._accountingService = new AccountingService();

    this.state = {
      dataSource: [],
      totalRecords: [],
      contractDataSource: [],
      isLoading: true,
      description: props.match.params.description,
      productionCategoryDepartment: props.match.params.categoryDepartment,
      paymentTypeData: [],
      paymentModeData: [],
      payeeNameList: [],
      invoices: [],
      count: 0,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchPaymentMode();
    this.fetchPaymentType();
    this.getPayee();
    this.getInvoiceIds();
  }

  getPayee = () => {
    this._accountingService
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN)
      .then((res) => {
        this.setState({
          payeeNameList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  handleSave = (row, type = false) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (
      row.description &&
      row.paymentDate &&
      row.paymentMode &&
      row.paymentType &&
      (row.payeeName || row.payeeId) &&
      row.amountPaid &&
      row.shootDate
    ) {
      // if (type === "amountPaid" || type === "paymentMode") {
      if (row.amountPaid > 10000 && row.paymentMode === "CASH") {
        this.showWarning(row);
      } else {
        this.handleSubmit(row);
      }
      // } else {
      //   this.handleSubmit(row);
      // }
    }
    this.setState({ dataSource: newData });
  };

  handleSubmit = (record) => {
    const { description, productionCategoryDepartment, productionPaymentId } =
      this.state;

    const data = {
      ...record,
      description,
      categoryDepartment: productionCategoryDepartment,
      category: AccountingVariables.PRODUCTION,
    };
    delete data.key;
    delete data.count;
    this._accountingService
      .services(Services.AccountingVariables.SAVE_PRODUCTION_DATA_MORE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource, productionCategoryDepartment, description } =
      this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      payeeId: null,
      payeeName: null,
      amountPaid: null,
      categoryDepartment: productionCategoryDepartment,
      comments: null,
      description,
      invoiceId: null,
      isAmountSplit: null,
      payeeId: null,
      payeeName: null,
      paymentDate: null,
      paymentId: null,
      paymentMode: null,
      paymentType: null,
      shootDate: null,
      payee: null,
    };

    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, paymentId }) => {
    if (paymentId) {
      this._accountingService
        .services(
          Services.AccountingVariables.DELETE_PRE_PRODUCTION_MORE,
          AccountingVariables.PRE_PRODUCTION,
          paymentId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  handleDD = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    this.handleSave(record);
  };

  onChange = (value, record, type) => {
    record[type] = value;
    record.count = parseInt(record.count);
    this.handleSave(record, type);
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  handleReceiverName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          payeeName: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handlePayee = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    record["payeeName"] = null;
    this.setState({
      payeeName: null,
    });
    this.handleSave(record);
  };

  handleReceiver = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.payeeName);
      if (newData) {
        this.setState({ eventType });
        record.payeeName = newData;
        record.payeeId = null;
        record.payee = newData;
        this.onChange(newData, record, "payee");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  fetchPaymentType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data || [],
        });
      });
  };

  fetchPaymentMode = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        this.setState({
          paymentModeData: res.data || [],
        });
      });
  };

  fetchData = () => {
    const { productionCategoryDepartment, description } = this.state;
    const data = {
      productionCategoryDepartment,
      category: AccountingVariables.PRODUCTION,
      description,
    };
    let dataSource = [];
    this._accountingService
      .services(
        Services.AccountingVariables.GET_PRODUCTION_DATA_WISE_MORE,
        data
      )
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: this.state.count,
              payee: data.payeeName,
              // paymentType: data.paymentType.toLowerCase(),
              // paymentMode: data.paymentMode.toLowerCase()
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState(
          {
            dataSource,
            totalRecords: dataSource,
            isLoading: false,
          },
          () => console.log("this.state.dataSource", this.state.dataSource)
        );
      })
      .catch((err) =>
        this.setState({
          isLoading: false,
        })
      );
  };

  getInvoiceIds = () => {
    const { productionCategoryDepartment, description } = this.state;
    const data = {
      productionCategoryDepartment,
      category: AccountingVariables.PRODUCTION,
      description,
    };
    this._accountingService
      .services(Services.AccountingVariables.GET_PRODUCTION_INVOICES, data)
      .then((res) => {
        this.setState({
          invoices: res.data,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: true,
        })
      );
  };

  showWarning = (row) => {
    const that = this;
    confirm({
      title: "Payment more than 10,000 will be Splited to Multiple Payments",
      content: "This Action can't be revoke",
      okText: "Ok",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          that.handleSubmit({ ...row, isAmountSplit: true });
          resolve();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        that.handleSubmit({ ...row, isAmountSplit: false });
      },
    });
  };

  handleInvoice = (value, record) => {
    const { invoices } = this.state;
    let invoiceNumber = null;
    invoices.map((invoice) => {
      if (invoice.value === value) {
        record["invoiceId"] = value;
        record["invoiceNumber"] = invoice.label;
      }
    });
    this.handleSave(record);
  };

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      description,
      payeeNameList,
      invoices,
    } = this.state;
    const { activePermisision, getPermissions } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const dataColumn = [
      {
        title: "#",
        key: "key",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Shoot date",
        dataIndex: "shootDate",
        key: "shootDate",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <DatePicker
            autoFocus
            className={`${!text ? "err" : ""}`}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "shootDate")
            }
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
          />
        ),
      },
      {
        title: "Invoice amount",
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
        align: "right",
        width: "10%",
      },
      {
        title: "Invoice number",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            showSearch
            value={text}
            onChange={(e) => this.handleInvoice(e, record)}
            // onBlur={e => this.handleReceiver(e, record, "blur")}
            // onSearch={val => this.handleReceiverName(val, record)}
          >
            {invoices &&
              invoices.map((list) => {
                return <option value={list.value}>{list.label}</option>;
              })}
          </Select>
        ),
      },
      {
        title: "Payee",
        dataIndex: "payee",
        key: "payee",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            showSearch
            className={`${!text ? "err" : ""}`}
            value={record.payee}
            onChange={(e) => this.handlePayee(e, record, "payeeId")}
            onBlur={(e) => this.handleReceiver(e, record, "blur")}
            onSearch={(val) => this.handleReceiverName(val, record)}
          >
            {payeeNameList &&
              payeeNameList.map((list) => {
                return <option value={list.value}>{list.label}</option>;
              })}
          </Select>
        ),
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        key: "paymentType",
        width: "9%",
        render: (text, record) => (
          <AccPaymentTypeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentType"
            likeBudget
          />
        ),
      },
      {
        title: "Payment mode",
        dataIndex: "paymentMode",
        key: "paymentMode",
        width: "9%",
        render: (text, record) => (
          <AccPaymentModeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentMode"
          />
        ),
      },
      {
        title: "Amount paid",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""} cash`}
            type="number"
            defaultValue={text}
            style={{ width: "100%" }}
            onBlur={(e) => this.onChange(e.target.value, record, "amountPaid")}
          />
        ),
      },
      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: "10%",
        render: (text, record) => (
          <DatePicker
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "paymentDate")
            }
            className={`${!text ? "err" : ""}`}
          />
        ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        width: "15%",
        render: (text, record) => (
          <TextArea
            placeholder="enter comments"
            defaultValue={text}
            onBlur={(e) => this.onChange(e.target.value, record, "comments")}
          />
        ),
      },

      // {
      //   title: "Status",
      //   dataIndex: "budgetState",
      //   key: "address",
      //   align: "center",
      //   render: (value, record) => (
      //     <Tag className={`statusTag ${record.budgetStateEnum}`}>
      //       {record.budgetState}
      //     </Tag>
      //   )
      // },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      dataColumn.push({
        title: "",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "3%",
        render: (text, record) => (
          <>
            <DeleteComponent
              record={record}
              onClick={(record) => this.handleDelete(record)}
            />
            {/* <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
            <Link to={`${MenusRoute.ACCOUNTING}/pre-production/more`}>
              <Icon type="edit" />
            </Link> */}
          </>
        ),
      });
    }

    const columns = dataColumn;
    console.log("dataSource", dataSource);
    return (
      <Fragment>
        <Row>
          <Col xl={24}>
            <Title hasBack {...this.props}>
              <TitleText>Description - {description}</TitleText>
            </Title>
          </Col>
        </Row>
        <Row style={{ padding: 10 }} type="flex" justify="center">
          <Col xl={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24}>
            <Table
              rowKey="key"
              bordered
              loading={isLoading}
              dataSource={dataSource}
              columns={columns}
              // pagination={{ pageSize: 1000 }}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccProductionDataMore);
