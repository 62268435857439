import React, { Fragment } from "react";
import { Popconfirm, Button, Tooltip } from "antd";
import { GlobalConstants } from "constants/AppConstants";

const DeleteComponent = ({ onClick, record = false }) => {
  return (
    <Fragment>
      <Popconfirm
        disabled={record.isEnable === false}
        title="Sure to delete?"
        onConfirm={() => onClick(record)}
      >
        <Tooltip title={GlobalConstants.DEL} placement={GlobalConstants.RIGHT}>
          <Button
            icon="delete"
            type="link"
            disabled={record.isEnable === false}
          />
        </Tooltip>
      </Popconfirm>
    </Fragment>
  );
};

export default DeleteComponent;
