import Resizer from "react-image-file-resizer";

const ResizeFile = async (file, type = "base64") => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1280,
      854,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      type
    );
  });
};

export default ResizeFile;
