import React, { useState, useEffect, Component } from "react";
import { Value } from "./Label";
// import { Progress } from "antd";

// import ThemeColors from "constants/ThemeConstants/variables";

// const ProgressBar = ({ Percent }) => {
//   const [PercentValue, setPercent] = useState(0);

//   // useEffect(() => {
//   //   setTimeout(() => {
//   //     setPercent(90);
//   //   }, 2000);
//   // }, []);

//   useEffect(() => {
//     let temp = 0;
//     setInterval(() => {
//       if (temp < Percent) {
//         temp = temp + 1;
//         setPercent(temp);
//       }
//     }, 10);
//   }, [Percent]);

//   return (
//     <Progress
//       // type="circle"
//       status="active"
//       showInfo
//       strokeColor={{
//         "0%": ThemeColors.darkGrey,
//         "100%": ThemeColors.primaryColor
//       }}
//       format={percent => `${percent}% Completed`}
//       percent={PercentValue}
//       width={200}
//     />
//   );
// };

// export default ProgressBar;

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    const progressInit = 0;
    const goalInit = 100;
    this.state = {
      progress: progressInit,
      validProgress: props.Percent,
      goal: goalInit,
      validGoal: goalInit,
    };

    this.changeProgress = this.changeProgress.bind(this);
    this.changeGoal = this.changeGoal.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   const { Percent } = nextProps;
  //   if (Percent > 0) {
  //     this.setState({
  //       validProgress: Percent
  //     });
  //   }
  // }

  changeProgress(event) {
    if (event.target.value) {
      this.setState({ progress: event.target.value });
      this.setState({ validProgress: event.target.value });
    } else {
      this.setState({ progress: event.target.value });
    }
  }

  changeGoal(event) {
    if (event.target.value) {
      this.setState({ goal: event.target.value });
      this.setState({ validGoal: event.target.value });
    } else {
      this.setState({ goal: event.target.value });
    }
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <div className="progress-container">
          <ProgressBarComponent
            progress={this.state.validProgress}
            goal={this.state.validGoal}
          />
        </div>
        {/* <Value>{this.state.validProgress}% Completed</Value> */}
      </div>
    );
  }
}

class ProgressBarComponent extends Component {
  calculateProgress(progress, goal) {
    if (Number(progress) === 0) {
      return 0.75 + "%";
    }
    if (Number(goal) >= Number(progress)) {
      return (progress / goal) * 100 + "%";
    } else {
      return 100 + "%";
    }
  }

  render() {
    const { progress, goal } = this.props;
    return (
      <div
        className="progress-bar"
        style={{ width: this.calculateProgress(progress, goal) }}
      ></div>
    );
  }
}

export default ProgressBar;
