import React, { Component, Fragment } from "react";
import { Modal, Button, Row, Input, Col, Select, Form } from "antd";
import { connect } from "react-redux";
import { startCase } from "lodash";

import "./CreateProject.css";
import UploadImage from "components/shared/UploadImage/UploadImage";
import Axios from "axios";
import { Notifications, GlobalConstants } from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import moment from "moment";
import { handleError } from "utilities/ErrorHandler";
import AddButton from "components/shared/AddButton";
import { getScriptAPIURL } from "utilities/APIHelper";

const { Option } = Select;

class CreateProjectModal extends Component {
  state = {
    isLoading: false,
    uploadingImage: false,
    projectName: null,
    projectTypeId: null,
    projectDescription: null,
    projectimageId: null,
    companyId: 1,
    projectDropDown: null,
    todayDate: null,
    futuredate: null,
    imageUrl: null,
  };

  componentDidMount() {
    this.getProject();
  }

  componentWillReceiveProps(nextProps) {
    const { activeProject, isEditPage } = nextProps;
    if (activeProject) {
      this.setState({
        activeProject,
        visible: true,
        imageUrl: activeProject?.projectImage?.url,
      });
    } else if (isEditPage) {
      this.setState({
        activeProject,
        visible: false,
        imageUrl: null,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
      isLoading: false,
      imageUrl: null,
    });
  };

  handleCancel = (e) => {
    const { removeActiveProject } = this.props;
    this.setState({
      visible: false,
      isLoading: false,
      activeProject: null,
      imageUrl: null,
    });
    removeActiveProject && removeActiveProject();
  };

  handleImage = (projectimageId) => {
    this.setState({
      projectimageId,
    });
  };

  getProject = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.props.accessToken}`,
      },
    };
    Axios.get(
      `${getScriptAPIURL()}/master-data?type=PROJECT_TYPE`,
      config
    ).then((res) => {
      this.setState({
        projectDropDown: res.data,
      });
    });
  };

  handleUpdate = () => {
    this.props.form.validateFields((err) => {
      if (err == null) {
        const { updateProject } = this.props;
        const {
          projectName,
          projectTypeId,
          projectDescription,
          activeProject: { projectId },
          projectimageId,
        } = this.state;
        const data = {
          name: startCase(projectName || this.state.activeProject.projectName),
          projectTypeId:
            projectTypeId || this.state.activeProject.projectTypeId,
          description:
            projectDescription || this.state.activeProject.projectDescription,
          projectimageId: projectimageId || this.state.projectimageId,
        };

        updateProject(projectId, data);
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const config = {
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
          },
        };
        const data = {
          projectName: startCase(this.state.projectName),
          projectTypeId: this.state.projectTypeId,
          projectDescription: this.state.projectDescription,
          projectimageId: this.state.projectimageId,
          companyId: this.state.companyId,
        };
        Axios.post(`${getScriptAPIURL()}/projects`, data, config)
          .then((response) => {
            this.setState({ isLoading: false, visible: false, imageUrl: null });
            showNotifications(
              Notifications.SUCCESS,
              "Project Created",
              "Get your work simplified!!"
            );
            this.props.refreshProjectList();
            this.setState(
              {
                todayDate: new Date(),
              },
              () => {
                this.setState({
                  futuredate: new Date(+this.state.todayDate + 30 * 86400000),
                });
              }
            );
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            showNotifications(
              Notifications.ERROR,
              "Failed",
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
              handleError(
                // err.response && err.response.data && err.response.data.message
                err.response &&
                  err.response.data.errors[0] &&
                  err.response.data.errors[0].message
              )
            );
          });
      }
    });
  };

  render() {
    const { TextArea } = Input;
    const { getFieldDecorator } = this.props.form;
    const { isLoading, projectTypeId, visible, activeProject, uploadingImage } =
      this.state;
    const hasProject = activeProject ? true : false;
    const { currentUser } = this.props;
    return (
      <Fragment>
        {currentUser &&
          GlobalConstants.CREATE_PROJECT.includes(currentUser?.roleName) && (
            <AddButton type="primary" onClick={this.showModal} text="Project" />
          )}
        <Modal
          destroyOnClose
          className="createModal"
          key={1}
          title={hasProject ? "Update Project" : "Create Project"}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={
            <Button
              htmlType="submit"
              type="primary"
              onClick={(e) =>
                hasProject ? this.handleUpdate() : this.handleSubmit(e)
              }
              loading={isLoading || uploadingImage}
            >
              {hasProject ? "Update" : "Create"}
            </Button>
          }
          width={"450px"}
          style={{ maxHeight: "40px" }}
        >
          {/* <h3
            className="font-600 mb-20"
            style={{ textAlign: "center", color: "#ee556a" }}
          >
            Create Project
          </h3> */}
          <Row>
            <Col>
              <Form
              // onSubmit={!hasProject ? this.handleSubmit : this.handleUpdate}
              >
                <Row gutter={16} className="mt-5">
                  <Col xl={{ span: 12 }}>
                    <label className="font-600" label="Username">
                      *Project Name :
                    </label>
                    <Form.Item>
                      {getFieldDecorator("projectName", {
                        rules: [
                          {
                            required: true,
                            message: "Enter your project Name!",
                          },
                        ],
                        initialValue: hasProject
                          ? activeProject.projectName
                          : null,
                      })(
                        <Input
                          // maxLength="100"
                          placeholder="Project Name"
                          autoFocus
                          onChange={(e) =>
                            this.setState({ projectName: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 12 }}>
                    <label className="font-600">*Project Type </label>
                    <Form.Item>
                      {getFieldDecorator("projectTypeId", {
                        initialValue: hasProject
                          ? activeProject.projectType
                          : null,
                        rules: [
                          {
                            required: true,
                            message: "Select your project Type!",
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          placeholder="Select Project Type"
                          className="w-100"
                          onChange={(value) =>
                            this.setState({ projectTypeId: value })
                          }
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={this.state.projectTypeId}
                        >
                          {this.state.projectDropDown &&
                            (this.state.projectDropDown || []).map(
                              (option, i) => (
                                <Option key={i} value={option.value}>
                                  {option.label}
                                </Option>
                              )
                            )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <label className="font-600">*Project Description </label>
                  <Form.Item>
                    {getFieldDecorator("projectDescription", {
                      initialValue: hasProject
                        ? activeProject.projectDescription
                        : null,
                      rules: [
                        {
                          required: true,
                          message: "Enter your project Description!",
                        },
                      ],
                    })(
                      <TextArea
                        className="mt-5"
                        rows={4}
                        onChange={(e) =>
                          this.setState({ projectDescription: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Row>

                <Row>
                  <label className="font-600">Project Image</label>
                  <div style={{ display: "flex" }}>
                    {this.state.imageUrl && (
                      <img
                        alt="project"
                        src={this.state.imageUrl}
                        style={{
                          height: 94,
                          width: 94,
                          objectFit: "cover",
                          marginRight: 10,
                          marginTop: 10,
                        }}
                      />
                    )}

                    <div className="mt-5">
                      <Form.Item>
                        {getFieldDecorator("projectimageId", {
                          initialValue:
                            (activeProject && activeProject.projectimageId) ||
                            this.state.projectimageId,
                        })(
                          <UploadImage
                            handleImage={this.handleImage}
                            handleResponse={(e) => {
                              this.setState({ imageUrl: e?.url });
                            }}
                            projectimageId={
                              activeProject && activeProject.projectimageId
                            }
                            text="Upload"
                            showPreview={true}
                            uploading={(e) =>
                              this.setState({
                                uploadingImage: e,
                              })
                            }
                            onChange={(e) => {
                              this.setState({ projectimageId: e.target.value });
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

const CreateProject = Form.create()(CreateProjectModal);

const mapStateToProps = ({ user }) => {
  return {
    currentUser: user.currentUser,
    accessToken: user.accessToken,
  };
};

export default connect(mapStateToProps)(CreateProject);
