import React, { Component } from "react";
import CreateCharacter from "components/shared/AddCharacter";
import CharacterCard from "components/shared/CharacterCard";
import { Row, Col, Button } from "antd";
import JuniorArtistTable from "components/JuniorArtistTable";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import { Services, LocalStorage } from "constants/AppConstants";
import { connect } from "react-redux";
import NoSceneFound from "components/NoSceneFound";
import JuniorArtistTableNew from "components/JuniorArtistTableNew";

class JuniorArtistComponent extends Component {
  state = {
    cardList: [],
    isActorsFetched: false,
    modalVisible: false,
    modalClose: false,
    activeSceneId: null,
    staffCount: 0,
    staffData: [],
  };

  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
  }

  componentDidMount() {
    this.setState(
      {
        activeSceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
      },
      () => {}
    );
  }

  renderActor = () => {
    let actorList = [];
    actorList.push(
      <JuniorArtistTableNew
        {...this.props}
        activeSceneId={this.state.activeSceneId}
      />
    );
    return actorList;
  };

  render() {
    return (
      <Row className="mb-25">
        <Col
          xl={{ offset: 1, span: 21 }}
          lg={{ offset: 1, span: 21 }}
          md={{ offset: 1, span: 21 }}
          style={{ border: "1px solid #ccc" }}
        >
          <h3
            className=""
            style={{
              backgroundColor: "#001529",
              color: "#fff",
              padding: "5px",
              textAlign: "center",
            }}
          >
            Junior Artist
          </h3>
          <Row type="flex" justify="center" style={{ marginBottom: 10 }}>
            <Col
              xl={{ span: 24 }}
              style={{
                paddingTop: 0,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {this.renderActor()}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene }) => {
  return {
    activeSceneId: scene.activeSceneId,
  };
};

export default connect(mapStateToProps)(JuniorArtistComponent);
