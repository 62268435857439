import React, { Component, Fragment } from "react";
import { ProjectServices } from "services";
import { connect } from "react-redux";

import CreateProject from "components/CreateProject";
import CreateCompany from "components/CreateCompany";
import ProjectItem from "components/Dashboard/ProjectItem";
import {
  Services,
  LocalStorage,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import NoProjectFound from "components/NoProjectFound";
import SkeletonLoader from "components/shared/SkeletonLoader";
import Resetpassword from "components/Reset Password";
import Storage from "utilities/LocalStorageHelper";
import SearchBar from "components/shared/SearchBar";
import GotoTop from "components/shared/GotoTop";
import { Row, Col } from "antd";
import { showNotifications } from "components/shared/NotificationComponent";
import SkeletonComponent from "components/shared/SkeletonComponent";

class Projects extends Component {
  constructor(props) {
    super(props);
    this._projectServices = new ProjectServices();
    this.state = {
      projectList: [],
      isProjectsFetched: false,
      filteredProjects: null,
      searchKey: null,
      activeProject: null,
      isEditPage: false,
    };
    this._storage = new Storage();
  }

  componentDidMount() {
    // this.checkIfProjectSelected()
    this.fetchProjects();
    localStorage.removeItem(LocalStorage.PAM);
    localStorage.removeItem(LocalStorage.ACTIVE_PROJECT_ID);
  }

  checkIfProjectSelected = () => {
    let projectId = JSON.parse(
      this._storage.load(LocalStorage.ACTIVE_PROJECT_ID)
    );
    if (!projectId) {
      this.fetchProjects();
    } else {
      this.props.history.push(`/project/overview/${projectId}`);
    }
  };

  deleteProject = (proId) => {
    this.setState({
      isProjectsFetched: false,
    });
    this._projectServices
      .projectServices(
        Services.DashboardVariables.DELETE_PROJECT,
        JSON.parse(this._storage.load(LocalStorage.ACCESS_TOKEN)),
        proId
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Project deleted Successfully!!"
        );
        this.fetchProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateProject = (proId, data) => {
    this._projectServices
      .projectServices(
        Services.DashboardVariables.UPDATE_PROJECT,
        JSON.parse(this._storage.load(LocalStorage.ACCESS_TOKEN)),
        data,
        proId
      )
      .then((res) => {
        // this.setState({
        //   projectList: res.data.projects,
        //   filteredProjects: res.data.projects,
        //   isProjectsFetched: true
        // });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Project updated Successfully!!"
        );
        this.setState({
          activeProject: null,
        });
        this.fetchProjects();
      })
      .catch((err) => {
        if (err.response.data.status === 403) {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            GlobalConstants.ACCESS_DENIED_ERROR
          );
        } else {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong!!"
          );
        }
      });
  };

  fetchProjects = () => {
    this.setState({
      isProjectsFetched: false,
    });
    this._projectServices
      .projectServices(
        Services.DashboardVariables.GET_PROJECT_LIST,
        JSON.parse(this._storage.load(LocalStorage.ACCESS_TOKEN))
      )
      .then((res) => {
        this.setState(
          {
            projectList: res.data.projects,
            filteredProjects: res.data.projects,
            isProjectsFetched: true,
          },
          () => {
            if (this.state.searchKey) {
              this.handleSearch(this.state.searchKey);
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isProjectsFetched: true,
        });
      });
  };

  refreshProjectList = () => {
    this.fetchProjects();
  };

  handleActiveProject = (activeProject = null) => {
    this.setState({
      activeProject,
      isEditPage: activeProject !== null,
    });
  };

  handleSearch = (value) => {
    this.setState({
      searchKey: value || "",
      filteredProjects: value
        ? this.state.projectList.filter((project) =>
            project?.projectName?.toLowerCase().includes(value.toLowerCase())
          )
        : this.state.projectList,
    });
  };

  renderProjects = () => {
    const { filteredProjects } = this.state;
    let projectListTemplate = [];
    if (filteredProjects) {
      filteredProjects.map((project, i) => {
        projectListTemplate.push(
          <ProjectItem
            key={i}
            createdDate={project.createdDate}
            projectId={project.projectId}
            projectTitle={project.projectName}
            projectImageId={project.projectimageId}
            projectImage={project.projectImage}
            companyName={project.companyName}
            handleActiveProject={this.handleActiveProject}
            project={project}
            deleteProject={this.deleteProject}
            {...this.props}
          />
        );
      });
      return projectListTemplate;
    }
  };

  renderSkeletonCards = () => {
    let template = [];
    for (let i = 1; i <= 6; i++) {
      template.push(
        <Col
          key={i}
          xl={6}
          style={{
            height: 300,
            padding: 5,
          }}
        >
          <Col xl={24} className="projectsSkeleton">
            <SkeletonComponent width={"100%"} height={150} />
            <Col xl={24}>
              <SkeletonComponent width={"80%"} />
              <SkeletonComponent width={"60%"} />
              <SkeletonComponent width={"100%"} />
            </Col>
          </Col>
        </Col>
      );
    }
    return template;
  };

  render() {
    const {
      isProjectsFetched,
      projectList,
      filteredProjects,
      activeProject,
      isEditPage,
      searchKey,
    } = this.state;

    const { activePermisision, currentUser } = this.props;

    return (
      <Fragment>
        <Resetpassword />
        <CreateCompany />
        {projectList && (
          <div style={{ paddingBottom: 10 }}>
            <SearchBar
              searchKey={searchKey}
              handleChange={(e) =>
                this.handleSearch((e && e.target.value) || null)
              }
            />
            {projectList && !searchKey && (
              <CreateProject
                refreshProjectList={this.refreshProjectList}
                removeActiveProject={this.handleActiveProject}
                activeProject={activeProject}
                updateProject={this.updateProject}
                isEditPage={isEditPage}
                {...this.props}
              />
            )}
          </div>
        )}
        {isProjectsFetched ? (
          filteredProjects && filteredProjects.length !== 0 ? (
            <Row>
              <Col xs={24}>
                <Row>{this.renderProjects()}</Row>
              </Col>
            </Row>
          ) : (
            <>
              <NoProjectFound
                {...this.props}
                refreshProjectList={this.refreshProjectList}
              />
            </>
          )
        ) : (
          this.renderSkeletonCards()
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    currentUser: user.currentUser,
    accessToken: user.accessToken,
  };
};
export default connect(mapStateToProps)(Projects);
