import React, { Component } from "react";
import { Upload, Modal, Icon, Popconfirm } from "antd";

import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";

import "./FileCard.css";
import { getScriptAPIURL } from "utilities/APIHelper";
import CostumesMakeupService from "services/CostumesMakeupServices";
import { showNotifications } from "../NotificationComponent";
import DocumentDelete from "services/DocumentDelete";

class FileCard extends Component {
  constructor(props) {
    super(props);
    this._globalServices = new GlobalService();
    this._costumeServices = new CostumesMakeupService();
    this._deleteDocumentService = new DocumentDelete();
    this.state = {
      file: this.props.file,
      previewVisible: false,
    };
  }

  handlePreview = () => {
    this.setState({
      previewVisible: true,
    });
  };

  componentDidMount() {
    this.setState({
      file: this.props.file,
    });
  }

  fetchFileDetails = (fileId) => {
    this._globalServices
      .globalService(Services.GlobalVariables.GET_IMAGE_FROM_ID, fileId)
      .then((res) => {
        console.log("fildcard fetch", res);
      })
      .catch((err) => {
        console.log("fildcard fetch", err);
      });
  };

  getThumbnailImage = (type) => {
    switch (type) {
      case GlobalConstants.PDF:
        return "https://img.icons8.com/cute-clipart/64/000000/document.png";
      case GlobalConstants.XLS:
        return "https://img.icons8.com/ios/50/000000/xls.png";
      default:
        return;
    }
  };

  deleteImage = () => {};

  confirmDelete = (info) => {
    const type = info.fileType === GlobalConstants.IMAGE ? "Image" : "Document";
    const deleteId = info.attachmentId || info.documentId;
    switch (info.fileType) {
      case GlobalConstants.IMAGE:
        this._deleteDocumentService
          .deleteService(this.props.pageType, deleteId)
          .then((res) => {
            this.props.deleteImage(deleteId);
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              `${type} ${Notifications.DELETED_SUCCESSFULLY}`
            );
          })
          .catch((err) => {
            console.log("costume delete image err...", err);
          });
        break;
      case GlobalConstants.DOCUMENT:
        this._deleteDocumentService
          .deleteService(
            this.props.pageType,
            info.attachmentId,
            this.props.screenType,
            this.props.projectPlanningCategoryId
          )
          .then((res) => {
            this.props.deleteDoc(info.documentId);
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              `${type} ${Notifications.DELETED_SUCCESSFULLY}`
            );
          });
        break;
      default:
        break;
    }
  };

  getTemplateBasedOnType = (type, file = null) => {
    switch (type) {
      case GlobalConstants.DOCUMENT:
        const thumbnail = this.getThumbnailImage(GlobalConstants.PDF);
        if (this.props.isImageOnly) {
          return (
            <div
              class="customCard"
              style={{ width: "15%", justifyContent: "center" }}
            >
              <Popconfirm
                title="Are you sure delete this Document?"
                onConfirm={() => this.confirmDelete(file)}
                // onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
                // loading={this.state.isLoading}
              >
                <a className="delete">
                  <Icon type="delete" />
                </a>
              </Popconfirm>
              <a
                href={`${getScriptAPIURL()}/file/download/${
                  this.props.file.fileId || this.props.file.fileData
                }`}
                target="_blank"
              >
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={thumbnail}
                />
              </a>
            </div>
          );
        }
        return (
          <div className="customCard">
            <Popconfirm
              title="Are you sure delete this Document?"
              onConfirm={() => this.confirmDelete(file)}
              // onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
              // loading={this.state.isLoading}
            >
              <a className="delete">
                <Icon type="delete" />
              </a>
            </Popconfirm>
            <img style={{ width: "30px", height: "30px" }} src={thumbnail} />
            <a href={this.props.file?.attachment?.url} target="_blank">
              Download
            </a>
          </div>
        );
      case GlobalConstants.IMAGE:
        let imageId = this.props.file.fileData || this.props.file.fileId;
        return (
          <div className="customImageCard">
            <Popconfirm
              title="Are you sure delete this Character?"
              onConfirm={() => this.confirmDelete(file)}
              // onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
              // loading={this.state.isLoading}
            >
              <a className="delete">
                <Icon type="delete" />
              </a>
            </Popconfirm>

            <img
              alt="example"
              style={{ width: "100%" }}
              // src={`${getScriptAPIURL()}/file/download/${imageId}`}
              src={
                imageId
                  ? `${getScriptAPIURL()}/file/download/${imageId}`
                  : this.props.file.attachment.url
              }
              onClick={this.handlePreview}
            />
          </div>
        );
      default:
        return;
    }
  };

  render() {
    const template = this.getTemplateBasedOnType(
      this.props?.file?.fileType,
      this.props?.file
    );
    return (
      <React.Fragment>
        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={() =>
            this.setState({
              previewVisible: false,
            })
          }
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={`${getScriptAPIURL()}/file/download/${
              this.props?.file?.fileId || this.props?.file?.fileData
            }`}
          />
        </Modal>
        {template}
      </React.Fragment>
    );
  }
}

export default FileCard;
