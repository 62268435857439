import React, { Component, Fragment } from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ArtSetServices from "services/ArtSetServices";
import {
  Services,
  GlobalConstants,
  Screens,
  LocalStorage,
  EnumLocation,
} from "constants/AppConstants";

import "antd/dist/antd.css";
import {
  Capitalize,
  checkFor,
  removeColumns,
} from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import { setActiveTab } from "store/action";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import ThemeColors from "constants/ThemeConstants/variables";
import TooltipComponent from "components/shared/TooltipComponent";
import EnumToUI from "utilities/EnumHelper";

class ArtSetData extends Component {
  constructor(props) {
    super(props);
    this._artsetService = new ArtSetServices();
    this.state = {
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchArtsetInfoData();
  }

  fetchArtsetInfoData = () => {
    this._artsetService
      .service(Services.ArtSetVariables.GET_ART_SET_LIST_INFO)
      .then((res) => {
        this.setState({
          dataSource: res.data.artSets,
          isLoading: true,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: true,
        });
      });
  };

  render() {
    const { dataSource, isLoading } = this.state;
    const { activePermisision } = this.props;
    let columns = [
      {
        title: "#",
        dataIndex: "key",
        width: "6%",
        align: "center",
        render: (text, record, index) => <span>{index + 1}</span>,
      },

      {
        title: "Schedule",
        dataIndex: "shootDate",
        width: "13%",
        render: (text) => (
          <Tag
            style={{ marginLeft: 0, borderRadius: 12, marginBottom: 3 }}
            color={ThemeColors.primaryColor}
          >
            {text}
          </Tag>
        ),
      },
      {
        title: "Call Sheet",
        dataIndex: "callSheet",
        width: "9%",
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocation",
        width: "20%",
        render: (text, record) => (
          <p className="commaContainer">
            {record.locations &&
              record.locations.map((location) => (
                <span>{Capitalize(location.actualLocation)}</span>
              ))}
          </p>
        ),
      },
      {
        title: "Scene Location",
        dataIndex: "sceneLocation",
        width: "30%",
        render: (text, record) => (
          <p className="commaContainer">
            {record.locations &&
              record.locations.map((location) => (
                <span>{location.sceneLocation}</span>
              ))}
          </p>
        ),
      },

      {
        title: "Scenes",
        dataIndex: "scenes",
        width: "13%",
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.ART_SET
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                      this.props.setActiveTab("1");
                    }}
                  >
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },

      {
        title: "Budget (₹)",
        dataIndex: "budget",
        width: "9%",
        align: "right",
      },
      // {
      //   title: "Status",
      //   dataIndex: "state",
      //   width: "13.5%",
      //   align: "center",
      //   render: (value, record) => (
      //     <Tag className={`statusTag ${record.state}`}>
      //       {EnumToUI(record.state, EnumLocation?.location)}
      //     </Tag>
      //   ),
      // },
    ];

    if (!checkFor(activePermisision, GlobalConstants.FINANCE)) {
      columns = removeColumns(columns, ["Budget (₹)"]);
    }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: "6%",
        render: (text, record) => (
          <div>
            <div
              onClick={() =>
                this.props.history.push(
                  `${`/project/update-artset/${record.shootDate}`}`,
                  record
                )
              }
            >
              <Link>More</Link>
            </div>
          </div>
        ),
      });
    }

    return (
      <Fragment>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Table
            loading={!isLoading}
            bordered
            columns={columns}
            dataSource={dataSource}
            className="art-set-col-width"
            pagination={false}
            locale={{
              emptyText: isLoading && <RecordNotFound />,
            }}
          />
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.ART_SET, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtSetData);
