import React, { Component } from "react";
import MainCharacterComponent from "components/MainCharacterComponent";
import SupportingCharacterComponent from "components/SupportingCharacterComponent";
import JuniorArtistComponent from "components/JuniorArtistComponent";
import AnimalComponent from "components/Animal";
import { GlobalConstants } from "constants/AppConstants";
import { connect } from "react-redux";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";

class ActorTab extends Component {
  componentDidMount() {
    localStorage.removeItem(GlobalConstants.REDIRECT_TO);
  }
  render() {
    const { activePermisision } = this.props;
    return (
      <div>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <div>
            <MainCharacterComponent
              {...this.props}
              ADD={checkFor(activePermisision, "Add")}
              EDIT={checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )}
              DELETE={checkFor(activePermisision, "Delete")}
              FINANCE={checkFor(activePermisision, "Finance")}
              PERSONAL={checkFor(activePermisision, "Personal")}
            />
            <SupportingCharacterComponent
              {...this.props}
              ADD={checkFor(activePermisision, "Add")}
              EDIT={checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )}
              DELETE={checkFor(activePermisision, "Delete")}
              FINANCE={checkFor(activePermisision, "Finance")}
              PERSONAL={checkFor(activePermisision, "Personal")}
            />
            <JuniorArtistComponent
              {...this.props}
              ADD={checkFor(activePermisision, "Add")}
              EDIT={checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )}
              DELETE={checkFor(activePermisision, "Delete")}
              FINANCE={checkFor(activePermisision, "Finance")}
              PERSONAL={checkFor(activePermisision, "Personal")}
            />
            <AnimalComponent
              {...this.props}
              ADD={checkFor(activePermisision, "Add")}
              EDIT={checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )}
              DELETE={checkFor(activePermisision, "Delete")}
              FINANCE={checkFor(activePermisision, "Finance")}
              PERSONAL={checkFor(activePermisision, "Personal")}
            />
          </div>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActorTab);
