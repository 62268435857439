import axios from "axios";
export class GeoServices {
  services(type, value) {
    switch (type) {
      case "PIN_CODE":
        return axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${value}|country:IN&key=AIzaSyDPaDoNeqMjLm24S533uZyEOrPSxvMlbTk`
        );
      default:
        return;
    }
  }
}
