import React, { useState } from "react";
import { Table, Input, Tag, TimePicker, Button } from "antd";
import RecordNotFound from "components/shared/RecordNotFound";
import SchedularService from "services/SchedularServices";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import { showActionMessage } from "components/shared/NotificationComponent";
import { StartCase } from "utilities/ValidationHelper";
import moment from "moment";

const SchedularActorList = ({ dataSource }) => {
  const [TimeValue, setTimeValue] = useState(null);
  const [ActiveRow, setActiveRow] = useState(null);
  const api = new SchedularService();

  const onChange = (time, timeString, row) => {
    setTimeValue(timeString);
    if (!timeString) {
      handleCallTime({ ...row, timeString });
    }
  };

  const handleOpenCloseTimePicker = (status, row) => {
    if (status) {
      setActiveRow(row);
    } else {
      TimeValue && handleCallTime({ ...row, TimeValue });
    }
  };

  const handleCallTime = ({ TimeValue, sceneCharacterMappingId }) => {
    const data = {
      callTime: TimeValue,
    };
    api
      .services(
        Services.SchedularVariables.UPDATE_ACTOR_CALL_TIME,
        null,
        null,
        data,
        sceneCharacterMappingId
      )
      .then((res) =>
        showActionMessage(
          GlobalConstants.SUCCESS,
          `Call Time ${Notifications.UPDATED_SUCCESSFULLY}`
        )
      )
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Actor Name",
      dataIndex: "actorName",
      key: "actorName",
      align: "center",
      render: (text) => StartCase(text),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // align: "center"
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      // align: "center",
      render: (text, record) =>
        text && <span>{`(${record.phoneCode}) ${record.phoneNumber}`}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      key: "role",
      render: (text) => (
        <Tag className={`${text} dateTag`}>{StartCase(text)}</Tag>
      ),
    },
    {
      title: "Call Time",
      dataIndex: "callTime",
      // align: "center",
      key: "callTime",
      render: (text, record) => (
        // <Input
        //   placeholder="example: 9hrs"
        //   style={{ width: 150 }}
        //   onBlur={e => handleCallTime(e, record)}
        // />
        <TimePicker
          use12Hours
          format="h:mm A"
          onChange={(time, timeString) => onChange(time, timeString, record)}
          onOpenChange={(e) => handleOpenCloseTimePicker(e, record)}
          defaultValue={text ? moment(text, "h:mm A") : ""}
          // placement="topRight"
          // addon={e => (
          //   <a
          //     size="small"
          //     type="primary"
          //     onClick={() => setTimeValue(moment())}
          //   >
          //     Now
          //   </a>
          // )}
        />
      ),
    },
  ];

  return (
    <Table
      bordered
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      locale={{
        emptyText: <RecordNotFound />,
      }}
    />
  );
};

export default SchedularActorList;
