import { notification, message as Message } from "antd";
import { GlobalConstants } from "constants/AppConstants";

export const showNotifications = (
  type,
  title = "Please give Notification Title ",
  message = "Get your work simplified!!"
) => {
  notification.config({
    duration: 6,
  });
  notification[type]({
    message: title,
    description: message,
    style: {
      width: 400,
    },
  });
};

export const showActionMessage = (type, message = "Loading") => {
  switch (type) {
    case GlobalConstants.SHOW:
      Message.loading(message, 0);
      break;
    case GlobalConstants.HIDE:
      Message.destroy();
      break;
    case GlobalConstants.SUCCESS:
      Message.success(message);
      break;
    case GlobalConstants.WARNING:
      Message.warning(message);
      break;
    case GlobalConstants.ERROR:
      Message.error(message);
    default:
      break;
  }
};
