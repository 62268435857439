import React, { Component, Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Button,
  Collapse,
  Table,
  Icon,
  Popconfirm,
  Tooltip,
} from "antd";
import moment from "moment";

import {
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import ArtSetServices from "services/ArtSetServices";
import { handleError } from "utilities/ErrorHandler";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import {
  checkDateGreaterThan,
  disableDateHelper,
  checkFor,
} from "utilities/ValidationHelper";
import BudjetService from "services/BudjetService";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";

const dateFormat = "DD-MM-YYYY";
const { Option } = Select;

class StartDateEndDate extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._artSetService = new ArtSetServices();
    this._budjetService = new BudjetService();
    this.state = {
      setWorkEndDate: null,
      setWorkStartDate: null,
      isLoading: false,
      gettingStatus: "TO_BE_CONFIRMED",
      status: null,
      sceneLocationId: props.sceneLocationId,
      artSetBudgetId: props.artSetBudgetId,
      shootDate: props.shootDate,
      totalRecords: [],
      dataSource: [],
      count: 0,
    };
  }

  componentDidMount() {
    this.getStatus();
    this.getBudget();
  }

  convertFormat = (date) => moment(date, dateFormat).format("DD-MM-YYYY");

  dateChange(value, dateString, row, type) {
    const { dataSource, shootDate } = this.state;
    row.setWorkStartDate = this.convertFormat(row.setWorkStartDate);
    row.setWorkEndDate = this.convertFormat(row.setWorkEndDate);
    if (type !== "sceneLocationId") {
      row[type] = this.convertFormat(value);
    }
    if (type === "setWorkStartDate") {
      if (row.setWorkStartDate !== "Invalid date") {
        dataSource.forEach((data) => {
          if (data.setWorkEndDate === "Invalid date") {
            data.setWorkEndDate = null;
          }
          return {
            ...data,
            [type]: value,
          };
        });
        this.setState(
          {
            dataSource,
          },
          () => this.updateDates(row)
        );
      } else {
        dataSource.forEach((data) => {
          return {
            ...data,
            [type]: value,
          };
        });
        this.setState({
          dataSource,
        });
      }
    } else if (type === "setWorkEndDate") {
      if (row.setWorkEndDate !== "Invalid date" && row.setWorkEndDate) {
        dataSource.forEach((data) => {
          return {
            ...data,
            [type]: value,
          };
        });
        this.setState(
          {
            dataSource,
          },
          () => this.updateDates(row)
        );
      }
    }
  }

  handleDelete = (key, record) => {
    if (record.artSetBudgetDateId) {
      this._budjetService
        .budjetService(
          Services.BudjetVariables.DEL_ART_SET_SHOOT_DATE_DATA,
          record.artSetBudgetDateId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.getBudget();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  updateDates = (row) => {
    const { shootDate, dataSource, sceneLocationId } = this.state;
    const { refreshData } = this.props;
    const { setWorkStartDate, setWorkEndDate } = row;
    let temp = dataSource.map((data) => {
      return {
        ...data,
        artSetDateId: data.artSetBudgetDateId,
        sceneLocationId,
      };
    });
    const data = {
      artSets: temp,
      shootDate,
    };
    this._artSetService
      .service(Services.ArtSetVariables.UPDATE_ART_SET_DATES, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getBudget();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  getBudget = () => {
    const { artSetBudgetId } = this.state;
    if (artSetBudgetId) {
      this.setState({
        dataSource: [],
        totalRecords: [],
      });
      this._artSetService
        .service(Services.ArtSetVariables.GET_DATA_BY_BUDGET_ID, artSetBudgetId)
        .then((res) => {
          let dataSource = [];
          res.data.artSetDates &&
            res.data.artSetDates.map((data, i) => {
              dataSource.push({ ...data, key: i });
              this.setState({
                count: i,
              });
            });
          this.setState({
            dataSource,
            isLoading: false,
            totalRecords: dataSource,
          });
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    }
  };

  getStatus = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState(
          {
            status: res.data,
          },
          () => console.log("status...", this.state.status)
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource, sceneLocationId } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      setWorkEndDate: null,
      setWorkStartDate: "",
      sceneLocationId: sceneLocationId,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      setWorkStartDate,
      setWorkEndDate,
      artSetBudgetId,
      shootDate,
      sceneLocationId,
      gettingStatus,
    } = this.state;
    const data = {
      setWorkStartDate,
      setWorkEndDate,
      state: gettingStatus,
      artSetBudgetId,
      sceneLocationId,
      shootDate,
    };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true,
        });
        this._artSetService
          .service(Services.ArtSetVariables.UPDATE_ART_SET, data)
          .then((res) => {
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
            this.setState({
              isLoading: false,
            });
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  };

  disabledDate = (current, toDate) => {
    const { shootDate } = this.state;
    // Can not select days before today and today
    if (current) {
      if (toDate) {
        return (
          current < moment(toDate, dateFormat).add(0, "days")
          // &&
          // current >
          // moment(this.convertFormat(shootDate), dateFormat).add(-1, "days")
        );
      }
      return current < moment().startOf("day");
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      setWorkEndDate,
      setWorkStartDate,
      gettingStatus,
      status,
      isLoading,
      shootDate,
      dataSource,
      totalRecords,
    } = this.state;

    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "Work Start date",
        dataIndex: "setWorkStartDate",
        key: "name",
        width: 200,
        align: "center",
        render: (text, record) => (
          <DatePicker
            style={{ width: "100%" }}
            defaultValue={text ? moment(text, dateFormat) : ""}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "setWorkStartDate")
            }
            className={`${!text ? "err" : ""}`}
            format={dateFormat}
            disabledDate={(current) => this.disabledDate(current)}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.artSetBudgetDateId
            }
            // disabledDate={d => this.disableDateHelper(d)}
            // disabled={!record.sceneLocationId}
            // autoFocus
          />
        ),
      },
      {
        title: "Work End date",
        dataIndex: "setWorkEndDate",
        key: "address",
        align: "center",
        width: 200,

        render: (text, record) =>
          record.setWorkStartDate !== "Invalid date" &&
          record.setWorkStartDate && (
            <>
              {console.log("Start Date...", record.setWorkStartDate.valueOf())}
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={text ? moment(text, dateFormat) : ""}
                onChange={(value, dateString) =>
                  this.dateChange(value, dateString, record, "setWorkEndDate")
                }
                disabled={
                  record.setWorkStartDate === "Invalid date" ||
                  (!record.setWorkStartDate &&
                    !checkFor(
                      activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    ) &&
                    record.artSetBudgetDateId)
                  // !record.sceneLocationId
                }
                format={dateFormat}
                disabledDate={(current) =>
                  this.disabledDate(current, record.setWorkStartDate)
                }
              />
            </>
          ),
      },
      {
        title: "Operation",
        dataIndex: "operation",
        render: (text, record) => (
          <Row type="flex" justify="center">
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record.key, record)}
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button
                    icon="delete"
                    type="link"
                    disabled={record.isEnable === false}
                  />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          </Row>
        ),
      },
    ];

    const { activePermisision } = this.props;

    return (
      <Fragment>
        <Row type="flex" justify="center">
          <Col xl={18} style={{ paddingTop: 10 }}>
            <Col xl={18}>
              <Col xl={20}>
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_ADD
                ) && (
                  <Button
                    onClick={this.handleAdd}
                    type="primary"
                    style={{ float: "right", marginBottom: 10 }}
                    disabled={hasNewRow}
                  >
                    <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                  </Button>
                )}
              </Col>
              <Col xl={20}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  bordered
                  pagination={false}
                  scroll={{ y: 240 }}
                />
              </Col>
            </Col>
            <Col xl={6}></Col>
            {/* <Col
              xl={{ span: 7 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Row gutter={16}>
                <Col xs={{ span: 8 }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                      textAlign: "right"
                    }}
                  >
                    <label>*Work Start Date</label>
                  </div>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                  <Form.Item>
                    {getFieldDecorator("setWorkStartDate", {
                      initialValue: setWorkStartDate
                        ? moment(setWorkStartDate, dateFormat)
                        : "",
                      rules: [
                        {
                          required: true,
                          message: "Please select start date!"
                        }
                      ]
                    })(
                      <DatePicker
                        allowClear={false}
                        format={dateFormat}
                        defaultValue={
                          setWorkStartDate
                            ? moment(setWorkStartDate, dateFormat)
                            : ""
                        }
                        // disabledDate={current => this.disabledDate(current)}
                        onChange={value =>
                          this.dateChange(value, "setWorkStartDate")
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 7 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Row gutter={16}>
                <Col xs={{ span: 8 }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                      textAlign: "right"
                    }}
                  >
                    <label>*Work End Date</label>
                  </div>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                  <Form.Item>
                    {getFieldDecorator("setWorkEndDate", {
                      initialValue: setWorkEndDate
                        ? moment(setWorkEndDate, dateFormat)
                        : "",
                      rules: [
                        {
                          required: true,
                          message: "Please select end date!"
                        }
                      ]
                    })(
                      <DatePicker
                        allowClear={false}
                        defaultValue={
                          setWorkEndDate
                            ? moment(setWorkEndDate, dateFormat)
                            : ""
                        }
                        format={dateFormat}
                        disabledDate={current =>
                          this.disabledDate(current, setWorkStartDate)
                        }
                        onChange={value =>
                          this.dateChange(value, "setWorkEndDate")
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 7 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Row gutter={16}>
                <Col xs={{ span: 8 }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                      textAlign: "right"
                    }}
                  >
                    <label>*Status</label>
                  </div>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                  <Form.Item>
                    {getFieldDecorator("status", {
                      initialValue:
                        (gettingStatus && gettingStatus) ||
                        GlobalConstants.TO_BE_CONFIRMED,
                      rules: [
                        {
                          required: true,
                          message: "Please Enter status!"
                        }
                      ]
                    })(
                      <Select
                        defaultValue={GlobalConstants.TO_BE_CONFIRMED}
                        placeholder="Status"
                        style={{ width: "100%" }}
                        onChange={value =>
                          this.setState({
                            gettingStatus: value
                          })
                        }
                      >
                        {status &&
                          status.map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 3 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Form onSubmit={this.handleSubmit}>
                <Row type="flex" justify="end">
                  <Col>
                    <Button
                      type="primary"
                      onClick={this.handleSubmit}
                      loading={isLoading}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col> */}
          </Col>
        </Row>
        <Row></Row>
      </Fragment>
    );
  }
}

const StartEndDate = Form.create()(StartDateEndDate);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.ART_SET, menus),
  };
};

export default connect(mapStateToProps, null)(StartEndDate);
