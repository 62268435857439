import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {Button, Col, Icon, Table } from "antd";
import RecordNotFound from "components/shared/RecordNotFound";
import TechnicianService from "services/TechnicialService";
import { checkFor} from "utilities/ValidationHelper";
import { GlobalConstants, LocalStorage } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
const ThirdPartyPayee = () => {
    // State hook
    const userState = useSelector((state) => state?.user);
    // States
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    // const values
    const activePermisision = getSPermissionFromMenus(
        "Char-Actor",
        userState?.menus || []
      );
    const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const techniciansColumn = [
        {
            title: "#",
            align: "center",
            key: "key",
            width: "10%",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Technician Type",
            dataIndex: "technicianType",
            key: "technicianType",
        },
    ];
    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      techniciansColumn.push({
        title: "Action",
        width: "10%",
        align: "center",
        render: (text, record) => {
          return (
              <Link
                to={{
                  pathname: "/project/technicians",
                  state: { technicianType: record.technicianType, technicianId: record.id },
                }}
              >
                More
              </Link>
            )
        },
      });
    }
    // UseEffect to get 3rd party Technician Data from api
    useEffect(() => {
        getThirdParyTechniciansData();
    },[])

    // functions
    const getThirdParyTechniciansData = () => {
        setLoading(true);
        const payload = {
            legalAgreementType: "THIRD_PARTY_PAYEE",
            projectId,
            size: 0,
          };
    TechnicianService.getTechnicians(payload)
    .then((res) => {
        setLoading(false);
        setTableData(res?.data?.content || []);
    })
    .catch((err) => {
        setLoading(true);
    })

    }
    return (
        <div>
            <Col xl={24}>
                <Table
                    bordered
                    dataSource={tableData}
                    columns={techniciansColumn}
                    loading={loading}
                    pagination={false}
                    locale={{
                        emptyText: !loading && <RecordNotFound />,
                    }}
                />
            </Col>
        </div>
    )
}

export default ThirdPartyPayee;