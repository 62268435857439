import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class AccountingService {
  accountingService(
    type,
    data,
    id,
    budgetDepartment,
    productionCategory,
    isBudget = false
  ) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    let projectCategoryDocumentId = {
      projectCategoryDocumentId: null,
    };

    switch (type) {
      case Services.AccountingVariables.GET_PRE_PRODUCTION_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/pre/production/payments`,
          config
        );
      case Services.AccountingVariables.GET_RECEIVER_NAME:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/payee`,
          config
        );

      case Services.AccountingVariables.SAVE_PRE_PRODUCTION_DATA:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/pre/production/payments`,
          data,
          config
        );
      case Services.AccountingVariables.UPDATE_PRE_PRODUCTION_DATA:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/pre/production/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.GET_POST_PRODUCTION_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/post/production/payments`,
          config
        );
      case Services.AccountingVariables.SAVE_POST_PRODUCTION_DATA:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/post/production/payments`,
          data,
          config
        );
      case Services.AccountingVariables.UPDATE_POST_PRODUCTION_DATA:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/post/production/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.GET_PRODUCTION_DATA:
        return axios.get(
          `${getScriptAPIURL()}/production-payment-summary?projectId=${projectId}&productionDepartment=${
            data.productionDepartment
          }&isAdvance=${false}`,
          config
        );
      case Services.AccountingVariables.GET_PRODUCTION_DATA_ADVANCE_PAYMENT:
        return axios.get(
          `${getScriptAPIURL()}/production-payment-summary?projectId=${projectId}&productionDepartment=${
            data?.location
          }&isAdvance=${data?.isAdvance}`,
          config
        );
      case Services.AccountingVariables.GET_PRODUCTION_DATA_BY_PURCHASE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/production/purchase`,
          config
        );
      case Services.AccountingVariables.GET_PRODUCTION_BUDJET_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/production/payments?budgetDepartment=${budgetDepartment}&shootDate=${data}&isBudget=${isBudget}`,
          config
        );
      case Services.AccountingVariables.UPDATE_PRODUCTION_BUDJET_DATA:
        return axios.patch(
          `${getScriptAPIURL()}/production/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.DEL_PRODUCTION_BUDJET_DATA:
        let url = productionCategory
          ? `${getScriptAPIURL()}/project/${projectId}/production/${id}?budgetDepartment=${budgetDepartment}&productionCategory=${productionCategory}&shootDate=${data}`
          : `${getScriptAPIURL()}/project/${projectId}/production/${id}?budgetDepartment=${budgetDepartment}&shootDate=${data}`;
        return axios.delete(url, config);
      case Services.AccountingVariables.DEL_PRE_PRODUCTION:
        return axios.delete(
          `${getScriptAPIURL()}/project/production/${id}`,
          config
        );
      case Services.AccountingVariables.POST_UPDATE_PRODUCTION:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/production/payments`,
          data,
          config
        );
      case Services.AccountingVariables.POST_UPDATE_PRODUCTION_VENDOR:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/vendors/schedule`,
          data,
          config
        );
      case Services.AccountingVariables.POST_UPDATE_ARTSET:
        return axios.post(
          `${getScriptAPIURL()}/artsets/production`,
          data,
          config
        );
      case Services.AccountingVariables.UPDATE_BUDGET_STATE:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}`,
          data,
          config
        );
      case Services.AccountingVariables.GET_PROJECT_PLAN:
        console.log(type, data, id, budgetDepartment, productionCategory);

        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account?category=${productionCategory}`,
          config
        );
      case Services.AccountingVariables.CREATE_PROJECT_PLAN:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/plans`,
          data,
          config
        );
      case Services.AccountingVariables.DELETE_PROJECT_PLAN:
        return axios.delete(`${getScriptAPIURL()}/project/plans/${id}`, config);
      case Services.AccountingVariables.UPDATE_PROJECT_PLAN:
        return axios.put(
          `${getScriptAPIURL()}/project/plans/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.GET_PROJECT_PLAN_BY_CATEGORY:
        // return axios.get(
        //   `${getScriptAPIURL()}project/plans/${id}/categories`,
        //   config
        // );
        console.log(type, data, id, budgetDepartment, productionCategory);
        return axios.get(
          `${getScriptAPIURL()}/account/${id}/payments?category=${productionCategory}`,
          config
        );
      case Services.AccountingVariables.CREATE_PROJECT_PLAN_BY_CATEGORY:
        console.log(type, data, id, budgetDepartment, productionCategory);
        return axios.post(
          `${getScriptAPIURL()}/api/v1/account/payments`,
          data,
          config
        );
      // return axios.post(
      //   `${getScriptAPIURL()}project/plans/${id}/categories`,
      //   data,
      //   config
      // );
      case Services.AccountingVariables.UPDATE_PROJECT_PLAN_BY_CATEGORY:
        return axios.put(
          `${getScriptAPIURL()}/account/payments/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.DELETE_PROJECT_PLAN_BY_CATEGORY:
        return axios.patch(
          `${getScriptAPIURL()}/project/plans/categories/${id}`,
          projectCategoryDocumentId,
          config
        );
      case Services.AccountingVariables.GET_CAST_TECNICIANS:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/cast/technicians?type=${data}`,
          config
        );
      case Services.AccountingVariables.CAST_TECNICIANS:
        return axios.get(
          `${getScriptAPIURL()}/production-payment-summary?projectId=${projectId}&productionDepartment=${type}&isAdvance=true`,
          config
        );
      case Services.AccountingVariables.GET_VENDOR_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/vendors`,
          config
        );
      case Services.AccountingVariables.CREATE_VENDOR_BY_PROJECT:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/vendors`,
          data,
          config
        );
      case Services.AccountingVariables.GET_VENDOR_BY_ID:
        return axios.get(`${getScriptAPIURL()}/vendors/${id}`, config);
      case Services.AccountingVariables.CREATE_VENDOR_PAYMENT:
        return axios.patch(
          `${getScriptAPIURL()}/projects/${projectId}/vendors/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.DELETE_VENDOR_PAYMENT:
        return axios.patch(`${getScriptAPIURL()}/vendors/${id}`, data, config);
      case Services.AccountingVariables.GET_VENDOR_BY_EQUIPMENT:
        return axios.get(
          `${getScriptAPIURL()}/project/vendor/${id}/equipments`,
          config
        );
      case Services.AccountingVariables.CREATE_VENDOR_BY_EQUIPMENT:
        return axios.post(
          `${getScriptAPIURL()}/project/vendor/${id}/equipments`,
          data,
          config
        );
      case Services.AccountingVariables.UPDATE_VENDOR_BY_EQUIPMENT:
        return axios.put(
          `${getScriptAPIURL()}/project/vendor/equipments/${id}`,
          data,
          config
        );
      case Services.AccountingVariables.DELETE_VENDOR_BY_EQUIPMENT:
        return axios.delete(
          `${getScriptAPIURL()}/project/vendor/equipments/${id}`,
          config
        );
      case Services.AccountingVariables.DEL_ART_SET_SHOOT_DATE_DATA:
        return axios.delete(`${getScriptAPIURL()}/artset/date/${data}`, config);
      case Services.AccountingVariables.GET_VENDOR_EQUIPMENT_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/vendor/${id}/equipments`,
          config
        );
      case Services.AccountingVariables.GET_ACCOUNTING_ESTIMATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/accounts`,
          config
        );
      case Services.AccountingVariables.GET_CHART_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/budgets/chart?shootDate=${data}`,
          config
        );
      default:
        break;
    }
  }
}
