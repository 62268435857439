import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class InvoiceService {
  invoiceService(
    type,
    data,
    id,
    budgetDepartment,
    productionCategory,
    isBudget = false
  ) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    let projectCategoryDocumentId = {
      projectCategoryDocumentId: null,
    };

    switch (type) {
      case Services.InvoiceVariables.GET_INVOICES:
        return axios.get(
          `/project/${projectId}/scheduled/invoices?category=${data}`,
          config
        );
      case Services.InvoiceVariables.GET_INVOICE_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/project/invoices/${data}`,
          config
        );
      case Services.InvoiceVariables.GET_INVOICES_BY_SHOOT_DATE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/invoices?shootDate=${data}`,
          config
        );
      case Services.InvoiceVariables.GET_INVOICES_WITHOUT_SHOOT_DATE:
        return axios.get(
          `${getScriptAPIURL()}/production-payment?projectId=${projectId}&category=${data}`,
          config
        );
      case Services.InvoiceVariables.GET_INVOICES_DESCRIPTION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/payment/descriptions?shootDate=${data}`,
          config
        );
      case Services.InvoiceVariables.CREATE_INVOICE_BY_SHOOT_DATE:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/invoices`,
          data,
          config
        );
      case Services.InvoiceVariables.UPDATE_INVOICE_BY_SHOOT_DATE:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/invoices/${id}`,
          data,
          config
        );
      case Services.InvoiceVariables.DELETE_INVOICE_BY_SHOOT_DATE:
        return axios.delete(
          `${getScriptAPIURL()}/project/invoices/${data}`,
          config
        );

      default:
        break;
    }
  }
}
