import React, { useState, useEffect } from "react";
import { Tabs } from "antd";

import { GlobalConstants } from "constants/AppConstants";
import AccountOverviewTable from "./AccountOverviewTable";
import DashboardServices from "services/DashboardServices";
import { SceneOVerviewVariable } from "constants/AppConstants/ServicesConstants";

const { TabPane } = Tabs;

const AccountOverviewTabs = ({
  SelectedTab,
  startDate,
  endDate,
  detailedViewData,
}) => {
  const [ActiveKey, setActiveKey] = useState(SelectedTab);
  const ar = [
    "PRODUCTION",
    "ART_SET",
    "EQUIPMENT",
    "COSTUME_MAKEUP",
    "SONG_STUNT",
  ];
  const [detailedViewData1, setdetailedViewData] = useState(detailedViewData);
  const _dashboardService = new DashboardServices();

  useEffect(() => {
    setActiveKey(SelectedTab);
    setdetailedViewData(detailedViewData);
  }, [SelectedTab, detailedViewData]);

  const getDetailedViewData = (key) => {
    console.log("category", key);
    _dashboardService
      .service(SceneOVerviewVariable.GET_DETAILED_VIEW_DATA, {
        startDate: startDate,
        endDate: endDate,
        dept: key,
        category: "PRODUCTION",
      })
      .then((res) => {
        setdetailedViewData(res.data.payments || []);
      })
      .catch((err) => {
        setdetailedViewData([]);
      });
  };
  const getDetailedViewDataCategory = () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      category: "PRODUCTION_PURCHASE",
    };
    _dashboardService
      .service(SceneOVerviewVariable.GET_DETAILED_VIEW_DATA_CATEGORY, data)
      .then((res) => {
        setdetailedViewData(res.data.payments || []);
      })
      .catch((err) => {
        setdetailedViewData([]);
      });
  };

  const handleTabChange = (key) => {
    console.log("kjey", key);
    setActiveKey(key);
    if (key == 6) {
      getDetailedViewDataCategory();
    } else {
      getDetailedViewData(ar[key - 1]);
    }
  };
  return (
    <Tabs
      activeKey={ActiveKey.toString()}
      onChange={handleTabChange}
      destroyInactiveTabPane
    >
      <TabPane tab={GlobalConstants.DAILY_EXPENSE} key="1">
        <AccountOverviewTable detailedViewData={detailedViewData1} />
      </TabPane>
      <TabPane tab={GlobalConstants.ART_SET} key="2">
        <AccountOverviewTable detailedViewData={detailedViewData1} />
      </TabPane>
      <TabPane tab={GlobalConstants.EQUIPMENTS} key="3">
        <AccountOverviewTable detailedViewData={detailedViewData1} />
      </TabPane>
      <TabPane tab={GlobalConstants.COSTUME_AND_MAKEUP} key="4">
        <AccountOverviewTable detailedViewData={detailedViewData1} />
      </TabPane>
      <TabPane tab={GlobalConstants.SONG_AND_STUNT} key="5">
        <AccountOverviewTable detailedViewData={detailedViewData1} />
      </TabPane>
      <TabPane tab={GlobalConstants.BY_PURCHASE} key="6">
        <AccountOverviewTable detailedViewData={detailedViewData1} />
      </TabPane>
    </Tabs>
  );
};

export default AccountOverviewTabs;
