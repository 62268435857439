import axios from "axios";
import { LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

const projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

const getAllEpisode = (data = {}) => {
  return axios.get(`${getScriptAPIURL()}/project/${projectId}/episode`, {
    params: data,
  });
};

const updateEpisode = (id, data) => {
  return axios.patch(
    `${getScriptAPIURL()}/project/${projectId}/episode/${id}`,
    data
  );
};

const EpisodeService = { getAllEpisode, updateEpisode };

export default EpisodeService;
