import React, { useEffect, Fragment, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { connect } from "react-redux";

import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import Costumes from "components/Costumes";
import { setCostumeLeftActiveTab } from "store/action";
import { GlobalConstants } from "constants/AppConstants";
import GeneralCostumes from "components/GeneralCostumes";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";

const { TabPane } = Tabs;

const CostumeLeftTabs = (props) => {
  const { setCostumeLeftActiveTab, costumeLeftActiveTab, activePermisision } =
    props;
  const [ActiveTab, setActiveTab] = useState(costumeLeftActiveTab);

  const handleChange = (e) => {
    setCostumeLeftActiveTab(e);
  };

  return (
    <Fragment>
      <Row span={24}>
        <Title>
          <TitleText>Costumes </TitleText>
        </Title>
      </Row>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row style={{ padding: 10 }}>
          <Col>
            <Tabs
              defaultActiveKey={ActiveTab}
              onChange={handleChange}
              animated
              destroyInactiveTabPane
            >
              <TabPane tab="General Costumes" key="1">
                <GeneralCostumes />
              </TabPane>
              <TabPane tab="Special Costumes by Purchase" key="2">
                <Costumes {...props} viewBy={GlobalConstants.PURCHASE} />
              </TabPane>
              <TabPane tab="Special Costumes by Hire Per Day" key="3">
                <Costumes {...props} viewBy={GlobalConstants.PER_DAY} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { costumeLeftActiveTab },
  user: { menus },
}) => {
  return {
    costumeLeftActiveTab,
    activePermisision: getSPermissionFromMenus(PermissionPage.COSTUME, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostumeLeftTabs);
