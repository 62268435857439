import React, { Component } from "react";
import { Card, Icon, Skeleton, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import moment, { locale } from "moment";

import { connect } from "react-redux";

import camera from "../../../assets/images/camera.jpg";
import { setActiveProject } from "store/action";
import CreateProject from "components/Reset Password";
import { LocalStorage, GlobalConstants } from "constants/AppConstants";
import Axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

const { confirm } = Modal;

class ProjectItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectImageId: null,
      isLoaded: false,
    };
  }
  componentDidMount() {
    this.timeStamp();
  }

  timeStamp = () => {
    let date = this.props.createdDate;
  };

  setActiveProjects = (projectIDS, projectTitle) => {
    localStorage.setItem(LocalStorage.ACTIVE_PROJECT_ID, this.props.projectId);
    localStorage.setItem(LocalStorage.ACTIVE_PROJECT_NAME, projectTitle);
    this.props.setActiveProject(projectIDS, projectTitle);
    localStorage.setItem(
      LocalStorage.PROJECT_DATA,
      JSON.stringify(this.props.project)
    );
  };

  checkTheDays = (createdDate, days) => {
    let startDate = moment(moment(createdDate).format("YYYY-MM-DD"));
    let endDate = moment(moment().format("YYYY-MM-DD"));
    if (endDate.diff(startDate, "days") < days) {
      return true;
    } else return false;
  };

  showDeleteConfirm = () => {
    const { deleteProject, projectId } = this.props;
    confirm({
      title: "Are you sure delete this Project?",
      content: "This Action can't be revoke",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteProject(projectId);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };
  render() {
    const {
      createdDate,
      projectId,
      projectTitle,
      projectImageId,
      companyName,
      project,
      handleActiveProject,
      currentUser: { roleName },
    } = this.props;
    const projectIDS = {
      projectId,
      projectImageId,
    };

    const { isLoaded } = this.state;
    const imageSrc = this.props?.projectImage?.url || camera;

    return (
      <Col
        xl={6}
        style={{ borderWidth: 0, marginBottom: 5, cursor: "pointer" }}
      >
        <div
          onClick={() => {
            this.setActiveProjects(projectIDS, projectTitle);
            this.props.history.push(`/project/overview/${projectId}`);
          }}
          className="link-style"
        >
          <div className="buttonArea">
            {GlobalConstants.EDIT_PROJECT.includes(roleName) && (
              <div
                className="edit_icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleActiveProject(project);
                }}
              >
                <Icon type="edit" />
              </div>
            )}
            {GlobalConstants.DELETE_PROJECT.includes(roleName) && (
              <div
                className="delete_icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.showDeleteConfirm();
                }}
              >
                <Icon type="delete" />
              </div>
            )}
          </div>
          <div style={{ padding: 2 }} className="card-style">
            <Card
              cover={
                <img
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${camera}`;
                  }}
                  src={imageSrc}
                  onLoad={() =>
                    this.setState({
                      isLoaded: true,
                    })
                  }
                  style={{ objectFit: "cover", height: 150 }}
                  alt="camera"
                  className="project-image"
                />
              }
            >
              <>
                <h3>{projectTitle}</h3>
                <p>
                  Created
                  {`${
                    this.checkTheDays(createdDate, 13)
                      ? ` ${moment(createdDate).fromNow()}`
                      : ` on ${moment(createdDate).format("DD-MM-YYYY")}`
                  }`}
                </p>
                <h3>{companyName}</h3>
              </>
            </Card>
          </div>
        </div>
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveProject: (projectId, projectTitle) =>
      dispatch(setActiveProject(projectId, projectTitle)),
  };
};

export default connect(null, mapDispatchToProps)(ProjectItem);
