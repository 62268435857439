import React, { Fragment, Component } from "react";
import "antd/dist/antd.css";
import { Upload, Button, Icon, message, Row, Col } from "antd";

import "./uploaddocument.scss";
import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
  ImageDomain,
  AttachmentType,
} from "constants/AppConstants";
import { showActionMessage, showNotifications } from "../NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import UploadService from "services/uploadService";

class ImageComponent extends Component {
  render() {
    const { index, file, handleRemove, isUploading } = this.props;
    return (
      <div key={index}>
        {!isUploading && (
          <a>
            <Icon type="delete" onClick={() => handleRemove(file.uid)} />
          </a>
        )}
        {` ${file.name}`}
      </div>
    );
  }
}
class UploadDocumentComponent extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
  }

  state = {
    fileList: [],
    DocumentsIds: [],
    pdfUpload: false,
    isUploading: false,
    showUpload: true,
    uploadedFiles: 0,
  };

  beforeUpload = (file) => {
    let isJPG =
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    if (isJPG) {
      this.setState({
        pdfUpload: true,
      });
    }
    if (!isJPG) {
      message.error("You can only upload PDF , DOC and DOCX file !");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Document must smaller than 5MB!");
    }
    return !isJPG || !isLt2M;
  };

  handleSave = () => {
    this.setState({
      isUploading: true,
    });
    const { fileList } = this.state;

    // fileList.map((file, i) => {
    //   const type = this.getFileType(file);
    //   let imageFile = new FormData();
    //   imageFile.append("file", file.originFileObj);
    //   this.getIdForImage(imageFile, type, fileList.length);
    // });

    fileList.map((file, i) => {
      const type = this.getFileType(file);
      let imageFile = new FormData();
      imageFile.append("file", file);
      this.getIdForImage(imageFile, type, fileList.length);
    });
  };

  getFileType = (file) =>
    file.type.split("/")[0] === "image"
      ? GlobalConstants.IMAGE
      : GlobalConstants.DOCUMENT;

  getIdForImage = (imageFile, type, totalFiles) => {
    const { handleDocument } = this.props;
    UploadService.projectImageUpload(ImageDomain?.aceScript, imageFile, {
      attachmentType: this.props.type || AttachmentType?.projectPicture,
      isBucketUrlRequired: true,
    }).then((res) => {
      let DocumentsIds = [...this.state.DocumentsIds];
      DocumentsIds.push({
        attachmentId: res?.data?.id,
        fileType: type,
      });
      this.setState(
        {
          DocumentsIds,
          uploadedFiles: this.state.uploadedFiles + 1,
        },
        () => {
          if (this.state.DocumentsIds.length === totalFiles) {
            this.setState(
              {
                fileList: [],
                uploadedFiles: 0,
                isUploading: false,
                showUpload: false,
              },
              () => handleDocument(this.state.DocumentsIds)
            );
            setTimeout(() => {
              this.setState({
                isUploading: false,
                showUpload: true,
                DocumentsIds: [],
              });
            }, 1);
            // showActionMessage(
            //   GlobalConstants.SUCCESS,
            //   GlobalConstants.DOCUMENT_UPLOADED_SUCCESSFULLY
            // );
          }
        }
      );
    });

    //   .catch((err) => {
    //     this.setState(
    //       {
    //         isUploading: false,
    //         showUpload: false,
    //       },
    //       () =>
    //         setTimeout(() => {
    //           this.setState({ showUpload: true });
    //         }, 1)
    //     );
    //     showNotifications(
    //       Notifications.ERROR,
    //       "Failed",
    //       handleError(
    //         err.response && err.response.data && err.response.data.message
    //       )
    //     );
    //     // this.props.getPermissions();
    //   });
  };

  handleRemove = (fileId) => {
    const { fileList } = this.state;
    this.setState({ fileList: fileList.filter((item) => item.uid !== fileId) });
  };

  handleRemoveAll = () => {
    this.setState({
      fileList: [],
    });
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const { fileList, isUploading, uploadedFiles, showUpload } = this.state;
    const { handleCancel, canUpload } = this.props;

    // const images = fileList.map(file => file.type.split("/")[0] === "image" && file);
    const images = fileList.filter(
      (file) => file.type.split("/")[0] === "image" && file
    );
    const docs = fileList.filter(
      (file) => file.type.split("/")[0] === "application" && file
    );
    const text = isUploading
      ? `Uploading...(${uploadedFiles}/${fileList.length})`
      : "Save";

    console.log(
      "fileList",
      fileList,
      fileList.map((file) => file.type),
      images,
      docs
    );

    const renderImages = (images) => {
      return images.map((file, i) => (
        <ImageComponent
          index={i}
          key={i}
          handleRemove={this.handleRemove}
          file={file}
          isUploading={isUploading}
        />
      ));
    };
    const renderDocuments = (docs) => {
      return docs.map((file, i) => (
        <div key={i}>
          {!isUploading && (
            <a>
              <Icon type="delete" onClick={() => this.handleRemove(file.uid)} />
            </a>
          )}
          {` ${file.name}`}
        </div>
      ));
    };

    // const props = {
    //   // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    //   // onChange: this.handleChange,
    //   showUploadList: false,
    //   multiple: true
    // };
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      listType: "picture",
      // defaultFileList: [...fileList],
      className: "upload-list-inline",
      multiple: true,
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
    };
    return (
      <Row>
        <Col>
          {showUpload && (
            <Upload
              {...props}
              // {...props}
              // customRequest={this.dummyRequest}
              // // accept=".doc,.docx,.pdf"
              // className="customUpload"
              // // beforeUpload={this.beforeUpload}
              // onChange={
              //   !this.state.pdfUpload === true
              //     ? info => this.handleChange(info)
              //     : null
              // }
            >
              <Button disabled={!canUpload} type="primary" icon="upload">
                Documents
              </Button>
            </Upload>
          )}
          {/* {images.length !== 0 && (
            <Row>
              <Col xl={24} style={{ marginTop: 10 }}>
                <h2>Images</h2>
              </Col>
              <Col xl={24}>{renderImages(images)}</Col>
            </Row>
          )}
          {docs.length !== 0 && (
            <Row>
              <Col xl={24} style={{ marginTop: 10 }}>
                <h2>Documents</h2>
              </Col>
              <Col xl={24}>{renderDocuments(docs)}</Col>
            </Row>
          )} */}
        </Col>
        {/* {fileList.length !== 0 && (
          <Col xl={24}>
            <Button
              type="primary"
              icon="delete"
              style={{ marginTop: 10 }}
              onClick={this.handleRemoveAll}
            >
              Remove all
            </Button>
          </Col>
        )} */}

        <Col xl={24} style={{ marginTop: 10 }}>
          <Col style={{ float: "right" }}>
            <Button
              type="ghost"
              style={{ marginRight: 10 }}
              disabled={isUploading}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={fileList.length === 0}
              type="primary"
              loading={isUploading}
              onClick={this.handleSave}
            >
              {text}
            </Button>
          </Col>
        </Col>

        {/* {fileList.map((file, i) => (
          <div key={i}>
            {`${file.name} `}
            <a>
              <Icon type="delete" onClick={() => this.handleRemove(file.uid)} />
            </a>
          </div>
        ))} */}
      </Row>
    );
  }
}

UploadDocumentComponent.defaultProps = {
  canUpload: true,
};

export default UploadDocumentComponent;
