import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Tag } from "antd";
import { Link } from "react-router-dom";

import BudjetService from "services/BudjetService";
import { Services, GlobalConstants } from "constants/AppConstants";
import {
  StartCase,
  checkFor,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";

const CastTechnicianTable = ({ type, activePermisision }) => {
  const castColumnsDayWise = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Character name",
      dataIndex: "characterName",
      align: "left",
      key: "characterName",
      render: (text) => StartCase(text),
    },
    {
      title: "Character Type",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => (
        <Tag className={`${text} dateTag`}>{ReplaceWithSpace(text)}</Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        // <Link
        //   to={`${
        //     record.actorId
        //       ? `${MenusRoute.ACCOUNTING}/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
        //       : record.crewId
        //       ? `/project/updateCrewprofile/${record.crewId}`
        //       : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
        //   }`}
        // >
        //   {(record.actorId || record.crewId) &&
        //     checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
        //       <Button type="link">More</Button>
        //     )}
        //   {!record.actorId &&
        //     !record.crewId &&
        //     checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
        //       <Button type="default">+ Actor</Button>
        //     )}
        // </Link>
        <Link
          to={`${
            record.actorId
              ? `/project/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
              : record.crewId
              ? `/project/updateCrewprofile/${record.crewId}`
              : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
          }`}
        >
          {(record.actorId || record.crewId) &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <Button type="link">More</Button>
            )}
          {!record.actorId &&
            !record.crewId &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="default">+ Actor</Button>
            )}
        </Link>
      ),
    },
  ];
  const castColumnsContract = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Character name",
      dataIndex: "characterName",
      align: "left",
      key: "characterName",
      render: (text) => StartCase(text),
    },
    {
      title: "Character Type",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => (
        <Tag className={`${text} dateTag`}>{ReplaceWithSpace(text)}</Tag>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "AMount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        // <Link
        //   to={`${
        //     record.actorId
        //       ? `${MenusRoute.ACCOUNTING}/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
        //       : record.crewId
        //       ? `/project/updateCrewprofile/${record.crewId}`
        //       : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
        //   }`}
        // >
        //   {(record.actorId || record.crewId) &&
        //     checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
        //       <Button type="link">More</Button>
        //     )}
        //   {!record.actorId &&
        //     !record.crewId &&
        //     checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
        //       <Button type="default">+ Actor</Button>
        //     )}
        // </Link>
        <Link
          to={`${
            record.actorId
              ? `/project/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
              : record.crewId
              ? `/project/updateCrewprofile/${record.crewId}`
              : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
          }`}
        >
          {(record.actorId || record.crewId) &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <Button type="link">More</Button>
            )}
          {!record.actorId &&
            !record.crewId &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="default">+ Actor</Button>
            )}
        </Link>
      ),
    },
  ];
  const technicianContract = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Role",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => <Tag className={`${text} dateTag`}>{text}</Tag>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
    {
      align: "center",
      title: "Action",
      render: (text, record) => (
        <Link
          to={`${
            record.actorId
              ? `/project/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
              : record.crewId
              ? `/project/updateCrewprofile/${record.crewId}`
              : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
          }`}
        >
          {(record.actorId || record.crewId) &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <Button type="link">More</Button>
            )}
          {!record.actorId &&
            !record.crewId &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="default">+ Actor</Button>
            )}
        </Link>
      ),
    },
  ];
  const technicianDayWise = [
    {
      title: "#",
      dataIndex: "sno",
      width: "8%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      key: "name",
      render: (text) => StartCase(text),
    },
    {
      title: "Role",
      dataIndex: "roleName",
      align: "left",
      key: "roleName",
      render: (text) => <Tag className={`${text} dateTag`}>{text}</Tag>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      align: "left",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "salary",
      align: "right",
      key: "salary",
      render: (text) => <span className="flrt">{text}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      align: "left",
      key: "paymentType",
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <Link
          to={`${
            record.actorId
              ? `/project/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
              : record.crewId
              ? `/project/updateCrewprofile/${record.crewId}`
              : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
          }`}
        >
          {(record.actorId || record.crewId) &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <Button type="link">More</Button>
            )}
          {!record.actorId &&
            !record.crewId &&
            checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="default">+ Actor</Button>
            )}
        </Link>
      ),
    },
  ];

  const budgetService = new BudjetService();
  const [DataSource, setDataSource] = useState([]);
  const [Loading, setLoading] = useState(true);
  const getColumn = (type) => {
    switch (type) {
      case Services.BudjetVariables.CAST_CONTRACT:
        return castColumnsContract;
      case Services.BudjetVariables.CAST_PER_DAY:
        return castColumnsDayWise;
      case Services.BudjetVariables.TECHNICIAN_CONTRACT:
        return technicianContract;
      case Services.BudjetVariables.TECHNICIAN_PER_DAY:
        return technicianDayWise;
      default:
        break;
    }
  };
  const column = getColumn(type);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (type === Services.BudjetVariables.TECHNICIAN_CONTRACT) {
      budgetService
        .budjetService(Services.BudjetVariables.TECHNICIAN_CONTRACT, "CONTRACT")
        .then((res) => {
          setDataSource(res.data.content || []);
          setLoading(false);
        });
    } else if (type === Services.BudjetVariables.TECHNICIAN_PER_DAY) {
      budgetService
        .budjetService(Services.BudjetVariables.TECHNICIAN_CONTRACT, "PER_DAY")
        .then((res) => {
          setDataSource(res.data.content || []);
          setLoading(false);
        });
    } else {
      budgetService
        .budjetService(Services.BudjetVariables.CAST_TECNICIANS, type)
        .then((res) => {
          setDataSource(res.data.content || []);
          setLoading(false);
        });
    }
  };
  return (
    <Row type="flex" justify="center" style={{ padding: 10 }}>
      <Col xl={24}>
        <Table
          bordered
          loading={Loading}
          dataSource={DataSource}
          columns={column}
          locale={{
            emptyText: <RecordNotFound text="No Cast & Technicians found" />,
          }}
        />
      </Col>
    </Row>
  );
};

export default CastTechnicianTable;
