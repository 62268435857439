import React from "react";
import { Tooltip } from "antd";

const TooltipComponentWithEllipsis = ({ text, placement = "left" }) => {
  return (
    <Tooltip placement={placement} title={text}>
      <div className="ellipsis">{text}</div>
    </Tooltip>
  );
};

export default TooltipComponentWithEllipsis;
