export const Errors = [
  {
    status: 200,
    message: "Success",
  },
  {
    status: 204,
    message: "No Content",
  },
  {
    status: 401,
    message: "Unauthorized",
  },
  {
    status: 404,
    message: "Bad Request",
  },
];
