import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Row } from "antd";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { connect } from "react-redux";
import { checkFor, Capitalize } from "utilities/ValidationHelper";

class CastTechnicianslist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { dataSource: nextProps.dataSource };
  }

  render() {
    const { dataSource } = this.state;
    const { activePermisision } = this.props;
    const columns = [
      {
        title: "#",
        dataIndex: "sno",
        align: "center",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "Name",
        dataIndex: "name",
        align: "center",
        render: (text, record) => (
          <span>{Capitalize(record.name || record.characterName)}</span>
        ),
      },

      {
        title: "Role",
        dataIndex: "roleName",
        align: "center",
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        align: "center",
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        render: (text, record) => (
          <span>
            <React.Fragment>
              <Row>
                <Link
                  to={`${
                    record.actorId
                      ? `/project/actorprofile/${record.actorId}/${record.characterName}/${record.roleName}/${record.characterId}`
                      : record.crewId
                      ? `/project/updateCrewprofile/${record.crewId}`
                      : `/project/audition/${record.roleName}/${record.characterId}/${record.characterName}`
                  }`}
                >
                  {(record.actorId || record.crewId) &&
                    checkFor(
                      activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    ) && <Button type="link">More</Button>}
                  {!record.actorId &&
                    !record.crewId &&
                    checkFor(
                      activePermisision,
                      GlobalConstants.PERMISSION_ADD
                    ) && <Button type="default">+ Actor</Button>}
                </Link>
              </Row>
            </React.Fragment>
          </span>
        ),
      },
    ];
    return (
      <Fragment>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          locale={{
            emptyText: <RecordNotFound text="No Cast & Technicians found" />,
          }}
          bordered
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CastTechnicianslist);
