import React, { Component } from "react";
import {
  Modal,
  Col,
  Row,
  Button,
  Icon,
  Upload,
  Input,
  InputNumber,
  Select,
  Form,
} from "antd";
import { showNotifications } from "components/shared/NotificationComponent";
import Uploaddocumentfile from "components/uploaddocumentfile";
import UploadImage from "components/shared/UploadImage/UploadImage";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import MakeupTabService from "services/SceneOverviewService/MakeupTabService";
import FileCard from "components/shared/FileCardComponent";
import { numberOnly } from "utilities/ValidationHelper";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import ImageViewer from "components/shared/ImageViewer";

const Option = { Select };

class LocationConfirmModel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  handleCancel = (e) => {
    this.props.closeModal();
  };

  handleSubmit = (e) => {
    this.props.submitData();
  };

  render() {
    const {
      closeModal,
      isModalVisible,
      confirmedLocationName,
      // characterName
    } = this.props;
    return (
      <div>
        <Modal
          destroyOnClose
          onCancel={this.handleCancel}
          visible={isModalVisible}
          key={2}
          width="450px"
          footer={
            <Row
              gutter={12}
              type="flex"
              justify="end"
              // style={{ marginTop: "20px" }}
            >
              <Col>
                <Button onClick={closeModal}>Cancel</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={this.handleSubmit}>
                  Ok
                </Button>
              </Col>
            </Row>
          }
        >
          <h3
            className="font-600 primary-font-color text-center"
            style={{ fontSize: "20px", margin: 0 }}
          >
            {confirmedLocationName} is already confirmed {/* {characterName} */}
          </h3>
          <h3 className="font-600 mb-20 text-center primary-font-color">
            Are You Sure Want To continue ?
          </h3>
        </Modal>
      </div>
    );
  }
}

// UpdateMakeupModal.propTypes = {
//   name: PropTypes.string.isRequired
// };
const locationconfirmmodel = Form.create()(LocationConfirmModel);

export default locationconfirmmodel;
