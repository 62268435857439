import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { Table, Tag, Row, Col } from "antd";

import EquipmentProjectService from "services/EquipmentProjectService";
import { EquipmentVariables } from "constants/AppConstants/ServicesConstants";
import moment from "moment";
import { GlobalConstants, Screens, LocalStorage } from "constants/AppConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { checkFor, Capitalize } from "utilities/ValidationHelper";
import ThemeColors from "constants/ThemeConstants/variables";
import TooltipIcon from "components/shared/TooltipIcon";
import TooltipComponent from "components/shared/TooltipComponent";

class SpecialEquipment extends Component {
  constructor(props) {
    super(props);
    this._equipmentProjectService = new EquipmentProjectService();
    this.state = {
      dataSource: [],
      isDataFetched: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this._equipmentProjectService
      .equipmentProjectService(EquipmentVariables.GET_SPECIAL_EQUIPMENT)
      .then((res) => {
        this.setState({
          dataSource: res.data.equipments,
          isDataFetched: true,
        });
      })
      .catch((err) => {
        this.setState({
          isDataFetched: true,
        });
      });
  };

  render() {
    const { isDataFetched, dataSource } = this.state;
    const { activePermisision } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "sno",
        align: "center",
        width: "5%",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "Shooting Date",
        dataIndex: "shootDate",
        // align: "center",
        width: "10%",
        render: (text) => (
          <>
            <Tag
              style={{ marginLeft: 0, borderRadius: 12, marginBottom: 3 }}
              color={ThemeColors.primaryColor}
            >
              {text}
            </Tag>
          </>
        ),
      },
      {
        title: "Scene Location",
        dataIndex: "sceneLocation",
        width: "20%",
        render: (text, record) => (
          // <Link
          //   to={`/project/location-audition/${record.sceneLocation}/${record.sceneLocationId}`}
          // >
          //   {record.sceneLocation}
          // </Link>
          <p className="commaContainer">
            {record.locations &&
              record.locations.map((location) => (
                <span>{location.sceneLocation}</span>
              ))}
          </p>
        ),
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocation",
        width: "20%",
        render: (text, record) => (
          // <Link
          //   to={`/project/update-location/${record.actualLocationId}/${record.actualLocation}/${record.sceneLocation}/${record.sceneLocationId}`}
          // >
          //   {Capitalize(record.actualLocation)}
          // </Link>
          <p className="commaContainer">
            {record.locations &&
              record.locations.map((location) => (
                <span>{Capitalize(location.actualLocation)}</span>
              ))}
          </p>
        ),
      },
      {
        title: "Scene No",
        dataIndex: "scenes",
        width: "10%",
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.EQUIPMENTS
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                    }}
                  >
                    {/* <Link style={{ color: "white" }}>{scene.sceneNumber}</Link> */}
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },
      // {
      //   title: "Status",
      //   dataIndex: "state",
      //   align: "center",
      //   render: (value, record) => (
      //     <Tag className={`statusTag ${record.stateEnum}`}>{record.state}</Tag>
      //   )
      // },
      {
        title: "Cost Estimation",
        dataIndex: "totalPrice",
        align: "center",
        width: "10%",
        render: (text) =>
          text && <div style={{ textAlign: "right" }}>{text}</div>,
      },
      {
        title: "Equipments",
        dataIndex: "equipments",
        align: "center",
        width: "20%",
        render: (equipments, record) =>
          equipments &&
          equipments.map((equipment, i) => (
            <Tag className={`EquipmentTag`}>
              {equipment.equipmentName} ({equipment.count})
            </Tag>
          )),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "",
        dataIndex: "type",
        align: "center",
        render: (text, record) => (
          <div
            onClick={() => {
              const date = moment(record?.shootDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              );
              this.props.history.push(
                `/project/special-equipments-details/${date}`
              );
            }}
          >
            <TooltipIcon icon="more" />
          </div>
        ),
      });
    }

    return (
      <Fragment>
        <Row type="flex" justify="center">
          <Col xs={22} xl={24}>
            <Table
              // scroll={{ y: "50vh" }}
              pagination={false}
              bordered
              dataSource={dataSource}
              loading={!isDataFetched}
              columns={columns}
              locale={{
                emptyText: isDataFetched && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.EQUIPMENT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialEquipment);
