import React, { useState } from "react";
import { Row, Col } from "antd";

import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import CostumeMakeupDescriptionTable from "components/shared/CostumeMakeupDescriptionTable";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";

const MakeupDescription = (props) => {
  const [costumeMakeupId, setcostumeMakeupId] = useState(
    props.location.state.costumeMakeupId
  );
  const [characterName, setcharacterName] = useState(
    props.location.state.characterName
  );
  const [pageType, setpageType] = useState(props.location.state.pageType);
  const [totalCost, settotalCost] = useState(props.location.state.totalCost);
  return (
    <Row type="flex" justify="center" align="middle">
      <Col xs={24}>
        <Title hasBack {...props}>
          <TitleText>{pageType} Overview</TitleText>
        </Title>
      </Col>

      <Col xs={22} style={{ marginTop: 10 }}>
        <Row>
          <Col xs={24}>
            <CostumeMakeupDescriptionTable
              {...props}
              sceneCostumeMakeupId={costumeMakeupId}
              characterName={characterName}
              totalCost={totalCost}
              pageType={pageType}
              activePermisision={props.activePermisision}
              getPermissions={props.getPermissions}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.MAKEUP, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeupDescription);
