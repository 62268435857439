import React, { Component } from "react";

class Cast extends Component {
  componentDidMount() {
    console.log("componentDidMount Cast");
  }

  render() {
    return <div>Cast</div>;
  }
}

export default Cast;
