import React, { Component } from "react";
import { Table, Divider, Tag } from "antd";
import moment from "moment";
import { StartCase, ReplaceWithSpace } from "utilities/ValidationHelper";

const columns = [
  {
    title: "#",
    align: "center",
    width: "6%",
    render: (text, record, index) => index + 1,
  },
  {
    title: "description",
    dataIndex: "description",
    width: "16%",
    className: "category-width",
    // editable: true,
    align: "center",
  },
  {
    title: "shoot Date",
    dataIndex: "shootDate",
    width: "15%",
    align: "center",
  },
  {
    title: "PAYMENT DATE",
    dataIndex: "paymentDate",
    width: "15%",
    align: "center",
  },

  {
    title: "PAYEE",
    dataIndex: "receiverName",
    width: "10%",
  },
  {
    title: "AMOUNT PAID",
    dataIndex: "amountPaid",
    width: "15%",
    align: "right",
    width: "10%",
  },
  {
    title: "Payment Type",
    dataIndex: "paymentType",
    align: "center",
    width: "10%",
    render: (text) => ReplaceWithSpace(text),
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
    align: "center",
    width: "10%",
    render: (value, record) => (
      <Tag className={`statusTag ${record.paymentMode}`}>
        {record.paymentMode}
      </Tag>
    ),
  },
  {
    title: "REFERENCE NO",
    dataIndex: "referenceNumber",
    align: "center",
    width: "10%",
  },
  {
    title: "VOUCHER NO",
    dataIndex: "vocherNumber",
    align: "center",
    width: "10%",
  },
];

export default class AccountOverviewTable extends Component {
  render() {
    const { detailedViewData } = this.props;
    detailedViewData.map(
      (l) => (l.shootDate = moment(l.shootDate).format("DD-MMM-YYYY"))
    );
    detailedViewData.map(
      (l) => (l.paymentDate = moment(l.paymentDate).format("DD-MMM-YYYY"))
    );
    return (
      <Table
        columns={columns}
        dataSource={detailedViewData}
        bordered
        pagination={false}
      />
    );
  }
}
