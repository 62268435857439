import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class CostumesMakeupService {
  service(type, id, characterName, datas) {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    let activeProject = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let data = null;
    if (id) {
      data = `characterId=${id}`;
    } else if (characterName) {
      data = `generalName=${characterName}`;
    }
    switch (type) {
      case Services.CostumesMakeupVariables.GET_COSTUMES_LIST:
        return axios.get(
          `${getScriptAPIURL()}/projects/${activeProject}/costume?costType=${datas}`,
          config
        );
      case Services.CostumesMakeupVariables.GET_COSTUMES_LIST_BY_CHARC:
        return axios.get(
          `${getScriptAPIURL()}/characters/costumes?paymentType=${datas}&${data}`,
          config
        );
      // case Services.CostumesMakeupVariables.UPDATE_COSTUME_LIST:
      //   return axios.put(`${getScriptAPIURL()}costumes/${id}`, datas, config);
      case Services.CostumesMakeupVariables.UPDATE_COSTUME_LIST:
        return axios.patch(
          `${getScriptAPIURL()}/characters/costumes`,
          datas,
          config
        );
      case Services.CostumesMakeupVariables.DELETE_COSTUME_LIST:
        return axios.delete(`${getScriptAPIURL()}/costumes/${id}`, config);
      case Services.CostumesMakeupVariables.GET_MAKEUP_LIST:
        return axios.get(
          `${getScriptAPIURL()}/projects/${activeProject}/makeup?costType=${datas}
          `,
          config
        );
      case Services.CostumesMakeupVariables.GET_MAKEUP_LIST_BY_CHARC:
        return axios.get(
          `${getScriptAPIURL()}/characters/makeup?paymentType=${datas}&${data}`,
          config
        );
      // case Services.CostumesMakeupVariables.UPDATE_MAKEUP_LIST:
      //   return axios.put(`${getScriptAPIURL()}makeup/${id}`, datas, config);
      case Services.CostumesMakeupVariables.UPDATE_MAKEUP_LIST:
        return axios.patch(
          `${getScriptAPIURL()}/characters/makeup`,
          datas,
          config
        );
      case Services.CostumesMakeupVariables.DELETE_MAKEUP_LIST:
        return axios.delete(`${getScriptAPIURL()}/costumes/${id}`, config);
      case Services.CostumesMakeupVariables.GET_MAKEUP_PROFILE:
        return axios.get(
          `${getScriptAPIURL()}/characters/${id}/costumes`,
          config
        );
      case Services.CostumesMakeupVariables.COSTUMES_DELETE_DOC:
        return axios.delete(
          `${getScriptAPIURL()}/costumes/documents/${id}`,
          config
        );
      case Services.CostumesMakeupVariables.MAKEUP_DELETE_DOC:
        return axios.delete(
          `${getScriptAPIURL()}/makeup/documents/${id}`,
          config
        );
      default:
        break;
    }
  }
}
