import React, { useState, Fragment } from "react";
import { Row, Col, Button, Tabs } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

// import "./accounting.scss";
import { Services, MenusRoute } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { ReplaceWithSpace, checkFor } from "utilities/ValidationHelper";
import { setCostumeLeftActiveTab } from "store/action";
import ProjectReport from "./ProjectReport";
import AccountsReport from "./AccountsReport";
import DataIncompletionReport from "./DataIncompletionReport";
import BudgetEstimationReport from "./BudgetEstimationReport";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const { TabPane } = Tabs;

const ReportTabs = [
  GlobalConstants.PROJECT_REPORTS,
  GlobalConstants.DATA_INCOMPLETION_REPORT,
  GlobalConstants.BUDGET_ESTIMATION_REPORT,
  GlobalConstants.ACCOUNTS_REPORT,
];

const components = [
  { name: GlobalConstants.PROJECT_REPORTS, component: ProjectReport },
  {
    name: GlobalConstants.DATA_INCOMPLETION_REPORT,
    component: DataIncompletionReport,
  },
  {
    name: GlobalConstants.BUDGET_ESTIMATION_REPORT,
    component: BudgetEstimationReport,
  },
  { name: GlobalConstants.ACCOUNTS_REPORT, component: AccountsReport },
];

const ReportMain = (props) => {
  const { setCostumeLeftActiveTab, costumeLeftActiveTab, activePermisision } =
    props;
  const [ActiveTab, setActiveTab] = useState(costumeLeftActiveTab);
  const handleChange = (e) => {
    setCostumeLeftActiveTab(e);
  };

  const renderTabs = (activePermisision) => {
    if (!checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      ReportTabs.splice(2, 2);
    }
    let tabs = [];
    ReportTabs.map((tab, i) => {
      let Component = components[i].component;
      tabs.push(
        <TabPane tab={ReplaceWithSpace(tab)} key={i + 1}>
          <Component />
        </TabPane>
      );
    });
    return tabs;
  };

  return (
    <Fragment>
      <Row span={24}>
        <Title>
          <TitleText>Report </TitleText>
        </Title>
      </Row>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row style={{ padding: 10 }}>
          <Col>
            <Tabs
              defaultActiveKey={ActiveTab}
              onChange={handleChange}
              animated
              destroyInactiveTabPane
            >
              {renderTabs(activePermisision)}
            </Tabs>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { costumeLeftActiveTab },
  user: { menus },
}) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.REPORT, menus),
    costumeLeftActiveTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportMain);
