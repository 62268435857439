import React, { Component, Fragment } from "react";
import { Table, Input, Icon, Button } from "antd";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import DeleteComponent from "components/shared/DeleteComponent";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import { checkFor } from "utilities/ValidationHelper";
import SchedularService from "services/SchedularServices";
import { Services, LocalStorage, Notifications } from "constants/AppConstants";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";

const Config = [
  {
    name: "Crew List",
    value: "crews",
    crewType: "CREW",
  },
  {
    name: "Actor List",
    value: "actors",
    crewType: "ACTOR",
  },
  {
    name: "Junior List",
    value: "juniors",
    crewType: "JUNIOR_ARTIST",
  },
];
class AttendanceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.page,
      shootDate: props.shootDate,
      type: this.getType(props.page),
      crewType: this.getCrewtype(props.page),
      dataSource: [],
      totalRecords: [],
      count: 0,
    };
    this.scheduler = new SchedularService();
  }

  getType = (type) => {
    let obj = Object.assign({}, ...Config.filter((conf) => conf.name === type));
    return obj.value;
  };
  getCrewtype = (type) => {
    let obj = Object.assign({}, ...Config.filter((conf) => conf.name === type));
    return obj.crewType;
  };

  fetchData = () => {
    this.scheduler
      .services(
        Services.SchedularVariables.GET_ATTENDANCE,
        localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        null,
        this.state.shootDate
      )
      .then((res) => {
        let dataSource = [];
        res.data[this.state.type] &&
          res.data[this.state.type].map((data, i) => {
            dataSource.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
          wholeData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  handleAdd = () => {
    const { count, dataSource, crewType } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      category: null,
      quantity: null,
      crewType,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, productionAccountId }) => {
    if (productionAccountId) {
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  onChange(value, record, type) {
    record[type] = value;
    this.handleSave(record, value, type);
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.category && row.quantity) {
      this.handleUpdate(row);
    }
    this.setState({ dataSource: newData });
  };

  handleUpdate = (row) => {
    const { wholeData, dataSource, type } = this.state;
    const data = {
      ...wholeData,
      [type]: dataSource,
    };
    this.scheduler
      .services(
        Services.SchedularVariables.UPDATE_ATTENDANCE,
        localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        null,
        data
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          // "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  render() {
    const { dataSource, totalRecords, page } = this.state;
    const { activePermisision } = this.props;

    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        width: "10%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Details",
        dataIndex: "category",
        key: "category",
        width: "55%",
        render: (text, record) => (
          <Input
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
              record.attandanceId
            }
            autoFocus={!record.attandanceId}
            defaultValue={text}
            style={{ width: "100%" }}
            onFocus={(e) =>
              this.setState({
                activeValue: e.target.value,
              })
            }
            onBlur={(e) =>
              e.target.value !== this.state.activeValue &&
              this.onChange(e.target.value, record, "category")
            }
          />
        ),
      },
      {
        title: "Count",
        dataIndex: "quantity",
        key: "quantity",
        width: "25%",
        render: (text, record) => (
          <Input
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
            }
            defaultValue={text}
            onFocus={(e) => {
              e.currentTarget.select();
              this.setState({
                activeValue: e.target.value,
              });
            }}
            onBlur={(e) =>
              e.target.value !== this.state.activeValue &&
              this.onChange(e.target.value, record, "quantity")
            }
            style={{ width: "100%" }}
            type="number"
          />
        ),
      },
      // {
      //   title: "#",
      //   align: "center",
      //   width: "10%",
      //   render: (text, record) => (
      //     <>
      //       <DeleteComponent
      //         record={record}
      //         onClick={record => this.handleDelete(record)}
      //       />
      //       {/* <Divider
      //           type="vertical"
      //           style={{ marginLeft: 5, marginRight: 5 }}
      //         /> */}
      //     </>
      //   )
      // }
    ];
    return (
      <Fragment>
        <Title>
          <TitleText>{page}</TitleText>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ float: "right" }}
              // disabled={hasNewRow}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          )}
        </Title>
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.SCHEDULER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceTable);
