import React, { Component } from "react";

import ScriptOverview from "components/ScriptOverview";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";

class SceneOverview extends Component {
  render() {
    return (
      <div>
        <ScriptOverview {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(null, mapDispatchToProps)(SceneOverview);
