import React, { Component, Fragment } from "react";
import { Table, Button, Row, Col, Icon, Input, Select, DatePicker } from "antd";
import DeleteComponent from "components/shared/DeleteComponent";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { checkFor, StartCase } from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import TooltipIcon from "components/shared/TooltipIcon";
import { MenusRoute, Services, Notifications } from "constants/AppConstants";
import TransportService from "services/TransportService";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import TextArea from "antd/lib/input/TextArea";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

class TransportMoreDetails extends Component {
  constructor(props) {
    super(props);
    this._transportService = new TransportService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      scheduleVehicleId: props.match.params.scheduleVehicleId,
      details: props.location.state.details,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({
      isLoading: true,
      // dataSource: []
    });
    let dataSource = [];
    const { scheduleVehicleId } = this.state;
    this._transportService
      .transportService(
        Services.TransportVariables.GET_VEHICLE_PASSENGER_BY_ID,
        scheduleVehicleId
      )
      .then((res) => {
        res.data.passengers &&
          res.data.passengers.map((passenger) => {
            dataSource.push({ ...passenger });
          });
        this.setState({
          dataSource,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  handleDelete = ({ key, vehiclePassengerId }) => {
    if (vehiclePassengerId) {
      this._transportService
        .transportService(
          Services.TransportVariables.DELETE_SCHEDULE_VEHICLE,
          vehiclePassengerId,
          null,
          true
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  handleBlur = (value, record) => {
    this._transportService
      .transportService(
        Services.TransportVariables.UPDATE_PICKUP,
        value,
        record.vehiclePassengerId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  render() {
    const { dataSource, isLoading, totalRecords, details } = this.state;
    const { activePermisision } = this.props;
    var dataSource1 = [
      {
        actorId: 0,
        actorName: "actor",
        characterType: "MAIN",
        crewId: "string",
        crewName: "crew",
        pickupAddress: {
          addressLine: "string",
          addressLine2: "string",
          addressTypeId: 0,
          city: "string",
          country: "string",
          id: 0,
          landMark: "string",
          state: "string",
          zipCode: "string",
        },
        dropLocation: "string",
        dropLocationId: 0,
        equipmentId: 0,
        equipmentName: "string",
        vehiclePassengerId: 0,
      },
    ];
    const columns = [
      {
        title: "#",
        align: "center",
        width: "8%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Actor/Crew/Equipment",
        dataIndex: "name",
        key: "name",
        align: "left",
        width: "45%",
        render: (text, record) => (
          <div>
            {StartCase(
              (record && record.crewName) ||
                record.actorName ||
                record.equipmentName
            )}
          </div>
        ),
      },
      // {
      //   title: "Pickup",
      //   dataIndex: "pickupAddress",
      //   key: "pickupAddress",
      //   align: "center",
      //   width: "10%",
      //   render: (text, record) => (
      //     <div className="commaContainer">
      //       {text &&
      //         Object.values(text).map(a => {
      //           if (isNaN(a)) return <span>{a}</span>;
      //         })}
      //     </div>
      //   )
      // },
      {
        title: "Pickup Address",
        dataIndex: "pickUpAddressText",
        width: "39%",
        render: (text, record) =>
          !isLoading && (
            <TextArea
              className="caps"
              defaultValue={text}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
              }
              onBlur={(e) => this.handleBlur(e.target.value, record)}
            />
          ),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "Action",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "8%",
        render: (text, record) => (
          <>
            <DeleteComponent
              record={record}
              onClick={(record) => this.handleDelete(record)}
            />
          </>
        ),
      });
    }

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>
              Car Name - {StartCase(details.vehicleName)} Drop location -{" "}
              {StartCase(details.dropLocationName)}
            </TitleText>
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ padding: 10 }}
        >
          <Col xl={18}>
            <Table
              loading={isLoading}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.TRANSPORT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransportMoreDetails);
