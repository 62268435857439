const OnboardVariables = {
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  LOGOUT: "LOGOUT",
  SUCCESS: "Success",
  ERROR: "Error",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  RESET_PASSWORD: "RESET_PASSWORD",
  GET_COUNTRY: "GET_COUNTRY",
  GET_EXISTING_LOCATION: "GET_EXISTING_LOCATION",
  GET_EXISTING_LOCATION_DETAILS: "GET_EXISTING_LOCATION_DETAILS",
};
const NotificationVariables = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
};

const DashboardVariables = {
  CREATE_PROJECT: "CREATE_PROJECT",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  GET_COMPANY: "GET_COMPANY",
  GET_PROJECT_LIST: "GET_PROJECT_LIST",
  DELETE_PROJECT: "DELETE_PROJECT",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  GET_SCENE_LIST_WITH_NO_CHAR_MAP: "GET_SCENE_LIST_WITH_NO_CHAR_MAP",
  GET_DETAILED_VIEW_DATA: "GET_DETAILED_VIEW_DATA",
  GET_DETAILED_VIEW_DATA_CATEGORY: "GET_DETAILED_VIEW_DATA_CATEGORY",
  GET_DETAILED_CHART_DATA: "GET_DETAILED_CHART_DATA",
  GET_LOCATIONS_WITHOUT_ACTUAL_LOCATION:
    "GET_LOCATIONS_WITHOUT_ACTUAL_LOCATION",
};

const ScriptVariables = {
  DOWNLOAD: "DOWNLOAD",
  UPLOAD: "UPLOAD",
};

const SceneOVerviewVariable = {
  GET_SCENE: "GET_SCENE",
  UPDATE_SCENE: "UPDATE_SCENE",
  UPDATE_SCENE_STATUS: "UPDATE_SCENE_STATUS",
  GET_ACTOR: "GET_ACTOR",
  GET_JUNIOR_ANIMAL_ACTOR: "GET_JUNIOR_ANIMAL_ACTOR",
  ADD_ACTOR: "ADD_ACTOR",
  DELETE_ACTOR: "DELETE_ACTOR",
  GET_LOCATION: "GET_LOCATION",
  GET_ACTUAL_LOCATION_LIST_BY_PROJECT: "GET_ACTUAL_LOCATION_LIST_BY_PROJECT",
  GET_LOCATION_LIST_BY_PROJECT: "GET_LOCATION_LIST_BY_PROJECT",
  GET_ACTUAL_LOCATION: "GET_ACTUAL_LOCATION",
  GET_CHARC_NAME: "GET_CHARC_NAME",
  GET_COSTUMES: "GET_COSTUMES",
  GET_CHARC_NAME_LIST: "GET_CHARC_NAME_LIST",
  GET_CHARC_NAME_LIST_REPORT: "GET_CHARC_NAME_LIST_REPORT",
  GET_CHARC_NAME_LIST_GENERAL_COSTUME: "GET_CHARC_NAME_LIST_GENERAL_COSTUME",
  ADD_LOCATION: "ADD_LOCATION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  GET_MAKEUP: "GET_MAKEUP",
  UPDATE_COSTUME_LIST: "UPDATE_COSTUME_LIST",
  UPDATE_MAKEUP_LIST: "UPDATE_MAKEUP_LIST",
  UPDATE_MAKEUP: "UPDATE_MAKEUP",
  GET_COSTUME_BY_ID: "GET_COSTUME_BY_ID",
  GET_DETAILED_CHART_DATA: "GET_DETAILED_CHART_DATA",
  GET_MAKEUP_BY_ID: "GET_MAKEUP_BY_ID",
  GET_ACTORS_BY_ID: "GET_ACTORS_BY_ID",
  DELETE_LOCATION: "DELETE_LOCATION",
  GET_LOCATION_BY_ID: "GET_LOCATION_BY_ID",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  CREATE_COSTUME_BUDJET: "CREATE_COSTUME_BUDJET",
  GET_PREVIOUS_SCENE_IDS: "GET_PREVIOUS_SCENE_IDS",
  CREATE_MAKEUP_BUDJET: "CREATE_MAKEUP_BUDJET",
  ADD_JUNIOR_ACTOR: "ADD_JUNIOR_ACTOR",
  ADD_ANIMAL_ACTOR: "ADD_ANIMAL_ACTOR",
  DEL_ACTOR_CARD: "DEL_ACTOR_CARD",
  SCENE_LOCATION: "SCENE_LOCATION",
  EDIT_JUNIOR_ACTOR: "EDIT_JUNIOR_ACTOR",
  EDIT_ANIMAL_ACTOR: "EDIT_ANIMAL_ACTOR",
  GET_MAKEUP_DATA: "GET_MAKEUP_DATA",
  GET_CONFIRMED_LOCATION: "GET_CONFIRMED_LOCATION",
  DRAG_DROP: "DRAG_DROP",
  PAN_DROP_DOWN: "PAN_DROP_DOWN",
  DESIGNATION_DROP_DOWN: "DESIGNATION_DROP_DOWN",
  SAVE_COSTUME_TAB_TABLE: "SAVE_COSTUME_TAB_TABLE",
  GENERAL_SAVE_COSTUME_TAB_TABLE: "GENERAL_SAVE_COSTUME_TAB_TABLE",
  GENERAL_SAVE_MAKEUP_TAB_TABLE: "GENERAL_SAVE_MAKEUP_TAB_TABLE",
  SAVE_MAKEUP_TAB_TABLE: "SAVE_MAKEUP_TAB_TABLE",
  DELETE_COSTUME_TAB_TABLE: "DELETE_COSTUME_TAB_TABLE",
  DELETE_MAKEUP_TAB_TABLE: "DELETE_MAKEUP_TAB_TABLE",
  PAN_DROP_DOWN: "PAN_DROP_DOWN",
  DESIGNATION_DROP_DOWN: "DESIGNATION_DROP_DOWN",
  GETTING_MAKEUP: "GETTING_MAKEUP",
  POSTING_MAKEUP: "POSTING_MAKEUP",
  UPDATING_MAKEUP: "UPDATING_MAKEUP",
  GET_SCENE_NUMBERS_DROPDOWN: "GET_SCENE_NUMBERS_DROPDOWN",
  GET_DASH_PIE_CHART: "GET_DASH_PIE_CHART",
  GET_DASH_BAR_CHART: "GET_DASH_BAR_CHART",
  GET_DASH_CHART: "GET_DASH_CHART",
  GET_DETAILED_VIEW_DATA: "GET_DETAILED_VIEW_DATA",
  GET_DETAILED_VIEW_DATA_CATEGORY: "GET_DETAILED_VIEW_DATA_CATEGORY",
  GET_DETAILED_VIEW_CHART_DATA: "GET_DETAILED_VIEW_CHART_DATA",
  GET_NEXT_SCENE_NUMBERS_DROPDOWN: "GET_NEXT_SCENE_NUMBERS_DROPDOWN",
  GET_NEW_SCENE_NUMBERS_DROPDOWN: "GET_NEW_SCENE_NUMBERS_DROPDOWN",
  CREATE_INSERT_SCENE: "CREATE_INSERT_SCENE",
  DELETE_SCENE: "DELETE_SCENE",
  GET_GENERAL_COSTUME_BY_PROJECT: "GET_GENERAL_COSTUME_BY_PROJECT",
  GET_GENERAL_MAKEUP_BY_PROJECT: "GET_GENERAL_MAKEUP_BY_PROJECT",
  GET_GENERAL_COSTUME_DETAILS_BY_PROJECT:
    "GET_GENERAL_COSTUME_DETAILS_BY_PROJECT",
  GET_GENERAL_MAKEUP_DETAILS_BY_PROJECT:
    "GET_GENERAL_MAKEUP_DETAILS_BY_PROJECT",
  SAVE_GENERAL_COSTUME_DETAILS: "SAVE_GENERAL_COSTUME_DETAILS",
  SAVE_GENERAL_MAKEUP_DETAILS: "SAVE_GENERAL_MAKEUP_DETAILS",
  UPDATE_GENERAL_COSTUME_DETAILS: "UPDATE_GENERAL_COSTUME_DETAILS",
  UPDATE_GENERAL_MAKEUP_DETAILS: "UPDATE_GENERAL_MAKEUP_DETAILS",
  DELETE_GENERAL_COSTUME_DETAILS: "DELETE_GENERAL_COSTUME_DETAILS",
  DELETE_GENERAL_MAKEUP_DETAILS: "DELETE_GENERAL_MAKEUP_DETAILS",
  GET_EXISTING_LOCATION: "GET_EXISTING_LOCATION",
  GET_EXISTING_LOCATION_DETAILS: "GET_EXISTING_LOCATION_DETAILS",
};

const GlobalVariables = {
  GET_IMAGE_ID: "GET_IMAGE_ID",
  GET_PAYMENT_LIST_DD: "GET_PAYMENT_LIST_DD",
  GET_MASTER_DATA: "GET_MASTER_DATA",
  GET_WARNING_DATA: "GET_WARNING_DATA",
  GET_PASSANGER_TYPE: "GET_PASSANGER_TYPE",
  GET_MASTER_DATA_RATE: "GET_MASTER_DATA_RATE",
  GOVERNMENT_APPROVAL: "GOVERNMENT_APPROVAL",
  GET_IMAGE_FROM_ID: "GET_IMAGE_FROM_ID",
  APPROVAL_STATUS: "APPROVAL_STATUS",
  GET_IMAGE_IDS: "GET_IMAGE_IDS",
  GET_SCENE_NUMBERS: "GET_SCENE_NUMBERS",
  GET_SCENE_LOCATION: "GET_SCENE_LOCATION",
  GET_PROJECT_PERCENTAGE: "GET_PROJECT_PERCENTAGE",
  DOWNLOAD_VOUCHER: "DOWNLOAD_VOUCHER",
  DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
  DOWNLOAD_SCHEDULER: "DOWNLOAD_SCHEDULER",
  CALL_SHEET: "CALL_SHEET",
  BREAK: "BREAK",
  SCHEDULE_STATE: "SCHEDULE_STATE",
  PHONE_CODE: "PHONE_CODE",
  GET_DESCRIPTION_TYPE: "GET_DESCRIPTION_TYPE",
};

const ActorVariables = {
  GET_ACTORS_LIST: "GET_ACTORS_LIST",
  GET_ACTORS_MAIN: "GET_ACTORS_MAIN",
  GET_ACTORS_SUPPORTING: "GET_ACTORS_SUPPORTING",
  GET_ACTORS_JUNIOR: "GET_ACTORS_JUNIOR",
  GET_ACTORS_ANIMAL: "GET_ACTORS_ANIMAL",
  GET_ACTOR_ID: "GET_ACTOR_ID",
  DELETE_ACTOR_ID: "DELETE_ACTOR_ID",
  DELETE_ACTOR_PAYMENT_TERMS_ID: "DELETE_ACTOR_PAYMENT_TERMS_ID",
  POST_PAYMENT_DETAILS: "POST_PAYMENT_DETAILS",
  LEGAL_AGREEMENT_SAVE: "LEGAL_AGREEMENT_SAVE",
  LEGAL_AGREEMENT_UPDATE: "LEGAL_AGREEMENT_UPDATE",
  LEGAL_AGREEMENT_GET: "LEGAL_AGREEMENT_GET",
  LEGAL_AGREEMENT_DOCUMENT_DELETE: "LEGAL_AGREEMENT_DOCUMENT_DELETE",
  LEGAL_AGREEMENT_DOCUMENT_SAVE: "LEGAL_AGREEMENT_DOCUMENT_SAVE",
  PUT_PAYMENT_DETAILS: "PUT_PAYMENT_DETAILS",
  POST_STAFF_DETAILS: "POST_STAFF_DETAILS",
  PUT_STAFF_DETAILS: "PUT_STAFF_DETAILS",
  POST_ACTORAVAILABILITY_DETAILS: "POST_ACTORAVAILABILITY_DETAILS",
  PUT_ACTORAVAILABILITY_DETAILS: "PUT_ACTORAVAILABILITY_DETAILS",
  GET_CONFIRMED_ACTOR: "GET_CONFIRMED_ACTOR",
  GET_ANIMAL_DETAILS: "GET_ANIMAL_DETAILS",
  UPDATE_ANIMAL_STATUS: "UPDATE_ANIMAL_STATUS",
  DEL_CHARACTER: "DEL_CHARACTER",
  CHECK_SHOOT_DATE: "CHECK_SHOOT_DATE",
  GET_JUNIOR_CHARC: "GET_JUNIOR_CHARC",
  GET_ANIMAL_CHARC: "GET_ANIMAL_CHARC",
  UPDATE_ACTOR_NAME: "UPDATE_ACTOR_NAME",
  GET_ACTOR_OPTIONS: "GET_ACTOR_OPTIONS",
  GET_CHARACTER_OPTIONS: "GET_CHARACTER_OPTIONS",
  GET_DOCUMENTS_BY_ACTOR_ID: "GET_DOCUMENTS_BY_ACTOR_ID",
  GET_DOCUMENTS_BY_ACTOR_ID: "GET_DOCUMENTS_BY_ACTOR_ID",
  GET_EXISTING_ACTORS: "GET_EXISTING_ACTORS",
  GET_ACTOR_BY_ID: "GET_ACTOR_BY_ID",
};

const AddActor = {
  ADD_ACTOR: "ADD_ACTOR",
  DEL_ACTOR: "DEL_ACTOR",
  DEL_PAYMENT: "DEL_PAYMENT",
  DELETE_ACTOR_DOCUMENT: "DELETE_ACTOR_DOCUMENT",
  DELETE_ACTOR_AVAILABILITY: "DELETE_ACTOR_AVAILABILITY",
};

const LocationVariables = {
  GET_LOCATION_LIST: "GET_LOCATION_LIST",
  GET_LOCATION_BY_SHOOT_DATE: "GET_LOCATION_BY_SHOOT_DATE",
  ADD_LOCATION: "ADD_LOCATION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  LOCATION_DELETE_DOC: "LOCATION_DELETE_DOC",
  DELETE_LOCATION: "DELETE_LOCATION",
  GET_LOCATION_LIST_BY_PROJECT: "GET_LOCATION_LIST_BY_PROJECT",
  UPDATE_LOCATION_NAME: "UPDATE_LOCATION_NAME",
  CHECK_SHOOT_DATE: "CHECK_SHOOT_DATE",
  PUT_LOCATION_AVAILABILITY_DETAILS: "PUT_LOCATION_AVAILABILITY_DETAILS",
  DELETE_LOCATION_AVAILABILITY: "DELETE_LOCATION_AVAILABILITY",
  POST_LOCATION_AVAILABILITY: "POST_LOCATION_AVAILABILITY",
};

const ArtSetVariables = {
  GET_ART_SET_LIST: "GET_ART_SET_LIST",
  GET_ART_SET_DROPDOWN: "GET_ART_SET_DROPDOWN",
  GET_ART_SET_BY_NAME: "GET_ART_SET_BY_NAME",
  GET_ART_SET_PROJECT_LIST: "GET_ART_SET_PROJECT_LIST",
  ADD_ART_SET: "ADD_ART_SET",
  DELETE_ART_SET_LIST: "DELETE_ART_SET_LIST",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  DELETE_TABLE_IMAGE: "DELETE_TABLE_IMAGE",
  UPDATE_ART_SET_LIST: "UPDATE_ART_SET_LIST",
  ADD_ART_SET_BUDJET: "ADD_ART_SET_BUDJET",
  UPDATE_ART_SET_BUDJET: "UPDATE_ART_SET_BUDJET",
  GET_DATA_BY_BUDGET_ID: "GET_DATA_BY_BUDGET_ID",
  GET_ART_SET_LIST_SHOOT_DAY: "GET_ART_SET_LIST_SHOOT_DAY",
  UPDATE_ART_SET: "UPDATE_ART_SET",
  GET_ART_SET: " GET_ART_SET",
  GET_ART_SET_DATES: "GET_ART_SET_DATES",
  UPDATE_ART_SET_DATES: "UPDATE_ART_SET_DATES",
  DELETE_ART_SET_UPDATE: "DELETE_ART_SET_UPDATE",
  ART_SET_TABLE_UNIQUE: "ART_SET_TABLE_UNIQUE",
  GET_ART_SET_OVERVIEW_DETAILS: "GET_ART_SET_OVERVIEW_DETAILS",
};

const CostumesMakeupVariables = {
  GET_COSTUMES_LIST: "GET_COSTUMES_LIST",
  GET_MAKEUP_LIST: "GET_MAKEUP_LIST",
  GET_MAKEUP_PROFILE: "GET_MAKEUP_PROFILE",
  COSTUMES_DELETE_DOC: "COSTUMES_DELETE_DOC",
  MAKEUP_DELETE_DOC: "MAKEUP_DELETE_DOC",
  GET_COSTUMES_LIST_BY_CHARC: "GET_COSTUMES_LIST_BY_CHARC",
  GET_MAKEUP_LIST_BY_CHARC: "GET_MAKEUP_LIST_BY_CHARC",
  UPDATE_COSTUME_LIST: "UPDATE_COSTUME_LIST",
  UPDATE_MAKEUP_LIST: "UPDATE_MAKEUP_LIST",
  DELETE_COSTUME_LIST: "DELETE_COSTUME_LIST",
  DELETE_MAKEUP_LIST: "DELETE_MAKEUP_LIST",
};

const SongVariables = {
  GET_SONGS_STUNT_LIST: "GET_SONGS_STUNT_LIST",
  GET_SONGS_STUNT_DD_LIST: "GET_SONGS_STUNT_DD_LIST",
  GET_SONGS_STUNT_DETAIL: "GET_SONGS_STUNT_DETAIL",
  GET_SONGS_STUNT_LIST_BY_PROJECT: "GET_SONGS_STUNT_LIST_BY_PROJECT",
  ADD_SONGS_STUNTS: "ADD_SONGS_STUNTS",
  UPDATE_SONGS_STUNTS: "UPDATE_SONGS_STUNTS",
  DELETE_SONGS_STUNTS: "DELETE_SONGS_STUNTS",
  GET_SONGS_STUNTS_BUDGET: "GET_SONGS_STUNTS_BUDGET",
  ADD_SONGS_STUNTS_BUDGET: "ADD_SONGS_STUNTS_BUDGET",
  UPDATE_SONGS_STUNTS_BUDGET: "UPDATE_SONGS_STUNTS_BUDGET",
  SONGS: "song",
  STUNT: "stunt",
  SONG_DELETE_DOC: "SONG_DELETE_DOC",
  STUNT_DELETE_DOC: "STUNT_DELETE_DOC",
};

const RolesVariables = {
  GET_ROLES: "GET_ROLES",
  GET_ROLE_PERMISSIONS: "GET_ROLE_PERMISSIONS",
  UPDATE_ROLE_PERMISSIONS: "UPDATE_ROLE_PERMISSIONS",
  RESET_ROLE_PERMISSIONS: "RESET_ROLE_PERMISSIONS",
};

const SchedularVariables = {
  GET_LIST: "GET_LIST",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  GET_MAIN_ACTORS: "GET_MAIN_ACTORS",
  UPDATE_ACTOR_CALL_TIME: "UPDATE_ACTOR_CALL_TIME",
  UPDATE_DATE_CALL_SHEET: "UPDATE_DATE_CALL_SHEET",
  GET_SCHEDULER_MORE_SCREEN_DETAILS: "GET_SCHEDULER_MORE_SCREEN_DETAILS",
  UPDATE_CALL_TIME_BREAK: "UPDATE_CALL_TIME_BREAK",
  UPDATE_SCHEDULAR_SCENE_TABLE: "UPDATE_SCHEDULAR_SCENE_TABLE",
  GET_PDF_URL: "GET_PDF_URL",
  SEND_MAIL: "SEND_MAIL",
  SWAP_SHOOT_DATE: "SWAP_SHOOT_DATE",
  SHOOT_ACTOR_AVAILABILITY: "SHOOT_ACTOR_AVAILABILITY",
  GET_ATTENDANCE: "GET_ATTENDANCE",
  UPDATE_ATTENDANCE: "UPDATE_ATTENDANCE",
};

// const AccountingVariables = {
//   SAVE_PRE_PRODUCTION: "SAVE_PRE_PRODUCTION",
//   SAVE_PRE_PRODUCTION_MORE: "SAVE_PRE_PRODUCTION_MORE",
//   UPDATE_PRE_PRODUCTION_MORE: "UPDATE_PRE_PRODUCTION_MORE",
//   UPDATE_PRE_PRODUCTION: "UPDATE_PRE_PRODUCTION",
//   GET_PRE_PRODUCTION: "GET_PRE_PRODUCTION",
//   GET_PRE_PRODUCTION_MORE: "GET_PRE_PRODUCTION_MORE",
//   DELETE_PRE_PRODUCTION: "DELETE_PRE_PRODUCTION",
//   DELETE_PRE_PRODUCTION_MORE: "DELETE_PRE_PRODUCTION_MORE",
//   PRE_PRODUCTION: "PRE_PRODUCTION",
//   POST_PRODUCTION: "POST_PRODUCTION",
//   GET_ACCOUNT_ESTIMATION: "GET_ACCOUNT_ESTIMATION",
//   GET_PAYEE_DROPDOWN: "GET_PAYEE_DROPDOWN"
// };

const BudjetVariables = {
  GET_PRE_PRODUCTION_DATA: "GET_PRE_PRODUCTION_DATA",
  SAVE_PRE_PRODUCTION_DATA: "SAVE_PRE_PRODUCTION_DATA",
  UPDATE_PRE_PRODUCTION_DATA: "UPDATE_PRE_PRODUCTION_DATA",
  GET_POST_PRODUCTION_DATA: "GET_POST_PRODUCTION_DATA",
  SAVE_POST_PRODUCTION_DATA: "SAVE_POST_PRODUCTION_DATA",
  UPDATE_POST_PRODUCTION_DATA: "UPDATE_POST_PRODUCTION_DATA",
  GET_PRODUCTION_DATA: "GET_PRODUCTION_DATA",
  GET_PRODUCTION_DATA_BY_PURCHASE: "GET_PRODUCTION_DATA_BY_PURCHASE",
  GET_PRODUCTION_DATA_BY_PURCHASE_MORE: "GET_PRODUCTION_DATA_BY_PURCHASE_MORE",
  GET_PRODUCTION_BUDJET_DATA: "GET_PRODUCTION_BUDJET_DATA",
  UPDATE_PRODUCTION_BUDJET_DATA: "UPDATE_PRODUCTION_BUDJET_DATA",
  DEL_PRODUCTION_BUDJET_DATA: "DEL_PRODUCTION_BUDJET_DATA",
  DEL_ART_SET_SHOOT_DATE_DATA: "DEL_ART_SET_SHOOT_DATE_DATA",
  DEL_PRE_PRODUCTION: "DEL_PRE_PRODUCTION",
  POST_UPDATE_PRODUCTION: "POST_UPDATE_PRODUCTION",
  POST_UPDATE_PRODUCTION_VENDOR: "POST_UPDATE_PRODUCTION_VENDOR",
  POST_UPDATE_ARTSET: "POST_UPDATE_ARTSET",
  UPDATE_BUDGET_STATE: "UPDATE_BUDGET_STATE",
  GET_PROJECT_PLAN: "GET_PROJECT_PLAN",
  CREATE_PROJECT_PLAN: "CREATE_PROJECT_PLAN",
  CREATE_PROJECT_PLAN: "CREATE_PROJECT_PLAN",
  PRODUCTION: "PRODUCTION",
  ART_SET: "ART_SET",
  EQUIPMENTS: "EQUIPMENT",
  COSTUME_MAKEUP: "COSTUME_MAKEUP",
  SONG_STUNT: "SONG_STUNT",
  UPDATE_PROJECT_PLAN: "UPDATE_PROJECT_PLAN",
  DELETE_PROJECT_PLAN: "DELETE_PROJECT_PLAN",
  GET_PROJECT_PLAN_BY_CATEGORY: "GET_PROJECT_PLAN_BY_CATEGORY",
  CREATE_PROJECT_PLAN_BY_CATEGORY: "CREATE_PROJECT_PLAN_BY_CATEGORY",
  UPDATE_PROJECT_PLAN_BY_CATEGORY: "UPDATE_PROJECT_PLAN_BY_CATEGORY",
  DELETE_PROJECT_PLAN_BY_CATEGORY: "DELETE_PROJECT_PLAN_BY_CATEGORY",
  GET_CAST_TECNICIAN: "GET_CAST_TECNICIAN",
  CAST_TECNICIANS: "CAST_TECHNICIAN",
  CAST_TECNICIANS_NEW: "CAST_TECHNICIAN_NEW",
  CAST: "CAST",
  CAST_PER_DAY: "CAST_PER_DAY",
  CAST_CONTRACT: "CAST_CONTRACT",
  TECHNICIAN_PER_DAY: "TECHNICIAN_PER_DAY",
  TECHNICIAN_CONTRACT: "TECHNICIAN_CONTRACT",
  DELETE_TRAVEL_LODEGING_DOCUMENT: "DELETE_TRAVEL_LODEGING_DOCUMENT",
  GET_VENDOR_BY_PROJECT: "GET_VENDOR_BY_PROJECT",
  CREATE_VENDOR_BY_PROJECT: "CREATE_VENDOR_BY_PROJECT",
  GET_VENDOR_BY_ID: "GET_VENDOR_BY_ID",
  CREATE_VENDOR_PAYMENT: "CREATE_VENDOR_PAYMENT",
  DELETE_VENDOR_PAYMENT: "DELETE_VENDOR_PAYMENT",
  GET_VENDOR_BY_EQUIPMENT: "GET_VENDOR_BY_EQUIPMENT",
  CREATE_VENDOR_BY_EQUIPMENT: "CREATE_VENDOR_BY_EQUIPMENT",
  UPDATE_VENDOR_BY_EQUIPMENT: "UPDATE_VENDOR_BY_EQUIPMENT",
  DELETE_VENDOR_BY_EQUIPMENT: "DELETE_VENDOR_BY_EQUIPMENT",
  GET_VENDOR_EQUIPMENT_DROPDOWN: "GET_VENDOR_EQUIPMENT_DROPDOWN",
  GET_BUDGET_ESTIMATION: "GET_BUDGET_ESTIMATION",
  GET_CHART_DATA: "GET_CHART_DATA",
  GET_PREVIOUS_PAYMENT: "GET_PREVIOUS_PAYMENT",
  UPDATE_EQUIPMENT: "UPDATE_EQUIPMENT",
};

const TransportVariables = {
  GET_TRANSPORT_MAIN_BY_SHOOT_DATE: "GET_TRANSPORT_MAIN_BY_SHOOT_DATE",
  GET_PASSANGER_DD: "GET_PASSANGER_DD",
  GET_VEHICLE_LIST: "GET_VEHICLE_LIST",
  GET_DRIVER_LIST: "GET_DRIVER_LIST",
  GET_DRIVER_DETAIL: "GET_DRIVER_DETAIL",
  GET_VEHICLE_DROP_LOCATION_LIST: "GET_VEHICLE_DROP_LOCATION_LIST",
  GET_VEHICLE_PASSENGER: "GET_VEHICLE_PASSENGER",
  GET_VEHICLE_PASSENGER_BY_ID: "GET_VEHICLE_PASSENGER_BY_ID",
  GET_SCHEDULE_VEHICLE: "GET_SCHEDULE_VEHICLE",
  SAVE_SCHEDULE_VEHICLE: "SAVE_SCHEDULE_VEHICLE",
  UPDATE_PICKUP: "UPDATE_PICKUP",
  DELETE_SCHEDULE_VEHICLE: "DELETE_SCHEDULE_VEHICLE",
};

const VfxVariables = {
  GET_VFX_TAB_LIST: "GET_VFX_TAB_LIST",
  GET_VFX_LIST: "GET_VFX_LIST",
  GET_VFX: "GET_VFX",
  GET_VFX_BY_ID: "GET_VFX_BY_ID",
  GET_REQUIREMENT_LIST_DD: "GET_REQUIREMENT_LIST_DD",
  GET_PAYMENT_LIST_DD: "GET_PAYMENT_LIST_DD",
  SAVE_VFX: "SAVE_VFX",
  UPDATE_VFX: "UPDATE_VFX",
  UPDATE_VFX_DESCRIPTION: "UPDATE_VFX_DESCRIPTION",
  DELETE_VFX: "DELETE_VFX",
  DELETE_VFX_DOCUMENT: "DELETE_VFX_DOCUMENT",
};

const AccountingVariables = {
  VENDOR: "VENDOR",
  CAST_TECNICIANS: "CAST_TECHNICIAN",
  GET_PRODUCTION_DATA_WISE_MORE: "GET_PRODUCTION_DATA_WISE_MORE",
  GET_PRODUCTION_INVOICES: "GET_PRODUCTION_INVOICES",
  GET_INVOICE_NUMBERS: "GET_INVOICE_NUMBERS",
  DELETE_PRE_POST_PRODUCTION_IMAGE: "DELETE_PRE_POST_PRODUCTION_IMAGE",
  DELETE_PRE_POST_PRODUCTION_IMAGE_OLD: "DELETE_PRE_POST_PRODUCTION_IMAGE_OLD",
  SAVE_PRE_PRODUCTION: "SAVE_PRE_PRODUCTION",
  GET_PRE_PRODUCTION_MORE_ACTOR_NEW_ADVANCED:
    "GET_PRE_PRODUCTION_MORE_ACTOR_NEW_ADVANCED",
  SAVE_LOCATION: "SAVE_LOCATION",
  SAVE_PRODUCTION_DATA_MORE: "SAVE_PRODUCTION_DATA_MORE",
  UPDATE_PRE_PRODUCTION_MORE: "UPDATE_PRE_PRODUCTION_MORE",
  UPDATE_PRE_PRODUCTION: "UPDATE_PRE_PRODUCTION",
  DELETE_PRE_PRODUCTION: "DELETE_PRE_PRODUCTION",
  SAVE_PRE_PRODUCTION_MORE: "SAVE_PRE_PRODUCTION_MORE",
  SAVE_PRE_PRODUCTION_MORE_NEW: "SAVE_PRE_PRODUCTION_MORE_NEW",
  DELETE_PRE_PRODUCTION_MORE: "DELETE_PRE_PRODUCTION_MORE",
  PRE_PRODUCTION: "PRE_PRODUCTION",
  TRAVEL_AND_LODGEING: "TRAVEL_AND_LODGEING",
  POST_PRODUCTION: "POST_PRODUCTION",
  GET_ACCOUNT_ESTIMATION: "GET_ACCOUNT_ESTIMATION",
  GET_PAYEE_DROPDOWN: "GET_PAYEE_DROPDOWN",
  GET_PRODUCTION_DATE_MORE_BY_ID: "GET_PRODUCTION_DATE_MORE_BY_ID",
  GET_PAYEE_DD: "GET_PAYEE_DD",
  GET_PAYEE_DD_WITHOUT_DEPT: "GET_PAYEE_DD_WITHOUT_DEPT",
  GET_PAYEE_BY_ID: "GET_PAYEE_BY_ID",
  GET_PAYEE_CATEGORY_WISE: "GET_PAYEE_CATEGORY_WISE",
  GET_CURRENT_PAYEE: "GET_CURRENT_PAYEE",
  SAVE_PAYEE: "SAVE_PAYEE",
  UPDATE_PAYEE: "UPDATE_PAYEE",
  GET_PAYMENT_DESCRIPTION: "GET_PAYMENT_DESCRIPTION",
  LEGAL_PAYEE_AMOUNT: "LEGAL_PAYEE_AMOUNT",
  GET_PRE_PRODUCTION: "GET_PRE_PRODUCTION",
  GET_ART_SET_WITH_SHOOT_DATES: "GET_ART_SET_WITH_SHOOT_DATES",
  GET_ART_SET_WITH_SHOOT_DATES_NEW: "GET_ART_SET_WITH_SHOOT_DATES_NEW",
  GET_PRE_PRODUCTION_MORE: "GET_PRE_PRODUCTION_MORE",
  GET_PRE_PRODUCTION_MORE_ACTOR: "GET_PRE_PRODUCTION_MORE_ACTOR",
  GET_PRE_PRODUCTION_MORE_ACTOR_NEW: "GET_PRE_PRODUCTION_MORE_ACTOR_NEW",
  GET_PRE_PRODUCTION_MORE_ACTOR_ADVANCE:
    "GET_PRE_PRODUCTION_MORE_ACTOR_ADVANCE",
  GET_PRODUCTION_MORE_FOR_LOCATION: "GET_PRODUCTION_MORE_FOR_LOCATION",
  GET_PRODUCTION_ART_SET_MORE: "GET_PRODUCTION_ART_SET_MORE",
  GET_PRODUCTION_TRANSPORT_MORE: "GET_PRODUCTION_TRANSPORT_MORE",
  GET_PRODUCTION_ART_SET_MORE_DESCRIPTION:
    "GET_PRODUCTION_ART_SET_MORE_DESCRIPTION",
  GET_PRE_PRODUCTION_DATA: "GET_PRE_PRODUCTION_DATA",
  GET_RECEIVER_NAME: "GET_RECEIVER_NAME",
  SAVE_PRE_PRODUCTION_DATA: "SAVE_PRE_PRODUCTION_DATA",
  UPDATE_PRE_PRODUCTION_DATA: "UPDATE_PRE_PRODUCTION_DATA",
  GET_POST_PRODUCTION_DATA: "GET_POST_PRODUCTION_DATA",
  SAVE_POST_PRODUCTION_DATA: "SAVE_POST_PRODUCTION_DATA",
  UPDATE_POST_PRODUCTION_DATA: "UPDATE_POST_PRODUCTION_DATA",
  GET_PRODUCTION_DATA: "GET_PRODUCTION_DATA",
  GET_PRODUCTION_DATA_ADVANCE_PAYMENT: "GET_PRODUCTION_DATA_ADVANCE_PAYMENT",
  GET_PRODUCTION_DATA_BY_PURCHASE: "GET_PRODUCTION_DATA_BY_PURCHASE",
  GET_PRODUCTION_BUDJET_DATA: "GET_PRODUCTION_BUDJET_DATA",
  UPDATE_PRODUCTION_BUDJET_DATA: "UPDATE_PRODUCTION_BUDJET_DATA",
  DEL_PRODUCTION_BUDJET_DATA: "DEL_PRODUCTION_BUDJET_DATA",
  DEL_ART_SET_SHOOT_DATE_DATA: "DEL_ART_SET_SHOOT_DATE_DATA",
  DEL_PRE_PRODUCTION: "DEL_PRE_PRODUCTION",
  POST_UPDATE_PRODUCTION: "POST_UPDATE_PRODUCTION",
  POST_UPDATE_PRODUCTION_VENDOR: "POST_UPDATE_PRODUCTION_VENDOR",
  POST_UPDATE_ARTSET: "POST_UPDATE_ARTSET",
  UPDATE_BUDGET_STATE: "UPDATE_BUDGET_STATE",
  GET_PROJECT_PLAN: "GET_PROJECT_PLAN",
  CREATE_PROJECT_PLAN: "CREATE_PROJECT_PLAN",
  CREATE_PROJECT_PLAN: "CREATE_PROJECT_PLAN",
  PRODUCTION: "PRODUCTION",
  ART_SET: "ART_SET",
  EQUIPMENTS: "EQUIPMENT",
  COSTUME_MAKEUP: "COSTUME_MAKEUP",
  SONG_STUNT: "SONG_STUNT",
  UPDATE_PROJECT_PLAN: "UPDATE_PROJECT_PLAN",
  DELETE_PROJECT_PLAN: "DELETE_PROJECT_PLAN",
  GET_PROJECT_PLAN_BY_CATEGORY: "GET_PROJECT_PLAN_BY_CATEGORY",
  CREATE_PROJECT_PLAN_BY_CATEGORY: "CREATE_PROJECT_PLAN_BY_CATEGORY",
  UPDATE_PROJECT_PLAN_BY_CATEGORY: "UPDATE_PROJECT_PLAN_BY_CATEGORY",
  DELETE_PROJECT_PLAN_BY_CATEGORY: "DELETE_PROJECT_PLAN_BY_CATEGORY",
  GET_CAST_TECNICIANS: "GET_CAST_TECNICIANS",
  CAST: "CAST",
  CAST_PER_DAY: "CAST_PER_DAY",
  CAST_CONTRACT: "CAST_CONTRACT",
  TECHNICIAN_PER_DAY: "TECHNICIAN_PER_DAY",
  TECHNICIAN_CONTRACT: "TECHNICIAN_CONTRACT",
  DELETE_TRAVEL_LODEGING_DOCUMENT: "DELETE_TRAVEL_LODEGING_DOCUMENT",
  GET_VENDOR_BY_PROJECT: "GET_VENDOR_BY_PROJECT",
  CREATE_VENDOR_BY_PROJECT: "CREATE_VENDOR_BY_PROJECT",
  GET_VENDOR_BY_ID: "GET_VENDOR_BY_ID",
  CREATE_VENDOR_PAYMENT: "CREATE_VENDOR_PAYMENT",
  DELETE_VENDOR_PAYMENT: "DELETE_VENDOR_PAYMENT",
  GET_VENDOR_BY_EQUIPMENT: "GET_VENDOR_BY_EQUIPMENT",
  CREATE_VENDOR_BY_EQUIPMENT: "CREATE_VENDOR_BY_EQUIPMENT",
  UPDATE_VENDOR_BY_EQUIPMENT: "UPDATE_VENDOR_BY_EQUIPMENT",
  DELETE_VENDOR_BY_EQUIPMENT: "DELETE_VENDOR_BY_EQUIPMENT",
  GET_VENDOR_EQUIPMENT_DROPDOWN: "GET_VENDOR_EQUIPMENT_DROPDOWN",
  GET_BUDGET_ESTIMATION: "GET_BUDGET_ESTIMATION",
  GET_ACCOUNTING_ESTIMATION: "GET_ACCOUNTING_ESTIMATION",
  GET_CHART_DATA: "GET_CHART_DATA",
  GET_PREVIOUS_PAYEE: "GET_PREVIOUS_PAYEE",
  GET_SUB_CATEGORY: "GET_SUB_CATEGORY",
  GET_PRODUCTION_SUB_CATEGORY: "GET_PRODUCTION_SUB_CATEGORY",
};
const EquipmentVariables = {
  GET_EQUIPMENT_TAB: "GET_EQUIPMENT_TAB",
  POST_EQUIPMENT_TAB: "POST_EQUIPMENT_TAB",
  EQUIPMENT_DROPDOWN: "EQUIPMENT_DROPDOWN",
  EQUIPMENT_DROPDOWN_LEFT: "EQUIPMENT_DROPDOWN_LEFT",
  GET_EQUIPMENT_PREVIOUS_DATA: "GET_EQUIPMENT_PREVIOUS_DATA",
  PUT_EQUIPMENT_TAB: "PUT_EQUIPMENT_TAB",
  DEL_EQUIPMENT_TAB: "DEL_EQUIPMENT_TAB",
  GET_STANDARD_EQUIPMENT: "GET_STANDARD_EQUIPMENT",
  POST_STANDARD_EQUIPMENT: "POST_STANDARD_EQUIPMENT",
  PUT_STANDARD_EQUIPMENT: "PUT_STANDARD_EQUIPMENT",
  DELETE_STANDARD_EQUIPMENT: "DELETE_STANDARD_EQUIPMENT",
  GET_SPECIAL_EQUIPMENT: "GET_SPECIAL_EQUIPMENT",
  GET_SPECIAL_EQUIPMENT_BY_DATE: "GET_SPECIAL_EQUIPMENT_BY_DATE",
  GET_SPECIAL_EQUIPMENT_BY_SHOOT_DATE: "GET_SPECIAL_EQUIPMENT_BY_SHOOT_DATE",
  UPDATE_SPECIAL_EQUIPMENT_STATE: "UPDATE_SPECIAL_EQUIPMENT_STATE",
  UPDATE_SPECIAL_EQUIPMENT_REQUIRED: "UPDATE_SPECIAL_EQUIPMENT_REQUIRED",
  UPDATE_SPECIAL_EQUIPMENT: "UPDATE_SPECIAL_EQUIPMENT",
  DELETE_SPECIAL_EQUIPMENT: "DELETE_SPECIAL_EQUIPMENT",
  GET_VENDOR_LIST: "GET_VENDOR_LIST",
  DELETE_EQUIPMENT: "DELETE_EQUIPMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
};

const InvoiceVariables = {
  GET_INVOICES: "GET_INVOICES",
  GET_INVOICE_BY_ID: "GET_INVOICE_BY_ID",
  GET_INVOICES_BY_SHOOT_DATE: "GET_INVOICES_BY_SHOOT_DATE",
  GET_INVOICES_WITHOUT_SHOOT_DATE: "GET_INVOICES_WITHOUT_SHOOT_DATE",
  GET_INVOICES_BY_CATEGORY: "GET_INVOICES_BY_CATEGORY",
  GET_INVOICES_DESCRIPTION: "GET_INVOICES_DESCRIPTION",
  CREATE_INVOICE_BY_SHOOT_DATE: "CREATE_INVOICE_BY_SHOOT_DATE",
  UPDATE_INVOICE_BY_SHOOT_DATE: "UPDATE_INVOICE_BY_SHOOT_DATE",
  DELETE_INVOICE_BY_SHOOT_DATE: "DELETE_INVOICE_BY_SHOOT_DATE",
  DELETE_INVOICE_DOCUMENT: "DELETE_INVOICE_DOCUMENT",
};

const ReportVariables = {
  GET_REPORTS: "GET_REPORTS",
  SAVE_REPORT: "SAVE_REPORT",
  UPDATE_REPORT: "UPDATE_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
  GET_REPORT_FIELDS_BY_ID: "GET_REPORT_FIELDS_BY_ID",
  GET_ALL_REPORT_FIELDS: "  GET_ALL_REPORT_FIELDS",
  SAVE_REPORT_FIELDS: "SAVE_REPORT_FIELDS",
};

const CrewVariables = {
  GET_PROJECT_DATA: "GET_PROJECT_DATA",
  CREATE_CREW_MEMBER: "CREATE_CREW_MEMBER",
  UPDATE_CREW_MEMBER: "UPDATE_CREW_MEMBER",
  UPDATE_CREW_MEMBER: "UPDATE_CREW_MEMBER",
  DELETE_CREW_MEMBER: "DELETE_CREW_MEMBER",
  GET_CREW_MEMBER: "GET_CREW_MEMBER",
  CREATE_CREW_PAYMENT: "CREATE_CREW_PAYMENT",
  CREATE_CREW_PAYMENT: "CREATE_CREW_PAYMENT",
  UPDATE_CREW_PAYMENT: "UPDATE_CREW_PAYMENT",
  UPDATE_CREW_PAYMENT: "UPDATE_CREW_PAYMENT",
  DELETE_CREW_PAYMENT: "DELETE_CREW_PAYMENT",
  DELETE_CREW_DOCUMENT: "DELETE_CREW_DOCUMENT",
};

const LegalPayeeVariables = {
  UPDATE_LEGAL_PAYEE: "UPDATE_LEGAL_PAYEE",
  DELETE_LEGAL_PAYEE: "DELETE_LEGAL_PAYEE",
  CREATE_LEGAL_PAYEE: "CREATE_LEGAL_PAYEE",
};

export {
  OnboardVariables,
  ScriptVariables,
  DashboardVariables,
  GlobalVariables,
  ActorVariables,
  AddActor,
  SceneOVerviewVariable,
  LocationVariables,
  ArtSetVariables,
  SongVariables,
  CostumesMakeupVariables,
  RolesVariables,
  SchedularVariables,
  BudjetVariables,
  EquipmentVariables,
  CrewVariables,
  NotificationVariables,
  AccountingVariables,
  InvoiceVariables,
  ReportVariables,
  TransportVariables,
  VfxVariables,
  LegalPayeeVariables,
};
