import React, { Component } from "react";
import { Table, Row, Col, Tag } from "antd";
import CostumesMakeupService from "services/CostumesMakeupServices";
import {
  Services,
  GlobalConstants,
  Screens,
  LocalStorage,
} from "constants/AppConstants";
import { Link } from "react-router-dom";
import RecordNotFound from "components/shared/RecordNotFound";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import { checkFor, Capitalize } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import TooltipIcon from "components/shared/TooltipIcon";
import TooltipComponent from "components/shared/TooltipComponent";

class Makeup extends Component {
  constructor(props) {
    super(props);
    this._makeupService = new CostumesMakeupService();
  }

  state = {
    visible: false,
    dataSource: [],
    dataFetched: false,
    type: null,
    isModalVisible: false,
    budgetId: null,
    recordData: null,
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  componentDidMount() {
    this.getCostumesList();
  }

  getCostumesList = () => {
    const { viewBy } = this.props;
    this._makeupService
      .service(
        Services.CostumesMakeupVariables.GET_MAKEUP_LIST,
        null,
        null,
        viewBy
      )
      .then((res) => {
        this.setState({ dataSource: res.data.costumes, dataFetched: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { viewBy } = this.props;
    const { dataFetched } = this.state;
    const { activePermisision } = this.props;
    const columns = [
      {
        title: "#",
        dataIndex: "sNo",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Character",
        dataIndex: "characterName",
        width: "30%",
        render: (text, record) => (
          <span>{Capitalize(record.characterName || record.generalInfo)}</span>
        ),
      },
      {
        title: "Actor",
        dataIndex: "actor",
        width: "30%",
        render: (text) => text && Capitalize(text),
      },
      {
        title: "Scene Number",
        dataIndex: "scenes",
        width: "15%",
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.MAKEUP
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                    }}
                  >
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },
    ];

    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_FINANCE)
    ) {
      columns.splice(4, 0, {
        title: "Total Cost (₹)",
        dataIndex: "specialCost",
        align: "right",
        width: "15%",
      });
    }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "Actions",
        dataIndex: "action",
        align: "center",
        render: (text, record) =>
          record.characterId !== 0 && (
            <div
              onClick={() =>
                this.props.history.push(
                  `/project/charactermakeupdetails/${viewBy}/${record.characterId}/${record.characterName}`
                )
              }
            >
              <Link>
                <TooltipIcon icon="edit" />
              </Link>
            </div>
          ),
      });
    }
    return (
      <Row>
        <Col xs={24}>
          <br />
          <Row type="flex" justify="center">
            <Col xl={24}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
                <Table
                  columns={columns}
                  dataSource={this.state.dataSource}
                  loading={!dataFetched}
                  // scroll={{ y: "50vh" }}
                  pagination={false}
                  locale={{
                    emptyText: dataFetched && <RecordNotFound />,
                  }}
                  bordered
                />
              ) : (
                <ContactAdminErrorTemplate />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.MAKEUP, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Makeup);
