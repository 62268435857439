import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Select,
  DatePicker,
  Tag,
} from "antd";

import { amountOnly, PriceFormat } from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  Services,
  LocalStorage,
  Notifications,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import DeleteComponent from "components/shared/DeleteComponent";
import InvoiceService from "services/InvoiceService";
import GlobalService from "services/GlobalService";
import InvoiceDocumentUpload from "./InvoiceDocumentUpload";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import moment from "moment";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const { Option, OptGroup } = Select;

const dateFormat = GlobalConstants.DD_MM_YYYY;

class InvoicesMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
      shootDate: props.match.params.shootDate,
      descriptionData: [],
      category: props.location.state.category,
      tdsData: [],
      gstData: [],
    };

    this._invoicesService = new InvoiceService();
    this._globalService = new GlobalService();
  }

  componentDidMount() {
    const { activePermisision } = this.props;
    this.fetchInvoicesByShootDate();
    this.fetchDescription();
    this.fetchGst();
    this.fetchTds();
  }

  fetchInvoicesByShootDate() {
    this.setState({
      isLoading: true,
    });
    let dataSource = [];
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.GET_INVOICES_BY_SHOOT_DATE,
        this.state.shootDate
      )
      .then((res) => {
        res.data.invoices &&
          res.data.invoices.map((data, i) => {
            dataSource.push({
              ...data,
              key: this.state.count,
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
        });
      });
  }
  fetchDescription() {
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.GET_INVOICES_DESCRIPTION,
        this.state.shootDate
      )
      .then((res) => {
        this.setState({
          descriptionData: Object.values(res.data) || [],
        });
      });
  }
  fetchTds() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "TDS_TAX")
      .then((res) => {
        this.setState({
          tdsData: res.data || [],
        });
      });
  }
  fetchGst() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "GST_TAX")
      .then((res) => {
        this.setState({
          gstData: res.data || [],
        });
      });
  }

  handleAdd = () => {
    const { count, dataSource, shootDate } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      category: "PRE_PRODUCTION",
      descriptionData: null,
      gstRate: null,
      invoiceAmount: null,
      invoiceDate: null,
      invoiceNumber: null,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      shootDate,
      tdsTax: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, invoiceId }) => {
    if (invoiceId) {
      this._invoicesService
        .invoiceService(
          Services.InvoiceVariables.DELETE_INVOICE_BY_SHOOT_DATE,
          invoiceId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchInvoicesByShootDate();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  onChange = (value, record, type) => {
    record[type] = value;
    this.handleSave(record, type);
  };

  handleSave = (row, type = false) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (
      row.description &&
      row.gstRate &&
      row.tdsTax &&
      row.invoiceNumber &&
      row.invoiceAmount &&
      row.invoiceDate &&
      row.shootDate
    ) {
      if (row.invoiceId) {
        this.handleUpdate(row);
      } else {
        this.handleSubmit(row);
      }
    }
    this.setState({ dataSource: newData });
  };

  handleSubmit = (record) => {
    const data = {
      ...record,
      // invoiceNumber: record.invoiceNumber.toString().toUpperCase(),
      descriptionData: record.description,
    };
    delete data.key;
    delete data.description;
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.CREATE_INVOICE_BY_SHOOT_DATE,
        data
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchInvoicesByShootDate();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  handleUpdate = (record) => {
    const data = {
      ...record,
      // invoiceNumber: record.invoiceNumber.toUppercase(),
      // descriptionData: `${record.productionCategoryDepartment}#${record.description}`,
      descriptionData: record.description,
      invoiceDate: record.shootDate,
    };
    delete data.key;
    delete data.description;
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.UPDATE_INVOICE_BY_SHOOT_DATE,
        data,
        data.invoiceId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchInvoicesByShootDate();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  render() {
    const { dataSource, isLoading, totalRecords, shootDate, descriptionData } =
      this.state;
    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const groupName = ["Production", "Location", "ArtSet", "Animal"];

    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        key: "key",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        width: "10%",
        // render: (text, record) => (
        //   <Input
        //     autoFocus={!record.invoiceId}
        //     className={`${!text ? "err" : ""} upperCase`}
        //     defaultValue={text}
        //     style={{ width: "100%" }}
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.invoiceId
        //     }
        //     onBlur={e =>
        //       this.onChange(
        //         e.target.value.toUpperCase(),
        //         record,
        //         "invoiceNumber"
        //       )
        //     }
        //   />
        // )
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "description",
        width: "20%",
        // render: (text, record) => (
        //   <Select
        //     onChange={value => {
        //       this.onChange(value, record, "description");
        //     }}
        //     defaultValue={text}
        //     value={text}
        //     style={{ width: "100%" }}
        //     className={`${!text ? "err" : ""} border-none caps`}
        //     placeholder="Select type"
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.invoiceId
        //     }
        //   >
        //     {descriptionData &&
        //       descriptionData.map((characterGroup, i) => (
        //         <OptGroup label={groupName[i]}>
        //           {characterGroup &&
        //             characterGroup.map((character, i) => (
        //               <Option value={character.value}>{character.label}</Option>
        //             ))}
        //         </OptGroup>
        //       ))}
        //   </Select>
        // )
      },
      {
        title: "AMOUNT",
        dataIndex: "invoiceAmount",
        key: "invoiceAmount",
        align: "right",
        className: "cash",
        width: "10%",
        // render: (text, record) => (
        //   <Input
        //     type="number"
        //     className={`${!text ? "err" : ""}`}
        //     defaultValue={text}
        //     style={{ width: "100%" }}
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.invoiceId
        //     }
        //     onBlur={e => this.onChange(e.target.value, record, "invoiceAmount")}
        //   />
        // )
      },
      {
        title: "GST %",
        dataIndex: "gstTax",
        key: "gstTax",
        width: "5%",
        align: "right",
        // render: (text, record) => (
        //   <Select
        //     style={{ width: "100%" }}
        //     className={`${!text ? "err" : ""} border-none caps`}
        //     placeholder="Select type"
        //     onChange={value => this.onChange(value, record, "gstRate")}
        //     defaultValue={text ? text : ""}
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.invoiceId
        //     }
        //   >
        //     {this.state.gstData &&
        //       this.state.gstData.map((option, i) => (
        //         <Option value={option.value}>{option.label}</Option>
        //       ))}
        //   </Select>
        // )
      },
      {
        title: "TDS %",
        dataIndex: "tdsTax",
        key: "tdsTax",
        width: "5%",
        align: "right",
        // render: (text, record) => (
        //   <Select
        //     style={{ width: "100%" }}
        //     className={`${!text ? "err" : ""} border-none caps cash`}
        //     placeholder="Select type"
        //     onChange={value => this.onChange(value, record, "tdsTax")}
        //     defaultValue={text ? text : ""}
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.invoiceId
        //     }
        //   >
        //     {this.state.tdsData &&
        //       this.state.tdsData.map((option, i) => (
        //         <Option value={option.value}>{option.label}</Option>
        //       ))}
        //   </Select>
        // )
      },
      {
        title: "Net Amount",
        dataIndex: "netTotalAmount",
        key: "netTotalAmount",
        width: "10%",
        align: "right",
      },
      {
        title: "Invoice date",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        width: "10%",
        align: "center",
        render: (text) => <Tag className="statusTag">{text}</Tag>,
        // render: (text, record) => (
        //   <DatePicker
        //     defaultValue={text ? moment(text, dateFormat) : ""}
        //     format={dateFormat}
        //     onChange={(value, dateString) =>
        //       this.dateChange(value, dateString, record, "invoiceDate")
        //     }
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.invoiceId
        //     }
        //     className={`${!text ? "err" : ""}`}
        //     format={dateFormat}
        //     // disabledDate={current => this.disabledDate(current)}
        //   />
        // )
      },
      {
        title: "Documents",
        dataIndex: "documents",
        key: "documents",
        align: "center",
        width: "10%",
        render: (text, record) =>
          record.invoiceId && (
            <InvoiceDocumentUpload
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.invoiceId
              }
              canUpload={checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )}
              canDelete={checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_DELETE
              )}
              data={record}
            />
          ),
      },
    ];

    // if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
    //   columns.push({
    //     title: "action",
    //     align: "center",
    //     width: "5%",
    //     render: (text, record) => (
    //       <>
    //         {checkFor(
    //           this.props.activePermisision,
    //           GlobalConstants.PERMISSION_DELETE
    //         ) ? (
    //           <DeleteComponent
    //             record={record}
    //             onClick={record => this.handleDelete(record)}
    //           />
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     )
    //   });
    // }

    return (
      <Fragment>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row type="flex" justify="center">
            <Col xl={24}>
              <Title hasBack {...this.props}>
                <TitleText>Invoices - Date {shootDate} </TitleText>
              </Title>
            </Col>
            <Col xl={23}>
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ padding: 10 }}
              >
                {/* <Col xl={24} style={{ paddingBottom: 10 }}>
                  {checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_ADD
                  ) && (
                    <Button
                      onClick={this.handleAdd}
                      type="primary"
                      style={{ marginTop: 16, float: "right" }}
                      disabled={hasNewRow}
                    >
                      <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                    </Button>
                  )}
                </Col> */}
                <Col xl={24} className="">
                  <Table
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ y: "60vh" }}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: !isLoading && <RecordNotFound />,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.INVOICE, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesMore);
