import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Table, Button, Row, Col, Icon, Input, Select, DatePicker } from "antd";
import moment from "moment";

import { amountOnly, StartCase } from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  MenusRoute,
  Services,
  Notifications,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { Link } from "react-router-dom";
import DeleteComponent from "components/shared/DeleteComponent";
import AccPaymentTypeDD from "../AccPaymentTypeDD";
import AccPaymentModeDD from "../AccPaymentModeDD";
import SharedDocumentUpload from "components/shared/SharedDocumentUpload";
import Accounting from "services/AccountingServices";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";

const { MonthPicker, RangePicker } = DatePicker;
const { TextArea } = Input;

const dateFormat = "DD-MM-YYYY";

class AccPostProductionEdit extends Component {
  constructor(props) {
    // this._accountingService  = new AccountingService();
    super(props);
    this.account = new Accounting();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      receiverNameList: [],
      receiverName: null,
      productionAccountId: props.match.params.id,
    };
  }

  componentDidMount() {
    // this.handleAdd();
    this.fetchData();
    this.getPayee();
  }

  getPayee = () => {
    this.account
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN)
      .then((res) => {
        this.setState({
          receiverNameList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  fetchData = () => {
    const { productionAccountId } = this.state;
    let dataSource = [];
    this.account
      .services(
        Services.AccountingVariables.GET_PRE_PRODUCTION_MORE,
        AccountingVariables.POST_PRODUCTION,
        productionAccountId
      )
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: this.state.count,
              payee: data.receiverName,
              paymentType: data.paymentType.toLowerCase(),
              paymentMode: data.paymentMode.toLowerCase(),
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState(
          {
            dataSource,
            totalRecords: dataSource,
            isLoading: false,
          },
          () => console.log("this.state.dataSource", this.state.dataSource)
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      payeeId: null,
      receiverName: null,
      amountPaid: null,
      paymentMode: null,
      referenceNumber: null,
      documents: [],
      comments: null,
      paymentDate: null,
      count: 0,
      description: null,
      payee: null,
    };

    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, productionPaymentId }) => {
    if (productionPaymentId) {
      this.account
        .services(
          Services.AccountingVariables.DELETE_PRE_PRODUCTION_MORE,
          AccountingVariables.PRE_PRODUCTION,
          productionPaymentId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  inputChange(value, record, type) {
    this.onChange(value, record, type);
  }

  onChange = (value, record, type) => {
    record[type] = value;
    record.count = parseInt(record.count);
    this.handleSave(record);
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  handleSubmit = (record) => {
    const { productionAccountId } = this.state;
    const {
      amountPaid,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode,
      paymentType,
      referenceNumber,
      receiverName,
    } = record;
    const data = {
      amountPaid,
      category: AccountingVariables.POST_PRODUCTION,
      comments,
      department: "PRODUCTION",
      description,
      payeeId,
      paymentDate,
      paymentMode: paymentMode,
      paymentType: paymentType,
      productionAccountId,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      receiverName,
      referenceNumber,
    };
    console.log(data);
    this.account
      .services(Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (record) => {
    const { productionAccountId } = this.state;
    const {
      amountPaid,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode,
      paymentType,
      referenceNumber,
      receiverName,
      productionPaymentId,
    } = record;
    const data = {
      amountPaid,
      category: AccountingVariables.POST_PRODUCTION,
      comments,
      department: "PRODUCTION",
      description,
      payeeId,
      paymentDate: moment(
        paymentDate,
        GlobalConstants.DD_MM_YYYY,
        true
      ).isValid()
        ? paymentDate
        : moment(paymentDate).utc().format(GlobalConstants.DD_MM_YYYY),
      paymentMode: paymentMode.toUpperCase(),
      paymentType: paymentType.toUpperCase(),
      productionAccountId,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      receiverName,
      referenceNumber,
    };
    console.log(data);
    this.account
      .services(
        Services.AccountingVariables.UPDATE_PRE_PRODUCTION_MORE,
        data,
        productionPaymentId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.productionPaymentId) {
      if (
        row.description &&
        row.paymentDate &&
        row.paymentMode &&
        row.paymentType &&
        (row.receiverName || row.payeeId) &&
        row.amountPaid &&
        row.referenceNumber
      ) {
        this.handleUpdate(row);
      }
      // let equal = checkEqual(row, this.state.activeRecord);
      // if (!checkEqual(row, this.state.activeRecord)) {

      // }
    } else {
      if (
        row.description &&
        row.paymentDate &&
        row.paymentMode &&
        row.paymentType &&
        (row.receiverName || row.payeeId) &&
        row.amountPaid &&
        row.referenceNumber
      ) {
        this.handleSubmit(row);
        // else {]
        //   showNotifications(
        //     Notifications.ERROR,
        //     "Failed",
        //     "Crew count must be greater than 0"
        //   );
        // }
      }
    }
    this.setState({ dataSource: newData });
  };

  handleReceiverName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          receiverName: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handlePayee = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    record["receiverName"] = null;
    this.setState({
      receiverName: null,
    });
    this.handleSave(record);
  };

  handleReceiver = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.receiverName);
      if (newData) {
        this.setState({ eventType });
        record.receiverName = newData;
        record.payeeId = null;
        record.payee = newData;
        this.onChange(newData, record, "payee");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  disabledDate = (current, toDate) => {
    const { shootDate } = this.state;
    // Can not select days before today and today
    if (current) {
      if (toDate) {
        return current < moment(toDate, dateFormat).add(1, "days");
      }
      return current < moment().startOf("day");
    }
  };

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      receiverNameList,
      productionAccountId,
    } = this.state;
    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "name",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            autoFocus={!record.productionPaymentId}
            onBlur={(e) => this.onChange(e.target.value, record, "description")}
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "RECEIVER NAME",
        dataIndex: "payee",
        key: "payee",
        width: "10%",
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            showSearch
            className={`${!text ? "err" : ""}`}
            value={record.payee}
            onChange={(e) => this.handlePayee(e, record, "payeeId")}
            onBlur={(e) => this.handleReceiver(e, record, "blur")}
            onSearch={(val) => this.handleReceiverName(val, record)}
          >
            {receiverNameList &&
              receiverNameList.map((list) => {
                return <option value={list.value}>{list.label}</option>;
              })}
          </Select>
        ),
      },
      {
        title: "AMOUNT PAID",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: "8%",
        className: "cash",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            type="number"
            defaultValue={text}
            onBlur={(e) => this.onChange(amountOnly(e), record, "amountPaid")}
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        key: "paymentType",
        width: "7%",
        render: (text, record) => (
          <AccPaymentTypeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentType"
          />
        ),
      },
      {
        title: "Payment Mode",
        dataIndex: "paymentMode",
        key: "paymentMode",
        width: "8%",
        render: (text, record) => (
          <AccPaymentModeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentMode"
          />
        ),
      },
      {
        title: "Reference no",
        dataIndex: "referenceNumber",
        key: "referenceNumber",
        width: "8%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            onBlur={(e) =>
              this.onChange(e.target.value, record, "referenceNumber")
            }
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: "10%",
        render: (text, record) => (
          <DatePicker
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "paymentDate")
            }
            className={`${!text ? "err" : ""}`}
            format={dateFormat}
            disabledDate={(current) => this.disabledDate(current)}
          />
        ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        width: "10%",
        render: (text, record) => (
          <TextArea
            defaultValue={text}
            placeholder="enter comments"
            autoSize
            onBlur={(e) => this.onChange(e.target.value, record, "comments")}
          />
        ),
      },
      {
        title: "Documents",
        dataIndex: "documents",
        key: "documents",
        width: "10%",
        render: (text, record) =>
          record.productionPaymentId && (
            <SharedDocumentUpload
              type={GlobalConstants.PRE_PRODUCTION_DOCUMENT}
              // key={index}
              refreshData={this.fetchData}
              data={{
                ...record,
                productionAccountId,
                paymentDate: moment(record.paymentDate)
                  .utc()
                  .format(GlobalConstants.DD_MM_YYYY),
              }}
            />
          ),
      },

      {
        title: "",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "3%",
        render: (text, record) => (
          <>
            <DeleteComponent
              record={record}
              onClick={(record) => this.handleDelete(record)}
            />
            {/* <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
            <Link to={`${MenusRoute.ACCOUNTING}/pre-production/more`}>
              <Icon type="edit" />
            </Link> */}
          </>
        ),
      },
    ];
    return (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Accounting - Post-Production </TitleText>
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ padding: 10 }}
        >
          <Col xl={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24} className="accProductionScroll">
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccPostProductionEdit);
