import axios from "axios";
import { Services } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class OnboardServices {
  projectServices(type, token, data = null, companyId) {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    switch (type) {
      case Services.DashboardVariables.GET_PROJECT_LIST:
        return axios.get(`${getScriptAPIURL()}/projects`, config);
      case Services.DashboardVariables.UPDATE_COMPANY:
        return axios.put(
          `${getScriptAPIURL()}/companies/${companyId}`,
          data,
          config
        );
      case Services.DashboardVariables.GET_COMPANY:
        return axios.get(`${getScriptAPIURL()}/companies/${data}`, config);
      case Services.DashboardVariables.DELETE_PROJECT:
        return axios.delete(`${getScriptAPIURL()}/projects/${data}`, config);
      case Services.DashboardVariables.UPDATE_PROJECT:
        return axios.patch(
          `${getScriptAPIURL()}/projects/${companyId}`,
          data,
          config
        );
      default:
        break;
    }
  }
}
