import React, { Fragment, useState } from "react";
import { Row, Col, Icon, Popconfirm, Tooltip, Modal, Button } from "antd";
import { GlobalConstants } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import TooltipComponent from "../TooltipComponent";
import { Capitalize } from "utilities/ValidationHelper";
import Axios from "axios";
import { getFileExtension } from "utilities/UserHelper";
import { generateRandomName } from "utilities/UserHelper";

const DisplayDocumentComponent = ({
  files,
  handleDelete,
  canDelete = true,
}) => {
  const [ModalVisible, setModalVisible] = useState(false);
  const [ActiveFileName, setActiveFileName] = useState(null);
  const images =
    files && files.filter((file) => file?.fileType === GlobalConstants.IMAGE);
  const docs =
    files &&
    files.filter((file) => file?.fileType === GlobalConstants.DOCUMENT);

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleSelect = (file) => {
    setActiveFileName(file?.attachment?.url);
    setModalVisible(true);
  };

  const downloadFile = (url) => {
    const fileExtension = getFileExtension(url);
    const fileName = generateRandomName(10);
    Axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileName}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const renderImage = (images) => {
    return images.map((file, i) => (
      <Col key={i} xl={4} className="imageStyleContainer">
        <Col xl={24} className="overlayComponent">
          <div
            style={{ marginRight: 5 }}
            onClick={() => downloadFile(file?.attachment?.url)}
          >
            <Icon type="download" style={{ color: "white", marginLeft: 10 }} />
          </div>
          {canDelete && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(file?.entityId)}
            >
              <Tooltip
                title={GlobalConstants.DEL}
                placement={GlobalConstants.RIGHT}
              >
                <Icon type="delete" style={{ color: "#fff" }} />
              </Tooltip>
            </Popconfirm>
          )}
        </Col>
        <Col>
          <img
            alt=""
            style={{ height: 150 }}
            className="imageStyle"
            src={file?.attachment?.url}
          />
        </Col>
      </Col>
    ));
  };
  const renderDocuments = (docs) => {
    return docs.map((file, i) => (
      <Col key={i} xl={4} className="documentStyleContainer">
        <Col>
          <Col xl={24} className="overlayComponent">
            {/* <Icon
              type="eye"
              style={{ color: "white" }}
              onClick={() => {
                handleSelect(file);
              }}
            /> */}
            <div
              onClick={() => downloadFile(file?.attachment?.url)}
              style={{ cursor: "pointer" }}
            >
              <Icon
                type="download"
                style={{ color: "white", marginLeft: 5, marginRight: 5 }}
              />
            </div>
            {canDelete && (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleDelete(file?.entityId);
                }}
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <a onClick={(e) => e.stopPropagation()}>
                    <Icon type="delete" />
                  </a>
                </Tooltip>
              </Popconfirm>
            )}
          </Col>
          <Col className="documentStyle">
            <div className="desc">
              <TooltipComponent
                text={Capitalize(file.fileName)}
              ></TooltipComponent>
            </div>
            <img
              className="docStyle"
              alt=""
              src={`https://img.icons8.com/cute-clipart/64/000000/document.png`}
            />
          </Col>
        </Col>
      </Col>
    ));
  };

  return (
    <Row>
      {images && images.length !== 0 && (
        <Fragment>
          <Col xl={24} style={{ marginTop: 10 }}>
            <h2>Images</h2>
          </Col>
          <Col xl={24}>{renderImage(images)}</Col>
        </Fragment>
      )}
      {docs && docs.length !== 0 && (
        <Fragment>
          <Col xl={24} style={{ marginTop: 10 }}>
            <h2>Documents</h2>
          </Col>
          <Col xl={24}>{renderDocuments(docs)}</Col>
        </Fragment>
      )}
      <Modal
        width="100%"
        // title="Basic Modal"
        visible={ModalVisible}
        // onOk={this.handleOk}
        onCancel={handleCancel}
        footer={null}
        className="pdf-modal no-x"
        destroyOnClose
      >
        <div className="pdf-style">
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <Button onClick={handleCancel} type="primary" icon="close"></Button>
          </div>
          <iframe
            className="pdf"
            src={`https://docs.google.com/viewer?url=${ActiveFileName}&embedded=true`}
          ></iframe>
        </div>
      </Modal>
    </Row>
  );
};

export default DisplayDocumentComponent;

//https://docs.google.com/viewer?url=http://122.165.203.72:5051/user/api/v1/file/download/3933&embedded=true
