import React, { useState, Fragment, useEffect } from "react";
import { Row, Tabs, Col } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { connect } from "react-redux";
import { setCostumeLeftActiveTab } from "store/action";
import { Services } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getPermissions } from "store/thunk-actions";
import AccCastTechnicianTable from "../AccCastTechnicianTable/AccCastTechnicianTable";

import Accounting from "services/AccountingServices";
const { TabPane } = Tabs;

const accounting = new Accounting();
const AccCastTechnicians = (props) => {
  const { setCostumeLeftActiveTab, costumeLeftActiveTab } = props;
  const [ActiveTab, setActiveTab] = useState(costumeLeftActiveTab);
  const [currentcastTech, setcurrentcastTech] = useState("");

  const handleChange = (e) => {
    setCostumeLeftActiveTab(e);
  };

  useEffect(() => {
    accounting
      .services(Services.AccountingVariables.GET_ACCOUNT_ESTIMATION)
      .then((res) => {
        console.clear();
        console.log({ res });
        setcurrentcastTech(res.data.castTechniciansBudget || "");
      });
  });

  return (
    <Fragment>
      <Row span={24}>
        <Title hasBack {...props}>
          <TitleText>
            Accounting - Cast & Technicians {currentcastTech}{" "}
          </TitleText>
        </Title>
      </Row>
      <Row style={{ padding: 10 }}>
        <Col>
          <Tabs
            defaultActiveKey={ActiveTab}
            onChange={handleChange}
            animated
            destroyInactiveTabPane
          >
            <TabPane tab="Cast - Contract wise" key="1">
              <AccCastTechnicianTable
                type={Services.AccountingVariables.CAST_CONTRACT}
                {...props}
              />
            </TabPane>
            <TabPane tab="Cast - Day wise" key="2">
              <AccCastTechnicianTable
                type={Services.AccountingVariables.CAST_PER_DAY}
                {...props}
              />
            </TabPane>
            <TabPane tab="Technician - Contract wise" key="3">
              <AccCastTechnicianTable
                type={Services.AccountingVariables.TECHNICIAN_CONTRACT}
                {...props}
              />
            </TabPane>
            <TabPane tab="Technician - Day wise" key="4">
              <AccCastTechnicianTable
                type={Services.AccountingVariables.TECHNICIAN_PER_DAY}
                {...props}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { costumeLeftActiveTab },
  user: { menus },
}) => {
  return {
    costumeLeftActiveTab,
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccCastTechnicians);
