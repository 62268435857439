import React, { useState, useEffect, Fragment } from "react";
import { PriceFormat, checkFor } from "utilities/ValidationHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import { Table, Row, Col, Tag } from "antd";
import RecordNotFound from "components/shared/RecordNotFound";
import { MenusRoute, Services } from "constants/AppConstants";
import InvoiceService from "services/InvoiceService";
import ThemeColors from "constants/ThemeConstants/variables";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const InvoiceTable = ({ history, category, activePermisision }) => {
  const [DataSource, setDataSource] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);

  const api = new InvoiceService();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    api
      .invoiceService(Services.InvoiceVariables.GET_INVOICES, category)
      .then((res) => {
        setDataSource(res.data.projectShootDateInvoiceResponseDTOs || []);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const columns = [
    {
      title: "#",
      align: "center",
      width: "5%",
      key: "key",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shoot date",
      dataIndex: "shootDate",
      key: "shootDate",
      render: (text) => (
        <Tag
          style={{ marginLeft: 0, borderRadius: 12, marginBottom: 3 }}
          color={ThemeColors.primaryColor}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "No of invoices",
      dataIndex: "noOfInvoices",
      key: "noOfInvoices",
      align: "right",
    },
    {
      title: "Invoice amount",
      dataIndex: "totalInvoiceAmount",
      key: "totalInvoiceAmount",
      align: "right",
      render: (text) => PriceFormat(text),
    },
    {
      title: "Total no of services used",
      dataIndex: "totalNoOfServices",
      key: "totalNoOfServices",
      align: "right",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns.push({
      title: "action",
      dataIndex: "address",
      align: "center",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            history.push({
              pathname: `${MenusRoute.INVOICE_MORE}/${record.shootDate}`,
              state: {
                category,
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  return (
    <Fragment>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row type="flex" justify="center">
          <Col xl={24} style={{ marginTop: 24 }}>
            <Table
              dataSource={DataSource}
              columns={columns}
              bordered
              rowKey="key"
              loading={IsLoading}
              locale={{
                emptyText: !IsLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.INVOICE, menus),
  };
};

export default connect(mapStateToProps, null)(InvoiceTable);
