import React, { Component, Fragment } from "react";
import { Row, Col, Button, Table, Input, Icon } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

// import "./accounting.scss";
import { Services, MenusRoute, Notifications } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { checkFor, PriceFormat } from "utilities/ValidationHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import RecordNotFound from "components/shared/RecordNotFound";
import ReportService from "services/ReportService";
import DeleteComponent from "components/shared/DeleteComponent";
import { Link } from "react-router-dom";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import GlobalService from "services/GlobalService";
import { getScriptAPIURL } from "utilities/APIHelper";

class Report extends Component {
  constructor(props) {
    super(props);
    this._reportService = new ReportService();
    this.global = new GlobalService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      isLoading: true,
      count: 0,
    };
  }

  componentDidMount() {
    this.fetchInvoicesData();
  }

  fetchInvoicesData = () => {
    let dataSource = [];
    this._reportService
      .reportService(Services.ReportVariables.GET_REPORTS)
      .then((res) => {
        res.data.reports &&
          res.data.reports.map((data, i) => {
            dataSource.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
        });
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      count: null,
      reportDescription: null,
      reportName: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: JSON.parse(JSON.stringify(record)),
    });
  };

  onChange(value, record, type) {
    record[type] = value;
    this.handleSave(record, value, type);
  }

  handleSubmit = (row) => {
    const { reportDescription, reportName } = row;
    const data = {
      reportDescription,
      reportFileType: "EXCEL",
      reportName,
    };
    this._reportService
      .reportService(Services.ReportVariables.SAVE_REPORT, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchInvoicesData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (row) => {
    const { reportDescription, reportId, reportName } = row;
    const data = {
      reportDescription,
      reportFileType: "EXCEL",
      reportId,
      reportName,
    };
    this._reportService
      .reportService(Services.ReportVariables.UPDATE_REPORT, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchInvoicesData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleDelete = ({ key, reportId }) => {
    if (reportId) {
      this._reportService
        .reportService(Services.ReportVariables.DELETE_REPORT, reportId)
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchInvoicesData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    if (row.reportId) {
      if (row.reportDescription && row.reportName) this.handleUpdate(row);
    } else {
      if (row.reportDescription && row.reportName) {
        this.handleSubmit(row);
      }
    }
    this.setState({ dataSource: newData });
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const { history, activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        key: "key",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "report Name",
        dataIndex: "reportName",
        key: "reportName",
        align: "left",
        width: "20%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            autoFocus={!record.reportId}
            defaultValue={text}
            onBlur={(e) => this.onChange(e.target.value, record, "reportName")}
            style={{ width: "100%" }}
          />
        ),
      },
      {
        title: "DESCRITPION",
        dataIndex: "reportDescription",
        key: "reportDescription",
        // align: "center",
        width: "40%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            onBlur={(e) =>
              this.onChange(e.target.value, record, "reportDescription")
            }
            style={{ width: "100%" }}
          />
        ),
      },

      {
        title: "ACTION",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.reportId && (
                <Link to={`${MenusRoute.REPORT}/${record.reportId}`}>
                  <TooltipIcon icon="more" />
                </Link>
              )}
            {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) &&
              record.reportId && (
                <a
                  href={`${getScriptAPIURL()}/report?id=${record.reportId}`}
                  style={{ marginLeft: 5 }}
                >
                  <TooltipIcon icon="download" />
                </a>
              )}
            {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
              <DeleteComponent
                record={record}
                onClick={(record) => this.handleDelete(record)}
              />
            )}
          </>
        ),
      },
    ];
    return (
      <Fragment>
        <Row type="flex" justify="center">
          <Col xl={24}>
            <Title>
              <TitleText>Reports </TitleText>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
                <Button
                  onClick={this.handleAdd}
                  type="primary"
                  className="flrt"
                  disabled={hasNewRow}
                >
                  <Icon type="plus" style={{ fontSize: "15px" }} /> Report
                </Button>
              )}
            </Title>
          </Col>
          <Col xl={24} style={{ padding: 10 }}>
            <Table
              dataSource={dataSource}
              columns={columns}
              bordered
              rowKey="key"
              pagination={dataSource.length > 10}
              loading={isLoading}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.REPORT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
