import { Services, LocalStorage } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL, getScriptTemplateAPIURL } from "utilities/APIHelper";

export default class ScriptService {
  scriptService(type, companyId, data, direction) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    // let projectId = 3;
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
        // "Content-Type": "multipart/form-data"
      },
    };
    switch (type) {
      case Services.ScriptVariables.DOWNLOAD:
        return axios.get(`${getScriptTemplateAPIURL()}/script_template/ACE_SCRIPT_BREAK_DOWN_TEMPLATE.xlsx`);

      case Services.AddActor.ADD_ACTOR:
        return axios.post(`${getScriptAPIURL()}/actors`, companyId, config);

      case Services.ScriptVariables.UPLOAD:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/script-import`,
          data,
          config
        );

      case Services.SceneOVerviewVariable.UPDATE_COSTUME:
        return axios.post(
          `${getScriptAPIURL()}/costume/budget`,
          companyId,
          config
        );
      case Services.SceneOVerviewVariable.UPDATE_MAKEUP:
        return axios.post(
          `${getScriptAPIURL()}/makeup/budget`,
          companyId,
          config
        );
      case Services.AddActor.DEL_ACTOR:
        return axios.delete(
          `${getScriptAPIURL()}/actors/staffs/${companyId}`,
          config
        );
      case Services.AddActor.DEL_PAYMENT:
        return axios.delete(
          `${getScriptAPIURL()}/actors/payments/${companyId}`,
          config
        );
      case Services.AddActor.DELETE_ACTOR_AVAILABILITY:
        return axios.delete(
          `${getScriptAPIURL()}/actors/availability/${companyId}`,
          config
        );
      case Services.SceneOVerviewVariable.DRAG_DROP:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/scenes/reorder?direction=${direction}`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.PAN_DROP_DOWN:
        return axios.get(
          `${getScriptAPIURL()}/master-data?type=PAYMENT_PROOF`,
          config
        );
      case Services.SceneOVerviewVariable.DESIGNATION_DROP_DOWN:
        return axios.get(
          `${getScriptAPIURL()}/master-data?type=DESIGNATION`,
          config
        );

      case Services.SceneOVerviewVariable.GET_DASH_PIE_CHART:
        return axios.get(
          `${getScriptAPIURL()}/master-data?type=PAYMENT_PROOF`,
          config
        );
      // case Services.SceneOVerviewVariable.GET_DASH_CHART:
      //   return axios.get(
      //     `${getScriptAPIURL()}/project/${projectId}/chart?type=${data}`,
      //     config
      //   );
      case Services.SceneOVerviewVariable.GET_DETAILED_VIEW_DATA:
        return axios.get(
          `${getScriptAPIURL()}/master-data?type=DESIGNATION?${data}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_DETAILED_VIEW_CHART_DATA:
        return axios.get(
          `${getScriptAPIURL()}/master-data?type=DESIGNATION?${data}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_SCENE_NUMBERS_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/scene/numbers`,
          config
        );
      case Services.SceneOVerviewVariable.GET_NEXT_SCENE_NUMBERS_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/next/scene/numbers`,
          config
        );
      case Services.SceneOVerviewVariable.GET_NEW_SCENE_NUMBERS_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/next/scene/numbers?sceneNumber=${data}`,
          config
        );
      case Services.SceneOVerviewVariable.CREATE_INSERT_SCENE:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/scenes`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.DELETE_SCENE:
        return axios.delete(
          `${getScriptAPIURL()}/projects/${projectId}/scenes/${companyId}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_DESCRIPTION_TYPE:
        return axios.get(
          `${getScriptAPIURL()}/master/data?isType=false&type=DESCRIPTION_TYPE`,
          config
        );
      default:
        break;
    }
  }
}
