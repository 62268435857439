import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import CreateLocationModal from "components/Location/AddLocationModal";
import SkeletonLoader from "components/shared/SkeletonLoader";
import NoSceneFound from "components/NoSceneFound";
import { Services, GlobalConstants } from "constants/AppConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import LocationCardComponent from "components/LocationCardComponent";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { Link } from "react-router-dom";

class LocationAuditionComponent extends Component {
  state = {
    cardList: [],
    isActorsFetched: false,
    locationModel: false,
    mapModel: false,
    latitude: null,
    longitude: null,
    locationName: null,
    locationId: null,
  };

  constructor(props) {
    super(props);
    this._locationService = new LocationTabService();
  }

  handleLatLongValue = (latitude, longitude) => {
    this.setState({
      latitude,
      longitude,
    });
  };

  handleModel = (name, value, type) => {
    if (type !== "entire") {
      if (name === "map") {
        this.setState({
          mapModel: value,
          locationModel: !value,
        });
      } else {
        this.setState({
          mapModel: !value,
          locationModel: value,
        });
      }
    } else {
      this.setState({
        mapModel: false,
        locationModel: false,
      });
    }
  };

  componentDidMount() {
    this.setState(
      {
        locationId: this.props.match.params.locationId,
      },
      () => {
        this.fetchLocation();
      }
    );
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  fetchLocation = () => {
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION,
        this.state.locationId
      )
      .then((res) => {
        this.setState({
          cardList: res.data.locations,
          isActorsFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isActorsFetched: true,
        });
      });
  };

  refreshLocation = () => {
    this.fetchLocation();
  };

  renderLocation = () => {
    let locationList = [];
    if (this.state.cardList) {
      this.state.cardList.forEach((location) => {
        const image =
          (location &&
            location &&
            location.locationImages &&
            location &&
            location.locationImages[0] &&
            location &&
            location.locationImages[0] &&
            location &&
            location.locationImages[0].attachment &&
            location &&
            location.locationImages[0].attachment.url) ||
          null;
        locationList.push(
          <LocationCardComponent
            {...this.props}
            actualLocationName={location.actualLocationName}
            sceneLocationName={this.props.match.params.locationName}
            locationId={location.locationId}
            locationAuditionId={location.locationAuditionId}
            sceneLocationId={this.props.match.params.locationId}
            status={location.state}
            imageId={image}
            ImageIdList={location.locationImages}
            refreshLocation={this.fetchLocation}
          />
        );
      });
      return locationList;
    }
    return (
      <NoSceneFound
        type="Location"
        refreshProjectList={this.refreshProjectList}
      />
    );
  };

  render() {
    const { latitude, longitude } = this.state;
    const { activePermisision } = this.props;
    return (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Location Audition </TitleText>
          </Title>
        </Row>
        <Row className="mt-25" align="middle" justify="center" type="flex">
          <Col
            xl={{ span: 21 }}
            lg={{ span: 21 }}
            md={{ span: 21 }}
            style={{ border: "1px solid #ccc" }}
          >
            <h3
              className=""
              style={{
                backgroundColor: "#001529",
                color: "#fff",
                padding: "10px",
                textAlign: "center",
              }}
            >
              {this.props.match.params.locationName}
            </h3>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) ? (
              <Link to={`/project/add-location/` + this.state.locationId}>
                <Button
                  type="primary"
                  // onClick={() => {
                  //   this.setState({ locationModel: true });
                  // }}
                  icon="plus"
                  style={{ marginLeft: "10px" }}
                >
                  Add Location
                </Button>
              </Link>
            ) : null}
            {this.state.locationModel && (
              <CreateLocationModal
                locationModel={this.state.locationModel}
                handleModel={this.handleModel}
                latitude={latitude}
                longitude={longitude}
                refreshLocation={this.fetchLocation}
                closeModal={this.closeModal}
                type="Add"
                sceneLocationName={this.props.match.params.locationName}
                sceneLocationId={this.props.match.params.locationId}
              />
            )}
            {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
              <Row className="pb-10">
                {this.state.isActorsFetched ? (
                  this.renderLocation()
                ) : (
                  <SkeletonLoader />
                )}
              </Row>
            ) : (
              <ContactAdminErrorTemplate />
            )}
            {/* <GoogleLocation
              geoLocation={this.geoLocation}
              handleModel={this.handleModel}
              mapModel={this.state.mapModel}
              getLatLongValues={this.handleLatLongValue}
              latitude={latitude}
              longitude={longitude}
            /> */}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneLocationId: scene.activeSceneLocationId,
    activePermisision: getSPermissionFromMenus("Location", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationAuditionComponent);
