import React from "react";
import "../subscription/subscription.scss";

const Subscription = () => {
  return (
    <div className="subscription">
      <p className="title">Choose Your Plan!</p>
      <div className="plan">
        <div className="top">
          <div className="contents">
            <p style={{ fontSize: "large", color: "white" }}>Free</p>
          </div>
          <p className="dollar">$</p>
        </div>
        <div className="circle">
          <h1 style={{ fontWeight: "bolder" }}>0</h1>
        </div>
        <p style={{ position: "absolute", top: "40%", right: "20%" }}>/month</p>
        <div className="bottom">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ul>
              <li>
                <span className="listText">1 Project</span>
              </li>
              <li>
                <span className="listText">Unlimitted Employee</span>
              </li>
              <li>
                <span className="listText">Live Support</span>
              </li>
              <li>
                <span className="listText">Lorem Lpsum</span>
              </li>
              <li>
                <span className="listText">Lorem Lpsum</span>
              </li>
            </ul>
            <button className="startButton">Buy Now</button>
          </div>
        </div>
      </div>
      <div className="plan" style={{ marginLeft: "20px" }}>
        <div className="top" style={{ backgroundColor: "#FDD273" }}>
          <div className="contents">
            <p
              style={{ fontSize: "large", color: "white", fontWeight: "bold" }}
            >
              Pro
            </p>
          </div>
          <p className="dollar">$</p>
        </div>
        <div className="circle" style={{ borderColor: "#FCB414" }}>
          <h1 style={{ fontWeight: "bolder" }}>75</h1>
        </div>
        <p style={{ position: "absolute", top: "40%", right: "20%" }}>/month</p>
        <div className="bottom">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ul>
              <li>
                <span className="listText">3 Projects</span>
              </li>
              <li>
                <span className="listText">Unlimitted Employee</span>
              </li>
              <li>
                <span className="listText">Live Support</span>
              </li>
              <li>
                <span className="listText">Lorem Lpsum</span>
              </li>
              <li>
                <span className="listText">Lorem Lpsum</span>
              </li>
            </ul>
            <button
              className="startButton"
              style={{ backgroundColor: "#FCB414" }}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
