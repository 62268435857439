import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  BackTop,
  Icon,
} from "antd";
import GlobalService from "services/GlobalService";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import BudjetService from "services/BudjetService";

import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import * as _ from "lodash";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { handleError } from "utilities/ErrorHandler";
import { checkFor } from "utilities/ValidationHelper";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class PostProduction extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._budjetService = new BudjetService();

    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      stageDatas: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getStageDatas();
    this.getPostProductionData();
  }

  getPostProductionData = () => {
    this.setState({ count: 1, dataSource: [], isLoading: true });
    this._budjetService
      .budjetService(Services.BudjetVariables.GET_POST_PRODUCTION_DATA)
      .then((res) => {
        let preProductionData = [];
        res.data.postProductionPayments &&
          res.data.postProductionPayments.map((data, i) => {
            preProductionData.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource: preProductionData,
          totalRecords: preProductionData,
          isLoading: false,
        });
      });
  };

  getStageDatas = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.POST_PRODUCTION_STAGE
      )
      .then((res) => {
        this.setState({
          stageDatas: res.data,
        });
      })
      .catch((err) => {});
  };
  handleDelete = (key, projectProductionCategoryId) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      }
      // () => this.props.getFromChild(this.state.dataSource)
    );
    this._budjetService
      .budjetService(
        Services.BudjetVariables.DEL_PRE_PRODUCTION,
        null,
        projectProductionCategoryId
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Deleted",
          "Deleted Successfully!!"
        );
        this.getPostProductionData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );

        this.props.getPermissions();
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      category: "",
      postProductionStage: "",
      totalPrice: "",
      //   projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record) {
    this.handleSave(record, value, "postProductionStage");

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }
  inputChange(value, record) {
    console.log(record);
    this.handleSave(record, value, "category");

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      if (type === "postProductionStage") {
        let modifyItem = newData[index];
        modifyItem.postProductionStage = value;
      } else if (type === "category") {
        let modifyItem = newData[index];
        modifyItem.category = value;
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
    if (!row.projectProductionCategoryId) {
      if (row.category && row.postProductionStage && row.totalPrice) {
        this.handleSubmit(row);
      }
    }
    if (row.projectProductionCategoryId) {
      if (row.category && row.postProductionStage && row.totalPrice) {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      }
    }
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  handleSubmit = (record) => {
    const data = {
      category: record.category,
      productionStage: record.postProductionStage,
      totalPrice: record.totalPrice,
    };

    this._budjetService
      .budjetService(Services.BudjetVariables.SAVE_POST_PRODUCTION_DATA, data)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully"
        );
        this.getPostProductionData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );

        this.props.getPermissions();
      });
  };

  handleUpdate = (record) => {
    const data = {
      categoryId: record.categoryId,
      category: record.category,
      productionStage: record.postProductionStage,
      totalPrice: record.totalPrice,
    };
    this.setState({
      isLoading: true,
    });
    this._budjetService
      .budjetService(
        Services.BudjetVariables.UPDATE_POST_PRODUCTION_DATA,
        data,
        record.projectProductionCategoryId
      )
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Success",
        //   "Updated Successfully"
        // );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getPostProductionData();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );

        this.props.getPermissions();
      });
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const column = [
      {
        title: "#",
        align: "center",
        width: "6%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "category",
        align: "left",
        className: "budjet-text-ellipsis",
        width: "30%",
        render: (text, record, index) => (
          <Input
            defaultValue={text}
            disabled={record.isEnable === false}
            onBlur={(e) => this.inputChange(e.target.value, record)}
          />
        ),
      },
      {
        title: "Post Production Stage",
        dataIndex: "postProductionStage",
        align: "center",
        width: "15%",
        render: (text, record) => (
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Select type"
            //   optionFilterProp="children"
            onChange={(value) => this.onChange(value, record)}
            defaultValue={text ? text : ""}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
            }
          >
            {this.state.stageDatas &&
              this.state.stageDatas.map((data) => {
                return <Option value={data.value}>{data.label}</Option>;
              })}
          </Select>
        ),
      },
      {
        title: "Total (₹)",
        dataIndex: "totalPrice",
        width: "15%",
        className: "cash",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        align: "center",
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      column.push({
        title: "Action",
        dataIndex: "operation",
        width: "8%",
        render: (text, record) => (
          <Row type="flex" justify="center">
            <React.Fragment>
              <Popconfirm
                disabled={record.isEnable === false}
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(
                    record.key,
                    record.projectProductionCategoryId
                  )
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  {record.isEnable && (
                    <Button
                      icon="delete"
                      type="link"
                      disabled={record.isEnable === false}
                    />
                  )}
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          </Row>
        ),
      });
    }

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "category" ? "text" : "number",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });

    return (
      <div>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Post-Production </TitleText>
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Col xl={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              loading={isLoading}
              className="production-table"
            />
          </Col>
        </Row>
        <BackTop visibilityHeight="50" />
      </div>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostProduction);
