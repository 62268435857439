import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
  AutoComplete,
  Icon,
} from "antd";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import { ScriptService } from "services";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import EquipmentServices from "services/EquipmentServices";
import RecordNotFound from "components/shared/RecordNotFound";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import { checkFor, removeColumns } from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;
const { TextArea } = Input;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: false,
                message: `${title} is required.`,
              },
              {
                // pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              type="number"
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: false,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    }
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class EquipmentTab extends Component {
  getEquipmentDropDown = () => {
    this._equipmentService
      .service(
        Services.EquipmentVariables.EQUIPMENT_DROPDOWN,
        localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        null,
        this.state.designationTypeValue
      )
      .then((res) => {
        this.setState({ equipmentDropDown: res.data.equipments });
      });
  };

  setEquipmentWithDD = (record, value) => {
    const { equipmentDropDown } = this.state;
    const filteredEquipment = equipmentDropDown.filter(
      (equipment) => equipment.value === value
    );
    const obj = Object.assign({}, ...filteredEquipment);
    this._equipmentService
      .service(
        Services.EquipmentVariables.GET_EQUIPMENT_PREVIOUS_DATA,
        localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        obj.value
      )
      .then((res) => {
        if (isEmpty(res.data)) {
          record = {
            ...record,
            equipment: obj.label,
          };
          this.handleSave(record, value);
        } else {
          this.setState({ equipmentDropDown: res.data.equipments });
          let record = {
            equipment: obj.label,
            amount: res.data.amount,
            paymentType: res.data.paymentType,
          };
          this.handleSubmit(record);
        }
      });
    //   this.handleSave(record, value);
    //   this.setState({
    //     isOnChanged: true
    //   })

    //   const lengthOfTheDataSource = this.state.dataSource.length - 1;
    //   const newData = [...this.state.dataSource];
    //   const indexOfTheCurrentRow = newData.findIndex(
    //     data => record.key === data.key
    //   );
    // });
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
    // this._actorsService = new ActorsServices();
    this._actorService = new ActorTabService();
    this._equipmentService = new EquipmentServices();
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      actorId: null,
      activeSceneId: null,
      equipmentDropDown: [],
      equipment: null,
      designationTypeValue: null,
      isDisableInput: false,
      // tableCount: 0
    };
  }

  componentDidMount() {
    this.getEquipmentDropDown();
    this.fetchData();
    this.getPaymentData();
    this.checkForEmpty(this.state.dataSource);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  getPaymentData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EQUIPMENT_PAYMENT
      )
      .then((res) => {
        this.setState({
          paymentTypedData: res.data,
        });
      });
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  designationTypeValue(value, record) {
    if (value.length > 1) {
      delete record.equipment;
    }
    if (value && value.trim().length > 0) {
      this.setState({
        designationTypeValue: value,
      });
    }
    this.setState({
      isOnChanged: false,
    });
    this.getEquipmentDropDown();
  }

  // designationBlurChanges(value, record) {
  //   this.getEquipmentDropDown()
  //   let designation = this.state.designationTypeValue;
  //   let a = this.state.equipmentDropDown || [];
  //   console.log('a', a);
  //   let b = a.map(a => a.name);
  //   console.log("b", b)
  //   let dx = true;
  //   if (designation != null) {
  //     new Promise(resolve => {
  //       b.forEach(x => {
  //         if (x && x.toString().trim() != designation.toString().trim()) {
  //           dx = false;
  //         } else {
  //           dx = true;
  //         }
  //       });

  //       resolve();
  //     })
  //       .then(() => {
  //         if (!dx) {
  //           a.push({ label: designation, value: designation });
  //         }
  //       })
  //       .then(() => {
  //         this.setState({
  //           equipmentDropDown: a
  //         });
  //       })
  //       .then(() => {
  //         console.log("dsy", designation);
  //         if (!this.state.isDisableInput) {
  //           this.onChange(designation, record);
  //           record.equipment = a
  //         }
  //         // this.setState({
  //         //   designationShow:designation
  //         // });
  //       })
  //   }
  // }

  designationBlurChanges(value, record, eventType) {
    if (this.state.isOnChanged != true) {
      let designation = this.state.designationTypeValue;
      if (designation != null) {
        this.setState({ eventType });
        record.characterName = designation;
        this.onChange(designation, record, "characterName");
      } else {
        this.setState({
          isOnChanged: false,
        });
      }
    }
  }

  handleFocus = (record) => {
    this.setState({ designationTypeValue: null, eventType: "isNotBlur" });
  };

  fetchData = () => {
    this.setState({
      count: 0,
      isLoading: true,
      dataSource: [],
    });
    this._equipmentService
      .service(
        Services.EquipmentVariables.GET_EQUIPMENT_TAB,
        localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID)
      )
      .then((res) => {
        let dataSource = [];
        res.data.equipments &&
          res.data.equipments.map((datum, i) => {
            dataSource.push({ ...datum, key: i });
          });
        this.setState(
          {
            dataSource,
            totalRecords: dataSource,
            isLoading: false,
            count: res?.data?.equipments.length,
            // isActorsFetched: true,
          },
          () => {
            this.checkForEmpty(this.state.dataSource);
          }
        );
        if (res?.data === "") {
          this.setState({ dataSource: [], totalRecords: [], count: 0 });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleSubmit = (record) => {
    const data = {
      description: record.description,
      quantity: parseInt(record.quantity),
      equipment: record.equipment,
      equipmentType: "SPECIAL",
      amount: record.amount,
      paymentType: record.paymentType,
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
    };

    this._equipmentService
      .service(Services.EquipmentVariables.POST_EQUIPMENT_TAB, null, data)
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipments Created Successfully!!"
        );
        this.props.getNotificationCount();
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleUpdate = (record) => {
    const data = {
      description: record.description,
      quantity: parseInt(record.quantity),
      equipment: record.equipment,
      equipmentType: "SPECIAL",
      amount: record.amount,
      paymentType: record.paymentType,
    };
    let sceneEquipmentId = record.sceneEquipmentId;
    this._equipmentService
      .service(
        Services.EquipmentVariables.PUT_EQUIPMENT_TAB,
        localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
        data,
        null,
        sceneEquipmentId
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleDelete = (key, id) => {
    this.getEquipmentDropDown();
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
        isDisableInput: false,
        designationTypeValue: null,
      },
      () => {
        this.checkForEmpty(this.state.dataSource);
      }
    );
    this._equipmentService
      .service(
        Services.EquipmentVariables.DEL_EQUIPMENT_TAB,
        null,
        null,
        null,
        id
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Successfully",
          "Successfully Deleted !"
        );
        this.props.getNotificationCount();
      });
  };

  handleAdd = () => {
    this.getEquipmentDropDown();
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      quantity: "",
      equipment: "",
    };
    this.setState({
      dataSource: [...dataSource, newData],
      // count:count + 1
    });
  };

  onChange(value, record, type) {
    if (!type) {
      new Promise((resolve) => {
        this.setEquipmentWithDD(record, value);
        resolve();
      }).then(() => {
        const lengthOfTheDataSource = this.state.dataSource.length - 1;
        const newData = [...this.state.dataSource];
        const indexOfTheCurrentRow = newData.findIndex(
          (data) => record.key === data.key
        );
      });
    } else {
      this.handleSave(record, value, type);
      this.setState({
        isOnChanged: true,
      });
    }

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    let modifyItem = newData[index];
    if (value) {
      if (type === "description") {
        modifyItem.description = value;
      } else if (type === "paymentType") {
        modifyItem.paymentType = value;
        modifyItem.paymentType = value;
      } else {
        modifyItem.equipment = value;
      }
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });

    if (!row.sceneEquipmentId) {
      if (row.quantity && row.equipment) {
        this.handleSubmit(row);
      }
      // else {
      //   showNotifications(
      //     Notifications.ERROR,
      //     "Failed",
      //     "All Fields Are Mandatory"
      //   );
      // }
    }
    if (row.sceneEquipmentId) {
      if (row.equipment) {
        this.handleUpdate(row);
      }
    }
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    let columns = [
      {
        title: "#",
        dataIndex: "sceneTypeDetailId",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.sceneTypeDetailId < b.sceneTypeDetailId,
        align: "center",
        width: "10%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Equipment",
        dataIndex: "equipment",
        width: "15%",
        // editable: true,
        align: "left",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select
              showSearch
              onSearch={(val) => this.designationTypeValue(val, record)}
              onFocus={() => this.handleFocus(record)}
              disabled={
                !checkFor(
                  this.props.activePermisision,
                  GlobalConstants.PERMISSION_EDIT
                )
              }
              onBlur={(value) =>
                this.designationBlurChanges(value, record, "blur")
              }
              value={text ? text : ""}
              style={{ width: "100%" }}
              placeholder="Select Equipment"
              defaultValue={text ? text : ""}
              className={`${!text ? "custom-empty-active" : ""} caps`}
              onChange={(value) => this.onChange(value, record)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.equipmentDropDown &&
                this.state.equipmentDropDown.map((option, i) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          ) : null,
      },

      {
        title: "Quantity",
        dataIndex: "quantity",
        align: "right",
        editable: checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_EDIT
        ),
        width: "10%",
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""} cash`,
            },
            children: text,
          };
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        editable: checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_EDIT
        ),
        width: "10%",
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""} cash`,
            },
            children: text,
          };
        },
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        align: "left",
        width: "10%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Select
              showSearch
              disabled={
                !checkFor(
                  this.props.activePermisision,
                  GlobalConstants.PERMISSION_EDIT
                )
              }
              onSearch={(val) => this.designationTypeValue(val, record)}
              onFocus={() => this.handleFocus(record)}
              onBlur={(value) =>
                this.designationBlurChanges(value, record, "blur")
              }
              value={text ? text : ""}
              style={{ width: "100%" }}
              placeholder="Select Equipment"
              defaultValue={text ? text : ""}
              className={`${!text ? "custom-empty-active" : ""}`}
              onChange={(value) => this.onChange(value, record, "paymentType")}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.paymentTypedData &&
                this.state.paymentTypedData.map((option, i) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          ) : null,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 200,
        align: "left",
        render: (text, record) => (
          <TextArea
            defaultValue={text}
            onBlur={(e) => this.onChange(e.target.value, record, "description")}
          />
        ),
        // editable: checkFor(
        //   this.props.activePermisision,
        //   GlobalConstants.PERMISSION_EDIT
        // ),
        // // align: "center",
        // render: (text, record) => {
        //   return {
        //     props: {
        //       className: `${!text ? "empty-actives" : ""}`
        //     },
        //     children: text
        //   };
        // }
      },
    ];
    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_DELETE)
    ) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "5%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.sceneEquipmentId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  {" "}
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null,
      });
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    if (
      !checkFor(
        this.props.activePermisision,
        GlobalConstants.PERMISSION_FINANCE
      )
    ) {
      columns = removeColumns(columns, ["Payment type", "Amount"]);
    }
    const columnsName = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex !== "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          paddingTop: 0,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_ADD
        ) ? (
          <Col xs={24}>
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 5, float: "right" }}
              disabled={hasNewRow}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} />
              Entry
            </Button>
          </Col>
        ) : (
          ""
        )}
        <Col xs={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={24}>
              <Table
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columnsName}
                pagination={false}
                locale={{
                  emptyText: !isLoading && <RecordNotFound />,
                }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Equipment", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTab);
