import React, { Fragment, Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
  Icon,
} from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import { ScriptService } from "services";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { connect } from "react-redux";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import * as _ from "lodash";
import { handleError } from "utilities/ErrorHandler";
import RecordNotFound from "components/shared/RecordNotFound";
import { getPermissions } from "store/thunk-actions";
import { setActiveTab } from "store/action";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.dataIndex === "characterCount") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^[0-9]+$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              autoFocus
              type="number"
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onKeyDown={(e) => this._handleKeyPress(e, record)}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([a-zA-Z]{1,}[\\w!@#$%^&*)(+=._-]*)$"),
                message: `${title} is start with alphabet`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              autoFocus
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onKeyDown={(e) => this._handleKeyPress(e, record)}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            autoFocus
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onKeyDown={(e) => this._handleKeyPress(e, record)}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  _handleKeyPress = (event, record) => {
    if (event.keyCode === 9) {
      this.input.focus();
      event.preventDefault();
    }
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      activePermisision,
      ...restProps
    } = this.props;

    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class AnimalTableNew extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
    // this._actorsService = new ActorsServices();
    this._actorService = new ActorTabService();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      dropDown: null,
      dropDownawb: null,
      actorId: null,
      activeSceneId: null,
      characterDropdown: null,
      // tableCount: 0
    };
  }

  componentDidMount() {
    this.getAnimalPaymentType();
    this.getAWBStatusType();
    // this.getCharaDropdown();

    //   if (this.props.activeSceneId) {
    //     this.setState(
    //       {
    //         activeSceneId: this.props.activeSceneId
    //       },
    //       // () => this.fetchActor()
    //     );
    //   }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchActor();
        }
      );
    }
  }
  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  getAnimalPaymentType = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "ANIMAL_PAYMENT_TYPE"
      )
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  getAWBStatusType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "AWB_STATUS")
      .then((res) => {
        this.setState({
          dropDownawb: res.data,
        });
      });
  };

  getCharaDropdown = (value, record) => {
    const { dataSource } = this.state;
    let charcDropdownList = [];
    this._actorService
      .actorTabService(
        Services.ActorVariables.GET_ANIMAL_CHARC,
        value,
        null,
        null
      )
      .then((res) => {
        let temp = dataSource.map((data) => data.characterName);
        temp = JSON.parse(JSON.stringify(temp));
        if (!record.characterId) {
          temp.pop();
        }
        res.data.characters.map((data) => {
          let index = temp.indexOf(data.value);
          if (index === -1) {
            charcDropdownList.push({ ...data });
          }
        });
        this.setState({
          charcDropdownList,
        });
      })
      .catch((err) => {});
  };

  fetchActor = () => {
    this.setState({
      count: 0,
      isLoading: false,
    });
    this._actorService
      .actorTabService(
        Services.SceneOVerviewVariable.GET_ACTOR,
        "ANIMAL",
        null,
        this.state.activeSceneId
      )
      .then((res) => {
        let dataSource = [];
        res.data.characters &&
          res.data.characters.map((datum, i) => {
            dataSource.push({ ...datum, key: i + 1 });
            return null;
          });
        this.setState(
          {
            dataSource,
            totalRecords: dataSource,
            isLoading: true,
            count: dataSource.length + 1,
          },
          () => {
            // this.checkForEmpty(this.state.dataSource);
          }
        );
      });
  };

  handleSubmit = (record) => {
    const data = {
      characterName: record.characterName,
      characterType: "ANIMAL",
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
      characterCount: record.characterCount,
      salary: record.salary,
      // animalPaymentType: record.animalPaymentType,
      paymentType: record.animalPaymentType,
      awbStatus: record.awbStatus,
    };

    this._actorService
      .actorTabService(Services.SceneOVerviewVariable.ADD_ANIMAL_ACTOR, data)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Successfully",
          "Created New Character!!"
        );
        this.fetchActor();
        this.setState({
          charcDropdownList: [],
        });
      })
      .catch((err) => {
        this.fetchActor();
        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleUpdate = (record) => {
    let characterId = record.characterId;
    let type = record.animalPaymentType;
    let status = record.awbStatus;
    type = type && type.replace(/ /g, "_");
    status = status && status.replace(/ /g, "_");
    let enumType = type && type.toUpperCase();
    let enumStatus = status && status.toUpperCase();
    let data = {
      characterCount: record.characterCount,
      salary: record.salary,
      // animalPaymentType: enumType,
      paymentType: enumType,
      awbStatis: enumStatus,
      characterName: record.characterName,
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
      characterType: "ANIMAL",
    };
    this.setState({
      isLoading: true,
    });
    this._actorService
      .actorTabService(
        Services.SceneOVerviewVariable.EDIT_ANIMAL_ACTOR,
        data,
        characterId
      )
      .then((res) => {
        this.setState({
          isLoading: false,
          charcDropdownList: [],
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchActor();
        this.setState({
          charcDropdownList: [],
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        this.props.getPermissions();
        this.fetchActor();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message
        );
      });
  };

  handleDelete = (key, characterId) => {
    const dataSource = [...this.state.dataSource];
    if (characterId) {
      this._actorService
        .actorTabService(
          Services.SceneOVerviewVariable.DELETE_ACTOR,
          characterId
        )
        .then((res) => {
          this.fetchActor();
          showNotifications(
            Notifications.SUCCESS,
            "Successfully",
            "Character Removed !!"
          );
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Error",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          );
          // this.setState(
          //   {
          //     dataSource: dataSource.filter(item => item.key !== key)
          //   },
          //   () => {
          //     // this.checkForEmpty(this.state.dataSource);
          //   }
          // );
          this.props.getPermissions();
        });
    } else {
      this.setState(
        {
          dataSource: dataSource.filter((item) => item.key !== key),
        },
        () => {
          // this.checkForEmpty(this.state.dataSource);
        }
      );
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      characterName: "",
      characterCount: "",
      paymentType: null,
      salary: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      // count:count + 1
    });
  };

  onChange(value, record, type, fromOnBlur = false) {
    if (!fromOnBlur && type === "characterName") {
      this._actorService
        .actorTabService(
          Services.SceneOVerviewVariable.GET_JUNIOR_ANIMAL_ACTOR,
          value,
          "ANIMAL"
        )
        .then((res) => {
          const record = {
            characterName: value,
            // characterCount: null,
            paymentType: res.data.animalPaymentEnum || null,
            salary: res.data.amount || null,
            awbStatus: res.data.awbStatusEnum || "PENDING",
            animalPaymentType: res.data.animalPaymentEnum,
          };
          this.handleSubmit(record);
        })
        .catch((err) => {
          console.log(err);
          this.setState({});
        });
    }
    this.setState({
      isOnChanged: true,
    });
    this.handleSave(record, value, type);
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      let modifyItem = newData[index];
      switch (type) {
        case "characterName":
          modifyItem.characterName = value;
          break;
        case "paymentType":
          modifyItem.paymentType = value;
          break;
        case "animalPaymentType":
          modifyItem.animalPaymentType = value;
          break;
        case "awbStatus":
          modifyItem.awbStatus = value;
          break;
        default:
          break;
      }
      // modifyItem.paymentType = value;
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
    if (!row.characterId) {
      if (
        !checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        ) &&
        row.characterName &&
        row.characterCount
        // row.awbStatus
      ) {
        this.handleSubmit(row);
      } else if (
        row.characterName &&
        row.characterCount &&
        row.salary &&
        row.animalPaymentType
        // row.awbStatus
      ) {
        this.handleSubmit(row);
      }
      // else {
      //   showNotifications(
      //     Notifications.ERROR,
      //     "Failed",
      //     "All Fields Are Mandatory"
      //   );
      // }
    }
    if (row.characterId) {
      if (
        !checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        ) &&
        row.characterName &&
        row.characterCount
        // row.awbStatus
      ) {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      } else if (
        row.characterName &&
        row.characterCount &&
        row.salary
        // row.animalPaymentType
        // row.awbStatus
      ) {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      }
    }
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  handleCharac = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          animalCharcKeyword: value,
        },
        () => this.getCharaDropdown(this.state.animalCharcKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  setCharacName = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = this.state.animalCharcKeyword;
      if (newData) {
        this.setState({ eventType });
        record.characterName = newData;
        this.onChange(newData, record, "characterName", true);
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const { activePermisision } = this.props;
    let columns = [
      {
        title: "#",
        dataIndex: "sno",
        align: "center",
        render: (text, record, index) => <span>{index + 1}</span>,
      },
      {
        title: "Character Name",
        dataIndex: "characterName",
        width: "40%",
        align: "center",
        render: (text, record) =>
          // <Form.Item style={{ margin: 0 }}>
          //   {console.log("text......", text)}
          //   {this.props.form.getFieldDecorator("characName", {
          //     initialValue: text
          //   })(
          this.state.isLoading && (
            <Select
              showSearch
              onSearch={(val) => this.handleCharac(val, record)}
              onFocus={() =>
                this.setState({
                  animalCharcKeyword: null,
                  eventType: "isNotBlur",
                })
              }
              onBlur={(value) => this.setCharacName(value, record, "blur")}
              style={{ width: "100%", minWidth: "98%", maxWidth: "200px" }}
              placeholder="Select type"
              disabled={!this.props.EDIT && record.characterId}
              onChange={(value) =>
                this.onChange(value, record, "characterName")
              }
              value={text ? text : ""}
              className={`${!text ? "custom-empty-active" : ""}`}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.charcDropdownList &&
                this.state.charcDropdownList.map((option, i) => (
                  <Option value={option.label}>{option.label}</Option>
                ))}
            </Select>
          ),
        //     )}
        // </Form.Item>
      },
      {
        title: "Quantity",
        dataIndex: "characterCount",
        width: "30%",
        editable: true,
        align: "center",
        render: (text, record) => {
          return {
            props: {
              className: `${!text ? "empty-active" : ""} cash`,
            },
            children: text,
          };
        },
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      columns.push(
        {
          title: "Unit Rate (₹)",
          dataIndex: "salary",
          width: "30%",
          editable: true,
          align: "center",
          render: (text, record) => {
            return {
              props: {
                className: `${!text ? "empty-active" : ""} cash`,
              },
              children: text,
            };
          },
        },
        {
          title: "Type",
          dataIndex: "paymentType",
          align: "center",
          render: (text, record) =>
            this.state.isLoading ? (
              <>
                <Select
                  style={{ width: 140 }}
                  placeholder="Select type"
                  disabled={!this.props.EDIT && record.characterId}
                  onChange={(value) =>
                    this.onChange(value, record, "animalPaymentType")
                  }
                  value={text ? text : ""}
                  className={`${!text ? "custom-empty-active" : ""}`}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.dropDown &&
                    this.state.dropDown.map((option, i) => (
                      <Option value={option.value}>{option.label}</Option>
                    ))}
                </Select>
              </>
            ) : null,
        }
      );
    }

    const { ADD, EDIT, DELETE, PERSONAL, FINANCE } = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    if (this.props.DELETE) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.characterId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null,
      });
    }
    const columnEdit = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record, i) => {
          const cellStatus = record.key === i && record.characterId;
          return {
            record,
            inputType: col.dataIndex === "characterName" ? "number" : "text",
            editable:
              col.editable !== this.props.EDIT
                ? cellStatus
                  ? false
                  : true
                : true,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            setActiveRecord: this.setActiveRecord,
          };
        },
      };
    });
    return (
      <Fragment>
        <Row type="flex" justify="center" align="middle">
          <Col xl={24}>
            {ADD && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ float: "right", marginBottom: 5 }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnEdit}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
              loading={!isLoading}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    getPermissions: () => dispatch(getPermissions()),
  };
};
const animalTableNew = Form.create()(AnimalTableNew);
export default connect(mapStateToProps, mapDispatchToProps)(animalTableNew);
