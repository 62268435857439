const GlobalConstants = {
  YYYY_MM_DD: "YYYY-MM-DD",
  PROJECT_SUB_REPROT_TYPE: "PROJECT_SUB_REPROT_TYPE",
  DD_MM_YYYY: "DD-MM-YYYY",
  PAYMENT_TYPE: "PAYMENT_TYPE",
  PAYMENT_MODE: "PRODUCTION_CATEGORY",
  DESCRIPTION_TYPE: "DESCRIPTION_TYPE",
  PRODUCTION_CATEGORY: "PRODUCTION_CATEGORY",
  PRODUCTION_SUB_CATEGORY: "PRODUCTION_SUB_CATEGORY",
  PRODUCTION_DEPARTMENT: "PRODUCTION_DEPARTMENT",
  EQUIPMENT_RESOURCE_TYPE: "EQUIPMENT_RESOURCE_TYPE",
  CAMERA_LIGHT_AND_EQUIPMENTS: "CAMERA_LIGHT_AND_EQUIPMENTS",
  CATERING: "CATERING",
  VENDOR_TYPE: "VENDOR_TYPE",
  VENDOR: "VENDOR",
  VFX: "VFX",
  APPROVAL_STATUS: "APPROVAL_STATUS",
  SCHEDULE_STATE: "SCHEDULE_STATE",
  AWB_STATUS: "AWB_STATUS",
  LOCATION_TYPE: "LOCATION_TYPE",
  ADD: "ADD",
  INSERT: "INSERT",
  DELETE: "DELETE",
  VIEW: "VIEW",
  FINANCE: "FINANCE",
  PERSONAL: "PERSONAL",
  UPDATE: "UPDATE",
  EDIT: "EDIT",
  MAIN_MENU: "MAIN_MENU",
  REDIRECT_TO: "REDIRECT_TO",
  PROJECT_REPORTS: "PROJECT REPORT",
  DATA_INCOMPLETION_REPORT: "DATA INCOMPLETION REPORT",
  BUDGET_ESTIMATION_REPORT: "BUDGET ESTIMATION REPORT",
  ACCOUNTS_REPORT: "ACCOUNTS REPORT",
  FROM_DASHBOARD: "From Dashboard",
  DOCUMENT: "DOCUMENT",
  IMAGE: "IMAGE",
  PDF: "PDF",
  XLS: "XLS",
  TIME: "TIME",
  ENVIRONMENT: "ENVIRONMENT",
  SEQUENCE_TYPE: "SEQUENCE_TYPE",
  SHOW: "SHOW",
  HIDE: "HIDE",
  MAKE_UP_TIME: "MAKE_UP_TIME",
  ART_SET_DETAIL_TYPE: "ART_SET_DETAIL_TYPE",
  ART_SET_DETAIL_TYPE: "ART_SET_DETAIL_TYPE",
  COSTUME_MAKEUP_PAYMENT: "COSTUME_MAKEUP_PAYMENT",
  EQUIPMENT_PAYMENT: "EQUIPMENT_PAYMENT",
  COSTUME: "COSTUME",
  MAKEUP: "MAKEUP",
  TO_BE_CONFIRMED: "TO_BE_CONFIRMED",
  CONFIRMED: "CONFIRMED",
  NEGOTIATION: "NEGOTIATION",
  CHARACTER_TYPE: "CHARACTER_TYPE",
  ACTOR_PAYMENT_TYPE: "ACTOR_PAYMENT_TYPE",
  ART_SET_PAYMENT: "ART_SET_PAYMENT",
  ACTUAL_LOCATION: "Actual Location",
  ACTUAL_LOCATION_CAPS: "ACTUAL_LOCATION",
  SCENE_LOCATION: "Scene Location",
  MAIN_ACTOR: "Main Actor",
  RIGHT: "right",
  TOP_RIGHT: "topRight",
  TOP_LEFT: "topLeft",
  LEFT: "left",
  TOP: "top",
  DEL: "Delete",
  INS: "Insert",
  REMOVE_INVOICE: "Remove Invoice",
  REMOVE_PAYEE: "Remove Payee",
  EDT: "Edit",
  CPY: "Create duplicate",
  SAV: "Save",
  TRANSPORT: "Transport",
  TRANSPORT_CAPS: "TRANSPORT",
  MORE: "More",
  DOWNLOAD: "Download",
  ATTENDANCE: "Attendance",
  RESET: "Reset",
  SPENT_AMOUNT: "Spent Amount",
  IMPORT_FROM_PREVIOUS_SCENES: "Import Values from Previous Scenes",
  SCENE_LOCATION_CAPS: "SCENE_LOCATION",
  MAIN_ACTOR: "Main Actor",
  MAIN_ACTOR_CAPS: "MAIN_ACTOR",
  SUCCESS: "SUCCESS",
  CHANGES_HAVE_BEEN_SAVED: "The changes have been saved..",
  CREATED_SUCCESSFULLY: "created successfully",
  UPDATED_SUCCESSFULLY: "updated successfully",
  DOCUMENT_UPLOADED_SUCCESSFULLY: "Document uploaded Successfully..",
  SUCCESSFULLY_REMOVED: "Removed successfully..",
  WARNING: "WARNING",
  ERROR: "ERROR",
  ACTORS: "ACTORS",
  ACTOR_CAPS: "ACTOR",
  PRE_PRODUCTION_PAYMENT_TYPE: "PRE_PRODUCTION_PAYMENT_TYPE",
  POST_PRODUCTION_STAGE: "POST_PRODUCTION_STAGE",
  PRODUCTION_PAYMENT_TYPE: "PRODUCTION_PAYMENT_TYPE",
  PAYMENT_STATUS: "PAYMENT_STATUS",
  INVOICE_DOCUMENT: "INVOICE_DOCUMENT",
  PAYMENT_MODE: "PAYMENT_MODE",
  ACCOUNTING_ACTOR_DEPARTMENT: "ACCOUNTING_ACTOR_DEPARTMENT",
  ACCOUNTING_DEPARTMENT: "ACCOUNTING_DEPARTMENT",
  ACTOR_CALL_SHEET_MONTHLY_REPORT: "ACTOR_CALL_SHEET_MONTHLY_REPORT",
  PER_DAY: "PER_DAY",
  SPECIAL_PROPERTY: "SPECIAL_PROPERTY",
  CONTRACT: "CONTRACT",
  DAY: "Day",
  TECHNICIAN: "TECHNICIAN",
  SHORT_FILM: "SHORT_FILM",
  HOURS: "HOURS",
  PRE_PRODUCTION: "PRE_PRODUCTION",
  CAST_TECHNICIANS: "CAST_TECHNICIANS",
  TRAVEL_AND_LODGEING: "TRAVEL_AND_LODGEING",
  PRODUCTION: "PRODUCTION",
  DAILY_EXPENSE: "Daily Expense",
  POST_PRODUCTION: "POST_PRODUCTION",
  SCENE: "SCENE",
  SCHEDULER_PDF: "SCHEDULER_PDF",
  BUDGET_STATE: "BUDGET_STATE",
  BY_PURCHASE: "Purchase wise",
  BY_CALL_SHEET: "Day wise",
  DATA_WISE: "Data wise",
  ART_SET: "Art Set Budgets",
  Location: "Location",
  Actor: "Actor",
  ACTOR: "ACTOR",
  ART_SET_CAPS: "ART_SET",
  PRODUCTION: "Production",
  PRODUCTION_CAPS: "PRODUCTION",
  PRODUCTION_PURCHASE: "PRODUCTION_PURCHASE",
  JUNIOR_CAPS: "JUNIOR",
  JUNIOR_ARTIST_CAPS: "JUNIOR_ARTIST",
  ANIMAL_CAPS: "ANIMAL",
  ANIMAL_CAPS: "ANIMAL",
  ACTUAL_LOCATION_CAPS: "ACTUAL_LOCATION",
  LOCATION_CAPS: "LOCATION",
  EQUIPMENTS: "Equipments",
  EQUIPMENTS_CAPS: "EQUIPMENT",
  SONG_CAPS: "SONG",
  STUNT_CAPS: "STUNT",
  EQUIPMENTS_CAPS: "EQUIPMENT",
  SONG_AND_STUNT: "Songs & Stunts",
  SONG_AND_STUNT_CAPS: "SONG_STUNT",
  COSTUME_AND_MAKEUP: "Costumes & Makeup",
  COSTUME_AND_MAKEUP_CAPS: "COSTUME_MAKEUP",
  COSTUME_MAKEUP_AND_SONG_STUNT: "Costumes & Songs",
  Pre_Production: "Pre-Production",
  Production: "Production",
  Post_Production: "Post-Production",
  Travel_Lodgeing: "Travel & Lodging",
  Cast_Technicians: "Cast & Technicians",
  Vendor: "Vendor",
  EXPENSE_TYPE: "EXPENSE_TYPE",
  ACCESS_DENIED: "ACCESS_DENIED",
  ACTOR_SCHEDULE_REPORT: "ACTOR_SCHEDULE_REPORT",
  LOCATION_SCHEDULE_REPORT: "LOCATION_SCHEDULE_REPORT",
  IN_PROGRESS: "IN_PROGRESS",
  ACCESS_DENIED_ERROR: "You Don't have permission to access this",
  NAME_ALREADY_EXISTS_FOR_THE_PROJECT: "NAME_ALREADY_EXISTS_FOR_THE_PROJECT ",
  PERMISSION_VIEW: "View",
  PERMISSION_EDIT: "Edit",
  PERMISSION_ADD: "Add",
  PERMISSION_DELETE: "Delete",
  PERMISSION_FINANCE: "Finance",
  PERMISSION_PERSONAL: "Personal",
  SCENE_WITH_NO_CHAR_MAP: "SCENE_WITH_NO_CHAR_MAP",
  LOCATION_WITHOUT_ACTUAL: "LOCATION_WITHOUT_ACTUAL",
  CHARACTERS_WITHOUT_CONFIRM: "CHARACTERS_WITHOUT_CONFIRM",
  EQUIPMENTS_WITHOUT_MAP: "EQUIPMENTS_WITHOUT_MAP",
  GET_DASH_CHART: "GET_DASH_CHART",
  ACTOR_AVAILABILITY: "ACTOR_AVAILABILITY",
  LOCATION_AVAILABILITY: "LOCATION_AVAILABILITY",
  UNUSED_EQUIPMENTS: "UNUSED_EQUIPMENTS",
  SCHEDULER_ALERT: "SCHEDULER_ALERT",
  HIRE_PER_DAY: "HIRE_PER_DAY",
  SALUTATION_TYPE: "SALUTATION_TYPE",
  AGREEMENT_TYPE: "AGREEMENT_TYPE",
  GST_TAX: "GST_TAX",
  PURCHASE: "PURCHASE",
  PROJECT_WISE: "PROJECT_WISE",
  PRE_PRODUCTION_DOCUMENT: "PRE_PRODUCTION_DOCUMENT",
  EQUIPMENT_ALERT: ["ProjectAdmin", "ExecutiveProducer", "Director", "DOP"],
  COSTUME_ALERT: ["Costumer", "ProjectAdmin", "ExecutiveProducer", "Director"],
  ARTSET_ALERT: [
    "ArtDirector",
    "ProjectAdmin",
    "ExecutiveProducer",
    "Director",
  ],
  CHART_INFO: ["ProjectAdmin", "ExecutiveProducer"],
  PROJECT_COMPLETION_STATUS: ["ProjectAdmin", "ExecutiveProducer", "Director"],
  SCRIPT_ADD: ["ProjectAdmin", "ExecutiveProducer", "Director"],
  CREATE_PROJECT: ["ProjectAdmin", "ExecutiveProducer"],
  EDIT_PROJECT: ["ProjectAdmin", "ExecutiveProducer", "Director"],
  DELETE_PROJECT: ["ProjectAdmin", "ExecutiveProducer"],
  NO_ADD: [`Report`, `Dashboard`],
  NO_EDIT: [`Report`, `Dashboard`],
  NO_DELETE: [`Report`, `Dashboard`],
  NO_FINANCE: ["Invoice", "Budgeter", "Accounting", "Transport"],
  NO_PERSONEL: [`Report`, `Dashboard`],
};

const PermissionPage = {
  INVOICE: "Invoice",
  TRANSPORT: "Transport",
  SCHEDULER: "Scheduler",
  ACCOUNTING: "Accounting",
  BUDGETER: "Budgeter",
  CREW: "Crew",
  EQUIPMENT: "Equipment",
  STUNT: "Stunt",
  SONG: "Song",
  VFX: "VFX",
  MAKEUP: "Makeup",
  COSTUME: "Costume",
  ART_SET: "Art/Set",
  LOCATION: "Location",
  CHAR_ACTOR: "Char-Actor",
  SCRIPT_BREAKDOWN: "Script Breakdown",
  DASHBOARD: "Dashboard",
  REPORT: "Report",
};

const NotificationTitleConstants = {
  MAP_CHARACTER: "Map Character with Scenes",
  CONFIRM_ACTUAL_LOCATION: "Confirm the Actual Locations",
  CONFIRM_ACTOR: "Confirm Actor",
  ACTOR_AVAILABILITY: "Actor Availability Warnings",
  LOCATION_AVAILABILITY: "Location Availability Warnings",
  ARTSET_ALERTS: "Art Set Alerts",
  COSTUME_ALERTS: "Costume Alerts",
  MAKEUP_ALERTS: "Makeup Alerts",
  EQUIPMENT_ALERTS: "Equipment Alerts",
  SONG_ALERTS: "Song Alerts",
  STUNT_ALERTS: "Stunt Alerts",
};

export { GlobalConstants, PermissionPage, NotificationTitleConstants };
