import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "screens/Home";
import Login from "screens/auth/Login";
import PageNotFound from "components/shared/PageNotFound";
import ProjectPage from "screens/ProjectPage";
import Cast from "components/Cast";
import Dashboard from "components/Dashboard";
import Script from "components/Script";
import Equipments from "components/Costumes";
import Approvals from "components/Approvals";
import Projects from "components/Projects";
import Makeup from "components/Makeup";
import VFX from "components/Vfx";
import Song from "components/Song";
import Stunt from "components/Stunt";
import Actor from "components/Actor";
import SceneOverview from "components/SceneOverview";
import actorprofiledata from "components/Actorprofile";
import AddlocationForm from "components/addlocation";
import CreateLocationModal from "components/Location/AddLocationModal";
import AuditionComponent from "components/AuditionComponent";
import locationpop from "components/Location";
import Costumepersondetails from "components/Costumepersondetails";
import LocationTab from "components/LocationTab";
import LocationAuditionComponent from "components/LocationAuditionComponent";
import CreateActor from "components/AddActor";
import UpdateLocation from "components/UpdateLocationPage";
import AddLocationPage from "components/AddLocationPage";
import Schedular from "components/Schedulor/Schedular";
import SchedularUpdate from "components/Schedulor/Scheduleupdate";
import UpdateArtSetPage from "components/UpdateArtSetPage";
import ArtSetMain from "../../components/Artset/ArtSetMain";
import BudjeterMain from "components/Budjeter/BudjetMain";
import PreProduction from "components/Budjeter/PreProduction";
// import ArtSetMain from "components/ArtSet/ArtSetMain";
import Production from "components/Budjeter/Production";
import PostProduction from "components/Budjeter/PostProduction";
import UpdateProduction from "components/Budjeter/Production/UpdateProduction";
import MakeUpPersonDetails from "components/MakeUpPersonDetails";
import Costumes from "components/Costumes";
import EquipmentTab from "components/Equipment/EquipmentTab";
import EditSpecialEquipment from "components/Equipment/EditSpecialEquipment";
import Crew from "components/Crew";
import crewprofile from "components/CrewUpdate";
import TravelLodgeing from "components/Budjeter/TravelLodgeing";
import CastTechnicianMain from "components/Budjeter/CastTechnicianMain";
import Travellist from "components/Budjeter/Travellist";
import Vendor from "components/Budjeter/Vendor";
import VendorUpdate from "components/Budjeter/VendorUpdate";
import VendorEquipment from "components/Budjeter/VendorEquipment";
import ArtSetOverview from "components/Artset/ArtSetOverView";
import ProductionUpdateTabs from "components/Budjeter/Production/ProductionUpdateTabs";
import CostumeLeftTabs from "components/CostumeLeft";
import MakeupLeft from "components/MakeupLeft";
import CostumeDescription from "components/Costumes/CostumeDescription";
import AnimalStatusComponent from "components/Actor/AnimalStatusComponent";
import ProductionLeftTab from "components/Budjeter/Production/ProductionLeftTab";
import UpdateArtSetPageNew from "components/UpdateArtSetPage/UpdateArtSetPageNew";
import EditSpecialEquipmentNew from "components/Equipment/EditSpecialEquipment/EditSpecialEquipmentNew";
import ProtectedRoute from "./ProtectedRoute";
import MakeupDescription from "components/Costumes/MakeupDescription";
import CastTechnicians from "components/CastTechniciansLeft/CastTechnicians";
import AccountOverview from "components/Dashboard/AccountOverview/AccountOverview";
import { MenusRoute } from "constants/AppConstants";
import AccountingMain from "components/AccountingMain/AccountingMain";
import AccPreProduction from "components/AccountingMain/AccPreProduction/AccPreProduction";
import AccPreProductionEdit from "components/AccountingMain/AccPreProductionEdit/AccPreProductionEdit";
import AccPostProduction from "components/AccountingMain/AccPostProduction/AccPostProduction";
import AccPostProductionEdit from "components/AccountingMain/AccPostProductionEdit/AccPostProductionEdit";
import AccTravelLodging from "components/AccountingMain/AccTravelLodging/AccTravelLodging";
import AccTravellist from "components/AccountingMain/AccTravellist/AccTravellist";
import AccCastTechnicians from "components/AccountingMain/AccCastTechnicians/AccCastTechnicians";
import AccVendor from "components/AccountingMain/AccVendor/AccVendor";
import AccVendorEdit from "components/AccountingMain/AccVendor/AccVendorEdit";
import ActorProfileVendor from "components/Actorprofile/ActorProfileVendor";
import AccProduction from "components/AccountingMain/AccProduction/AccProduction";
import AccProductionUpdateTabs from "components/AccountingMain/AccProductionUpdateTabs";
import AccReceiverPayment from "components/AccountingMain/AccReceiverPayment";
import TransportMain from "components/Transporting/TransportMain";
import TransportMore from "components/Transporting/TransportMore";
import TransportMoreDetails from "components/Transporting/TransportMoreDetails";
import InvoicesMain from "components/Invoices/InvoicesMain";
import InvoicesMore from "components/Invoices/InvoicesMore";
import AccProductionDataMore from "components/AccountingMain/AccProduction/AccProductionDataMore";
import Report from "components/Report";
import ReportMore from "components/Report/ReportMore";
import ReportMain from "components/Report/ReportMain";
import AddPayment from "components/AccountingMain/AddPayment";
import AccAddLocation from "components/AccountingMain/AccProduction/AccAddLocation";
import AccProductionArtSetTableMore from "components/AccountingMain/AccPreProductionEdit/AccProductionArtSetTableMore";
import AccLocationActorTable from "components/AccountingMain/AccProduction/AccLocationTable";
import AccLocationTableMore from "components/AccountingMain/AccProduction/AccLocationTableMore";
import AccProductionShootWise from "components/AccountingMain/AccPreProductionEdit/AccProductionShootWise";
import AccProductionActor from "components/AccountingMain/AccPreProductionEdit/AccProductionActor";
import AccTransportTableMore from "components/AccountingMain/AccPreProductionEdit/AccTransportTableMore";
import AccProductionCostumeMakeupMore from "components/AccountingMain/AccPreProductionEdit/AccProductionCostumeMakeupMore";
import AccProductionSongStuntMore from "components/AccountingMain/AccPreProductionEdit/AccProductionSongStuntMore";
import PurchaseMore from "components/Budjeter/Production/PurchaseMore";
import AttendanceMain from "components/Attendance/AttendanceMain";
import AccProductionPurchasePayment from "components/AccountingMain/AccPreProductionEdit/AccProductionPurchasePayment";
import AccProductionArtsetAdvanceMore from "components/AccountingMain/AccPreProductionEdit/AccProductionArtsetAdvanceMore";
import AccProductionLocationAdvanceMore from "components/AccountingMain/AccPreProductionEdit/AccProductionLocationAdvanceMore";
import AccProductionActorAdvanceMore from "components/AccountingMain/AccPreProductionEdit/AccProductionActorAdvanceMore";
import ActorLegal from "components/ActorLegal/ActorLegal";
import Legal from "components/Legal/Legal";
import RegistrationPage from "screens/auth/Registration/registration";
import Subscription from "screens/auth/subscription/subscription";

import TechnicianTable from "components/Legal/LegalTabTables/TechniciansTable/TechnicianTable";
import Verification from "screens/auth/Verification/verification";
const MainRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/sign-up" component={RegistrationPage} />
      <Route path="/subscription" component={Subscription} />
      <Route path="/verification" component={Verification} />
      <Route path="/project" component={ProjectPage} />
      <ProtectedRoute path="/" component={Home} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

const WelcomeRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path="/projects" component={Projects} />
      <ProtectedRoute path="/approval" component={Approvals} />
      {/*  SET WELCOME SCREEN for "/" */}
      <ProtectedRoute exact path="/" component={Projects} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

const ProjectRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/actorprofile/:id/:characterName/:characterType/:characterId`}
        component={ActorProfileVendor}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/update-production/:shootDate/:type`}
        component={AccProductionUpdateTabs}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/travellist/:id`}
        component={AccPreProductionEdit}
      />
      {/* <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/production/:description/:categoryDepartment`}
        component={AccPreProductionEdit}
      /> */}
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/production/edit`}
        component={AccPreProductionEdit}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/production/purchase/edit`}
        component={AccProductionPurchasePayment}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/production/shootwise`}
        component={AccProductionShootWise}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/production/location`}
        component={AccLocationTableMore}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/production/location/advance`}
        component={AccProductionLocationAdvanceMore}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/pre-production/:id/edit`}
        component={AccPreProductionEdit}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ACCOUNTING}/post-production/:id/edit`}
        component={AccPreProductionEdit}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/vendor/edit`}
        component={AccVendorEdit}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/artset/advance`}
        component={AccProductionArtsetAdvanceMore}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/artset/:shootDate`}
        component={AccProductionArtSetTableMore}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/transport/:shootDate`}
        component={AccTransportTableMore}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/actor/advance`}
        component={AccProductionActorAdvanceMore}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/actor/:shootDate`}
        component={AccProductionActor}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/costumemakeup/:shootDate`}
        component={AccProductionCostumeMakeupMore}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production/songstunt/:shootDate`}
        component={AccProductionSongStuntMore}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/travel-lodgeing`}
        component={AccTravelLodging}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/vendor`}
        component={AccVendor}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/casttechnicians`}
        component={AccCastTechnicians}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/pre-production`}
        component={AccPreProduction}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/post-production`}
        component={AccPostProduction}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/production`}
        component={AccProduction}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/addlocation`}
        component={AccAddLocation}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/receiverpayment`}
        component={AccReceiverPayment}
      />
      <ProtectedRoute
        path={`${MenusRoute.ACCOUNTING}/addpayment`}
        component={AddPayment}
      />
      <ProtectedRoute
        path="/project/actor/animal/:sceneId"
        component={AnimalStatusComponent}
      />
      <ProtectedRoute path="/project/overview/" component={Dashboard} />
      <ProtectedRoute
        path="/project/accountoverview"
        component={AccountOverview}
      />
      <ProtectedRoute path="/project/script" component={Script} />
      <ProtectedRoute path="/project/actor" component={Actor} />
      <ProtectedRoute path="/project/artset" component={ArtSetMain} />
      <ProtectedRoute path="/project/costumes" component={CostumeLeftTabs} />
      <ProtectedRoute path="/project/makeup" component={MakeupLeft} />
      <ProtectedRoute path="/project/vfx" component={VFX} />
      <ProtectedRoute path="/project/song" component={Song} />
      <ProtectedRoute path="/project/stunt" component={Stunt} />
      <ProtectedRoute
        path="/project/screen-overview"
        component={SceneOverview}
      />
      <ProtectedRoute path="/project/location" component={locationpop} />
      <ProtectedRoute path="/project/schedular" component={Schedular} />
      <ProtectedRoute
        path="/project/travel-lodgeing"
        component={TravelLodgeing}
      />

      <ProtectedRoute path="/project/travellist/:id" component={Travellist} />
      <ProtectedRoute path="/project/vendor" component={Vendor} />
      <ProtectedRoute
        path="/project/casttechnicians"
        component={CastTechnicians}
      />
      <ProtectedRoute path="/project/vendorcreate" component={VendorUpdate} />
      <ProtectedRoute
        path="/project/vendorupdate/:vendorId"
        component={VendorUpdate}
      />
      <ProtectedRoute
        path="/project/vendorEquipment/:vendorId"
        component={VendorEquipment}
      />
      <ProtectedRoute
        path="/project/schedularupdate/:projectId/:date"
        component={SchedularUpdate}
      />
      <ProtectedRoute
        path="/project/addactor/:characterName/:characterType/:characterId"
        component={actorprofiledata}
      />
      <ProtectedRoute
        path="/project/actorprofile/legal/:id/:legalAgreementId/:rumeneration/:paymentType/:characterName/:name/:legalAgreementType"
        component={ActorLegal}
      />
      <ProtectedRoute
        path="/project/actorprofile/legal/:actorId"
        component={ActorLegal}
      />
      <ProtectedRoute
        path="/project/actorprofile/:id/:characterName/:characterType/:characterId"
        component={actorprofiledata}
      />
      <ProtectedRoute
        path="/project/addlocationmodal"
        component={CreateLocationModal}
      />
      <ProtectedRoute
        path="/project/charactercostumedetails/:type/:characterId/:characterName"
        component={Costumepersondetails}
      />
      <ProtectedRoute
        path="/project/charactermakeupdetails/:type/:characterId/:characterName"
        component={MakeUpPersonDetails}
      />
      <ProtectedRoute
        path="/project/audition/:type/:id/:characterName"
        component={AuditionComponent}
      />
      <ProtectedRoute
        path="/project/location-audition/:locationName/:locationId"
        component={LocationAuditionComponent}
      />
      <ProtectedRoute
        path="/project/add-location"
        component={AddLocationPage}
      />
      <ProtectedRoute
        path="/project/update-location/:locationId/:actualLocationName/:sceneLocationName/:sceneLocationId"
        component={UpdateLocation}
      />
      <ProtectedRoute path="/project/budgeter" component={BudjeterMain} />
      <ProtectedRoute
        exact
        path={MenusRoute.ACCOUNTING}
        component={AccountingMain}
      />
      <ProtectedRoute
        path="/project/pre-production"
        component={PreProduction}
      />

      <ProtectedRoute
        path="/project/post-production"
        component={PostProduction}
      />
      <ProtectedRoute
        path="/project/production"
        component={ProductionLeftTab}
      />
      <ProtectedRoute
        exact
        path="/project/assets/:type"
        component={PurchaseMore}
      />
      <ProtectedRoute
        path="/project/update-production/:shootDate/:type"
        component={ProductionUpdateTabs}
      />
      {/* <Route path="/project/update-artset" component={UpdateArtSetPage}></Route> */}
      <ProtectedRoute
        path="/project/update-artset/:artSetId/:sceneLocationId/:shootDate/:artSetBudgetId"
        component={UpdateArtSetPage}
      ></ProtectedRoute>
      <ProtectedRoute
        path="/project/update-artset/:artSetId/:sceneLocationId/:shootDate"
        component={UpdateArtSetPage}
      ></ProtectedRoute>
      <ProtectedRoute
        path="/project/update-artset/:shootDate"
        component={UpdateArtSetPageNew}
      ></ProtectedRoute>
      <ProtectedRoute path="/project/equipments" component={EquipmentTab} />
      <ProtectedRoute
        path="/project/special-equipments-details/:shootDate"
        component={EditSpecialEquipmentNew}
      />
      <ProtectedRoute path="/project/crew" component={Crew} />
      <ProtectedRoute
        path="/project/updateCrewprofile/:userId"
        component={crewprofile}
      />
      <ProtectedRoute path="/project/crewprofile/" component={crewprofile} />
      <ProtectedRoute
        path="/project/artsetoverview"
        component={ArtSetOverview}
      />
      <ProtectedRoute
        path="/project/costumedescription"
        component={CostumeDescription}
      />
      <ProtectedRoute
        path="/project/makeupdescription"
        component={MakeupDescription}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.TRANSPORT}/more/:scheduleVehicleId`}
        component={TransportMoreDetails}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.TRANSPORT}/:shootDate`}
        component={TransportMore}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.ATTENDANCE}/:shootDate`}
        component={AttendanceMain}
      />
      <ProtectedRoute
        exact
        path={MenusRoute.TRANSPORT}
        component={TransportMain}
      />
      <ProtectedRoute
        exact
        path={MenusRoute.INVOICE}
        component={InvoicesMain}
      />
      <ProtectedRoute exact path={MenusRoute.REPORT} component={ReportMain} />
      <ProtectedRoute
        exact
        path={`${MenusRoute.REPORT}/:id`}
        component={ReportMore}
      />
      <ProtectedRoute
        exact
        path={`${MenusRoute.INVOICE_MORE}/:shootDate`}
        component={InvoicesMore}
      />
      <ProtectedRoute exact path={`${MenusRoute.LEGAL}`} component={Legal} />
      <ProtectedRoute path="/project/technicians" component={TechnicianTable} />

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export { MainRoutes, ProjectRoutes, WelcomeRoutes };
