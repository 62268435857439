import React from "react";
import { Tooltip } from "antd";

const TooltipComponent = ({ text, placement = "left", sceneNumber = null }) => {
  return (
    <Tooltip placement={placement} title={text}>
      {sceneNumber ? sceneNumber : text}
    </Tooltip>
  );
};

export default TooltipComponent;
