import React, { Component } from "react";
import { Table, Button, Select, Tag, Row, Col } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import TooltipIcon from "components/shared/TooltipIcon";
import {
  MenusRoute,
  Services,
  LocalStorage,
  Screens,
  GlobalConstants,
} from "constants/AppConstants";
import { Link } from "react-router-dom";
import VfxService from "services/VfxService";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { setActiveTab } from "store/action";
import RecordNotFound from "components/shared/RecordNotFound";
import ReadMore from "components/shared/ReadMore";
import { StartCase, checkFor } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import TooltipComponent from "components/shared/TooltipComponent";

class VFX extends Component {
  constructor(props) {
    super(props);
    this._vfxService = new VfxService();
    this.state = {
      dataSource: [],
      isLoading: true,
    };
  }
  state = { visible: false };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.fetchVfxDetails();
  }

  fetchVfxDetails() {
    this._vfxService
      .vfxService(Services.VfxVariables.GET_VFX_LIST)
      .then((res) => {
        this.setState({
          dataSource: res.data.scenes || [],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { dataSource, isLoading } = this.state;
    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        key: "key",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Scene No",
        dataIndex: "sceneNumber",
        key: "sceneNumber",
        width: "10%",
        render: (text, record) => (
          <Tag
            className={`${record.environmentType}${record.timeType} sceneno-tag`}
          >
            <Link
              style={{
                color: "white",
              }}
              to="/project/screen-overview"
              onClick={() => {
                localStorage.setItem(GlobalConstants.REDIRECT_TO, Screens.VFX);
                localStorage.setItem(
                  LocalStorage.ACTIVE_SCENE_ID,
                  record.sceneId
                );
                this.props.setActiveTab("1");
              }}
            >
              <TooltipComponent
                sceneNumber={record.sceneNumber}
                text={record.sceneDescription}
                placement="top"
              />
            </Link>
          </Tag>
        ),
      },
      {
        title: "VFX Description",
        dataIndex: "description",
        key: "description",
        width: "45%",
        render: (text) => (
          // text && <TooltipComponent text={StartCase(text)} placement="left" />
          <ReadMore textColor="#ee556a" lines={1}>
            {StartCase(text)}
          </ReadMore>
        ),
      },
      // {
      //   title: "Budget",
      //   dataIndex: "budget",
      //   key: "budget",
      //   align: "right"
      // },
      {
        title: "VFX Requirements",
        dataIndex: "vfxRequirements",
        key: "vfxRequirements",
        width: "30%",
        render: (text) => (
          <div className="commaContainer">
            {text &&
              text.map((a) => {
                return <span>{StartCase(a)}</span>;
              })}
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: "5%",
        render: (text, record) => (
          <Link
            style={{
              color: "white",
            }}
            to="/project/screen-overview"
            onClick={() => {
              localStorage.setItem(GlobalConstants.REDIRECT_TO, Screens.VFX);
              localStorage.setItem(
                LocalStorage.ACTIVE_SCENE_ID,
                record.sceneId
              );
              this.props.setActiveTab("1");
            }}
          >
            <TooltipIcon
              onClick={() => {
                // this.props.history.push(`${MenusRoute.INVOICE_MORE}/${record.shootDate}`)
              }}
              icon="more"
            />
          </Link>
        ),
      },
    ];

    const { activePermisision } = this.props;

    return (
      <Row>
        <Title>
          <TitleText>VFX </TitleText>
        </Title>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row style={{ padding: 10 }}>
            <Col>
              <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={dataSource}
                // scroll={{ y: "50vh" }}
                pagination={false}
                locale={{
                  emptyText: !isLoading && <RecordNotFound />,
                }}
              />
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Art/Set", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VFX);
