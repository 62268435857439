import React, { Component } from "react";
import { Table, Button } from "antd";
import UpdateSet from "UpdateSet";
import { Images } from "assets/images";
import UpdateMakeupModal from "components/UpdateMakeupModal";
import MakeupTabService from "services/SceneOverviewService/MakeupTabService";
import { Services, GlobalConstants } from "constants/AppConstants";
import { connect } from "react-redux";
import { setActiveTab } from "store/action";
import { Link } from "react-router-dom";
import { Capitalize } from "utilities/ValidationHelper";
import MakeupTable from "./MakeupTable";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";

class MakeupTab extends Component {
  state = {
    data: [],
    dataFetched: false,
    type: "ADD",
    isModalVisible: false,
    budgetId: null,
    recordData: null,
    activeSceneId: null,
  };

  constructor(props) {
    super(props);
    this._makeupService = new MakeupTabService();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.setActiveTab("1");
    localStorage.removeItem(GlobalConstants.REDIRECT_TO);
  }

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  fetchData = () => {
    this._makeupService
      .makeupTabService(
        Services.SceneOVerviewVariable.GET_MAKEUP,
        null,
        null,
        this.state.activeSceneId
      )
      .then((res) => {
        console.log(res);
        this.setState({ data: res.data.makeUp });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  refreshData = () => {
    this.fetchData();
  };

  render() {
    return (
      <div>
        {/* <Table
          columns={columns}
          dataSource={this.state.data}
          pagination={false}
          style={{ padding: "20px 50px" }}
        />
        <UpdateMakeupModal
          // refreshData={this.refreshData}
          // value={record}
          type={type}
          isModalVisible={isModalVisible}
          closeModal={this.closeModal}
          budgetId={budgetId}
          recordData={recordData}
          refreshData={this.fetchData}
        /> */}
        <MakeupTable />
      </div>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Makeup", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeupTab);
