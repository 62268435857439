import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";

import MenuNavigations from "components/shared/MenuNavigations";
import { Menus, LocalStorage } from "constants/AppConstants";
import { ProjectRoutes } from "config/routes";
import { setActiveMenuPermissions } from "store/action";

import "styles.scss";
import SideBarNotification from "components/SidebarNotification";

const { Content, Footer, Sider } = Layout;
// const MenuData = [
//   // {
//   //   menuId: 1,
//   //   menuName: "Back To Projects",
//   //   url: "/welcome"
//   // },
//   {
//     menuId: 1,
//     menuName: "Dashboard",
//     url: "/project/overview/1"
//   },
//   {
//     menuId: 2,
//     menuName: "Script Breakdown",
//     url: "/project/script"
//   },
//   {
//     menuId: 3,
//     menuName: "Actors",
//     url: "/project/actor"
//   },
//   {
//     menuId: 4,
//     menuName: "Location",
//     url: "/project/location"
//   },
//   {
//     menuId: 5,
//     menuName: "Art/Set",
//     url: "/project/artset"
//   },
//   {
//     menuId: 6,
//     menuName: "Costumes",
//     url: "/project/costumes"
//   },
//   {
//     menuId: 7,
//     menuName: "Makeup",
//     url: "/project/makeup"
//   },
//   {
//     menuId: 8,
//     menuName: "VFX",
//     url: "/project/vfx"
//   },
//   {
//     menuId: 9,
//     menuName: "Song",
//     url: "/project/song"
//   },
//   {
//     menuId: 10,
//     menuName: "Stunt",
//     url: "/project/stunt"
//   }
// ];
class ProjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      user: null,
      drawerVisible: false,
      selectedMenu: localStorage.getItem(LocalStorage.PAM) || Menus.DASHBOARD,
    };
  }

  componentDidMount() {
    this.setPermissions(
      localStorage.getItem(LocalStorage.PAM) || Menus.DASHBOARD
    );
  }

  componentWillMount() {
    window.addEventListener("resize", this.resize());
  }
  resize = () => {
    this.setState({ isMobileView: window.innerWidth <= 760 ? true : true });
  };
  onDrawerClose = () => {
    this.setState({ drawerVisible: false });
  };

  setPermissions = (key) => {
    let menus = this.props.menus;
    let menu = Object.assign({}, ...menus.filter((menu) => menu.name === key));
    this.props.setActiveMenuPermissions(menu.permissions);
  };

  onSelectMenu = (key) => {
    this.setPermissions(key);
    this.setState({ selectedMenu: key });
    localStorage.setItem(LocalStorage.PAM, key);
  };
  render() {
    const { drawerVisible, selectedMenu, isMobileView } = this.state;
    const { menus } = this.props;
    return (
      <Fragment>
        {/* <DrawerComponent visible={drawerVisible} onClose={this.onDrawerClose} /> */}
        <Layout
          style={{ minHeight: "100%", display: "flex", flexDirection: "row" }}
        >
          {/* <Sider
            className="custom-slider"
            // collapsed={isMobileView}
            // onMouseEnter={() =>
            //   this.setState({
            //     isMobileView: false
            //   })
            // }
            // onMouseLeave={() =>
            //   this.setState({
            //     isMobileView: true
            //   })
            // }
          > */}
          <div className="slider_style">
            {console.log(menus)}
            <MenuNavigations
              MenuData={menus}
              onSelectMenu={this.onSelectMenu}
              defaultSelectedKeys={selectedMenu}
            />
          </div>
          {/* </Sider> */}
          <Layout
            style={{
              width: "90%",
              backgroundColor: "#f9f9fc",
              padding: 10,
              paddingLeft: 65,
            }}
          >
            {/* <Header style={{ background: "#000000", padding: 0 }} /> */}
            <Content
              style={{
                position: "relative",
                boxShadow: "0 0 13px 0 rgba(82,63,105,.05)",
                borderRadius: 4,
                overflow: "hidden",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  // padding: 24,
                  background: "#fff",
                  // minHeight: 360,
                  position: "relative",
                  height: "100%",
                }}
                // className="boxShadow"
              >
                <ProjectRoutes />
                {/* <Workspace showComponent={selectedMenu} /> */}
              </div>
            </Content>
          </Layout>
        </Layout>
        <SideBarNotification {...this.props} />
      </Fragment>
    );
  }
}
const mapStateToProps = ({ user: { menus } }) => {
  return {
    menus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveMenuPermissions: (activeTabId) =>
      dispatch(setActiveMenuPermissions(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
