import React, { useState, Fragment } from "react";
import Production from ".";
import { Tabs, Row, Col } from "antd";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import { GlobalConstants } from "constants/AppConstants";
import { connect } from "react-redux";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";

const { TabPane } = Tabs;

const ProductionLeftTab = (props) => {
  //   const { setCostumeLeftActiveTab, costumeLeftActiveTab } = props;
  const [ActiveTab, setActiveTab] = useState("1");

  const handleChange = (e) => {
    setActiveTab(e);
  };

  return (
    <Fragment>
      <Row>
        <Col xl={24}>
          <Title hasBack {...props}>
            <TitleText>Production </TitleText>
          </Title>
        </Col>
      </Row>
      {checkFor(props.activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row style={{ padding: 10 }}>
          <Col>
            <Tabs
              activeKey={ActiveTab}
              onChange={handleChange}
              animated
              destroyInactiveTabPane
            >
              <TabPane tab={GlobalConstants.BY_CALL_SHEET} key="1">
                <Production
                  {...props}
                  pageType={GlobalConstants.BY_CALL_SHEET}
                />
              </TabPane>
              <TabPane tab={"Assets & Purchases"} key="2">
                <Production pageType={GlobalConstants.BY_PURCHASE} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

export default connect(mapStateToProps, null)(ProductionLeftTab);
