import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Icon,
  Divider,
  Tooltip,
  Modal,
  Tag,
} from "antd";
import UploadImage from "components/shared/UploadImage/UploadImage";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
// import "./ArtSetTable.css";
import FileCard from "components/shared/FileCardComponent";
import {
  GlobalConstants,
  LocalStorage,
  Screens,
  Menus,
  AttachmentType,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { ScriptService } from "services";
import { Services, Notifications } from "constants/AppConstants";
import ArtSetTabService from "services/SceneOverviewService/ArtSetTabServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { Link } from "react-router-dom";
import ImageViewer from "components/shared/ImageViewer";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import * as _ from "lodash";
import CostumesMakeupService from "services/CostumesMakeupServices";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { handleError } from "utilities/ErrorHandler";
import RecordNotFound from "components/shared/RecordNotFound";
import { checkFor, removeColumns } from "utilities/ValidationHelper";
import TooltipComponent from "components/shared/TooltipComponent";

// import "./CostumeTable.css"

const { Option } = Select;
const { confirm } = Modal;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title, name } = this.props;
    if (this.props.inputType !== "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              name={name}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            onFocus={this.setActiveRecord}
            name={name}
            type="number"
          />
        )}
      </Form.Item>
    );
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave(record, values[e.currentTarget.name], e.currentTarget.name);
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class MakeUpPersonDetails extends Component {
  getArtSetType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_DETAIL_TYPE
      )
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  handleSeperateImage = (record) => {
    let temp = [];
    record.costumeMakeupDocument &&
      record.costumeMakeupDocument.forEach((record) => {
        temp.push({
          attachmentId: record.attachmentId,
          documentId: record.documentId,
        });
      });
    return temp;
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._makeupService = new CostumesMakeupService();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      count: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      dropDown: null,
      sceneId: null,
      isLoading: true,
      isButtonLoading: false,
      sceneId: null,
      statusTypeData: [],
      type: props.match.params.type,
      // tableCount: 0
    };
  }

  componentDidMount() {
    const { characterId, characterName } = this.props.match.params;
    if (characterId && characterName) {
      this.setState(
        {
          characterId,
          characterName,
        },
        () => this.getMakeupData()
      );
    }
  }

  getMakeupData = () => {
    const { characterName, characterId, type } = this.state;
    this.setState({
      count: 0,
      isLoading: false,
    });
    this._makeupService
      .service(
        Services.CostumesMakeupVariables.GET_MAKEUP_LIST_BY_CHARC,
        characterId,
        characterName,
        type
      )
      .then((res) => {
        let dataSource = [];
        res.data.charactersMakeups &&
          res.data.charactersMakeups.map((datum, i) => {
            dataSource.push({ ...datum, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          activeRecord: null,
          isLoading: true,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: true,
        });
      });
  };

  recordData = (record) => {
    if (this.state.fileList) {
      this.handleSave(record, this.state.fileList, "image");
    }
  };

  deleteImage = (id) => {
    let deleteIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let finalData = imageFiles.splice(deleteIndex, 1);
    this.setState({
      imageFiles,
    });
  };

  splitBasedOnFileTypes = (files) => {
    let tempImageFiles = [];
    let files1 = files.filter((file) => {
      if (file.images !== undefined) {
        tempImageFiles.push(...file.images);
      }
    });
    let imageFiles =
      tempImageFiles &&
      tempImageFiles.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      imageFiles,
    });
  };

  handleImage = (url, data) => {
    const { fileList } = this.state;
    // this.setState({
    //   projectimageid: url
    // });
    if (fileList) {
      let temp = [
        ...fileList,
        { attachmentId: url, fileType: GlobalConstants.IMAGE },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ attachmentId: url, fileType: GlobalConstants.IMAGE }];
      this.setState(
        {
          fileList: temp,
        },
        () => {
          this.handleSave(
            data,
            this.state.fileList,
            "image",
            this.state.fileList
          );
        }
      );
    }
  };

  getArtSetData = () => {
    if (this.state.serviceType) {
      this.setState({ count: 0 });
      this._artSetTabService
        .service(
          this.state.serviceType,
          null,
          null,
          this.state.sceneId || this.state.shootDate
        )
        .then((res) => {
          let artSetData = [];
          res.data.artSets &&
            res.data.artSets.map((data, i) => {
              artSetData.push({ ...data, key: this.state.count });
              this.setState({
                count: this.state.count + 1,
              });
            });
          this.setState(
            {
              dataSource: artSetData,
              // isLoading: true
            },
            () => {
              this.splitBasedOnFileTypes(this.state.dataSource);
            }
          );
        });
    }
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
    this._makeupService
      .service(Services.CostumesMakeupVariables.DELETE_MAKEUP_LIST, id)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Successfully Deleted!!"
        );
        // this.setState({ count: 0 });
        // this.getArtSetData();
        this.getMakeupData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );

        this.props.getPermissions();
      });
  };

  handleUpdate = (record) => {
    const { activeRecord } = this.state;
    const { description, scenes } = activeRecord;
    const { characterId } = this.props.match.params;
    const sceneIds = scenes.map((scene) => scene.sceneId);
    const recordData = {
      characterId: parseInt(characterId),
      specialCost: record.specialCost,
      description: record.description,
      oldDescription: description,
      sceneIds,
      documents: record?.images,
    };
    this._makeupService
      .service(
        Services.CostumesMakeupVariables.UPDATE_MAKEUP_LIST,
        record.costumeMakeupId,
        null,
        recordData
      )
      .then((res) => {
        this.setState({ isButtonLoading: false });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getMakeupData();
      })
      .catch((err) => {
        this.props.getPermissions();
        this.getMakeupData();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      sceneNumber: "",
      description: "",
      cost: "",
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type) {
    this.handleSave(record, value, type);
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
    // if (
    //   record.description &&
    //   record.type &&
    //   record.quantity &&
    //   record.image &&
    //   indexOfTheCurrentRow === lengthOfTheDataSource
    // ) {
    //   this.handleAdd();
    // }
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      let modifyItem = newData[index];
      switch (type) {
        case "type":
          modifyItem.type = value;
          break;
        case "specialCost":
          modifyItem.specialCost = value;
          break;
        case "description":
          modifyItem.description = value;
          break;
        case "image":
          if (modifyItem.images) {
            modifyItem.images = [...modifyItem.images, ...value];
          } else {
            modifyItem.images = value;
          }
          this.setState({ fileList: null });
          break;
        default:
          break;
      }
    }
    if (
      row.description &&
      (checkFor(
        this.props.activePermisision,
        GlobalConstants.PERMISSION_FINANCE
      )
        ? row.specialCost
        : true)
    ) {
      if (type !== "image") {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      } else {
        this.handleUpdate(row);
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  render() {
    const { dataSource, isLoading } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const { activePermisision } = this.props;
    let column = [
      {
        title: "#",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Scene Number",
        dataIndex: "scene",
        width: "20%",
        align: "center",
        render: (text, record) =>
          record.scenes &&
          record.scenes.map((scene, i) => (
            <span>
              {/* {scene.sceneNumber} */}
              <Tag
                className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                style={{
                  color: "white",
                  backgroundColor: "#ccc",
                }}
                onClick={() => {
                  localStorage.setItem(
                    GlobalConstants.REDIRECT_TO,
                    Screens.GENERAL
                  );
                  localStorage.setItem(
                    LocalStorage.ACTIVE_SCENE_ID,
                    scene.sceneId
                  );
                  // this.props.setActiveTab("1");
                }}
              >
                <Link to="/project/screen-overview" style={{ color: "white" }}>
                  <TooltipComponent
                    sceneNumber={scene.sceneNumber}
                    text={scene.description}
                    placement="top"
                  />
                </Link>
              </Tag>
            </span>
          )),
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "25%",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      },
      {
        title: "Cost (₹)",
        dataIndex: "specialCost",
        width: "15%",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      },
      {
        title: "Image",
        dataIndex: "images",
        width: "20%",
        align: "center",
        className: "upload-table  take-td-full",
        render: (text, record) => {
          return (
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
              <React.Fragment>
                <div>
                  {record?.imageDTOs?.length > 0 ? (
                    <ImageViewer
                      imageCount={1}
                      ImagesData={record?.imageDTOs}
                      refreshData={() => this.getMakeupData()}
                      type="table"
                      canDelete={true}
                      url={Services.CostumesMakeupVariables.MAKEUP_DELETE_DOC}
                    />
                  ) : null}
                </div>
                <div
                  className="small-upload-image"
                  onClick={() => this.setActiveRecord(record)}
                >
                  {!this.state.isButtonLoading ? (
                    <UploadImage
                      keys={record}
                      type={AttachmentType?.makeupPicture}
                      handleImage={this.handleImage}
                    />
                  ) : null}
                </div>
              </React.Fragment>
            )
          );
        },
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      column.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "5%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Row>
                <Col xl={11}>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() =>
                      this.handleDelete(record.key, record.costumeMakeupId)
                    }
                  >
                    <Tooltip
                      title={GlobalConstants.DEL}
                      placement={GlobalConstants.RIGHT}
                    >
                      <Button icon="delete" type="link" />
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </React.Fragment>
          ) : null,
      });
    }

    if (!checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      column = removeColumns(column, ["Cost (₹)"]);
    }

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          name: col.dataIndex,
          inputType: col.dataIndex === "specialCost" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xl={24}>
          <Title hasBack {...this.props}>
            <TitleText>Makeup Person Details </TitleText>
          </Title>
        </Col>
        <Col xs={24} style={{ padding: 10 }}>
          <Col xs={24}>
            <Row>
              <Col xs={24}>
                <Table
                  className="art-set"
                  components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  locale={{
                    emptyText: isLoading && <RecordNotFound />,
                  }}
                  loading={!isLoading}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus(Menus.MAKEUP, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakeUpPersonDetails);
