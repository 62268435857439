import React, { Component } from "react";
import "antd/dist/antd.css";
import { Upload, Button, Icon } from "antd";
import GlobalService from "services/GlobalService";
import { Services } from "constants/AppConstants";

class UploadMultipleDocumentFile extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
  }
  state = {
    fileList: null,
  };
  handleChange = (info) => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-10);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    this.handleFile(info);
    this.setState({ fileList });
  };

  handleFile = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    // this.setState({ fileList });
    // console.log("uploadfile list", this.state.fileList);

    // const token = {
    //   headers: {
    //     "Authorization": `Bearer ${this.props.accessToken}`
    //   }
    // };
    if (info.file.status === "done") {
      let imageFile = new FormData();
      imageFile.append("file", info.file.originFileObj);
      console.log("file image", imageFile);
      this._globalService
        .globalService(Services.GlobalVariables.GET_IMAGE_ID, imageFile)
        .then((res) => {
          console.log("imageid", res.data);
          this.props.handleDocument(res.data.fileDataId);
          console.log("photoid", res.data.fileDataId);
        });
    }
  };
  render() {
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      multiple: true,
    };
    return (
      <Upload {...props} fileList={this.state.fileList}>
        <Button>
          <Icon type="upload" /> Upload
        </Button>
      </Upload>
    );
  }
}
export default UploadMultipleDocumentFile;
