import React, { Component } from "react";
import { Input } from "antd";
import debounce from "lodash/debounce";
import { GeoServices } from "./geoCall";
import { numberOnly } from "utilities/ValidationHelper";
import { GlobalConstants } from "constants/AppConstants";
import { showActionMessage } from "../NotificationComponent";

class GeoCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      defaultValue: null,
    };
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.defaultValue);
    if (this.state.defaultValue !== nextProps.defaultValue) {
      this.setState({
        defaultValue: nextProps.defaultValue,
      });
    }
  }

  handleGeoChange = (e) => {
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          console.log("results....", res.data.results[0].address_components);
          if (
            res.data.results[0].address_components[1] &&
            res.data.results[0].address_components[2] &&
            res.data.results[0].address_components[3]
          ) {
            this.props.getDetails(
              res.data.results[0].address_components[1].long_name,
              (res.data.results[0].address_components[4] &&
                res.data.results[0].address_components[3].long_name) ||
                res.data.results[0].address_components[2].long_name,
              (res.data.results[0].address_components[4] &&
                res.data.results[0].address_components[4].long_name) ||
                res.data.results[0].address_components[3].long_name,
              e
            );
          } else {
            showActionMessage(
              GlobalConstants.WARNING,
              `No data found in this pincode, Please try again`
            );
            this.props.getDetails(null, null, null, e);
          }
        })
        .catch((err) => {
          console.log(err);
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
          this.props.getDetails(null, null, null, e);
        });
    }
  };
  render() {
    const { defaultValue } = this.props;
    console.log("this.props.defaultValue.zipcode", defaultValue);
    return (
      <Input
        type="number"
        defaultValue={defaultValue || this.state.defaultValue}
        placeholder="enter you pincode"
        // style={{
        //   marginTop: 10,
        //   width: 400
        // }}
        // onChange={this.handleGeoChange}
        maxLength={6}
        onChange={(e) => this.handleGeoChange(numberOnly(e))}
      />
    );
  }
}

export default GeoCode;
