import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Table, Button, Row, Col, Icon, Input, Divider, Select } from "antd";

import {
  amountOnly,
  StartCase,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  MenusRoute,
  Services,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { Link } from "react-router-dom";
import DeleteComponent from "components/shared/DeleteComponent";
import Accounting from "services/AccountingServices";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import TooltipIcon from "components/shared/TooltipIcon";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

class AccVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
      currentAccountingVendor: "",
    };
    this.Accounting = new Accounting();
  }

  componentDidMount() {
    this.fetchData();
    this.getAccountEstimation();
  }

  getAccountEstimation() {
    this.Accounting.services(
      Services.AccountingVariables.GET_ACCOUNT_ESTIMATION
    ).then((res) => {
      console.clear();
      console.log({ res });
      this.setState({
        currentAccountingVendor: res.data.vendorBudget || "",
      });
    });
  }

  fetchData = () => {
    this.setState({
      isLoading: true,
    });
    this.Accounting.services(
      Services.AccountingVariables.GET_PRE_PRODUCTION,
      AccountingVariables.VENDOR
    )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionAccounts,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      quantity: "",
      amount: "",
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, projectProductionCategoryId }) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };

  render() {
    const { dataSource, isLoading, currentAccountingVendor } = this.state;
    const { activePermisision } = this.props;

    const columns = [
      {
        title: "#",
        align: "center",
        width: "10%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Vendor",
        dataIndex: "description",
        key: "name",
        align: "left",
        width: "20%",
        render: (text, record) =>
          checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
            <Link to={`/project/vendorupdate/${record.vendorId}`}>
              <Button type="link">{StartCase(text)}</Button>
            </Link>
          ) : (
            StartCase(text)
          ),
      },
      {
        title: "Vendor Type",
        dataIndex: "vendorType",
        key: "vendorType",
        align: "left",
        width: "20%",
        render: (text) => ReplaceWithSpace(text),
      },
      {
        title: "Amount paid",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: "20%",
      },
      {
        title: "Payment Type",
        dataIndex: "vendorPaymentType",
        key: "vendorPaymentType",
        align: "left",
        width: "20%",
        render: (text) => text && ReplaceWithSpace(text),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "ACTION",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <>
            <Link
              to={{
                pathname: `${MenusRoute.ACCOUNTING}/pre-production/${record.productionAccountId}/edit`,
                state: {
                  category: GlobalConstants.VENDOR,
                  title: StartCase(record.description),
                  hidePaymentType: true,
                },
              }}
            >
              <TooltipIcon icon="edit" />
            </Link>
            {/* {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
              <Link to={`/project/vendorupdate/${record.vendorId}`}>
                <Button type="link">Edit</Button>
              </Link>
            ) : (
              ""
            )} */}
            {/* {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) ? (
              <DeleteComponent
                record={record}
                onClick={record => this.handleDelete(record)}
              />
            ) : (
              ""
            )} */}
            {/* <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            /> */}
          </>
        ),
      });
    }
    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>
              Accounting - Vendor {currentAccountingVendor}{" "}
            </TitleText>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button type="primary" style={{ float: "right" }}>
                <Link to="/project/vendorcreate">
                  <Icon type="plus" style={{ fontSize: "15px" }} /> Vendor
                </Link>
              </Button>
            )}
          </Title>
        </Row>
        <Row type="flex" justify="center" align="middle">
          <Col xl={20} style={{ paddingTop: 24 }}>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              loading={isLoading}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccVendor);
