import React, { Component, Fragment } from "react";
import { Button, Modal } from "antd";
import Uploaddocumentfile from "components/uploaddocumentfile";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
  AttachmentType,
} from "constants/AppConstants";
import UploadDocumentComponent from "components/shared/UploadDocumentAndDisplay/UploadDocumentComponent";
import { handleError } from "utilities/ErrorHandler";
import EquipmentProjectService from "services/EquipmentProjectService";
import DocumentDelete from "services/DocumentDelete";
import Accounting from "services/AccountingServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import DisplayDocumentComponent from "components/shared/UploadDocumentAndDisplay/DisplayDocumentComponent";
import VfxService from "services/VfxService";

class VFXDocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      data: props.data,
      fileList: [],
      vfxDetailId: props.data.vfxDetailId,
    };

    this.vfx = new VfxService();
    this._docDelete = new DocumentDelete();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { vfxDetailId } = this.state;
    if (vfxDetailId) {
      this.vfx
        .vfxService(Services.VfxVariables.GET_VFX_BY_ID, vfxDetailId)
        .then((res) => {
          this.setState({
            dataSource: res.data,
            isLoading: false,
            fileList: res.data.vfxDocuments || [],
          });
        });
    }
  };

  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleDelete = (id) => {
    this._docDelete
      .deleteService(Services.VfxVariables.DELETE_VFX_DOCUMENT, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.fetchData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  handleDocument = (files) => {
    const { data, fileList } = this.state;
    const lists = fileList;

    files.forEach((file) => {
      lists.push({
        attachmentId: file?.attachmentId,
        fileType: file?.fileType,
      });
    });
    data.vfxDocuments = lists;
    this.handleUpdate(data);
  };

  handleUpdate = (data) => {
    const { vfxDetailId } = data;
    this.vfx
      .vfxService(Services.VfxVariables.UPDATE_VFX, vfxDetailId, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  render() {
    const { isModalVisible, fileList } = this.state;
    const { data, refreshData, disabled } = this.props;
    return (
      <Fragment>
        <Button
          style={{ width: "100%" }}
          type="ghost"
          onClick={this.showModal}
          disabled={disabled}
        >
          Documents
        </Button>
        <Modal
          title="Documents"
          visible={isModalVisible}
          onOk={this.handleOk}
          // onCancel={this.handleCancel}
          className="createModal no-x"
          footer={null}
          destroyOnClose
          width={1000}
        >
          <DisplayDocumentComponent
            handleDelete={this.handleDelete}
            files={fileList}
          />
          <UploadDocumentComponent
            type={AttachmentType?.vfxDocument}
            handleDocument={this.handleDocument}
            handleCancel={this.handleCancel}
          />
        </Modal>
      </Fragment>
    );
  }
}
export default VFXDocumentUpload;
