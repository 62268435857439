import React from "react";
import TextComponent from "components/shared/TextComponent";
import logo from "assets/images/logo.png";

const NoSceneFound = (props) => {
  const refreshProjectList = () => {
    props.refreshProjectList();
  };
  return (
    <div style={{ textAlign: "center" }}>
      <TextComponent style={{ color: "red" }} customize>
        <img
          src={logo}
          style={{ width: "70px", height: "70px", objectFit: "contain" }}
          alt="logo image"
        />
        <h2>No {props.type} found for this scene</h2>
      </TextComponent>
    </div>
  );
};

export default NoSceneFound;
