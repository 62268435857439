import React, { useState, useEffect } from "react";
import GlobalService from "services/GlobalService";
import { Services, GlobalConstants } from "constants/AppConstants";
import { Select } from "antd";
import TransportService from "services/TransportService";

const { Option, OptGroup } = Select;

const groupName = [
  "Main",
  "Supporting",
  "Junior",
  "Animal",
  "Crews",
  "Special Equipments",
  "Standard Equipments",
  "Common Equipments",
];

const ActorCrewDD = ({
  text,
  record,
  onChange,
  type,
  disabled,
  fieldName,
  className,
  shootDate,
  likeBudget = false,
}) => {
  const [characterNameList, setPaymentTypeData] = useState([]);
  const [dataLoaded, setLoading] = useState(false);
  const api = new TransportService();

  useEffect(() => {
    getPaymentTypeData();
  }, [type]);

  const getPaymentTypeData = () => {
    setLoading(false);
    if (type) {
      const data = {
        type,
        shootDate,
      };
      api
        .transportService(
          Services.TransportVariables.GET_VEHICLE_PASSENGER,
          data
        )
        .then((res) => {
          setPaymentTypeData(Object.values(res.data));
          setLoading(true);
        });
    }
  };

  const getName = (value) => {
    let name = null;
    characterNameList &&
      characterNameList.map((character) => {
        character &&
          character.map((char) => {
            if (char.value === value) {
              name = char.type;
              return;
            }
          });
      });
    return name;
  };

  const handleDD = (values, record, fieldName) => {
    const data =
      values &&
      values.map((value) => {
        return {
          [`${getName(value)}`]: value,
        };
      });
    onChange(data, record, fieldName);
  };

  return (
    characterNameList &&
    dataLoaded && (
      <Select
        style={{ width: "100%" }}
        className={`border-none ${className}`}
        placeholder="Select type"
        disabled={disabled}
        onChange={(value) => handleDD(value, record, fieldName)}
        defaultValue={text}
        mode="multiple"
        maxTagTextLength={3}
        maxTagCount={2}
      >
        {characterNameList &&
          characterNameList.map((characterGroup, i) => (
            <OptGroup key={i} label={groupName[i]}>
              {characterGroup &&
                characterGroup.map((character, i) => (
                  <Option key={i} value={character.value}>
                    {character.label}
                  </Option>
                ))}
            </OptGroup>
          ))}
      </Select>
    )
  );
};

export default ActorCrewDD;
