import React, { useState, useEffect } from "react";
import GlobalService from "services/GlobalService";
import { Services, GlobalConstants } from "constants/AppConstants";
import { Select } from "antd";

const { Option } = Select;

const AccPaymentTypeDD = ({
  text,
  record,
  onChange,
  type,
  disabled,
  fieldName,
  className,
  dataSource = null,
  likeBudget = false,
}) => {
  const [PaymentTypeData, setPaymentTypeData] = useState([]);
  const api = new GlobalService();

  useEffect(() => {
    if (!dataSource) {
      if (likeBudget) getPaymentTypeData1();
      else {
        getPaymentTypeData();
      }
    } else {
      setPaymentTypeData(dataSource);
    }
  }, [type, text]);

  const getPaymentTypeData = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRE_PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        setPaymentTypeData(res.data);
      });
  };
  const getPaymentTypeData1 = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        setPaymentTypeData(res.data);
      });
  };
  console.log(text);
  return (
    PaymentTypeData && (
      <Select
        style={{ width: "100%" }}
        className={`border-none ${className}`}
        placeholder="Select type"
        disabled={disabled}
        onChange={(value) => onChange(value, record, fieldName)}
        value={text ? text : ""}
        // disabled={record.type !== "SPECIAL_PROPERTY"}
      >
        {PaymentTypeData &&
          PaymentTypeData.map((option, i) => (
            <Option key={i} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
    )
  );
};

export default AccPaymentTypeDD;
