import React from "react";
import "../Verification/verification.scss";

const Verification = () => {
  const queryParamaters = new URLSearchParams(window.location.search);
  const status = queryParamaters.get("status");

  return (
    <div className="verification">
      <div className="logo">ACE</div>
      <div className="innerBox">
        <img src="mail-icon.png" className="mailIcon" />
        <h1 style={{ marginTop: "2%" }}>
          {status == "true"
            ? "Your account has been verified!"
            : "Account verification failed!"}
        </h1>
        <p>
          {status == "true"
            ? "Click on the button to Login"
            : "There seems to be some error. Please try again :("}
        </p>
        <button className="login">
          {status == "true" ? "LOGIN" : "Try again"}
        </button>
      </div>
    </div>
  );
};

export default Verification;
