import React, { Component, Fragment } from "react";
import { Layout, Button, Breadcrumb } from "antd";
import DrawerComponent from "components/shared/DrawerComponent";
import MenuNavigations from "components/shared/MenuNavigations";
import Workspace from "components/shared/Workspace";
import { Menus, LocalStorage } from "constants/AppConstants";
import { WelcomeRoutes, ProjectRoutes } from "config/routes";
import { connect } from "react-redux";
const { Content, Footer, Sider } = Layout;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      user: null,
      drawerVisible: false,
      selectedMenu: localStorage.getItem(LocalStorage.PAM) || Menus.PROJECTS,
    };
    // code to find whether the page is reloaded
    if (window.performance) {
      if (performance.navigation.type === 1) {
        // alert("This page is reloaded");
      } else {
        // alert("This page is not reloaded");
      }
    }
  }
  componentWillMount() {
    window.addEventListener("resize", this.resize());
  }
  resize = () => {
    this.setState({ isMobileView: window.innerWidth <= 760 ? true : false });
  };
  onDrawerClose = () => {
    this.setState({ drawerVisible: false });
  };
  onSelectMenu = (key) => {
    this.setState({ selectedMenu: key });
    localStorage.setItem(LocalStorage.PAM, key);
  };
  render() {
    const { drawerVisible, selectedMenu, isMobileView } = this.state;
    const { role } = this.props;
    const MenuData = [
      {
        menuId: 1,
        name: "Projects",
        url: "/projects",
      },
    ];

    if (role === "ProjectAdmin") {
      MenuData.push({
        menuId: 2,
        name: "Approval",
        url: "/approval",
      });
    }

    return (
      <Fragment>
        {/* <DrawerComponent visible={drawerVisible} onClose={this.onDrawerClose} /> */}
        <Layout style={{ minHeight: "100%" }}>
          <div className="slider_style home">
            <MenuNavigations
              MenuData={MenuData}
              onSelectMenu={this.onSelectMenu}
              defaultSelectedKeys={selectedMenu}
            />
          </div>
          <Layout style={{ padding: 20, paddingLeft: 60 }}>
            {/* <Header style={{ background: "#000000", padding: 0 }} /> */}
            <Content
              style={{
                margin: "0 16px",
                position: "relative",
                boxShadow: "0 0 13px 0 rgba(82,63,105,.05)",
                borderRadius: 4,
                overflow: "hidden",
                backgroundColor: "white",
              }}
            >
              {/* {selectedMenu} */}
              {/* <div
               style={{
                 backgroundColor: "red",
                 position: "absolute",
                 borderRadius: "10px",
                 right: "-5px",
                 top: "5px"
               }}
             >
               <Button
                 type="primary"
                 icon="setting"
                 size="default"
                 onClick={() => this.setState({ drawerVisible: true })}
               />
             </div> */}
              <div
                style={{
                  padding: 10,
                  background: "#fff",
                  minHeight: 360,
                  // marginTop: "20px",
                }}
              >
                <WelcomeRoutes />
              </div>
              {/* <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
                ACE Production ©2019 Created by Round's Edge Technologies
              </Footer> */}
            </Content>
          </Layout>
        </Layout>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ scene, user }) => {
  if (user.currentUser) {
    return {
      role: user.currentUser && user.currentUser.roleName,
      // activePermisision: getSPermissionFromMenus("Costume", menus)
    };
  }
};
export default connect(mapStateToProps, null)(Home);
