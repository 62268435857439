import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getUserAPIURL, getScriptAPIURL } from "utilities/APIHelper";

// const headers = {
//   'Content-Type': 'application/json',
//    }
export default class RoleServices {
  services(type = null, roleId = null, companyId = null, data = null) {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )} `,
      },
    };

    switch (type) {
      case Services.RolesVariables.GET_ROLES:
        return axios.get(`${getUserAPIURL()}/roles`, config);
      case Services.RolesVariables.GET_ROLE_PERMISSIONS:
        return axios.get(
          `${getUserAPIURL()}/role/${roleId}/company/${companyId}/permissions`,
          config
        );
      case Services.RolesVariables.UPDATE_ROLE_PERMISSIONS:
        return axios.put(
          `${getUserAPIURL()}/role/${roleId}/company/${companyId}/permissions`,
          data,
          config
        );
      case Services.RolesVariables.RESET_ROLE_PERMISSIONS:
        return axios.post(
          `${getUserAPIURL()}/role/permissions`,
          data,
          config
        );
      default:
        break;
    }
  }
}
