import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Form, DatePicker, Select } from "antd";

import { LocalStorage, Services } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import PDFComponent from "components/shared/PDFViewer";
import { GlobalConstants } from "constants/AppConstants";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import GlobalService from "services/GlobalService";

const { Option } = Select;

const BudgetEstimationReportForm = (props) => {
  const api = new GlobalService();
  const [ShootDate, setShootDate] = useState(null);
  const [CategoryList, setCategoryList] = useState(null);
  const [Category, setCategory] = useState(null);

  const dateFormat = GlobalConstants.DD_MM_YYYY;
  const formItemLayout = {
    labelCol: {
      //   xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      //   xs: { span: 12 },
      sm: { span: 12 },
    },
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_CATEGORY
      )
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dateChange = (value, shootDate) => {
    setShootDate(shootDate);
  };

  let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  // const url = ShootDate
  //   ? `${getScriptAPIURL()}budget/schedule/reports/download?contentDisposition=INLINE&date=${ShootDate}&projectId=${projectId}`
  //   : `${getScriptAPIURL()}project/${projectId}/budget/reports/download?contentDisposition=INLINE`;
  const url = `${getScriptAPIURL()}/project/${projectId}/budget/reports/download?contentDisposition=INLINE${
    Category ? `&budgetProductionType=${Category}` : ""
  }${ShootDate ? `&date=${ShootDate}` : ""}`;

  const { getFieldDecorator } = props.form;
  const { activePermisision } = props;

  return (
    <Fragment>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row>
          <Col>
            <Form {...formItemLayout}>
              <Row gutter={16}>
                <Col xl={7}>
                  <Form.Item label="Shoot date" hasFeedback>
                    {getFieldDecorator("ShootDate", {
                      rules: [
                        {
                          required: false,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <DatePicker
                        value={ShootDate}
                        onChange={dateChange}
                        format={dateFormat}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={7}>
                  <Form.Item label="Category" hasFeedback>
                    {getFieldDecorator("Category", {
                      rules: [
                        {
                          required: false,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        className="upperCase"
                        allowClear
                        onChange={(Category) => {
                          setCategory(Category);
                          // getPayee(Category);
                          // setPayee(null);
                          // props.form.setFieldsValue({
                          //   Payee: null
                          // });
                        }}
                      >
                        {CategoryList &&
                          CategoryList.map((list, i) => {
                            return (
                              <Option key={i} value={list.value}>
                                {list.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col xl={3}>
                  <PDFComponent
                    customURL={url}
                    icon="download"
                    text="Report"
                    projectId={projectId}
                    hideMail
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const BudgetEstimationReport = Form.create({ name: "register" })(
  BudgetEstimationReportForm
);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.REPORT, menus),
  };
};

export default connect(mapStateToProps, null)(BudgetEstimationReport);
