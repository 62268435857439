import React, { Component, Fragment } from "react";
import { Table, Tag, Button, Row, Col, Divider, Icon, Input } from "antd";
import { Link } from "react-router-dom";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import BudjetService from "services/BudjetService";
import {
  Services,
  GlobalConstants,
  LocalStorage,
  Screens,
  MenusRoute,
} from "constants/AppConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import {
  checkFor,
  Capitalize,
  PriceFormat,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import AccProductionAddEntry from "../AccPreProductionEdit/AccProductionAddEntry";
import Highlighter from "react-highlight-words";

const { Search } = Input;

class AccProductionTable extends Component {
  constructor(props) {
    super(props);

    this._budgetService = new BudjetService();

    this.state = {
      dataSource: [],
      contractDataSource: [],
      isLoading: false,
      pageType: props.pageType,
      searchKey: null,
      productionDepartment: props.productionDepartment,
    };
  }

  componentDidMount() {
    this.getProductionData();
  }

  getProductionData = () => {
    const { pageType } = this.state;
    const type =
      pageType === GlobalConstants.BY_CALL_SHEET
        ? Services.BudjetVariables.GET_PRODUCTION_DATA
        : null;
    if (type) {
      this._budgetService
        .budjetService(type)
        .then((res) => {
          this.setState({
            dataSource: res.data.productionShoots,
            isLoading: true,
          });
        })
        .catch((err) =>
          this.setState({
            isLoading: true,
          })
        );
    } else {
      this._budgetService
        .budjetService(Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE)
        .then((res) => {
          this.setState({
            contractDataSource: res.data.productionPurchases,
            isLoading: true,
          });
        })
        .catch((err) =>
          this.setState({
            isLoading: true,
          })
        );
    }
  };

  render() {
    const {
      dataSource,
      isLoading,
      pageType,
      contractDataSource,
      searchKey,
      productionDepartment,
    } = this.state;
    const { activePermisision, getPermissions } = this.props;

    const contractColumn = [
      {
        title: "#",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      // {
      //   title: "Description",
      //   dataIndex: "description",
      //   // align: "center",
      //   width: "70%",
      //   render: text => (
      //     <div style={{ textAlign: "left" }}>{Capitalize(text)}</div>
      //   )
      // },
      // {
      //   title: "Details",
      //   dataIndex: "description",
      //   // align: "center",
      //   render: text => (
      //     <div style={{ textAlign: "left" }}>{Capitalize(text)}</div>
      //   )
      // },
      {
        title: "Department",
        dataIndex: "productionCategoryDepartment",
        align: "center",
        width: "30%",
        render: (text, record) => (
          <Tag className={`${record.productionCategoryDepartment} dateTag`}>
            {ReplaceWithSpace(text)}
          </Tag>
        ),
      },
      {
        title: "Paid Amount",
        dataIndex: "amountPaid",
        align: "right",
        width: "30%",
        // render: (text, record) => (
        //   <Tag className={`${record.departmentEnum} dateTag`}>{text}</Tag>
        // )
      },
      {
        title: "Invoice Amount",
        dataIndex: "budgetEstimation",
        align: "right",
        width: "30%",
        // render: (text, record) => (
        //   <Tag className={`${record.departmentEnum} dateTag`}>{text}</Tag>
        // )
      },
      // {
      //   title: "Payment Type",
      //   dataIndex: "paymentType",
      //   align: "center"
      // }
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      contractColumn.push({
        title: "Action",
        align: "center",
        width: "5%",

        render: (text, record) => (
          <TooltipIcon
            onClick={() =>
              this.props.history.push({
                pathname: `${MenusRoute.ACCOUNTING}/production/purchase/edit`,
                state: {
                  category: GlobalConstants.PRODUCTION_PURCHASE,
                  title: record.description,
                  details: {
                    productionCategoryDepartment:
                      record.productionCategoryDepartment,
                    description: record.description || "",
                    categoryDepartment: record.departmentEnum,
                  },
                },
              })
            }
            icon="more"
          />
        ),
      });
    }
    const callSheetColumns = [
      {
        title: "#",
        dataIndex: "name",
        key: "name",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Shooting Date",
        dataIndex: "shootDate",
        align: "center",
      },
      {
        title: "Scene Location",
        dataIndex: "sceneLocations",
        render: (text, record) => (
          <div className="commaContainer" style={{ width: 200 }}>
            {text &&
              text.map((a) => {
                return <span>{a.locationName}</span>;
              })}
          </div>
        ),
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocations",
        render: (text, record) => (
          <div className="commaContainer" style={{ width: 100 }}>
            {text &&
              text.map((a) => {
                return <span>{Capitalize(a.locationName)}</span>;
              })}
          </div>
        ),
      },
      {
        title: "Scene",
        dataIndex: "scenes",
        render: (text, record) => (
          <div style={{ width: 150 }}>
            {text &&
              text.map((scene) => {
                return (
                  <span>
                    <Tag
                      className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                      style={{
                        color: "white",
                        backgroundColor: "#ccc",
                        marginBottom: 10,
                      }}
                      onClick={() => {
                        this.props.history.push("/project/screen-overview");
                        localStorage.setItem(
                          GlobalConstants.REDIRECT_TO,
                          Screens.GENERAL
                        );
                        localStorage.setItem(
                          LocalStorage.ACTIVE_SCENE_ID,
                          scene.sceneId
                        );
                        // this.props.setActiveTab("1");
                      }}
                    >
                      <Link style={{ color: "white" }}>
                        {scene.sceneNumber}
                      </Link>
                    </Tag>
                  </span>
                );
              })}
          </div>
        ),
      },
      {
        title: "Amount Paid",
        dataIndex: "budgetEstimation",
        key: "address",
        align: "center",
        render: (text) => (
          <div style={{ textAlign: "right" }}>{PriceFormat(text)}</div>
        ),
      },
      // {
      //   title: "Status",
      //   dataIndex: "budgetState",
      //   key: "address",
      //   align: "center",
      //   render: (value, record) => (
      //     <Tag className={`statusTag ${record.budgetStateEnum}`}>
      //       {record.budgetState}
      //     </Tag>
      //   )
      // },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      callSheetColumns.push({
        title: "Action",
        key: "action",
        align: "center",
        render: (text, record) => (
          <>
            {/* <Link to={`/project/update-production/${record.shootDate}/view`}> */}
            <Icon type="eye" />
            {/* </Link> */}
            <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            />
            <Link
              to={`${MenusRoute.ACCOUNTING}/update-production/${record.shootDate}/edit`}
            >
              <Icon type="edit" />
            </Link>
          </>
        ),
      });
    }

    let filteredDataSource = searchKey
      ? contractDataSource.filter((data) =>
          data?.description?.toLowerCase()?.includes(searchKey)
        )
      : contractDataSource;

    const columns =
      pageType === GlobalConstants.BY_PURCHASE
        ? contractColumn
        : callSheetColumns;

    return (
      <Fragment>
        <Row type="flex" justify="start" style={{ padding: 10 }}>
          <Col
            xl={12}
            style={{
              marginBottom: 10,
            }}
          >
            {/* <Row type="flex">
              <Col xl={12}></Col>
              <Col xl={12}>
                <AccProductionAddEntry {...this.props} />
              </Col>
            </Row> */}
            <Search
              placeholder="search description here..."
              onChange={(e) => this.setState({ searchKey: e.target.value })}
              enterButton
              allowClear
            />
          </Col>
          <Col xl={24}>
            <Table
              bordered
              loading={!isLoading}
              dataSource={
                pageType === GlobalConstants.BY_PURCHASE
                  ? filteredDataSource
                  : dataSource
              }
              columns={columns}
              // pagination={{ pageSize: 1000 }}
              pagination={false}
              locale={{
                emptyText: isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccProductionTable);
