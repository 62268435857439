import React, { Component, Fragment } from "react";
import { Form, Button, Row, Icon, Modal, Col, Select, Input } from "antd";

import BudjetService from "services/BudjetService";
import GlobalService from "services/GlobalService";
import {
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import { ReplaceWithSpace } from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";

const { Option } = Select;

class AccProductionAddEntryForm extends Component {
  constructor(props) {
    super(props);
    this.global = new GlobalService();
    this._budjetService = new BudjetService();
    this.state = {
      Description: null,
      Department: props.productionDepartment,
      DepartmentList: [],
      ModalVisible: false,
    };
  }

  componentDidMount() {
    this.getDepartmentData();
  }

  getDepartmentData = () => {
    this.global
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRE_PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOk = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { refreshData } = this.props;
        const { Description, Department } = this.state;
        const data = {
          category: Description,
          categoryDepartment: Department,
        };
        this._budjetService
          .budjetService(Services.BudjetVariables.POST_UPDATE_PRODUCTION, data)
          .then((res) => {
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
            this.setState({
              ModalVisible: false,
            });
            refreshData();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      ModalVisible: false,
    });
  };

  render() {
    const { Description, Department, DepartmentList, ModalVisible } =
      this.state;
    const { getFieldDecorator } = this.props.form;
    const { title, departments, disabled } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
    };

    return (
      <Fragment>
        <Row>
          <Button
            disabled={disabled}
            onClick={() =>
              this.setState({
                ModalVisible: true,
              })
            }
            type="primary"
            style={{ marginBottom: 16, float: "right" }}
            //   disabled={hasNewRow}
          >
            <Icon type="plus" style={{ fontSize: "15px" }} /> {title}
          </Button>
        </Row>
        <Modal
          destroyOnClose
          className="createModal"
          key={1}
          title={`Create ${title}`}
          visible={ModalVisible}
          onOk={this.handleOk}
          okText="Save"
          cancelText="Cancel"
          onCancel={this.handleCancel}
        >
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col xl={24}>
                <Form.Item label="Description" hasFeedback>
                  {getFieldDecorator("Description", {
                    rules: [
                      {
                        required: true,
                        message: "Description Required!",
                      },
                    ],
                  })(
                    <Input
                      autoFocus
                      placeholder="enter description"
                      onChange={(e) =>
                        this.setState({
                          Description: e.target.value,
                        })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              {departments && (
                <Col xl={24}>
                  <Form.Item label="Department" hasFeedback>
                    {getFieldDecorator("Department", {
                      rules: [
                        {
                          required: true,
                          message: "Department Required!",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        // className="upperCase"
                        showSearch
                        onChange={(Department) =>
                          this.setState({
                            Department,
                          })
                        }
                        onBlur={this.handleInvoiceBlur}
                        onSearch={this.handleInvoiceNumber}
                      >
                        {departments &&
                          departments.map((data, i) => {
                            return (
                              <Option key={i} value={data.value}>
                                {data.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Modal>
      </Fragment>
    );
  }
}

const AccProductionAddEntry = Form.create({ name: "register" })(
  AccProductionAddEntryForm
);

export default AccProductionAddEntry;
