import React, { Fragment, Component } from "react";
import { Button, Table, Row, Col, Tag } from "antd";
import { connect } from "react-redux";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  Services,
  GlobalConstants,
  Screens,
  LocalStorage,
  EnumLocation,
} from "constants/AppConstants";
import SongsStuntsServices from "services/SongsStuntsServices";
import { setActiveTab } from "store/action";
import RecordNotFound from "components/shared/RecordNotFound";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { checkFor, removeColumns } from "utilities/ValidationHelper";
import { Link } from "react-router-dom";
import TooltipIcon from "components/shared/TooltipIcon";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import TooltipComponent from "components/shared/TooltipComponent";
import EnumToUI from "utilities/EnumHelper";

const data = [
  {
    key: "1",
    age: "1a,2d,4b",
    aloc: "Gokulam house",
    status: "Approved",
    budget: "10000",
    scdesc: "Naman House ",
  },
  {
    key: "2",
    age: "2a,3d,6c",
    aloc: "To be confirmed",
    status: "Pending",
    budget: "",
    scdesc: "Ramu House",
  },
  {
    key: "3",
    age: "3c,4d,5a",
    aloc: "To be confirmed",
    status: "Pending",
    budget: "",
    scdesc: "Akshara house",
  },
];
class Stunt extends Component {
  constructor(props) {
    super(props);
    this._songServices = new SongsStuntsServices();
    this.state = {
      StuntData: [],
      fetchedData: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this._songServices
      .service(
        Services.SongVariables.GET_SONGS_STUNT_LIST_BY_PROJECT,
        Services.SongVariables.STUNT
      )
      .then((res) => {
        this.setState({
          StuntData: res.data.scenes,
          fetchedData: true,
        });
      })
      .catch((err) => {
        this.setState({
          fetchedData: true,
        });
      });
  };

  render() {
    const { activePermisision } = this.props;
    let columns = [
      {
        title: "#",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Scene no",
        dataIndex: "sceneNumber",
        width: "10%",
        render: (text, record) => (
          <Tag
            className={`${record.environmentType}${record.timeType} sceneno-tag`}
          >
            <Link
              style={{
                color: "white",
              }}
              to="/project/screen-overview"
              onClick={() => {
                localStorage.setItem(
                  GlobalConstants.REDIRECT_TO,
                  Screens.STUNTS
                );
                localStorage.setItem(
                  LocalStorage.ACTIVE_SCENE_ID,
                  record.sceneId
                );
                this.props.setActiveTab("1");
              }}
            >
              <TooltipComponent
                sceneNumber={record.sceneNumber}
                text={record.sceneDescription}
                placement="top"
              />
            </Link>
          </Tag>
        ),
        // align: "center"
      },
      {
        title: "Scene Location",
        dataIndex: "location",
        width: "28%",
        // align: "center",
        className: "caps",
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocation",
        width: "25%",
        // align: "center",
        className: "caps",
      },

      {
        title: "Budget (₹)",
        dataIndex: "budget",
        align: "right",
        width: "10%",
        render: (text) =>
          text && <span style={{ float: "right" }}>{text}</span>,
      },
      {
        title: "Status",
        dataIndex: "state",
        align: "center",
        width: "15%",
        render: (value, record) => (
          <Tag className={`statusTag ${record.state}`}>
            {EnumToUI(record.state, EnumLocation?.location)}
          </Tag>
        ),
      },
      // {
      //   title: "Documents",
      //   dataIndex: "doc",
      //   align: "center"
      // },
    ];

    if (!checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      columns = removeColumns(columns, ["Budget (₹)"]);
    }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "Action",
        key: "action",
        align: "center",
        // width: "5%",
        render: (text, record) => (
          <div>
            <Button
              type="link"
              onClick={() => {
                this.props.history.push("/project/screen-overview");
                localStorage.setItem(
                  GlobalConstants.REDIRECT_TO,
                  Screens.STUNTS
                );
                localStorage.setItem(
                  LocalStorage.ACTIVE_SCENE_ID,
                  record.sceneId
                );
                this.props.setActiveTab("1");
              }}
            >
              <TooltipIcon
                onClick={() => {
                  // this.props.history.push(`${MenusRoute.INVOICE_MORE}/${record.shootDate}`)
                }}
                icon="more"
              />
            </Button>
          </div>
        ),
      });
    }
    const { StuntData, fetchedData } = this.state;
    return (
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Stunt </TitleText>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row style={{ padding: 10 }} type="flex" justify="center">
            <Col xl={24}>
              <Table
                columns={columns}
                // className="box-shadow"
                dataSource={StuntData}
                // pagination={{ pageSize: 1000 }}
                // scroll={{ y: "50vh" }}
                pagination={false}
                loading={!fetchedData}
                locale={{
                  emptyText: fetchedData && <RecordNotFound />,
                }}
                bordered
              />
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
        {/* <Row
          type="flex"
          justify="end"
          style={{ paddingLeft: "450px", paddingTop: "50px" }}
        >
          <Col span={3}>
            <Button type="default">Cancel</Button>
          </Col>
          <Col span={3}>
            <Button type="primary">Save</Button>
          </Col>
        </Row> */}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.STUNT, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stunt);
