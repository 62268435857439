export const Images = {
  logo: require("./logo.png"),
  loading: require("./loading.png"),
  camera: require("./camera.jpg"),
  user: require("./user.png"),
  location: require("./location.png"),
  scene: require("./scene.png"),
  script: require("./script.png"),
  time: require("./time.png"),
  type: require("./type.png"),
  takepic: require("./takepic.png"),
};
