import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { isEmpty } from "lodash";
import "antd/dist/antd.css";
import {
  Table,
  Tabs,
  Select,
  Input,
  Row,
  Col,
  Button,
  Modal,
  Popover,
  Form,
  Checkbox,
  Icon,
  Tag,
  Upload,
  Popconfirm,
  Tooltip,
  Divider,
} from "antd";
import CreateScene from "./CreateScene";
import InsertScene from "./InsertScene";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { Link } from "react-router-dom";
import Axios from "axios";
import { connect } from "react-redux";
import { LocalStorage, GlobalConstants } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { Services, Notifications } from "constants/AppConstants";
import { setActiveTab, setActiveSceneId } from "store/action";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import "./Script.css";
import { ScriptService } from "services";
import FIlterForm from "components/shared/FIlterForm/FIlterForm";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, StartCase } from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import TooltipComponent from "components/shared/TooltipComponent";
import ReadMore from "components/shared/ReadMore";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { getScriptAPIURL, getScriptTemplateAPIURL } from "utilities/APIHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import EpisodeModal from "./EpisodeModal";
//import Search from "antd/lib/transfer/search";
// import Highlighter from "react-highlight-words";

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;

let dragingIndex = -1;
const plainOptions = ["Create", "Insert"];
const content1 = (
  <div>
    <p> Scene will be created at the last scene of the project </p>
  </div>
);
const content = (
  <div>
    <p>Insert the scene before the selected scene number</p>
  </div>
);

class BodyRow extends Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);
const { TabPane } = Tabs;

class Script extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._scriptService = new ScriptService();
    this._locationService = new LocationTabService();
    this.state = {
      fileList: [],
      excelError: [],
      errorVisible: false,
      insertVisible: false,
      isDataFetched: false,
      isSearchActive: false,
      searchText: null,
      index: 0,
      activeFilter: [],
      sceneNumberDataSource: [],
      sceneNumberDataSourceAll: [],
      LocationDataSource: [],
      descriptionDataSource: [],
      topToBottom: [],
      bottomToTop: [],
      data: [],
      getSceneDetails: [],
      showHeader: false,
      initialLoad: true,
      SceneNoSelectedKeys: [],
      LocationSelectedKeys: [],
      EnvironmentTime: "",
      EnvironmentType: "",
      Description: "",
      Episode: "",
      insertSceneNumber: null,
      insertSceneId: null,
      projectData: JSON.parse(localStorage.getItem(LocalStorage.PROJECT_DATA)),
      showEpisodeModal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.tableContainer = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.tableRef = this.tableRef.bind(this);
  }

  resetSearch = () => {
    this.setState({
      index: this.state.index + 1,
    });
  };

  componentWillUpdate() {
    this.props.setActiveTab("1");
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const that = this;
    confirm({
      title: "You are about to change scene order. Are you want to continue?",
      content: "When you click the OK button, The changes will be saved",
      onOk() {
        return new Promise((resolve, reject) => {
          that.changeScene(dragIndex, hoverIndex);
          resolve();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showInsertModal = (sceneNumber, sceneId) => {
    this.setState({
      insertVisible: true,
      insertSceneNumber: sceneNumber,
      insertSceneId: sceneId,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleInsertCancel = () => {
    this.setState({
      insertVisible: false,
      insertSceneNumber: null,
      insertSceneId: null,
    });
  };

  onChange = (checkedList) => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  changeScene = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];
    const hoverRow = data[hoverIndex];
    let p = new Promise((resolve) => {
      this.setState(
        update(this.state, {
          data: {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          },
        })
      );
      resolve();
    });
    p.then(() => {
      let temp = this.state.data.map((data, i) => {
        return {
          oldSceneOrder: data.sceneOrder,
          sceneId: data.sceneId,
          newSceneOrder:
            dragRow.sceneOrder === data.sceneOrder
              ? hoverRow.sceneOrder
              : hoverRow.sceneOrder === data.sceneOrder
              ? dragRow.sceneOrder
              : data.sceneOrder,
          sceneNumber: data.sceneNumber,
        };
      });
      if (dragIndex < hoverIndex) {
        let sliceCount = Math.abs(dragIndex - hoverIndex) + 1;
        let sliced = temp.splice(dragIndex, sliceCount);
        // console.log("this.state.data", Math.abs(dragIndex - hoverIndex) + 1);
        // console.log("this.state.data", sliced);
        this.setState(
          {
            topToBottom: sliced,
          },
          () => {
            this.handleTop();
          }
        );
      } else {
        let sliceCount = Math.abs(hoverIndex - dragIndex) + 1;
        let sliced = temp.splice(hoverIndex, sliceCount);
        // console.log("this.state.data", Math.abs(hoverIndex - dragIndex) + 1);
        // console.log("this.state.data", sliced);
        this.setState(
          {
            bottomToTop: sliced,
          },
          () => {
            this.handleBottom();
          }
        );
      }
    });
  };

  hasSearchKeys = (
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = null,
    EnvironmentType = null,
    Description = null
  ) =>
    !isEmpty(SceneNoSelectedKeys) ||
    !isEmpty(LocationSelectedKeys) ||
    !isEmpty(EnvironmentTime) ||
    !isEmpty(EnvironmentType) ||
    Description;

  componentDidMount() {
    setTimeout(() => {
      this.getScriptBreakdownData();
    }, 500);
    this.getEnvironmentTimeData();
    this.getEnvironmentTypeData();
    // const node = ReactDOM.findDOMNode(this.tableContainer);
    // node.scrollTop = node.scrollHeight;
    // node.addEventListener("scroll", this.handleScroll);
  }

  handleBottom() {
    let data = this.state.bottomToTop;
    this._scriptService
      .scriptService(Services.SceneOVerviewVariable.DRAG_DROP, null, data, "UP")
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getScriptBreakdownData();
        // this.getActorDetails();
        // this.getArtSetData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  }

  handleTop() {
    let data = this.state.topToBottom;
    this._scriptService
      .scriptService(
        Services.SceneOVerviewVariable.DRAG_DROP,
        null,
        data,
        "DOWN"
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Scene Changed Successfully!!"
        );
        this.getScriptBreakdownData();
        // this.getActorDetails();
        // this.getArtSetData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  }

  getScriptBreakdownData = () => {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    const {
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
    } = this.state;
    if (
      this.hasSearchKeys(
        SceneNoSelectedKeys,
        LocationSelectedKeys,
        EnvironmentTime,
        EnvironmentType,
        Description
      )
    ) {
      this.search(
        SceneNoSelectedKeys,
        LocationSelectedKeys,
        EnvironmentTime,
        EnvironmentType,
        Description
      );
    } else {
      Axios.get(
        `${getScriptAPIURL()}/project/${projectId}/scenes`,
        config
      ).then((res) => {
        this.setState(
          {
            data: Array.isArray(res.data.scenes) ? res.data.scenes : [],
            initialData:
              this.state.initialLoad && Array.isArray(res.data.scenes)
                ? res.data.scenes
                : [],
            getSceneDetails: Array.isArray(res.data.scenes)
              ? res.data.scenes
              : [],
            isDataFetched: true,
            initialLoad: false,
          },
          () => {
            this.getSceneNumberDataSource();
            this.getLocationDataSource();
            this.getDescriptionDataSource();
          }
        );
      });
    }
  };

  componentWillReceiveProps() {
    document.removeEventListener("keydown", this.escFunction, false);
    this.resetSearch();
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.resetSearch();
    }
  }

  state = {
    data: [],
  };

  setActiveScene = (sceneId) => {
    localStorage.setItem(LocalStorage.ACTIVE_SCENE_ID, sceneId);
  };

  handleUpdate = (a, r) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    const datas = {
      environmentType: a,
    };
    Axios.put(`${getScriptAPIURL()}/scenes/${r}/environment`, datas, config)
      .then((response) => {
        this.setState({ isLoading: false, visible: false }, () =>
          this.getScriptBreakdownData()
        );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        // this.handleClear();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        this.getScriptBreakdownData();
      });
  };

  handleUpdate1 = (a, r) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    const datas = {
      timeType: a,
    };
    Axios.put(
      `${getScriptAPIURL()}/scenes/${r}/environment`,
      datas,
      config
    ).then((response) => {
      this.setState({ isLoading: false, visible: false }, () =>
        this.getScriptBreakdownData()
      );
      showActionMessage(
        GlobalConstants.SUCCESS,
        GlobalConstants.CHANGES_HAVE_BEEN_SAVED
      );
    });
  };

  getEnvironmentTypeData = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ENVIRONMENT
      )
      .then((res) => {
        this.setState({ environment: res.data });
        res.data.map((option, i) => {
          template.push(<Option value={option.value}>{option.label}</Option>);
        });
      });
    return template;
  };
  getEnvironmentTimeData = () => {
    let time = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.TIME
      )
      .then((res) => {
        this.setState({ time: res.data });
        res.data.map((option, i) => {
          time.push(<Option value={option.value}>{option.label}</Option>);
        });
      });
    return time;
  };

  handleSearch = (selectedKeys, confirm, type = null) => {
    let filterList = this.state.activeFilter;
    filterList.push(type);
    confirm();
    this.setState({ searchText: selectedKeys[0], activeFilter: filterList });
  };

  handleReset = (clearFilters, type) => {
    let removeFilter = this.state.activeFilter;
    let index = removeFilter.indexOf(type);
    removeFilter.splice(index, 1);
    clearFilters();
    this.setState({ searchText: null, activeFilter: removeFilter });
  };

  getSceneNumberDataSource = () => {
    // let sceneNumberDatas = [];
    // this.state.initialData &&
    //   this.state.initialData.map(a => {
    //     sceneNumberDatas.push(a.sceneNumber);
    //   });

    this._scriptService
      .scriptService(Services.SceneOVerviewVariable.GET_SCENE_NUMBERS_DROPDOWN)
      .then((res) => {
        this.setState({
          sceneNumberDataSourceAll: res?.data?.sceneNumbers,
        });
        let sceneNumberDataSource = res.data.sceneNumbers;
        sceneNumberDataSource = sceneNumberDataSource.map(
          (scene) => scene.label
        );
        this.setState({
          sceneNumberDataSource,
          sceneNumberDataSourceAll: res.data.sceneNumbers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // this.setState({ sceneNumberDataSource: sceneNumberDatas });
  };

  getLocationDataSource() {
    // let locationDatas = [];
    // this.state.data &&
    //   this.state.data.map(a => {
    //     locationDatas.push(a.location);
    //   });
    // this.setState({ LocationDataSource: [...new Set(locationDatas)] });
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_LIST_BY_PROJECT
      )
      .then((res) => {
        this.setState({
          LocationDataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDescriptionDataSource() {
    let locationDatas = [];
    this.state.data &&
      this.state.data.map((a) => {
        locationDatas.push(a.description);
      });
    this.setState({ descriptionDataSource: [...new Set(locationDatas)] });
  }

  handleDelete = (key, id) => {
    this._scriptService
      .scriptService(
        Services.SceneOVerviewVariable.DELETE_SCENE,
        id,
        null,
        null
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.getScriptBreakdownData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  handleSubmit(
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = "",
    EnvironmentType = "",
    Description = "",
    Episode = ""
  ) {
    this.setState({
      isDataFetched: false,
      isSearchActive: true,
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      Episode,
    });
    this.search(
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      Episode
    );
  }

  convertSceneNumber = (data = []) => {
    let result = [];
    data.length &&
      data.map((a) => {
        const singleData = this.state.sceneNumberDataSourceAll?.find(
          (e) => e?.label === a
        );
        result.push(singleData?.label);
        return null;
      });
    return result || [];
  };

  search = (
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = "",
    EnvironmentType = "",
    Description = "",
    Episode = ""
  ) => {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    Axios.get(
      `${getScriptAPIURL()}/project/${projectId}/scenes?description=${Description}&environmentType=${EnvironmentType}&locations=${LocationSelectedKeys}&sceneNumbers=${this.convertSceneNumber(
        SceneNoSelectedKeys
      )}&timeType=${EnvironmentTime}&episode=${Episode}`,
      config
    ).then((res) => {
      this.setState({ data: res.data.scenes, isDataFetched: true }, () => {
        this.getSceneNumberDataSource();
        this.getLocationDataSource();
        this.getDescriptionDataSource();
      });
    });
  };

  handleClear = () => {
    this.setState({
      isDataFetched: false,
      isSearchActive: false,
      SceneNoSelectedKeys: [],
      LocationSelectedKeys: [],
      EnvironmentTime: "",
      EnvironmentType: "",
      Description: "",
    });
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    Axios.get(
      `${getScriptAPIURL()}/project/${projectId}/scenes?description=&environmentType=&locations=&sceneNumbers=&timeType=`,
      config
    ).then((res) => {
      this.setState({ data: res.data.scenes, isDataFetched: true }, () => {
        this.getSceneNumberDataSource();
        this.getLocationDataSource();
        this.getDescriptionDataSource();
      });
    });
    // this.getScriptBreakdownData();
    //e.preventDefault();
  };

  handleChange = (info) => {
    let fileList = [...info.fileList];
    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);
    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    if (fileList.length > 0) {
      var onMyForm = new FormData();
      onMyForm.append("file", fileList[0].originFileObj);
      showActionMessage(GlobalConstants.SHOW, "Script Upload is in Process");
      this._scriptService
        .scriptService(
          Services.ScriptVariables.UPLOAD,
          this.props.match.params.id,
          onMyForm
        )
        .then(
          (res) => {
            this.setState({ fileList: null });
            showActionMessage(GlobalConstants.HIDE);
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Script uploaded Successfully!!"
            );
            this.getScriptBreakdownData();
          },
          (err) => {
            this.setState(
              {
                fileList: null,
                excelError: err.response.data.errors,
                errorVisible: true,
              },
              () => {
                console.log("error.....");
                showActionMessage(GlobalConstants.HIDE);
              }
            );
          }
        );
    }
  };

  handleScroll() {
    const node = ReactDOM.findDOMNode(this.tableContainer);
    // if (node.scrollTop >= 53 && !this.state.showHeader) {
    //   this.setState({
    //     showHeader: true
    //   });
    // } else if (node.scrollTop < 53 &&this.state.showHeader) {
    //   this.setState({
    //     showHeader: false
    //   });
    // }
  }

  tableRef(target) {
    this.tableContainer = target;
  }

  render() {
    const { isDataFetched, showHeader, getSceneDetails } = this.state;
    let missingColumns = {
      sceneNumber: [],
      location: [],
      environmentType: [],
      timeType: [],
      description: [],
    };
    const errorColumn = [
      {
        title: "Scene Number",
        dataIndex: "sceneNumber",
      },
      {
        title: "Invalid Column",
        dataIndex: "invalidColumn",
      },
    ];

    const columns = [
      {
        title: "SCENE",
        // title: () => (
        //   <div>
        //     <span>SCENE</span>
        //     {getSceneDetails.map((scene, row) => {
        //       if (scene.sceneNumber === "" || scene.sceneNumber === null) {
        //         missingColumns.sceneNumber = [
        //           ...missingColumns.sceneNumber,
        //           row + 1,
        //         ];
        //       }
        //     })}
        //     {missingColumns.sceneNumber.length !== 0 && (
        //       <TooltipIcon
        //         titleText={
        //           <span>
        //             Please add Scene Number for the mentioned{" "}
        //             {missingColumns.sceneNumber.length !== 1 ? "rows" : "row"}:{" "}
        //             {missingColumns.sceneNumber.join(", ")}.
        //           </span>
        //         }
        //         style={{
        //           color: "black",
        //         }}
        //         icon={"warning"}
        //         placement={GlobalConstants.TOP}
        //       />
        //     )}
        //   </div>
        // ),
        dataIndex: "sceneNumber",
        className: "text-center",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <>
            <div
              style={{
                width: 12,
                backgroundColor: record?.colorCode
                  ? record?.colorCode
                  : "white",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
              }}
            ></div>
            <Tag
              className={`${record.environmentType}${record.timeType} sceneno-tag`}
            >
              <Link
                style={{
                  color: "white",
                }}
                to={`/project/screen-overview/${record.sceneLocationId}`}
                onClick={() => {
                  this.setActiveScene(record.sceneId);
                }}
              >
                {record.sceneNumber}
              </Link>
            </Tag>
          </>
        ),
      },
      {
        title: () => (
          <div>
            <span>LOCATION</span>
            {getSceneDetails.map((scene, row) => {
              if (scene.location === "" || scene.location === null) {
                missingColumns.location = [
                  ...missingColumns.location,
                  scene.sceneNumber,
                ];
              }
            })}
            {missingColumns.location.length !== 0 && (
              <>
                <TooltipIcon
                  titleText={
                    <span>
                      Please add Scene Location for the mentioned{" "}
                      {missingColumns.location.length !== 1
                        ? "scenes"
                        : "scene"}
                      : {missingColumns.location.join(", ")}.
                    </span>
                  }
                  style={{
                    color: "#ffb100",
                  }}
                  icon={"warning"}
                  placement={GlobalConstants.TOP}
                />
              </>
            )}
          </div>
        ),
        dataIndex: "location",
        width: "25%",
        align: "left",
        render: (text, record) => (
          <Link
            to={`/project/location-audition/${record.location}/${record.sceneLocationId}`}
          >
            {record.location}
          </Link>
        ),
      },
      {
        title: () => (
          <div>
            <span>INT / EXT</span>
            {getSceneDetails.map((scene, row) => {
              if (
                scene.environmentType === "" ||
                scene.environmentType === null
              ) {
                missingColumns.environmentType = [
                  ...missingColumns.environmentType,
                  scene.sceneNumber,
                ];
              }
            })}
            {missingColumns.environmentType.length !== 0 && (
              <TooltipIcon
                titleText={
                  <span>
                    Please choose the Environment Type for the mentioned{" "}
                    {missingColumns.environmentType.length !== 1
                      ? "scenes"
                      : "scene"}
                    : {missingColumns.environmentType.join(", ")}.
                  </span>
                }
                style={{
                  color: "#ffb100",
                }}
                icon={"warning"}
                placement={GlobalConstants.TOP}
              />
            )}{" "}
          </div>
        ),
        dataIndex: "environmentType",
        width: "12%",
        align: "left",
        render: (text, record) => (
          <Select
            defaultValue={record.environmentType}
            disabled={
              !checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )
            }
            style={{ width: "90%" }}
            onChange={(a) => {
              this.handleUpdate(a, record.sceneId);
            }}
            value={record.environmentType}
          >
            {this.state.environment &&
              this.state.environment.map((a, i) => (
                <Option value={a.value}>{a.label}</Option>
              ))}
          </Select>
        ),
      },

      {
        title: () => (
          <div>
            <span>DAY / NIGHT</span>
            {getSceneDetails.map((scene, row) => {
              if (scene.timeType === "" || scene.timeType === null) {
                missingColumns.timeType = [
                  ...missingColumns.timeType,
                  scene.sceneNumber,
                ];
              }
            })}
            {missingColumns.timeType.length !== 0 && (
              <TooltipIcon
                titleText={
                  <span>
                    Please choose the Time for the mentioned{" "}
                    {missingColumns.timeType.length !== 1 ? "scenes" : "scene"}:{" "}
                    {missingColumns.timeType.join(", ")}.
                  </span>
                }
                style={{
                  color: "#ffb100",
                }}
                icon={"warning"}
                placement={GlobalConstants.TOP}
              />
            )}
          </div>
        ),
        dataIndex: "timeType",
        align: "left",
        width: "12%",
        render: (text, record) => (
          <Select
            defaultValue={record.timeType}
            disabled={
              !checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )
            }
            style={{ width: "90%" }}
            onChange={(a) => {
              this.handleUpdate1(a, record.sceneId);
            }}
            value={record.timeType}
          >
            {this.state.time &&
              this.state.time.map((a, i) => (
                <Option value={a.value}>{a.label}</Option>
              ))}
          </Select>
        ),
      },
      {
        title: () => (
          <div>
            <span>DESCRIPTION</span>
            {getSceneDetails.map((scene, row) => {
              if (scene.description === "" || scene.description === null) {
                missingColumns.description = [
                  ...missingColumns.description,
                  scene.sceneNumber,
                ];
              }
            })}
            {missingColumns.description.length !== 0 && (
              <TooltipIcon
                titleText={
                  <span>
                    Please add the Scene Description for the mentioned{" "}
                    {missingColumns.description.length !== 1
                      ? "scenes"
                      : "scene"}
                    : {missingColumns.description.join(", ")}.
                  </span>
                }
                style={{
                  color: "#ffb100",
                }}
                icon={"warning"}
                placement={GlobalConstants.TOP}
              />
            )}
          </div>
        ),
        dataIndex: "description",
        width: "35%",
        align: "left",
        render: (text) =>
          text && <TooltipComponent text={StartCase(text)} placement="left" />,
      },
    ];
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      // multiple: true
    };

    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_DELETE)
    ) {
      columns.push({
        title: "Action",
        //key: "action",
        align: "center",
        width: "5%",
        render: (text, record) => {
          return (
            <div className="tdContainer">
              <div
                className="childDiv"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 5,
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {/* <span> */}
                {/* <a className="primary-font-color" href="javascript:;"> */}
                {/* <Row type="flex" justify="space-around"> */}
                {/* <React.Fragment> */}
                {checkFor(
                  this.props.activePermisision,
                  GlobalConstants.PERMISSION_DELETE
                ) && (
                  <>
                    {/* <Link to=""> */}
                    <Tooltip
                      title={"Insert Scene"}
                      placement={GlobalConstants.LEFT}
                    >
                      <Button
                        style={{
                          height: "18px",
                          width: "18px",
                          fontSize: "14px",
                          marginTop: 0,
                        }}
                        icon="plus"
                        type="link"
                        // disabled={this.state.insertVisible}
                        // loading={this.state.insertVisible}
                        onClick={() =>
                          this.showInsertModal(
                            record.sceneNumber,
                            record.sceneId
                          )
                        }
                      />
                    </Tooltip>
                    {/* </Link> */}
                    {/* <Divider
                              type="vertical"
                              style={{
                                marginTop: "5px",
                                marginLeft: "4px",
                                marginRight: "4px",
                              }}
                            /> */}
                  </>
                )}

                <Popconfirm
                  title="Sure to Delete?"
                  onConfirm={() =>
                    this.handleDelete(record.key, record.sceneId)
                  }
                >
                  <Tooltip
                    title={GlobalConstants.DEL}
                    placement={GlobalConstants.RIGHT}
                  >
                    <Button
                      style={{
                        height: "18px",
                        width: "18px",
                        fontSize: "14px",
                        marginTop: 0,
                      }}
                      icon="delete"
                      type="link"
                    />
                  </Tooltip>
                </Popconfirm>
                {/* </React.Fragment> */}
                {/* </Row> */}
                {/* </a> */}
                {/* </span> */}
                {/*  <span>{"text"}</span> */}
              </div>
            </div>
          );
        },
      });
    }
    const {
      activePermisision,
      // currentUser: { role },
    } = this.props;

    return (
      <Fragment>
        <Modal
          className="errorModal"
          visible={this.state.errorVisible}
          onOk={() => this.setState({ errorVisible: false })}
          onCancel={() =>
            this.setState({
              errorVisible: false,
            })
          }
          footer={
            <Button
              type="primary"
              onClick={() => this.setState({ errorVisible: false })}
            >
              Ok
            </Button>
          }
        >
          <Table
            columns={errorColumn}
            dataSource={this.state.excelError}
            pagination={false}
          />
        </Modal>

        <Row>
          <Col xl={24}>
            <Title>
              <TitleText>Script Breakdown</TitleText>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) && (
                <Row type="flex">
                  {this.state.isDataFetched &&
                    this.state.getSceneDetails.length < 1 && (
                      <>
                        <Upload {...props} fileList={this.state.fileList}>
                          <Button type="primary">
                            <Icon type="upload" style={{ fontSize: "16px" }} />
                            Script
                          </Button>
                        </Upload>
                        <a
                          href={
                            this.state.projectData?.projectTypeId === 5
                              ? `${getScriptTemplateAPIURL()}/script_template/ACE_WEB_SERIES_SCRIPT_BREAK_DOWN_TEMPLATE.xlsx`
                              : `${getScriptTemplateAPIURL()}/script_template/ACE_SCRIPT_BREAK_DOWN_TEMPLATE.xlsx`
                          }
                          style={{ marginLeft: 5 }}
                        >
                          <Button type="primary">
                            <Icon
                              type="download"
                              style={{ fontSize: "16px" }}
                            />
                            Template
                          </Button>
                        </a>
                      </>
                    )}
                  {this.state.projectData?.projectTypeId === 5 && (
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          showEpisodeModal: true,
                        });
                      }}
                      style={{ marginLeft: 5 }}
                    >
                      Episode
                    </Button>
                  )}
                </Row>
              )}
            </Title>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
              <Fragment>
                <Row type="flex" align="middle" justify="center">
                  <Col xl={23} sm={22}>
                    <Row
                      type="flex"
                      align="middle"
                      justify="center"
                      className="fieldset-search"
                      style={{
                        marginBottom: 0,
                        marginTop: 10,
                        marginLeft: -30,
                        marginRight: -30,
                      }}
                    >
                      <Col xl={24} sm={22}>
                        <FIlterForm
                          sceneNumberDataSource={
                            this.state.sceneNumberDataSource
                          }
                          LocationDataSource={this.state.LocationDataSource}
                          environment={this.state.environment}
                          time={this.state.time}
                          handleSubmit={this.handleSubmit}
                          handleClear={this.handleClear}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal
                  destroyOnClose={true}
                  visible={this.state.visible}
                  onCancel={this.handleCancel}
                  footer={false}
                  title="New Scene"
                  className="createModal"
                >
                  <Tabs defaultActiveKey="1">
                    <TabPane
                      tab={
                        <span>
                          Create
                          <Popover content={content1}>
                            <Icon
                              style={{ paddingLeft: "10px" }}
                              type="info-circle"
                            />
                          </Popover>
                        </span>
                      }
                      key="1"
                    >
                      <CreateScene
                        pageType="createScene"
                        refreshData={this.getScriptBreakdownData}
                        closeModal={this.handleCancel}
                      />
                    </TabPane>
                    <TabPane
                      disabled={this.state.isTabDisable}
                      tab={
                        <span>
                          Insert
                          <Popover content={content}>
                            <Icon
                              style={{ paddingLeft: "10px" }}
                              type="info-circle"
                            />
                          </Popover>
                        </span>
                      }
                      key="2"
                    >
                      <InsertScene
                        pageType="insertScene"
                        refreshData={this.getScriptBreakdownData}
                        closeModal={this.handleCancel}
                      />
                    </TabPane>
                  </Tabs>
                </Modal>
                <Row style={{ padding: 10, backgroundColor: "#FDFDFD" }}>
                  <Col xl={24}>
                    <DndProvider backend={HTML5Backend}>
                      <Table
                        // scroll={{ y: "50vh" }}
                        // className="script-table"
                        bordered
                        key={this.state.index}
                        pagination={false}
                        columns={columns}
                        dataSource={this.state.data}
                        components={
                          this.state.isSearchActive
                            ? {
                                body: {},
                              }
                            : { body: { row: DragableBodyRow } }
                        }
                        onRow={(record, index) => ({
                          index,
                          moveRow: this.moveRow,
                        })}
                        loading={!isDataFetched}
                      />
                    </DndProvider>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <ContactAdminErrorTemplate />
            )}
          </Col>
        </Row>
        <Modal
          destroyOnClose={true}
          visible={this.state.insertVisible}
          onCancel={this.handleInsertCancel}
          footer={false}
          title="Insert Scene"
          className="insertModal"
          overlayClassName="insertModalOverlay"
          type="individual"
        >
          <InsertScene
            pageType="insertScene"
            insertSceneNumber={this.state.insertSceneNumber}
            insertSceneId={this.state.insertSceneId}
            refreshData={this.getScriptBreakdownData}
            closeModal={this.handleInsertCancel}
          />
        </Modal>
        <Modal
          destroyOnClose={true}
          visible={this.state.showEpisodeModal}
          onCancel={() => {
            this.setState({
              ...this.state,
              showEpisodeModal: false,
            });
          }}
          footer={false}
          title="Episode"
          className="episodeModal"
          overlayClassName="episodeModalOverlay"
          type="individual"
        >
          <EpisodeModal />
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ scene, user: { menus, currentUser } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Script Breakdown", menus),
    currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    setActiveSceneId: (activeSceneId) =>
      dispatch(setActiveSceneId(activeSceneId)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Script);
