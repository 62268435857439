// export const Icons = {
//   DASHBOARD: "dashboard",
//   SCRIPT_BREAKDOWN: "scriptBreakdown",
//   CHAR_ACTOR: "char-actor",
//   OVERVIEW:'dashboard',
//   CAST: "desktop",
//   CREW: "file",
//   CGI: "CGI",
//   EQUIPMENTS: "setting",
//   PROJECTS: "PROJECTS",
//   APPROVALS: "heart",
//   HERO_AUDITION: "desktop",
//   HEROINE_AUDITION: "dashboard",
//   COLLECTION: "book",
//   ACTORS: "ACTORS",
//   TRASH: "trash",
//   VIDEO_CAMERA:'videoCamera',
//   LOCATION:'location',
//   SCRIPTS:'book',
//   ART_SET: "Art/Set",
//   COSTUMES: "COSTUMES",
//   COSTUME : "Costume",
//   MAKE_UP : "Makeup",
//   VFX : "Vfx",
//   SONG : "Song",
//   STUNT : "Stunt",
//   SCENE_OVERVIEW : "Scence_overview",
//   EQUIPMENT : "Equipment",
//   SCHEDULAR : "Schedular",
//   BUDGETER : "Budgeter",
//   // CREW : "Equipment",
// };

export const Icons = {
  DASHBOARD: "Dashboard",
  SCRIPTS_BREAKDOWN: "Script Breakdown",
  ACTORS: "Char-Actor",
  CREW: "Crew",
  LOCATION: "Location",
  EQUIPMENTS: "Equipment",
  COSTUMES: "Costume",
  MAKEUP: "Makeup",
  ART_SET: "Art/Set",
  CGI: "CGI",
  VFX: "VFX",
  SONG: "Song",
  STUNT: "Stunt",
  APPROVALS: "Approval",
  HERO_AUDITION: "Hero Audition",
  HEROINE_AUDITION: "Heroine Audition",
  COLLECTION: "Collections",
  SCENE_OVERVIEW: "Scene Overview",
  // ART_SET: "ART/SET",
  PROJECTS: "Projects",
  SCHEDULAR: "Scheduler",
  BUDJETER: "Budgeter",
  ACCOUNTING: "Accounting",
  TRANSPORT: "Transport",
  INVOICE: "Invoice",
  REPORT: "Report",
};
