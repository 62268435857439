import React, { useState, Fragment, Component } from "react";
import Production from ".";
import { Tabs, Row, Col, Table, Button, Icon, Tag } from "antd";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import { GlobalConstants, Services } from "constants/AppConstants";
import { connect } from "react-redux";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, ReplaceWithSpace } from "utilities/ValidationHelper";
import BudjetService from "services/BudjetService";
import { Link } from "react-router-dom";
import TooltipComponent from "components/shared/TooltipComponent";

const { TabPane } = Tabs;

class Artset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this._budgetService = new BudjetService();
  }

  componentDidMount() {
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE_MORE,
        "ART_SET"
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionPurchases,
          isLoading: true,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: true,
        })
      );
  }
  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: "#",
        keyIndex: "name",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Details",
        dataIndex: "description",
        key: "description",
        render: (text) => ReplaceWithSpace(text),
      },
      {
        title: "Cost",
        dataIndex: "budgetEstimation",
        align: "right",
        key: "budgetEstimation",
      },
      {
        title: "Scene Number",
        dataIndex: "scenes",
        key: "scenes",
        render: (text, record) =>
          text &&
          text.map((record) => (
            <Tag
              className={`${record.environmentType}${record.timeType} sceneno-tag`}
            >
              <Link
                style={{
                  color: "white",
                }}
              >
                <TooltipComponent
                  sceneNumber={record.sceneNumber}
                  text={record.sceneDescription}
                  placement="top"
                />
              </Link>
            </Tag>
          )),

        // <span>{text}</span>
      },
    ];

    return (
      <Row>
        <Col xl={24}>
          {/* <Col xl={24}>
            <Button type="primary" style={{ float: "right", marginBottom: 10 }}>
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          </Col> */}
          <Col>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Col>
      </Row>
    );
  }
}

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this._budgetService = new BudjetService();
  }
  componentDidMount() {
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE_MORE,
        GlobalConstants.LOCATION_CAPS
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionPurchases,
          isLoading: true,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: true,
        })
      );
  }
  render() {
    const { dataSource } = this.state;

    const columns = [
      {
        title: "#",
        keyIndex: "name",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Scene Location",
        dataIndex: "sceneLocations",
        key: "sceneLocations",
        render: (text) =>
          text && (
            <div className="commaContainer" style={{ width: 200 }}>
              {text &&
                text.map((a) => {
                  return <span>{a.sceneLocation}</span>;
                })}
            </div>
          ),
      },
      {
        title: "Actual Location",
        dataIndex: "actualLocation",
        key: "actualLocation",
      },
      {
        title: "Caution Deposit",
        dataIndex: "budgetEstimation",
        key: "budgetEstimation",
        align: "right",
      },
      {
        title: "Scene Number",
        dataIndex: "scenes",
        key: "scenes",
        render: (text, record) =>
          text &&
          text.map((record) => (
            <Tag
              className={`${record.environmentType}${record.timeType} sceneno-tag`}
            >
              <Link
                style={{
                  color: "white",
                }}
              >
                <TooltipComponent
                  sceneNumber={record.sceneNumber}
                  text={record.sceneDescription}
                  placement="top"
                />
              </Link>
            </Tag>
          )),

        // <span>{text}</span>
      },
    ];

    return (
      <Row>
        <Col xl={24}>
          {/* <Col xl={24}>
            <Button type="primary" style={{ float: "right", marginBottom: 10 }}>
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          </Col> */}
          <Col>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Col>
      </Row>
    );
  }
}

class Costume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this._budgetService = new BudjetService();
  }
  componentDidMount() {
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE_MORE,
        GlobalConstants.COSTUME
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionPurchases,
          isLoading: true,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: true,
        })
      );
  }
  render() {
    const { dataSource } = this.state;

    const columns = [
      {
        title: "#",
        keyIndex: "name",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Detail",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Actor/Character",
        dataIndex: "characterName",
        key: "characterName",
      },
      {
        title: "Cost",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
      },
      {
        title: "Scene Number",
        dataIndex: "scenes",
        key: "scenes",
        render: (text, record) =>
          text &&
          text.map((record) => (
            <Tag
              className={`${record.environmentType}${record.timeType} sceneno-tag`}
            >
              <Link
                style={{
                  color: "white",
                }}
              >
                <TooltipComponent
                  sceneNumber={record.sceneNumber}
                  text={record.sceneDescription}
                  placement="top"
                />
              </Link>
            </Tag>
          )),

        // <span>{text}</span>
      },
    ];

    return (
      <Row>
        <Col xl={24}>
          {/* <Col xl={24}>
            <Button type="primary" style={{ float: "right", marginBottom: 10 }}>
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          </Col> */}
          <Col>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Col>
      </Row>
    );
  }
}

class Makeup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this._budgetService = new BudjetService();
  }
  componentDidMount() {
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE_MORE,
        GlobalConstants.MAKEUP
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionPurchases,
          isLoading: true,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: true,
        })
      );
  }
  render() {
    const { dataSource } = this.state;

    const columns = [
      {
        title: "#",
        keyIndex: "name",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Detail",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Actor/Character",
        dataIndex: "characterName",
        key: "characterName",
      },
      {
        title: "Cost",
        dataIndex: "budgetEstimation",
        key: "budgetEstimation",
        align: "right",
      },
      {
        title: "Scene Number",
        dataIndex: "scenes",
        key: "scenes",
        render: (text, record) =>
          text &&
          text.map((record) => (
            <Tag
              className={`${record.environmentType}${record.timeType} sceneno-tag`}
            >
              {console.log(record)}
              <Link
                style={{
                  color: "white",
                }}
              >
                <TooltipComponent
                  sceneNumber={record.sceneNumber}
                  text={record.sceneDescription}
                  placement="top"
                />
              </Link>
            </Tag>
          )),

        // <span>{text}</span>
      },
    ];
    return (
      <Row>
        <Col xl={24}>
          {/* <Col xl={24}>
            <Button type="primary" style={{ float: "right", marginBottom: 10 }}>
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          </Col> */}
          <Col>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Col>
      </Row>
    );
  }
}

const config = {
  ART_SET: Artset,
  COSTUME: Costume,
  MAKEUP: Makeup,
  LOCATION: Location,
};

class PurchaseMore extends Component {
  //   const { setCostumeLeftActiveTab, costumeLeftActiveTab } = props;
  constructor(props) {
    super(props);
    this.state = {
      ActiveTab: "1",
      Type: props.match.params.type,
    };
  }

  renderTable = () => {
    const { Type } = this.state;
    let type = Type.includes("Art") ? "Artset" : Type;
    let Component = config[type];
    return <Component />;
  };

  render() {
    const { Type } = this.state;
    return (
      <Fragment>
        <Row>
          <Col xl={24}>
            <Title hasBack {...this.props}>
              <TitleText>Purchase More - {ReplaceWithSpace(Type)}</TitleText>
            </Title>
          </Col>
        </Row>
        {checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_VIEW
        ) ? (
          <Row style={{ padding: 10 }}>
            <Col>{Type && this.renderTable()}</Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

export default connect(mapStateToProps, null)(PurchaseMore);
