import React from "react";
import LottieComponent from "../LottieComponent";
import { Lotties } from "constants/AppConstants";
import { Value } from "../Label";

const RecordNotFound = ({ text = "No record Found", showImage = true }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {showImage && (
        <LottieComponent type={Lotties.RECORD_NOT_FOUND} width={250} />
      )}
      <Value>{text}</Value>
    </div>
  );
};

export default RecordNotFound;
