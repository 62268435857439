import React, { Component } from "react";
import { Modal, Row, Col, Select, Form, Input, Button, Radio } from "antd";
import { Link } from "react-router-dom";
import { Images } from "assets/images";
import Uploaddocumentfile from "components/uploaddocumentfile";
import UploadImage from "components/shared/UploadImage/UploadImage";
import Paymentterms from "components/paymentterms";
import Dynamictable from "components/dynamictable";
import { Services, Notifications, LocalStorage } from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import { ScriptService, OnboardServices } from "services";
import GlobalService from "services/GlobalService";
import ActorsServices from "services/ActorsServices";

const { Option } = Select;

class AddActorModal extends Component {
  state = {
    projectimageid: null,
    formvalues: null,
    paymentData: null,
    staffData: null,
    fileuploadid: null,
    isButtonDisabled: true,
    actorName: null,
    characterName: null,
    characterType: null,
    rumeneration: null,
    pan: null,
    gst: null,
    addressLine: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
    vanityVan: null,
    pickup: null,
    conveyance: null,
    status: null,
    // isLoading: false,
    gettingCharacterType: null,
    gettingVanityVan: null,
    gettingStatus: null,
    gettingcountry: null,
  };

  constructor(props) {
    super(props);
    this.scriptservice = new ScriptService();
    this._globalService = new GlobalService();
    this.onboardservices = new OnboardServices();
    this._actorService = new ActorsServices();
  }

  componentDidMount() {
    this.getCharacterType();
    this.getCountries();
    this.getStatus();
    this.getVanityVan();
  }

  getVanityVan = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "VANITY_VAN")
      .then((res) => {
        this.setState({
          vanityVan: res.data,
        });
      });
  };

  getStatus = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({
          status: res.data,
        });
      });
  };

  getCharacterType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "CHARACTER_TYPE")
      .then((res) => {
        this.setState({
          characterType: res.data,
        });
      });
  };

  getCountries = () => {
    let country = [];
    this.onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          country: res.data.countries,
        });
      });
  };

  handleImage = (url) => {
    this.setState({
      projectimageid: url,
    });
  };

  handleDocument = (id) => {
    this.setState({
      fileuploadid: id,
    });
  };

  handlepayment = (data) => {
    this.setState({
      paymentData: data,
    });
  };

  handlestaff = (details) => {
    this.setState({
      staffData: details,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formvalues: values,
          isButtonDisabled: false,
          isLoading: true,
        });

        const data = {
          actorDocument: [
            {
              fileData: this.state.fileuploadid,
              fileType: "DOCUMENT",
            },
            {
              fileData: this.state.projectimageid,
              fileType: "IMAGE",
            },
          ],

          actorName: values.actorname,
          actorPayment: this.state.paymentData,
          actorStaff: this.state.staffData,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: values.zipcode,
          },
          characterName: values.character,
          characterType: values.charactertype,
          pan: values.pan,
          projectActor: {
            conveyance: values.conveyance,
            gst: values.gst,
            isPickUP: values.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumeneration: values.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        this.scriptservice
          .scriptService(Services.AddActor.ADD_ACTOR, data)
          .then((response) => {
            // this.setState({ isLoading: false, visible: false });
            showNotifications(Notifications.SUCCESS, "Created Sucessfully!");
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isButtonDisabled,
      actorName,
      characterName,
      characterType,
      city,
      state,
      status,
      rumeneration,
      pan,
      gst,
      addressLine,
      zipCode,
      country,
      conveyance,
      pickup,
      vanityVan,
      paymentData,
      staffData,
      isLoading,
      gettingCharacterType,
      gettingVanityVan,
      gettingStatus,
      gettingcountry,
    } = this.state;

    return (
      <Modal
        visible={this.props.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.props.closeModal}
        width="1000px"
      >
        <Row>
          <Col
            xl={{ span: 18, offset: 3 }}
            lg={{ span: 18, offset: 3 }}
            md={{ span: 21, offset: 3 }}
          >
            <Row>
              <Col
                xl={{ span: 10, offset: 10 }}
                lg={{ span: 10, offset: 10 }}
                md={{ span: 10, offset: 10 }}
              >
                <div
                  style={{
                    color: "#2da01c",
                    marginBottom: "10px",
                    fontSize: "20px",
                  }}
                >
                  Actor Profile
                </div>
              </Col>
            </Row>

            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Actor Name</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("actorname", {
                          initialValue: actorName,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Actorname!",
                            },
                            {
                              pattern: new RegExp("^[A-Za-z]*$"),
                              message: "Enter Alphabets onlys",
                            },
                          ],
                        })(<Input type="text" placeholder="ActorName" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Character</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("character", {
                          initialValue: characterName,
                          rules: [
                            {
                              required: true,
                              message: "Please enter character!",
                            },
                            {
                              pattern: new RegExp("^[A-Za-z]*$"),
                              message: "Enter Alphabets onlys",
                            },
                          ],
                        })(<Input type="text" placeholder="Character" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 10 }}>
                  <Row style={{ position: "absolute", right: "50%" }}>
                    <Form.Item>
                      {getFieldDecorator("projectimage", {
                        initialValue: this.state.projectimage,
                      })(
                        <img
                          src={Images.user}
                          style={{ height: "100px", width: "100px" }}
                        />
                      )}
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Character Type</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("charactertype", {
                          initialValue: gettingCharacterType || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter charactertype!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="CharacterType"
                            style={{ width: "100%" }}
                          >
                            {characterType &&
                              characterType.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Remuneration</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("rumeneration", {
                          initialValue: rumeneration,
                          rules: [
                            {
                              required: true,
                              message: "Please enter rumeneration!",
                            },
                          ],
                        })(<Input type="number" placeholder="Rumeneration" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 6 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Payment Terms</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 17 }} lg={{ span: 19 }} md={{ span: 18 }}>
                      <Form.Item>
                        {getFieldDecorator("paymentdata", {
                          initialValue: paymentData,
                        })(
                          <Paymentterms
                            paymentData={this.state.paymentData}
                            handlepayment={this.handlepayment}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Pan No</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("pan", {
                          initialValue: pan,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Pan No!",
                            },
                          ],
                        })(<Input type="text" placeholder="Pan No" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Gst No</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("gst", {
                          initialValue: gst,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Gst No!",
                            },
                          ],
                        })(<Input type="text" placeholder="Gst No" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                          marginBottom: "30px",
                        }}
                      >
                        <label>Address</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("Address", {
                          initialValue: addressLine,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Address!",
                            },
                          ],
                        })(<Input type="text" placeholder="Address" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                          marginBottom: "30px",
                        }}
                      >
                        <label>City</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("city", {
                          initialValue: city,
                          rules: [
                            {
                              required: true,
                              message: "Please enter City!",
                            },
                            {
                              pattern: new RegExp("^[A-Za-z]*$"),
                              message: "Enter Alphabets onlys",
                            },
                          ],
                        })(<Input type="text" placeholder="City" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                          marginBottom: "30px",
                        }}
                      >
                        <label>State</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("state", {
                          initialValue: state,
                          rules: [
                            {
                              required: true,
                              message: "Please enter State!",
                            },
                            {
                              pattern: new RegExp("^[A-Za-z]*$"),
                              message: "Enter Alphabets onlys",
                            },
                          ],
                        })(<Input type="text" placeholder="State" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Zip code</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("zipcode", {
                          initialValue: zipCode,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Zipcode!",
                            },
                          ],
                        })(<Input type="number" placeholder="Zipcode" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Country</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("country", {
                          initialValue: gettingcountry || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter country!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Country"
                            style={{ width: "100%" }}
                          >
                            {country &&
                              country.map((option, i) => (
                                <Option value={option.name}>
                                  {option.name}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 4 }} lg={{ span: 5 }} md={{ span: 6 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Staff Details</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 17 }} lg={{ span: 19 }} md={{ span: 18 }}>
                      <Form.Item>
                        {getFieldDecorator("staffdata", {
                          initialValue: staffData,
                        })(
                          <Dynamictable
                            staffData={this.state.staffData}
                            handlestaff={this.handlestaff}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Vanity Van</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("vanityvan", {
                          initialValue: gettingVanityVan || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Vanityvan!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Vanityvan"
                            style={{ width: "100%" }}
                          >
                            {vanityVan &&
                              vanityVan.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                          marginBottom: "30px",
                        }}
                      >
                        <label>Pickup</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("pickup", {
                          initialValue: pickup,
                        })(
                          <Radio.Group name="radiogroup">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                          marginBottom: "30px",
                        }}
                      >
                        <label>Conveyance</label>
                      </div>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("conveyance", {
                          initialValue: conveyance,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Conveyance!",
                            },
                          ],
                        })(<Input type="number" placeholder="Conveyance" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Contract</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("fileuploadid", {
                          initialValue: this.state.fileuploadid,
                        })(
                          <Uploaddocumentfile
                            handleDocument={this.handleDocument}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl={{ span: 24 }} lg={{ span: 12 }} md={{ span: 14 }}>
                  <Row gutter={16}>
                    <Col xl={{ span: 4 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Photos</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 18 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("projectimageid", {
                          initialValue: this.state.projectimageid,
                        })(<UploadImage handleImage={this.handleImage} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 14 }}
                  style={{ marginBottom: "30px" }}
                >
                  <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 10 }} md={{ span: 11 }}>
                      <div
                        style={{
                          fontWeight: "600",
                          paddingTop: "8px",
                          textAlign: "right",
                        }}
                      >
                        <label>Status</label>
                      </div>
                    </Col>

                    <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                      <Form.Item>
                        {getFieldDecorator("status", {
                          initialValue: gettingStatus,
                          rules: [
                            {
                              required: true,
                              message: "Please enter status!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Status"
                            style={{ width: "100%" }}
                          >
                            {status &&
                              status.map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                gutter={12}
                type="flex"
                justify="end"
                style={{ marginTop: "20px" }}
              >
                <Col>
                  <Link to="/project/actor">
                    <Button>Cancel</Button>
                  </Link>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={isLoading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const AddActor = Form.create()(AddActorModal);
export default AddActor;
