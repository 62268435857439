import React, { Component, Fragment } from "react";
import { Drawer, Icon, Badge, Collapse, List, Avatar } from "antd";
import { connect } from "react-redux";

import { toggleNotificationBar } from "store/action";

import "./sidebar.scss";
import IconComponent from "components/shared/IconComponent";
import { Icons } from "constants/AppConstants";
import NotificationContentComponent from "./NotificationContentComponent";

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const customPanelStyle = {
  // background: "rgba(255, 0, 234, 0.02)",
  borderRadius: 4,
  // marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

class SideBarNotification extends Component {
  state = { visible: false };

  componentDidMount() {}

  showDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const {
      isNotificationBarVisible,
      toggleNotificationBar,
      notificationCount,
    } = this.props;
    return isNotificationBarVisible ? (
      <Drawer
        title={
          <Fragment>
            <Badge count={1} dot>
              <Icon type="notification" style={{ fontSize: "20px" }} />
            </Badge>
            <span>
              {`${
                notificationCount
                  ? `Pending Items (${notificationCount})`
                  : "No Pending Items"
              } `}
            </span>
          </Fragment>
        }
        placement="right"
        className="createModal"
        closable={true}
        onClose={toggleNotificationBar}
        visible={true}
        width={"30%"}
      >
        {isNotificationBarVisible && (
          <NotificationContentComponent {...this.props} />
        )}
      </Drawer>
    ) : null;
  }
}

const mapStateToProps = ({
  activeItems: { isNotificationBarVisible },
  user: { notificationCount },
}) => {
  return {
    isNotificationBarVisible,
    notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNotificationBar: () => dispatch(toggleNotificationBar()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBarNotification);
