import React from "react";
import "./registration.scss";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { Input } from "antd";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RegistrationPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [passwordMsg, setPasswordMsg] = useState(null);
  const history = useHistory();
  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm();

  const passwordConfirmation = (value) => {
    if (getValues("password") != value) {
      setPasswordMsg("Your password does not match!");
    }
    if (getValues("password") == value) {
      setPasswordMsg(null);
    }
    if (value == "") {
      setPasswordMsg(null);
    }
  };
  return (
    <div className="registration">
      <div className="left">
        <div className="text" style={{ fontSize: "xx-large" }}>
          <h1>Welcome Back!</h1>
          <p>It's great to see you again</p>
          <p style={{ fontSize: "large" }}>Get your work simplified!</p>
        </div>
        <img
          src="/great.png"
          alt="Movie Man"
          style={{ height: "70%", width: "75%", marginTop: "20px" }}
          className="movieMan"
        />
      </div>
      <div className="right ">
        <div className="aceLogo">
          <p>
            Welcome To &nbsp; <span style={{ color: "#ee556a" }}>ACE</span>
          </p>
        </div>
        <div className="formContainer">
          <p
            style={{
              fontSize: "x-large",
              fontWeight: "bold",
              marginTop: "5px",
              color: "white",
            }}
          >
            SIGN UP
          </p>
          <div className="formItem" style={{ marginTop: "0" }}>
            <label>Production Company* </label>
            <Controller
              name="productionCompany"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter company name!",
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/i,
                  message: "Invalid character!",
                },
              }}
              render={() => (
                <Input
                  placeholder="Company name"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setValue("productionCompany", e.target.value);
                    trigger("productionCompany");
                    console.log(errors?.productionCompany?.message);
                  }}
                />
              )}
            />
          </div>
          <p className="errMsg">{errors?.productionCompany?.message}</p>
          <div className="formItem">
            <label>Authrized Person Name* </label>
            <Controller
              name="authPersonName"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please enter name!",
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/i,
                  message: "Invalid character!",
                },
              }}
              render={() => (
                <Input
                  placeholder="Person name"
                  onChange={(e) => {
                    setValue("authPersonName", e.target.value);
                    trigger("authPersonName");
                    console.log(e.target.value);
                  }}
                />
              )}
            />
          </div>
          <p className="errMsg">{errors?.authPersonName?.message}</p>
          <div className="formItem">
            <label>Phone Number* </label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Enter phone number!",
                },
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "Invalid character!",
                },
              }}
              render={() => (
                <Input
                  maxLength={10}
                  value={getValues("phoneNumber")}
                  placeholder="Phone number"
                  onChange={(e) => {
                    const re = /^[0-9]+$/i;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      console.log(e.target.value);
                      setValue("phoneNumber", e.target.value);
                      trigger("phoneNumber");
                    }
                  }}
                />
              )}
            />
          </div>
          <p className="errMsg">{errors?.phoneNumber?.message}</p>
          <div className="formItem">
            <label>Email* </label>
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Enter email id!",
                },
                pattern: {
                  value: /^[A-Za-z0-9_%+-]+@[A-Za-z0-9.-]+.[A-Z]$/i,
                  message: "Invalid format!",
                },
              }}
              render={() => (
                <Input
                  placeholder="Email Id"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setValue("email", e.target.value);
                    trigger("email");
                  }}
                />
              )}
            />
          </div>
          <p className="errMsg">{errors?.email?.message}</p>
          <div className="formItem">
            <label>Password* </label>
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Enter password!",
                },
                pattern: {
                  value: /^[A-Za-z0-9_%+-]+$/i,
                  message: "Invalid character!",
                },
              }}
              render={() => (
                <div className="passwordWrapper">
                  <Input
                    suffix={
                      <button
                        className="eyeButton"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <img
                          src={showPassword ? "/hide.png" : "/view.png"}
                          className="eyeIcon"
                        ></img>{" "}
                      </button>
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    onChange={(e) => {
                      setValue("password", e.target.value);
                      trigger("password");
                    }}
                  />
                </div>
              )}
            />
          </div>
          <p className="errMsg">{errors?.password?.message}</p>
          <div className="formItem">
            <label>Confirm Password* </label>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Enter password again!",
                },
                pattern: {
                  value: /^[A-Za-z0-9_%+-]+$/i,
                  message: "Invalid character!",
                },
              }}
              render={() => (
                <div className="passwordWrapper">
                  <Input
                    suffix={
                      <button
                        className="eyeButton"
                        onClick={() => setShowConfPassword(!showConfPassword)}
                      >
                        <img
                          src={showConfPassword ? "/hide.png" : "/view.png"}
                          className="eyeIcon"
                        ></img>{" "}
                      </button>
                    }
                    type={showConfPassword ? "text" : "password"}
                    placeholder="Re-enter password"
                    onChange={(e) => {
                      passwordConfirmation(e.target.value);
                      setValue("confirmPassword", e.target.value);
                      trigger("confirmPassword");
                    }}
                  />
                </div>
              )}
            />
          </div>
          <p className="errMsg">
            {errors?.confirmPassword?.message}
            {passwordMsg}
          </p>
          <button
            className="signUp"
            onClick={() => {
              trigger().then((res) => {
                console.log(res);
                if (res === true) {
                  history.push("/subscription");
                }
              });
            }}
          >
            Sign Up
          </button>
          <p
            style={{
              color: "white",
              fontSize: "smaller",
              marginTop: "25px",
              marginBottom: "10px",
            }}
          >
            Already have an account?{" "}
            <Link to="/login">
              <span style={{ color: "black" }}>Login</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default RegistrationPage;
