import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
  AutoComplete,
  Modal
} from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage
} from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import EquipmentServices from "services/EquipmentServices";
import { Capitalize } from "utilities/ValidationHelper";
import BudjetService from "services/BudjetService";
import RecordNotFound from "components/shared/RecordNotFound";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.dataIndex === "quantity") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`
              },
              {
                pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                message: "Enter numbers only"
              }
            ],
            initialValue: record[dataIndex]
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`
              }
            ],
            initialValue: record[dataIndex]
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex]
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class VendorEquipment extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._budgetService = new BudjetService();
    this._equipmentService = new EquipmentServices();
    // this.designationTypeValue = this.designationTypeValue.bind(this);
    // this.designationBlurChanges = this.designationBlurChanges.bind(this);
    // this.onChange = this.onChange.bind(this);
    this.state = {
      dataSource: [],
      count: 0,
      page: null,
      actorId: null,
      activeSceneId: null,
      equipmentDropDown: [],
      equipment: null,
      designationTypeValue: null,
      isDisableInput: false,
      vendorId: props.match.params.vendorId,
      modalvisible: false
    };
  }

  componentDidMount() {
    this.getEquipmentDropDown();
    this.fetchData();
    this.getPaymentTypeData();
    this.checkForEmpty(this.state.dataSource);
  }

  getEquipmentDropDown = () => {
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_VENDOR_EQUIPMENT_DROPDOWN,
        "",
        this.state.vendorId
      )
      .then((res) => {
        this.setState({ equipmentDropDown: res.data.equipments });
      });
  };

  getPaymentTypeData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data
        });
      });
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  // designationTypeValue(value) {
  //     if (value && value.trim().length > 0) {
  //         console.log("val;;ue", value);

  //         this.setState({
  //             designationTypeValue: value
  //         });
  //     }
  // }

  // designationBlurChanges(value, record, type) {
  //     this.getEquipmentDropDown()
  //     let designation = this.state.designationTypeValue;
  //     let a = this.state.equipmentDropDown || [];
  //     let b = a.map(a => a.name);
  //     let dx = true;
  //     if (designation != null) {
  //         new Promise(resolve => {
  //             b.forEach(x => {
  //                 if (x && x.toString().trim() != designation.toString().trim()) {
  //                     dx = false;
  //                 } else {
  //                     dx = true;
  //                 }
  //             });

  //             resolve();
  //         })
  //             .then(() => {
  //                 if (!dx) {
  //                     a.push({ label: designation, value: designation });
  //                 }
  //             })
  //             .then(() => {
  //                 this.setState({
  //                     equipmentDropDown: a
  //                 });
  //             })
  //             .then(() => {
  //                 console.log("dsy", designation);
  //                 if (!this.state.isDisableInput) {
  //                     this.onChange(designation, record, type);
  //                     record.equipment = a
  //                 }
  //             })
  //     }
  // }

  fetchData = () => {
    this.setState({
      count: 0,
      isRefreshSelect: true
    });
    this._budgetService
      .budjetService(
        Services.BudjetVariables.GET_VENDOR_BY_EQUIPMENT,
        "",
        this.state.vendorId
      )
      .then((res) => {
        // console.log("Main", res.data.sceneDetails);
        let dataSource = [];
        res.data.equipments &&
          res.data.equipments.map((datum, i) => {
            dataSource.push({ ...datum, key: this.state.count });
            this.setState({
              count: this.state.count + 1
            });
          });
        this.setState(
          {
            dataSource,
            isRefreshSelect: false
            // isActorsFetched: true,
          },
          () => {
            this.checkForEmpty(this.state.dataSource);
            console.log("list....", this.state.cardList);
          }
        );
      })
      .catch((err) => {
        this.setState({
          isRefreshSelect: false
        });
        console.log("song fetch data", err);
      });
  };

  handleUpdate = (record) => {
    const data = {
      equipment: record.equipment,
      paymentType: record.paymentType,
      price: record.price,
      quantity: record.quantity
    };
    this._budgetService
      .budjetService(
        Services.BudjetVariables.UPDATE_VENDOR_BY_EQUIPMENT,
        data,
        record.projectVendorEquipmentId
      )
      .then((res) => {
        this.setState({
          isDisableInput: false
        });
        this.fetchData();
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully!!"
        );
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this._budgetService
      .budjetService(
        Services.BudjetVariables.DELETE_VENDOR_BY_EQUIPMENT,
        null,
        id
      )
      .then((res) => {
        this.fetchData();
        this.setState(
          {
            dataSource: dataSource.filter((item) => item.key !== key)
          },
          () => {
            this.checkForEmpty(this.state.dataSource);
          }
        );
        showNotifications(Notifications.SUCCESS, "Successfully", " Deleted !!");
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1
    });
    const newData = {
      key: count,
      equipment: "",
      price: "",
      paymentType: "",
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    };
    this.setState({
      dataSource: [...dataSource, newData]
      // count:count + 1
    });
  };

  onChange = (value, record, type) => {
    // if (value) {
    //     this.setState({ isDisableInput: true })
    // }
    this.handleSave(record, value, type);

    // const lengthOfTheDataSource = this.state.dataSource.length - 1;
    // const newData = [...this.state.dataSource];
    // const indexOfTheCurrentRow = newData.findIndex(
    //     data => record.key === data.key
    // );
  };

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    switch (type) {
      case "equipment":
        if (value) {
          let modifyItem = newData[index];
          modifyItem.equipment = value;
        }
        break;
      case "paymentType":
        if (value) {
          let modifyItem = newData[index];
          modifyItem.paymentType = value;
        }
        break;
      default:
        break;
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ dataSource: newData }, () => {
      console.log("dataSource save", this.state.dataSource);
    });

    // if (!row.projectVendorEquipmentId) {
    //     if (row.paymentType && row.price && row.equipment) {
    //         this.handleSubmit(row);
    //     } else {
    //         // showNotifications(
    //         //     Notifications.ERROR,
    //         //     "Failed",
    //         //     "All Fields Are Mandatory"
    //         // );
    //     }
    // }
    if (
      row.projectVendorEquipmentId &&
      row.price &&
      row.paymentType &&
      row.equipment &&
      row.quantity
    ) {
      this.handleUpdate(row);
    }
  };

  handleCancel = () => {
    this.setState({
      modalvisible: false
    });
  };

  handleCreateEquipment = () => {
    const data = {
      equipment: this.state.equipmentName,
      projectVendorId: this.state.vendorId
    };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true
        });
        this._budgetService
          .budjetService(
            Services.BudjetVariables.CREATE_VENDOR_BY_EQUIPMENT,
            data,
            this.state.vendorId
          )
          .then((res) => {
            this.fetchData();
            this.setState({
              isLoading: false,
              modalvisible: false
            });
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "New Equipment created successfully!!"
            );
          })
          .catch((err) => {
            this.setState({
              isLoading: false
            });
            showNotifications(
              Notifications.ERROR,
              "Failed",
              err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            );
          });
      }
    });
  };

  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: "#",
        dataIndex: "sno",
        width: "8%",
        align: "center",
        render: (text, record, index) => index + 1
      },
      {
        title: "Equipment",
        dataIndex: "equipment",
        align: "center",
        width: "25%",
        render: (text, record) => <span>{Capitalize(text)}</span>
        // render: (text, record) =>
        //     this.state.dataSource.length >= 1 ? (
        //         <Select
        //             showSearch
        //             onSearch={this.designationTypeValue}
        //             style={{ width: "100%" }}
        //             placeholder="Select Equipment"
        //             defaultValue={text ? text : ""}
        //             onChange={value => this.onChange(value, record, "equipment")}
        //         >
        //             {this.state.equipmentDropDown &&
        //                 this.state.equipmentDropDown.map((option, i) => (
        //                     <Option value={option.label}>
        //                         {option.label}
        //                     </Option>
        //                 ))}
        //         </Select>
        //     ) : null
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "15%",
        editable: true,
        align: "center"
      },
      {
        title: "Rate",
        dataIndex: "price",
        width: "15%",
        editable: true,
        align: "center"
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        width: "15%",
        align: "center",
        render: (text, record) =>
          !this.state.isRefreshSelect && (
            <Select
              style={{ width: "100%" }}
              className="border-none"
              placeholder="Select type"
              onChange={(value) => this.onChange(value, record, "paymentType")}
              defaultValue={text ? text : ""}
            >
              {this.state.paymentTypeData &&
                this.state.paymentTypeData.map((option, i) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          )
      },
      {
        title: "Action",
        dataIndex: "operation",
        width: "8%",
        align: "center",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.projectVendorEquipmentId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  {" "}
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null
      }
    ];

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columnsData = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });
    return (
      <div>
        <Row type="flex" justify="space-between" style={{ marginBottom: 20 }}>
          <Button type="primary" onClick={() => this.props.history.goBack()}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => this.setState({ modalvisible: true })}
          >
            Add Equipment
          </Button>
        </Row>
        <Row type="flex" align="middle" justify="center">
          <Col xl={21}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnsData}
              pagination={false}
              locale={{
                emptyText: <RecordNotFound />
              }}
            />
          </Col>
        </Row>
        <Modal
          destroyOnClose
          title="Add Equipment"
          visible={this.state.modalvisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="400px"
          footer={
            <Row type="flex" justify="end">
              <Button type="default" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={this.state.isLoading}
                onClick={this.handleCreateEquipment}
              >
                Save
              </Button>
            </Row>
          }
        >
          <label className="font-600">Equipment</label>
          <Form.Item>
            {this.props.form.getFieldDecorator("equipmentName", {
              rules: [
                {
                  required: true,
                  message: "Select Equipment!"
                }
              ]
            })(
              <Select
                placeholder="Select Equipment"
                style={{ width: "100%" }}
                showSearch
                onSearch={this.designationTypeValue}
                onChange={(value) => {
                  this.setState({ equipmentName: value });
                }}
              >
                {this.state.equipmentDropDown &&
                  this.state.equipmentDropDown.map((option, i) => (
                    <Option value={option.label}>{option.label}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Modal>
        {/* <Row type="flex" justify="center">
                    <Col xl={21} style={{ textAlign: "end" }}>
                        <Button
                            onClick={this.handleAdd}
                            type="primary"
                            style={{ marginTop: 16 }}
                        >
                            Add Entry
                        </Button>
                    </Col>
                </Row> */}
      </div>
    );
  }
}

export default Form.create()(VendorEquipment);
