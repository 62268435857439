export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_ACTIVE_MENU_PERMISSIONS = "SET_ACTIVE_MENU_PERMISSIONS";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_MENUS = "SET_MENUS";
export const SET_FONTSIZE = "SET_FONTSIZE";
export const SET_SCENE_NUMBERS = "SET_SCENE_NUMBERS";
export const SEt_ACTIVE_SCENE_ID = "SEt_ACTIVE_SCENE_ID";
export const SET_ACTIVE_SCENE_LOCATION_ID = "SET_ACTIVE_SCENE_LOCATION_ID";
export const SET_ACTIVE_COMPONENT_CAST = "SET_ACTIVE_COMPONENT_CAST";
export const SET_COSTUME_LEFT_ACTIVE_TAB = "SET_COSTUME_LEFT_ACTIVE_TAB";
export const SET_MAKEUP_LEFT_ACTIVE_TAB = "SET_MAKEUP_LEFT_ACTIVE_TAB";
export const OPEN_NOTIFICATION_BAR = "OPEN_NOTIFICATION_BAR";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
