import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Icon,
  Divider,
  Tooltip,
  Modal,
} from "antd";
import UploadImage from "components/shared/UploadImage/UploadImage";
import "./ArtSetTable.css";
import FileCard from "components/shared/FileCardComponent";
import {
  AttachmentType,
  GlobalConstants,
  LocalStorage,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { ScriptService } from "services";
import { Services, Notifications } from "constants/AppConstants";
import ArtSetTabService from "services/SceneOverviewService/ArtSetTabServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { Link } from "react-router-dom";
import ImageViewer from "components/shared/ImageViewer";
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import * as _ from "lodash";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import PaymentTypeDD from "./PaymentTypeDD";
import { handleError } from "utilities/ErrorHandler";
import { getNotificationCount } from "store/thunk-actions";

const { Option } = Select;
const { confirm } = Modal;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              type="number"
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              style={{ width: "100%" }}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              style={{ width: "100%" }}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class ArtSetTable extends Component {
  getArtSetType = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_DETAIL_TYPE
      )
      .then((res) => {
        this.setState({
          dropDown: res.data,
        });
      });
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
    this._artSetTabService = new ArtSetTabService();
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      artSetDDList: [],
      count: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      dropDown: null,
      sceneId: null,
      isLoading: true,
      isButtonLoading: false,
      paymentTypeData: [],
      pageType: null,
      serviceType: null,
      shootDate: null,
      artSetKeyword: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pageType === "Tab") {
      if (nextProps.activeSceneId !== this.state.sceneId) {
        this.setState(
          {
            sceneId: nextProps.activeSceneId,
            pageType: nextProps.pageType,
            serviceType: nextProps.serviceType,
          },
          () => {
            this.getArtSetData();
            this.getArtSetDD();
          }
        );
      }
    }
  }

  recordData = (record) => {
    if (this.state.fileList) {
      this.handleSave(record, this.state.fileList, "image");
    }
  };

  deleteImage = (id) => {
    let deleteIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let finalData = imageFiles.splice(deleteIndex, 1);
    this.setState({
      imageFiles,
    });
  };

  splitBasedOnFileTypes = (files) => {
    let tempImageFiles = [];
    files.forEach((file) => {
      if (file.images) {
        tempImageFiles.push(file?.images);
      }
    });
    let imageFiles =
      tempImageFiles &&
      tempImageFiles.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      imageFiles,
    });
  };

  handleImage = (url, data) => {
    const { fileList } = this.state;
    let temp = [{ attachmentId: url, fileType: GlobalConstants.IMAGE }];
    this.setState(
      {
        fileList: temp,
      },
      () => {
        this.handleSave(
          data,
          this.state.fileList,
          "image",
          this.state.fileList
        );
      }
    );
  };

  componentDidMount() {
    if (
      this.props.pageType === "Page" &&
      this.props.serviceType &&
      this.props.shootDate
    ) {
      this.setState(
        {
          pageType: this.props.pageType,
          serviceType: this.props.serviceType,
          shootDate: this.props.shootDate,
        },
        () => this.getArtSetData()
      );
    }
    this.getArtSetDD();
    this.getArtSetType();
    this.getPaymentTypeData();
  }
  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  getPaymentTypeData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_PAYMENT
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data,
        });
      });
  }

  getArtSetData = () => {
    if (this.state.serviceType) {
      this.setState({ count: 0, dataSource: [] });
      this._artSetTabService
        .service(
          this.state.serviceType,
          null,
          null,
          this.state.sceneId || this.state.shootDate
        )
        .then((res) => {
          let artSetData = [];
          res.data.artSets &&
            res.data.artSets.map((data, i) => {
              artSetData.push({ ...data, key: this.state.count });
              this.setState({
                count: this.state.count + 1,
              });
            });
          this.setState(
            {
              dataSource: artSetData,
              totalRecords: artSetData,
              // isLoading: true
            },
            () => {
              this.splitBasedOnFileTypes(this.state.dataSource);
              // this.checkForEmpty(this.state.dataSource);
            }
          );
        });
    }
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        // this.checkForEmpty(this.state.dataSource);
      }
    );
    if (id) {
      this._artSetTabService
        .service(Services.ArtSetVariables.DELETE_ART_SET_LIST, id)
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.getArtSetData();
          this.getArtSetDD();
          this.props.getNotificationCount();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          );
        });
    }
  };

  handleSubmit = (record) => {
    this.setState({ isButtonLoading: true });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
    let data = this.state.dataSource[indexOfTheCurrentRow];
    record.type = record.type;
    this._artSetTabService
      .service(Services.ArtSetVariables.ADD_ART_SET, record)
      .then((res) => {
        this.setState({ isButtonLoading: false });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully!!"
        );
        // this.setState({ count: 0 });
        this.getArtSetData();
        this.getArtSetDD();
        this.props.getNotificationCount();
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          showNotifications(
            Notifications.ERROR,
            "Access Denied",
            "Please contact your Project Admin"
          );
          this.props.getPermissions();
        } else {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );
        }
      });
  };

  handleUpdate = (record) => {
    this.setState({ isButtonLoading: true });
    let type = record.type;
    type = type.replace(/ /g, "_");
    let enumType = type.toUpperCase();
    let data = {
      description: record.description,
      images: record.images,
      quantity: record.quantity,
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
      type: enumType,
      artSetDetailId: record.artSetDetailId,
      costType: record.costType,
      costEstimation: record.costEstimation,
      sceneId: record.sceneId,
    };
    // if (type !== "SPECIAL_PROPERTY") {
    //   delete data.costType;
    // }
    if (record.artSetDetailId) {
      this._artSetTabService
        .service(
          Services.ArtSetVariables.UPDATE_ART_SET_LIST,
          data,
          record.artSetDetailId
        )
        .then((res) => {
          this.setState({ isButtonLoading: false });
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
          if (this.state.pageType === "Page") {
            this.getArtSetData();
          }
          this.getArtSetData();
          this.props.getNotificationCount();
          // this.setState({ count: 0 });
          // this.getArtSetData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );
        });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: "",
      type: null,
      quantity: "",
      images: [],
      costEstimation: "",
      costType: null,
      sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type) {
    this.handleSave(record, value, type);
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
    let newDataSoruce = newData.map((data) => {
      if (data?.key === record?.key) {
        data[type] = value;
      }
      return data;
    });
    this.setState({
      dataSource: newDataSoruce,
    });
  }

  handleSave = (row, value = null, type) => {
    const that = this;
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      let modifyItem = newData[index];
      switch (type) {
        case "type":
          modifyItem.type = value;
          // if (modifyItem.type !== "SPECIAL_PROPERTY") {
          // modifyItem.costType = null;
          modifyItem.costTypeValue = null;
          modifyItem.costType = null;

          // }
          // this.setState(
          //   {
          //     dataSource: { ...this.state.dataSource, ...modifyItem }
          //   },
          //   () => console.log("modifyItem...", this.state.dataSource)
          // );
          break;
        case "costType":
          modifyItem.costTypeValue = value;
          modifyItem.costType = value;
          break;
        case "description":
          modifyItem.description = value;
          break;
        case "image":
          if (modifyItem.images) {
            modifyItem.images = [...modifyItem.images, ...value];
          } else {
            modifyItem.images = value;
          }
          break;
        default:
          break;
      }
    }
    if (!row.artSetDetailId) {
      let isValidEnum = row.type === "SPECIAL_PROPERTY" ? row.costType : true;
      if (
        !checkFor(
          this.props.currentPermission,
          GlobalConstants.PERMISSION_FINANCE
        ) &&
        row.description &&
        row.type &&
        row.quantity
      ) {
        let data = row;
        confirm({
          title:
            "Do you want to apply this Art/Set details to all scenes which are shoot at the same location?",
          content: `Click "OK" to apply`,
          onOk() {
            return new Promise((resolve, reject) => {
              data = { ...data, isSetAllScenes: true };
              that.handleSubmit(data);
              resolve();
            }).catch(() => console.log("Oops errors!"));
          },
          onCancel() {
            data = { ...data, isSetAllScenes: false };
            that.handleSubmit(data);
          },
        });
      } else if (
        row.description &&
        row.type &&
        row.quantity &&
        row.costEstimation &&
        // isValidEnum
        row.costType
        // row.images.length > 0
      ) {
        let data = row;
        confirm({
          title:
            "Do you want to apply this Art/Set details to all scenes which are shoot at the same location?",
          content: `Click "OK" to apply`,
          onOk() {
            return new Promise((resolve, reject) => {
              data = { ...data, isSetAllScenes: true };
              that.handleSubmit(data);
              resolve();
            }).catch(() => console.log("Oops errors!"));
          },
          onCancel() {
            data = { ...data, isSetAllScenes: false };
            that.handleSubmit(data);
          },
        });
      }
    }
    if (row.artSetDetailId) {
      if (
        row.description &&
        row.type &&
        // row.quantity &&
        row.costEstimation &&
        // row.costType &&
        row.costType
        // &&
        // isValidEnum
        // row.images.length > 0
      ) {
        if (!_.isEqual(row, this.state.activeRecord)) {
          this.handleUpdate(row);
        }
      }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      // this.props.getFromChild(this.state.dataSource, this.state.count);
    });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  getArtSetDD = (value) => {
    this._artSetTabService
      .service(Services.ArtSetVariables.GET_ART_SET_DROPDOWN, null, null, null)
      .then((res) => {
        this.setState({
          artSetDDList: res.data.artSets,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  handleArtSet = (name, record) => {
    this._artSetTabService
      .service(Services.ArtSetVariables.GET_ART_SET_BY_NAME, name)
      .then((res) => {
        let data = {
          costEstimation: res.data.amount,
          costType: res.data.costType,
          type: res.data.type,
          description: name,
          sceneId: this.state.sceneId,
          quantity: 1,
        };
        if (record.artSetDetailId) {
          data = {
            ...data,
            artSetDetailId: record.artSetDetailId,
            quantity: 1,
          };
          this.handleUpdate(data);
        } else {
          this.handleSubmit(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleArtSetValue = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          artSetKeyword: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  setArtSet = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = this.state.artSetKeyword;
      if (newData) {
        this.setState({ eventType });
        record.description = newData;
        this.onChange(newData, record, "description");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  render() {
    const { dataSource, isLoading, totalRecords } = this.state;
    const { currentPermission, activePermissions } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    let columnsData = null;

    if (this.state.pageType === "Page") {
      columnsData = [
        {
          title: "#",
          align: "center",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Description",
          dataIndex: "description",
          // editable: true,
          width: "15%",
          className: "overflowInput",
        },
        {
          title: "Type",
          dataIndex: "type",
          width: "15%",
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <Select
                className={`${!text ? "custom-empty-active" : ""}`}
                style={{ width: "100%" }}
                placeholder="Select type"
                disabled={
                  !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
                }
                onChange={(value) => this.onChange(value, record, "type")}
                value={text ? text : ""}
              >
                {this.state.dropDown &&
                  this.state.dropDown.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ) : null,
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          editable: true,
          width: "15%",
          render: (text, record) => {
            return {
              props: {
                className: `${!text ? "empty-active" : ""}`,
              },
              children: text,
            };
          },
        },
        {
          title: "Cost Estimation (₹)",
          dataIndex: "costEstimation",
          editable: true,
          className: "cash",
          render: (text, record) => {
            return {
              props: {
                className: `${!text ? "empty-active" : ""}`,
              },
              children: text,
            };
          },
        },
        {
          title: "Payment Type",
          dataIndex: "costTypeValue",
          render: (text, record) => (
            <PaymentTypeDD
              type={record.type}
              record={record}
              text={text}
              onChange={this.onChange}
            />
          ),
        },
        {
          title: "Image Reference",
          dataIndex: "images",
          className: "upload-table",
          render: (text, record) => {
            return (
              <React.Fragment>
                {record?.images?.length > 0 ? (
                  <ImageViewer
                    imageCount={1}
                    ImagesData={record?.images}
                    type="table"
                    refreshData={this.getArtSetData}
                    url={Services.ArtSetVariables.DELETE_TABLE_IMAGE}
                    canDelete
                  />
                ) : null}
                <div className="small-upload-image">
                  {!this.state.isButtonLoading ? (
                    <UploadImage
                      keys={record}
                      type="ARTSET"
                      handleImage={this.handleImage}
                    />
                  ) : null}
                </div>
              </React.Fragment>
            );
          },
        },
      ];
    } else if (this.state.pageType !== "Page") {
      columnsData = [
        {
          title: "#",
          align: "center",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Description",
          dataIndex: "description",
          // editable: true,
          width: "20%",
          className: "overflowInput",
          render: (text, record) =>
            this.state.isLoading && (
              <Select
                showSearch
                onSearch={(val) => this.handleArtSetValue(val, record)}
                disabled={
                  !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
                }
                onFocus={() =>
                  this.setState({
                    juniorCharcKeyword: null,
                    eventType: "isNotBlur",
                  })
                }
                onBlur={(value) => this.setArtSet(value, record, "blur")}
                style={{ width: "100%", minWidth: "98%", maxWidth: "200px" }}
                placeholder="Select type"
                // disabled={!this.props.EDIT && record.characterId}
                // onChange={value =>
                //   this.onChange(value, record, "characterName")
                // }
                onChange={(value) => this.handleArtSet(value, record)}
                value={text ? text : ""}
                // defaultValue={text ? text : ""}
                className={`${!text ? "custom-empty-active" : ""}`}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.artSetDDList &&
                  this.state.artSetDDList.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ),
        },
        {
          title: "Type",
          dataIndex: "type",
          render: (text, record) =>
            this.state.dataSource.length >= 1 ? (
              <Select
                style={{ width: "100%" }}
                placeholder="Select type"
                disabled={
                  !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
                }
                className={`${!text ? "custom-empty-active" : ""}`}
                onChange={(value) => this.onChange(value, record, "type")}
                value={record?.type}
              >
                {this.state.dropDown &&
                  this.state.dropDown.map((option, i) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            ) : null,
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          editable: checkFor(
            currentPermission,
            GlobalConstants.PERMISSION_EDIT
          ),
          width: "8%",
          className: "cash",
          render: (text, record) => {
            return {
              props: {
                className: `${!text ? "empty-active" : ""}`,
              },
              children: text,
            };
          },
        },
        {
          title: "Cost Estimation (₹)",
          dataIndex: "costEstimation",
          editable: checkFor(
            currentPermission,
            GlobalConstants.PERMISSION_EDIT
          ),
          width: "15%",
          className: "cash",
          render: (text, record) => {
            return {
              props: {
                className: `${!text ? "empty-active" : ""}`,
              },
              children: text,
            };
          },
        },
        {
          title: "Payment Type",
          dataIndex: "costTypeValue",
          render: (text, record) => (
            <PaymentTypeDD
              disabled={
                !checkFor(currentPermission, GlobalConstants.PERMISSION_EDIT)
              }
              type={record.type}
              text={record.costType}
              onChange={this.onChange}
              record={record}
            />
          ),
        },
        {
          title: "Image Reference",
          dataIndex: "images",
          className: "upload-table",
          render: (text, record) => {
            return (
              <>
                {record?.images?.length > 0 ? (
                  <ImageViewer
                    imageCount={1}
                    ImagesData={record?.images}
                    type="table"
                    refreshData={this.getArtSetData}
                    url={Services.ArtSetVariables.DELETE_TABLE_IMAGE}
                    canDelete
                    // canDelete={true}
                  />
                ) : null}
                <div className="small-upload-image">
                  {!this.state.isButtonLoading ? (
                    <UploadImage
                      keys={record}
                      type={AttachmentType?.artSetImage}
                      handleImage={this.handleImage}
                    />
                  ) : null}
                </div>
              </>
            );
          },
        },
      ];
    }
    if (checkFor(currentPermission, GlobalConstants.PERMISSION_DELETE)) {
      columnsData.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              <Row type="flex" justify="center">
                <Col xl={11}>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() =>
                      this.handleDelete(record.key, record.artSetDetailId)
                    }
                  >
                    <Tooltip
                      title={GlobalConstants.DEL}
                      placement={GlobalConstants.RIGHT}
                    >
                      <Button icon="delete" type="link" />
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </React.Fragment>
          ) : null,
      });
    }

    if (!checkFor(currentPermission, "Finance")) {
      columnsData.splice(4, 2);
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = columnsData.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex !== "description" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Row type="flex" justify="center" align="middle" style={{ padding: 10 }}>
        <Col xs={24}>
          <Row type="flex" justify="center" align="middle">
            {this.state.pageType !== "Page"
              ? checkFor(currentPermission, "Add") && (
                  <Col xs={24} style={{ paddingBottom: 10 }}>
                    <Button
                      onClick={this.handleAdd}
                      type="primary"
                      style={{ marginTop: 16, float: "right" }}
                      disabled={hasNewRow}
                    >
                      <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                    </Button>
                  </Col>
                )
              : null}
            <Col xs={24}>
              <Table
                className="art-set"
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{
                  emptyText: isLoading && <RecordNotFound />,
                }}
                loading={!isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({
  scene,
  activeItems: { activeMenuPermissions },
  user: { menus },
}) => {
  return {
    activeSceneId: scene.activeSceneId,
    currentPermission: getSPermissionFromMenus("Art/Set", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtSetTable);
