import VendorImage from "assets/lottiefiles/5666-zezinho.json";
import RecordNotFound from "assets/lottiefiles/5451-search-file.json";
import AccessDenied from "assets/lottiefiles/5270-locked.json";

const LottieFile = {
  VendorImage,
  RecordNotFound,
  AccessDenied,
};

export default LottieFile;
