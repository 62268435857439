import React, { Fragment, useState, useEffect } from "react";
import { Table, Row, Col, Select } from "antd";

import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import ActorsServices from "services/ActorsServices";
import { Services, GlobalConstants } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { showActionMessage } from "components/shared/NotificationComponent";
import RecordNotFound from "components/shared/RecordNotFound";
import { checkFor } from "utilities/ValidationHelper";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";

const { Option } = Select;

const AnimalStatusComponent = (props) => {
  const { activePermisision } = props;
  const [DataSource, setDataSource] = useState([]);
  const [DDList, setDDList] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const {
    match: {
      params: { sceneId },
    },
  } = props;
  const api = new ActorsServices();
  const DDApi = new GlobalService();
  const handleStatusChange = (awbStatus, name) => {
    api
      .actorsServices(
        Services.ActorVariables.UPDATE_ANIMAL_STATUS,
        name,
        null,
        awbStatus
      )
      .then((res) => {
        console.log(res);
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
      })
      .catch((err) => {
        showActionMessage(GlobalConstants.ERROR, "Something went wrong...");
      });
  };
  const columns = [
    {
      title: "#",
      align: "center",
      width: "10%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Animal Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "AWB Status",
      dataIndex: "awbStatus",
      render: (text, record) => (
        <Select
          onChange={(e) => handleStatusChange(e, record.name)}
          defaultValue={text ? text : ""}
          style={{ width: "50%" }}
          className="border-none"
          placeholder="Select type"
          disabled={
            !checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)
          }
        >
          {DDList &&
            DDList.map((option, i) => (
              <Option value={option.value}>{option.label}</Option>
            ))}
        </Select>
      ),
    },
  ];

  const getStatusList = () => {
    DDApi.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.AWB_STATUS
    ).then((res) => {
      setDDList(res.data);
    });
  };

  const getData = () => {
    setIsLoading(true);
    api
      .actorsServices(Services.ActorVariables.GET_ANIMAL_DETAILS, sceneId)
      .then((res) => {
        setDataSource(res.data.animals);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getStatusList();
  }, []);

  return (
    <Fragment>
      <Row style={{ marginBottom: 24 }}>
        <Col xl={24}>
          <Title {...props} hasBack>
            <TitleText>Animal Status</TitleText>
          </Title>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xl={12}>
          <Table
            loading={IsLoading}
            dataSource={DataSource}
            columns={columns}
            bordered
            pagination={false}
            locale={{
              emptyText: <RecordNotFound />,
            }}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

export default connect(mapStateToProps)(AnimalStatusComponent);
