import {
  SET_FONTSIZE,
  SET_USER,
  REMOVE_USER,
  SET_ACCESS_TOKEN,
  SET_ACTIVE_PROJECT,
  SET_ACTIVE_MENU,
  SET_MENUS,
  SET_ACTIVE_TAB,
  SET_SCENE_NUMBERS,
  SEt_ACTIVE_SCENE_ID,
  SET_ACTIVE_SCENE_LOCATION_ID,
  SET_ACTIVE_MENU_PERMISSIONS,
  SET_ACTIVE_COMPONENT_CAST,
  SET_COSTUME_LEFT_ACTIVE_TAB,
  SET_MAKEUP_LEFT_ACTIVE_TAB,
  OPEN_NOTIFICATION_BAR,
  SET_NOTIFICATION_COUNT,
} from "./actionTypes";

export const setFontSize = (size) => {
  return {
    type: SET_FONTSIZE,
    payload: {
      size,
    },
  };
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: {
      user,
    },
  };
};

export const setMenus = (menus) => {
  return {
    type: SET_MENUS,
    payload: {
      menus,
    },
  };
};
export const setNotificationCount = (count) => {
  return {
    type: SET_NOTIFICATION_COUNT,
    payload: {
      count,
    },
  };
};

export const setAccessToken = (accessToken) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload: {
      accessToken,
    },
  };
};

export const setActiveMenuPermissions = (activeMenuPermissions) => {
  return {
    type: SET_ACTIVE_MENU_PERMISSIONS,
    payload: {
      activeMenuPermissions,
    },
  };
};

export const setCostumeLeftActiveTab = (key) => {
  return {
    type: SET_COSTUME_LEFT_ACTIVE_TAB,
    key,
  };
};

export const setMakeupLeftActiveTab = (key) => {
  return {
    type: SET_MAKEUP_LEFT_ACTIVE_TAB,
    key,
  };
};

export const setActiveProject = (projectIds, projectTitle) => {
  return {
    type: SET_ACTIVE_PROJECT,
    payload: {
      projectIds,
      projectTitle,
    },
  };
};

export const setActiveTab = (activeTabId) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: {
      activeTabId,
    },
  };
};

export const setSceneNumbers = (sceneNumbers) => {
  return {
    type: SET_SCENE_NUMBERS,
    payload: { sceneNumbers },
  };
};

export const setActiveSceneId = (activeSceneId) => {
  return {
    type: SEt_ACTIVE_SCENE_ID,
    payload: {
      activeSceneId,
    },
  };
};

export const setActiveSceneLocationId = (activeSceneLocationId) => {
  return {
    type: SET_ACTIVE_SCENE_LOCATION_ID,
    payload: {
      activeSceneLocationId,
    },
  };
};

export const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const setActiveComponentCast = (listTypeCast) => {
  return {
    type: SET_ACTIVE_COMPONENT_CAST,
    payload: {
      listTypeCast,
    },
  };
};

export const toggleNotificationBar = () => {
  return {
    type: OPEN_NOTIFICATION_BAR,
  };
};
