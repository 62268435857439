import React from "react";
import "./gototop.css";
import { BackTop, Icon } from "antd";

export default function GotoTop({ onClick }) {
  return (
    <div>
      <BackTop visibilityHeight={100}>
        <div className="gototop">
          <Icon type="arrow-up" />
        </div>
      </BackTop>
    </div>
  );
}
