import React, { Component } from "react";
import "antd/dist/antd.css";
import { Table, Input, Button, Popconfirm, Form, Row, Col, Select } from "antd";
import { Services } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { AutoComplete } from "antd";
import { ScriptService } from "services";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  state = {
    editing: true,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    console.log("save", e.target.value);
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  handleChange = (value) => {
    console.log(value);
    // this.props.form.validateField(["Dropdown1"]);
  };
  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class Dynamictable extends Component {
  getPaymentType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "PAYMENT_TYPE")
      .then((res) => {
        this.setState(
          {
            dropDown: res.data,
          },
          () => console.log("dropdown...", this.state.dropdown)
        );
      });
  };

  handleChange = (record, value) => {
    console.log("record....", record);
    console.log("value...", value);
    let paymenttypework = true;
    this.setState(
      {
        paymentType: value,
      },
      () => this.handleSave(record, paymenttypework)
    );
  };

  constructor(props) {
    super(props);
    this.scriptservice = new ScriptService();
    this._globalService = new GlobalService();
    this.columns = [
      {
        title: "Name",
        dataIndex: "staffName",
        editable: true,
        align: "center",
      },
      {
        title: "Designation",
        dataIndex: "designation",
        editable: true,
        align: "center",
      },
      {
        title: "Payment",
        dataIndex: "payment",
        editable: true,
        align: "center",
      },
      {
        title: "Type",
        dataIndex: "paymentType",
        editable: true,
        align: "center",
        width: "25%",
        render: (record) => (
          <Select
            placeholder="PaymentType"
            className="mt-5"
            onChange={(value) => this.handleChange(record, value)}
            defaultValue={record.paymentType}
          >
            {this.state.dropDown.map((option, i) => (
              <Option value={option.value}>{option.label}</Option>
            ))}
          </Select>
          // <span>{record}</span>
        ),
      },

      {
        title: "Action",
        dataIndex: "operation",
        align: "center",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Popconfirm
              title="Are you sure want to delete?"
              onConfirm={() => this.handleDelete(record.key, record.staffId)}
            >
              <a href="javascript:;">Delete</a>
            </Popconfirm>
          ) : null,
      },
    ];

    this.state = {
      dataSource: [
        // {
        //   key: '0',
        //   name: 'Edward King 0',
        //   designation: '32',
        //   payment: 'London, Park Lane no. 0',
        //   type:'ok'
        // },
        // {
        //   key: '1',
        //   name: 'Edward King 1',
        //   designation: '32',
        //   payment: 'London, Park Lane no. 1',
        //   type:'ok'
        // },
      ],
      // paymentType:null,
      count: 2,
      dropDown: null,
      // paymentTypeValue: null
    };
  }

  handleDelete = (key, staffId) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
    if (staffId && key) {
      this.scriptservice
        .scriptService(
          Services.AddActor.DEL_ACTOR,
          staffId,
          console.log("hai iam there", staffId)
        )
        .then((res) => {
          // this.props.refreshData(this.props.actorId)
          // this.props.refreshData()
        });
    }
  };

  handleAdd = () => {
    const { count, dataSource, paymentType, paymentTypeValue } = this.state;
    const newData = {
      key: count,
      staffName: null,
      designation: null,
      payment: null,
      paymentType: null,
    };

    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
      // paymentType
    });
  };

  componentDidMount() {
    // this.getPaymentType();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.staffData) {
      this.setState({
        dataSource: nextProps.staffData,
      });
    }
  }

  handleSave = (row, paymenttypework) => {
    // let temp = this.state.dataSource;
    // console.log("handlesavedatasource", temp);
    // let data = {
    //   paymentType: this.state.paymentTypeValue
    // };
    // console.log("this.state.paymentTypeValue", this.state.paymentTypeValue);
    //   console.log("data...temp", temp)
    // console.log("data...", data);
    // // if (paymenttypework) {
    //   Object.assign(temp[row.key - 1], data);
    //   console.log("temp", temp);
    //   this.setState(
    //     {
    //       dataSource: [...this.state.dataSource]
    //     },
    //     () => console.log("datasource....", this.state.dataSource)
    //   );
    // }

    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
    this.props.handlestaff(newData);
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    console.log("render datasour", dataSource);
    return (
      <div>
        <Row>
          <Col xl={{ span: 23 }} md={{ span: 24 }}>
            <Table
              components={components}
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 2, offset: 16 }}
            lg={{ span: 1, offset: 14 }}
            md={{ span: 1, offset: 13 }}
            style={{ marginTop: "20px" }}
          >
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ marginLeft: "16px" }}
            >
              Add Staff Details
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dynamictable;
Dynamictable.defaultProps = {
  dataSource: [],
};
