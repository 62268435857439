import React from "react";
import { Col } from "antd";
import SkeletonComponent from "components/shared/SkeletonComponent";

const ActorSkeletonCard = () => {
  return (
    <Col xl={6} className="skeletonCard" style={{ marginTop: 5 }}>
      <Col xl={22} className="card" style={{ height: 100, maxHeight: 100 }}>
        <Col xl={22}>
          <Col xl={16}>
            <SkeletonComponent width={100} />
            <SkeletonComponent width={130} />
          </Col>
          <Col xl={8} className="flexCenter">
            <SkeletonComponent circle={true} width={50} height={50} />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default ActorSkeletonCard;
