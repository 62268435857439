import React, { Component, Fragment } from "react";
import { Row, Col, Button } from "antd";
import { Images } from "assets/images";
import { Link } from "react-router-dom";
import { ToWords } from "to-words";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";

import "./BudjeterMain.scss";
import BudjetService from "services/BudjetService";
import { Services } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { checkFor, PriceFormat } from "utilities/ValidationHelper";
import SkeletonComponent from "components/shared/SkeletonComponent";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

class BudjeterMain extends Component {
  constructor(props) {
    super(props);
    this._budgetService = new BudjetService();
    this.state = {
      postProductionBudget: null,
      preProductionBudget: null,
      productionBudget: null,
      totalBudget: null,
      totalBudgetRoundOff: null,
      totalBudgetRoundOffInWords: null,
      travelAndLodgeingBudget: null,
      vendorBudget: null,
      dataFetched: false,
      loadingCount: 1,
      loadingPreProductionBudget: true,
      loadingPostProductionBudget: true,
      loadingProductionBudget: true,
      loadingTravelAndLodgeingBudget: true,
      loadingVendorBudget: true,
      loadingCastTechniciansBudget: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({
      loadingPreProductionBudget: true,
      loadingPostProductionBudget: true,
      loadingProductionBudget: true,
      loadingTravelAndLodgeingBudget: true,
      loadingVendorBudget: true,
      loadingCastTechniciansBudget: true,
    });
    this._budgetService
      .getBudgeter({ category: "PRE_PRODUCTION" })
      .then((res) => {
        this.setState(
          {
            loadingPreProductionBudget: false,
            preProductionBudget: res.data.preProductionBudget,
          },
          () => this.checkLoadingComplete()
        );
      });

    this._budgetService
      .getBudgeter({ category: "POST_PRODUCTION" })
      .then((res) => {
        this.setState(
          {
            loadingPostProductionBudget: false,
            postProductionBudget: res.data.postProductionBudget,
          },
          () => this.checkLoadingComplete()
        );
      });
    this._budgetService.getBudgeter({ category: "PRODUCTION" }).then((res) => {
      this.setState(
        {
          loadingProductionBudget: false,
          productionBudget: res.data.productionBudget,
        },
        () => this.checkLoadingComplete()
      );
    });
    this._budgetService
      .getBudgeter({ category: "TRAVEL_AND_LODGEING" })
      .then((res) => {
        this.setState(
          {
            loadingTravelAndLodgeingBudget: false,
            travelAndLodgeingBudget: res.data.travelAndLodgeingBudget,
          },
          () => this.checkLoadingComplete()
        );
      });
    this._budgetService.getBudgeter({ category: "VENDOR" }).then((res) => {
      this.setState(
        {
          loadingVendorBudget: false,
          vendorBudget: res.data.vendorBudget,
        },
        () => this.checkLoadingComplete()
      );
    });
    this._budgetService
      .getBudgeter({ category: "CAST_TECHNICIANS" })
      .then((res) => {
        this.setState(
          {
            loadingCastTechniciansBudget: false,
            castTechniciansBudget: res.data.castTechniciansBudget,
          },
          () => this.checkLoadingComplete()
        );
      });
  };

  checkLoadingComplete = () => {
    if (
      !this.state.loadingPreProductionBudget &&
      !this.state.loadingPostProductionBudget &&
      !this.state.loadingProductionBudget &&
      !this.state.loadingTravelAndLodgeingBudget &&
      !this.state.loadingVendorBudget &&
      !this.state.loadingCastTechniciansBudget
    )
      this.setState({ dataFetched: true });
  };

  amountNumberToText = () => {
    let word = "";
    try {
      word = this.toWords.convert(this.calculateTotal());
    } catch (error) {
      console.log(error);
    }
    return word;
  };

  calculateTotal = () => {
    let data = this.state;
    let total =
      data?.castTechniciansBudget +
      data?.postProductionBudget +
      data?.preProductionBudget +
      data?.productionBudget +
      data?.travelAndLodgeingBudget +
      data?.vendorBudget;
    return total;
  };

  toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  renderSkeletonCards = () => {
    let template = [];
    for (let i = 1; i <= 6; i++) {
      template.push(
        <Col xl={8} className="skeletonCard">
          <Col xl={22} className="card">
            <Col xl={22}>
              <Col xl={16}>
                <SkeletonComponent width={100} />
                <SkeletonComponent width={150} />
              </Col>
              <Col xl={8} className="flexCenter">
                <SkeletonComponent circle={true} width={40} height={40} />
              </Col>
            </Col>
          </Col>
        </Col>
      );
    }
    return template;
  };

  renderCards = (cards) => {
    const { activePermisision } = this.props;
    return cards.map((card, i) => (
      <Col
        xl={{ span: 8 }}
        lg={{ span: 8 }}
        style={{ padding: "10px" }}
        className="card-hover"
      >
        {!checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) &&
          !checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE) && (
            <div className="disabledCard"></div>
          )}
        <Link
          to={card.url}
          className="btn btn-img"
          style={{ margin: 5 }}
          // onClick={() => this.props.history.push(card.url)}
        >
          <div className="details_container">
            <div className="title_amount">
              <span className="title">{card.key}</span>
              <span className="amount">
                &#8377; {PriceFormat(card.value || "0.00")}
              </span>
            </div>
          </div>
          <div className="image_container">
            <img
              className="image"
              src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
            />
          </div>
        </Link>
      </Col>
    ));
  };

  render() {
    const {
      postProductionBudget,
      preProductionBudget,
      vendorBudget,
      totalBudget,
      productionBudget,
      totalBudgetRoundOff,
      travelAndLodgeingBudget,
      castTechniciansBudget,
      totalBudgetRoundOffInWords,
      dataFetched,
    } = this.state;

    const { activePermisision, getPermissions } = this.props;

    const cards = [
      {
        key: "Pre-Production",
        value: preProductionBudget && preProductionBudget,
        url: "/project/pre-production",
      },
      {
        key: "Production",
        value: productionBudget && productionBudget,
        url: "/project/production",
      },
      {
        key: "Post-Production",
        value: postProductionBudget && postProductionBudget,
        url: "/project/post-production",
      },
      {
        key: "Travel & Lodging",
        value: travelAndLodgeingBudget && travelAndLodgeingBudget,
        url: "/project/travel-lodgeing",
      },
      {
        key: "Cast & Technicians",
        value: castTechniciansBudget && castTechniciansBudget,
        url: "/project/casttechnicians",
      },
      {
        key: "Vendor",
        value: vendorBudget && vendorBudget,
        url: "/project/vendor",
      },
    ];

    return (
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Budget </TitleText>
          </Title>
          <br />
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Fragment>
            <div style={{ textAlign: "center" }}>
              {this.state.dataFetched && (
                <p>
                  <span style={{ fontWeight: 600, fontSize: 20 }}>
                    BUDGET ESTIMATION ( &#8377;{" "}
                    {PriceFormat(this.calculateTotal(totalBudget))} )
                  </span>

                  <div style={{ fontSize: 18, fontWeight: "bold" }}>
                    {`Rupees ${this.amountNumberToText()}`}
                    {!dataFetched && <SkeletonComponent width={400} />}
                  </div>
                </p>
              )}
            </div>
            <Row type="flex" justify="center">
              <Col xl={18}>
                <Row>
                  {dataFetched
                    ? this.renderCards(cards)
                    : this.renderSkeletonCards()}
                  {/* <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ padding: "10px" }}
                className="card-hover"
              >
                <Link
                  to="/project/pre-production"
                  className="btn btn-img"
                  style={{ margin: 5 }}
                >
                  <div className="details_container">
                    <div className="title_amount">
                      <span className="title">Pre-Production</span>
                      <span className="amount">
                        &#8377; {preProductionBudget}
                      </span>
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      className="image"
                      src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ padding: "10px" }}
              >
                <Link
                  to="/project/production"
                  className="btn btn-img"
                  style={{ margin: 5 }}
                >
                  <div className="details_container">
                    <div className="title_amount">
                      <span className="title">Production</span>
                      <span className="amount">&#8377; {productionBudget}</span>
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      className="image"
                      src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ padding: "10px" }}
              >
                <Link
                  to="/project/post-production"
                  className="btn btn-img"
                  style={{ margin: 5 }}
                >
                  <div className="details_container">
                    <div className="title_amount">
                      <span className="title"> Post-Production</span>
                      <span className="amount">
                        &#8377; {postProductionBudget}
                      </span>
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      className="image"
                      src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ padding: "10px" }}
              >
                <Link
                  to="/project/travel-lodgeing"
                  className="btn btn-img"
                  style={{ margin: 5 }}
                >
                  <div className="details_container">
                    <div className="title_amount">
                      <span className="title">Travel & Lodging</span>
                      <span className="amount">
                        &#8377; {travelAndLodgeingBudget}
                      </span>
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      className="image"
                      src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ padding: "10px" }}
              >
                <Link
                  to="/project/casttechnicians"
                  className="btn btn-img"
                  style={{ margin: 5 }}
                >
                  <div className="details_container">
                    <div className="title_amount">
                      <span className="title">CAST & TECHNICIANS</span>
                      <span className="amount">
                        &#8377; {castTechniciansBudget}
                      </span>
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      className="image"
                      src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                style={{ padding: "10px" }}
              >
                <Link
                  to="/project/vendor"
                  className="btn btn-img"
                  style={{ margin: 5 }}
                >
                  <div className="details_container">
                    <div className="title_amount">
                      <span className="title">Vendor</span>
                      <span className="amount">&#8377; {vendorBudget}</span>
                    </div>
                  </div>
                  <div className="image_container">
                    <img
                      className="image"
                      src="https://img.icons8.com/cotton/64/000000/rupee--v1.png"
                    />
                  </div>
                </Link>
              </Col> */}
                </Row>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BudjeterMain);
