import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "antd";
import LocationScenesTable from "./LocationScenesTable";

const { Panel } = Collapse;

const LocationAccordion = ({ location, scheduleDate }) => {
  const [OpenPanel, setOpenPanel] = useState(["0"]);
  const handleAccordionChange = (OpenPanel) => {
    setOpenPanel(OpenPanel);
  };

  const PanelHeader = ({ label, value }) => (
    <Col xl={12}>
      <Col xl={7}>{label}</Col>
      <Col xl={2}>:</Col>
      <Col xl={11}>{value}</Col>
    </Col>
  );

  const header = (locationName, actualLocationName) => {
    return (
      <Row>
        <Col xl={24}>
          <PanelHeader label="Location Name" value={locationName} />
          <PanelHeader label="Actual Location" value={actualLocationName} />
        </Col>
      </Row>
    );
  };

  const Address = ({ value }) => <span>{value && value}</span>;

  const panelLengh = (length) => {
    let temp = [];
    for (let i = 0; i < length; i++) {
      temp.push(i.toString());
    }
    return temp;
  };

  const addressText = (address) => {
    return (
      address && (
        <div className="commaContainer">
          <Address value={address && address.addressLine} />
          <Address value={address && address.city} />
          <Address value={address && address.country} />
          <Address value={address && address.state} />
          <Address value={address && address.zipCode} />
        </div>
      )
    );
  };

  let locations = location;
  return (
    location &&
    location.map((location, i) => {
      const {
        locationName,
        actualLocationName,
        contactNumber,
        contactPerson,
        address,
        scenes,
        comments,
      } = location;
      return (
        <Collapse
          // accordion
          activeKey={panelLengh(locations.length)}
          destroyInactivePanel
          onChange={handleAccordionChange}
        >
          <Panel
            // header={`Location Name: ${locationName} Actual Location Name: ${actualLocationName} Contact Number: ${contactNumber}`}
            header={header(locationName, actualLocationName)}
            key={i}
            className="accordion_style"
          >
            <Row style={{ padding: 27, paddingTop: 10, paddingRight: 0 }}>
              <Col xl={24}>
                <PanelHeader label="Contact Number" value={contactNumber} />
                <PanelHeader label="Contact Person" value={contactPerson} />
                <PanelHeader label="Address" value={addressText(address)} />
                <PanelHeader label="Notes" value={comments} />
              </Col>
              <Col xl={24} style={{ paddingTop: 10 }}>
                <LocationScenesTable
                  scheduleDate={scheduleDate}
                  dataSource={scenes}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      );
    })
  );
};

export default LocationAccordion;
