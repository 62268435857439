import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class Accounting {
  services(type, data, id, AccType) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    switch (type) {
      case Services.AccountingVariables.GET_PRODUCTION_DATA_WISE_MORE:
        let { productionCategoryDepartment, category, description } = data;
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/payments?category=${category}&description=${description}&productionCategoryDepartment=${productionCategoryDepartment}`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_INVOICES:
        return axios.get(
          `${getScriptAPIURL()}/project/invoices?category=${
            data.category
          }&description=${data.description}&productionCategoryDepartment=${
            data.productionCategoryDepartment
          }&projectId=${projectId}`,
          config
        );

      case Services.AccountingVariables.GET_INVOICE_NUMBERS:
        return axios.get(
          `${getScriptAPIURL()}/project/invoices?category=PRODUCTION&description=asd&productionCategoryDepartment=LOCATION&projectId=${projectId}`,
          config
        );

      case Services.AccountingVariables.GET_ACCOUNT_ESTIMATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/accounts`,
          config
        );

      case Services.AccountingVariables.SAVE_PRE_PRODUCTION:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/account`,
          data,
          config
        );

      case Services.AccountingVariables.UPDATE_PRE_PRODUCTION:
        return axios.patch(`${getScriptAPIURL()}/account/${id}`, data, config);

      case Services.AccountingVariables.GET_PRE_PRODUCTION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account?category=${data}`,
          config
        );

      case Services.AccountingVariables.GET_ART_SET_WITH_SHOOT_DATES:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/accounts/details?category=${data}`,
          config
        );

      case Services.AccountingVariables.GET_ART_SET_WITH_SHOOT_DATES_NEW:
        return axios.get(
          `${getScriptAPIURL()}/production-payment-summary?projectId=${projectId}&productionDepartment=${
            data?.department
          }&isAdvance=${data?.isAdvance}`,
          config
        );

      case Services.AccountingVariables.GET_PAYMENT_DESCRIPTION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/payment-descripion?category=${
            data?.category
          }&department=${data?.department}&shootDate=${data?.shootDate}`,
          config
        );
      case Services.AccountingVariables.GET_PAYEE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/legal-agreement?category=${
            data?.category
          }&legalAgreementType=${data?.agreementType}&categoryDepartment=${
            data?.categoryDepartment
          }`,
          config
        );

      case Services.AccountingVariables.LEGAL_PAYEE_AMOUNT:
        return axios.get(
          `${getScriptAPIURL()}/legal-payee/${
            data?.payeeId
          }/amount?billAmount=${data?.value}`,
          config
        );

      case Services.AccountingVariables.DELETE_PRE_PRODUCTION:
        return axios.delete(`${getScriptAPIURL()}/account/${id}`, config);

      case Services.AccountingVariables.GET_PRODUCTION_MORE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/payments?category=${
            data.category
          }&description=${data.description}&productionCategoryDepartment=${
            data.productionCategoryDepartment
          }&department=${data.department}&isAdvance=${data.isAdvance || false}`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_MORE_FOR_LOCATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/payments?shootDate=${
            data.shootDate || ""
          }&category=${data.category}&description=${
            data.description
          }&productionCategoryDepartment=${
            data.productionCategoryDepartment
          }&department=${data.department}${
            data.productionAccountId &&
            `&productionAccountId=${data.productionAccountId}`
          }&isAdvance=${data.isAdvance}`,
          config
        );

      case Services.AccountingVariables.GET_PRE_PRODUCTION_MORE_ACTOR:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/actor/payments?shootDate=${
            data.shootDate
          }&isAdvance=${data.isAdvance}`,
          config
        );
      case Services.AccountingVariables.GET_PRE_PRODUCTION_MORE_ACTOR_NEW:
        return axios.get(
          `${getScriptAPIURL()}/production-payment?projectId=${projectId}&category=${
            data?.category
          }&productionCategoryDepartment=${
            data?.productionCategoryDepartment
          }&size=0&page=0&isAdvance=${data?.isAdvance}&shootDate=${
            data?.shootDate
          }&sort=vocherNumber,desc`
        );
      case Services.AccountingVariables
        .GET_PRE_PRODUCTION_MORE_ACTOR_NEW_ADVANCED:
        return axios.get(
          `${getScriptAPIURL()}/production-payment?projectId=${projectId}&category=${
            data?.category
          }&size=0&page=0&isAdvance=${data?.isAdvance}&payeeId=${
            data?.payeeId
          }&sort=vocherNumber,desc`
        );

      case Services.AccountingVariables.GET_PRE_PRODUCTION_MORE_ACTOR_ADVANCE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/actor/payments?category=PRODUCTION&characterType=${
            data.characterType
          }&description=${data.actorName}&isAdvance=true`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_ART_SET_MORE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/payments?category=${
            data.category
          }&department=${data.department}&shootDate=${
            data.shootDate
          }&isAdvance=${data.isAdvance || false}`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_TRANSPORT_MORE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/payments?category=${
            data.category
          }&department=${data.department}&shootDate=${
            data.shootDate
          }&productionCategoryDepartment=TRANSPORT`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_ART_SET_MORE_DESCRIPTION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/descriptions?category=${
            data.category
          }&department=${data.department}&shootDate=${data.shootDate}`,
          config
        );

      case Services.AccountingVariables.GET_PRE_PRODUCTION_MORE:
        return axios.get(
          `${getScriptAPIURL()}/production-payment`,
          { params: data },
          config
        );

      case Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE:
        return axios.post(
          `${getScriptAPIURL()}/production-payment`,
          data,
          config
        );

      case Services.AccountingVariables.UPDATE_PRE_PRODUCTION_MORE:
        return axios.patch(
          `${getScriptAPIURL()}/account/payments/${id}`,
          data,
          config
        );

      case Services.AccountingVariables.DELETE_PRE_PRODUCTION_MORE:
        return axios.delete(
          `${getScriptAPIURL()}/production-payment/${id}`,
          config
        );

      case Services.AccountingVariables.GET_PAYEE_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/payee?category=${data}`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_DATE_MORE_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/account/payments/${data}`,
          config
        );

      case Services.AccountingVariables.GET_PAYEE_BY_ID:
        return axios.get(`${getScriptAPIURL()}/payees/${data}/details`, config);

      case Services.AccountingVariables.GET_PAYEE_CATEGORY_WISE:
        return data.category === "PRODUCTION"
          ? axios.get(
              `${getScriptAPIURL()}/projects/${projectId}/payee?category=${
                data.category
              }&description=${data.description}&productionDepartment=${
                data.productionDepartment
              }`,
              config
            )
          : axios.get(
              `${getScriptAPIURL()}/projects/${projectId}/payee?category=${
                data.category
              }&description=${data.description}`,
              config
            );
      case Services.AccountingVariables.GET_CURRENT_PAYEE:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/payees/details?payeeId=${data}`,
          config
        );
      case Services.AccountingVariables.GET_PREVIOUS_PAYEE:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/payees/details?phoneNumber=${data}`,
          config
        );

      case Services.AccountingVariables.GET_SUB_CATEGORY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/subcategories?category=${data}`,
          config
        );

      case Services.AccountingVariables.GET_PRODUCTION_SUB_CATEGORY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/account/production/subcategories?category=${
            data.category
          }&categoryDepartment=${
            data.categoryDepartment
          }&productionDepartment=${data.productionDepartment}`,
          config
        );

      case Services.AccountingVariables.GET_PAYEE_DD:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/payee?category=${
            data.category
          }&description=${data.description}&productionDepartment=${
            data.productionDepartment
          }`,
          config
        );

      case Services.AccountingVariables.GET_PAYEE_DD_WITHOUT_DEPT:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/payee?category=${
            data.category
          }&description=${data.description}`,
          config
        );

      case Services.AccountingVariables.SAVE_PAYEE:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/payee`,
          data,
          config
        );

      case Services.AccountingVariables.UPDATE_PAYEE:
        return axios.put(
          `${getScriptAPIURL()}/project/payee/${id}`,
          data,
          config
        );

      case Services.AccountingVariables.SAVE_PRODUCTION_DATA_MORE:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/account/production/payments`,
          data,
          config
        );

      case Services.AccountingVariables.SAVE_LOCATION:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/account/locations`,
          data,
          config
        );

      default:
        break;
    }
  }
}
