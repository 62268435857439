import React, { Component, Fragment } from "react";
import { Tabs, Row, Col } from "antd";
import StandardEquipment from "../StandardEquipment";
import SpecialEquipment from "../SpecialEquipment";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { GlobalConstants } from "constants/AppConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import { checkFor } from "utilities/ValidationHelper";

const { TabPane } = Tabs;

class EquipmentTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultActiveKey: localStorage.getItem(GlobalConstants.FROM_DASHBOARD)
        ? "2"
        : "1",
    };
  }

  componentDidMount() {
    localStorage.removeItem(GlobalConstants.FROM_DASHBOARD);
  }

  render() {
    const { defaultActiveKey } = this.state;
    const { activePermisision } = this.props;
    return (
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Equipment </TitleText>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row style={{ padding: 10 }}>
            <Col>
              <Tabs defaultActiveKey={defaultActiveKey} destroyInactiveTabPane>
                <TabPane tab="Special Equipment" key="1">
                  <SpecialEquipment {...this.props} />
                </TabPane>
                <TabPane tab="Standard Equipment" key="2">
                  <StandardEquipment />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.CREW, menus),
  };
};

export default connect(mapStateToProps, null)(EquipmentTab);
