import React from "react";

const TitleText = ({ children }) => {
  return (
    <span
      style={{
        color: "#fff",
        margin: 0,
        fontWeight: "bold",
        fontSize: 16,
      }}
    >
      {children}
    </span>
  );
};

export default TitleText;
