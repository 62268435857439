import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button, Icon, Input, Tag, Switch } from "antd";

import TooltipIcon from "components/shared/TooltipIcon";
import { MenusRoute, GlobalConstants, Services } from "constants/AppConstants";
import { Link } from "react-router-dom";
import Accounting from "services/AccountingServices";
import RecordNotFound from "components/shared/RecordNotFound";
import moment from "moment";
import AccProductionAddEntry from "./AccProductionAddEntry";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { connect } from "react-redux";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, ReplaceWithSpace } from "utilities/ValidationHelper";
import AccountingService from "services/AccountingService";

const { Search } = Input;

const AccProductionActorTable = (props) => {
  const [DataSource, setDataSource] = useState([]);
  const [AdvanceDataSource, setAdvanceDataSource] = useState([]);
  const [SearchKey, setSearchKey] = useState(null);
  const [IsLoading, setIsLoading] = useState(true);

  const [switchActive, setSwitchActive] = useState(false);
  let filteredDataSource = [];
  const account = new AccountingService();
  const Account = new Accounting();
  const { activePermisision } = props;
  useEffect(() => {
    fetchData();
    getAdvancePaymentData();
  }, []);

  const handleSearch = (key) => {
    setSearchKey(key);
  };

  filteredDataSource = SearchKey
    ? switchActive
      ? AdvanceDataSource.filter((data) =>
          data.description.toLowerCase().includes(SearchKey)
        )
      : DataSource.filter((data) =>
          data.description.toLowerCase().includes(SearchKey)
        )
    : switchActive
    ? AdvanceDataSource
    : DataSource;

  const fetchData = () => {
    Account.services(
      Services.AccountingVariables.GET_ART_SET_WITH_SHOOT_DATES_NEW,
      {
        department: GlobalConstants.ACTOR_CAPS,
        isAdvance: switchActive,
      }
    )
      .then((res) => {
        setDataSource(res.data.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getAdvancePaymentData = () => {
    account
      .accountingService(
        Services.AccountingVariables.GET_PRODUCTION_DATA_ADVANCE_PAYMENT,
        {
          location: GlobalConstants.ACTOR_CAPS,
          isAdvance: true,
        }
      )
      .then((res) => {
        setAdvanceDataSource(res.data.content);
      });
  };

  const columns = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shoot date",
      dataIndex: "shootDate",
      key: "shootDate",
      width: "20%",
      render: (text) => <Tag className="statusTag">{text}</Tag>,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      align: "right",
      width: "20%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns.push({
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <>
          {console.log(record)}
          <TooltipIcon
            onClick={() =>
              props.history.push({
                pathname: `${MenusRoute.ACCOUNTING}/production/actor/${record.shootDate}`,
                isAdvance: false,
                state: {
                  title: `Actor ${record.shootDate}`,
                  details: {
                    productionCategoryDepartment:
                      GlobalConstants.PRODUCTION_CAPS,
                    department: GlobalConstants.ACTORS,
                    category: GlobalConstants.PRODUCTION_CAPS,
                    categoryDepartment: GlobalConstants.ACTOR,
                  },
                },
              })
            }
            icon="more"
          />
        </>
      ),
    });
  }

  const columns1 = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Char Name",
      dataIndex: "characterName",
      key: "charName",
      width: "18%",
    },
    {
      title: "Actor Name",
      dataIndex: "actorName",
      key: "actorName",
      width: "18%",
    },
    {
      title: "Payee Name",
      dataIndex: "actorName",
      key: "actorName",
      width: "10%",
      render: (text, record) => (
        <>
          {record?.legalAgreement?.partyName ? (
            <span>{record?.legalAgreement?.partyName}</span>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Character Type",
      dataIndex: "charcterType",
      key: "charType",
      width: "10%",
      render: (text) => <Tag className={`statusTag ${text}`}>{text}</Tag>,
    },
    {
      title: "Agreed amount",
      dataIndex: "agreedAmount",
      key: "agreedAmount",
      width: "10%",
      align: "right",
    },
    {
      title: "Payment type",
      dataIndex: "paymentType",
      key: "paymentType",
      width: "10%",
      render: (text) => <Tag className={`statusTag ${text}`}>{text}</Tag>,
    },
    {
      title: "Advance Amount",
      dataIndex: "advancePayment",
      key: "advancePayment",
      align: "right",
      width: "10%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns1.push({
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() => {
            if (record?.legalAgreement?.partyName) {
              props.history.push({
                pathname: `${MenusRoute.ACCOUNTING}/production/actor/advance`,
                state: {
                  title: `Actor ${moment(record.shootDate).format(
                    GlobalConstants.DD_MM_YYYY
                  )}`,
                  isAdvance: switchActive,
                  characterType: record.charcterType,
                  actorName: record.actorName,
                  details: {
                    productionCategoryDepartment:
                      GlobalConstants.PRODUCTION_CAPS,
                    department: GlobalConstants.ART_SET_CAPS,
                    category: GlobalConstants.PRODUCTION_CAPS,
                    categoryDepartment: record.categoryDepartment,
                    isAdvance: switchActive,
                    characterType: record.charcterType,
                    actorName: record.actorName,
                  },
                  record: record,
                },
              });
            }
          }}
          placement={"left"}
          style={{
            color: record?.legalAgreement?.partyName ? "#ED556A" : "gray",
          }}
          titleText={
            !record?.legalAgreement?.partyName ? "More Disabled" : "More"
          }
          icon={"more"}
        />
      ),
    });
  }

  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Row style={{ padding: 10 }}>
      <div style={{ textAlign: "center", marginBottom: 10 }}>
        <span>
          {" "}
          Shoot date{" "}
          <Switch
            onChange={(record) => {
              console.log(record);
              setSwitchActive(record);
            }}
          />{" "}
          Contract Payment{" "}
        </span>
      </div>

      <Col>
        {switchActive ? (
          <Table
            dataSource={filteredDataSource}
            columns={columns1}
            bordered
            // scroll={{ y: "50vh" }}
            pagination={false}
            loading={IsLoading}
            locale={{
              emptyText: !IsLoading && <RecordNotFound />,
            }}
          />
        ) : (
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            bordered
            // scroll={{ y: "50vh" }}
            pagination={false}
            loading={IsLoading}
            locale={{
              emptyText: !IsLoading && <RecordNotFound />,
            }}
          />
        )}
      </Col>
    </Row>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

export default connect(mapStateToProps, null)(AccProductionActorTable);
