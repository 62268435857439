import React, { Component, Fragment } from "react";
import { Row, Col, DatePicker, Tabs } from "antd";
import moment from "moment";

import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import { Label } from "components/shared/Label";

import "./accountOverview.scss";

import BarChart from "components/shared/ChartJs/Bar";
import AccountOverviewTabs from "./AccountOverViewTabs";
import { SceneOVerviewVariable } from "constants/AppConstants/ServicesConstants";

import { ScriptService } from "services";
import DashboardServices from "services/DashboardServices";

const { RangePicker } = DatePicker;

const dateFormat = "DD-MM-YYYY";
const ar = [
  "PRODUCTION",
  "ART_SET",
  "EQUIPMENT",
  "COSTUME_MAKEUP",
  "SONG_STUNT",
];

class AccountOverview extends Component {
  state = {
    SelectedTab: "1",
    detailedViewData: [],
    detailedViewchartData: {},
    startDate: null,
    endDate: null,
  };
  constructor(props) {
    super(props);
    this._scriptService = new ScriptService();
    this._dashboardService = new DashboardServices();
  }

  setActiveTab = (SelectedTab) => {
    this.setState({
      SelectedTab,
    });
  };

  componentDidMount() {
    this.getDetailedViewChartData();
    if (this.state.SelectedTab == 6) {
      this.getDetailedViewDataCategory();
    } else {
      this.getDetailedViewData(ar[this.state.SelectedTab - 1]);
    }
  }

  getDetailedViewData = (key) => {
    this._dashboardService
      .service(SceneOVerviewVariable.GET_DETAILED_VIEW_DATA, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        dept: key,
        category: "PRODUCTION",
      })
      .then((res) => {
        this.setState({ detailedViewData: res.data.payments || [] });
      })
      .catch((err) => {
        this.setState({ detailedViewData: [] });
      });
  };

  getDetailedViewDataCategory = () => {
    const data = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      category: "PRODUCTION_PURCHASE",
    };
    this._dashboardService
      .service(SceneOVerviewVariable.GET_DETAILED_VIEW_DATA_CATEGORY, data)
      .then((res) => {
        this.setState({ detailedViewData: res.data.payments || [] });
      })
      .catch((err) => {
        this.setState({ detailedViewData: [] });
      });
  };

  getDetailedViewChartData = () => {
    console.log(moment(this.state.startDate, dateFormat));
    console.log(this.state.startDate);
    console.log(this.state.endDate);
    this._dashboardService
      .service(SceneOVerviewVariable.GET_DETAILED_CHART_DATA, {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        type: "bar",
      })
      .then((res) => {
        this.setState({
          detailedViewchartData: res.data || [],
        });
      });
  };

  render() {
    const {
      SelectedTab,
      startDate,
      endDate,
      detailedViewData,
      detailedViewchartData,
    } = this.state;

    console.log({ detailedViewData });
    const barChartData = {};
    return (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Accounting Overview</TitleText>
          </Title>
        </Row>
        <Row type="flex" justify="center">
          <Col xl={18}>
            <Row style={{ padding: 10 }} type="flex" align="middle">
              <Col xl={3}>
                <Label style={{ float: "right", fontSize: "1rem" }}>
                  Pick Dates
                </Label>
              </Col>
              <Col xl={18} style={{ paddingLeft: 5 }}>
                <RangePicker
                  style={{ width: 270 }}
                  className="customPicker"
                  // defaultValue={[
                  //   moment(startDate, dateFormat),
                  //   moment(endDate, dateFormat)
                  // ]}
                  onChange={(value, record) => {
                    console.log(value, record);
                    this.setState(
                      {
                        startDate: record && record[0],
                        endDate: record && record[1],
                      },
                      () => {
                        var that = this;
                        that.componentDidMount();
                      }
                    );
                    // this.getDetailedViewData();
                  }}
                  format={dateFormat}
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ width: "100%", height: 300 }}
                className="accountOverviewBarChart"
              >
                <BarChart height={"100%"} data={detailedViewchartData} />
                {/* <BarChart data={detailedViewchartData}
                  handleClick={data => this.setActiveTab(data.index + 1)}
                /> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Col xl={24}>
            <AccountOverviewTabs
              SelectedTab={SelectedTab}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              detailedViewData={detailedViewData}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default AccountOverview;
