import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Button, Col } from "antd";
import { FaGripHorizontal, FaBars } from "react-icons/fa";

import CastTechnicianscard from "../CastTechnicianscard";
import CastTechnicianslist from "../CastTechnicianslist";
import ButtonGroup from "antd/lib/button/button-group";
import BudjetService from "services/BudjetService";
import { Services } from "constants/AppConstants";
import { setActiveComponentCast } from "store/action";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";

class CastTechnicians extends Component {
  constructor(props) {
    super(props);
    this._budgetService = new BudjetService();
    this.state = {
      isActive: props.listTypeCast,
      dataSource: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handlePageType = (value) => {
    this.setState(
      {
        isActive: value,
      },
      () => this.props.setActiveComponentCast(this.state.isActive)
    );
  };

  fetchData = () => {
    this._budgetService
      .budjetService(Services.BudjetVariables.GET_CAST_TECNICIANS)
      .then((res) => {
        this.setState({
          dataSource: res.data.castTechnicians || [],
        });
      })
      .catch((err) => {});
  };

  render() {
    const { isActive, dataSource } = this.state;

    return (
      <Fragment>
        <Row className="cast-technicians-layout">
          <Col xs={24} style={{ paddingBottom: 20 }}>
            <Title hasBack {...this.props}>
              <TitleText>Cast & Technicians </TitleText>
            </Title>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xl={20}>
            <Row>
              <ButtonGroup style={{ float: "right" }}>
                <Button
                  type={isActive === "grid" ? "primary" : "default"}
                  // size="default"
                  onClick={() => {
                    this.handlePageType("grid");
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaGripHorizontal />
                  </div>
                </Button>
                <Button
                  type={isActive === "list" ? "primary" : "default"}
                  // size="default"
                  onClick={() => {
                    this.handlePageType("list");
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaBars />
                  </div>
                </Button>
              </ButtonGroup>
            </Row>
            {isActive === "grid" ? (
              <CastTechnicianscard dataSource={dataSource} {...this.props} />
            ) : (
              <CastTechnicianslist dataSource={dataSource} {...this.props} />
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ activeItems, user: { menus } }) => {
  return {
    listTypeCast: activeItems.listTypeCast,
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveComponentCast: (activeType) =>
      dispatch(setActiveComponentCast(activeType)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CastTechnicians);
