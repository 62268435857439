import React, { useState, useEffect } from "react";
import GlobalService from "services/GlobalService";
import { Services, GlobalConstants } from "constants/AppConstants";
import { Select } from "antd";

const { Option } = Select;

const AccPaymentModeDD = ({
  text,
  record,
  onChange,
  type,
  disabled,
  fieldName,
  className,
  dataSource = null,
}) => {
  const [PaymentTypeData, setPaymentTypeData] = useState([]);
  const api = new GlobalService();

  useEffect(() => {
    if (!dataSource) {
      getPaymentTypeData();
    } else {
      setPaymentTypeData(dataSource);
    }
  }, [type, text]);

  const getPaymentTypeData = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        setPaymentTypeData(res.data);
      });
  };
  return (
    PaymentTypeData && (
      <Select
        style={{ width: "100%" }}
        className={`border-none upperCase ${className}`}
        placeholder="Select type"
        disabled={disabled}
        onChange={(value) => onChange(value, record, fieldName)}
        value={text ? text.toUpperCase() : ""}
        // disabled={record.type !== "SPECIAL_PROPERTY"}
      >
        {PaymentTypeData &&
          PaymentTypeData.map((option, i) => (
            <Option key={i} value={option.value}>
              {option.label}
            </Option>
          ))}
      </Select>
    )
  );
};

export default AccPaymentModeDD;
