import React, { Component } from "react";
import { Modal, Button, Form, Input, Row, Col, Select } from "antd";
import "./AddCharacter.css";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import { Services, Notifications, LocalStorage } from "constants/AppConstants";
import { showNotifications } from "../NotificationComponent";
import { numberOnly } from "utilities/ValidationHelper";
import GlobalService from "services/GlobalService";
import { ScriptService } from "services";
import { connect } from "react-redux";
import { getNotificationCount } from "store/thunk-actions";

const { Option } = Select;

class AddCharacter extends Component {
  state = {
    visible: false,
    addCharacter: false,
    characterName: null,
    characterType: null,
    imageId: null,
    sceneId: null,
    characterCount: null,
    actorName: null,
    isLoading: false,
    rate: null,
    characterNameList: [],
    CharacterList: [],
    paymentType: null,
    dropDown: null,
    activeSceneId: null
  };

  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
    this._globalService = new GlobalService();
    this.scriptservice = new ScriptService();
  }

  componentDidMount() {
    const { type } = this.props;
    this.setState({
      characterType: type
      // sceneId: 1
    });
    this.getCharacterTypeData();
    this.getPaymentType();
  }

  componentWillReceiveProps(nextProps) {
    // console.log("nextProps", nextProps.characterName);
    if (nextProps.characterName !== this.state.characterNameList) {
      this.setState(
        {
          characterNameList: nextProps.characterName,
          activeSceneId: nextProps.activeSceneId
        }
        // () => this.getCharacterName(nextProps.characterName)
      );
    }
  }

  getCharacterTypeData() {
    let names = [];
    const characterTypeData = this.props.characterTypeData || [];
    characterTypeData.forEach((a) => {
      names.push(a.characterName);
    });
    this.getCharacterName(names);
  }

  getPaymentType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "PAYMENT_TYPE")
      .then((res) => {
        this.setState(
          {
            dropDown: res.data
          },
          () => console.log("dropdown...", this.state.dropdown)
        );
      });
  };

  getCharacterName = (names = null) => {
    const { type } = this.props;
    console.log("names", names);
    if (type !== "JUNIOR") {
      this._actorService
        .actorTabService(
          Services.SceneOVerviewVariable.GET_CHARC_NAME,
          names || this.state.characterNameList,
          type
        )
        .then((res) => {
          this.setState({ CharacterList: res.data.characters });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true
        });
        console.log("Received values of form: ", values);
        const data = {
          characterName: this.state.characterName,
          // characters: this.state.characterName,
          characterType: this.state.characterType,
          imageId: this.state.imageId,
          sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
          characterCount: this.state.characterCount,
          rate: this.state.rate
        };

        this._actorService
          .actorTabService(Services.SceneOVerviewVariable.ADD_ACTOR, data)
          .then((res) => {
            this.setState({
              visible: false,
              characterName: null,
              imageId: null,
              actorName: null,
              isLoading: false
            });
            this.props.refreshActors();
            this.props.closeModal();
            this.props.getNotificationCount();
            showNotifications(
              Notifications.SUCCESS,
              "Successfully",
              "Created New Character!!"
            );
          })
          .catch((err) => {
            this.setState({
              isLoading: false
            });
            this.props.closeModal();
            this.props.getPermissions();
          });
      }
    });
  };

  handleJuniorSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true
        });
        console.log("Received values of form: ", values);
        const data = {
          characterName: this.state.characterName,
          characterType: "JUNIOR",
          sceneId: localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID),
          characterCount: this.state.characterCount,
          salary: this.state.rate,
          paymentType: values.paymentType
        };

        this._actorService
          .actorTabService(
            Services.SceneOVerviewVariable.ADD_JUNIOR_ACTOR,
            data
          )
          .then((res) => {
            this.setState({
              visible: false,
              characterName: null,
              imageId: null,
              actorName: null,
              isLoading: false
            });
            this.props.refreshActors();
            this.props.closeModal();
            this.props.getNotificationCount();
            showNotifications(
              Notifications.SUCCESS,
              "Successfully",
              "Created New Character!!"
            );
          })
          .catch((err) => {
            this.setState({
              isLoading: false
            });
            this.props.closeModal();
            this.props.getPermissions();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something went wrong!!"
            );
          });
      }
    });
  };

  onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = (blur) => {
    console.log("blur", blur);
  };

  onFocus = (focus) => {
    console.log("focus", focus);
  };

  onSearch = (search) => {
    console.log("search:", search);
  };

  showModal = () => {
    console.log("show");
    this.setState({
      visible: true
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: this.props.closeModal
    });
  };

  handleImage = (id) => {
    console.log(id);

    this.setState({
      imageId: id
    });
  };

  addCharacter = (e) => {
    console.log(e);
    this.setState({ addCharacter: true });
    console.log(this.state);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { type } = this.props;
    return (
      <div>
        <Modal
          destroyOnClose
          visible={this.props.isvisible}
          onCancel={this.props.closeModal}
          className="createModal"
          title="Add Character"
          footer={
            <Row
              gutter={12}
              type="flex"
              justify="end"
              style={{ marginTop: "20px" }}
            >
              <Col>
                <Button onClick={this.props.closeModal}>Cancel</Button>
              </Col>
              <Col>
                {this.props.type === "JUNIOR" ? (
                  <Button
                    type="primary"
                    onClick={this.handleJuniorSubmit}
                    loading={this.state.isLoading}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    loading={this.state.isLoading}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          }
        >
          {/* <h3 className="font-600 pb-20 text-center primary-font-color">
            Add Character
          </h3> */}
          {type !== "JUNIOR" ? (
            <div>
              <Form>
                {/* <Row type="flex" justify="center">
                  <div className="mt-5">
                    <Form.Item>
                      {getFieldDecorator("projectimage", {
                        initialValue: this.state.projectimage
                      })(
                        <UploadImage
                          handleImage={this.handleImage}
                          text="Upload Actor image"
                        />
                      )}
                    </Form.Item>
                  </div>
                </Row> */}
                <Row gutter={16} className="mt-5">
                  <Col xl={{ span: 18, offset: 3 }}>
                    <label className="font-600" label="Username">
                      *Character Name :
                    </label>
                    <Form.Item>
                      {getFieldDecorator("charactername", {
                        rules: [
                          {
                            required: true,
                            message: "Enter your Character Name!"
                          }
                        ]
                      })(
                        <Select
                          mode="tags"
                          style={{ width: "100%" }}
                          placeholder="Select Character Name"
                          onChange={(value) =>
                            this.setState({
                              characterName: value
                            })
                          }
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          tokenSeparators={[","]}
                        >
                          {this.state.CharacterList &&
                            this.state.CharacterList.map((option, i) => (
                              <Option value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col xl={{ span: 12 }}>
                    <label className="font-600">Actor Name </label>
                    <Form.Item>
                      {getFieldDecorator("actorname", {})(
                        <Input
                          placeholder="Actor Name"
                          onChange={e =>
                            this.setState({
                              actorName: e.target.value
                            })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col> */}
                </Row>
              </Form>
            </div>
          ) : (
            <Form handleSubmit={this.handleJuniorSubmit}>
              <Row gutter={16} className="mt-5">
                <Col xl={{ span: 12 }}>
                  <label className="font-600" label="Username">
                    *Character Name :
                  </label>
                  <Form.Item>
                    {getFieldDecorator("charactername", {
                      rules: [
                        {
                          required: true,
                          message: "Enter your Junior Artist Type!"
                        }
                      ]
                    })(
                      <Input
                        placeholder="Enter Junior Artist"
                        onChange={(e) => {
                          this.setState({ characterName: e.target.value });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 12 }}>
                  <label className="font-600">*Quantity </label>
                  <Form.Item>
                    {getFieldDecorator("quantity", {
                      rules: [
                        {
                          required: true,
                          message: "Enter quantity!"
                        }
                      ]
                    })(
                      <Input
                        className="w-100"
                        placeholder="Quantity"
                        onChange={(e) => {
                          this.setState({ characterCount: numberOnly(e) });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={{ span: 12 }}>
                  <label className="font-600">Rate </label>
                  <Form.Item>
                    {getFieldDecorator("rate")(
                      <Input
                        className="w-100"
                        placeholder="Rate"
                        onChange={(e) => {
                          this.setState({ rate: numberOnly(e) });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>

                <div>
                  <Col xl={{ span: 12 }}>
                    <label className="font-600">Payment Type </label>
                    <Form.Item>
                      {getFieldDecorator("paymentType", {
                        initialValue: this.state.paymentType || undefined,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter PaymentType!"
                          }
                        ]
                      })(
                        <Select
                          placeholder="Payment Type"
                          style={{ width: "100%" }}
                        >
                          {this.state.dropDown &&
                            this.state.dropDown.map((option, i) => (
                              <Option value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col xl={{ span: 1 }}>
                      <Icon type="minus-circle" />
                    </Col> */}
                </div>
              </Row>
            </Form>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ scene }) => {
  return {
    activeSceneId: scene.activeSceneId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationCount: () => dispatch(getNotificationCount())
  };
};

const CreateCharacter = Form.create()(AddCharacter);

export default connect(mapStateToProps, mapDispatchToProps)(CreateCharacter);
