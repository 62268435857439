import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Select,
  DatePicker,
  Modal,
} from "antd";
import moment from "moment";

import {
  amountOnly,
  StartCase,
  ReplaceWithSpace,
  disableFutureDates,
} from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  MenusRoute,
  Services,
  Notifications,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { Link } from "react-router-dom";
import DeleteComponent from "components/shared/DeleteComponent";
import AccPaymentTypeDD from "../AccPaymentTypeDD";
import AccPaymentModeDD from "../AccPaymentModeDD";
import SharedDocumentUpload from "components/shared/SharedDocumentUpload";
import Accounting from "services/AccountingServices";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import InvoiceForm from "components/shared/InvoiceForm";
import TooltipIcon from "components/shared/TooltipIcon";
import GlobalService from "services/GlobalService";
import VoucherDownloadButton from "components/shared/VoucherDownloadButton";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Loader from "components/shared/Loader";
import PayeeForm from "components/shared/PayeeForm";

const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

const dateFormat = "DD-MM-YYYY";

class AccProductionCostumeMakeupMore extends Component {
  constructor(props) {
    super(props);
    this.account = new Accounting();
    this.api = new GlobalService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
      receiverNameList: [],
      expenseTypeData: [],
      receiverName: null,
      productionAccountId: null,
      hidePaymentType: false,
      isInvoiceVisible: false,
      category: props.location.state.details.category,
      isAdvance: props.location.state.details.isAdvance || false,
      fromTravel: false,
      details: props.location.state.details || false,
      title: props.location.state.title,
      activePayment: null,
      paymentTypeList: null,
      paymentModeList: null,
      shootDate: props.match.params.shootDate || null,
    };
  }

  componentDidMount() {
    this.fetchProductionData();
    this.getPayee();
    this.getPaymentTypeData();
    this.getPaymentModeData();
    this.fetchExpenseType();
    this.getDescriptionData();
  }

  getDescriptionData = () => {
    const {
      details: { category, department, categoryDepartment },
      shootDate,
    } = this.state;
    const data = {
      category,
      department,
      categoryDepartment,
      shootDate,
    };
    this.account
      .services(
        Services.AccountingVariables.GET_PRODUCTION_ART_SET_MORE_DESCRIPTION,
        data
      )
      .then((res) => {
        this.setState({
          descriptions: res.data.descriptions,
        });
      });
  };

  fetchProductionData = () => {
    const {
      details: { category, department, categoryDepartment },
      shootDate,
      isAdvance,
    } = this.state;
    const data = {
      category,
      department,
      categoryDepartment,
      shootDate,
    };
    let dataSource = [];
    this.account
      .services(Services.AccountingVariables.GET_PRODUCTION_ART_SET_MORE, data)
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: i,
              payee: data.receiverName || null,
              paymentDate: data.paymentDate,
              paymentType:
                (data.paymentType && data.paymentType.toLowerCase()) || null,
              paymentMode:
                (data.paymentMode && data.paymentMode.toLowerCase()) || null,
              shootDate: data.shootDate,
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
          count: res?.data?.payments?.length,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  fetchExpenseType = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EXPENSE_TYPE
      )
      .then((res) => {
        this.setState({
          expenseTypeData: res.data,
        });
      });
  };

  getPaymentTypeData = () => {
    const { details } = this.state;
    let type = details
      ? GlobalConstants.PRODUCTION_PAYMENT_TYPE
      : GlobalConstants.PRE_PRODUCTION_PAYMENT_TYPE;
    this.api
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, type)
      .then((res) => {
        this.setState({
          paymentTypeList: res.data,
        });
      });
  };

  getPaymentModeData = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        this.setState({
          paymentModeList: res.data,
        });
      });
  };

  getPayee = () => {
    const { category } = this.state;
    this.account
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN, category)
      .then((res) => {
        this.setState({
          receiverNameList: res.data,
        });
      })
      .catch((err) => {
        this.setState({});
      });
  };

  handleAdd = () => {
    const { count, dataSource, details, shootDate } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      payeeId: null,
      receiverName: null,
      amountPaid: null,
      paymentMode: null,
      referenceNumber: null,
      documents: [],
      comments: null,
      paymentDate: null,
      count: 0,
      description: details ? details.description : null,
      payee: null,
      isAmountSplit: false,
      expenseType: null,
      // itemQuantity: null,
      shootDate: shootDate,
      categoryDepartment: GlobalConstants.COSTUME,
    };

    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, productionPaymentId }) => {
    if (productionPaymentId) {
      this.account
        .services(
          Services.AccountingVariables.DELETE_PRE_PRODUCTION_MORE,
          AccountingVariables.PRE_PRODUCTION,
          productionPaymentId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchProductionData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  inputChange(value, record, type) {
    this.onChange(value, record, type);
  }

  onChange = (value, record, type) => {
    if (value !== "NaN" && value) {
      record[type] = value;
      record.count = parseInt(record.count);
      this.handleSave(record);
    }
    if (value === "" && type === "billAmount") {
      record[type] = null;
      this.handleSave(record);
    }
  };

  handleInvoiceNumber = (invoiceNumber, invoiceId, record) => {
    record["invoiceNumber"] = invoiceNumber;
    record["invoiceId"] = invoiceId;
    this.handleSave(record);
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  showWarning = (row) => {
    const that = this;
    confirm({
      title: "Payment more than 10,000 will be Splited to Multiple Payments",
      content: "This Action can't be revoke",
      okText: "Ok",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          that.handleSubmit({ ...row, isAmountSplit: true });
          resolve();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        that.handleSubmit({ ...row, isAmountSplit: false });
      },
    });
  };

  handleSubmit = (record) => {
    const {
      productionAccountId,
      category,
      details: { productionCategoryDepartment },
      shootDate,
      isAdvance,
    } = this.state;
    this.setState({
      isLoading: true,
    });
    const {
      amountPaid,
      comments,
      description,
      payeeId,
      paymentDate,
      paymentMode,
      paymentType,
      referenceNumber,
      receiverName,
      invoiceId,
      productionPaymentId,
      isAmountSplit,
      expenseType,
      // itemQuantity,
      categoryDepartment,
      billAmount,
    } = record;
    const data = {
      amountPaid,
      category,
      comments,
      department: GlobalConstants.COSTUME_AND_MAKEUP_CAPS,
      description,
      payeeId,
      paymentDate: moment(
        paymentDate,
        GlobalConstants.DD_MM_YYYY,
        true
      ).isValid()
        ? paymentDate
        : moment(paymentDate)
            // .utc()
            .format(GlobalConstants.DD_MM_YYYY),
      invoiceId,
      paymentMode: paymentMode.toUpperCase(),
      paymentType: paymentType && paymentType.toUpperCase(),
      productionAccountId,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      payeeName: receiverName,
      referenceNumber,
      paymentId: productionPaymentId,
      isAmountSplit,
      expenseType,
      // itemQuantity,
      shootDate,
      productionDepartment: GlobalConstants.COSTUME_AND_MAKEUP_CAPS,
      categoryDepartment: categoryDepartment || GlobalConstants.COSTUME,
      billAmount,
      isAdvance,
    };
    console.log("data", data);
    this.account
      .services(Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE, data)
      .then((res) => {
        // showActionMessage(
        //   GlobalConstants.SUCCESS,
        //   GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        // );
        this.setState({
          isLoading: false,
        });
        if (categoryDepartment) {
          this.fetchProductionData();
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        // if (categoryDepartment) {
        //   this.fetchProductionData();
        // } else {
        //   this.fetchData();
        // }
      });
  };

  checkForTravelData = (row) => {
    const { fromTravel } = this.state;
    if (fromTravel) {
      return row.expenseType;
      //  && row.itemQuantity;
    } else {
      return true;
    }
  };

  checkForProductionData = (row) => {
    const { details } = this.state;
    if (details) {
      return row.shootDate;
    } else {
      return true;
    }
  };

  checkForReference = (row) => {
    if (row.paymentMode.toUpperCase() === "CASH") {
      return true;
    } else {
      return row.referenceNumber;
    }
  };

  checkForPaymentType = (row) => {
    const { hidePaymentType } = this.state;
    if (hidePaymentType) {
      return true;
    } else {
      return row.paymentType;
    }
  };

  handleSave = (row) => {
    const { details } = this.state;
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // if (row.productionPaymentId) {
    if (
      row.description &&
      row.paymentDate &&
      row.paymentMode &&
      row.paymentType &&
      (row.receiverName || row.payeeId) &&
      row.amountPaid &&
      (row.invoiceId || row.billAmount) &&
      this.checkForReference(row) &&
      this.checkForProductionData(row)
    ) {
      if (
        parseFloat(row.amountPaid).toFixed(2) > 10000.0 &&
        row.paymentMode.toUpperCase() === "CASH"
      ) {
        this.showWarning(row);
      } else {
        this.handleSubmit(row);
      }
    }
    this.setState({ dataSource: newData });
    console.log("row", row);
  };

  handleReceiverName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          receiverName: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };
  handleDescriptionName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState(
        {
          descriptionValue: value,
        }
        // () => this.getArtSetDD(this.state.artSetKeyword, record)
      );
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handlePayee = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    record["receiverName"] = null;
    this.setState({
      receiverName: null,
    });
    this.handleSave(record);
  };

  handleDescriptionDD = (value, record, type) => {
    record[type] = value;
    this.setState({
      descriptionValue: null,
    });
    this.handleSave(record);
  };

  handleReceiver = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.receiverName);
      if (newData) {
        this.setState({ eventType });
        record.receiverName = newData;
        record.payeeId = null;
        record.payee = newData;
        this.onChange(newData, record, "payee");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };
  handleDescription = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.descriptionValue);
      if (newData) {
        this.setState({ eventType });
        record.description = newData;
        this.onChange(newData, record, "description");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  disabledDate = (current, toDate) => {
    const { shootDate } = this.state;
    // Can not select days before today and today
    if (current) {
      if (toDate) {
        return current < moment(toDate, dateFormat).add(1, "days");
      }
      return current < moment().startOf("day");
    }
  };

  handleInvoice = (activePayment) => {
    this.setState({
      activePayment,
      isInvoiceVisible: true,
      activeInvoice: activePayment.invoiceId,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isInvoiceVisible: false,
      activeInvoice: null,
    });
  };

  removeInvoice = (record) => {
    record["invoiceNumber"] = null;
    record["invoiceId"] = null;
    this.handleSave(record);
  };

  handlePayeeName = (payee, payeeId, record) => {
    record["payeeName"] = payee;
    record["payee"] = payee;
    record["payeeId"] = payeeId;
    this.handleSave(record);
  };

  handlePayeeModal = (activePayment) => {
    this.setState({
      activePayment,
      isPayeeVisible: true,
      activePayee: activePayment.payeeId,
    });
  };

  handleClosePayeeModal = () => {
    this.setState({
      isPayeeVisible: false,
      activePayee: null,
    });
  };

  removePayee = (record) => {
    record["payee"] = null;
    record["payeeId"] = null;
    record["payeeName"] = null;
    record["receiverName"] = null;
    this.handleSave(record);
  };

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      receiverNameList,
      productionAccountId,
      isInvoiceVisible,
      activePayment,
      activeInvoice,
      category,
      paymentTypeList,
      paymentModeList,
      fromTravel,
      title,
      details,
      hidePaymentType,
      descriptions,
      activePayee,
      isPayeeVisible,
    } = this.state;

    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    let columns = [
      {
        title: "#",
        align: "center",
        width: "3%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: "8%",
        render: (text, record) => (
          <DatePicker
            autoFocus={!record.productionPaymentId}
            defaultValue={text ? moment(text, dateFormat) : null}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "paymentDate")
            }
            className={`${!text ? "err" : ""}`}
            format={dateFormat}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
            disabledDate={(current) => disableFutureDates(current)}
          />
        ),
      },

      {
        title: "AMOUNT PAID",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: "8%",
        className: "cash",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            type="number"
            defaultValue={text}
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(amountOnly(e), record, "amountPaid")
            }
            onFocus={(e) => {
              e.currentTarget.select();
              this.setState({ activeValue: e.target.value });
            }}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "name",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(e.target.value, record, "description")
            }
            style={{ width: "100%" }}
            disabled={
              record.productionPaymentId ||
              (!checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId)
            }
            onFocus={(e) => {
              e.currentTarget.select();
              this.setState({ activeValue: e.target.value });
            }}
          />
          //   <Select
          //     style={{ width: "100%" }}
          //     showSearch
          //     className={`${!text ? "err" : ""}`}
          //     value={record.description}
          //     onChange={e => this.handleDescriptionDD(e, record, "description")}
          //     onBlur={e => this.handleDescription(e, record, "blur")}
          //     onSearch={val => this.handleDescriptionName(val, record)}
          //     disabled={
          //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
          //       record.productionPaymentId
          //     }
          //   >
          //     {descriptions &&
          //       descriptions.map(list => {
          //         return <Option value={list.value}>{list.label}</Option>;
          //       })}
          //   </Select>
        ),
      },
      {
        title: "Invoice",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        width: "7%",
        align: "center",
        render: (text, record) =>
          record.description &&
          !record.billAmount && (
            <>
              {text ? (
                checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
                  <>
                    <a onClick={() => this.handleInvoice(record)}>{text}</a>
                    <TooltipIcon
                      icon="delete"
                      titleText={GlobalConstants.REMOVE_INVOICE}
                      onClick={() => this.removeInvoice(record)}
                    ></TooltipIcon>
                  </>
                ) : (
                  <a>{text}</a>
                )
              ) : (
                <Button
                  onClick={() => this.handleInvoice(record)}
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={
                    !checkFor(
                      activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    ) && record.productionPaymentId
                  }
                >
                  <Icon type="plus" style={{ fontSize: "11px" }} /> Invoice
                </Button>
              )}
            </>
          ),
      },
      {
        title: "Bill Amount",
        dataIndex: "billAmount",
        key: "billAmount",
        width: "8%",
        align: "right",
        render: (text, record) =>
          !record.invoiceNumber && (
            <Input
              type="number"
              className={`${!text ? "err" : ""} upperCase cash`}
              defaultValue={text}
              onBlur={(e) =>
                this.state.activeValue !== e.target.value &&
                this.onChange(e.target.value, record, "billAmount")
              }
              onFocus={(e) => {
                e.currentTarget.select();
                this.setState({ activeValue: e.target.value });
              }}
              style={{ width: "100%" }}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
            />
          ),
      },
      {
        title: "Payee",
        dataIndex: "payee",
        key: "payee",
        width: "8%",
        // render: (text, record) => (
        //   <Select
        //     style={{ width: "100%" }}
        //     showSearch
        //     className={`${!text ? "err" : ""}`}
        //     value={record.payee}
        //     onChange={e => this.handlePayee(e, record, "payeeId")}
        //     onBlur={e => this.handleReceiver(e, record, "blur")}
        //     onSearch={val => this.handleReceiverName(val, record)}
        //     disabled={
        //       !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //       record.productionPaymentId
        //     }
        //   >
        //     {receiverNameList &&
        //       receiverNameList.map(list => {
        //         return <Option value={list.value}>{list.label}</Option>;
        //       })}
        //   </Select>
        // )
        render: (text, record) =>
          record.description &&
          (text ? (
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
              <>
                <a onClick={() => this.handlePayeeModal(record)}>
                  {StartCase(record.payeeName || text)}
                </a>
                <TooltipIcon
                  icon="delete"
                  titleText={GlobalConstants.REMOVE_PAYEE}
                  onClick={() => this.removePayee(record)}
                ></TooltipIcon>
              </>
            ) : (
              <a>{StartCase(record.payeeName || text)}</a>
            )
          ) : (
            <Button
              onClick={() => this.handlePayeeModal(record)}
              style={{ width: "100%" }}
              type="primary"
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
            >
              <Icon type="plus" style={{ fontSize: "11px" }} /> Payee
            </Button>
          )),
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        key: "paymentType",
        width: "10%",
        render: (text, record) => (
          <AccPaymentTypeDD
            className={`${!text ? "err" : ""}`}
            text={ReplaceWithSpace(text)}
            record={record}
            onChange={this.onChange}
            fieldName="paymentType"
            dataSource={paymentTypeList}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Payment Mode",
        dataIndex: "paymentMode",
        key: "paymentMode",
        width: "8%",
        render: (text, record) => (
          <AccPaymentModeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentMode"
            dataSource={paymentModeList}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Reference no",
        dataIndex: "referenceNumber",
        key: "referenceNumber",
        width: "10%",
        render: (text, record) => (
          <Input
            className={`${
              !text &&
              record.paymentMode &&
              record.paymentMode.toUpperCase() !== "CASH"
                ? "err"
                : ""
            } upperCase`}
            defaultValue={text}
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(e.target.value, record, "referenceNumber")
            }
            onFocus={(e) => {
              // e.currentTarget.select();
              this.setState({ activeValue: e.target.value });
            }}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Voucher no",
        dataIndex: "vocherNumber",
        key: "vocherNumber",
        width: "8%",
        render: (text, record) =>
          text && (
            <VoucherDownloadButton
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
              voucherNumber={text}
            />
          ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        width: "10%",
        render: (text, record) => (
          <TextArea
            onFocus={(e) => {
              // e.currentTarget.select();
              this.setState({ activeValue: e.target.value });
            }}
            defaultValue={text}
            placeholder="enter comments"
            onBlur={(e) =>
              this.state.activeValue !== e.target.value &&
              this.onChange(e.target.value, record, "comments")
            }
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Documents",
        dataIndex: "documents",
        key: "documents",
        width: "5%",
        render: (text, record) =>
          record.productionPaymentId && (
            <SharedDocumentUpload
              category={category}
              iconButton
              type={GlobalConstants.PRE_PRODUCTION_DOCUMENT}
              buttonType="primary"
              // key={index}
              // refreshData={this.fetchData}
              data={{
                ...record,
                productionPaymentId: record.productionPaymentId,
                paymentDate: moment(record.paymentDate)
                  // .utc()
                  .format(GlobalConstants.DD_MM_YYYY),
              }}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId
              }
            />
          ),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "",
        dataIndex: "address",
        key: "address",
        align: "center",
        // width: "3%",
        render: (text, record) => (
          <>
            <DeleteComponent
              record={record}
              onClick={(record) => this.handleDelete(record)}
            />
          </>
        ),
      });
    }

    if (hidePaymentType) {
      columns = columns.filter((column) => column.title !== "Payment Type");
    }

    return (
      <Fragment>
        {isLoading && <Loader />}
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Accounting - Production - {title} </TitleText>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                className="flrt"
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ padding: 10 }}
          >
            <Col xl={24} className="accProductionScroll">
              <Table
                bordered
                dataSource={dataSource}
                style={{ width: 1600 }}
                columns={columns}
                // scroll={{ y: "50vh" }}
                pagination={false}
                loading={isLoading}
                // pagination={{ pageSize: 1000 }}
                locale={{
                  emptyText: !isLoading && <RecordNotFound />,
                }}
                // loading={isLoading}
              />
            </Col>
            {activePayment && (
              <Modal
                destroyOnClose
                className="createModal no-x"
                key={1}
                title="Create Invoice"
                visible={isInvoiceVisible}
                width={"95%"}
                // style={{ maxHeight: "40px" }}
                footer={[
                  <Button key="back" onClick={() => this.handleCloseModal()}>
                    Close
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => this.child.handleSubmit()}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <InvoiceForm
                  category={category}
                  activeInvoice={activeInvoice}
                  activePayment={activePayment}
                  details={{
                    productionCategoryDepartment:
                      GlobalConstants.PRODUCTION_CAPS,
                  }}
                  description={activePayment.description}
                  details={details}
                  onRef={(ref) => (this.child = ref)}
                  handleInvoiceNumber={this.handleInvoiceNumber}
                  handleCloseModal={this.handleCloseModal}
                />
              </Modal>
            )}
            {activePayment && (
              <Modal
                destroyOnClose
                className="createModal no-x"
                key={1}
                title="Create Payee"
                visible={isPayeeVisible}
                // width={"95%"}
                // style={{ maxHeight: "40px" }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => this.handleClosePayeeModal()}
                  >
                    Close
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => this.child.handleSubmit()}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <PayeeForm
                  category={category}
                  activePayee={activePayee}
                  activePayment={activePayment}
                  description={activePayment.description}
                  details={details}
                  onRef={(ref) => (this.child = ref)}
                  handlePayeeName={this.handlePayeeName}
                  handleClosePayeeModal={this.handleClosePayeeModal}
                />
              </Modal>
            )}
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccProductionCostumeMakeupMore);
