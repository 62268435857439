import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Icon,
  Radio,
  Table,
  Spin,
} from "antd";
import Axios from "axios";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  AttachmentType,
} from "constants/AppConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  numberOnly,
  amountOnly,
  handleGoogleResponse,
  checkFor,
} from "utilities/ValidationHelper";
import PaymentTermsTable from "components/PaymentTermsTable";
import Uploaddocumentfile from "components/uploaddocumentfile";
import ImageViewer from "components/shared/ImageViewer";
import GlobalService from "services/GlobalService";
import { OnboardServices } from "services";
import FileCard from "components/shared/FileCardComponent";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import GeoCode from "components/shared/GeoCode";
import CrewService from "services/CrewServices";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import RoleServices from "services/RoleServices";
import { GeoServices } from "components/shared/GeoCode/geoCall";
import debounce from "lodash/debounce";
import { handleError } from "utilities/ErrorHandler";
import { getPermissions } from "store/thunk-actions";
import { connect } from "react-redux";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import Loader from "components/shared/Loader";
import "./crewUpdate.scss";

const Option = { Select };

class Crewprofile extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._onboardservices = new OnboardServices();
    this._crewService = new CrewService();
    this._roleService = new RoleServices();
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
    this.state = {
      data: [],
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      paymentTypeList: null,
      country: null,
      name: [],
      phoneNumber: null,
      email: null,
      rumeneration: null,
      address: [],
      gst: null,
      pan: null,
      phoneCode: "+91",
      roleId: null,
      phoneCodeList: null,
      userId: this.props.match.params.userId,
      roleDataList: [],
      isDetailsFetched: true,
    };
  }

  componentDidMount() {
    this.getPaymentData();
    this.getCountries();
    this.getCrewMemberDetails();
    this.getRoleData();
  }

  getRoleData() {
    this._roleService
      .services(Services.RolesVariables.GET_ROLES)
      .then((res) => {
        this.setState({
          roleDataList: res.data.roles,
        });
      });
  }

  getCrewMemberDetails() {
    if (this.state.userId) {
      this.setState({
        isDetailsFetched: false,
      });
      this._crewService
        .crewService(
          Services.CrewVariables.GET_CREW_MEMBER,
          "",
          this.state.userId
        )
        .then((res) => {
          this.setState(
            {
              projectMemberId: res.data.projectMemberId,
              crewName: res.data.user.name.firstName,
              email: res.data.user.email,
              phoneCode: res.data.user.phoneCode,
              phoneNumber: res.data.user.phoneNumber,
              roleId: res.data.user.roleId,
              rumeneration: res.data.rumenuration,
              pan: res.data.user.panNumber,
              gst: res.data.user.gst,
              paymentType: res.data.paymentType,
              addressLine:
                res.data.user.address && res.data.user.address.addressLine,
              city: res.data.user.address && res.data.user.address.city,
              state: res.data.user.address && res.data.user.address.state,
              country: res.data.user.address && res.data.user.address.country,
              zipCode: res.data.user.address && res.data.user.address.zipCode,
              fileList: res.data.projectMemberDocuments,
              isDetailsFetched: true,
            },
            () => this.splitBasedOnFileTypes(this.state.fileList)
          );
        });
    }
  }

  getPaymentData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ACTOR_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({ paymentTypeList: res.data });
      });
  };

  handlepayment = (data) => {
    this.setState({
      paymentData: data,
    });
  };

  handleCancel = (e) => {
    this.props.closeModal();
  };

  handleSubmit = (e) => {
    const data = {
      user: {
        name: {
          firstName: this.state.crewName,
        },
        phoneNumber: this.state.phoneNumber,
        phoneCode: this.state.phoneCode,
        email: this.state.email,
        panNumber: this.state.pan,
        gst: this.state.gst,
        roleId: this.state.roleId,
        address: {
          addressLine: this.state.addressLine,
          city: this.state.city,
          country: this.state.country,
          state: this.state.state,
          zipCode: this.state.zipCode,
        },
      },
      paymentType: this.state.paymentType,
      rumeneration: this.state.rumeneration,
      projectMemberPayments: this.state.paymentData,
      projectMemberDocuments: this.state.fileList,
    };
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this._crewService
          .crewService(Services.CrewVariables.CREATE_CREW_MEMBER, data)
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "New Crew Memeber Added Successfully"
            );
            this.setState({
              isLoading: false,
            });
            this.props.history.goBack();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message,
              handleError(
                // err.response && err.response.data && err.response.data.message
                err.response &&
                  err.response.data.errors[0] &&
                  err.response.data.errors[0].message
              )
            );
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  };

  handleUpdate = (e) => {
    let data = {
      user: {
        name: {
          firstName: this.state.crewName,
        },
        phoneNumber: this.state.phoneNumber,
        phoneCode: this.state.phoneCode,
        email: this.state.email,
        panNumber: this.state.pan,
        roleId: this.state.roleId,
        gst: this.state.gst,
        address: {
          addressLine: this.state.addressLine,
          city: this.state.city,
          country: this.state.country,
          state: this.state.state,
          zipCode: this.state.zipCode,
        },
      },
      paymentType: this.state.paymentType,
      rumeneration: this.state.rumeneration,
      projectMemberPayments: this.state.paymentData,
      projectMemberDocuments: this.state.fileList,
    };
    if (
      data.user.address.addressLine === null &&
      data.user.address.city === null &&
      data.user.address.state === null &&
      data.user.address.country === null &&
      data.user.address.zipCode === null
    ) {
      delete data.user.address;
    }
    this._crewService
      .crewService(
        Services.CrewVariables.UPDATE_CREW_MEMBER,
        data,
        this.state.userId
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "New Crew Memeber Added Successfully"
        );
        this.setState({
          isLoading: false,
        });
        this.props.history.goBack();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
        this.setState({
          isLoading: false,
        });
        this.props.getPermissions();
      });
  };

  getCountries = () => {
    this._onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          countryListDatas: res.data.countries,
          phoneCodeList: res.data.countries,
        });
      });
  };

  getDetails = (city, state, country, zipCode) => {
    this.setState({
      city,
      state,
      country,
      zipCode,
    });
  };

  handleImageIDS = (ids) => {
    console.log("url", ids);
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
    });
    console.log(files);
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  handleDocument = (id) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { attachmentId: id, fileType: GlobalConstants.DOCUMENT },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ attachmentId: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };

  handleGeoChange = (e) => {
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          const [city, state, country] = handleGoogleResponse(res);
          this.setState({
            city,
            state,
            country,
            zipCode: e,
          });
        })
        .catch((err) => {
          this.setState({
            city: null,
            state: null,
            country: null,
            zipCode: e,
          });
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
          // this.props.getDetails(null, null, null, e);
        });
    }
  };

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState(
      {
        docFiles,
        imageFiles,
      },
      () => console.log("files....", this.state)
    );
  };

  deleteImage = (id) => {
    console.log("image files id", id);
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteImageIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;

    imageFiles.splice(deleteImageIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  deleteDoc = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteDocIndex = this.state.docFiles.findIndex(
      (a) => a.documentId === id
    );

    let docFiles = this.state.docFiles;
    let fileList = this.state.fileList;
    docFiles.splice(deleteDocIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      docFiles,
      fileList,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const {
      crewName,
      email,
      phoneCode,
      phoneNumber,
      pan,
      gst,
      zipCode,
      country,
      state,
      city,
      addressLine,
      isLoading,
      roleDataList,
      roleId,
      rumeneration,
      paymentType,
      projectMemberId,
      userId,
      isDetailsFetched,
    } = this.state;
    const { activePermisision, getPermissions } = this.props;
    return (
      <Fragment>
        <Row>
          <Col xl={{ span: 24 }} lg={{ span: 18 }} md={{ span: 21 }}>
            <Row span={24}>
              <Title hasBack {...this.props}>
                <TitleText>Crew Profile </TitleText>
              </Title>
            </Row>

            <Form onSubmit={this.handleSubmit} style={{ padding: 20 }}>
              <Row gutter={12}>
                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>*Name</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("crewName", {
                      initialValue: crewName,
                      rules: [
                        {
                          required: true,
                          message: "Please Enter name!",
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        placeholder="Name"
                        onChange={(e) =>
                          this.setState({ crewName: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>*Role Name </label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("roleId", {
                      initialValue: roleId,
                      rules: [
                        {
                          required: true,
                          message: "Select Role Name!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select Role Name"
                        style={{ width: "100%" }}
                        onChange={(value) => this.setState({ roleId: value })}
                      >
                        {roleDataList &&
                          roleDataList.map((option, i) => (
                            <Option value={option.id}>{option.name}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>*Phone: </label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("phone", {
                      initialValue: phoneNumber,
                      rules: [
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                        this.state.phoneCode === "+91" && {
                          pattern: ".{10,}",
                          message: "Enter 10 Numbers Only!",
                        },
                      ],
                    })(
                      <Input
                        addonBefore={
                          <Form.Item
                            className="payment-type"
                            style={{
                              margin: "0px",
                              height: "30px",
                              top: "-4px",
                            }}
                          >
                            {getFieldDecorator("phoneCode", {
                              initialValue: phoneCode || undefined,
                            })(
                              <Select
                                showSearch
                                defaultValue={this.state.phoneCode}
                                style={{ width: "80px" }}
                                optionFilterProp="children"
                                onChange={(value) =>
                                  this.setState({ phoneCode: value })
                                }
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(input.toString().toLowerCase())
                                }
                              >
                                {this.state.phoneCodeList &&
                                  (this.state.phoneCodeList || []).map(
                                    (option, i) => (
                                      <Option value={option.phoneCode}>
                                        {option.phoneCode}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            )}
                          </Form.Item>
                        }
                        style={{ fontWeight: "bold" }}
                        placeholder=" Phone number"
                        onChange={(e) =>
                          this.setState({ phoneNumber: numberOnly(e) })
                        }
                        maxLength={this.state.phoneCode === "+91" ? 10 : null}
                        minLength={this.state.phoneCode === "+91" ? 10 : null}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>*Email</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("email", {
                      initialValue: email || undefined,
                      rules: [
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ],
                    })(
                      <input
                        className="ant-input"
                        disabled={userId}
                        type="email"
                        placeholder="Enter your email "
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>Salary</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("salary", {
                      initialValue: rumeneration || undefined,
                      rules: [
                        {
                          required: false,
                          message: "Please enter your salary!",
                        },
                      ],
                    })(
                      <Input
                        style={{ fontWeight: "bold" }}
                        type="text"
                        onChange={(e) =>
                          this.setState({ rumeneration: numberOnly(e) })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>Payment Type </label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("paymentType", {
                      initialValue: paymentType,
                      rules: [
                        {
                          required: false,
                          message: "Please Enter Payment type!",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Payment Type"
                        style={{ width: "100%" }}
                        name="paymentType"
                        onChange={(value) =>
                          this.setState({ paymentType: value })
                        }
                      >
                        {this.state.paymentTypeList &&
                          this.state.paymentTypeList.map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {this.state.paymentType === "CONTRACT" ||
              this.state.paymentType === "Contract" ? (
                <Row gutter={12}>
                  <Col span={24}>
                    <div
                      style={{ display: "flex", flex: 1, alignItems: "center" }}
                    >
                      <label>Payment Terms</label>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <PaymentTermsTable
                        {...this.props}
                        getFromChild={this.handlepayment}
                        actorId={projectMemberId}
                        rumeneration={this.state.rumeneration}
                        userId={this.state.userId}
                        isFromCrew
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}

              <Row gutter={12}>
                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label> PAN </label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("pan", {
                      initialValue: pan,
                      rules: [
                        {
                          required: false,
                          message: "Please Enter Pan !",
                        },
                        {
                          pattern: new RegExp(
                            "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                          ),
                          message:
                            "Please Enter Correct Pan Format Ex: JSDFG1234H ",
                        },
                      ],
                    })(
                      <Input
                        style={{
                          textTransform: "uppercase",
                        }}
                        type="text"
                        placeholder="Pan No"
                        maxLength={10}
                        onChange={(e) =>
                          this.setState({
                            pan: e.target.value.toUpperCase(),
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>GST </label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("gst", {
                      initialValue: gst,
                      rules: [
                        {
                          required: false,
                          message: "Please Enter Gst !",
                        },
                        {
                          pattern: new RegExp(
                            "[0-3]{1}[0-9]{1}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-2]{1}[Zz]{1}[0-9A-Za-z]{1}"
                          ),
                          message:
                            "Please Enter Correct Gst Format Ex:22AAAAA0000A1Z5",
                        },
                      ],
                    })(
                      <Input
                        style={{
                          textTransform: "uppercase",
                        }}
                        type="text"
                        maxLength={15}
                        placeholder="Gst No"
                        onChange={(e) =>
                          this.setState({
                            gst: e.target.value.toUpperCase(),
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>Zip Code</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("zipCode", {
                      initialValue: zipCode,
                      rules: [
                        {
                          required: false,
                          message: "Please Enter Zipcode!",
                        },
                        {
                          pattern: new RegExp("^[1-9][0-9]{5}$"),
                          message: "Enter Six Digits Onlys",
                        },
                      ],
                    })(
                      <Input
                        placeholder="enter you pincode"
                        maxLength={6}
                        onChange={(e) => this.handleGeoChange(numberOnly(e))}
                      />
                      // <GeoCode
                      //   defaultValue={this.state.zipCode}
                      //   getDetails={this.getDetails}
                      // />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>Country</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("country", {
                      initialValue: country,
                    })(
                      <Select
                        style={{ fontWeight: "bold", color: "black" }}
                        showSearch
                        placeholder="Country"
                        className="w-100"
                        onChange={(value) => this.setState({ country: value })}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.countryListDatas &&
                          (this.state.countryListDatas || []).map(
                            (option, i) => (
                              <Option value={option.name}>{option.name}</Option>
                            )
                          )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>State</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("state", {
                      initialValue: state,
                      // rules: [
                      //   {
                      //     required: false,
                      //     message: "Please Enter State!"
                      //   }
                      // ]
                    })(
                      <Input
                        type="text"
                        placeholder="State"
                        onChange={(e) =>
                          this.setState({ state: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>City</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("city", {
                      initialValue: city,
                      // rules: [
                      //   {
                      //     required: false,
                      //     message: "Please Enter City!"
                      //   }
                      // ]
                    })(
                      <Input
                        type="text"
                        placeholder="City"
                        onChange={(e) =>
                          this.setState({ city: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>Address</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("Address", {
                      initialValue: addressLine,
                      rules: [
                        {
                          required: false,
                          message: "Please Enter Address!",
                        },
                      ],
                    })(
                      <Input
                        type="text"
                        placeholder="Address"
                        onChange={(e) =>
                          this.setState({ addressLine: e.target.value })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                    }}
                  >
                    <label>Contract</label>
                  </div>
                  <Form.Item>
                    {getFieldDecorator("fileuploadid", {
                      initialValue: this.state.fileuploadid,
                      rules: [
                        {
                          required: false,
                          message: "Please Upload Documents!",
                        },
                      ],
                    })(
                      <React.Fragment>
                        <div className="fileCardContainer">
                          {this.state.docFiles &&
                            this.state.docFiles.map((file, i) => (
                              <FileCard
                                file={file}
                                deleteDoc={this.deleteDoc}
                                pageType={
                                  Services.CrewVariables.DELETE_CREW_DOCUMENT
                                }
                              />
                            ))}
                        </div>
                        <Uploaddocumentfile
                          type={AttachmentType?.crewDocument}
                          handleDocument={this.handleDocument}
                        />
                      </React.Fragment>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Row
              gutter={12}
              type="flex"
              justify="end"
              style={{ marginTop: "20px" }}
            >
              <Col>
                <Button onClick={() => this.props.history.goBack()}>
                  Cancel
                </Button>
              </Col>
              <Col>
                {this.state.userId ? (
                  <Button
                    type="primary"
                    onClick={this.handleUpdate}
                    loading={isLoading}
                    disabled={
                      !checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_EDIT
                      )
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={this.handleSubmit}
                    disabled={
                      !checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_ADD
                      )
                    }
                  >
                    Submit
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {!isDetailsFetched && <Loader />}
      </Fragment>
    );
  }
}
const crewprofile = Form.create()(Crewprofile);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.CREW, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(crewprofile);
