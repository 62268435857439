import React, { Component } from "react";
import "./artset.css";
import {
  Table,
  Switch,
  Button,
  Input,
  Icon,
  Tag,
  AutoComplete,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ArtSetServices from "services/ArtSetServices";
import {
  Services,
  GlobalConstants,
  LocalStorage,
  Screens,
  Lotties,
} from "constants/AppConstants";
import ArtSetUpdate from "UpdateSet";
import { Capitalize, checkFor } from "utilities/ValidationHelper";
import GlobalService from "services/GlobalService";
import LottieComponent from "components/shared/LottieComponent";
import { Value } from "components/shared/Label";
import RecordNotFound from "components/shared/RecordNotFound";
import { setActiveTab } from "store/action";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import TooltipComponent from "components/shared/TooltipComponent";

class ArtsetRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      artSets: [],
      type: null,
      isModalVisible: false,
      recordData: null,
      statusListDatas: [],
      index: null,
      typeListData: [],
      sceneLocationDataSource: [],
      sceneNumberDataSource: [],
      actualLocationDataSource: [],
      isActorsFetched: false,
    };
    this._globalService = new GlobalService();
    this._artSetService = new ArtSetServices();
  }

  componentDidMount() {
    this.fetchData();
    this.getStatusFilter();
    this.getTypeFilter();
  }

  componentWillReceiveProps() {
    this.resetSearch();
  }

  getTypeFilter = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.LOCATION_TYPE
      )
      .then((res) => {
        this.setState({ typeListData: res.data });
      });
  };

  getStatusFilter = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({ statusListDatas: res.data });
      });
  };

  fetchData = () => {
    this._artSetService
      .service(Services.ArtSetVariables.GET_ART_SET_PROJECT_LIST)
      .then((res) => {
        this.setState(
          {
            artSets: res.data.artSets,
            isActorsFetched: true,
          },
          () => {
            this.getSceneLocationDataSource();
            this.getSceneNumberDataSource();
            this.getActualLocationDataSource();
          }
        );
      })
      .catch((err) => {
        this.setState({
          isActorsFetched: true,
        });
      });
  };

  getSceneLocationDataSource() {
    let locationDatas = [];
    this.state.artSets &&
      this.state.artSets.map((a) => {
        locationDatas.push(a.sceneLocation);
      });
    this.setState({ sceneLocationDataSource: [...new Set(locationDatas)] });
  }

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  getSceneNumberDataSource = () => {
    let sceneNumberDatas = [];
    let sceneNumberSource = [];

    new Promise((resolve) => {
      this.state.artSets &&
        this.state.artSets.forEach((a) => {
          a.scenes.forEach((b) => {
            sceneNumberSource.push(b?.sceneNumber);
          });
          sceneNumberDatas.push(a.scenes);
        });
      resolve();
    }).then(() => {
      this.setState({ sceneNumberDataSource: sceneNumberSource });
    });
  };

  getActualLocationDataSource() {
    let locationDatas = [];
    this.state.artSets &&
      this.state.artSets.map((a) => {
        if (a.actualLocation) locationDatas.push(a.actualLocation);
      });
    this.setState({ actualLocationDataSource: [...new Set(locationDatas)] });
  }

  getColumnSearchPropsId = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <AutoComplete
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          dataSource={this.state.sceneNumberDataSource}
          placeholder="Scene Number"
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      let scenes =
        record.scenes && record.scenes.map((scene, i) => scene.sceneNumber);
      return scenes && scenes.includes(value);
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
  });

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <AutoComplete
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          dataSource={this.state.actualLocationDataSource || []}
          placeholder={
            dataIndex === "actualLocation"
              ? "fin. Actual Location"
              : "Scene Number"
          }
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  resetSearch = () => {
    this.setState({
      index: this.state.index + 1,
    });
  };

  render() {
    const { activePermisision } = this.props;
    const columns = [
      {
        title: "#",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Scene Location",
        width: "25%",
        align: "left",
        dataIndex: "sceneLocation",
        // ...this.getColumnSearchProps("sceneLocation"),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <AutoComplete
              onChange={(e) => setSelectedKeys(e ? [e] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              dataSource={this.state.sceneLocationDataSource}
              placeholder="Scene Location"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon
            type="search"
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        ),
        onFilter: (value, record) => {
          if (record.sceneLocation) {
            return record.sceneLocation
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            // setTimeout(() => this.searchInput.select());
          }
        },
        render: (text, record) => (
          <Link
            to={`/project/location-audition/${record.sceneLocation}/${record.sceneLocationId}`}
          >
            {record.sceneLocation}
          </Link>
        ),
      },
      {
        title: "Scene No",
        dataIndex: "scenes",
        width: "20%",
        align: "left",
        ...this.getColumnSearchPropsId("scenes"),
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.ART_SET
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                      this.props.setActiveTab("1");
                    }}
                  >
                    {/* <Link style={{ color: "white" }}>{scene.sceneNumber}</Link> */}
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },

      {
        title: "Finalized Actual Location",
        dataIndex: "actualLocation",
        width: "20%",
        align: "left",
        ...this.getColumnSearchProps("actualLocation"),
        render: (text, record) => (
          <Link
            to={`/project/update-location/${record.actualLocationId}/${record.actualLocation}/${record.sceneLocation}/${record.sceneLocationId}`}
          >
            {Capitalize(record.actualLocation)}
          </Link>
        ),
      },
      // {
      //   title: "Type",
      //   dataIndex: "type",
      //   width: "20%",
      //   align: "center",
      //   filters:
      //     this.state.typeListData &&
      //     (this.state.typeListData || []).map((option, i) => ({
      //       value: option.label,
      //       text: option.label,
      //     })),
      //   onFilter: (value, record) => {
      //     return record.type === value;
      //   },
      // },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "Action",
        width: "10%",
        align: "center",
        render: (text, record) => {
          let scenes =
            record.scenes &&
            record.scenes.map((scene) => {
              return {
                sceneId: scene.sceneId,
                sceneNumber: scene.sceneNumber,
              };
            });
          return (
            record.actualLocation && (
              <Link
                to={{
                  pathname: "/project/artsetoverview",
                  state: { sceneLocationId: record.sceneLocationId, scenes },
                }}
              >
                More
              </Link>
            )
          );
        },
      });
    }
    const { artSets, isActorsFetched } = this.state;
    return (
      <div>
        <Tooltip title={GlobalConstants.RESET} placement={GlobalConstants.TOP}>
          <Button
            type="primary"
            style={{
              position: "absolute",
              right: "0",
              top: "20px",
              // marginBottom : '10px'
            }}
            onClick={this.resetSearch}
          >
            {/* Reset Filter */}
            <Icon type="reload" style={{ fontSize: "15px" }} />
          </Button>
        </Tooltip>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Table
            bordered
            className="art-set-col-width"
            columns={columns}
            dataSource={artSets}
            key={this.state.index}
            pagination={false}
            // scroll={{ y: "55vh" }}
            loading={!isActorsFetched}
            // loading={
            //   true && {
            //     indicator: (
            //       <div>
            //         <LottieComponent
            //           type={Lotties.RECORD_NOT_FOUND}
            //           width={250}
            //         />
            //       </div>
            //     )
            //   }
            // }
            locale={{
              emptyText: isActorsFetched && <RecordNotFound />,
            }}
          />
        ) : (
          <ContactAdminErrorTemplate />
        )}
        <ArtSetUpdate
          isModalVisible={this.state.isModalVisible}
          closeModal={this.closeModal}
          recordData={this.state.recordData}
          type={this.state.type}
          refreshData={this.fetchData}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.ART_SET, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtsetRecord);
