import React, { Component } from "react";
import "./generalscriptdetails.css";
import {
  Row,
  Col,
  Select,
  Radio,
  Checkbox,
  Input,
  Button,
  Icon,
  Modal,
  Form,
  InputNumber,
  Tooltip,
  Tag,
} from "antd";
import { connect } from "react-redux";
import Axios from "axios";
import GeneralTabService from "services/SceneOverviewService/GeneralTabService";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Screens,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { Link } from "react-router-dom";
import { setActiveTab, setActiveSceneLocationId } from "store/action";
import {
  numberOnly,
  checkFor,
  StartCase,
  ReplaceWithSpace,
  removePercentage,
} from "utilities/ValidationHelper";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { handleError } from "utilities/ErrorHandler";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getScriptAPIURL } from "utilities/APIHelper";

const { Option } = Select;
const { TextArea } = Input;

class GeneralScriptDetails extends Component {
  constructor(props) {
    super(props);
    this._generalTabService = new GeneralTabService();
    this._globalService = new GlobalService();
    this._locationTabService = new LocationTabService();
  }
  state = {
    visible: false,
    sceneNo: null,
    screenLocation: null,
    description: null,
    environmentType: null,
    environmentTime: null,
    timeToShoot: null,
    art_set: null,
    costumes: null,
    makeup: null,
    sceneType: null,
    vfx: null,
    plainOptions: null,
    isLoading: false,
    timeRequiredType: [],
    timeRequiredTimeType: null,
    environmentTypeData: [],
    environmentTimeData: [],
    isLocationEditable: false,
    isDescriptionEditable: false,
    activeSceneId: null,
    sceneVisible: false,
    sceneLocation: "",
    sceneLocationName: "",
    descriptionVisible: false,
    makeupData: [],
    costumeData: [],
    environmentDataType: [],
    environmentDataTime: [],
    scenelocationDropDown: [],
    minute: null,
    second: null,
    StatusList: [],
    detailsFetched: false,
  };

  componentDidMount() {
    this.getSceneDetails();
    this.getSceneData();
    this.getTimeType();
    this.getMakeupData();
    this.getCostumesData();
    this.getEnvironmentTypeData();
    this.getEnvironmentTimeData();
    this.getSceneLocation();
    this.getStatusList();
  }

  getStatusList = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SCHEDULE_STATE
      )
      .then((res) => this.setState({ StatusList: res.data }))
      .catch((err) => {});
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.props.resetPanes(this.state.activeSceneId);
          this.props.form.resetFields();
          this.getSceneDetails();
          localStorage.setItem(
            LocalStorage.ACTIVE_SCENE_ID,
            this.state.activeSceneId
          );
          showActionMessage(
            GlobalConstants.SUCCESS,
            "New Scene Loaded Successfully"
          );
        }
      );
    }
  }

  checkForRedirect = (redirectTo, checkbox, costume, makeup) => {
    switch (redirectTo) {
      case Screens.SONGS:
        let isSongAvailable = checkbox && checkbox.includes("SONG");
        if (isSongAvailable) {
          this.props.setActiveTab("8");
        }
        break;
      case Screens.STUNTS:
        let isStuntAvailable = checkbox && checkbox.includes("STUNT");
        if (isStuntAvailable) {
          this.props.setActiveTab("9");
        }
        break;
      case Screens.COSTUMES:
        // let isCostumeAvailable = costume && costume.includes("SPECIAL");
        // if (isCostumeAvailable) {
        this.props.setActiveTab("5");
        // }
        break;
      case Screens.MAKEUP:
        // let isMakeupAvailable = makeup && makeup.includes("SPECIAL");
        // if (isMakeupAvailable) {
        this.props.setActiveTab("6");
        // }
        break;
      case Screens.ACTOR:
        this.props.setActiveTab("2");
        break;
      case Screens.LOCATION:
        this.props.setActiveTab("3");
        break;
      case Screens.ART_SET:
        this.props.setActiveTab("4");
        break;
      case Screens.EQUIPMENTS:
        this.props.setActiveTab("11");
        break;
      case Screens.VFX:
        this.props.setActiveTab("7");
        break;
      default:
        break;
    }
  };

  getTimeType() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "MAKE_UP_TIME")
      .then((res) => {
        this.setState({ timeRequiredType: res.data });
      });
  }

  getSceneData = () => {
    let datas = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "SCENE")
      .then((res) => {
        res.data.map((checkbox) => {
          datas.push({ label: checkbox.label, value: checkbox.value });
          this.setState({
            plainOptions: datas,
          });
        });
      });
    return datas;
  };

  getEnvironmentTypeData = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "ENVIRONMENT")
      .then((res) => {
        this.setState({ environmentDataType: res.data });
      });
  };

  getEnvironmentTimeData = () => {
    let time = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "TIME")
      .then((res) => {
        this.setState({ environmentDataTime: res.data });
      });
  };

  getCostumesData = () => {
    let costumes = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "COSTUME")
      .then((res) => {
        this.setState({
          costumeData: res.data,
        });
      });
  };

  getMakeupData = () => {
    let costumes = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "MAKEUP")
      .then((res) => {
        this.setState({
          makeupData: res.data,
        });
      });
  };
  getSceneLocation = () => {
    this._locationTabService
      .locationTabService(Services.SceneOVerviewVariable.SCENE_LOCATION)
      .then((res) => {
        this.setState({
          scenelocationDropDown: Array.isArray(res.data) ? res.data : [],
        });
      });
  };

  setSceneLocationId = (sceneLocationId) => {
    localStorage.setItem(LocalStorage.LOCATION_ID, sceneLocationId);
    this.props.setActiveSceneLocationId(sceneLocationId);
  };
  // onFocus = (value) => {
  //   this.getSceneLocation(value);
  // };
  // onBlur = () => {
  //   // this.getSceneLocation()
  // };
  // onSearch = value => {
  //   // this.setState({
  //   //   sceneLocation:value
  //   // },() => console.log(this.state.sceneLocation))
  //   this.getSceneLocation(value);
  // };

  getSceneDetails = () => {
    this.setState({
      detailsFetched: false,
    });
    this._generalTabService
      .generalTabService(
        Services.SceneOVerviewVariable.GET_SCENE,
        null,
        this.state.activeSceneId
      )
      .then((res) => {
        this.setState(
          {
            sceneNo: res.data.sceneNumber,
            sceneLocation: res.data.location,
            sceneLocationName: res.data.location,
            description: res.data.description,
            environmentType: res.data.environmentType,
            environmentTime: res.data.timeType,
            sceneType: res.data.sceneTypes,
            vfx: res.data.cgiState,
            timeToShoot: res.data.requiredTime,
            costumes: res.data.costumeType,
            makeup: res.data.makeupType,
            minute: res.data.minute,
            timeRequiredTimeType: res.data.requiredTimeType,
            second: res.data.second,
            state: res.data.state,
            detailsFetched: true,
            sceneId: res.data.sceneId,
            // art_set: "Set",
          },
          () => {
            this.props.handleVfxTabStatus(this.state.vfx);
            //            this.props.handleCostumeTab(this.state.costumes);
            //          this.props.handleMakeupTab(this.state.makeup);
            this.props.handleSongStuntTab(this.state.sceneType);
            this.setSceneLocationId(res.data.sceneLocationId);
            localStorage.setItem(
              LocalStorage.SCENE_LOCATION_NAME,
              res.data.location
            );
            let redirectTo = localStorage.getItem(
              GlobalConstants.REDIRECT_TO,
              Screens.SONGS
            );
            this.checkForRedirect(
              redirectTo,
              this.state.sceneType,
              this.state.costumes,
              this.state.makeup
            );
          }
        );
      });
  };

  handleEditEnvironment = (e) => {
    this.setState({
      visible: true,
    });
  };
  handleEditSceneLocation = (e) => {
    this.setState({
      sceneVisible: true,
    });
  };
  handleDescription = (e) => {
    this.setState({
      descriptionVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleSceneOk = (e) => {
    this.setState({
      sceneVisible: false,
    });
  };
  handleDescriptionOk = (e) => {
    this.setState({
      descriptionVisible: false,
    });
  };
  handleCancel = (e) => {
    this.setState(
      {
        visible: false,
        environmentTime: null,
        environmentType: null,
      },
      () => this.getSceneDetails()
    );
  };

  handleSceneCancel = (e) => {
    this.setState(
      {
        sceneVisible: false,
        sceneLocation: "",
      },
      () => {
        this.getSceneDetails();
      }
    );
  };

  handleDescriptionCancel = (e) => {
    this.setState(
      {
        descriptionVisible: false,
        description: null,
      },
      () => {
        this.getSceneDetails();
      }
    );
  };

  updateSceneLocation = (type) => {
    const { refreshData } = this.props;
    const data = {
      costumeType: this.state.costumes,
      description: this.state.description,
      environmentType: this.state.environmentType,
      location: this.state.sceneLocation,
      makeupType: this.state.makeup,
      minute: this.state.minute,
      requiredTime: this.state.timeToShoot,
      sceneNumber: this.state.sceneNo,
      sceneTypes: this.state.sceneType,
      sceneLocation: this.state.sceneLocation,
      timeType: this.state.environmentTime,
      cgiState: this.state.vfx,
      requiredTimeType: this.state.timeRequiredTimeType,
      second: this.state.second,
    };
    this._generalTabService
      .generalTabService(Services.SceneOVerviewVariable.UPDATE_SCENE, data)
      .then((res) => {
        this.setState({
          isLoading: false,
          isLocationEditable: false,
          isDescriptionEditable: false,
        });
        this.handleSceneCancel();
        this.handleDescriptionCancel();
        this.getSceneDetails();
        this.getSceneLocation();
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Successfully",
        //   `Updated ${type}!!`
        // );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        refreshData();
      });
  };

  generalSceneSubmit = (e = null) => {
    e && e.preventDefault();
    const data = {
      costumeType: this.state.costumes,
      description: this.state.description,
      environmentType: this.state.environmentType,
      location: this.state.sceneLocation,
      makeupType: this.state.makeup,
      minute: this.state.minute,
      requiredTime: this.state.timeToShoot,
      sceneNumber: this.state.sceneNo,
      sceneTypes: this.state.sceneType,
      timeType: this.state.environmentTime,
      cgiState: this.state.vfx,
      requiredTimeType: this.state.timeRequiredTimeType,
      second: this.state.second,
    };

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isLoading: true,
        });
        this._generalTabService
          .generalTabService(Services.SceneOVerviewVariable.UPDATE_SCENE, data)
          .then((res) => {
            this.setState({
              isLoading: false,
              isLocationEditable: false,
              isDescriptionEditable: false,
            });
            this.handleSceneCancel();
            this.handleDescriptionCancel();
            this.getSceneDetails();
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
          });
      }
    });
  };

  handleVfxTabs = (e) => {
    this.props.handleVfxTabStatus(e.target.value);
    this.setState(
      {
        vfx: e.target.value,
      },
      () => this.generalSceneSubmit()
    );
  };

  handleapproxMinute = (e) => {
    this.setState(
      {
        minute: e.target.value,
      },
      () => this.generalSceneSubmit()
    );
  };
  handleapproxSecond = (e) => {
    this.setState(
      {
        second: e.target.value,
      },
      () => this.generalSceneSubmit()
    );
  };

  handleSceneType = (e) => {
    this.props.handleSongStuntTab(e);
    this.setState(
      {
        sceneType: e,
      },
      () => this.generalSceneSubmit()
    );
  };

  handleArtSet = (e) => {
    this.props.handleSetTab(e);
  };

  handleCostumeType = (e) => {
    this.setState({ costumes: e });
    this.props.handleCostumeTab(e);
  };

  handleMakeupType = (e) => {
    this.setState({ makeup: e });
    this.props.handleMakeupTab(e);
  };

  handleRequiredTimeType = (e) => {
    this.setState({ timeRequiredTimeType: e });
  };

  handleEnvironmentTimeType = (e) => {
    this.setState({ environmentType: e });
  };

  handleEnvironmentDayType = (e) => {
    this.setState({ environmentTime: e });
  };

  handleUpdate = (e, r) => {
    const { refreshData } = this.props;
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    const datas = {
      environmentType: this.state.environmentType,
      timeType: this.state.environmentTime,
    };
    Axios.put(
      `${getScriptAPIURL()}/scenes/${r}/environment`,
      datas,
      config
    ).then((response) => {
      this.setState({ isLoading: false, visible: false });
      refreshData();
      showActionMessage(
        GlobalConstants.SUCCESS,
        GlobalConstants.CHANGES_HAVE_BEEN_SAVED
      );
    });
  };

  handleStatusChange = (status) => {
    this._generalTabService
      .generalTabService(
        Services.SceneOVerviewVariable.UPDATE_SCENE_STATUS,
        status
      )
      .then((res) =>
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        )
      )
      .catch((err) => {
        this.getSceneDetails();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleLocationBlur = (e) => {
    if (e) {
      const { scenelocationDropDown } = this.state;
      let total = scenelocationDropDown;

      this.setState(
        {
          scenelocationDropDown: [
            ...total,
            Object.assign({}, { label: e, value: e }),
          ],
        },
        () => {
          this.props.form.setFieldsValue({
            sceneLocation: e,
          });
        }
      );
    }
  };

  handleSearch = (value) => {
    this.props.form.setFieldsValue({
      sceneLocation: value,
    });
    this.setState({ sceneLocation: value });
  };

  // handleLocationChange = (newLocation) => {
  //   let existingLocation = typeof this.state.sceneLocation === "string" ? this.state.sceneLocation.join("") : this.state.sceneLocation;
  //   let newlyAdded = newLocation.filter(location => !existingLocation.includes(location));
  //   this.setState({
  //     sceneLocation: newlyAdded
  //   })
  // }

  handleSceneLocation = (newLocation) => {
    this.setState({
      sceneLocation: removePercentage(newLocation),
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const InputGroup = Input.Group;
    const {
      art_set,
      vfx,
      costumes,
      makeup,
      sceneNo,
      sceneType,
      screenLocation,
      environmentTime,
      environmentType,
      timeToShoot,
      description,
      sceneLocation,
      sceneVisible,
      minute,
      second,
      StatusList,
      state,
      detailsFetched,
    } = this.state;
    return (
      <div>
        <Form>
          <Row>
            <Col
              lg={{ span: 11 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 7 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">Scene &nbsp; :</h3>
                </Col>
                <Col
                  xs={{ span: 23 }}
                  lg={{ span: 14 }}
                  md={{ span: 10 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("sceneNo", {
                      initialValue: sceneNo || undefined,
                      rules: [
                        { required: true, message: "Enter Scene Number!" },
                      ],
                    })(<Input placeholder="Scene Number" disabled />)}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }}>
              <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 10 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">
                    Scene Location &nbsp; :
                  </h3>
                </Col>
                <Col
                  lg={{ span: 12 }}
                  xs={{ span: 23 }}
                  md={{ span: 10 }}
                  className="pl-15"
                >
                  <Row gutter={0}>
                    <Col span={22}>
                      <Form.Item className="m-0">
                        {getFieldDecorator("sceneLocationName", {
                          initialValue: this.state.sceneLocationName,
                          rules: [
                            {
                              required: true,
                              message: "Select / Enter the Scene Location!",
                            },
                          ],
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                    {checkFor(
                      this.props.activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    ) ? (
                      <Col span={2}>
                        <div
                          onClick={(e) => this.handleEditSceneLocation(e)}
                          style={{
                            color: "#2da01c",
                            marginLeft: "5px",
                            height: "34px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginTop: "3px",
                          }}
                        >
                          <Tooltip
                            title={GlobalConstants.EDT}
                            placement={GlobalConstants.RIGHT}
                          >
                            <Icon type="edit" />
                          </Tooltip>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </Row>
              <Modal
                destroyOnClose
                visible={this.state.sceneVisible}
                onOk={this.handleSceneOk}
                title="Edit Scene Location"
                className="createModal no-x"
                onCancel={this.handleSceneCancel}
                footer={
                  <Row
                    gutter={12}
                    type="flex"
                    justify="end"
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button onClick={this.handleSceneCancel}>Cancel</Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={(e) => {
                          this.updateSceneLocation("Scene Location");
                        }}
                      >
                        Ok
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Form>
                  <Row className="mt-5" style={{ marginTop: "10px" }}>
                    <Col
                      xl={{ span: 6, offset: 4 }}
                      className=" mt-5 veritical-center"
                    >
                      <label
                        className="font-600"
                        label="Username"
                        style={{ marginTop: "5px" }}
                      >
                        Scene Location
                      </label>
                    </Col>
                    <Col xl={{ span: 10 }}>
                      <Form.Item>
                        {getFieldDecorator("sceneLocation", {
                          initialValue: sceneLocation,
                          rules: [
                            {
                              required: true,
                              message: "Enter Scene Location!",
                            },
                          ],
                        })(
                          <Select
                            showSearch
                            // mode="tags"
                            style={{ width: 200 }}
                            placeholder="Select a Scene Location"
                            onSearch={(value) => this.handleSearch(value)}
                            onChange={(newLocation) =>
                              this.handleSceneLocation(newLocation)
                            }
                            onBlur={(e) => this.handleLocationBlur(e)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            // onClick = {this.getSceneLocation}
                            // onFocus={this.onFocus}
                          >
                            {this.state.scenelocationDropDown &&
                              this.state.scenelocationDropDown.map((option) => (
                                <Option value={option.label}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Col>
          </Row>
          <Row className="mb-25">
            <Col
              lg={{ span: 3 }}
              md={{ span: 10 }}
              xs={{ span: 23, offset: 1 }}
            >
              <h3 className="text-right pr-15 font-600 m-0">
                Description &nbsp; :
              </h3>
            </Col>
            <Col
              xs={{ span: 23 }}
              lg={{ span: 19 }}
              md={{ span: 10 }}
              className="pl-15 pl-0"
            >
              {/* <Form.Item className="m-0">
                {getFieldDecorator("description", {
                  initialValue: description || undefined,
                  rules: [
                    { required: true, message: "Enter scene description!" }
                  ]
                })(
                  <TextArea
                    rows={2}
                    style={{ width: "97.5%", display: "flex" }}
                    onChange={e =>
                      this.setState({
                        description: e.target.value
                      })
                    }
                  />
                )}
              </Form.Item> */}
              <Row gutter={0}>
                <Col span={23}>
                  <Form.Item className="m-0">
                    {getFieldDecorator("description", {
                      initialValue: description,
                      rules: [
                        {
                          required: true,
                          message: "Enter scene description!",
                        },
                      ],
                    })(
                      <TextArea
                        className="caps"
                        disabled
                        rows={2}
                        style={{ width: "98.2%", display: "flex" }}
                        // onChange={e =>
                        //   this.setState({
                        //     description: e.target.value
                        //   })
                        // }
                      />
                    )}
                  </Form.Item>
                </Col>
                {checkFor(
                  this.props.activePermisision,
                  GlobalConstants.PERMISSION_EDIT
                ) ? (
                  <Col span={1}>
                    <div
                      onClick={(e) => {
                        this.handleDescription(e);
                      }}
                      style={{
                        // border: "1px solid #e3e3e3",
                        // backgroundColor: "#2da01c",
                        color: "#2da01c",
                        marginLeft: "-11px",
                        height: "34px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        // marginTop: "3px"
                        width: "30px",
                      }}
                    >
                      <Tooltip
                        title={GlobalConstants.EDT}
                        placement={GlobalConstants.RIGHT}
                      >
                        {" "}
                        <Icon type="edit" />
                      </Tooltip>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <Modal
                destroyOnClose
                visible={this.state.descriptionVisible}
                title="Edit Description"
                className="createModal no-x"
                onOk={this.handleDescriptionOk}
                onCancel={this.handleDescriptionCancel}
                footer={
                  <Row
                    gutter={12}
                    type="flex"
                    justify="end"
                    style={{ marginTop: "20px" }}
                  >
                    <Col>
                      <Button onClick={this.handleDescriptionCancel}>
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={(e) => {
                          this.updateSceneLocation("Description");
                        }}
                      >
                        Ok
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Form>
                  <Row className="mt-5" style={{ marginTop: "10px" }}>
                    <Col
                      xl={{ span: 6, offset: 4 }}
                      className=" mt-5 veritical-center"
                    >
                      <label
                        className="font-600"
                        label="Username"
                        style={{ marginTop: "5px" }}
                      >
                        Description
                      </label>
                    </Col>
                    <Col xl={{ span: 10 }}>
                      <Form.Item>
                        {getFieldDecorator("description", {
                          initialValue: description || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Enter Scene description!",
                            },
                          ],
                        })(
                          <TextArea
                            placeholder="Description"
                            className="mt-5 caps"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              this.setState({
                                description: StartCase(e.target.value),
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 11 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 7 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">
                    Environment &nbsp; :
                  </h3>
                </Col>
                <Col
                  xs={{ span: 23 }}
                  md={{ span: 10 }}
                  lg={{ span: 14 }}
                  className="pl-15"
                >
                  {/* <Search
                  value="INT/DAY"
                  enterButton={<Icon type="edit" />}
                  onSearch={this.handleEditEnvironment}
                /> */}
                  <Row gutter={0}>
                    <Col span={22}>
                      <Form.Item className="m-0">
                        {getFieldDecorator("environment", {
                          initialValue:
                            environmentType &&
                            environmentTime &&
                            `${environmentType} / ${environmentTime}`,
                        })(
                          <Input
                            disabled
                            // placeholder={`${environmentType}/${environmentTime}`}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {checkFor(
                      this.props.activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    ) ? (
                      <Col span={2}>
                        <div
                          onClick={this.handleEditEnvironment}
                          style={{
                            // border: "1px solid #e3e3e3",
                            // backgroundColor: "#2da01c",
                            color: "#2da01c",
                            marginLeft: "5px",
                            height: "34px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginTop: "3px",
                          }}
                        >
                          <Tooltip
                            title={GlobalConstants.EDT}
                            placement={GlobalConstants.RIGHT}
                          >
                            <Icon type="edit" />
                          </Tooltip>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Modal
                    destroyOnClose
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    title="Edit Environment"
                    className="createModal no-x"
                    onCancel={this.handleCancel}
                    footer={
                      <Row
                        gutter={12}
                        type="flex"
                        justify="end"
                        style={{ marginTop: "20px" }}
                      >
                        <Col>
                          <Button onClick={this.handleCancel}>Cancel</Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            // disabled={
                            //   (!checkFor(
                            //     this.props.activePermisision,
                            //     GlobalConstants.PERMISSION_EDIT
                            //   ))}

                            onClick={(e) => {
                              this.handleUpdate(e, this.state.sceneId);
                            }}
                          >
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <Form>
                      <Row gutter={16} className="mt-5">
                        <Col xl={{ span: 12 }}>
                          <label className="font-600" label="Username">
                            INT/EXT :
                          </label>
                          <Form.Item>
                            {getFieldDecorator("environmentType", {
                              initialValue: environmentType || undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "Enter Environment!",
                                },
                              ],
                            })(
                              <Select
                                placeholder="Select Environment Time Type"
                                className="w-100"
                                // onChange={makeup =>
                                //   this.setState({
                                //     makeup
                                //   })
                                // }
                                onChange={this.handleEnvironmentTimeType}
                              >
                                {this.state.environmentDataType &&
                                  this.state.environmentDataType.map(
                                    (option, i) => (
                                      <Option value={option.value}>
                                        {option.label}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xl={{ span: 12 }}>
                          <label className="font-600">DAY/NIGHT </label>
                          <Form.Item>
                            {getFieldDecorator("environmentTime", {
                              initialValue: environmentTime || undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "Select Environment Time!",
                                },
                              ],
                            })(
                              <Select
                                placeholder="Select Environment Time Type"
                                className="w-100"
                                // onChange={makeup =>
                                //   this.setState({
                                //     makeup
                                //   })
                                // }
                                onChange={this.handleEnvironmentDayType}
                              >
                                {this.state.environmentDataTime &&
                                  this.state.environmentDataTime.map(
                                    (option, i) => (
                                      <Option value={option.value}>
                                        {option.label}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                </Col>
              </Row>
              {/* <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 7 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600">ART / SET &nbsp; :</h3>
                </Col>
                <Col
                  md={{ span: 10 }}
                  xs={{ span: 23 }}
                  lg={{ span: 14 }}
                  className="pl-15"
                >
                  <Form.Item>
                    {getFieldDecorator("art_set", {
                      initialValue: art_set,
                      rules: [{ required: true, message: "Select Art / Set!" }]
                    })(
                      <Select
                        placeholder="Select Art / Set"
                        className="w-100"
                        onChange={this.handleArtSet}
                      >
                        <Option value="Live">Live Location</Option>
                        <Option value="Set">Set</Option>
                        <Option value="Live and Set">Live and Set</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 7 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">Makeup &nbsp; :</h3>
                </Col>
                <Col
                  md={{ span: 10 }}
                  xs={{ span: 23 }}
                  lg={{ span: 14 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("makeup", {
                      initialValue: makeup || undefined
                      // rules: [
                      //   { required: true, message: "Select Makeup Type!" }
                      // ]
                    })(
                      <Select
                        placeholder="Select Makeup Type"
                        className="w-100"
                        // onChange={makeup =>
                        //   this.setState({
                        //     makeup
                        //   })
                        // }
                        onChange={this.handleMakeupType}
                      >
                        {this.state.makeupData &&
                          this.state.makeupData.map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row> */}
              <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 7 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0"> VFX &nbsp; :</h3>
                </Col>
                <Col md={{ span: 10 }} lg={{ span: 14 }} xs={{ span: 23 }}>
                  <div className="text-left ml-15">
                    <Form.Item className="m-0">
                      {getFieldDecorator("vfx", {
                        initialValue: vfx || undefined,
                      })(
                        <Radio.Group
                          name="radiogroup"
                          onChange={this.handleVfxTabs}
                          disabled={
                            !checkFor(
                              this.props.activePermisision,
                              GlobalConstants.PERMISSION_EDIT
                            )
                          }
                        >
                          <Radio value={"Yes"}>Yes</Radio>
                          <Radio value={"No"}>No</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 7 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">
                    {" "}
                    Shooting Status &nbsp; :
                  </h3>
                </Col>
                <Col md={{ span: 10 }} lg={{ span: 14 }} xs={{ span: 23 }}>
                  {detailsFetched && (
                    <div className="text-left ml-15">
                      <Tag className={`statusTag ${state}`}>
                        {ReplaceWithSpace(state)}
                      </Tag>
                      {/* <Form.Item className="m-0">
                        {getFieldDecorator("state", {
                          initialValue: state
                        })(
                          <Select
                            style={{ width: 150 }}
                            onChange={this.handleStatusChange}
                            value={state}
                            disabled={
                              !checkFor(
                                this.props.activePermisision,
                                GlobalConstants.PERMISSION_EDIT
                              )
                            }
                          >
                            {StatusList &&
                              StatusList.map((status, i) => (
                                <Option value={status.value} key={i}>
                                  {status.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item> */}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>

            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }}>
              <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 10 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">
                    Scene Type &nbsp; :
                  </h3>
                </Col>
                <Col md={{ span: 10 }} xs={{ span: 23 }} lg={{ span: 12 }}>
                  <div className="text-left ml-15 pt-3">
                    <Form.Item className="m-0">
                      {getFieldDecorator("scene_type", {
                        initialValue: sceneType || undefined,
                        rules: [
                          {
                            required: false,
                            message: "Select Scene Type!",
                          },
                        ],
                      })(
                        <Checkbox.Group
                          style={{ textTransform: "capitalize" }}
                          options={this.state.plainOptions}
                          onChange={this.handleSceneType}
                          disabled={
                            !checkFor(
                              this.props.activePermisision,
                              GlobalConstants.PERMISSION_EDIT
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {/* <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 10 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 m-0">Costumes &nbsp; :</h3>
                </Col>
                <Col 
                  md={{ span: 10 }}
                  xs={{ span: 23 }}
                  lg={{ span: 12 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("costumes", {
                      initialValue: costumes || undefined
                      // rules: [
                      //   {
                      //     required: true,
                      //     message: "Select Costumes Type!"
                      //   }
                      // ]
                    })(
                      <Select
                        placeholder="Select Costume Type"
                        className="w-100"
                        // onChange={makeup =>
                        //   this.setState({
                        //     makeup
                        //   })
                        // }
                        onChange={this.handleCostumeType}
                      >
                        {this.state.costumeData &&
                          this.state.costumeData.map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row className="mb-25 veritical-center">
                <Col
                  lg={{ span: 10 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 pt-3 m-0">
                    Approx Shooting Time &nbsp; :
                  </h3>
                </Col>
                <Col md={{ span: 10 }} xs={{ span: 23 }} lg={{ span: 12 }}>
                  <div className="text-left ml-15 pt-3">
                    <Form.Item className="m-0">
                      {getFieldDecorator("time_required", {
                        initialValue: timeToShoot || undefined
                        // rules: [
                        //   {
                        //     required: true,
                        //     message: "Enter time required to shoot!"
                        //   }
                        // ]
                      })(
                        <Input
                          className="w-100"
                          onChange={value => {
                            this.setState({
                              timeToShoot: numberOnly(value)
                            });
                          }}
                          addonAfter={
                            <Form.Item className="m-0 requiredTime">
                              {getFieldDecorator("reuiredTimeType", {
                                initialValue: this.state.timeRequiredTimeType
                              })(
                                <Select
                                  placeholder="Select Shift Type"
                                  // className="w-100"
                                  style={{ width: "150px" }}
                                  // onChange={value =>
                                  //   this.setState({
                                  //     timeRequiredTimeType:value
                                  //   })
                                  // }
                                  onChange={this.handleRequiredTimeType}
                                >
                                  {this.state.timeRequiredType &&
                                    this.state.timeRequiredType.map(
                                      (option, i) => (
                                        <Option value={option.value}>
                                          {option.label}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              )}
                            </Form.Item>
                          }
                          placeholder="Time required"
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row> */}

              {/* <Row>
            <Col xl={{ span: 22, offset: 1 }}> */}
              <Row
                className="mb-25 veritical-center"
                type="flex"
                justify="start"
              >
                <Col
                  lg={{ span: 10 }}
                  md={{ span: 10 }}
                  xs={{ span: 23, offset: 1 }}
                >
                  <h3 className="text-right font-600 pt-3 m-0">
                    Approx Scene Duration &nbsp; :
                  </h3>
                </Col>
                <Col lg={7} style={{ paddingLeft: 15 }}>
                  <Row>
                    <Col xl={10}>
                      <Form.Item className="m-0">
                        {getFieldDecorator("minute", {
                          initialValue: minute || undefined,
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: "Enter time required to shoot!"
                          //   }
                          // ]
                        })(
                          <Input
                            className="w-100"
                            placeholder="Minutes"
                            disabled={
                              !checkFor(
                                this.props.activePermisision,
                                GlobalConstants.PERMISSION_EDIT
                              )
                            }
                            onBlur={this.handleapproxMinute}
                            onChange={(value) => {
                              this.setState({
                                minute: numberOnly(value),
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col
                      xl={2}
                      style={{
                        textAlign: "center",
                        paddingTop: "8px",
                        fontWeight: "bolder",
                      }}
                    >
                      :
                    </Col>
                    <Col xl={10}>
                      <Form.Item className="m-0">
                        {getFieldDecorator("second", {
                          initialValue: this.state.second || undefined,
                          rules: [
                            {
                              required: false,
                              message: "Please Enter Seconds!",
                            },
                            {
                              pattern: new RegExp("^[0-5]?[0-9]$"),
                              message: "Please Enter Below 60 Seconds",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Seconds"
                            className="w-100"
                            maxLength="2"
                            disabled={
                              !checkFor(
                                this.props.activePermisision,
                                GlobalConstants.PERMISSION_EDIT
                              )
                            }
                            onBlur={this.handleapproxSecond}
                            onChange={(value) =>
                              this.setState({
                                second: numberOnly(value),
                              })
                            }
                            // onChange={this.handleTimeRequiredType}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* </Col>
        </Row> */}

          {/* <Row
            gutter={12}
            type="flex"
            justify="end"
            style={{ marginTop: "20px", marginRight: "71px" }}
          >
            <Col>
              <Link to="/project/script">
                <Button>Back</Button>
              </Link>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={this.generalSceneSubmit}
                loading={this.state.isLoading}
                disabled={
                  !checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  )
                }
              >
                Update
              </Button>
            </Col>
          </Row> */}
        </Form>
      </div>
    );
  }
}

const GeneralScriptComponent = Form.create()(GeneralScriptDetails);
const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Script Breakdown", menus),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    setActiveSceneLocationId: (activeSceneLocationId) =>
      dispatch(setActiveSceneLocationId(activeSceneLocationId)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralScriptComponent);
