import { LocalStorage, Services } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class ActorTabService {
  actorTabService(type, data, charc, sceneId = null) {
    let finalSceneId = "";
    if (sceneId) {
      finalSceneId = sceneId;
    } else {
      finalSceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    }
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    switch (type) {
      case Services.SceneOVerviewVariable.GET_ACTOR:
        return axios.get(
          `${getScriptAPIURL()}/scene/${finalSceneId}/characters?types=${data}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_JUNIOR_ANIMAL_ACTOR:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/scene/${finalSceneId}?name=${data}&type=${charc}`,
          config
        );
      case Services.SceneOVerviewVariable.ADD_ACTOR:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/characters`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.DELETE_ACTOR:
        return axios.delete(
          `${getScriptAPIURL()}/scenes/${finalSceneId}/characters/${data}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_CHARC_NAME:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/characters?names=${data}&type=${charc}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_ACTORS_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/characters/${data}`,
          config
        );
      case Services.SceneOVerviewVariable.ADD_JUNIOR_ACTOR:
        return axios.post(`${getScriptAPIURL()}/characters`, data, config);
      case Services.SceneOVerviewVariable.ADD_ANIMAL_ACTOR:
        return axios.post(`${getScriptAPIURL()}/characters`, data, config);

      case Services.SceneOVerviewVariable.DEL_ACTOR_CARD:
        return axios.delete(
          `${getScriptAPIURL()}/actor-audition/${data}`,
          config
        );
      case Services.SceneOVerviewVariable.EDIT_JUNIOR_ACTOR:
        return axios.put(
          `${getScriptAPIURL()}/characters/${charc}`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.EDIT_ANIMAL_ACTOR:
        return axios.put(
          `${getScriptAPIURL()}/characters/${charc}`,
          data,
          config
        );
      case Services.ActorVariables.GET_JUNIOR_CHARC:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/scenes/${finalSceneId}/junior/characters?name=${data}&type=JUNIOR`,
          config
        );
      case Services.ActorVariables.GET_ANIMAL_CHARC:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/scenes/${finalSceneId}/junior/characters?name=${data}&type=ANIMAL`,
          config
        );
      default:
        break;
    }
  }
}
