import React, { Component } from "react";

import "./videoplayer.css";

class VideoPlayer extends Component {
  render() {
    const { title, videoSource } = this.props;
    return (
      <div className="container">
        <span className="title">{title}</span>
        <iframe
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          className="player"
          type="text/html"
          src={videoSource}
          frameBorder="0"
        />
      </div>
    );
  }
}

export default VideoPlayer;
