import React, { Component, Fragment } from "react";
import {
  Table,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Tag,
  Modal,
  Icon,
  Tooltip,
  Popconfirm,
} from "antd";
import { omit, keys, isEmpty } from "lodash";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Resizable } from "react-resizable";
import moment from "moment";

import Title from "components/shared/TitleComponent";
import PDFComponent from "components/shared/PDFViewer";
import TitleText from "components/shared/TItleText";

import {
  MenusRoute,
  GlobalConstants,
  Services,
  LocalStorage,
  Notifications,
  Icons,
} from "constants/AppConstants";
import SchedularService from "services/SchedularServices";
import GlobalService from "services/GlobalService";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";

import "./scheduler.scss";
import SchedularActorAvailabilityTable from "../SchedularActorAvailabilityTable";
import { handleError } from "utilities/ErrorHandler";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import { connect } from "react-redux";
import {
  checkFor,
  Capitalize,
  checkDateGreaterThan,
} from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import TooltipComponent from "components/shared/TooltipComponent";
import FilterFormScheduler from "components/shared/FIlterForm/FilterFormScheduler";
import Axios from "axios";
import TooltipIcon from "components/shared/TooltipIcon";
import ThemeColors from "constants/ThemeConstants/variables";
import { Link } from "react-router-dom";
import { getScriptAPIURL } from "utilities/APIHelper";
import IconComponent from "components/shared/IconComponent";

const dateFormat = "DD-MM-YYYY";

let dragingIndex = -1;

const { Option } = Select;

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

class BodyRow extends Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

class Schedular extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._schedularService = new SchedularService();
    this.state = {
      callSheetOptions: [],
      visible: false,
      value: null,
      scheduleBy: GlobalConstants.ACTUAL_LOCATION_CAPS,
      scheduledFor: GlobalConstants.ACTUAL_LOCATION_CAPS,
      data: [],
      isScheduleDataLoading: false,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      actorList: [],
      activeMainActor: null,
      isModalVisible: false,
      shootDate: null,
      totalRecords: [],
      isCopyEnabled: false,
      isSearchActive: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleClear = this.handleClear.bind(this);
  }

  handleSubmit(
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = [],
    EnvironmentType = [],
    Description = "",
    ActualLocationSelectedKeys,
    CallSheet,
    ShootStartDate,
    ShootEndDate,
    selectedCharacters,
    selectedActors
  ) {
    this.setState({
      isDataFetched: false,
      isSearchActive: true,
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
    });
    this.search(
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
      selectedCharacters,
      selectedActors
    );
  }

  search = (
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = [],
    EnvironmentType = [],
    Description = "",
    ActualLocationSelectedKeys = [],
    CallSheet = "",
    ShootStartDate = "",
    ShootEndDate = "",
    selectedCharacters = [],
    selectedActors = []
  ) => {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let queryParams = {
      type: "ACTUAL_LOCATION",
      description: Description,
      environmentType:
        EnvironmentType != "" ? EnvironmentType.join(",") : EnvironmentType,
      sceneLocationIds: LocationSelectedKeys.join(","),
      sceneNumbers: SceneNoSelectedKeys.join(","),
      timeType:
        EnvironmentTime != "" ? EnvironmentTime.join(",") : EnvironmentTime,
      callSheets: CallSheet != "" ? CallSheet.join(",") : CallSheet,
      locationIds: ActualLocationSelectedKeys.join(","),
      startShootDate: ShootStartDate,
      endShootDate: ShootEndDate,
      actorIds: selectedActors.join(","),
      characterIds: selectedCharacters.join(","),
    };
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
      params: queryParams,
    };
    Axios.get(`${getScriptAPIURL()}/projects/${projectId}/schedulers`, config)
      .then((res) => {
        let data = [];
        res.data.scenes &&
          res.data.scenes.map((datum, i) => {
            data.push({ ...datum, rowIndex: i });
          });
        this.setState({
          isScheduleDataLoading: false,
          data,
          totalRecords: data ? JSON.parse(JSON.stringify(data)) : [],
          isCopyEnabled: false,
        });
      })
      .catch((err) => {
        this.setState({ isScheduleDataLoading: false });
      });
  };

  handleCopy = (record) => {
    const { data } = this.state;
    const { rowIndex } = record;
    const removeFields = {
      sceneScheduleId: null,
      shootingDate: null,
      callSheet: null,
      callSheetEnum: undefined,
      colorCode: null,
    };
    this.setState({
      isCopyEnabled: true,
    });
    let elementToInsert = JSON.parse(JSON.stringify(record));
    let finalData = [];
    elementToInsert = omit(elementToInsert, keys(removeFields));
    elementToInsert = {
      ...elementToInsert,
      ...removeFields,
    };
    elementToInsert.isDeleteEnable = true;
    data.splice(rowIndex + 1, 0, elementToInsert);
    data && data.map((datum, i) => finalData.push({ ...datum, rowIndex: i }));
    this.setState({ data: finalData });
  };

  handleDelete = (record) => {
    const { data } = this.state;
    data.splice(record.rowIndex, 1);
    new Promise((resolve) => {
      this.setState({
        data: [],
      });
      resolve();
    }).then(() => {
      this.setState({
        data: JSON.parse(JSON.stringify(data)),
        isScheduleDataLoading: false,
        isCopyEnabled: false,
      });
    });
  };

  componentDidMount() {
    setTimeout(() => {
      this.fetchSchedularList();
    }, 500);
    this.fetchActorList();
    this.getCallSheet();
  }
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  getCallSheet = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        Services.GlobalVariables.CALL_SHEET
      )
      .then((res) => {
        this.setState({
          callSheetOptions: res.data,
        });
      })
      .catch((err) => {});
  };

  fetchSchedularList = () => {
    this.setState({
      isScheduleDataLoading: true,
      scheduledFor: this.state.scheduleBy,
      count: 0,
    });
    const {
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
    } = this.state;
    if (
      this.hasSearchKeys(
        SceneNoSelectedKeys,
        LocationSelectedKeys,
        EnvironmentTime,
        EnvironmentType,
        Description,
        ActualLocationSelectedKeys,
        CallSheet,
        ShootStartDate,
        ShootEndDate
      )
    ) {
      this.search(
        SceneNoSelectedKeys,
        LocationSelectedKeys,
        EnvironmentTime,
        EnvironmentType,
        Description
      );
    } else {
      this._schedularService
        .services(
          Services.SchedularVariables.GET_LIST,
          this.state.projectId,
          null,
          this.state.scheduleBy,
          this.state.activeMainActor
        )
        .then((res) => {
          let data = [];
          res.data.scenes &&
            res.data.scenes.map((datum, i) => {
              if (datum.shootingDate) {
                datum.showAttendance = moment(datum.shootingDate).isSameOrAfter(
                  moment(new Date())
                );
              } else {
                datum.showAttendance = false;
              }
              datum.location = `${datum?.location || ""} - ${
                datum?.actualLocationName || ""
              }`;
              data.push({ ...datum, rowIndex: i });
            });
          this.setState({
            isScheduleDataLoading: false,
            data,
            totalRecords: data ? JSON.parse(JSON.stringify(data)) : [],
            isCopyEnabled: false,
          });
        })
        .catch((err) => {
          this.setState({ isScheduleDataLoading: false });
        });
    }
  };

  handleClear = () => {
    this.setState(
      {
        isDataFetched: false,
        isSearchActive: false,
        SceneNoSelectedKeys: [],
        LocationSelectedKeys: [],
        EnvironmentTime: "",
        EnvironmentType: "",
        Description: "",
        ActualLocationSelectedKeys: [],
        CallSheet: "",
        ShootStartDate: "",
        ShootEndDate: "",
      },
      () => this.fetchSchedularList()
    );

    // this.getScriptBreakdownData();
    //e.preventDefault();
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  fetchActorList = () => {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    this._schedularService
      .services(Services.SchedularVariables.GET_MAIN_ACTORS, projectId)
      .then((res) => {
        this.setState({
          actorList: res.data.scenes,
        });
      })
      .catch((err) => {});
  };

  refreshList = () => {
    this._schedularService
      .services(
        Services.SchedularVariables.GET_LIST,
        this.state.projectId,
        null,
        this.state.scheduleBy
      )
      .then((res) => {
        this.setState({ data: res.data.scenes });
      })
      .catch((err) => {});
  };

  onDateChange(value, dateString, record, type) {
    const { data } = this.state;
    const index = record.rowIndex;
    if (value) {
      if (type === "shootDate") {
        let shootDate = null;
        // if (record.shootingEndDate) {
        //   shootDate = moment(dateString, dateFormat).format("YYYY-MM-DD");
        // }
        // let shootEndDate = moment(record.shootingEndDate).format("YYYY-MM-DD");
        if (shootDate !== null) {
          // if (shootDate <= shootEndDate) {
          //   let data = {
          //     callSheet: record.callSheetEnum,
          //     shootDate: shootDate
          //   };
          //   this.handleUpdate(record.sceneId, data, record);
          // } else {
          let data = {
            callSheet: record.callSheetEnum,
            // shootEndDate: dateString,
            shootDate: shootDate,
          };
          this.handleUpdate(record.sceneId, data, record);
          // showNotifications(
          //   Notifications.ERROR,
          //   `"TO DATE" Is Greater Than "FROM DATE"`,
          //   " "
          // )
          // }
        } else {
          let data = {
            callSheet: record.callSheetEnum,
            // shootEndDate: dateString,
            shootDate: moment(dateString, dateFormat).format("YYYY-MM-DD"),
          };
          this.handleUpdate(record.sceneId, data, record);
        }
      } else if (type === "shootEndDate") {
        let shootDate = moment(record.shootingDate).format("YYYY-MM-DD");
        let shootEndDate = moment(dateString, dateFormat).format("YYYY-MM-DD");
        if (record.shootingDate) {
          if (shootDate <= shootEndDate) {
            let data = {
              callSheet: record.callSheetEnum,
              // shootEndDate: dateString,
              shootDate: moment(record.shootingDate).format(dateFormat),
            };
            this.handleUpdate(record.sceneId, data, record);
          } else {
            showNotifications(
              Notifications.ERROR,
              `"TO DATE" Is Greater Than "FROM DATE"`,
              " "
            );
          }
        }
      }
    } else {
      this.handleRowDelete(null, record.sceneScheduleId, true);
      let newData = [...data];
      newData = newData.map((datum) => {
        if (datum.rowIndex === index) {
          return {
            ...datum,
            shootingDate: value,
          };
        }
        return datum;
      });
      this.setState({
        data: newData,
      });
    }
  }

  handleCallSheetChange = (record, callSheet) => {
    let data = {
      callSheet,
      shootDate: record.shootingDate,
    };
    if (record.shootingDate) {
      this.handleUpdate(record.sceneId, data, record);
    }
  };

  handleUpdate = (sceneId, data, record) => {
    let shootEndDate = "";
    let shootDate = moment(record.shootingDate).format("DD-MM-YYYY") || "";
    if (data.shootEndDate) {
      shootEndDate = data.shootEndDate;
      data.shootDate = moment(data.shootDate).format("DD-MM-YYYY");
      data.sceneId = record.sceneId;
      data.sceneSchedulerId = record.sceneScheduleId;
      delete data.shootEndDate;
    } else if (data.shootDate) {
      if (!moment(data.shootDate, "DD-MM-YYYY", true).isValid()) {
        data.shootDate = moment(data.shootDate).format("DD-MM-YYYY");
      }
      // shootDate = moment(data.shootDate).format(dateFormat);
      data.sceneId = record.sceneId;
      data.sceneSchedulerId = record.sceneScheduleId;
      // this.setState({

      // });
    }
    this.setState({
      isScheduleDataLoading: true,
      activeData: {
        sceneId,
        data,
        record,
      },
    });

    this._schedularService
      .services(
        Services.SchedularVariables.SHOOT_ACTOR_AVAILABILITY,
        localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        record.sceneId,
        data.shootDate,
        shootEndDate,
        record.sceneLocationId
      )
      .then((res) => {
        this.setState({
          isModalVisible: false,
        });
        this._schedularService
          .services(
            Services.SchedularVariables.UPDATE_DATE_CALL_SHEET,
            null,
            record.sceneScheduleId,
            data
          )
          .then((res) => {
            this.setState({ activeData: null });
            this.fetchSchedularList();
            this.props.getNotificationCount();
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
          })
          .catch((err) => {
            this.props.getPermissions();
            this.props.getNotificationCount();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
            this.setState({
              isScheduleDataLoading: false,
            });
          });
      })
      .catch((err) => {
        if (!this.state.isModalVisible) {
          let datas = err.response.data.errors;
          let dataSource = [];
          datas &&
            datas.map((dataList) => {
              let availabilities = dataList.availabilities;
              availabilities &&
                availabilities.map((list, i) => {
                  let actorDetails = Object.assign({}, list, {
                    actorId: dataList.actorId,
                    actorName: dataList.actorName,
                    locationName: dataList.locationrName,
                    count: i + 1,
                    fromDate:
                      moment(dataList?.fromDate).format("DD-MM-YYYY") ||
                      dataList?.fromDate,
                    toDate:
                      moment(dataList?.toDate).format("DD-MM-YYYY") ||
                      dataList?.toDate,
                  });
                  dataSource.push(actorDetails);
                });
            });
          this.setState({
            isModalVisible: true,
            availabilitiesDataSource: [dataSource],
          });
        } else {
          this._schedularService
            .services(
              Services.SchedularVariables.UPDATE_DATE_CALL_SHEET,
              null,
              sceneId,
              data
            )
            .then((res) => {
              this.setState({ activeData: null, isModalVisible: false });
              this.fetchSchedularList();
              showActionMessage(
                GlobalConstants.SUCCESS,
                GlobalConstants.CHANGES_HAVE_BEEN_SAVED
              );
            })
            .catch((err) => {
              this.setState({
                isScheduleDataLoading: false,
              });
            });
        }

        this.props.getPermissions();
      });
  };

  onChange = ({ target: { value } }) => {
    if (value !== GlobalConstants.ACTORS) {
      this.setState({
        scheduleBy: value,
        activeMainActor: null,
      });
    } else {
      this.setState({
        scheduleBy: value,
      });
    }
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    let dataToSend = null;
    let sourceData = data[dragIndex];
    let destinationData = data[hoverIndex];
    if (destinationData.shootingDate && sourceData.shootingDate !== null) {
      let dataToSend = {
        sourceSceneId: sourceData.sceneId,
        targetSceneId: destinationData.sceneId,
        sourceSceneScheduleId: sourceData.sceneScheduleId,
        targetSceneScheduleId: destinationData.sceneScheduleId,
      };
      this._schedularService
        .services(
          Services.SchedularVariables.SWAP_SHOOT_DATE,
          this.state.projectId,
          null,
          dataToSend
        )
        .then((res) => {
          this.fetchSchedularList();

          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong"
          );
        });
    } else if (sourceData.shootingDate === null) {
      let shootingData = {
        callSheet: sourceData.callSheetEnum,
        shootDate: destinationData.shootingDate,
      };
      this.handleUpdate(sourceData.sceneId, shootingData, sourceData);
    } else {
      showNotifications(
        Notifications.ERROR,
        `"Shooting Date" is Mandatory`,
        " "
      );
    }
  };

  handleResize =
    (index) =>
    (e, { size }) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };

  onActorChange = (activeMainActor) => {
    this.setState({ activeMainActor });
  };

  makeSchedule = () => {
    this.setState({
      isScheduleDataLoading: true,
      scheduledFor: this.state.scheduleBy,
    });
    let activeMainActor = null;
    if (this.state.scheduleBy === GlobalConstants.ACTORS) {
      activeMainActor = this.state.activeMainActor;
    }
    this._schedularService
      .services(
        Services.SchedularVariables.GET_LIST,
        this.state.projectId,
        null,
        this.state.scheduleBy,
        activeMainActor
      )
      .then((res) => {
        this.setState({
          data: res.data.scenes,
          isScheduleDataLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isScheduleDataLoading: false,
        });
      });
  };

  handleModalCancel = () => {
    this.setState(
      {
        isModalVisible: false,
        isScheduleDataLoading: false,
      },
      () => this.fetchSchedularList()
    );
  };

  handleRowDelete = (key, id, isShootDate = false) => {
    let sceneSchedulerId = id;
    this._schedularService
      .services(
        Services.SchedularVariables.DELETE_SCHEDULE,
        sceneSchedulerId,
        null,
        isShootDate
      )
      .then((res) => {
        this.fetchSchedularList();
        this.props.getNotificationCount();
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Deleted Successfully"
        );
      })
      .catch((err) => {
        this.fetchSchedularList();

        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSubmit(
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = [],
    EnvironmentType = [],
    Description = "",
    ActualLocationSelectedKeys,
    CallSheet,
    ShootStartDate,
    ShootEndDate,
    selectedCharacters,
    selectedActors
  ) {
    this.setState({
      isDataFetched: false,
      isSearchActive: true,
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
    });
    this.search(
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
      selectedActors,
      selectedCharacters
    );
  }

  renderTooltipText = (data) => {
    let template = [];
    data.map((r, v) => {
      template.push(<li>{Capitalize(r)}</li>);
    });
    return <ul className="tooltipBullet">{template}</ul>;
  };

  hasSearchKeys = (
    SceneNoSelectedKeys = [],
    LocationSelectedKeys = [],
    EnvironmentTime = null,
    EnvironmentType = null,
    Description = null,
    ActualLocationSelectedKeys = [],
    CallSheet = null,
    ShootStartDate = null,
    ShootEndDate = null
  ) =>
    !isEmpty(SceneNoSelectedKeys) ||
    !isEmpty(LocationSelectedKeys) ||
    !isEmpty(EnvironmentTime) ||
    !isEmpty(EnvironmentType) ||
    Description ||
    !isEmpty(ActualLocationSelectedKeys) ||
    CallSheet ||
    ShootStartDate ||
    ShootEndDate;

  navigateToBudgetProductionPage(rowInfo) {
    let shootDate = moment(rowInfo.shootingDate).format("DD-MM-YYYY");
    this.props.history.push(`/project/update-production/${shootDate}/edit`);
  }
  render() {
    const {
      scheduleBy,
      isScheduleDataLoading,
      data,
      actorList,
      activeMainActor,
      scheduledFor,
      totalRecords,
      activeData,
      isCopyEnabled,
    } = this.state;
    const { activePermisision } = this.props;
    const hasNewRow = data && data.length === totalRecords.length + 1;
    const columns = [
      {
        title: "Shooting date",
        dataIndex: "shootingDate",
        align: "center",
        width: "6%",
        render: (text, record) => (
          <div className="tdContainer">
            <div
              className="strip"
              style={{ backgroundColor: record.colorCode }}
            ></div>
            <div className="childDiv" style={{ justifyContent: "center" }}>
              <DatePicker
                placeholder="Select Date"
                style={{ width: "90%", paddingLeft: "5px" }}
                value={text ? moment(text, dateFormat) : ""}
                onChange={(value, dateString) =>
                  this.onDateChange(value, dateString, record, "shootDate")
                }
                format={dateFormat}
                disabledDate={this.disabledDate}
                allowClear={true}
                disabled={
                  !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
                }
              />
            </div>
          </div>
        ),
      },
      {
        title: "Call Sheet",
        dataIndex: "callSheet",
        align: "center",
        width: "6%",
        render: (text, record) => (
          <div className="tdContainer">
            <div className="childDiv" style={{ justifyContent: "center" }}>
              <Select
                value={text}
                style={{ width: "90%" }}
                onChange={(value) => {
                  this.handleCallSheetChange(record, value);
                }}
                placeholder="Select Callsheet"
                disabled={
                  !record.shootingDate ||
                  !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
                }
              >
                {this.state.callSheetOptions &&
                  this.state.callSheetOptions.map((option) => (
                    <Option value={option.value}>{option.label}</Option>
                  ))}
              </Select>
            </div>
          </div>
        ),
      },
      {
        title: "Scene No",
        dataIndex: "sceneNumber",
        align: "center",
        width: "5%",
        render: (text, record) => (
          <Tag
            className={`${record.environmentType}${record.timeType} sceneno-tag`}
          >
            <Link
              style={{
                color: "white",
              }}
              to="/project/screen-overview"
              onClick={() => {
                localStorage.setItem(
                  LocalStorage.ACTIVE_SCENE_ID,
                  record.sceneId
                );
              }}
            >
              {record.sceneNumber}
            </Link>
          </Tag>
        ),
      },
      {
        title: "Env",
        dataIndex: "environmentType",
        align: "center",
        width: "7%",
        render: (text, record) => (
          // <div
          //   className="tdContainer"
          //   // style={{ backgroundColor: record.colorCode }}
          // >
          <div className="childDiv" style={{ padding: "0px 5px" }}>
            {record.environmentType}/{record.timeType}
          </div>
          // </div>
        ),
      },
      {
        title: "Scene Description",
        dataIndex: "description",
        align: "center",
        width: "15%",
        render: (text, record) => (
          <div
            className="tdContainer"
            style={{
              // backgroundColor: record.colorCode,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="childDivDes">
              {<TooltipComponent text={Capitalize(text)}></TooltipComponent>}
            </div>
          </div>
        ),
      },
      {
        title: "Scene Location - Actual Location",
        dataIndex: "location",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <div className="tdContainer">
            <div className="childDiv" style={{ padding: "0px 5px" }}>
              {text}
            </div>
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: "6%",
        render: (text, record) => {
          return (
            <div className="tdContainer">
              <div className="childDiv">
                {record.shootingDate &&
                  checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_VIEW
                  ) &&
                  checkFor(
                    this.props.activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) && (
                    <Fragment>
                      <Tooltip
                        title={GlobalConstants.EDT}
                        placement={GlobalConstants.RIGHT}
                      >
                        <Button
                          icon="edit"
                          type="link"
                          style={{
                            color: ThemeColors.primaryColor,
                            fontSize: 15,
                            padding: 5,
                          }}
                          onClick={() => {
                            this.props.history.push(
                              `${MenusRoute.SCHEDULARUPDATE}/${
                                this.state.projectId
                              }/${moment(
                                record?.shootingDate,
                                "DD-MM-YYYY"
                              ).format("YYYY-MM-DD")}`
                            );
                          }}
                        />
                      </Tooltip>
                      {!hasNewRow &&
                        checkFor(
                          this.props.activePermisision,
                          GlobalConstants.PERMISSION_EDIT
                        ) && (
                          <Fragment>
                            <Tooltip
                              title={GlobalConstants.CPY}
                              placement={GlobalConstants.RIGHT}
                            >
                              <Button
                                icon="copy"
                                type="link"
                                style={{
                                  color: ThemeColors.primaryColor,
                                  fontSize: 15,
                                  padding: 5,
                                }}
                                onClick={() => this.handleCopy(record)}
                              />
                            </Tooltip>
                          </Fragment>
                        )}
                    </Fragment>
                  )}
                {record.isDeleteEnable && (
                  <Button
                    icon="delete"
                    type="link"
                    style={{ fontSize: 15, padding: 5 }}
                    onClick={() => {
                      this.setState({ isScheduleDataLoading: true });
                      this.handleDelete(record);
                    }}
                  />
                )}
                {!record.isDeleteEnable &&
                  checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_DELETE
                  ) && (
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() =>
                        this.handleRowDelete(
                          record.rowIndex,
                          record.sceneScheduleId
                        )
                      }
                    >
                      <Tooltip
                        title={GlobalConstants.DEL}
                        placement={GlobalConstants.RIGHT}
                      >
                        <Button
                          icon="delete"
                          type="link"
                          style={{ color: "red", fontSize: 15, padding: 5 }}
                        />
                      </Tooltip>
                    </Popconfirm>
                  )}
                {!record.isDeleteEnable &&
                  record.dateWarnings &&
                  record.dateWarnings.length !== 0 &&
                  !checkDateGreaterThan(
                    moment(record.shootingDate).format(
                      GlobalConstants.DD_MM_YYYY
                    ),
                    moment().format(GlobalConstants.DD_MM_YYYY)
                  ) && (
                    <TooltipIcon
                      titleText={
                        record.dateWarnings &&
                        this.renderTooltipText(record.dateWarnings)
                      }
                      style={{
                        padding: 5,
                        color: "#ffb100",
                      }}
                      icon={"warning"}
                      // onClick={() => this.props.history.push("/login")}
                    />
                  )}
                {/* {!record.isDeleteEnable &&
                  record.sceneScheduleId &&
                  record.shootingDate && (
                    <TooltipIcon
                      style={{
                        padding: 5,
                        color: ThemeColors.primaryColor,
                      }}
                      icon={"car"}
                      onClick={() =>
                        this.props.history.push(
                          `${MenusRoute.TRANSPORT}/${moment(
                            record.shootingDate
                          ).format(GlobalConstants.DD_MM_YYYY)}`
                        )
                      }
                    />
                  )} */}
                {!record.isDeleteEnable &&
                  record.sceneScheduleId &&
                  record.shootingDate &&
                  record.showAttendance && (
                    <TooltipIcon
                      style={{
                        padding: 5,
                        color: ThemeColors.primaryColor,
                      }}
                      icon={"profile"}
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${MenusRoute.ATTENDANCE}/${moment(
                            record.shootingDate
                          ).format(GlobalConstants.DD_MM_YYYY)}`,
                          state: {
                            locationName: record.sceneLocation,
                          },
                        })
                      }
                    />
                  )}
                {checkFor(activePermisision, GlobalConstants.FINANCE) && (
                  <Tooltip
                    title={"Budget Production Page"}
                    placement={GlobalConstants.RIGHT}
                  >
                    <Icon
                      style={{
                        padding: 5,
                        color: ThemeColors.primaryColor,
                      }}
                      onClick={() =>
                        this.navigateToBudgetProductionPage(record)
                      }
                    >
                      <IconComponent name={Icons.BUDJETER} />
                    </Icon>
                  </Tooltip>
                )}
              </div>
            </div>
          );
        },
      },
    ];

    let isMainActorEnabled = scheduleBy === GlobalConstants.ACTORS;
    // let checkIfSameResult = scheduleBy === scheduledFor;

    const columnsResize = columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

    return (
      <Fragment>
        <Row span={24}>
          <Title>
            <TitleText>Scheduler </TitleText>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) && (
              <Col style={{ textAlign: "end" }}>
                <PDFComponent
                  customURL={`${getScriptAPIURL()}/project/${projectId}/scheduler/report?contentDisposition=INLINE`}
                  // icon="download"
                  text="Schedule PDF"
                  projectId={projectId}
                  hideMail
                />
              </Col>
            )}
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row type="flex" align="middle" justify="center">
            <Col xl={23} sm={22}>
              <Row
                type="flex"
                align="middle"
                justify="center"
                className="fieldset-search"
                style={{
                  marginBottom: 0,
                  marginTop: 10,
                  marginLeft: -15,
                  marginRight: -15,
                }}
              >
                <Col xl={24} sm={22}>
                  <FilterFormScheduler
                    handleSubmit={this.handleSubmit}
                    handleClear={this.handleClear}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
        <Row style={{ padding: 10 }}>
          <Col>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) && (
              <DndProvider backend={HTML5Backend}>
                <Table
                  // scroll={{ y: "70vh" }}
                  columns={columnsResize}
                  loading={isScheduleDataLoading}
                  dataSource={data}
                  pagination={false}
                  components={
                    this.state.isSearchActive
                      ? {
                          header: {
                            cell: ResizeableTitle,
                          },
                          body: {},
                        }
                      : {
                          header: {
                            cell: ResizeableTitle,
                          },
                          body: {
                            row: DragableBodyRow,
                          },
                        }
                  }
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow,
                  })}
                  bordered
                  className="schedular-table scroll-default box-shadow"
                />
              </DndProvider>
            )}
          </Col>
        </Row>
        <Modal
          title="Actor/Location Availability"
          className="createModal"
          visible={this.state.isModalVisible}
          onCancel={this.handleModalCancel}
          footer={
            <Row>
              <Button type="default" onClick={this.handleModalCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  this.handleUpdate(
                    this.state.activeData.sceneId,
                    this.state.activeData.data,
                    this.state.activeData.record
                  )
                }
              >
                Save
              </Button>
            </Row>
          }
          width="800px"
        >
          <SchedularActorAvailabilityTable
            pageType="Schedular"
            dataSource={this.state.availabilitiesDataSource}
          />
          <div style={{ paddingTop: 20 }}>
            <Icon type="question-circle" /> Click Save Update the date
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.SCHEDULER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedular);
