import React, { Component } from "react";
import { Table, InputNumber, Icon, Popconfirm, Form } from "antd";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import { Services, Notifications } from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import RecordNotFound from "components/shared/RecordNotFound";

class JuniorArtistTable extends Component {
  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
  }

  deleteCharacter = (id) => {
    console.log(id);
    if (id) {
      this._actorService
        .actorTabService(Services.SceneOVerviewVariable.DELETE_ACTOR, id)
        .then((res) => {
          console.log(res);
          showNotifications(
            Notifications.SUCCESS,
            "Successfully",
            "Character Removed !!"
          );
          this.props.refreshActors();
        });
    }
  };

  render() {
    const columns = [
      // {
      //   title: "#",
      //   dataIndex: "characterId",
      //   align: "center"
      // },
      {
        title: "Type",
        dataIndex: "characterName",
        align: "center",
      },
      {
        title: "Quantity",
        dataIndex: "characterCount",
        align: "center",
      },
      {
        title: "Rate",
        dataIndex: "salary",
        align: "center",
      },
      {
        title: "PaymentType",
        dataIndex: "paymentType",
        align: "center",
      },
      {
        title: "Delete",
        // dataIndex: <Icon type="delete" />,
        align: "center",
        render: (text, record) => (
          <div>
            {}
            <Popconfirm
              title="Are you sure delete this Character?"
              onConfirm={() => this.deleteCharacter(record.characterId)}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">
                <Icon type="delete" />
              </a>
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <div>
        <Table
          bordered
          dataSource={this.props.data}
          columns={columns}
          pagination={false}
          locale={{
            emptyText: <RecordNotFound />,
          }}
        />
      </div>
    );
  }
}

export default JuniorArtistTable;
