import React, { useState, useEffect } from "react";
import GlobalService from "services/GlobalService";
import { Services, GlobalConstants } from "constants/AppConstants";
import { Select } from "antd";

const { Option } = Select;

const PaymentTypeDD = ({ text, record, onChange, type, disabled }) => {
  const [PaymentTypeData, setPaymentTypeData] = useState([]);
  const api = new GlobalService();

  useEffect(() => {
    getPaymentTypeData();
  }, [type, text]);

  const getPaymentTypeData = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_PAYMENT,
        type === "SPECIAL_PROPERTY"
      )
      .then((res) => {
        setPaymentTypeData(res.data);
      });
  };
  return (
    <Select
      style={{ width: "100%" }}
      className={`${!text ? "custom-empty-active" : ""}`}
      placeholder="Select type"
      disabled={disabled}
      onChange={(value) => onChange(value, record, "costType")}
      value={text ? text : ""}
      // disabled={record.type !== "SPECIAL_PROPERTY"}
    >
      {PaymentTypeData &&
        PaymentTypeData.map((option, i) => (
          <Option value={option.value}>{option.label}</Option>
        ))}
    </Select>
  );
};

export default PaymentTypeDD;
