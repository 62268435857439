import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  Icon,
  Popconfirm,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";

import { GlobalConstants, LocalStorage, Menus } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { Services, Notifications } from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";

// import "./CostumeTable.css";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, Capitalize } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import RecordNotFound from "components/shared/RecordNotFound";
import { handleError } from "utilities/ErrorHandler";
import TooltipIcon from "components/shared/TooltipIcon";

const { Option, OptGroup } = Select;

const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title, name } = this.props;
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              name={name}
            />
          )}
        </Form.Item>
      );
    } else if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              name={name}
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
  };
  state = {
    editing: false,
  };
  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave(record, values[e.currentTarget.name], e.currentTarget.name);
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };
  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class GeneralCostumeMakeupTable extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._costumeTabService = new CostumeTabService();
    this.onChange = this.onChange.bind(this);
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      isModalVisible: false,
      fileList: null,
      docFiles: null,
      dropDown: null,
      sceneId: null,
      isLoading: true,
      isButtonLoading: false,
      sceneId: null,
      statusTypeData: [],
      characterNameList: [],
      activeSceneId: null,
      designationTypeValue: null,
      activeRecord: null,
      pageType: props.pageType,
    };
  }
  componentDidMount() {
    this.getCharacterListData();
    this.getStatusData();
    this.getCostumeData();
    // this.checkForEmpty(this.state.dataSource);
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.sceneId) {
      this.setState(
        {
          sceneId: nextProps.activeSceneId,
        },
        () => this.getCostumeData()
      );
    }
  }

  handleFocus = (record) => {
    this.setState({ designationTypeValue: null, eventType: "isNotBlur" });
  };

  designationTypeValue(value, record) {
    if (value.length) {
      delete record.characterName;
      delete record.characterId;
    }
    if (value && value.trim().length > 0) {
      this.setState({
        designationTypeValue: value,
      });
    }
    this.setState({
      isOnChanged: false,
    });
  }

  designationBlurChanges(value, record, eventType) {
    if (this.state.isOnChanged != true) {
      let designation = this.state.designationTypeValue;
      if (designation) {
        this.setState({ eventType });
        record.characterName = designation;
        this.onChange(designation, record, "characterName");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  }

  toggleModal = (activeRecord, activeValue) => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
      activeRecord,
      activeValue,
    });
  };

  getCharacterListData() {
    this._costumeTabService
      .costumeTabService(
        Services.SceneOVerviewVariable.GET_CHARC_NAME_LIST_GENERAL_COSTUME
      )
      .then((res) => {
        this.setState({ characterNameList: Object.values(res.data) });
      });
  }

  getStatusData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({
          statusTypeData: res.data,
        });
      });
  }

  getCostumeData = () => {
    const { pageType } = this.props;
    if (pageType) {
      const type =
        pageType === Menus.MAKEUP
          ? Services.SceneOVerviewVariable.GET_GENERAL_MAKEUP_BY_PROJECT
          : Services.SceneOVerviewVariable.GET_GENERAL_COSTUME_BY_PROJECT;
      this.setState({ count: 0, isLoading: true });
      this._costumeTabService
        .costumeTabService(type)
        .then((res) => {
          let costumeData = [];
          res.data.costumes &&
            res.data.costumes.map((data, i) => {
              costumeData.push({ ...data, key: i });
              return data;
            });
          this.setState(
            {
              dataSource: costumeData,
              totalRecords: costumeData,
              isLoading: false,
              count: costumeData?.length,
            },
            () => {
              // this.checkForEmpty(this.state.dataSource);
            }
          );
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  recordData = (record) => {
    if (this.state.fileList) {
      this.handleSave(record, this.state.fileList, "image");
    }
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this._costumeTabService
      .costumeTabService(
        Services.SceneOVerviewVariable.DELETE_COSTUME_TAB_TABLE,
        id
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Successfully Deleted!!"
        );
        this.setState(
          {
            dataSource: dataSource.filter((item) => item.key !== key),
          },
          () => {
            this.checkForEmpty(this.state.dataSource);
          }
        );
        this.getCostumeData();
      })
      .catch((err) => {
        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  handleSubmit = (record) => {
    const { pageType } = this.props;
    let recordData = {
      cost: record.cost,
      costumeName: record.costumeMakeupName,
      sceneCharacterId: record.sceneCharacterId,
    };
    this.setState({ isButtonLoading: true });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
    let data = this.state.dataSource[indexOfTheCurrentRow];
    const type =
      pageType === Menus.MAKEUP
        ? Services.SceneOVerviewVariable.GENERAL_SAVE_MAKEUP_TAB_TABLE
        : Services.SceneOVerviewVariable.GENERAL_SAVE_COSTUME_TAB_TABLE;
    this._costumeTabService
      .costumeTabService(type, recordData)
      .then((res) => {
        this.setState({
          isButtonLoading: false,
          eventType: null,
          designationTypeValue: null,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          this.props.pageType === Menus.COSTUMES
            ? "New Costume Created!!"
            : "New Makeup Created!!"
        );
        this.getCostumeData();
      })
      .catch((err) => {
        this.props.getPermissions();
        this.setState({
          isButtonLoading: false,
          eventType: null,
          designationTypeValue: null,
        });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  handleUpdate = (record) => {
    const { pageType } = this.props;
    const type =
      pageType === Menus.MAKEUP
        ? Services.SceneOVerviewVariable.UPDATE_MAKEUP_LIST
        : Services.SceneOVerviewVariable.UPDATE_COSTUME_LIST;
    let recordData = {
      cost: record.specialCost,
      sceneCharacterId: record.characterId,
      description: record.costumeMakeupName,
    };
    recordData.cost = record.specialCost;
    this._costumeTabService
      .costumeTabService(type, recordData, record.costumeMakeupId)
      .then((res) => {
        this.setState({
          isButtonLoading: false,
          eventType: null,
          designationTypeValue: null,
        });
        // showNotifications(
        //   Notifications.SUCCESS,
        //   "Success",
        //   "Updated Successfully!!"
        // );
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getCostumeData();
      })
      .catch((err) => {
        this.props.getPermissions();
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      characterName: "",
      description: "",
      costumeMakeupDocument: "",
      actor: "",
      specialCost: "",
      state: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record, type, eventType) {
    this.handleSave(record, value, type);
    this.setState({
      isOnChanged: true,
    });
    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    let modifyItem = null;
    if (value || value === 0) {
      modifyItem = newData[index];
      switch (type) {
        case "characterName":
          if (typeof value === "number") {
            modifyItem.sceneCharacterId = value;
          } else if (typeof value !== "number") {
            modifyItem.generalInfo = value;
            modifyItem.sceneCharacterId = "";
          }
          break;
        case "costumeMakeupName":
          modifyItem.costumeMakeupName = value;
          break;
        case "specialCost":
          modifyItem.cost = value;
          modifyItem.specialCost = value;
          break;
        default:
          break;
      }
    }
    if (!row.costumeMakeupId) {
      if (
        (row.sceneCharacterId || row.sceneCharacterId === 0) &&
        row.costumeMakeupName &&
        (!checkFor(
          this.props.activePermisision,
          GlobalConstants.PERMISSION_FINANCE
        )
          ? true
          : row.cost)
      ) {
        this.handleSubmit(row);
      }
    }
    if (row.costumeMakeupId) {
      // if (row.specialCost && row.state) {
      if (!_.isEqual(row, this.state.activeRecord)) {
        this.handleUpdate(modifyItem);
      }
      // }
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: record,
    });
  };

  sum = (dataSource, key) => {
    return dataSource.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
  };

  render() {
    const { isLoading, dataSource, characterNameList, totalRecords } =
      this.state;
    const hasNewRow = dataSource.length === totalRecords.length + 1;
    const totalCost = this.sum(dataSource, "specialCost");
    const { activePermisision } = this.props;
    const groupName = ["Main", "Supporting", "Junior", "Animal", "Others"];
    // characterNameList.splice(4, 1);
    let columns = [
      {
        title: "#",
        width: "5%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Character",
        dataIndex: "characterName",
        width: "15%",
        render: (text, record) => (
          <Select
            // showSearch
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
              record.costumeMakeupId
            }
            onFocus={() => this.handleFocus(record)}
            onSearch={(val) => this.designationTypeValue(val, record)}
            onBlur={(value) =>
              this.designationBlurChanges(value, record, "blur")
            }
            onChange={(value) => {
              this.onChange(value, record, "characterName");
            }}
            defaultValue={text ? text : record.sceneCharacterId}
            value={text ? text : record.sceneCharacterId}
            style={{ width: "100%" }}
            className="border-none"
            placeholder="Select type"
          >
            {characterNameList &&
              characterNameList.map((characterGroup, i) => (
                <OptGroup label={groupName[i]}>
                  {characterGroup &&
                    characterGroup.map((character, i) => (
                      <Option value={parseInt(character.value)}>
                        {Capitalize(character.label)}
                      </Option>
                    ))}
                </OptGroup>
              ))}
          </Select>
        ),
      },
      {
        title: "Actor",
        dataIndex: "actor",
        width: "10%",
        render: (text) => text && Capitalize(text),
      },
      {
        title:
          this.props.pageType === Menus.COSTUMES
            ? "Costume Name"
            : "Makeup Name",
        dataIndex: "costumeMakeupName",
        width: "10%",
        className: "caps",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_FINANCE)) {
      columns.splice(4, 0, {
        title: "Cost (₹)",
        dataIndex: "specialCost",
        width: "10%",
        className: "cash",
        editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
      });
    }

    const path =
      this.props.pageType === Menus.COSTUMES
        ? "/project/costumedescription"
        : "/project/makeupdescription";

    // if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    //   columns.push({
    //     title: "",
    //     width: "5%",
    //     align: "center",
    //     render: (text, record) =>
    //       record.costumeMakeupId && (
    //         <Link
    //           to={{
    //             pathname: path,
    //             state: {
    //               costumeMakeupId: record.costumeMakeupId,
    //               characterName: record.characterName,
    //               totalCost: record.specialCost,
    //               pageType: this.props.pageType
    //             }
    //           }}
    //         >
    //           More
    //         </Link>
    //       )
    //   });
    // }

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "5%",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <React.Fragment>
              {record.costumeMakeupId && (
                <Link
                  to={{
                    pathname: path,
                    state: {
                      costumeMakeupId: record.costumeMakeupId,
                      characterName: record.characterName,
                      totalCost: record.specialCost,
                      pageType: this.props.pageType,
                    },
                  }}
                >
                  <TooltipIcon icon="edit" />
                </Link>
              )}
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() =>
                  this.handleDelete(record.key, record.costumeMakeupId)
                }
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button icon="delete" type="link" />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          ) : null,
      });
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columnsData = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "specialCost" ? "number" : "text",
          name: col.dataIndex,
          editable:
            col.editable !==
            checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
              ? record.costumeMakeupId
                ? false
                : true
              : true,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });
    return (
      <Row type="flex" justify="center" align="middle">
        {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) ? (
          <Col xs={24}>
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 5, float: "right" }}
              disabled={hasNewRow}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} />
              Entry
            </Button>
          </Col>
        ) : null}
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
                <Table
                  components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columnsData}
                  // scroll={{ y: "50vh" }}
                  pagination={false}
                  locale={{
                    emptyText: !isLoading && <RecordNotFound />,
                  }}
                  loading={isLoading}
                />
              ) : (
                <ContactAdminErrorTemplate />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralCostumeMakeupTable);
