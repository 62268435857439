import React, { Component, Fragment } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Table } from "antd";
import InvoiceUpload from "components/shared/InvoiceUpload";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { Services, GlobalConstants } from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { ReplaceWithSpace } from "utilities/ValidationHelper";
import AddPaymentPrePostProduction from "./AddPaymentPrePostProduction";
import AddPaymentProduction from "./AddPaymentProduction";
import AddPaymentCast from "./AddPaymentCast";
import AddPaymentVendor from "./AddPaymentVendor";
import AddPaymentTravel from "./AddPaymentTravel";
import AddPaymentPurchase from "./AddPaymentPurchase";

const { Option } = Select;

const dateFormat = GlobalConstants.DD_MM_YYYY;

const config = {
  PRE_PRODUCTION: AddPaymentPrePostProduction,
  POST_PRODUCTION: AddPaymentPrePostProduction,
  PRODUCTION: AddPaymentProduction,
  CAST_TECHNICIANS: AddPaymentCast,
  VENDOR: AddPaymentVendor,
  TRAVEL_AND_LODGEING: AddPaymentTravel,
  PRODUCTION_PURCHASE: AddPaymentPurchase,
};
class AddPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Category: null,
      CategoryTypeList: [],
      ...this.getInitialState(),
    };
    this.global = new GlobalService();
  }

  getInitialState = () => {
    return {
      PaymentFormValues: {
        shootDate: null,
        paymentDate: null,
        expenseType: null,
        CategoryTypeList: [],
        SubCategoryList: [],
      },
    };
  };

  componentDidMount() {
    Promise.all([this.categoryTypeList(), this.subCategoryList()]).then(
      ([res1]) => {
        this.setState({
          CategoryTypeList: res1.data,
        });
      }
    );
  }

  subCategoryList = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PRODUCTION_CATEGORY
    );
  };

  categoryTypeList = () => {
    return this.global.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PRODUCTION_CATEGORY
    );
  };

  onChangeDD = (value, name) => {
    this.setState({
      PaymentFormValues: {
        ...this.state.PaymentFormValues,
        [name]: value,
      },
    });
  };

  renderPaymentForm = (Category) => {
    let Component = null;
    if (Category) {
      Component = config[Category];
    }
    return <Component category={Category} {...this.props} />;
  };

  render() {
    const { CategoryTypeList, Category } = this.state;

    let Obj =
      CategoryTypeList.length !== 0 &&
      Object.assign(
        {},
        ...CategoryTypeList.filter((cate) => cate.value === Category)
      );
    let value = null;
    if (Obj) {
      value = Obj.label;
    }
    return (
      <Fragment>
        <Row>
          <Col xl={24}>
            <Row span={24}>
              <Title hasBack {...this.props}>
                <TitleText>Add Payment </TitleText>
                <Select
                  allowClear
                  style={{ width: 150, float: "right" }}
                  type="primary"
                  placeholder="Choose category"
                  onChange={(Category) => this.setState({ Category })}
                >
                  {CategoryTypeList &&
                    CategoryTypeList.map((list, i) => {
                      return (
                        <Option key={i} value={list.value}>
                          {list.label}
                        </Option>
                      );
                    })}
                </Select>
              </Title>
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: 10 }}>
          {Category && (
            <Col
              xl={24}
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                paddingBottom: 10,
              }}
            >
              {value}
            </Col>
          )}
          {Category && <Col xl={24}>{this.renderPaymentForm(Category)}</Col>}
        </Row>
      </Fragment>
    );
  }
}

const AddPayment = Form.create({ name: "register" })(AddPaymentForm);

export default AddPayment;
