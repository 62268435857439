import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Row, Divider, Col } from "antd";
import BudjetService from "services/BudjetService";
import { Services } from "constants/AppConstants";
import { Capitalize, checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import {
  PermissionPage,
  GlobalConstants,
} from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";

class CastTechnicianslist extends Component {
  constructor(props) {
    super(props);
    this._budgetService = new BudjetService();

    this.state = {
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({
      isLoading: true,
    });
    this._budgetService
      .budjetService(Services.BudjetVariables.GET_VENDOR_BY_PROJECT)
      .then((res) => {
        this.setState({
          dataSource: res.data.vendors,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { dataSource, isLoading } = this.state;
    const { activePermisision } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "sno",
        align: "center",
        render: (text, record, i) => <span>{i + 1}</span>,
      },
      {
        title: "Name",
        dataIndex: "name",
        align: "center",
        render: (text, record, i) => <span>{Capitalize(text)}</span>,
      },

      {
        title: "Address",
        dataIndex: "role",
        align: "center",
        render: (text, record, i) => (
          <span>
            {Capitalize(record.address && record.address.addressLine)}
          </span>
        ),
      },
      {
        title: "Payment",
        dataIndex: "rumeneration",
        align: "center",
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        align: "center",
        render: (text, record, i) => (
          <span>
            {record.paymentType?.replace("_", " ")?.charAt(0)?.toUpperCase() +
              record?.paymentType?.slice(1)?.toLowerCase()?.replace("_", " ")}
            {/* {record.paymentType.charAt(0).toUpperCase() + record.paymentType.slice(1).toLowerCase()} */}
          </span>
        ),
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      columns.push({
        title: "Action",
        dataIndex: "action",
        align: "center",
        render: (text, record) => (
          <span>
            <React.Fragment>
              <Row>
                {/* <Link to={`/project/vendorEquipment/${record.projectVendorId}`}>
                  <Button type="link">More</Button>
                </Link>
                <Divider type="vertical" /> */}
                <Link to={`/project/vendorupdate/${record.vendorId}`}>
                  <Button type="link">Edit</Button>
                </Link>
              </Row>
            </React.Fragment>
          </span>
        ),
      });
    }
    return (
      <Row>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>Vendor </TitleText>
          </Title>
          <br />
        </Row>
        <Row
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Col>
            <Row type="flex" justify="center">
              <Col xs={24}>
                <Col xs={24}>
                  {checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_ADD
                  ) && (
                    <Button
                      type="primary"
                      style={{ marginBottom: 5, float: "right" }}
                    >
                      <Link to="/project/vendorcreate">+ Vendor</Link>
                    </Button>
                  )}
                </Col>
                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    locale={{
                      emptyText: !isLoading && <RecordNotFound />,
                    }}
                    bordered
                    loading={isLoading}
                  />
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CastTechnicianslist);
