import * as Services from "./ServicesConstants";
import { Icons } from "./IconConstants";
import { Errors } from "./ErrorConstants";
import { GlobalConstants } from "./GlobalConstants";
import { Lotties } from "./LottieConstant";

const API_ENDPOINT = process.env.REACT_APP_API_URL;

const ENV_CONSTANTS = {
  USER_API_ENDPOINT: `${API_ENDPOINT}:6060/ace-user/api/v1`,
  SCRIPT_API_ENDPOINT: `${API_ENDPOINT}:6061/ace-script/api/v1`,
  ATTACHEMENT_API_ENDPOINT: `${API_ENDPOINT}:6063/ace-attachment/api/v1`,
  SCRIPT_TEMPLATE_API_ENDPOINT: `${API_ENDPOINT}:6063/ace-attachment`,
};

const FontStyles = {
  SMALL: "15px",
  MEDIUM: "20px",
  LARGE: "30px",
};

const Menus = {
  DASHBOARD: "Dashboard",
  SCRIPTS_BREAKDOWN: "Script Breakdown",
  ACTORS: "Char-Actor",
  CREW: "Crew",
  LOCATION: "Location",
  EQUIPMENTS: "Equipment",
  COSTUMES: "Costume",
  MAKEUP: "Makeup",
  ART_SET: "Art/Set",
  CGI: "CGI",
  VFX: "VFX",
  SONG: "Song",
  STUNT: "Stunt",
  APPROVALS: "Approval",
  HERO_AUDITION: "Hero Audition",
  HEROINE_AUDITION: "Heroine Audition",
  COLLECTION: "Collections",
  SCENE_OVERVIEW: "Scene Overview",
  // ART_SET: "ART/SET",
  PROJECTS: "Projects",
  SCHEDULAR: "Scheduler",
  BUDJETER: "Budgeter",
  ACCOUNTING: "Accounting",
  TRANSPORT: "Transport",
  INVOICE: "Invoice",
  REPORT: "Report",
  LEGAL: "Legal",
};

const MenusRoute = {
  DASHBOARD: "/project/overview/",
  SCRIPTS_BREAKDOWN: "/project/script",
  ACTORS: "/project/actor",
  CREW: "/project/crew",
  LOCATION: "/project/location",
  EQUIPMENTS: "/project/equipments",
  COSTUMES: "/project/costumes",
  MAKEUP: "/project/makeup",
  ART_SET: "/project/artset",
  CGI: "CGI",
  VFX: "/project/vfx",
  SONG: "/project/song",
  STUNT: "/project/stunt",
  PROJECTS: "/projects",
  APPROVALS: "/approval",
  SCHEDULAR: "/project/schedular",
  SCHEDULARUPDATE: "/project/schedularupdate",
  ARTSETUPDATE: "/project/update-artset",
  BUDJETER: "/project/budgeter",
  ACCOUNTING: "/project/accounting",
  TRANSPORT: "/project/transporting",
  ATTENDANCE: "/project/attendance",
  INVOICE: "/project/invoices",
  REPORT: "/project/report",
  INVOICE_MORE: "/project/invoices",
  LEGAL: "/project/Legal",
};

const Notifications = {
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
  ERROR: "error",
  SUCCESSFULLY: "successfully",
  SUCCESS_CAP: "Success",
  WARNING_CAP: "Warning",
  INFO_CAP: "Info",
  ERROR_CAP: "Error",
  SOMETHING_WENT_WRONG: "Something went wrong",
  FAILED: "Failed",
  ADDED_SUCCESSFULLY: "added successfully!",
  UPDATED_SUCCESSFULLY: "updated successfully!",
  DELETED_SUCCESSFULLY: "deleted successfully!",
};

const LocalStorage = {
  USER: "user",
  ACCESS_TOKEN: "accessToken",
  ACTIVE_PROJECT_ID: "projectId",
  PROJECT_DATA: "projectData",
  ACTIVE_PROJECT_NAME: "ACTIVE_PROJECT_NAME",
  ACTIVE_SCENE_ID: "ACTIVE_SCENE_ID",
  LOCATION_ID: "LOCATION_ID",
  SCENE_LOCATION_NAME: "SCENE_LOCATION_NAME",
  PAM: "PAM",
  HAM: "HAM",
  TABINDEX: "tabIndex"
};

const Screens = {
  LOGIN: "LOGIN",
  DEFAULT: "DEFAULT",
  SONGS: "Songs",
  STUNTS: "Stunts",
  COSTUMES: "Costumes",
  ACTOR: "Charc-Actor",
  GENERAL: "General",
  LOCATION: "Location",
  MAKEUP: "MAKEUP",
  ART_SET: "ART_SET",
  EQUIPMENTS: "EQUIPMENT",
  VFX: "VFX",
};

const AttachmentType = {
  companyLogo: "COMPANY_LOGO",
  userPricture: "USER_PICTURE",
  userPan: "USER_PAN",
  projectPicture: "PROJECT_PICTURE",
  characterPicture: "CHARACTER_PICTURE",
  characterAuditionPicture: "CHARACTER_AUDITION_PICTURE",
  actorDocument: "ACTOR_DOCUMENT",
  actorPan: "ACTOR_PAN",
  locationPan: "LOCATION_PAN",
  locationPicture: "LOCATION_PICTURE",
  artSetImage: "ART_SET_IMAGE",
  artSetBudgetDocument: "ART_SET_BUDGET_DOCUMENT",
  projectPlanningDocument: "PROJECT_PLANNING_DOCUMENT",
  costumePicture: "COSTUME_PICTURE",
  makeupPicture: "MAKEUP_PICTURE",
  crewDocument: "CREW_DOCUMENT",
  eqipmentDocument: "EQUIPMENT_DOCUMENT",
  songImage: "SONG_IMAGE",
  stuntImage: "STUNT_IMAGE",
  vendorPan: "VENDOR_PAN",
  vfxDocument: "VFX_DOCUMENT",
  invoiceDocument: "INVOICE_DOCUMENT",
  productionPayment: "PRODUCTION_PAYMENT_DOCUMENT",
};

const ImageDomain = {
  aceUser: "ACE_USER",
  aceScript: "ACE_SCRIPT",
};

const EnumLocation = {
  location: "location",
  actor: "actor",
};

const Enums = {
  location: {
    TO_BE_CONFIRMED: "To be confirmed",
    CONFIRMED: "Confirmed",
  },
  actor: {
    TO_BE_CONFIRMED: "To be confirmed",
    CONFIRMED: "Confirmed",
  },
};

export {
  Menus,
  Icons,
  FontStyles,
  Services,
  Notifications,
  ENV_CONSTANTS,
  Errors,
  LocalStorage,
  MenusRoute,
  Screens,
  GlobalConstants,
  Lotties,
  AttachmentType,
  ImageDomain,
  Enums,
  EnumLocation,
};
