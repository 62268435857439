import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import {
  Table,
  Button,
  Icon,
  Input,
  Form,
  Tag,
  AutoComplete,
  Tooltip,
  Row,
  Col,
  Popconfirm,
  Modal,
} from "antd";
import CreateLocationModal from "components/Location/AddLocationModal";
import LocationServices from "services/LocationServices";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  Services,
  GlobalConstants,
  Screens,
  LocalStorage,
  Notifications,
} from "constants/AppConstants";
import GoogleLocation from "components/shared/GoogleMap";
import { Link } from "react-router-dom";
import GlobalService from "services/GlobalService";
import {
  Capitalize,
  checkFor,
  removePercentage,
} from "utilities/ValidationHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { setActiveTab } from "store/action";
import RecordNotFound from "components/shared/RecordNotFound";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import { EnumLocation } from "constants/AppConstants";
import TooltipComponent from "components/shared/TooltipComponent";
import EnumToUI from "utilities/EnumHelper";

class Location extends Component {
  constructor(props) {
    super(props);
    this._locationProjectService = new LocationServices();
    this._globalService = new GlobalService();

    this.state = {
      visible: false,
      dataSource: [],
      dataFetched: false,
      locationModel: false,
      mapModel: false,
      latitude: null,
      longitude: null,
      type: null,
      sceneLocationName: null,
      actualLocationId: null,
      sceneLocationId: null,
      actualLocation: null,
      statusListDatas: null,
      locationListDatas: null,
      index: null,
      sceneNumberDataSource: [],
      sceneLocationDataSource: [],
      actualLocationDataSource: [],
    };
  }

  handleLatLongValue = (latitude, longitude) => {
    this.setState({
      latitude,
      longitude,
    });
  };

  handleModel = (name, value, type) => {
    if (type !== "entire") {
      if (name === "map") {
        this.setState({
          mapModel: value,
          locationModel: !value,
        });
      } else {
        this.setState({
          mapModel: !value,
          locationModel: value,
        });
      }
    } else {
      this.setState({
        mapModel: false,
        locationModel: false,
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  resetSearch = () => {
    this.setState({
      index: this.state.index + 1,
    });
  };

  componentDidMount() {
    this.getLocations();
    this.getTypeFilter();
    this.getStatusFilter();
  }

  componentWillReceiveProps() {
    this.resetSearch();
  }

  getLocations = () => {
    this._locationProjectService
      .service(Services.LocationVariables.GET_LOCATION_LIST)
      .then((res) => {
        this.setState(
          {
            dataSource: res.data.locations,
            dataFetched: true,
          },
          () => {
            this.getSceneNumberDataSource();
            this.getSceneLocationDataSource();
            this.getActualLocationDataSource();
          }
        );
      })
      .catch((err) => {
        this.setState({
          dataFetched: true,
        });
      });
  };

  getStatusFilter = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({ statusListDatas: res.data });
      });
  };

  getTypeFilter = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.LOCATION_TYPE
      )
      .then((res) => {
        this.setState({ locationListDatas: res.data });
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  getActualLocationDataSource() {
    let locationDatas = [];
    this.state.dataSource &&
      this.state.dataSource.map((a) => {
        if (a.actualLocation) locationDatas.push(a.actualLocation);
      });
    this.setState({ actualLocationDataSource: [...new Set(locationDatas)] });
  }

  getSceneLocationDataSource() {
    let locationDatas = [];
    this.state.dataSource &&
      this.state.dataSource.map((a) => {
        locationDatas.push(a.sceneLocation);
      });
    this.setState({ sceneLocationDataSource: [...new Set(locationDatas)] });
  }

  getSceneNumberDataSource = () => {
    let sceneNumberDatas = [];
    let sceneNumberSource = [];

    new Promise((resolve) => {
      this.state.dataSource &&
        this.state.dataSource.forEach((a, pos) => {
          try {
            a.scenes.forEach((b, pos1) => {
              sceneNumberSource.push(b.sceneNumber);
            });
          } catch (Er) {
            console.log(Er);
          }
          sceneNumberDatas.push(a.scenes);
        });
      resolve();
    }).then(() => {
      this.setState({ sceneNumberDataSource: sceneNumberSource });
    });
  };

  getColumnSearchPropsId = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <AutoComplete
          onChange={(e) => {
            setSelectedKeys(e ? [e] : []);
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          dataSource={this.state.sceneNumberDataSource}
          placeholder="Scene Number"
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        {/* <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        /> */}
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      let scenes =
        record.scenes && record.scenes.map((scene, i) => scene.sceneNumber);
      return scenes && scenes.includes(value);
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
  });

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <AutoComplete
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          dataSource={this.state.actualLocationDataSource || []}
          placeholder={
            dataIndex === "scenes" ? "Scene Number" : "Search Actual location"
          }
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleDelete = (id) => {
    this._locationProjectService
      .service(Services.LocationVariables.DELETE_LOCATION, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.getLocations();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleClose = () => {
    this.setState({
      isEditEnabled: false,
      activeRecord: null,
    });
  };

  handleUpdateName = () => {
    const { activeRecord, activeName } = this.state;
    const data = {
      locationName: removePercentage(activeName),
    };

    this._locationProjectService
      .service(
        Services.LocationVariables.UPDATE_LOCATION_NAME,
        data,
        activeRecord.sceneLocationId
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getLocations();
        this.handleClose();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleClose = () => {
    this.setState({
      isEditEnabled: false,
      activeRecord: null,
    });
  };

  render() {
    const { activePermisision } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "sno",
        align: "center",
        width: "6%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "SCENE LOCATION",
        dataIndex: "sceneLocation",
        align: "left",
        width: "22%",
        ...this.getColumnSearchProps("sceneLocation"),
        // filterDropdown: ({
        //   setSelectedKeys,
        //   selectedKeys,
        //   confirm,
        //   clearFilters,
        // }) => (
        //   <div style={{ padding: 8 }}>
        //     <AutoComplete
        //       onChange={(e) => setSelectedKeys(e ? [e] : [])}
        //       onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
        //       style={{ width: 188, marginBottom: 8, display: "block" }}
        //       dataSource={this.state.sceneLocationDataSource}
        //       placeholder="Scene Location"
        //       filterOption={(inputValue, option) =>
        //         option.props.children
        //           .toUpperCase()
        //           .indexOf(inputValue.toUpperCase()) !== -1
        //       }
        //     />
        //     <Button
        //       type="primary"
        //       onClick={() => this.handleSearch(selectedKeys, confirm)}
        //       icon="search"
        //       size="small"
        //       style={{ width: 90, marginRight: 8 }}
        //     >
        //       Search
        //     </Button>
        //     <Button
        //       onClick={() => this.handleReset(clearFilters)}
        //       size="small"
        //       style={{ width: 90 }}
        //     >
        //       Reset
        //     </Button>
        //   </div>
        // ),
        // filterIcon: (filtered) => (
        //   <Icon
        //     type="search"
        //     style={{ color: filtered ? "#1890ff" : undefined }}
        //   />
        // ),
        // onFilter: (value, record) => {
        //   if (record.sceneLocation) {
        //     return record.sceneLocation
        //       .toString()
        //       .toLowerCase()
        //       .includes(value.toLowerCase());
        //   }
        // },
        // onFilterDropdownVisibleChange: (visible) => {
        //   if (visible) {
        //     // setTimeout(() => this.searchInput.select());
        //   }
        // },
        render: (text, record) => {
          const sceneLocation = record?.sceneLocation;
          const replacedString = sceneLocation.replaceAll("/", "-");
          return (
            <Fragment>
              <Link
                to={`/project/location-audition/${replacedString}/${record.sceneLocationId}`}
              >
                {record.sceneLocation}
              </Link>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
                <Icon
                  type="edit"
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    this.setState({
                      isEditEnabled: true,
                      activeRecord: record,
                      activeName: record.sceneLocation,
                    })
                  }
                />
              )}
            </Fragment>
          );
        },
      },
      {
        title: "SCENES",
        align: "left",
        dataIndex: "scenes",
        width: "22%",
        ...this.getColumnSearchPropsId("scenes"),
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.LOCATION
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                      this.props.setActiveTab("1");
                    }}
                  >
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="top"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },
      {
        title: "ACTUAL LOCATION",
        dataIndex: "actualLocation",
        width: "17%",
        align: "left",
        ...this.getColumnSearchProps("actualLocation"),
        render: (text, record) =>
          checkFor(
            this.props.activePermisision,
            GlobalConstants.PERMISSION_EDIT
          ) ? (
            <Link
              to={`/project/update-location/${record.actualLocationId}/${record.actualLocation}/${record.sceneLocation}/${record.sceneLocationId}`}
            >
              {Capitalize(record.actualLocation)}
              {record.governmentApprovalWarning ? (
                <Tooltip
                  title={record.governmentApprovalWarning}
                  placement={GlobalConstants.RIGHT}
                >
                  {" "}
                  <Button
                    icon="warning"
                    type="link"
                    style={{ color: "#f8990f" }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Link>
          ) : (
            <Link>{Capitalize(record.actualLocation)}</Link>
          ),
      },
      {
        title: "TYPE",
        dataIndex: "type",
        width: "10%",
        align: "left",
        filters:
          this.state.locationListDatas &&
          (this.state.locationListDatas || []).map((option, i) => ({
            value: option.label,
            text: option.label,
          })),
        onFilter: (value, record) => {
          return record.type === value;
        },
      },
      {
        title: "STATUS",
        dataIndex: "state",
        width: "10%",
        align: "center",
        render: (value, record) => {
          return (
            <Tag className={`statusTag ${record.state}`}>
              {EnumToUI(record.state, EnumLocation?.location)}
            </Tag>
          );
        },

        filters:
          this.state.statusListDatas &&
          (this.state.statusListDatas || []).map((option, i) => ({
            value: option.value,
            text: option.label,
          })),
        onFilter: (value, record) => {
          return record.state === value;
        },
      },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "5%",
        className: "operation",
        render: (text, record) =>
          !record.sceneCount ? (
            <Popconfirm
              title={`Are you sure to delete this location ${Capitalize(
                record.sceneLocation
              )} ?`}
              placement={GlobalConstants.TOP_RIGHT}
              onConfirm={() => this.handleDelete(record.sceneLocationId)}
            >
              <Tooltip
                title={GlobalConstants.DEL}
                placement={GlobalConstants.RIGHT}
              >
                <Button icon="delete" type="link" />
              </Tooltip>
            </Popconfirm>
          ) : null,
      });
    }
    const { dataFetched, isEditEnabled, activeName } = this.state;

    return (
      <Fragment>
        <Modal
          destroyOnClose
          className="createModal"
          visible={isEditEnabled}
          onCancel={this.handleClose}
          title="Edit Name"
          onOk={this.handleUpdateName}
        >
          <Row type="flex" justify="center">
            <Col xl={18}>
              <Input
                autoFocus
                placeholder="enter name"
                value={activeName}
                onChange={(e) => this.setState({ activeName: e.target.value })}
                onPressEnter={this.handleUpdateName}
              />
            </Col>
          </Row>
        </Modal>
        <Row span={24}>
          <Title>
            <TitleText>Location </TitleText>
            <Tooltip
              title={GlobalConstants.RESET}
              placement={GlobalConstants.TOP}
            >
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) && (
                <Button type="primary" onClick={this.resetSearch}>
                  {/* Reset Filter */}
                  <Icon type="reload" />
                </Button>
              )}
            </Tooltip>
          </Title>
        </Row>
        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row style={{ padding: 10 }}>
            <Col>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
                <Table
                  columns={columns}
                  dataSource={this.state.dataSource}
                  pagination={false}
                  locale={{
                    emptyText: dataFetched && <RecordNotFound />,
                  }}
                  loading={!dataFetched}
                  key={this.state.index}
                  bordered
                />
              ) : (
                <ContactAdminErrorTemplate />
              )}
            </Col>
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const locationpop = Form.create()(Location);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Location", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(locationpop);
