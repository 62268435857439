import React, { Component } from "react";
import { Row, Col, Icon, Popconfirm, Modal } from "antd";
import user from "assets/images/user.png";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { showNotifications } from "components/shared/NotificationComponent";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";

import "./AuditionCardComponent.css";
import { checkFor, Capitalize } from "utilities/ValidationHelper";

class AuditionCardComponent extends Component {
  state = {
    name: null,
    status: null,
    imageId: null,
    characterId: null,
    cardList: [],
    isLoading: false,
    visible: false,
    imageList: [],
  };

  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
    this._locationService = new LocationTabService();
  }

  componentDidMount() {
    this.setState({
      imageList: this.props.ImageIdList,
    });
  }

  showModal = () => {
    if (this.props.ImageIdList) {
      this.setState({
        visible: true,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  confirm = (e) => {
    this.setState({ isLoading: true });
    this._actorService
      .actorTabService(
        Services.SceneOVerviewVariable.DEL_ACTOR_CARD,
        this.props.characterAuditionId
      )
      .then((res) => {
        this.props.deleteActors(res);
        showNotifications(
          Notifications.SUCCESS,
          "Successfully Character Removed",
          "Get your work simplified!!"
        );

        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.props.getPermissions();
      });
  };

  getImages = (imageList) => {
    let images = [];
    imageList?.forEach((list) => {
      if (list.fileType === "IMAGE") {
        images.push({
          original: list?.attachment?.url,
          thumbnail: list?.attachment?.url,
        });
      }
    });

    return images;
  };

  render() {
    const { actorName, status, characterId, type, activePermisision } =
      this.props;

    const images = this.getImages(this.state.imageList);

    return (
      <div>
        <Col
          xl={{ span: 8 }}
          lg={{ span: 10 }}
          md={{ span: 16 }}
          xs={{ span: 24 }}
          style={{ padding: "10px", cursor: "pointer" }}
        >
          <div className={status !== "CONFIRMED" ? "rejected " : ""}>
            <div className="card">
              {status === "CONFIRMED" ? (
                <div>
                  <div className="confrimedCard">
                    <Icon type="check-circle" />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="confrimedCard" style={{ color: "red" }}>
                    <Icon type="close-circle" />
                  </div>
                </div>
              )}
              {checkFor(activePermisision, "Delete") ? (
                <div className="deleteCard">
                  <Popconfirm
                    title="Are you sure delete this Character?"
                    onConfirm={this.confirm}
                    okText="Yes"
                    cancelText="No"
                    loading={this.state.isLoading}
                  >
                    <a href="#" style={{ color: "red", fontSize: 14 }}>
                      <Icon type="delete" />
                    </a>
                  </Popconfirm>
                </div>
              ) : null}
              <Row>
                <Col span={16} className="vertical-center pl-15">
                  {checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_EDIT
                  ) ? (
                    <h3>
                      <a className="font-600 text-ellipsis">
                        <Link
                          to={`/project/actorprofile/${this.props.actorId}/${this.props.characterName}/${type}/${characterId}`}
                        >
                          <h4
                            style={{
                              textTransform: "capitalize",
                              textOverflow: "ellipsis",
                              maxWidth: "170px",
                              overflow: "hidden",
                            }}
                            className="primary-font-color"
                          >
                            {Capitalize(actorName)}
                          </h4>
                        </Link>
                      </a>
                    </h3>
                  ) : (
                    <h3>
                      <a className="font-600 text-ellipsis">
                        <Link>{Capitalize(actorName)}</Link>
                      </a>
                    </h3>
                  )}
                  <p style={{ textTransform: "capitalize" }}>
                    {status?.charAt(0)?.toUpperCase() +
                      status?.slice(1)?.toLowerCase()}
                  </p>
                </Col>
                <Col span={8}>
                  <img
                    onClick={this.showModal}
                    onError={(e) => {
                      console.log(e);
                      e.target.onerror = null;
                      e.target.src = user;
                    }}
                    src={this.props?.Image ? this.props.Image : user}
                    alt="user"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Modal
          // title="Basic Modal"
          footer=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <ImageGallery items={images} onClick={this.selectImage} />
        </Modal>
      </div>
    );
  }
}

export default AuditionCardComponent;
