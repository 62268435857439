import axios from "axios";
import { Services } from "constants/AppConstants";
import { getUserAPIURL, getScriptAPIURL } from "utilities/APIHelper";

export default class OnboardServices {
  onboardService(type, data) {
    switch (type) {
      case Services.OnboardVariables.LOGIN:
        return axios.post(`${getUserAPIURL()}/user/authenticate`, data);
      case Services.OnboardVariables.FORGOT_PASSWORD:
        return axios.put(`${getUserAPIURL()}/user/password`, data);
      case Services.OnboardVariables.RESET_PASSWORD:
        return axios.put(`${getUserAPIURL()}/user/password/reset`, data);
      case Services.OnboardVariables.LOGOUT:
        return axios.get(`${getUserAPIURL()}/user/logout`);
      case Services.OnboardVariables.GET_USER_DETAILS:
        return axios.get(`${getUserAPIURL()}/user`);
      case Services.OnboardVariables.GET_COUNTRY:
        return axios.get(`${getScriptAPIURL()}/countries`);
      default:
        break;
    }
  }
}
