import React from "react";
import "./label.scss";

const Label = ({ children, style }) => {
  return (
    <span
      style={{ fontSize: 16, fontWeight: "bold", ...style }}
      className="label"
    >
      {children}
    </span>
  );
};
const Value = ({ children, style }) => {
  return <span style={{ fontSize: 16, ...style }}>{children}</span>;
};

export { Label, Value };
