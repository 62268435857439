import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Table,
  Button,
  Row,
  Col,
  Icon,
  Input,
  Select,
  DatePicker,
  Modal,
} from "antd";
import moment from "moment";

import {
  amountOnly,
  StartCase,
  disableFutureDates,
} from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import DeleteComponent from "components/shared/DeleteComponent";
import AccPaymentModeDD from "../AccPaymentModeDD";
import SharedDocumentUpload from "components/shared/SharedDocumentUpload";
import Accounting from "services/AccountingServices";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import InvoiceForm from "components/shared/InvoiceForm";
import GlobalService from "services/GlobalService";
import VoucherDownloadButton from "components/shared/VoucherDownloadButton";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Loader from "components/shared/Loader";
import PayeeForm from "components/shared/PayeeForm";

const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

const dateFormat = "DD-MM-YYYY";

class AccProductionActorAdvanceMore extends Component {
  constructor(props) {
    super(props);
    this.account = new Accounting();
    this.api = new GlobalService();
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
      receiverNameList: [],
      expenseTypeData: [],
      receiverName: null,
      productionAccountId: null,
      shootDate: props.match.params.shootDate || null,
      isAdvance: props.location.state.isAdvance || false,
      descriptionId: props?.location?.state?.record.descriptionId || null,
      characterType: props.location.state.characterType,
      actorName: props.location.state.actorName,
      hidePaymentType: false,
      isInvoiceVisible: false,
      category: GlobalConstants.PRODUCTION_CAPS,
      fromTravel: false,
      details: false,
      title: props.location.state.title,
      activePayment: null,
      paymentTypeList: null,
      paymentModeList: null,
      ActorDepartment: null,
      paymentStatusList: [],
      payeeId: this.props?.location?.state?.record?.legalAgreement?.id || null,
    };
  }

  componentDidMount() {
    this.getPaymentStatusData();
    this.getPaymentTypeData();
    if (this.state?.details) {
      this.fetchProductionData();
    } else {
      this.fetchData();
    }
  }

  getAmountPaid = (value, record) => {
    this.setState({
      isLoading: true,
    });
    this.account
      .services(Services.AccountingVariables.LEGAL_PAYEE_AMOUNT, {
        value: value,
        payeeId: this.state.payeeId,
      })
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        this.onChange(res?.data?.netTotalAmount, record, "amountPaid");
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getPaymentStatusData = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_STATUS
      )
      .then((res) => {
        this.setState({
          paymentStatusList: res.data,
        });
      });
  };

  getActorDepartment = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ACCOUNTING_ACTOR_DEPARTMENT
      )
      .then((res) => {
        this.setState({
          ActorDepartment: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchProductionData = () => {
    const {
      details: {
        productionCategoryDepartment,
        description,
        categoryDepartment,
      },
      category,
      isAdvance,
    } = this.state;
    const data = {
      productionCategoryDepartment: categoryDepartment,
      description,
      category,
      isAdvance,
    };
    let dataSource = [];
    this.account
      .services(Services.AccountingVariables.GET_PRODUCTION_MORE, data)
      .then((res) => {
        res.data.payments &&
          res.data.payments.map((data, i) => {
            dataSource.push({
              ...data,
              key: this.state.count,
              payee: data.receiverName || null,
              department: data.department.toUpperCase(),
              paymentDate: data.paymentDate
                ? moment(
                    data.paymentDate,
                    GlobalConstants.DD_MM_YYYY,
                    true
                  ).isValid()
                  ? data.paymentDate
                  : moment(data.paymentDate)
                      // .utc()
                      .format(GlobalConstants.DD_MM_YYYY)
                : moment(),
              paymentType:
                (data.paymentType && data.paymentType.toLowerCase()) || null,
              paymentMode:
                (data.paymentMode && data.paymentMode.toLowerCase()) || null,
              shootDate: moment(
                data.shootDate,
                GlobalConstants.DD_MM_YYYY,
                true
              ).isValid()
                ? data.shootDate
                : moment(data.shootDate)
                    // .utc()
                    .format(GlobalConstants.DD_MM_YYYY) || null,
            });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource,
          totalRecords: dataSource,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  fetchData = () => {
    let dataSource = [];
    this.account
      .services(
        Services.AccountingVariables.GET_PRE_PRODUCTION_MORE_ACTOR_NEW_ADVANCED,
        {
          projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
          category: "PRODUCTION",
          productionCategoryDepartment: "ACTOR",
          isAdvance: true,
          size: 0,
          page: 0,
          payeeId: this.state.payeeId,
        }
      )
      .then((res) => {
        res.data.content &&
          res.data.content.map((data, i) => {
            dataSource.push({
              descriptionId: data?.descriptionId,
              payeeId: data?.payeeId,
              billAmount: data?.billAmount,
              amountPaid: data?.amountPaid,
              paymentMode: data?.paymentMode,
              referenceNumber: data?.referenceNumber,
              comments: data?.comments,
              paymentStatus: data?.paymentStatus,
              paymentDate: data?.paymentDate,
              vocherNumber: data?.vocherNumber,
              documents: data?.documents,
              productionPaymentId: data?.productionPaymentId,
            });
            this.setState({
              count: this.state.count + 1,
            });
          });

        this.setState(
          {
            dataSource: [],
          },
          () => {
            this.setState({
              dataSource,
              totalRecords: dataSource,
              isLoading: false,
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  fetchExpenseType = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.EXPENSE_TYPE
      )
      .then((res) => {
        this.setState({
          expenseTypeData: res.data,
        });
      });
  };

  getPaymentTypeData = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        console.log(res);
        this.setState({
          paymentTypeList: res.data,
        });
      });
  };

  getPaymentModeData = () => {
    this.api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        this.setState({
          paymentModeList: res.data,
        });
      });
  };

  getPayee = () => {
    const { category } = this.state;
    this.account
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN, category)
      .then((res) => {
        this.setState({
          receiverNameList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({});
      });
  };

  handleAdd = () => {
    const { count, dataSource, details, shootDate } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      payeeId: null,
      receiverName: null,
      amountPaid: null,
      paymentMode: null,
      referenceNumber: null,
      documents: [],
      comments: null,
      paymentDate: null,
      count: 0,
      description: details ? details.description : null,
      payee: null,
      isAmountSplit: false,
      expenseType: null,
      // itemQuantity: null,
      shootDate,
    };

    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, productionPaymentId }) => {
    if (productionPaymentId) {
      this.account
        .services(
          Services.AccountingVariables.DELETE_PRE_PRODUCTION_MORE,
          AccountingVariables.PRE_PRODUCTION,
          productionPaymentId
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
          // this.props.refreshData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  inputChange(value, record, type) {
    this.onChange(value, record, type);
  }

  onChange = (value, record, type) => {
    if (value !== "NaN" && value) {
      record[type] = value;
      record.count = parseInt(record.count);
      this.handleSave(record);
    }
    if (value === "" && type === "billAmount") {
      record[type] = null;
      this.handleSave(record);
    }
  };

  handleInvoiceNumber = (invoiceNumber, invoiceId, record) => {
    record["invoiceNumber"] = invoiceNumber;
    record["invoiceId"] = invoiceId;
    this.handleSave(record);
  };

  dateChange = (value, dateString, record, type) => {
    record[type] = dateString;
    this.handleSave(record);
  };

  showWarning = (row) => {
    const that = this;
    confirm({
      title: "Payment more than 10,000 will be Splitted to Multiple Payments",
      content: "This Action can't be revoke",
      okText: "Ok",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        return new Promise((resolve, reject) => {
          that.handleSubmit({ ...row, isAmountSplit: true });
          resolve();
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        that.handleSubmit({ ...row, isAmountSplit: false });
      },
    });
  };

  handleSubmit = (record) => {
    const payload = {
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      paymentId: record?.productionPaymentId,
      category: this.state?.category || null,
      categoryDepartment:
        this.state?.categoryDepartment === "MAIN"
          ? "MAIN_ACTOR"
          : "SUPPORTING_ACTOR",
      billAmount: record?.billAmount || null,
      amountPaid: record?.amountPaid || null,
      payeeId: this.state.payeeId || null,
      paymentMode: record?.paymentMode || null,
      referenceNumber: record?.referenceNumber || null,
      comments: record?.comments || null,
      paymentStatus: record?.paymentStatus || null,
      paymentDate: moment(
        record?.paymentDate,
        GlobalConstants.DD_MM_YYYY,
        true
      ).isValid()
        ? record?.paymentDate
        : moment(record?.paymentDate).format(GlobalConstants.DD_MM_YYYY),
      shootDate: this.state?.shootDate,
      isAdvance: this.state?.isAdvance,
      productionAccountId: this.state?.productionAccountId,
      isAmountSplit: false,
      documents: record?.documents || null,
      productionAccount: null,
      descriptionId: this.state.descriptionId,
    };
    this.setState({
      isLoading: true,
    });
    this.account
      .services(Services.AccountingVariables.SAVE_PRE_PRODUCTION_MORE, payload)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        this.setState({
          isLoading: false,
        });
      });
  };

  checkForTravelData = (row) => {
    const { fromTravel } = this.state;
    if (fromTravel) {
      return row.expenseType;
      //  && row.itemQuantity;
    } else {
      return true;
    }
  };

  checkForProductionData = (row) => {
    const { details } = this.state;
    if (details) {
      return row.shootDate;
    } else {
      return true;
    }
  };

  checkForReference = (row) => {
    if (row.paymentMode.toUpperCase() === "CASH") {
      return true;
    } else {
      return row.referenceNumber;
    }
  };

  checkForPaymentType = (row) => {
    const { hidePaymentType } = this.state;
    if (hidePaymentType) {
      return true;
    } else {
      return row.paymentType;
    }
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (
      row?.billAmount &&
      row?.amountPaid &&
      row?.paymentMode &&
      row?.paymentStatus &&
      row?.paymentDate
    ) {
      this.handleSubmit(row);
    }
    this.setState({ dataSource: newData });
  };

  handleReceiverName = (value, record) => {
    if (value && value.trim().length > 0) {
      this.setState({
        receiverName: value,
      });
    }
    this.setState({
      isOnChanged: false,
    });
  };

  handlePayee = (value, record, type) => {
    record[type] = value;
    record["payee"] = value;
    record["receiverName"] = null;
    this.setState({
      receiverName: null,
    });
    this.handleSave(record);
  };

  handleReceiver = (value, record, eventType) => {
    if (this.state.isOnChanged != true) {
      let newData = StartCase(this.state.receiverName);
      if (newData) {
        this.setState({ eventType });
        record.receiverName = newData;
        record.payeeId = null;
        record.payee = newData;
        this.onChange(newData, record, "payee");
      }
    } else {
      this.setState({
        isOnChanged: false,
      });
    }
  };

  disabledDate = (current, toDate) => {
    const { shootDate } = this.state;
    // Can not select days before today and today
    if (current) {
      if (toDate) {
        return current < moment(toDate, dateFormat).add(1, "days");
      }
      return current < moment().startOf("day");
    }
  };

  handleInvoice = (activePayment) => {
    this.setState({
      activePayment,
      isInvoiceVisible: true,
      activeInvoice: activePayment.invoiceId,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isInvoiceVisible: false,
      activeInvoice: null,
    });
  };

  removeInvoice = (record) => {
    record["invoiceNumber"] = null;
    record["invoiceId"] = null;
    this.handleSave(record);
  };

  handlePayeeName = (payee, payeeId, record) => {
    record["payeeName"] = payee;
    record["payee"] = payee;
    record["payeeId"] = payeeId;
    this.handleSave(record);
  };

  handlePayeeModal = (activePayment) => {
    this.setState({
      activePayment,
      isPayeeVisible: true,
      activePayee: activePayment.payeeId,
    });
  };

  handleClosePayeeModal = () => {
    this.setState({
      isPayeeVisible: false,
      activePayee: null,
    });
  };

  removePayee = (record) => {
    record["payee"] = null;
    record["payeeId"] = null;
    record["payeeName"] = null;
    record["receiverName"] = null;
    this.handleSave(record);
  };

  render() {
    const {
      dataSource,
      isLoading,
      totalRecords,
      receiverNameList,
      productionAccountId,
      isInvoiceVisible,
      activePayment,
      activeInvoice,
      category,
      paymentTypeList,
      paymentModeList,
      fromTravel,
      title,
      details,
      hidePaymentType,
      ActorDepartment,
      activePayee,
      isPayeeVisible,
      actorName,
    } = this.state;

    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        width: 40,
        render: (text, record, index) => index + 1,
      },
      {
        title: "Payee",
        dataIndex: "payeeId",
        key: "payeeId",
        width: 160,
        render: () => (
          <span>
            {this.props?.location?.state?.record?.legalAgreement?.partyName ||
              ""}
          </span>
        ),
      },
      {
        title: "Bill Amount",
        dataIndex: "billAmount",
        key: "billAmount",
        width: 140,
        align: "right",
        render: (text, record) =>
          !record.invoiceNumber && (
            <Input
              type="number"
              className={`${!text ? "err" : ""} upperCase cash`}
              defaultValue={text}
              onBlur={(e) => {
                this.state.activeValue !== e.target.value &&
                  this.onChange(e.target.value, record, "billAmount");
                this.getAmountPaid(e.target.value, record);
              }}
              onFocus={(e) => {
                e.currentTarget.select();
                this.setState({ activeValue: e.target.value });
              }}
              style={{ width: "100%" }}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
              }
            />
          ),
      },
      {
        title: "AMOUNT PAID",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        width: 140,
        className: "cash",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            type="number"
            value={text}
            onBlur={(e) => this.onChange(amountOnly(e), record, "amountPaid")}
            style={{ width: "100%" }}
            disabled={true}
          />
        ),
      },
      {
        title: "Payment Mode",
        dataIndex: "paymentMode",
        key: "paymentMode",
        width: 140,
        render: (text, record) => (
          <AccPaymentModeDD
            className={`${!text ? "err" : ""}`}
            text={text}
            record={record}
            onChange={this.onChange}
            fieldName="paymentMode"
            dataSource={paymentTypeList}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Reference no",
        dataIndex: "referenceNumber",
        key: "referenceNumber",
        width: 140,
        render: (text, record) => (
          <Input
            defaultValue={text}
            onBlur={(e) =>
              this.onChange(e.target.value, record, "referenceNumber")
            }
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        width: 150,
        render: (text, record) => (
          <TextArea
            defaultValue={text}
            placeholder="enter comments"
            onBlur={(e) => this.onChange(e.target.value, record, "comments")}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          />
        ),
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        width: 140,
        render: (text, record) => (
          <Select
            style={{ width: "100%" }}
            className={!text && "err"}
            defaultValue={text}
            onBlur={(e) => this.onChange(e, record, "paymentStatus")}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
          >
            {this.state.paymentStatusList.map((payment) => {
              return <Option value={payment?.value}>{payment?.label}</Option>;
            })}
          </Select>
        ),
      },
      {
        title: "Payment date",
        dataIndex: "paymentDate",
        key: "paymentDate",
        width: 140,
        render: (text, record) => (
          <DatePicker
            defaultValue={text ? moment(text, dateFormat) : ""}
            format={dateFormat}
            onChange={(value, dateString) =>
              this.dateChange(value, dateString, record, "paymentDate")
            }
            className={!text && "err"}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionPaymentId
            }
            disabledDate={(current) => disableFutureDates(current)}
          />
        ),
      },
      {
        title: "Voucher no",
        dataIndex: "vocherNumber",
        key: "vocherNumber",
        width: 140,
        render: (text, record) =>
          text && (
            <VoucherDownloadButton
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
                record.productionPaymentId &&
                !hasNewRow
              }
              voucherNumber={text}
            />
          ),
      },
      {
        title: "Actions",
        key: "action",
        align: "center",
        width: 60,
        render: (text, record) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <SharedDocumentUpload
              category={category}
              iconButton
              type={GlobalConstants.INVOICE_DOCUMENT}
              buttonType="primary"
              needDataBack={true}
              callback={(e) => {
                this.handleSave({
                  ...record,
                  documents: e?.documents || [],
                });
              }}
              data={{
                ...record,
                productionPaymentId: record.productionPaymentId,
                paymentDate: moment(record.paymentDate).format(
                  GlobalConstants.DD_MM_YYYY
                ),
              }}
            />
            {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
              <DeleteComponent
                record={record}
                onClick={(record) => this.handleDelete(record)}
              />
            )}
          </div>
        ),
      },
    ];

    return (
      <Fragment>
        {isLoading && <Loader />}
        <Title hasBack {...this.props}>
          <TitleText>Accounting - Production - Actor - {actorName} </TitleText>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
            <Button
              onClick={this.handleAdd}
              type="primary"
              className="flrt"
              disabled={hasNewRow}
            >
              <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
            </Button>
          )}
        </Title>

        {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ padding: 10 }}
          >
            <Col xl={24} className="accProductionScroll">
              <Table
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{
                  emptyText: !isLoading && <RecordNotFound />,
                }}
                loading={isLoading}
              />
            </Col>
            {activePayment && (
              <Modal
                destroyOnClose
                className="createModal no-x"
                key={1}
                title="Create Invoice"
                visible={isInvoiceVisible}
                width={"95%"}
                // style={{ maxHeight: "40px" }}
                footer={[
                  <Button key="back" onClick={() => this.handleCloseModal()}>
                    Close
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => this.child.handleSubmit()}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <InvoiceForm
                  category={category}
                  details={{
                    productionCategoryDepartment:
                      activePayment.categoryDepartment ||
                      activePayment.department,
                  }}
                  activeInvoice={activeInvoice}
                  activePayment={activePayment}
                  description={activePayment.description}
                  // details={details}
                  onRef={(ref) => (this.child = ref)}
                  handleInvoiceNumber={this.handleInvoiceNumber}
                  handleCloseModal={this.handleCloseModal}
                />
              </Modal>
            )}
            {activePayment && (
              <Modal
                destroyOnClose
                className="createModal no-x"
                key={1}
                title="Create Payee"
                visible={isPayeeVisible}
                // width={"95%"}
                // style={{ maxHeight: "40px" }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => this.handleClosePayeeModal()}
                  >
                    Close
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => this.child.handleSubmit()}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <PayeeForm
                  category={category}
                  activePayee={activePayee}
                  activePayment={activePayment}
                  description={activePayment.description}
                  details={{
                    categoryDepartment: GlobalConstants.ACTOR_CAPS,
                  }}
                  onRef={(ref) => (this.child = ref)}
                  handlePayeeName={this.handlePayeeName}
                  handleClosePayeeModal={this.handleClosePayeeModal}
                />
              </Modal>
            )}
          </Row>
        ) : (
          <ContactAdminErrorTemplate />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccProductionActorAdvanceMore);
