import React, { useState, Fragment } from "react";
import { Row, Tabs, Col } from "antd";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { connect } from "react-redux";
import { setCostumeLeftActiveTab } from "store/action";
import CastTechnicianTable from "./CastTechnicianTable";
import { Services } from "constants/AppConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getPermissions } from "store/thunk-actions";

const { TabPane } = Tabs;

const CastTechnicians = (props) => {
  const { setCostumeLeftActiveTab, costumeLeftActiveTab } = props;
  const [ActiveTab, setActiveTab] = useState(costumeLeftActiveTab);

  const handleChange = (e) => {
    setCostumeLeftActiveTab(e);
  };
  return (
    <Fragment>
      <Row span={24}>
        <Title hasBack {...props}>
          <TitleText>Cast & Technicians </TitleText>
        </Title>
      </Row>
      <Row style={{ padding: 10 }}>
        <Col>
          <Tabs
            defaultActiveKey={ActiveTab}
            onChange={handleChange}
            animated
            destroyInactiveTabPane
          >
            <TabPane tab="Cast - Contract wise" key="1">
              <CastTechnicianTable
                type={Services.BudjetVariables.CAST_CONTRACT}
                {...props}
              />
            </TabPane>
            <TabPane tab="Cast - Day wise" key="2">
              <CastTechnicianTable
                type={Services.BudjetVariables.CAST_PER_DAY}
                {...props}
              />
            </TabPane>
            <TabPane tab="Technician - Contract wise" key="3">
              <CastTechnicianTable
                type={Services.BudjetVariables.TECHNICIAN_CONTRACT}
                {...props}
              />
            </TabPane>
            <TabPane tab="Technician - Day wise" key="4">
              <CastTechnicianTable
                type={Services.BudjetVariables.TECHNICIAN_PER_DAY}
                {...props}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { costumeLeftActiveTab },
  user: { menus },
}) => {
  return {
    costumeLeftActiveTab,
    activePermisision: getSPermissionFromMenus(PermissionPage.BUDGETER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CastTechnicians);
