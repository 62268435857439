import React, { useState, Fragment, useEffect } from "react";
import { Tabs, Row, Col } from "antd";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import { GlobalConstants, Services } from "constants/AppConstants";
import AccProductionTable from "./AccProductionTable";
import AccProductionDataTable from "./AccProductionDataTable";
import { connect } from "react-redux";
import { setCostumeLeftActiveTab } from "store/action";
import DashboardServices from "services/DashboardServices";
import ProductionBarChart from "components/Budjeter/Production/ProductionBarChart";
import ThemeColors from "constants/ThemeConstants/variables";
import AccLocationTable from "./AccLocationTable";
import AccProductionActor from "../AccPreProductionEdit/AccProductionActor";
import AccProductionArtSetTable from "../AccPreProductionEdit/AccProductionArtSetTable";
import GlobalService from "services/GlobalService";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import AccProductionCostumeMakeupTable from "../AccPreProductionEdit/AccProductionCostumeMakeupTable";
import AccProductionSongStuntTable from "../AccPreProductionEdit/AccProductionSongStuntTable";
import AccProductionActorTable from "../AccPreProductionEdit/AccProductionActorTable";

const { TabPane } = Tabs;

const order1 = [
  "Daily Expense",
  "Location",
  "Actor",
  "Art Set",
  "Equipment",
  "Costume & Makeup",
  "Purchase",
];

const TabName = [
  GlobalConstants.DAILY_EXPENSE,
  GlobalConstants.Location,
  GlobalConstants.Actor,
  GlobalConstants.ART_SET,
  GlobalConstants.EQUIPMENTS,
  GlobalConstants.COSTUME_AND_MAKEUP,
  GlobalConstants.SONG_AND_STUNT,
];

const CostumeList = [
  {
    value: "COSTUME",
    label: "Costume",
  },
  {
    value: "MAKEUP",
    label: "Makeup",
  },
];

const SongList = [
  {
    value: "SONG",
    label: "Song",
  },
  {
    value: "STUNT",
    label: "Stunt",
  },
];

const AccProduction = (props) => {
  const { setCostumeLeftActiveTab, costumeLeftActiveTab, activePermisision } =
    props;
  const [BarChartData, setBarChartData] = useState(null);
  const [ProductionDepartmentList, setProductionDepartmentList] = useState([]);
  const [ActiveTab, setActiveTab] = useState(costumeLeftActiveTab);
  const dashboard = new DashboardServices();
  const global = new GlobalService();

  const handleChange = (e) => {
    setCostumeLeftActiveTab(e.toString());
  };

  useEffect(() => {
    getBarChartData();
    getProductionDepartmentList();
  }, []);

  const getBarChartData = () => {
    dashboard
      .service(GlobalConstants.GET_DASH_CHART, {
        type: "bar",
        isCategory: false,
        isTilldate: true,
      })
      .then((res) => {
        setBarChartData(res.data);
      });
  };

  const getProductionDepartmentList = () => {
    global
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ACCOUNTING_DEPARTMENT
      )
      .then((res) => {
        setProductionDepartmentList(res.data);
      });
  };

  const renderCharts = () => {
    const charts = [];
    for (let i = 1; i <= order1.length; i++) {
      const isSelected = i === parseInt(costumeLeftActiveTab);
      const index =
        BarChartData &&
        BarChartData.labels.findIndex((label) => label === order1[i - 1]);
      let tempData = {};
      if (BarChartData) {
        tempData = {
          // labels: [order1[i - 1]],
          labels: [order1[i - 1]],
          datasets: [
            {
              label: "Est",
              data: [BarChartData.datasets[0].data[index]],
              backgroundColor: ThemeColors.darkGrey,
            },
            {
              label: "Exp",
              data: [BarChartData.datasets[1].data[index]],
              backgroundColor: ThemeColors.primaryColor,
            },
          ],
        };
      }

      charts.push(
        <Col
          className={`barChartArea ${isSelected ? "activeChart" : ""}`}
          xl={isSelected ? 3 : 3}
          key={i}
          onClick={() => handleChange(i)}
        >
          <Col xl={24}>
            <ProductionBarChart className="chart" data={tempData} />
          </Col>
          <Col
            xl={24}
            style={{
              textAlign: "center",
              color: isSelected && ThemeColors.primaryColor,
            }}
          >
            <p>
              <div style={{ paddingBottom: 2, paddingTop: 5, fontSize: 13 }}>
                {TabName[i - 1]}
              </div>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>
                {BarChartData.datasets[1].data[index]}
              </span>
            </p>
          </Col>
        </Col>
      );
    }
    return charts;
  };

  return (
    <Fragment>
      <Row>
        <Col xl={24}>
          <Title hasBack {...props}>
            <TitleText>Accounting - Production </TitleText>
          </Title>
          <Row style={{ paddingTop: 20 }}>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 10 }}
            >
              <Col xl={{ span: 24 }}>
                <Row type="flex" justify="center" align="middle">
                  <Col
                    xl={24}
                    className="flexCenter"
                    style={{ flexWrap: "wrap" }}
                  >
                    {BarChartData && renderCharts()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row style={{ padding: 10 }}>
          <Col>
            <Tabs
              activeKey={costumeLeftActiveTab}
              onChange={handleChange}
              animated
              destroyInactiveTabPane
            >
              {/* <TabPane tab={GlobalConstants.DATA_WISE} key="1">
              <AccProductionDataTable {...props} pageType={GlobalConstants.BY_CALL_SHEET} />
            </TabPane> */}
              <TabPane tab={GlobalConstants.DAILY_EXPENSE} key="1">
                <AccProductionDataTable
                  title={GlobalConstants.DAILY_EXPENSE}
                  {...props}
                  pageType={GlobalConstants.BY_CALL_SHEET}
                  productionDepartment={"PRODUCTION"}
                  departments={ProductionDepartmentList}
                />
              </TabPane>
              <TabPane tab={GlobalConstants.Location} key="2">
                <AccLocationTable {...props} title={GlobalConstants.Location} />
              </TabPane>
              <TabPane tab={GlobalConstants.Actor} key="3">
                {/* <AccProductionActor
                  refreshData={getBarChartData}
                  title={GlobalConstants.Actor}
                /> */}
                <AccProductionActorTable {...props} />
              </TabPane>
              <TabPane tab={GlobalConstants.ART_SET} key="4">
                <AccProductionArtSetTable
                  {...props}
                  pageType={GlobalConstants.BY_CALL_SHEET}
                  title={GlobalConstants.ART_SET}
                  productionDepartment={"ART_SET"}
                />
              </TabPane>
              <TabPane tab={GlobalConstants.EQUIPMENTS} key="5">
                <AccProductionDataTable
                  {...props}
                  pageType={GlobalConstants.BY_CALL_SHEET}
                  title={GlobalConstants.EQUIPMENTS}
                  productionDepartment={"EQUIPMENT"}
                />
              </TabPane>
              <TabPane tab={GlobalConstants.COSTUME_AND_MAKEUP} key="6">
                {/* <AccProductionDataTable
                  {...props}
                  pageType={GlobalConstants.BY_CALL_SHEET}
                  title={GlobalConstants.COSTUME_AND_MAKEUP}
                  productionDepartment={"COSTUME_MAKEUP"}
                  departments={CostumeList}
                /> */}
                <AccProductionCostumeMakeupTable {...props} />
              </TabPane>
              <TabPane tab={GlobalConstants.SONG_AND_STUNT} key="7">
                {/* <AccProductionDataTable
                  {...props}
                  pageType={GlobalConstants.BY_CALL_SHEET}
                  title={GlobalConstants.SONG_AND_STUNT}
                  productionDepartment={"SONG_STUNT"}
                  departments={SongList}
                /> */}
                <AccProductionSongStuntTable {...props} />
              </TabPane>
              <TabPane tab={"Assets"} key="8">
                <AccProductionTable
                  {...props}
                  pageType={GlobalConstants.BY_PURCHASE}
                  title={GlobalConstants.BY_PURCHASE}
                  productionDepartment={null}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { costumeLeftActiveTab },
  user: { menus },
}) => {
  return {
    costumeLeftActiveTab,
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCostumeLeftActiveTab: (e) => dispatch(setCostumeLeftActiveTab(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccProduction);
