import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Divider,
  Tooltip,
  Icon,
} from "antd";
import moment from "moment";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { numberOnly, Capitalize } from "utilities/ValidationHelper";
import ActorsServices from "services/ActorsServices";
import { showNotifications } from "components/shared/NotificationComponent";
import { resolve } from "url";
import RecordNotFound from "components/shared/RecordNotFound";

const dateFormat = "DD-MM-YYYY";

// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                message: "Enter numbers onlys",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class SchedularActorAvailabilityTable extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.scriptservice = new ScriptService();
    this._actorsService = new ActorsServices();
    this.columns = [
      {
        title: "Name",
        dataIndex: "actorName",
        align: "center",
        render: (text, record) => (
          <>
            {Capitalize(record.actorName || record.locationName)}
            <Tooltip
              title={record.actorName ? "Actor name" : "Location name"}
              placement={GlobalConstants.RIGHT}
            >
              <a style={{ paddingLeft: 5 }}>
                <Icon type="exclamation-circle" />
              </a>
            </Tooltip>
          </>
        ),
        // render: (value, row, index) => {
        //     const obj = {
        //         children: value,
        //         props: {},
        //     };
        //     if (index === 0) {
        //         obj.props.rowSpan = 2;
        //     }
        //     // These two are merged into above cell
        //     if (index === 1) {
        //         obj.props.rowSpan = 0;
        //     }
        //     return obj;
        // },
      },
      {
        title: "From Date",
        dataIndex: "fromDate",
        align: "center",
        render: (text, record) =>
          text && (
            <DatePicker
              disabled={true}
              style={{ width: 140 }}
              defaultValue={text ? moment(text, dateFormat) : ""}
              onChange={(value, dateString) =>
                this.dateChange(value, dateString, record, "dateChange")
              }
              format={dateFormat}
              disabledDate={(current) => this.disabledDate(current)}
            />
          ),
      },
      {
        title: "To Date",
        dataIndex: "toDate",
        // editable: true,
        align: "center",
        render: (text, record) =>
          text && (
            <DatePicker
              disabled={true}
              style={{ width: 140 }}
              defaultValue={text ? moment(text, dateFormat) : ""}
              onChange={(value, dateString) =>
                this.dateChange(value, dateString, record, "dateChange")
              }
              format={dateFormat}
              disabledDate={(current) => this.disabledDate(current)}
            />
          ),
      },
      // {
      //     title: "Action",
      //     dataIndex: "operation",
      //     // align: "center",
      //     width: "5%",
      //     className: "operation",
      //     render: (text, record) =>
      //         this.state.dataSource.length >= 1 ? (
      //             <Row type="flex" justify="center" align="middle">
      //                 <Col xl={11}>
      //                     {record.actorAvailabilityId ? (
      //                         // <Link onClick={() => this.handleUpdate(record)}>Update / </Link>
      //                         <Tooltip
      //                             title={GlobalConstants.EDT}
      //                             placement={GlobalConstants.RIGHT}
      //                         >
      //                             <Button
      //                                 icon="edit"
      //                                 type="link"
      //                                 onClick={() => this.handleUpdate(record)}
      //                             />
      //                         </Tooltip>
      //                     ) : (
      //                             // <Link onClick={() => this.handleSubmit(record)}>Save / </Link>
      //                             <div>
      //                                 {this.state.actorId ? (
      //                                     <Tooltip
      //                                         title={GlobalConstants.SAV}
      //                                         placement={GlobalConstants.RIGHT}
      //                                     >
      //                                         {" "}
      //                                         <Button
      //                                             icon="save"
      //                                             type="link"
      //                                             onClick={() => this.handleSubmit(record)}
      //                                         />
      //                                     </Tooltip>
      //                                 ) : null}
      //                             </div>
      //                         )}
      //                 </Col>
      //                 <Col xl={2}>
      //                     {this.state.actorId ? <Divider type="vertical" /> : null}
      //                 </Col>
      //                 <Col xl={11}>
      //                     <Popconfirm
      //                         title="Sure to delete?"
      //                         onConfirm={() =>
      //                             this.handleDelete(record.key, record.actorAvailabilityId)
      //                         }
      //                     >
      //                         <Tooltip
      //                             title={GlobalConstants.DEL}
      //                             placement={GlobalConstants.RIGHT}
      //                         >
      //                             {" "}
      //                             <Button icon="delete" type="link" />
      //                         </Tooltip>
      //                     </Popconfirm>
      //                 </Col>
      //             </Row>
      //         ) : null
      // }
    ];

    this.state = {
      dataSource: [],
      count: 0,
      page: null,
      actorId: null,
      theCount: 0,
      propsDataSource: this.props.dataSource,
      // paymentCount: 0
    };
  }

  componentDidMount() {
    this.getActorDetails();
    this.checkForEmpty(this.state.dataSource);
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  disabledDate = (current, toDate) => {
    // Can not select days before today and today
    if (toDate) {
      return (
        current &&
        current <
          moment(moment(toDate, dateFormat).format("YYYY-MM-DD")).startOf("day")
      );
    }
    return current && current < moment().startOf("day");
  };

  // disabledDate1 = current => {
  //   console.log(current, "current");
  //   // Can not select days before today and today
  //   let date =  formdate;
  //   return current > date;
  // };

  // componentWillReceiveProps(nextProps) {
  //   if (this.state.actorId !== nextProps.actorId) {
  //     this.setState(
  //       {
  //         actorId: nextProps.actorId
  //       },
  //       () => this.getActorDetails()
  //     );
  //   }
  // }

  getActorDetails = () => {
    let data = this.state.propsDataSource && this.state.propsDataSource[0];
    let dataSource = [];

    new Promise((resolve) => {
      if (data)
        data.forEach((list) => {
          dataSource.push(Object.assign({}, list));
        });
      resolve();
    }).then(() => {
      this.setState({
        dataSource: dataSource,
      });
    });
  };

  handleSubmit = (record) => {
    let actorId = this.state.actorId;
    let actorAvailabilityId = record.actorAvailabilityId;
    let paymentId = null;
    let staffId = null;
    let data = {
      actorAvailability: record.actorAvailabilityId,
      actorId: actorId,
      fromDate: record.fromDate,
      toDate: record.toDate,
    };

    if (actorId) {
      this._actorsService
        .actorsServices(
          Services.ActorVariables.POST_ACTORAVAILABILITY_DETAILS,
          actorId,
          paymentId,
          data,
          staffId,
          actorAvailabilityId
        )
        .then((res) => {
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Saved Successfully!!"
          );
          // this.getArtSetData();
          this.getActorDetails();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Something Went Wrong"
          );
        });
    }
  };

  handleUpdate = (record) => {
    let actorId = record.actorId;
    let actorAvailabilityId = record.actorAvailabilityId;
    let paymentId = null;
    let staffId = null;
    let data = {
      actorAvailability: record.actorAvailabilityId,
      actorId: actorId,
      fromDate: record.fromDate,
      toDate: record.toDate,
    };
    if (record.fromDate <= record.toDate) {
      if (actorId) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.PUT_ACTORAVAILABILITY_DETAILS,
            actorId,
            paymentId,
            data,
            staffId,
            actorAvailabilityId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Updated Successfully!!"
            );
            // this.getActorDetails();
            // this.getArtSetData();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    } else {
      showNotifications(
        Notifications.ERROR,
        "Note",
        "To Date must Be Greater Than From Date Or They Must Equal!!"
      );
    }
  };

  handleDelete = (key, actorAvailabilityId) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        this.props.getFromChild(this.state.dataSource);
        this.checkForEmpty(this.state.dataSource);
      }
    );
    this.scriptservice
      .scriptService(
        Services.AddActor.DELETE_ACTOR_AVAILABILITY,
        actorAvailabilityId,
        console.log("hai iam there", actorAvailabilityId)
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Deleted",
          "Deleted Successfully!!"
        );
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      fromDate: "",
      toDate: "",
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, dateString, record, onChange) {
    this.handleSave(record, dateString, onChange);

    // const lengthOfTheDataSource = this.state.dataSource.length - 1;
    // const newData = [...this.state.dataSource];
    // const indexOfTheCurrentRow = newData.findIndex(
    //   data => record.key === data.key
    // );

    // if (
    //   record.staffName &&
    //   record.designation &&
    //   indexOfTheCurrentRow === lengthOfTheDataSource
    // ) {
    //   this.handleAdd();
    // }
  }
  dateChange(value, dateString, record, dateChange) {
    this.handleSave(record, dateString, dateChange);

    // const lengthOfTheDataSource = this.state.dataSource.length - 1;
    // const newData = [...this.state.dataSource];
    // const indexOfTheCurrentRow = newData.findIndex(
    //   data => record.key === data.key
    // );

    // if (
    //   record.staffName &&
    //   record.designation &&
    //   indexOfTheCurrentRow === lengthOfTheDataSource
    // ) {
    //   this.handleAdd();
    // }
  }

  handleSave = (row, value = null, type, date) => {
    if (row.actorAvailabilityId) {
      if (type === "onChange") {
        row.toDate = value;
      } else if (type === "dateChange") {
        row.fromDate = value;
      }
      if (row.fromDate && row.toDate) {
        if (
          moment(row.fromDate, dateFormat).format("YYYY-MM-DD") <=
          moment(row.toDate, dateFormat).format("YYYY-MM-DD")
        ) {
          this.handleUpdate(row);
          // this.props.getFromChild(this.state.dataSource);
        } else {
          showNotifications(
            Notifications.ERROR,
            `"TO DATE" Is Greater Than "FROM DATE"`,
            " "
          );
        }
      } else {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "All Fields Are Mandatory"
        );
      }
    }
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);

    if (type === "dateChange") {
      if (value) {
        let modifyItem = newData[index];
        modifyItem.fromDate = value;
      }
    }
    if (type === "onChange") {
      if (value) {
        let modifyDate = newData[index];
        modifyDate.toDate = value;
      }
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    newData.map((dateData, i) => {
      if (row.fromDate && value.toDate) {
        if (row.fromDate <= value.toDate) {
          this.setState({ dataSource: newData }, () => {
            // this.handleSubmit(row);
            this.props.getFromChild(this.state.dataSource);
          });
        }
      }
    });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "amount" ? "number" : "text",
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Row type="flex" justify="center" align="middle">
          <Col xl={24}>
            <Table
              components={components}
              className="editing-table"
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              locale={{
                emptyText: <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SchedularActorAvailabilityTable;
