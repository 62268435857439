import React, { Component, Fragment } from "react";
import "antd/dist/antd.css";
import {
  Table,
  Switch,
  Button,
  Input,
  Icon,
  AutoComplete,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Tag,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import ActorsServices from "services/ActorsServices";
import {
  Services,
  GlobalConstants,
  Notifications,
  LocalStorage,
  Screens,
} from "constants/AppConstants";
import { Capitalize, checkFor, StartCase } from "utilities/ValidationHelper";
import GlobalService from "services/GlobalService";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import { getSPermissionFromMenus } from "store/stateHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

import { Tabs } from "antd";
import RecordNotFound from "components/shared/RecordNotFound";
import { handleError } from "utilities/ErrorHandler";
import { setActiveTab } from "store/action";
import ThemeColors from "constants/ThemeConstants/variables";
import TooltipComponent from "components/shared/TooltipComponent";
import moment from "moment";

const { TabPane } = Tabs;

const data = [
  {
    key: "1",
    age: "1",
    loc: "Arjun",
    desc: "Aditya",
  },
  {
    key: "2",
    age: "2",
    loc: "Aditi",
    desc: "Yazhini",
  },
  {
    key: "3",
    age: "3",
    loc: "Madhu",
    desc: "Krithi",
  },
  {
    key: "4",
    age: "4",
    desc: "krishna",
    loc: "Yet to be confirmed",
  },
];
class Cast extends Component {
  constructor(props) {
    super(props);
    this._actorsServices = new ActorsServices();
    this._globalService = new GlobalService();

    this.state = {
      fetchActorsAPI: Services.ActorVariables.GET_ACTORS_MAIN,
      actorsList: null,
      isActorsFetched: false,
      searchText: "",
      index: 0,
      statusListDatas: [],
      typeListData: [],
      characterNameDataSource: [],
      actorNameDataSource: [],
      isPastShootingDate: false,
    };
  }

  componentDidMount() {
    this.fetchActors();
    this.getStatusFilter();
    this.getTypeFilter();
  }

  componentWillReceiveProps() {
    this.resetSearch();
  }

  getStatusFilter = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({ statusListDatas: res.data });
      });
  };

  getTypeFilter = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.CHARACTER_TYPE
      )
      .then((res) => {
        this.setState({ typeListData: res.data });
      });
  };

  onTabChanged = (key) => {
    switch (key) {
      case "1":
        this.setFetchApi(Services.ActorVariables.GET_ACTORS_MAIN);
        break;
      case "2":
        this.setFetchApi(Services.ActorVariables.GET_ACTORS_SUPPORTING);
        break;
      case "3":
        this.setFetchApi(Services.ActorVariables.GET_ACTORS_JUNIOR);
        break;
      case "4":
        this.setFetchApi(Services.ActorVariables.GET_ACTORS_ANIMAL);
        break;
      default:
        this.setFetchApi(Services.ActorVariables.GET_ACTORS_MAIN);
        break;
    }
  };

  fetchActors = () => {
    this.setState({
      isLoading: true,
    });
    this._actorsServices
      .actorsServices(this.state.fetchActorsAPI)
      .then((res) => {
        if (res.data.length !== 0) {
          let shootingDate = moment(res.data.firstShootingDate).format(
            "YYYY-MM-DD"
          );
          let currentDate = moment(new Date()).format("YYYY-MM-DD");
          this.setState(
            {
              isActorsFetched: true,
              actorsList: res.data.characters,
              isLoading: false,
              isPastShootingDate:
                moment(shootingDate).isSameOrBefore(currentDate),
            },
            () => {
              this.getCharachterName();
              this.getActorName();
              // this.getCompletedSceneStatus();
            }
          );
        } else {
          this.setState({ actorsList: [], isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ actorsList: [], isLoading: false });
      });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <AutoComplete
          onChange={(e) => setSelectedKeys(e ? [e] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
          dataSource={this.state.characterNameDataSource || []}
          placeholder="Character name"
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  resetSearch = () => {
    this.setState({
      index: this.state.index + 1,
    });
  };

  getCharachterName = () => {
    let characterData = [];
    const { fetchActorsAPI } = this.state;
    if (
      fetchActorsAPI !== Services.ActorVariables.GET_ACTORS_JUNIOR &&
      fetchActorsAPI !== Services.ActorVariables.GET_ACTORS_ANIMAL
    ) {
      this.state.actorsList &&
        this.state.actorsList.map((a) => {
          characterData.push(a.characterName);
        });
      this.setState({ characterNameDataSource: characterData });
    }
  };

  getActorName = () => {
    let actorData = [];
    this.state.actorsList &&
      this.state.actorsList.map((a) => {
        if (a.actorName) {
          actorData.push(a.actorName);
        }
      });
    this.setState({ actorNameDataSource: actorData });
  };

  // getCompletedSceneStatus = () => {
  //   let sceneCount = [];
  //   let scenes = [];
  //   const { fetchActorsAPI, actorsList } = this.state;
  //   if (
  //     fetchActorsAPI !== Services.ActorVariables.GET_ACTORS_JUNIOR &&
  //     fetchActorsAPI !== Services.ActorVariables.GET_ACTORS_ANIMAL
  //   ) {
  //     console.log(actorsList);
  //     actorsList &&
  //       actorsList.map((actor) => {
  //         sceneCount.push({
  //           confirmedSceneCount: actor.confirmedSceneCount,
  //           totalSceneCount: actor.totalSceneCount,
  //         });
  //         actor.scenes.map((scene) => {
  //           console.log(scene.length);
  //         });
  //       });
  //     sceneCount.map((scene) => {
  //       if (scene.confirmedSceneCount !== 0 || scene.totalSceneCount !== 0) {
  //         scenes.push({
  //           confirmedSceneCount: scene.confirmedSceneCount,
  //           totalSceneCount: scene.totalSceneCount,
  //         });
  //       }
  //     });
  //     console.log(sceneCount.length === scenes.length);
  //     return this.setState({ sceneCountData: sceneCount });
  //   }
  // };

  handleDelete = (characterId) => {
    this._actorsServices
      .actorsServices(
        Services.ActorVariables.DEL_CHARACTER,
        null,
        null,
        null,
        null,
        null,
        characterId
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Deleted",
          "Deleted Successfully!!"
        );
        this.fetchActors();
      });
  };

  setFetchApi = (data) => {
    new Promise((resolve) => {
      this.setState({
        fetchActorsAPI: data,
      });
      resolve();
    }).then(() => {
      this.fetchActors();
    });
  };

  handleUpdateName = () => {
    const { activeRecord, activeName } = this.state;

    const data = {
      characterName: activeName,
      characterType: activeRecord.characterType,
    };
    this._actorsServices
      .actorsServices(
        Services.ActorVariables.UPDATE_ACTOR_NAME,
        activeRecord.characterId,
        null,
        data
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchActors();
        this.handleClose();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].message,
          handleError(
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );
      });
  };

  handleClose = () => {
    this.setState({
      isEditEnabled: false,
      activeRecord: null,
    });
  };

  render() {
    const columns = [
      {
        title: "#",
        align: "center",
        width: "6%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "CHARACTER",
        dataIndex: "characterName",
        width: "15%",
        align: "left",
        // ...this.getColumnSearchProps("characterName"),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <AutoComplete
              onChange={(e) => setSelectedKeys(e ? [e] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              dataSource={this.state.characterNameDataSource}
              placeholder="Character name"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              focus={() => this.setState({ searchText: "" })}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon
            type="search"
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        ),
        onFilter: (value, record) => {
          if (record.characterName) {
            return record.characterName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },
        render: (text, record) => (
          <Fragment>
            <Link
              to={`/project/audition/${record.characterType}/${record.characterId}/${record.characterName}`}
            >
              {Capitalize(text)}
            </Link>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ? (
              <Icon
                type="edit"
                style={{ marginLeft: 10 }}
                onClick={() =>
                  this.setState({
                    isEditEnabled: true,
                    activeRecord: record,
                    activeName: record.characterName,
                  })
                }
              />
            ) : null}
          </Fragment>
        ),
      },
      {
        title: "SCENES",
        align: "left",
        dataIndex: "scenes",
        width: "15%",
        // ...this.getColumnSearchPropsId("scenes"),
        render: (text, record) => (
          <div className="sceneNumber">
            {text &&
              text.map((scene, i) => (
                <span>
                  <Tag
                    className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.ACTOR
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        scene.sceneId
                      );
                      this.props.setActiveTab("1");
                    }}
                  >
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={scene.sceneNumber}
                        text={scene.description}
                        placement="left"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },
      {
        title: "ACTOR",
        dataIndex: "actorName",
        width: "15%",
        align: "left",
        // ...this.getColumnSearchProps("actorName"),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <AutoComplete
              onChange={(e) => setSelectedKeys(e ? [e] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              dataSource={this.state.actorNameDataSource}
              placeholder="Actor name"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon
            type="search"
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        ),
        onFilter: (value, record) => {
          if (record.actorName) {
            return record.actorName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        },
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            // setTimeout(() => this.searchInput.select());
          }
        },
        render: (text, record) => {
          return checkFor(
            this.props.activePermisision,
            GlobalConstants.PERMISSION_EDIT
          ) ? (
            <Link
              to={`/project/actorprofile/${record.actorId}/${record.characterName}/${record.characterType}/${record.characterId}`}
            >
              {StartCase(text)}
            </Link>
          ) : (
            StartCase(text)
          );
        },
      },
      {
        title: "STATUS",
        dataIndex: "state",
        align: "center",
        width: "10%",
        filters:
          this.state.statusListDatas &&
          this.state.statusListDatas.map((a, i) => ({
            text: a.label,
            value: a.value,
          })),
        onFilter: (value, record) => {
          return record.state === value;
        },
        render: (text, record) => (
          <Tag className={`${record.state}`}>
            {text?.charAt(0)?.toUpperCase()?.replaceAll("_", " ") +
              text?.slice(1)?.toLowerCase()?.replaceAll("_", " ")}
          </Tag>
        ),
      },
      {
        title: "SCENE COMPLETED STATUS",
        key: "action",
        align: "center",
        width: "15%",
        visible: this.state.isPastShootingDate,
        render: (text, record) => (
          <span>
            <a>
              <Link
                to={`/project/audition/${record.characterType}/${record.characterId}/${record.characterName}`}
              >
                {`${record.confirmedSceneCount}/${record.totalSceneCount}`}
              </Link>
              {/* <Link to="/project/actorprofile/5">More</Link> */}
            </a>
          </span>
        ),
      },
      {
        title: "COMPLETED SCENES",
        dataIndex: "completedScenes",
        align: "left",
        width: "15%",
        visible: this.state.isPastShootingDate,
        render: (completedScenes, record) => (
          <div className="sceneNumber">
            {completedScenes &&
              completedScenes.map((completedScene, i) => (
                <span>
                  <Tag
                    className={`${completedScene.environmentType}${completedScene.timeType} sceneno-tag`}
                    style={{
                      color: "white",
                      backgroundColor: "#ccc",
                    }}
                    onClick={() => {
                      this.props.history.push("/project/screen-overview");
                      localStorage.setItem(
                        GlobalConstants.REDIRECT_TO,
                        Screens.ACTOR
                      );
                      localStorage.setItem(
                        LocalStorage.ACTIVE_SCENE_ID,
                        completedScene.sceneId
                      );
                      this.props.setActiveTab("1");
                    }}
                  >
                    <Link style={{ color: "white" }}>
                      <TooltipComponent
                        sceneNumber={completedScene.sceneNumber}
                        text={completedScene.description}
                        placement="left"
                      />
                    </Link>
                  </Tag>
                </span>
              ))}
          </div>
        ),
      },
    ];

    // if (
    //   this.sceneCountData &&
    //   this.sceneCountData.map(
    //     (scene) =>
    //       scene.confirmedSceneCount !== 0 || scene.totalSceneCount !== 0
    //   )
    // ) {
    //   columns.push(
    //     {
    //       title: "SCENE COMPLETED STATUS",
    //       key: "action",
    //       align: "center",
    //       width: "20%",
    //       render: (text, record) => (
    //         <span>
    //           <a>
    //             <Link
    //               to={`/project/audition/${record.characterType}/${record.characterId}/${record.characterName}`}
    //             >
    //               {`${record.confirmedSceneCount}/${record.totalSceneCount}`}
    //             </Link>
    //             {/* <Link to="/project/actorprofile/5">More</Link> */}
    //           </a>
    //         </span>
    //       ),
    //     },

    //   );
    // }

    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_DELETE)
    ) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "10%",
        className: "operation",
        render: (text, record) =>
          record.confirmedSceneCount === 0 && record.totalSceneCount === 0 ? (
            <Row>
              <Col xl={24}>
                <Popconfirm
                  title={`Are you sure to delete the character ${Capitalize(
                    record.characterName
                  )} ?`}
                  onConfirm={() => this.handleDelete(record.characterId)}
                >
                  <Tooltip
                    title={GlobalConstants.DEL}
                    placement={GlobalConstants.RIGHT}
                  >
                    {" "}
                    <Button icon="delete" type="link" />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          ) : null,
      });
    }
    const animalColumns = [
      {
        title: "#",
        align: "center",
        width: "10%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Scene",
        width: "30%",
        // dataIndex:"sceneNumber"
        render: (text, record) => (
          <span>
            {record.scene && (
              <Tag
                className={`${record.scene.environmentType}${record.scene.timeType} sceneno-tag`}
                style={{
                  backgroundColor: "#ccc",
                }}
                onClick={() => {
                  this.props.history.push("/project/screen-overview");
                  localStorage.setItem(
                    GlobalConstants.REDIRECT_TO,
                    Screens.ACTOR
                  );
                  localStorage.setItem(
                    LocalStorage.ACTIVE_SCENE_ID,
                    record.scene.sceneId
                  );
                  this.props.setActiveTab("1");
                }}
              >
                <Link
                  style={{
                    color: "white",
                  }}
                >
                  <TooltipComponent
                    sceneNumber={record.scene.sceneNumber}
                    text={record.scene.description}
                    placement="left"
                  />
                </Link>
              </Tag>
            )}
          </span>
        ),
      },
      {
        title: "CHARACTER",
        dataIndex: "characterName",
        width: "50%",
        key: "characterName",
        // ...this.getColumnSearchProps("characterName"),
        render: (text, record) =>
          record.characters &&
          record.characters.map((character, i) => (
            <Tag color={ThemeColors.primaryColor} className="statusTag">
              {Capitalize(character.name)}({character.count})
            </Tag>
          )),
      },

      // {
      //   title: "COUNT",
      //   dataIndex: "characterCount",
      //   width: "20%",
      //   // ...this.getColumnSearchProps("actorName"),
      //   // filterDropdown: ({
      //   //   setSelectedKeys,
      //   //   selectedKeys,
      //   //   confirm,
      //   //   clearFilters
      //   // }) => (
      //   //     <div style={{ padding: 8 }}>
      //   //       <AutoComplete
      //   //         onChange={e => setSelectedKeys(e ? [e] : [])}
      //   //         onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
      //   //         style={{ width: 188, marginBottom: 8, display: "block" }}
      //   //         dataSource={this.state.actorNameDataSource}
      //   //         placeholder="Actor Name"
      //   //         filterOption={(inputValue, option) =>
      //   //           option.props.children
      //   //             .toUpperCase()
      //   //             .indexOf(inputValue.toUpperCase()) !== -1
      //   //         }
      //   //       />
      //   //       <Button
      //   //         type="primary"
      //   //         onClick={() => this.handleSearch(selectedKeys, confirm)}
      //   //         icon="search"
      //   //         size="small"
      //   //         style={{ width: 90, marginRight: 8 }}
      //   //       >
      //   //         Search
      //   //     </Button>
      //   //       <Button
      //   //         onClick={() => this.handleReset(clearFilters)}
      //   //         size="small"
      //   //         style={{ width: 90 }}
      //   //       >
      //   //         Reset
      //   //     </Button>
      //   //     </div>
      //   //   ),
      //   // filterIcon: filtered => (
      //   //   <Icon
      //   //     type="search"
      //   //     style={{ color: filtered ? "#1890ff" : undefined }}
      //   //   />
      //   // ),
      //   // onFilter: (value, record) => {
      //   //   if (record.actorName) {
      //   //     return record.actorName
      //   //       .toString()
      //   //       .toLowerCase()
      //   //       .includes(value.toLowerCase());
      //   //   }
      //   // },
      //   // onFilterDropdownVisibleChange: visible => {
      //   //   if (visible) {
      //   //     // setTimeout(() => this.searchInput.select());
      //   //   }
      //   // },
      //   render: (text, record) => {
      //     return record.characterCount;
      //     // return checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_EDIT) ?
      //     //   (<Link
      //     //     to={`/project/actorprofile/${record.actorId}/${record.characterName}/${record.characterType}/${record.characterId}`}
      //     //   >
      //     //     {Capitalize(text)}
      //     //   </Link>) : Capitalize(text)
      //   }
      // },
      // {
      //   title: "CHARACTER TYPE",
      //   dataIndex: "characterType",
      //   align: "center",
      //   width: "15%",
      //   filters:
      //     this.state.typeListData &&
      //     this.state.typeListData.map((a, i) => ({
      //       text: a.label,
      //       value: a.label
      //     })),
      //   onFilter: (value, record) => {
      //     return record.characterType === value;
      //   },
      //   render: text => Capitalize(text)
      // },
      // {
      //   title: "AWB STATUS",
      //   dataIndex: "awsStatus",
      //   align: "center",
      //   width: "12%",
      //   // filters:
      //   //   this.state.statusListDatas &&
      //   //   this.state.statusListDatas.map((a, i) => ({
      //   //     text: a.label,
      //   //     value: a.label
      //   //   })),
      //   // onFilter: (value, record) => {
      //   //   console.log("value", record.state);
      //   //   return record.state === value;
      //   // },
      //   render: text => Capitalize(text)
      // }

      // {
      //   title: "SCENE COMPLETED STATUS",
      //   key: "action",
      //   align: "center",
      //   width: "10%",
      //   render: (text, record) => (
      //     <span>
      //       <a>
      //         <Link
      //           to={`/project/audition/${record.characterType}/${record.characterId}/${record.characterName}`}
      //         >
      //           {`${record.confirmedSceneCount}/${record.totalSceneCount}`}
      //         </Link>
      //         {/* <Link to="/project/actorprofile/5">More</Link> */}
      //       </a>
      //     </span>
      //   )
      // },
      // {
      //   title: "Action",
      //   dataIndex: "operation",
      //   // align: "center",
      //   width: "5%",
      //   className: "operation",
      //   render: (text, record) =>
      //     record.confirmedSceneCount === 0 && record.totalSceneCount === 0 ? (
      //       <Row>
      //         <Col xl={11}>
      //           <Popconfirm
      //             title={`Are you sure to delete the character ${Capitalize(
      //               record.characterName
      //             )} ?`}
      //             onConfirm={() => this.handleDelete(record.characterId)}
      //           >
      //             <Tooltip
      //               title={GlobalConstants.DEL}
      //               placement={GlobalConstants.RIGHT}
      //             >
      //               {" "}
      //               <Button icon="delete" type="link" />
      //             </Tooltip>
      //           </Popconfirm>
      //         </Col>
      //       </Row>
      //     ) : null
      // }
    ];

    if (
      checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_EDIT)
    ) {
      animalColumns.push({
        title: "Action",
        align: "center",
        render: (text, record) =>
          record.scene && (
            <Link to={`/project/actor/animal/${record.scene.sceneId}`}>
              More
            </Link>
          ),
      });
    }

    const juniorColumns = [
      {
        title: "#",
        align: "center",
        width: "10%",
        render: (text, record, index) => index + 1,
      },

      {
        title: "Scene No",
        width: "30%",
        // dataIndex:"sceneNumber"
        render: (text, record) => (
          <span>
            {record.scene && (
              <Tag
                className={`${record.scene.environmentType}${record.scene.timeType} sceneno-tag`}
                style={{
                  backgroundColor: "#ccc",
                }}
                onClick={() => {
                  this.props.history.push("/project/screen-overview");
                  localStorage.setItem(
                    GlobalConstants.REDIRECT_TO,
                    Screens.ACTOR
                  );
                  localStorage.setItem(
                    LocalStorage.ACTIVE_SCENE_ID,
                    record.scene.sceneId
                  );
                  this.props.setActiveTab("1");
                }}
              >
                <Link
                  style={{
                    color: "white",
                  }}
                  // to="/project/screen-overview"
                  // onClick={() => {
                  //   localStorage.setItem(
                  //     LocalStorage.ACTIVE_SCENE_ID,
                  //     record.scene.sceneId
                  //   );
                  // }}
                >
                  {/* {record.sceneNumber} */}
                  <TooltipComponent
                    sceneNumber={record.scene.sceneNumber}
                    text={record.scene.description}
                    placement="left"
                  />
                </Link>
              </Tag>
            )}
          </span>
        ),
      },
      {
        title: "CHARACTER",
        // dataIndex: "characterName",
        width: "60%",
        // ...this.getColumnSearchProps("characterName"),
        render: (text, record) => {
          return (
            record.characters &&
            record.characters.map((character, i) => (
              <Tag color={ThemeColors.success} className="statusTag">
                {Capitalize(character.name)}({character.count})
              </Tag>
            ))
          );
        },
      },
      // {
      //   title: "Count",
      //   dataIndex: "characterCount",
      //   width: "20%",
      //   // ...this.getColumnSearchProps("actorName"),
      //   // filterDropdown: ({
      //   //   setSelectedKeys,
      //   //   selectedKeys,
      //   //   confirm,
      //   //   clearFilters
      //   // }) => (
      //   //     <div style={{ padding: 8 }}>
      //   //       <AutoComplete
      //   //         onChange={e => setSelectedKeys(e ? [e] : [])}
      //   //         onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
      //   //         style={{ width: 188, marginBottom: 8, display: "block" }}
      //   //         dataSource={this.state.actorNameDataSource}
      //   //         placeholder="Actor Name"
      //   //         filterOption={(inputValue, option) =>
      //   //           option.props.children
      //   //             .toUpperCase()
      //   //             .indexOf(inputValue.toUpperCase()) !== -1
      //   //         }
      //   //       />
      //   //       <Button
      //   //         type="primary"
      //   //         onClick={() => this.handleSearch(selectedKeys, confirm)}
      //   //         icon="search"
      //   //         size="small"
      //   //         style={{ width: 90, marginRight: 8 }}
      //   //       >
      //   //         Search
      //   //     </Button>
      //   //       <Button
      //   //         onClick={() => this.handleReset(clearFilters)}
      //   //         size="small"
      //   //         style={{ width: 90 }}
      //   //       >
      //   //         Reset
      //   //     </Button>
      //   //     </div>
      //   //   ),
      //   // filterIcon: filtered => (
      //   //   <Icon
      //   //     type="search"
      //   //     style={{ color: filtered ? "#1890ff" : undefined }}
      //   //   />
      //   // ),
      //   // onFilter: (value, record) => {
      //   //   if (record.actorName) {
      //   //     return record.actorName
      //   //       .toString()
      //   //       .toLowerCase()
      //   //       .includes(value.toLowerCase());
      //   //   }
      //   // },
      //   // onFilterDropdownVisibleChange: visible => {
      //   //   if (visible) {
      //   //     // setTimeout(() => this.searchInput.select());
      //   //   }
      //   // },
      //   render: (text, record) => {
      //     return record.characterCount;
      //     // return checkFor(this.props.activePermisision, GlobalConstants.PERMISSION_EDIT) ?
      //     //   (<Link
      //     //     to={`/project/actorprofile/${record.actorId}/${record.characterName}/${record.characterType}/${record.characterId}`}
      //     //   >
      //     //     {Capitalize(text)}
      //     //   </Link>) : Capitalize(text)
      //   }
      // },
      // {
      //   title: "CHARACTER TYPE",
      //   dataIndex: "characterType",
      //   align: "center",
      //   width: "15%",
      //   filters:
      //     this.state.typeListData &&
      //     this.state.typeListData.map((a, i) => ({
      //       text: a.label,
      //       value: a.label
      //     })),
      //   onFilter: (value, record) => {
      //     return record.characterType === value;
      //   },
      //   render: text => Capitalize(text)
      // },
      // // {
      // //   title: "STATUS",
      // //   dataIndex: "state",
      // //   align: "center",
      // //   width: "12%",
      // //   filters:
      // //     this.state.statusListDatas &&
      // //     this.state.statusListDatas.map((a, i) => ({
      // //       text: a.label,
      // //       value: a.label
      // //     })),
      // //   onFilter: (value, record) => {
      // //     console.log("value", record.state);
      // //     return record.state === value;
      // //   },
      // //   render: text => Capitalize(text)
      // // },

      // // {
      // //   title: "SCENE COMPLETED STATUS",
      // //   key: "action",
      // //   align: "center",
      // //   width: "10%",
      // //   render: (text, record) => (
      // //     <span>
      // //       <a>
      // //         <Link
      // //           to={`/project/audition/${record.characterType}/${record.characterId}/${record.characterName}`}
      // //         >
      // //           {`${record.confirmedSceneCount}/${record.totalSceneCount}`}
      // //         </Link>
      // //         {/* <Link to="/project/actorprofile/5">More</Link> */}
      // //       </a>
      // //     </span>
      // //   )
      // // },
      // {
      //   title: "Action",
      //   dataIndex: "operation",
      //   // align: "center",
      //   width: "5%",
      //   className: "operation",
      //   render: (text, record) =>
      //     record.confirmedSceneCount === 0 && record.totalSceneCount === 0 ? (
      //       <Row>
      //         <Col xl={11}>
      //           <Popconfirm
      //             title={`Are you sure to delete the character ${Capitalize(
      //               record.characterName
      //             )} ?`}
      //             onConfirm={() => this.handleDelete(record.characterId)}
      //           >
      //             <Tooltip
      //               title={GlobalConstants.DEL}
      //               placement={GlobalConstants.RIGHT}
      //             >
      //               {" "}
      //               <Button icon="delete" type="link" />
      //             </Tooltip>
      //           </Popconfirm>
      //         </Col>
      //       </Row>
      //     ) : null
      // }
    ];

    const {
      actorsList,
      isActorsFetched,
      isLoading,
      isEditEnabled,
      activeName,
    } = this.state;
    const { activePermisision } = this.props;
    return (
      <Fragment>
        <Modal
          destroyOnClose
          className="createModal"
          visible={isEditEnabled}
          onCancel={this.handleClose}
          title="Edit Name"
          onOk={this.handleUpdateName}
        >
          <Row type="flex" justify="center">
            <Col xl={18}>
              <Input
                autoFocus
                placeholder="enter name"
                value={activeName}
                onChange={(e) => this.setState({ activeName: e.target.value })}
                onPressEnter={this.handleUpdateName}
              />
            </Col>
          </Row>
        </Modal>
        <Row span={24}>
          <Title>
            <TitleText>Actor </TitleText>
            <div>
              <Tooltip
                title={GlobalConstants.RESET}
                placement={GlobalConstants.TOP}
              >
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_VIEW
                ) && (
                  <Button type="primary" onClick={this.resetSearch}>
                    <Icon type="reload" style={{ fontSize: "15px" }} />
                  </Button>
                )}
              </Tooltip>
            </div>
          </Title>
        </Row>
        <Row style={{ padding: 10 }} type="flex" justify="center">
          <Col xl={24}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
              <div>
                {/* <Button
                  type="primary"
                  style={{ marginBottom: 15 }}
                  onClick={this.resetSearch}
                >
                  // Reset Filter
                  <Icon type="reload" style={{ fontSize: "15px" }} />
                </Button> */}
                <Tabs
                  defaultActiveKey="1"
                  onTabClick={this.onTabChanged}
                  destroyInactiveTabPane
                >
                  <TabPane tab="Main" key="1">
                    <Table
                      loading={isLoading}
                      key={this.state.index}
                      columns={columns}
                      dataSource={actorsList}
                      pagination={false}
                      locale={{
                        emptyText: !isLoading && <RecordNotFound />,
                      }}
                      // loading={!isActorsFetched}
                      // scroll={{ x: 0, y: "70vh" }}
                      bordered
                    />
                  </TabPane>
                  <TabPane tab="Supporting" key="2">
                    <Table
                      loading={isLoading}
                      key={this.state.index}
                      columns={columns}
                      dataSource={actorsList}
                      pagination={false}
                      locale={{
                        emptyText: !isLoading && <RecordNotFound />,
                      }}
                      // loading={!isActorsFetched}
                      // scroll={{ x: 0, y: "70vh" }}
                      bordered
                    />
                  </TabPane>
                  <TabPane tab="Junior" key="3">
                    <Table
                      loading={isLoading}
                      key={this.state.index}
                      columns={juniorColumns}
                      dataSource={actorsList}
                      pagination={false}
                      locale={{
                        emptyText: !isLoading && <RecordNotFound />,
                      }}
                      // loading={!isActorsFetched}
                      // scroll={{ x: 0, y: "70vh" }}
                      bordered
                    />
                  </TabPane>
                  <TabPane tab="Animal" key="4">
                    <Row type="flex" justify="center">
                      <Col xl={24}>
                        <Table
                          loading={isLoading}
                          key={this.state.index}
                          columns={animalColumns}
                          dataSource={actorsList}
                          pagination={false}
                          locale={{
                            emptyText: !isLoading && <RecordNotFound />,
                          }}
                          // loading={!isActorsFetched}
                          // scroll={{ x: 0, y: "70vh" }}
                          bordered
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            ) : (
              <ContactAdminErrorTemplate />
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cast);
