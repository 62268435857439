import React, { Component, Fragment } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Upload,
  Icon,
  Modal,
  Table,
  Tabs,
  Popover,
} from "antd";
import Carousel from "nuka-carousel";
import { ScriptService } from "services";
import { connect } from "react-redux";
import Axios from "axios";

import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Screens,
} from "constants/AppConstants";
import { Images } from "assets/images";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";

import { getScriptAPIURL, getScriptTemplateAPIURL } from "utilities/APIHelper";
import SkeletonLoader from "components/shared/SkeletonLoader";
import VideoPlayer from "components/shared/VideoPlayer";
import InsertScene from "components/Script/InsertScene";
import PieChart from "components/shared/NivoCharts/Pie";
import ProgressBar from "components/shared/ProgressBar";
import CreateScene from "components/Script/CreateScene";
import { SceneOVerviewVariable } from "constants/AppConstants/ServicesConstants";
import DashboardServices from "services/DashboardServices";
import DashboardStatisticsTemplate from "components/shared/DashboardStatisticsTemplate";
import RecordNotFound from "components/shared/RecordNotFound";
import "./dashboard.css";
import "./dashboard.scss";
import BarChart from "components/shared/ChartJs/Bar";
import ReadMore from "components/shared/ReadMore";
// import ParticleComponent from "components/shared/ParticlesComponent";
import ThemeColors from "constants/ThemeConstants/variables";
import { Link } from "react-router-dom";
import SkeletonComponent from "components/shared/SkeletonComponent";
import DashboardTemplateSkeleton from "./DashboardTemplateSkeleton";
import { getNotificationCount } from "store/thunk-actions";
import { Value } from "components/shared/Label";
import GlobalService from "services/GlobalService";
import { checkFor } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";

const { TabPane } = Tabs;

const content1 = (
  <div>
    <p> Scene will be created at the last scene of the project </p>
  </div>
);
const content = (
  <div>
    <p>Insert the scene before the selected scene number</p>
  </div>
);

const data = {
  labels: ["Red", "Green", "Yellow"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this._scriptService = new ScriptService();
    this._dashboardService = new DashboardServices();
    this.global = new GlobalService();
    this.state = {
      pieChartData: [],
      barChartData: [],
      projectData: JSON.parse(localStorage.getItem(LocalStorage.PROJECT_DATA)),
    };

    // showActionMessage(GlobalConstants.SHOW, "Loading Project Details...");
  }

  componentDidMount() {
    this.fetchProjectDetails();
    this.getSceneNumber();
    this.getCharWithNoCharMap();
    this.getLocationWithoutActualLocation();
    this.getCharactersNotConfirmed();
    this.getEquipmentNotMapped();
    this.getActorAvailability();
    this.getUnusedEquipment();
    this.getArtSetAlert();
    this.getCostumeAlert();
    this.getMakeupAlert();
    this.getEquipmentAlert();
    this.getSongAlert();
    this.getStuntAlert();
    this.getLocationAvailability();
    this.getSchedulerAlert();
    this.props.getNotificationCount();
    this.getPieChartData();
    this.getBarChartData();
    this.getBarChartData2();
    this.getPercent();
  }

  getPercent = () => {
    this.global
      .globalService(Services.GlobalVariables.GET_PROJECT_PERCENTAGE)
      .then((res) => {
        this.setState({
          Percent:
            res.data.percentage !== "NaN"
              ? res.data.percentage &&
                parseFloat(res.data.percentage).toFixed(2)
              : 0,
          PercentLoaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          PercentLoaded: true,
        });
      });
  };
  state = {
    Percent: 0,
    PercentLoaded: false,
    fileList: [],
    excelError: [],
    isLoading: true,
    projectDescription: null,
    projectType: null,
    projectName: null,
    projectId: null,
    production: null,
    projectImageId: null,
    errorVisible: false,
    isTabDisable: false,
    sceneCount: null,
    scenesWithNoCharMap: null,
    LocationsWithoutActual: null,
    CharactersNotConfirmed: null,
    NotMappedEquipments: null,
    ActorAvailability: null,
    UnusedEquipment: null,
    detailsFetched: false,
  };

  getUnusedEquipment = () => {
    this._dashboardService
      .service(GlobalConstants.UNUSED_EQUIPMENTS)
      .then((res) => {
        this.setState({
          UnusedEquipment: res.data.equipments,
          isUnusedEquipmentFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isUnusedEquipmentFetched: true,
        });
      });
  };

  getArtSetAlert = () => {
    this._dashboardService
      .service(GlobalConstants.ALERT, GlobalConstants.ART_SET_CAPS)
      .then((res) => {
        this.setState({
          ArtSetAlert: res.data.scenes,
          isArtSetAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isArtSetAlertFetched: true,
        });
      });
  };

  getCostumeAlert = () => {
    this._dashboardService
      .service(GlobalConstants.ALERT, GlobalConstants.COSTUME)
      .then((res) => {
        this.setState({
          CostumeAlert: res.data.scenes,
          isCostumeAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isCostumeAlertFetched: true,
        });
      });
  };
  getMakeupAlert = () => {
    this._dashboardService
      .service(GlobalConstants.ALERT, GlobalConstants.MAKEUP)
      .then((res) => {
        this.setState({
          MakeupAlert: res.data.scenes,
          isMakeupAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isMakeupAlertFetched: true,
        });
      });
  };
  getEquipmentAlert = () => {
    this._dashboardService
      .service(GlobalConstants.ALERT, GlobalConstants.EQUIPMENTS_CAPS)
      .then((res) => {
        this.setState({
          EquipmentAlert: res.data.scenes,
          isEquipmentAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isEquipmentAlertFetched: true,
        });
      });
  };
  getSongAlert = () => {
    this._dashboardService
      .service(GlobalConstants.ALERT, GlobalConstants.SONG_CAPS)
      .then((res) => {
        this.setState({
          SongAlert: res.data.scenes,
          isSongAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isSongAlertFetched: true,
        });
      });
  };
  getStuntAlert = () => {
    this._dashboardService
      .service(GlobalConstants.ALERT, GlobalConstants.STUNT_CAPS)
      .then((res) => {
        this.setState({
          StuntAlert: res.data.scenes,
          isStuntAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isStuntAlertFetched: true,
        });
      });
  };
  getSchedulerAlert = () => {
    this._dashboardService
      .service(GlobalConstants.SCHEDULER_ALERT)
      .then((res) => {
        this.setState({
          SchedulerAlert: res.data.scenes,
          isSchedulerAlertFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isSchedulerAlertFetched: true,
        });
      });
  };

  getActorAvailability = () => {
    this._dashboardService
      .service(GlobalConstants.ACTOR_AVAILABILITY)
      .then((res) => {
        this.setState({
          ActorAvailability: res.data.actorAvailabilityWarings,
          isActorAvailabilityFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isActorAvailabilityFetched: true,
        });
      });
  };
  getLocationAvailability = () => {
    this._dashboardService
      .service(GlobalConstants.LOCATION_AVAILABILITY)
      .then((res) => {
        this.setState({
          LocationAvailability: res.data.locationAvailabilityWarings,
          isLocationAvailabilityFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLocationAvailabilityFetched: true,
        });
      });
  };

  getEquipmentNotMapped = () => {
    this._dashboardService
      .service(GlobalConstants.EQUIPMENTS_WITHOUT_MAP)
      .then((res) => {
        this.setState({
          NotMappedEquipments: res.data.equipments,
          isNotMappedEquipmentsFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isNotMappedEquipmentsFetched: true,
        });
      });
  };

  getCharactersNotConfirmed = () => {
    this._dashboardService
      .service(GlobalConstants.CHARACTERS_WITHOUT_CONFIRM)
      .then((res) => {
        this.setState({
          CharactersNotConfirmed: res.data.sceneCharacters,
          isCharactersNotConfirmedFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isCharactersNotConfirmedFetched: true,
        });
      });
  };

  getLocationWithoutActualLocation = () => {
    this._dashboardService
      .service(
        Services.DashboardVariables.GET_LOCATIONS_WITHOUT_ACTUAL_LOCATION
      )
      .then((res) => {
        this.setState({
          LocationsWithoutActual: res.data.sceneLocations,
          isLocationsWithoutActualFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLocationsWithoutActualFetched: true,
        });
      });
  };

  getCharWithNoCharMap = () => {
    this._dashboardService
      .service(Services.DashboardVariables.GET_SCENE_LIST_WITH_NO_CHAR_MAP)
      .then((res) => {
        this.setState({
          scenesWithNoCharMap: res.data.scenes,
          isscenesWithNoCharMapFetched: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isscenesWithNoCharMapFetched: true,
        });
      });
  };

  fetchProjectDetails = () => {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    Axios.get(`${getScriptAPIURL()}/projects/${projectId}`, config)
      .then((response) => {
        this.setState({
          projectImageId: response.data.projectimageId,
          projectDescription: response.data.projectDescription,
          projectType: response.data.projectType,
          producerName: response.data.producerName,
          projectName: response.data.projectName,
          sceneCount: response.data.sceneCount,
          production: response.data.companyName,
          detailsFetched: true,
        });
        // showActionMessage(GlobalConstants.HIDE);
      })
      .catch((err) => {
        // showActionMessage(GlobalConstants.HIDE);
        this.setState({
          detailsFetched: true,
        });
      });
  };
  handleChange = (info) => {
    let fileList = [...info.fileList];
    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);
    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    if (fileList.length > 0) {
      var onMyForm = new FormData();
      onMyForm.append("file", fileList[0].originFileObj);
      console.log("file", fileList);
      console.log(onMyForm);
      showActionMessage(GlobalConstants.SHOW, "Script Upload is in Process");
      this._scriptService
        .scriptService(
          Services.ScriptVariables.UPLOAD,
          this.props.match.params.id,
          onMyForm
        )
        .then(
          (res) => {
            this.setState({ fileList: null });
            showActionMessage(GlobalConstants.HIDE);
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Script uploaded Successfully!!"
            );
            this.fetchProjectDetails();
          },
          (err) => {
            this.setState(
              {
                fileList: null,
                excelError: err.response.data.errors,
                errorVisible: true,
              },
              () => {
                console.log("error.....");
                showActionMessage(GlobalConstants.HIDE);
              }
            );
          }
        );
    }
  };

  scriptDownload = (e) => {
    this._scriptService
      .scriptService(Services.ScriptVariables.DOWNLOAD)
      .then((res) => {
        let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  };

  getSceneNumber() {
    this._scriptService
      .scriptService(SceneOVerviewVariable.GET_SCENE_NUMBERS_DROPDOWN)
      .then((res) => {
        this.setState({ sceneNumberList: res.data.sceneNumbers }, () => {
          this.setState({
            isTabDisable: this.state.sceneNumberList.length === 0,
          });
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          sceneNumberList: [],
        });
      });
  }

  getPieChartData() {
    this._dashboardService
      .service(GlobalConstants.GET_DASH_CHART, {
        type: "pie",
        isCategory: false,
        isTilldate: false,
      })
      .then((res) => {
        this.setState({
          pieChartData: res.data || [],
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          pieChartData: [],
        });
      });
  }

  getBarChartData() {
    this._dashboardService
      .service(GlobalConstants.GET_DASH_CHART, {
        type: "bar",
        isCategory: true,
        isTilldate: false,
      })
      .then((res) => {
        // console.clear();
        console.log(JSON.stringify(res.data, null, 2));
        this.setState({
          barChartData: res.data || [],
          chartDataLoaded: true,
          BarIschartRequired: res.data.ischartRequired,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          barChartData: [],
          chartDataLoaded: true,
        });
      });
  }
  getBarChartData2() {
    this._dashboardService
      .service(GlobalConstants.GET_DASH_CHART, {
        type: "bar",
        isCategory: false,
        isTilldate: false,
      })
      .then((res) => {
        // console.clear();
        console.log(JSON.stringify(res.data, null, 2));
        this.setState({
          barChartData2: res.data || [],
          // chartDataLoaded: true,
          // BarIschartRequired: res.data.ischartRequired
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          barChartData: [],
          chartDataLoaded: true,
        });
      });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    // console.clear();
    // console.log(this.state.Percent);
    const {
      pieChartData,
      barChartData,
      barChartData2,
      chartDataLoaded,
      Percent,
      PercentLoaded,
      BarIschartRequired,
    } = this.state;

    // const barChartData = {
    //   labels: [
    //     "Production",
    //     "Art Set",
    //     "Equipment",
    //     "Costumes & Makeup",
    //     "Songs & Stunts"
    //   ],
    //   datasets: [
    //     {
    //       label: "Estimation Cost",
    //       //   backgroundColor: "rgba(255,99,132,0.2)",
    //       backgroundColor: [
    //         // for custom bar color
    //         "#ffed82",
    //         "#f0cfcf",
    //         "#ec5a5a",
    //         "#93d4a5",
    //         "#86ddcd"
    //       ],
    //       //   strokeColor: [
    //       //     // for custom bar border color
    //       //     "#ffed82",
    //       //     "#f0cfcf",
    //       //     "#ec5a5a",
    //       //     "#93d4a5",
    //       //     "#86ddcd"
    //       //   ],
    //       //   borderColor: "rgba(255,99,132,1)",
    //       borderWidth: 0,
    //       //   hoverBackgroundColor: "rgba(255,99,132,0.4)",
    //       //   hoverBorderColor: "rgba(255,99,132,1)",
    //       data: [10000, 5900, 80000, 30000, 58000]
    //     },
    //     {
    //       label: "Expenditure Cost",
    //       backgroundColor: [
    //         // for custom bar color
    //         "#ffffb3",
    //         "#ffe6e6",
    //         "#ff8c87",
    //         "#c5ffd6",
    //         "#b9ffff"
    //       ],
    //       borderWidth: 0,
    //       //   hoverBackgroundColor: "rgba(255,99,132,0.4)",
    //       //   hoverBorderColor: "rgba(255,99,132,1)",
    //       data: [5000, 6500, 73000, 23000, 59000]
    //     }
    //   ]
    // };

    //  const pieChartData = [
    //   {
    //     id: "Art Set",
    //     label: "Art Set",
    //     value: 100000,
    //     color: "orange"
    //   },
    //   {
    //     id: "Equipment",
    //     label: "Equipment",
    //     value: 10000,
    //     color: "indianred"
    //   },
    //   {
    //     id: "Costumes",
    //     label: "Costumes",
    //     value: 18900,
    //     color: "tomato"
    //   }
    // ]

    const columns = [
      {
        title: "Scene Number",
        dataIndex: "sceneNumber",
      },
      {
        title: "Invalid Column",
        dataIndex: "invalidColumn",
      },
    ];
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      // multiple: true
    };
    const {
      projectImageId,
      sceneCount,
      scenesWithNoCharMap,
      LocationsWithoutActual,
      CharactersNotConfirmed,
      NotMappedEquipments,
      ActorAvailability,
      UnusedEquipment,
      detailsFetched,
      isUnusedEquipmentFetched,
      isscenesWithNoCharMapFetched,
      isLocationsWithoutActualFetched,
      isCharactersNotConfirmedFetched,
      isNotMappedEquipmentsFetched,
      isActorAvailabilityFetched,
      ArtSetAlert,
      isArtSetAlertFetched,
      CostumeAlert,
      isCostumeAlertFetched,
      MakeupAlert,
      isMakeupAlertFetched,
      EquipmentAlert,
      isEquipmentAlertFetched,
      SongAlert,
      isSongAlertFetched,
      StuntAlert,
      isStuntAlertFetched,
      LocationAvailability,
      isLocationAvailabilityFetched,
      SchedulerAlert,
      isSchedulerAlertFetched,
    } = this.state;

    // const detailsFetched = false;
    let showUploadButton = sceneCount === 0;
    const imageSrc = projectImageId
      ? `${getScriptAPIURL()}/file/download/${projectImageId}`
      : Images.camera;

    const { activePermisision } = this.props;

    const roleName = this.props?.currentUser?.roleName;
    return (
      <div style={{ backgroundColor: "#f5f5f8" }}>
        <Modal
          className="errorModal"
          visible={this.state.errorVisible}
          onOk={() => this.setState({ errorVisible: false })}
          onCancel={() =>
            this.setState({
              errorVisible: false,
            })
          }
          footer={
            <Button
              type="primary"
              onClick={() => this.setState({ errorVisible: false })}
            >
              Ok
            </Button>
          }
        >
          <Table
            columns={columns}
            dataSource={this.state.excelError}
            pagination={false}
            locale={{
              emptyText: <RecordNotFound />,
            }}
          />
        </Modal>
        <Row>
          <Row style={{ overflow: "hidden" }}>
            <Col xs={24}>
              <Col style={{ width: "100%", position: "relative" }}>
                <Col>
                  {detailsFetched ? (
                    <Row
                      className="project-detail"
                      style={showUploadButton ? { height: 180 } : {}}
                    >
                      {!projectImageId ? (
                        <Col
                          className="projectImage"
                          style={{ backgroundColor: ThemeColors.primaryColor }}
                        >
                          {/* <ParticleComponent type="Snow" /> */}
                        </Col>
                      ) : (
                        <>
                          <img
                            alt="Project Image"
                            // onError={e => {
                            //   e.target.onerror = null;
                            //   e.target.src = `${Images.camera}`;
                            // }}
                            src={imageSrc}
                            className="projectImage"
                          />
                          <div className="image-overlay"></div>
                        </>
                      )}
                      <Col xs={24}>
                        <Col xs={showUploadButton ? 18 : 24}>
                          <Row style={{ padding: 10 }}>
                            <Col xs={24}>
                              <span className="project-name">
                                {this.state.projectName}
                              </span>
                            </Col>
                            <Col xs={24} style={{ marginBottom: 5 }}>
                              <span className="project-description">
                                <ReadMore lines={1}>
                                  {this.state.projectDescription}
                                </ReadMore>
                              </span>
                            </Col>
                            <Col xs={showUploadButton ? 12 : 8}>
                              <Col xs={showUploadButton ? 7 : 8}>
                                <span
                                  className="project-detail-label"
                                  style={
                                    projectImageId ? { color: "white" } : {}
                                  }
                                >
                                  Project Type
                                </span>
                              </Col>
                              <Col xs={1}>:</Col>
                              <Col>
                                <span className="product-detail-value">
                                  {this.state.projectType}
                                </span>
                              </Col>
                            </Col>
                            <Col xs={showUploadButton ? 12 : 8}>
                              <Col xs={showUploadButton ? 7 : 8}>
                                <span
                                  className="project-detail-label"
                                  style={
                                    projectImageId ? { color: "white" } : {}
                                  }
                                >
                                  Producer Name
                                </span>
                              </Col>
                              <Col xs={1}>:</Col>
                              <Col>
                                <span className="product-detail-value">
                                  {this.state.producerName}
                                </span>
                              </Col>
                            </Col>
                            <Col xs={showUploadButton ? 12 : 8}>
                              <Col xs={showUploadButton ? 7 : 8}>
                                <span
                                  className="project-detail-label"
                                  style={
                                    projectImageId ? { color: "white" } : {}
                                  }
                                >
                                  Production
                                </span>
                              </Col>
                              <Col xs={1}>:</Col>
                              <Col>
                                <span className="product-detail-value">
                                  {this.state.production}
                                </span>
                              </Col>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={{ span: 6 }} className="text-center">
                          <Card
                            className="upload project-script-upload"
                            // style={{ height: "240px" }}
                          >
                            {showUploadButton && (
                              <div>
                                <h3 className="upload-label">Script :</h3>
                                <div>
                                  <Upload
                                    {...props}
                                    fileList={this.state.fileList}
                                  >
                                    <Button
                                      type="primary"
                                      style={{
                                        backgroundColor: "white",
                                        color: ThemeColors.primaryColor,
                                      }}
                                    >
                                      Upload
                                    </Button>
                                  </Upload>
                                  <h4 className="upload-label">or</h4>
                                </div>
                                <div>
                                  <a
                                    href={
                                      this.state.projectData?.projectTypeId ===
                                      5
                                        ? `${getScriptTemplateAPIURL()}/script_template/ACE_WEB_SERIES_SCRIPT_BREAK_DOWN_TEMPLATE.xlsx`
                                        : `${getScriptTemplateAPIURL()}/script_template/ACE_SCRIPT_BREAK_DOWN_TEMPLATE.xlsx`
                                    }
                                  >
                                    <Button
                                      // onClick={this.scriptDownload}
                                      type="link"
                                      className="upload-label"
                                      style={{
                                        backgroundColor: "white",
                                        color: ThemeColors.primaryColor,
                                      }}
                                    >
                                      <Icon
                                        type="download"
                                        style={{
                                          fontSize: "16px",
                                          color: ThemeColors.primaryColor,
                                        }}
                                      />
                                      Template
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            )}
                            {/* <Button
                              type="primary"
                              style={{ textAlign: "center" }}
                              onClick={this.showModal}
                            >
                              Create
                            </Button>
                            <Modal
                              destroyOnClose={true}
                              visible={this.state.visible}
                              onCancel={this.handleCancel}
                              footer={false}
                              title="New Scene"
                              className="createModal"
                            >
                              <Tabs defaultActiveKey="1">
                                <TabPane
                                  tab={
                                    <span>
                                      Create
                                      <Popover content={content1}>
                                        <Icon
                                          style={{ paddingLeft: "10px" }}
                                          type="info-circle"
                                        />
                                      </Popover>
                                    </span>
                                  }
                                  key="1"
                                >
                                  <CreateScene
                                    pageType="createScene"
                                    refreshData={this.getScriptBreakdownData}
                                    closeModal={this.handleCancel}
                                  />
                                </TabPane>
                                <TabPane
                                  disabled={this.state.isTabDisable}
                                  tab={
                                    <span>
                                      Insert
                                      <Popover content={content}>
                                        <Icon
                                          style={{ paddingLeft: "10px" }}
                                          type="info-circle"
                                        />
                                      </Popover>
                                    </span>
                                  }
                                  key="2"
                                >
                                  <InsertScene
                                    tabDisable={() => this.tabDisable}
                                    refreshData={this.getScriptBreakdownData}
                                    closeModal={this.handleCancel}
                                  />
                                </TabPane>
                              </Tabs>
                            </Modal> */}
                          </Card>
                        </Col>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="dashboardSkeleton">
                        <Row>
                          <Col>
                            <SkeletonComponent width={200} height={25} />
                          </Col>
                          <Col style={{ marginTop: 10 }}>
                            <SkeletonComponent count={1} />
                          </Col>
                          <Row style={{ marginTop: 5 }}>
                            <Col xl={8}>
                              <SkeletonComponent width={200} />
                            </Col>
                            <Col xl={8}>
                              <SkeletonComponent width={200} />
                            </Col>
                            <Col xl={8}>
                              <SkeletonComponent width={200} />
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Col>
            </Col>
          </Row>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
            <Fragment>
              {GlobalConstants.PROJECT_COMPLETION_STATUS.includes(roleName) && (
                <Row>
                  {PercentLoaded ? (
                    <Col xl={24} style={{ padding: 10 }}>
                      <Col xl={24} className="completionArea">
                        <Value>
                          Project Information Completion Status - {Percent}%
                        </Value>
                        <ProgressBar Percent={Percent} />
                      </Col>
                    </Col>
                  ) : (
                    <Col xl={24} style={{ padding: 10 }}>
                      <Col xl={24} className="completionArea">
                        <SkeletonComponent width={250} height={13} />
                        <SkeletonComponent width={700} height={13} />
                      </Col>
                    </Col>
                  )}
                </Row>
              )}

              {GlobalConstants.CHART_INFO.includes(roleName) && (
                <Fragment>
                  {detailsFetched && chartDataLoaded ? (
                    BarIschartRequired && (
                      <Row
                        style={{
                          marginRight: 10,
                          marginLeft: 10,
                          // borderRadius: 8,
                          // marginBottom: 0,
                          // marginTop: 10,
                          backgroundColor: "white",
                          height: 350,
                          padding: 10,
                        }}
                      >
                        <Col
                          xl={16}
                          style={{
                            height: "100%",
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <Carousel
                            className="dashboardCarousal"
                            autoplay
                            wrapAround
                            renderCenterLeftControls={({ previousSlide }) => (
                              <button
                                className="carousalButton"
                                onClick={previousSlide}
                              >
                                {"<"}
                              </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                              <button
                                className="carousalButton"
                                onClick={nextSlide}
                              >
                                {">"}
                              </button>
                            )}
                          >
                            <Col xl={24} style={{ height: "100%" }}>
                              <BarChart
                                title="Budget Vs Actual"
                                height={330}
                                data={barChartData}
                              />
                            </Col>
                            <Col xl={24} style={{ height: "100%" }}>
                              <BarChart
                                title="Production"
                                height={330}
                                data={barChartData2}
                              />
                            </Col>
                          </Carousel>
                        </Col>
                        <Col xl={8} style={{ height: "100%" }}>
                          <Col style={{ height: "100%" }}>
                            <PieChart data={pieChartData} />
                          </Col>
                          <Col className="detailedView" xl={24}>
                            <Link to="/project/accountoverview">
                              Detailed view
                            </Link>
                          </Col>
                        </Col>
                      </Row>
                    )
                  ) : (
                    <Row
                      style={{
                        margin: 10,
                        // borderRadius: 8,
                        marginBottom: 0,
                        marginTop: 10,
                        backgroundColor: "white",
                        height: 350,
                        padding: 10,
                      }}
                    >
                      <Col>
                        <SkeletonComponent height={320} />
                      </Col>
                    </Row>
                  )}
                </Fragment>
              )}
              {/* {detailsFetched && ( */}
              <Row style={{ paddingBottom: 10 }}>
                <Col xs={24}>
                  {scenesWithNoCharMap && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Map Character with Scenes"}
                      data={scenesWithNoCharMap}
                      type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                      activeTab={Screens.ACTOR}
                    />
                  )}
                  {!isscenesWithNoCharMapFetched && (
                    <DashboardTemplateSkeleton />
                  )}
                  {LocationsWithoutActual && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Confirm the Actual Locations"}
                      data={LocationsWithoutActual}
                      type={GlobalConstants.LOCATION_WITHOUT_ACTUAL}
                    />
                  )}
                  {!isLocationsWithoutActualFetched && (
                    <DashboardTemplateSkeleton />
                  )}
                  {CharactersNotConfirmed && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Confirm Actor"}
                      data={CharactersNotConfirmed}
                      type={GlobalConstants.CHARACTERS_WITHOUT_CONFIRM}
                    />
                  )}
                  {!isCharactersNotConfirmedFetched && (
                    <DashboardTemplateSkeleton />
                  )}
                  {NotMappedEquipments && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Map Equipments with Vendor"}
                      data={NotMappedEquipments}
                      type={GlobalConstants.EQUIPMENTS_WITHOUT_MAP}
                    />
                  )}
                  {!isNotMappedEquipmentsFetched && (
                    <DashboardTemplateSkeleton />
                  )}
                  {ActorAvailability && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Actor Availability Warnings"}
                      data={ActorAvailability}
                      type={GlobalConstants.ACTOR_AVAILABILITY}
                    />
                  )}
                  {!isActorAvailabilityFetched && <DashboardTemplateSkeleton />}
                  {LocationAvailability && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Location Availability Warnings"}
                      data={LocationAvailability}
                      type={GlobalConstants.LOCATION_AVAILABILITY}
                    />
                  )}
                  {!isLocationAvailabilityFetched && (
                    <DashboardTemplateSkeleton />
                  )}
                  {UnusedEquipment && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Unused Equipments"}
                      refreshData={this.getUnusedEquipment}
                      data={UnusedEquipment}
                      type={GlobalConstants.UNUSED_EQUIPMENTS}
                    />
                  )}
                  {!isUnusedEquipmentFetched && <DashboardTemplateSkeleton />}
                  {GlobalConstants.ARTSET_ALERT.includes(roleName) && (
                    <Fragment>
                      {ArtSetAlert && (
                        <DashboardStatisticsTemplate
                          {...this.props}
                          title={"Art Set Alerts"}
                          refreshData={this.getArtSetAlert}
                          data={ArtSetAlert}
                          type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                          activeTab={Screens.ART_SET}
                        />
                      )}
                      {!isArtSetAlertFetched && <DashboardTemplateSkeleton />}
                    </Fragment>
                  )}
                  {GlobalConstants.COSTUME_ALERT.includes(roleName) && (
                    <Fragment>
                      {CostumeAlert && (
                        <DashboardStatisticsTemplate
                          {...this.props}
                          title={"Costume Alerts"}
                          refreshData={this.getCostumeAlert}
                          data={CostumeAlert}
                          type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                          activeTab={Screens.COSTUMES}
                        />
                      )}
                      {!isCostumeAlertFetched && <DashboardTemplateSkeleton />}
                    </Fragment>
                  )}
                  {MakeupAlert && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Makeup Alerts"}
                      refreshData={this.getMakeupAlert}
                      data={MakeupAlert}
                      type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                      activeTab={Screens.MAKEUP}
                    />
                  )}
                  {!isMakeupAlertFetched && <DashboardTemplateSkeleton />}
                  {GlobalConstants.EQUIPMENT_ALERT.includes(roleName) && (
                    <Fragment>
                      {EquipmentAlert && (
                        <DashboardStatisticsTemplate
                          {...this.props}
                          title={"Equipment Alerts"}
                          refreshData={this.getEquipmentAlert}
                          data={EquipmentAlert}
                          type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                          activeTab={Screens.EQUIPMENTS}
                        />
                      )}
                      {!isEquipmentAlertFetched && (
                        <DashboardTemplateSkeleton />
                      )}
                    </Fragment>
                  )}
                  {SongAlert && SongAlert.length !== 0 && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Song Alerts"}
                      refreshData={this.getSongAlert}
                      data={SongAlert}
                      type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                      activeTab={Screens.SONGS}
                    />
                  )}
                  {!isSongAlertFetched && <DashboardTemplateSkeleton />}
                  {StuntAlert && StuntAlert.length !== 0 && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Stunt Alerts"}
                      refreshData={this.getStuntAlert}
                      data={StuntAlert}
                      type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                      activeTab={Screens.STUNTS}
                    />
                  )}
                  {!isStuntAlertFetched && <DashboardTemplateSkeleton />}
                  {SchedulerAlert && (
                    <DashboardStatisticsTemplate
                      {...this.props}
                      title={"Scheduler Alerts"}
                      refreshData={this.getSchedulerAlert}
                      data={SchedulerAlert}
                      type={GlobalConstants.SCENE_WITH_NO_CHAR_MAP}
                    />
                  )}
                  {!isSchedulerAlertFetched && <DashboardTemplateSkeleton />}
                </Col>
              </Row>
            </Fragment>
          ) : (
            <ContactAdminErrorTemplate />
          )}
          {/* )} */}
          {/* <Row>
            <Col xl={24}>
              <Doughnut data={data} />
            </Col>
          </Row> */}
          {/* <img
            src={Images.scene}
            style={{
              objectFit: "contain",
              width: "500px",
              height: "400px",
              paddingLeft: "100px"
            }}
            alt="scene"
          />
          <img
            src={Images.type}
            style={{
              objectFit: "contain",
              width: "450px",
              height: "400px",
              padding: "30px"
            }}
            alt="type"
          /> */}
          {/* <img
            src={Images.script}
            style={{
              objectFit: "contain",
              width: "500px",
              height: "400px",
              paddingLeft: "100px"
            }}
            alt="time"
          /> */}
          {/* <img
            src={Images.time}
            style={{
              objectFit: "contain",
              width: "470px",
              height: "470px",
              padding: "40px"
            }}
            alt="script"
          /> */}
          {/* <Row type="flex" justify="end">
            <Col xs={6}>
              <VideoPlayer
                videoSource="https://www.youtube.com/embed/AsPX_On5bqc"
                title="How this works??"
              />
            </Col>
          </Row> */}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ user, activeItems }) => {
  return {
    currentUser: user.currentUser,
    accessToken: user.accessToken,
    activePermisision: getSPermissionFromMenus(
      PermissionPage.DASHBOARD,
      user.menus
    ),
    // projectImageId: activeItems.activeProjectIDs.projectImageId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
