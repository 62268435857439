import { string } from "prop-types";
import { startCase, isEqual } from "lodash";
import moment from "moment";

import { showActionMessage } from "components/shared/NotificationComponent";
import { GlobalConstants } from "constants/AppConstants";

export const numberOnly = (event) => {
  let number = event.target.value.replace(/[^0-9]/g, "");
  event.target.value = number;
  return number;
};

export const number = (e) => {
  const telNo = e.target.value;
  const re = /^[0-9\b]+$/;
  if (telNo === "" || re.test(telNo)) {
    return e.target.value;
  }
};

export const checkFor = (array, value) => {
  return array && array.includes(value);
};

export const removeColumns = (columns, array) => {
  array = array.map((array) => array.toLowerCase());
  return columns.filter((column) => {
    if (array.includes(column.title.toLowerCase())) {
      return;
    } else {
      return column;
    }
  });
};

export const amountOnly = (event) => {
  let number = event.target.value.replace(/[^0-9.]/g, "");
  event.target.value = number;
  return parseFloat(number).toFixed(2);
};

export const disableFutureDates = (current) => {
  // Can not select days before today and today
  if (current) {
    return current > moment();
  }
};

export const numberSecOnly = (event, sec) => {
  if (sec < 60) {
  }
  let number = event.target.value.replace(/[^0-9]/g, "");
  if (number < 60) {
    event.target.value = number;
    return number;
  }
};

export const PriceFormat = (input, withSymbol = false) => {
  if (input) {
    if (!isNaN(input)) {
      // var currencySymbol = "₹";
      // &#8377; indian currency symbol
      var currencySymbol = withSymbol ? "₹" : "";
      //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!
      var result = input.toString().split(".");

      var lastThree = result[0].substring(result[0].length - 3);
      var otherNumbers = result[0].substring(0, result[0].length - 3);
      if (otherNumbers != "") lastThree = "," + lastThree;
      var output =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      if (result.length > 1) {
        output += "." + result[1];
      }

      return currencySymbol + output;
    }
  }
};
// value && Intl.NumberFormat("en-IN").format(value);

export const MaskPaymentType = (type = "") => {
  switch (type) {
    case "CONTRACT":
      return "Contract";
    case "PER_DAY":
      return "Per Day";
    default:
      return type;
  }
};

export const DownloadFormURL = (fileUrl = "") => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.click();
};

export const ReplaceWithSpace = (str) => {
  if (str) {
    return startCase(str.toLowerCase());
  }
};

export const Capitalize = (str) => {
  if (str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
};

export const StartCase = (str) => str && startCase(str);

export const removePercentage = (str) => {
  return str.replaceAll("%", "");
};

export const notAllowNumber = (event) => {
  if (event) {
    return event.replace(/[^0-9]/g, "");
  }
};

export const handleGoogleResponse = (res) => {
  if (
    res.data.results[0].address_components[1] &&
    res.data.results[0].address_components[2] &&
    res.data.results[0].address_components[3]
  ) {
    return [
      res.data.results[0].address_components[1].long_name,
      (res.data.results[0].address_components[4] &&
        res.data.results[0].address_components[3].long_name) ||
        res.data.results[0].address_components[2].long_name,
      (res.data.results[0].address_components[4] &&
        res.data.results[0].address_components[4].long_name) ||
        res.data.results[0].address_components[3].long_name,
    ];
  } else {
    showActionMessage(
      GlobalConstants.WARNING,
      `No data found in this pincode, Please try again`
    );
    return [null, null, null];
    // this.props.getDetails(null, null, null, e);
  }
};

export const checkArray = (array) => (Array.isArray(array) ? array : []);

export const getArrayLength = (array) => array && array.length;

export const checkEqual = (current, existing) =>
  isEqual(
    JSON.parse(JSON.stringify(current)),
    JSON.parse(JSON.stringify(existing))
  );

export const checkDateGreaterThan = (givenDateToCheck, dateToCheckWith) => {
  let a = new Date(moment(givenDateToCheck, "DD-MM-YYYY").format("YYYY-MM-DD"));
  let b = new Date(moment(dateToCheckWith, "DD-MM-YYYY").format("YYYY-MM-DD"));
  return a < b;
};

export const disableDateHelper = (current, future, before) => {
  return (
    !current ||
    current.isAfter(
      moment(future, GlobalConstants.DD_MM_YYYY).format(
        GlobalConstants.YYYY_MM_DD
      )
    ) ||
    current.isSameOrBefore(
      moment(before, GlobalConstants.DD_MM_YYYY).format(
        GlobalConstants.YYYY_MM_DD
      )
    )
  );
};

export const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsl(" + h + "," + s + "%," + l + "%)";
};
