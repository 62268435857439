import { Errors, Screens } from "constants/AppConstants";

export const handleResponse = (code, page) => {
  let res = Errors.filter((a) => a.status === code);
  return res;
};

export const getResponseCode = (res, page = Screens.DEFAULT) => {
  let statusCode = parseInt(res.toString().match(/-?\d+\.?\d*/));
  return handleResponse(statusCode, page);
};
