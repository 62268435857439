import React, { Fragment, Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  BackTop,
  Tag,
  Icon,
} from "antd";
import { isEmpty } from "lodash";
import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
  LocalStorage,
} from "constants/AppConstants";
import BudjetService from "services/BudjetService";

import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";

import "./UpdateProduction.css";
import * as _ from "lodash";
import {
  GlobalVariables,
  BudjetVariables,
} from "constants/AppConstants/ServicesConstants";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import RecordNotFound from "components/shared/RecordNotFound";
import ThemeColors from "constants/ThemeConstants/variables";
import { handleError } from "utilities/ErrorHandler";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { checkFor } from "utilities/ValidationHelper";
import { findEnumValue } from "utilities/UserHelper";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                // pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class UpdateEquipments extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._budjetService = new BudjetService();

    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      shootDate: null,
      budgetStatusData: [],
      isArtSet: props.isArtSet,
      budgetState: "IN_PROGRESS",
    };
  }

  componentDidMount() {
    this.getTypeDatas();
    this.getBudgetState();
    if (this.props.match.params.shootDate) {
      this.setState(
        {
          shootDate: this.props.match.params.shootDate,
        },
        () => this.getBudjetEstimationData()
      );
    }
  }

  getBudgetState() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.BUDGET_STATE
      )
      .then((res) => {
        this.setState({
          budgetStatusData: res.data,
        });
      });
  }

  getBudjetEstimationData = () => {
    const { isArtSet } = this.state;
    const { page } = this.props;
    this.setState({ count: 0 });
    showActionMessage(GlobalConstants.SHOW, "Loading Details...");
    this._budjetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_BUDJET_DATA,
        this.state.shootDate,
        null,
        page,
        null,
        this.props.isViewOnly
      )
      .then((res) => {
        showActionMessage(GlobalConstants.HIDE);
        let preProductionData = [];
        res.data.postProductionPayments &&
          res.data.postProductionPayments.map((data, i) => {
            preProductionData.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource: preProductionData,
          totalRecords: preProductionData,
          isLoading: true,
          callSheet: res.data.callSheet,
          sceneLocations: res.data.sceneLocations,
          actualLocations: res.data.actualLocations,
          budgetState: res.data.budgetStateEnum || GlobalConstants.IN_PROGRESS,
        });
      })
      .catch((err) => {
        showActionMessage(GlobalConstants.HIDE);
        this.setState({
          isLoading: true,
        });
      });
  };

  getTypeDatas = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          typeDatas: res.data,
        });
      })
      .catch((err) => {});
  };
  handleDelete = (record) => {
    this._budjetService
      .budjetService(
        Services.BudjetVariables.DEL_PRODUCTION_BUDJET_DATA,
        this.props.match.params.shootDate,
        record.id,
        BudjetVariables.EQUIPMENTS,
        record.productionCategory
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.getBudjetEstimationData();
        this.props.refreshChartData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );

        this.props.getPermissions();
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      category: "",
      paymentType: "",
      crewCount: "",
      budget: "",
      //   projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  onChange(value, record) {
    this.handleSave(record, value, "paymentType");

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }
  inputChange(value, record) {
    this.handleSave(record, value, "category");

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => this.handleUpdate(row));
  };
  setActiveRecord = (record) => {
    this.setState(
      {
        activeRecord: record,
      },
      () => console.log("activeRecord", this.state.activeRecord)
    );
  };

  handleSubmit = (record) => {
    const data = {
      budget: record.budget,
      category: record.category,
      crewCount: record.crewCount,
      paymentType: record.paymentType,
      shootDate: this.props.match.params.shootDate,
    };

    this._budjetService
      .budjetService(Services.BudjetVariables.POST_UPDATE_PRODUCTION, data)
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully"
        );
        this.getBudjetEstimationData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message
        );
      });
  };

  handleUpdate = (record) => {
    const { refreshChartData } = this.props;
    const data = {
      budget: record.unitRate,
      budgetDepartment: BudjetVariables.EQUIPMENTS,
      crewCount: record.crewCount,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      shootDate: this.props.match.params.shootDate,
    };

    this._budjetService
      .budjetService(
        Services.BudjetVariables.UPDATE_PRODUCTION_BUDJET_DATA,
        data,
        record.id
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getBudjetEstimationData();
        refreshChartData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );

        this.props.getPermissions();
      });
  };

  handleStatusChange = (value) => {
    this.setState({
      isStatusLoading: true,
    });
    let data = {
      state: value,
      shootDate: this.state.shootDate,
      budgetDepartment: BudjetVariables.EQUIPMENTS,
    };
    this._budjetService
      .budjetService(Services.BudjetVariables.UPDATE_BUDGET_STATE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getBudjetEstimationData();
        this.setState({
          isStatusLoading: false,
        });
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message
        );
        this.setState({
          isStatusLoading: false,
        });
      });
  };

  handleUpdateMemberCount = (value, record) => {
    const { activeValue } = this.state;
    const { refreshChartData } = this.props;
    if (value && value !== activeValue) {
      const { budget, description, id, crewCount, paymentType } = record;
      const data = {
        memberCount: value,
      };
      this._budjetService
        .budjetService(Services.BudjetVariables.UPDATE_EQUIPMENT, data, id)
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.CHANGES_HAVE_BEEN_SAVED
          );
          // this.getBudjetEstimationData();
          refreshChartData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );
        });
    }
  };

  render() {
    const {
      dataSource,
      totalRecords,
      isLoading,
      actualLocations,
      sceneLocations,
      callSheet,
      budgetStatusData,
      budgetState,
    } = this.state;
    const { activePermisision, isViewOnly } = this.props;

    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const column = [
      {
        title: "#",
        align: "center",
        width: "6%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 200,
        className: "category-width",
        // editable: true,
        align: "left",
        render: (text, record, index) => (
          <Input
            defaultValue={text}
            // disabled={
            //   record.isEnable === false || this.state.budgetState === "LOCK"
            // }
            disabled
            onBlur={(e) => this.inputChange(e.target.value, record)}
          />
        ),
      },
      {
        title: "Count",
        dataIndex: "crewCount",
        width: "15%",
        align: "right",
        editable: false,
        //   checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //   !isViewOnly,
        // align: "right",
        // className: "cash"
      },
      {
        title: "Member Count",
        dataIndex: "memberCount",
        width: "10%",
        align: "right",
        render: (text, record) =>
          record.id ? (
            <Input
              type="number"
              className="cash"
              onPressEnter={(e) =>
                this.handleUpdateMemberCount(e.target.value, record)
              }
              onBlur={(e) =>
                this.handleUpdateMemberCount(e.target.value, record)
              }
              defaultValue={text}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
              }
              onFocus={(e) => {
                this.setState({ activeValue: e.target.value });
                e.currentTarget.select();
              }}
            />
          ) : (
            text
          ),

        //   checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //   !isViewOnly,
        // align: "right",
        // className: "cash"
      },
      {
        title: "Unit Rate  (₹)",
        dataIndex: "unitRate",
        width: "15%",
        align: "right",
        editable: false,
        //   checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
        //   isViewOnly,
        // align: "center",
        // className: "cash"
      },
      {
        title: "Total amount (₹)",
        dataIndex: "budget",
        width: "15%",
        // editable: true,
        align: "right",
        render: (text) => <span className="flrt">{text}</span>,
      },
      {
        title: "Payment Type(₹)",
        dataIndex: "paymentType",
        align: "left",
        width: "24%",
        render: (text, record) =>
          //   <Select
          //     showSearch
          //     style={{ width: 140 }}
          //     placeholder="Select type"
          //     // disabled={this.state.budgetState === "LOCK"}
          //     disabled
          //     onChange={value => this.onChange(value, record)}
          //     defaultValue={text ? text : ""}
          //     filterOption={(input, option) =>
          //       option.props.children
          //         .toLowerCase()
          //         .indexOf(input.toLowerCase()) >= 0
          //     }
          //   >
          //     {this.state.typeDatas &&
          //       this.state.typeDatas.map(data => {
          //         return <Option value={data.value}>{data.label}</Option>;
          //       })}
          //   </Select>
          findEnumValue(text),
      },
    ];

    // if (
    //   checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) &&
    //   !isViewOnly
    // ) {
    //   column.push({
    //     title: "Operation",
    //     dataIndex: "operation",
    //     width: "8%",
    //     render: (text, record) => (
    //       <Row type="flex" justify="center">
    //         <React.Fragment>
    //           <Popconfirm
    //             disabled={record.isEnable === false}
    //             title="Sure to delete?"
    //             onConfirm={() => this.handleDelete(record)}
    //           >
    //             <Tooltip
    //               title={GlobalConstants.DEL}
    //               placement={GlobalConstants.RIGHT}
    //             >
    //               <Button
    //                 icon="delete"
    //                 type="link"
    //                 disabled={record.isEnable === false}
    //               />
    //             </Tooltip>
    //           </Popconfirm>
    //         </React.Fragment>
    //       </Row>
    //     )
    //   });
    // }

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "category" ? "text" : "number",
          editable: this.state.budgetState !== "LOCK" ? col.editable : "",
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });

    return (
      <Row>
        <Col xs={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={24}>
              <Row type="flex" justify="middle" align="middle"></Row>
              <Row>
                <Col xl={24}>
                  <Row className="flex-row">
                    <Col xl={12}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Date:
                      </span>
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                          paddingLeft: 10,
                        }}
                      >
                        {this.props.match.params.shootDate}
                      </span>
                    </Col>
                    <Col xl={12}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Call Sheet:
                      </span>
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                          paddingLeft: 10,
                        }}
                      >
                        {callSheet}
                      </span>
                    </Col>

                    {/* <Col xl={8} style={{ paddingBottom: 10 }}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block"
                        }}
                      >
                        State:
                      </span>
                      {budgetState !== "LOCK" ? (
                        budgetState && (
                          <Select
                            style={{ marginLeft: 10, width: 200 }}
                            loading={this.state.isStatusLoading}
                            value={budgetState}
                            onChange={value => this.handleStatusChange(value)}
                            disabled={isEmpty(dataSource)}
                          >
                            {budgetStatusData &&
                              budgetStatusData.map(list => (
                                <option value={list.value}>{list.label}</option>
                              ))}
                          </Select>
                        )
                      ) : (
                        <span style={{ marginLeft: 10 }}>{budgetState}</span>
                      )}
                    </Col> */}
                  </Row>
                  <Row className="flex-row" style={{ padding: "10px 0" }}>
                    <Col xl={12}>
                      <span
                        // // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Scene Locations:
                      </span>
                      {sceneLocations &&
                        sceneLocations.map((sceneLocation, i) => (
                          <Tag
                            style={{
                              marginLeft: 0,
                              borderRadius: 12,
                              marginBottom: 3,
                            }}
                            color={ThemeColors.primaryColor}
                          >
                            {sceneLocation.locationName}
                          </Tag>
                        ))}
                    </Col>
                    <Col xl={12} style={{ paddingBottom: 10 }}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block",
                        }}
                      >
                        Actual Locations:
                      </span>
                      {actualLocations &&
                        actualLocations.map((actualLocation, i) => (
                          <Tag
                            style={{ marginLeft: 10, borderRadius: 12 }}
                            color={"#ee556a"}
                          >
                            {actualLocation.locationName}
                          </Tag>
                        ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xl={24}>
              {/* {page === GlobalConstants.PRODUCTION_CAPS && (
                <Button
                  onClick={this.handleAdd}
                  type="primary"
                  style={{ float: "right", marginBottom: 10 }}
                  disabled={hasNewRow}
                >
                  <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                </Button>
              )} */}
            </Col>
            <Col xl={24}>
              <Table
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={dataSource}
                columns={columns}
                // pagination={{ pageSize: 1000 }}
                // scroll={{ y: "50vh" }}
                pagination={false}
                loading={!isLoading}
                locale={{
                  emptyText: isLoading && <RecordNotFound />,
                }}
              />
            </Col>
          </Row>
          <BackTop visibilityHeight="50" />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.CREW, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEquipments);
