import { LocalStorage, Services } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class ArtSetTabService {
  service(type, data, artSetDetailId, sceneId = null) {
    let finalSceneId = "";
    if (sceneId) {
      finalSceneId = sceneId;
    } else {
      finalSceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    }
    // let sceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    switch (type) {
      case Services.ArtSetVariables.ADD_ART_SET:
        return axios.post(`${getScriptAPIURL()}/artsets`, data, config);
      case Services.ArtSetVariables.GET_ART_SET_LIST:
        return axios.get(
          `${getScriptAPIURL()}/scenes/${finalSceneId}/artsets`,
          config
        );
      case Services.ArtSetVariables.GET_ART_SET_BY_NAME:
        return axios.get(
          `${getScriptAPIURL()}/scene/${finalSceneId}/artSet?name=${data}`,
          config
        );
      case Services.ArtSetVariables.GET_ART_SET_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/artset-descritpion?sceneId=${finalSceneId}`,
          config
        );
      case Services.ArtSetVariables.DELETE_ART_SET_LIST:
        return axios.delete(`${getScriptAPIURL()}/artsets/${data}`, config);
      case Services.ArtSetVariables.UPDATE_ART_SET_LIST:
        return axios.put(
          `${getScriptAPIURL()}/artsets/${artSetDetailId}`,
          data,
          config
        );
      case Services.ArtSetVariables.ART_SET_TABLE_UNIQUE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/shoot/date/${sceneId}/artsets`,
          config
        );
      default:
        break;
    }
  }
}
