import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { Table, Button, Row, Col, Icon, Input, Divider } from "antd";

import { amountOnly, PriceFormat, StartCase } from "utilities/ValidationHelper";
import { checkFor } from "utilities/ValidationHelper";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import {
  GlobalConstants,
  LocalStorage,
  MenusRoute,
  Services,
  Notifications,
} from "constants/AppConstants";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { Link } from "react-router-dom";
import DeleteComponent from "components/shared/DeleteComponent";
import TooltipIcon from "components/shared/TooltipIcon";
import Accounting from "services/AccountingServices";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import { handleError } from "utilities/ErrorHandler";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

class AccPreProduction extends Component {
  currentPreProduction;
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: true,
      currentPreProduction: "",
    };
    this.Accounting = new Accounting();
  }

  componentDidMount() {
    // this.handleAdd();
    this.fetchData();
    this.getAccountEstimation();
  }

  getAccountEstimation() {
    this.Accounting.services(
      Services.AccountingVariables.GET_ACCOUNT_ESTIMATION
    ).then((res) => {
      this.setState({
        currentPreProduction: res?.data?.preProductionBudget || "",
      });
      this.currentPreProduction = res.data.preProductionBudget || "";
    });
  }

  fetchData = () => {
    this.setState({
      isLoading: true,
      count: 0,
      dataSource: [],
    });
    this.Accounting.services(
      Services.AccountingVariables.GET_PRE_PRODUCTION,
      AccountingVariables.PRE_PRODUCTION
    )
      .then((res) => {
        res?.data?.productionAccounts?.forEach((data) => {
          data.key = this.state.count;
          this.setState({
            count: this.state.count + 1,
          });
        });
        this.setState({
          dataSource: res?.data?.productionAccounts || [],
          totalRecords: res?.data?.productionAccounts || [],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      count: null,
      amountPaid: null,
      description: null,
    };
    this.setState({
      dataSource: [...dataSource, newData],
    });
  };

  handleDelete = ({ key, productionAccountId }) => {
    if (productionAccountId) {
      this.Accounting.services(
        Services.AccountingVariables.DELETE_PRE_PRODUCTION,
        AccountingVariables.PRE_PRODUCTION,
        productionAccountId
      )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  inputChange(value, record, type) {
    this.onChange(value, record, type);
  }

  setActiveRecord = (record) => {
    this.setState({
      activeRecord: JSON.parse(JSON.stringify(record)),
    });
  };

  onChange(value, record, type) {
    record[type] = value;
    record.count = parseInt(record.count);
    this.handleSave(record, value, type);
  }

  handleSubmit = (row) => {
    const { count, amountPaid, description } = row;
    const data = {
      category: AccountingVariables.PRE_PRODUCTION,
      itemQuantity: count,
      amountPaid,
      description,
    };
    this.Accounting.services(
      Services.AccountingVariables.SAVE_PRE_PRODUCTION,
      data,
      null,
      AccountingVariables.PRE_PRODUCTION
    )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (row) => {
    const { count, amountPaid, description, productionAccountId } = row;
    const data = {
      itemQuantity: count,
      amountPaid,
      description,
      category: "PRE_PRODUCTION",
    };
    this.Accounting.services(
      Services.AccountingVariables.UPDATE_PRE_PRODUCTION,
      data,
      productionAccountId
    )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.fetchData();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    if (row.productionAccountId) {
      if (!isEqual(row, this.state.activeRecord)) {
        if (row.description && row.count) this.handleUpdate(row);
      }
    } else {
      if (row.description && row.count) {
        this.handleSubmit(row);
      }
      // console.log("this.state.dataSource.", row);
      // console.log("this.state.dataSource..", this.state.activeRecord);
      // console.log("this.", isEqual(row, this.state.activeRecord));
      // if (
      //   (row.description && row.sceneLocation && row.crewCount && row.unitRate,
      //   row.paymentType && row.type)
      // ) {
      //   if (parseInt(row.crewCount) > 0) {
      //     // this.handleSubmit(row);
      //   }
      //   // else {
      //   //   showNotifications(
      //   //     Notifications.ERROR,
      //   //     "Failed",
      //   //     "Crew count must be greater than 0"
      //   //   );
      //   // }
      // }
    }
    this.setState({ dataSource: newData });
  };

  render() {
    const { dataSource, isLoading, totalRecords, currentPreProduction } =
      this.state;
    const { activePermisision } = this.props;
    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const columns = [
      {
        title: "#",
        align: "center",
        key: "key",
        width: "10%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "DESCRITPION",
        dataIndex: "description",
        key: "description",
        // align: "center",
        width: "40%",
        render: (text, record) => (
          <Input
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            onBlur={(e) => this.onChange(e.target.value, record, "description")}
            onFocus={() => this.setActiveRecord(record)}
            autoFocus={!record.productionAccountId}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionAccountId
            }
          />
        ),
      },
      {
        title: "Quantity",
        dataIndex: "count",
        key: "count",
        align: "right",
        width: "20%",
        className: "cash",
        render: (text, record) => (
          <Input
            type="number"
            className={`${!text ? "err" : ""}`}
            defaultValue={text}
            onFocus={() => this.setActiveRecord(record)}
            onBlur={(e) => this.onChange(amountOnly(e), record, "count")}
            style={{ width: "100%" }}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionAccountId
            }
          />
        ),
      },
      {
        title: "AMOUNT PAID",
        dataIndex: "amountPaid",
        key: "amountPaid",
        align: "right",
        className: "cash",
        width: "20%",
        render: (text, record) => PriceFormat(text),
      },
    ];

    if (
      checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
      checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE)
    ) {
      columns.push({
        title: "ACTION",
        dataIndex: "address",
        key: "address",
        align: "center",
        width: "10%",
        render: (text, record) => (
          <>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) &&
              record.productionAccountId && (
                <Link
                  to={{
                    pathname: `${MenusRoute.ACCOUNTING}/pre-production/${record.productionAccountId}/edit`,
                    state: {
                      category: GlobalConstants.PRE_PRODUCTION,
                      title: StartCase(record.description),
                      hidePaymentType: true,
                    },
                  }}
                >
                  <TooltipIcon icon="edit" />
                </Link>
              )}
            {checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) && (
              <DeleteComponent
                record={record}
                onClick={(record) => this.handleDelete(record)}
              />
            )}
            {/* <Divider
              type="vertical"
              style={{ marginLeft: 5, marginRight: 5 }}
            /> */}
          </>
        ),
      });
    }

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        <Row span={24}>
          <Title hasBack {...this.props}>
            <TitleText>
              Accounting Pre-Production {currentPreProduction}
            </TitleText>
          </Title>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{
            paddingTop: 0,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Col xl={24} style={{ paddingBottom: 10 }}>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginTop: 16, float: "right" }}
                disabled={hasNewRow}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            )}
          </Col>
          <Col xl={24}>
            <Table
              bordered
              dataSource={dataSource}
              columns={columns}
              loading={isLoading}
              pagination={false}
              locale={{
                emptyText: !isLoading && <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccPreProduction);
