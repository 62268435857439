import React, { Fragment, Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  BackTop,
  Tag,
  Icon,
  Modal,
} from "antd";
import { isEmpty } from "lodash";
import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
  LocalStorage,
} from "constants/AppConstants";
import BudjetService from "services/BudjetService";

import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";

import "./UpdateProduction.css";
import * as _ from "lodash";
import {
  GlobalVariables,
  BudjetVariables,
} from "constants/AppConstants/ServicesConstants";
import TitleText from "components/shared/TItleText";
import Title from "components/shared/TitleComponent";
import RecordNotFound from "components/shared/RecordNotFound";
import ThemeColors from "constants/ThemeConstants/variables";
import ArtSetStartEndDataTable from "./ArtSetStartEndDataTable";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import {
  checkFor,
  checkArray,
  getArrayLength,
  checkEqual,
} from "utilities/ValidationHelper";
import { handleError } from "utilities/ErrorHandler";
import LocationServices from "services/LocationServices";
import { findEnumValue, getProjectId } from "utilities/UserHelper";
import moment from "moment";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^([0-9].*)$"),
                // pattern: new RegExp("^[1-9]\d{0,2}(?:\.\d{0,2})?$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
              type="number"
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "text") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class UpdateArtSet extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._budjetService = new BudjetService();
    this._locationService = new LocationServices();

    this.onChange = this.onChange.bind(this);

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      typeDatas: null,
      isLoading: false,
      shootDate: null,
      budgetStatusData: [],
      isArtSet: props.isArtSet,
      budgetState: "IN_PROGRESS",
      isArtSetStartEndDataTableVisible: false,
      initialLoad: true,
    };
  }

  componentDidMount() {
    this.getTypeDatas();
    this.getBudgetState();
    if (this.props.match.params.shootDate) {
      this.setState(
        {
          shootDate: this.props.match.params.shootDate,
        },
        () => {
          this.getBudjetEstimationData();
          this.getSceneLocationList(this.state.shootDate);
        }
      );
    }
  }

  getSceneLocationList = (shootDate) => {
    const date = moment(shootDate, "YYYY-MM-DD").format("DD-MM-YYYY");
    this._locationService
      .service(Services.LocationVariables.GET_LOCATION_BY_SHOOT_DATE, date)
      .then((res) => {
        this.setState({
          sceneLocationList: checkArray(res.data),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getBudgetState() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.BUDGET_STATE
      )
      .then((res) => {
        this.setState({
          budgetStatusData: res.data,
        });
      });
  }

  getBudjetEstimationData = () => {
    const { isArtSet } = this.state;
    const { page } = this.props;
    this.setState({
      count: 0,
      isLoading: this.state.initialLoad ? false : true,
      dataSource: [],
    });
    this.state.initialLoad &&
      showActionMessage(GlobalConstants.SHOW, "Loading Details...");
    this._budjetService
      .budjetService(
        Services.BudjetVariables.GET_PRODUCTION_BUDJET_DATA,
        this.state.shootDate,
        null,
        page,
        null,
        this.props.isViewOnly
      )
      .then((res) => {
        showActionMessage(GlobalConstants.HIDE);
        let preProductionData = [];
        res.data.postProductionPayments &&
          res.data.postProductionPayments.map((data, i) => {
            preProductionData.push({ ...data, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });
        this.setState({
          dataSource: preProductionData,
          totalRecords: preProductionData,
          isLoading: true,
          callSheet: res.data.callSheet,
          sceneLocations: res.data.sceneLocations,
          actualLocations: res.data.actualLocations,
          budgetState: res.data.budgetStateEnum || GlobalConstants.IN_PROGRESS,
          initialLoad: false,
        });
      })
      .catch((err) => {
        showActionMessage(GlobalConstants.HIDE);
        this.setState({
          isLoading: true,
        });
      });
  };

  getTypeDatas = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_DETAIL_TYPE
      )
      .then((res) => {
        this.setState({
          artSetType: res.data,
        });
      })
      .catch((err) => {});
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ART_SET_PAYMENT
      )
      .then((res) => {
        this.setState({
          paymentTypeList: res.data,
        });
      })
      .catch((err) => {});
  };
  handleDelete = (key, record) => {
    if (record.id) {
      this._budjetService
        .budjetService(
          Services.BudjetVariables.DEL_PRODUCTION_BUDJET_DATA,
          this.props.match.params.shootDate,
          record.id,
          BudjetVariables.ART_SET,
          record.productionCategoryDepartment
        )
        .then((res) => {
          showActionMessage(
            GlobalConstants.SUCCESS,
            GlobalConstants.SUCCESSFULLY_REMOVED
          );
          this.getBudjetEstimationData();
          this.props.refreshChartData();
        })
        .catch((err) => {
          showNotifications(
            Notifications.ERROR,
            // "Failed",
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message,
            handleError(
              // err.response && err.response.data && err.response.data.message
              err.response &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].message
            )
          );

          this.props.getPermissions();
        });
    } else {
      const dataSource = [...this.state.dataSource];
      this.setState({
        dataSource: dataSource.filter((item) => item.key !== key),
      });
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      description: null,
      paymentType: null,
      crewCount: "0",
      budget: null,
      type: null,
      sceneLocation: null,
      sceneLocationId: null,
      productionCategoryDepartment: "ART_SET",
      productionCategoryDepartmentValue: "Art Set",
      //   projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    };
    this.setState(
      {
        dataSource: [...dataSource, newData],
      }
      // () =>
      //   this.refs.endArtSet.scrollIntoView({ block: "end", behavior: "smooth" })
    );
  };

  handleDropdown = (value, record, type) => {
    const { sceneLocationList } = this.state;
    let sceneLocation = null;
    let id = null;
    if (type === "sceneLocationId") {
      record.sceneLocation = value;
      sceneLocation = sceneLocationList.filter(
        (sceneLocation) => sceneLocation.label === value && sceneLocation.value
      );
      sceneLocation = Object.assign({}, ...sceneLocation);
    }

    id = (sceneLocation && sceneLocation.value) || value;
    this.onChange(id, record, type);
  };

  setActiveRecord = (record) => {
    console.clear();

    this.setState({
      activeRecord: JSON.parse(JSON.stringify(record)),
    });
  };

  onChange(value, record, type) {
    record[type] = value;
    record.crewCount = parseInt(record.crewCount);
    this.handleSave(record, value, type);

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }
  inputChange(value, record, type) {
    this.onChange(value, record, type);

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.id) {
      let equal = checkEqual(row, this.state.activeRecord);
      // console.log(
      //   "row",
      //   row,
      //   this.state.activeRecord,
      //   checkEqual(row, this.state.activeRecord)
      // );
      if (!checkEqual(row, this.state.activeRecord)) {
        this.handleUpdate(row);
      }
    } else {
      if (
        (row.description && row.sceneLocation && row.crewCount && row.unitRate,
        row.paymentType && row.type)
      ) {
        if (parseInt(row.crewCount) > 0) {
          this.handleSubmit(row);
        } else {
          showNotifications(
            Notifications.ERROR,
            "Failed",
            "Crew count must be greater than 0"
          );
        }
      }
    }
    this.setState({ dataSource: newData });
  };

  handleSubmit = (record) => {
    const {
      crewCount,
      description,
      paymentType,
      sceneLocationId,
      type,
      unitRate,
    } = record;
    const data = {
      description,
      count: crewCount,
      paymentType,
      shootDate: this.props.match.params.shootDate,
      sceneLocationId,
      type,
      unitPrice: unitRate,
      projectId: getProjectId(),
    };

    this._budjetService
      .budjetService(Services.BudjetVariables.POST_UPDATE_ARTSET, data)
      .then((res) => {
        this.props.refreshChartData();
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Created Successfully"
        );
        this.getBudjetEstimationData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message
          // "Something Went Wrong"
        );
      });
  };

  handleUpdate = (record) => {
    const { refreshChartData } = this.props;
    const data = {
      budget: record.unitRate,
      budgetDepartment: BudjetVariables.ART_SET,
      productionCategory: record.productionCategoryDepartment,
      crewCount: parseInt(record.crewCount),
      projectId: parseInt(localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)),
      shootDate: this.props.match.params.shootDate,
    };

    this._budjetService
      .budjetService(
        Services.BudjetVariables.UPDATE_PRODUCTION_BUDJET_DATA,
        data,
        record.id
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getBudjetEstimationData();
        refreshChartData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          )
        );

        this.props.getPermissions();
      });
  };

  handleStatusChange = (value) => {
    this.setState({
      isStatusLoading: true,
    });
    let data = {
      state: value,
      shootDate: this.state.shootDate,
      budgetDepartment: BudjetVariables.ART_SET,
    };
    this._budjetService
      .budjetService(Services.BudjetVariables.UPDATE_BUDGET_STATE, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        this.getBudjetEstimationData();
        this.setState({
          isStatusLoading: false,
        });
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
        );
        this.setState({
          isStatusLoading: false,
        });
      });
  };

  handleArtSetStartEndData = (isArtSetStartEndDataTableVisible) => {
    this.setState({
      isArtSetStartEndDataTableVisible,
    });
  };

  render() {
    const {
      dataSource,
      totalRecords,
      isLoading,
      actualLocations,
      sceneLocations,
      callSheet,
      budgetStatusData,
      budgetState,
      artSetType,
      paymentTypeList,
      isArtSetStartEndDataTableVisible,
    } = this.state;

    const { activePermisision, getPermissions, isViewOnly } = this.props;

    const hasNewRow = dataSource.length === totalRecords.length + 1;

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const typeToLabel = (type) => {
      return (
        this.state.artSetType.filter((data) => data.value === type)[0]?.label ||
        ""
      );
    };

    const column = [
      {
        title: "#",
        align: "center",
        width: "6%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 200,
        className: "category-width caps",
        align: "left",
        render: (text, record, index) => (
          <Input
            autoFocus
            defaultValue={text}
            className={`${!text ? "err" : ""}`}
            disabled={record.id}
            onBlur={(e) =>
              this.inputChange(e.target.value, record, "description")
            }
          />
        ),
      },
      {
        title: "Department",
        dataIndex: "productionCategoryDepartment",
        width: "10%",
        align: "center",
        render: (text, record) => (
          <Tag className={`${record.productionCategoryDepartment} dateTag`}>
            {findEnumValue(text)}
          </Tag>
        ),
      },
      {
        title: "Scene Location",
        align: "center",
        dataIndex: "sceneLocation",
        // width: 100,
        render: (text, record) => {
          if (record.id) {
            if (record.productionCategoryDepartment === "LOCATION")
              return record.sceneLocationName && record.sceneLocationName;
            return text && text.locationName && text.locationName;
          }

          return (
            <Select
              showSearch
              style={{ width: 150 }}
              placeholder="Select location"
              className={`${!text ? "err" : ""}`}
              onChange={(value) =>
                this.handleDropdown(value, record, "sceneLocationId")
              }
              // defaultValue={text ? text : ""}
              // filterOption={(input, option) =>
              //   option.props.children
              //     .toLowerCase()
              //     .indexOf(input.toLowerCase()) >= 0
              // }
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
                isViewOnly
              }
            >
              {this.state.sceneLocationList &&
                this.state.sceneLocationList.map((data) => {
                  return <Option value={data.label}>{data.label}</Option>;
                })}
            </Select>
          );
        },
      },
      {
        title: "Count",
        dataIndex: "crewCount",
        width: "9%",
        // editable: true,
        align: "right",
        // className: "cash",
        render: (text, record) => (
          <>
            {console.log(record)}
            <Input
              defaultValue={text}
              disabled={
                this.state.budgetState === "LOCK" ||
                record.productionCategoryDepartment ===
                  GlobalConstants.PRODUCTION_CAPS ||
                record.productionCategoryDepartment ===
                  GlobalConstants.JUNIOR_CAPS ||
                record.productionCategoryDepartment ===
                  GlobalConstants.ACTUAL_LOCATION_CAPS ||
                record.productionCategoryDepartment ===
                  GlobalConstants.LOCATION_CAPS ||
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
                isViewOnly ||
                record.productionCategoryDepartment == "LOCATION_WORK_RENT"
              }
              onFocus={() => this.setActiveRecord(record)}
              className={`${!text ? "err" : ""} cash`}
              style={{ textAlign: "right" }}
              type="number"
              // onChange={e => this.onChange(e.target.value, record, "crewCount")}
              onPressEnter={(e) =>
                this.onChange(e.target.value, record, "crewCount")
              }
              onBlur={(e) =>
                this.onChange(e.target.value.toString(), record, "crewCount")
              }
            />
          </>
        ),
      },
      {
        title: "Unit Rate(₹)",
        dataIndex: "unitRate",
        width: "9%",
        // editable: checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT),
        align: "right",
        // className: "cash",
        // render: (text, record) => {
        //   return {
        //     props: {
        //       className: `${!text ? "empty-active" : ""} cash`
        //     },
        //     children: text
        //   };
        // }
        render: (text, record, index) => (
          <Input
            defaultValue={text}
            disabled={
              !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
              isViewOnly ||
              record.productionCategoryDepartment == "LOCATION_WORK_RENT"
            }
            type="number"
            onFocus={() => this.setActiveRecord(record)}
            className={`${!text ? "err" : ""} cash`}
            onBlur={(e) => this.inputChange(e.target.value, record, "unitRate")}
            onPressEnter={(e) =>
              this.inputChange(e.target.value, record, "unitRate")
            }
          />
        ),
      },
      {
        title: "Total Amount(₹)",
        dataIndex: "budget",
        width: "10%",
        editable: false,
        align: "right",
        render: (text) => <span className="flrt">{text}</span>,
      },
      {
        title: "Art Set Type",
        dataIndex: "type",
        align: "left",
        width: "10%",
        render: (text, record) => {
          if (record.id) {
            return typeToLabel(record.type);
          }
          return (
            <Select
              style={{ width: 140 }}
              placeholder="Select type"
              className={`${!text ? "err" : ""}`}
              // onChange={value => this.onChange(value, record)}
              // defaultValue={text}
              onChange={(value) => this.handleDropdown(value, record, "type")}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
                isViewOnly
              }
            >
              {this.state.artSetType &&
                this.state.artSetType.map((data) => {
                  return <Option value={data.value}>{data.label}</Option>;
                })}
            </Select>
          );
        },
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        align: "left",
        width: "10%",
        render: (text, record) => {
          if (record.id) {
            return findEnumValue(text);
          }
          return (
            <Select
              style={{ width: 140 }}
              placeholder="Select type"
              className={`${!text ? "err" : ""}`}
              disabled={
                !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)
              }
              onChange={(value) =>
                this.handleDropdown(value, record, "paymentType")
              }
            >
              {this.state.paymentTypeList &&
                this.state.paymentTypeList.map((data) => {
                  return <Option value={data.value}>{data.label}</Option>;
                })}
            </Select>
          );
        },
      },

      // {
      //   title: "Art set dates",
      //   align: "center",
      //   width: "10%",
      //   render: (text, record) =>
      //     record.setWorkStartDate && (
      //       <Row>
      //         <Col xl={24}>
      //           <Tag className={`DATE dateTag`}>
      //             {`${record.setWorkStartDate} to ${record.setWorkStartDate}`}
      //           </Tag>
      //         </Col>
      //         {/* <Col xl={24}>
      //             <span style={{ paddingRight: 5, paddingLeft: 5 }}>to</span>
      //           </Col>
      //           <Col xl={24}>
      //             <Tag className={`DATE dateTag`}>{record.setWorkEndDate}</Tag>
      //           </Col> */}
      //       </Row>
      //     )
      // },
    ];

    if (
      checkFor(activePermisision, GlobalConstants.PERMISSION_DELETE) &&
      !isViewOnly
    ) {
      column.push({
        title: "",
        dataIndex: "operation",
        width: "5%",
        render: (text, record) => (
          <Row type="flex" justify="center">
            <React.Fragment>
              <Popconfirm
                disabled={record.isEnable === false}
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record.key, record)}
              >
                <Tooltip
                  title={GlobalConstants.DEL}
                  placement={GlobalConstants.RIGHT}
                >
                  <Button
                    icon="delete"
                    type="link"
                    disabled={record.isEnable === false}
                  />
                </Tooltip>
              </Popconfirm>
            </React.Fragment>
          </Row>
        ),
      });
    }

    const columns = column.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "category" ? "text" : "number",
          editable: this.state.budgetState !== "LOCK" ? col.editable : "",
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          setActiveRecord: this.setActiveRecord,
        }),
      };
    });

    return (
      <Fragment>
        <Row>
          <Col xs={24}>
            <Row type="flex" justify="center" align="middle">
              <Col xl={24}>
                <Row type="flex" justify="middle" align="middle"></Row>
                <Row>
                  <Col xl={24}>
                    <Row className="flex-row">
                      <Col xl={12}>
                        <span
                          // className="primary-font-color"
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            display: "inline-block",
                          }}
                        >
                          Date:
                        </span>
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            display: "inline-block",
                            paddingLeft: 10,
                          }}
                        >
                          {this.props.match.params.shootDate}
                        </span>
                      </Col>
                      <Col xl={12}>
                        <span
                          // className="primary-font-color"
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            display: "inline-block",
                          }}
                        >
                          Call Sheet:
                        </span>
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            display: "inline-block",
                            paddingLeft: 10,
                          }}
                        >
                          {callSheet}
                        </span>
                      </Col>

                      {/* <Col xl={8} style={{ paddingBottom: 10 }}>
                      <span
                        // className="primary-font-color"
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          display: "inline-block"
                        }}
                      >
                        State:
                      </span>
                      {budgetState !== "LOCK" ? (
                        budgetState && (
                          <Select
                            style={{ marginLeft: 10, width: 200 }}
                            loading={this.state.isStatusLoading}
                            value={budgetState}
                            onChange={value => this.handleStatusChange(value)}
                            disabled={isEmpty(dataSource)}
                          >
                            {budgetStatusData &&
                              budgetStatusData.map(list => (
                                <option value={list.value}>{list.label}</option>
                              ))}
                          </Select>
                        )
                      ) : (
                        <span style={{ marginLeft: 10 }}>{budgetState}</span>
                      )}
                    </Col> */}
                    </Row>
                    <Row style={{ padding: "10px 0" }}>
                      <Col xl={24}>
                        <span
                          // // className="primary-font-color"
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            display: "inline-block",
                          }}
                        >
                          Scene Locations:
                        </span>
                        {sceneLocations &&
                          sceneLocations.map((sceneLocation, i) => (
                            <Tag
                              style={{
                                marginLeft: 0,
                                borderRadius: 12,
                                marginBottom: 3,
                              }}
                              color={ThemeColors.primaryColor}
                            >
                              {`${sceneLocation.locationName}
                            ${
                              sceneLocation.setWorkStartDate
                                ? sceneLocation.setWorkStartDate
                                : ""
                            } 
                            ${
                              sceneLocation.setWorkEndDate
                                ? "to " + sceneLocation.setWorkEndDate
                                : ""
                            }`}
                            </Tag>
                          ))}
                      </Col>
                      <Col xl={24} style={{ paddingTop: 10 }}>
                        <span
                          // className="primary-font-color"
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            display: "inline-block",
                          }}
                        >
                          Actual Locations:{" "}
                          {!isViewOnly && (
                            <Icon
                              type="edit"
                              theme="twoTone"
                              twoToneColor={ThemeColors.primaryColor}
                              onClick={() =>
                                this.handleArtSetStartEndData(true)
                              }
                            />
                          )}
                        </span>
                        {actualLocations &&
                          actualLocations.map((actualLocation, i) => (
                            <Tag
                              style={{
                                marginLeft: 10,
                                borderRadius: 20,
                                paddingTop: 5,
                                paddingBottom: 5,
                              }}
                              color={"#ee556a"}
                            >
                              {actualLocation.locationName &&
                                actualLocation.locationName}{" "}
                              {actualLocation.artSetDates &&
                                actualLocation.artSetDates.length !== 0 &&
                                actualLocation.artSetDates.map((artSet) => (
                                  <Tag
                                    style={{ borderRadius: 12 }}
                                    color={"#67c3ef"}
                                  >
                                    {`${artSet.setWorkStartDate} ${
                                      artSet.setWorkEndDate
                                        ? " to " + artSet.setWorkEndDate
                                        : ""
                                    } `}
                                  </Tag>
                                ))}
                            </Tag>
                          ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) &&
                !isViewOnly && (
                  <Col xl={24}>
                    <Button
                      onClick={this.handleAdd}
                      type="primary"
                      style={{ float: "right", marginBottom: 10 }}
                      disabled={hasNewRow}
                    >
                      <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
                    </Button>
                  </Col>
                )}
              <Col xl={24}>
                <Table
                  components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  // pagination={{ pageSize: 1000 }}
                  // scroll={{ y: "50vh" }}
                  pagination={false}
                  loading={!isLoading}
                  locale={{
                    emptyText: isLoading && <RecordNotFound />,
                  }}
                />
              </Col>
              <div ref="endArtSet"></div>
            </Row>
            <BackTop visibilityHeight="50" />
          </Col>
        </Row>
        <Modal
          visible={isArtSetStartEndDataTableVisible}
          onCancel={() => this.handleArtSetStartEndData(false)}
          title="Art/Set Work Dates"
          className="createModal"
          width="60%"
          footer={null}
          destroyOnClose
        >
          <Row>
            <Col style={{ padding: 10 }}>
              <Icon type="question-circle" /> Pick your dates, your changes will
              be saved automatically.
            </Col>
            <ArtSetStartEndDataTable
              shootDate={this.props.match.params.shootDate}
              refreshData={this.getBudjetEstimationData}
            />
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.CREW, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateArtSet);
