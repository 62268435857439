import {
  Services,
  LocalStorage,
  GlobalConstants,
} from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class SchedularService {
  services(
    type,
    projectId,
    sceneId,
    data = null,
    characterId = null,
    sceneLocationId
  ) {
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.SchedularVariables.GET_LIST:
        let actorSelected = data === GlobalConstants.ACTORS;
        let appendWithUrl = `characterId=${characterId}&`;
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/schedulers?${
            actorSelected ? appendWithUrl : ""
          }type=${data}`,
          config,
          data
        );
      case Services.SchedularVariables.GET_MAIN_ACTORS:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/scenes/characters/scheduler`,
          config
        );
      case Services.SchedularVariables.UPDATE_ACTOR_CALL_TIME:
        return axios.patch(
          `${getScriptAPIURL()}/scenes/character/${characterId}`,
          data,
          config
        );
      case Services.SchedularVariables.UPDATE_DATE_CALL_SHEET:
        return axios.post(
          `${getScriptAPIURL()}/scenes/schedulers`,
          data,
          config
        );
      case Services.SchedularVariables.DELETE_SCHEDULE:
        return axios.delete(
          `${getScriptAPIURL()}/scenes/schedulers/${projectId}?isShootDate=${data}`,
          config
        );
      case Services.SchedularVariables.UPDATE_CALL_TIME_BREAK:
        return axios.put(`${getScriptAPIURL()}/schedulers`, data, config);
      case Services.SchedularVariables.UPDATE_SCHEDULAR_SCENE_TABLE:
        return axios.patch(
          `${getScriptAPIURL()}/scenes/schedulers/${sceneId}?scheduleState=${data}`,
          (data = {}),
          config
        );
      case Services.SchedularVariables.GET_SCHEDULER_MORE_SCREEN_DETAILS:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/scenes/scheduler?date=${data}`,
          config
        );
      case Services.SchedularVariables.GET_PDF_URL:
        return axios.get(
          `${getScriptAPIURL()}/scenes/scheduler/report/download?contentDisposition=INLINE&date=${data}&projectId=${projectId}`,
          config
        );
      case Services.SchedularVariables.SEND_MAIL:
        return axios.put(
          `${getScriptAPIURL()}/scenes/scheduler/report`,
          data,
          config
        );
      case Services.SchedularVariables.SWAP_SHOOT_DATE:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/scene/scheduler/info`,
          data,
          config
        );
      case Services.SchedularVariables.SHOOT_ACTOR_AVAILABILITY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/schedulers/check/actor/availability?sceneId=${sceneId}&sceneLocationId=${sceneLocationId}&shootStartDate=${data}${
            characterId ? `&shootEndDate=${characterId}` : ""
          }`,
          config
        );
      case Services.SchedularVariables.GET_ATTENDANCE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/attendances?shootDate=${data}`,
          config
        );
      case Services.SchedularVariables.UPDATE_ATTENDANCE:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/attendances`,
          data,
          config
        );
      default:
        break;
    }
  }
}
