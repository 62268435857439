import React from "react";
import { Bar } from "react-chartjs-2";

// const data = {
//   labels: [
//     "Production",
//     "Art Set",
//     "Equipment",
//     "Costumes & Makeup",
//     "Songs & Stunts"
//   ],
//   datasets: [
//     {
//       label: "Estimation Cost",
//       //   backgroundColor: "rgba(255,99,132,0.2)",
//       backgroundColor: [
//         // for custom bar color
//         "#ffed82",
//         "#f0cfcf",
//         "#ec5a5a",
//         "#93d4a5",
//         "#86ddcd"
//       ],
//       //   strokeColor: [
//       //     // for custom bar border color
//       //     "#ffed82",
//       //     "#f0cfcf",
//       //     "#ec5a5a",
//       //     "#93d4a5",
//       //     "#86ddcd"
//       //   ],
//       //   borderColor: "rgba(255,99,132,1)",
//       borderWidth: 0,
//       //   hoverBackgroundColor: "rgba(255,99,132,0.4)",
//       //   hoverBorderColor: "rgba(255,99,132,1)",
//       data: [10000, 5900, 80000, 30000, 58000]
//     },
//     {
//       label: "Expenditure Cost",
//       backgroundColor: [
//         // for custom bar color
//         "#ffffb3",
//         "#ffe6e6",
//         "#ff8c87",
//         "#c5ffd6",
//         "#b9ffff"
//       ],
//       borderWidth: 0,
//       //   hoverBackgroundColor: "rgba(255,99,132,0.4)",
//       //   hoverBorderColor: "rgba(255,99,132,1)",
//       data: [5000, 6500, 73000, 23000, 59000]
//     }
//   ]
// };

const BarChart = ({ height = 300, data, title = false }) => {
  if (data && data.datasets) {
    data.datasets.map(
      (r) =>
        (r.backgroundColor = [
          "#ffed82",
          "#f0cfcf",
          "#ec5a5a",
          "#93d4a5",
          "#86ddcd",
        ])
    );
  }
  console.log("data", data);
  return (
    <Bar
      data={data}
      height={height}
      options={{
        title: {
          display: title,
          text: title,
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                // display: false,
                color: "#f5f5f5",
              },
            },
          ],
        },
      }}
    />
  );
};

export default BarChart;
