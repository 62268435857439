import { Services, LocalStorage } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class GeneralTabService {
  generalTabService(type, data, sceneId = null) {
    let finalSceneId = "";
    if (sceneId) {
      finalSceneId = sceneId;
    } else {
      finalSceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    }
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    switch (type) {
      case Services.SceneOVerviewVariable.GET_SCENE:
        return axios.get(`${getScriptAPIURL()}/scenes/${finalSceneId}`, config);
      case Services.SceneOVerviewVariable.UPDATE_SCENE:
        return axios.put(
          `${getScriptAPIURL()}/scenes/${finalSceneId}`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.UPDATE_SCENE_STATUS:
        return axios.patch(
          `${getScriptAPIURL()}/scenes/${finalSceneId}?scheduleState=${data}`,
          (data = {}),
          config
        );
      default:
        break;
    }
  }
}
