import React, { Component } from "react";
import { Row, Col, Icon, Popconfirm, Tooltip, Modal } from "antd";
import user from "assets/images/user.png";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import {
  Services,
  Notifications,
  GlobalConstants
} from "constants/AppConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { getScriptAPIURL } from "utilities/APIHelper";
import { showNotifications } from "components/shared/NotificationComponent";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { Capitalize } from "utilities/ValidationHelper";
import { connect } from "react-redux";
import { getNotificationCount } from "store/thunk-actions";

class ActorCardComponent extends Component {
  state = {
    name: null,
    status: null,
    imageId: null,
    characterId: null,
    cardList: [],
    isLoading: false,
    visible: false,
    imageList: []
  };

  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
    this._locationService = new LocationTabService();
  }

  componentDidMount() {
    this.setState({
      imageList: this.props.ImageIdList
    });
  }

  showModal = () => {
    if (this.props.ImageIdList) {
      this.setState({
        visible: true
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };

  confirm = (e) => {
    this.setState({ isLoading: true });
    this._actorService
      .actorTabService(
        Services.SceneOVerviewVariable.DELETE_ACTOR,
        this.props.characterId
      )
      .then((res) => {
        showNotifications(
          Notifications.SUCCESS,
          "Successfully",
          " Character Removed!!"
        );
        this.props.refreshActors();
        this.props.getNotificationCount();
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.props.getPermissions();
      });
  };

  getImages = (imageList) => {
    let images = [];

    // console.log("sss", imageList?.length > 0 && imageList[0]?.attachment?.url);
    imageList?.length > 0 &&
      imageList.map((list) => {
        list?.attachment?.url &&
          images.push({
            // original: `${getScriptAPIURL()}/file/download/${list.fileData}`,
            // thumbnail: `${getScriptAPIURL()}/file/download/${list.fileData}`
            original: list?.attachment?.url,
            thumbnail: list?.attachment?.url
          });
      });

    return images;
  };

  cancel = (e) => {
    // message.error("Click on No");
  };

  render() {
    const {
      characterName,
      actorName,
      type,
      imageId,
      characterId,
      actorId,
      EDIT,
      DELETE
    } = this.props;
    const images = this.getImages(this.state.imageList);

    return (
      <div>
        <Col
          xl={{ span: 6 }}
          lg={{ span: 10 }}
          md={{ span: 16 }}
          xs={{ span: 24 }}
          style={{ padding: "10px" }}
        >
          <div className="card">
            {DELETE ? (
              <div className="deleteCard">
                <Popconfirm
                  title="Are you sure delete this Character?"
                  onConfirm={this.confirm}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                  loading={this.state.isLoading}
                >
                  <a href="#">
                    <Tooltip
                      title={GlobalConstants.DEL}
                      placement={GlobalConstants.RIGHT}
                    >
                      <Icon type="delete" style={{ fontSize: "13px" }} />
                    </Tooltip>
                  </a>
                </Popconfirm>
              </div>
            ) : null}
            <Row>
              <Col span={16} className="vertical-center pl-15">
                <h3>
                  <a className="font-600 text-ellipsis">
                    <Link
                      to={`/project/audition/${type}/${characterId}/${characterName}`}
                    >
                      <h4
                        className="primary-font-color"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {Capitalize(characterName)}
                      </h4>
                    </Link>
                  </a>
                </h3>
                <p>
                  {actorName ? (
                    EDIT ? (
                      <Link
                        to={`/project/actorprofile/${actorId}/${characterName}/${type}/${characterId}`}
                      >
                        {Capitalize(actorName)}
                      </Link>
                    ) : (
                      Capitalize(actorName)
                    )
                  ) : (
                    "To be confirmed"
                  )}
                </p>
              </Col>
              <Col span={8}>
                <img
                  onClick={this.showModal}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = user;
                  }}
                  // src={`${getScriptAPIURL()}/file/download/${
                  //   this.props.imageId
                  //   }`}
                  src={
                    this.props?.ImageIdList?.length > 0 &&
                    this.props?.ImageIdList[0]?.attachment?.url
                      ? this.props?.ImageIdList[0]?.attachment?.url
                      : ""
                  }
                  alt="user"
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Modal
          // title="Basic Modal"
          footer=""
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <ImageGallery items={images} onClick={this.selectImage} />
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationCount: () => dispatch(getNotificationCount())
  };
};

export default connect(null, mapDispatchToProps)(ActorCardComponent);
