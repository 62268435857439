import React, { useEffect, Fragment, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { connect } from "react-redux";

import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import Makeup from "components/Makeup";
import { setMakeupLeftActiveTab } from "store/action";
import { GlobalConstants } from "constants/AppConstants";
import GeneralMakeups from "components/GeneralMakeups";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";

const { TabPane } = Tabs;

const CostumeLeftTabs = (props) => {
  const { setMakeupLeftActiveTab, makeupLeftActiveTab, activePermisision } =
    props;
  const [ActiveTab, setActiveTab] = useState(makeupLeftActiveTab);

  const handleChange = (e) => {
    setMakeupLeftActiveTab(e);
  };

  return (
    <Fragment>
      <Row span={24}>
        <Title>
          <TitleText>Makeups</TitleText>
        </Title>
      </Row>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row style={{ padding: 10 }}>
          <Col>
            <Tabs
              defaultActiveKey={ActiveTab}
              onChange={handleChange}
              destroyInactiveTabPane
            >
              <TabPane tab="General Makeups" key="1">
                <GeneralMakeups />
              </TabPane>
              <TabPane tab="Special Makeups by Purchase" key="2">
                <Makeup {...props} viewBy={GlobalConstants.PURCHASE} />
              </TabPane>
              <TabPane tab="Special Makeups by Hire Per Day" key="3">
                <Makeup {...props} viewBy={GlobalConstants.PER_DAY} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  activeItems: { makeupLeftActiveTab },
  user: { menus },
}) => {
  return {
    makeupLeftActiveTab,
    activePermisision: getSPermissionFromMenus(PermissionPage.MAKEUP, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMakeupLeftActiveTab: (e) => dispatch(setMakeupLeftActiveTab(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostumeLeftTabs);
