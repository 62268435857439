import React, { Component, Fragment } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
  Icon,
  AutoComplete,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
// import CreatableSelect from "react-select/creatable";
import debounce from "lodash/debounce";

import GlobalService from "services/GlobalService";
import {
  Services,
  LocalStorage,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { OnboardServices } from "services";
import UploadImage from "components/shared/UploadImage/UploadImage";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import FileCard from "components/shared/FileCardComponent";
import {
  numberOnly,
  amountOnly,
  checkFor,
  handleGoogleResponse,
} from "utilities/ValidationHelper";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import GeoCode from "components/shared/GeoCode";
import LocationConfirmModel from "components/LocationConfirmModel";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import { GeoServices } from "components/shared/GeoCode/geoCall";
import LocationAvailabilityTable from "../LocationAvailabilityTable";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const { TextArea } = Input;

class AddLocationModal extends Component {
  state = {
    visible: false,
    sceneLocation: null,
    actualLocation: null,
    locationType: null,
    contactPerson: null,
    contactNumber: null,
    shootAmount: null,
    shootAmountType: null,
    workAmount: null,
    workAmountType: null,
    addressLine: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
    status: null,
    imageId: null,
    isLoading: false,
    locationId: null,
    pan: null,
    gst: null,
    locationListDatas: [],
    statusListDatas: [],
    countryListDatas: [],
    paymentListDatasShoot: [],
    paymentListDatasSetWork: [],
    actualLocationList: [],
    fileList: null,
    docFiles: null,
    imageFiles: null,
    sceneLocationId: null,
    isModalVisible: false,
    confirmedLocationName: null,
    isNeedGovernmentApproval: false,
    governmentApprovalStatusDatas: [],
    phoneCodeList: [
      { name: "Andorra", code: "AD", iso: "AND", phoneCode: "+376" },
      {
        name: "United Arab Emirates",
        code: "AE",
        iso: "ARE",
        phoneCode: "+971",
      },
      { name: "Afghanistan", code: "AF", iso: "AFG", phoneCode: "+93" },
      {
        name: "Antigua & Barbuda",
        code: "AG",
        iso: "ATG",
        phoneCode: "+1-268",
      },
      { name: "Anguilla", code: "AI", iso: "AIA", phoneCode: "+1-264" },
      { name: "Albania", code: "AL", iso: "ALB", phoneCode: "+355" },
      { name: "Armenia", code: "AM", iso: "ARM", phoneCode: "+374" },
      { name: "Angola", code: "AO", iso: "AGO", phoneCode: "+244" },
      { name: "Antarctica", code: "AQ", iso: "ATA", phoneCode: "0" },
      { name: "Argentina", code: "AR", iso: "ARG", phoneCode: "+54" },
      { name: "American Samoa", code: "AS", iso: "ASM", phoneCode: "+1-684" },
      { name: "Austria", code: "AT", iso: "AUT", phoneCode: "+43" },
      { name: "Australia", code: "AU", iso: "AUS", phoneCode: "+61" },
      { name: "Aruba", code: "AW", iso: "ABW", phoneCode: "+297" },
      { name: "Åland Islands", code: "AX", iso: "ALA", phoneCode: "+358-18" },
      { name: "Azerbaijan", code: "AZ", iso: "AZE", phoneCode: "+374-97" },
      {
        name: "Bosnia & Herzegovina",
        code: "BA",
        iso: "BIH",
        phoneCode: "+387",
      },
      { name: "Barbados", code: "BB", iso: "BRB", phoneCode: "+1-246" },
      { name: "Bangladesh", code: "BD", iso: "BGD", phoneCode: "+880" },
      { name: "Belgium", code: "BE", iso: "BEL", phoneCode: "+32" },
      { name: "Burkina Faso", code: "BF", iso: "BFA", phoneCode: "+226" },
      { name: "Bulgaria", code: "BG", iso: "BGR", phoneCode: "+359" },
      { name: "Bahrain", code: "BH", iso: "BHR", phoneCode: "+973" },
      { name: "Burundi", code: "BI", iso: "BDI", phoneCode: "+257" },
      { name: "Benin", code: "BJ", iso: "BEN", phoneCode: "+229" },
      { name: "St. Barthélemy", code: "BL", iso: "BLM", phoneCode: "+590" },
      { name: "Bermuda", code: "BM", iso: "BMU", phoneCode: "+1-441" },
      { name: "Brunei", code: "BN", iso: "BRN", phoneCode: "+673" },
      { name: "Bolivia", code: "BO", iso: "BOL", phoneCode: "+591" },
      {
        name: "Caribbean Netherlands",
        code: "BQ",
        iso: "BES",
        phoneCode: "+599",
      },
      { name: "Brazil", code: "BR", iso: "BRA", phoneCode: "+55" },
      { name: "Bahamas", code: "BS", iso: "BHS", phoneCode: "+1-242" },
      { name: "Bhutan", code: "BT", iso: "BTN", phoneCode: "+975" },
      { name: "Bouvet Island", code: "BV", iso: "BVT", phoneCode: "0" },
      { name: "Botswana", code: "BW", iso: "BWA", phoneCode: "+267" },
      { name: "Belarus", code: "BY", iso: "BLR", phoneCode: "+375" },
      { name: "Belize", code: "BZ", iso: "BLZ", phoneCode: "+501" },
      { name: "Canada", code: "CA", iso: "CAN", phoneCode: "+1" },
      {
        name: "Cocos (Keeling) Islands",
        code: "CC",
        iso: "CCK",
        phoneCode: "+61",
      },
      { name: "Congo - Kinshasa", code: "CD", iso: "COD", phoneCode: "+243" },
      {
        name: "Central African Republic",
        code: "CF",
        iso: "CAF",
        phoneCode: "+236",
      },
      {
        name: "Congo - Brazzaville",
        code: "CG",
        iso: "COG",
        phoneCode: "+242",
      },
      { name: "Switzerland", code: "CH", iso: "CHE", phoneCode: "+41" },
      { name: "Côte d’Ivoire", code: "CI", iso: "CIV", phoneCode: "+225" },
      { name: "Cook Islands", code: "CK", iso: "COK", phoneCode: "+682" },
      { name: "Chile", code: "CL", iso: "CHL", phoneCode: "+56" },
      { name: "Cameroon", code: "CM", iso: "CMR", phoneCode: "+237" },
      { name: "China", code: "CN", iso: "CHN", phoneCode: "+86" },
      { name: "Colombia", code: "CO", iso: "COL", phoneCode: "+57" },
      { name: "Costa Rica", code: "CR", iso: "CRI", phoneCode: "+506" },
      { name: "Cuba", code: "CU", iso: "CUB", phoneCode: "+53" },
      { name: "Cape Verde", code: "CV", iso: "CPV", phoneCode: "+238" },
      { name: "Curaçao", code: "CW", iso: "CUW", phoneCode: "+599" },
      { name: "Christmas Island", code: "CX", iso: "CXR", phoneCode: "+61" },
      { name: "Cyprus", code: "CY", iso: "CYP", phoneCode: "+90-392" },
      { name: "Czechia", code: "CZ", iso: "CZE", phoneCode: "+420" },
      { name: "Germany", code: "DE", iso: "DEU", phoneCode: "+49" },
      { name: "Djibouti", code: "DJ", iso: "DJI", phoneCode: "+253" },
      { name: "Denmark", code: "DK", iso: "DNK", phoneCode: "+45" },
      { name: "Dominica", code: "DM", iso: "DMA", phoneCode: "+1-767" },
      {
        name: "Dominican Republic",
        code: "DO",
        iso: "DOM",
        phoneCode: "+1-809",
      },
      { name: "Algeria", code: "DZ", iso: "DZA", phoneCode: "+213" },
      { name: "Ecuador", code: "EC", iso: "ECU", phoneCode: "+593" },
      { name: "Estonia", code: "EE", iso: "EST", phoneCode: "+372" },
      { name: "Egypt", code: "EG", iso: "EGY", phoneCode: "+20" },
      { name: "Western Sahara", code: "EH", iso: "ESH", phoneCode: "+212" },
      { name: "Eritrea", code: "ER", iso: "ERI", phoneCode: "+291" },
      { name: "Spain", code: "ES", iso: "ESP", phoneCode: "+34" },
      { name: "Ethiopia", code: "ET", iso: "ETH", phoneCode: "+251" },
      { name: "Finland", code: "FI", iso: "FIN", phoneCode: "+358" },
      { name: "Fiji", code: "FJ", iso: "FJI", phoneCode: "+679" },
      { name: "Falkland Islands", code: "FK", iso: "FLK", phoneCode: "+500" },
      { name: "Micronesia", code: "FM", iso: "FSM", phoneCode: "+691" },
      { name: "Faroe Islands", code: "FO", iso: "FRO", phoneCode: "+298" },
      { name: "France", code: "FR", iso: "FRA", phoneCode: "+33" },
      { name: "Gabon", code: "GA", iso: "GAB", phoneCode: "+241" },
      { name: "United Kingdom", code: "GB", iso: "GBR", phoneCode: "+44" },
      { name: "Grenada", code: "GD", iso: "GRD", phoneCode: "+1-473" },
      { name: "Georgia", code: "GE", iso: "GEO", phoneCode: "+995" },
      { name: "French Guiana", code: "GF", iso: "GUF", phoneCode: "+594" },
      { name: "Guernsey", code: "GG", iso: "GGY", phoneCode: "+44" },
      { name: "Ghana", code: "GH", iso: "GHA", phoneCode: "+233" },
      { name: "Gibraltar", code: "GI", iso: "GIB", phoneCode: "+350" },
      { name: "Greenland", code: "GL", iso: "GRL", phoneCode: "+299" },
      { name: "Gambia", code: "GM", iso: "GMB", phoneCode: "+220" },
      { name: "Guinea", code: "GN", iso: "GIN", phoneCode: "+224" },
      { name: "Guadeloupe", code: "GP", iso: "GLP", phoneCode: "+590" },
      { name: "Equatorial Guinea", code: "GQ", iso: "GNQ", phoneCode: "+240" },
      { name: "Greece", code: "GR", iso: "GRC", phoneCode: "+30" },
      {
        name: "South Georgia & South Sandwich Islands",
        code: "GS",
        iso: "SGS",
        phoneCode: "0",
      },
      { name: "Guatemala", code: "GT", iso: "GTM", phoneCode: "+502" },
      { name: "Guam", code: "GU", iso: "GUM", phoneCode: "+1-671" },
      { name: "Guinea-Bissau", code: "GW", iso: "GNB", phoneCode: "+245" },
      { name: "Guyana", code: "GY", iso: "GUY", phoneCode: "+592" },
      {
        name: "Hong Kong SAR China",
        code: "HK",
        iso: "HKG",
        phoneCode: "+852",
      },
      {
        name: "Heard & McDonald Islands",
        code: "HM",
        iso: "HMD",
        phoneCode: "0",
      },
      { name: "Honduras", code: "HN", iso: "HND", phoneCode: "+504" },
      { name: "Croatia", code: "HR", iso: "HRV", phoneCode: "+385" },
      { name: "Haiti", code: "HT", iso: "HTI", phoneCode: "+509" },
      { name: "Hungary", code: "HU", iso: "HUN", phoneCode: "+36" },
      { name: "Indonesia", code: "ID", iso: "IDN", phoneCode: "+62" },
      { name: "Ireland", code: "IE", iso: "IRL", phoneCode: "+353" },
      { name: "Israel", code: "IL", iso: "ISR", phoneCode: "+972" },
      { name: "Isle of Man", code: "IM", iso: "IMN", phoneCode: "+44" },
      { name: "India", code: "IN", iso: "IND", phoneCode: "+91" },
      {
        name: "British Indian Ocean Territory",
        code: "IO",
        iso: "IOT",
        phoneCode: "+246",
      },
      { name: "Iraq", code: "IQ", iso: "IRQ", phoneCode: "+964" },
      { name: "Iran", code: "IR", iso: "IRN", phoneCode: "+98" },
      { name: "Iceland", code: "IS", iso: "ISL", phoneCode: "+354" },
      { name: "Italy", code: "IT", iso: "ITA", phoneCode: "+39" },
      { name: "Jersey", code: "JE", iso: "JEY", phoneCode: "+44" },
      { name: "Jamaica", code: "JM", iso: "JAM", phoneCode: "+1-876" },
      { name: "Jordan", code: "JO", iso: "JOR", phoneCode: "+962" },
      { name: "Japan", code: "JP", iso: "JPN", phoneCode: "+81" },
      { name: "Kenya", code: "KE", iso: "KEN", phoneCode: "+254" },
      { name: "Kyrgyzstan", code: "KG", iso: "KGZ", phoneCode: "+996" },
      { name: "Cambodia", code: "KH", iso: "KHM", phoneCode: "+855" },
      { name: "Kiribati", code: "KI", iso: "KIR", phoneCode: "+686" },
      { name: "Comoros", code: "KM", iso: "COM", phoneCode: "+269" },
      {
        name: "St. Kitts & Nevis",
        code: "KN",
        iso: "KNA",
        phoneCode: "+1-869",
      },
      { name: "North Korea", code: "KP", iso: "PRK", phoneCode: "+850" },
      { name: "South Korea", code: "KR", iso: "KOR", phoneCode: "+82" },
      { name: "Kuwait", code: "KW", iso: "KWT", phoneCode: "+965" },
      { name: "Cayman Islands", code: "KY", iso: "CYM", phoneCode: "+1-345" },
      { name: "Kazakhstan", code: "KZ", iso: "KAZ", phoneCode: "+7" },
      { name: "Laos", code: "LA", iso: "LAO", phoneCode: "+856" },
      { name: "Lebanon", code: "LB", iso: "LBN", phoneCode: "+961" },
      { name: "St. Lucia", code: "LC", iso: "LCA", phoneCode: "+1-758" },
      { name: "Liechtenstein", code: "LI", iso: "LIE", phoneCode: "+423" },
      { name: "Sri Lanka", code: "LK", iso: "LKA", phoneCode: "+94" },
      { name: "Liberia", code: "LR", iso: "LBR", phoneCode: "+231" },
      { name: "Lesotho", code: "LS", iso: "LSO", phoneCode: "+266" },
      { name: "Lithuania", code: "LT", iso: "LTU", phoneCode: "+370" },
      { name: "Luxembourg", code: "LU", iso: "LUX", phoneCode: "+352" },
      { name: "Latvia", code: "LV", iso: "LVA", phoneCode: "+371" },
      { name: "Libya", code: "LY", iso: "LBY", phoneCode: "+218" },
      { name: "Morocco", code: "MA", iso: "MAR", phoneCode: "+212" },
      { name: "Monaco", code: "MC", iso: "MCO", phoneCode: "+377" },
      { name: "Moldova", code: "MD", iso: "MDA", phoneCode: "+373-533" },
      { name: "Montenegro", code: "ME", iso: "MNE", phoneCode: "+382" },
      { name: "St. Martin", code: "MF", iso: "MAF", phoneCode: "+590" },
      { name: "Madagascar", code: "MG", iso: "MDG", phoneCode: "+261" },
      { name: "Marshall Islands", code: "MH", iso: "MHL", phoneCode: "+692" },
      { name: "Macedonia", code: "MK", iso: "MKD", phoneCode: "+389" },
      { name: "Mali", code: "ML", iso: "MLI", phoneCode: "+223" },
      { name: "Myanmar (Burma)", code: "MM", iso: "MMR", phoneCode: "+95" },
      { name: "Mongolia", code: "MN", iso: "MNG", phoneCode: "+976" },
      { name: "Macau SAR China", code: "MO", iso: "MAC", phoneCode: "+853" },
      {
        name: "Northern Mariana Islands",
        code: "MP",
        iso: "MNP",
        phoneCode: "+1-670",
      },
      { name: "Martinique", code: "MQ", iso: "MTQ", phoneCode: "+596" },
      { name: "Mauritania", code: "MR", iso: "MRT", phoneCode: "+222" },
      { name: "Montserrat", code: "MS", iso: "MSR", phoneCode: "+1-664" },
      { name: "Malta", code: "MT", iso: "MLT", phoneCode: "+356" },
      { name: "Mauritius", code: "MU", iso: "MUS", phoneCode: "+230" },
      { name: "Maldives", code: "MV", iso: "MDV", phoneCode: "+960" },
      { name: "Malawi", code: "MW", iso: "MWI", phoneCode: "+265" },
      { name: "Mexico", code: "MX", iso: "MEX", phoneCode: "+52" },
      { name: "Malaysia", code: "MY", iso: "MYS", phoneCode: "+60" },
      { name: "Mozambique", code: "MZ", iso: "MOZ", phoneCode: "+258" },
      { name: "Namibia", code: "NA", iso: "NAM", phoneCode: "+264" },
      { name: "New Caledonia", code: "NC", iso: "NCL", phoneCode: "+687" },
      { name: "Niger", code: "NE", iso: "NER", phoneCode: "+227" },
      { name: "Norfolk Island", code: "NF", iso: "NFK", phoneCode: "+672" },
      { name: "Nigeria", code: "NG", iso: "NGA", phoneCode: "+234" },
      { name: "Nicaragua", code: "NI", iso: "NIC", phoneCode: "+505" },
      { name: "Netherlands", code: "NL", iso: "NLD", phoneCode: "+31" },
      { name: "Norway", code: "NO", iso: "NOR", phoneCode: "+47" },
      { name: "Nepal", code: "NP", iso: "NPL", phoneCode: "+977" },
      { name: "Nauru", code: "NR", iso: "NRU", phoneCode: "+674" },
      { name: "Niue", code: "NU", iso: "NIU", phoneCode: "+683" },
      { name: "New Zealand", code: "NZ", iso: "NZL", phoneCode: "+64" },
      { name: "Oman", code: "OM", iso: "OMN", phoneCode: "+968" },
      { name: "Panama", code: "PA", iso: "PAN", phoneCode: "+507" },
      { name: "Peru", code: "PE", iso: "PER", phoneCode: "+51" },
      { name: "French Polynesia", code: "PF", iso: "PYF", phoneCode: "+689" },
      { name: "Papua New Guinea", code: "PG", iso: "PNG", phoneCode: "+675" },
      { name: "Philippines", code: "PH", iso: "PHL", phoneCode: "+63" },
      { name: "Pakistan", code: "PK", iso: "PAK", phoneCode: "+92" },
      { name: "Poland", code: "PL", iso: "POL", phoneCode: "+48" },
      {
        name: "St. Pierre & Miquelon",
        code: "PM",
        iso: "SPM",
        phoneCode: "+508",
      },
      { name: "Pitcairn Islands", code: "PN", iso: "PCN", phoneCode: "0" },
      { name: "Puerto Rico", code: "PR", iso: "PRI", phoneCode: "+1-787" },
      {
        name: "Palestinian Territories",
        code: "PS",
        iso: "PSE",
        phoneCode: "+970",
      },
      { name: "Portugal", code: "PT", iso: "PRT", phoneCode: "+351" },
      { name: "Palau", code: "PW", iso: "PLW", phoneCode: "+680" },
      { name: "Paraguay", code: "PY", iso: "PRY", phoneCode: "+595" },
      { name: "Qatar", code: "QA", iso: "QAT", phoneCode: "+974" },
      { name: "Réunion", code: "RE", iso: "REU", phoneCode: "+262" },
      { name: "Romania", code: "RO", iso: "ROU", phoneCode: "+40" },
      { name: "Serbia", code: "RS", iso: "SRB", phoneCode: "+381" },
      { name: "Russia", code: "RU", iso: "RUS", phoneCode: "+7" },
      { name: "Rwanda", code: "RW", iso: "RWA", phoneCode: "+250" },
      { name: "Saudi Arabia", code: "SA", iso: "SAU", phoneCode: "+966" },
      { name: "Solomon Islands", code: "SB", iso: "SLB", phoneCode: "+677" },
      { name: "Seychelles", code: "SC", iso: "SYC", phoneCode: "+248" },
      { name: "Sudan", code: "SD", iso: "SDN", phoneCode: "+249" },
      { name: "Sweden", code: "SE", iso: "SWE", phoneCode: "+46" },
      { name: "Singapore", code: "SG", iso: "SGP", phoneCode: "+65" },
      { name: "St. Helena", code: "SH", iso: "SHN", phoneCode: "+290" },
      { name: "Slovenia", code: "SI", iso: "SVN", phoneCode: "+386" },
      {
        name: "Svalbard & Jan Mayen",
        code: "SJ",
        iso: "SJM",
        phoneCode: "+47",
      },
      { name: "Slovakia", code: "SK", iso: "SVK", phoneCode: "+421" },
      { name: "Sierra Leone", code: "SL", iso: "SLE", phoneCode: "+232" },
      { name: "San Marino", code: "SM", iso: "SMR", phoneCode: "+378" },
      { name: "Senegal", code: "SN", iso: "SEN", phoneCode: "+221" },
      { name: "Somalia", code: "SO", iso: "SOM", phoneCode: "+252" },
      { name: "Suriname", code: "SR", iso: "SUR", phoneCode: "+597" },
      { name: "South Sudan", code: "SS", iso: "SSD", phoneCode: "+211" },
      {
        name: "São Tomé & Príncipe",
        code: "ST",
        iso: "STP",
        phoneCode: "+239",
      },
      { name: "El Salvador", code: "SV", iso: "SLV", phoneCode: "+503" },
      { name: "Sint Maarten", code: "SX", iso: "SXM", phoneCode: "+1" },
      { name: "Syria", code: "SY", iso: "SYR", phoneCode: "+963" },
      { name: "Swaziland", code: "SZ", iso: "SWZ", phoneCode: "+268" },
      {
        name: "Turks & Caicos Islands",
        code: "TC",
        iso: "TCA",
        phoneCode: "+1-649",
      },
      { name: "Chad", code: "TD", iso: "TCD", phoneCode: "+235" },
      {
        name: "French Southern Territories",
        code: "TF",
        iso: "ATF",
        phoneCode: "0",
      },
      { name: "Togo", code: "TG", iso: "TGO", phoneCode: "+228" },
      { name: "Thailand", code: "TH", iso: "THA", phoneCode: "+66" },
      { name: "Tajikistan", code: "TJ", iso: "TJK", phoneCode: "+992" },
      { name: "Tokelau", code: "TK", iso: "TKL", phoneCode: "+690" },
      { name: "Timor-Leste", code: "TL", iso: "TLS", phoneCode: "+670" },
      { name: "Turkmenistan", code: "TM", iso: "TKM", phoneCode: "+993" },
      { name: "Tunisia", code: "TN", iso: "TUN", phoneCode: "+216" },
      { name: "Tonga", code: "TO", iso: "TON", phoneCode: "+676" },
      { name: "Turkey", code: "TR", iso: "TUR", phoneCode: "+90" },
      {
        name: "Trinidad & Tobago",
        code: "TT",
        iso: "TTO",
        phoneCode: "+1-868",
      },
      { name: "Tuvalu", code: "TV", iso: "TUV", phoneCode: "+688" },
      { name: "Taiwan", code: "TW", iso: "TWN", phoneCode: "+886" },
      { name: "Tanzania", code: "TZ", iso: "TZA", phoneCode: "+255" },
      { name: "Ukraine", code: "UA", iso: "UKR", phoneCode: "+380" },
      { name: "Uganda", code: "UG", iso: "UGA", phoneCode: "+256" },
      { name: "U.S. Outlying Islands", code: "UM", iso: "UMI", phoneCode: "0" },
      { name: "United States", code: "US", iso: "USA", phoneCode: "+1" },
      { name: "Uruguay", code: "UY", iso: "URY", phoneCode: "+598" },
      { name: "Uzbekistan", code: "UZ", iso: "UZB", phoneCode: "+998" },
      { name: "Vatican City", code: "VA", iso: "VAT", phoneCode: "+379" },
      {
        name: "St. Vincent & Grenadines",
        code: "VC",
        iso: "VCT",
        phoneCode: "+1-784",
      },
      { name: "Venezuela", code: "VE", iso: "VEN", phoneCode: "+58" },
      {
        name: "British Virgin Islands",
        code: "VG",
        iso: "VGB",
        phoneCode: "+1-284",
      },
      {
        name: "U.S. Virgin Islands",
        code: "VI",
        iso: "VIR",
        phoneCode: "+1-340",
      },
      { name: "Vietnam", code: "VN", iso: "VNM", phoneCode: "+84" },
      { name: "Vanuatu", code: "VU", iso: "VUT", phoneCode: "+678" },
      { name: "Wallis & Futuna", code: "WF", iso: "WLF", phoneCode: "+681" },
      { name: "Samoa", code: "WS", iso: "WSM", phoneCode: "+685" },
      { name: "Yemen", code: "YE", iso: "YEM", phoneCode: "+967" },
      { name: "Mayotte", code: "YT", iso: "MYT", phoneCode: "+262" },
      { name: "South Africa", code: "ZA", iso: "ZAF", phoneCode: "+27" },
      { name: "Zambia", code: "ZM", iso: "ZMB", phoneCode: "+260" },
      { name: "Zimbabwe", code: "ZW", iso: "ZWE", phoneCode: "+263" },
    ],
    phoneCode: "+91",
  };

  constructor(props) {
    super(props);
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
    this._globalService = new GlobalService();
    this._onboardservices = new OnboardServices();
    this._locationService = new LocationTabService();
  }

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      docFiles,
      imageFiles,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === "Update") {
      if (nextProps.locationId) {
        if (nextProps.locationId !== this.state.locationId) {
          this.setState(
            {
              locationId: nextProps.locationId,
              actualLocation: nextProps.actualLocationName,
            },
            () => {
              this.getLocationDetails();
            }
          );
        }
      }
    }
  }

  getGovtApprovalStatusData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "GOVERNMENT_APPROVAL"
      )
      .then((res) => {
        this.setState({ governmentApprovalStatusDatas: res.data });
      });
  };

  handleGeoChange = (e) => {
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          const [city, state, country] = handleGoogleResponse(res);
          this.setState({
            city,
            state,
            country,
            zipCode: e,
          });
          this.props.form.setFieldsValue({
            city,
            state,
            country,
          });
        })
        .catch((err) => {
          this.setState({
            city: null,
            state: null,
            country: null,
            zipCode: e,
          });
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
        });
    }
  };

  componentDidMount() {
    this.getLocationData();
    this.getGovtApprovalStatusData();
    this.getPaymentData();
    this.getStatusData();
    this.getCountries();
    this.setState(
      {
        locationId: this.props.locationId,
        sceneLocationId: this.props.sceneLocationId,
      },
      () => {
        if (this.state.locationId) {
          this.getLocationDetails();
        }
      }
    );
  }

  getLocationDetails = (isCreate) => {
    const { actualLocation, locationId, sceneLocationId } = this.state;
    const id = isCreate ? isCreate : locationId;
    if (sceneLocationId) {
      this._locationService
        .locationTabService(
          Services.SceneOVerviewVariable.GET_LOCATION_BY_ID,
          id,
          sceneLocationId
        )
        .then((res) => {
          this.setState(
            {
              addressLine: res.data.address && res.data.address.addressLine,
              city: res.data.address && res.data.address.city,
              country: res.data.address && res.data.address.country,
              state: res.data.address && res.data.address.state,
              zipCode: res.data.address && res.data.address.zipCode,
              contactNumber: res.data.contactNumber,
              contactPerson: res.data.contactPerson,
              latitude: res.data.latitude,
              longitude: res.data.longitude,
              locationType: res.data.locationType,
              shootAmount: res.data.shootAmount,
              shootAmountType: res.data.shootPaymentType,
              // status: res.data.stateEnum,
              workAmount: res.data.workAmount,
              workAmountType: res.data.workPaymentType,
              isNeedGovernmentApproval: res.data.isNeedGovernmentApproval,
              governmentApprovalStatus: res.data.governmentApprovalStatusEnum,
              actualLocation: isCreate
                ? actualLocation
                : this.props.actualLocationName,
              fileList: res.data.locationImage,
              pan: res.data.pan,
              gst: res.data.gst,
              comment: res.data.comment,
            },
            () => this.splitBasedOnFileTypes(this.state.fileList)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  deleteImage = (id) => {
    let fileDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );
    let imageDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;
    imageFiles.splice(imageDeleteIndex, 1);
    fileList.splice(fileDeleteIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.props.handleModel("location", false, "entire");
  };

  handleCancel = (e) => {
    this.props.form.resetFields();
    this.props.handleModel("location", false, "entire");
    this.setState({
      sceneLocation: null,
      actualLocation: null,
      locationType: null,
      contactPerson: null,
      contactNumber: null,
      shootAmount: null,
      shootAmountType: null,
      workAmount: null,
      workAmountType: null,
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      status: null,
      imageId: null,
      isLoading: false,
      locationId: null,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      status: null,
      gst: null,
      pan: null,
      isNeedGovernmentApproval: false,
      governmentApprovalStatus: null,
      comment: false,
    });
  };

  handleImage = (url) => {
    const { fileList } = this.state;

    this.setState({
      projectimageid: url,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { fileData: url, fileType: GlobalConstants.IMAGE },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ fileData: url, fileType: GlobalConstants.IMAGE }];
      this.setState({
        fileList: temp,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      addressLine,
      city,
      state,
      country,
      zipCode,
      contactNumber,
      contactPerson,
      imageId,
      locationType,
      sceneLocation,
      actualLocation,
      shootAmount,
      shootAmountType,
      workAmount,
      workAmountType,
      phoneCode,
      status,
      isNeedGovernmentApproval,
      governmentApprovalStatus,
      locationId,
      pan,
      gst,
      comment,
      locationAvailabilities,
      cautionDeposit,
    } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        if (
          this.state.status === GlobalConstants.CONFIRMED ||
          this.state.status === GlobalConstants.NEGOTIATION
        ) {
          const data = {
            locationId:
              this.state.sceneLocationId ||
              localStorage.getItem(LocalStorage.LOCATION_ID),
            address: {
              addressLine: addressLine,
              city: city,
              country: country,
              state: state,
              zipCode: zipCode,
            },
            contactNumber: contactNumber,
            contactPerson: contactPerson,
            phoneCode: phoneCode,
            latitude: this.props.latitude,
            locationImage: this.state.fileList,
            locationName: actualLocation,
            locationType: locationType,
            longitude: this.props.longitude,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            shootAmount: shootAmount,
            // shootPaymentType: "PER_DAY",
            shootPaymentType: shootAmountType,
            state: this.state.status,
            workAmount: workAmount,
            // workPay mentType: "PER_DAY"
            workPaymentType: workAmountType,
            isNeedGovernmentApproval,
            governmentApprovalStatus,
            actualLocationId: locationId,
            pan,
            gst,
            comment,
            locationAvailabilities,
            cautionDeposit,
          };
          if (
            !checkFor(
              this.props.activePermisision,
              GlobalConstants.PERMISSION_PERSONAL
            )
          ) {
            delete data.address;
          }
          this._locationService
            .locationTabService(
              Services.SceneOVerviewVariable.ADD_LOCATION,
              data
            )
            .then((res) => {
              this.props.form.resetFields();
              this.setState({ isLoading: false });
              this.handleCancel();
              this.props.refreshLocation();
              this.props.getNotificationCount();
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "New Location Created!!"
              );
              this.getLocationData();
            })
            .catch((err) => {
              this.props.getPermissions();
              console.log(err);
              this.setState({ isLoading: false });
              this.props.form.resetFields();
              // this.handleCancel();
              showNotifications(
                Notifications.ERROR,
                "Failed",
                handleError(
                  err.response && err.response.data && err.response.data.message
                )
              );
            });
        } else {
          const data = {
            locationId:
              this.state.sceneLocationId ||
              localStorage.getItem(LocalStorage.LOCATION_ID),
            contactNumber: contactNumber,
            contactPerson: contactPerson,
            phoneCode: phoneCode,
            locationImage: this.state.fileList,
            locationName: actualLocation,
            locationType: locationType,
            state: this.state.status,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            address: {
              addressLine: addressLine,
              city: city,
              country: country,
              state: state,
              zipCode: zipCode,
            },
            isNeedGovernmentApproval,
            governmentApprovalStatus,
            pan,
            gst,
            comment,
            cautionDeposit,
          };
          if (
            !checkFor(
              this.props.activePermisision,
              GlobalConstants.PERMISSION_PERSONAL
            )
          ) {
            delete data.address;
          }
          this._locationService
            .locationTabService(
              Services.SceneOVerviewVariable.ADD_LOCATION,
              data
            )
            .then((res) => {
              this.props.form.resetFields();
              this.setState({ isLoading: false });
              this.handleCancel();
              this.props.refreshLocation();
              this.props.getNotificationCount();
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "New Location Created!!"
              );
            })
            .catch((err) => {
              console.log(err);
              this.props.getPermissions();
              this.setState({ isLoading: false });
              this.props.form.resetFields();
              // this.handleCancel();
              showNotifications(
                Notifications.ERROR,
                "Failed",
                handleError(
                  err.response && err.response.data && err.response.data.message
                )
              );
            });
        }
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    const {
      addressLine,
      city,
      state,
      country,
      zipCode,
      contactNumber,
      contactPerson,
      imageId,
      locationType,
      sceneLocation,
      actualLocation,
      shootAmount,
      shootAmountType,
      workAmount,
      workAmountType,
      status,
      phoneCode,
      isNeedGovernmentApproval,
      governmentApprovalStatus,
      pan,
      gst,
      comment,
      cautionDeposit,
    } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const data = {
          locationId:
            this.props.sceneLocationId ||
            localStorage.getItem(LocalStorage.LOCATION_ID),
          actualLocationId: this.state.locationId,
          address: {
            addressLine: addressLine,
            city: city,
            country: country,
            state: state,
            zipCode: zipCode,
          },
          contactNumber: contactNumber,
          phoneCode: phoneCode,
          contactPerson: contactPerson,
          latitude: this.props.latitude,
          locationImage: this.state.fileList,
          locationName: actualLocation,
          locationType: locationType,
          longitude: this.props.longitude,
          projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
          shootAmount: shootAmount,
          shootPaymentType: shootAmountType,
          state: this.state.status,
          workAmount: workAmount,
          workPaymentType: workAmountType,
          isNeedGovernmentApproval,
          governmentApprovalStatus,
          pan,
          gst,
          comment,
          cautionDeposit,
        };
        if (
          !checkFor(
            this.props.activePermisision,
            GlobalConstants.PERMISSION_PERSONAL
          )
        ) {
          delete data.address;
        }

        this._locationService
          .locationTabService(
            Services.SceneOVerviewVariable.UPDATE_LOCATION,
            data
          )
          .then((res) => {
            this.setState({ isLoading: false, locationId: null });
            this.props.form.resetFields();
            this.props.getNotificationCount();
            this.handleCancel();
            this.props.refreshLocation();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Location Updated Successfully!!"
            );
          })
          .catch((err) => {
            console.log(err.response.data);
            this.setState({ isLoading: false });
            this.props.form.resetFields();
            // this.handleCancel();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    });
  };

  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
    });
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  getLocationData = () => {
    if (this.props.activeSceneLocationId || this.props.sceneLocationId) {
      this._globalService
        .globalService(
          Services.GlobalVariables.GET_MASTER_DATA,
          "LOCATION_TYPE"
        )
        .then((res) => {
          this.setState({ locationListDatas: res.data });
        });
      this._locationService
        .locationTabService(
          Services.SceneOVerviewVariable.GET_ACTUAL_LOCATION,
          null,
          this.props.activeSceneLocationId || this.props.sceneLocationId
        )
        .then((res) => {
          this.setState({
            actualLocationList: Array.isArray(res.data) ? res.data : [],
          });
          // this.setState({ locationListDatas: res.data });
        });
    }
  };

  getDetails = (city, state, country, zipCode) => {
    this.setState({
      city,
      state,
      country,
      zipCode,
    });
  };

  getPaymentData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "LOCATION_PAYMENT_TYPE",
        true
      )
      .then((res) => {
        this.setState({ paymentListDatasShoot: res.data });
      });
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "LOCATION_PAYMENT_TYPE"
      )
      .then((res) => {
        this.setState({ paymentListDatasSetWork: res.data });
      });
  };

  getStatusData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({ statusListDatas: res.data });
      });
  };

  getCountries = () => {
    this._onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          countryListDatas: res.data.countries,
          // phoneCodeList: res.data.countries
        });
      });
  };

  handleStatus = (value) => {
    if (value !== GlobalConstants.CONFIRMED) {
      this.setState({
        status: value,
      });
    }

    let locationId = this.state.sceneLocationId || localStorage.LOCATION_ID;
    if (value === GlobalConstants.CONFIRMED) {
      this._locationService
        .locationTabService(
          Services.SceneOVerviewVariable.GET_CONFIRMED_LOCATION,
          null,
          locationId
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              confirmedLocationName:
                res.data.locations && res.data.locations[0].actualLocationName,
              isModalVisible: true,
            });
          }
          if (res.status === 204) {
            this.setState({
              status: value,
            });
          }
        });
    }
  };
  submitData = () => {
    this.setState({
      status: GlobalConstants.CONFIRMED,
      isModalVisible: false,
    });
  };

  closeModal = () => {
    this.setState({
      // gettingStatus: GlobalConstants.TO_BE_CONFIRMED,
      isModalVisible: false,
    });
    this.handleCancel();
  };

  // handleChange = (obj, actionMeta) => {
  //   this.setState(
  //     {
  //       actualLocation: obj && obj.label
  //     },
  //     () =>
  //       actionMeta.action !== "create-option" &&
  //       this.getLocationDetails(obj && obj.value)
  //   );
  // };

  handleSearch = (actualLocation) => {
    this.setState({
      actualLocation,
    });
  };

  handleLoctaionBlur = (actualLocation) => {
    if (actualLocation) {
      const { actualLocationList } = this.state;
      let total = actualLocationList;
      let check = false;
      total.filter((list) => {
        return (check =
          list.label.toLowerCase() !== actualLocation.toLowerCase());
      });
      this.setState(
        {
          actualLocationList: [...total, { label: actualLocation }],
        },
        () => this.setState({ actualLocation: actualLocation })
      );
    }
  };

  handleChange = (actualLocationName) => {
    if (actualLocationName) {
      let actualLocation = actualLocationName;
      const { actualLocationList } = this.state;
      let tempLocation = null;
      tempLocation = actualLocationList.find(
        (location, i) => location.label === actualLocationName
      );
      actualLocation = tempLocation.label;
      this.setState(
        {
          actualLocation,
          locationId: tempLocation && tempLocation.value,
        },
        () =>
          actualLocation &&
          this.getLocationDetails(tempLocation && tempLocation.value)
      );
    }
  };

  handleInputChange = (inputValue, actionMeta) => {};

  handleAvailability = (details) => {
    this.setState(
      {
        locationAvailabilities: details,
      },
      () =>
        console.log("locationAvailabilities", this.state.locationAvailabilities)
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { latitude, longitude, activePermisision } = this.props;
    const { fileList, docFiles, imageFiles } = this.state;

    let IsFinance = checkFor(
      activePermisision,
      GlobalConstants.PERMISSION_FINANCE
    );
    let IsPersonal = checkFor(
      activePermisision,
      GlobalConstants.PERMISSION_PERSONAL
    );
    return (
      <Fragment>
        <Modal
          destroyOnClosek
          visible={this.props.locationModel}
          onOk={this.handleOk}
          className="createModal no-x"
          title={
            <div
              className="font-600 text-center primary-font-color white"
              style={{ margin: 0, fontSize: "20px" }}
            >
              {this.props.sceneLocationName ||
                localStorage.getItem(LocalStorage.SCENE_LOCATION_NAME)}{" "}
              - {this.props.type} Location
            </div>
          }
          // onCancel={this.handleCancel}
          width="1000px"
          footer={
            <Row
              gutter={12}
              type="flex"
              justify="end"
              style={{ marginTop: "20px" }}
            >
              <Col>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </Col>
              <Col>
                {this.state.locationId != undefined ? (
                  <Button
                    type="primary"
                    loading={this.state.isLoading}
                    onClick={this.handleUpdate}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={this.state.isLoading}
                    onClick={this.handleSubmit}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          }
        >
          {/* <h3
            className="font-600 text-center primary-font-color"
            style={{ margin: 0, fontSize: "20px" }}
          >
            {this.props.sceneLocationName ||
              localStorage.getItem(LocalStorage.SCENE_LOCATION_NAME)}
          </h3>
          <h3 className="font-600 mb-20 text-center primary-font-color">
            {this.props.type} Location
          </h3> */}
          <Form>
            <Row gutter={16} className="mt-5">
              <Col xl={{ span: 8 }}>
                <label className="font-600">*Actual Location </label>
                <Form.Item>
                  {getFieldDecorator("actuallocation", {
                    initialValue: this.state.actualLocation,
                    rules: [
                      {
                        required: true,
                        message: "Enter your Actual Location!",
                      },
                      // {
                      //   pattern: new RegExp("([a-zA-Z0-9])"),
                      //   message: "location name must be in alphabet"
                      // }
                    ],
                  })(
                    // <CreatableSelect
                    //   isClearable
                    //   onChange={this.handleChange}
                    //   // onInputChange={() => alert("hai")}
                    //   options={this.state.actualLocationList}
                    //   // maxMenuHeight={30}
                    //   maxMenuHeight={200}
                    //   placeholder="Select Actual Location"
                    // />

                    <Select
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onSearch={(value) => this.handleSearch(value)}
                      onChange={this.handleChange}
                      value={this.state.actualLocation}
                      onBlur={this.handleLoctaionBlur}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.actualLocationList.map(
                        (actualLocation, i) => (
                          <Option value={actualLocation.label}>
                            {actualLocation.label}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 8 }}>
                <label className="font-600">Location Type </label>
                <Form.Item>
                  {getFieldDecorator("locationtype", {
                    initialValue: this.state.locationType,
                  })(
                    <Select
                      onChange={(value) =>
                        this.setState({ locationType: value })
                      }
                      placeholder="Select Location Type"
                      className="w-100"
                    >
                      {this.state.locationListDatas &&
                        (this.state.locationListDatas || []).map(
                          (option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          )
                        )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 8 }}>
                <label className="font-600">Contact Person </label>
                <Form.Item>
                  {getFieldDecorator("contactperson", {
                    initialValue: this.state.contactPerson,
                  })(
                    <Input
                      placeholder="Contact Person Name"
                      onChange={(e) =>
                        this.setState({ contactPerson: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xl={{ span: 8 }}>
                <label className="font-600">*Contact Number </label>
                <Form.Item>
                  {getFieldDecorator("contactnumber", {
                    initialValue: this.state.contactNumber,
                    rules: [
                      {
                        required: true,
                        message: "Enter your contact number!",
                      },
                      this.state.phoneCode === "+91" && {
                        pattern: ".{10,}",
                        message: "Enter 10 Numbers Only!",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Contact Number"
                      style={{ width: "100%" }}
                      maxLength={this.state.phoneCode === "+91" ? 10 : null}
                      onChange={(value) => {
                        this.setState({
                          contactNumber: numberOnly(value),
                        });
                      }}
                      addonBefore={
                        <Form.Item
                          className="payment-type"
                          style={{
                            margin: "0px",
                            height: "30px",
                            top: "-4px",
                          }}
                        >
                          {getFieldDecorator("phoneCode", {
                            initialValue: this.state.phoneCode,
                          })(
                            <Select
                              showSearch
                              placeholder="Country Code"
                              style={{ width: "100px" }}
                              optionFilterProp="children"
                              onChange={(value) => {
                                this.setState({ phoneCode: value });
                              }}
                              filterOption={(input, option) => {
                                return option.props.children
                                  .toString()
                                  .includes(`+${input.toString()}`);
                              }}
                            >
                              {this.state.phoneCodeList &&
                                (this.state.phoneCodeList || []).map(
                                  (option, i) => (
                                    <Option value={option.phoneCode}>
                                      {option.phoneCode}
                                    </Option>
                                  )
                                )}
                            </Select>
                          )}
                        </Form.Item>
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              {/* {checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_PERSONAL
              ) ? ( */}
              {/* <div> */}
              {IsPersonal && (
                <Fragment>
                  {checkFor(
                    activePermisision,
                    GlobalConstants.PERMISSION_FINANCE
                  ) && (
                    <>
                      <Col xl={{ span: 8 }}>
                        <label className="font-600">PAN </label>
                        <Form.Item>
                          {getFieldDecorator("pan", {
                            initialValue: this.state.pan,
                            rules: [
                              // { required: true, message: "Enter your Zip Code!" },
                              {
                                pattern: new RegExp(
                                  "([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}"
                                ),
                                message:
                                  "Please Enter Correct Pan Format Ex: JSDFG1234H ",
                              },
                            ],
                          })(
                            <Input
                              placeholder="enter PAN"
                              // maxLength="6"
                              onChange={(e) =>
                                this.setState({ pan: e.target.value })
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xl={{ span: 8 }}>
                        <label className="font-600">GST </label>
                        <Form.Item>
                          {getFieldDecorator("gst", {
                            initialValue: this.state.gst,
                            rules: [
                              // { required: true, message: "Enter your Zip Code!" },
                              {
                                pattern: new RegExp(
                                  "[0-3]{1}[0-9]{1}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-2]{1}[Zz]{1}[0-9A-Za-z]{1}"
                                ),
                                message:
                                  "Please Enter Correct Gst Format Ex:22AAAAA0000A1Z5",
                              },
                            ],
                          })(
                            <Input
                              placeholder="enter GST"
                              // maxLength="6"
                              onChange={(e) =>
                                this.setState({ gst: e.target.value })
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col xl={{ span: 8 }}>
                    <label className="font-600">Zip Code </label>
                    <Form.Item>
                      {getFieldDecorator("zipcode", {
                        initialValue: this.state.zipCode,
                      })(
                        <Input
                          placeholder="Zip Code"
                          maxLength="6"
                          onChange={(e) => this.handleGeoChange(numberOnly(e))}
                        />
                        // <GeoCode
                        //   defaultValue={this.state.zipCode}
                        //   getDetails={this.getDetails}
                        // />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 8 }}>
                    <label className="font-600">Country </label>
                    <Form.Item>
                      {getFieldDecorator("country", {
                        initialValue: this.state.country || undefined,
                        // rules: [{ required: true, message: "Enter your Country!" }]
                      })(
                        <Select
                          showSearch
                          placeholder="Country"
                          className="w-100"
                          onChange={(value) =>
                            this.setState({ country: value })
                          }
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.countryListDatas &&
                            (this.state.countryListDatas || []).map(
                              (option, i) => (
                                <Option value={option.name}>
                                  {option.name}
                                </Option>
                              )
                            )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 8 }}>
                    <label className="font-600">State </label>
                    <Form.Item>
                      {getFieldDecorator("state", {
                        initialValue: this.state.state,
                        rules: [
                          {
                            pattern: new RegExp("([a-zA-Z])"),
                            message: "State must be in alphabet",
                          },
                        ],
                      })(
                        <Input
                          placeholder="State"
                          onChange={(e) =>
                            this.setState({ state: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 8 }}>
                    <label className="font-600">City </label>
                    <Form.Item>
                      {getFieldDecorator("city", {
                        initialValue: this.state.city,
                      })(
                        <Input
                          placeholder="City"
                          onChange={(e) =>
                            this.setState({ city: e.target.value })
                          }
                          // value={this.state.city}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 8 }}>
                    <label className="font-600">*Address </label>
                    <Form.Item>
                      {getFieldDecorator("address", {
                        initialValue: this.state.addressLine,
                        rules: [
                          {
                            required: true,
                            message: "Enter your Address!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Address"
                          onChange={(e) =>
                            this.setState({ addressLine: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={{ span: 8 }}>
                    <label className="font-600">Status </label>
                    <Form.Item>
                      {getFieldDecorator("locationstatus", {
                        initialValue:
                          this.state.status ||
                          (this.state.statusListDatas &&
                            this.state.statusListDatas.length !== 0 &&
                            this.state.statusListDatas[0].label),
                      })(
                        <Select
                          placeholder="Status"
                          className="w-100"
                          // onChange={value =>
                          //   this.setState({ status: value }, () => {
                          //     console.log("status.....", this.state.status);
                          //   })
                          // }
                          onChange={this.handleStatus}
                        >
                          {this.state.statusListDatas &&
                            (this.state.statusListDatas || []).map(
                              (option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              )
                            )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Fragment>
              )}
              {/* </div> */}
              {/* ) : null} */}

              {this.state.status === GlobalConstants.CONFIRMED ||
              this.state.status === GlobalConstants.NEGOTIATION ? (
                <React.Fragment>
                  {true ? (
                    <div>
                      <Col xl={{ span: 8 }}>
                        <label className="font-600">
                          Rent/Hire (Shoot Day) :
                        </label>
                        <Form.Item>
                          {getFieldDecorator("shootday", {
                            initialValue: this.state.shootAmount,
                            rules: [
                              {
                                required:
                                  this.state.status ===
                                  GlobalConstants.CONFIRMED
                                    ? true
                                    : false,
                                message: "This field is required!",
                              },
                            ],
                          })(
                            <Input
                              prefix=" ₹ "
                              placeholder="Enter Amount"
                              onChange={(e) =>
                                this.setState({ shootAmount: amountOnly(e) })
                              }
                              addonAfter={
                                <Form.Item
                                  className="payment-type"
                                  style={{
                                    margin: "0px",
                                    height: "30px",
                                    top: "-4px",
                                  }}
                                >
                                  {getFieldDecorator("shootAmountType", {
                                    initialValue:
                                      this.state.shootAmountType || undefined,
                                  })(
                                    <Select
                                      placeholder="Payment Type"
                                      style={{ width: "125px" }}
                                      onChange={(value) =>
                                        this.setState({
                                          shootAmountType: value,
                                        })
                                      }
                                    >
                                      {this.state.paymentListDatasShoot &&
                                        (
                                          this.state.paymentListDatasShoot || []
                                        ).map((option, i) => (
                                          <Option value={option.value}>
                                            {option.label}
                                          </Option>
                                        ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col xl={{ span: 8 }}>
                        <label className="font-600">
                          Rent/Hire (Set Work Day) :
                        </label>
                        <Form.Item>
                          {getFieldDecorator("setWorkDay", {
                            initialValue: this.state.workAmount,
                            rules: [
                              {
                                required:
                                  this.state.status ===
                                  GlobalConstants.CONFIRMED
                                    ? true
                                    : false,
                                message: "This field is required!",
                              },
                            ],
                          })(
                            <Input
                              prefix=" ₹ "
                              placeholder="Enter Amount"
                              onChange={(e) =>
                                this.setState({ workAmount: amountOnly(e) })
                              }
                              addonAfter={
                                <Form.Item
                                  className="payment-type"
                                  style={{
                                    margin: "0px",
                                    height: "30px",
                                    top: "-4px",
                                  }}
                                >
                                  {getFieldDecorator("workAmountType", {
                                    initialValue:
                                      this.state.workAmountType || undefined,
                                  })(
                                    <Select
                                      placeholder="Payment Type"
                                      onChange={(value) =>
                                        this.setState({ workAmountType: value })
                                      }
                                      style={{ width: "125px" }}
                                    >
                                      {this.state.paymentListDatasSetWork &&
                                        (
                                          this.state.paymentListDatasSetWork ||
                                          []
                                        ).map((option, i) => (
                                          <Option value={option.value}>
                                            {option.label}
                                          </Option>
                                        ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </div>
                  ) : null}
                  {/* <Col xl={{ span: 8 }}>
                <label className="font-600">Geo Tag/Google Location </label>
                <Row gutter={0}>
                  <Col span={22}>
                    <Form.Item>
                      {getFieldDecorator("geo", {
                        initialValue: `${latitude},${longitude}`
                      })(<Input disabled />)}
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <div
                      onClick={() => {
                        this.props.handleModel("location", false);
                      }}
                      style={{
                        border: "1px solid #e3e3e3",
                        backgroundColor: "#2da01c",
                        color: "#fff",
                        marginLeft: "-10px",
                        height: "34px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginTop: "3px"
                      }}
                    >
                      <Icon type="environment" />
                    </div>
                  </Col>
                </Row>
              </Col> */}

                  {/* <Col xl={{ span: 8 }}>
                <label className="font-600">Address </label>
                <Form.Item>
                  {getFieldDecorator("locationtaddress", {
                    rules: [
                      {
                        required: true,
                        message: "Enter your Location Address!"
                      }
                    ]
                  })(<TextArea placeholder="Address" rows={4} />)}
                </Form.Item>
              </Col> */}
                </React.Fragment>
              ) : null}
              {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) && (
                <Col xl={{ span: 8 }}>
                  <label className="font-600">Government Approval </label>
                  <Form.Item className="m-0">
                    {getFieldDecorator("isNeedGovernmentApproval", {
                      initialValue: this.state.isNeedGovernmentApproval,
                    })(
                      <Checkbox
                        checked={this.state.isNeedGovernmentApproval}
                        onChange={(value) => {
                          this.setState({
                            isNeedGovernmentApproval:
                              value.target.checked || false,
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              )}
              <Col xl={{ span: 8 }} className="pl-5">
                {this.state.isNeedGovernmentApproval == true ? (
                  <>
                    <label className="font-600">
                      GovernmentApproval Status
                    </label>
                    <Form.Item
                      className="payment-type"
                      style={{
                        margin: "0px",
                        height: "30px",
                        top: "-4px",
                      }}
                    >
                      {getFieldDecorator("governmentApprovalStatus", {
                        initialValue:
                          this.state.governmentApprovalStatus || undefined,
                        rules: [
                          {
                            required: this.state.isNeedGovernmentApproval
                              ? true
                              : false,
                            message: "Goverment Approval status is required!",
                          },
                        ],
                      })(
                        <Select
                          placeholder="Government approval status"
                          onChange={(value) =>
                            this.setState({
                              governmentApprovalStatus: value,
                              isdirty: true,
                            })
                          }
                          style={{ width: "232px" }}
                        >
                          {this.state.governmentApprovalStatusDatas &&
                            (
                              this.state.governmentApprovalStatusDatas || []
                            ).map((option, i) => (
                              <Option value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
              </Col>
              {checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_FINANCE
              ) && (
                <Col xl={{ span: 8 }} className="pl-5">
                  <>
                    <label className="font-600">Caution Deposit </label>
                    <Form.Item
                      className="payment-type"
                      style={{
                        margin: "0px",
                        height: "30px",
                        top: "-4px",
                      }}
                    >
                      {getFieldDecorator("cautionDeposit", {
                        rules: [
                          {
                            pattern: new RegExp("^(0|[0-9].*)$"),
                            message: "Enter numbers only",
                          },
                        ],
                        initialValue: this.state.cautionDeposit || undefined,
                      })(
                        <Input
                          onChange={(e) =>
                            this.setState({ cautionDeposit: numberOnly(e) })
                          }
                          placeholder=""
                        />
                      )}
                    </Form.Item>
                  </>
                </Col>
              )}
            </Row>
            <Row>
              <Col xl={16}>
                <label className="font-600">Notes </label>
                <Form.Item className="payment-type" style={{}}>
                  {getFieldDecorator("comment", {
                    rules: [
                      {
                        required: true,
                        message: "Enter your Notes!",
                      },
                    ],
                    initialValue: this.state.comment || undefined,
                  })(
                    <TextArea
                      placeholder="enter comment for the location"
                      // autoSize={{ minRows: 6, maxRows: 5 }}
                      rows={4}
                      onChange={(e) =>
                        this.setState({ comment: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {this.state.status === GlobalConstants.CONFIRMED && (
              <Row>
                <Col xl={16}>
                  <label className="font-600">Location Availability</label>
                  <Form.Item className="payment-type" style={{}}>
                    {getFieldDecorator("comment", {
                      // rules: [
                      //   {
                      //     required: true,
                      //     message: `GovernmentApproval Status is required.`
                      //   }
                      // ],
                      initialValue: this.state.comment || undefined,
                    })(
                      <LocationAvailabilityTable
                        getFromChild={this.handleAvailability}
                        // locationId={this.props.match.params.locationId}
                        // sceneLocationId={this.props.match.params.sceneLocationId}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <label className="font-600">Photos</label>
              {/* <UploadImage
                handleImage={this.handleImage}
                text="Upload Location Image"
              /> */}
              <Form.Item>
                {getFieldDecorator("projectimageid", {
                  initialValue: this.state.projectimageid,
                })(
                  <React.Fragment>
                    <div className="fileCardContainer">
                      {imageFiles &&
                        imageFiles.map((file, i) => (
                          <FileCard
                            file={file}
                            deleteImage={this.deleteImage}
                            pageType={
                              Services.LocationVariables.LOCATION_DELETE_DOC
                            }
                          />
                        ))}
                    </div>
                    {!this.state.isLoading && (
                      // <UploadImage handleImage={this.handleImage} />
                      <MultipleSelectImage
                        maxLength={2}
                        handleImageIDS={this.handleImageIDS}
                      />
                    )}
                  </React.Fragment>
                )}
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <LocationConfirmModel
          isModalVisible={this.state.isModalVisible}
          closeModal={this.closeModal}
          submitData={this.submitData}
          confirmedLocationName={this.state.confirmedLocationName}
          // characterName={this.props.match.params.characterName}
        />
      </Fragment>
    );
  }
}
const CreateLocationModal = Form.create()(AddLocationModal);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.LOCATION, menus),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLocationModal);
