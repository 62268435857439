import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Tooltip } from "antd";
import { Doughnut } from "react-chartjs-2";

// import UpdateProduction from "./UpdateProduction";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { GlobalConstants, Services } from "constants/AppConstants";
import { Label, Value } from "components/shared/Label";
import ThemeColors from "constants/ThemeConstants/variables";
// import UpdateArtSet from "./UpdateProduction/UpdateArtset";
// import UpdateEquipments from "./UpdateProduction/UpdateEquipments";
// import UpdateCostumeMakeup from "./UpdateProduction/UpdateCostumeMakeup";
// import UpdateSongStunt from "./UpdateProduction/UpdateSongStunt";
import BudjetService from "services/BudjetService";
import { PriceFormat } from "utilities/ValidationHelper";
import AccUpdateProduction from "./AccUpdateProduction";

const { TabPane } = Tabs;

const TabName = [
  GlobalConstants.PRODUCTION,
  GlobalConstants.ART_SET,
  GlobalConstants.EQUIPMENTS,
  GlobalConstants.COSTUME_AND_MAKEUP,
  GlobalConstants.SONG_AND_STUNT,
];

const options = {
  legend: {
    display: false,
    labels: {
      fontColor: "rgb(255, 99, 132)",
    },
  },
};

const order = [
  "production",
  "artset",
  "equipment",
  "costumeMakeup",
  "songStunt",
];

const AccProductionUpdateTabs = (props) => {
  const [SelectedTab, setSelectedTab] = useState(1);
  const [TotalAmount, setTotalAmount] = useState(null);
  const [TotalAmountInWords, setTotalAmountInWords] = useState(null);
  const [ProductionAmount, setProductionAmount] = useState(null);
  const [ArtSetAmount, setArtSetAmount] = useState(null);
  const [EquipmentAmount, setEquipmentAmount] = useState(null);
  const [CostumeMakeupAmount, setCostumeMakeupAmount] = useState(null);
  const [SongStuntAmount, setSongStuntAmount] = useState(null);
  const [ChartData, setChartData] = useState(null);
  const [PageViewType, setPageViewType] = useState(
    props.match.params.type === "view"
  );
  const api = new BudjetService();

  useEffect(() => {
    getChartData();
  }, []);

  const getChartData = () => {
    api
      .budjetService(
        Services.BudjetVariables.GET_CHART_DATA,
        props.match.params.shootDate
      )
      .then((res) => {
        setTotalAmount(res.data.budgets[0].totalbudget);
        setTotalAmountInWords(res.data.budgets[0].totalBudgetRoundOffInWords);
        setChartData(
          handleChartData(res.data.budgets[0], res.data.budgets[0].totalbudget)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChartData = (chartData, totalAmount) => {
    let data = [];
    let chartDatas = Object.values(chartData);
    chartDatas.map((chartDatum, i) => {
      return (
        chartData[order[i]] &&
        data.push({
          labels: ["Amount spent", "Total amount"],
          datasets: [
            {
              data: [
                parseFloat(chartData[order[i]].spentAmount).toFixed(2),
                parseFloat(totalAmount).toFixed(2),
              ],
              backgroundColor: [ThemeColors.primaryColor, ThemeColors.darkGrey],
              hoverBackgroundColor: [
                ThemeColors.primaryColor,
                ThemeColors.darkGrey,
              ],
            },
          ],
        })
      );
    });
    return data;
  };

  const renderCharts = () => {
    const charts = [];
    for (let i = 1; i <= 5; i++) {
      let chart = ChartData[i - 1];
      const isSelected = i === parseInt(SelectedTab);
      charts.push(
        <Col
          className={`doughnutArea ${isSelected ? "activeChart" : ""}`}
          xl={isSelected ? 4 : 4}
          key={i}
          onClick={() => setSelectedTab(i)}
        >
          <Col xl={24}>
            <Doughnut
              className="chart"
              data={ChartData[i - 1]}
              options={options}
            />
          </Col>
          <Col
            xl={24}
            style={{
              textAlign: "center",
              color: isSelected && ThemeColors.primaryColor,
              // fontWeight: isSelected && "bold",
              // fontSize: isSelected && 16
            }}
          >
            <p>
              <div style={{ paddingBottom: 2, paddingTop: 5 }}>
                {TabName[i - 1]}
              </div>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>
                {chart.datasets[0].data[0]}
              </span>
            </p>
          </Col>
        </Col>
      );
    }
    return charts;
  };

  const handleTabChange = (id) => {
    setSelectedTab(id);
  };

  return (
    <Row>
      <Col xs={24}>
        <Title hasBack {...props}>
          <TitleText>Accounting - Update Production</TitleText>
        </Title>
        <Row style={{ paddingTop: 20 }}>
          <Row type="flex" justify="center" align="middle">
            {TotalAmount && (
              <Col
                // xs={12}
                style={{ paddingLeft: 24, alignItems: "center" }}
                className="flex-row"
              >
                <Label style={{ fontSize: 20 }}>Total amount</Label>
                <Value
                  style={{ fontSize: 20, paddingLeft: 5, fontWeight: "bold" }}
                >
                  &#8377; {PriceFormat(TotalAmount)}
                  {/* {TotalAmount &&
                  TotalAmount.toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "INR"
                  })} */}
                  {/* {Intl.NumberFormat("en-IN").format(TotalAmount && TotalAmount)} */}
                </Value>
              </Col>
            )}
            {/* {TotalAmountInWords} */}
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col>
              <div style={{ fontSize: 18, fontWeight: "bold" }}>
                {`${
                  TotalAmountInWords ? `Rupees ${TotalAmountInWords} Only` : ""
                }`}
              </div>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ paddingTop: 10 }}
          >
            <Col xl={{ span: 20, offset: 2 }}>
              <Row type="flex" justify="center" align="middle">
                <Col xl={24}> {ChartData && renderCharts()}</Col>
              </Row>
            </Col>
          </Row>
        </Row>
        <Row style={{ padding: 24, paddingTop: 0 }}>
          <Tabs
            activeKey={SelectedTab.toString()}
            onChange={handleTabChange}
            destroyInactiveTabPane
          >
            <TabPane tab={GlobalConstants.PRODUCTION} key="1">
              {/* <UpdateProduction
                isViewOnly={PageViewType}
                page={GlobalConstants.PRODUCTION_CAPS}
                column={GlobalConstants.PRODUCTION}
                {...props}
                refreshChartData={getChartData}
              ></UpdateProduction> */}
              <AccUpdateProduction />
            </TabPane>
            <TabPane tab={GlobalConstants.ART_SET} key="2">
              {/* <UpdateArtSet
                isViewOnly={PageViewType}
                page={GlobalConstants.ART_SET_CAPS}
                {...props}
                refreshChartData={getChartData}
              ></UpdateArtSet> */}
              <AccUpdateProduction />
            </TabPane>
            <TabPane tab={GlobalConstants.EQUIPMENTS} key="3">
              {/* <UpdateEquipments
                isViewOnly={PageViewType}
                page={GlobalConstants.EQUIPMENTS_CAPS}
                {...props}
                refreshChartData={getChartData}
              ></UpdateEquipments> */}{" "}
              <AccUpdateProduction />
            </TabPane>
            <TabPane tab={GlobalConstants.COSTUME_AND_MAKEUP} key="4">
              {/* <UpdateCostumeMakeup
                isViewOnly={PageViewType}
                page={GlobalConstants.COSTUME_AND_MAKEUP_CAPS}
                {...props}
                refreshChartData={getChartData}
              ></UpdateCostumeMakeup> */}{" "}
              <AccUpdateProduction />
            </TabPane>
            <TabPane tab={GlobalConstants.SONG_AND_STUNT} key="5">
              {/* <UpdateSongStunt
                isViewOnly={PageViewType}
                page={GlobalConstants.SONG_AND_STUNT_CAPS}
                {...props}
                refreshChartData={getChartData}
              ></UpdateSongStunt> */}{" "}
              <AccUpdateProduction />
            </TabPane>
          </Tabs>
        </Row>
      </Col>
    </Row>
  );
};

export default AccProductionUpdateTabs;
