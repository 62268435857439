import React, { Component } from "react";
import {
  Modal,
  Button,
  Icon,
  Table,
  Switch,
  Input,
  Row,
  Col,
  Upload,
  InputNumber,
  Select,
  Popconfirm,
  Divider,
} from "antd";
import SongsModel from "components/songsmodel";
import SongsStuntsServices from "services/SongsStuntsServices";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import { showNotifications } from "components/shared/NotificationComponent";
import Uploaddocumentfile from "components/uploaddocumentfile";
import Paymenttablemodal from "components/paymenttablemodal";
import { ScriptService } from "services";

class Paymenttermspopup extends Component {
  constructor(props) {
    super(props);
    this.scriptservice = new ScriptService();
    this._songsServices = new SongsStuntsServices();
    this._globalService = new GlobalService();
    this.state = {
      SongsData: [],
      isDataFetched: false,
      visible: false,
      recordData: null,
      materialHire: null,
      materialPurchase: null,
      propertyHire: null,
      propertyPurchase: null,
      timeRequiredToShoot: null,
      state: null,
      statusData: [],
      fileId: null,
      dataSource: [],
      modaldata: null,
    };
  }

  componentDidMount() {
    // this.fetchData();
    // this.fetchBudgetData();
    // this.getStatus();
  }

  handlemodal = (data) => {
    this.props.handlepayment(data);
  };

  handlepostdata = (postdata) => {
    this.props.handlepaymentpost(postdata);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.paymentData) {
      this.setState(
        {
          dataSource: nextProps.paymentData,
        },
        () => console.log("this.state.dataSource", this.state.dataSource)
      );
    }
  }

  //   getStatus = () => {
  //     this._globalService
  //       .globalService(
  //         Services.GlobalVariables.GET_MASTER_DATA,
  //         Services.GlobalVariables.APPROVAL_STATUS
  //       )
  //       .then(res => {
  //         this.setState(
  //           {
  //             statusData: res.data
  //           },
  //           () => console.log("status...", this.state.statusData)
  //         );
  //       });
  //   };

  //   fetchBudgetData = () => {
  //     this._songsServices
  //       .service(
  //         Services.SongVariables.GET_SONGS_STUNTS_BUDGET,
  //         Services.SongVariables.SONGS
  //       )
  //       .then(res => {
  //         this.setState(
  //           {
  //             materialHire: res.data.materialHire,
  //             materialPurchase: res.data.materialPurchase,
  //             propertyHire: res.data.propertyHire,
  //             propertyPurchase: res.data.propertyPurchase,
  //             state: res.data.state,
  //             timeRequiredToShoot: res.data.timeRequiredToShoot,
  //             sceneBudgetId: res.data.sceneBudgetId
  //           },
  //           () => console.log("this.state.song", res.data)
  //         );
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   };

  //   fetchData = () => {
  //     this._songsServices
  //       .service(
  //         Services.SongVariables.GET_SONGS_STUNT_LIST,
  //         Services.SongVariables.SONGS
  //       )
  //       .then(res => {
  //         console.log("SongsData..", res);
  //         this.setState({
  //           isDataFetched: true,
  //           SongsData: res.data.sceneDetails
  //         });
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   };

  handleDelete = (key, paymentId, record) => {
    // console.log(this.state.dataSource)
    let filteredData = [];
    if (paymentId) {
      filteredData = this.state.dataSource.filter(
        (a) => a.paymentId !== paymentId
      );
    } else {
      filteredData = this.state.dataSource.filter(
        (a) => a.paymentId !== paymentId
      );
    }

    this.setState({
      dataSource: filteredData,
    });
    // const dataSource = [...this.state.dataSource];
    // // this.setState({ dataSource: dataSource.filter(item => item.key !== key) });

    // this.scriptservice
    // .scriptService(
    //   Services.AddActor.DEL_ACTOR,
    //   paymentId,
    //   console.log("hai iam there", paymentId)
    // )
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  //   handleSubmit = () => {
  //     const {
  //       materialHire,
  //       materialPurchase,
  //       propertyHire,
  //       propertyPurchase,
  //       timeRequiredToShoot,
  //       state,
  //       sceneBudgetId,
  //       fileId
  //     } = this.state;
  //     const data = {
  //       materialHire,
  //       materialPurchase,
  //       propertyHire,
  //       propertyPurchase,
  //       timeRequiredToShoot: timeRequiredToShoot.toString(),
  //       state,
  //       sceneBudgetId,
  //       documents: [
  //         {
  //           fileId,
  //           fileType: "DOCUMENT"
  //         }
  //       ]
  //     };
  //     if (!sceneBudgetId) {
  //       this.setState({
  //         isButtonLoading: true
  //       });
  //       this._songsServices
  //         .service(
  //           Services.SongVariables.ADD_SONGS_STUNTS_BUDGET,
  //           Services.SongVariables.SONGS,
  //           null,
  //           data
  //         )
  //         .then(res => {
  //           this.setState(
  //             {
  //               isButtonLoading: false
  //             },
  //             () => this.fetchBudgetData()
  //           );
  //           showNotifications(
  //             Notifications.SUCCESS,
  //             Notifications.SUCCESS_CAP,
  //             `Song budget ${Notifications.ADDED_SUCCESSFULLY}`
  //           );
  //         })
  //         .catch(err => {
  //           this.setState(
  //             {
  //               isButtonLoading: false
  //             },
  //             () => this.fetchBudgetData()
  //           );

  //           showNotifications(
  //             Notifications.ERROR,
  //             Notifications.FAILED,
  //             `${Notifications.SOMETHING_WENT_WRONG}`
  //           );
  //         });
  //     } else {
  //       this.setState({
  //         isButtonLoading: true
  //       });
  //       this._songsServices
  //         .service(
  //           Services.SongVariables.UPDATE_SONGS_STUNTS_BUDGET,
  //           Services.SongVariables.SONGS,
  //           sceneBudgetId,
  //           data
  //         )
  //         .then(res => {
  //           this.setState(
  //             {
  //               isButtonLoading: false
  //             },
  //             () => this.fetchBudgetData()
  //           );
  //           showNotifications(
  //             Notifications.SUCCESS,
  //             Notifications.SUCCESS_CAP,
  //             `Song budget ${Notifications.UPDATED_SUCCESSFULLY}`
  //           );
  //         })
  //         .catch(err => {
  //           this.setState(
  //             {
  //               isButtonLoading: false
  //             },
  //             () => this.fetchBudgetData()
  //           );
  //           showNotifications(
  //             Notifications.ERROR,
  //             Notifications.FAILED,
  //             `${Notifications.SOMETHING_WENT_WRONG}`
  //           );
  //         });
  //     }
  //   };

  //   handleDocument = id => {
  //     this.setState(
  //       {
  //         fileId: id
  //       },
  //       () => console.log("fileupload id...", this.state.fileId)
  //     );
  //   };

  render() {
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      // multiple: true
    };
    const { Option } = Select;
    const {
      SongsData,
      materialHire,
      materialPurchase,
      propertyHire,
      propertyPurchase,
      timeRequiredToShoot,
      state,
      isDataFetched,
      recordData,
      type,
      statusData,
      sceneBudgetId,
      isButtonLoading,
      dataSource,
    } = this.state;
    const buttonText = sceneBudgetId ? "Update" : "Save";
    const columns = [
      {
        title: "Amount",
        dataIndex: "amount",
        align: "center",
      },
      {
        title: "PaymentDate",
        dataIndex: "paymentDate",
        align: "center",
      },
      {
        title: "remarks",
        dataIndex: "remarks",
        align: "center",
      },

      {
        title: "Action",
        key: "action",
        align: "center",
        render: (text, record) => (
          <div>
            <Button
              icon="edit"
              type="link"
              onClick={() =>
                this.setState(
                  {
                    visible: true,
                    type: GlobalConstants.UPDATE,
                    recordData: record,
                  },
                  () =>
                    console.log("this.state.recordData", this.state.recordData)
                )
              }
            />
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this Song?"
              onConfirm={() => this.handleDelete(record.key, record.paymentId)}
              // onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
              // loading={this.state.isLoading}
            >
              <Button icon="delete" type="link" />
            </Popconfirm>
          </div>
        ),
      },
    ];

    function onChange(value) {
      console.log("changed", value);
    }
    return (
      <div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          key={2}
          width={["630px"]}
          style={{ maxHeight: "40px" }}
          footer={null}
          destroyOnClose
        >
          <Paymenttablemodal
            closeModal={this.handleCancel}
            recordData={recordData}
            type={type}
            refreshData={this.fetchData}
            handlemodal={this.handlemodal}
            handlepostdata={this.handlepostdata}
          />
        </Modal>

        <Row>
          <Col
            xl={{ span: 21 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              // loading={!isDataFetched}
            />
          </Col>
          <Col xl={{ span: 3 }}>
            <div style={{ marginLeft: "20px" }}>
              <Button
                onClick={() =>
                  this.setState({
                    visible: true,
                    type: GlobalConstants.ADD,
                    recordData: null,
                  })
                }
                type="primary"
              >
                + New
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Paymenttermspopup;
Paymenttermspopup.defaultProps = {
  dataSource: [],
};
