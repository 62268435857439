import React, { useEffect } from "react";
import { Button, Icon } from "antd";

const AddButton = ({
  onClick,
  text = "Entry",
  style,
  type = "primary",
  disabled = false,
}) => {
  useEffect(() => {
    return () => {};
  }, [disabled]);

  return (
    <Button
      onClick={onClick}
      style={style}
      type="primary"
      disabled={disabled}
      type={type}
    >
      <Icon type="plus" style={{ fontSize: "15px" }} />
      {text}
    </Button>
  );
};

export default AddButton;
