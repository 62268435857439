import React, { Component } from "react";
import { Checkbox, Col, Row, Table, Select, Button } from "antd";
import { connect } from "react-redux";
import * as _ from "lodash";
import {
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import ThemeColors from "constants/ThemeConstants/variables";
import RoleServices from "services/RoleServices";
import { showNotifications } from "components/shared/NotificationComponent";
import RecordNotFound from "components/shared/RecordNotFound";

const { Option } = Select;

class Approvals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      dataSource: [],
      permissionData: [],
      roles: [],
      isDataFetched: false,
      roleId: null,
      companyId: null,
      isButtonLoading: false,
      backupPermissions: [],
      changedPermissions: [],
      isResetInProgress: false,
    };
    this._roleServices = new RoleServices();
  }

  componentDidMount() {
    this.fetchRoles();
    this.setState({
      isDataFetched: true,
    });
  }

  fetchRoles = () => {
    this.setState({
      isRolesFetched: true,
    });
    this._roleServices
      .services(Services.RolesVariables.GET_ROLES)
      .then((res) => {
        console.log(res);
        this.setState({
          roles: res.data.roles,
          isRolesFetched: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRolesFetched: false,
        });
      });
  };

  handleChangesInPermissions = (
    changedPermissions,
    permission,
    backupPermission
  ) => {
    let isAvailable = this.isAvailableInPreviousChangedPermission(
      changedPermissions,
      permission.menuId
    );
    if (this.isEqualToBackupPermission(permission, backupPermission)) {
      if (isAvailable === -1) {
        this.addChangePermission(permission);
      }
    } else {
      this.removeFromChangedPermissions(permission.menuId);
    }
  };

  isAnyOneIsTrue = (permission) => {
    return (
      permission.isAdd ||
      permission.isEdit ||
      permission.isDelete ||
      permission.isView ||
      permission.isFinance ||
      permission.isPersonal
    );
  };

  isAvailableInPreviousChangedPermission = (changedPermissions, menuId) => {
    return changedPermissions.findIndex(
      (changedPermission) => changedPermission.menuId === menuId
    );
  };

  addChangePermission = (permission) => {
    let changedPermissions = [...this.state.changedPermissions];
    changedPermissions.push(permission);
    this.setState(
      {
        changedPermissions,
      },
      console.log(this.state.changedPermissions)
    );
  };

  removeFromChangedPermissions = (menuId) => {
    const { changedPermissions } = this.state;
    let removedFromChangedPermissions = changedPermissions.filter(
      (changedPermission) => changedPermission.menuId !== menuId
    );
    this.setState({
      changedPermissions: removedFromChangedPermissions,
    });
  };

  isEqualToBackupPermission = (permission, backupPermission) => {
    return (
      permission.isMenuActive !== backupPermission[0].isMenuActive ||
      permission.isAdd !== backupPermission[0].isAdd ||
      permission.isEdit !== backupPermission[0].isEdit ||
      permission.isDelete !== backupPermission[0].isDelete ||
      permission.isView !== backupPermission[0].isView ||
      permission.isFinance !== backupPermission[0].isFinance ||
      permission.isPersonal !== backupPermission[0].isPersonal
    );
  };

  handleChange = (e, record) => {
    const { roleId, companyId, permissionData } = this.state;
    let data = {
      isAdd: record.isAdd,
      isDelete: record.isDelete,
      isEdit: record.isEdit,
      isMenuActive: record.isMenuActive,
      isView: record.isView,
      isFinance: record.isFinance,
      isPersonal: record.isPersonal,
      menu: record.menu,
      menuId: record.menuId,
    };

    let backupPermission = this.state.backupPermissions.filter(
      (permission) => permission.menuId === data.menuId
    );

    let rowIndex = permissionData.findIndex(
      (permission) => permission.menuId === data.menuId
    );

    let filteredData = [];
    let changedPermissions = [...this.state.changedPermissions];
    switch (e.target.name) {
      case GlobalConstants.MAIN_MENU:
        if (!e.target.checked) {
          filteredData = permissionData.map((permission) => {
            if (permission.menuId === data.menuId) {
              permission.isMenuActive = e.target.checked;
              permission.isAdd = e.target.checked;
              permission.isEdit = e.target.checked;
              permission.isDelete = e.target.checked;
              permission.isFinance = e.target.checked;
              permission.isPersonal = e.target.checked;
              permission.isView = e.target.checked;
              this.handleChangesInPermissions(
                changedPermissions,
                permission,
                backupPermission
              );
            }
            return permission;
          });
        } else {
          filteredData = permissionData.map((permission) => {
            if (permission.menuId === data.menuId) {
              permission.isMenuActive = e.target.checked;
              permission.isView = e.target.checked;
              this.handleChangesInPermissions(
                changedPermissions,
                permission,
                backupPermission
              );
            }
            return permission;
          });
        }
        break;
      case GlobalConstants.ADD:
        filteredData = permissionData.map((permission) => {
          if (permission.menuId === data.menuId && permission.isMenuActive) {
            permission.isAdd = e.target.checked;
            if (!this.isAnyOneIsTrue(permission)) {
              permission.isMenuActive = e.target.checked;
            }
            this.handleChangesInPermissions(
              changedPermissions,
              permission,
              backupPermission
            );
          }
          return permission;
        });
        break;
      case GlobalConstants.EDIT:
        filteredData = permissionData.map((permission) => {
          if (permission.menuId === data.menuId && permission.isMenuActive) {
            permission.isEdit = e.target.checked;
            if (!this.isAnyOneIsTrue(permission)) {
              permission.isMenuActive = e.target.checked;
            }
            this.handleChangesInPermissions(
              changedPermissions,
              permission,
              backupPermission
            );
          }
          return permission;
        });
        break;
      case GlobalConstants.DELETE:
        filteredData = permissionData.map((permission) => {
          if (permission.menuId === data.menuId && permission.isMenuActive) {
            permission.isDelete = e.target.checked;
            if (!this.isAnyOneIsTrue(permission)) {
              permission.isMenuActive = e.target.checked;
            }
            this.handleChangesInPermissions(
              changedPermissions,
              permission,
              backupPermission
            );
          }
          return permission;
        });
        break;
      case GlobalConstants.VIEW:
        filteredData = permissionData.map((permission) => {
          if (permission.menuId === data.menuId && permission.isMenuActive) {
            permission.isView = e.target.checked;
            // if (!this.isAnyOneIsTrue(permission)) {
            permission.isMenuActive = e.target.checked;
            if (!e.target.checked) {
              permission.isAdd = e.target.checked;
              permission.isEdit = e.target.checked;
              permission.isDelete = e.target.checked;
              permission.isFinance = e.target.checked;
              permission.isPersonal = e.target.checked;
            }
            // }
            this.handleChangesInPermissions(
              changedPermissions,
              permission,
              backupPermission
            );
          }
          return permission;
        });
        break;
      case GlobalConstants.FINANCE:
        filteredData = permissionData.map((permission) => {
          if (permission.menuId === data.menuId && permission.isMenuActive) {
            permission.isFinance = e.target.checked;
            if (!this.isAnyOneIsTrue(permission)) {
              permission.isMenuActive = e.target.checked;
            }
            this.handleChangesInPermissions(
              changedPermissions,
              permission,
              backupPermission
            );
          }
          return permission;
        });
        break;
      case GlobalConstants.PERSONAL:
        filteredData = permissionData.map((permission) => {
          if (permission.menuId === data.menuId && permission.isMenuActive) {
            permission.isPersonal = e.target.checked;
            if (!this.isAnyOneIsTrue(permission)) {
              permission.isMenuActive = e.target.checked;
            }
            this.handleChangesInPermissions(
              changedPermissions,
              permission,
              backupPermission
            );
          }
          return permission;
        });
        break;
      default:
        break;
    }

    this.setState(
      {
        permissionData: filteredData,
      },
      () => console.log("changed permissions", this.state.permissionData)
    );
  };

  handleSubmit = () => {
    this.setState({
      isButtonLoading: true,
    });
    const { changedPermissions, roleId, companyId } = this.state;
    let data = changedPermissions;

    console.log("final data", data);

    this._roleServices
      .services(
        Services.RolesVariables.UPDATE_ROLE_PERMISSIONS,
        roleId,
        companyId,
        data
      )
      .then((res) => {
        this.setState(
          {
            isButtonLoading: false,
            changedPermissions: [],
          },
          () => {
            this.getRolePermissionData(roleId, companyId);
            showNotifications(
              Notifications.SUCCESS,
              Notifications.SUCCESS_CAP,
              `Roles ${Notifications.UPDATED_SUCCESSFULLY}`
            );
          }
        );
      })
      .catch((err) => {
        this.setState(
          {
            permissionData: this.state.backupPermissions,
            changedPermissions: [],
            isButtonLoading: false,
          },
          () =>
            showNotifications(
              Notifications.ERROR,
              Notifications.ERROR_CAP,
              `${Notifications.SOMETHING_WENT_WRONG}`
            )
        );
      });
  };

  modifyContent = () => {};

  handleRoleChange = (roleId) => {
    const { companyId } = this.props;
    this.setState({
      companyId,
      roleId,
    });
    this.getRolePermissionData(roleId, companyId);
  };

  getRolePermissionData = (roleId, companyId) => {
    this.setState({
      isDataFetched: false,
    });
    this._roleServices
      .services(Services.RolesVariables.GET_ROLE_PERMISSIONS, roleId, companyId)
      .then((res) => {
        this.setState(
          {
            permissionData: res.data.rolePermissions,
            backupPermissions: JSON.parse(
              JSON.stringify(res.data.rolePermissions)
            ),
            isDataFetched: true,
          },
          () => console.log("datasource...", this.state.permissionData)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetToDefaultPermission = () => {
    const { roleId, companyId } = this.state;
    let data = {
      companyId: companyId,
      roleId: roleId,
    };
    this.setState({ isResetInProgress: true });
    this._roleServices
      .services(
        Services.RolesVariables.RESET_ROLE_PERMISSIONS,
        roleId,
        companyId,
        data
      )
      .then((res) => {
        this.setState({ isResetInProgress: false }, () => {
          this.getRolePermissionData(roleId, companyId);
          showNotifications(
            Notifications.SUCCESS,
            Notifications.SUCCESS_CAP,
            `Roles resetted ${Notifications.SUCCESSFULLY}`
          );
        });
      })
      .catch((err) => {
        this.setState({ isResetInProgress: false }, () =>
          showNotifications(
            Notifications.ERROR,
            Notifications.ERROR_CAP,
            `${Notifications.SOMETHING_WENT_WRONG}`
          )
        );
        console.log("getRolePermissionData", err);
      });
  };

  chechForProduction = (menu) => {
    const list = ["Invoice", "Budgeter", "Accounting", "Transport"];
    return !list.includes(menu);
  };

  render() {
    const {
      dataSource,
      roles,
      isDataFetched,
      permissionData,
      isButtonLoading,
      isResetInProgress,
      backupPermissions,
      changedPermissions,
      roleId,
      isRolesFetched,
    } = this.state;
    console.log(
      "isEqual",
      _.isEqual(this.state.permissionData[0], this.state.backupPermissions[0])
    );
    const isStatesEqual = _.isEqual(
      this.state.permissionData,
      this.state.backupPermissions
    );
    const buttonText = isStatesEqual ? "No Changes to Save" : "Save Changes";
    const resetButtonText = isResetInProgress
      ? "Resetting your permissions..."
      : "Reset to Default Permissions";
    const columns = [
      {
        title: "#",
        dataIndex: "isMenuActive",
        key: "isMenuActive",
        align: "center",
        render: (text, record) => (
          <Checkbox
            checked={text}
            name={GlobalConstants.MAIN_MENU}
            onChange={(e) => this.handleChange(e, record)}
          />
        ),
      },
      {
        title: "MENUS",
        dataIndex: "menu",
        key: "menuName",
        align: "center",
      },
      {
        title: "ADD",
        key: "isAdd",
        align: "center",
        render: (record) =>
          !GlobalConstants.NO_ADD.includes(record.menu) &&
          record.isMenuActive && (
            <Checkbox
              checked={record.isAdd}
              name={GlobalConstants.ADD}
              onChange={(e) => this.handleChange(e, record)}
            />
          ),
      },
      {
        title: "EDIT",
        key: "isEdit",
        align: "center",
        render: (record) =>
          !GlobalConstants.NO_EDIT.includes(record.menu) &&
          record.isMenuActive && (
            <Checkbox
              checked={record.isEdit}
              name={GlobalConstants.EDIT}
              onChange={(e) => this.handleChange(e, record)}
            />
          ),
      },
      {
        title: "DELETE",
        key: "isDelete",
        align: "center",
        render: (record) =>
          !GlobalConstants.NO_DELETE.includes(record.menu) &&
          record.isMenuActive && (
            <Checkbox
              checked={record.isDelete}
              name={GlobalConstants.DELETE}
              onChange={(e) => this.handleChange(e, record)}
            />
          ),
      },
      {
        title: "VIEW",
        key: "isView",
        align: "center",
        render: (record) =>
          record.isMenuActive && (
            <Checkbox
              checked={record.isView}
              name={GlobalConstants.VIEW}
              onChange={(e) => this.handleChange(e, record)}
            />
          ),
      },
      {
        title: GlobalConstants.FINANCE,
        key: "isFinance",
        align: "center",
        render: (record) =>
          !GlobalConstants.NO_FINANCE.includes(record.menu) &&
          record.isMenuActive && (
            <Checkbox
              checked={record.isFinance}
              name={GlobalConstants.FINANCE}
              onChange={(e) => this.handleChange(e, record)}
            />
          ),
      },
      {
        title: GlobalConstants.PERSONAL,
        key: "isPersonal",
        align: "center",
        render: (record) =>
          !GlobalConstants.NO_PERSONEL.includes(record.menu) &&
          record.isMenuActive && (
            <Checkbox
              checked={record.isPersonal}
              name={GlobalConstants.PERSONAL}
              onChange={(e) => this.handleChange(e, record)}
            />
          ),
      },
    ];

    const SelectComponent = (
      <React.Fragment>
        <span
          style={{
            fontWeight: "bold",
            padding: "0 10px",
          }}
        >
          Select Role:
        </span>
        <Select
          // defaultValue="lucy"
          style={{ width: 140 }}
          onChange={this.handleRoleChange}
          placeholder="select role"
          loading={isRolesFetched}
        >
          {/* <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
          <Option value="Yiminghe">yiminghe</Option> */}
          {roles &&
            roles.map((role, i) => (
              <Option value={role.id}>{role.name}</Option>
            ))}
        </Select>
      </React.Fragment>
    );

    return (
      <Row>
        <Col span={24}>
          <Row
            type="flex"
            justify="center"
            style={{
              margin: "10px 0 10px 0",
            }}
          >
            <Col xs={12}>
              {SelectComponent}
              <Button
                loading={isResetInProgress}
                icon="sync"
                type="primary"
                onClick={() => this.resetToDefaultPermission()}
                style={{
                  marginLeft: 10,
                }}
                disabled={!roleId}
              >
                {resetButtonText}
              </Button>
            </Col>
          </Row>
          <Table
            // rowSelection={rowSelection}
            loading={!isDataFetched}
            columns={columns}
            dataSource={permissionData}
            pagination={false}
            locale={{
              emptyText: isDataFetched && <RecordNotFound />,
            }}
            pagination={false}
          />
        </Col>
        {permissionData.length !== 0 && (
          <>
            <Button
              disabled={isStatesEqual}
              type="primary"
              style={{
                marginTop: 10,
                backgroundColor: isStatesEqual
                  ? "#f5f5f5"
                  : ThemeColors.success,
                borderColor: isStatesEqual ? "" : ThemeColors.success,
              }}
              onClick={this.handleSubmit}
              loading={isButtonLoading}
            >
              {buttonText}
            </Button>
            <Button
              disabled={isStatesEqual}
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() =>
                this.setState({
                  permissionData: JSON.parse(JSON.stringify(backupPermissions)),
                  changedPermissions: [],
                })
              }
            >
              Reset
            </Button>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    companyId: user.currentUser && user.currentUser.companyId,
  };
};

export default connect(mapStateToProps, null)(Approvals);
