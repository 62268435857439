import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Icon,
  Select,
  Button,
  Checkbox,
  DatePicker,
} from "antd";
import FileCard from "components/shared/FileCardComponent";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Screens,
} from "constants/AppConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import GlobalService from "services/GlobalService";
import GeoCode from "components/shared/GeoCode";
import { OnboardServices } from "services";
import ImageViewer from "components/shared/ImageViewer";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import { setActiveTab } from "store/action";
import { connect } from "react-redux";
import LocationConfirmModel from "components/LocationConfirmModel";
import { numberOnly, amountOnly, checkFor } from "utilities/ValidationHelper";
import { GeoServices } from "components/shared/GeoCode/geoCall";
import debounce from "lodash/debounce";
import { Prompt } from "react-router-dom";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import LocationAvailabilityTable from "components/Location/LocationAvailabilityTable";
import Loader from "components/shared/Loader";
import Accounting from "services/AccountingServices";
import { handleError } from "utilities/ErrorHandler";
import moment from "moment";

const { TextArea } = Input;
class UpdateLocationPage extends Component {
  state = {
    visible: false,
    sceneLocation: null,
    actualLocation: null,
    locationType: null,
    contactPerson: null,
    contactNumber: null,
    shootAmount: null,
    shootAmountType: null,
    workAmount: null,
    workAmountType: null,
    addressLine: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
    status: null,
    imageId: null,
    isLoading: false,
    locationId: null,
    locationListDatas: [],
    statusListDatas: [],
    countryListDatas: [],
    paymentListDatasShoot: [],
    paymentListDatasSetWork: [],
    governmentApprovalStatusDatas: [],
    fileList: null,
    docFiles: null,
    imageFiles: null,
    status: null,
    sceneLocationName: null,
    isModalVisible: false,
    confirmedLocationName: null,
    sceneLocationId: null,
    phoneCodeList: null,
    phoneCode: "+91",
    isdirty: false,
    isLocationDetailFetched: false,
    shootDate: null,
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._onboardservices = new OnboardServices();
    this._locationService = new LocationTabService();
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
    this.account = new Accounting();
  }

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState(
      {
        docFiles,
        imageFiles,
      },
      () => console.log("files....", this.state.imageFiles)
    );
  };

  componentWillUnmount() {
    localStorage.setItem(GlobalConstants.REDIRECT_TO, Screens.LOCATION);
    this.props.setActiveTab("1");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === "Update") {
      if (nextProps.locationId) {
        if (nextProps.locationId !== this.state.locationId) {
          this.setState(
            {
              locationId: nextProps.locationId,
              actualLocation: nextProps.actualLocationName,
            },
            () => {
              this.getLocationDetails();
            }
          );
        }
      }
    }
  }

  componentDidMount() {
    this.getLocationData();
    this.getPaymentData();
    this.getGovtApprovalStatusData();
    this.getStatusData();
    this.getCountries();
    this.getPhoneCode();
    this.setState(
      {
        locationId: this.props.match.params.locationId,
        sceneLocationName: this.props.match.params.sceneLocationName,
        sceneLocationId: this.props.match.params.sceneLocationId,
      },
      () => {
        if (this.state.locationId) {
          this.getLocationDetails();
        }
      }
    );
  }

  getLocationDetails = () => {
    this.setState({
      isLocationDetailFetched: false,
    });
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_BY_ID,
        this.state.locationId,
        this.state.sceneLocationId ||
          localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID)
      )
      .then((res) => {
        this.setState(
          {
            addressLine: res.data.address && res.data.address.addressLine,
            city: res.data.address && res.data.address.city,
            country: res.data.address && res.data.address.country,
            state: res.data.address && res.data.address.state,
            zipCode: res.data.address && res.data.address.zipCode,
            phoneCode: res.data.phoneCode,
            contactNumber: res.data.contactNumber,
            contactPerson: res.data.contactPerson,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            locationType: res.data.locationType,
            shootAmount: res.data.shootAmount,
            governmentApprovalStatus: res.data.governmentApprovalStatusEnum,
            isNeedGovernmentApproval: res.data.isNeedGovernmentApproval,
            shootAmountType: res.data.shootPaymentType,
            status: res.data.stateEnum,
            workAmount: res.data.workAmount,
            workAmountType: res.data.workPaymentType,
            actualLocation: this.props.match.params.actualLocationName,
            fileList: res.data.locationImage,
            pan: res.data.pan,
            gst: res.data.gst,
            comment: res.data.comment,
            cautionDeposit: res.data.cautionDeposit,
            isLocationDetailFetched: true,
          },
          () => {
            this.splitBasedOnFileTypes(this.state.fileList);
          }
        );
        this.refs.image.scrollIntoView({ block: "end", behavior: "smooth" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteImage = (id) => {
    console.log("image files id", id);
    let fileDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );
    let imageDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;
    imageFiles.splice(imageDeleteIndex, 1);
    fileList.splice(fileDeleteIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    // this.props.handleModel("location", false, "entire");
  };

  handleCancel = (e) => {
    this.props.form.resetFields();
    // this.props.handleModel("location", false, "entire");
    this.setState({
      sceneLocation: null,
      actualLocation: null,
      locationType: null,
      contactPerson: null,
      contactNumber: null,
      shootAmount: null,
      shootAmountType: null,
      workAmount: null,
      workAmountType: null,
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      status: null,
      imageId: null,
      isLoading: false,
      locationId: null,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      status: null,
    });
  };

  disableUpdate = (value) => {
    this.setState({
      isLoading: value,
    });
  };

  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
    });
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState(
        {
          fileList: temp,
        },
        () => console.log("this.state.fileList", this.state.fileList)
      );
    }
  };

  // handleImage = url => {
  //   const { fileList } = this.state;

  //   this.setState({
  //     projectimageid: url
  //   });

  //   if (fileList) {
  //     let temp = [
  //       ...fileList,
  //       { fileData: url, fileType: GlobalConstants.IMAGE }
  //     ];
  //     this.setState({
  //       fileList: temp
  //     });
  //   } else {
  //     let temp = [{ fileData: url, fileType: GlobalConstants.IMAGE }];
  //     this.setState(
  //       {
  //         fileList: temp
  //       },
  //       () => {
  //         console.log(this.state.fileList);
  //       }
  //     );
  //   }
  // };

  getDetails = (city, state, country, zipCode) => {
    this.setState({
      city,
      state,
      country,
    });
    this.setState({
      zipCode,
      isdirty: true,
    });
  };

  getLocationData = () => {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "LOCATION_TYPE")
      .then((res) => {
        this.setState({ locationListDatas: res.data });
      });
  };

  getPaymentData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "LOCATION_PAYMENT_TYPE",
        true
      )
      .then((res) => {
        this.setState({ paymentListDatasShoot: res.data });
      });
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "LOCATION_PAYMENT_TYPE"
      )
      .then((res) => {
        this.setState({ paymentListDatasSetWork: res.data });
      });
  };

  getGovtApprovalStatusData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "GOVERNMENT_APPROVAL"
      )
      .then((res) => {
        this.setState({ governmentApprovalStatusDatas: res.data });
      });
  };

  getStatusData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({ statusListDatas: res.data });
      });
  };

  getCountries = () => {
    this._onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          countryListDatas: res.data.countries,
        });
      });
  };

  getPhoneCode = () => {
    this._globalService
      .globalService(Services.GlobalVariables.PHONE_CODE)
      .then((res) => {
        this.setState({
          phoneCodeList: res.data.countries,
        });
      });
  };

  handleStatus = (value) => {
    if (value !== GlobalConstants.CONFIRMED) {
      this.setState({ status: value });
    }

    let locationId = this.state.sceneLocationId || localStorage.LOCATION_ID;
    if (value === GlobalConstants.CONFIRMED) {
      this._locationService
        .locationTabService(
          Services.SceneOVerviewVariable.GET_CONFIRMED_LOCATION,
          null,
          locationId
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              confirmedLocationName:
                res.data.locations && res.data.locations[0].actualLocationName,
              isModalVisible: true,
              isdirty: true,
            });
          }
          if (res.status === 204) {
            this.setState({
              status: value,
            });
          }
        });
    }
  };
  submitData = () => {
    this.setState({
      status: GlobalConstants.CONFIRMED,
      isModalVisible: false,
    });
  };
  closeModal = () => {
    this.setState({
      // gettingStatus: GlobalConstants.TO_BE_CONFIRMED,
      isModalVisible: false,
    });
    this.props.history.goBack();
  };

  handleGeoChange = (e) => {
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          if (
            res.data.results[0].address_components[1] &&
            res.data.results[0].address_components[2] &&
            res.data.results[0].address_components[3]
          ) {
            this.setState({
              city: res.data.results[0].address_components[1].long_name,
              state: res.data.results[0].address_components[2].long_name,
              country: res.data.results[0].address_components[3].long_name,
              zipCode: res.data.results[0].address_components[0].long_name,
            });
          } else {
            showActionMessage(
              GlobalConstants.WARNING,
              `No data found in this pincode, Please try again`
            );
            // this.props.getDetails(null, null, null, e);
          }
        })
        .catch((err) => {
          console.log(err);
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
          // this.props.getDetails(null, null, null, e);
        });
    }
  };

  handleAvailability = (details) => {
    this.setState({
      locationAvailabilities: details,
    });
  };

  handleUpdate = (e) => {
    this.setState({
      isdirty: false,
    });
    e.preventDefault();
    const {
      addressLine,
      city,
      state,
      country,
      zipCode,
      contactNumber,
      contactPerson,
      imageId,
      locationType,
      sceneLocation,
      actualLocation,
      shootAmount,
      shootAmountType,
      workAmount,
      workAmountType,
      phoneCode,
      status,
      shootDate,
      isNeedGovernmentApproval,
      governmentApprovalStatus,
      pan,
      gst,
      comment,
      cautionDeposit,
    } = this.state;
    this.props.form.validateFields((err, values) => {
      console.clear();

      if (!err) {
        this.setState({ isLoading: true });
        const data = {
          address: {
            addressLine: addressLine,
            city: city,
            country: country,
            state: state,
            zipCode: zipCode,
          },
          contactNumber: contactNumber,
          phoneCode: phoneCode,
          contactPerson: contactPerson,
          locationImage: this.state.fileList,
          locationName: actualLocation,
          locationType: locationType,
          projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
          shootAmount: shootAmount,
          shootPaymentType: shootAmountType,
          shootDate,
          pan,
          gst,
          comment,
        };
        if (
          !checkFor(
            this.props.activePermisision,
            GlobalConstants.PERMISSION_PERSONAL
          )
        ) {
          delete data.address;
        }
        this.account
          .services(Services.AccountingVariables.SAVE_LOCATION, data)
          .then((res) => {
            showActionMessage(
              GlobalConstants.SUCCESS,
              GlobalConstants.CHANGES_HAVE_BEEN_SAVED
            );
            this.setState({
              isLoading: false,
            });
            this.props.history.goBack();
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    if (current) {
      return current > moment();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { latitude, longitude, activePermisision } = this.props;
    const {
      fileList,
      docFiles,
      imageFiles,
      locationId,
      isLocationDetailFetched,
    } = this.state;
    const { Option } = Select;

    const maxImageReached = imageFiles && imageFiles.length >= 4;
    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Row>
        <Prompt
          when={this.state.isdirty == true}
          message={(location) =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <Row span={24}>
          <Title
            hasBack
            {...this.props}
            handleChange={() => {
              localStorage.setItem(
                GlobalConstants.REDIRECT_TO,
                Screens.LOCATION
              );
              this.props.setActiveTab("1");
            }}
          >
            <TitleText>Add location</TitleText>
          </Title>
          <br />
        </Row>
        <Row style={{ padding: 10 }}>
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                >
                  <label ref="image" className="text-right font-600 m-0">
                    *Actual Location :
                  </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 12 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("actuallocation", {
                      initialValue: this.state.actualLocation,
                      rules: [
                        {
                          required: true,
                          message: "Enter Actual Location!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Actual Location"
                        onChange={(e) =>
                          this.setState({
                            actualLocation: e.target.value,
                            isdirty: true,
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
              style={
                {
                  //display: "flex",
                  //justifyContent: "center",
                  //marginBottom: 20
                }
              }
            >
              <Row className="mb-25 veritical-center">
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 11 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                >
                  <Row
                    style={{
                      position: "absolute",
                      right: "0%",
                      zIndex: 1,
                      display: "flex",
                    }}
                  >
                    {imageFiles && (
                      <ImageViewer
                        imageCount={1}
                        type="table"
                        canDelete={true}
                        ImagesData={imageFiles}
                        url={Services.LocationVariables.LOCATION_DELETE_DOC}
                        refreshData={() => this.getLocationDetails()}
                      />
                    )}
                    {/* {!maxImageReached && (
                    <MultipleSelectImage
                      maxLength={4}
                      handleImageIDS={this.handleImageIDS}
                    />
                  )} */}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 12 }}
                >
                  <label ref="image" className="text-right font-600 m-0">
                    *Shoot Date :
                  </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 12 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("shootDate", {
                      initialValue: this.state.shootAmount,
                      rules: [
                        {
                          required: true,
                          message: "Enter Shoot date!",
                        },
                      ],
                    })(
                      <DatePicker
                        onChange={(value, shootDate) =>
                          this.setState({ shootDate })
                        }
                        format={GlobalConstants.DD_MM_YYYY}
                        style={{ width: "100%" }}
                        disabledDate={this.disabledDate}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="font-600">
                    {!checkFor(
                      this.props.activePermisision,
                      GlobalConstants.PERMISSION_FINANCE
                    ) && "*"}
                    Location Type :
                  </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("locationtype", {
                      initialValue: this.state.locationType || undefined,
                      rules: [
                        !checkFor(
                          this.props.activePermisision,
                          GlobalConstants.PERMISSION_FINANCE
                        )
                          ? {
                              required: true,
                              message: "Please Select the Location Type",
                            }
                          : {
                              required: false,
                              message: "Please Select the Location Type",
                            },
                      ],
                    })(
                      <Select
                        onChange={(value) =>
                          this.setState({ locationType: value, isdirty: true })
                        }
                        placeholder="Select Location Type"
                        className="w-100"
                      >
                        {this.state.locationListDatas &&
                          (this.state.locationListDatas || []).map(
                            (option, i) => (
                              <Option value={option.value}>
                                {option.label}
                              </Option>
                            )
                          )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="text-right font-600 m-0">
                    Contact Person :
                  </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("contactperson", {
                      initialValue: this.state.contactPerson,
                      rules: [
                        {
                          required: false,
                          message: "Enter your contact person name!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Contact Person Name"
                        onChange={(e) =>
                          this.setState({
                            contactPerson: e.target.value,
                            isdirty: true,
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="text-right font-600 m-0">
                    Contact Number :
                  </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("contactnumber", {
                      initialValue: this.state.contactNumber,
                      rules: [
                        {
                          required: false,
                          message: "Enter your contact number!",
                        },
                        this.state.phoneCode === "+91" && {
                          pattern: ".{10,}",
                          message: "Enter 10 Numbers Only!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Contact Number"
                        style={{ width: "100%" }}
                        minLength={this.state.phoneCode === "+91" ? 10 : null}
                        maxLength={this.state.phoneCode === "+91" ? 10 : null}
                        onChange={(value) => {
                          this.setState({
                            contactNumber: numberOnly(value),
                            isdirty: true,
                          });
                        }}
                        addonBefore={
                          <Form.Item
                            className="payment-type"
                            style={{
                              margin: "0px",
                              height: "30px",
                              top: "-4px",
                            }}
                          >
                            {getFieldDecorator("phoneCode", {
                              initialValue: this.state.phoneCode,
                            })(
                              <Select
                                showSearch
                                placeholder="Country Code"
                                style={{ width: "100px" }}
                                optionFilterProp="children"
                                onChange={(value) => {
                                  this.setState({
                                    phoneCode: value,
                                    isdirty: true,
                                  });
                                }}
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(`+${input.toString()}`)
                                }
                              >
                                {this.state.phoneCodeList &&
                                  (this.state.phoneCodeList || []).map(
                                    (option, i) => (
                                      <Option value={option.phoneCode}>
                                        {option.phoneCode}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            )}
                          </Form.Item>
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="text-right font-600 m-0">PAN </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("pan", {
                      initialValue: this.state.pan,
                      // rules: [
                      //   {
                      //     required: false,
                      //     message: "Enter your contact person name!"
                      //   }
                      // ]
                    })(
                      <Input
                        placeholder="enter PAN"
                        onChange={(e) => this.setState({ pan: e.target.value })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Row className="mb-25 veritical-center">
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 9 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="text-right font-600 m-0">GST </label>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                  className="pl-15"
                >
                  <Form.Item className="m-0">
                    {getFieldDecorator("gst", {
                      initialValue: this.state.gst,
                      // rules: [
                      //   {
                      //     required: false,
                      //     message: "Enter your contact number!"
                      //   }
                      //   // {
                      //   //   pattern: ".{10,}",
                      //   //   message: "Enter 10 Numbers Only!"
                      //   // }
                      // ]
                    })(
                      <Input
                        placeholder="enter GST"
                        onChange={(e) => this.setState({ gst: e.target.value })}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {checkFor(
              activePermisision,
              GlobalConstants.PERMISSION_PERSONAL
            ) ? (
              <div>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row className="mb-25 veritical-center">
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 9 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="text-right font-600 m-0">
                        Zip Code
                      </label>
                    </Col>
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                      className="pl-15"
                    >
                      <Form.Item className="m-0">
                        {getFieldDecorator("zipcode", {
                          initialValue: this.state.zipCode,
                          rules: [
                            {
                              required: false,
                              message: "Enter your Zip Code!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="enter you pincode"
                            maxLength={6}
                            onChange={(e) =>
                              this.handleGeoChange(numberOnly(e))
                            }
                          />
                          // <GeoCode
                          //   defaultValue={this.state.zipCode}
                          //   getDetails={this.getDetails}
                          // />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row className="mb-25 veritical-center">
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 9 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="text-right font-600 m-0">Country</label>
                    </Col>
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                      className="pl-15"
                    >
                      <Form.Item className="m-0">
                        {getFieldDecorator("country", {
                          initialValue: this.state.country || undefined,
                          // rules: [{ required: false, message: "Enter your Country!" }]
                        })(
                          <Select
                            style={{ fontWeight: "bold", color: "black" }}
                            showSearch
                            placeholder="Country"
                            className="w-100"
                            onChange={(value) =>
                              this.setState({ country: value, isdirty: true })
                            }
                            optionFilterProp="children"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.state.countryListDatas &&
                              (this.state.countryListDatas || []).map(
                                (option, i) => (
                                  <Option value={option.name}>
                                    {option.name}
                                  </Option>
                                )
                              )}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row className="mb-25 veritical-center">
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 9 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="text-right font-600 m-0">State </label>
                    </Col>
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                      className="pl-15"
                    >
                      <Form.Item className="m-0">
                        {getFieldDecorator("state", {
                          initialValue: this.state.state,
                          rules: [
                            { required: false, message: "Enter your State!" },
                          ],
                        })(
                          <Input
                            placeholder="State"
                            onChange={(e) =>
                              this.setState({
                                state: e.target.value,
                                isdirty: true,
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row className="mb-25 veritical-center">
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 9 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="text-right font-600 m-0">City </label>
                    </Col>
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                      className="pl-15"
                    >
                      <Form.Item className="m-0">
                        {getFieldDecorator("city", {
                          initialValue: this.state.city,
                          rules: [
                            { required: false, message: "Enter your City!" },
                          ],
                        })(
                          <Input
                            placeholder="City"
                            onChange={(e) =>
                              this.setState({
                                city: e.target.value,
                                isdirty: true,
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <Row className="mb-25 veritical-center">
                    <Col
                      style={{ textAlign: "right" }}
                      xl={{ span: 9 }}
                      lg={{ span: 10 }}
                      md={{ span: 12 }}
                      xs={{ span: 21 }}
                    >
                      <label className="text-right font-600 m-0">Address</label>
                    </Col>
                    <Col
                      xl={{ span: 12 }}
                      lg={{ span: 12 }}
                      md={{ span: 10 }}
                      xs={{ span: 22 }}
                      className="pl-15"
                    >
                      <Form.Item className="m-0">
                        {getFieldDecorator("address", {
                          initialValue: this.state.addressLine,
                          rules: [
                            { required: false, message: "Enter your Address!" },
                          ],
                        })(
                          <Input
                            placeholder="Address"
                            onChange={(e) =>
                              this.setState({
                                addressLine: e.target.value,
                                isdirty: true,
                              })
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </div>
            ) : null}
            {/* <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row className="mb-25 veritical-center">
              <Col
                style={{ textAlign: "right" }}
                xl={{ span: 9 }}
                lg={{ span: 10 }}
                md={{ span: 12 }}
                xs={{ span: 21 }}
              >
                <label className="text-right font-600 m-0">Status </label>
              </Col>
              <Col
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 10 }}
                xs={{ span: 22 }}
                className="pl-15"
              >
                <Form.Item className="m-0">
                  {getFieldDecorator("locationstatus", {
                    initialValue:
                      this.state.status || GlobalConstants.TO_BE_CONFIRMED,
                    rules: [
                      {
                        required: false,
                        message: "Enter your Actual Location!"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Status"
                      className="w-100"
                      // onChange={value =>
                      //   this.setState({ status: value }, () => {
                      //     console.log("status.....", this.state.status);
                      //   })
                      // }
                      onChange={this.handleStatus}
                    >
                      {this.state.statusListDatas &&
                        (this.state.statusListDatas || []).map((option, i) => (
                          <Option value={option.value}>{option.label}</Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col> */}

            <React.Fragment>
              {checkFor(
                activePermisision,
                GlobalConstants.PERMISSION_FINANCE
              ) ? (
                <div>
                  <Col
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row className="mb-25 veritical-center">
                      <Col
                        style={{ textAlign: "right" }}
                        xl={{ span: 9 }}
                        lg={{ span: 10 }}
                        md={{ span: 12 }}
                        xs={{ span: 21 }}
                      >
                        <label className="text-right font-600 m-0">
                          Rent/Hire (Shoot Day) :
                        </label>
                      </Col>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 10 }}
                        xs={{ span: 22 }}
                        className="pl-15"
                      >
                        <Form.Item className="m-0">
                          {getFieldDecorator("shootday", {
                            initialValue: this.state.shootAmount,
                            rules: [
                              {
                                required: false,
                                message: "Enter shootday!",
                              },
                              // {
                              //   pattern: new RegExp("^[0-9]*$"),
                              //   message: "Enter Numbers Only!"
                              // }
                            ],
                          })(
                            <Input
                              prefix=" ₹ "
                              placeholder="Enter Amount"
                              onChange={(e) =>
                                this.setState({
                                  shootAmount: amountOnly(e),
                                  isdirty: true,
                                })
                              }
                              addonAfter={
                                <Form.Item
                                  className="payment-type"
                                  style={{
                                    margin: "0px",
                                    height: "30px",
                                    top: "-4px",
                                  }}
                                >
                                  {getFieldDecorator("shootAmountType", {
                                    initialValue:
                                      this.state.shootAmountType || undefined,
                                  })(
                                    <Select
                                      placeholder="Payment Type"
                                      style={{ width: "125px" }}
                                      onChange={(value) =>
                                        this.setState({
                                          shootAmountType: value,
                                          isdirty: true,
                                        })
                                      }
                                    >
                                      {this.state.paymentListDatasShoot &&
                                        (
                                          this.state.paymentListDatasShoot || []
                                        ).map((option, i) => (
                                          <Option value={option.value}>
                                            {option.label}
                                          </Option>
                                        ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <Row className="mb-25 veritical-center">
                      <Col
                        style={{ textAlign: "right" }}
                        xl={{ span: 9 }}
                        lg={{ span: 10 }}
                        md={{ span: 12 }}
                        xs={{ span: 21 }}
                      >
                        <label className="text-right font-600 m-0">
                          *Rent/Hire (Set Work Day) :
                        </label>
                      </Col>
                      <Col
                        xl={{ span: 12 }}
                        lg={{ span: 12 }}
                        md={{ span: 10 }}
                        xs={{ span: 22 }}
                        className="pl-15"
                      >
                        <Form.Item className="m-0">
                          {getFieldDecorator("setWorkDay", {
                            initialValue: this.state.workAmount,
                            rules: [
                              {
                                required: false,
                                message: "Enter set work day!"
                              }
                              // {
                              //   pattern: new RegExp("^[0-9]*$"),
                              //   message: "Enter Numbers Only!"
                              // }
                            ]
                          })(
                            <Input
                              prefix=" ₹ "
                              placeholder="Enter Amount"
                              onChange={e =>
                                this.setState({
                                  workAmount: amountOnly(e),
                                  isdirty: true
                                })
                              }
                              addonAfter={
                                <Form.Item
                                  className="payment-type"
                                  style={{
                                    margin: "0px",
                                    height: "30px",
                                    top: "-4px"
                                  }}
                                >
                                  {getFieldDecorator("workAmountType", {
                                    initialValue:
                                      this.state.workAmountType || undefined
                                  })(
                                    <Select
                                      placeholder="Payment Type"
                                      onChange={value =>
                                        this.setState({
                                          workAmountType: value,
                                          isdirty: true
                                        })
                                      }
                                      style={{ width: "125px" }}
                                    >
                                      {this.state.paymentListDatasSetWork &&
                                        (
                                          this.state.paymentListDatasSetWork ||
                                          []
                                        ).map((option, i) => (
                                          <Option value={option.value}>
                                            {option.label}
                                          </Option>
                                        ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              }
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col> */}
                </div>
              ) : null}

              {/* <Col xl={{ span: 12 }}>
                  <label className="font-600">Geo Tag/Google Location </label>
                  <Row gutter={0}>
                    <Col span={22}>
                      <Form.Item>
                        {getFieldDecorator("geo", {
                          initialValue: `${latitude},${longitude}`
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <div
                        onClick={() => {
                          this.props.handleModel("location", false);
                        }}
                        style={{
                          border: "1px solid #e3e3e3",
                          backgroundColor: "#2da01c",
                          color: "#fff",
                          marginLeft: "-10px",
                          height: "34px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          marginTop: "3px"
                        }}
                      >
                        <Icon type="environment" />
                      </div>
                    </Col>
                  </Row>
                </Col> */}

              {/* <Col xl={{ span: 12 }}>
                <label className="font-600">Address </label>
                <Form.Item>
                  {getFieldDecorator("locationtaddress", {
                    rules: [
                      {
                        required: false,
                        message: "Enter your Location Address!"
                      }
                    ]
                  })(<TextArea placeholder="Address" rows={4} />)}
                </Form.Item>
              </Col> */}
            </React.Fragment>

            {/* <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row className="mb-25 veritical-center">
              <Col
                style={{ textAlign: "right" }}
                xl={{ span: 9 }}
                lg={{ span: 10 }}
                md={{ span: 12 }}
                xs={{ span: 21 }}
              >
                <label className="text-right font-600 m-0">
                  Government Approval:
                </label>
              </Col>

              <Col
                xl={{ span: 2 }}
                lg={{ span: 3 }}
                md={{ span: 3 }}
                xs={{ span: 5 }}
                className="pl-15"
              >
                <Form.Item className="m-0" style={{ marginRight: 10 }}>
                  {getFieldDecorator("isNeedGovernmentApproval", {
                    initialValue: this.state.isNeedGovernmentApproval
                  })(
                    <Checkbox
                      checked={this.state.isNeedGovernmentApproval}
                      onChange={value => {
                        this.setState({
                          isNeedGovernmentApproval:
                            value.target.checked || false
                        });
                        console.log("this.state", this.state);
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 10 }}
                xs={{ span: 22 }}
              >
                {this.state.isNeedGovernmentApproval ? (
                  <Form.Item
                    className="payment-type"
                    style={{
                      margin: "0px",
                      height: "30px",
                      top: "-4px"
                    }}
                  >
                    {getFieldDecorator("governmentApprovalStatus", {
                      initialValue:
                        this.state.governmentApprovalStatus || undefined
                    })(
                      <Select
                        placeholder="Government approval status"
                        onChange={value =>
                          this.setState({
                            governmentApprovalStatus: value,
                            isdirty: true
                          })
                        }
                        style={{ width: "232px" }}
                      >
                        {this.state.governmentApprovalStatusDatas &&
                          (
                            this.state.governmentApprovalStatusDatas || []
                          ).map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col> */}
            {/* <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row className="mb-25 veritical-center">
              <Col
                style={{ textAlign: "right" }}
                xl={{ span: 9 }}
                lg={{ span: 10 }}
                md={{ span: 12 }}
                xs={{ span: 21 }}
              >
                <label className="text-right font-600 m-0">
                  Caution deposit :
                </label>
              </Col>
              <Col
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 10 }}
                xs={{ span: 22 }}
                className="pl-15"
              >
                <Form.Item className="m-0">
                  {getFieldDecorator("cautionDeposit", {
                    initialValue: this.state.cautionDeposit,
                    rules: [{ required: false, message: "" }]
                  })(
                    <Input
                      // placeholder="enter comment for the location"
                      // autoSize={{ minRows: 6, maxRows: 5 }}
                      onChange={e =>
                        this.setState({ cautionDeposit: numberOnly(e) })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col> */}

            <Col xl={12}>
              <Col
                style={{ textAlign: "right" }}
                xl={{ span: 9 }}
                lg={{ span: 10 }}
                md={{ span: 12 }}
                xs={{ span: 21 }}
              >
                <label className="font-600">Notes </label>
              </Col>

              <Col
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 10 }}
                xs={{ span: 22 }}
              >
                <Form.Item className="payment-type pl-15">
                  {getFieldDecorator("comment", {
                    // rules: [
                    //   {
                    //     required: false,
                    //     message: `GovernmentApproval Status is required.`
                    //   }
                    // ],
                    initialValue: this.state.comment || undefined,
                  })(
                    <TextArea
                      placeholder="enter comment for the location"
                      // autoSize={{ minRows: 6, maxRows: 5 }}
                      rows={4}
                      onChange={(e) =>
                        this.setState({ comment: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            </Col>
            {/* {this.state.status === GlobalConstants.CONFIRMED && (
            <Col xl={24}>
              <Col xl={{ span: 22, offset: 1 }}>
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 4 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="font-600">Location Availability </label>
                </Col>
                <Col
                  xl={{ span: 10 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                >
                  <Form.Item className="payment-type pl-15">
                    <LocationAvailabilityTable
                      getFromChild={this.handleAvailability}
                      locationId={this.props.match.params.locationId}
                      sceneLocationId={this.props.match.params.sceneLocationId}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Col>
          )} */}

            <Col xl={24}>
              <Col xl={{ span: 22, offset: 2 }}>
                <Col
                  style={{ textAlign: "right" }}
                  xl={{ span: 3 }}
                  lg={{ span: 10 }}
                  md={{ span: 12 }}
                  xs={{ span: 21 }}
                >
                  <label className="font-600">Images </label>
                </Col>

                <Col
                  xl={{ span: 21 }}
                  lg={{ span: 12 }}
                  md={{ span: 10 }}
                  xs={{ span: 22 }}
                  className="pl-15"
                >
                  <Form.Item>
                    {getFieldDecorator("projectimageid", {
                      initialValue: this.state.projectimageid,
                    })(
                      <React.Fragment>
                        <div className="fileCardContainer">
                          {imageFiles &&
                            imageFiles.map((file, i) => (
                              <FileCard
                                file={file}
                                deleteImage={this.deleteImage}
                                pageType={
                                  Services.LocationVariables.LOCATION_DELETE_DOC
                                }
                              />
                            ))}
                        </div>
                        {!this.state.isLoading && (
                          <MultipleSelectImage
                            maxLength={4}
                            handleImageIDS={this.handleImageIDS}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Form.Item>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row
            gutter={12}
            type="flex"
            justify="end"
            style={{ marginTop: "20px" }}
          >
            <Col>
              <Button
                onClick={() => {
                  this.props.history.goBack();
                  localStorage.setItem(
                    GlobalConstants.REDIRECT_TO,
                    Screens.LOCATION
                  );
                  this.props.setActiveTab("1");
                }}
              >
                Cancel
              </Button>
            </Col>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) ? (
              <Col>
                <Button
                  type="primary"
                  loading={this.state.isLoading}
                  onClick={this.handleUpdate}
                >
                  Save Location
                </Button>
              </Col>
            ) : null}
          </Row>
        </Row>
      </Row>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const AccAddLocation = Form.create()(UpdateLocationPage);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Location", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccAddLocation);
