import React, { Component } from "react";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Divider,
  Tooltip,
  Icon,
} from "antd";
import moment from "moment";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  GlobalConstants,
} from "constants/AppConstants";
import { numberOnly, checkFor } from "utilities/ValidationHelper";
import ActorsServices from "services/ActorsServices";
import "./PaymentTermsTable.css";
import { showNotifications } from "components/shared/NotificationComponent";
import { promised } from "q";
import "./PaymentTermsTable.css";
import * as _ from "lodash";
import CrewService from "services/CrewServices";
import BudjetService from "services/BudjetService";
import RecordNotFound from "components/shared/RecordNotFound";
import { handleError } from "utilities/ErrorHandler";

const dateFormat = "YYYY-MM-DD";

// const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              // {
              //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
              //   message: "Enter numbers onlys"
              // }
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
              type="number"
              onFocus={this.setActiveRecord}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            onFocus={this.setActiveRecord}
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };
  setActiveRecord = (e) => {
    const { record } = this.props;
    this.props.setActiveRecord({ ...record });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class PaymentTermsTable extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.scriptservice = new ScriptService();
    this._actorsService = new ActorsServices();
    this._crewService = new CrewService();
    this._budjetService = new BudjetService();

    this.state = {
      dataSource: [],
      totalRecords: [],
      count: 0,
      page: null,
      actorId: null,
      rumen: null,
      dataAmount: null,
      pendingPayment: 0,
      isFromCrew: props.isFromCrew,
      isFromVendor: props.isFromVendor,
      vendorData: props.vendorData,
    };
  }

  componentDidMount() {
    if (!this.state.isFromCrew && !this.state.isFromVendor) {
      this.setState({
        rumen: this.props.rumeneration,
      });
      if (this.props.actorId) {
        this.setState(
          {
            actorId: this.props.actorId,
          },
          () => this.getActorDetails()
        );
      }
    } else if (this.state.isFromCrew) {
      this.setState(
        {
          userId: this.props.userId,
          membershipId: this.props.actorId,
          rumen: this.props.rumeneration,
        },
        () => this.getCrewMemberDetails()
      );
    } else if (this.state.isFromVendor) {
      this.setState(
        {
          rumen: this.props.rumeneration,
        },
        () => this.getVendorDetails()
      );
    }
    this.checkForEmpty(this.state.dataSource);
  }
  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        rumen: nextProps.rumeneration,
      }
      // () => this.getActorDetails()
    );
  }
  disabledDate = (current) => {
    console.log(current, "current");
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  getVendorDetails() {
    if (this.props.vendorId) {
      this._budjetService
        .budjetService(
          Services.BudjetVariables.GET_VENDOR_BY_ID,
          "",
          this.props.vendorId
        )
        .then((res) => {
          let dataSource = [];
          res.data.payments &&
            res.data.payments.map((datum, i) => {
              var a;
              new Promise((resolve) => {
                a =
                  datum && datum.paymentDate
                    ? moment(datum.paymentDate).format("DD-MM-YYYY")
                    : "";
                resolve();
              }).then(() => {
                datum.paymentDate = a;
                dataSource.push({ ...datum, key: this.state.count });
                this.setState({
                  dataSource,
                  count: this.state.count + 1,
                });
              });
            });
        });
    }
  }

  getCrewMemberDetails() {
    this._crewService
      .crewService(
        Services.CrewVariables.GET_CREW_MEMBER,
        "",
        this.state.userId
      )
      .then((res) => {
        let dataSource = [];
        res.data.projectMemberPayments &&
          res.data.projectMemberPayments.map((datum, i) => {
            var a;
            new Promise((resolve) => {
              a =
                datum && datum.paymentDate
                  ? moment(datum.paymentDate).format("DD-MM-YYYY")
                  : "";
              resolve();
            }).then(() => {
              datum.paymentDate = a;
              dataSource.push({ ...datum, key: this.state.count });
              this.setState(
                {
                  dataSource,
                  count: this.state.count + 1,
                },
                () => {
                  console.log("data payment table...", this.state.dataSource);
                }
              );
            });
          });
      });
  }

  getActorDetails = () => {
    this._actorsService
      .actorsServices(Services.ActorVariables.GET_ACTOR_ID, this.state.actorId)
      .then((res) => {
        let dataSource = [];
        res.data.actorPayment.map((datum, i) => {
          var a;
          new Promise((resolve) => {
            a =
              datum && datum.paymentDate
                ? moment(datum.paymentDate).format("DD-MM-YYYY")
                : "";
            resolve();
          }).then(() => {
            datum.paymentDate = a;
            dataSource.push({ ...datum, key: this.state.count });
            this.setState(
              {
                dataSource,
                totalRecords: dataSource,
                count: this.state.count + 1,
              },
              () => {
                console.log("data payment table...", this.state.dataSource);
              }
            );
          });
        });
        this.setState({
          pendingPayment: res.data.projectActor.pendingPayment,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = (record) => {
    let actorId = this.state.actorId;
    let paymentId = null;
    let projectVendorDetails = this.state.vendorData;
    let data = {
      actorId: actorId,
      amount: record.amount,
      paymentDate: record.paymentDate,
      remarks: record.remarks,
    };

    if (this.props.isFromCrew) {
      delete data.actorId;
      data.projectMemberId = this.state.membershipId;
    } else if (this.props.isFromVendor) {
      delete data.actorId;
      data.paymentId = record.paymentId;
      projectVendorDetails = {
        ...projectVendorDetails,
        projectVendorPayment: [data],
      };
    }
    if (actorId || this.state.membershipId || this.props.vendorId) {
      if (
        this.props.rumeneration !== undefined &&
        !this.props.isFromCrew &&
        !this.props.isFromVendor
      ) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.POST_PAYMENT_DETAILS,
            actorId,
            paymentId,
            data
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Saved Successfully!!"
            );
            this.getActorDetails();
          })
          .catch((err) => {
            this.props.getPermissions && this.props.getPermissions();
            this.getActorDetails();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      } else if (this.props.isFromCrew) {
        this._crewService
          .crewService(
            Services.CrewVariables.CREATE_CREW_PAYMENT,
            data,
            this.state.membershipId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Saved Successfully!!"
            );
            // this.getArtSetData();
            this.getCrewMemberDetails();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      } else if (this.props.isFromVendor) {
        this._budjetService
          .budjetService(
            Services.BudjetVariables.CREATE_VENDOR_PAYMENT,
            projectVendorDetails,
            this.props.vendorId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Saved Successfully!!"
            );
            this.getVendorDetails();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "Something Went Wrong"
            );
          });
      }
    }
  };

  handleUpdate = (record) => {
    let actorId = this.state.actorId;
    let paymentId = record.paymentId;
    let projectVendorDetails = this.state.vendorData;
    let data = {
      actorId: this.state.actorId,
      amount: record.amount,
      paymentDate: record.paymentDate,
      remarks: record.remarks,
    };
    if (this.props.isFromCrew) {
      delete data.actorId;
      data.projectMemberId = this.state.membershipId;
    } else if (this.props.isFromVendor) {
      delete data.actorId;
      data.paymentId = record.paymentId;
      projectVendorDetails = {
        ...projectVendorDetails,
        projectVendorPayment: [data],
      };
    }

    if (actorId || this.state.membershipId || this.props.vendorId) {
      console.log("renumeration..", this.props.rumeneration);
      if (
        this.props.rumeneration !== undefined &&
        !this.props.isFromCrew &&
        !this.props.isFromVendor
      ) {
        this._actorsService
          .actorsServices(
            Services.ActorVariables.PUT_PAYMENT_DETAILS,
            actorId,
            paymentId,
            data
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Updated Successfully!!"
            );
            this.getActorDetails();
          })
          .catch((err) => {
            this.props.getPermissions();
            this.getActorDetails();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      } else if (this.props.isFromCrew) {
        this._crewService
          .crewService(
            Services.CrewVariables.UPDATE_CREW_PAYMENT,
            data,
            this.state.membershipId,
            record.paymentId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Updated Successfully!!"
            );
            this.getCrewMemberDetails();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      } else if (this.props.isFromVendor) {
        this._budjetService
          .budjetService(
            Services.BudjetVariables.CREATE_VENDOR_PAYMENT,
            projectVendorDetails,
            this.props.vendorId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Updated Successfully!!"
            );
            this.getVendorDetails();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    }
  };

  handleDelete = (key, paymentId) => {
    const dataSource = [...this.state.dataSource];
    console.log("payment delete function in payment", paymentId);
    if (paymentId) {
      if (!this.props.isFromCrew && !this.props.isFromVendor) {
        this.scriptservice
          .scriptService(
            Services.AddActor.DEL_PAYMENT,
            paymentId,
            console.log("hai iam there", paymentId)
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Deleted",
              "Deleted Successfully!!"
            );
            this.getActorDetails();
            this.setState(
              {
                dataSource: dataSource.filter((item) => item.key !== key),
              },
              () => {
                this.props.getFromChild(this.state.dataSource);
                this.checkForEmpty(this.state.dataSource);
              }
            );
          })
          .catch((err) => {
            this.props.getPermissions && this.props.getPermissions();
          });
      } else if (this.props.isFromCrew) {
        this._crewService
          .crewService(
            Services.CrewVariables.DELETE_CREW_PAYMENT,
            "",
            paymentId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Deleted",
              "Deleted Successfully!!"
            );
            this.getCrewMemberDetails();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      } else if (this.props.isFromVendor) {
        let data = {
          vendorPaymentId: paymentId,
        };
        this._budjetService
          .budjetService(
            Services.BudjetVariables.DELETE_VENDOR_PAYMENT,
            data,
            this.props.vendorId
          )
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Deleted",
              "Deleted Successfully!!"
            );
            this.getVendorDetails();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      amount: "",
      paymentDate: moment().format(dateFormat),
      remarks: "",
    };
    this.setState(
      {
        dataSource: [...dataSource, newData],
      },
      () => console.log("this.state.count", this.state.count)
    );
  };

  onChange(value, dateString, record) {
    this.handleSave(record, dateString);
  }

  handleSave = (row, value = null) => {
    const { dataSource } = this.state;

    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    if (value) {
      let modifyItem = newData[index];
      modifyItem.paymentDate = value;
    }
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      let dummy = 0;
      this.state.dataSource.forEach((dateData, i) => {
        if (dateData.amount) {
          dummy = dummy + parseFloat(dateData.amount);
        }
      });
      this.props.getFromChild(this.state.dataSource);
      if (parseInt(this.state.rumen) >= parseInt(dummy)) {
        if (!row.paymentId) {
          if (row.amount && row.paymentDate && row.remarks) {
            this.handleSubmit(row);
          } else {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              "All Fields Are Mandatory"
            );
          }
        }
        if (row.paymentId) {
          if (row.amount && row.paymentDate && row.remarks) {
            if (!_.isEqual(row, this.state.activeRecord)) {
              this.handleUpdate(row);
            }
          }
        }
      } else {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Amount Should Be Equal To Or Lesser Than Rumeneration"
        );
      }
    });
  };

  setActiveRecord = (record) => {
    this.setState(
      {
        activeRecord: record,
      },
      () => console.log("activeRecord", this.state.activeRecord)
    );
  };

  handlePendingAmount = () => {
    let pendingAmount = 0;
    let totalPaidTermAmount = 0.0;
    let { dataSource, pendingPayment } = this.state;
    let { isFromCrew, isFromVendor, rumeneration } = this.props;
    if (!isFromCrew && !isFromVendor) {
      if (pendingPayment > 0.0) {
        pendingAmount = pendingPayment;
        return (
          <span style={{ color: "red" }}>
            Please Update the payment schedule for the Balance Amount of ₹{" "}
            {pendingAmount}
          </span>
        );
      } else if (rumeneration !== undefined && rumeneration > 0.0) {
        dataSource.map((data) => {
          if (data.paymentId) {
            totalPaidTermAmount = parseFloat(data.amount) + totalPaidTermAmount;
          } else if (data.remarks) {
            totalPaidTermAmount = parseFloat(data.amount) + totalPaidTermAmount;
          }
        });
        pendingAmount = rumeneration - totalPaidTermAmount;
        return pendingAmount !== 0 ? (
          <span style={{ color: "red" }}>
            Please Update the payment schedule for the Balance Amount of ₹{" "}
            {pendingAmount.toFixed(2)}
          </span>
        ) : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    const { dataSource, totalRecords } = this.state;
    // const hasNewRow = dataSource.length !== totalRecords.length + 1;
    let hasNewRow = false;
    let totalAmount = 0;
    let rumeneration1 = this.props.rumeneration;

    if (rumeneration1 < totalAmount) {
      alert("excess");
      hasNewRow = true;
    }
    dataSource.map((d) => (totalAmount = parseInt(d.amount) + totalAmount));
    // console.log("Total", this.props)
    // this.props.setTotal(totalAmount || 0)

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    let columns = [
      {
        title: "Amount",
        dataIndex: "amount",
        width: "10%",
        editable: true,
        align: "center",
      },
      {
        title: "Payment Date",
        dataIndex: "paymentDate",
        width: "15%",
        align: "center",
        render: (text, record) => (
          <DatePicker
            disabled={
              !checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              ) && record.paymentId
            }
            defaultValue={text ? moment(text, dateFormat) : ""}
            onChange={(value, dateString) =>
              this.onChange(value, dateString, record)
            }
            format={dateFormat}
            disabledDate={this.disabledDate}
          />
        ),
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        editable: true,
        width: "30%",
        align: "center",
      },
    ];
    if (checkFor(this.props.activePermisision, "Delete")) {
      columns.push({
        title: "Action",
        dataIndex: "operation",
        align: "center",
        width: "5%",
        className: "operation",
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Row type="flex" justify="center" align="middle">
              <Col xl={11}>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() =>
                    this.handleDelete(record.key, record.paymentId)
                  }
                >
                  <Tooltip
                    title={GlobalConstants.DEL}
                    placement={GlobalConstants.RIGHT}
                  >
                    <Button icon="delete" type="link" />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          ) : null,
      });
    }
    const columnsData = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record, i) => {
          const cellStatus = record.key === i && record.paymentId;
          return {
            record,
            inputType: col.dataIndex === "amount" ? "number" : "text",
            editable:
              col.editable !==
              checkFor(
                this.props.activePermisision,
                GlobalConstants.PERMISSION_EDIT
              )
                ? record.paymentId
                  ? false
                  : true
                : true,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            setActiveRecord: this.setActiveRecord,
          };
        },
      };
    });
    return (
      <div>
        <Row type="flex" justify="center" align="middle">
          <Col xl={12}>{this.handlePendingAmount()}</Col>
          {checkFor(this.props.activePermisision, "Add") ? (
            <Col xl={12}>
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginBottom: 16, float: "right" }}
                disabled={rumeneration1 <= totalAmount}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Entry
              </Button>
            </Col>
          ) : null}
          <Col xl={24}>
            <Table
              components={components}
              className="editing-table"
              rowClassName={() => "editable-row"}
              bordered
              dataSource={dataSource}
              columns={columnsData}
              pagination={false}
              locale={{
                emptyText: <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default PaymentTermsTable;
