import React, { Component, Fragment } from "react";
import GlobalService from "services/GlobalService";
import { Button } from "antd";
import { Services, GlobalConstants } from "constants/AppConstants";

const MIME_TYPES = {
  "audio/aac": ".aac",
  "application/x-abiword": ".abw",
  "application/x-freearc": ".arc",
  "video/x-msvideo": ".avi",
  "application/vnd.amazon.ebook": ".azw",
  "application/octet-stream": ".bin",
  "image/bmp": ".bmp",
  "application/x-bzip": ".bz",
  "application/x-bzip2": ".bz2",
  "application/x-csh": ".csh",
  "text/css": ".css",
  "text/csv": ".csv",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "application/vnd.ms-fontobject": ".eot",
  "application/epub+zip": ".epub",
  "application/gzip": ".gz",
  "image/gif": ".gif",
  "text/html": ".html",
  "image/vnd.microsoft.icon": ".ico",
  "text/calendar": ".ics",
  "application/java-archive": ".jar",
  "image/jpeg": ".jpeg",
  "text/javascript": ".js",
  "application/json": ".json",
  "application/ld+json": ".jsonld",
  "audio/midi audio/x-midi": ".midi",
  "text/javascript": ".mjs",
  "audio/mpeg": ".mp3",
  "video/mpeg": ".mpeg",
  "application/vnd.apple.installer+xml": ".mpkg",
  "application/vnd.oasis.opendocument.presentation": ".odp",
  "application/vnd.oasis.opendocument.spreadsheet": ".ods",
  "application/vnd.oasis.opendocument.text": ".odt",
  "audio/ogg": ".oga",
  "video/ogg": ".ogv",
  "application/ogg": ".ogx",
  "audio/opus": ".opus",
  "font/otf": ".otf",
  "image/png": ".png",
  "application/pdf": ".pdf",
  "application/php": ".php",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    ".pptx",
  "application/vnd.rar": ".rar",
  "application/rtf": ".rtf",
  "application/x-sh": ".sh",
  "image/svg+xml": ".svg",
  "application/x-shockwave-flash": ".swf",
  "application/x-tar": ".tar",
  "image/tiff": ".tiff",
  "video/mp2t": ".ts",
  "font/ttf": ".ttf",
  "text/plain": ".txt",
  "application/vnd.visio": ".vsd",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "video/webm": ".webm",
  "image/webp": ".webp",
  "font/woff": ".woff",
  "font/woff2": ".woff2",
  "application/xhtml+xml": ".xhtml",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "text/xml ": ".xml",
  "application/vnd.mozilla.xul+xml": ".xul",
  "application/zip": ".zip",
  "video/3gpp": ".3gp",
  "video/3gpp2": ".3g2",
  "application/x-7z-compressed": ".7z",
};

export default class VoucherDownloadButton extends Component {
  constructor(props) {
    super(props);
    this.api = new GlobalService();
    this.state = {
      IsDownloading: false,
    };
  }

  voucherDownload = (vocherNumber) => {
    this.setState({
      IsDownloading: true,
    });
    this.api
      .globalService(Services.GlobalVariables.DOWNLOAD_VOUCHER, {
        // category: GlobalConstants.PRODUCTION_CAPS,
        vocherNumber,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log("blob type", response.data.type);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${vocherNumber}${MIME_TYPES[response.data.type]}`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState(
          {
            IsDownloading: false,
          },
          () => this.playAudio()
        );
      });
  };

  schedulerDownload = () => {
    this.setState({
      IsDownloading: true,
    });
    this.api
      .globalService(Services.GlobalVariables.DOWNLOAD_SCHEDULER)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log("blob type", response.data.type);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Scheduler${MIME_TYPES[response.data.type]}`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState(
          {
            IsDownloading: false,
          },
          () => this.playAudio()
        );
      });
  };

  downloadVoucher = (vocherNumber, type) => {
    switch (type) {
      case GlobalConstants.SCHEDULER_PDF:
        this.schedulerDownload();
        break;

      default:
        this.voucherDownload(vocherNumber);
        break;
    }
  };

  playAudio() {
    const audioEl = document.getElementsByClassName("audio-element")[0];
    audioEl.play();
  }
  render() {
    const { voucherNumber, disabled, text, type } = this.props;
    const { IsDownloading } = this.state;
    return (
      <Fragment>
        <Button
          style={{ width: "100%" }}
          type="primary"
          icon="download"
          onClick={() => this.downloadVoucher(voucherNumber, type)}
          loading={IsDownloading}
          disabled={disabled}
        >
          {/* {voucherNumber} */}
          {IsDownloading
            ? "Downloading..."
            : voucherNumber
            ? voucherNumber
            : text}
        </Button>
        <audio className="audio-element">
          <source src={require("../../assets/music/smb_pause.wav")}></source>
          {/* <source src="https://api.coderrocketfuel.com/assets/pomodoro-times-up.mp3"></source> */}
        </audio>
      </Fragment>
    );
  }
}
