import React, { Component, Fragment } from "react";
import { Table, Tag, Button, Row, Col, Divider, Icon, Input } from "antd";
import Highlighter from "react-highlight-words";

import { Link } from "react-router-dom";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import AccountingServices from "services/AccountingService";
import {
  Services,
  GlobalConstants,
  LocalStorage,
  Screens,
  MenusRoute,
} from "constants/AppConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import {
  checkFor,
  Capitalize,
  PriceFormat,
  StartCase,
  ReplaceWithSpace,
} from "utilities/ValidationHelper";
import TooltipIcon from "components/shared/TooltipIcon";
import ReadMore from "components/shared/ReadMore";
import AccProductionAddEntry from "../AccPreProductionEdit/AccProductionAddEntry";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";

const { Search } = Input;

class AccProductionDataTable extends Component {
  constructor(props) {
    super(props);

    this._accountingService = new AccountingServices();

    this.state = {
      dataSource: [],
      contractDataSource: [],
      isLoading: true,
      pageType: props.pageType,
      productionDepartment: props.productionDepartment,
      searchKey: null,
    };
  }

  componentDidMount() {
    this.getProductionData();
  }

  getProductionData = () => {
    this._accountingService
      .accountingService(Services.AccountingVariables.GET_PRODUCTION_DATA, {
        productionDepartment: this.props.productionDepartment,
      })
      .then((res) => {
        this.setState({
          dataSource: res.data.content || [],
          isLoading: false,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: false,
        })
      );
  };

  render() {
    const { dataSource, isLoading, pageType, productionDepartment, searchKey } =
      this.state;
    const { activePermisision, getPermissions } = this.props;

    const dataColumn = [
      {
        title: "#",
        key: "key",
        align: "center",
        width: "5%",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "left",
        width: "20%",
        render: (text) => (
          // <ReadMore textColor="#ee556a" lines={1}>
          <Highlighter
            highlightClassName="highLightClass"
            searchWords={[searchKey]}
            autoEscape={true}
            textToHighlight={text}
          />
          // </ReadMore>
        ),
      },
      {
        title: "Department",
        dataIndex: "categoryDepartment",
        key: "categoryDepartment",
        align: "left",
        width: "10%",
        render: (text, record) => (
          <Tag className={`${record.categoryDepartment} dateTag`}>
            {record.categoryDepartment === GlobalConstants.PRODUCTION_CAPS
              ? GlobalConstants.DAILY_EXPENSE
              : ReplaceWithSpace(text)}
          </Tag>
        ),
      },
      {
        title: "Invoice amount",
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "right",
        width: "10%",
      },
      {
        title: "Pending amount",
        dataIndex: "pendingAmount",
        key: "pendingAmount",
        align: "right",
        width: "10%",
      },
      {
        title: "Paid amount",
        dataIndex: "paidAmount",
        key: "paidAmount",
        align: "right",
        width: "10%",
      },
      // {
      //   title: "Pending",
      //   dataIndex: "pendingAmount",
      //   key: "pendingAmount",
      //   align: "right",
      //   width: "10%"
      // }
      // {
      //   title: "Status",
      //   dataIndex: "budgetState",
      //   key: "address",
      //   align: "center",
      //   render: (value, record) => (
      //     <Tag className={`statusTag ${record.budgetStateEnum}`}>
      //       {record.budgetState}
      //     </Tag>
      //   )
      // },
    ];

    if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
      dataColumn.push({
        title: "Action",
        // key: "key",
        align: "center",
        width: "5%",
        render: (text, record) => (
          <TooltipIcon
            onClick={() =>
              record.categoryDepartment !== GlobalConstants.TRANSPORT_CAPS
                ? this.props.history.push({
                    pathname: `${MenusRoute.ACCOUNTING}/production/edit`,
                    state: {
                      category: GlobalConstants.PRODUCTION_CAPS,
                      title: record.description,
                      details: {
                        productionCategoryDepartment: productionDepartment,
                        description: record.description,
                        categoryDepartment: record.categoryDepartment,
                        department: productionDepartment,
                      },
                    },
                  })
                : this.props.history.push(
                    `${MenusRoute.ACCOUNTING}/production/shootwise`
                  )
            }
            icon="more"
          />
        ),
      });
    }

    let filteredDataSource = searchKey
      ? dataSource.filter((data) =>
          data.description.toLowerCase().includes(searchKey)
        )
      : dataSource;

    const columns = dataColumn;

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <Fragment>
        <Row type="flex" justify="center" style={{ padding: 10 }}>
          <Col xl={24}>
            <Row type="flex">
              <Col xl={12} style={{ marginBottom: 5 }}>
                {/* {productionDepartment === "PRODUCTION" && ( */}
                <Search
                  placeholder="search description here..."
                  onChange={(e) => this.setState({ searchKey: e.target.value })}
                  enterButton
                  allowClear
                />
                {/* )} */}
              </Col>
              <Col xl={12}>
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_ADD
                ) && (
                  <AccProductionAddEntry
                    refreshData={this.getProductionData}
                    {...this.props}
                  />
                )}
                {/* disabled={dataSource.length === 0} */}
              </Col>
            </Row>
          </Col>
          <Col xl={24}>
            <Table
              rowKey="key"
              bordered
              loading={isLoading}
              dataSource={filteredDataSource}
              columns={columns}
              // pagination={{ pageSize: 1000 }}
              // scroll={{ y: "50vh" }}
              pagination={false}
              locale={{
                emptyText: <RecordNotFound />,
              }}
            />
          </Col>
        </Row>
      </Fragment>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccProductionDataTable);
