import React, { Component, Fragment } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Button,
  Collapse,
} from "antd";
import moment from "moment";

import FileCard from "components/shared/FileCardComponent";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Screens,
} from "constants/AppConstants";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { numberOnly, amountOnly, Capitalize } from "utilities/ValidationHelper";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import GlobalService from "services/GlobalService";
import GeoCode from "components/shared/GeoCode";
import { OnboardServices } from "services";
import ImageViewer from "components/shared/ImageViewer";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import { setActiveTab } from "store/action";
import { connect } from "react-redux";
import Uploaddocumentfile from "components/uploaddocumentfile";
import ArtSetServices from "services/ArtSetServices";
import UploadMultipleDocumentFile from "components/shared/UploadMultipleDocumentFile";
import ArtSetTable from "components/ArtSetTab/ArtSetTable";
import { handleError } from "utilities/ErrorHandler";
import { Value, Label } from "components/shared/Label";
import ThemeColors from "constants/ThemeConstants/variables";
import ArtSetOverviewTable from "components/Artset/ArtSetOverView/ArtSetOverviewTable/ArtSetOverviewTable";
import StartEndDate from "./StartDateEndDate";
import TooltipComponentWithEllipsis from "components/shared/TooltipComponentWithEllipsis";

const dateFormat = "DD-MM-YYYY";
const { Panel } = Collapse;

class UpdateArtSetPage extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._onboardservices = new OnboardServices();
    this._locationService = new LocationTabService();
    this._artSetService = new ArtSetServices();
    this.state = {
      materialPurchase: null,
      materialHire: null,
      labourExpense: null,
      setUpTime: null,
      setUpTimeType: null,
      status: null,
      gettingStatus: "TO_BE_CONFIRMED",
      timeRequiredType: null,
      fileuploadid: null,
      fileList: null,
      shootDate: null,
      actualLocationName: null,
      locationTypeValue: null,
      fileId: null,
      docFiles: null,
      imageFiles: null,
      imageData: null,
      setWorkEndDate: null,
      setWorkStartDate: null,
      artSetBudgetId: props.match.params.artSetBudgetId,
      sceneArtSets: [],
      openPanel: "00",
    };
  }

  componentDidMount() {
    // this.setState({
    //   gettingStatus: "TO_BE_CONFIRMED"
    // });
    this.getStatus();
    this.getTimeType();
    this.getArtSetData(this.props.match.params.shootDate);
    this.setState({
      artSetId: this.props.match.params.artSetId,
      shootDate: this.props.match.params.shootDate,
    });
    this.setState({
      sceneLocationId: this.props.match.params.sceneLocationId,
    });
  }

  deleteImage = (id) => {
    console.log("image files id", id);
    let fileDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );
    let imageDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;
    imageFiles.splice(imageDeleteIndex, 1);
    fileList.splice(fileDeleteIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  handleImageIDS = (ids) => {
    console.log("url", ids);
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ fileId: id, fileType: GlobalConstants.IMAGE });
    });
    console.log(files);
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  handleDocument = (id) => {
    console.log("fileuploadid", id);

    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { fileId: id, fileType: GlobalConstants.DOCUMENT },
      ];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ fileId: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };
  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState(
      {
        docFiles,
        imageFiles,
      },
      () => console.log("files....", this.state)
    );
  };
  deleteDoc = (id) => {
    console.log("image files id", id);
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteDocIndex = this.state.docFiles.findIndex(
      (a) => a.documentId === id
    );

    let docFiles = this.state.docFiles;
    let fileList = this.state.fileList;
    docFiles.splice(deleteDocIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      docFiles,
      fileList,
    });
  };

  // handleImage = url => {
  //   const { fileList } = this.state;

  //   this.setState({
  //     projectimageid: url
  //   });

  //   if (fileList) {
  //     let temp = [
  //       ...fileList,
  //       { fileId: url, fileType: GlobalConstants.IMAGE }
  //     ];
  //     this.setState({
  //       fileList: temp
  //     });
  //   } else {
  //     let temp = [{ fileId: url, fileType: GlobalConstants.IMAGE }];
  //     this.setState(
  //       {
  //         fileList: temp
  //       },
  //       () => {
  //         console.log(this.state.fileList);
  //       }
  //     );
  //   }
  // };et/budgets/

  getArtSetData(date) {
    this._artSetService
      .service(Services.ArtSetVariables.GET_ART_SET_LIST_SHOOT_DAY, date)
      .then((res) => {
        this.setState(
          {
            sceneArtSets: res.data.scenArtSets,
          },
          () => {
            this.splitBasedOnFileTypes(this.state.fileList);
          }
        );
      });

    // showNotifications(
    //   Notifications.SUCCESS,
    //   "Success",
    //   "Art Set Loaded Sccessfully"
    // );
  }

  handleUpdate = (e) => {
    e.preventDefault();
    const {
      artSetId,
      materialPurchase,
      sceneLocationId,
      shootDate,
      materialHire,
      labourExpense,
      setUpTime,
      setUpTimeType,
      status,
      gettingStatus,
      timeRequiredType,
      fileuploadid,
      fileList,
      setWorkStartDate,
      setWorkEndDate,
    } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.setState({ isLoading: true });
        // console.log("Received values of form: ", values);
        // let type = this.state.gettingStatus;
        // type = type.replace(/ /g, "_");
        // let enumType = type.toUpperCase();

        // let setUpTimeType = this.state.setUpTimeType;
        // setUpTimeType = setUpTimeType.replace(/ /g, "_");
        // let setUp = setUpTimeType.toUpperCase();

        const data = {
          labourExpense: labourExpense,
          artSetBudgetId: artSetId,
          documents: fileList,
          materialsHire: materialHire,
          materialsPurchase: materialPurchase,
          sceneLocationId: sceneLocationId,
          setUpTimeRequired: setUpTime,
          setUpTimeRequiredType: setUpTimeType,
          shootDate: shootDate,
          state: gettingStatus,
          setWorkStartDate: moment(setWorkStartDate, dateFormat).format(
            "DD-MM-YYYY"
          ),
          setWorkEndDate: moment(setWorkEndDate, dateFormat).format(
            "DD-MM-YYYY"
          ),
        };
        this._artSetService
          .service(Services.ArtSetVariables.UPDATE_ART_SET, data)
          .then((res) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Art Set Updated!!"
            );
            // this.props.history.goBack()
            this.getArtSetData();
          })
          .catch((err) => {
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      artSetId,
      sceneLocationId,
      shootDate,
      materialPurchase,
      materialHire,
      labourExpense,
      setUpTime,
      setUpTimeType,
      gettingStatus,
      timeRequiredType,
      fileuploadid,
      fileList,
      setWorkStartDate,
      setWorkEndDate,
    } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.setState({ isLoading: true });
        // console.log("Received values of form: ", values);
        // let type = this.state.gettingStatus;
        // type = type.replace(/ /g, "_");
        // let enumType = type.toUpperCase();

        // let setUpTimeType = this.state.setUpTimeType;
        // setUpTimeType = setUpTimeType.replace(/ /g, "_");
        // let setUp = setUpTimeType.toUpperCase();
        const data = {
          labourExpense: labourExpense,
          // artSetBudgetId: artSetId,
          documents: fileList,
          materialsHire: materialHire,
          materialsPurchase: materialPurchase,
          sceneLocationId: sceneLocationId,
          setUpTimeRequired: setUpTime,
          setUpTimeRequiredType: setUpTimeType,
          shootDate: shootDate,
          state: this.state.gettingStatus,
          setWorkStartDate,
          setWorkEndDate,
          gettingStatus,
        };
        this._artSetService
          .service(Services.ArtSetVariables.UPDATE_ART_SET, data)
          .then((res) => {
            // console.log(res);
            // this.setState({ isLoading: false, locationId: null });
            // this.props.form.resetFields();
            // this.handleCancel();
            // this.props.refreshLocation();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Art Set Saved!!"
            );
            // this.getArtSetData();
            // this.props.history.goBack();
            // this.props.history.goBack();
            // localStorage.setItem(
            //   GlobalConstants.REDIRECT_TO,
            //   Screens.LOCATION
            // );
            // this.props.setActiveTab("1");
          })
          .catch((err) => {
            console.log(err);
            // this.setState({ isLoading: false });
            // this.props.form.resetFields();
            // this.handleCancel();
            showNotifications(
              Notifications.ERROR,
              "Failed",
              handleError(
                err.response && err.response.data && err.response.data.message
              )
            );
          });
      }
    });
  };
  handleStatus(value) {
    this.setState({
      gettingStatus: value,
    });
  }
  handleTimeRequiredType(value) {
    this.setState({
      setUpTimeType: value,
    });
  }

  getTimeType() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "MAKE_UP_TIME")
      .then((res) => {
        this.setState({ timeRequiredType: res.data });
      });
  }

  getStatus = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState(
          {
            status: res.data,
          },
          () => console.log("status...", this.state.status)
        );
      });
  };

  disabledDate = (current, toDate) => {
    // Can not select days before today and today
    if (toDate) {
      return (
        current &&
        current <
          moment(moment(toDate, dateFormat).format("YYYY-MM-DD")).startOf("day")
      );
    }
    return current && current < moment().startOf("day");
  };

  dateChange = (value, type) => {
    this.setState({
      [type]: moment(value, dateFormat).format("DD-MM-YYYY"),
    });
  };

  handleStatusChange = (value, sceneLocationId) => {
    this.setState({
      gettingStatus: value,
    });
    const data = {
      artSets: [
        {
          sceneLocationId,
          state: value,
        },
      ],
      shootDate: this.state.shootDate,
    };
    this._artSetService
      .service(Services.ArtSetVariables.UPDATE_ART_SET_DATES, data)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.CHANGES_HAVE_BEEN_SAVED
        );
        // refreshData();
        // this.getDates();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  renderScenes = (scenes, parentI) => {
    const { openPanel } = this.state;
    return (
      scenes &&
      scenes.map((scene, i) => (
        <Row className="mt-25" type="flex" justify="center">
          <Col md={{ span: 18 }}>
            <Collapse
              accordion
              activeKey={scenes.map(
                (scene, i) => parentI.toString() + i.toString()
              )}
              // onChange={this.handleAccordionChange}
            >
              <Panel
                header={`Scene Number: ${scene.sceneNumber}`}
                className="accordion_style"
                key={parentI.toString() + i.toString()}
              >
                <ArtSetOverviewTable
                  {...this.props}
                  artSetLeft
                  artSetLeftSceneId={scene.sceneId}
                  serviceType={Services.ArtSetVariables.GET_ART_SET_LIST}
                />
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ))
    );
  };

  handleAccordionChange = (openPanel) => {
    this.setState({
      openPanel,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { latitude, longitude } = this.props;
    const {
      fileList,
      docFiles,
      imageFiles,
      setWorkStartDate,
      setWorkEndDate,
      sceneArtSets,
    } = this.state;
    const { Option } = Select;
    const {
      materialPurchase,
      materialHire,
      labourExpense,
      setUpTime,
      setUpTimeType,
      status,
      gettingStatus,
      timeRequiredType,
    } = this.state;
    return (
      <Row>
        <Col xs={24} style={{ paddingBottom: 25 }}>
          <Title hasBack {...this.props}>
            <TitleText>
              Update Art Set Budget - {this.state.shootDate}{" "}
            </TitleText>
          </Title>

          {sceneArtSets.map((scene, i) => (
            <Fragment>
              <Row type="flex" justify="center" className="mt-25">
                <Col xs={18}>
                  <Row>
                    <Col xs={12}>
                      <Col xl={9} className="trt">
                        <Label style={{ fontSize: 18 }}>Scene Location</Label>
                      </Col>
                      <Col xl={15}>
                        <Value
                          style={{
                            paddingLeft: 5,
                            color: ThemeColors.primaryColor,
                          }}
                        >
                          <TooltipComponentWithEllipsis
                            text={Capitalize(scene.sceneLocation)}
                            placement="topLeft"
                          ></TooltipComponentWithEllipsis>
                        </Value>
                      </Col>
                    </Col>
                    <Col
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Col>
                        <Label style={{ fontSize: 18 }}>Actual Location</Label>
                      </Col>
                      <Col xl={15}>
                        <Value
                          style={{
                            paddingLeft: 5,
                            color: ThemeColors.primaryColor,
                          }}
                        >
                          <TooltipComponentWithEllipsis
                            text={Capitalize(scene.actualLocation)}
                            placement="topLeft"
                          ></TooltipComponentWithEllipsis>
                        </Value>
                      </Col>
                    </Col>

                    {/* <Col xs={{ span: 12 }}>
                      <Col xl={9} className="trt">
                        <Label style={{ fontSize: 18 }}>Status</Label>
                      </Col>
                      <Col xl={15}>
                        <Form.Item style={{ paddingLeft: 5 }}>
                          {getFieldDecorator("status", {
                            initialValue:
                              scene.stateEnum ||
                              GlobalConstants.TO_BE_CONFIRMED,
                            rules: [
                              {
                                required: true,
                                message: "Please Enter status!",
                              },
                            ],
                          })(
                            <Select
                              defaultValue={GlobalConstants.TO_BE_CONFIRMED}
                              placeholder="Status"
                              style={{ width: "60%" }}
                              onChange={(value) =>
                                this.handleStatusChange(
                                  value,
                                  scene.sceneLocationId
                                )
                              }
                            >
                              {status &&
                                status.map((option, i) => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
              {this.renderScenes(scene.scenes, i)}
              <StartEndDate
                shootDate={this.state.shootDate}
                sceneLocationId={scene.sceneLocationId}
                artSetBudgetId={scene.artSetBudgetId}
              />
            </Fragment>
          ))}
        </Col>

        {/* <Row type="flex" justify="center">
          <Col xs={18}>
            {this.state.shootDate && (
              <ArtSetTable
                pageType="Page"
                serviceType={Services.ArtSetVariables.ART_SET_TABLE_UNIQUE}
                shootDate={this.state.shootDate}
              />
            )}
          </Col>
        </Row> */}

        {/* <Row type="flex" justify="center">
          <Col xl={20}>
            <Col
              xl={{ span: 8 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Row gutter={16}>
                <Col xs={{ span: 8 }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                      textAlign: "right"
                    }}
                  >
                    <label>*Work Start Date</label>
                  </div>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                  <Form.Item>
                    {getFieldDecorator("setWorkStartDate", {
                      initialValue: setWorkStartDate
                        ? moment(setWorkStartDate, dateFormat)
                        : "",
                      rules: [
                        {
                          required: true,
                          message: "Please Enter status!"
                        }
                      ]
                    })(
                      <DatePicker
                        format={dateFormat}
                        // defaultValue={
                        //   setWorkStartDate
                        //     ? moment(setWorkStartDate, dateFormat)
                        //     : ""
                        // }
                        disabledDate={current => this.disabledDate(current)}
                        onChange={value =>
                          this.dateChange(value, "setWorkStartDate")
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 8 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Row gutter={16}>
                <Col xs={{ span: 8 }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                      textAlign: "right"
                    }}
                  >
                    <label>*Work End Date</label>
                  </div>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                  <Form.Item>
                    {getFieldDecorator("setWorkEndDate", {
                      initialValue: setWorkEndDate
                        ? moment(setWorkEndDate, dateFormat)
                        : "",
                      rules: [
                        {
                          required: true,
                          message: "Please Enter status!"
                        }
                      ]
                    })(
                      <DatePicker
                        // defaultValue={
                        //   setWorkEndDate
                        //     ? moment(setWorkEndDate, dateFormat)
                        //     : ""
                        // }
                        format={dateFormat}
                        disabledDate={current =>
                          this.disabledDate(current, setWorkStartDate)
                        }
                        onChange={value =>
                          this.dateChange(value, "setWorkEndDate")
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xl={{ span: 8 }}
              style={{ marginBottom: "30px", marginTop: 30 }}
            >
              <Row gutter={16}>
                <Col xs={{ span: 8 }}>
                  <div
                    style={{
                      fontWeight: "600",
                      paddingTop: "8px",
                      textAlign: "right"
                    }}
                  >
                    <label>*Status</label>
                  </div>
                </Col>

                <Col xl={{ span: 12 }} lg={{ span: 13 }} md={{ span: 12 }}>
                  <Form.Item>
                    {getFieldDecorator("status", {
                      initialValue:
                        (gettingStatus && gettingStatus) ||
                        GlobalConstants.TO_BE_CONFIRMED,
                      rules: [
                        {
                          required: true,
                          message: "Please Enter status!"
                        }
                      ]
                    })(
                      <Select
                        defaultValue={GlobalConstants.TO_BE_CONFIRMED}
                        placeholder="Status"
                        style={{ width: "100%" }}
                        onChange={value =>
                          this.setState({
                            gettingStatus: value
                          })
                        }
                      >
                        {status &&
                          status.map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 22 }} lg={{ span: 24 }} md={{ span: 21 }}>
            <Form onSubmit={this.handleSubmit}>
              <Row type="flex" justify="end">
                <Col>
                  <Button
                    onClick={() => this.props.history.goBack()}
                    style={{ marginRight: 10 }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  {this.state.artSetId !== "undefined" ? (
                    <Button
                      type="primary"
                      onClick={this.handleUpdate}
                      // loading={isLoading}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={this.handleSubmit}
                      // loading={isLoading}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row> */}
      </Row>
    );
  }
}

const UpdateArtSet = Form.create()(UpdateArtSetPage);

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(null, mapDispatchToProps)(UpdateArtSet);
