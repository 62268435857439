import React, { Component } from "react";
import { Form, Input, Row, Col, Select, DatePicker, Table } from "antd";

import {
  LocalStorage,
  GlobalConstants,
  Services,
  Notifications,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import InvoiceService from "services/InvoiceService";
import { handleError } from "utilities/ErrorHandler";
import { AccountingVariables } from "constants/AppConstants/ServicesConstants";
import AccountingService from "services/AccountingServices";
import { StartCase } from "utilities/ValidationHelper";
import moment from "moment";
import DocumentDelete from "services/DocumentDelete";

import "components/shared/invoice.scss";
import InvoiceDisplay from "components/shared/InvoiceDisplay";
import InvoiceUpload from "components/shared/InvoiceUpload";
import Loader from "components/shared/Loader";
import {
  showActionMessage,
  showNotifications,
} from "components/shared/NotificationComponent";

const { Option } = Select;

const gstData = [
  {
    name: "GST_0_25",
    value: 0.25,
  },
  {
    name: "GST_5",
    value: 5,
  },
  {
    name: "GST_12",
    value: 12,
  },
  {
    name: "GST_18",
    value: 18,
  },
  {
    name: "GST_28",
    value: 28,
  },
];

const dateFormat = GlobalConstants.DD_MM_YYYY;
const renderContent = (value, row, index) => {
  const obj = {
    children: value,
    props: {},
  };
  if (index === 4) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const columns = [
  {
    title: "#",
    align: "center",
    width: "5%",
    render: (text, row, index) => {
      if (index < 1) {
        return <a>{index + 1}</a>;
      }
      return {
        props: {
          colSpan: 0,
        },
      };
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    align: "center",
    render: (text, row, index) => {
      if (index < 1) {
        return <a>{text}</a>;
      }
      return {
        children: <a style={{ float: "right" }}>{text}</a>,
        props: {
          colSpan: 2,
        },
      };
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    align: "center",

    // render: (value, row, index) => {
    //   const obj = {
    //     children: value,
    //     props: {}
    //   };
    //   if (index === 2) {
    //     obj.props.rowSpan = 1;
    //   }
    //   // These two are merged into above cell
    //   if (index === 3) {
    //     obj.props.rowSpan = 0;
    //   }
    //   if (index === 4) {
    //     obj.props.colSpan = 0;
    //   }
    //   return obj;
    // }
  },
];

class InvoiceFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(props),
      isDataFetched: true,
    };

    this._invoicesService = new InvoiceService();
    this._globalService = new GlobalService();
    this._accountingService = new AccountingService();
    this._docDelete = new DocumentDelete();
  }

  getInitialState = ({
    category,
    description,
    activePayment,
    activeInvoice,
    details: { productionCategoryDepartment },
  }) => {
    return {
      descriptionData: description,
      invoiceNumber: null,
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
      activeInvoice,
      productionCategoryDepartment,
      tdsTax: null,
      tdsData: [],
      gstData: [],
      invoices: [],
      invoiceDocuments: [],
      category,
    };
  };

  generateTableData = ({ descriptionData, gstRate, tdsTax }) => {
    this.setState({});
  };

  componentDidMount() {
    const { activeInvoice } = this.state;
    this.props.onRef(this); // reference to access the functions of this component from parent's
    this.fetchGst();
    this.fetchTds();
    this.fetchInvoiceNumbers();
    if (activeInvoice) {
      this.setState(
        {
          isDataFetched: false,
        },
        () => this.handleInvoiceChange(activeInvoice)
      );
    }
  }

  fetchInvoiceNumbers = () => {
    const { descriptionData, category } = this.state;
    const data = {
      description: descriptionData ? descriptionData : "",
      category,
      productionCategoryDepartment: "",
    };
    this._accountingService
      .services(Services.AccountingVariables.GET_PRODUCTION_INVOICES, data)
      .then((res) => {
        this.setState({
          invoices: res.data,
        });
      })
      .catch((err) =>
        this.setState({
          isLoading: true,
        })
      );
  };

  fetchTds() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "TDS_TAX")
      .then((res) => {
        this.setState({
          tdsData: res.data || [],
        });
      });
  }

  fetchGst() {
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA_RATE, "GST_TAX")
      .then((res) => {
        this.setState({
          gstData: res.data || [],
        });
      });
  }

  // handleDelete = id => {
  //   const { productionAccountId } = this.state;
  //   this._docDelete
  //     .deleteService(
  //       Services.AccountingVariables.DELETE_PRE_POST_PRODUCTION_IMAGE,
  //       productionId,
  //       null,
  //       id
  //     )
  //     .then(res => {
  //       showActionMessage(
  //         GlobalConstants.SUCCESS,
  //         GlobalConstants.SUCCESSFULLY_REMOVED
  //       );
  //       this.fetchData();
  //     })
  //     .catch(err => {
  //       showNotifications(
  //         Notifications.ERROR,
  //         "Failed",
  //         "Something Went Wrong"
  //       );
  //     });
  // };

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleSave = (data) => {
    const { record } = this.state;
    const { handleInvoiceNumber, handleCloseModal, category } = this.props;
    console.log("data", data);
    // let tempDescription = category === GlobalConstants.PRODUCTION_CAPS ?
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.CREATE_INVOICE_BY_SHOOT_DATE,
        data
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          `Invoice ${GlobalConstants.CREATED_SUCCESSFULLY}`
        );
        handleInvoiceNumber(res.data.invoiceNumber, res.data.invoiceId, record);
        handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleUpdate = (data, invoiceId) => {
    const { record } = this.state;
    const { handleInvoiceNumber, handleCloseModal } = this.props;
    this._invoicesService
      .invoiceService(
        Services.InvoiceVariables.UPDATE_INVOICE_BY_SHOOT_DATE,
        data,
        invoiceId
      )
      .then((res) => {
        // showActionMessage(
        //   GlobalConstants.SUCCESS,
        //   `Invoice ${GlobalConstants.UPDATED_SUCCESSFULLY}`
        // );
        handleInvoiceNumber(res.data.invoiceNumber, res.data.invoiceId, record);
        handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  handleSubmit() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.upload.handleSave();
      }
    });
  }

  checkForInvoiceId = () => {
    const {
      descriptionData,
      invoiceNumber,
      invoiceAmount,
      invoiceDate,
      gstRate,
      tdsTax,
      category,
      invoiceId,
      invoiceDocuments,
      shootDate,
      productionCategoryDepartment,
    } = this.state;

    const data = {
      descriptionData: productionCategoryDepartment
        ? `${productionCategoryDepartment}#${descriptionData}`
        : descriptionData,
      invoiceNumber: invoiceNumber.toUpperCase(),
      invoiceAmount,
      invoiceDate,
      gstRate,
      tdsTax,
      category,
      invoiceId,
      invoiceDocuments,
      shootDate,
    };
    if (invoiceId) this.handleUpdate(data, invoiceId);
    else this.handleSave(data);
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  dateChange = (value, dateString, record, type) => {
    this.setState({
      invoiceDate: dateString,
    });
  };

  handleInvoiceNumber = (invoiceNumber) => {
    this.setState(
      {
        invoiceNumber,
      },
      () =>
        this.props.form.setFieldsValue({
          invoiceNumber: invoiceNumber,
        })
    );
  };

  handleInvoiceChange = (id) => {
    this._invoicesService
      .invoiceService(Services.InvoiceVariables.GET_INVOICE_BY_ID, id)
      .then((res) => {
        const {
          // descriptionData,
          invoiceNumber,
          invoiceAmount,
          invoiceDate,
          gstRate,
          tdsTax,
          invoiceId,
          invoiceDocuments,
        } = res.data;
        this.setState(
          {
            // descriptionData,
            gstRate,
            invoiceAmount,
            invoiceDate: invoiceDate
              ? invoiceDate
              : moment().format(dateFormat),
            invoiceDocuments: invoiceDocuments || [],
            invoiceNumber,
            tdsTax,
            invoiceId,
            isDataFetched: true,
          },
          () =>
            this.props.form.setFieldsValue({
              invoiceNumber: invoiceNumber,
              // descriptionData,
              invoiceAmount,
              invoiceDate: invoiceDate
                ? moment(invoiceDate, dateFormat)
                : moment(),
              gstRate,
              tdsTax,
            })
        );
      });
  };

  handleDocument = (files = []) => {
    const { invoiceDocuments } = this.state;
    const lists = [...invoiceDocuments];

    files.map((file) => {
      const { fileData, fileType, fileId } = file;
      lists.push({
        fileId,
        fileType,
      });
    });
    // data.invoiceDocuments = lists;
    // this.handleUpdate(lists);
    console.log("lists", lists);
    this.setState(
      {
        invoiceDocuments: lists,
      },
      () => this.checkForInvoiceId()
    );
  };

  handleInvoiceBlur = (invoiceNumber) => {
    this.props.form.setFieldsValue({
      invoiceNumber: invoiceNumber,
    });
    this.setState({
      invoiceNumber,
    });
  };

  handleDelete = (id) => {
    const { activeInvoice } = this.state;
    this._docDelete
      .deleteService(Services.InvoiceVariables.DELETE_INVOICE_DOCUMENT, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        this.handleInvoiceChange(activeInvoice);
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      descriptionData,
      invoiceNumber,
      invoiceAmount,
      invoiceDate,
      gstRate,
      invoices,
      tdsTax,
      invoiceDocuments,
      isDataFetched,
    } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
      },
    };
    const tableCol = {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 19 },
      },
    };
    const customCol = {
      labelCol: {
        sm: { span: 2 },
      },
      wrapperCol: {
        sm: { span: 22 },
      },
    };

    const getGstPer = Object.assign(
      {},
      ...gstData.filter((gst) => gst.name === gstRate)
    );
    let gstAmount = 0;
    let invAmount = 0;
    if (invoiceAmount) {
      invAmount = parseFloat(invoiceAmount).toFixed(2);
    }
    if (getGstPer) {
      gstAmount = (invAmount * getGstPer.value) / 100 || 0;
    }
    invAmount = parseFloat(invAmount);
    gstAmount = parseFloat(gstAmount);
    const totalInvoiceGST = invAmount + gstAmount;

    // console.log(typeof invAmount);
    // console.log(typeof gstAmount);
    // console.log("invAmount", invAmount);
    // console.log("gstAmount", gstAmount);
    // console.log("totalInvoiceGST", totalInvoiceGST);

    const tdsAmount = (invAmount * tdsTax) / 100;
    // console.log("tdsAmount", tdsAmount);

    const total = invAmount + gstAmount - tdsAmount;

    let dataSource = [
      {
        key: "1",
        description: descriptionData,
        amount: invoiceAmount,
      },
      {
        key: "2",
        description: `GST ${getGstPer.value ? getGstPer.value + "%" : ""}`,
        amount: gstAmount ? gstAmount : 0,
      },
      {
        key: "3",
        description: `TDS ${tdsTax ? tdsTax + "%" : ""}`,
        amount: tdsAmount ? tdsAmount : 0,
      },
      {
        key: "4",
        description: "Total Amount",
        amount: total ? total : 0,
      },
    ];

    if (!tdsTax) {
      dataSource = dataSource.filter((data) => data.key !== "3");
    }

    return (
      <>
        {!isDataFetched && <Loader />}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col xl={8}>
              <Form.Item label="Invoice Number" hasFeedback>
                {getFieldDecorator("invoiceNumber", {
                  initialValue: invoiceNumber,
                  rules: [
                    {
                      required: true,
                      message: "Invoice Number Required!",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%" }}
                    className="upperCase"
                    showSearch
                    // autoFocus
                    onChange={this.handleInvoiceChange}
                    onBlur={this.handleInvoiceBlur}
                    onSearch={this.handleInvoiceNumber}
                  >
                    {invoices &&
                      invoices.map((list, i) => {
                        return (
                          <Option key={i} value={list.value}>
                            {list.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xl={8}>
              <Form.Item label="Description" hasFeedback>
                <Input disabled defaultValue={descriptionData} />
              </Form.Item>
            </Col>
            <Col xl={8}>
              <Form.Item label="Amount" hasFeedback>
                {getFieldDecorator("invoiceAmount", {
                  rules: [
                    {
                      required: true,
                      message: "Invoice Amount Required!",
                    },
                    {
                      pattern: new RegExp("^([0-9].*)$"),
                      message: "Enter numbers only",
                    },
                  ],
                })(
                  <Input
                    type="number"
                    placeholder="Enter Invoice amount"
                    onChange={(e) =>
                      this.setState({
                        invoiceAmount: e.target.value,
                      })
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={8}>
              <Form.Item label="GST" hasFeedback>
                {getFieldDecorator("gstRate", {
                  rules: [
                    {
                      required: true,
                      message: "GST Required!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Select type"
                    onChange={(gstRate) =>
                      this.setState({
                        gstRate,
                      })
                    }
                    allowClear
                    disabled={!invoiceAmount}
                  >
                    {this.state.gstData &&
                      this.state.gstData.map((option, i) => (
                        <Option value={option.value}>{option.label}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xl={8}>
              <Form.Item label="TDS" hasFeedback>
                {getFieldDecorator("tdsTax", {
                  rules: [
                    {
                      required: false,
                      message: "TDS Required!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Select type"
                    allowClear
                    onChange={(tdsTax) =>
                      this.setState({
                        tdsTax,
                      })
                    }
                    disabled={!invoiceAmount || !gstRate}
                  >
                    {this.state.tdsData &&
                      this.state.tdsData.map((option, i) => (
                        <Option value={option.value}>{option.label}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xl={8}>
              <Form.Item label="Invoice Date" hasFeedback>
                {getFieldDecorator("invoiceDate", {
                  rules: [
                    {
                      required: true,
                      message: "Invoice Date Required!",
                    },
                  ],
                })(
                  <DatePicker
                    format={dateFormat}
                    onChange={this.dateChange}
                    // disabledDate={current => this.disabledDate(current)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item {...tableCol} label="Summary">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  bordered
                />
              </Form.Item>
            </Col>
            <Col xl={24}>
              <Form.Item {...customCol} label="Files">
                {getFieldDecorator("document", {
                  rules: [
                    {
                      required: false,
                      message: "Invoice Date Required!",
                    },
                  ],
                })(
                  <>
                    <InvoiceDisplay
                      handleDelete={this.handleDelete}
                      files={invoiceDocuments}
                    />
                    <InvoiceUpload
                      onRef={(ref) => (this.upload = ref)}
                      handleDocument={this.handleDocument}
                      handleCancel={this.handleCancel}
                    />
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const AddPaymentInvoiceForm = Form.create({ name: "register" })(
  InvoiceFormEdit
);

export default AddPaymentInvoiceForm;
