import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button, Icon, Input, Tag, Switch } from "antd";

import TooltipIcon from "components/shared/TooltipIcon";
import { MenusRoute, GlobalConstants, Services } from "constants/AppConstants";
import { Link } from "react-router-dom";
import Accounting from "services/AccountingServices";
import RecordNotFound from "components/shared/RecordNotFound";
import moment from "moment";
import AccProductionAddEntry from "./AccProductionAddEntry";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { connect } from "react-redux";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor, ReplaceWithSpace } from "utilities/ValidationHelper";
import AccountingService from "services/AccountingService";

const { Search } = Input;

const AccProductionArtSetTable = (props) => {
  const [DataSource, setDataSource] = useState([]);
  const [AdvanceDataSource, setAdvanceDataSource] = useState([]);
  const [SearchKey, setSearchKey] = useState(null);
  const [IsLoading, setIsLoading] = useState(true);

  const [switchActive, setSwitchActive] = useState(false);
  let filteredDataSource = [];
  const account = new AccountingService();
  const Account = new Accounting();
  const { activePermisision } = props;
  useEffect(() => {
    fetchData();
    getAdvancePaymentData();
  }, []);

  const handleSearch = (key) => {
    setSearchKey(key);
  };

  filteredDataSource = SearchKey
    ? switchActive
      ? AdvanceDataSource.filter((data) =>
          data.description.toLowerCase().includes(SearchKey)
        )
      : DataSource.filter((data) =>
          data.description.toLowerCase().includes(SearchKey)
        )
    : switchActive
    ? AdvanceDataSource
    : DataSource;

  const fetchData = () => {
    Account.services(
      Services.AccountingVariables.GET_ART_SET_WITH_SHOOT_DATES_NEW,
      {
        department: GlobalConstants.ART_SET_CAPS,
        isAdvance: switchActive,
      }
    )
      .then((res) => {
        setDataSource(res.data.content);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAdvancePaymentData = () => {
    account
      .accountingService(
        Services.AccountingVariables.GET_PRODUCTION_DATA_ADVANCE_PAYMENT,
        GlobalConstants.ART_SET_CAPS
      )
      .then((res) => {
        setAdvanceDataSource(res.data.advancePayments);
      });
    // .catch(err =>
    //   this.setState({
    //     isLoading: true
    //   })
    // );
  };

  const columns = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shoot date",
      dataIndex: "shootDate",
      key: "shootDate",
      width: "20%",
      render: (text) => <Tag className="statusTag">{text}</Tag>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      align: "right",
      width: "20%",
    },

    {
      title: "Pending amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
      align: "right",
      width: "20%",
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      align: "right",
      width: "20%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns.push({
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            props.history.push({
              pathname: `${MenusRoute.ACCOUNTING}/production/artset/${record.shootDate}`,
              state: {
                title: `Artset ${record.shootDate}`,
                details: {
                  productionCategoryDepartment: GlobalConstants.PRODUCTION_CAPS,
                  department: GlobalConstants.ART_SET_CAPS,
                  category: GlobalConstants.PRODUCTION_CAPS,
                  categoryDepartment: GlobalConstants.ART_SET_CAPS,
                },
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  const columns1 = [
    {
      title: "#",
      key: "key",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "16%",
    },
    {
      title: "Scence Location",
      dataIndex: "sceneLocations",
      key: "sceneLocations",
      width: "18%",
      render: (text) => (
        <div className="commaContainer" style={{ width: 200 }}>
          {text &&
            text.map((a) => {
              return <span>{a}</span>;
            })}
        </div>
      ),
    },
    {
      title: "Actual Location",
      dataIndex: "actualLocation",
      key: "actualLocation",
      width: "18%",
    },
    {
      title: "Agreed amount",
      dataIndex: "agreedAmount",
      key: "agreedAmount",
      width: "10%",
      align: "right",
    },
    {
      title: "Advance Amount",
      dataIndex: "advancePayment",
      key: "advancePayment",
      align: "right",
      width: "10%",
    },
  ];

  if (checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT)) {
    columns1.push({
      title: "Action",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <TooltipIcon
          onClick={() =>
            props.history.push({
              pathname: `${MenusRoute.ACCOUNTING}/production/artset/advance`,
              state: {
                title: `Artset `,
                details: {
                  productionCategoryDepartment: GlobalConstants.PRODUCTION_CAPS,
                  department: GlobalConstants.ART_SET_CAPS,
                  category: GlobalConstants.PRODUCTION_CAPS,
                  categoryDepartment: GlobalConstants.ART_SET_CAPS,
                  shootDate: null,
                  isAdvance: switchActive,
                  showShootDate: true,
                },
              },
            })
          }
          icon="more"
        />
      ),
    });
  }

  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Row style={{ padding: 10 }}>
      <div style={{ textAlign: "center", marginBottom: 10 }}>
        <span>
          {" "}
          Shoot date{" "}
          <Switch
            onChange={(record) => {
              console.log(record);
              setSwitchActive(record);
            }}
          />{" "}
          Advance Payment{" "}
        </span>
      </div>

      <Col>
        {switchActive ? (
          <Table
            dataSource={filteredDataSource}
            columns={columns1}
            bordered
            // scroll={{ y: "50vh" }}
            pagination={false}
            loading={IsLoading}
            locale={{
              emptyText: !IsLoading && <RecordNotFound />,
            }}
          />
        ) : (
          <Table
            dataSource={filteredDataSource}
            columns={columns}
            bordered
            // scroll={{ y: "50vh" }}
            pagination={false}
            loading={IsLoading}
            locale={{
              emptyText: !IsLoading && <RecordNotFound />,
            }}
          />
        )}
      </Col>
    </Row>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

export default connect(mapStateToProps, null)(AccProductionArtSetTable);
