import React, { PureComponent } from "react";
import { Select, Row, Col, Button } from "antd";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import { showNotifications } from "components/shared/NotificationComponent";

const { Option } = Select;

export default class PaymentTypeDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this._costumeTabService = new CostumeTabService();
    this.state = {
      selectedPreviousSceneID: null,
      previousSceneIDs: null,
      selectedSceneID: null,
      isButtonLoading: false,
      dropdownType: props.dropdownType,
      sceneCharacterId:
        props.activeRecord.sceneCharacterId || props.activeRecord.characterId,
    };
  }

  componentDidMount() {
    this.getPreviousSceneIDs();
  }

  handleChange = (selectedSceneID) => {
    this.setState({
      selectedSceneID,
    });
  };

  handleSave = () => {
    const { selectedSceneID } = this.state;
    const { refreshData, toggleModal, dropdownType } = this.props;
    const TYPE =
      dropdownType === GlobalConstants.COSTUME
        ? Services.SceneOVerviewVariable.SAVE_COSTUME_TAB_TABLE
        : Services.SceneOVerviewVariable.SAVE_MAKEUP_TAB_TABLE;
    const { description, paymentType, specialCost, sceneCharacterId } =
      this.props.activeRecord;
    let data = {
      description,
      paymentType,
      sceneCharacterId,
      cost: specialCost,
      previousSceneId: selectedSceneID,
    };
    this._costumeTabService
      .costumeTabService(TYPE, data)
      .then((res) => {
        this.setState({
          isButtonLoading: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "New Costume Created!!"
        );
        refreshData();
        toggleModal();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  getPreviousSceneIDs() {
    const { sceneCharacterId, dropdownType } = this.state;
    this._costumeTabService
      .costumeTabService(
        Services.SceneOVerviewVariable.GET_PREVIOUS_SCENE_IDS,
        sceneCharacterId,
        dropdownType
      )
      .then((res) => {
        this.setState({ previousSceneIDs: res.data.previousScenes });
      });
  }

  renderOptions = (previousSceneIDs) => {
    return (
      previousSceneIDs &&
      previousSceneIDs.map((previousScene, i) => (
        <Option key={i} value={previousScene.value}>
          {previousScene.label}
        </Option>
      ))
    );
  };

  render() {
    const {
      previousSceneIDs,
      sceneCharacterId,
      selectedSceneID,
      isButtonLoading,
    } = this.state;
    const { toggleModal } = this.props;
    let selected = null;
    if (selectedSceneID) {
      selected = Object.assign(
        {},
        ...previousSceneIDs.filter(
          (previousScene) => previousScene.value === selectedSceneID
        )
      );
    }
    console.log("selected && selected.label", selected && selected.label);
    return (
      <Row>
        <Col xs={24}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select from the following Scenes"
            onChange={this.handleChange}
          >
            {this.renderOptions(previousSceneIDs)}
          </Select>
          <Row
            type="flex"
            justify="end"
            className="mt-25"
            style={{ borderTop: 1 }}
          >
            <Col
              xs={13}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button onClick={toggleModal}>Close</Button>
              <Button
                type="primary"
                disabled={!selectedSceneID}
                style={{ marginLeft: 4 }}
                onClick={this.handleSave}
                loading={isButtonLoading}
              >{`Proceed with Scene ${selected ? selected.label : ""}`}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
