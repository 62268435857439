import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class BudjetService {
  budjetService(
    type,
    data,
    id,
    budgetDepartment,
    productionCategory,
    isBudget = false
  ) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };

    let projectCategoryDocumentId = {
      projectCategoryDocumentId: null,
    };

    switch (type) {
      case Services.BudjetVariables.GET_PRE_PRODUCTION_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/pre/production/payments`,
          config
        );

      case Services.BudjetVariables.SAVE_PRE_PRODUCTION_DATA:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/pre/production/payments`,
          data,
          config
        );
      case Services.BudjetVariables.UPDATE_PRE_PRODUCTION_DATA:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/pre/production/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.GET_POST_PRODUCTION_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/post/production/payments`,
          config
        );
      case Services.BudjetVariables.SAVE_POST_PRODUCTION_DATA:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/post/production/payments`,
          data,
          config
        );
      case Services.BudjetVariables.UPDATE_POST_PRODUCTION_DATA:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/post/production/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.GET_PRODUCTION_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/production/shoots`,
          config
        );
      case Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/overall/purchase`,
          config
        );
      case Services.BudjetVariables.GET_PRODUCTION_DATA_BY_PURCHASE_MORE:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/production/purchase?department=${data}&isBudget=${true}`,
          config
        );
      case Services.BudjetVariables.GET_PRODUCTION_BUDJET_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/production/payments?budgetDepartment=${budgetDepartment}&shootDate=${data}&isBudget=${isBudget}`,
          config
        );
      case Services.BudjetVariables.UPDATE_PRODUCTION_BUDJET_DATA:
        return axios.patch(
          `${getScriptAPIURL()}/production/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.DEL_PRODUCTION_BUDJET_DATA:
        let url = productionCategory
          ? `${getScriptAPIURL()}/project/${projectId}/production/${id}?budgetDepartment=${budgetDepartment}&productionCategory=${productionCategory}&shootDate=${data}`
          : `${getScriptAPIURL()}/project/${projectId}/production/${id}?budgetDepartment=${budgetDepartment}&shootDate=${data}`;
        return axios.delete(url, config);
      case Services.BudjetVariables.DEL_PRE_PRODUCTION:
        return axios.delete(
          `${getScriptAPIURL()}/project/production/${id}`,
          config
        );
      case Services.BudjetVariables.POST_UPDATE_PRODUCTION:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/production/payments`,
          data,
          config
        );
      case Services.BudjetVariables.POST_UPDATE_PRODUCTION_VENDOR:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/vendors/schedule`,
          data,
          config
        );
      case Services.BudjetVariables.POST_UPDATE_ARTSET:
        return axios.post(
          `${getScriptAPIURL()}/artsets/production`,
          data,
          config
        );
      case Services.BudjetVariables.UPDATE_BUDGET_STATE:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}`,
          data,
          config
        );
      case Services.BudjetVariables.GET_PROJECT_PLAN:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/plans`,
          config
        );
      case Services.BudjetVariables.CREATE_PROJECT_PLAN:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/plans`,
          data,
          config
        );
      case Services.BudjetVariables.DELETE_PROJECT_PLAN:
        return axios.delete(`${getScriptAPIURL()}/project/plans/${id}`, config);
      case Services.BudjetVariables.UPDATE_PROJECT_PLAN:
        return axios.put(
          `${getScriptAPIURL()}/project/plans/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.GET_PROJECT_PLAN_BY_CATEGORY:
        return axios.get(
          `${getScriptAPIURL()}/project/plans/${id}/categories`,
          config
        );
      case Services.BudjetVariables.CREATE_PROJECT_PLAN_BY_CATEGORY:
        return axios.post(
          `${getScriptAPIURL()}/project/plans/${id}/categories`,
          data,
          config
        );
      case Services.BudjetVariables.UPDATE_PROJECT_PLAN_BY_CATEGORY:
        return axios.put(
          `${getScriptAPIURL()}/project/plans/categories/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.DELETE_PROJECT_PLAN_BY_CATEGORY:
        return axios.patch(
          `${getScriptAPIURL()}/project/plans/categories/${id}`,
          projectCategoryDocumentId,
          config
        );
      case Services.BudjetVariables.GET_CAST_TECNICIANS:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/cast/technicians?type=${data}`,
          config
        );
      case Services.BudjetVariables.CAST_TECNICIANS:
        return axios.get(
          `${getScriptAPIURL()}/production-payment-summary?projectId=${projectId}&productionDepartment=${type}&isAdvance=true`,
          config
        );
      case Services.BudjetVariables.TECHNICIAN_CONTRACT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}?paymentType=${data}`,
          config
        );
      case Services.BudjetVariables.GET_VENDOR_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/vendors`,
          config
        );
      case Services.BudjetVariables.CREATE_VENDOR_BY_PROJECT:
        return axios.post(
          `${getScriptAPIURL()}/projects/${projectId}/vendors`,
          data,
          config
        );
      case Services.BudjetVariables.GET_VENDOR_BY_ID:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/vendors/${id}`,
          config
        );
      case Services.BudjetVariables.CREATE_VENDOR_PAYMENT:
        return axios.patch(
          `${getScriptAPIURL()}/projects/${projectId}/vendors/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.DELETE_VENDOR_PAYMENT:
        return axios.patch(`${getScriptAPIURL()}/vendors/${id}`, data, config);
      case Services.BudjetVariables.GET_VENDOR_BY_EQUIPMENT:
        return axios.get(
          `${getScriptAPIURL()}/project/vendor/${id}/equipments`,
          config
        );
      case Services.BudjetVariables.CREATE_VENDOR_BY_EQUIPMENT:
        return axios.post(
          `${getScriptAPIURL()}/project/vendor/${id}/equipments`,
          data,
          config
        );
      case Services.BudjetVariables.UPDATE_VENDOR_BY_EQUIPMENT:
        return axios.put(
          `${getScriptAPIURL()}/project/vendor/equipments/${id}`,
          data,
          config
        );
      case Services.BudjetVariables.DELETE_VENDOR_BY_EQUIPMENT:
        return axios.delete(
          `${getScriptAPIURL()}/project/vendor/equipments/${id}`,
          config
        );
      case Services.BudjetVariables.DEL_ART_SET_SHOOT_DATE_DATA:
        return axios.delete(`${getScriptAPIURL()}/artset/date/${data}`, config);
      case Services.BudjetVariables.GET_VENDOR_EQUIPMENT_DROPDOWN:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/vendor/${id}/equipments`,
          config
        );
      case Services.BudjetVariables.GET_BUDGET_ESTIMATION:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/budgets`,
          config
        );
      case Services.BudjetVariables.GET_CHART_DATA:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/budgets/chart?shootDate=${data}`,
          config
        );
      case Services.BudjetVariables.GET_PREVIOUS_PAYMENT:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/previous/payments?count=${
            data.count
          }&description=${data.description}`,
          config
        );
      case Services.BudjetVariables.UPDATE_EQUIPMENT:
        return axios.patch(
          `${getScriptAPIURL()}/production/equipmet/${id}`,
          data,
          config
        );
      default:
        break;
    }
  }
  getTransport(params = {}) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    return axios.get(
      `${getScriptAPIURL()}/project/${projectId}/production/shoots`,
      { params }
    );
  }
  getBudgeter(params = {}) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    return axios.get(`${getScriptAPIURL()}/project/${projectId}/budgets`, {
      params,
    });
  }
}
