import axios from "axios";
import { EquipmentVariables } from "constants/AppConstants/ServicesConstants";
import { getScriptAPIURL } from "utilities/APIHelper";
import { LocalStorage } from "constants/AppConstants";

export default class EquipmentProjectService {
  equipmentProjectService(type, data, id) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )} `,
      },
    };

    switch (type) {
      case EquipmentVariables.GET_STANDARD_EQUIPMENT:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/equipments`,
          config
        );
      case EquipmentVariables.POST_STANDARD_EQUIPMENT:
        return axios.post(
          `${getScriptAPIURL()}/standard/equipments`,
          data,
          config
        );
      case EquipmentVariables.PUT_STANDARD_EQUIPMENT:
        return axios.put(
          `${getScriptAPIURL()}/standard/equipment/${id}`,
          data,
          config
        );
      case EquipmentVariables.DELETE_STANDARD_EQUIPMENT:
        return axios.delete(
          `${getScriptAPIURL()}/standard/equipments/${id}`,
          config
        );
      case EquipmentVariables.GET_SPECIAL_EQUIPMENT:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/equipments/info`,
          config
        );
      case EquipmentVariables.GET_SPECIAL_EQUIPMENT_BY_DATE:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/equipments/${data}`,
          config
        );
      case EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT_STATE:
        return axios.put(
          `${getScriptAPIURL()}/project/${projectId}/equipments`,
          data,
          config
        );
      case EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT:
        return axios.put(
          `${getScriptAPIURL()}/scene/schedule/equipments/${id}`,
          data,
          config
        );
      case EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT_REQUIRED:
        return axios.put(
          `${getScriptAPIURL()}/scene/equipments/${id}/${data}`,
          null,
          config
        );
      case EquipmentVariables.GET_VENDOR_LIST:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/${data}/vendors`,
          config
        );
      case EquipmentVariables.DELETE_EQUIPMENT:
        return axios.delete(
          `${getScriptAPIURL()}/project/equipments/${data}`,
          config
        );
      default:
        break;
    }
  }
}
