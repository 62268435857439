import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Icon,
  Popconfirm,
  Modal,
  Input,
  Form,
} from "antd";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
  MenusRoute,
} from "constants/AppConstants";
import AccountingService from "services/AccountingService";
import Accounting from "services/AccountingServices";
import { Capitalize, checkFor, StartCase } from "utilities/ValidationHelper";
import { showNotifications } from "components/shared/NotificationComponent";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { handleError } from "utilities/ErrorHandler";

import "./TravelLoading.scss";

const { TextArea } = Input;

class AccTravelLodging extends Component {
  constructor(props) {
    super(props);

    this._accountingService = new AccountingService();
    this.state = {
      modalVisible: false,
      isLoading: false,
      isEnableEdit: null,
      dataSource: [],
      activeName: null,
      currentTravelLodging: "",
    };
    this.Accounting = new Accounting();
  }

  componentDidMount() {
    this.fetchCardData();
    this.getAccountEstimation();
  }
  getAccountEstimation() {
    this.Accounting.services(
      Services.AccountingVariables.GET_ACCOUNT_ESTIMATION
    ).then((res) => {
      this.setState({
        currentTravelLodging: res.data.travelAndLodgeingBudget || "",
      });
    });
  }

  fetchCardData = () => {
    this._accountingService
      .accountingService(
        Services.AccountingVariables.GET_PROJECT_PLAN,
        null,
        null,
        null,
        GlobalConstants.TRAVEL_AND_LODGEING
      )
      .then((res) => {
        this.setState({
          dataSource: res.data.productionAccounts,
        });
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      planName: null,
    });
  };

  handleSubmit = (e) => {
    const { planName, description } = this.state;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const data = {
          name: planName,
          description: description,
          projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        };
        this._accountingService
          .accountingService(
            Services.AccountingVariables.CREATE_PROJECT_PLAN,
            data
          )
          .then((res) => {
            this.setState({ isLoading: false, modalVisible: false });
            this.fetchCardData();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "New Plan Created Successfully"
            );
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            if (
              err.response.data.message ===
              GlobalConstants.NAME_ALREADY_EXISTS_FOR_THE_PROJECT
            ) {
              showNotifications(
                Notifications.ERROR,
                "Failed",
                "Already this name exists"
              );
            } else {
              showNotifications(
                Notifications.ERROR,
                "Failed",
                handleError(
                  err.response && err.response.data && err.response.data.message
                )
              );
            }
            this.props.getPermissions();
          });
      }
    });
  };

  handleUpdate = (id) => {
    const data = {
      name: this.state.planName,
    };
    if (this.state.planName) {
      if (this.state.planName !== this.state.activeName) {
        this._accountingService
          .accountingService(
            Services.AccountingVariables.UPDATE_PROJECT_PLAN,
            data,
            id
          )
          .then((res) => {
            this.setState({
              isEnableEdit: null,
              activeName: null,
            });
            this.fetchCardData();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Plan Updated Successfully"
            );
          })
          .catch((err) => {
            this.setState({ isEnableEdit: false });
            if (
              err.response.data.message ===
              GlobalConstants.NAME_ALREADY_EXISTS_FOR_THE_PROJECT
            ) {
              showNotifications(
                Notifications.ERROR,
                "Failed",
                "Already this name exists"
              );
            } else {
              showNotifications(
                Notifications.ERROR,
                "Failed",
                handleError(
                  err.response && err.response.data && err.response.data.message
                )
              );
            }
            this.props.getPermissions();
          });
      } else {
        this.setState({
          isEnableEdit: null,
          activeName: null,
        });
      }
    } else {
      this.setState({
        isEnableEdit: null,
        activeName: null,
      });
    }
  };

  handleDelete = (id) => {
    this._accountingService
      .accountingService(
        Services.AccountingVariables.DELETE_PROJECT_PLAN,
        "",
        id
      )
      .then((res) => {
        this.setState({ isEnableEdit: false });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Plan deleted successfully"
        );
        this.fetchCardData();
      })
      .catch((err) => {
        this.setState({ isEnableEdit: false });
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
        this.props.getPermissions();
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { activePermisision } = this.props;
    const {
      modalVisible,
      isEnableEdit,
      isLoading,
      dataSource,
      currentTravelLodging,
    } = this.state;

    return (
      <Row className="mb-25">
        <Col xs={24} style={{ paddingBottom: 20 }}>
          <Title hasBack {...this.props}>
            <TitleText>
              Accounting - Travel & Lodging {currentTravelLodging}
            </TitleText>
          </Title>
        </Col>
        <Col
          xl={{ offset: 1, span: 21 }}
          lg={{ offset: 1, span: 21 }}
          md={{ offset: 1, span: 21 }}
          style={{ border: "1px solid #ccc", padding: 10 }}
        >
          <h3
            className=""
            style={{
              backgroundColor: "#001529",
              color: "#fff",
              padding: "10px",
              textAlign: "center",
            }}
          >
            PROJECT PLANNING
          </h3>
          <Row>
            {checkFor(activePermisision, GlobalConstants.PERMISSION_ADD) && (
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ modalVisible: true, isEnableEdit: null });
                }}
                style={{ marginBottom: 10 }}
              >
                <Icon type="plus" style={{ fontSize: "15px" }} /> Project Plan
              </Button>
            )}
          </Row>

          <React.Fragment>
            {dataSource &&
              dataSource.map((list, index) => (
                <Col
                  xl={{ span: 8 }}
                  lg={{ span: 10 }}
                  md={{ span: 16 }}
                  xs={{ span: 24 }}
                >
                  <div className="card travelCard">
                    <Fragment>
                      {checkFor(
                        activePermisision,
                        GlobalConstants.PERMISSION_DELETE
                      ) && (
                        <div className="deleteCard">
                          <Popconfirm
                            title="Are you sure delete this plan?"
                            onConfirm={() =>
                              this.handleDelete(list.projectPlanningId)
                            }
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <a href="#">
                              <Tooltip
                                title={GlobalConstants.DEL}
                                placement={GlobalConstants.RIGHT}
                              >
                                <Icon
                                  type="delete"
                                  style={{ fontSize: "13px" }}
                                />
                              </Tooltip>
                            </a>
                          </Popconfirm>
                        </div>
                      )}
                      <Row>
                        <Col xl={24} className="vertical-center pl-15">
                          <Row
                            type="flex"
                            justify="space-around"
                            style={{ width: "100%" }}
                          >
                            {isEnableEdit !== index ? (
                              <a className="font-600 ">
                                <Link>
                                  <h4
                                    className="primary-font-color"
                                    style={{ cursor: "default" }}
                                  >
                                    {Capitalize(list.description)}
                                  </h4>
                                </Link>
                              </a>
                            ) : (
                              <Form.Item style={{ marginBottom: 5 }}>
                                {this.props.form.getFieldDecorator("planName", {
                                  initialValue: list.description,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Enter your Plan Name!",
                                    },
                                  ],
                                })(
                                  <Input
                                    type="text"
                                    name="planName"
                                    autoFocus
                                    onChange={(e) => this.handleChange(e)}
                                    onBlur={() =>
                                      this.handleUpdate(list.projectPlanningId)
                                    }
                                    onPressEnter={() =>
                                      this.handleUpdate(list.projectPlanningId)
                                    }
                                  />
                                )}
                              </Form.Item>
                            )}
                            {isEnableEdit !== index &&
                              checkFor(
                                activePermisision,
                                GlobalConstants.PERMISSION_EDIT
                              ) && (
                                <span
                                  className="travel-name-edit-icon"
                                  onClick={(e) =>
                                    this.setState({
                                      isEnableEdit: index,
                                      activeName: list.description,
                                    })
                                  }
                                >
                                  <Icon type="edit" />
                                </span>
                              )}
                          </Row>
                          <p>
                            {checkFor(
                              activePermisision,
                              GlobalConstants.PERMISSION_EDIT
                            ) && (
                              <Link
                                to={{
                                  pathname: `${MenusRoute.ACCOUNTING}/travellist/${list.productionAccountId}`,
                                  state: {
                                    category:
                                      GlobalConstants.TRAVEL_AND_LODGEING,
                                    fromTravel: true,
                                    title: StartCase(list.description),
                                    hidePaymentType: true,
                                  },
                                }}
                              >
                                View More
                              </Link>
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Fragment>
                  </div>
                </Col>
              ))}
          </React.Fragment>
        </Col>
        <Modal
          destroyOnClose
          title="Add Project Plan"
          visible={modalVisible}
          className="createModal"
          onCancel={this.handleCancel}
          footer={
            <Row type="flex" justify="end" gutter="12">
              <Col>
                <Button type="default" onClick={this.handleCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={(e) => this.handleSubmit(e)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          }
        >
          <Row>
            <Col xl={12}>
              <label className="font-600">Plan Name </label>
              <Form.Item>
                {getFieldDecorator("createplanName", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Plan Name!",
                    },
                  ],
                })(
                  <Input
                    autoFocus
                    type="text"
                    name="planName"
                    onChange={(e) => this.handleChange(e)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <label className="font-600">Description </label>
              <Form.Item style={{ paddingTop: 5 }}>
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Description!",
                    },
                  ],
                })(
                  <TextArea
                    style={{ height: 100 }}
                    name="description"
                    onChange={(e) => this.handleChange(e)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(
      PermissionPage.ACCOUNTING,
      menus
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AccTravelLodging));
