import React, { Component } from "react";
import { Button } from "antd";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import { Link } from "react-router-dom";
import { Capitalize } from "utilities/ValidationHelper";
import { connect } from "react-redux";
import { setActiveTab } from "store/action";
import CostumeTable from "./CostumeTable";
import { handleError } from "utilities/ErrorHandler";
import { showNotifications } from "components/shared/NotificationComponent";

class CostumesTab extends Component {
  state = {
    data: [],
    dataFetched: false,
    type: "ADD",
    isModalVisible: false,
    budgetId: null,
    recordData: null,
    activeSceneId: null,
  };
  constructor(props) {
    super(props);
    this._costumeService = new CostumeTabService();
  }

  componentDidMount() {
    localStorage.removeItem(GlobalConstants.REDIRECT_TO);
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.setActiveTab("1");
    // localStorage.removeItem(GlobalConstants.REDIRECT_TO);
  }

  fetchData = () => {
    this._costumeService
      .costumeTabService(
        Services.SceneOVerviewVariable.GET_COSTUMES,
        null,
        null,
        this.state.activeSceneId
      )
      .then((res) => {
        console.log("res..", res);
        this.setState({ data: res.data.costumes, fetchData: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    return (
      <div>
        {/* <Table
          columns={columns}
          dataSource={this.state.data}
          pagination={false}
          style={{ padding: "20px 50px" }}
          loading={this.state.dataFetched}
        />
        <UpdateCostumes
          // value={record}
          type={type}
          isModalVisible={isModalVisible}
          closeModal={this.closeModal}
          budgetId={budgetId}
          recordData={recordData}
          refreshData={this.fetchData}
        /> */}
        <CostumeTable />
      </div>
    );
  }
}

const mapStateToProps = ({ scene }) => {
  return {
    activeSceneId: scene.activeSceneId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostumesTab);
