import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Row,
  Col,
  Select,
  Tooltip,
  Tag,
  Checkbox,
} from "antd";
import GlobalService from "services/GlobalService";
import {
  Services,
  Notifications,
  GlobalConstants,
  LocalStorage,
  Screens,
} from "constants/AppConstants";
import { showNotifications } from "components/shared/NotificationComponent";
import EquipmentServices from "services/EquipmentServices";
import EquipmentProjectService from "services/EquipmentProjectService";
import moment from "moment";
import { EquipmentVariables } from "constants/AppConstants/ServicesConstants";
import RecordNotFound from "components/shared/RecordNotFound";
import { handleError } from "utilities/ErrorHandler";

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {
  getInput = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    if (this.props.dataIndex === "quantity") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
              {
                pattern: new RegExp("^(0|[1-9][0-9]*)$"),
                message: "Enter numbers only",
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    }
    if (this.props.inputType === "number") {
      return (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: record[dataIndex],
          })(
            <Input
              ref={(node) => (this.input = node)}
              onPressEnter={this.save}
              onBlur={this.save}
            />
          )}
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
            // {
            //   pattern: new RegExp("^(0|[1-9][0-9]*)$"),
            //   message: "Enter numbers only"
            // }
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            type="number"
          />
        )}
      </Form.Item>
    );
  };

  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      this.getInput(form)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class EditSpecialEquipment extends Component {
  getEquipmentDropDown = () => {
    console.log("Equipment dropdown call");
    this._equipmentService
      .service(
        Services.EquipmentVariables.EQUIPMENT_DROPDOWN,
        null,
        null,
        this.state.designationTypeValue
      )
      .then((res) => {
        console.log("dropdown", res.data);
        this.setState({ equipmentDropDown: res.data.equipments });
      });
  };

  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._equipmentService = new EquipmentServices();
    this._equipmentProjectService = new EquipmentProjectService();
    this.designationTypeValue = this.designationTypeValue.bind(this);
    this.designationBlurChanges = this.designationBlurChanges.bind(this);
    this.onChange = this.onChange.bind(this);
    this.columns = [
      {
        title: "#",
        dataIndex: "sno",
        width: "8%",
        align: "center",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Equipment",
        dataIndex: "equipment",
        align: "center",
        width: "25%",
      },
      {
        title: "Payment Type",
        dataIndex: "paymentType",
        width: "15%",
        align: "center",
        // editable: true
        render: (text, record) => {
          return (
            <Select
              showSearch
              style={{ width: "100%" }}
              className="border-none"
              placeholder="Select type"
              onChange={(value) => this.onChange(value, record, "paymentType")}
              value={text ? text : ""}
              disabled={!record.equipment}
            >
              {this.state.paymentTypeData &&
                this.state.paymentTypeData.map((option, i) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          );
        },
      },
      {
        title: "Rate",
        dataIndex: "price",
        width: "15%",
        editable: true,
        align: "center",
      },
      {
        title: "Is Required",
        dataIndex: "isRequired",
        width: "15%",
        align: "center",
        render: (text, record) => (
          <Checkbox
            onClick={(e) =>
              this.handleRequired(e, record.sceneScheduleEquipmentId)
            }
            checked={text}
            disabled={!record.equipment}
          ></Checkbox>
        ),
      },
    ];

    this.state = {
      dataSource: [],
      count: 0,
      page: null,
      equipmentDropDown: [],
      equipment: null,
      designationTypeValue: null,
      isDisableInput: false,
      equipmentDetails: null,
    };
  }

  componentDidMount() {
    this.getEquipmentDropDown();
    this.getPaymentTypeData();
    this.getStatusData();
    this.checkForEmpty(this.state.dataSource);
    this.setEquipmentDetails();
  }

  handleRequired = (e, value) => {
    console.log("isrequired", e.target.checked);
    this._equipmentProjectService
      .equipmentProjectService(
        EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT_REQUIRED,
        e.target.checked,
        value
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully!!"
        );
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  setEquipmentDetails() {
    if (this.props.location.state) {
      this.setState(
        {
          equipmentDetails: this.props.location.state.specialEquipmentDetails,
        },
        () => {
          if (
            this.state.equipmentDetails &&
            this.state.equipmentDetails.shootDate
          ) {
            this.fetchData(
              moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
            );
          }
        }
      );
    }
  }

  getPaymentTypeData() {
    console.log("payment type call");
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          paymentTypeData: res.data,
        });
      });
  }

  getStatusData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({
          statusData: res.data,
        });
      });
  }

  checkForEmpty = (dataSource) => dataSource.length === 0 && this.handleAdd();
  designationTypeValue(value) {
    if (value && value.trim().length > 0) {
      console.log("val;;ue", value);
      this.setState({
        designationTypeValue: value,
      });
    }
    this.getEquipmentDropDown();
  }

  designationBlurChanges(value, record, type) {
    this.getEquipmentDropDown();
    let designation = this.state.designationTypeValue;
    let a = this.state.equipmentDropDown || [];
    console.log("a", a);
    let b = a.map((a) => a.name);
    console.log("b", b);
    let dx = true;
    if (designation != null) {
      new Promise((resolve) => {
        b.forEach((x) => {
          if (x && x.toString().trim() != designation.toString().trim()) {
            dx = false;
          } else {
            dx = true;
          }
        });

        resolve();
      })
        .then(() => {
          if (!dx) {
            a.push({ label: designation, value: designation });
          }
        })
        .then(() => {
          this.setState({
            equipmentDropDown: a,
          });
        })
        .then(() => {
          console.log("dsy", designation);
          if (!this.state.isDisableInput) {
            this.onChange(designation, record, type);
            record.equipment = a;
          }
        });
    }
  }

  fetchData = (date) => {
    this.setState({
      count: 0,
    });
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.GET_SPECIAL_EQUIPMENT_BY_DATE,
        date
      )
      .then((res) => {
        console.log("response data" + res);
        let dataSource = [];
        res.data.equipments &&
          res.data.equipments.map((datum, i) => {
            dataSource.push({ ...datum, key: this.state.count });
            this.setState({
              count: this.state.count + 1,
            });
          });

        this.setState(
          {
            dataSource,
            statusValue:
              res.data.equipments &&
              res.data.equipments[0] &&
              res.data.equipments[0].state,
          },
          () => {
            this.checkForEmpty(this.state.dataSource);
          }
        );
      })
      .catch((err) => {
        console.log("song fetch data", err);
      });
  };

  handleUpdate = (record) => {
    const data = {
      paymentType: record.paymentType,
      price: record.price,
    };

    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT,
        data,
        record.sceneScheduleEquipmentId
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully!!"
        );
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleStatus = (value) => {
    const data = {
      shootDate:
        this.state.equipmentDetails &&
        moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY"),
      state: value,
    };
    this._equipmentProjectService
      .equipmentProjectService(
        EquipmentVariables.UPDATE_SPECIAL_EQUIPMENT_STATE,
        data
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showNotifications(
          Notifications.SUCCESS,
          "Success",
          "Equipment Updated Successfully !!"
        );
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          err.response &&
            err.response.data.errors[0] &&
            err.response.data.errors[0].message,
          handleError(
            // err.response && err.response.data && err.response.data.message
            err.response &&
              err.response.data.errors[0] &&
              err.response.data.errors[0].message
          )
        );
      });
  };

  handleDelete = (key, id) => {
    const dataSource = [...this.state.dataSource];
    this.setState(
      {
        dataSource: dataSource.filter((item) => item.key !== key),
      },
      () => {
        this.checkForEmpty(this.state.dataSource);
      }
    );
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.DELETE_STANDARD_EQUIPMENT,
        null,
        id
      )
      .then((res) => {
        console.log(res);
        showNotifications(Notifications.SUCCESS, "Successfully", " Deleted !!");
        if (
          this.state.equipmentDetails &&
          this.state.equipmentDetails.shootDate
        ) {
          this.fetchData(
            moment(this.state.equipmentDetails.shootDate).format("DD-MM-YYYY")
          );
        }
      });
  };

  handleAdd = () => {
    console.log("handle add called");
    const { count, dataSource } = this.state;
    this.setState({
      count: count + 1,
    });
    const newData = {
      key: count,
      equipment: "",
      price: "",
      paymentType: "",
      projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
    };
    this.setState({
      dataSource: [...dataSource, newData],
      // count:count + 1
    });
  };

  onChange(value, record, type) {
    if (value) {
      this.setState({ isDisableInput: true });
    }

    this.handleSave(record, value, type);

    const lengthOfTheDataSource = this.state.dataSource.length - 1;
    const newData = [...this.state.dataSource];
    const indexOfTheCurrentRow = newData.findIndex(
      (data) => record.key === data.key
    );
  }

  handleSave = (row, value = null, type) => {
    console.log("value.....", value);
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    switch (type) {
      case "equipment":
        if (value) {
          let modifyItem = newData[index];
          modifyItem.equipment = value;
        }
        break;
      case "paymentType":
        if (value) {
          let modifyItem = newData[index];
          modifyItem.paymentType = value;
        }
        break;
      default:
        break;
    }

    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, () => {
      console.log("dataSource save", this.state.dataSource);
    });
    console.log("row....", row);
    if (row.sceneScheduleEquipmentId) {
      this.handleUpdate(row);
    }
  };

  render() {
    console.log("this.state.statusValue", this.state.statusValue);
    const { getFieldDecorator } = this.props.form;
    const { dataSource, equipmentDetails } = this.state;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "description" ? "number" : "text",
          editable: record.equipment,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <Fragment>
        <Row>
          <Col xl={24}>
            <Title hasBack {...this.props}>
              <TitleText>Special Equipment </TitleText>
            </Title>
          </Col>
          <br />
        </Row>
        <Row type="flex" justify="center">
          <Col xl={20}>
            <Row style={{ paddingTop: 20, paddingBottom: 30 }}>
              <Col md={24}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    paddingTop: 10,
                  }}
                >
                  <label className="text-right font-600">Date : &nbsp; </label>
                  <p>
                    {equipmentDetails &&
                      moment(equipmentDetails.shootDate).format("DD-MM-YYYY")}
                  </p>
                </Row>
              </Col>
              <Col md={24}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    paddingTop: 10,
                  }}
                >
                  <label className="text-right font-600">
                    Scene Location : &nbsp;{" "}
                  </label>
                  <p>{equipmentDetails && equipmentDetails.sceneLocation}</p>
                </Row>
              </Col>
              <Col md={24}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    paddingTop: 10,
                  }}
                >
                  <label className="text-right font-600">
                    Actual Location : &nbsp;{" "}
                  </label>
                  <p>{equipmentDetails && equipmentDetails.actualLocation}</p>
                </Row>
              </Col>
              <Col md={24}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    paddingTop: 10,
                  }}
                >
                  <label className="text-right font-600">
                    Scene Number : &nbsp;{" "}
                  </label>
                  <div className="sceneNumber">
                    {equipmentDetails &&
                      equipmentDetails.scenes.map((scene, i) => (
                        <span>
                          <Tag
                            className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                            style={{
                              color: "white",
                              backgroundColor: "#ccc",
                            }}
                            onClick={() => {
                              this.props.history.push(
                                "/project/screen-overview"
                              );
                              localStorage.setItem(
                                GlobalConstants.REDIRECT_TO,
                                Screens.GENERAL
                              );
                              localStorage.setItem(
                                LocalStorage.ACTIVE_SCENE_ID,
                                scene.sceneId
                              );
                            }}
                          >
                            <Link style={{ color: "white" }}>
                              {scene.sceneNumber}
                            </Link>
                          </Tag>
                        </span>
                      ))}
                  </div>
                </Row>
              </Col>
              <Col md={24}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: 10,
                  }}
                >
                  <Col xl={1}>
                    <label
                      className="text-right font-600"
                      style={{ marginRight: 10 }}
                    >
                      Status :
                    </label>
                  </Col>
                  <Col>
                    <Form.Item>
                      {getFieldDecorator("status", {
                        initialValue: this.state.statusValue,
                        // rules: [
                        //   { required: true, message: "Please input your username!" }
                        // ]
                      })(
                        <Select
                          showSearch
                          style={{ width: 150 }}
                          className="border-none"
                          placeholder="Select Status"
                          // defaultValue={
                          //   this.state.statusValue ? this.state.statusValue : ""
                          // }
                          onChange={(value) => this.handleStatus(value)}
                        >
                          {this.state.statusData &&
                            this.state.statusData.map((option, i) => (
                              <Option value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col xl={24}>
                <Table
                  components={components}
                  rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  locale={{
                    emptyText: <RecordNotFound />,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Form.create()(EditSpecialEquipment);
