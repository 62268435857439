import { Col, Input, Row, notification } from "antd";
import { Label } from "components/shared/Label";
import React, { useEffect, useState } from "react";
import EpisodeService from "services/EpisodeService";

const EpisodeModal = () => {
  const [episodeData, setEpisodeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchEpisodeData();
  }, []);

  const fetchEpisodeData = () => {
    const payload = {
      size: 10,
      page: 0,
      sort: "episode.episodeNumber,asc",
    };
    setIsLoading(true);
    EpisodeService.getAllEpisode(payload).then((res) => {
      setIsLoading(false);
      setEpisodeData([]);
      //   setEpisodeData(res?.data?.content || []);
    });
  };

  const onBlurCheck = (data = {}) => {
    if (data?.title !== "") updateEpisode(data);
  };

  const changeValue = (data = "", allData = {}, key) => {
    let totalData = episodeData.map((a) => {
      if (a?.id === allData?.id) {
        a[key] = data;
      }
      return a;
    });
    setEpisodeData(totalData);
  };

  const updateEpisode = (data = {}) => {
    const payload = {
      title: data?.title,
    };
    EpisodeService.updateEpisode(data?.id, payload).then((res) => {
      notification.success({
        message: "Episode Updated Successfully!",
      });
    });
  };

  return (
    <div>
      {episodeData?.map((episode) => {
        return (
          <Row style={{ display: "flex", gap: 10, marginBottom: 12 }}>
            <Col>
              <Label>Episode Number</Label>
              <Input
                style={{ width: "100%" }}
                min={1}
                placeholder="Episode Number"
                defaultValue={episode?.episodeNumber}
                disabled
              />
            </Col>
            <Col>
              <Label>Episode Title</Label>
              <Input
                placeholder="Episode Title"
                defaultValue={episode?.title}
                onChange={(e) => changeValue(e.target.value, episode, "title")}
                onBlur={(e) => onBlurCheck(episode)}
              />
            </Col>
          </Row>
        );
      })}
      {!isLoading && !episodeData?.length && (
        <div
          style={{
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: 16, color: "black" }}>
            No Episode Found !
          </span>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: 16, color: "black" }}>Loading...</span>
        </div>
      )}
    </div>
  );
};

export default EpisodeModal;
