import React, { Component } from "react";
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Tooltip,
  DatePicker,
} from "antd";
import { isEmpty, debounce } from "lodash";
import "antd/dist/antd.css";

import "./Filterform.scss";
import LocationServices from "services/LocationServices";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import { Services, GlobalConstants } from "constants/AppConstants";
import { ScriptService } from "services";
import GlobalService from "services/GlobalService";
import ActorsServices from "services/ActorsServices";

const { Option } = Select;

class FilterFormScheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SceneNoSelectedKeys: [],
      LocationSelectedKeys: [],
      EnvironmentType: "",
      EnvironmentTime: "",
      Description: "",
      resetForms: false,
      isSearchActive: false,
      LocationDataSource: [],
      ActualLocationDataSource: [],
      CallSheet: "",
      ActualLocationSelectedKeys: [],
      ShootStartDate: null,
      ShootEndDate: null,
      actorOptions: [],
      characterOptions: [],
      selectedActors: [],
      selectedCharacters: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this._globalService = new GlobalService();
    this._scriptService = new ScriptService();
    this._locationService = new LocationTabService();
    this._actorService = new ActorsServices();
    this.handleDesc = debounce(this.handleDesc, 300);
  }

  componentDidMount() {
    this.getEnvironmentTimeData();
    this.getEnvironmentTypeData();
    this.getSceneNumberDataSource();
    this.getLocationDataSource();
    this.getActualLocationDataSource();
    this.getCallSheet();
    this.getActorOptions();
    this.getCharacterOptions();
  }

  getActualLocationDataSource = () => {
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_ACTUAL_LOCATION_LIST_BY_PROJECT
      )
      .then((res) => {
        this.setState({
          ActualLocationDataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCallSheet = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        Services.GlobalVariables.CALL_SHEET
      )
      .then((res) => {
        this.setState({
          callSheetOptions: res.data,
        });
      })
      .catch((err) => {});
  };

  getActorOptions = () => {
    this._actorService
      .actorsServices(Services.ActorVariables.GET_ACTOR_OPTIONS)
      .then((res) => {
        this.setState({
          actorOptions: (res && res.data && res.data.characters) || [],
        });
      });
  };
  getCharacterOptions = () => {
    this._actorService
      .actorsServices(Services.ActorVariables.GET_CHARACTER_OPTIONS)
      .then((res) => {
        this.setState({
          characterOptions: (res && res.data && res.data.characters) || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSceneNumberDataSource = () => {
    // let sceneNumberDatas = [];
    // this.state.initialData &&
    //   this.state.initialData.map(a => {
    //     sceneNumberDatas.push(a.sceneNumber);
    //   });

    this._scriptService
      .scriptService(Services.SceneOVerviewVariable.GET_SCENE_NUMBERS_DROPDOWN)
      .then((res) => {
        let sceneNumberDataSource = res.data.sceneNumbers;
        sceneNumberDataSource = sceneNumberDataSource.map(
          (scene) => scene.label
        );
        this.setState({
          sceneNumberDataSource,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // this.setState({ sceneNumberDataSource: sceneNumberDatas });
  };

  getLocationDataSource() {
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_LIST_BY_PROJECT
      )
      .then((res) => {
        this.setState({
          LocationDataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getEnvironmentTimeData = () => {
    let time = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.TIME
      )
      .then((res) => {
        this.setState({ time: res.data });
        res.data.map((option, i) => {
          time.push(<Option value={option.value}>{option.label}</Option>);
        });
      });
    return time;
  };
  getEnvironmentTypeData = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ENVIRONMENT
      )
      .then((res) => {
        this.setState({ environment: res.data });
        res.data.map((option, i) => {
          template.push(<Option value={option.value}>{option.label}</Option>);
        });
      });
    return template;
  };

  getLocation = () => {
    this._locationService
      .locationTabService(
        Services.SceneOVerviewVariable.GET_LOCATION_LIST_BY_PROJECT
      )
      .then((res) => {
        this.setState({
          LocationDataSource: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleReset = () => {
    this.props.handleClear();
    let p = new Promise((resolve) => {
      this.setState({
        resetForms: true,
      });
      resolve();
    });

    p.then(() => {
      this.setState({
        resetForms: false,
        isSearchActive: false,
        SceneNoSelectedKeys: [],
        LocationSelectedKeys: [],
        EnvironmentType: "",
        EnvironmentTime: "",
        Description: "",
        ActualLocationSelectedKeys: [],
        CallSheet: "",
        ShootStartDate: "",
        ShootEndDate: "",
      });
    });
  };

  checkHasValues = (
    SceneNoSelectedKeys,
    LocationSelectedKeys,
    EnvironmentTime,
    EnvironmentType,
    Description
  ) =>
    !isEmpty(SceneNoSelectedKeys) ||
    !isEmpty(LocationSelectedKeys) ||
    !isEmpty(EnvironmentTime) ||
    !isEmpty(EnvironmentType) ||
    Description;

  handleSubmit() {
    let SceneNoSelectedKeys = this.state.SceneNoSelectedKeys;
    let LocationSelectedKeys = this.state.LocationSelectedKeys;
    let EnvironmentTime = this.state.EnvironmentTime;
    let EnvironmentType = this.state.EnvironmentType;
    let Description = this.state.Description;
    const {
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
    } = this.state;
    // if (
    //   this.checkHasValues(
    //     SceneNoSelectedKeys,
    //     LocationSelectedKeys,
    //     EnvironmentTime,
    //     EnvironmentType,
    //     Description
    //   )
    // ) {
    this.props.handleSubmit(
      SceneNoSelectedKeys,
      LocationSelectedKeys,
      EnvironmentTime,
      EnvironmentType,
      Description,
      ActualLocationSelectedKeys,
      CallSheet,
      ShootStartDate,
      ShootEndDate,
      this.state.selectedActors,
      this.state.selectedCharacters
    );
    // }
  }

  handleLocation = (SelectedKeys) => {
    const { LocationDataSource } = this.state;

    const LocationSelectedKeys = [];
    SelectedKeys.forEach((keyLocation) => {
      LocationDataSource.filter((location) => {
        if (location.label === keyLocation) {
          LocationSelectedKeys.push(location.value);
        }
      });
    });

    this.setState(
      {
        LocationSelectedKeys,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );
  };

  handleScenes = (SceneNoSelectedKeys) =>
    this.setState(
      {
        SceneNoSelectedKeys,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );

  handleEnv = (EnvironmentType) =>
    this.setState(
      {
        EnvironmentType,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );

  handleDesc = (Description) => {
    this.setState(
      {
        Description,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );
  };

  handleCallSheet = (CallSheet) => {
    this.setState(
      {
        CallSheet,
      },
      () => this.handleSubmit()
    );
  };

  handleActualLocation = (SelectedKeys) => {
    const { ActualLocationDataSource } = this.state;

    const ActualLocationSelectedKeys = [];
    SelectedKeys.forEach((keyLocation) => {
      ActualLocationDataSource.filter((location) => {
        if (location.label === keyLocation) {
          ActualLocationSelectedKeys.push(location.value);
        }
      });
    });

    this.setState(
      {
        ActualLocationSelectedKeys,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );
  };

  handleStartDate = (value, ShootStartDate) => {
    this.setState(
      {
        ShootStartDate,
      },
      () => this.handleSubmit()
    );
  };

  handleEndDate = (value, ShootEndDate) => {
    this.setState(
      {
        ShootEndDate,
      },
      () => this.handleSubmit()
    );
  };

  handleEnvTime = (EnvironmentTime) =>
    this.setState(
      {
        EnvironmentTime,
        isSearchActive: true,
      },
      () => this.handleSubmit()
    );

  handleCharacterSelection = (selected) => {
    this.setState(
      {
        selectedCharacters: selected,
      },
      () => this.handleSubmit()
    );
  };
  handleActorSelection = (selected) => {
    this.setState(
      {
        selectedActors: selected,
      },
      () => this.handleSubmit()
    );
  };

  render() {
    const { form } = this.props;
    const {
      resetForms,
      sceneNumberDataSource,
      LocationDataSource,
      time,
      environment,
      callSheetOptions,
      ActualLocationDataSource,
      characterOptions,
      actorOptions,
    } = this.state;
    const { Option } = Select;
    return (
      <Form className="search-form">
        {!resetForms && (
          <Row style={{ margin: 0 }}>
            <Row gutter={8}>
              <Col
                xl={{ span: 4 }}
                sm={{ span: 12 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  placeholder="Scene no"
                  style={{ width: "100%" }}
                  // onBlur={this.handleSubmit}
                  onChange={this.handleScenes}
                >
                  {sceneNumberDataSource &&
                    sceneNumberDataSource.map((option) => (
                      <Option value={option}>{option}</Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 6 }}
                sm={{ span: 10 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={5}
                  placeholder="Location"
                  // onBlur={this.handleSubmit}
                  onChange={this.handleLocation}
                >
                  {LocationDataSource &&
                    LocationDataSource.map((option) => (
                      <Option value={option.label}>{option.label}</Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 4 }}
                sm={{ span: 12 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={2}
                  name="EnvironmentType"
                  placeholder="INT/EXT"
                  allowClear
                  // onBlur={this.handleSubmit}
                  onChange={this.handleEnv}
                >
                  {environment &&
                    environment.map((a, i) => (
                      <Option value={a.value}>{a.label}</Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 4 }}
                sm={{ span: 12 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={2}
                  name="EnvironmentType"
                  // name="EnvironmentTime"
                  placeholder="DAY/NIGHT"
                  allowClear
                  // onBlur={this.handleSubmit}
                  onChange={this.handleEnvTime}
                >
                  {time &&
                    time.map((a, i) => (
                      <Option value={a.value}>{a.value}</Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 6 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <Input
                  name="Description"
                  allowClear
                  placeholder="Description"
                  onChange={(e) => this.handleDesc(e.target.value)}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col
                xl={{ span: 4 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  maxTagTextLength={2}
                  placeholder="Call sheet"
                  style={{ width: "100%" }}
                  // onBlur={this.handleSubmit}
                  onChange={this.handleCallSheet}
                >
                  {callSheetOptions &&
                    callSheetOptions.map((option) => (
                      <Option value={option.value}>{option.label}</Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 6 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={5}
                  placeholder="Actual Location"
                  // onBlur={this.handleSubmit}
                  onChange={this.handleActualLocation}
                >
                  {ActualLocationDataSource &&
                    ActualLocationDataSource.map((option) => (
                      <Option value={option.label}>{option.label}</Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 4 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select start date"
                  // defaultValue={text ? moment(text, dateFormat) : ""}
                  onChange={(value, dateString) =>
                    this.handleStartDate(value, dateString)
                  }
                  format={GlobalConstants.DD_MM_YYYY}
                  // disabledDate={current => this.disabledDate(current)}
                  // disabledDate={d => this.disableDateHelper(d)}
                  // disabled={!record.sceneLocationId}
                  // autoFocus
                />
              </Col>
              <Col
                xl={{ span: 4 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select end date"
                  onChange={(value, dateString) =>
                    this.handleEndDate(value, dateString)
                  }
                  format={GlobalConstants.DD_MM_YYYY}
                  // defaultValue={text ? moment(text, dateFormat) : ""}
                  // onChange={(value, dateString) =>
                  //   this.dateChange(
                  //     value,
                  //     dateString,
                  //     record,
                  //     "setWorkStartDate"
                  //   )
                  // }
                  // format={dateFormat}
                  // disabledDate={current => this.disabledDate(current)}
                  // disabledDate={d => this.disableDateHelper(d)}
                  // disabled={!record.sceneLocationId}
                  // autoFocus
                />
              </Col>
              <Col
                xl={{ span: 6 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={5}
                  placeholder="Actor"
                  // onBlur={this.handleSubmit}
                  onChange={this.handleActorSelection}
                >
                  {actorOptions &&
                    actorOptions.map((option) => (
                      <Option value={option.value}>{option.label}</Option>
                    ))}
                </Select>
              </Col>

              <Col
                xl={{ span: 4 }}
                sm={{ span: 24 }}
                style={{ paddingTop: "5px" }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={1}
                  maxTagTextLength={5}
                  placeholder="Character"
                  // onBlur={this.handleSubmit}
                  onChange={this.handleCharacterSelection}
                >
                  {characterOptions &&
                    characterOptions.map((option) => (
                      <Option value={option.characterId}>
                        {option.characterName}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col
                xl={{ span: 2, offset: 0 }}
                sm={{ offset: 6, span: 12 }}
                style={{ paddingTop: "5px" }}
              >
                <Row type="flex" style={{ justifyContent: "space-evenly" }}>
                  <Tooltip
                    title={GlobalConstants.RESET}
                    placement={GlobalConstants.TOP}
                  >
                    <Button
                      onClick={this.handleReset}
                      style={{ position: "relative", width: "100%" }}
                      type="primary"
                    >
                      <Icon type="reload" style={{ fontSize: "15px" }} />
                    </Button>
                  </Tooltip>
                </Row>
              </Col>
            </Row>
          </Row>
        )}
      </Form>
    );
  }
}
export default Form.create()(FilterFormScheduler);
