import { LocalStorage, Services } from "constants/AppConstants";
import axios from "axios";
import { getScriptAPIURL } from "utilities/APIHelper";
export default class CostumeTabService {
  costumeTabService(type, data, budjetId, sceneId = null) {
    let finalSceneId = "";
    if (sceneId) {
      finalSceneId = sceneId;
    } else {
      finalSceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    }
    // let sceneId = localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID);
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.SceneOVerviewVariable.GET_COSTUMES:
        return axios.get(
          `${getScriptAPIURL()}/scenes/${finalSceneId}/costumes`,
          config
        );
      case Services.SceneOVerviewVariable.GET_CHARC_NAME_LIST:
        return axios.get(
          // `${getScriptAPIURL()}/scene/${finalSceneId}/characters?types=MAIN,SUPPORTING`,
          `${getScriptAPIURL()}/projects/${projectId}/character?sceneId=${finalSceneId}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_CHARC_NAME_LIST_REPORT:
        return axios.get(
          // `${getScriptAPIURL()}/scene/${finalSceneId}/characters?types=MAIN,SUPPORTING`,
          `${getScriptAPIURL()}/projects/${projectId}/character?isActor=true`,
          config
        );
      case Services.SceneOVerviewVariable.GET_CHARC_NAME_LIST_GENERAL_COSTUME:
        return axios.get(
          // `${getScriptAPIURL()}/scene/${finalSceneId}/characters?types=MAIN,SUPPORTING`,
          `${getScriptAPIURL()}/projects/${projectId}/character`,
          config
        );
      case Services.SceneOVerviewVariable.SAVE_COSTUME_TAB_TABLE:
        return axios.post(
          `${getScriptAPIURL()}/scene/${finalSceneId}/costumes`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.SAVE_MAKEUP_TAB_TABLE:
        return axios.post(
          `${getScriptAPIURL()}/scene/${finalSceneId}/makeup`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.UPDATE_COSTUME_LIST:
        return axios.put(
          `${getScriptAPIURL()}/costumes/${budjetId}`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.UPDATE_MAKEUP_LIST:
        return axios.put(
          `${getScriptAPIURL()}/makeup/${budjetId}`,
          data,
          config
        );

      case Services.SceneOVerviewVariable.DELETE_COSTUME_TAB_TABLE:
        return axios.delete(`${getScriptAPIURL()}/costumes/${data}`, config);
      case Services.SceneOVerviewVariable.GET_COSTUME_BY_ID:
        return axios.get(`${getScriptAPIURL()}/costume/budget/${data}`, config);
      case Services.SceneOVerviewVariable.CREATE_COSTUME_BUDJET:
        return axios.post(`${getScriptAPIURL()}/costume/budget`, data, config);
      case Services.SceneOVerviewVariable.GET_PREVIOUS_SCENE_IDS:
        return axios.get(
          `${getScriptAPIURL()}/scene/${finalSceneId}/character/${data}?type=${budjetId}`,
          config
        );
      case Services.SceneOVerviewVariable.GET_GENERAL_COSTUME_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/general/costume`,
          config
        );
      case Services.SceneOVerviewVariable.GET_GENERAL_MAKEUP_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/general/makeup`,
          config
        );
      case Services.SceneOVerviewVariable.GENERAL_SAVE_COSTUME_TAB_TABLE:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/costumes`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.GENERAL_SAVE_MAKEUP_TAB_TABLE:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}/makeups`,
          data,
          config
        );
      case Services.SceneOVerviewVariable
        .GET_GENERAL_COSTUME_DETAILS_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/costume/${budjetId}/details`,
          config
        );
      case Services.SceneOVerviewVariable.GET_GENERAL_MAKEUP_DETAILS_BY_PROJECT:
        return axios.get(
          `${getScriptAPIURL()}/makeup/${budjetId}/details`,
          config
        );
      case Services.SceneOVerviewVariable.SAVE_GENERAL_COSTUME_DETAILS:
        return axios.post(
          `${getScriptAPIURL()}/costume/${budjetId}/details`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.SAVE_GENERAL_MAKEUP_DETAILS:
        return axios.post(
          `${getScriptAPIURL()}/makeup/${budjetId}/details`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.UPDATE_GENERAL_COSTUME_DETAILS:
        return axios.post(
          `${getScriptAPIURL()}/costume/${budjetId}/details`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.UPDATE_GENERAL_MAKEUP_DETAILS:
        return axios.post(
          `${getScriptAPIURL()}/makeup/${budjetId}/details`,
          data,
          config
        );
      case Services.SceneOVerviewVariable.DELETE_GENERAL_COSTUME_DETAILS:
        return axios.delete(
          `${getScriptAPIURL()}/costume/details/${budjetId}`,
          config
        );
      case Services.SceneOVerviewVariable.DELETE_GENERAL_MAKEUP_DETAILS:
        return axios.delete(
          `${getScriptAPIURL()}/makeup/details/${budjetId}`,
          config
        );
      default:
        break;
    }
  }
}
