import React, { Component, Fragment } from "react";
import { Button, Modal } from "antd";
import Uploaddocumentfile from "components/uploaddocumentfile";
import {
  GlobalConstants,
  LocalStorage,
  Services,
  Notifications,
  AttachmentType,
} from "constants/AppConstants";
import UploadDocumentComponent from "components/shared/UploadDocumentAndDisplay/UploadDocumentComponent";
import { showActionMessage, showNotifications } from "./NotificationComponent";
import { handleError } from "utilities/ErrorHandler";
import EquipmentProjectService from "services/EquipmentProjectService";
import DisplayDocumentComponent from "./UploadDocumentAndDisplay/DisplayDocumentComponent";
import DocumentDelete from "services/DocumentDelete";

class EquipmentDocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      fileList: [],
      data: props.data,
      fileList: props.data.documents,
    };
    this._equipmentProjectService = new EquipmentProjectService();
    this._docDelete = new DocumentDelete();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      fileList: nextProps.data.documents,
    });
  }
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleDelete = (id) => {
    const { refreshData } = this.props;
    this._docDelete
      .deleteService(Services.EquipmentVariables.DELETE_DOCUMENT, id)
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.SUCCESSFULLY_REMOVED
        );
        refreshData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          "Something Went Wrong"
        );
      });
  };

  handleDocument = (files) => {
    const { data, fileList } = this.state;
    const lists = [...fileList];
    files.map((file) => {
      const { attachmentId, fileType } = file;
      lists.push({
        attachmentId,
        fileType,
      });
    });
    data.equipmentDocuments = lists;
    this.handleUpdate(data);
  };

  handleUpdate = (record) => {
    record.equipmentType = "STANDARD";
    record.equipmentResourceType = record.equipmentResourceType;
    record.projectId = parseInt(
      localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
    );
    this._equipmentProjectService
      .equipmentProjectService(
        Services.EquipmentVariables.PUT_STANDARD_EQUIPMENT,
        record,
        record.scheduleEquipmentId
      )
      .then((res) => {
        this.setState({
          isDisableInput: false,
        });
        showActionMessage(
          GlobalConstants.SUCCESS,
          GlobalConstants.DOCUMENT_UPLOADED_SUCCESSFULLY
        );
        this.props.refreshData();
      })
      .catch((err) => {
        showNotifications(
          Notifications.ERROR,
          "Failed",
          handleError(
            err.response && err.response.data && err.response.data.message
          )
        );
      });
  };

  render() {
    const { isModalVisible, fileList } = this.state;
    const { data, refreshData, disabled } = this.props;
    return (
      <Fragment>
        <Button
          disabled={disabled}
          style={{ width: "100%" }}
          type="ghost"
          onClick={this.showModal}
        >
          Documents
        </Button>
        <Modal
          title="Documents"
          visible={isModalVisible}
          onOk={this.handleOk}
          className="createModal no-x"
          footer={null}
          destroyOnClose
          width={1000}
        >
          <DisplayDocumentComponent
            handleDelete={this.handleDelete}
            files={this.props?.data?.documents || []}
          />
          <UploadDocumentComponent
            handleDocument={this.handleDocument}
            handleCancel={this.handleCancel}
            type={AttachmentType?.eqipmentDocument}
          />
        </Modal>
      </Fragment>
    );
  }
}
export default EquipmentDocumentUpload;
