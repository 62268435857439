import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Form, Select, DatePicker, Button } from "antd";
import PDFComponent from "components/shared/PDFViewer";
import {
  GlobalConstants,
  Services,
  LocalStorage,
} from "constants/AppConstants";
import GlobalService from "services/GlobalService";
import Accounting from "services/AccountingServices";
import { getScriptAPIURL } from "utilities/APIHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import { checkFor } from "utilities/ValidationHelper";
import { connect } from "react-redux";

const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

const AccountList = [
  {
    label: "Account",
    value: "ACCOUNT",
  },
  {
    label: "Budget vs Account",
    value: "BUDGET",
  },
];

const AccountsReportForm = (props) => {
  const [ShootDate, setShootDate] = useState(null);
  const [AccountType, setAccountType] = useState("ACCOUNT");
  const [shootStartDate, setShootStartDate] = useState("");
  const [shootEndDate, setShootEndDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [PaymentDate, setPaymentDate] = useState(null);
  const [PaymentMode, setPaymentMode] = useState(null);
  const [Category, setCategory] = useState(null);
  const [Payee, setPayee] = useState(null);
  const [PaymentList, setPaymentList] = useState(null);
  const [CategoryList, setCategoryList] = useState(null);
  const [PayeeList, setPayeeList] = useState(null);
  const dateFormat = GlobalConstants.DD_MM_YYYY;
  const api = new GlobalService();
  const account = new Accounting();

  useEffect(() => {
    getPaymentMode();
    getPayee();
    getCategories();
  }, []);

  const getCategories = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PRODUCTION_CATEGORY
      )
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPaymentMode = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.PAYMENT_MODE
      )
      .then((res) => {
        setPaymentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPayee = (Category = "") => {
    account
      .services(Services.AccountingVariables.GET_PAYEE_DROPDOWN, Category)
      .then((res) => {
        setPayeeList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formItemLayout = {
    labelCol: {
      sm: { span: 10 },
    },
    wrapperCol: {
      sm: { span: 12 },
    },
  };

  const customCol = {
    labelCol: {
      sm: { span: 4 },
    },
    wrapperCol: {
      sm: { span: 20 },
    },
  };

  const ShootDateChange = (value, ShootDate) => {
    setShootDate(ShootDate);
  };

  const paymentDateChange = (value, PaymentDate) => {
    setPaymentDate(PaymentDate);
  };

  const { getFieldDecorator } = props.form;

  const resetFields = () => {
    setCategory(null);
    setPaymentMode(null);
    setPayee(null);
    setShootDate(null);
    setPaymentDate(null);
    setShootStartDate(null);
    setShootEndDate(null);
    setPaymentStartDate(null);
    setPaymentEndDate(null);
    props.form.setFieldsValue({
      Category: null,
      PaymentMode: null,
      Payee: null,
      ShootDate: null,
      PaymentDate: null,
      Payment_Date: null,
    });
  };

  const hasValues =
    Category || PaymentMode || Payee || ShootDate || PaymentDate;

  let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
  const url = `${getScriptAPIURL()}/account/report/download?contentDisposition=INLINE&isAccount=${
    AccountType === "ACCOUNT"
  }${Category ? `&category=${Category}` : ""}${
    Payee ? `&payeeId=${Payee}` : ""
  }${PaymentDate ? `&paymentDate=${PaymentDate}` : ""}${
    PaymentMode ? `&paymentMode=${PaymentMode}` : ""
  }&projectId=${projectId}${ShootDate ? `&shootDate=${ShootDate}` : ""}${
    shootStartDate ? `&startShootDate=${shootStartDate}` : ""
  }${shootEndDate ? `&endShootDate=${shootEndDate}` : ""}${
    paymentStartDate ? `&paymentStartDate=${paymentStartDate}` : ""
  }${paymentEndDate ? `&paymentEndDate=${paymentEndDate}` : ""}`;

  const { activePermisision } = props;

  console.log("url", url);

  return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
    <Row>
      <Col>
        <Form {...formItemLayout}>
          <Row gutter={16}>
            <Col xl={7}>
              <Form.Item label="Report Type" hasFeedback>
                {getFieldDecorator("AccountType", {
                  initialValue: "ACCOUNT",
                  rules: [
                    {
                      required: false,
                      message: "Invoice Number Required!",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%" }}
                    className="upperCase"
                    allowClear
                    onChange={(AccountType) => {
                      setAccountType(AccountType);
                    }}
                  >
                    {AccountList &&
                      AccountList.map((list, i) => {
                        return (
                          <Option key={i} value={list.value}>
                            {list.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xl={7}>
              <Form.Item label="Category" hasFeedback>
                {getFieldDecorator("Category", {
                  rules: [
                    {
                      required: false,
                      message: "Invoice Number Required!",
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%" }}
                    className="upperCase"
                    allowClear
                    onChange={(Category) => {
                      setCategory(Category);
                      getPayee(Category);
                      setPayee(null);
                      props.form.setFieldsValue({
                        Payee: null,
                      });
                    }}
                  >
                    {CategoryList &&
                      CategoryList.map((list, i) => {
                        return (
                          <Option key={i} value={list.value}>
                            {list.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {Category === GlobalConstants.PRODUCTION_CAPS && (
              <Col xl={7}>
                <Form.Item label="Shoot Date" hasFeedback>
                  {getFieldDecorator(
                    "ShootDate",
                    {}
                  )(
                    <RangePicker
                      style={{ width: 180 }}
                      className="customPicker"
                      // defaultValue={[
                      //   moment(startDate, dateFormat),
                      //   moment(endDate, dateFormat)
                      // ]}
                      onChange={(value, record) => {
                        setShootStartDate(record[0]);
                        setShootEndDate(record[1]);
                      }}
                      format={dateFormat}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            <Col xl={7}>
              <Form.Item label="Payment Date" hasFeedback>
                {getFieldDecorator(
                  "PaymentDate",
                  {}
                )(
                  <RangePicker
                    style={{ width: 180 }}
                    className="customPicker"
                    // defaultValue={[
                    //   moment(startDate, dateFormat),
                    //   moment(endDate, dateFormat)
                    // ]}
                    onChange={(value, record) => {
                      console.log(value, record);
                      console.log(record);
                      setPaymentStartDate(record[0]);
                      setPaymentEndDate(record[1]);
                    }}
                    format={dateFormat}
                  />
                )}
              </Form.Item>
            </Col>

            {AccountType === "ACCOUNT" && (
              <Fragment>
                <Col xl={7}>
                  <Form.Item label="Payment mode" hasFeedback>
                    {getFieldDecorator("PaymentMode", {
                      rules: [
                        {
                          required: false,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        className="upperCase"
                        allowClear
                        onChange={(PaymentMode) => setPaymentMode(PaymentMode)}
                      >
                        {PaymentList &&
                          PaymentList.map((list, i) => {
                            return (
                              <Option key={i} value={list.value}>
                                {list.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={7}>
                  <Form.Item label="Payee" hasFeedback>
                    {getFieldDecorator("Payee", {
                      rules: [
                        {
                          required: false,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        className="upperCase"
                        showSearch
                        allowClear
                        onChange={(Payee) => setPayee(Payee)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {PayeeList &&
                          PayeeList.map((list, i) => {
                            return (
                              <Option key={i} value={list.value}>
                                {list.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={7}>
                  <Form.Item label="Shoot date" hasFeedback>
                    {getFieldDecorator("ShootDate", {
                      rules: [
                        {
                          required: false,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <DatePicker
                        onChange={(value, ShootDate) => setShootDate(ShootDate)}
                        format={dateFormat}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={7}>
                  <Form.Item label="Payment date" hasFeedback>
                    {getFieldDecorator("Payment_Date", {
                      rules: [
                        {
                          required: false,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <DatePicker
                        onChange={(value, PaymentDate) =>
                          setPaymentDate(PaymentDate)
                        }
                        format={dateFormat}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Fragment>
            )}
            <Col xl={7} style={{ textAlign: "right" }}>
              <Row type="flex">
                <PDFComponent
                  customURL={url}
                  icon="download"
                  text="Report"
                  projectId={projectId}
                  hideMail
                  disabled={!AccountType}
                />
                {hasValues && (
                  <Button
                    type="primary"
                    style={{ marginLeft: 5 }}
                    onClick={resetFields}
                  >
                    Reset
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  ) : (
    <ContactAdminErrorTemplate />
  );
};

const AccountsReport = Form.create({ name: "register" })(AccountsReportForm);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.REPORT, menus),
  };
};

export default connect(mapStateToProps, null)(AccountsReport);
