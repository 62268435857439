import React from "react";
import { Col } from "antd";
import SkeletonComponent from "components/shared/SkeletonComponent";

const DashboardTemplateSkeleton = () => {
  return (
    <Col xl={8} className="dashboardTemplateSkeletonContainer">
      <Col className="dashboardTemplateSkeleton">
        <Col className="dashboardTemplateSkeletonHead">
          <SkeletonComponent width={"80%"} />
        </Col>
        <Col className="dashboardTemplateSkeletonBody">
          <Col className="list">
            <SkeletonComponent width={"100%"} />
          </Col>
          <Col className="list">
            <SkeletonComponent width={"100%"} />
          </Col>
          <Col className="list">
            <SkeletonComponent width={"100%"} />
          </Col>
          <Col className="list">
            <SkeletonComponent width={"100%"} />
          </Col>
          <Col className="list">
            <SkeletonComponent width={"100%"} />
          </Col>
          <Col className="list">
            <SkeletonComponent width={"100%"} />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default DashboardTemplateSkeleton;
