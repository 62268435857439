import React, { useRef, useState } from "react";
import "./MultipleUpload.scss";
import { generateRandomName, urltoFile } from "utilities/UserHelper";
import ResizeFile from "utilities/ImageCompression";
import UploadService from "services/uploadService";
import { AttachmentType, ImageDomain, Services } from "constants/AppConstants";
import ImageViewer from "components/shared/ImageViewer";
import WhiteDeleteIcon from "../../assets/icons/delete_white.png";
import { Modal, Popconfirm } from "antd";

const MultipleUpload = ({
  showSmall = false,
  existingData = [],
  multiple = false,
  type = AttachmentType?.projectPicture,
  uploadedAttachement = () => {},
  refreshData = () => {},
  onDelete = () => {},
  accept = "image/*",
  morePreview = true,
  sendNewDataOnly = false,
}) => {
  const inputRef = useRef(() => null);
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  let documentUrl = (type === 'LOCATION_PICTURE'?Services.LocationVariables.LOCATION_DELETE_DOC:Services.AddActor.DELETE_ACTOR_DOCUMENT);
  const handleFileSelect = (e) => {
    const files = [...e.target.files];
    const processedFiles = [];

    files.forEach((file) => {
      const fileType = file?.type?.includes("image") || false;
      const info = {
        id: generateRandomName(),
        data: file,
        loading: true,
        type: fileType ? "IMAGE" : "DOCUMENT",
      };
      processedFiles.push(info);
    });
    uploadImage(processedFiles);
    uploadDocument(processedFiles);
    setLoading(true);
  };

  const uploadImage = async (datas) => {
    datas.forEach((image) => {
      if (image?.type === "IMAGE") {
        ResizeFile(image?.data).then((base64) => {
          urltoFile(base64).then((file) => {
            let formData = new FormData();
            formData.append("file", file);
            UploadService.projectImageUpload(ImageDomain?.aceScript, formData, {
              attachmentType: type,
              isBucketUrlRequired: true,
            }).then((res) => {
              changeLoadingStatus(image, datas, res?.data);
            });
          });
        });
      }
    });
  };
  const uploadDocument = async (datas) => {
    datas.forEach((file) => {
      if (file?.type === "DOCUMENT") {
        let formData = new FormData();
        formData.append("file", file?.data);
        UploadService.projectImageUpload(ImageDomain?.aceScript, formData, {
          attachmentType: type,
          isBucketUrlRequired: true,
        }).then((res) => {
          changeLoadingStatus(file, datas, res?.data);
        });
      }
    });
  };

  const changeLoadingStatus = (data, allData, response) => {
    let newData = allData.map((a) => {
      if (a.id === data.id) {
        a.loading = false;
        a.response = response;
      }
      return a;
    });
    checkForLoading(newData);
  };

  const checkForLoading = (data) => {
    if (data.filter((value) => value.loading === true).length === 0) {
      setLoading(false);
      let newData = [];
      data.forEach((a) => {
        let b = {
          attachmentId: a?.response?.id,
          fileType: a?.type,
          attachment: {
            url: a?.response?.url,
          },
        };
        newData.push(b);
      });
      if (sendNewDataOnly) {
        uploadedAttachement([...newData]);
        return null;
      }
      if (multiple === true) {
        uploadedAttachement([...newData, ...existingData]);
      } else {
        uploadedAttachement(newData[0]);
      }
    } else {
      setLoading(true);
    }
  };

  const deleteAttachment = (data) => {
    onDelete(data);
  };

  const handleDownload = (fileUrl = "") => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.click();
  };

  return (
    <div
      className={
        !showSmall
          ? "multiple-upload-wrapper"
          : "multiple-upload-wrapper upload-wrapper-small"
      }
    >
      <div
        className="new-upload"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {loading ? (
          <span>Uploading...</span>
        ) : (
          <>
            <input
              accept={accept}
              type="file"
              id="input"
              ref={inputRef}
              multiple={multiple}
              onChange={handleFileSelect}
            />
            <div className="content">
              <p id="plus-icon">+</p>
              <span id="upload-txt">Upload</span>
            </div>
          </>
        )}
      </div>
      {/* LOCATION_PICTURE */}
      {morePreview ? (
        <ImageViewer
          imageCount={2}
          type="table"
          ImagesData={existingData || []}
          canDelete={true}
          refreshData={refreshData}
          // url={Services.AddActor.DELETE_ACTOR_DOCUMENT}
          url={documentUrl}
        />
      ) : (
        <div className="existing-wrapper">
          {existingData &&
            existingData.map((data) => {
              return (
                <div className="main-div">
                  {data?.fileType === "DOCUMENT" ? (
                    <div className="inner-div">
                      <Popconfirm
                        title="Are you sure to delete this?"
                        cancelText="No"
                        okText="Yes"
                        onConfirm={() => deleteAttachment(data)}
                      >
                        <div className="delete-btn">
                          <img alt="delete" src={WhiteDeleteIcon} />
                        </div>
                      </Popconfirm>
                      <div
                        className="document-div"
                        onClick={() => handleDownload(data?.attachment?.url)}
                      >
                        {!showSmall && (
                          <span>Doument Id - {data?.attachmentId}</span>
                        )}
                        <br />
                        <span id="download">View</span>
                      </div>
                    </div>
                  ) : (
                    <div className="inner-div">
                      <Popconfirm
                        title="Are you sure to delete this?"
                        cancelText="No"
                        okText="Yes"
                        onConfirm={() => deleteAttachment(data)}
                      >
                        <div className="delete-btn">
                          <img alt="delete" src={WhiteDeleteIcon} />
                        </div>
                      </Popconfirm>
                      <img
                        alt="Attachement"
                        src={data?.attachment?.url}
                        onClick={() => {
                          setPreviewVisible(true);
                          setPreviewImage(data?.attachment?.url);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default MultipleUpload;
