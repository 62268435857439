import React from "react";
import { PulseLoader } from "react-spinners";

import ThemeColors from "constants/ThemeConstants/variables";

const Loader = ({ size = 12 }) => {
  return (
    <div className="loaderContainer" style={{ backgroundColor: "#00000012" }}>
      <PulseLoader
        size={size}
        //size={"150px"} this also works
        color={ThemeColors.primaryColor}
        loading
      />
    </div>
  );
};

export default Loader;
