import React, { Component } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Form,
} from "antd";
import "./UpdateSet.css";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import FileUpload from "components/shared/FileUpload";
import PropTypes from "prop-types";
import GlobalService from "services/GlobalService";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import FileCard from "components/shared/FileCardComponent";
import Uploaddocumentfile from "components/uploaddocumentfile";
import UploadImage from "components/shared/UploadImage/UploadImage";
import { numberOnly } from "utilities/ValidationHelper";
import ArtSetServices from "services/ArtSetServices";
import { showNotifications } from "components/shared/NotificationComponent";

const { Option } = Select;

class UpdateSet extends Component {
  constructor(props) {
    super(props);
    this._globalService = new GlobalService();
    this._artSetService = new ArtSetServices();
    this.state = {
      visible: false,
      actualLocation: null,
      materialPurchase: null,
      materialHire: null,
      setupTime: null,
      setupTimeType: null,
      status: null,
      getStatusData: [],
      getTimeType: [],
      fileList: null,
      docFiles: null,
      imageFiles: null,
      sceneLocationId: null,
      sceneLocationType: null,
      sceneBudjetId: null,
    };
  }

  emptyState = () => {
    this.setState({
      visible: false,
      actualLocation: null,
      sceneLocationType: null,
      materialPurchase: null,
      materialHire: null,
      setupTime: null,
      setupTimeType: null,
      status: null,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      sceneLocationId: null,
      sceneBudjetId: null,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === "UPDATE") {
      if (this.state.sceneBudjetId !== nextProps.recordData.artSetId) {
        this.setState(
          {
            actualLocation: nextProps.recordData.sceneLocation,
            sceneLocationId: nextProps.recordData.sceneLocationId,
            sceneLocationType: nextProps.recordData.type,
            sceneBudjetId: nextProps.recordData.artSetId,
          },
          () => {
            if (this.state.sceneBudjetId) {
              this.getDataByBudjet();
            }
          }
        );
      }
    }
    if (nextProps.type === "ADD") {
      this.setState({
        actualLocation: nextProps.recordData.sceneLocation,
        sceneLocationId: nextProps.recordData.sceneLocationId,
        sceneLocationType: nextProps.recordData.type,
      });
    }
  }

  componentDidMount() {
    this.getStatus();
    this.getMakeupTimeData();
  }

  getStatus = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.APPROVAL_STATUS
      )
      .then((res) => {
        this.setState({ statusData: res.data });
      });
  };

  getMakeupTimeData() {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.MAKE_UP_TIME
      )
      .then((res) => {
        this.setState({ makeuptimeData: res.data });
      });
  }

  getDataByBudjet = () => {
    const { sceneBudjetId } = this.state;
    if (sceneBudjetId) {
      this._artSetService
        .service(
          Services.ArtSetVariables.GET_DATA_BY_BUDGET_ID,
          this.state.sceneBudjetId
        )
        .then((res) => {
          this.setState(
            {
              materialPurchase: res.data.materialsPurchase,
              materialHire: res.data.materialsHire,
              setupTime: res.data.setUpTimeRequired,
              setupTimeType: res.data.setUpTimeRequiredType,
              status: res.data.stateEnum,
              fileList: res.data.documents,
            },
            () => this.splitBasedOnFileTypes(this.state.fileList)
          );
        });
    }
  };

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      docFiles,
      imageFiles,
    });
  };

  handleImageIDS = (ids) => {
    const { fileList } = this.state;
    let files = [];
    ids.map((id) => {
      files.push({ fileId: id, fileType: GlobalConstants.IMAGE });
    });
    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }
  };

  deleteImage = (id) => {
    let fileDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );
    let imageDeleteIndex = this.state.fileList.findIndex(
      (a) => a.locationImageId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;
    imageFiles.splice(imageDeleteIndex, 1);
    fileList.splice(fileDeleteIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  handleDocument = (id) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { fileId: id, fileType: GlobalConstants.DOCUMENT },
      ];

      this.setState({
        fileList: temp,
      });
    } else {
      let temp = [{ fileId: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };

  deleteDoc = (id) => {
    let deleteIndex = this.state.docFiles.findIndex((a) => a.documentId === id);

    let docFiles = this.state.docFiles;
    let finalData = docFiles.splice(deleteIndex, 1);
    this.setState({
      docFiles,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.props.closeModal();
    this.setState({
      visible: false,
      actualLocation: null,
      sceneLocationType: null,
      materialPurchase: null,
      materialHire: null,
      setupTime: null,
      setupTimeType: null,
      status: null,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      sceneLocationId: null,
      sceneBudjetId: null,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          documents: this.state.fileList,
          materialsHire: this.state.materialHire,
          materialsPurchase: this.state.materialPurchase,
          sceneLocationId: this.state.sceneLocationId,
          setUpTimeRequired: this.state.setupTime,
          setUpTimeRequiredType: this.state.setupTimeType,
          state: this.state.status,
        };

        this._artSetService
          .service(Services.ArtSetVariables.ADD_ART_SET_BUDJET, data)
          .then((res) => {
            this.emptyState();
            this.handleCancel();
            this.props.refreshData();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Budjet Created Successfully"
            );
          })
          .catch((err) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Budjet Created Successfully"
            );
          });
      }
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          documents: this.state.fileList,
          materialsHire: this.state.materialHire,
          materialsPurchase: this.state.materialPurchase,
          sceneLocationId: this.state.sceneLocationId,
          setUpTimeRequired: this.state.setupTime,
          setUpTimeRequiredType: this.state.setupTimeType,
          state: this.state.status,
          artSetBudgetId: this.state.sceneBudjetId,
        };

        this._artSetService
          .service(Services.ArtSetVariables.ADD_ART_SET_BUDJET, data)
          .then((res) => {
            this.setState({
              visible: false,
              actualLocation: null,
              sceneLocationType: null,
              materialPurchase: null,
              materialHire: null,
              setupTime: null,
              setupTimeType: null,
              status: null,
              fileList: null,
              docFiles: null,
              imageFiles: null,
              sceneLocationId: null,
              sceneBudjetId: null,
            });
            this.handleCancel();
            this.props.refreshData();
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Budjet Updated Successfully"
            );
          })
          .catch((err) => {
            showNotifications(
              Notifications.SUCCESS,
              "Success",
              "Budjet Created Successfully"
            );
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      actualLocation,
      sceneLocationType,
      materialPurchase,
      materialHire,
      setupTime,
      setupTimeType,
      status,
      visible,
    } = this.state;
    return (
      <div>
        {/* <div onClick={this.showModal}>ArtSet</div> */}
        <Modal
          destroyOnClose
          visible={this.props.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="1000px"
          footer={
            <Row
              gutter={12}
              type="flex"
              justify="end"
              style={{ marginTop: "20px" }}
            >
              <Col>
                <Button onClick={this.handleCancel}>Cancel</Button>
              </Col>
              <Col>
                {this.props.type === "ADD" ? (
                  <Button type="primary" onClick={this.handleSubmit}>
                    Save
                  </Button>
                ) : (
                  <Button type="primary" onClick={this.handleUpdate}>
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          }
        >
          <h3
            className="font-600 text-center primary-font-color"
            style={{ marginBottom: 30 }}
          >
            {this.props.type} LOCATION
          </h3>
          <Row>
            <Col xl={{ span: 24 }}>
              <Row gutter={16}>
                <Col xl={{ span: 12 }}>
                  <Row>
                    <Col
                      xl={{ span: 10 }}
                      style={{ paddingTop: 8, paddingRight: 8 }}
                      className="text-right"
                    >
                      <label className="font-600">* Actual Location </label>
                    </Col>
                    <Col xl={{ span: 14 }}>
                      <Form.Item>
                        {getFieldDecorator("actualLocation", {
                          initialValue: actualLocation || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter actual Location!",
                            },
                          ],
                        })(
                          <Input
                            disabled
                            placeholder="Actual Location"
                            onChange={(e) => {
                              this.setState({ actualLocation: e.target.value });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row>
                    <Col
                      xl={{ span: 10 }}
                      style={{ paddingTop: 8, paddingRight: 8 }}
                      className="text-right"
                    >
                      <label className="font-600">* Location Type </label>
                    </Col>
                    <Col xl={{ span: 14 }}>
                      <Form.Item>
                        {getFieldDecorator("sceneLocationType", {
                          initialValue: sceneLocationType || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Location Type!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Location Type"
                            disabled
                            onChange={(e) => {
                              this.setState({
                                sceneLocationType: e.target.value,
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row>
                    <Col
                      className="text-right"
                      xl={{ span: 10 }}
                      style={{ paddingTop: 8, paddingRight: 8 }}
                    >
                      <label className="font-600">* Material Purchase</label>
                    </Col>
                    <Col xl={{ span: 14 }}>
                      <Form.Item>
                        {getFieldDecorator("materialPurchase", {
                          initialValue: materialPurchase || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Material Purchase!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Material Purchase"
                            onChange={(e) => {
                              this.setState({
                                materialPurchase: numberOnly(e),
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row />
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row>
                    <Col
                      className="text-right"
                      xl={{ span: 10 }}
                      style={{ paddingTop: 8, paddingRight: 8 }}
                    >
                      <label className="font-600">* Material Hire</label>
                    </Col>
                    <Col xl={{ span: 14 }}>
                      <Form.Item>
                        {getFieldDecorator("materialHire", {
                          initialValue: materialHire || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Material Hire!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Material Hire"
                            onChange={(e) => {
                              this.setState({ materialHire: numberOnly(e) });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row gutter={16}>
                    <Col
                      className="text-right"
                      xl={{ span: 10 }}
                      style={{ paddingTop: 8 }}
                    >
                      <label className="font-600">* Setup Time Required</label>
                    </Col>
                    <Col xl={{ span: 14 }} style={{ paddingLeft: 0 }}>
                      <Form.Item>
                        {getFieldDecorator("setupTime", {
                          initialValue: setupTime || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Setup Time!",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Setup Time"
                            onChange={(e) => {
                              this.setState({ setupTime: numberOnly(e) });
                            }}
                            addonAfter={
                              <Select
                                placeholder="Select Type"
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.setState({
                                    setupTimeType: e,
                                  });
                                }}
                              >
                                {this.state.makeuptimeData &&
                                  (this.state.makeuptimeData || []).map(
                                    (option, i) => (
                                      <Option value={option.value}>
                                        {option.label}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row>
                    <Col
                      className="text-right"
                      xl={{ span: 10 }}
                      style={{ paddingTop: 8, paddingRight: 8 }}
                    >
                      <label className="font-600">* Status</label>
                    </Col>
                    <Col xl={{ span: 14 }}>
                      <Form.Item>
                        {getFieldDecorator("status", {
                          initialValue: status || undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please Select Status!",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Select Status"
                            className="w-100"
                            onChange={(e) => {
                              this.setState({
                                status: e,
                              });
                            }}
                          >
                            {this.state.statusData &&
                              (this.state.statusData || []).map((option, i) => (
                                <Option value={option.value}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={{ span: 12 }}>
                  <Row>
                    <Col className="text-right" xl={{ span: 10 }}>
                      <label className="pb-5 font-600">Upload Document </label>
                    </Col>
                    <Col xl={{ span: 14 }} style={{ paddingLeft: 8 }}>
                      <Form.Item>
                        {getFieldDecorator("fileuploadid", {
                          initialValue: this.state.fileuploadid,
                        })(
                          <React.Fragment>
                            <div className="fileCardContainer">
                              {this.state.docFiles &&
                                this.state.docFiles.map((file, i) => (
                                  <FileCard
                                    file={file}
                                    deleteDoc={this.deleteDoc}
                                    pageType={
                                      Services.ArtSetVariables.DELETE_DOCUMENT
                                    }
                                  />
                                ))}
                            </div>
                            <Uploaddocumentfile
                              handleDocument={this.handleDocument}
                            />
                          </React.Fragment>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginLeft: "-2px" }}>
            <Col className="text-right" xl={{ span: 5 }}>
              <label className="pb-5 font-600">Upload Image </label>
            </Col>
            <Col
              xl={{ span: 17 }}
              className="imageSelect"
              style={{ paddingLeft: 8 }}
            >
              <React.Fragment>
                <div className="fileCardContainer">
                  {this.state.imageFiles &&
                    this.state.imageFiles.map((file, i) => (
                      <FileCard
                        file={file}
                        deleteImage={this.deleteImage}
                        pageType={Services.ArtSetVariables.DELETE_DOCUMENT}
                      />
                    ))}
                </div>
                {/* <UploadImage handleImage={this.handleImage} /> */}
                <MultipleSelectImage handleImageIDS={this.handleImageIDS} />
              </React.Fragment>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const ArtSetUpdate = Form.create()(UpdateSet);
export default ArtSetUpdate;
