import React, { Component } from "react";
import "./ScriptOverview.css";
import { Tabs, Row, Col } from "antd";
import { connect } from "react-redux";
import CharacterCard from "components/shared/CharacterCard";
import GeneralScriptDetails from "components/GeneralScriptDetails";
import ActorTab from "components/ActorTab";
import ArtSetTab from "components/ArtSetTab";
import CostumesTab from "components/CostumesTab";
import MakeupTab from "components/MakeupTab";
import Songtab from "components/songtab";
import VFXTab from "components/VFXTab";
import StuntTab from "components/Stunttab";
import LocationTab from "components/LocationTab";
import { setActiveTab, setSceneNumbers, setActiveSceneId } from "store/action";
import PaginationComponent from "components/shared/PaginationComponent";
import GlobalService from "services/GlobalService";
import { LocalStorage, Services } from "constants/AppConstants";
import EquipmentTab from "components/EquipmentTab";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import GeneralTabService from "services/SceneOverviewService/GeneralTabService";
const { TabPane } = Tabs;

class ScriptOverview extends Component {
  constructor(props) {
    super(props);
    this._globalServices = new GlobalService();
    this._generalTabService = new GeneralTabService();
    const panes = [
      {
        tabId: "1",
        key: "1",
        tabName: "General",
        tabContent: (
          <GeneralScriptDetails
            handleVfxTabStatus={this.handleVfxTabStatus}
            handleSongStuntTab={this.handleSongStuntTab}
            handleSetTab={this.handleSetTab}
            //handleCostumeTab={this.handleCostumeTab}
            //handleMakeupTab={this.handleMakeupTab}
            resetPanes={this.resetPanes}
            refreshData={this.getSceneDetails}
          />
        ),
      },
      {
        tabId: "2",
        key: "2",
        tabName: "Char-Actor",
        tabContent: <ActorTab />,
      },
      {
        tabId: "3",
        key: "3",
        tabName: "Location",
        tabContent: <LocationTab />,
      },
      {
        tabId: "4",
        tabName: "Art / Set",
        tabContent: <ArtSetTab {...this.props} />,
      },
      {
        tabId: "5",
        key: "5",
        tabName: "Costume",
        tabContent: <CostumesTab />,
      },
      {
        tabId: "6",
        key: "6",
        tabName: "Makeup",
        tabContent: <MakeupTab />,
      },
      {
        tabId: "11",
        key: "4",
        tabName: "Equipment",
        tabContent: <EquipmentTab />,
      },
    ];
    this.state = {
      activeKey: panes[0].tabId,
      panes,
      environmentTime: "",
      environmentType: "",
      sceneLocation: "",
    };
  }

  resetPanes = (id) => {
    if (id !== this.state.activeSceneId) {
      const panes = [
        {
          tabId: "1",
          key: "1",
          tabName: "General",
          tabContent: (
            <GeneralScriptDetails
              handleVfxTabStatus={this.handleVfxTabStatus}
              handleSongStuntTab={this.handleSongStuntTab}
              handleSetTab={this.handleSetTab}
              // handleCostumeTab={this.handleCostumeTab}
              // handleMakeupTab={this.handleMakeupTab}
              resetPanes={this.resetPanes}
              refreshData={this.getSceneDetails}
            />
          ),
        },
        {
          tabId: "2",
          key: "2",
          tabName: "Char-Actor",
          tabContent: <ActorTab />,
        },
        {
          tabId: "3",
          key: "3",
          tabName: "Location",
          tabContent: <LocationTab />,
        },
        {
          tabId: "4",
          tabName: "Art / Set",
          tabContent: <ArtSetTab {...this.props} />,
        },
        {
          tabId: "5",
          key: "5",
          tabName: "Costume",
          tabContent: <CostumesTab />,
        },
        {
          tabId: "6",
          key: "6",
          tabName: "Makeup",
          tabContent: <MakeupTab />,
        },
        {
          tabId: "11",
          key: "4",
          tabName: "Equipment",
          tabContent: <EquipmentTab />,
        },
      ];
      this.state = {
        activeKey: panes[0].tabId,
        panes,
      };
    }
  };

  // handleVfxTabStatus = vfxTab => {
  //   if (vfxTab) {
  //     let tempPanes = this.state.panes;
  //     let isAlreadyAvail = tempPanes.filter(a => a.tabName.includes("VFX"));
  //     if (isAlreadyAvail.length === 0) {
  //       if (vfxTab == "Yes") {
  //         let temp = this.state.panes;
  //         let position = temp.length;
  //         temp.splice(position, 0, {
  //           tabId: "7",
  //           tabName: "VFX",
  //           tabContent: <VFXTab />
  //         });
  //         this.setState({
  //           panes: temp
  //         });
  //       } else if (vfxTab == "No") {
  //         // var filtereddata = this.state.panes.filter(a => a.tabName != "VFX");
  //         // console.log("filtereddata", filtereddata);
  //         // this.setState({
  //         //   panes: filtereddata
  //         // });
  //       }
  //     } else {
  //       var panes = this.state.panes.filter(a => a.tabName != "VFX");
  //       console.log("this.state.panes", this.state.panes);
  //       this.setState(
  //         {
  //           panes
  //         },
  //         () => console.log("this.state.panes", this.state.panes)
  //       );
  //     }
  //   }
  // };
  handleVfxTabStatus = (vfxTab) => {
    if (vfxTab === "Yes") {
      let tempPanes = this.state.panes;
      let isAlreadyAvail = tempPanes.filter((a) => a.tabName.includes("VFX"));
      if (isAlreadyAvail.length === 0) {
        let temp = this.state.panes;
        let position = temp.length;
        temp.splice(position, 0, {
          tabId: "7",
          tabName: "VFX",
          tabContent: <VFXTab />,
        });
        this.setState({
          panes: temp,
        });
      }
    } else {
      // var panes = this.state.panes.filter(a => a.tabName != "VFX");
      // this.setState(
      //   {
      //     panes
      //   },
      //   () => console.log("this.state.panes", this.state.panes)
      // );
      const index = this.state.panes.findIndex((a) => a.tabName === "VFX");
      if (index !== -1) {
        const temp = this.state.panes;
        this.setState({
          panes: temp,
        });
      }
    }
  };

  componentDidMount() {
    this.fetchSceneNumbers();
    this.getSceneDetails();
    this.props.setActiveSceneId(
      localStorage.getItem(LocalStorage.ACTIVE_SCENE_ID)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId,
        },
        () => {
          this.getSceneDetails(this.state.activeSceneId);
        }
      );
    }
  }

  getSceneDetails = (activeSceneId) => {
    this._generalTabService
      .generalTabService(
        Services.SceneOVerviewVariable.GET_SCENE,
        null,
        activeSceneId
      )
      .then((res) => {
        this.setState({
          sceneLocation: res.data.location,
          environmentType: res.data.environmentType,
          environmentTime: res.data.timeType,
        });
      });
  };

  fetchSceneNumbers() {
    this._globalServices
      .globalService(
        Services.GlobalVariables.GET_SCENE_NUMBERS,
        localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID)
      )
      .then((res) => {
        this.props.setSceneNumbers(res.data.scenes);
        // this.setState({
        // roles: res.data.roles
        // });
      })
      .catch((err) => {
        // this.setState({
        // });
      });
  }

  renderTabPanes = (panes) => {
    this.setState({
      panes,
    });
  };

  handleCostumeTab = (e) => {
    const { panes } = this.state;
    let tempPanes = panes;

    let isSelected = e === "SPECIAL";
    let isAlreadyAvail = tempPanes.filter((x) => x.tabName.includes("Costume"));
    if (isSelected) {
      if (isAlreadyAvail.length === 0) {
        tempPanes.splice(4, 0, {
          tabId: "5",
          tabName: "Costume",
          tabContent: <CostumesTab />,
        });
      }
    } else {
      tempPanes = tempPanes.filter((a) => a.tabName != "Costume");
    }
    this.renderTabPanes(tempPanes);
  };

  handleMakeupTab = (e) => {
    // const { panes } = this.state;
    // let tempPanes = JSON.parse(JSON.stringify(panes));
    // console.log("filterData", JSON.parse(JSON.stringify(this.state.panes)));
    if (e) {
      let tempPanes = this.state.panes;
      let isSelected = e === "SPECIAL";
      let isAlreadyAvail = tempPanes.filter((x) =>
        x.tabName.includes("Makeup")
      );
      if (isSelected) {
        if (isAlreadyAvail.length === 0) {
          tempPanes.splice(5, 0, {
            tabId: "6",
            tabName: "Makeup",
            tabContent: <MakeupTab />,
          });
        }
      } else {
        tempPanes = tempPanes.filter((a) => a.tabName != "Makeup");
      }
      this.renderTabPanes(tempPanes);
    } else {
      var filtereddata = this.state.panes.filter((a) => a.tabName != "Makeup");

      this.setState(
        {
          panes: JSON.parse(JSON.stringify(filtereddata)),
        }
        // () => console.log("this.state.panes", this.state.panes)
      );
    }
  };

  handleSongStuntTab = (e) => {
    const { panes } = this.state;
    let tempPanes = panes;
    if (e) {
      let isSongsSelected = e.includes("SONG");
      let isStuntSelected = e.includes("STUNT");
      if (isSongsSelected) {
        let isAlreadyAvail = tempPanes.filter((a) =>
          a.tabName.includes("Song")
        );
        if (isAlreadyAvail.length === 0) {
          // tempPanes.push({
          //   tabId: "8",
          //   tabName: "Song",
          //   tabContent: <Songtab />
          // });
          tempPanes.splice(tempPanes.length - 1, 0, {
            tabId: "8",
            tabName: "Song",
            tabContent: <Songtab />,
          });
        }
      } else {
        tempPanes = tempPanes.filter((a) => a.tabName !== "Song");
      }
      if (isStuntSelected) {
        let isAlreadyAvail = tempPanes.filter((a) =>
          a.tabName.includes("Stunt")
        );
        if (isAlreadyAvail.length === 0) {
          tempPanes.splice(tempPanes.length - 1, 0, {
            tabId: "9",
            tabName: "Stunt",
            tabContent: <StuntTab />,
          });
          // tempPanes.push({
          //   tabId: "9",
          //   tabName: "Stunt",
          //   tabContent: <StuntTab />
          // });
        }
      } else {
        tempPanes = tempPanes.filter((a) => a.tabName !== "Stunt");
      }
    } else {
      tempPanes = tempPanes.filter((a) => a.tabName !== "Stunt");
      tempPanes = tempPanes.filter((a) => a.tabName !== "Song");
    }
    this.renderTabPanes(tempPanes);
  };
  onChangeTab = (activeKey) => {
    this.setState({
      activeKey,
    });
    this.props.setActiveTab(activeKey);
  };
  handleSetTab = (e) => {
    const { panes } = this.state;
    let tempPanes = panes;
    let isSetSelected = e != "Live";
    let isSetAvail = tempPanes.filter((a) => a.tabName === "Art / Set");
    if (isSetSelected) {
      if (isSetAvail.length === 0) {
        tempPanes.push({
          tabId: "4",
          tabName: "Art / Set",
          tabContent: <ArtSetTab {...this.props} />,
        });
      }
    } else {
      tempPanes = tempPanes.filter((a) => a.tabName != "Art / Set");
    }
    this.renderTabPanes(tempPanes);
  };
  render() {
    const { environmentTime, environmentType, sceneLocation } = this.state;
    return (
      <Row>
        <Col xs={24}>
          <Title hasBack {...this.props}>
            {/* Script Scene Details */}
            <TitleText>{`${environmentType} - ${sceneLocation} - ${environmentTime}`}</TitleText>
          </Title>
          <Row
            style={{
              // backgroundColor: "#F0F2F5",
              // minHeight: "93vh",
              maxHeight: "1000vh",
            }}
            type="flex"
            justify="middle"
            className="mt-10"
          >
            <Col xl={24}>
              <Row type="flex" justify="center">
                <Col xl={23} sm={24} className="simple">
                  <PaginationComponent />
                </Col>
              </Row>
            </Col>
            <Col
              style={{
                backgroundColor: "#fff",
                paddingBottom: "15px",
              }}
              xl={24}
              sm={24}
            >
              <Row type="flex" justify="center">
                <Col xs={24}>
                  <Tabs
                    defaultActiveKey={this.props.activeTabId}
                    onChange={this.onChangeTab}
                    // activeKey={this.props.activeTabId}
                    destroyInactiveTabPane
                  >
                    {this.state.panes.map((pane, index) => (
                      <TabPane tab={pane.tabName} key={pane.tabId}>
                        <div key={index}>{pane.tabContent}</div>
                      </TabPane>
                    ))}
                  </Tabs>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = ({ activeItems, scene }) => {
  return {
    activeTabId: activeItems.activeTabId,
    activeSceneId: scene.activeSceneId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (activeTabId) => dispatch(setActiveTab(activeTabId)),
    setSceneNumbers: (sceneNumbers) => dispatch(setSceneNumbers(sceneNumbers)),
    setActiveSceneId: (activeSceneId) =>
      dispatch(setActiveSceneId(activeSceneId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ScriptOverview);
