import axios from "axios";
import { Services, LocalStorage } from "constants/AppConstants";
import { getScriptAPIURL } from "utilities/APIHelper";

export default class ArtSetServices {
  service(type, data, token) {
    let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    let config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem(LocalStorage.ACCESS_TOKEN)
        )}`,
      },
    };
    switch (type) {
      case Services.ArtSetVariables.GET_ART_SET_PROJECT_LIST:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/artsets`,
          config
        );
      case Services.ArtSetVariables.GET_ART_SET_LIST_INFO:
        return axios.get(
          `${getScriptAPIURL()}/projects/${projectId}/artset/info?dataLength=0`,
          config
        );
      case Services.ArtSetVariables.GET_ART_SET_LIST_SHOOT_DAY:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/shoot/date/${data}/artsets`,
          config
        );
      case Services.ArtSetVariables.ADD_ART_SET_BUDJET:
        return axios.post(`${getScriptAPIURL()}/artset/budgets`, data, config);
      case Services.ArtSetVariables.UPDATE_ART_SET_BUDJET:
        return axios.post(`${getScriptAPIURL()}/artset/budgets`, data, config);
      case Services.ArtSetVariables.GET_DATA_BY_BUDGET_ID:
        return axios.get(`${getScriptAPIURL()}/artset/budgets/${data}`, config);
      case Services.ArtSetVariables.UPDATE_ART_SET:
        return axios.post(`${getScriptAPIURL()}/artset/budgets`, data, config);
      // case Services.ArtSetVariables.GET_DATA_BY_BUDGET_ID:
      //   return axios.get(`${getScriptAPIURL()}/artset/budgets/${data}`, config);
      case Services.ArtSetVariables.GET_ART_SET:
        return axios.get(`${getScriptAPIURL()}/artset/budgets/${data}`, config);
      case Services.ArtSetVariables.GET_ART_SET_DATES:
        return axios.get(
          `${getScriptAPIURL()}/project/${projectId}/location/artset?shootDate=${data}`,
          config
        );
      case Services.ArtSetVariables.UPDATE_ART_SET_DATES:
        return axios.post(
          `${getScriptAPIURL()}/project/${projectId}`,
          data,
          config
        );
      case Services.ArtSetVariables.GET_ART_SET_OVERVIEW_DETAILS:
        return axios.get(
          `${getScriptAPIURL()}/scene/location/${data}/artsets`,
          config
        );
      default:
        break;
    }
  }
}
