import React, { useState, useEffect } from "react";
import { Table, Row, Col, Select } from "antd";

import GlobalService from "services/GlobalService";
import { checkDateGreaterThan } from "utilities/ValidationHelper";
import RecordNotFound from "components/shared/RecordNotFound";
import {
  Services,
  GlobalConstants,
  Notifications,
} from "constants/AppConstants";
import SchedularService from "services/SchedularServices";
import { showActionMessage } from "components/shared/NotificationComponent";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";
import moment from "moment";

const { Option } = Select;

const LocationScenesTable = ({
  dataSource,
  activePermisision,
  scheduleDate,
}) => {
  console.log("dataSource...", dataSource);
  const [StatusList, setStatusList] = useState(null);
  const api = new GlobalService();
  const schedularAPI = new SchedularService();

  const getStatusList = () => {
    api
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.SCHEDULE_STATE
      )
      .then((res) => setStatusList(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getStatusList();
  }, []);

  const handleStatusChange = (status, record) => {
    schedularAPI
      .services(
        Services.SchedularVariables.UPDATE_SCHEDULAR_SCENE_TABLE,
        null,
        record.sceneSchedulerId,
        status
      )
      .then((res) => {
        showActionMessage(
          GlobalConstants.SUCCESS,
          `Your Changes ${Notifications.UPDATED_SUCCESSFULLY}`
        );
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Scene No",
      dataIndex: "sceneNumber",
      key: "sceneNumber",
      align: "center",
    },
    {
      title: "Environment",
      dataIndex: "enviromentType",
      key: "enviromentType",
      align: "center",
      render: (text, record) => (
        <>
          {record.enviromentType} - {record.timeType}
        </>
      ),
    },
    {
      title: "Scene Description",
      dataIndex: "sceneDescription",
      key: "sceneDescription",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      align: "center",
      render: (text, record) => (
        <Select
          defaultValue={record.state}
          style={{ width: 150 }}
          onChange={(e) => handleStatusChange(e, record)}
          disabled={
            !checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) ||
            checkDateGreaterThan(
              moment().format(GlobalConstants.DD_MM_YYYY),
              scheduleDate
            )
          }
        >
          {StatusList &&
            StatusList.map((status, i) => (
              <Option value={status.value} key={i}>
                {status.label}
              </Option>
            ))}
        </Select>
      ),
    },
  ];

  return (
    <Table
      bordered
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      locale={{
        emptyText: <RecordNotFound />,
      }}
    />
  );
};

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.SCHEDULER, menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationScenesTable);
