import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Radio,
  Checkbox,
  Tag,
  Icon,
} from "antd";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
import Uploaddocumentfile from "components/uploaddocumentfile";
import ImageViewer from "components/shared/ImageViewer";
import { ScriptService } from "services";
import {
  Services,
  Notifications,
  LocalStorage,
  GlobalConstants,
  Screens,
  AttachmentType,
} from "constants/AppConstants";
import {
  showNotifications,
  showActionMessage,
} from "components/shared/NotificationComponent";
import GlobalService from "services/GlobalService";
import { OnboardServices } from "services";
import ActorsServices from "services/ActorsServices";
import FileCard from "components/shared/FileCardComponent";
import {
  amountOnly,
  checkFor,
  numberOnly,
  handleGoogleResponse,
} from "utilities/ValidationHelper";
import PaymentTermsTable from "components/PaymentTermsTable";
import StaffDetailsTable from "components/StaffDetailsTable";
import GeoCode from "components/shared/GeoCode";
import MultipleSelectImage from "components/shared/MulitpleSelectImage";
import ActorAvailabilityTable from "components/ActorAvailabilityTable";
import ActorConfirmModel from "components/ActorConfirmModel";
import { Link, Prompt } from "react-router-dom";
import { getSPermissionFromMenus } from "store/stateHelper";
import { getPermissions, getNotificationCount } from "store/thunk-actions";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import Title from "components/shared/TitleComponent";
import TitleText from "components/shared/TItleText";
import { GeoServices } from "components/shared/GeoCode/geoCall";

import "./profile.scss";
import { handleResponse } from "utilities/HandleResponseHelper";
import { handleError } from "utilities/ErrorHandler";
import Loader from "components/shared/Loader";
import TooltipComponent from "components/shared/TooltipComponent";
import { getScriptAPIURL } from "utilities/APIHelper";
import Axios from "axios";
import { getFileExtension } from "utilities/UserHelper";
import booleanPropNaming from "eslint-plugin-react/lib/rules/boolean-prop-naming";
import MultipleUpload from "components/MultipleUpload/MultipleUpload";

const Option = { Select };

const MIME_TYPES = {
  "audio/aac": ".aac",
  "application/x-abiword": ".abw",
  "application/x-freearc": ".arc",
  "video/x-msvideo": ".avi",
  "application/vnd.amazon.ebook": ".azw",
  "application/octet-stream": ".bin",
  "image/bmp": ".bmp",
  "application/x-bzip": ".bz",
  "application/x-bzip2": ".bz2",
  "application/x-csh": ".csh",
  "text/css": ".css",
  "text/csv": ".csv",
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "application/vnd.ms-fontobject": ".eot",
  "application/epub+zip": ".epub",
  "application/gzip": ".gz",
  "image/gif": ".gif",
  "text/html": ".html",
  "image/vnd.microsoft.icon": ".ico",
  "text/calendar": ".ics",
  "application/java-archive": ".jar",
  "image/jpeg": ".jpeg",
  "text/javascript": ".js",
  "application/json": ".json",
  "application/ld+json": ".jsonld",
  "audio/midi audio/x-midi": ".midi",
  "text/javascript": ".mjs",
  "audio/mpeg": ".mp3",
  "video/mpeg": ".mpeg",
  "application/vnd.apple.installer+xml": ".mpkg",
  "application/vnd.oasis.opendocument.presentation": ".odp",
  "application/vnd.oasis.opendocument.spreadsheet": ".ods",
  "application/vnd.oasis.opendocument.text": ".odt",
  "audio/ogg": ".oga",
  "video/ogg": ".ogv",
  "application/ogg": ".ogx",
  "audio/opus": ".opus",
  "font/otf": ".otf",
  "image/png": ".png",
  "application/pdf": ".pdf",
  "application/php": ".php",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    ".pptx",
  "application/vnd.rar": ".rar",
  "application/rtf": ".rtf",
  "application/x-sh": ".sh",
  "image/svg+xml": ".svg",
  "application/x-shockwave-flash": ".swf",
  "application/x-tar": ".tar",
  "image/tiff": ".tiff",
  "video/mp2t": ".ts",
  "font/ttf": ".ttf",
  "text/plain": ".txt",
  "application/vnd.visio": ".vsd",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "video/webm": ".webm",
  "image/webp": ".webp",
  "font/woff": ".woff",
  "font/woff2": ".woff2",
  "application/xhtml+xml": ".xhtml",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "text/xml ": ".xml",
  "application/vnd.mozilla.xul+xml": ".xul",
  "application/zip": ".zip",
  "video/3gpp": ".3gp",
  "video/3gpp2": ".3g2",
  "application/x-7z-compressed": ".7z",
};

class Actorprofile extends Component {
  constructor(props) {
    super(props);
    this.handleGeoChange = debounce(this.handleGeoChange, 1000);
    this._geoService = new GeoServices();
    this.scriptservice = new ScriptService();
    this._globalService = new GlobalService();
    this.onboardservices = new OnboardServices();
    this._actorService = new ActorsServices();
    this.totalAmount = 0;
    this.projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);
    this.state = {
      isdirty: false,
      ddd: "",
      newActorId: null,
      currentStatus: null,
      actorId: null,
      projectimageid: null,
      formvalues: null,
      paymentData: [],
      staffData: [],
      fileuploadid: null,
      isButtonDisabled: true,
      actorName: null,
      characterName: null,
      characterType: null,
      rumeneration: null,
      pan: null,
      gst: null,
      addressLine: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      vanityVan: null,
      pickup: true,
      pickupAddressLine: null,
      pickupCity: null,
      pickupState: null,
      pickupZipCode: null,
      pickupGettingCountry: null,
      notes: "",
      projectActor: {},
      legalAgreementId: null,
      permanentAddress: {
        addressLine: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
      },
      pickupAddress: {
        addressLine: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
      },
      conveyance: null,
      status: null,
      isLoading: false,
      gettingCharacterName: null,
      gettingCharacterType: null,
      gettingVanityVan: null,
      gettingStatus: null,
      gettingcountry: null,
      fileData: null,
      rowKey: 0,
      fileList: null,
      docFiles: null,
      imageFiles: null,
      paymentCount: 0,
      staffCount: 0,
      actorAvailabilityCount: 0,
      isStaffRequired: false,
      actorPaymentTypeData: null,
      actorPaymentType: null,
      isPaymentTableLoading: false,
      actorAvailability: [],
      confirmedActorname: null,
      isModalVisible: false,
      phoneCodeList: null,
      phoneCode: "+91",
      isActorDetailFetched: true,
      phoneNumber: null,
      pickupSameAsPermanent: false,
      isDownloading: false,
      existingActorsList: [],
    };
    // this.state = {
    //   paymentData: [
    //     {
    //       amount: 50,
    //       paymentDate: "20-10-2019",
    //       paymentId: 1,
    //       remarks: "fgfjgh"
    //     },
    //     {
    //       amount: 50000,
    //       paymentDate: "20-10-2019",
    //       paymentId: 2,
    //       remarks: "fgfjgh"
    //     }
    //   ]
    // };
  }

  handleGeoChange = (e, isPickUP = false) => {
    console.log("isPickUP", isPickUP);
    console.log("e", e);

    let { permanentAddress, pickupAddress } = this.state;
    if (e.length > 5) {
      this._geoService
        .services("PIN_CODE", e)
        .then((res) => {
          if (res.data.error_message) {
            showActionMessage(GlobalConstants.ERROR, res.data.error_message);
            if (isPickUP) {
              this.setState(
                {
                  pickupZipCode: e,
                  pickupAddress: { ...pickupAddress, zipCode: e },
                },
                () =>
                  this.isSameAddress(
                    this.state.permanentAddress,
                    this.state.pickupAddress
                  )
              );
            } else {
              this.setState(
                {
                  zipCode: e,
                  permanentAddress: { ...permanentAddress, zipCode: e },
                },
                () =>
                  this.isSameAddress(
                    this.state.permanentAddress,
                    this.state.pickupAddress
                  )
              );
            }
          } else {
            const [city, state, gettingcountry] = handleGoogleResponse(res);
            if (isPickUP) {
              this.setState(
                {
                  pickupCity: city,
                  pickupState: state,
                  pickupGettingCountry: gettingcountry,
                  pickupZipCode: e,
                  pickupAddress: { ...pickupAddress, zipCode: e },
                },
                () =>
                  this.isSameAddress(
                    this.state.permanentAddress,
                    this.state.pickupAddress
                  )
              );
            } else {
              this.setState(
                {
                  city,
                  state,
                  gettingcountry,
                  zipCode: e,
                  permanentAddress: { ...permanentAddress, zipCode: e },
                },
                () =>
                  this.isSameAddress(
                    this.state.permanentAddress,
                    this.state.pickupAddress
                  )
              );
            }
          }
        })
        .catch((err) => {
          if (isPickUP) {
            this.setState(
              {
                pickupZipCode: e,
                pickupAddress: { ...pickupAddress, zipCode: e },
              },
              () =>
                this.isSameAddress(
                  this.state.permanentAddress,
                  this.state.pickupAddress
                )
            );
          } else {
            this.setState(
              {
                zipCode: e,
                permanentAddress: { ...permanentAddress, zipCode: e },
              },
              () =>
                this.isSameAddress(
                  this.state.permanentAddress,
                  this.state.pickupAddress
                )
            );
          }
          showActionMessage(
            GlobalConstants.ERROR,
            `Invalid Zipcode, Please try again`
          );
        });
    }
  };

  componentDidMount() {
    this.getCountries();
    this.getActorDetails();
    this.getActorPaymentTypeData();
    this.getCharacterType();
    this.getCountries();
    this.getStatus();
    this.getVanityVan();
    if (this.props.match.params.id) {
      this.setState({
        actorId: this.props.match.params.id,
      });
    }
    this.setState({
      gettingStatus: "TO_BE_CONFIRMED",
    });
    let characterType = this.props.match.params.characterType;
  }

  getActorDetails = () => {
    let characterType = this.props.characterType;

    let urlId = this.props.match.params.id;
    let actorId = urlId;
    // if(this.props){
    if (
      !this.props.match.params.id ||
      this.props.match.params.id == "undefined"
    ) {
      return;
    }
    // }

    this.setState({
      isActorDetailFetched: false,
    });

    this._actorService
      .actorsServices(Services.ActorVariables.GET_ACTOR_ID, actorId)
      .then((res) => {
        this.setState({ isActorDetailFetched: true });
        try {
          this.setState(
            {
              fileData: [],
              actorName: res.data.actorName,
              gettingCharacterName: this.props.match.params.characterName,
              gettingCharacterType: this.props.match.params.characterType,
              rumeneration: res.data.projectActor.rumuneration,
              pendingPayment: res.data.projectActor.pendingPayment,
              actorPaymentType: res.data.actorPaymentType,
              gst: res.data.projectActor.gst,
              paymentData: res.data.actorPayment,
              staffData: res.data.actorStaff,
              pan: res.data.pan,
              imageFiles: res?.data?.actorDocument,
              isPaymentTableLoading: false,
              mappedScenes: res.data.mappedScenes,
              addressLine: res.data.address && res.data.address.addressLine,
              city: res.data.address && res.data.address.city,
              state: res.data.address && res.data.address.state,
              zipCode: res.data.address && res.data.address.zipCode,
              gettingcountry: res.data.address && res.data.address.country,

              pickupAddressLine:
                res.data.pickUpAddress && res.data.pickUpAddress.addressLine,
              pickupCity: res.data.pickUpAddress && res.data.pickUpAddress.city,
              pickupState:
                res.data.pickUpAddress && res.data.pickUpAddress.state,
              pickupZipCode:
                res.data.pickUpAddress && res.data.pickUpAddress.zipCode,
              pickupGettingCountry:
                res.data.pickUpAddress && res.data.pickUpAddress.country,
              gettingVanityVan:
                res.data.projectActor && res.data.projectActor.vanityVan,
              pickup:
                res.data.projectActor && res.data.projectActor.isPickUP
                  ? true
                  : false,
              permanentAddress: res.data.address,
              pickupAddress: res.data.pickUpAddress,
              conveyance:
                res.data.projectActor && res.data.projectActor.conveyance,
              gettingStatus:
                res.data.projectActor && res.data.projectActor.state,
              currentStatus:
                res.data.projectActor && res.data.projectActor.state,
              projectActor: res.data.projectActor,
              legalAgreementId: res.data.legalAgreementId,
              notes: res.data.notes,
              fileList: res.data && res.data.actorDocument,
              isStaffRequired:
                res.data && res.data.isStaffRequired ? true : false,
              phoneCode: res.data.phoneCode,
              phoneNumber: res.data.phoneNumber,
              email: res.data.email,
              isActorDetailFetched: true,
            },
            () => {
              this.isSameAddress(
                this.state.permanentAddress,
                this.state.pickupAddress
              );
            }
          );
        } catch (err) {
          this.setState({
            isActorDetailFetched: true,
          });
        }
        this.refs.image.scrollIntoView({ block: "end", behavior: "smooth" });
        let paymentData = [];
        res.data.actorPayment.map((datum, i) => {
          paymentData.push({ ...datum, key: i });
          this.setState({
            paymentData,
            count: this.state.paymentCount + 1,
          });
        });
        let actorAvailability = [];
        res.data.actorAvailability.map((datum, i) => {
          actorAvailability.push({ ...datum, key: i });
          this.setState({
            actorAvailability,
            count: this.state.actorAvailabilityCount + 1,
          });
        });

        let staffData = [];
        res.data.actorStaff.map((datum, i) => {
          staffData.push({ ...datum, key: i });
          this.setState({
            staffData,
            count: this.state.staffCount + 1,
          });
        });
      })
      .catch((err) => {
        this.setState({
          isActorDetailFetched: true,
        });
        this.props.getPermissions();
      });
  };

  getExistingActor = (e) => {
    if (e === "") return null;
    this.setState({
      actorName: e,
      isdirty: true,
    });
    let characterId = this.props.location.pathname.split("/")[5];
    let data = e;
    this._actorService
      .actorsServices(
        Services.ActorVariables.GET_EXISTING_ACTORS,
        null,
        null,
        data,
        null,
        null,
        characterId
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            existingActorsList: res.data.characters,
          });
        }
      })
      .catch((err) => {});
  };

  getexistingActorId = (id, actorName) => {
    this.setState({
      actorName: actorName,
      newActorId: id,
    });

    this._actorService
      .actorsServices(
        Services.ActorVariables.GET_ACTOR_BY_ID,
        id,
        null,
        null,
        null,
        null,
        null
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            phoneNumber: res.data.phoneNumber,
          });
          this.setState({
            email: res.data.email,
          });
        }
      })
      .catch((err) => {});
  };

  getActorPaymentTypeData = () => {
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        GlobalConstants.ACTOR_PAYMENT_TYPE
      )
      .then((res) => {
        this.setState({
          actorPaymentTypeData: res.data,
        });
      });
  };

  getVanityVan = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "VANITY_VAN")
      .then((res) => {
        this.setState({
          vanityVan: res.data,
        });
      });
  };

  getStatus = () => {
    let template = [];
    this._globalService
      .globalService(
        Services.GlobalVariables.GET_MASTER_DATA,
        "APPROVAL_STATUS"
      )
      .then((res) => {
        this.setState({
          status: res.data,
        });
      });
  };

  getCharacterType = () => {
    let template = [];
    this._globalService
      .globalService(Services.GlobalVariables.GET_MASTER_DATA, "CHARACTER_TYPE")
      .then((res) => {
        this.setState({
          characterType: res.data,
        });
      });
  };

  getCountries = () => {
    let country = [];
    this.onboardservices
      .onboardService(Services.OnboardVariables.GET_COUNTRY)
      .then((res) => {
        this.setState({
          country: res.data.countries,
          phoneCodeList: res.data.countries,
        });
      });
  };

  getDetails = (city, state, gettingcountry, zipCode) => {
    this.setState({
      city,
      isdirty: true,
    });
    this.setState({
      state,
      isdirty: true,
    });
    this.setState({
      gettingcountry,
      isdirty: true,
    });
    this.setState({
      zipCode,
      isdirty: true,
    });
  };

  handleImageIDS = (data) => {
    const { fileList } = this.state;
    let files = [];
    data.map((id) => {
      // files.push({ attachmentId: id, fileType: GlobalConstants.IMAGE });
      files.push(id);
    });

    if (fileList) {
      let temp = [...fileList, ...files];
      this.setState({
        fileList: temp,
      });
    } else {
      let temp = files;
      this.setState({
        fileList: temp,
      });
    }

    // this.setState(
    //   {
    //     fileList: data,
    //   },
    //   () => {
    //     this.handleImageUpdate();
    //   }
    // );
  };

  handleDocument = (id) => {
    const { fileList } = this.state;
    this.setState({
      fileuploadid: id,
    });

    if (fileList) {
      let temp = [
        ...fileList,
        { attachmentId: id, fileType: GlobalConstants.DOCUMENT },
      ];
      this.setState(
        {
          fileList: temp,
        },
        () => {
          if (this.props.match.params.id != undefined) {
            this.handleDocumentUpdate();
          }
        }
      );
    } else {
      let temp = [{ attachmentId: id, fileType: GlobalConstants.DOCUMENT }];
      this.setState({
        fileList: temp,
      });
    }
  };

  splitBasedOnFileTypes = (files) => {
    const { fileList } = this.state;
    let docFiles =
      files &&
      files.filter((file) => file.fileType === GlobalConstants.DOCUMENT);
    let imageFiles =
      files && files.filter((file) => file.fileType === GlobalConstants.IMAGE);
    this.setState({
      docFiles,
      imageFiles,
    });
  };

  // handlepaymentpost = (postdata) => {
  // let datainstate = this.state.paymentData
  // datainstate.push({...postdata, key: this.state.rowKey})
  // // let length = datainstate.length
  // // let arraylength = length + 1
  // //  let addobject = Object.assign(datainstate[arraylength],datainpost)

  //   this.setState({
  //     paymentData:datainstate,
  //     rowKey: this.state.rowKey + 1
  //   })
  // //   // this.setState({
  // //   //   paymentData:addobject
  // //   // })
  // }

  handlepayment = (data) => {
    this.setState({
      paymentData: data,
    });
  };

  handlestaff = (details) => {
    this.setState({
      staffData: details,
    });
  };

  handleAvailability = (details) => {
    this.setState({
      actorAvailability: details,
    });
  };

  deleteImage = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteImageIndex = this.state.imageFiles.findIndex(
      (a) => a.documentId === id
    );

    let imageFiles = this.state.imageFiles;
    let fileList = this.state.fileList;

    imageFiles.splice(deleteImageIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      imageFiles,
      fileList,
    });
  };

  //compare permanent Address and Pickup Address
  isSameAddress = (address1, address2) => {
    let t_address1 =
      address1?.addressLine +
        address1?.city +
        address1?.state +
        address1?.zipCode +
        address1?.country || "";
    let t_address2 =
      address2?.addressLine +
        address2?.city +
        address2?.state +
        address2?.zipCode +
        address2?.country || "";
    this.setState({
      pickupSameAsPermanent:
        t_address1?.toLowerCase() === t_address2?.toLowerCase() || false,
    });
  };

  deleteDoc = (id) => {
    let deleteFileIndex = this.state.fileList.findIndex(
      (a) => a.documentId === id
    );
    let deleteDocIndex = this.state.docFiles.findIndex(
      (a) => a.documentId === id
    );

    let docFiles = this.state.docFiles;
    let fileList = this.state.fileList;
    docFiles.splice(deleteDocIndex, 1);
    fileList.splice(deleteFileIndex, 1);
    this.setState({
      docFiles,
      fileList,
    });
  };
  handleImageUpdate() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          actorId: this.props.match.params.id,
          actorName: this.state.actorName,
          // actorPayment: this.state.paymentData,
          // actorStaff: this.state.staffData,
          address: {
            addressLine: this.state.addressLine,
            city: this.state.city,
            country: this.state.gettingcountry,
            state: this.state.state,
            zipCode: this.state.zipCode,
          },
          characterName: this.props.match.params.characterName,
          characterType: this.props.match.params.characterType,
          sceneCharacterId: this.props?.match?.params?.characterId || null,
          actorPaymentType: this.state.actorPaymentType,
          isStaffRequired: this.state.isStaffRequired,
          actorDocument: this.state.fileList,
          pan: this.state.pan,
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: this.state.gst,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: this.state.gettingStatus,
            vanityVan: this.state.gettingVanityVan,
          },
        };

        const notConfirmedData = {
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          actorId: this.props.match.params.id,
          actorDocument: this.state.fileList,
          actorName: this.state.actorName,
          characterName: this.props.match.params.characterName,
          characterType: this.props.match.params.characterType,
          sceneCharacterId: this.props?.match?.params?.characterId || null,
          projectActor: {
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            state: this.state.gettingStatus,
          },
        };

        if (
          this.state.gettingStatus === GlobalConstants.CONFIRMED ||
          this.state.gettingStatus === GlobalConstants.NEGOTIATION
        ) {
          this.setState({
            isActorDetailFetched: false,
          });
          this.scriptservice
            .scriptService(Services.AddActor.ADD_ACTOR, data)
            .then((res) => {
              showActionMessage(
                GlobalConstants.SUCCESS,
                GlobalConstants.CHANGES_HAVE_BEEN_SAVED
              );
              this.setState({ isLoading: false });
              this.getActorDetails();
            })
            .catch((err) => {
              this.setState({ isLoading: true, isActorDetailFetched: true });
              showNotifications(
                Notifications.ERROR,
                "Failed",
                handleError(
                  err.response && err.response.data && err.response.data.message
                )
              );
            });
        } else {
          this.setState({
            isActorDetailFetched: true,
          });
          this.setState({ isdirty: false });
          this.scriptservice
            .scriptService(Services.AddActor.ADD_ACTOR, notConfirmedData)
            .then((res) => {
              showActionMessage(
                GlobalConstants.SUCCESS,
                GlobalConstants.CHANGES_HAVE_BEEN_SAVED
              );
              // this.getActorDetails();
              this.props.history.goBack();
            })
            .catch((err) => {
              this.setState({ isLoading: false, isActorDetailFetched: true });
              showNotifications(
                Notifications.ERROR,
                "Failed",
                handleError(
                  err.response && err.response.data && err.response.data.message
                )
              );
            });
        }
      }
    });
  }
  handleDocumentUpdate() {
    const data = {
      actorId: this.props.match.params.id,
      actorName: this.state.actorName,
      // actorPayment: this.state.paymentData,
      // actorStaff: this.state.staffData,
      address: {
        addressLine: this.state.addressLine,
        city: this.state.city,
        country: this.state.gettingcountry,
        state: this.state.state,
        zipCode: this.state.zipCode,
      },
      characterName: this.props.match.params.characterName,
      characterType: this.props.match.params.characterType,
      sceneCharacterId: this.props?.match?.params?.characterId || null,
      actorPaymentType: this.state.actorPaymentType,
      isStaffRequired: this.state.isStaffRequired,
      actorDocument: this.state.fileList,
      pan: this.state.pan,
      projectActor: {
        conveyance: this.state.conveyance,
        gst: this.state.gst,
        isPickUP: this.state.pickup,
        projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        rumuneration: this.state.rumeneration,
        state: this.state.gettingStatus,
        vanityVan: this.state.gettingVanityVan,
      },
      email: this.state.email,
      phoneCode: this.state.phoneCode,
      phoneNumber: this.state.phoneNumber,
    };

    const notConfirmedData = {
      email: this.state.email,
      phoneCode: this.state.phoneCode,
      phoneNumber: this.state.phoneNumber,
      actorId: this.props.match.params.id,
      actorDocument: this.state.fileList,
      actorName: this.state.actorName,
      characterName: this.props.match.params.characterName,
      characterType: this.props.match.params.characterType,
      sceneCharacterId: this.props?.match?.params?.characterId || null,
      projectActor: {
        projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
        state: this.state.gettingStatus,
      },
    };

    if (
      this.state.gettingStatus === GlobalConstants.CONFIRMED ||
      this.state.gettingStatus === GlobalConstants.NEGOTIATION
    ) {
      this.scriptservice
        .scriptService(
          Services.AddActor.ADD_ACTOR,
          data,
          console.log("hai iam there", data)
        )
        .then((res) => {
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Actor Document Updated!!"
          );
          this.setState({ isLoading: false });
          // this.props.history.goBack();
          this.getActorDetails();
        })
        .catch((err) => {
          this.setState({ isLoading: true });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    } else {
      this.scriptservice
        .scriptService(
          Services.AddActor.ADD_ACTOR,
          notConfirmedData,
          console.log("hai iam there", notConfirmedData)
        )
        .then((res) => {
          showNotifications(
            Notifications.SUCCESS,
            "Success",
            "Actor Document Created!!"
          );
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          showNotifications(
            Notifications.ERROR,
            "Failed",
            handleError(
              err.response && err.response.data && err.response.data.message
            )
          );
        });
    }
  }

  handleSubmit = (e) => {
    this.setState({
      isdirty: false,
    });
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let modifiedData = this.state.staffData;
        if (modifiedData.length > 0) {
          modifiedData[0].designationName =
            this.state.staffData[0]?.designationLabel || null;
        }
        this.setState({
          formvalues: values,
          isButtonDisabled: false,
          staffData: modifiedData,
        });

        const data = {
          actorDocument: this.state.fileList,
          actorId: this.state.newActorId,
          actorName: this.state.actorName,
          actorPayment: this.state.paymentData,
          actorStaff: this.state.staffData,
          actorAvailability: this.state.actorAvailability,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: values.zipCode,
          },
          actorPaymentType: this.state.actorPaymentType,
          characterName: values.character,
          characterType: values.charactertype,
          sceneCharacterId: this.props?.match?.params?.characterId || null,
          isStaffRequired: this.state.isStaffRequired,
          pan: values.pan,
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          notes: this.state.notes,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: values.gst,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
          pickUpAddress: {
            country: this.state.pickupGettingCountry,
            addressLine: this.state.pickupAddressLine,
            city: this.state.pickupCity,
            state: this.state.pickupState,
            zipCode: this.state.pickupZipCode,
          },
        };

        const notConfirmedData = {
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          actorDocument: this.state.fileList,
          actorName: this.state.actorName,
          actorId: this.state.newActorId,
          actorPayment: null,
          actorStaff: null,
          characterName: values.character,
          characterType: values.charactertype,
          isStaffRequired: null,
          actorPaymentType: null,
          pan: values.pan,
          sceneCharacterId: this.props?.match?.params?.characterId || null,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: null,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: null,
            state: values.status,
            vanityVan: values.vanityvan,
          },
        };

        if (
          this.state.gettingStatus === GlobalConstants.CONFIRMED ||
          this.state.gettingStatus === GlobalConstants.NEGOTIATION
        ) {
          this.scriptservice
            .scriptService(Services.AddActor.ADD_ACTOR, data)
            .then((res) => {
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "Actor Successfully Created!!"
              );
              this.props.history.goBack();
              this.props.getNotificationCount();
            })
            .catch((err) => {
              if (
                err.response.data &&
                err.response.data.message === GlobalConstants.ACCESS_DENIED
              ) {
                this.props.getPermissions();
                this.props.history.goBack();
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  GlobalConstants.ACCESS_DENIED_ERROR
                );
              } else {
                this.setState({ isLoading: false });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  handleError(
                    err.response &&
                      err.response.data &&
                      err.response.data.message
                  )
                );
              }
            });
        } else {
          this.scriptservice
            .scriptService(
              Services.AddActor.ADD_ACTOR,
              notConfirmedData,
              console.log("hai iam there", notConfirmedData)
            )
            .then((res) => {
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "Actor Successfully Created!!"
              );
              this.props.history.goBack();
              this.props.getNotificationCount();
            })
            .catch((err) => {
              if (err.response.data.message === GlobalConstants.ACCESS_DENIED) {
                this.props.getPermissions();
                this.props.history.goBack();
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  GlobalConstants.ACCESS_DENIED_ERROR
                );
              } else {
                this.setState({ isLoading: false });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  handleError(
                    err.response &&
                      err.response.data &&
                      err.response.data.message
                  )
                );
              }
            });
        }
      }
    });
  };

  handleUpdate = (e) => {
    const { activePermisision } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          isdirty: false,
          isActorDetailFetched: false,
        });
        const data = {
          actorId: this.props.match.params.id,
          actorName: values.actorname,
          address: {
            addressLine: values.Address,
            city: values.city,
            country: values.country,
            state: values.state,
            zipCode: values.zipCode,
          },
          characterName: values.character,
          characterType: values.charactertype,
          actorPaymentType: this.state.actorPaymentType,
          sceneCharacterId: this.props?.match?.params?.characterId || null,
          isStaffRequired: this.state.isStaffRequired,
          // actorDocument: this.state.fileList,
          pan: values.pan,
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          projectActor: {
            conveyance: this.state.conveyance,
            gst: values.gst,
            isPickUP: this.state.pickup,
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            rumuneration: this.state.rumeneration,
            state: values.status,
            vanityVan: values.vanityvan,
          },
          pickUpAddress: {
            country: this.state.pickupGettingCountry,
            addressLine: this.state.pickupAddressLine,
            city: this.state.pickupCity,
            state: this.state.pickupState,
            zipCode: this.state.pickupZipCode,
          },
          notes: this.state.notes,
        };

        const notConfirmedData = {
          email: this.state.email,
          phoneCode: this.state.phoneCode,
          phoneNumber: this.state.phoneNumber,
          actorId: this.props.match.params.id,
          actorDocument: this.state.fileList,
          sceneCharacterId: this.props?.match?.params?.characterId || null,
          actorName: values.actorname,
          characterName: values.character,
          characterType: values.charactertype,
          projectActor: {
            projectId: localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID),
            state: values.status,
          },
        };

        if (
          this.state.gettingStatus === GlobalConstants.CONFIRMED ||
          this.state.gettingStatus === GlobalConstants.NEGOTIATION
        ) {
          if (
            !checkFor(activePermisision, GlobalConstants.PERMISSION_PERSONAL)
          ) {
            delete data.address;
            delete data.pickUpAddress;
          }
          this.scriptservice
            .scriptService(
              Services.AddActor.ADD_ACTOR,
              data,
              console.log("hai iam there", data)
            )
            .then((res) => {
              // showNotifications(
              //   Notifications.SUCCESS,
              //   "Success",
              //   "Actor Successfully Updated!!"
              // );
              showActionMessage(
                GlobalConstants.SUCCESS,
                GlobalConstants.CHANGES_HAVE_BEEN_SAVED
              );
              this.props.getNotificationCount();
              this.setState({ isLoading: false, isActorDetailFetched: true });
              this.props.history.goBack();
            })
            .catch((err) => {
              if (
                err?.response?.data?.message === GlobalConstants.ACCESS_DENIED
              ) {
                this.props.getPermissions();
                this.props.history.goBack();
                this.setState({
                  isActorDetailFetched: true,
                });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  handleError(
                    err.response &&
                      err.response.data &&
                      err.response.data.message
                  )
                );
              } else {
                this.setState({ isLoading: true, isActorDetailFetched: true });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  err.response &&
                    err.response.data.errors[0] &&
                    err.response.data.errors[0].message,
                  handleError(
                    err.response &&
                      err.response.data.errors[0] &&
                      err.response.data.errors[0].message
                  )
                );
              }
            });
        } else {
          if (
            !checkFor(activePermisision, GlobalConstants.PERMISSION_PERSONAL)
          ) {
            delete notConfirmedData.address;
            delete notConfirmedData.pickUpAddress;
          }
          this.scriptservice
            .scriptService(
              Services.AddActor.ADD_ACTOR,
              notConfirmedData,
              console.log("hai iam there", notConfirmedData)
            )
            .then((res) => {
              showNotifications(
                Notifications.SUCCESS,
                "Success",
                "Actor Successfully Created!!"
              );
              this.setState({
                isActorDetailFetched: true,
              });
              this.props.history.goBack();
              this.props.getNotificationCount();
            })
            .catch((err) => {
              if (err.response.data.message === GlobalConstants.ACCESS_DENIED) {
                this.props.getPermissions();
                this.props.history.goBack();
                this.setState({
                  isActorDetailFetched: true,
                });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  GlobalConstants.ACCESS_DENIED_ERROR
                );
              } else {
                this.setState({ isLoading: false, isActorDetailFetched: true });
                showNotifications(
                  Notifications.ERROR,
                  "Failed",
                  err.response &&
                    err.response.data.errors[0] &&
                    err.response.data.errors[0].message,
                  handleError(
                    err.response &&
                      err.response.data.errors[0] &&
                      err.response.data.errors[0].message
                  )
                );
              }
            });
        }
      }
    });
  };

  staffTable() {
    if (this.state.isStaffRequired === true) {
      // this.getActorDetails();
    }
  }

  setActorPayment = (value) => {
    this.setState({
      actorPaymentType: value,
      isPaymentTableLoading: true,
      isdirty: true,
    });
  };

  changeStaffDetails = (value) => {
    this.setState({
      isStaffRequired: value.target.value,
      isdirty: true,
    });
    if (value.target.value === true) {
      // this.getActorDetails();
    }
  };

  // onBlur
  dd1 = (value) => {
    let ddd = this.state.ddd;
    let a = this.state.status || [];
    let b = a.map((a) => a.label);
    let dx = true;
    new Promise((resolve) => {
      b.forEach((x) => {
        if (x.trim() != ddd.trim()) {
          dx = false;
        } else {
          dx = true;
        }
      });

      resolve();
    })
      .then(() => {
        if (!dx) {
          a.push({ label: ddd, value: ddd });
        }
      })
      .then(() => {
        this.setState({
          status: a,
        });
      })
      .then(() => {
        this.setState({
          gettingStatus: ddd,
        });
      });
  };

  // onType
  ddd = (value) => {
    if (value && value.trim().length > 0) {
      this.setState({
        ddd: value,
      });
    }
  };

  handleStatus = (value) => {
    if (value !== GlobalConstants.CONFIRMED) {
      this.setState({
        gettingStatus: value,
        // isdirty: true
      });
    }

    let characterId = this.props.match.params.characterId;
    if (value === GlobalConstants.CONFIRMED) {
      this._actorService
        .actorsServices(
          Services.ActorVariables.GET_CONFIRMED_ACTOR,
          null,
          null,
          null,
          null,
          null,
          characterId
        )
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              confirmedActorname:
                res.data.actors && res.data.actors[0].actorName,
              isModalVisible: true,
            });
          }
          if (res.status === 204) {
            this.setState({
              gettingStatus: value,
            });
          }
        });
    }
  };
  submitData = () => {
    this.setState({
      gettingStatus: GlobalConstants.CONFIRMED,
      isModalVisible: false,
    });
  };
  closeModal = () => {
    this.setState({
      // gettingStatus: GlobalConstants.TO_BE_CONFIRMED,
      isModalVisible: false,
    });
    this.props.history.goBack();
  };

  handlePickupSameAsPermanent = (isSelected) => {
    if (isSelected) {
      this.props.form.setFieldsValue({
        pickupZipCode: this.props.form.getFieldValue("zipCode"),
        pickupGettingCountry: this.props.form.getFieldValue("country"),
        pickupState: this.props.form.getFieldValue("state"),
        pickupCity: this.props.form.getFieldValue("city"),
        pickupAddressLine: this.props.form.getFieldValue("Address"),
      });
    }
    if (isSelected) {
      this.setState({
        pickupZipCode: this.props.form.getFieldValue("zipCode"),
        pickupGettingCountry: this.props.form.getFieldValue("country"),
        pickupState: this.props.form.getFieldValue("state"),
        pickupCity: this.props.form.getFieldValue("city"),
        pickupAddressLine: this.props.form.getFieldValue("Address"),
      });
    }
    this.setState({ pickupSameAsPermanent: isSelected });
  };

  handleDownloadDocument = () => {
    this.setState({
      isDownloading: true,
    });
    this.state?.docFiles?.forEach((file) => {
      Axios({
        url: file?.attachment?.url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `${this.state.actorName}.${getFileExtension(file?.attachment?.url)}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          isDownloading: false,
        });
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { Option } = Select;
    const {
      isButtonDisabled,
      actorName,
      characterName,
      characterType,
      city,
      state,
      status,
      rumeneration,
      pan,
      gst,
      addressLine,
      zipCode,
      country,
      conveyance,
      pickup,
      vanityVan,
      paymentData,
      staffData,
      gettingVanityVan,
      gettingStatus,
      gettingcountry,
      isStaffRequired,
      actorAvailability,
      isModalVisible,
      fileList,
      pickupGettingCountry,
      pickupAddressLine,
      pickupCity,
      pickupState,
      pickupZipCode,
      permanentAddress,
      pickupAddress,
    } = this.state;
    const { activePermisision } = this.props;

    const { imageFiles } = this.state;
    const maxImageReached = imageFiles && imageFiles.length >= 4;

    const updateTotalAmount = (e) => {
      this.totalAmount = e;
    };

    return checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
      <div>
        <Prompt
          when={this.state.isdirty == true}
          message={(location) =>
            " Are you sure you want to leave the page without submitting"
          }
        />
        <Row
          type="flex"
          align="middle"
          justify={"space-between"}
          style={{
            backgroundColor: "#001529",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 20,
          }}
          className="box-shadow"
        >
          <Col xl={12}>
            <Button
              type="primary"
              style={{
                backgroundColor: "transparent",
                borderWidth: 0,
                fontSize: 20,
                paddingLeft: 0,
              }}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <Icon type="left" />
            </Button>
            <TitleText>
              Actor Profile -{this.props?.match?.params?.characterName || ""} -{" "}
              {this.props?.match?.params?.characterType || ""}
            </TitleText>
          </Col>
          {checkFor(activePermisision, GlobalConstants.PERMISSION_EDIT) && (
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {this.props.match.params.id != undefined ? (
                <Button
                  type="primary"
                  disabled={
                    !checkFor(
                      activePermisision,
                      GlobalConstants.PERMISSION_EDIT
                    )
                  }
                  onClick={this.handleUpdate}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={
                    !checkFor(activePermisision, GlobalConstants.PERMISSION_ADD)
                  }
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>
              )}
            </Col>
          )}
        </Row>

        <Row align="middle" justify="center" type="flex">
          <Col span={22} className="actor-profile-wrapper">
            <Form
              onSubmit={this.handleSubmit}
              scrollToFirstError={true}
              form={this.props.form}
            >
              <Row>
                {this.props.match.params.id && (
                  <Col span={8}>
                    <label ref="image" className="label">
                      Scene Numbers
                    </label>
                    {this.state.mappedScenes &&
                      this.state.mappedScenes.map((scene, i) => (
                        <Tag
                          className={`${scene.environmentType}${scene.timeType} sceneno-tag`}
                          style={{
                            color: "white",
                            backgroundColor: "#ccc",
                          }}
                          onClick={() => {
                            this.props.history.push("/project/screen-overview");
                            localStorage.setItem(
                              GlobalConstants.REDIRECT_TO,
                              Screens.ACTOR
                            );
                            localStorage.setItem(
                              LocalStorage.ACTIVE_SCENE_ID,
                              scene.sceneId
                            );
                            this.props.setActiveTab("1");
                          }}
                        >
                          <Link style={{ color: "white" }}>
                            <TooltipComponent
                              sceneNumber={scene.sceneNumber}
                              text={scene.description}
                              placement="left"
                            />
                          </Link>
                        </Tag>
                      ))}
                  </Col>
                )}
                <Col span={14}>
                  {this.state.isActorDetailFetched && (
                    <>
                      <label className="label">Images</label>
                      <Form.Item>
                        {getFieldDecorator("fileuploadid", {
                          initialValue: this.state.fileuploadid,
                          rules: [
                            {
                              required: false,
                              message: "Please Upload Documents!",
                            },
                          ],
                        })(
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MultipleUpload
                              multiple={true}
                              existingData={this.state.fileList || []}
                              type={AttachmentType?.actorDocument}
                              uploadedAttachement={(e) =>
                                this.handleImageIDS(e)
                              }
                              refreshData={() => this.getActorDetails()}
                            />
                            {/* <div className="fileCardContainer">
                              {this.state.fileList &&
                                this.state.fileList.map((file, i) => (
                                  <FileCard
                                    file={file}
                                    deleteImage={this.deleteImage}
                                    pageType={
                                      Services.LocationVariables
                                        .DELETE_ACTOR_DOCUMENT
                                    }
                                  />
                                ))}
                            </div> */}
                          </div>
                        )}
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col span={2}>
                  {this.state?.actorId && this.state.legalAgreementId && (
                    <>
                      {this.state.gettingStatus ===
                        GlobalConstants.CONFIRMED && (
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {this.state?.legalAgreementId && (
                            <Link
                              to={{
                                pathname: `/project/actorprofile/legal/${
                                  this.state?.projectActor?.id || null
                                }/${this.state?.legalAgreementId || null}/${
                                  this.state?.rumeneration || null
                                }/${this.state?.actorPaymentType}/${
                                  this.props.match.params.characterName
                                }/${this.state?.actorName}/ACTOR`,
                              }}
                            >
                              <Button type="primary">
                                View Legal Information
                              </Button>
                            </Link>
                          )}
                        </Col>
                      )}
                    </>
                  )}
                  {this.state?.actorId && !this.state.legalAgreementId && (
                    <>
                      {this.state.gettingStatus ===
                        GlobalConstants.CONFIRMED && (
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {this.state.rumeneration &&
                            this.state.actorPaymentType && (
                              <Link
                                to={{
                                  pathname: `/project/actorprofile/legal/${
                                    this.state?.projectActor?.id || null
                                  }/${this.state?.legalAgreementId || null}/${
                                    this.state?.rumeneration || null
                                  }/${this.state?.actorPaymentType}/${
                                    this.props.match.params.characterName
                                  }/${this.state?.actorName}/ACTOR`,
                                }}
                              >
                                <Button type="primary" icon="plus">
                                  Add Legal Information
                                </Button>
                              </Link>
                            )}
                        </Col>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <label className="label">*Actor Name</label>
                  <Form.Item>
                    {getFieldDecorator("actorname", {
                      initialValue: actorName,
                      rules: [
                        {
                          required: true,
                          message: "Please Enter Actorname!",
                        },
                      ],
                    })(
                      // <Input
                      //   type="text"
                      //   placeholder="Actor Name"
                      //   onChange={(value) =>
                      //     this.setState({ actorName: value }, () => {})
                      //   }
                      // />
                      <Select
                        showSearch
                        placeholder="Country"
                        className="w-100"
                        onSearch={(value) => this.getExistingActor(value)}
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={(e) => {
                          console.log(e);
                          // if (
                          //   this.state.existingActorsList?.includes({
                          //     label: e,
                          //   }) === false
                          // ) {
                          //   let oldData = this.state?.existingActorsList;
                          //   if (typeof oldData === "string") {
                          //     oldData = [{ label: e, value: e }];
                          //   } else {
                          //     oldData.push({ label: e, value: e });
                          //   }
                          //   console.log(oldData);
                          //   this.setState({
                          //     existingActorsList: oldData,
                          //     actorName: e,
                          //   });
                          // }
                        }}
                        value={this.state.actorName || null}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.existingActorsList &&
                          this.state.existingActorsList.map((option, i) => (
                            <Option
                              value={option.value}
                              key={i}
                              onClick={(e) => {
                                this.setState({ newActorId: e?.key });
                                this.getexistingActorId(
                                  option.value,
                                  option.label
                                );
                              }}
                            >
                              {option.label}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <label className="label">*Phone Number</label>
                  <Form.Item>
                    {getFieldDecorator("phoneNumber", {
                      initialValue: this.state.phoneNumber,
                      rules: [
                        {
                          required: false,
                          message: "Please input your phone number!",
                        },
                        this.state.phoneCode === "+91" && {
                          pattern: ".{10,}",
                          message: "Enter 10 Numbers Only!",
                        },
                      ],
                    })(
                      <Input
                        addonBefore={
                          <Form.Item
                            className="payment-type"
                            style={{
                              margin: "0px",
                              height: "30px",
                              top: "-4px",
                            }}
                          >
                            {getFieldDecorator("phoneCode", {
                              initialValue: this.state.phoneCode || undefined,
                            })(
                              <Select
                                showSearch
                                defaultValue={this.state.phoneCode || "+91"}
                                style={{ width: "80px" }}
                                optionFilterProp="children"
                                onChange={(value) =>
                                  this.setState({ phoneCode: value })
                                }
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toString()
                                    .toLowerCase()
                                    .includes(input.toString().toLowerCase())
                                }
                              >
                                {this.state.phoneCodeList &&
                                  (this.state.phoneCodeList || []).map(
                                    (option, i) => (
                                      <Option value={option.phoneCode}>
                                        {option.phoneCode}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            )}
                          </Form.Item>
                        }
                        style={{ fontWeight: "bold" }}
                        placeholder="Phone Number"
                        onChange={(e) =>
                          this.setState({ phoneNumber: numberOnly(e) })
                        }
                        maxLength={this.state.phoneCode === "+91" ? 10 : null}
                        minLength={this.state.phoneCode === "+91" ? 10 : null}
                      />
                    )}
                  </Form.Item>
                </Col>
                {checkFor(
                  activePermisision,
                  GlobalConstants.PERMISSION_FINANCE
                ) && (
                  <Col span={8}>
                    <label className="label">Email</label>
                    <Form.Item>
                      {getFieldDecorator("email", {
                        initialValue: this.state.email,
                        rules: [
                          {
                            required: false,
                            message: "Please Enter status!",
                          },
                        ],
                      })(
                        <Input
                          style={{ textTransform: "none !important" }}
                          placeholder="Enter email "
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span={8}>
                  <label className="label">*Status</label>
                  <Form.Item>
                    {getFieldDecorator("status", {
                      initialValue:
                        (gettingStatus && gettingStatus) ||
                        GlobalConstants.TO_BE_CONFIRMED,
                      rules: [
                        {
                          required: true,
                          message: "Please Enter status!",
                        },
                      ],
                    })(
                      <Select
                        defaultValue={GlobalConstants.TO_BE_CONFIRMED}
                        placeholder="Status"
                        style={{ width: "100%" }}
                        onChange={this.handleStatus}
                      >
                        {status &&
                          status.map((option, i) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {this.state.gettingStatus === GlobalConstants.CONFIRMED ||
                this.state.gettingStatus === GlobalConstants.NEGOTIATION ? (
                  <>
                    <Col span={8}>
                      <label className="label">*Remuneration</label>
                      <Form.Item>
                        {getFieldDecorator("rumeneration", {
                          initialValue: rumeneration,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Rumeneration!",
                            },
                          ],
                        })(
                          <Input
                            prefix="₹"
                            type="number"
                            min={0}
                            placeholder="Rumeneration"
                            onChange={(e) => {
                              this.setState(
                                {
                                  rumeneration: e.target.value,
                                },
                                () => {
                                  this.setState({
                                    isdirty: true,
                                  });
                                }
                              );
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    {checkFor(activePermisision, "Finance") && (
                      <Col span={8}>
                        <label className="label">*Payment Type</label>
                        <Form.Item>
                          {getFieldDecorator("actorPaymentType", {
                            initialValue:
                              this.state.actorPaymentType || undefined,
                            rules: [
                              {
                                required: true,
                                message: "Payment Type is required!",
                              },
                            ],
                          })(
                            <Select
                              placeholder="Payment Type"
                              style={{ width: "100%" }}
                              onChange={this.setActorPayment}
                            >
                              {this.state.actorPaymentTypeData &&
                                this.state.actorPaymentTypeData.map(
                                  (option, i) => (
                                    <Option value={option.value}>
                                      {option.label}
                                    </Option>
                                  )
                                )}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </>
                ) : null}
              </Row>
              {this.state.gettingStatus === GlobalConstants.CONFIRMED ||
              this.state.gettingStatus === GlobalConstants.NEGOTIATION ? (
                <Row gutter={16}>
                  <Col span={8}>
                    <label>Availability Dates</label>
                    <Form.Item>
                      {getFieldDecorator("actorAvailability", {
                        initialValue: actorAvailability,
                        rules: [
                          {
                            required: false,
                            message: "Please Enter Actor Availability",
                          },
                        ],
                      })(
                        <ActorAvailabilityTable
                          getFromChild={this.handleAvailability}
                          actorId={this.state.actorId}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <label className="label">Notes</label>
                    <Form.Item>
                      {getFieldDecorator("notes", {
                        initialValue: this.state.notes,
                      })(
                        <TextArea
                          type="text"
                          placeholder="Notes"
                          onChange={(value) =>
                            this.setState({ notes: value.target.value })
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              <Row
                gutter={20}
                type="flex"
                justify="end"
                style={{ marginTop: "20px" }}
              >
                <Col>
                  <Button onClick={() => this.props.history.goBack()}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  {this.props.match.params.id != undefined ? (
                    <Button
                      type="primary"
                      disabled={
                        !checkFor(
                          activePermisision,
                          GlobalConstants.PERMISSION_EDIT
                        )
                      }
                      onClick={this.handleUpdate}
                      // loading={isLoading}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled={
                        !checkFor(
                          activePermisision,
                          GlobalConstants.PERMISSION_ADD
                        )
                      }
                      onClick={this.handleSubmit}
                      // loading={isLoading}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {!this.state.isActorDetailFetched && <Loader size={12} />}
        <ActorConfirmModel
          isModalVisible={isModalVisible}
          closeModal={this.closeModal}
          submitData={this.submitData}
          confirmedActorname={this.state.confirmedActorname}
          characterName={this.props.match.params.characterName}
        />
      </div>
    ) : (
      <ContactAdminErrorTemplate />
    );
  }
}

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus("Char-Actor", menus),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions()),
    getNotificationCount: () => dispatch(getNotificationCount()),
  };
};

const actorprofiledata = Form.create()(Actorprofile);
export default connect(mapStateToProps, mapDispatchToProps)(actorprofiledata);
