import React, { Component } from "react";
import CreateCharacter from "components/shared/AddCharacter";
import ActorCardComponent from "components/ActorCardComponent";
import { Row, Col, Button } from "antd";
import ActorTabService from "services/SceneOverviewService/ActorTabService";
import { Services, GlobalConstants } from "constants/AppConstants";
import NoSceneFound from "components/NoSceneFound";
import SkeletonLoader from "components/shared/SkeletonLoader";
import { connect } from "react-redux";
import { getPermissions } from "store/thunk-actions";
import { getSPermissionFromMenus } from "store/stateHelper";
import { checkFor } from "utilities/ValidationHelper";
import SkeletonComponent from "components/shared/SkeletonComponent";
import ActorSkeletonCard from "components/Actor/ActorSkeletonCard";
//import { Link } from "react-router-dom";

class MainCharacterComponent extends Component {
  state = {
    cardList: [],
    isActorsFetched: false,
    characterName: [],
    modalVisible: false,
    modalClose: false,
    activeSceneId: null
  };

  constructor(props) {
    super(props);
    this._actorService = new ActorTabService();
  }

  componentDidMount() {
    this.fetchActor();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeSceneId !== this.state.activeSceneId) {
      this.setState(
        {
          activeSceneId: nextProps.activeSceneId
        },
        () => {
          this.fetchActor();
          this.getCharacterName();
        }
      );
    }
  }

  fetchActor = () => {
    this.setState({
      isActorsFetched: false
    });
    this._actorService
      .actorTabService(
        Services.SceneOVerviewVariable.GET_ACTOR,
        "MAIN",
        null,
        this.state.activeSceneId
      )
      .then((res) => {
        this.setState({
          cardList: res.data.characters,
          isActorsFetched: true
        });
        this.getCharacterName();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  refreshActors = () => {
    this.fetchActor();
  };

  getCharacterName = () => {
    let characterName = [];
    this.state.cardList &&
      this.state.cardList.map((actor, i) => {
        characterName.push(actor.characterName);
      });
    this.setState({
      characterName: characterName
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false
    });
  };

  renderActor = () => {
    let actorList = [];
    if (this.state.cardList) {
      this.state.cardList.map((actor, i) => {
        actorList.push(
          <ActorCardComponent
            type={actor.characterType}
            characterName={actor.characterName}
            actorName={actor.actorName}
            characterId={actor.characterId}
            actorId={actor.actorId}
            imageId={
              actor.actorImages &&
              actor.actorImages[0] &&
              actor.actorImages[0].fileData
            }
            refreshActors={this.refreshActors}
            ImageIdList={actor.actorImages}
            {...this.props}
          />
        );
      });
      return actorList;
    }
    return (
      <NoSceneFound type="Actor" refreshProjectList={this.refreshProjectList} />
    );
  };

  renderSkeletonCards = () => {
    let template = [];
    for (let i = 1; i <= 4; i++) {
      template.push(<ActorSkeletonCard />);
    }
    return template;
  };

  render() {
    const { ADD } = this.props;
    return (
      <Row className="mb-25">
        <Col
          xl={{ offset: 1, span: 21 }}
          lg={{ offset: 1, span: 21 }}
          md={{ offset: 1, span: 21 }}
          style={{ border: "1px solid #ccc" }}
        >
          <h3
            className=""
            style={{
              backgroundColor: "#001529",
              color: "#fff",
              padding: "5px",
              textAlign: "center"
            }}
          >
            Main Character
          </h3>

          {ADD && (
            <Button
              type="primary"
              icon="plus"
              style={{
                display: "flex",
                marginLeft: "10px",
                alignItems: "center"
              }}
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
            >
              Add Main Character
            </Button>
          )}
          <Row className="pb-10">
            {this.state.isActorsFetched
              ? this.renderActor()
              : this.renderSkeletonCards()}
          </Row>
        </Col>
        {this.state.modalVisible && (
          <CreateCharacter
            type="MAIN"
            refreshActors={this.refreshActors}
            characterName={this.state.characterName}
            isvisible={this.state.modalVisible}
            closeModal={this.closeModal}
            characterTypeData={this.state.cardList || []}
            {...this.props}
          />
        )}
      </Row>
    );
  }
}
const mapStateToProps = ({ scene, user: { menus } }) => {
  return {
    activeSceneId: scene.activeSceneId,
    activePermisision: getSPermissionFromMenus("Char-Actor", menus)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermissions: () => dispatch(getPermissions())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainCharacterComponent);
