import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Form, Select, Button, DatePicker } from "antd";

import { LocalStorage } from "constants/AppConstants";
import PDFComponent from "components/shared/PDFViewer";
import GlobalService from "services/GlobalService";
import { Services, GlobalConstants } from "constants/AppConstants";
import LocationTabService from "services/SceneOverviewService/LocationTabService";
import CostumeTabService from "services/SceneOverviewService/CostumeTabServices";
import Loader from "components/shared/Loader";
import { getScriptAPIURL } from "utilities/APIHelper";
import { getSPermissionFromMenus } from "store/stateHelper";
import { PermissionPage } from "constants/AppConstants/GlobalConstants";
import { connect } from "react-redux";
import { checkFor } from "utilities/ValidationHelper";
import ContactAdminErrorTemplate from "components/shared/ContactAdminErrorTemplate";
import moment from "moment";

const { Option, OptGroup } = Select;

const { RangePicker, MonthPicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";
const ShootDatesFor = [
  "ACTOR_SCHEDULE_REPORT",
  "LOCATION_SCHEDULE_REPORT",
  "LOCATION_GENERAL_REPORT",
  "ART_SET_GENERAL_REPORT",
];
const ProjectReportForm = (props) => {
  const [ProjectTypeList, setProjectTypeList] = useState(null);
  const [ActorList, setActorList] = useState(null);
  const [SceneLocationList, setSceneLocationList] = useState(null);
  const [ProjectType, setProjectType] = useState("ACTOR_GENERAL_REPORT");
  const [EndDate, setEndDate] = useState("");
  const [SceneLocation, setSceneLocation] = useState(null);
  const [Actors, setActors] = useState([]);
  const [StartDate, setStartDate] = useState("");
  const [actorCallSheetDate, setActorCallSheetDate] = useState(null);
  const [AllLoaded, setAllLoaded] = useState(false);
  const api = new GlobalService();
  const location = new LocationTabService();
  const actor = new CostumeTabService();

  useEffect(() => {
    Promise.all([projectTypeList(), sceneLocationList(), actorList()]).then(
      ([res1, res2, res3]) => {
        setProjectTypeList(res1.data);
        setSceneLocationList(res2.data);
        setActorList(Object.values(res3.data));
        setAllLoaded(true);
      }
    );
  }, []);

  const groupName = [
    "Main",
    "Supporting",
    "Junior",
    "Animal",
    "Crews",
    "Special Equipments",
    "Standard Equipments",
  ];

  const projectTypeList = () => {
    return api.globalService(
      Services.GlobalVariables.GET_MASTER_DATA,
      GlobalConstants.PROJECT_SUB_REPROT_TYPE
    );
  };

  const sceneLocationList = () => {
    return location.locationTabService(
      Services.SceneOVerviewVariable.SCENE_LOCATION
      // locationData
    );
  };

  const actorList = () => {
    return actor.costumeTabService(
      Services.SceneOVerviewVariable.GET_CHARC_NAME_LIST_REPORT
    );
  };

  const resetFields = () => {
    const oldProjectType = ProjectType;
    setProjectType(null);
    setSceneLocation(null);
    setActors(null);
    setActorCallSheetDate(null);
    setTimeout(() => {
      setProjectType(oldProjectType);
    }, 100);
  };

  const formItemLayout = {
    labelCol: {
      sm: { span: 10 },
    },
    wrapperCol: {
      sm: { span: 12 },
    },
  };

  const customCol = {
    labelCol: {
      sm: { span: 10 },
    },
    wrapperCol: {
      sm: { span: 14 },
    },
  };

  const { getFieldDecorator } = props.form;

  const handleReportChange = (ProjectType) => {
    setProjectType(ProjectType);
    if (!ShootDatesFor.includes(ProjectType)) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleRShootDateRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  let projectId = localStorage.getItem(LocalStorage.ACTIVE_PROJECT_ID);

  const { activePermisision } = props;

  const showShootDate = ShootDatesFor.includes(ProjectType);

  const url = `${getScriptAPIURL()}/project/reports/download?${
    Actors && Actors.length !== 0 ? `actorId=${Actors}&` : ""
  }contentDisposition=INLINE&projectId=${projectId}${
    StartDate ? `&startShootDate=${StartDate}` : ""
  }${EndDate ? `&endShootDate=${EndDate}` : ""}&reportType=${ProjectType}${
    SceneLocation && SceneLocation.length !== 0
      ? `&sceneLocationId=${SceneLocation}`
      : ""
  }${
    actorCallSheetDate
      ? `&shootMonthYear=${moment(actorCallSheetDate).format("MMM-YYYY")}`
      : ""
  }`;

  return (
    <Fragment>
      {checkFor(activePermisision, GlobalConstants.PERMISSION_VIEW) ? (
        <Row>
          <Col>
            <Form {...formItemLayout}>
              <Row gutter={16}>
                <Col xl={10}>
                  <Form.Item
                    label="Report type"
                    hasFeedback
                    validateStatus={AllLoaded ? "" : "validating"}
                    help={
                      AllLoaded ? "" : "The information is being loading..."
                    }
                  >
                    {getFieldDecorator("ProjectType", {
                      initialValue: ProjectType,
                      rules: [
                        {
                          required: true,
                          message: "Invoice Number Required!",
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        className="upperCase"
                        showSearch
                        allowClear
                        onChange={handleReportChange}
                      >
                        {ProjectTypeList &&
                          ProjectTypeList.map((list, i) => {
                            return (
                              <Option key={i} value={list.value}>
                                {list.label}
                              </Option>
                            );
                          })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {showShootDate && (
                  <Col xl={10}>
                    <Form.Item
                      label="Shoot Date"
                      hasFeedback
                      validateStatus={AllLoaded ? "" : "validating"}
                      help={
                        AllLoaded ? "" : "The information is being loading..."
                      }
                    >
                      {getFieldDecorator("ShootDate", {
                        initialValue: "",
                      })(
                        <RangePicker
                          style={{ width: 240 }}
                          className="customPicker"
                          // defaultValue={[
                          //   moment(startDate, dateFormat),
                          //   moment(endDate, dateFormat)
                          // ]}
                          onChange={(value, record) => {
                            handleRShootDateRangeChange(record[0], record[1]);
                          }}
                          format={dateFormat}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                {ProjectType ===
                  GlobalConstants.ACTOR_CALL_SHEET_MONTHLY_REPORT && (
                  <Col xl={10}>
                    <Form.Item
                      label="Shoot Month"
                      hasFeedback
                      validateStatus={AllLoaded ? "" : "validating"}
                      help={
                        AllLoaded ? "" : "The information is being loading..."
                      }
                    >
                      {getFieldDecorator("ShootDate", {
                        initialValue: "",
                      })(
                        <MonthPicker
                          style={{ width: 240 }}
                          className="customPicker"
                          // defaultValue={[
                          //   moment(startDate, dateFormat),
                          //   moment(endDate, dateFormat)
                          // ]}
                          format="MMM-YYYY"
                          onChange={(value) => {
                            setActorCallSheetDate(value);
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
                {ProjectType === GlobalConstants.ACTOR_SCHEDULE_REPORT && (
                  <Col xl={10}>
                    <Form.Item label="Actor" hasFeedback>
                      {getFieldDecorator("Actors", {
                        rules: [
                          {
                            required: false,
                            message: "Invoice Number Required!",
                          },
                        ],
                      })(
                        <Select
                          className="upperCase"
                          // mode="multiple"
                          maxTagTextLength={3}
                          maxTagCount={2}
                          allowClear
                          onChange={(Actors) => setActors(Actors)}
                        >
                          {ActorList &&
                            ActorList.map((characterGroup, i) => (
                              <OptGroup label={groupName[i]} key={i}>
                                {characterGroup &&
                                  characterGroup.map((character, i) => (
                                    <Option value={character.value}>
                                      {character.label}
                                    </Option>
                                  ))}
                              </OptGroup>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col xl={4}>
                  <Row type="flex">
                    <PDFComponent
                      customURL={url}
                      icon="download"
                      text="Report"
                      projectId={projectId}
                      hideMail
                      disabled={!ProjectType}
                    />
                    {(ProjectType ===
                      GlobalConstants.LOCATION_SCHEDULE_REPORT ||
                      ProjectType === GlobalConstants.ACTOR_SCHEDULE_REPORT ||
                      ProjectType === "LOCATION_GENERAL_REPORT") && (
                      <Button
                        type="primary"
                        style={{ marginLeft: 5 }}
                        onClick={resetFields}
                      >
                        Reset
                      </Button>
                    )}
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <ContactAdminErrorTemplate />
      )}
    </Fragment>
  );
};

const ProjectReport = Form.create({ name: "register" })(ProjectReportForm);

const mapStateToProps = ({ user: { menus } }) => {
  return {
    activePermisision: getSPermissionFromMenus(PermissionPage.REPORT, menus),
  };
};

export default connect(mapStateToProps, null)(ProjectReport);
