import React, { useState } from "react";
import { Icon } from "antd";

import "./searchbar.scss";

export default function SearchBar({ handleChange, searchKey = null }) {
  const [Focused, setFocused] = useState(false);
  return (
    <div className="searchBar_container">
      <div className={`searchBar focused`}>
        <input
          className="input"
          name="searchKey"
          onChange={(e) => handleChange(e)}
          placeholder="Search Projects..."
          value={searchKey}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <div className="icon_container">
          <Icon
            type={searchKey ? "close" : "search"}
            className="icon"
            style={searchKey ? { color: "#ee556a" } : {}}
            onClick={() => searchKey && handleChange()}
          />
        </div>
      </div>
    </div>
  );
}
